<template>
  <div class="profile">
    <profile-info-tabs
      v-if="this.$route.name != 'Register' && !isTimeLineShown"
    />
    <router-view></router-view>
    <custom-popup
      :visible="welcomePopupVisible"
      :flexible-size="true"
      @close="welcomePopupVisible = false"
    >
      <div class="welcome" @click="welcomePopupVisible = false"></div>
    </custom-popup>
  </div>
</template>

<script>
import ProfileInfoTabs from "./ProfileInfoTabs";
// import ProfileJobs from "./ProfileJobs";
// import ProfileSalaries from "./ProfileSalaries/ProfileSalaries";
// import ProfileInfo from "./ProfileInfo";
// import ProfilePerformance from "@/components/Profile/ProfilePerformance";
// import ProfileInOuts from "@/components/Profile/ProfileInOuts";
// import VacationTermsAndRequests from "@/components/Profile/VacationTermsAndRequests";
// import ProfileSchedule from "@/components/Profile/ProfileSchedule";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";

export default {
  components: {
    ProfileInfoTabs,
    // ProfileJobs,
    // ProfileSalaries,
    // ProfileInfo,
    CustomPopup,
    // VacationTermsAndRequests,
    // ProfilePerformance,
    // ProfileInOuts,
    // ProfileSchedule,
  },
  created() {
    if (this.$route.query.firstAuth == "true") this.welcomePopupVisible = true;
  },
  data() {
    return {
      selectedTab: 0,
      welcomePopupVisible: false,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isHR() {
      return this.$store.getters.hasRole("HR");
    },
    isMember() {
      return this.$store.getters.hasRole("Member");
    },
    isManager() {
      return this.$store.getters.hasRole("Manager");
    },
    isSuperAdmin() {
      return this.$store.getters.hasRole("Super Admin");
    },
    adminTabs() {
      return [
        "Personal info",
        "Jobs",
        "Salaries",
        "Performance",
        "Attendance",
        "Schedule",
        "Vacations",
      ];
    },
    superAdminTabs() {
      return [
        "Personal info",
        "Jobs",
        "Salaries",
        "Performance",
        "Attendance",
        "Schedule",
        "Vacations",
      ];
    },
    hrTabs() {
      return [
        "Personal info",
        "Jobs",
        "Salaries",
        "Performance",
        "Attendance",
        "Schedule",
        "Vacations",
      ];
    },

    managerTabs() {
      return [
        "Personal info",
        "Jobs",
        "Salaries",
        "Performance",
        "Attendance",
        "Schedule",
      ];
    },
    isTimeLineShown() {
      return this.$store.state.showTimeLine;
    },
    titles() {
      if (this.isAdmin) return this.adminTabs;
      else if (this.isHR) return this.hrTabs;
      else if (this.isSuperAdmin) return this.superAdminTabs;
      else if (this.isManager) return this.managerTabs;
      else return this.userTabs;
    },
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>

<style lang="scss">
.profile {
  display: inline;

  & > .overlay {
    .popup {
      padding: 0;

      .title {
        display: none;
      }
    }
    .welcome {
      width: 1200 * $rem;
      height: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("~@/assets/images/welcome.png");
      position: relative;
      padding-top: 53.2%;
    }
  }
}
</style>
