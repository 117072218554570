<template>
<div class="week-schedule">
    <week-schedule-header :cellWidth="cellWidth" :schedule="weekSchedule" />
    <div class="wrapper">
        <week-schedule-content
            :cellWidth="cellWidth"
            :cellHeight="cellHeight"
            @update-schedule="updateSchedule"
            @delete-day="deleteDayHandler"
            @copy="copyDayHandler"
            :schedule="weekSchedule"
            :noEdit="noEdit"
        />
    </div>
    <div class="overlay-no-edit" v-if="noEdit"></div>
</div>
</template>

<script>
import WeekScheduleHeader from "@/components/WeekSchedule/WeekScheduleHeader.vue"
import WeekScheduleContent from "@/components/WeekSchedule/WeekScheduleContent.vue"
import axios from "@/Helpers/AxiosInstance.js"
export default {
    components: {
        WeekScheduleHeader,
        WeekScheduleContent,
    },
    props: {
        scheduleID: Number,
        noEdit: Boolean,
    },
    created() {
        this.getWeekSchedule()
    },
    data() {
        return {
            cellHeight: 26 * 0.0625,
            sidebarExpanded: true,
            sideChatExpanded: false,
            weekSchedule: [],
            cellWidth: 196 * 0.0625,
        }
    },
    methods: {
        updateSchedule(data) {
            var day = ((data.day + 1) % 7) + 1 //for sql - sun: 0, mon: 1, etc...
            var scheduleStartTime = data.fromTime * 60000 //in ms
            var scheduleEndTime = data.toTime * 60000
            var totalWorkTime = data.totalTime * 60000
            var breakStart = 0
            var breakEnd = 0
            var totalBreakTime = data.totalBreak * 60000
            if(scheduleEndTime == 24*60*60*1000) scheduleEndTime -= 60000 //minus 1 minute for backend to work if 24 hour is passed
            axios
                .post("Schedules/UpdateFixedScheduleDay", {
                    Schedule: this.scheduleID,
                    Day: day,
                    ScheduleStartTime: scheduleStartTime,
                    ScheduleEndTime: scheduleEndTime, 
                    TotalWorkTime: totalWorkTime,
                    BreakStartTime: breakStart,
                    BreakEndTime: breakEnd,
                    TotalBreakTime: totalBreakTime,
                })
                .then(() => {
                    this.getWeekSchedule()
                })
        },
        copyDayHandler(data) {
            var original = this.weekSchedule.find(el => el.day == data.fromDay)
            var toDay = ((data.toDay + 1) % 7) + 1
            axios
                .post("Schedules/UpdateFixedScheduleDay", {
                    Schedule: this.scheduleID,
                    Day: toDay,
                    ScheduleStartTime: original.ScheduleStartTime,
                    ScheduleEndTime: original.ScheduleEndTime,
                    TotalWorkTime: original.TotalWorkTime,
                    BreakStartTime: original.BreakStart,
                    BreakEndTime: original.BreakEnd,
                    TotalBreakTime: original.TotalBreakTime,
                })
                .then(() => {
                    this.getWeekSchedule()
                })
        },
        getWeekSchedule() {
            axios
                .get("Schedules/GetFixedScheduleDays", {
                    params: {
                        Schedule: this.scheduleID,
                    },
                })
                .then(resp => {
                    if (!resp.data.ErrorMsg) this.weekSchedule = resp.data.Value
                    //calc total Hrs
                    var weeklyTotalHours = this.weekSchedule.reduce(
                        (sum, el) => (sum += el.TotalWorkTime / 3600000), 
                        0
                    )
                    weeklyTotalHours = parseInt(weeklyTotalHours)
                    this.$emit("totalHours", weeklyTotalHours)
                })
        },
        deleteDayHandler(day) {
            axios
                .post("Schedules/FreeFixedScheduleDay", {
                    FixedScheduleID: this.scheduleID,
                    Day: day,
                })
                .then(resp => {
                    if (!resp.data.ErrorMsg) {
                        this.getWeekSchedule()
                    }
                })
        },
    },
    watch: {
        scheduleID() {
            this.getWeekSchedule()
        },
    },
}
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    overflow: hidden;
}

.week-schedule > .button-container {
    position: absolute;
    margin-bottom: 15 * $rem;
    width: 210 * $rem;
    top: 15 * $rem;
    left: 20 * $rem;
}

.week-schedule {
    border-radius: 6 * $rem;
    overflow: hidden;
    display: inline-block;
    position: relative;

    .overlay-no-edit {
        position: absolute;
        background-color: transparent;
        width: 100%;
        height: 100%;
        z-index: 5;
        top: 0;
        left: 0;
    }
}
</style>