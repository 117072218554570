<template>
  <div
    class="confirmation-content"
    :class="{ alternativePadding: mustConfirmNumb < 2 }"
  >
    <div class="multiple-confirmation-content">
      <div class="confirmation-tabs" v-if="rejectNumb == 0">
        <div
          v-if="mustConfirmNumb > 1"
          :class="{
            backgroundColor: confirmNumb >= 1,
          }"
        ></div>
        <div
          v-if="mustConfirmNumb >= 2"
          :class="{ backgroundColor: confirmNumb >= 2 }"
        ></div>
        <div
          v-if="mustConfirmNumb >= 3"
          :class="{ backgroundColor: confirmNumb >= 3 }"
        ></div>
        <div
          v-if="mustConfirmNumb >= 4"
          :class="{ backgroundColor: confirmNumb >= 4 }"
        ></div>
        <div
          v-if="mustConfirmNumb >= 5"
          :class="{ backgroundColor: confirmNumb >= 5 }"
        ></div>
        <div
          v-if="mustConfirmNumb >= 6"
          :class="{ backgroundColor: confirmNumb >= 6 }"
        ></div>
      </div>
      <div class="confirmation-status-content">
        <span
          class="status-text"
          :class="{
            statusApprovedColor: approved == true,
            statusRejectedColor: approved == false,
          }"
          >{{ confirmStatusText }}</span
        >

        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    confirmNumb: Number,
    mustConfirmNumb: Number,
    rejectNumb: Number,
    approved: Boolean,
  },
  data() {
    return {};
  },

  computed: {
    confirmStatusText() {
      if (this.approved == true) {
        return "APPROVED";
      } else if (this.approved == null) {
        return "PENDING";
      } else {
        return "REJECTED";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.multiple-confirmation-content {
  .confirmation-tabs {
    display: flex;
    div {
      width: 12 * $rem;
      height: 2px;
      background-color: #cfcfdd;
      margin-right: 4px;
    }
  }
}
.confirmation-content {
  padding-top: 15px;
  margin-left: 30px;
}
.alternativePadding {
  padding-top: 3px;
}
.confirmation-tabs {
  div.backgroundColor {
    background-color: #1de9b6;
  }
}
.status-text {
  color: #9696b9;
  opacity: 0.9;
  font-size: 14px;
  display: block;
  width: 71px;
  font-weight: 400;
}
.confirmation-status-content {
  display: flex;
  align-items: center;
  position: relative;
}

.statusApprovedColor {
  color: #18cc9f;
  opacity: 0.9;
  font-size: 14px;
}
.statusRejectedColor {
  color: #d06779;
  opacity: 0.9;
  font-size: 14px;
}
.arrow {
  border: solid #9696b9;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 2px;
}
</style>