<template>
  <div class="attendance-inout-content">
    <div class="top-header">
      <ul>
        <!-- <li class="route" v-if="isTbilisiOffice">
          <router-link
            tag="span"
            :to="{ path: '/AttendanceReportUser' }"
            active-class="active"
          >
            Attendance Report
          </router-link>
        </li> -->
        <li class="route">
          <router-link
            tag="span"
            :to="{ path: '/AttendanceUser' }"
            active-class="active"
          >
            In/Out Log
          </router-link>
        </li>
      </ul>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  created() {
    // if (!this.isTbilisiOffice) {
    //   this.$router.push({
    //     path: 'AttendanceUser',
    //   })
    // }
    this.$router.push({
      path: 'AttendanceUser',
    })
  },
  computed: {
    isTbilisiOffice() {
      return this.$store.state.isProduction
        ? this.$store.state.profile.OfficeID === 3
        : this.$store.state.profile.OfficeID === 38
    },
  },
}
</script>

<style scoped lang="scss">
.attendance-inout-content {
  display: inline;
}
.top-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
  padding-bottom: 0.625rem;
  margin-left: 2.5rem;
  ul {
    list-style: none;

    li.route {
      margin-left: 1.875rem;
      display: inline-flex;
      &:first-child {
        margin-left: 0;
      }
      span {
        font-size: 0.9375rem;
        color: $blue-primary;
        font-weight: 500;
        line-height: 1.1875rem;
        cursor: pointer;
        position: relative;

        &.active {
          &::after {
            content: '';
            width: 100%;
            height: 0.25rem;
            background: $green-primary;
            z-index: 999;
            position: absolute;
            display: block;
            bottom: -0.625rem;
            left: 0;
          }
        }
      }
    }
  }
}
</style>
