<template>
  <div
    class="user-bookmark-creation"
    :style="componentPosition"
    ref="bookmarkCreation"
    v-on:click.stop
  >
    <div class="link-content">
      <span>Link</span>
      <input type="text" v-model="newBookmark.link" class="link-input" />
    </div>
    <div class="name-content">
      <span>Name</span>
      <input type="text" v-model="newBookmark.linkName" />
    </div>
    <div class="select-content">
      <span>Folder</span>
      <slot></slot>
    </div>
    <div class="footer">
      <span @click="newFolder = true" v-if="newFolder == false"
        >+ Add new folder</span
      >
      <input
        v-if="newFolder == true"
        v-model="newBookmark.newFolderName"
        type="text"
        placeholder="Enter folder name"
      />
    </div>
    <button class="save-button" @click="$emit('createBookmark', newBookmark)">
      Save
    </button>
  </div>
</template>

<script>
export default {
  created() {
    document.addEventListener("click", this.documentClick);
  },
  data() {
    return {
      newFolder: false,
      newBookmark: {
        newFolderName: "",
        link: "",
        linkName: "",
      },
      position: Number,
    };
  },
  methods: {
    documentClick(event) {
      let targetEl = event.target;

      if (
        targetEl.classList.contains("custom-select-options") ||
        targetEl.parentNode.classList.contains("custom-select-options")
      )
        return;

      this.$emit("close");
    },
  },
  computed: {
    componentPosition() {
      return "left:" + (this.$attrs.position - 260) + "px";
    },
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style scoped lang="scss">
.user-bookmark-creation {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 280px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0.125rem 0.125rem 0.625rem 0.125rem rgba(40, 52, 147, 0.08);
  position: fixed;
  right: 5px;
  z-index: 35;
  top: 25px;
  .link-content {
    display: flex;
    align-items: center;
    span {
      width: 43px;
      color: $blue-primary;
      font-weight: 300;
      font-size: 15px;
    }
  }
  .name-content {
    display: flex;
    align-items: center;
    margin-top: 10px;
    span {
      width: 43px;
      color: $blue-primary;
      font-weight: 300;
      font-size: 15px;
    }
  }
  .select-content {
    display: flex;
    align-items: center;
    margin-top: 10px;
    span {
      width: 43px;
      color: $blue-primary;
      font-weight: 300;
      font-size: 15px;
    }
    .select-wrapper {
      margin-left: 10px;
      width: 200px;
    }
  }
  input {
    width: 200px;
    // background-color: #ededfc;
    outline: none;
    border: 0.0625rem solid #dedff4;
    height: 25px;
    margin-left: 10px;
    color: $blue-primary;
    padding-left: 5px;
    border-radius: 3px;
  }
}
.footer {
  margin-top: auto;
  border-top: 0.0625rem solid #dedff4;

  span {
    color: $blue-primary;
    font-weight: 300;
    cursor: pointer;
    padding-top: 5px;
    display: block;
  }
  margin-bottom: 10px;
  input {
    margin-left: 0;
    margin-top: 5px;
    height: 25px;
    border: 0;
    &::placeholder {
      color: $blue-primary;
      font-weight: 300;
    }
  }
}

.save-button {
  width: 60px;
  height: 30px;
  background-color: #8a91c7;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15 * $rem;
  border-radius: 3 * $rem;
  margin-left: auto;

  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }
}
</style>