<template>
  <custom-popup
    :visible="true"
    @close="$emit('close')"
    :flexibleSize="true"
    :bigSize="true"
  >
    <div class="container">
      <div class="helper">
        <h3>Department Jobs</h3>
        <form @submit.prevent="addJob">
          <!-- <custom-select
                        class="custom-select"
                        title="Job"
                        v-model="currentJobId"
                        :options="jobs"
                        :isRequired="true"
                        @clear="currentJobId = ''"
                    /> -->
          <input-search
            class="custom-search"
            title="Select job"
            :options="jobs"
            @select="(id) => (currentJobId = id)"
          />

          <button class="btn">Add</button>
        </form>
      </div>

      <grid
        identificator="DepartmentJobID"
        title="Departments"
        :gridData="departmentJobs"
        :columns="columns"
        :pagination="false"
        :canDelete="true"
        @rowDelete="deleteRow"
        :alternativeStyle="true"
      />
    </div>
  </custom-popup>
</template>

<script>
import Grid from "@/components/ReusableComponents/Grid/Grid.vue";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";
import axios from "@/Helpers/AxiosInstance.js";
import InputSearch from "@/components/ReusableComponents/InputSearch/InputSearch.vue";
export default {
  components: {
    Grid,

    CustomPopup,
    InputSearch,
  },
  props: {
    departmentID: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.getDepartmentJobs();
    this.getJobs();
  },
  data() {
    return {
      currentJobId: "",
      jobs: [],
      departmentJobs: [],
    };
  },
  methods: {
    getDepartmentJobs() {
      axios
        .get("departments/GetDepartmentJobs", {
          params: {
            DepartmentID: this.departmentID,
          },
        })
        .then((resp) => {
          if (!resp.data.Value.length) return;
          this.departmentJobs = resp.data.Value;
        });
    },
    addJob() {
      axios
        .post("departments/AssignJobDepartment", {
          JobID: this.currentJobId,
          DepartmentID: this.departmentID,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.getDepartmentJobs();
          }
        });
    },

    deleteRow(id) {
      axios
        .post("departments/UnassignJobDepartment", {
          DepartmentJobId: id,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.getDepartmentJobs();
          }
        });
    },
    getJobs() {
      axios.get("jobs/GetJobs").then((resp) => {
        this.jobs = resp.data.Value.Jobs.map((el) => ({
          title: el.Name,
          value: el.ID,
        }));
      });
    },
  },
  computed: {
    columns() {
      return [
        {
          title: "Department",
          key: "DepartmentName",
        },
        {
          title: "Job",
          key: "JobName",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: flex;
  margin-bottom: 15 * $rem;

  .input-search,
  .select-wrapper,
  label {
    margin-right: 15 * $rem;
  }
}

.btn {
  background-color: rgba($blue-primary, 0.5);
  color: #ffffff;
  height: 38px;
  width: 100px;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
}
.helper {
  display: flex;
  justify-content: space-between;
  padding-left: 40 * $rem;
  padding-right: 40 * $rem;
  h3 {
    font-size: 1.25rem;
    color: #283593;
    font-weight: 400;
  }
}

::v-deep tbody td {
  max-width: 15rem !important;

  span {
    max-width: initial !important;
  }
}
</style>