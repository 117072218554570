<template>
<div class="background">
    <div class="choose-schedule-type" :style="{'background-image': `url(${require('@/assets/images/schedulesImage.png')})`}">
        <div class="fixed" @click="$router.push('FixedSchedules')">Fixed</div>
        <div class="flexible" @click="$router.push('FlexibleSchedule')">Flexible</div>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
    .background {
        overflow: hidden;
    }
    .choose-schedule-type {
        height: 100%;
        background-repeat: no-repeat;
        position: relative;
        top: -60px;

        .fixed, .flexible {
            font-family: 'Anton', sans-serif;
            font-size: 93 * $rem;
            color: rgba($blue-primary, 0.8);
            width: 450 * $rem;
            height: 260 * $rem;
            border-radius: 10 * $rem;
            background-color: #fefeff;
            box-shadow: $box-shadow2;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-top: 100 * $rem;
            cursor: pointer;

            &:hover {
                border: $rem solid $green-primary;
            }
        }

        .flexible {
            margin-top: 65 * $rem;
        }
    }
    .background {
        padding: 1.25rem 4.0625rem;
    }
</style>