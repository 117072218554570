<template>
    <div class="template-wrapper" @click="$emit('close')" v-if="visible">
        <div class="filter-popup" @click.stop="" v-if="!filterStepPassed">
            <div class="filter-date">
              <date-picker 
                :title="'Year/Month'"
                :alternativeIcon="true"
                :addCustom="'month'"
                v-model="pickedDate"
              />
            </div>
            <div class="skip" @click="skipStep">
                <span>Skip</span>
            </div>
        </div>
        <div class="popup-wrapper" @click.stop="" v-if="filterStepPassed && !chartVisible">
            <div class="main-title">
                <h1>Performance Report</h1>
                <div class="left">
                    <div class="chart-btn" @click="generateChart()">
                        <img src="@/assets/grades/chart.svg" alt="">
                    </div>
                    <div class="back" @click="clearFilter">
                        <img src="@/assets/grades/back.svg" alt="">
                        <span>Back</span>
                    </div>
                </div>

            </div>
            <div class="grid-wrapper">
                <ul class="col-titles">
                    <li v-for="(col,ind) in columns" :key="ind" :class="col">
                        <span>
                            {{col}}
                        </span>
                    </li>
                </ul>
                <ul class="sub-titles">
                    <li></li>
                    <li></li>
                    <li>
                        <span>
                           Planned 
                        </span>
                        <span>
                            Reached
                        </span>
                    </li>
                </ul>
                <ul class="persons">
                    <li class="single-person" v-for="(person,ind) in reports" :key="ind">    
                        <div class="title">
                            <span>
                                {{
                                    `${person.FirstName} ${person.LastName}`
                                }}
                            </span>
                        </div>
                        <div class="data-wrapper">
                            <div class="person-data" v-for="(rec,ind) in person.Attendances" :key="ind">
                                <div class="col-value">
                                    <span>
                                        {{
                                            `${rec.Month ? rec.Month : '-'} ${rec.MonthPart ? rec.MonthPart.split('-')[0] : '-'}`
                                        }}
                                    </span>
                                </div>
                                <div class="col-value">
                                    <span>
                                        {{
                                            rec.PlannedTime
                                        }}
                                    </span>
                                </div>
                                <div class="col-value with-percentage-total">
                                    <div>
                                        <span :class="[{'overtime': parseInt(rec.totalReachedPercentage) >= 100},{'unreached': parseInt(rec.totalReachedPercentage) < 100 }]">
                                            {{rec.AttendanceTime}}
                                        </span>
                                    </div>
                                    <div>
                                        <span :class="[{'overtime': parseInt(rec.totalReachedPercentage) >= 100},{'unreached': parseInt(rec.totalReachedPercentage) < 100 }]">
                                            {{rec.totalReachedPercentage ? `${rec.totalReachedPercentage}%` : 0}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="chart-wrapper" v-if="chartVisible" @click.stop="">
            <div class="back" @click="chartVisible = false">
                <img src="@/assets/grades/back.svg" alt="">
                <span>Back</span>
            </div>
            <div class="chart">      
                <line-chart
                    :width="700"
                    :chartData="chartData"
                    :options="chartOptions"
                />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance.js";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue";
import LineChart from "@/components/ReusableComponents/Charts/LineChart.js";

export default {
    components: {
        DatePicker,
        LineChart
    },
    props:{
        visible: {
            type: Boolean,
            default: false,
        },
        singlePersonMode: {
            type: Boolean,
            default: false,
        },
        personID: Number,
    },
    created(){
        this.getReports(new Date().getFullYear(),new Date().getMonth())
    },

    data(){
        return{
            columns: [
                "Name Surname",
                "Month",
                "Attendance",
            ],
            reports:[],
            filterStepPassed: false,
            pickedDate: null,
            Months:['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
            chartVisible: false,
            triggerChart: false,
            chartOptions: {},
            chartData: {},
        }
    },
    watch:{
        pickedDate(val){
            let year = new Date(val).getFullYear()
            let monthInd = new Date(val).getMonth()
            let monthString = this.Months[new Date(val).getMonth()] 
            this.getReports(year,monthInd)
        }
    },
    methods:{
        getReports(year,month){
            if(this.singlePersonMode) this.filterStepPassed = true
            axios.post('grading/GetPerformanceReportForGrading',{
                year: year,
                month: month,
                personID: this.singlePersonMode && this.personID ? this.personID : null
            }).then(resp => {
                if(this.pickedDate) this.filterStepPassed = true
                this.reports = resp.data.Value.PersonWithAttendances
                this.reports.forEach(el => {
                    el.Attendances.forEach(a => {
                        this.$set(a,'Month',this.substractMonthByIndex(a.MonthPart))
                        this.$set(a,'totalReachedPercentage',this.percentage(a.AttendanceTime,a.PlannedTime))
                    })
                })
                let chartColors = []
                let datasets = resp.data.Value.Chart.dataSets
                let labels = resp.data.Value.Chart.labels

                datasets.forEach((el,ind) => {
                let color = this.getRandomColor()
                if(chartColors.some(c => c === color)){
                    color = this.getRandomColor()
                }else{
                    chartColors.push(color)
                }
                el['backgroundColor'] = chartColors[ind]
                el['borderColor'] = chartColors[ind]
                el['pointBackgroundColor'] = chartColors[ind]
                el['pointBorderColor'] = chartColors[ind]
                el['pointHoverBackgroundColor'] = chartColors[ind]
                el['pointHoverBorderColor'] = chartColors[ind]
            })

                this.$set(this.chartData,'labels',labels)
                this.$set(this.chartData,'datasets',datasets)
            })
        },
        getRandomColor() {
            var letters = '0123456789ABCDEF'.split('');
            var color = '#';
            for (var i = 0; i < 6; i++ ) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`;
            return setOpacity(color,.3);
        },
        substractMonthByIndex(StringDate){
            const MONTHS = this.Months
            let monthInd = StringDate.split('-')[1] ? StringDate.split('-')[1] : 0
            return MONTHS[Number(monthInd - 1)]
        },
        percentage(partialValue, totalValue) {
            let total = (100 * partialValue) / totalValue
            if(isNaN(total)) return 0
            if(!isFinite(total)) return 0
            return total.toFixed(0)
        },
        skipStep(){
            this.getReports(new Date().getFullYear(),new Date().getMonth())
            this.filterStepPassed = true
            
        },
        clearFilter(){
            this.pickedDate = null
            this.filterStepPassed = false
        },
        generateChart(){
            this.chartVisible = true

        }
    
    }
}
</script>

<style lang="scss" scoped>
.template-wrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    @include centerElement;
    background: rgba(#707070,.2);
    z-index: 99;

    .filter-popup{
        width: 18.75rem;
        height: 12rem;
        background: #fff;
        animation: slideUp .3s forwards steps(50);
        padding: 1.125rem 1.875rem;
        margin-left: 5rem;
        border-radius: 0.3125rem;
        @include centerElement;

        .skip{
            cursor: pointer;
            padding: 7px 25px;
            background: #7D8FB3;
            border-radius: 0.375rem;
            span{
                color: #fff;
            }
        }
    }
    .popup-wrapper{
        width: 53.75rem;
        background: #fff;
        box-shadow: 0 0 1.25rem rgba(#707070,.1);
        border-radius: 0.3125rem;
        margin-left: 5rem;
        position: relative;
        animation: slideUp .3s forwards steps(50);
        padding: 1.625rem 1.875rem;
        overflow: hidden;
        padding-bottom: 4rem;
        @keyframes slideUp {
            from{
                transform: translateY(5.625rem);
                opacity: .2;
            }
            to{
                transform: translateY(0);
                opacity: 1;
            }
        }

        .left{
            @include flex(flex-start,center,row);
            .chart-btn{
                cursor: pointer;
                img{
                    width: 1.375rem;
                }    
                margin-right: 1.5625rem;
            }

            .back{
                cursor: pointer;
                @include flex(flex-start,center,row);
                img{
                    width: 1.0625rem;
                }
                span{
                    color: #7D8FB3;
                    margin-left: 8px;
                    font-size: 0.875rem;
                }
            }
        }


        .overtime{
            color: #5BD09E !important;
        }
        .unreached{
            color: #FF3356 !important;
        }

        .main-title{
            @include flex(space-between,center,row);
            h1{
                font-size: 1.3125rem;
                color: #4D5E80;
                font-weight: 400;
            }
        }

        .grid-wrapper{
            width: 100%;
            height: calc(100% - 1rem);
            @include flex(left,left,column);
            margin-top: 1.5625rem;


            ul{
                width: 100%;
                @include flex(flex-start,center,row);
                list-style: none;
                &.col-titles,&.sub-titles{
                    span{
                        color: #97A1C2;
                        font-size: 0.875rem;
                    }
                    li{
                        width: 19.7rem;

                        &.Attendance{
                            width: 24rem;
                            margin-left: 4.0625rem;
                        }
                        &.Month{
                            width: 9.2rem;
                        }
                    }
                }
                &.sub-titles{
                    padding: 0.575rem 0;
                    background: rgba(#EBEBF7,.6);
                    border-radius: 0.3125rem;
                    margin-top: 0.9rem;
                }

                &.persons{
                    @include flex(left,flex-start,column);
                    max-height: 35rem;
                    min-height: 5rem;
                    overflow: auto;
                }

                &.sub-titles{
                    li{
                        width: 13rem;
                    }
                    span{
                        &:nth-child(2){
                            margin-left: 5.3125rem;
                        }
                    }
                }
                li{
                    @include flex(flex-start,flex-start,row);
                    .title{
                        width: 17rem;
                        span{
                            font-size: 1rem;
                            color: #6B7A99;
                            font-weight: 500;
                        }
                    }
                    .data-wrapper{
                        .col-value{
                            width: 9rem;
                            font-size: 0.875rem;
                            color: #7D8FB3;
                            font-weight: 450;

                            &.with-percentage-total{
                                display: grid;
                                grid-template-columns: 5rem 5rem;
                                span{
                                    &:first-child{
                                        margin-left: -1.5625rem;
                                    }
                                    &:nth-child(2){
                                        margin-left: 1.5625rem;
                                    }
                                }
                            }
                        }
                        @include flex(flex-start,center,column);
                        .person-data{
                            @include flex(flex-start,center,row);
                            margin-top: 0.9375rem;
                            &:first-child{
                                margin-top: 0;
                            }
                        }
                    }

                    &.single-person{
                        margin-top: 1.5625rem;
                        padding-bottom: 0.9375rem;
                        // border-bottom: 0.0625rem solid rgba(#EBEBF7,.5);
                    }
                }
            }
        }
    }

    .chart-wrapper{
        width: 53.75rem;
        height: 34.375rem;
        background: #fff;
        box-shadow: 0 0 1.25rem rgba(#707070,.1);
        border-radius: 0.3125rem;
        margin-left: 5rem;
        position: relative;
        animation: slideUp .3s forwards steps(50);
        padding: 1.625rem 1.875rem;
        position: relative;
        .back{
            position: absolute;
            top: 1rem;
            right: 1rem;
            cursor: pointer;
            @include flex(flex-end,center,row);
            img{
                width: 1.0625rem;
            }
            span{
                color: #7D8FB3;
                margin-left: 8px;
                font-size: 0.875rem;
            }
        }
    }
}
</style>