<template>
    <div class="comment-content">
        <div class="input-content">
            <input
                type="text"
                @focusin="doFocus"
                v-on="listeners"
                @focusout="doBlur"
                placeholder="Comment ..."
                v-model="comment"
            />
        </div>

        <div class="actions-content">
            <span
                @click="comment = ''"
                ref="cancelIcon"
                v-if="focused"
                class="material-icons cancel"
                >close</span
            >
        </div>
    </div>
</template>

<script>
export default {
    props: {
        triggerChangeOnBlur: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            focused: false,
            comment: '',
        }
    },

    computed: {
        listeners() {
            if (this.triggerChangeOnBlur) {
                return { change: this.inputHandler }
            } else {
                return { input: this.inputHandler }
            }
        },
    },
    methods: {
        inputHandler() {
            this.$emit('input', this.comment)
        },
        doFocus() {
            this.focused = true

            this.$emit('focus')
        },
        doBlur() {
            setTimeout(() => (this.focused = false), 150)
        },
    },
}
</script>

<style lang="scss" scoped>
.comment-content {
    margin-left: 22px;
    display: flex;
    max-width: 185 * $rem;
    padding-top: 5px;
    input {
        width: 185 * $rem;
        border: 0;
        outline: none;
        // border-bottom: 0.0625rem solid #dcdde6;
        font-weight: 300;
        background-color: transparent;
        color: $blue-primary;
        opacity: 0.8;
        &::placeholder {
            opacity: 0.5;
            color: $blue-primary;
        }
    }
    .actions-content {
        margin-left: 10px;
        .material-icons {
            color: $blue-primary;
            opacity: 0.7;
            font-size: 22px;
            cursor: pointer;
            margin-right: 5px;
        }
    }
}
.display-none {
    display: none;
}
.display {
    display: block;
}
</style>