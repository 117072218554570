<template>
    <div class="background">
        <performance-report 
            @close="reportVisible = false"
            :visible="reportVisible"
        />
        <custom-snackbar
            :text="snackbar.text"
            :purpose="snackbar.purpose"
            v-if="snackbar.visible"
            @close="snackbar.visible = false"
        />
        <div ref="additionalInfo" class="info-tooltip" :style="colOjbValue.style" v-if="colOjbValue.description && colOjbValue.description !== null">
            <h2 v-if="colOjbValue.fullname && colOjbValue.fullname !== ''">
                {{colOjbValue.fullname ? colOjbValue.fullname : ''}}
            </h2>
            <p>
                {{colOjbValue.description ? colOjbValue.description : ''}}
            </p>
        </div>
        <div class="content-wrapper">
            <custom-loader v-if="loading" />
            <div class="content-header">
                <div class="left-side">
                    <div class="back-btn" @click="$router.push('/Grades/TeamRatingOverall')">
                        <img src="@/assets/grades/back.svg" alt="">
                        <span>Back</span>
                    </div>
                </div>
                <div class="filters" v-if="!limitedGradingView">
                    <ul>
                        <li v-for="(f,ind) in filters" :key="ind">
                            <input-search 
                                :title="f.title"
                                :options="f.options"
                                @select="(val) => (f.selected = val, f.typeName === 'person' || f.typeName === 'grade'  ? null : getComparePersons(true,false))"
                            />
                        </li>
                    </ul>
                </div>
                <div class="right-side">
                    <div class="performance-report" @click="reportVisible = true">
                        <span>Performance Report</span>
                    </div>
                    <div class="compare-with">
                        <multi-select
                            :title="'Compare With'"
                            :options="dropdownPersonsList"
                            :visible="multiSelectVisible"
                            @open="multiSelectVisible = true"
                            @close="(multiSelectVisible = false,checkPersonGradings())"
                            @select="(state,id) => selectedPersonsforQuery(state,id)"
                        />
                    </div>
                </div>
            </div>

            <div class="compare-content">
                <div class="compare-cards" v-if="limitedGradingView">
                    <div class="card" v-for="(obj,index) in compareData" :key="index">
                        <div class="person-details">
                            <div class="top-side">
                                <div class="left-side">
                                    <div class="user-avatar" :style="`background-image:url('${getPersonAvatar(obj.GradingHistory.PersonID)}')`" > </div>
                                    <div class="details">
                                        <span class="person_name">
                                            {{
                                                obj.GradingHistory.FirstName + ' ' + obj.GradingHistory.LastName
                                            }}
                                        </span>
                                        <span class="job_name">
                                            {{ obj.GradingHistory.JobName ? obj.GradingHistory.JobName : '-' }}
                                        </span>
                                        <div class="badge">
                                            <span>
                                                {{obj.GradingHistory.CurrentBadgeName}}
                                            </span>
                                            <img :src="getBadge(obj.GradingHistory.CurrentGradeID)" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="right-side">
                                    <div class="score-state">
                                        <div class="score-compared">
                                            <div class="comparedResult">
                                                <div>
                                                    <span :style="comparedScoreColor(obj.GradingHistory.AvgScore,obj.GradingHistory.BeforeGradeAvgScore)">
                                                        {{compareAvgScore(obj.GradingHistory.AvgScore,obj.GradingHistory.BeforeGradeAvgScore).result.toFixed(1) }}
                                                    </span>
                                                    <img :src="require(compareAvgScore(obj.GradingHistory.AvgScore,obj.GradingHistory.BeforeGradeAvgScore).compareStatus === 'increased' ? '@/assets/grades/ratingOverall/increase.svg' : compareAvgScore(obj.GradingHistory.AvgScore,obj.GradingHistory.BeforeGradeAvgScore).compareStatus === 'decreased' ? '@/assets/grades/ratingOverall/decrease.svg' : '@/assets/grades/ratingOverall/line.svg')" alt="">
                                                </div>
                                            </div>
                                            <span>Rated {{
                                                    calculateTimeDiff(new Date(obj.GradingHistory.GradingDate),new Date())
                                                }} ago</span>
                                        </div>
                                        <div :class="['current-score']" :style="personCurrentScoreColor(recalculateAveragePersonScore(obj.GradingHistory.AvgScore),obj.GradingHistory.Status ? false : true)">
                                            <span :style="personCurrentScoreColor(recalculateAveragePersonScore(obj.GradingHistory.AvgScore),obj.GradingHistory.Status ? false : true,obj.GradingHistory.Status ? false : true)">
                                                {{ recalculateAveragePersonScore(obj.GradingHistory.AvgScore)}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom-side">
                                <div class="score-line">
                                    <div :class="['single-line',{'blank':  obj.GradingHistory.AvgScore < score.score}]" :style="`background:${score.color}`" v-for="(score,ind) in personScoreRanges()" :key="ind"> </div>
                                </div>
                                <div class="additional-details">
                                    <ul>
                                        <li>
                                            <div class="title">
                                                Grade :
                                            </div>
                                            <div class="value">
                                                {{obj.GradingHistory.CurrentGradeDescription ? obj.GradingHistory.CurrentGradeDescription : '-'}}
                                            </div>
                                        </li>
                                        <li>
                                            <div class="title">
                                                Salary :
                                            </div>
                                            <div class="value">
                                                {{obj.GradingHistory.CurrentSalary}}
                                            </div>
                                        </li>
                                        <li>
                                            <div class="title">
                                                SR:
                                            </div>
                                            <div class="value">
                                                {{ 
                                                    `${salaryRanges(obj.GradingHistory.PersonGradingHistoryID) ? salaryRanges(obj.GradingHistory.PersonGradingHistoryID).MinSalary : ''} - ${salaryRanges(obj.GradingHistory.PersonGradingHistoryID) ? salaryRanges(obj.GradingHistory.PersonGradingHistoryID).MaxSalary : ''}`
                                                }}
                                                <span class="selected-curr curr-obj"  @click="collapseCurrencyOptions(obj.GradingHistory.PersonGradingHistoryID)">
                                                    {{`${salaryRanges(obj.GradingHistory.PersonGradingHistoryID) ? salaryRanges(obj.GradingHistory.PersonGradingHistoryID).Code : ''}`}}
                                                </span>
                                                <div  class="currency-options curr-obj" v-if="visibleCurrencyOptionsIn === obj.GradingHistory.PersonGradingHistoryID && salaryRanges(obj.GradingHistory.PersonGradingHistoryID,true).length > 0">
                                                    <span class="curr-obj" v-for="(curr,ind) in salaryRanges(obj.GradingHistory.PersonGradingHistoryID,true)" :key="ind" @click="selectCurrency(curr.value,obj.GradingHistory.PersonGradingHistoryID)">
                                                        {{
                                                            curr.title
                                                        }}
                                                    </span>
                                                </div>                                                
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="person-criterias">
                            <ul>
                                <li v-for="(criteria,ind) in obj.CriteriaDetails" :key="ind">
                                    <div class="main-content">
                                        <div class="criteria-title">
                                            <span>
                                                {{
                                                    criteria.CriteriaDetail.CriteriaDescription
                                                }}
                                            </span>
                                        </div>
                                        <div class="right-side">
                                            <div class="criteria-rated-score">
                                                <span :style="setComparedObjStatuScoreState(criteria.CriteriaDetail.CriteriaID,obj.GradingHistory.PersonGradingHistoryID,index,true,false)">
                                                    {{
                                                        criteria.CriteriaDetail.CriteriaScore
                                                    }}
                                                </span>
                                                <img v-if="setComparedObjStatuScoreState(criteria.CriteriaDetail.CriteriaID,obj.GradingHistory.PersonGradingHistoryID,index,false,true) === 'high'" src="@/assets/grades/ratingOverall/increase.svg" alt="">
                                                <img v-if="setComparedObjStatuScoreState(criteria.CriteriaDetail.CriteriaID,obj.GradingHistory.PersonGradingHistoryID,index,false,true) === 'low'" src="@/assets/grades/ratingOverall/decrease.svg" alt="">
                                            </div>
                                            <div :class="['dropdown-arrow',{'collapsed':criteria.CriteriaDetail.collapsed}]" @click="collapseCriteria(criteria.CriteriaDetail.PersonGradingHistoryID,criteria.CriteriaDetail.CriteriaID)">
                                                <img src="@/assets/grades/arrowCircle.svg" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="criteria-details" v-if="criteria.CriteriaDetail.collapsed">
                                        <ul>
                                            <li class="single-description" v-for="(desc,ind) in criteria.CriteriaDetail.descriptions" :key="ind"> 
                                                <div class="description-content">
                                                    <div class="top">
                                                        <div class="score-range">
                                                            <span>
                                                                {{
                                                                    `${desc.CriteriaDescriptionMinScore} - ${desc.CriteriaDescriptionMaxScore}`
                                                                }}
                                                                <img v-if="!visibilityBycurrentRatingRange(desc.CriteriaDescriptionMinScore,desc.CriteriaDescriptionMaxScore,criteria.CriteriaDetail.totalRateValue)" @click="showRangeSlider(criteria.CriteriaDetail.PersonGradingHistoryID,desc.CriteriaDescriptionID)" src="@/assets/grades/ratingOverall/star.svg" alt="">
                                                                <img v-else src="@/assets/grades/ratingOverall/starfill.svg" alt="" @click="showRangeSlider(criteria.CriteriaDetail.PersonGradingHistoryID,desc.CriteriaDescriptionID)">
                                                            </span>
                                                        </div>
                                                        <div class="score-slidebar" v-if="rangeVisibility(desc.CriteriaDescriptionID,criteria.CriteriaDetail.PersonGradingHistoryID)">
                                                            <slide-bar
                                                                v-model="criteria.CriteriaDetail.totalRateValue"
                                                                :min="desc.CriteriaDescriptionMinScore"
                                                                :max="desc.CriteriaDescriptionMaxScore"
                                                                :data="generateRateBarData(desc.CriteriaDescriptionMinScore,desc.CriteriaDescriptionMaxScore)"
                                                                :range="generateRateBarRanges(desc.CriteriaDescriptionMinScore,desc.CriteriaDescriptionMaxScore)"
                                                                :lineHeight="3"
                                                                :showTooltip="true"
                                                                :isDisabled="false"
                                                            >
                                                            </slide-bar>
                                                            <div class="close-range-bar" @click="(rangeToolbarVisible.id = null,rangeToolbarVisible.parent = null)">
                                                                <img src="@/assets/grades/arrowCircle.svg" alt="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="bottom">
                                                        <p>
                                                            {{
                                                                desc.CriteriaDescriptionDescription
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="actions">
                            <div class="save" @click="updatePersonGradingHistory(false,obj.GradingHistory.PersonGradingHistoryID)">
                                <span>Save</span>
                            </div>
                            <div class="send" @click="updatePersonGradingHistory(true,obj.GradingHistory.PersonGradingHistoryID)">
                                <span>Send</span>
                            </div>
                        </div>
                    </div>
                    <div class="chart-placeholder">
                        <div class="chart-loader" v-if="chartLoading">
                            <img src="@/assets/gifs/pallete.svg" alt="">
                        </div>
                        <div class="title">
                            <h1>
                                Engagement by Criterias
                            </h1>
                        </div>
                        <div class="chart-wrapper">
                            <radar-chart
                                v-if="chartData.datasets.length > 0"
                                :chartData="chartData"
                                :options="chartOptions"
                                :triggerChartUpdate="triggerChartRender"
                            />
                        </div>
                    </div>
                </div>
                <div class="compare-grid" @scroll="scrolled()" v-else>
                    <div class="grid-wrapper">
                        <div class="left-side-titles">
                            <ul>
                                <li class="titles-header common-header">
                                    <span>
                                        Criteria
                                    </span>
                                </li>
                                <li @mouseenter="hoveredColumn = t.id" @mouseleave="hoveredColumn = null" :class="['single-title common-col',{'hoveredCol': t.hovered}]" v-for="(t,ind) in gridColumns" :key="ind">
                                    <span>
                                        {{t.title}}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div class="right-side-content">
                            <ul v-dragscroll>
                                <li>
                                    <div class="single-person" v-show="filterPerson(person.GradingHistory.PersonID,person.GradingHistory.CurrentGradeID)" v-for="(person,ind) in compareData" :key="ind">
                                        <div class="user-info common-header">
                                            <div class="avatar" :style="`background-image:url(${getPersonAvatar(person.GradingHistory.PersonID)})`" > 
                                                <div :style="`background-image:url(${getBadge(person.GradingHistory.CurrentGradeID)})`" class="badge">
                                                    
                                                </div>
                                            </div>
                                            <div class="person-name">
                                                <span> 
                                                    {{
                                                        person.GradingHistory.FirstName
                                                    }}
                                                    <br>
                                                    {{
                                                        person.GradingHistory.LastName
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="scores" v-for="(criteria,index) in gridColumns" :key="index" @mouseenter="hoveredColumn = criteria.id" @mouseleave="hoveredColumn = null">
                                            <div :class="['single-score common-col',{'hoveredCol': criteria.hovered}]">
                                                <span :title="person.GradingHistory.FirstName + ' ' + person.GradingHistory.LastName" @click="showAdditionalInfo($event,person.GradingHistory.PersonID,person.GradingHistory.PersonGradingHistoryID,criteria.id)"  :style="calcScoreStateColor(personCriteriaByColumn(person.GradingHistory.PersonGradingHistoryID,criteria.id))">
                                                    {{
                                                        personCriteriaByColumn(person.GradingHistory.PersonGradingHistoryID,criteria.id) ? personCriteriaByColumn(person.GradingHistory.PersonGradingHistoryID,criteria.id) : '-'
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance.js";
import Score from '@/components/Grades/classes/GradingScore.js'
import SlideBar from '@/components/Grades/GradeComponents/SlideBar/SlideBar.vue'
import {getDateString} from '@/Helpers/HelperFunctions.js'
import RadarChart from "@/components/ReusableComponents/Charts/RadarChart.js";
import CustomLoader from '@/components/ReusableComponents/Loader/CustomLoader.vue'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import { dragscroll } from "vue-dragscroll";
import InputSearch from '@/components/ReusableComponents/InputSearch/InputSearch.vue'
import MultiSelect from '@/components/Grades/GradeComponents/MultiSelect/MultiSelect.vue'
import PerformanceReport from '@/components/Grades/GradeComponents/PerformanceReport/PerformanceReport.vue'


export default {
    created(){
        this.getFilterTags()
        this.getCompareObjFromQuery()
        document.addEventListener('click',this.clickHandler)

    },
    beforeDestroy(){
        document.removeEventListener('click',this.clickHandler)
    },
    components:{
        SlideBar,
        RadarChart,
        CustomLoader,
        CustomSnackbar,
        InputSearch,
        MultiSelect,
        PerformanceReport,
    },
    directives: {
        dragscroll,
    },
    data(){
        return{
            snackbar:{
                text:'',
                purpose: '',
                visible: false,
            },
            limitedGradingView: false,
            loading: false,
            chartLoading: false,
            compareQuery: [],
            compareData: [],
            rangeToolbarVisible: {
                id: null,
                parent: null
            },
            visibleCurrencyOptionsIn: null,
            compareScores: [],
            chartOptions: {
                title: {
                    display: true,
                    text: 'Person Rating Compare'
                },
                scale: {
                    ticks: {
                        beginAtZero: true,
                        showLabelBackdrop: false
                    },
                    pointLabels: {
                        callback: (label) => {
                            return label.length > 10 ? label.substr(0, 10) + '...' : label;
                        },
                    },
                },
                pointLabels:{
                    callbacks: {
                        label: function(label) {
                            //
                        },
                    }
                },
                legend: {
                    labels: {
                        usePointStyle: true,
                        boxWidth: 6,
                    },
                },        
            },
            triggerChartRender: false,
            chartData:{
                datasets:[],
                labels: [],
            },
            gridColumns:[],
            hoveredColumn: null,
            personsGradeIDs: [],
            filters:[
                {
                    title: 'Select Person',
                    selected: null,
                    options: [],
                    typeName: 'person'
                },
                {
                    title: 'Select Grade',
                    selected: null,
                    options: [],
                    typeName: 'grade'
                },
                {
                    title: 'Select Criteria',
                    selected: null,
                    options: [],
                    typeName: 'criteria'
                }
            ],
            multiSelectVisible: false,
            dropdownPersonsList: [],
            colOjbValue: {
                personID: null,
                fullname: null,
                historyID: null,
                criteriaID: null,
                description: '',
                style: {},
            },
            reportVisible: false,
        }
    },
    watch:{
        hoveredColumn(val){
            this.gridColumns.forEach(el => {
                if(el.id === val){
                    el.hovered = true
                }else{
                    el.hovered = false
                }
            })
        }
    },
    methods:{
        scrolled(){
            this.colOjbValue.description = ''
        },
        clickHandler($event){
            if($event.target.matches('.curr-obj') || $event.path.includes(this.$refs.additionalInfo) || $event.target.matches('.single-score > span')) return
            else{
                this.visibleCurrencyOptionsIn = null
                this.colOjbValue.description = ''
            }
        },
        getCompareObjFromQuery(){
            const query = this.$route.query.gradingHistoryIDs
            this.compareQuery = query
            this.getComparePersons(false,true)
        },
        getComparePersons(filterMode,firstCall){
            let objData = this.compareQuery.map(el => ({
                id: parseInt(Math.abs(el)),
            }))

            let filterCriteria = this.filters.find(obj => obj.typeName === 'criteria').selected
            // let filterGrade = this.filters.find(obj => obj.typeName === 'grade').selected

            this.loading = true
            axios.post('grading/GetPersonsGradingDetailsForCompare',{
                personGradingHistories: objData ? objData : [],
                personID: null,
                gradeID: null,
                criteriaID: filterMode && filterCriteria ? filterCriteria : null,
            }).then(resp => {
                let data = resp.data.Value.Gradings
                let criterias = resp.data.Value.Criterias
                data.forEach(el => {
                    this.$set(el,'selectedCurrency','GEL')
                    el.CriteriaDetails.forEach(obj => {
                        this.$set(obj.CriteriaDetail,'collapsed',false)
                        this.$set(obj.CriteriaDetail,'totalRateValue',obj.CriteriaDetail.CriteriaScore)
                    })
                    // el.CriteriaDetails.sort((a,b) => b.CriteriaDetail.CriteriaID - a.CriteriaDetail.CriteriaID)
                })
                this.gridColumns = criterias.map(el => ({
                    title: el.CriteriaDescription,
                    id: el.CriteriaID,
                    hovered: false,
                }))

                if(firstCall){
                    this.filters.find(el => el.typeName === 'criteria').options = criterias.map(crit => ({
                        title: crit.CriteriaDescription,
                        value: crit.CriteriaID,
                    }))

                    let personMappedData = data.map(el => ({
                        title: el.GradingHistory.FirstName + ' ' + el.GradingHistory.LastName,
                        value: el.GradingHistory.PersonID
                    })) 

                    this.filters.find(el => el.typeName === 'person').options = [...new Map(personMappedData.map(e=> [e['value'], e])).values()]

                    let gradeIds = data.map(el => el.GradingHistory.CurrentGradeID)
                    this.personsGradeIDs = [...new Set(gradeIds)]

                    this.filters.find(el => el.typeName === 'grade').options = this.filters.find(el => el.typeName === 'grade').options.filter(el => this.personsGradeIDs.includes(el.value))
                }

                this.compareData = data
                if(data.length <= 2 && data.length > 0){
                    this.limitedGradingView = true
                }else{
                    this.limitedGradingView = false
                }
                this.getComparedPersonsChartData()
                this.loading = false
                this.compareCriteriaScoresForPersons()
            }).catch(err => {
                this.loading = false
            })
        },
        getFilterTags(){
            axios.get('grading/GetGradingHistoryFilterLists').then(resp =>{
                let grades = resp.data.Value.Grades
                let persons = resp.data.Value.Persons

                this.filters.find(el => el.typeName === 'grade').options = grades.map(el => ({
                    title: el.GradeDescription,
                    value: el.GradeID,
                }))

                let personList = persons.map(el => ({
                    title: `${el.FirstName} ${el.LastName}`,
                    id: el.PersonID,
                    selected: false,
                }))

                this.dropdownPersonsList = [...new Map(personList.map(e=> [e['id'], e])).values()]
            })
        },
        getComparedPersonsChartData(){
            let gradingHistoriesIDs = this.$route.query.gradingHistoryIDs.map(id => ({
                id: Number(id)
            }))
            this.chartLoading = true
            axios.post('grading/GetGradingCompareDataForChart',{
                gradingHistories : gradingHistoriesIDs.length > 0 ? gradingHistoriesIDs : []
            }).then(resp => {
                let data = resp.data.Value
                this.generateChartData(data.Chart)
                this.chartLoading = false
            }).catch(err => {
                this.chartLoading = false
            })
        },
        generateChartData(array){
            let chartColors = []
            array.dataSets.forEach((el,ind) => {
                let color = this.getRandomColor()
                if(chartColors.some(c => c === color)){
                    color = this.getRandomColor()
                }else{
                    chartColors.push(color)
                }
                el['backgroundColor'] = chartColors[ind]
                el['borderColor'] = chartColors[ind]
                el['pointBackgroundColor'] = chartColors[ind]
                el['pointBorderColor'] = chartColors[ind]
                el['pointHoverBackgroundColor'] = chartColors[ind]
                el['pointHoverBorderColor'] = chartColors[ind]
            })
            this.chartData.datasets = array.dataSets
            this.chartDataPlaceholder = array.dataSets
            this.chartData.labels = array.labels
        },
        getRandomColor() {
            var letters = '0123456789ABCDEF'.split('');
            var color = '#';
            for (var i = 0; i < 6; i++ ) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`;
            return setOpacity(color,.3);
        },
        getPersonAvatar(personID){
            let isProd = location.href.indexOf('.upgaming.com')>0;
            let reqSrc
            if(isProd){
                reqSrc = 'https://intranet.upgaming.com/api/Person/GetPersonAvatar2?ID='
            }else{
                reqSrc = 'https://intranet.upgaming.dev/api/Person/GetPersonAvatar2?ID='
            }
            return reqSrc + personID
        },
        getBadge(id){
            let isProd = location.href.indexOf('.upgaming.com')>0;
            let reqSrc
            if(isProd){
                reqSrc = 'https://intranet.upgaming.com/api/grading/GetGradeBadgeNameIcon?GradeID='
            }else{
                reqSrc = 'https://intranet.upgaming.dev/api/grading/GetGradeBadgeNameIcon?GradeID='
            }

            return reqSrc + id
        },
        filterPerson(personid,gradeID){
            let visible = true
            if(
                (this.filters.find(el => el.typeName === 'person').selected &&
                this.filters.find(el => el.typeName === 'person').selected !== null)
                ||
                (this.filters.find(el => el.typeName === 'grade').selected &&
                this.filters.find(el => el.typeName === 'grade').selected !== null)
            ){
                if(personid === this.filters.find(el => el.typeName === 'person').selected || gradeID === this.filters.find(el => el.typeName === 'grade').selected){
                    visible = true
                }
                else visible = false
            }

            return visible
        },
        personScoreRanges(){
            let personScoresArr = []
            for (let i = 1; i <= 10; i++){
                personScoresArr.push(new Score(i).scoreObj(true))
            }
            return personScoresArr
        },
        comparedScoreColor(avg,beforeAvg){
            let style = {}
            if(this.compareAvgScore(avg,beforeAvg).compareStatus === 'increased'){
                style = {color: '#29CC39'}
            }
            if(this.compareAvgScore(avg,beforeAvg).compareStatus === 'decreased'){
                style = {color: '#FF3356'}
            }
            if(this.compareAvgScore(avg,beforeAvg).compareStatus !== 'decreased' && this.compareAvgScore(avg,beforeAvg).compareStatus !== 'increased'){
                style = {color: '#FF9233'}
            }
            return style
        },
        compareAvgScore(current,beforeCurrent){
            let compareResult = {
                result: null,
                compareStatus: null,
                compareStatusImgSrc: null,
            }
            if(current > beforeCurrent){
                compareResult.result = Math.abs(current) - Math.abs(beforeCurrent)
                compareResult.compareStatus = 'increased'

            }
            if(current < beforeCurrent){
                compareResult.result = Math.abs(beforeCurrent) - Math.abs(current)
                compareResult.compareStatus = 'decreased'
            }

            if(current === beforeCurrent){
                compareResult.result = Math.abs(current)
                compareResult.compareStatus = 'null'
            }
            return compareResult
        },
        personCurrentScoreColor(score,border,text){
            let style = ''
            if(!border){
                style = `background:${new Score(score).currentScoreColor()}`
            }else{
                style = `border: 2px solid ${new Score(score).currentScoreColor()}`
            }

            if(text){
                style = `color: ${new Score(score).currentScoreColor()}`
            }
            return  style
        },
        isInt(n) {
            return n % 1 === 0;
        },
        recalculateAveragePersonScore(score){
            if(this.isInt(score)) return score
            else{
                if(score.toString().split('.')[1] === '5') return score
                else{
                    if(parseInt(score.toString().split('.')[1]) > 5){
                        return Math.ceil(score)
                    }else{
                        return Math.round(score)
                    }
                }
            }
        },
        collapseCriteria(historyID,criteriaID){
            axios.get('grading/GetGradePartCriteriaDescriptions',{
                params:{
                    CriteriaID: criteriaID,
                }
            }).then(resp => {
                let descriptions = resp.data.Value
                descriptions.forEach(el => {
                    this.$set(el,'rangeVisible',false)
                })
                this.compareData.forEach(el => {
                    el.CriteriaDetails.forEach(obj => {
                        if(obj.CriteriaDetail.PersonGradingHistoryID === historyID && obj.CriteriaDetail.CriteriaID === criteriaID){
                            obj.CriteriaDetail.collapsed = !obj.CriteriaDetail.collapsed
                            obj.CriteriaDetail['descriptions'] = descriptions
                        }
                    })
                })
            })
        },
        range(start,stop,step = 0.5){
            return Array(Math.ceil(((stop + 0.5) - start) / step)).fill(start).map((x, y) => x + y * step)
        },
        generateRateBarData(min,max){
            return this.range(min,max)
        },
        generateRateBarRanges(min,max){
            let rangeArray = this.range(min,max)
            let rangeLabels = []

            rangeArray.forEach(el => {
                let obj = {
                    label: el.toString(),
                    isHide: !this.isInt(el),
                    type: this.isInt(el) ? 'int' : !this.isInt(el) ? 'float' : undefined,
                }
                rangeLabels.push(obj)
            })
            return rangeLabels
        },
        showRangeSlider(historyID,descID){
            this.rangeToolbarVisible.id = descID
            this.rangeToolbarVisible.parent = historyID
        },
        rangeVisibility(descID,historyID){
            let visible = false
            if(this.rangeToolbarVisible.id === descID && this.rangeToolbarVisible.parent === historyID){
                visible = true
            }
            return visible
        },
        salaryRanges(historyID,listMode){
            let salary = this.compareData.filter(el => {
                return el.GradingHistory.PersonGradingHistoryID === historyID
            })[0].SalaryRanges
            let currency
            if(!listMode){
                currency = salary.find(el => el.Code === this.compareData.find(el => el.GradingHistory.PersonGradingHistoryID === historyID).selectedCurrency)
                if(!currency || currency === undefined){
                    currency = salary[0]
                }
            }
            else{
                currency = salary.map(el => ({
                    title: el.Code,
                    value: el.Code,
                }))
            }
            return currency
        },
        selectCurrency(code,historyID){
            this.compareData.filter(el => el.GradingHistory.PersonGradingHistoryID === historyID)[0].selectedCurrency = code
            this.$nextTick(() => {
                this.visibleCurrencyOptionsIn = null
            })
        },
        collapseCurrencyOptions(id){
            this.visibleCurrencyOptionsIn = id
        },
        compareCriteriaScoresForPersons(){
            let compareArr1Criterias = []
            let compareArr2Criterias = []

            const objectsEqual = (o1, o2) =>
                Object.keys(o1).length === Object.keys(o2).length 
                    && Object.keys(o1).every(p => o1[p.CriteriaID] === o2[p.CriteriaID]);


            const arraysEqual = (a1, a2) => a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

            this.compareData[0].CriteriaDetails.forEach(el => {
                compareArr1Criterias.push(el.CriteriaDetail)
            })
            this.compareData[1].CriteriaDetails.forEach(el => {
                compareArr2Criterias.push(el.CriteriaDetail)
            })

            if(arraysEqual(compareArr1Criterias,compareArr2Criterias)){
                let compareValues = this.compareData.map(el => el.CriteriaDetails.map(c => ({
                    score: c.CriteriaDetail.CriteriaScore,
                    criteriaID :c.CriteriaDetail.CriteriaID,
                    historyID: c.CriteriaDetail.PersonGradingHistoryID
                })))
                const compareNumbers = (a,b) => {
                    if(Number(a) > Number(b)){
                        return a
                    }
                    if(b > a){
                        return b
                    }
                    if(a === b){
                        return 'equal'
                    }
                }
    
                const compareArrayObjScores = (arr1,arr2) => {
                    arr1.forEach(el => {
                        let compareObj = arr2.find(obj => obj.criteriaID === el.criteriaID).score
                        if(compareNumbers(el.score,compareObj) === el.score){
                            el['comparedStatus'] = 'high'
                        }
                        else if(compareNumbers(el.score,compareObj) === 'equal'){
                            el['comparedStatus'] = 'equal'
                        }
                        else{
                            el['comparedStatus'] = 'low'
                        }
                    })
                }
    
                compareArrayObjScores(compareValues[0],compareValues[1])
                compareArrayObjScores(compareValues[1],compareValues[0])
    
                this.compareScores = compareValues
            }

        },
        setComparedObjStatuScoreState(criteriaID,historyID,ind,color,statusName){
            if(!this.limitedGradingView) return
            if(this.compareScores && this.compareScores.length > 0){
                let currentObj
                let style
                if(ind === 0){
                    currentObj = this.compareScores[0].filter(obj => obj.historyID === historyID && obj.criteriaID === criteriaID)[0]
                }
                if(ind === 1){
                    currentObj = this.compareScores[1].filter(obj => obj.historyID === historyID && obj.criteriaID === criteriaID)[0]
                }
    
                if(currentObj.comparedStatus === 'low') style = {color: '#FF3356'}
                if(currentObj.comparedStatus === 'high') style = {color: '#29CC39'}
                if(currentObj.comparedStatus === 'equal') style = {color: '#FF9233'}
                return currentObj.comparedStatus && color ? style : currentObj.comparedStatus && statusName ? currentObj.comparedStatus : ''

            }else{
                return null
            }
        },
        visibilityBycurrentRatingRange(min,max,rateValue){
            let visible = false
            let range = this.range(min,max)
            range.forEach(el => el = Number(el))
            if(range.includes(Number(rateValue)) && rateValue !== null) {
                visible = true
                return true
            }
            else visible = false
            return visible
        },
        calculateTimeDiff(end,start){
            let months;
            months = (start.getFullYear() - end.getFullYear()) * 12
            months -= end.getMonth()
            months += start.getMonth()
            const diffTime = Math.abs(start - end);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            return months <= 0 ? diffDays + ' days' : months + ' months'
        },
        convetToDateString(timeStamp,format){
            return getDateString(timeStamp,format)
        },
        snackbarMessage(text,purpose){
            this.snackbar.visible = true
            this.snackbar.text = text
            this.snackbar.purpose = purpose
        },
        updatePersonGradingHistory(send,historyID){
            let newScores = []
            let historyObj = this.compareData.filter(obj => obj.GradingHistory.PersonGradingHistoryID === historyID)[0]
            let gradePartID = historyObj.GradingHistory.CurrentGradePartID
            historyObj.CriteriaDetails.forEach(crit => {
                if(Number(crit.CriteriaDetail.CriteriaScore) !== Number(crit.CriteriaDetail.totalRateValue)){
                    newScores.push({
                        gradingHistoryDetailID: crit.CriteriaDetail.PersonGradingHistoryDetailID ? crit.CriteriaDetail.PersonGradingHistoryDetailID : null,
                        score: crit.CriteriaDetail.totalRateValue ? crit.CriteriaDetail.totalRateValue : null,
                    })
                }
            })

            axios.post('grading/UpdatePersonsGradingHistories',{
                gradePartID: gradePartID ? gradePartID : null,
                newScores: newScores,
            }).then(resp => {
                if(!resp.data.ErrorMsg){
                    if(!send){
                        this.getComparePersons()
                        this.snackbarMessage('Request Saved Successfully','success')
                    }
                    else{
                        axios.post('grading/UpdatePersonGradingHistoryStatus',{
                            personGradingHistoryID: historyID ? historyID : null,
                            status : true,
                        }).then(res => {
                            if(!res.data.ErrorMsg){
                                this.snackbarMessage('Request Saved Successfully','success')
                                this.getComparePersons()
                            }
                            else{
                                this.snackbarMessage(res.data.ErrorMsg,'error')
                            }
                        })
                    }
                }else{
                    this.snackbarMessage(resp.data.ErrorMsg,'error')
                }
            })
        },
        calcScoreStateColor(score){
            if(score === null || score === undefined || !score) return style = {color: '#97A1C2' }
            const inRange = (i,min,max) => ((i-min) * (i-max) <= 0)
            let style
            
            if(inRange(Number(score.toFixed(0)),0,3)){
                style = {
                    color: '#FF3356'
                }
            }

            if(inRange(Number(score.toFixed(0)),4,6)){
                style = {
                    color: '#FF9233'
                }
            }
            if(inRange(Number(score.toFixed(0)),7,10)){
                style = {
                    color: '#29CC39'
                }
            }
            return style
        },
        personCriteriaByColumn(historyID,criteriaID){
            let currentPersonData = this.compareData.find(el => el.GradingHistory.PersonGradingHistoryID === historyID)
            let currentCriteia = currentPersonData.CriteriaDetails.find(el => el.CriteriaDetail.CriteriaID === criteriaID)

            return currentCriteia ? currentCriteia.CriteriaDetail.CriteriaScore : 0
        },
        selectedPersonsforQuery(checked,id){
            if(!this.limitedGradingView){
                this.dropdownPersonsList.find(el => el.id === id).selected = checked
            }else{
                let firstComparableObj = parseInt(this.$route.query.gradingHistoryIDs[0])
                axios.post('grading/GetPersonsCurrentGrade',{
                    Persons:[{
                        id: id,
                    }]
                }).then(resp => {
                    if(resp.data.Value && resp.data.Value.length > 0 && resp.data.Value[0].GradingHistoryID){
                        const historyID = resp.data.Value[0].GradingHistoryID
                        let gradingHistoryIDs = [firstComparableObj,historyID]
                        let currentPath = this.$route.path
                        this.$router.replace({path: currentPath,query:{gradingHistoryIDs: gradingHistoryIDs}})
                    }else{
                        const FullName = this.dropdownPersonsList.find(el => el.id === id).title
                        this.snackbarMessage(`${FullName} Has No Grading History`,'warning')
                    }
                })
            }
        },
        checkPersonGradings(){
            let someSelected = this.dropdownPersonsList.some(el => el.selected)
            if(someSelected){
                const selectedPersons = this.dropdownPersonsList.filter(el => el.selected).map(obj => ({
                    id: obj.id
                }))
                axios.post('grading/GetPersonsCurrentGrade',{
                    Persons:selectedPersons
                }).then(resp => {
                    if(resp.data.Value && resp.data.Value.length > 0){
                        const gradingHistoryIDs = resp.data.Value.map(el => el.GradingHistoryID)
                        if(gradingHistoryIDs && gradingHistoryIDs.length > 0 && gradingHistoryIDs.length > 1){
                            const routerObj = {path:this.$route.path,query: {gradingHistoryIDs:gradingHistoryIDs}}
                            this.$router.replace(routerObj)
                        }
                    }
                    
                })
                
            }
            else return
        },
        showAdditionalInfo($event,person,history,criteria){
            let historyObj = this.compareData.find(el => el.GradingHistory.PersonGradingHistoryID === history)
            let criteriaObj = historyObj.CriteriaDetails.find(crit => crit.CriteriaDetail.CriteriaID === criteria)
            let criteriaDescription = criteriaObj && criteriaObj.CriteriaDetail ? criteriaObj.CriteriaDetail.CriteriaDescriptionDescription : null
            this.colOjbValue.description = criteriaDescription
            let fullwidth = window.innerWidth - 270
            let fullheight = window.innerHeight - 400
            this.colOjbValue.personID = person
            let fullname = (historyID,personID) => {
                let name = '' 
                let currObj = this.compareData.find(el => el.GradingHistory.PersonGradingHistoryID === historyID).GradingHistory
                if(currObj){
                    name = `${currObj.FirstName} ${currObj.LastName}`
                }else name = ''
                return name
            }
            this.colOjbValue.fullname = fullname(history,person)
            this.$nextTick(() => {
                if(!criteriaDescription || criteriaDescription == null) return
                let containerHeight = this.$refs.additionalInfo.clientHeight

                let x = $event.x
                let y = $event.y
                let style = {}
                if(x > fullwidth ){
                    style = {
                        top: `${y}px`,
                        left: `${x - 260}px !important`
                    }
                }else if(y > fullheight){
                    style = {
                        top: `${y - containerHeight}px`,
                        left: `${x}px !important`
                    }
                }
                else{
                    style = {
                        top: `${y}px`,
                        left: `${x}px`
                    }
                }
                this.colOjbValue.style = style
            })
        }
    }
}
</script>

<style lang="scss" scoped>

*{
    font-family: Roboto,firaGo;
}

.info-tooltip{
    width: 15rem;
    max-height: 22rem;
    position: fixed;
    overflow: auto;
    padding: 0.625rem 0.9375rem;
    background: #fff;
    z-index: 99;
    border-radius: 0.25rem;
    box-shadow: 0 0 1.25rem rgba(#26334D,.2);
    font-size: 0.75rem;
    color: #4D5E80;
    font-weight: 400;
    animation: openUp .4s forwards steps(40);

    h2{
        font-size: 0.875rem;
        padding-bottom: 0.125rem;
    }
}


.background{
    overflow: hidden;
    background: rgba(#ededfc,.6);
}

ul{
    list-style: none;
}

.hoveredCol{
    background: $purple !important;

    &::after{
        background: $purple !important;
    }

    span{
        color: #fff;
    }
}

.content-wrapper{
    position: relative;
    width: 100%;
    height: calc(100% - .9rem);

    .content-header{
        width: calc(100% - 1rem);
        margin: auto;
        @include flex(space-between,center,row);
        padding: 0.9375rem 0.9375rem;
        background: #ffffff;
        margin-top: 0.625rem;
        border-radius: 0.75rem;

        .filters{
            ul{
                @include flex(flex-start,center,row);
                li{
                    margin-left: 0.9375rem;
                    &:first-child{
                        margin-left: 0;
                    }

                    ::v-deep{
                        .input-search{
                            width: 13.75rem;
                            label{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .left-side{
            
            .back-btn{                
                @include flex(flex-start,center,row);
                cursor: pointer;
                img{
                    width: 1.25rem;
                }
                span{
                    color: #ADB8CC;
                    font-size: 0.875rem;
                    margin-left: 0.3125rem;
                }
            }
        }

        .right-side{
            padding-right: 1.25rem;
            @include flex(flex-start,center,row);

            & > div{
                border-radius: 1.875rem !important;
                width: 10rem !important;
                height: 2.5rem;
                cursor: pointer;
                span{
                    font-weight: 600;
                }
            }

            .performance-report{
                background: #ffffff;
                border: 0.0625rem solid $purple;
                @include centerElement;
                font-weight: 500;
                transition: ease .2s;
                &:active{
                    background: $purple;
                    span{
                        color: #ffffff;
                    }
                }

                span{
                    color: $purple;
                    font-size: 0.75rem;
                }
            }

            .compare-with{
                margin-left: 0.9375rem;
                position: relative;
                .wrapper{
                    width: 10rem !important;
                    @include purpleBtn;
                    @include flex(center,center,row);
                    border-radius: 1.5625rem !important;
                    img{
                        width: 1.25rem;
                    }

                    span{
                        padding-left: 0.625rem;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .compare-content{
        width: 100%;
        height: calc(100% - 3rem);
        margin-top: 0.9375rem;
        position: relative;

        .compare-cards{
            width: calc(100% - 1.2rem);
            margin: auto;
            height: calc(100% - 3rem);
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 20px;

            .card,.chart-placeholder{
                background: #ffffff;
                height: calc(100% - .5rem);
                border-radius: 0.625rem;
                box-shadow: 0 0 1.25rem rgba(#ADB8CC,.1);
                border: 0.125rem solid transparent;
                transition: ease .3s;
            }

            .chart-placeholder{
                position: relative;

                .chart-loader{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    img{
                        width: 6.25rem;
                    }
                }
                .title{
                    width: 100%;
                    padding: 0.625rem 0.9375rem;
                    h1{
                        font-size: 1.1875rem;
                        color: #97A1C2;
                        font-weight: 400;
                    }
                }

                .chart-wrapper{
                    margin-top: 1.5625rem;
                }
            }

            .card{
                position: relative;
                padding: 1.1875rem 1.25rem; 
                animation: .2s scaleUp forwards;
                &:hover{
                    border: 0.125rem solid rgba(#29CC39,.5);
                    box-shadow: 0 0 1.25rem 0.9375rem rgba(#29CC39,.1);
                }
                @keyframes scaleUp {
                    from{
                        transform: scaleY(.2);
                    }
                    to{
                        transform: scaleY(1)
                    }
                }
                
                .actions{
                    margin-top: 0.9375rem;
                    @include flex(flex-end,center,row);

                    .send,.save{
                        @include purpleBtn;
                        @include centerElement;
                        border-radius: 1.25rem;
                        font-size: 0.875rem;
                        width: 5.625rem;
                        height: 2.3rem;
                    }

                    .save{
                        margin-right: 1.25rem;
                        background: #ffffff;
                        border: 0.0625rem solid $purple;
                        color: $purple;
                        &:hover{
                            box-shadow: 0 0 1.25rem rgba(#7D8FB3,.4) !important;
                        }
                        &:active{
                            color: #ffffff !important;
                            border: 0.0625rem solid transparent !important;
                        }
                    }
                }

                .person-details{
                    width: 100%;
                    @include flex(flex-start,left,column);
                    .top-side{
                        width: 100%;
                        @include flex(space-between,left,row);

                        .left-side{
                            @include flex(flex-start,center,row);
                            .user-avatar{
                                width: 4.0625rem;
                                height: 4.0625rem;
                                background-size: cover;
                                background-repeat: no-repeat;
                                border-radius: 50%;
                                background-color: rgba(#CCD6F6,.4);
                            }

                            .details{
                                @include flex(flex-start,left,column);
                                margin-left: 0.9375rem;
                                span{
                                    &.person_name{
                                        color: #283593;
                                        font-size: 0.875rem;
                                        font-weight: 500;
                                    }

                                    &.job_name{
                                        margin-top: 0.3125rem;
                                        font-size: 0.8125rem;
                                        color: #9696B9;
                                        font-weight: 500;
                                    }
                                }

                                .badge{
                                    @include flex(flex-start,center,row);
                                    span{
                                        color: #4D5E80;
                                        font-size: 0.9375rem;
                                        font-weight: 600;
                                    }

                                    img{
                                        margin-left: 0.625rem;
                                        width: 1.5rem;
                                        pointer-events: none;
                                        user-select: none;
                                    }
                                }
                            }
                        }

                        .right-side{
                            .score-state{
                                @include flex(flex-start,center,row);

                                .score-compared{
                                    text-align: right;
                                    padding-right: 0.625rem;
                                    .comparedResult{
                                        span{
                                            font-size: 0.875rem;
                                            margin-right: 0.3125rem;
                                            font-weight: 500;
                                        }
                                        img{
                                            width: 0.5rem;
                                        }
                                    }
                                    span:not(.comparedResult > span){
                                        color: #97A1C2;
                                        font-size: 0.8125rem;
                                    }
                                }

                                .current-score{
                                    user-select: none;
                                    width: 2.5rem;
                                    height: 2.5rem;
                                    border-radius: 50%;
                                    overflow: hidden;
                                    @include centerElement;
                                    span{
                                        color: #ffffff;
                                        font-size: 0.9375rem;
                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                    }
                    .bottom-side{
                        margin-top: 1.25rem;
                        .score-line{
                            @include flex(flex-start,center,row);
                            .single-line{
                                width: 1.5625rem;
                                height: 0.4375rem;
                                border-radius: 1.875rem;
                                background: #CCD6F6;
                                margin-left: 0.3125rem;

                                &:first-child{
                                    margin-left: 0;
                                }
                                &.blank{
                                    background: #CCD6F6 !important;
                                }
                            }
                        }

                        .additional-details{
                            margin-top: 1.25rem;

                            ul{
                                @include flex(flex-start,left,column);

                                li{
                                    @include flex(flex-start,center,row);
                                    margin-top: 0.3125rem;
                                    &:first-child{
                                        margin-top: 0;
                                    }
                                    .title{
                                        font-size: 0.875rem;
                                        color: #9696B9;
                                        font-size: 0.875rem;
                                        width: 3.2rem;
                                    }
                                    .value{
                                        font-size: 0.875rem;
                                        color: #4D5E80;
                                        font-weight: 500;
                                        position: relative;
                                        
                                        .selected-curr{
                                            cursor: pointer;
                                        }

                                        .currency-options{
                                            position: absolute;
                                            width: 3.125rem;
                                            padding: 0.3125rem 0;
                                            background: $purple;
                                            left: calc(100% - (3.125rem / 2));
                                            border-radius: 6px;
                                            @include flex(center,center,column);
                                            z-index: 99;
                                            span{
                                                cursor: pointer;
                                                color: #ffffff;
                                                font-weight: 600;
                                                padding: 0.3125rem 0;

                                                &:hover{
                                                    color: rgba(#ffffff,.8);
                                                }
                                            }
                                        }
                                    }             
                                }
                            }
                        }
                    }
                }

                .person-criterias{
                    width: 100%;
                    margin-top: 2.9375rem;
                    position: relative;
                    height: 26rem;
                    ul{
                        width: 100%;
                        @include flex(flex-start,left,column);
                        overflow: auto;
                        min-height: 12.5rem;
                        max-height: 25rem;

                        li{
                            width: 100%;
                            position: relative;
                            margin-top: 0.625rem;
                            &:first-child{
                                margin-top: 0;
                            }

                            &.collapsed{
                                .main-content{
                                    background: $purple;
                                }
                            }

                            .main-content{
                                width: 100%;
                                height: 2.1875rem;
                                background: #EBEBF7;
                                @include flex(space-between,center,row);
                                padding: 0 0.625rem;
                                border-radius: 0.3125rem;
                                transition: ease .3s;



                                .criteria-title{
                                    span{
                                        font-size: 0.875rem;
                                        color: #7D8FB3;
                                    }
                                }

                                .right-side{
                                    @include flex(flex-start,center,row);
                                    .criteria-rated-score{
                                        margin-right: 1.5625rem;
                                        span{
                                            font-size: 0.8125rem;
                                            color: #4D5E80;
                                            font-weight: 500;
                                        }
                                        img{
                                            width: 0.5625rem;
                                        }
                                    }

                                    .dropdown-arrow{
                                        cursor: pointer;
                                        margin-top: 0.3125rem;
                                        &.collapsed{
                                            img{
                                                transform: rotate(-180deg);
                                            }
                                        }
                                        img{
                                            width: 1.25rem;
                                            transition: ease .2s;
                                        }
                                    }
                                }
                            }

                            .criteria-details{
                                width: 100%;
                                position: relative;

                                ul{
                                    max-height: unset !important;
                                    min-height: unset !important;
                                }

                                .single-description{
                                    margin-top: 0.9375rem;
                                    .description-content{
                                        padding-left: 0.5rem;
                                        .top{
                                            .score-range{
                                                span{
                                                    color: #7D8FB3;
                                                    font-weight: 500;
                                                    font-size: 0.875rem;

                                                    img{
                                                        margin-left: 0.3125rem;
                                                        width: 1rem;
                                                        cursor: pointer;
                                                    }
                                                }
                                            }
                                            .score-slidebar{
                                                position: relative;
                                                margin-left: 0.3125rem;
                                                @include flex(flex-start,center,row);

                                                .close-range-bar{
                                                    cursor: pointer;
                                                    margin-left: 25px;
                                                    img{
                                                        width: 1.1875rem;
                                                        transform: rotate(90deg);
                                                    }
                                                }
                                            }
                                        }
                                        .bottom{
                                            margin-top: 0.625rem;
                                            p{
                                                font-size: 0.875rem;
                                                color: rgba(#4D5E80,.8);
                                                font-weight: 500;
                                                line-height: 1.1875rem;

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .compare-grid{
            width: calc(100% - 1rem);
            height: calc(100% - 3rem);
            margin: auto;
            position: relative;
            overflow-y: auto;
            overflow-x: hidden;
            animation: .2s scaleUp forwards;


            .grid-wrapper{
                width: 100%;
                // height: 100%;
                @include flex(flex-start,left,row);

                .common-header{
                    background: #ffffff;
                    width: 100%;
                    height: 7.8125rem;
                    overflow: hidden;
                    &.titles-header{
                        border-top-left-radius: 0.625rem;
                        border-bottom-left-radius: 0.625rem;
                        padding: 0 1.875rem !important;
                        @include flex(flex-start,end,row);
                        position: relative;
                        overflow: visible;
                        &::after{
                            content: '';
                            position: absolute;
                            width: 2rem;
                            height: 100%;
                            right: -2rem;
                            top: 0;
                            background: rgb(255,255,255);
                            background: linear-gradient(90deg, rgba(255,255,255,0.9164040616246498) 43%, rgba(251,251,251,0.5018382352941176) 100%);
                            // z-index: 9;
                        }
                        span{
                            padding-bottom: 1.1875rem;
                            color: #7D8FB3;
                            font-size: 0.8125rem;
                            font-weight: 500;
                        }
                    }
                }
                .common-col{
                    background: #ffffff;
                    height: 3.125rem;
                    margin-top: 0.75rem;
                    // box-shadow: 0 0 0.9375rem rgba(#4D5E80,.05);

                    &.single-title{
                        padding: 0 1.875rem !important;
                        @include flex(left,center,row);
                        color: rgba(#6B7A99,.8) !important;
                        font-size: 0.8125rem;
                        font-weight: 600;
                        border-top-left-radius: 0.625rem;
                        border-bottom-left-radius: 0.625rem;
                        position: relative;
                        &::after{
                            content: '';
                            position: absolute;
                            width: 2rem;
                            height: 100%;
                            right: -2rem;
                            top: 0;
                            background: rgb(255,255,255);
                            background: linear-gradient(90deg, rgba(255,255,255,0.9164040616246498) 43%, rgba(251,251,251,0.5018382352941176) 100%);
                        }
                    }
                    &.single-score{
                        @include centerElement;
                        span{
                            cursor: pointer;
                            font-size: 0.875rem;
                            font-weight: 600;
                        }
                    }
                }

                .left-side-titles{
                    width: 18rem;
                    ul{
                        li{
                            &.titles-header{

                            }
                        }
                    }
                }

                .right-side-content{
                    width: calc(100% - 18.3rem);
                    ul{
                        overflow-y: auto;
                        &::-webkit-scrollbar{
                            display: none;
                        }

                        li{
                            display: inline-flex;
                            .single-person{
                                width: 9.375rem;
                                transition: all .2s;
                                &:first-child{
                                    margin-left: 2rem;
                                }

                                &:last-child{
                                    .user-info,.scores > .single-score{
                                        border-top-right-radius: 0.625rem;
                                        border-bottom-right-radius: 0.625rem;
                                    }
                                }

                                .single-score{
                                    position: relative;
                                }
                                .user-info{
                                    @include flex(center,center,column);
                                    .avatar{
                                        width: 3.125rem;
                                        height: 3.125rem;
                                        background-size: cover;
                                        border-radius: 50%;
                                        position: relative;

                                        .badge{
                                            width: 1.25rem;
                                            height: 1.25rem;
                                            position: absolute;
                                            background-size: cover;
                                            bottom: -0.625rem;
                                            right: -0.625rem;
                                            z-index: 9;
                                            border-radius: 50%;
                                        }

                                        &::before{
                                            content: '';
                                            width: calc(100% + 0.625rem);
                                            height: calc(100% + 0.625rem);
                                            position: absolute;
                                            top: 50%;
                                            left: 50%;
                                            background: transparent;
                                            transform: translate(-50%,-50%);
                                            border: 0.125rem solid rgba(#6B7A99,.2);
                                            border-radius: 50%;
                                            box-shadow: 0 0 1.25rem rgba(#6B7A99,.1);
                                        }
                                    }

                                    .person-name{
                                        margin-top: 0.625rem;
                                        white-space: nowrap;
                                        max-width: 8rem;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        text-align: center;

                                        span{
                                            font-size: 0.875rem;
                                            font-weight: 500;
                                            color: #6B7A99;   
                                        }
                                    }
                                }
                            }
                        }
                    }

                }



                // ul.list{
                //     list-style: none;
                //     height: calc(100% - 2rem);
                //     border-radius: 1.25rem;
                //     overflow: auto;
                //     background: #ffffff;


                //     li{
                //         width: 100%;
                //         padding: 0.5rem 0.625rem;
                //         margin-top: 0.75rem;
                //         padding-bottom: 0.9375rem;
                //         &.grid-header{
                //             margin-top: 0;
                //             height: 7.8125rem;

                //             .title{
                //                 font-size: 0.875rem;
                //                 color: #7D8FB3;
                //                 font-weight: 500;
                //                 height: 100%;
                //                 @include flex(flex-start,flex-end,row);

                //                 span{
                //                     padding: 0 0.625rem;
                //                 }
                                
                //             }

                //             .persons{

                //                 .person{
                //                     @include flex(center,center,column);
                //                     .avatar{
                //                         width: 3.125rem;
                //                         height: 3.125rem;
                //                         border-radius: 50%;
                //                         background-size: cover;
                //                         position: relative;

                //                         &::before{
                //                             content: '';
                //                             width: calc(100% + 0.625rem);
                //                             height: calc(100% + 0.625rem);
                //                             position: absolute;
                //                             top: 50%;
                //                             left: 50%;
                //                             transform: translate(-50%,-50%);
                //                             border: 0.125rem solid rgba(#6B7A99,.2);
                //                             border-radius: 50%;
                //                             box-shadow: 0 0 1.25rem rgba(#6B7A99,.1);
                //                         }
                //                     }

                //                     .title-name{
                //                         white-space: nowrap;
                //                         max-width: 9rem;
                //                         overflow: hidden;
                //                         text-overflow: ellipsis;
                //                         span{
                //                             font-size: 0.875rem;
                //                             font-weight: 500;
                //                             color: #6B7A99;   
                //                         }
                //                     }
                //                 }
                //             }
                            
                //         }

                //         &.grid-single-item{
                //             .single-person-data{

                //                 .title{
                //                     @include flex(flex-start,center,row);
                //                     font-size: 0.875rem;
                //                     font-weight: 600;
                //                     color: rgba(#283593,.8);
                //                     &::before{
                //                         content: '';
                //                         position: absolute;
                //                         bottom: -50%;
                //                         left: 0;
                //                         width: 100%;
                //                         height: 0.0625rem;
                //                         background: rgba(#6B7A99,.1);
                //                     }
                //                 }
                //             }
                //         }

                //         &.grid-header,&.grid-single-item,.single-person-data{
                //             display: grid;
                //             grid-template-columns: 19rem auto;

                //             .scores{
                //                 position: relative;
                //                 &::before{
                //                     content: '';
                //                     position: absolute;
                //                     bottom: -50%;
                //                     left: 0;
                //                     width: 100%;
                //                     height: 0.0625rem;
                //                     background: rgba(#6B7A99,.1);
                //                 }
                //             }

                //             .persons,.scores{
                //                 display: inline-flex;
                //                 .row{
                //                     width: 10rem !important;
                //                     padding: 0.3125rem 0.3125rem;
                //                     margin-left: 0.9375rem;
                //                     overflow: hidden;
                //                     @include centerElement;
                //                     span{
                //                         white-space: nowrap !important;
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // }
            }
        }

    }
}

</style>