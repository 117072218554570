<template>
    <div>
        <editor
            v-if="offerToolbar"
            :api-key="key"
            :value="value"
            :placeholder="title"
            @input="inputHandler"
            :init="{
                inline: true,
                height: 300,
                branding: false,
                menubar: false,
                statusbar: false,
                toolbar:
                    'undo redo | styleselect | bold italic |  fontsizeselect fontselect | alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist outdent indent | link  | ' +
                    'forecolor backcolor emoticons | help ',
                plugins: [
                    'advlist autoresize autolink link image lists charmap print preview hr anchor pagebreak',
                    ' searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
                    'table emoticons template paste help',
                ],
                branding: true,
                skin: 'borderless',
            }"
        />
        <editor
            v-else
            :api-key="key"
            :value="value"
            :placeholder="title"
            @input="inputHandler"
            :init="{
                inline: true,
                height: 300,
                branding: false,
                menubar: false,
                statusbar: false,
                toolbar: 'bold italic |  fontselect | ' + 'forecolor backcolor emoticons',
                branding: true,
                skin: 'borderless',
            }"
        />
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
export default {
    components: {
        editor: Editor,
    },
    props: {
        value: String,
        title: String,
        offerToolbar: Boolean,
    },
    methods: {
        inputHandler(val) {
            this.$emit('input', val)
        },
    },
    data() {
        return {
            key: 'ryzrlj7vmuqo266grm3goqq8npkvaehj06fdvitz81omre1e',
        }
    },
}
</script>

<style lang="scss" scoped>
.mce-content-body {
    outline: none;
    width: 100%;
    height: 2.5rem;
    border-radius: 0.375rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0.0625rem solid #dcdde6;
    color: rgba(40, 53, 147, 0.7);
    font-size: 0.9375rem;
    padding: 0 0.9375rem 0 0.9375rem;
    outline: none;
    &::placeholder {
        padding: 10px;
    }
    ::v-deep p {
        margin-top: 10px;
    }
}
.mce-content-body:not([dir='rtl'])[data-mce-placeholder]:not(.mce-visualblocks)::before {
    top: 8px;
    left: 15px;
    color: rgba(40, 53, 147, 0.7);
    font-size: 1rem;
    font-weight: 300;
}
</style>
