<template>
  <div class="background">
    <div class="jobs-info">
      <h1 class="user-details">{{ this.personName }}</h1>
      <grid
        :columns="columns"
        @rowEdit="editRow"
        :canEdit="isAdmin"
        :gridData="gridData"
        :canAdd="isAdmin"
        identificator="ID"
        @add-click="addNewClickHandler"
        :canDelete="isAdmin"
        @rowDelete="showDeletePopup"
        @trClicked="trClickHandler"
      />
      <custom-popup
        :visible="popupVisible"
        @close="popupVisible = false"
        title="Assign Job"
        :flexibleSize="true"
      >
        <form class="add-form" @submit.prevent="assignJob">
          <input-search
            v-model="newJob.name"
            title="Choose Job "
            :options="inputOptions"
            @select="(value) => (this.selectedJobID = value)"
          />
          <date-picker
            title="Start Date"
            v-model="newJob.startTime"
            @clear="newJob.startTime = ''"
          />
          <button class="btn">Save</button>
        </form>
      </custom-popup>
      <delete-item
        @delete="deleteRow"
        @close="deletePopupVisible = false"
        v-if="deletePopupVisible"
        :text="deleteText"
      />
      <custom-snackbar
        v-if="snackbarVisible"
        :purpose="snackbarPurpose"
        :text="snackbarText"
        @close="snackbarVisible = false"
      />
    </div>
  </div>
</template>

<script>
import Grid from "@/components/ReusableComponents/Grid/Grid.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import axios from "@/Helpers/AxiosInstance.js";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue";
import InputSearch from "@/components/ReusableComponents/InputSearch/InputSearch.vue";
import DeleteItem from "@/components/ReusableComponents/DeleteItem/DeleteItem.vue";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup.vue";

import {
  timestampToCET,
  checkPermission,
  getDateString,
  CETToTimestamp,
} from "@/Helpers/HelperFunctions.js";
export default {
  data() {
    return {
      accessPermissions: {
        getJobs: checkPermission("GetJobs"),
        createJob: checkPermission("CreateJob"),
        updatePermission: checkPermission("UpdatePermission"),

        assignPersonJob: checkPermission("AssignPersonJob"),
      },
      gridData: [],
      personName: " ",
      snackbarText: "",
      snackbarPurpose: "error",
      snackbarVisible: false,
      popupVisible: false,
      deletePopupVisible: false,
      newJob: {
        name: "",
        startTime: "",
        jobID: "",
      },

      allJobs: [],
      selectedJobID: "",
    };
  },
  created() {
    this.getPersonJobs();
    this.getAllJobs();
    this.getPersonName();
  },
  components: {
    Grid,
    CustomSnackbar,
    DatePicker,
    CustomPopup,
    DeleteItem,
    InputSearch,
  },
  computed: {
    columns() {
      return [
        {
          title: "Job Title",
          key: "JobName",
        },
        {
          title: "From Date",
          key: "StartTime",
          editable: true,
          editType: "date",

          customTemplate: (item, val) => getDateString(CETToTimestamp(val)),
        },
        {
          title: "To Date",
          key: "EndTime",
          editable: true,
          editType: "date",
          customTemplate: (item, val) =>
            val ? getDateString(CETToTimestamp(val)) : "Till Today",
        },
        {
          title: "Total Period",
          key: "EndTime",
          customTemplate: (item, val) => this.getDurationString(item.StartTime),
        },
      ];
    },
    isAdmin() {
      return this.$store.getters.isAdmin || this.$store.getters.isSuperAdmin || this.$store.getters.hasRole("HR");
    },
    deleteText() {
      if (this.gridData.length == 0 || !this.selectedJobID) return "";
      var name = this.gridData.filter((el) => el.ID == this.selectedJobID)[0]
        .Name;

      return `Would you like to delete job <strong>${name}</strong>?`;
    },
    inputOptions() {
      return this.allJobs.map((el) => {
        el.value = el.ID;
        el.title = el.Name;
        return el;
      });
    },
  },
  methods: {
    deleteRow() {
      var item = this.gridData.filter((el) => el.ID == this.selectedJobID)[0];
      var data = {
        ID: this.selectedJobID,

        Deleted: true,
        EndTime: item.EndTime,
      };
      this.$confirm("Would you like to delete this job?").then(() => {
        axios
          .post("jobs/UpdatePersonJob", data)
          .then((resp) => {
            this.deletePopupVisible = false;
            if (!resp.data.ErrorMsg) {
              this.snackbarText = "Deleted Successfully!";
              this.snackbarPurpose = "success";
              this.snackbarVisible = true;
              this.getPersonJobs();
            } else {
              this.snackbarVisible = true;
              this.snackbarText = resp.data.ErrorMsg;
              this.snackbarPurpose = "error";
            }
          })
          .catch(() => {
            this.snackbarVisible = true;
            this.snackbarText = "Error";
            this.snackbarPurpose = "error";
          });
      });
    },
    getPersonName(id) {
      id = this.$route.params.id;
      axios.get(`Person/GetPersonsDetails?Person=${id}`).then((resp) => {
        let firstName = resp.data.Value.FirstName;
        let lastName = resp.data.Value.LastName;
        let UpperCaseName =
          firstName.charAt(0).toUpperCase() + firstName.slice(1);
        let UpperCaseLastName =
          lastName.charAt(0).toUpperCase() + lastName.slice(1);
        this.personName = `${UpperCaseName} ${UpperCaseLastName}`;
      });
    },
    showDeletePopup(id) {
      this.selectedJobID = id;
      this.deletePopupVisible = true;
    },
    editRow(values) {
      values["Deleted"] = null;

      values["EndTime"] = values["EndTime"] == 0 ? null : values["EndTime"];

      axios
        .post("jobs/UpdatePersonJob", values)
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.getPersonJobs();
            this.snackbarText = "Updated Successfully!";
            this.snackbarPurpose = "success";
            this.snackbarVisible = true;
          } else {
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
            this.snackbarPurpose = "error";
          }
        })

        .catch(() => {
          this.snackbarVisible = true;
          this.snackbarText = "Error";
          this.snackbarPurpose = "error";
        });
    },
    trClickHandler(element, e) {
      if (e.target.matches(".i-permissions")) {
        this.$router.push({
          name: "Permissions",
          params: { job: element, currentSection: "2" },
        });
      } else if (e.target.matches(".i-roles")) {
        this.rolesPopupVisible = true;
        this.selectedJobID = element.ID;
      } else if (e.target.matches(".i-person")) {
        this.personPopupVisible = true;
        this.selectedJobID = element.ID;
      }
    },
    getPersonJobs(id) {
      id = this.$route.params.id;
      axios
        .get(`jobs/GetPersonJobs?Person=${id}&ShowHistory=true`)
        .then((resp) => {
          this.gridData = resp.data.Value;
          this.gridData.forEach((el) => {
            this.$set(el, "canEdit", this.isAdmin && ((new Date(el.EndTime) > new Date()) || el.EndTime == null) ? true : false);
            this.$set(el, "canDelete", this.isAdmin && ((new Date(el.EndTime) > new Date()) || el.EndTime == null) ? true : false);
          });
        });
    },
    getAllJobs() {
      axios.get("jobs/GetJobs").then((response) => {
        this.allJobs = response.data.Value.Jobs;
      });
    },
    addNewClickHandler() {
      this.popupVisible = true;
    },

    getDurationString(start) {
      var duration = Date.now() - start;
      var days = Math.abs(parseInt(duration / (24 * 3600000)) % 30);
      var months = Math.abs(
        (new Date().getFullYear() - new Date(start).getFullYear()) * 12
      );
      months -= new Date(start).getMonth();
      months += new Date().getMonth();
      months = months <= 0 ? 0 : months % 12;
      var year = Math.abs(parseInt(duration / (365 * 24 * 3600000))); //hmm 365 :)
      var res = "";
      if (year) res += `${year} Year`;
      if (months) {
        if (res) res += ", ";
        res += `${months} month`;
      }
      if (days) {
        if (res) res += " & ";
        res += `${days} days`;
      }
      return res;
    },

    assignJob(job) {
      axios
        .post("jobs/AssignPersonjob", {
          Person: parseInt(this.$route.params.id),
          Job: this.selectedJobID,
          StartTime: timestampToCET(this.newJob.startTime),
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.popupVisible = false;
            this.getPersonJobs();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  height: calc(100% - 43 * #{$rem});
}

.add-form {
  label {
    margin-right: 0.9375rem;
  }
  .input-search {
    display: inline-block;
  }
}
::v-deep .grid {
  .grid-header {
    margin-bottom: 0;
  }
}

.user-details {
  font-family: "Roboto";
  color: #283593;
  font-size: 1.125rem;
  font-weight: 400;
  position: absolute;
  left: 6.225rem;
  top: 1.875rem;
}
</style>