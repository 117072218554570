<template>
  <div class="arrow" @click="$emit('click')">
    <div class="line"></div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.arrow {
  margin-top: 61 * $rem;
  height: 10 * $rem;
  width: 15 * $rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  cursor: pointer;

  .line {
    background-color: #8a91c7;
    height: 1 * $rem;
    transform: rotate(-38deg);
  }

  .line + .line {
    transform: rotate(38deg);
  }
}
</style>