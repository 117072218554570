<template>
    <div>  
        <div 
            class="content" 
            ref="content" 
            @mousedown="mouseDownHandler" 
            @mouseup="mouseUpHandler" 
            @mousemove="mouseMoveHandler"
            @dblclick="isDragging = false"
            @contextmenu.prevent="rightClickHandler"
        >
            <div
                class="schedule-item" 
                v-show="currentItem.startTime && currentItem.endTime && currentItem.day >= 0"
                :style="{'width': `${cellWidthWithOffset}rem`, 'top': topPosition(currentItem), 'left': leftPosition(currentItem), 'height': itemHeight(currentItem)}"
                @mousedown.stop
            >
            </div>
            <template v-for="daySchedule in savedScheduleItems">
                <div
                    v-if="daySchedule.ScheduleStartTime !== daySchedule.ScheduleEndTime"
                    :key="daySchedule.day + 'w'"
                    class="schedule-item"
                    :class="daySchedule.TotalWorkTime > 0 ? 'schedule-item-multi' : 'schedule-item-one'"
                    :style="{'width': `${cellWidthWithOffset}rem`, 'top': topPosition(daySchedule), 'left': leftPosition(daySchedule), 'height': itemHeight(daySchedule)}"
                    @click.stop @mousedown.stop
                >
                    <span class="start-time">{{getScheduleStartTime(daySchedule)}}</span>
                    <div class="actions" v-if="!noEdit">
                        <i class="material-icons" @click="editDay(daySchedule.day)">create</i>
                        <i class="material-icons" @click="copyHandler(daySchedule)">content_copy</i>
                        <i class="material-icons" @click="$emit('delete-day', daySchedule.Day)">close</i>
                        <span v-show="copiedDay == daySchedule.day">Copied!</span>
                    </div>
                    <span class="end-time">{{getScheduleEndTime(daySchedule)}}</span>
                    <span class="title start">Start Time</span>
                    <span class="title end">End Time</span>
                </div>
            </template>
            <div class="row" v-for="time in times" :key="time" :style="{'height': `${cellHeight}rem`}">
                <span class="time">{{ time }}</span>
                <div 
                    class="cell" 
                    v-for="day in [1,2,3,4,5,6,7]" 
                    :key="day"
                    :style="{'width': `${cellWidth}rem`}"
                >
                </div>
            </div>
            <div class="paste" v-show="showPaste" ref="paste" @click="pasteHandler" @mousedown.stop>Paste</div>
        </div> 
        <update-fixed-schedule
            v-if="popupVisible"
            :schedule="updateSchedule"
            @close="popupVisible = false"
            @update-schedule="emitUpdateAndClosePopup"
        />
    </div>
</template>

<script>
import UpdateFixedSchedule from './UpdateFixedSchedule.vue'
const DEFAULT_START = '12:00'
const DEFAULT_END = '18:00'
export default {
    components: {
        UpdateFixedSchedule,
    },
    props: {
        cellWidth: Number,
        cellHeight: Number,
        schedule: Array,
        noEdit: Boolean,
    },
    created() {
        document.addEventListener('click', this.closePaste)
    },
    data() {
        return {
            startTime: '00:00',
            isDragging: false,
            currentItem: {
                startTime: '',
                endTime: '',
                day: '',
            },
            popupVisible: false,
            popupData: {
                workStart: '',
                workEnd: '',
            } ,
            updateSchedule: {
                day: 0,
                scheduleStartTime: 0,
                scheduleEndTime: 0,
                totalWorkTime: 0,
                breakStart: 0,
                breakEnd: 0,
                totalBreakTime: 0,
            },
            littleOffset: 22 * 0.0625,
            copiedDay: null,
            showPaste: false,
            pasteDay: 0,
        }
    },
    computed: {
        times() {
            var arr = [this.startTime]
            var hr = parseInt(this.startTime.split(':')[0])
            while(hr != 24) {
                hr++
                let hrText = ('0'+hr).slice(-2) + ':00'
                arr.push(hrText)
            }
            return arr
        },
        savedScheduleItems() {
            return this.schedule.map(el => {
                let hr = ('0' + Math.floor(el.ScheduleStartTime / (60000 * 60))).slice(-2)
                let mm = ('0' + ((el.ScheduleStartTime / 60000) % 60)).slice(-2)
                el.startTime = `${hr}:${mm}`
                hr = ('0' + Math.floor(el.ScheduleEndTime / (60000 * 60))).slice(-2)
                mm = ('0' + ((el.ScheduleEndTime / 60000) % 60)).slice(-2)
                el.endTime = `${hr}:${mm}`
                el.day = (el.Day + 5) % 7
                return el
            })
        },
        cellWidthWithOffset() {
            return (this.cellWidth - 2 * this.littleOffset)
        },
        totalHeight() {
            return this.times.length * this.cellHeight
        },
    },
    methods: {
        mouseDownHandler(e) {
            if(e.which == 3) return //right click is handled separately
            this.currentItem.endTime = ''
            var containerRect = this.$refs.content.getBoundingClientRect()
            var clickX = Math.floor(e.clientX - containerRect.x)
            var clickY = Math.floor(e.clientY - containerRect.y)
            var cellHeightPx = this.cellHeight / 0.0625
            var cellWidthPx = this.cellWidth / 0.0625
            var day = Math.floor(clickX / cellWidthPx)
            if(this.savedScheduleItems.find(el => el.day == day && el.ScheduleStartTime)) return
            this.isDragging = true
            this.currentItem.day = day
            this.currentItem.startTime = this.times[Math.floor(clickY / cellHeightPx)]
        },
        mouseUpHandler() {
            this.isDragging = false
            if(this.currentItem.day < 0) return
            if(!this.currentItem.startTime) return
            if(!this.currentItem.endTime) { //this happens when user clicks on an empty day
                this.currentItem.startTime = DEFAULT_START
                this.currentItem.endTime = DEFAULT_END
            }
            this.updateSchedule.scheduleStartTime = this.currentItem.startTime
            this.updateSchedule.scheduleEndTime = this.currentItem.endTime
            this.updateSchedule.day = this.currentItem.day
            this.popupVisible = true
        },
        mouseMoveHandler(e) {
            if(this.isDragging == false) return
            var containerRect = this.$refs.content.getBoundingClientRect()
            var y = Math.floor(e.clientY - containerRect.y)
            var cellHeightPx = this.cellHeight / 0.0625
            this.currentItem.endTime = this.times[Math.floor(y / cellHeightPx) + 1]
        },
        emitUpdateAndClosePopup(data) {
            this.popupVisible = false
            this.$emit('update-schedule', data)
        },
        topPosition(item) {
            if(!item.endTime || !item.startTime) return 0
            if(!item.ScheduleStartTime) {
                var startHour = parseInt(item.startTime.split(':')[0])
                var endHour = parseInt(item.endTime.split(':')[0])
                var tableStartHour = parseInt(this.startTime.split(':')[0])
                return Math.min(startHour-tableStartHour, endHour-tableStartHour) * this.cellHeight + this.cellHeight / 2 + 'rem'
            }
            else {
                var differenceInHours = item.ScheduleStartTime / (60000 * 60) - parseInt(this.startTime.split(':')[0])
                return differenceInHours * this.cellHeight + this.cellHeight / 2 +'rem'
            }
        },
        leftPosition(item) {
            return item.day * this.cellWidth + parseFloat(this.littleOffset) + 'rem'
        },
        itemHeight(item) {
            if(!item.endTime || !item.startTime) return 0
            if(!item.ScheduleStartTime) {
                var startTime = item.startTime
                var endTime = item.endTime
                return (Math.abs(parseInt(startTime.split(':')[0]) - parseInt(endTime.split(':')[0]))) * this.cellHeight + 'rem'
            }
            else {
                var difference = item.ScheduleEndTime - item.ScheduleStartTime
                return (difference / (60000*60)) * this.cellHeight + 'rem'
            }
        },
        getScheduleEndTime(schedule) {
            var endHr = ('0' + Math.floor(schedule.ScheduleEndTime / (60000 * 60))).slice(-2)
            var endMm = ('0' + (schedule.ScheduleEndTime / 60000) % 60).slice(-2)
            return `${endHr} : ${endMm}`
        },
        getScheduleStartTime(schedule) {
            var startHr = ('0' + Math.floor(schedule.ScheduleStartTime / (60000 * 60))).slice(-2)
            var startMm = ('0' + (schedule.ScheduleStartTime / 60000) % 60).slice(-2)
            return `${startHr} : ${startMm}`
        },
        editDay(day) {
            var schedule = this.schedule.filter(el => el.ScheduleStartTime && el.day == day)[0]
            if(!schedule) return
            this.updateSchedule.day = schedule.day
            this.updateSchedule.scheduleStartTime = `${(('0' + Math.floor(schedule.ScheduleStartTime / 3600000 ))).slice(-2)}:` +
                                                    `${(('0' + (schedule.ScheduleStartTime / 60000) % 60)).slice(-2)}`

            this.updateSchedule.scheduleEndTime = `${(('0' + Math.floor(schedule.ScheduleEndTime / 3600000 ))).slice(-2)}:` +
                                                    `${(('0' + (schedule.ScheduleEndTime / 60000) % 60)).slice(-2)}`
            this.updateSchedule.totalWorkTime = `${(('0' + Math.floor(schedule.TotalWorkTime / 3600000 ))).slice(-2)}:` +
                                                    `${(('0' + (schedule.TotalWorkTime / 60000) % 60)).slice(-2)}`
            this.updateSchedule.breakStart = `${(('0' + Math.floor(schedule.BreakStart / 3600000 ))).slice(-2)}:` +
                                                `${(('0' + (schedule.BreakStart / 60000) % 60)).slice(-2)}`
            this.updateSchedule.breakEnd = `${(('0' + Math.floor(schedule.BreakEnd / 3600000 ))).slice(-2)}:` +
                                                `${(('0' + (schedule.BreakEnd / 60000) % 60)).slice(-2)}`
            this.updateSchedule.totalBreakTime = `${(('0' + Math.floor(schedule.TotalBreakTime / 3600000 ))).slice(-2)}:` +
                                                `${(('0' + (schedule.TotalBreakTime / 60000) % 60)).slice(-2)}`
            this.popupVisible = true
        },
        rightClickHandler(e) {
            if(this.copiedDay == null) return
            this.showPaste = false
            var containerRect = this.$refs.content.getBoundingClientRect()
            var clickX = Math.floor(e.clientX - containerRect.x)
            var clickY = Math.floor(e.clientY - containerRect.y)
            var cellHeightPx = this.cellHeight / 0.0625
            var cellWidthPx = this.cellWidth / 0.0625
            var day = Math.floor(clickX / cellWidthPx)
            if(day < 0) return
            if(this.savedScheduleItems.find(el => el.day == day && el.ScheduleStartTime)) return
            this.showPaste = true
            this.pasteDay = day
            this.$refs.paste.style.left = `${clickX}px`
            this.$refs.paste.style.top = `${clickY}px`

        },
        copyHandler(schedule) {
            this.copiedDay = schedule.day
        },
        pasteHandler() {
            this.$emit('copy', {fromDay: this.copiedDay, toDay: this.pasteDay})
        },
        closePaste() {
            this.showPaste = false
        },
    },
    watch: {
        popupVisible(newValue) {
            if(newValue == false) {
                this.currentItem.startTime = ''
                this.currentItem.endTime = ''
                this.currentItem.day = ''
            }
        },
    },
    destroyed() {
        document.removeEventListener('click', this.closePaste)
    },
}
</script>

<style scoped lang="scss">
    .content {
        width: calc(100%);
        margin-left: 50 * $rem;
        position: relative;
        
        .row {
            height: 27 * $rem;
            display: flex;
            position: relative;

            &:last-of-type .cell{
                border-bottom: 2px solid #dfe1ef;
            }

            .cell {
                border-left: 2px solid #dfe1ef;
                cursor: pointer !important;
                background-color: #f7f7fe;

                &:first-of-type {
                    border-left: none;
                }

                &.selected {
                    background-color: black;
                }
            }

            .time {
                height: 100%;
                width: 50 * $rem;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0 * $rem;
                left: -50 * $rem;
                font-size: 14 * $rem;
                color: rgba($blue-primary, 0.6);
                background-color: #d0d2ec;
                font-weight: 300;
                -webkit-touch-callout: none;
                -webkit-user-drag: none;
                user-select: none;
            }
        }

        .schedule-item-multi {
           flex-direction: column;
        }
        .schedule-item-one {
            .start-time {
                position: relative;
                left: 0.938rem;
            }
            .end-time {
                position: relative;
                right: 0.938rem;
            }
           .start, .end {
               width: 100%;
               text-align: center;
           }
        }
        .schedule-item {
            position: absolute;
            background-color: #baf3e8;
            border-radius: 6 * $rem;
            z-index: 2;
            display: flex;            
            align-items: center;
            justify-content: space-between;
            padding: 12 * $rem 0;
            user-select: none;
            
            &:hover {
                .actions {
                    display: block
                }
            }

            .start-time, .end-time {
                color: rgba($blue-primary, 0.6);
                font-weight: 400;
                font-size: 16 * $rem;
            }

            .actions {
                display: none;
                color: rgba($blue-primary, 0.6);
                font-size: 20 * $rem;
                position: relative;
                left: 77px;
                top: -45px;
                padding-left: 1.25rem;
                
                i {
                    cursor: pointer;
                    display: block;
                    margin-top: 5px;
                    font-size: 18px;
                }

                span {
                    position: absolute;
                    bottom: -20 * $rem;
                    right: 3.125rem;
                    font-size: 13 * $rem;
                    transform: translateX(-50%);
                }
            }

            .title {
                position: absolute;
                font-weight: 300;
                color: #afafc6;
                font-size: 14 * $rem;

                &.start {
                    top: -25 * $rem;
                }

                &.end {
                    bottom: -25 * $rem;
                }
            }
        }

        .day-overlay {
            background-color: rgba(58, 58, 58, 0.294);
            position: absolute;
            top: 0;
            z-index: 10;
            opacity: 0;
            transition: opacity .3s ease-out;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            i {
                font-size: 44 * $rem;
                color: #363636;
                cursor: pointer;
                padding: 15 * $rem;
                background-color: #fff;
                border-radius: 50%;

                &.delete {
                    color: $red;
                }

                &.edit {
                    color: #616161;
                }

                &:hover {
                    filter: brightness(1.2);
                }
            }

            &.visible {
                opacity: 1;
                cursor: initial;
            }
        }

        .paste {
            position: absolute;
            font-size: 14 * $rem;
            background-color: #baf3e8;
            padding:  8 * $rem 10 * $rem;
            border-radius: 3 * $rem;
            font-weight: 400;
            color: rgba($blue-primary, 0.6);
            cursor: pointer;
            z-index: 5;
            animation: fade-down .3s ease-out;
        }

        @keyframes fade-down {
            0% {
                opacity: 0.5;
                transform: translateY(-10*$rem);
            }

            100% {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
</style>