<template>
  <div class="background">
    <div class="payroll-prepaid">
      <div class="ess-container">
        <div class="add" @click="popUpVisible(true), (create = true)">
          <span>Assign</span>
        </div>         
        <div class="filter-div">
          <date-range
            :initialFrom="fromDate"
            :initialTo="toDate"
            @from="(from) => (fromDate = from)"
            @to="(to) => (toDate = to)"
            class="date_range"
          />
        </div>

        <div class="filter-div">
          <custom-select
            class="input"
            title="Office"
            v-model="officesFilter"
            :options="officesListOptions"
            @clear="officesFilter = ''"
            :clearVisible="true"
          />
        </div>

        <div class="filter-div">
          <custom-select
            class="input"
            title="Department"
            v-model="departmentFilter"
            :options="departmentListOptions"
            @clear="departmentFilter = ''"
            :clearVisible="true"
          />
        </div>

        <div class="filter-div">
          <choose-persons
            :allPersons="employeeListOptions"
            @person-clicked="personClickHanlder"
            v-model="employeeFilter"
            :isOpened="true"                  
            title="Employee"
          />
        </div>

        <div class="filter-div">
          <custom-select
            class="input"
            title="Incentive Type"
            v-model="salaryFactorTypeFilter"
            :options="salaryFactorTypeTypesList"
            @clear="salaryFactorTypeFilter = ''"
            :clearVisible="true"
          />
        </div>
        
        <div class="filter-div">
          <custom-select
            class="input"
            title="Status"
            v-model="statusFilter"
            :options="statusListOptions"
            @clear="statusFilter = ''"
            :clearVisible="true"
          />
        </div>

        <div class="filter-wrapper">
          <div class="clear-all" @click="clearAllFilters">
            <span>Clear All</span>
          </div>
        </div>
      </div>
      <!-- end ess-container -->

      <div class="person-list">
        <table class="person-list-table">
          <thead class="table-head">
            <tr class="person-list-table-th">
              <th>#</th>
              <th>Name Surname</th>
              <th>Personal ID</th>
              <th>Basic Amount <img src="@/assets/payrollSortIcon.svg" :class="sortByAmount ? 'sortedImageAsc' : 'sortedImageDesc'" @click.stop="sortVisibleAmount()"/></th>
              <th>Incentive Type</th>
              <th>Comment</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody class="table-body">
            <template v-for="prePaidList in prePaidSalariesList">
              <tr class="person-list-table-td" :key="prePaidList.ID">
                <td>{{ prePaidList.PersonID }}</td>
                <td>{{ prePaidList.FirstName }} {{ prePaidList.LastName }}</td>
                <td>{{ prePaidList.PersonalNumber }}</td>
                <td v-if="prePaidList.GrossAmount">
                  {{ prePaidList.GrossAmount }}
                  <span>{{ prePaidList.CurrencyCode }}</span>
                </td>
                <td v-else>-</td>
                <td>{{ prePaidList.SalaryFactorTypeTypeName }}</td>
                <td>{{ prePaidList.Comment }}</td>
                <td>
                  <span class="person-list-table-status" :class="prePaidList.StatusName">{{
                    prePaidList.StatusName
                  }}</span>
                </td>
                <td>
                  <img
                    v-if="prePaidList.canEdit"
                    src="@/assets/icons/edit.svg"
                    alt="hr"
                    @click="
                      editprePaidSalary(
                        prePaidList.ID,
                        prePaidList.SalaryFactorTypeTypeID,
                        prePaidList.SalaryFactorTypeTypeName,
                      )
                    "
                  />
                  <img
                    v-if="prePaidList.canDelete"
                    src="@/assets/icons/close.svg"
                    alt="hr"
                    @click="
                      deleteprePaidSalary(
                        prePaidList.ID,
                        prePaidList.SalaryFactorTypeTypeName,
                      )
                    "
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!-- end person-list -->

      <custom-snackbar
        v-if="snackbarVisible"
        :purpose="snackbarPurpose"
        :text="snackbarText"
        @close="snackbarVisible = false"
      />
      <form class="assign-form" @submit.prevent="CreatePrePaidSalary">
        <custom-popup
          :visible="popupVisible"
          @close="popUpVisible(false)"
          :title="create ? 'Assign' : 'Edit'"
          :flexibleSize="false"
        >
          <div class="bullet-step">
            <span :class="{ 'bullet-step-active': showUserInfo }"></span>
            <span
              :class="{ 'bullet-step-active': hasTypes || chooseHasFactory || needOnedate }"
            ></span>
            <span v-if="!needOnedate" :class="{ 'bullet-step-active': chooseHasFactory }"></span>
          </div>
          <!-- end bullet-step -->

          <div class="flex-2">
            <div class="form-field">
              <search-input
                  :options="employeeListOptions"
                  title="Assigning debtor"
                  :isRequired="true"
                  @clear="newPrepaid.employeeSelect=''"
                  v-model="newPrepaid.employeeSelect"
              />
            </div>

            <div class="form-field" v-if="!create && (this.isSuperAdmin || this.isCfo)">
              <custom-select
                class="input"
                title="Status"
                v-model="newPrepaid.status"
                :options="statusListOptions"
                @clear="newPrepaid.status = ''"
                :clearVisible="true"
              />
            </div>
          </div>
          <!-- end flex-2 -->

          <div v-if="showUserInfo">
            <div class="user_info">
              <div class="user_info_img">
                <img
                  :src="
                    this.$store.state.getAvatar + this.newPrepaid.employeeSelect
                  "
                />
              </div>
              <div class="user_info_text">
                <div class="user_info_name">
                  {{ salaryList.FirstName.charAt(0).toUpperCase() + salaryList.FirstName.slice(1) }} 
                  {{ salaryList.LastName.charAt(0).toUpperCase() + salaryList.LastName.slice(1) }} 
                </div>
                <div class="user_info_job">{{ salaryList.JobName}} </div>
              </div>
            </div>
            <div class="user_info_details">
              <div class="user_info_details_box">
                <div>
                  <span class="boxtitle">Salary (Basic):</span
                  ><span class="value"
                    ><span>{{ salaryList.BasicSalary }}</span
                    >{{ salaryList.CurrencyCode }}</span
                  >
                </div>
                <div>
                  <span class="boxtitle">Job start date:</span
                  ><span class="value">{{
                    salaryList.JobStartDate
                      ? convertDate(salaryList.JobStartDate)
                      : "-"
                  }}</span>
                </div>
              </div>
              <div class="user_info_details_box">
                <div>
                  <span class="boxtitle">Salary Start Date:</span
                  ><span class="value">{{
                    salaryList.SalaryStartDate
                      ? convertDate(salaryList.SalaryStartDate)
                      : "-"
                  }}</span>
                </div>
                <div>
                  <span class="boxtitle">Salary End Date:</span
                  ><span class="value">{{
                    salaryList.SalaryEndDate ? convertDate(salaryList.SalaryEndDate) : "-"
                  }}</span>
                </div>
              </div>
            </div>
            <!-- end user_info_details -->
          </div>

          <div class="flex-2">
            <div class="form-field">
              <custom-select
                class="input"
                title="Factor type"
                :isRequired="true"
                :disabled="!create"
                v-model="newPrepaid.salaryFactorTypeTypes"
                :options="salaryFactorTypeTypesList"
                @clear="newPrepaid.salaryFactorTypeTypes = ''"
                :clearVisible="true"
              />
            </div>

            <div class="form-field" v-if="hasTypes">
              <custom-select
                class="input"
                :title="salaryFactorTypesTitle"
                :isRequired="true"
                v-model="newPrepaid.salaryFactorTypes"
                :options="salaryFactorTypesList"
                @clear="newPrepaid.salaryFactorTypes = ''"
                :clearVisible="true"
              />
            </div>
          </div>
          <!-- end flex -->

          <div v-if="(hasTypes && chooseHasFactory) || needOnedate">
            <div class="static-info-content" v-if="!needOnedate">
              <div class="form-field-value">
                <div>Company</div>
                <div>{{ company ? company : "-" }}</div>
              </div>
              <div class="form-field-value">
                <div>Assign type</div>
                <div>{{ factortype ? factortype : "-" }}</div>
              </div>
            </div>
            <div class="flex-2 margin-top">
              <div class="form-field">
                <custom-input
                  class="input"
                  v-model="newPrepaid.companyCost"
                  title="Company cost"
                  :disabled="disableStatus.DisableCompanyCost"
                  @clear="newPrepaid.companyCost = ''"
                />
              </div>
              <div class="form-field" v-if="!needOnedate">
                <custom-input
                  class="input"
                  v-model="newPrepaid.salaryCost"
                  title="Employee cost"
                  :disabled="disableStatus.DisableSalaryCost"
                  @clear="newPrepaid.salaryCost = ''"
                />
              </div>
              <div class="form-field" v-if="needOnedate">
                <date-picker
                  class="date"
                  title="Transfer date"
                  :isRequired="true"
                  v-model="newPrepaid.StartDate"
                  @clear="newPrepaid.StartDate = ''"
                />
              </div>
            </div>
            <!-- end flex -->

            <div class="flex-3" v-if="!needOnedate">
              <div class="form-field">
                <custom-input
                  class="input"
                  v-model="newPrepaid.payDay"
                  title="Pay day"
                  :disabled="disableStatus.DisablePayDay"
                  :isRequired="!disableStatus.DisablePayDay"
                  @clear="newPrepaid.payDay = ''"
                />
              </div>
              <div class="form-field">
                <date-picker
                  class="date"
                  title="Factor start date"
                  :isRequired="!disableStatus.DisableFactorStartDate"
                  :disabled="disableStatus.DisableFactorStartDate"
                  v-model="newPrepaid.StartDate"
                  @clear="newPrepaid.StartDate = ''"
                />
              </div>
              <div class="form-field">
                <date-picker
                  class="date"
                  title="Factor end date"
                  :disabled="disableStatus.DisableFactorEndDate"
                  v-model="newPrepaid.EndDate"
                  @clear="newPrepaid.EndDate = ''"
                />
              </div>
            </div>
            <!-- end flex-3 -->            

            <div class="flex">
              <div class="form-field">
                <custom-text-area
                  title="Description"
                  :rows="5"
                  v-model="newPrepaid.note"
                />
              </div>
            </div>
            <div class="save-button-div">
              <button class="save-button">Save</button>
            </div>
          </div>
        </custom-popup>
      </form>
    </div>

    <delete-item
      @delete="deleteRow"
      @close="deletePopupVisible = false"
      v-if="deletePopupVisible"
      :text="`Would you like to delete the preparation?`"
    />
  </div>
</template>   
<script>
import CustomTextArea from "@/components/ReusableComponents/CustomTextArea/CustomTextArea.vue";
import { getDateString, CETToTimestamp } from "@/Helpers/HelperFunctions";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup.vue";
import SearchInput from '@/components/ReusableComponents/SearchInput/SearchInput.vue'
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue";
import choosePersons from "@/components/ReusableComponents/ChoosePersons/choosePersons.vue";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue";
import DateRange from "@/components/ReusableComponents/DateRange/DateRange";
import DeleteItem from "@/components/ReusableComponents/DeleteItem/DeleteItem.vue";
import axios from "@/Helpers/AxiosInstance.js";

export default {
  components: {
    CustomTextArea,
    CustomSelect,
    DatePicker,
    DateRange,
    CustomPopup,
    SearchInput,
    CustomInput,
    choosePersons,
    CustomSnackbar,
    DeleteItem,
  },
  data() {
    return {
      create: false,
      popupVisible: false,
      deletePopupVisible: false,
      snackbarVisible: false,
      snackbarPurpose: "error",
      snackbarText: "",
      searchCompany: "",

      employeeFilter: [],
      employeeListOptions: [],
      fromDate: null,
      toDate: null,
      statusFilter: "",
      statusListOptions: [],
      searchFilter: "",
      officesListOptions: [],
      officesFilter: '',
      departmentListOptions: [],
      departmentFilter: '',
      salaryFactorTypeFilter: '',
      showTypeTypeName: null,
      sortByAmount: false,
      sortOrder: '',

      showUserInfo: false,
      serviceName: "",
      newPrepaid: {
        employeeSelect: null,
        status: null,
        salaryFactorTypeTypes: null,
        salaryFactorTypes: null,
        companyCost: null,
        salaryCost: null,
        payDay: null,
        StartDate: null,
        EndDate: null,
        note: "",
      },
      disableStatus: {
        DisableCompanyCost: false,
        DisableSalaryCost: false,
        DisablePayDay: false,
        DisableFactorStartDate: false,
        DisableFactorEndDate: false,
      },
      company: "",
      factortype: "",
      salaryList: [],
      prePaidSalariesList: [],
      salaryFactorTypeTypesList: [],
      hasTypes: false,
      needOnedate: false,
      chooseHasFactory: false,
      salaryFactorTypesList: [],
      salaryFactorTypesListById: [],
      amountTitle: "",
      selectedFactory: "",
      selectedFactoryType: "",
      selectedFactoryTypeName: "",
      salaryFactorTypesTitle: "Bonus type",
    };
  },
  created() {
    this.getPrePaidSalaries()
    this.getEmployees()
    this.getSalaryFactorTypeTypes()
    this.getSalaryFactorStatuses()
    this.getOffices()
    this.getDepartments()
  },
  computed: {
    isSuperAdmin() {
      return this.$store.getters.hasRole("Super Admin");
    },
    isCfo() {
      return this.$store.getters.hasRole("CFO");
    },
  },
  methods: {
    personClickHanlder(el) {
      el.selected = !el.selected;
      const exist = this.employeeFilter.includes(el.value);
      if (exist) {
        this.employeeFilter = this.employeeFilter.filter(
          (element) => element != el.value
        );
      } else {
        this.employeeFilter.push(el.value);
      }
    },
    popUpVisible(status) {
      this.newPrepaid = {
        employeeSelect: null,
        status: null,
        salaryFactorTypeTypes: null,
        salaryFactorTypes: null,
        companyCost: null,
        salaryCost: null,
        payDay: null,
        StartDate: null,
        EndDate: null,
        note: "",
      }
      this.hasTypes = false
      this.needOnedate = false
      this.showUserInfo = false
      this.chooseHasFactory = false
      this.popupVisible = status
    },
    getPrePaidSalaries() {
      let persons = []
      if(this.employeeFilter.length > 0){
          persons = this.employeeFilter.map((el) => ({
            personID:  el,
          }));
      }
      let object = {
        FromDate: this.fromDate,
        ToDate: this.toDate,
        OfficeID: this.officesFilter,
        DepartmentID: this.departmentFilter,
        Persons: persons,
        SalaryFactorTypeType: this.showTypeTypeName,
        Status: this.statusFilter,
        SortByAmount: this.sortByAmount,
        SortOrder: this.sortByAmount,
      }
      axios
        .post(
          `salary/GetPersonsCompanyInnerFactors`, object
        )
        .then((response) => {
          this.prePaidSalariesList = response.data.Value;
          if (this.prePaidSalariesList) {
            this.prePaidSalariesList.forEach((el) => {
              this.$set(el, "canEdit", el.CanUpdate);
              this.$set(el, "canDelete", el.CanUpdate);
            });
          }
        });
    },
    CreatePrePaidSalary() {
      let objectData = {
        personID: this.newPrepaid.employeeSelect,
        salaryID: null,
        salaryFactorTypeID: this.newPrepaid.salaryFactorTypes,
        companyCost: this.newPrepaid.companyCost,
        salaryCost: this.newPrepaid.salaryCost,
        payDay: this.newPrepaid.payDay,
        startDate: this.newPrepaid.StartDate,
        endDate: this.newPrepaid.EndDate,
        purpose: this.newPrepaid.note,
      };

      this.serviceName = "salary/CreateSalaryFactors";
      if (!this.create) {
        objectData.SalaryFactorID = this.selectedFactory
        objectData.SalaryFactorTypeType =this.selectedFactoryTypeName
        objectData.status = this.newPrepaid.status
        this.serviceName = "salary/UpdateSalaryFactor"
      }

      axios.post(this.serviceName, objectData).then((resp) => {
        if (!resp.data.ErrorMsg) {
          this.popupVisible = false;
          (this.newPrepaid = {
            employeeSelect: null,
            status: null,
            salaryFactorTypeTypes: null,
            salaryFactorTypes: null,
            companyCost: null,
            salaryCost: null,
            payDay: null,
            StartDate: null,
            EndDate: null,
            note: "",
          }),
            this.getPrePaidSalaries();
        } else {
          this.snackbarVisible = true;
          this.snackbarText = resp.data.ErrorMsg;
          this.snackbarPurpose = "error";
        }
      });
    },
    editprePaidSalary(id, factorTypeID, SalaryFactorTypeTypeName) {
      this.selectedFactory = id
      this.selectedFactoryType = factorTypeID
      this.selectedFactoryTypeName = SalaryFactorTypeTypeName
      let object = {
         ID: id,
         SalaryFactorTypeType: SalaryFactorTypeTypeName,
         Persons: [],
      }
      axios
        .post(
          `salary/GetPersonsCompanyInnerFactors`, object
        )
        .then((response) => {
          this.company = response.data.Value[0].DestinationTypeName;
          this.factortype = response.data.Value[0].CalculationTypeName;
          this.newPrepaid = {
            employeeSelect: response.data.Value[0].PersonID,
            status: response.data.Value[0].StatusID,
            salaryFactorTypeTypes: response.data.Value[0].SalaryFactorTypeTypeID,
            salaryFactorTypes: response.data.Value[0].SalaryFactorTypeID,
            companyCost: response.data.Value[0].CompanyCost,
            salaryCost: response.data.Value[0].SalaryCost,
            payDay: response.data.Value[0].PayDay,
            StartDate: response.data.Value[0].StartDate,
            EndDate: response.data.Value[0].EndDate,
            note: response.data.Value[0].Comment,
          },
          this.create = false
          this.popupVisible = true;
        });
    },
    deleteprePaidSalary (id, typeNaem)
    {
      this.selectedfactory = id;
      this.selectedFactoryTypeName = typeNaem
      this.deletePopupVisible = true;
    },
    deleteRow() {
      var data = {
        SalaryFactorID: this.selectedfactory,
        SalaryFactorTypeType: this.selectedFactoryTypeName,
        delete: true,
      };   
      axios.post("salary/UpdateSalaryFactor", data).then((resp) => {
          this.deletePopupVisible = false;
          if (!resp.data.ErrorMsg) {
            this.snackbarText = "Deleted Successfully!";
            this.snackbarPurpose = "success";            
            this.snackbarVisible = true;
            this.getPrePaidSalaries();
          } else {
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
            this.snackbarPurpose = "error";
          }
        })
        .catch(() => {
          this.snackbarVisible = true;
          this.snackbarText = "Error";
          this.snackbarPurpose = "error";
        });
    },
    getPersonInfo() {
      axios
        .get(
          `salary/GetPersonsCurrentSalary?PersonID=${this.newPrepaid.employeeSelect}`
        )
        .then((resp) => {
          this.salaryList = resp.data.Value[0];
          this.showUserInfo = true;
        });
    },
    getEmployees() {
      axios.get("salary/GetPersonsWithSalaryAssigned").then((resp) => {
        this.employeeListOptions = resp.data.Value.map((el) => ({
          title: el.FirstName + " " + el.LastName,
          value: el.ID,
        }));
      });
    },
    getSalaryFactorTypeTypes() {
      axios
        .get("salary/GetSalaryFactorTypeTypes?WithoutOther=true")
        .then((resp) => {
          this.salaryFactorTypeTypesList = resp.data.Value.map((el) => ({
            title: el.Name,
            value: el.ID,
            needOnedate: el.NeedOneDateParam,
            hasTypes: el.HasTypes,
          }));
        });
    },
    getSalaryFactorTypes() {
      axios
        .get(
          `salary/GetSalaryFactorTypes?SalaryFactorTypeTypeID=${this.newPrepaid.salaryFactorTypeTypes}`
        )
        .then((resp) => {
          this.salaryFactorTypesList = resp.data.Value.FactorsWithPersons.map((el) => ({
            title: el.Name,
            value: el.ID,
          }));
        });
    },
    async getSalaryFactorTypesById() {
      await axios
        .get(
          `salary/GetSalaryFactorTypes?ID=${this.newPrepaid.salaryFactorTypes}`
        )
        .then((resp) => {
          this.salaryFactorTypesListById = resp.data.Value.FactorsWithPersons[0]
          this.company = this.salaryFactorTypesListById.DestinationTypeName;
          this.factortype = this.salaryFactorTypesListById.SalaryFactorCalculationTypeName;
          this.newPrepaid.companyCost = this.salaryFactorTypesListById.CompanyCost;
          this.newPrepaid.salaryCost = this.salaryFactorTypesListById.SalaryCost;
          this.newPrepaid.payDay = this.salaryFactorTypesListById.PayDay;
          this.newPrepaid.StartDate = this.salaryFactorTypesListById.StartDate;
          this.newPrepaid.EndDate = this.salaryFactorTypesListById.EndDate;

          this.disableStatus.DisableCompanyCost = this.salaryFactorTypesListById.DisableCompanyCost;
          this.disableStatus.DisableSalaryCost = this.salaryFactorTypesListById.DisableSalaryCost;
          this.disableStatus.DisablePayDay = this.salaryFactorTypesListById.DisablePayDay;
          this.disableStatus.DisableFactorStartDate = this.salaryFactorTypesListById.DisableFactorStartDate;
          this.disableStatus.DisableFactorEndDate = this.salaryFactorTypesListById.DisableFactorEndDate;
        });
    },
    getSalaryFactorStatuses() {
      axios.get(`salary/GetSalaryFactorStatuses`).then((resp) => {
        this.statusListOptions = resp.data.Value.map((el) => ({
          title: el.StatusName,
          value: el.ID,
        }));
      });
    },
    getOffices() {
      axios.get(`Company/GetOffices`).then((resp) => {
        this.officesListOptions = resp.data.Value.map((el) => ({
          title:  el.Name,
          value: el.ID,
        }));     
      });
    },
    getDepartments() {
      axios.get(`departments/GetDepartments`).then((resp) => {
        this.departmentListOptions = resp.data.Value.map((el) => ({
          title:  el.Name,
          value: el.ID,
        }));     
      });
    },
    sortVisibleAmount() {
      this.sortByAmount = !this.sortByAmount
      this.sortOrder = this.sortByAmount
      if(this.sortByAmount) this.sortOrder = !this.sortByAmount
      this.getPrePaidSalaries()
    },
    clearAllFilters() {
      this.fromDate = null
      this.toDate = null
      this.officesFilter = ''
      this.departmentFilter = ''
      this.employeeFilter = []
      this.salaryFactorTypeFilter = ''
      this.showTypeTypeName = null
      this.statusFilter = ''
      this.getPrePaidSalaries()
    },
    convertDate(date) {
      return getDateString(CETToTimestamp(date), "dd MMM yyyy");
    },
  },
  watch: {   
    fromDate(to, from) {
      this.getPrePaidSalaries()
    },
    
    officesFilter(to, from) {
      this.officesFilter = to
      this.getPrePaidSalaries()
    },
    departmentFilter(to, from) {
      this.departmentFilter = to
      this.getPrePaidSalaries()
    },
    employeeFilter(to, from) {
      this.employeeFilter = to
      this.getPrePaidSalaries()
    },
    salaryFactorTypeFilter(to, from) {
      this.showTypeTypeName = null
      if(to) {
        this.showTypeTypeName = this.salaryFactorTypeTypesList.filter(
          (el) => el.value == to
        )[0].title;
      }
      this.salaryFactorTypeFilter = to
      this.getPrePaidSalaries()
    },
    statusFilter(to, from) {
      this.statusFilter = to
      this.getPrePaidSalaries()
    },    
    "newPrepaid.salaryFactorTypeTypes": function (to, from) {
      if (to) {
        this.newPrepaid.salaryFactorTypeTypes = to;
        let showTypeStatus = this.salaryFactorTypeTypesList.filter(
          (el) => el.value == to
        );
        this.salaryFactorTypesTitle = showTypeStatus[0].title+" type"
        this.hasTypes = showTypeStatus[0].hasTypes
        this.needOnedate = showTypeStatus[0].needOnedate
        this.getSalaryFactorTypes()
      }
    },
    "newPrepaid.employeeSelect": function (to, from) {
      if (to) {
        this.newPrepaid.employeeSelect = to;
        this.getPersonInfo();
      }
    },
    "newPrepaid.salaryFactorTypes": function (to, from) {  
      this.chooseHasFactory = true 
      if ((to && this.create) || (to && from && !this.create)) {
        this.newPrepaid.salaryFactorTypes = to               
        this.getSalaryFactorTypesById()
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.payroll-prepaid {
  .ess-container {
    display: flex;
    justify-content: end;
    .filter-div {
      width: 12.938rem;
      margin: 1.875rem 1.125rem 0.0625rem 0;
      margin-bottom: 0.9375rem;
      label {
        width: 100%;
        ::v-deep .el-input {
          width: 100%;
        }
      }  
      ::v-deep .container {
        background: #ffffff;
        width: 100%;
      }    
    }
    .filter-wrapper {
      margin: 1.875rem 0 0.9375rem 0;
      &:last-child {
        width: 6.25rem;
      }
      .clear-all {
        cursor: pointer;
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        padding-left: 0.625rem;
        color: #959bc9;
        border-left: 0.0625rem solid #dcdde6;
      }
    }
    & > .add {
      height: 2.375rem;
      margin: 1.875rem 1.125rem 0.0625rem 0;
      padding: 0.5rem 2rem 0rem;
      border-radius: 0.1875rem;
      background-color: #3f46bf;
      cursor: pointer;
      span {
        -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
        font-size: 0.75rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #ededfc;
      }
    }
    .search {
      position: relative;
      input {
        width: 12.5rem;
        height: 2.375rem;
        background-color: #fff;
        border: 0;
        outline: none;
        border-radius: 0.375rem;
        font-weight: 300;
        font-size: 0.875rem;
        color: #9696b9;
        padding: 0 2.1875rem 0 0.9375rem;
      }
      img {
        position: absolute;
        height: 1.3125rem;
        width: 1.3125rem;
        right: 0.75rem;
        top: 0.5rem;
      }
    }
  }
  // end header
  .assign-form {
    .bullet-step {
      display: flex;
      position: absolute;
      top: 2.0625rem;
      left: 45.5%;
      span {
        width: 0.625rem;
        height: 0.625rem;
        display: block;
        -webkit-border-radius: 0.625rem;
        -moz-border-radius: 0.625rem;
        border-radius: 0.625rem;
        background: #959bc9;
        margin-left: 0.625rem;
      }
      .bullet-step-active {
        background: #6956d6;
      }
    }
    .user_info {
      margin: 1.875rem 0.3125rem 0 0;
      display: flex;

      .user_info_img {
        width: 3.75rem;
        height: 3.75rem;
        background-color: rgba(40, 53, 147, 0.5);
        border-radius: 50%;
        cursor: pointer;
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border: solid 0.0625rem #ededfc;
        img {
           width: 3.75rem;
        }
      }
      .user_info_text {
        margin: 0.75rem 0 0.375rem 0.75rem;
      }
      .user_info_name {
        -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
        font-size: 1rem;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        color: #283593;
      }
      .user_info_job {
        -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
        font-size: 0.75rem;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #9696b9;
      }
    }
    .user_info_details {
      width: 100%;
      margin-top: 0.75rem;
      display: flex;
      justify-content: space-between;
      padding-bottom: 1.25rem;
      border-bottom: solid 0.0625rem #ededfc;
      .user_info_details_box {
        width: 50%;
        text-align: left;
        div {
          margin-top: 0.4375rem;
          .boxtitle {
            width: 33%;
            display: inline-block;
            -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
            font-size: 0.875rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal;
            color: #959bc9;
          }
          .value {
            width: 33%;
            display: inline-block;
            -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
            font-size: 0.875rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal;
            color: #283593;
            span {
              color: #c44f7d;
              display: inline-block;
              padding-right: 0.3125rem;
            }
          }
        }
      }
    }
    .flex {
      margin-top: 1.25rem;
    }
    .flex-2 {
      display: flex;
      justify-content: space-between;
      margin-top: 1.25rem;
      .form-field {
        width: 48%;
      }
      ::v-deep .el-input {
        width: 100%;
      }
    }
    .flex-3 {
      display: flex;
      justify-content: space-between;
      margin-top: 1.25rem;
      .form-field {
        width: 30%;
      }
      ::v-deep .el-input {
        width: 100%;
      }
    }
    .static-info-content {
      width: 48%;
      display: flex;
      justify-content: space-between;
      margin-top: 1.25rem;
      .form-field-value {
        div:first-child {
          font-size: 0.75rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: left;
          color: #959bc9;
        }
        div:last-child {
          font-size: 0.875rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.36;
          letter-spacing: normal;
          text-align: left;
          color: #283593;
        }
      }
    }
    .form-field {
      width: 100%;
      label {
        width: 100%;
      }
    }
    .save-button-div {
      width: 100%;
      text-align: right;
    }
    .save-button {
      background-color: #6956d6;
      color: #fcfcfc;
      height: 3rem;
      width: 6.25rem;
      border-radius: 0.375rem;
      font-size: 1rem;
      cursor: pointer;
      margin-top: 3.75rem;
    }
    ::v-deep .popup {
      height: auto;
      padding-bottom: 1.875rem;
      position: relative;
      overflow: visible;
    }
    .assign-button-div {
      width: 100%;
      text-align: right;
    }
    .assign-button {
      background-color: #6956d6;
      color: #fcfcfc;
      height: 3rem;
      width: 6.25rem;
      border-radius: 0.375rem;
      font-size: 1rem;
      cursor: pointer;
      margin-top: 3.75rem;
    }
  }
  // end pop up

  .person-list {
    width: calc(100% - 2.5rem);
    max-height: 100%;
    overflow: auto;
    margin-left: 1.25rem;
    .person-list-table {
      width: 100%;
      table-layout: fixed;
      position: relative;
      border-collapse: collapse;
      .person-list-table-th {
        width: 100%;
        height: 2.5rem;
        th:first-child {
          width: 5%;
          -webkit-border-top-left-radius: 0.1875rem;
          -webkit-border-bottom-left-radius: 0.1875rem;
          -moz-border-radius-topleft: 0.1875rem;
          -moz-border-radius-bottomleft: 0.1875rem;
          border-top-left-radius: 0.1875rem;
          border-bottom-left-radius: 0.1875rem;
        }
        th:last-child {
          width: 5%;
          -webkit-border-top-right-radius: 0.1875rem;
          -webkit-border-bottom-right-radius: 0.1875rem;
          -moz-border-radius-topright: 0.1875rem;
          -moz-border-radius-bottomright: 0.1875rem;
          border-top-right-radius: 0.1875rem;
          border-bottom-right-radius: 0.1875rem;
        }
        th {
          font-size: 0.75rem;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #ededfc;
          background-color: #6956d6;
          padding-left: 0.625rem;
        }
        img {
          display: inline-block;
          margin-left: 0.3125rem;
          cursor: pointer;
        }
        .sortedImageAsc {
          transform: rotate(180deg); 
        }
        .sortedImageDesc {           
          image-orientation: none;       
        }
      }
      tr:nth-child(odd) {
        background-color: #dfe0f4;
      }
      .table-head {
        width: 100%;
        display: table;
        table-layout: fixed;
      }
      .table-body {
        height: 100%;
        display: block;
        overflow-y: auto;
        max-height: 44.0625rem;
      }
      .person-list-table-td {
        width: 100%;
        display: table;
        table-layout: fixed;
        td:first-child, td:last-child{
          width: 5%;
        }
        td {
          height: 2.5rem;
          font-size: 0.875rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #283593;
          padding: 0.625rem 0 0.625rem 0.625rem;
          span:not(.person-list-table-status) {
            color: #959bc9;
          }
          .person-list-table-status {            
            font-size: 0.875rem;
          }
          .person-list-table-status.Pending {
             color: #FFA346;
          }
          .person-list-table-status.Approve  {
             color: #1DE9B6;
          }
          .person-list-table-status.Reject  {
             color: #FF5F58;
          }
          img {
            width: 1.6875rem;
            cursor: pointer;
          }
        }
      }
    }
  }
  // end list
}
</style>