<template>
  <div class="background">
    <section class="terms-section">
      <span class="user-details"> {{ this.personName }}</span>
      <p class="assigned-terms">Assigned vacation terms</p>
      <vacation-terms-table
        @active-edit="activeEdit"
        @checked="checkedEdit"
        @cancel="cancel"
        :profileTerms="true"
        :data="personRestrictions"
        :canEdition="true"
      />
    </section>
    <section class="requests-section">
      <p>Requests</p>

      <vacation-user-table
        :requests="personVacation"
        :profileVacation="true"
        @active-delete="deleteVacation"
        @active-edit="editVacation"
        :canEdit="canEdit"
        @checked="checkedEdition"
        @cancel="cancelEdition"
      />
    </section>
  </div>
</template>

<script>
import VacationTermsTable from "@/components/VacationAdmin/VacationTermsTable";
import axios from "@/Helpers/AxiosInstance.js";
import VacationUserTable from "@/components/vacationUser/VacationUserTable.vue";
import {
  getDateString,
  CETToTimestamp,
  timestampToCET,
} from "@/Helpers/HelperFunctions.js";

export default {
  components: {
    VacationTermsTable,
    VacationUserTable,
  },
  created() {
    this.getPersVacRestrictions();
    this.getPersonVacationInfo();
    this.getPersonName();
  },
  data() {
    return {
      personName: "",
      personRestrictions: [],
      personVacation: [],
      canEdit: false,
    };
  },
  computed: {
    columns() {
      return [
        {
          title: "From Date",
          key: "FromDate",
          customTemplate: (item, val) => getDateString(val, "dd MMM yyyy"),
        },
        {
          title: "To Date",
          key: "ToDate",
          customTemplate: (item, val) => getDateString(val, "dd MMM yyyy"),
        },
        {
          title: "Days",
          key: "TotalDays",
        },
        {
          title: "Vacation Type",
          key: "VacationType",
          customTemplate: (item, val) => {
            if (val == 1) {
              return "Paid";
            } else if (val == 2) {
              return "Not Paid";
            } else if (val == 3) {
              return "Day Off";
            } else if (val == 4) {
              return "Sick Days";
            } else {
              return "";
            }
          },
        },
        {
          title: "Last Vacation",
          key: "LastVacationDate",
          customTemplate: (item, val) =>
            val ? this.getDurationString(val, item.FromDate) : "-",
        },
        {
          title: "Status",
          Key: "Status",
          customTemplate: (item) => {
            if (item.Approved == true) {
              return "Approved";
            } else if (item.Approved == false) {
              return "Rejected";
            } else {
              return "Pending";
            }
          },
        },
      ];
    },
  },
  methods: {
    getPersVacRestrictions(id) {
      id = this.$route.params.id;
      axios
        .get(`vacations/GetPersonVacationRestrictions?PersonID=${id}`)
        .then((resp) => {
          this.personRestrictions = resp.data.Value.PersonRestrictions;
          this.personRestrictions.forEach((el) => {
            this.$set(el, "canEdit", false);
          });
        });
    },
    getDurationString(start) {
      var duration = Date.now() - start;
      var fullDays = parseInt(duration / (24 * 3600000));
      var days = fullDays % 30;
      var months = parseInt(fullDays / 30);
      var res = "";
      if (months) {
        if (res) res += ", ";
        res += `${months} month`;
      }
      if (days) {
        if (res) res += " & ";
        res += `${days} days`;
      }
      return res;
    },
    checkedEdition(vacation, newVacation) {
      axios
        .post("vacations/UpdateVacation", {
          VacationID: vacation.VacationID,
          Deleted: null,
          FromDate: newVacation.newVacationFromDate
            ? newVacation.newVacationFromDate
            : null,
          ToDate: newVacation.newVacationToDate
            ? newVacation.newVacationToDate
            : null,
          Type: newVacation.newVacationType
            ? newVacation.newVacationType
            : null,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.getPersonVacationInfo(this.$route.query.userI);
            vacation.canEdit = false;
            (newVacation.newVacationFromDate = ""),
              (newVacation.newVacationToDate = ""),
              (newVacation.newVacationType = "");
          }
        });
    },
    getPersonName(id) {
      id = this.$route.params.id;
      axios.get(`Person/GetPersonsDetails?Person=${id}`).then((resp) => {
        let firstName = resp.data.Value.FirstName;
        let lastName = resp.data.Value.LastName;
        let UpperCaseName =
          firstName.charAt(0).toUpperCase() + firstName.slice(1);
        let UpperCaseLastName =
          lastName.charAt(0).toUpperCase() + lastName.slice(1);
        this.personName = `${UpperCaseName} ${UpperCaseLastName}`;
      });
    },
    getPersonVacationInfo(id) {
      id = this.$route.params.id;

      axios
        .get(`vacations/GetPersonsVacations?PersonID=${id}`)
        .then((response) => {
          this.personVacation = response.data.Value;
          this.personVacation.forEach((el) => {
            this.$set(el, "commentVisible", false);
            this.$set(el, "canEdit", false);
          });
        });
    },
    activeEdit(el) {
      el.canEdit = true;
    },
    cancel(el) {
      el.canEdit = false;
    },
    editVacation(vacation) {
      vacation.canEdit = true;
    },
    cancelEdition(vacation) {
      vacation.canEdit = false;
    },
    deleteVacation(vacation) {
      this.$confirm("Are you sure you want to delete request?").then(() => {
        axios
          .post("vacations/UpdateVacation", {
            VacationID: vacation.VacationID,
            Deleted: true,
          })
          .then((resp) => {
            this.getData();
          });
      });
    },
    checkedEdit(y, el, newType) {
      const arrayToSend = el[0].Restrictions.map((x) => {
        let quantity;

        if (x.VacationTypeName == "Paid") {
          quantity = newType.paid;
        } else if (x.VacationTypeName == "NotPaid") {
          quantity = newType.notPaid;
        } else if (x.VacationTypeName == "DayOff") {
          quantity = newType.dayOff;
        } else if (x.VacationTypeName == "SickDays") {
          quantity = newType.sickDays;
        } else if (x.VacationTypeName == "Maternity Leave") {
          quantity = newType.maternityLeave;
        } else {
          quantity = null;
        }

        return {
          RestrictionID: x.VacationRestrictionID,
          Year: newType.Year === "" ? y.Year : Math.abs(newType.Year),
          Quantity:
            Math.abs(quantity) === 0 ? el.AnnualQuantity : Math.abs(quantity),
        };
      });

      axios
        .post("vacations/UpdatePersonVacationRestriction", {
          TVP: arrayToSend,
        })
        .then((resp) => {
          this.getPersVacRestrictions(this.$route.params.id);
          y.canEdit = false;
          (newType.notPaid = ""),
            (newType.paid = ""),
            (newType.dayOff = ""),
            (newType.sickDays = ""),
            (newType.maternityLeave = "");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.background {
  padding: 35px 60px 0 60px;
  padding-top: 35px;
}
.requests-section {
  margin-top: 130px;
  height: 54%;
  ::v-deep .grid .table-wrapper.pagination {
    height: 100%;
    min-height: 0;
  }
  p {
    color: $blue-primary;
    font-size: 18px;
    margin-top: 30px;
    // margin-bottom: 20px;
    // font-weight: 500;
  }
  ::v-deep table tbody tr {
    height: 60px;
    td:nth-child(1) {
      padding-left: 27px;
      width: 170px;
    }
  }
  ::v-deep table thead tr {
    height: 60px;
    th:nth-child(1) {
      padding-left: 27px;
    }
  }
  // ::v-deep .grid .table-helper table thead th:nth-child(1) {
  //     padding-left: 77px;
  // }
}
.terms-section {
  p {
    color: $blue-primary;
    font-size: 18px;
    margin-bottom: 20px;
    // font-weight: 500;
  }
  height: 23%;
  ::v-deep table tbody tr {
    td:nth-child(3) {
      padding-left: 5px;
    }
  }
  ::v-deep .tbody-wrapper {
    height: 100%;
  }
  .terms-table-content {
    height: 100%;
  }
  ::v-deep table tbody tr td {
    // width: 0 !important;
    font-size: 1rem;
    &:nth-child(1) {
      padding-left: 30px !important;
    }
  }
  ::v-deep table tbody tr {
    height: 60px !important;
  }
  ::v-deep table thead tr th:nth-child(1) {
    padding-left: 30px !important;
  }
  ::v-deep table {
    width: 100%;
    table-layout: fixed;

    thead {
      tr {
        height: 30px;
      }
    }
  }
  ::v-deep table tbody .actions {
    padding-left: 55px;
  }
  ::v-deep table tbody tr {
    // background-color: #fff !important;
    height: 35px;
    td {
      // padding-left: 0 !important;
    }
  }
  ::v-deep table {
    tbody {
      height: 105px;
      overflow: auto;
    }
  }
  ::v-deep thead tr th {
    font-size: 0.9375rem;
    padding-left: 0 !important;
    width: 35px !important;
  }
}

.user-details {
  font-family: "Roboto";
  color: #283593;
  font-size: 1.125rem;
  font-weight: 400;
}

.assigned-terms {
  margin-top: 0.625rem;
  font-size: 0.9375rem !important;
}

::v-deep .requests-section table thead tr th:nth-child(1) {
  padding-left: 0.1875rem !important;
}

::v-deep .dates {
  width: 17rem !important;
}
::v-deep .persons-confirmations {
  padding-left: 0 !important;
}
</style>