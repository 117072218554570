import VacationTeams from '@/components/VacationAdmin/VacationTeams.vue'
import VacationTerms from '@/components/VacationAdmin/VacationTerms.vue'
import VacationAdmin from '@/components/VacationAdmin/VacationAdmin.vue'
import VacationsView from '@/components/VacationAdmin/VacationsView.vue'
import VacationStatistics from '@/components/VacationAdmin/VacationStatistics.vue'

const routes = {
  name: 'Vacations',
  path: '/VacationsView',
  redirect: '/VacationsView/VacationsAdmin',
  component: VacationsView,
  children: [
    {
      name: 'Vacations_admin',
      path: 'VacationsAdmin',
      component: VacationAdmin,
    },
    {
      name: 'Vacation_Teams',
      path: 'VacationTeams',
      component: VacationTeams,
    },
    {
      name: 'Vacation_terms',
      path: 'Vacationterms',
      component: VacationTerms,
    },
    {
      name: 'Vacation_statistics',
      path: 'VacationStatistics',
      component: VacationStatistics,
    },
  ],
}

export default routes
