<template>
    <div 
        :class="['gradeNode-block',{'noHover':editMode}]"
        :style="[{width:width,height:height}]">
        <div class="content-wrapper">
            <div class="toolbar" ref="toolbar">
                <button :class="{'visible':editMode}" @click="$emit('editClicked',nodeID)">
                    ...
                </button>
                <div class="toolbar-box" v-if="editMode">
                    <ul>
                        <li v-for="(opt,ind) in toolbarOptions" :key="ind">
                            <button @click="$emit(`${opt.name}`)">
                                <img :src="require(`@/assets${opt.icon}`)" alt="">
                                <span> {{opt.name}} </span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="content" v-if="!packageNode">
                <div class="title">
                    <span>
                        {{this.title ? this.title : ''}}
                    </span>
                </div>
                <ul>
                    <li v-for="(item,ind)  in grades" :key="ind">
                        {{item.Description ? item.Description : ''}}
                    </li>
                </ul>
                <span class="overflowed" v-if="grades.length > 6">...</span>
            </div>

            <div class="content" v-if="packageNode">
                <div class="title">
                    <span>
                        {{this.title ? this.title : ''}}
                    </span>
                </div>
                <ul class="package-detail">
                    <li>
                        <div class="title">
                            Discount
                        </div>
                        <div class="value">
                            <span>
                                {{discount ? `${discount}%`: '-'}}
                            </span>
                        </div>
                    </li>
                    <li>
                        <div class="title">
                            Daily Limit
                        </div>
                        <div class="value">
                            <span>
                                {{dailyLimit ? `${dailyLimit} ${defaultCurrency}` : '-'}}
                            </span>
                        </div>
                    </li>
                    <li>
                        <div class="title">
                            Monthly Limit
                        </div>
                        <div class="value">
                            <span>
                                {{monthlyLimit ? `${monthlyLimit} ${defaultCurrency}` : '-'}}
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
            
        </div>
        <div v-if="!packageNode" class="btn" @click="detailsClickHandler">
            <button class="view-details">
                {{this.assignMode ? 'Assign' : 'View details'}}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        width: {
            type: String,
            default: '21.3125rem',
        },
        height: {
            type: String,
            default: '15.625rem',
        },
        title: [String,Number],
        nodeID: Number,
        grades: Array,
        toolbarOptions: Array,
        editMode: Boolean,
        discount: [String,Number],
        dailyLimit: [String,Number],
        monthlyLimit: [String,Number],
        packageNode:{
            type:Boolean,
            default: false,
        },
        defaultCurrency: [String],
        assignMode:{
            type:Boolean,
            default: false,
        }
    },
    created(){
        document.addEventListener("click", this.clickHandler);
    },
    methods:{
        detailsClickHandler(){
            this.$emit('view',this.nodeID)
        },
        clickHandler(event){
            if(event.path.includes(this.$refs.toolbar)) return
            this.$emit('closeToolbar')
        },
    }
}
</script>

<style lang="scss" scoped>
button{
    outline: none;
    border: none;
    background: transparent;
    user-select: none;
}
.gradeNode-block{
    position: relative;
    border-radius: 0.625rem;
    background: #F5F5FF;
    transition: ease .3s;
    overflow: hidden;
    box-shadow: 0 0 10px rgba($grayBlue,.1);

    &:hover:not(.noHover){
        background: $purple;

        .content-wrapper{
            .toolbar button{
                    color: #ffffff;
            }
            .content{
                .title{
                    color: #ffffff;
                }
                .rate-period{
                    div{
                        color: #CCD6F6;
                        span{
                            color: #ffffff;
                        }
                    }
                }

                ul li{
                    color: #ffffff;
                }

                .overflowed{
                    color: #ffffff;
                }
            }
        }
        span{
            color: #ffffff !important;
        }
        .btn button{
            color: #ffffff;
            transition: ease .2s;
            &:active{
                color: $green-primary;
            }
        }
    }

    .content-wrapper{
        padding: 0 1.875rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;

        .toolbar{
            position: relative;
            align-self: flex-end;
            button{
                font-size: 1.6rem;
                color: #C3CAD9;
                transition: ease .3s;
                cursor: pointer;

                &.visible{
                    color: $purple;
                    // border-radius: 0.625rem;
                }
            }

            .toolbar-box{
                width: 3.25rem;
                background: $purple;
                border-radius: 0.625rem;
                padding: 0.625rem 0.875rem;
                position: absolute;
                top: 100%;
                right: calc(-50% - (.45rem));
                animation: show .3s forwards;


                ul{
                    width: 100%;
                    list-style: none;

                    li{ 
                        width: 100%;
                        margin-top: 1.25rem;

                        &:first-child{
                            margin-top: 0;
                        }


                        button{
                            width: 100%;
                            cursor: pointer;

                            @include flex(center,center,column);
                            img{
                                width: 1.25rem;
                            }
                            span{
                                font-size: 0.625rem;
                                color: #E3D3FD;
                                font-weight: 700;
                                padding-top: 0.3125rem;
                            }
                        }
                    }
                }
            }
        }

        .content{
            .title{
                color: $grayBlue;
                font-size: 0.875rem;
                line-height: 1.25rem;
                font-weight: 700;
            }

            .rate-period{
                margin-top: 0.4375rem;
                div{
                    font-size: 0.75rem;
                    font-weight: 700;
                    color: rgba($grayBlue,.7);
                    span{
                        color: $grayBlue;
                    }
                }
            }

            ul{
                width: 100%;
                max-height: 7rem;
                overflow: hidden;
                list-style: none;
                margin-top: 0.9375rem;

                                    
                &.package-detail{
                    overflow: visible;
                    li{
                        width: 100%;
                        margin-top: 1.025rem;
                        // @include flex(space-between,center,row);
                        display: grid;
                        grid-template-columns: 7rem 4rem;
                        justify-content: space-between;
                        .value{
                            font-size: 0.8313rem;
                            span{
                                color: rgba($purple,.8);
                            }
                            white-space: nowrap;
                            padding-right: 9.375rem;
                        }
                    }
                }
                li{
                    font-weight: 700;
                    color: $grayBlue;
                    font-size: 0.75rem;
                    .title,.value{
                        font-size: 0.75rem;
                    }
                    margin-top: 0.375rem;
                    &:first-child{
                        margin-top: 0;
                    }
                }
            }

            .overflowed{
                color: $grayBlue;
                pointer-events: none;
            }

        }
    }
    .btn{
        bottom: 1.5625rem;
        right: 1.875rem;
        position: absolute;
        button{
            font-weight: 700;
            cursor: pointer;
            color: $purple;
            font-size: 0.75rem;
        }
    }
}
</style>