<template>
    <div :class="['background',{'resized':allSelectedTags.length > 0}]">
        <custom-loader 
            v-if="loading"
        />
        <div :class="['top-header']">
            <div class="filters">
                <ul class="tags" v-dragscroll>
                    <li class="clear-all-filters" @click.stop="clearAllFilteredTags">
                        <span>Clear All</span>
                    </li>
                    <li class="single-tag" v-for="(tag,ind) in allSelectedTags" :key="ind">
                        <div class="remove-tag" @click="deleteFilteredTag(tag.parentObjType,tag.value)">
                            <img src="@/assets/grades/ratingOverall/close.svg" alt="">
                        </div>
                        <span>
                            {{tag.title ? tag.title : '-'}}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="content-wrapper">
            <div class="no-records" v-if="!loading && this.chartData.datasets.length < 1">
                <span>There Are No Records!</span>
            </div>
            <div class="filter-btn">
                <multi-filter 
                    :visible="filterVisible"
                    @collapse="(val) => filterVisible = val"
                    @close="(val) => filterVisible = val"
                    :data="filters"
                    @selectedParent="selectParent"
                    :selectedParentName="selectedParentName"
                    :selectedParentChildrens="selectedParentChilds"
                    @selectChild="selectTag"
                    @closeParent="clearParentArr"
                />
                <div class="route">
                    <router-link 
                        :to="{path: '/Grades/TeamRatingOverall'}"
                    >
                    <img src="@/assets/grades/list.svg" alt="">
                    </router-link>
                </div>
            </div>
            <div class="chart-wrapper">
                <radar-chart
                    v-if="this.chartData.datasets.length > 0"
                    :widht="1600"
                    :height="750"
                    :chartData="chartData"
                    :options="chartOptions"
                    :triggerChartUpdate="triggerChartRender"
                />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance.js";
import { dragscroll } from "vue-dragscroll";
import MultiFilter from '@/components/Grades/GradeComponents/MultiFilter/MultiFilter.vue'
import RadarChart from "@/components/ReusableComponents/Charts/RadarChart.js";
import CustomLoader from '@/components/ReusableComponents/Loader/CustomLoader.vue'


export default {
    components: {
        MultiFilter,
        RadarChart,
        CustomLoader,
    },
    directives: {
        dragscroll,
    },
    watch:{
        filterVisible:{
            handler(val){
                if(!val){
                    let allFilters = []
                    let availableFilterTypes = ['dep','job','person','grade']
                    let findObj = (typeName)  => this.filters.find(el => el.typeName === typeName)
                    
                    availableFilterTypes.forEach(el =>{
                        findObj(el).children.forEach(obj => {
                            if(obj.selected){
                                allFilters.push({
                                    title: obj.title,
                                    value: obj.value,
                                    parentObjType: el,
                                })
                            }else return
                        })
                    })
                    this.allSelectedTags = allFilters
                    let lc = window.localStorage
                    lc.setItem('filteredTags',JSON.stringify(allFilters))
                    // if(lc.getItem('filteredTags')){
                    // }else{
                    //     lc.setItem('filteredTags',JSON.stringify(allFilters))
                    // }
                    this.getChartData(true)
                }
            }
        }
    },
    data(){
        return{
            filterVisible: false,
            loading: false,
            selectedParentName: '',
            selectedParentChilds: [],
            filters: [
                {
                    title: 'Department',
                    selected: false,
                    typeName: 'dep',
                    children: [],
                },
                {
                    title: 'Job',
                    selected: false,
                    typeName: 'job',
                    children: [],
                },
                {
                    title: 'Person',
                    selected: false,
                    typeName: 'person',
                    children: [],
                },
                {
                    title: 'Grade',
                    selected: false,
                    typeName: 'grade',
                    children: [],
                },
            ],
            selectedFilters:{
                persons:[],
                jobs: [],
                departments: [],
                grades: [],
            },
            allSelectedTags: [],

            chartData: {
                datasets:[],
                labels: [],
            },
            chartOptions: {
                responsive:true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 10,
                        },
                    }]
                },
                title: {
                    display: true,
                    text: 'Team Ratings Chart'
                },
                scale: {
                    ticks: {
                        beginAtZero: true,
                        showLabelBackdrop: false
                    },
                    pointLabels: {
                        callback: (label) => {
                            return label.length > 40 ? label.substr(0, 40) + '...' : label;
                        },
                    },
                },
                pointLabels:{
                    callbacks: {
                        label: function(label) {

                        }
                    }
                },
                legend: {
                    labels: {
                        usePointStyle: true,
                        boxWidth: 6,
                    }
                },        
            },
            triggerChartRender : false,
        }
    },
    async created(){
        await this.getFilterLists()
        if(window.localStorage.getItem('filteredTags')){
            this.allSelectedTags = JSON.parse(window.localStorage.getItem('filteredTags'))
            this.allSelectedTags.forEach(el => {
                this.filters.find(f => f.typeName === el.parentObjType).children.find(c => c.value === el.value).selected = true
            })
            await this.getChartData(true)
        }else{
            await this.getChartData(false)
        }
    },
    methods:{
        async getFilterLists(){
            this.loading = true
            await axios.get('grading/GetGradingHistoryFilterLists').then(resp => {
                this.loading = false
                let filterData = resp.data.Value
                let filterParent = (typename) => this.filters.find(el => el.typeName === typename)
                
                filterParent('dep').children = filterData.Departments.map(el => ({
                    title: el.DepartmentName,
                    value: el.DepartmentID,
                    selected: false,
                    parentTypeName: 'dep'
                }))

                filterParent('job').children = filterData.Jobs.map(el => ({
                    title: el.JobName,
                    value: el.JobID,
                    selected: false,
                    parentTypeName: 'job'
                }))

                filterParent('person').children = filterData.Persons.map(el => ({
                    title: el.FirstName + ' ' + el.LastName,
                    value: el.PersonID,
                    selected: false,
                    parentTypeName: 'person'
                }))

                filterParent('grade').children = filterData.Grades.map(el => ({
                    title: el.GradeDescription,
                    value: el.GradeID,
                    selected: false,
                    parentTypeName: 'grade'
                }))


                this.filters.forEach(el => {
                    el.children = [...new Map(el.children.map(e=> [e['value'], e])).values()]
                })
            })
            .catch(err => {
                this.loading = false
            })
        },

        async getChartData(filterMode){
            let deps = []
            let jobs = []
            let persons = []
            let grades = []

            this.loading = true
            this.allSelectedTags.forEach(el => {
                if(el.parentObjType === 'job'){
                    jobs.push({id: el.value})
                }
                if(el.parentObjType === 'dep'){
                    deps.push({id: el.value})
                }
                if(el.parentObjType === 'person'){
                    persons.push({id: el.value})
                }
                if(el.parentObjType === 'grade'){
                    grades.push({id: el.value})
                }
            })
            await axios.post('grading/GetGradingDataForChart',{
                departments: filterMode ? deps : [],
                jobs: filterMode ? jobs : [],
                persons: filterMode ? persons : [],
                grades: filterMode ? grades : [],
            }).then(resp => {
                let chart = resp.data.Value.Chart
                this.triggerChartRender = !this.triggerChartRender
                this.generateChartData(chart)
                this.loading = false
            })
            .catch(err => {
                this.loading = false
            })
        },
        selectParent(val,title){
            this.selectedParentName = title
            this.filters.forEach(el => {
                if(el.typeName === val) el.selected = true
                else el.selected = false
            })
            this.selectedParentChilds = this.filters.find(el => el.typeName === val).children
        },
        selectTag(child){
            child.selected = !child.selected
            if(child.parentTypeName === 'job'){
                if(child.selected){
                    this.selectedFilters.jobs.push(child)
                }else{
                    this.selectedFilters.jobs = this.selectedFilters.jobs.filter(el => el.value !== child.value)
                }
            } 
                
            if(child.parentTypeName === 'person'){
                if(child.selected){
                    this.selectedFilters.persons.push(child)
                }else{
                    this.selectedFilters.persons = this.selectedFilters.persons.filter(el => el.value !== child.value)
                }
            } 

            if(child.parentTypeName === 'dep'){
                if(child.selected){
                    this.selectedFilters.departments.push(child)
                }else{
                    this.selectedFilters.departments = this.selectedFilters.departments.filter(el => el.value !== child.value)
                }
            } 

            if(child.parentTypeName === 'grade'){
                if(child.selected){
                    this.selectedFilters.grades.push(child)
                }else{
                    this.selectedFilters.grades = this.selectedFilters.grades.filter(el => el.value !== child.value)
                }
            }
        },
        clearParentArr(){
            this.filters.forEach(el => el.selected = false)
        },
        clearAllFilteredTags(){
            this.allSelectedTags = []
            this.filters.forEach(el => {
                el.children.forEach(obj => {
                    obj.selected = false
                })
            })

            this.getChartData(true)
        },
        deleteFilteredTag(typename,id){
            this.allSelectedTags = this.allSelectedTags.filter(el => el.value !== id)
            this.filters.find(el => el.typeName === typename).children.find(child => child.value === id).selected = false
            window.localStorage.setItem('filteredTags',JSON.stringify(this.allSelectedTags))
            this.getChartData(true)
        },
        getRandomColor() {
            var letters = '0123456789ABCDEF'.split('');
            var color = '#';
            for (var i = 0; i < 6; i++ ) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`;
            return setOpacity(color,.3);
        },
        generateChartData(array){
            let chartColors = []
            array.dataSets.forEach((el,ind) => {
                let color = this.getRandomColor()
                if(chartColors.some(c => c === color)){
                    color = this.getRandomColor()
                }else{
                    chartColors.push(color)
                }
                el['backgroundColor'] = chartColors[ind]
                el['borderColor'] = chartColors[ind]
                el['pointBackgroundColor'] = chartColors[ind]
                el['pointBorderColor'] = chartColors[ind]
                el['pointHoverBackgroundColor'] = chartColors[ind]
                el['pointHoverBorderColor'] = chartColors[ind]
            })
            // array.labels = array.labels.map(el => el.split(' ')[0])
            this.chartData.datasets = array.dataSets
            this.chartDataPlaceholder = array.dataSets
            this.chartData.labels = array.labels
        },
    }
}
</script>

<style lang="scss" scoped>

*{
    font-family: Roboto,firaGo;
}

.fade-enter-active {
    transition: opacity .5s;
}

.fade-leave-active{
    transition: .2s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
    transform: translateY(0.625rem);
}

.background{
    overflow: hidden;
    background: rgba(#ededfc,.6);

    &.resized{
        .top-header{
            display: flex;
            position: relative;
            top: unset !important;
            left: unset !important;
        }
        .content-wrapper{
            height: calc(100% - 6.5rem);
        }
    }
}

.top-header{
    width: calc(100% - 1.875rem);
    margin: auto;
    background: #ffffff;
    border-radius: 0.625rem;
    margin-top: 0.625rem;
    padding-bottom: 0.3125rem;
    box-shadow: 0 0 1.25rem rgba($grayBlue, .16);
    position: relative;
    @include flex(flex-start,center,row);
    transition: ease .2s;
    display: none;

    .filters{
        width: 100%;
        position: relative;

        ul{
            width: 100%;
            max-height: 4.5rem;
            @include flex(flex-start,center,row);
            flex-wrap: wrap;
            list-style: none;
            overflow: auto;

            li{
                transition: ease .3s;
                @include flex(flex-start,center,row);
                background: $purple;
                border-radius: 1.875rem;
                padding: 0 0.5rem;
                height: 1.875rem;
                min-width: 3rem;
                max-width: 12rem;
                margin-left: 0.3125rem;
                margin-top: 0.3125rem;
                overflow: hidden;
                cursor: pointer;
                position: relative;
                transition: ease .3s;
                user-select: none;
                &:hover:not(.clear-all-filters){
                    background: rgba($purple,.8);
                }

                .remove-tag{
                    margin-top: 0.1875rem;
                    img{
                        width: 1.225rem;
                    }
                }
                span{
                    color: #ffffff;
                    font-size: 0.6875rem;
                    font-weight: 500;
                    margin-left: 0.4375rem;
                    padding-right: 0.3125rem;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                &.clear-all-filters{
                    padding: 0 0.75rem;
                    background: #DEE3EB;
                    span{
                        color: #6B7A99;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.content-wrapper{
    width: calc(100% - 1.875rem);
    height: calc(100% - 1.5rem);
    overflow: auto;
    position: relative;
    margin: auto;
    background: #ffffff;
    border-radius: 1.25rem;
    margin-top: 0.625rem;
    transition: ease .3s;

    .no-records{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        pointer-events: none;
        user-select: none;
        span{
            font-size: 2.1875rem;
            color: rgba($grayBlue, .8);
            white-space: nowrap;
        }
    }

    .filter-btn{
        position: absolute;
        top: 0;
        @include flex(flex-start,center,row);
        .route{
            img{
                width: 1.5rem;
            }
        }
        ::v-deep{
            .filter{
                margin-top: 0;
            }
            .filter-container{
                top: calc(100% - 2.5rem);
                height: 29rem;
            }
        }   
    }
}
</style>