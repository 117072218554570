<template>
    <div v-if="visible" class="comments-wrapper" id="drag__container" ref="commentChat">
        <div class="comment-content">
            <div class="top-header" id="drag__target" @mousedown="addListeners()">
                <div class="current-person-overview">
                    <span class="person-title">
                        {{overviewData.personTitle ? overviewData.personTitle : ''}}
                    </span>
                    <span class="job-title">
                        {{overviewData.personSecondaryTitle ? overviewData.personSecondaryTitle : ''}}
                    </span>
                </div>
                <div class="close" @click.stop="$emit('close')">
                    <img src="@/assets/grades/exit.svg" alt="">
                </div>
            </div>
            <div class="middle-content">
                <div class="loading" v-if="loading">
                    <img src="@/assets/gifs/pallete.svg" alt="">
                </div>
                <div class="no-content" v-if="!loading && messages.length < 1">
                    <span>There are no Comments</span>
                </div>
                <ul class="messages-list">
                    <li v-for="(m,ind) in messages" :key="ind" :id="`message` + m.MessageID">
                        <div class="sender-info">
                            <div :class="['left-side',{'not-seen':!m.Seen && !commonchatMode}]">
                                <div class="avatar" :style="`background-image: url(https://intranet.upgaming.dev/api/Person/GetPersonAvatar2?ID=${m.PersonID})`"></div>
                                <div class="title"> 
                                    <span> {{`${m.SenderFirstName} ${m.SenderLastName}`}} </span>
                                </div>
                                <span class="sent-date">{{m.sendDate}}</span>
                            </div>
                            <div class="right-side">
                                <div class="actions">
                                    <div @click.stop="$emit('toolbarVisible',m)">
                                        ...
                                    </div>
                                    <div class="action-tools" v-if="m.toolbarVisible">
                                        <div class="delete" @click.stop="$emit('deleteMsg',m.MessageID,historyDetailID)">
                                            <img src="@/assets/grades/trash-gray.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="comment">
                            <p>
                                {{m.Message}}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="bottom-content">
                <div class="send-message-wrapper">
                    <textarea @keyup="keyEvent" name="" id="" @input="$emit('input',$event.target.value)" :value="message" placeholder="Reply ....."></textarea>
                    <div class="send-btn" @click.stop="$emit('send',historyDetailID)">
                        <img src="@/assets/grades/send.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        visible: {
            type: Boolean,
            default: false,
        },
        overviewData: Object,
        message: [String,Number],
        historyDetailID: [Number,String],
        messages: Array,
        loading: {
            type:Boolean,
            default: false,
        },
        scrollBottomMode: {
            type: Boolean,
            default: false,
        },
        commonchatMode:{
            type: Boolean,
            default: false,
        }
    },
    data(){
        return{

        }
    },
    created(){
        document.addEventListener('click',this.clickHandler)
        if(this.scrollBottomMode){
            this.scrolltoBottom()
        }
    },
    watch:{
        loading(){
            if(this.scrollBottomMode){
                this.scrolltoBottom()
            }
        },
        immediate: true,
    },
    destroyed(){
        document.removeEventListener('click',this.clickHandler)
    },
    methods:{
        scrolltoBottom(){
            this.$nextTick(() => {
                const messagelist = document.querySelector('.messages-list')
                messagelist.scrollTo({top:messagelist.scrollHeight,behavior:'smooth'})
            })
        },
        clickHandler($event){
            if($event.path.includes(this.$refs.commentChat) || $event.target.matches('.comment-chat-btn') || $event.target.matches('.common-comment-btn')) return
            else{
                this.$emit('close')
            }
        },
        // scrollEvent(){
        //     if(this.messages.every(el => el.Seen)) return 
        //     else{
        //         this.messages.forEach(el => {
        //             if(!el.Seen){
        //                 el['observed'] = this.isInViewport(`#message${el.MessageID}`)
        //             }
        //             else return
        //         })
    
        //         let observedMessagesArr = this.messages.filter(el => el.observed).map(m => m.MessageID)
                
        //         if(observedMessagesArr.length > 0){
        //             this.$emit('seenObj',observedMessagesArr,this.historyDetailID)
        //         }
        //     }
            
        // },
        // isInViewport(element) {
        //     let el = document.querySelector(element)
        //     const rect = el.getBoundingClientRect();
        //     let state = (
        //         rect.top >= 0 &&
        //         rect.left >= 0 &&
        //         rect.bottom <= (document.querySelector('.messages-list').innerHeight  || document.documentElement.clientHeight) &&
        //         rect.right <= (document.querySelector('.messages-list').innerWidth  || document.documentElement.clientWidth)
        //     );
        //     return state
        // },
        addListeners(){
            document.getElementById('drag__target').addEventListener('mousedown', this.mouseDown(), false);
            window.addEventListener('mouseup', this.mouseUp, false);
        },

        mouseUp(){
            window.removeEventListener('mousemove', this.divMove, true);
        },

        mouseDown(e){
            window.addEventListener('mousemove', this.divMove, true);
        },

        divMove(e){
            let div = document.getElementById('drag__container');
            div.style.position = 'fixed';
            div.style.top = (e.clientY - 20) + 'px';
            div.style.left = (e.clientX - 50) + 'px';
        },
        keyEvent(e){
            if(e.key === 'Enter' || e.keyCode == '13') this.$emit('send',this.historyDetailID)
        }
    }
    
}
</script>


<style lang="scss" scoped>
    .comments-wrapper{
        width: 23.125rem;
        height: 33.75rem;
        position: fixed;
        top: 30%;
        right: 30%;
        background: #ffffff;
        z-index: 999999999999999;
        box-shadow: 0 0 1.25rem rgba(#3d3d3d,.2);
        overflow: hidden;
        border-radius: 0.625rem;
        animation: openUp .3s forwards;
        @keyframes openUp {
            from{
                opacity: 0;
                transform: scaleY(0);
            }
            to{
                opacity: 1;
                transform: scaleY(1);
            }
        }

        .comment-content{
            width: 100%;
            height: 100%;
            position: relative;
            padding: 0.625rem 0.9375rem;

            .top-header{
                width: 100%;
                @include flex(space-between,center,row);
                cursor: grab;
                .current-person-overview{
                    span{
                        font-size: 0.8125rem;
                        color: #4D5E80;
                        display: block;
                        max-width: 16rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        &.job-title{
                            margin-top: 0.25rem;
                            font-weight: 500;
                        }
                    }
                }

                .close{
                    cursor: pointer;
                    user-select: none;
                    img{
                        width: 0.6875rem;
                    }
                }
            }
        }

        .middle-content{
            width: 100;
            height: 100%;
            position: relative;

            .no-content{
                position: absolute;
                top: 40%;
                left: 40%;
                transform: translate(-30%,-100%);

                span{
                    font-size: 1rem;
                    color: rgba(#3d3d3d,.6);
                }
            }

            .loading{
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-40%,-50%);
                img{
                    width: 2.8125rem;
                }
            }

            ul{
                width: 100%;
                height: 100%;
                margin-top: 0.9375rem;
                overflow: auto;
                padding-bottom: 9rem;
                &::-webkit-scrollbar{
                    display: none;
                }
                li{
                    width: 100%;
                    @include flex(flex-start,left,column);
                    padding-top: 0.9375rem;
                    border-top: 0.0625rem solid rgba(#3d3d3d,.1);
                    padding-bottom: 0.9375rem;

                    .sender-info{
                        width: 100%;
                        @include flex(space-between,center,row);
                        .left-side{
                            @include flex(flex-start,left,row);
                            &.not-seen{
                                &::before{
                                    content: '';
                                    width: 0.4375rem;
                                    height: 0.4375rem;
                                    background: #FF3333;
                                    border-radius: 50%;
                                }
                            }
                            .avatar{
                                width: 2.1875rem;
                                height: 2.1875rem;
                                border-radius: 50%;
                                background-size: cover;
                            }
                            .title{
                                max-width: 9rem;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                margin-left: 0.5rem;
                                font-size: 0.9375rem;
                                color: #4D5E80;
                                font-weight: 500;
                            }
                            .sent-date{
                                margin-left: 0.5625rem;
                                font-size: 0.8125rem;
                                color: rgba(#3d3d3d,.6);
                            }
                        }

                        .right-side{
                            align-self: left;
                            cursor: pointer;
                            padding-right: 0.3125rem;
                            .actions{
                                @include flex(center,center,column);
                                
                                div{
                                    font-size: 1rem;
                                }
                                position: relative;

                                .action-tools{
                                    position: absolute;
                                    top: 100%;
                                    left: 0;
                                    .delete{
                                        margin-top: 0.5625rem;
                                        img{
                                            width: 1.0625rem;
                                        }

                                    }
                                }
                            }
                        }


                    }
                    .comment{
                        padding: 0 0.625rem;
                        padding-left: 2.8125rem;
                        p{
                            font-size: 0.9375rem;
                            color: rgba(#3d3d3d,.8);
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        .send-message-wrapper{
            width: 100%;
            height: 5.8rem;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 99;
            background: rgba(#ffffff,1);
            padding: 0 0.3125rem;
            padding-bottom: 0.625rem;
            @include flex(center,flex-end,row);

            textarea{
                width: calc(100% - 1rem);
                height: calc(100% - 1rem);
                outline: none;
                resize: none;
                border: rgba(#4D5E80,.2) 0.0625rem solid;
                border-radius: 0.375rem;
                font-size: 0.9375rem;
                color: #4D5E80;
                padding: 0.4375rem 0.625rem;
                padding-right: 1.75rem;

                &::placeholder{
                    opacity: .5;
                    font-weight: 300;
                }
            }

            .send-btn{
                position: absolute;
                right: 1rem;
                bottom: 2rem;
                cursor: pointer;
                user-select: none;
                @include centerElement;
                img{
                    width: 1.5rem;
                    filter: invert(36%) sepia(14%) saturate(1156%) hue-rotate(182deg) brightness(94%) contrast(90%);
                }
            }
        }
    }
</style>