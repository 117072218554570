<template>
  <section
    ref="newsContainer"
    class="news-polls-section"
    :style="[!noBanner ? { height: '100%' } : null]"
  >
    <div
      v-if="!onlyOffer && !haveOnlyOfferAndBanner"
      class="news-and-polls"
      :style="[!noBanner ? { height: '98%' } : null]"
    >
      <ul class="column-one">
        <li
          v-for="(el, index) in itemsForColumnOne"
          :key="index"
          :class="{ itemWithBanner: index % 2 }"
        >
          <div class="persons-banner-wrapper" @click="getSinglePollOrNews(el)">
            <img
              :src="require('@/assets/icons/poll-flag-user.svg')"
              v-if="el.isPoll"
            />
            <div
              class="banner"
              v-if="index % 2"
              :style="{
                backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${
                  el.Poll || el.News
                }&PlacementType=${el.PlacementType})`,
              }"
            ></div>
            <div class="info-wrapper">
              <div class="date">{{ getDateString(el.PublishDate) }}</div>
              <h2>{{ el.Title }}</h2>
              <p>{{ htmlToText(el.Description) }}</p>
            </div>
          </div>
          <div class="persons-content" @click.stop="audienceClickHandler(el)">
            <div
              class="avatar"
              v-for="person in el.Visibility.slice(0, 6)"
              :key="person.Person"
              :style="{
                'background-image': `url(https://intranet.upgaming.com/api/Person/GetPersonAvatar2?ID=${person.Person})`,
              }"
            ></div>
            <div class="count" v-if="el.Visibility.length > 6">
              +{{ el.Visibility.length - 6 }}
            </div>
            <audience-list
              :data="listOfPersons"
              :placementId="el.News || el.Poll"
              :audienceVisible="audienceVisible"
            />
          </div>
        </li>
      </ul>
      <ul class="column-two">
        <li
          v-for="(el, index) in itemsForColumnTwo"
          :key="index"
          :class="{ itemWithBanner: !(index % 2) }"
        >
          <div class="persons-banner-wrapper" @click="getSinglePollOrNews(el)">
            <img
              :src="require('@/assets/icons/poll-flag-user.svg')"
              v-if="el.isPoll"
            />
            <div
              class="banner"
              v-if="!(index % 2)"
              :style="{
                backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${
                  el.Poll || el.News
                }&PlacementType=${el.PlacementType})`,
              }"
            ></div>

            <div class="info-wrapper">
              <div class="date">{{ getDateString(el.PublishDate) }}</div>
              <h2>{{ el.Title }}</h2>
              <p>{{ htmlToText(el.Description) }}</p>
            </div>
          </div>
          <div class="persons-content" @click.stop="audienceClickHandler(el)">
            <div
              class="avatar"
              v-for="person in el.Visibility.slice(0, 6)"
              :key="person.Person"
              :style="{
                'background-image': `url(https://intranet.upgaming.com/api/Person/GetPersonAvatar2?ID=${person.Person})`,
              }"
            ></div>
            <div class="count" v-if="el.Visibility.length > 6">
              +{{ el.Visibility.length - 6 }}
            </div>

            <audience-list
              :data="listOfPersons"
              :placementId="el.News || el.Poll"
              :audienceVisible="audienceVisible"
            />
          </div>
        </li>
      </ul>
      <ul class="column-three">
        <li
          v-for="(el, index) in itemsForColumnThree"
          :key="index"
          :class="{ itemWithBanner: index % 2 }"
        >
          <div class="persons-banner-wrapper" @click="getSinglePollOrNews(el)">
            <img
              :src="require('@/assets/icons/poll-flag-user.svg')"
              v-if="el.isPoll"
            />
            <div
              class="banner"
              v-if="index % 2"
              :style="{
                backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${
                  el.Poll || el.News
                }&PlacementType=${el.PlacementType})`,
              }"
            ></div>
            <div class="info-wrapper">
              <div class="date">{{ getDateString(el.PublishDate) }}</div>
              <h2>{{ el.Title }}</h2>
              <p>{{ htmlToText(el.Description) }}</p>
            </div>
          </div>

          <div class="persons-content" @click.stop="audienceClickHandler(el)">
            <div
              class="avatar"
              v-for="person in el.Visibility.slice(0, 6)"
              :key="person.Person"
              :style="{
                'background-image': `url(https://intranet.upgaming.com/api/Person/GetPersonAvatar2?ID=${person.Person})`,
              }"
            ></div>
            <div class="count" v-if="el.Visibility.length > 6">
              +{{ el.Visibility.length - 6 }}
            </div>
            <audience-list
              :data="listOfPersons"
              :placementId="el.News || el.Poll"
              :audienceVisible="audienceVisible"
            />
          </div>
        </li>
      </ul>
    </div>
    <div
      class="no-data-text-bottom"
      :style="{
        'background-image': `url(${require('@/assets/noPlacementBackground.png')})`,
      }"
      v-else-if="haveOnlyOfferAndBanner"
    >
      <span>No news or poll was found for you</span>
    </div>
    <div
      class="no-data-text"
      :style="{
        'background-image': `url(${require('@/assets/noPlacementBackground.png')})`,
      }"
      v-else
    >
      <span>No news or poll was found for you</span>
    </div>
    <div class="poll-popup">
      <custom-popup
        :visible="pollDetailsVisible"
        v-if="poll"
        :flexible-size="true"
        @close="pollDetailsVisible = false"
      >
        <div class="poll">
          <div class="poll__content">
            <div class="banner-details-content">
              <div
                class="poll__banner"
                :style="{
                  backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${poll.id}&PlacementType=Poll)`,
                }"
              />
              <div class="poll__details">
                <div class="wrapper">
                  <div class="poll-date">
                    Expiry date:
                    {{ getDateString(poll.expiryDate, "dd MMM yyyy") }}
                  </div>
                  <div class="poll__question">
                    {{ poll.question || "question" }}
                  </div>
                  <div class="poll__options">
                    <div
                      class="poll__option"
                      v-for="(option, i) in poll.options"
                      :key="i"
                    >
                      <div class="poll__option-wrapper">
                        <div class="poll__option-index">{{ i + 1 }}</div>
                        <div class="poll__option-name">{{ option.option }}</div>
                      </div>
                      <div
                        :class="[
                          'poll__option-checkbox',
                          {
                            'poll__option-checkbox--selected': selectedOptions.includes(
                              option.id
                            ),
                          },
                        ]"
                        @click="optionClickHandler(option)"
                      >
                        <svg
                          v-if="selectedOptions.includes(option.id)"
                          class="checkbox__mark"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 512 512"
                          xml:space="preserve"
                        >
                          <path
                            fill="#283593"
                            d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
			c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
			C514.5,101.703,514.499,85.494,504.502,75.496z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="poll__comment"
                  v-if="!alreadySelected && !poll.anonymous"
                ></div>
              </div>
            </div>
            <div class="desc-comment-content">
              <div class="poll__description">{{ poll.description }}</div>
              <textarea
                v-if="!poll.anonymous"
                placeholder="Type your comment"
                type="text"
                class="cstm-input"
                v-model="pollComment"
              />
            </div>
          </div>
          <div
            class="poll__button"
            v-if="!alreadySelected"
            @click="makePollChoice"
          >
            Send
          </div>
        </div>
      </custom-popup>
    </div>
    <div class="news-popup">
      <custom-popup
        :visible="newsDetailsVisible"
        @close="newsDetailsVisible = false"
        :flexible-size="true"
      >
        <div class="news" v-if="newsDetails">
          <div class="desc-banner-content">
            <div
              class="news__banner"
              :style="{
                backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${newsDetails.id}&PlacementType=News)`,
              }"
            />
            <div class="desc-content">
              <div class="news__title">
                {{ newsDetails.title }}
              </div>
              <div
                :class="[
                  'news__text',
                  { 'news__text--without-banner': !newsDetails.banner },
                ]"
                v-html="newsDetails.text"
              />
            </div>
          </div>
          <div class="news-attachments">
            <div class="attached-files">
              <div @click="downloadAttachment(file.FileID)" :class="['single-file',{'border':file.FileName}]" v-for="(file,ind) in newsDetails.newsAttachments" :key="ind">
                  <div v-if="file.FileName" class="file-block" :type="file.FileName ? file.FileName.split('.').pop() : '' ">
                      {{file.FileName ? file.FileName.split(".").slice(-1)[0] : ''}}
                  </div>
                    <div v-if="file.FileName" class="file-name">
                      {{file.FileName ? file.FileName : ''}}
                  </div>
              </div>
            </div>
          </div>
          <div class="news__audience">
            <div class="news__audience-header">
              <div class="news__audience-header-title">
                <b>Audience</b> <span> {{ audience.length }} </span>
              </div>
            </div>
            <div class="news__audience-items" v-dragscroll>
              <div
                class="news__audience-items-item"
                v-for="person in audience"
                :key="person.PersonID"
              >
                <div
                  v-if="person"
                  class="news__audience-items-item-avatar"
                  :style="{
                    'background-image': `url(data:image/jpeg;base64,${person.File})`,
                  }"
                />
                <div class="news__audience-items-item-name">
                  <p>{{ person.FirstName }}</p>
                  <p>{{ person.LastName }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <span v-else></span>
      </custom-popup>
    </div>
    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
  </section>
</template>

<script>
import AudienceList from "./AudienceList.vue";
import axios from "@/Helpers/AxiosInstance";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";
import { dragscroll } from "vue-dragscroll";
import CustomSnackbar from "../ReusableComponents/CustomSnackbar/CustomSnackbar.vue";

export default {
  components: {
    AudienceList,
    CustomPopup,
    CustomSnackbar,
  },
  directives: {
    dragscroll,
  },
  created() {
    document.addEventListener("click", this.documentClick);
  },
  props: {
    news: Array,
    polls: Array,
    noBanner: Boolean,
    onlyOffer: Boolean,
    haveOnlyOfferAndBanner: Boolean,
  },

  data() {
    return {
      audienceVisible: 0,
      listOfPersons: [],
      selectedOptions: [],
      audience: [],
      alreadySelected: false,
      pollComment: "",

      snackbarPurpose: "",
      snackbarText: "",
      snackbarVisible: false,
      newsDetailsVisible: false,
      newsDetails: {},
      pollDetailsVisible: false,
      poll: {},
    };
  },
  methods: {
    getPerson(id) {
      axios
        .get("News/GetVisibilityInfoAboutNews", {
          params: {
            News: id,
          },
        })
        .then((resp) => {
          this.audience = resp.data.Value;
        });
    },
    optionClickHandler(option) {
      const exists = this.selectedOptions.includes(option.id);
      if (this.poll.type === 1) {
        if (exists) {
          this.selectedOptions = [];
        } else {
          this.selectedOptions = [option.id];
        }
      } else {
        if (exists) {
          this.selectedOptions = this.selectedOptions.filter(
            (el) => el !== option.id
          );
        } else {
          this.selectedOptions.push(option.id);
        }
      }
    },
    async makePollChoice() {
      const { ID: userId } = this.$store.state.profile;
      const response = await axios.post("News/MakePollChoice", {
        Person: userId,
        Comment: this.pollComment,
        TVP: this.selectedOptions.map((el) => ({ PollOption: el })),
      });

      if (!response.data.ErrorMsg) {
        this.snackbarVisible = true;
        this.snackbarText = "Successfully saved";
        this.snackbarPurpose = "success";
        this.pollDetailsVisible = false;
        this.pollComment = "";
      }
    },
    documentClick() {
      this.audienceVisible = 0;
    },

    htmlToText(html) {
      const e = document.createElement("p");
      e.innerHTML = html;

      return e.textContent;
    },
    audienceClickHandler(el) {
      let targetEl = event.currentTarget;
      this.audienceVisible = el.News || el.Poll;

      this.listOfPersons = [];
      el.Visibility.forEach((person) => {
        this.listOfPersons.push(person);
      });
    },
    getDateString(date, format = "dd MMM, yyyy") {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      if (date === "Invalid Date") return;

      const MONTHS = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      var days = ("0" + date.getDate()).slice(-2),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        year = date.getFullYear(),
        hours = ("0" + date.getHours()).slice(-2),
        minutes = ("0" + date.getMinutes()).slice(-2),
        seconds = ("0" + date.getSeconds()).slice(-2);

      return format
        .replace("dd", days)
        .replace("MMM", MONTHS[parseInt(month) - 1])
        .replace("MM", month)
        .replace("yyyy", year)
        .replace("yy", String(year).slice(-2))
        .replace("HH", hours)
        .replace("mm", minutes)
        .replace("ss", seconds)
        .replace("ww", WEEKDAYS[date.getDay()]);
    },
    async getSinglePollOrNews(el) {
      if (el.isPoll) {
        this.pollDetailsVisible = true;
        let id = el.Poll;
        const data = await axios
          .get("News/GetPollDetails", {
            params: {
              Poll: id,
            },
          })
          .then((resp) => {
            const { Value } = resp.data;
            return Value;
          });

        const {
          Poll: {
            Poll,
            Anonymous,
            Description,
            ExpiryDate,
            File,
            Question,
            Title,
            Type,
          },
          PollOptions: options,
        } = data;
        this.poll = {
          id: el.Poll,
          title: Title,
          file: File,
          anonymous: Anonymous,
          type: Type,
          question: Question,
          description: Description,
          options: options.map(({ Option, PollOPtionID }) => ({
            option: Option,
            id: PollOPtionID,
          })),
          expiryDate: new Date(ExpiryDate),
        };
      } else {
        let id = el.News;
        this.getPerson(id);
        this.newsDetailsVisible = true;
        const data = await axios
          .get("News/GetNewsDetails", {
            params: {
              News: id,
            },
          })
          .then((resp) => resp.data.Value);
        const news = data.News;
        const restrictions = data.Restrictions;
        const persons = [];

        this.newsDetails = {
          title: news.Title,
          banner: news.File,
          text: news.Description,
          id: news.NewsID,
          newsAttachments: news.NewsFiles,
          restrictions: persons,
        };
      }
    },
    downloadAttachment(fileID){
      if(!fileID) return
      const downloadLink = document.createElement("a");
      downloadLink.href = !this.$store.state.isProduction ? 
          `https://intranet.upgaming.dev/api/News/GetNewsFileByID?FileID=${fileID}` : 
              `https://intranet.upgaming.com/api/News/GetNewsFileByID?FileID=${fileID}`;
      downloadLink.target = 'blank'
      downloadLink.click();
    }
  },
  computed: {
    newsAndPolls() {
      return [...this.polls, ...this.news];
    },
    newsAndPollsByTime() {
      return this.newsAndPolls.slice().sort(function (a, b) {
        return b.PublishDate - a.PublishDate;
      });
    },
    itemsForColumnOne() {
      let result = [];
      let data = this.newsAndPollsByTime;
      for (let i = 0; i < data.length; i += 3) {
        result.push(data[i]);
      }

      return result;
    },
    itemsForColumnTwo() {
      let result = [];
      let data = this.newsAndPollsByTime;
      for (let i = 1; i < data.length; i += 3) {
        result.push(data[i]);
      }

      return result;
    },
    itemsForColumnThree() {
      let result = [];
      let data = this.newsAndPollsByTime;
      for (let i = 2; i < data.length; i += 3) {
        result.push(data[i]);
      }

      return result;
    },
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style scoped lang="scss">
.no-data-text-bottom {
  height: 100%;
  width: 99%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  padding-top: 90px;
  background-size: cover;
  background-repeat: no-repeat;
  span {
    font-size: 40px;
    font-weight: 600;
    color: rgba($blue-primary, 0.5);
  }
}
.no-data-text {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  font-size: 50px;
  background-size: cover;
  padding-top: 90px;
  background-repeat: no-repeat;
  span {
    font-size: 40px;
    font-weight: 600;
    color: rgba($blue-primary, 0.5);
  }
}
.news-popup {
  ::v-deep .popup {
    height: 90% !important;
    width: 70% !important;
    top: 55%;
    left: 46%;
    overflow: hidden;
    .title {
      display: none;
    }
  }
  .desc-content {
    width: calc(50% - 15px);
  }

  .news {
    padding: 30px 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .desc-banner-content {
      height: 410px;
      display: flex;
      justify-content: space-between;
    }
    &__title {
      font-size: 20px;
      font-weight: 500;
      color: #283593;
      margin-bottom: 15px;
      padding-left: 15px;
    }

    &__banner {
      width: calc(52.5% - 17px);
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: 25px;
      border-radius: 6px;
    }

    &__text {
      font-size: 14px;
      font-weight: 300;
      overflow-y: auto;
      padding-left: 15px;
      max-height: 370px;
      word-break: break-word;
      color: #283593;

      &--without-banner {
        max-height: calc(250px + 25px + 210px);
      }
    }

    &__audience {
      margin-top: auto;
      &-header {
        margin-bottom: 25px;

        &-title {
          font-size: 18px;
          color: #283593;

          b {
            font-weight: 500;
          }
          span {
            font-weight: lighter;
          }
        }
      }

      &-items {
        display: flex;
        overflow: hidden;
        cursor: e-resize;
        padding-bottom: 20px;

        &-item {
          margin-right: 65px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          &-avatar {
            width: 90px;
            height: 90px;
            background-color: #ededfc;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 100%;
            margin-bottom: 5px;
          }

          &-name {
            color: #283593;
            font-weight: lighter;
          }
        }
      }
    }
  }
}
.poll-popup {
  ::v-deep .popup {
    width: 70% !important;
    height: initial;
    top: 52%;
    left: 46%;
    h3.title {
      display: none;
    }
  }
  .banner-details-content {
    display: flex;
    width: 100%;
    height: 410px;
  }
  .desc-comment-content {
    margin-top: 30px;
  }
  .cstm-input {
    background-color: transparent;
    border: 0;
    font-size: 14 * $rem;
    width: 100%;
    color: #283593;
    height: 60px;
    max-width: 100%;
    font-weight: 300;
    border: 1px solid #dcdde6;
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 5px;

    &::placeholder {
      color: #9696b9;
    }

    &:focus {
      outline: 0;
    }
  }

  .poll {
    display: flex;
    flex-direction: column;

    &__content {
      display: flex;
      height: 550px;
      flex-direction: column;
    }

    &__banner {
      width: 50%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 10px;
      margin-top: 24px;
    }

    &__details {
      margin-left: 50px;
      color: #9696b9;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 50%;
      .wrapper {
        // width: 106%;
      }
    }

    &__description {
      margin-top: 15px;
      height: 75px;
      margin-bottom: 15px;
      font-weight: 300;
      font-size: 14px;
      word-break: break-word;
      overflow: auto;
      color: $blue-primary;
    }

    &__question {
      height: 50px;
      overflow-y: auto;
      margin-bottom: 60px;
      color: $blue-primary;
    }

    &__options {
      max-height: 300px;
      display: flex;
      flex-direction: column;
      margin-bottom: 60px;
      overflow-y: auto;
      padding-right: 20px;
    }

    &__option {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 10px;

      &-wrapper {
        display: flex;
        align-items: flex-start;
      }

      &-index {
        margin-right: 10px;
        font-weight: 500;
        color: #9696b9;
      }

      &-name {
        color: #283593;
        max-width: 490px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 300;
      }

      &-checkbox {
        margin-left: auto;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        border: 1px solid #959bc9;
        background-color: transparent;
        cursor: pointer;

        &--selected {
          border: 1px solid #283593 !important;
        }

        svg {
          width: 12px;
          height: 10px;
        }
      }
    }
    .poll-date {
      text-align: right;
      padding-right: 10px;
      margin-bottom: 10px;
      color: $blue-primary;
    }

    &__button {
      cursor: pointer;
      margin-top: 105px;
      margin-left: auto;
      background-color: #959bc9;
      color: white;
      border-radius: 6px;
      width: 145px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.news-polls-section {
  margin-top: 30px;
  overflow: hidden;
  height: calc(100% - 17px);
  h3 {
    color: $blue-primary;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 300;
  }
  .news-and-polls {
    display: flex;
    // justify-content: space-between;
    height: 100%;
    overflow: auto;
  }
  ul {
    margin-right: 14px;
    &:last-child {
      margin-right: 0;
    }
    li {
      // width: 100%;
      width: 310px;
      cursor: pointer;
      list-style: none;
      padding: 30px;
      margin-bottom: 30px;
      height: 240px;
      background-color: #fff;
      color: $blue-primary;
      // overflow: hidden;
      border-radius: 6px;
      position: relative;
      img {
        position: absolute;
        top: 0;
        right: 10px;
      }
      .persons-content {
        padding-left: 0;
        margin-left: -15px;
      }

      .banner {
        height: 150px;
        width: 250px;
        background-color: #ededfc;
        border-radius: 6px;
        margin-bottom: 30px;
      }
      .date {
        font-size: 12px;
        font-weight: 300;
        margin-bottom: 20px;
      }
      h2 {
        font-weight: 500;
        font-size: 19px;
        margin-bottom: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: 46px;
        opacity: 0.7;
        word-break: break-word;
      }
      p {
        font-weight: 300;
        font-size: 13px;
        height: 35px;

        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    .itemWithBanner {
      height: 420px;
      padding: 0;
      .banner {
        width: 100%;
        height: 205px;
        margin-bottom: 0;
        background: rgba($blue-primary, 0.6);
        background-size: cover;
        background-repeat: no-repeat;
      }
      .persons-content {
        padding-left: 12px;
        margin-left: 0px;
        margin-top: 0;
      }
      .info-wrapper {
        padding: 12px;
        p {
          height: 35px;
          word-break: break-word;
        }
        h2 {
          margin-bottom: 10px;
        }
      }
    }
    .persons-content {
      display: flex;
      align-items: center;
      border-top: 1px solid #ededfc;
      margin-top: 10px;
      padding-left: 12px;
      position: relative;

      .avatar {
        height: 40px;
        width: 40px;
        border-radius: 100%;
        background-color: #ededfc;
        border: 3px solid white;
        margin-left: -11px;
        margin-top: 10px;
        background-size: cover;
        background-repeat: no-repeat;

        &:first-child {
          z-index: 5;
          margin-left: 1px;
        }
        &:nth-child(2) {
          z-index: 4;
        }
        &:nth-child(3) {
          z-index: 3;
        }
        &:nth-child(4) {
          z-index: 2;
        }
        &:nth-child(5) {
          z-index: 1;
        }
        &:nth-child(6) {
          z-index: 0;
        }
      }
      .count {
        height: 40px;
        width: 40px;
        border-radius: 100%;
        border: 1px solid #ededfc;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.6875rem;
        color: #9696b9;
        margin-top: 10px;
        margin-left: -11px;
        padding-left: 5px;
      }
    }
  }

  // .column-one {
  //     li {
  //         background-color: #fff;
  //     }
  // }
}

.news-attachments {
  width: 100%;
  padding: 0.75rem 0;

  .attached-files {
    width: 10rem;
    height: 3.1625rem;
    border-radius: 0.375rem;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .single-file{
      display: flex;
      align-items: center;
      margin-left: 0.9375rem;
      padding: 0.4375rem 0.4375rem;
      border-radius: 0.5rem;
      box-shadow: 0 0 0.625rem rgba($blue-primary, 0.1);

      &:first-child{
        margin-left: 0;
      }
      cursor: pointer;
      &.border {
        border: 0.0625rem solid #dedff4;
      }

    }

    .file-name {
      width: 5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $blue-primary;
      font-size: 0.875rem;
    }

    .file-block {
      width: 1.875rem;
      height: 1.875rem;
      font-size: 0.8125rem;
      display: grid;
      place-items: center;
      color: #fff;
      margin-right: 0.625rem;
      border-radius: 0.375rem;
      cursor: pointer;

      &[type="pdf"] {
        background-color: #ff6760;
      }

      &[type="xlsx"] {
        background-color: rgba(17, 123, 65, 0.871);
      }
      &[type="PNG"],
      [type="png"] {
        background-color: $green-primary;
      }
      &[type="SVG"],
      [type="svg"] {
        background-color: $green-primary;
      }
      &[type="jpg"] {
        background-color: rgba(17, 123, 65, 0.871);
      }
      &[type="txt"] {
        background-color: #364ba2;
      }

      &[type="docx"],
      &[type="doc"] {
        background-color: #364ba2;
      }
    }

    .delete {
      position: absolute;
      top: 0.125rem;
      right: 0.3125rem;
      font-size: 0.8125rem;
      color: $blue-primary;
      cursor: pointer;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}

.file-block{
    width: 1.875rem;
    height: 1.875rem;
    font-size: 0.8125rem;
    display: grid;
    place-items: center;
    color: #fff;
    margin-right: 0.625rem;
    border-radius: 0.375rem;

    &[type="pdf"] {
        background-color: #ff6760;
    }

    &[type="xlsx"] {
        background-color: rgba(17, 123, 65, 0.871);
    }
    &[type="PNG"],[type="png"] {
        background-color: $green-primary;
    }
    &[type="SVG"],[type="svg"] {
        background-color: $green-primary;
    }
    &[type="jpg"] {
        background-color: rgba(17, 123, 65, 0.871);
    }
    &[type="txt"] {
        background-color: #364ba2;
    }

    &[type="docx"],&[type="doc"] {
        background-color: #364ba2;
    }
    &[type="jpeg"]{
        background-color: $green-primary;
    }
}


</style>