<template>
  <label class="custom-file-input">
    <div class="select-button">
      <span v-if="value && value.filename">{{ value.filename }}</span>
      <span v-else>{{ title }}</span>
    </div>
    <!-- <img class="preview" ref="preview" alt="" /> -->
    <input type="file" :isRequired="isRequired" @change="onChange" :disabled="disabled"/>
  </label>
</template>
`1`
<script>
import { toBase64 } from "@/Helpers/HelperFunctions";

export default {
  props: {
    title: String,
    value: [Object || File],
    isRequired: {
      type: Boolean,
      default: false,
    },
    disabled:{
      type:Boolean,
      default: false,
    }
  },
  methods: {
    async onChange(e) {
      const file = e.target.files[0];

      const object = {
        size: file.size,
        type: file.type,
        filename: file.name,
        base64: await toBase64(file),
      };

      this.readURL(file);

      this.$emit("change", object);
    },
    readURL(file) {
      const reader = new FileReader();

      // reader.onload = (e) => {
      //   this.$refs.preview.src = e.target.result;
      // };

      reader.readAsDataURL(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-file-input > .select-button {
  cursor: pointer;
  width: 197 * $rem;
  display: inline-block;
  height: 40 * $rem;
  line-height: 40 * $rem;
  padding: 0 15 * $rem 0 15 * $rem;
  outline: none;
  color: #474747;
  border-radius: 5 * $rem;
  box-shadow: none;
  border: 1 * $rem solid $grey-border;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    border: 1 * $rem solid $green-primary;
  }
}

.custom-file-input > input[type="file"] {
  display: none;
}

.custom-file-input {
  display: flex;
  flex-direction: column;
}

.preview {
  cursor: pointer;
  margin-top: 5px;
  width: 197 * $rem;
  height: auto;
}
</style>
