<template>
  <div class="container" @click="clickedStatusID = ''">
    <div class="filters">
      <div class="filters-container">
        <div class="clear-all" @click="clearAllFilters">
          <span>Clear</span>
        </div>
        <div class="filter-wrapper">
          <!-- <custom-multi-select 
            :departments="departmentFilters"
          /> -->
          <select-persons
            :departments="departmentFilters"
            @select="(val) => selectDropdown(val)"
            :withoutDeps="false"
            :attendanceTitle="true"
            :clear="triggerDropdownClear"
          />
        </div>
        <!-- <div class="filter-wrapper" v-if="!userPage">
          <choose-persons
            :title="'სახელი, გვარი'"
            :allPersons="allPersons"
            @person-clicked="personClickHanlder"
            @clear="filters.selectedPersons = []"
          />
        </div> -->
        <div class="filter-wrapper job" v-if="!userPage">
          <choose-persons
            :title="'პოზიცია'"
            :allPersons="allJobs"
            @person-clicked="jobClickHandler"
            @clear="filters.jobs = []"
          />
        </div>
        <div class="filter-wrapper date">
          <date-picker
            :addCustom="'month'"
            title="თვე"
            v-model="filters.pickedDate"
            :alternativeIcon="true"
          />
        </div>
        <div class="filter-wrapper status">
          <custom-select
            :options="statusOptions"
            :title="'სტატუსი'"
            :clearVisible="true"
            v-model="filters.selectedStatus"
            @clear="filters.selectedStatus = ''"
          />
        </div>
      </div>
      <div class="export-button" @click="exportTable">
        <span>Export</span>
      </div>
    </div>

    <div class="white-container" ref="scrollContainer">
      <div class="attendance-report">
        <div class="left-side">
          <div class="header-wrapper">
            <div class="header">
              <span>სულ ნამუშევარი თვის განმავლობაში</span>
            </div>
          </div>
          <table class="table-one">
            <thead>
              <tr>
                <th v-for="col in columnsOne" :key="col.title">
                  <div
                    class="title-container"
                    :class="{ invisible: col.invisible }"
                  >
                    <div class="wrapper">
                      <span>{{ col.title }}</span>
                      <span
                        v-if="col.descText"
                        @mouseenter="hoveredColumn = col.title"
                        @mouseleave="hoveredColumn = ''"
                        class="desc-icon"
                      >
                        i
                      </span>
                    </div>
                    <div
                      v-if="col.extraTitle"
                      class="extra-text"
                      :class="{ invisible: userPage }"
                    >
                      {{ col.extraTitle }}
                    </div>
                    <div
                      v-if="col.descText && hoveredColumn === col.title"
                      class="desc-text"
                    >
                      {{ col.descText }}
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody @mouseleave="hoveredRow = ''">
              <template v-for="(el, index) in data">
                <tr
                  @click="getReportById(el)"
                  :key="index"
                  @mouseenter="hoveredRow = el.ID"
                  :class="{ hovered: hoveredRow === el.ID }"
                >
                  <template v-for="(col, index) in columnsOne">
                    <td :key="index" :class="{ hovered: hoveredRow === el.ID }">
                      <div class="value-content">
                        <span
                          v-if="col.type === 'text' || col.type === 'date'"
                          class="value"
                          :class="[{ name: col.boldFont }]"
                        >
                          {{
                            col.type !== 'date'
                              ? el[col.key]
                              : getDateString(el[col.key], 'MMM yyyy')
                          }}
                        </span>
                        <span
                          v-else
                          class="value"
                          :class="{
                            'positive-number':
                              col.positiveNumber && el[col.key] > 0,
                          }"
                        >
                          {{ el[col.key] > 0 ? el[col.key] : '-' }}
                        </span>
                        <span
                          :title="el[col.extraValue]"
                          class="extra-value"
                          v-if="col.extraValue"
                        >
                          {{ el[col.extraValue] }}
                        </span>
                      </div>
                    </td>
                  </template>
                </tr>
                <tr :key="el.index" class="spacer"></tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="right-side">
          <div class="header-wrapper">
            <div class="header">
              <span>არ გამოცხადების დღეების რ-ბა</span>
            </div>
          </div>
          <table class="table-two">
            <thead>
              <tr>
                <th v-for="col in columnsTwo" :key="col.title">
                  <div class="title-container">
                    <div class="wrapper">
                      <span>{{ col.title }}</span>
                      <span
                        @mouseenter="hoveredColumn = col.title"
                        @mouseleave="hoveredColumn = ''"
                        class="desc-icon"
                      >
                        i
                      </span>
                    </div>
                    <div
                      v-if="col.descText && hoveredColumn === col.title"
                      class="desc-text"
                    >
                      {{ col.descText }}
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody @mouseleave="hoveredRow = ''">
              <template v-for="(el, index) in data">
                <tr
                  @click="getReportById(el)"
                  :key="index"
                  @mouseenter="hoveredRow = el.ID"
                  :class="{ hovered: hoveredRow === el.ID }"
                >
                  <template v-for="col in columnsTwo">
                    <td
                      :key="col.title"
                      :class="{ hovered: hoveredRow === el.ID }"
                    >
                      <span
                        :style="{
                          color:
                            el[col.key] > 0 && col.key === 'AbsentDay'
                              ? '#FF3356'
                              : null,
                        }"
                      >
                        {{ el[col.key] > 0 ? el[col.key] : '-' }}
                      </span>
                    </td>
                  </template>
                </tr>
                <tr class="spacer" :key="el.index"></tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="statuses">
          <div class="header"></div>
          <div
            class="status"
            @click.stop="clickedStatusID = el.ID"
            :style="[
              el.Status === true
                ? { background: '#8833FF' }
                : el.Status === false
                ? { background: '#FF3356' }
                : { background: '#ff9233ec' },
              el.Status === true && userPage ? { cursor: 'not-allowed' } : null,
            ]"
            v-for="(el, index) in data"
            :key="index"
          >
            <span>
              {{
                el.Status === true
                  ? 'Approved'
                  : el.Status === false
                  ? 'Rejected'
                  : 'Pending'
              }}
            </span>
            <div
              class="select-content"
              v-if="clickedStatusID === el.ID && !userPage"
            >
              <div
                class="approve"
                v-if="el.Status === false || el.Status === null"
                @click="approveAttendance(true)"
              >
                Approve
              </div>
              <div
                class="reject"
                v-if="el.Status === true || el.Status === null"
                @click="approveAttendance(false)"
              >
                Reject
              </div>
              <div
                @click="approveAttendance(null)"
                class="pending"
                v-if="el.Status !== null"
              >
                Pending
              </div>
            </div>
            <div
              class="select-content"
              v-else-if="
                clickedStatusID === el.ID && userPage && el.Status !== true
              "
            >
              <div
                class="approve"
                @click="approveAttendance(true)"
                v-if="el.Status === false || el.Status === null"
              >
                Approve
              </div>
              <div
                class="reject"
                @click="approveAttendance(false)"
                v-if="el.Status === true || el.Status === null"
              >
                Reject
              </div>
              <div
                @click="approveAttendance(null)"
                class="pending"
                v-if="el.Status !== null"
              >
                Pending
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <custom-popup :visible="popupVisible" @close="popupVisible = false">
      <div class="popup-container" v-if="reportByID">
        <p>{{ getDateString(reportByID.Date, 'MMM yyyy') }}</p>
        <div class="top">
          <h3>სულ ნამუშევარი თვის განმავლობაში</h3>
          <div class="row">
            <span>დღეები</span>
            <span>{{ numberOrNull(reportByID.DayCount) }}</span>
          </div>
          <div class="row">
            <span>საათები</span>
            <div class="percent-hours">
              <span class="percent">{{ reportByID.Percentage }}%</span>
              <span
                :class="[
                  {
                    'positive-number':
                      reportByID.HasOffTime && reportByID.WorkedTime > 0,
                  },
                  {
                    'negative-number':
                      !reportByID.HasOffTime && reportByID.WorkedTime > 0,
                  },
                ]"
              >
                {{ numberOrNull(reportByID.WorkedTime) }}
              </span>
            </div>
          </div>
          <div class="row">
            <span>ზეგანაკვეთური</span>
            <span :class="{ 'positive-number': reportByID.OverTime > 0 }">
              {{ numberOrNull(reportByID.OverTime) }}
            </span>
          </div>
          <div class="row">
            <span>ღამის სმენაში ნამუშევარი საათები</span>
            <span>{{ numberOrNull(reportByID.NightTime) }}</span>
          </div>
          <div class="row">
            <span>დასვენების/უქმე დღეებში ნამუშევარი სთ</span>
            <span>{{ numberOrNull(reportByID.NotWorkingday) }}</span>
          </div>
        </div>
        <div class="bottom">
          <h3>არ გამოცხადების დღეები</h3>
          <div class="row">
            <span>ანაზღაურებადი შვებულება</span>
            <span>{{ numberOrNull(reportByID.VacationTotalDays) }}</span>
          </div>
          <div class="row">
            <span>ანაზღაურების გარეშე შვებულება</span>
            <span>{{ numberOrNull(reportByID.UnPaidVacation) }}</span>
          </div>
          <div class="row">
            <span>საავადმყოფო ფურცელი</span>
            <span>{{ numberOrNull(reportByID.SickDayVacation) }}</span>
          </div>
          <div class="row">
            <span>გაცდენა</span>
            <span :class="{ 'negative-number': reportByID.AbsentDay > 0 }">
              {{ numberOrNull(reportByID.AbsentDay) }}
            </span>
          </div>
          <div class="row">
            <span>დასვენების და უქმე დღეები</span>
            <span>{{ numberOrNull(reportByID.Holidays) }}</span>
          </div>
        </div>
        <div class="buttons">
          <div
            class="orange-button"
            @click="approveAttendance(true)"
            v-if="reportByID.Status === null || reportByID.Status === false"
          >
            ვეთანხმები, ვადასტურებ
          </div>
          <div
            class="reject"
            @click="approveAttendance(false)"
            v-if="reportByID.Status === null"
          >
            არ ვეთანხმები
          </div>
          <div class="accepted" v-if="reportByID.Status === true">
            დადასტურებული
          </div>
        </div>
      </div>
    </custom-popup>
  </div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance.js'
import CustomPopup from '../ReusableComponents/CustomPopup/CustomPopup.vue'
import choosePersons from '@/components/ReusableComponents/ChoosePersons/choosePersons.vue'
import DatePicker from '@/components/ReusableComponents/DatePicker/DatePicker.vue'
// import SearchInput from "@/components/ReusableComponents/SearchInput/SearchInput.vue";
import CustomSelect from '@/components/ReusableComponents/CustomSelect/CustomSelect.vue'
import SelectPersons from '@/components/FixedSchedules/SelectPersons.vue'
// import CustomMultiSelect from '@/components/ReusableComponents/CustomMultiSelect/CustomMultiSelect.vue'

export default {
  components: {
    CustomPopup,
    choosePersons,
    DatePicker,
    // SearchInput,
    CustomSelect,
    SelectPersons,
    // CustomMultiSelect,
  },

  created() {
    this.getDropdownFilters()

    let date = new Date()
    this.month = date.setMonth(date.getMonth() - 1)

    this.getData(this.month, true)
    this.getPersons()
    this.getJobs()
  },

  props: {
    userPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      departmentFilters: [],
      triggerDropdownClear: false,
      statusOptions: [
        {
          title: 'All',
          value: 2,
        },
        {
          title: 'Pending',
          value: null,
        },
        {
          title: 'Approved',
          value: 1,
        },
        {
          title: 'Rejected',
          value: 0,
        },
      ],
      data: [],
      clickedStatusID: '',
      month: '',
      filters: {
        selectedPersons: [],
        jobs: [],
        pickedDate: '',
        selectedStatus: 2, //default status All
      },
      hoveredColumn: '',
      popupVisible: false,
      allJobs: [],
      allPersons: [],
      hoveredRow: '',
      reportByID: {},
      columnsOne: [
        {
          title: '#',
          key: 'PersonID',
        },
        {
          title: 'სახელი, გვარი',
          extraTitle: '(პოზიცია)',
          key: 'FullName',
          extraValue: 'JobName',
          boldFont: true,
          type: 'text',
          invisible: this.userPage,
        },
        {
          title: 'თვე',
          key: 'Date',
          type: 'date',
        },
        {
          title: 'დღე',
          key: 'DayCount',
        },
        {
          title: 'საათი',
          key: 'WorkedTime',
        },
        {
          title: 'ზეგანაკვეთური',
          key: 'OverTime',
          positiveNumber: true,
        },
        {
          title: 'ღამე',
          descText:
            'თვის განმავლობაში ღამე ნამუშევარი საათების ჯამი (იანგარიშება 22:00-06:00 მდე პერიოდის საათები)',
          key: 'NightTime',
        },
        {
          title: 'უქმე',
          descText:
            'დასვენების, მათ შორის “ვიქენდებზე” და  უქმე დღეებში ნამუშევარი საათების ჯამური რაოდენობა',
          key: 'NotWorkingday',
        },
      ],
      columnsTwo: [
        {
          title: 'შ',
          key: 'VacationTotalDays',
          descText: 'ანაზღაურებადი შვებულება და ე.წ. დეი ოფი',
        },
        {
          title: 'უხ/შ',
          key: 'UnPaidVacation',
          descText: 'შვებულება ანაზღაურების გარეშე',
        },
        {
          title: 'ს/ფ',
          key: 'SickDayVacation',
          descText: 'გაცდენა ავადმყოფობის გამო',
        },
        {
          title: 'გ',
          key: 'AbsentDay',
          descText: 'გაცდენა',
        },
        {
          title: 'X',
          key: 'Holidays',
          descText: 'დასვენების და უქმე დღეები',
        },
      ],
    }
  },
  methods: {
    clearAllFilters() {
      this.filters.selectedPersons = []
      this.filters.jobs = []
      this.filters.pickedDate = ''
      this.filters.selectedStatus = 2
      this.triggerDropdownClear = !this.triggerDropdownClear
    },
    selectDropdown(val) {
      if (val.length && val.length > 0) {
        this.filters.selectedStatus = 2
      }
      this.filters.selectedPersons = val
    },
    getDropdownFilters() {
      axios.get('News/GetPlacementDropDownPersons').then((resp) => {
        let data = resp.data.Value.DepartmentJobPersons
        let mapped = data.map((dep) => ({
          name: dep.DepartmentName
            ? dep.DepartmentName
            : dep.DepartmentName === null
            ? 'Without Department'
            : dep.DepartmentName,
          id: dep.DepartmentID,
          jobs: dep.JobPersons.map((job) => ({
            id: job.JobID,
            name: job.JobName,
            people: job.Persons.map((person) => ({
              name: `${person.FirstName} ${person.LastName}`,
              id: person.PersonID,
            })),
          })),
        }))
        this.departmentFilters = mapped
      })
    },
    getReportById(row) {
      axios
        .post('attendance/GetAttendanceReport', {
          id: row.ID,
          jobIDs: [],
          personIDs: [],
        })
        .then((resp) => {
          this.popupVisible = true
          this.reportByID = resp.data.Value[0]
        })
    },
    // scrolledToBottom(event) {
    //   this.clickedStatusID = "";
    //   let el = event.srcElement;
    //   if (el.scrollTop >= el.scrollHeight - el.clientHeight) {
    //     let date = new Date(this.month);
    //     date.setMonth(date.getMonth() - 1);
    //     this.month = date.getTime();
    //     this.getData(this.month, false);
    //   }
    // },
    exportTable() {
      axios
        .post('attendance/GetAttendanceReportExcel', {
          date: this.month,
          departmentID: null,
          personIDs:
            this.filters.selectedPersons.length > 0
              ? this.filters.selectedPersons.map((el) => ({ id: el }))
              : [],
          departmentIDs: [],
          jobIDs: [],
          status: this.filters.selectedStatus,
        })
        .then((resp) => {
          this.downloadFile(resp.data)
        })
    },
    downloadFile(el) {
      var a = document.createElement('a')
      let fileName = 'AttendanceReport.xlsx'
      let fileType = 'application/vnd.ms-excel'
      a.href = `data:${fileType};base64,` + el
      a.download = fileName
      a.click()
    },
    // exportToExcel(response, fileName) {
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", `${fileName}.xlsx`);
    //   document.body.appendChild(link);
    //   link.click();
    // },
    numberOrNull(val) {
      return val > 0 ? val : '-'
    },
    getPersons() {
      axios.get('Person/GetPersonsBasicInfo').then((resp) => {
        this.allPersons = resp.data.Value.map((el) => ({
          title: el.FirstNameGeo + ' ' + el.LastNameGeo,
          value: el.ID,
          selected: el.selected,
        }))
      })
    },
    personClickHanlder(el) {
      el.selected = !el.selected
      const exist = this.filters.selectedPersons.includes(el.value)
      if (exist) {
        this.filters.selectedPersons = this.filters.selectedPersons.filter(
          (element) => element != el.value,
        )
      } else {
        this.filters.selectedPersons.push(el.value)
      }
    },
    jobClickHandler(el) {
      el.selected = !el.selected
      const exist = this.filters.jobs.includes(el.value)
      if (exist) {
        this.filters.jobs = this.filters.jobs.filter(
          (element) => element != el.value,
        )
      } else {
        this.filters.jobs.push(el.value)
      }
    },
    approveAttendance(status) {
      axios
        .post('attendance/ApproveAttendanceReport', {
          id: this.reportByID.ID || this.clickedStatusID,
          status: status,
        })
        .then((resp) => {
          this.getData(this.month, true)
          this.clickedStatusID = ''
          this.popupVisible = false
        })
    },
    getData(date, isFirst) {
      axios
        .post('attendance/GetAttendanceReport', {
          Date: date,
          departmentID: null,
          ID: null,
          personIDs: this.filters.selectedPersons.length
            ? this.filters.selectedPersons.map((el) => {
                return {
                  id: el,
                }
              })
            : [],

          status: this.filters.selectedStatus,
          jobIDs: this.filters.jobs.length
            ? this.filters.jobs.map((el) => {
                return {
                  id: el,
                }
              })
            : [],
        })
        .then((resp) => {
          if (isFirst) {
            this.data = resp.data.Value
          } else {
            this.data.push(...resp.data.Value)
          }
        })
    },
    getJobs() {
      axios.get('jobs/GetJobs').then((resp) => {
        if (resp.data.ErrorMsg) return
        this.allJobs = resp.data.Value.Jobs.map((el) => ({
          title: el.NameGeo,
          value: el.ID,
          selected: el.selected,
        }))
      })
    },

    getDateString(date, format = 'dd MMM yyyy') {
      if (!(date instanceof Date)) {
        date = new Date(date)
      }
      if (date === 'Invalid Date') return

      const MONTHS = [
        'იან',
        'თებ',
        'მარ',
        'აპრ',
        'მაი',
        'ივნ',
        'ივლ',
        'აგვ',
        'სექ',
        'ოქტ',
        'ნოე',
        'დეკ',
      ]
      const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

      var days = ('0' + date.getDate()).slice(-2),
        month = ('0' + (date.getMonth() + 1)).slice(-2),
        year = date.getFullYear(),
        hours = ('0' + date.getHours()).slice(-2),
        minutes = ('0' + date.getMinutes()).slice(-2),
        seconds = ('0' + date.getSeconds()).slice(-2)

      return format
        .replace('dd', days)
        .replace('MMM', MONTHS[parseInt(month) - 1])
        .replace('MM', month)
        .replace('yyyy', year)
        .replace('yy', String(year).slice(-2))
        .replace('HH', hours)
        .replace('mm', minutes)
        .replace('ss', seconds)
        .replace('ww', WEEKDAYS[date.getDay()])
    },
  },
  watch: {
    filters: {
      handler() {
        this.$refs.scrollContainer.scrollTop = 0
        if (this.filters.pickedDate) {
          this.month = this.filters.pickedDate
        }
        this.getData(this.month, true)
      },

      deep: true,
    },
  },
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: 'teli light';

  src: url('~@/assets/fonts/Teli-MT-Light.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'teli bold';

  src: url('~@/assets/fonts/Teli-MT-Bold.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'firago lowercase';

  src: url('~@/assets/fonts/FiraGO-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
::v-deep .overlay .popup {
  width: 640px;
  height: auto;
  max-height: 756px;
  padding: 37px 83px;
  > h3 {
    display: none;
  }
}
::v-deep .el-input__icon {
  display: none;
}
::v-deep .el-input__inner:focus {
  outline: none;
}
.popup-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  p {
    font-size: 18px;
    color: #4d5e80;
    margin-bottom: 20px;
    font-weight: 900;
  }
  h3 {
    font-size: 14px;
    color: #4d5e80;
    margin-bottom: 15px;
  }
  .top {
    margin-bottom: 70px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .percent {
      margin-right: 20px;
      color: #8833ff !important;
    }
    span:nth-child(1) {
      color: #9696b9;
    }
    span:nth-child(2) {
      color: #8833ff;
      font-weight: 900;
      font-size: 16px;
    }
    .negative-number {
      color: #ff3356 !important;
    }
    .positive-number {
      color: #1de9b6 !important;
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      width: 332px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      color: #ffffff;
      font-size: 13px;
      cursor: pointer;
      margin-top: 10px;
    }
    .orange-button {
      background-color: #ff9233ec;
    }
    .reject {
      background-color: #ff3356;
    }
    .accepted {
      background-color: #8833ff;
      pointer-events: none;
    }
  }
}
.container {
  padding: 33px 20px;
  font-family: 'teli light';
  height: 100%;
  .filters {
    height: 96px;
    background-color: #fff;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    padding: 0 45px;
    border-radius: 20px 20px 0 0;

    .filters-container {
      display: flex;
      margin-left: auto;

      .clear-all {
        @include centerElement();
        padding-right: 0.75rem;
        position: relative;

        &:hover {
          span {
            color: rgba($red, 0.5);
          }
          &::before {
            height: calc(100% - 0.75rem);
          }
        }
        span {
          color: rgba($blue-primary, 0.5);
          font-size: 1rem;
          cursor: pointer;
          transition: ease 0.3s;
        }
        &::before {
          content: '';
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translate(0, -50%);
          height: 0;
          width: 0.0625rem;
          background: rgba(#5769d6, 0.8);
          transition: ease 0.3s;
          background: red;
        }
      }
    }
    .filter-wrapper {
      width: 15.625rem;
      font-size: 13px;
      margin-right: 10px;

      &.date {
        ::v-deep label {
          height: 40px;
          width: 15.625rem;
        }
      }
      ::v-deep .container {
        height: 40px;
        .title {
          font-size: 14px;
        }
      }
      &.status {
        ::v-deep .input-content {
          height: 40px;
          label {
            width: 15.625rem;
          }
          .single-option {
            font-size: 15px;
          }
        }
      }
    }
    .export-button {
      background-color: #8833ff;
      border-radius: 5px;
      width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 12px;
      font-weight: 700;
      cursor: pointer;
      margin-left: auto;
    }
  }
}

.white-container {
  background-color: #fff;
  padding: 0px 10px 10px 10px;
  height: calc(100% - 100px);
  overflow: auto;
}
.attendance-report {
  border-radius: 10px;
  display: flex;
}

.table-one,
.table-two {
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-spacing: unset;
  //   table-layout: fixed;

  thead {
    font-size: 12px;
    color: #8833ff;
    font-family: 'teli bold';
    width: 100%;
    position: sticky;
    top: 62px;
    z-index: 10;
    background-color: #f5f5fc;
    tr {
      th:first-child {
        padding-left: 20px;

        border-bottom-left-radius: 10px;
      }
      th:last-child {
        border-bottom-right-radius: 10px;
      }
      th {
        vertical-align: text-top;
        padding-bottom: 10px;
        .title-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: relative;
          &.invisible {
            color: transparent;
          }
          .wrapper {
            display: flex;
            align-items: center;
          }
        }
        .desc-text {
          padding: 5px 10px;
          background-color: #ff9233ec;
          border-radius: 10px;
          color: #ffffff;

          font-weight: 400;
          position: absolute;
          top: 25px;
          font-family: 'firago lowercase';
          right: 0;
          white-space: nowrap;
        }
        .desc-icon {
          width: 16px;
          background-color: #ff9233;
          height: 16px;
          display: flex;
          justify-content: center;
          cursor: pointer;
          opacity: 0.6;
          color: #ff3356;
          align-items: center;
          border-radius: 100%;
          margin-left: 5px;
        }

        .extra-text {
          &.invisible {
            color: transparent;
          }
          color: #97a1c2;
          font-family: 'teli light';
        }
      }
    }
  }
  tbody {
    &::before {
      content: '@';
      display: block;
      line-height: 10px;
      text-indent: -99999px;
    }
    width: 100%;
    tr {
      margin-bottom: 5px;
      height: 60px;
      font-size: 14px;
      color: #97a1c2;
      font-weight: 300;
      &.hovered {
        background-color: #fbfafa;
      }
      &.spacer {
        height: 5px;
      }
      td:nth-child(2) {
        // width: 300px;
      }
      td {
        border: 1px solid #959bc9;
        border-left: 0;
        border-right: 0;

        &.hovered {
          border: 1px solid #8833ff;
          border-left: 0;
          border-right: 0;
        }
        .value-content {
          display: flex;
          flex-direction: column;
          .extra-value {
            margin-top: 3px;
            max-width: 330px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .value {
            &.name {
              font-family: 'teli bold';
            }
            &.positive-number {
              color: #2ebd59;
            }
            &.negative-number {
              color: #ff3356;
            }
          }
        }
      }
      td:first-child {
        border-left: 1px solid #959bc9;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-left: 15px;
        &.hovered {
          border: 1px solid #8833ff;

          border-right: 0;
        }
      }
      td:last-child {
        border-right: 1px solid #959bc9;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        &.hovered {
          border: 1px solid #8833ff;

          border-left: 0;
        }
      }
    }
  }
}

.statuses {
  &::before {
    content: '@';
    display: block;
    line-height: 20px;
    text-indent: -99999px;
    position: sticky;
    top: 0;
    background-color: #ffffff;
  }
  .header {
    height: 95px;
    background-color: #fff;
    z-index: 10;
    position: sticky;
    top: 0;
  }

  .status {
    width: 100px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    border-radius: 6px;
    margin-bottom: 5px;
    position: relative;
    cursor: pointer;
  }
  .select-content {
    position: absolute;
    top: 55px;
    z-index: 5;
    left: -50px;
    background-color: #ffffff;

    box-shadow: 0.125rem 0.125rem 0.625rem 0.125rem rgba(40, 52, 147, 0.08);

    width: 120px;
    border-radius: 6px;
    height: auto;
    div {
      padding: 10px;
    }
    .approve {
      color: rgb(24, 204, 159);
    }
    .reject {
      color: #ff3356;
    }
    .pending {
      color: #959bc9;
    }
    cursor: pointer;
    div:hover {
      border-radius: 6px;

      background-color: #e3e4eb;
    }
  }
}
.left-side,
.right-side {
  &::before {
    content: '@';
    display: block;
    line-height: 10px;
    text-indent: -99999px;
    position: sticky;
    top: 0;
    background-color: #ffffff;
  }
  .header-wrapper {
    background-color: #ffffff;
    position: sticky;
    top: 10px;
    z-index: 10;
  }
  .header {
    background-color: #f5f5fc;
    padding: 22px 62px 7px 70px;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    z-index: 10;
  }
}
.left-side {
  margin-right: 10px;
  width: 70%;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
  .header {
    font-family: 'teli bold';
    color: #4d5e80;
    text-align: right;
    padding-right: 150px;
  }
}
.right-side {
  width: 25%;

  margin-right: 10px;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
  .header {
    font-family: 'teli bold';
    color: #4d5e80;
    padding-left: 10px;
    text-align: center;
    padding: 22px 0 7px 0;
  }
  .table-two {
    table-layout: fixed;
    th:nth-child(1),
    td:nth-child(2) {
      padding-left: 15px;
    }
    th {
      padding-bottom: 26px;
    }
  }
}
.table-one {
  td:nth-child(2) {
    width: 350px;
  }
}
</style>
