<template>
    <div :class="['loader',{'fixed':fixed}]">
      <div class="wrapper">
        <img class="dots" :class="{'alternativeSize':alternativeSize}" src="@/assets/gifs/dualBall.svg" alt="">
      </div>
    </div>
</template>

<script>
export default {
    props:{
      alternativeSize: {
        type: Boolean,
      },
      fixed:{
        type: Boolean,
        default: false,
      }
    }
}
</script>

<style lang="scss" scoped>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  user-select: none;
  z-index: 999999999;

  &.fixed{
    position: fixed !important;
  }

  .wrapper{
    
    img{
  
      &.alternativeSize{
        width: 19rem !important;
      }
    }
  }


  .dots {
    border-radius: 50%;
    display: inline-block;
    margin-left: 1.5625rem;
    animation: loading 1s infinite;
    animation-fill-mode: forwards;

    &:nth-child(1) {
      animation-delay: 0.2s;
      margin-left: 0;
    }

    &:nth-child(2) {
      animation-delay: 0.4s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }

    @keyframes loading {
      0%,
      20% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}
</style>