<template>
    <div class="background">
        <div class="permissions">
            <permissions-header
                :title="headerTitle"
                :node-to-edit="!!nodeToEdit"
                :current-section="visibleSection"
                :for-assign="forAssign"
                @search="(val) => (searchTerm = val)"
                @change-section="(section) => (visibleSection = section)"
                @add-click="addToNodePopupVisible = true"
            />
            <permission-section
                v-show="visibleSection == SECTIONS.PERMISSIONS"
                :permissionsList="allPermissions"
                :nodes="nodes"
                :node-to-edit="nodeToEdit"
                :searchTerm="searchTerm"
                :popupVisible="addToNodePopupVisible"
                @close-popup="addToNodePopupVisible = false"
                @close-edit-node="closeNode"
                @get-permissions="getPermissions"
            />
            <permission-nodes
                @update-permissions="getPermissions"
                @edit-node="editNode"
                :for-assign="forAssign"
                :permission-without-nodes="permissionWithoutNodes"
                :nodes="nodes"
                v-show="visibleSection == SECTIONS.NODES"
                :type="type"
                :id="objectId"
            />
        </div>
    </div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance.js'
import PermissionsHeader from './PermissionsHeader'
import PermissionSection from './PermissionSection/PermissionSection'
import PermissionNodes from '@/components/Permissions/PermissionNodes/PermissionNodes'

export default {
    components: {
        PermissionNodes,
        PermissionsHeader,
        PermissionSection,
    },
    props: {
        currentSection: {
            type: [Number, String],
            default: 1,
        },
        role: Object,
        person: Object,
        job: Object,
    },
    async mounted() {
        await this.getPermissions()
        this.visibleSection = this.currentSection

        if (this.job) {
            this.type = 'job'
            this.objectId = this.job.ID
        } else if (this.role) {
            this.type = 'role'
            this.objectId = this.role.ID
        } else if (this.person) {
            this.type = 'person'
            this.objectId = this.person.ID
        }
    },
    data() {
        return {
            allPermissions: [],
            permissionsData: [],
            searchTerm: '',
            visibleSection: 0,
            SECTIONS: {
                PERMISSIONS: 1,
                NODES: 2,
            },
            addToNodePopupVisible: false,
            objectId: null,
            type: null,
            nodeToEdit: null,
        }
    },
    computed: {
        allPermissionsTree() {
            var copy = this.allPermissions
            copy.forEach((permission) => {
                let parentID = permission.ParentNode
                let parent = this.allPermissions.filter((el) => el.ID == parentID)[0]
                if (!parent) return
                if (!parent.children) parent.children = [permission]
                else parent.children.push(permission)
            })
            return copy.filter((el) => !el.ParentNode)
        },
        forAssign() {
            return !!(this.role || this.person || this.job)
        },
        nodes() {
            const nodes = this.permissionsData.filter((el) => el.IsNode)
            const permissions = this.permissionsData.filter((el) => !el.IsNode)
            let allNodes = nodes.map((el) => {
                const nodePermissions = permissions.filter(
                    (permission) => permission.ParentNode === el.ID,
                )
                return {
                    ID: el.ID,
                    Name: el.Name,
                    Permissions: nodePermissions,
                    PermissionCount: nodePermissions.length,
                    PermissionActiveCount: 0,
                }
            })
            if (this.searchTerm.length) {
                return allNodes.filter(
                    (el) =>
                        el.Name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                        el.Permissions.filter((perm) =>
                            perm.Name.toLowerCase().includes(this.searchTerm.toLowerCase()),
                        ).length,
                )
            }
            return allNodes
        },
        permissionWithoutNodes() {
            const permissions = this.permissionsData.filter((el) => !el.ParentNode && !el.IsNode)
            if (this.searchTerm.length) {
                return permissions.filter((el) =>
                    el.Name.toLowerCase().includes(this.searchTerm.toLowerCase()),
                )
            }
            return permissions
        },
        headerTitle() {
            if (!this.role && !this.job && !this.person) {
                return this.visibleSection == 1
                    ? `Permissions ${this.allPermissions.length}`
                    : `Nodes ${this.nodes.length}`
            }
            if (this.job) {
                return `<span style="color: #283593; opacity: 0.5">Assign permissions to </span> to ${this.job.Name}`
            } else if (this.role) {
                return `<span style="color: #283593; opacity: 0.5">Assign permissions to</span> ${this.role.Name}`
            } else if (this.person) {
                return `<span style="color: #283593; opacity: 0.5">Assign permissions to </span> ${this.person.FirstName} ${this.person.LastName} `
            }
            return null
        },
    },
    methods: {
        async getPermissions() {
            const response = await axios.get('Permission/GetPermissions')
            this.allPermissions = response.data.Value.filter((el) => !el.IsNode)
            this.permissionsData = response.data.Value
        },
        editNode(node) {
            this.visibleSection = '1'
            this.nodeToEdit = node
        },
        closeNode() {
            this.visibleSection = '2'
            this.nodeToEdit = null
        },
    },
}
</script>

<style lang="scss" scoped>
.background {
    padding-top: 33 * $rem;
    padding-left: 71 * $rem;
    padding-right: 71 * $rem;

    .permissions {
        height: 100%;
    }
}
</style>
