<template>
  <div class="input-search" @click.stop="">
    <div class="arrows-content" v-if="withArrows">
      <i class="material-icons" v-if="!collapsed">keyboard_arrow_down</i>
      <i class="material-icons" v-else>keyboard_arrow_up</i>
    </div>
    <custom-input
      :title="title"
      v-model="inputValue"
      :triggerChangeOnBlur="false"
      :isRequired="isRequired"
      @focus="collapsed = true"
      @clear="clearHandler"
      :placeholder="placeholder"
      :icon="icon"
      :disabled="disabled"
      ref="box"
    />
    <portal to="custom-select" v-if="collapsed && options.length > 0">
      <ul
        class="options custom-select-options"
        v-show="collapsed"
        :style="{
          left: optionsLeftOffset + 'px',
          top: optionsTopOffset + 50 + 'px',
        }"
      >
        <div class="clear-wrapper">
          <span @click.stop="clearHandler" class="clear-button"> clear </span>
        </div>
        <li
          v-for="option in modifiedOptions"
          :key="option.value"
          @click="itemClickHandler(option.value)"
          v-html="option.title"
        ></li>
      </ul>
    </portal>
  </div>
</template>

<script>
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput.vue";
export default {
  components: {
    CustomInput,
  },
  mounted() {
    document.addEventListener("click", this.documentClickHandler);
    var box = this.$refs.box.$el;
    var clientBoundintRect = box.getBoundingClientRect();
    this.optionsLeftOffset = clientBoundintRect.left;
    this.optionsTopOffset = clientBoundintRect.top;
  },
  props: {
    title: String,
    options: Array,
    placeholder: String,
    valueCleared: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    withArrows: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapsed: false,
      inputValue: "",
      optionsLeftOffset: 0,
      optionsTopOffset: 0,
    };
  },
  methods: {
    itemClickHandler(value) {
      var title = this.options.filter((el) => el.value == value)[0].title;
      this.inputValue = title;
      this.collapsed = false;
      this.$emit("select", value);
    },
    clearHandler() {
      this.inputValue = "";
      this.$emit("select", "");
    },
    documentClickHandler() {
      this.collapsed = false;
    },
  },
  computed: {
    modifiedOptions() {
      var options = this.options.filter(
        (el) => ~el.title.toLowerCase().indexOf(this.inputValue.toLowerCase())
      );
      if (options.length == 0) return this.options;
      return options.map((opt) => {
        let index = opt.title
          .toLowerCase()
          .indexOf(this.inputValue.toLowerCase());
        let modifiedTitle =
          opt.title.substring(0, index) +
          `<span class="found">${opt.title.substring(
            index,
            index + this.inputValue.length
          )}</span>` +
          opt.title.substring(index + this.inputValue.length);
        return { title: modifiedTitle, value: opt.value };
      });
    },
  },
  watch: {
    inputValue(value) {
      if (value == "") {
        this.$emit("select", "");
        this.collapsed = true;
        return;
      }
      if (value.length == 1) return;
      //value is equal to one of the items title
      var exactOption = this.options.filter(
        (el) => el.title.toLowerCase() == value.toLowerCase()
      )[0];
      if (exactOption) {
        this.collapsed = false;
        this.$emit("select", exactOption.value);
        return;
      }
      //partially matches title or not
      var matches = false;
      this.options.forEach((opt) => {
        if (~opt.title.toLowerCase().indexOf(value.toLowerCase())) {
          matches = true;
        }
      });
      if (matches) {
        this.collapsed = true;
      } else {
        this.collapsed = false; //doesnt match anything
      }
    },
    collapsed(val) {
      if (val) {
        var box = this.$refs.box.$el;
        var clientBoundintRect = box.getBoundingClientRect();
        this.optionsLeftOffset = clientBoundintRect.left;
        this.optionsTopOffset = clientBoundintRect.top;
      }
    },
    valueCleared(){
      if(this.valueCleared){
        this.clearHandler()
      }
    }
  },
  destroyed() {
    document.removeEventListener("click", this.documentClickHandler);
  },
};
</script>

<style lang="scss" scoped>
.clear-wrapper {
  height: 10px;
}
.clear-button {
  position: absolute;
  right: 20px;
  top: 5px;
  font-size: 14px;
  font-weight: 400;
  color: rgba($blue-primary, 0.7);
  cursor: pointer;
  height: 30px;
  display: block;
}
.input-search {
  $border-color: $green-primary;
  position: relative;
  width: 197 * $rem;
  .arrows-content {
    position: absolute;
    right: 0;
    z-index: 1;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0.6;
  }
  .selected-box {
    position: relative;
    display: inline-block;
    height: 40 * $rem;
    border-radius: 5 * $rem;
    background-color: white;
    border: 1 * $rem solid $grey-border;
    font-size: 15 * $rem;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 15 * $rem;

    span.title {
      color: rgb(70, 70, 70);
      position: absolute;
      top: 10 * $rem;
      font-size: 15 * $rem;
      transition: all 0.2s ease-out;
      background-color: white;
      white-space: nowrap;
      cursor: text;
      cursor: pointer;
    }

    .material-icons {
      font-size: 18 * $rem;
      position: absolute;
      right: 15 * $rem;
      top: 10 * $rem;

      &.clear {
        cursor: pointer;
        color: #b4b4b4;
      }
    }

    &.is-selected {
      span {
        top: -6 * $rem;
        font-size: 12 * $rem;
        color: $border-color;
        padding-left: 2 * $rem;
        padding-right: 2 * $rem;
        left: 15 * $rem;
      }
    }

    &.collapsed {
      border: 1 * $rem solid $border-color;
    }
  }
}
</style>
