<template>
  <div class="container">
    <div
      class="widget-content"
      :style="[
        optionsVisible ? { height: '100%' } : null,
        isOverFlow ? { right: '6px' } : null,
      ]"
    >
      <div class="for-background">
        <div
          class="widget-button"
          @click.stop="optionsVisible = !optionsVisible"
        >
          <span class="material-icons">add_circle_outline</span>
        </div>
      </div>
      <div class="widget-options" v-if="optionsVisible" @click.stop>
        <div
          class="all-button"
          :class="{ beforeActive: beforeSelect }"
          @click.stop="allClickHandler()"
        >
          <div class="dot" :class="{ selected: allSelected }"></div>
          <div class="name">All</div>
          <div class="loader"></div>
        </div>
        <template v-for="col in columns">
          <div class="opt-wrapper" :key="col.index">
            <div
              class="single-option"
              :class="{ active: col.alwaysActive }"
              @click.stop="optionsClickHandler(col)"
            >
              <div
                class="dot"
                :class="[
                  { selected: col.visible },
                  { active: col.alwaysActive },
                  // !col.customOptions
                  //   ? [{ selected: col.visible }, { active: col.alwaysActive }]
                  //   : { dropDown: col.showChilds },
                ]"
              ></div>
              <div class="opt-name">{{ col.title }}</div>
              <div v-if="col.customOptions" class="arrows-content">
                <span class="material-icons-round" v-if="!col.showChilds">
                  arrow_drop_down
                </span>
                <span class="material-icons-round collapsed" v-else>
                  arrow_drop_up
                </span>
              </div>
            </div>
            <div
              class="option-children-content"
              v-if="col.childrens && col.showChilds"
            >
              <div
                class="single-child"
                @click.stop="childClickHandler(col, child)"
                v-for="(child, ind) in col.childrens"
                :key="ind"
              >
                <div
                  class="dot"
                  :class="[
                    { selected: child.visible },
                    { active: child.alwaysActive },
                  ]"
                ></div>
                <div class="opt-name">{{ child.title }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="table-container-wrapper">
      <div
        class="payroll-table-container"
        ref="table"
        @scroll="getScrollPosition"
      >
        <table :style="tableWidth">
          <thead>
            <tr class="header-tr">
              <th></th>
              <th>#</th>
              <template v-for="col in columns">
                <template>
                  <th v-if="col.visible && !col.customStyle" :key="col.index">
                    {{ col.title }}
                    <div class="sort-content">
                      <img
                        v-if="col.canSort && !col.sorted"
                        src="@/assets/payrollSortIcon.svg"
                        alt=""
                        @click.stop="sortVisibleHandler(col)"
                      />
                      <img
                        v-else-if="col.canSort && col.sorted"
                        src="@/assets/payrollSortedIcon.png"
                        alt=""
                        @click.stop="sortVisibleHandler(col)"
                      />

                      <range-sorter
                        :visible="col.title === selectedTitle"
                        :title="col.key"
                        class="sorter"
                        @sort-options="(obj) => sortHanlder(col, obj)"
                      />
                    </div>
                  </th>
                  <th
                    class="custom-th"
                    :key="col.index"
                    v-if="col.customStyle && col.visible"
                  >
                    <div class="title">{{ col.title }}</div>
                    <div class="amount-wrapper">
                      <span>Company</span>
                      |
                      <span>Employee</span>
                    </div>
                  </th>
                </template>
                <template>
                  <template v-for="child in col.childrens">
                    <th
                      v-if="child.visible && child.customStyle"
                      :key="child.title"
                    >
                      <div class="title">{{ child.title }}</div>
                      <div class="amount-wrapper">
                        <span>Company</span>
                        |
                        <span>Employee</span>
                      </div>
                    </th>
                    <th :key="child.title" v-else-if="child.visible">
                      {{ child.title }}
                    </th>
                  </template>
                </template>
              </template>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(row, rowIndex) in data">
              <tr
                :key="rowIndex"
                :class="{
                  selected:
                    row.SalaryResultID === clickedSalaryId &&
                    clickedSalaryId !== 0 &&
                    row.isResponse &&
                    !isAdditionalColumns,
                }"
              >
                <td
                  :class="{
                    selected:
                      row.SalaryResultID === clickedSalaryId &&
                      clickedSalaryId !== 0 &&
                      row.isResponse &&
                      !isAdditionalColumns,
                  }"
                >
                  <span
                    :class="{
                      selected:
                        row.SalaryResultID === clickedSalaryId &&
                        clickedSalaryId !== 0 &&
                        row.isResponse &&
                        !isAdditionalColumns,
                    }"
                    v-if="row.Locked"
                    class="material-icons-outlined locked"
                  >
                    lock
                  </span>
                  <span
                    :class="{
                      selected:
                        row.SalaryResultID === clickedSalaryId &&
                        clickedSalaryId !== 0 &&
                        row.isResponse &&
                        !isAdditionalColumns,
                    }"
                    v-else
                    class="material-icons-outlined"
                  >
                    lock_open
                  </span>
                </td>
                <td
                  :class="{
                    selected:
                      row.SalaryResultID === clickedSalaryId &&
                      clickedSalaryId !== 0 &&
                      row.isResponse &&
                      !isAdditionalColumns,
                  }"
                >
                  {{ rowIndex + 1 }}
                </td>
                <template v-for="(col, ind) in columns">
                  <template>
                    <td
                      :class="{
                        selected:
                          row.SalaryResultID === clickedSalaryId &&
                          clickedSalaryId !== 0 &&
                          row.isResponse &&
                          !isAdditionalColumns,
                      }"
                      v-if="col.visible && !col.customStyle"
                      :key="ind"
                    >
                      <span
                        class="text"
                        :title="
                          getCustomValue(col, row[col.key], row).length > 21
                            ? getCustomValue(col, row[col.key], row)
                            : null
                        "
                        v-html="getCustomValue(col, row[col.key], row)"
                      ></span>
                    </td>
                    <td
                      class="custom-td"
                      v-if="
                        col.visible && col.customStyle && !col.customOptions
                      "
                      v-html="getCustomValue(col, row[col.key], row)"
                      :key="ind"
                    ></td>
                  </template>
                  <template>
                    <template v-for="child in col.childrens">
                      <td
                        class="custom-td"
                        v-if="child.visible && !child.customOptions"
                        v-html="getCustomValue(child, row[child.key], row)"
                        :key="child.title"
                      ></td>
                    </template>
                  </template>
                </template>
                <td>
                  <div class="arrow-content" v-if="!isAdditionalColumns">
                    <i
                      @click.stop="arrowClickHandler(row)"
                      v-if="row.SalaryResultID !== clickedSalaryId"
                      class="material-icons"
                    >
                      keyboard_arrow_down
                    </i>
                    <i
                      @click.stop=";(clickedSalaryId = null), (selectedTab = 1)"
                      v-else-if="row.isResponse"
                      class="material-icons"
                    >
                      keyboard_arrow_up
                    </i>
                  </div>
                </td>
              </tr>
              <tr class="additional-tr" :key="row.index">
                <td colspan="11">
                  <div
                    class="row-details"
                    :class="{
                      collapsed:
                        row.SalaryResultID === clickedSalaryId &&
                        clickedSalaryId !== 0 &&
                        row.isResponse &&
                        !isAdditionalColumns,
                    }"
                  >
                    <div class="details-tabs">
                      <div
                        @click="selectedTab = 1"
                        :class="{ selected: selectedTab == 1 }"
                      >
                        Results
                      </div>
                      <div
                        @click.stop="getParams(row)"
                        :class="{ selected: selectedTab == 2 }"
                      >
                        Parameters
                      </div>
                      <div
                        @click.stop="getTransactions(row)"
                        :class="{ selected: selectedTab == 3 }"
                      >
                        Transactions
                      </div>
                    </div>
                    <div class="details-container">
                      <div class="results-details" v-if="selectedTab == 1">
                        <div class="column-one">
                          <div
                            class="single-res"
                            v-for="el in results.columnOne"
                            :key="el.index"
                          >
                            <span class="title">{{ el.title }}</span>
                            <span class="value">{{ el.value }}</span>
                          </div>
                        </div>
                        <div class="column-two">
                          <div
                            class="single-res"
                            v-for="el in results.columnTwo"
                            :key="el.index"
                          >
                            <span class="title">{{ el.title }}</span>
                            <span :style="{ color: el.color }" class="value">
                              {{ fixedNumber(el.value) }}
                            </span>
                            <span class="currency">{{ el.currency }}</span>
                          </div>
                        </div>
                        <div class="column-three">
                          <div class="single-res" v-if="results.columnThree[0]">
                            <span class="title invisible">
                              {{ results.columnThree[0].title }}
                            </span>

                            <div class="value-content">
                              <div class="company-cost">
                                <img src="@/assets/company-logo.svg" alt="" />
                              </div>
                              <img
                                class="person-icon"
                                src="@/assets/person-logo.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="single-res" v-if="results.columnThree[0]">
                            <span class="title">
                              {{ results.columnThree[0].title }}
                            </span>
                            <div class="value-content">
                              <div class="company-cost">
                                <span class="value">
                                  {{ results.columnThree[0].value }}
                                </span>
                                <span class="currency">GEL</span>
                              </div>
                              <div class="salary-cost">
                                <span>-</span>
                              </div>
                            </div>
                          </div>
                          <template
                            v-for="(factor, index) in results.columnThree
                              .factors"
                          >
                            <div
                              v-if="results.columnThree.factors[0] != undefined"
                              class="single-factor"
                              :key="index"
                            >
                              <span>{{ factor.title }}</span>
                              <div class="value-content">
                                <div class="company-cost">
                                  <span class="value">
                                    {{ fixedNumber(factor.companyCost) }}
                                  </span>
                                </div>
                                <div class="salary-cost">
                                  <span class="value">
                                    {{ fixedNumber(factor.salaryCost) }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                        <div class="column-four">
                          <div class="single-res" v-if="results.columnFour[0]">
                            <span class="title">
                              {{ results.columnFour[0].title }}
                            </span>
                            <span class="value">
                              {{ fixedNumber(results.columnFour[0].value) }}
                            </span>
                            <span class="currency">
                              {{ results.columnFour[0].currency }}
                            </span>
                          </div>
                          <template
                            v-for="factor in results.columnFour.factors"
                          >
                            <div
                              class="single-factor"
                              v-if="results.columnFour.factors[0] != undefined"
                              :key="factor.index"
                            >
                              <span class="title">{{ factor.title }}</span>
                              <span class="value">
                                <img src="@/assets/company-logo.svg" alt="" />

                                {{ fixedNumber(factor.companyCost) }}
                              </span>
                              <span class="value">
                                <img src="@/assets/person-logo.svg" alt="" />

                                {{ fixedNumber(factor.salaryCost) }}
                              </span>
                            </div>
                          </template>
                        </div>
                        <div class="column-five">
                          <div
                            class="single-res"
                            v-for="el in results.columnFive"
                            :key="el.index"
                          >
                            <span class="title">{{ el.title }}</span>
                            <span :style="{ color: el.color }" class="value">
                              {{ fixedNumber(el.value) }}
                            </span>
                            <span class="currency">{{ el.currency }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="parameter-details" v-if="selectedTab == 2">
                        <grid
                          :columns="columnsForParameters"
                          :gridData="dataForParameters"
                          :pagination="false"
                          :whiteDelete="true"
                        />
                      </div>
                      <div class="transaction-details" v-if="selectedTab == 3">
                        <div
                          v-if="row.SalaryPaymentTypeName == 'Payment by cash'"
                          @click="$emit('make-payment', row)"
                          class="button"
                        >
                          MAKE PAYMENT
                        </div>
                        <grid
                          :columns="columnsForTransactions"
                          @change-status="updateTransaction"
                          :gridData="dataForTransactions"
                          :pagination="false"
                          :whiteDelete="true"
                          :canDelete="true"
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <div class="tfoot-wrapper" ref="footer" @scroll="getScrollPosition">
      <table id="tfoot">
        <thead>
          <tr class="header-tr">
            <th></th>
            <th>#</th>

            <template v-for="col in columns">
              <template>
                <th v-if="col.visible && !col.customStyle" :key="col.index">
                  {{ col.title }}
                </th>
                <th
                  class="custom-th"
                  :key="col.index"
                  v-if="col.customStyle && col.visible"
                >
                  <div class="title">{{ col.title }}</div>
                  <div class="amount-wrapper">
                    sms-button-content
                    <span>Company</span>
                    |
                    <span>Employee</span>
                  </div>
                </th>
              </template>

              <template>
                <template v-for="child in col.childrens">
                  <th v-if="child.visible" :key="child.title">
                    <div class="title">{{ child.title }}</div>
                    <div class="amount-wrapper">
                      <span>Company</span>
                      |
                      <span>Employee</span>
                    </div>
                  </th>
                </template>
              </template>
            </template>
            <th></th>
          </tr>
        </thead>
        <tfoot>
          <div class="sms-button-content" @click="$emit('send-sms')">
            <div class="button">
              <span>Send SMS</span>
            </div>
          </div>
          <div class="total-text">
            <span>TOTAL</span>
            <div class="border"></div>
          </div>
          <tr>
            <td></td>
            <td></td>
            <template v-for="(col, index) in columns">
              <template>
                <td :key="index" v-if="col.visible">
                  <div
                    class="text-container"
                    v-if="col.haveTotal && !col.factor"
                  >
                    <span
                      class="title"
                      :title="col.title.length > 23 ? col.title : null"
                    >
                      {{ col.title }}
                    </span>
                    <div class="amount-content">
                      <div class="amount-wrapper">
                        <span class="invisible">Company</span>
                        <span
                          class="amount"
                          :title="
                            getCustomValueForFooter(
                              col,
                              footerData[col.key],
                              footerData,
                            )
                          "
                          v-html="
                            getCustomValueForFooter(
                              col,
                              footerData[col.key],
                              footerData,
                            )
                          "
                        ></span>
                      </div>
                      <div class="amount-wrapper">
                        <span class="invisible">Employee</span>
                        <span class="amount invisible">1</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="text-container"
                    v-if="col.haveTotal && col.factor"
                  >
                    <span
                      class="title"
                      :title="col.title.length > 23 ? col.title : null"
                    >
                      {{ col.title }}
                    </span>
                    <div class="amount-content">
                      <div class="amount-wrapper">
                        <span>Company</span>
                        <span
                          class="amount"
                          v-html="
                            fixedNumber(getCustomValueForFactor(col).company)
                          "
                        ></span>
                      </div>
                      <div class="amount-wrapper">
                        <span>Employee</span>
                        <span
                          class="amount"
                          v-html="
                            fixedNumber(getCustomValueForFactor(col).salary)
                          "
                        ></span>
                      </div>
                    </div>
                  </div>
                </td>
              </template>
              <template>
                <template v-for="child in col.childrens">
                  <td :key="child.title" v-if="child.visible">
                    <div
                      class="text-container"
                      v-if="child.haveTotal && !child.customStyle"
                    >
                      <span
                        :title="child.title.length > 23 ? child.title : null"
                        class="title"
                      >
                        {{ child.title }}
                      </span>
                      <div class="amount-content">
                        <div class="amount-wrapper">
                          <span class="invisible">Company</span>
                          <span
                            class="amount"
                            v-html="
                              fixedNumber(
                                getCustomValueForFooter(
                                  child,
                                  footerData[child.key],
                                  footerData,
                                ),
                              )
                            "
                          ></span>
                        </div>

                        <div class="amount-wrapper">
                          <span class="invisible">Employee</span>
                          <span class="amount invisible">
                            1
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="text-container"
                      v-if="child.haveTotal && child.customStyle"
                    >
                      <span
                        class="title"
                        :title="child.title.length > 23 ? child.title : null"
                      >
                        {{ child.title }}
                      </span>
                      <div class="amount-content">
                        <div class="amount-wrapper">
                          <span>Company</span>
                          <span
                            class="amount"
                            v-html="
                              fixedNumber(
                                getCustomValueForFactor(child).company,
                              )
                            "
                          ></span>
                        </div>
                        <div class="amount-wrapper">
                          <span>Employee</span>
                          <span
                            class="amount"
                            v-html="
                              fixedNumber(getCustomValueForFactor(child).salary)
                            "
                          ></span>
                        </div>
                      </div>
                    </div>
                  </td>
                </template>
              </template>
            </template>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
import axios from '@/Helpers/AxiosInstance.js'
import RangeSorter from './RangeSorter.vue'
export default {
  created() {
    document.addEventListener('click', this.documentClick)
  },
  components: {
    Grid,
    RangeSorter,
  },
  props: {
    columns: Array,
    data: Array,
    results: Object,
    footerData: Object,

    columnsForParameters: Array,
    dataForParameters: Array,
    dataForFooterFactor: Array,
    columnsForTransactions: Array,
    dataForTransactions: Array,
  },
  data() {
    return {
      optionsVisible: false,
      allSelected: false,
      clickedSalaryId: null,
      selectedTab: 1,
      scrollLeft: 0,
      beforeSelect: false,

      selectedTitle: '',
      selectedChilds: [],
    }
  },

  methods: {
    sortHanlder(col, obj) {
      obj.ascSelected || obj.descSelected
        ? (col.sorted = true)
        : (col.sorted = false)
      this.$emit('sort', obj)
      this.selectedTitle = ''
    },
    optionsClickHandler(col) {
      if (!col.childrens) col.visible = !col.visible
      col.showChilds = !col.showChilds
      if (col.childrens) {
        col.childrens.forEach((child) => {
          child.visible = false
          this.selectedChilds = this.selectedChilds.filter(
            (el) => el !== child.id,
          )
        })
      }
    },

    childClickHandler(col, child) {
      col.visible = true
      child.visible = !child.visible
      let exist = this.selectedChilds.includes(child.id)
      if (exist) {
        this.selectedChilds = this.selectedChilds.filter(
          (el) => el !== child.id,
        )
      } else {
        this.selectedChilds.push(child.id)
      }
      // this.selectedChilds.push(child.id);
    },
    getParams(row) {
      this.selectedTab = 2
      this.$emit('show-params', row)
    },
    getTransactions(row) {
      this.$emit('show-transactions', row)
      this.selectedTab = 3
    },
    getCustomValue(column, val, row) {
      if (!val) {
        val = ''
      }
      if (!isNaN(val) && val.toString().indexOf('.') != -1) {
        val = val.toFixed(2)
      }
      if (!column.customTemplate) return `${val}`

      var text = column.customTemplate(row, val)

      return text
    },

    getCustomValueForFooter(column, val, row) {
      if (!val) {
        val = '-'
      }
      if (!isNaN(val) && val.toString().indexOf('.') != -1) {
        val = val.toFixed(2)
      }
      return val
    },
    sortVisibleHandler(col) {
      this.selectedTitle = col.title
    },
    getCustomValueForFactor(col) {
      let exist = this.dataForFooterFactor.filter(
        (el) => el.SalaryFactorTypeName === col.title,
      )[0]
      if (exist) {
        let companyCost = this.dataForFooterFactor.filter(
          (el) => el.SalaryFactorTypeName === col.title,
        )[0].CompanyCost
        let salaryCost = this.dataForFooterFactor.filter(
          (el) => el.SalaryFactorTypeName === col.title,
        )[0].SalaryCost
        return {
          company: companyCost > 0 ? companyCost : '-',
          salary: salaryCost > 0 ? salaryCost : '-',
        }
      } else {
        return {
          company: '-',
          salary: '-',
        }
      }
    },
    fixedNumber(val) {
      let result = val
      if (!isNaN(val) && val.toString().indexOf('.') != -1) {
        result = val.toFixed(2)
      }

      return result
    },

    getScrollPosition(event) {
      let scrollLeft = event.target.scrollLeft
      this.$refs.table.scrollLeft = scrollLeft
      this.$refs.footer.scrollLeft = scrollLeft
    },

    updateTransaction(row) {
      this.$emit('update-transaction', row)
    },
    documentClick() {
      this.selectedTitle = ''

      this.optionsVisible = false
    },

    allClickHandler() {
      this.beforeSelect = true
      setTimeout(() => {
        this.allSelected = !this.allSelected

        this.columns.forEach((el) => {
          if (this.allSelected) {
            el.visible = true
            if (el.childrens) {
              el.showChilds = true
              el.childrens.forEach((child) => {
                child.visible = true
              })
            }
          } else {
            if (!el.alwaysActive) el.visible = false
            if (el.childrens) {
              el.showChilds = false
              el.childrens.forEach((child) => {
                child.visible = false
              })
            }
          }
          this.beforeSelect = false
        })
      }, 100)
    },
    arrowClickHandler(row) {
      this.selectedTab = 1
      this.$set(row, 'isResponse', false)
      this.$emit('show-details', row)
      this.clickedSalaryId = row.SalaryResultID
    },
  },
  computed: {
    isAdditionalColumns() {
      return this.columns.filter((el) => el.visible === true).length +
        this.childsNumb >
        8
        ? true
        : false
    },
    tableWidth() {
      let addedColumnsNumb =
        this.columns.filter((el) => el.visible === true).length +
        this.childsNumb -
        8

      return `width: calc(100% +  175px * ${addedColumnsNumb})`
    },

    childsNumb() {
      if (!this.selectedChilds) return
      return this.selectedChilds.length
    },

    isOverFlow() {
      if (!this.data.length) return
      return this.data.length > 12 ? true : false
    },
  },
  destroyed() {
    document.addEventListener('click', this.documentClick)
  },
  watch: {
    isAdditionalColumns() {
      if (this.isAdditionalColumns) {
        this.data.forEach((el) => {
          el.detailsVisible = false
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
$tfootHeight: 130px;
.container {
  height: calc(100%);
  position: relative;
}

.table-container-wrapper {
  height: calc(100% - #{$tfootHeight});
  overflow: hidden;
  border-top-left-radius: 5px;
}

.payroll-table-container {
  height: calc(100% + 12px);
  overflow: auto;

  &::-webkit-scrollbar {
    // display: none;
  }
}

.tfoot-wrapper {
  height: $tfootHeight;

  overflow-y: hidden;
  overflow-x: auto;

  table {
    tfoot {
      background: #ffffff;
      height: 100px;
      position: relative;
      border-radius: 6px;

      .total-text {
        position: fixed;
        color: #959bc9;
        font-weight: 600;
        background-color: #fff;
        width: 400px;
        height: 92px;
        display: flex;
        border-radius: 6px 0 0 6px;
        align-items: center;
        justify-content: flex-end;
        .border {
          width: 2px;
          height: 50px;
          background-color: #ededfc;
          margin-left: 30px;
        }
      }

      td:nth-last-child(2) {
        border-radius: 6px;
      }
      td {
        &:first-child {
          border-radius: 6px;
        }

        font-size: 14px;
        // border-right: 1px solid black;
        white-space: nowrap;
        .text-container {
          display: flex;
          flex-direction: column;
          // align-items: flex-end;
          .title {
            font-size: 14px;
            color: #959bc9;
            margin-bottom: 5px;
            max-width: 160px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .amount-content {
            display: flex;
            align-items: center;
            .amount-wrapper {
              & .invisible {
                opacity: 0;
              }
              display: flex;
              flex-direction: column;
              // margin-right: 18px;
              span:nth-child(1) {
                font-size: 12px;
                color: #959bc9;
                font-weight: 300;
              }
              .amount {
                margin-top: 3px;
                font-size: 14px;
              }
              span:nth-child(2) {
                width: 69px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            // span:nth-child(1) {
            //   width: 69px;
            //   overflow: hidden;
            //   text-overflow: ellipsis;
            // }
          }
          .amount {
            font-size: 16px;
            color: $blue-primary;
            font-weight: 400;
          }
        }
      }
    }
  }
  & > table {
    height: 100%;

    thead {
      height: 15px;
      // pointer-events: none;
      .header-tr {
        font-size: 5px;
      }
      opacity: 0;
      & > * {
        height: 0 !important;
      }
    }
  }
}

.widget-content {
  .for-background {
    background-color: #6956d6;
    border-radius: 0px 5px 5px 0px;
    height: 50px;
  }
  position: absolute;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 11;
  // background-color: #6956d6;

  .widget-button {
    cursor: pointer;
    margin-right: 10px;
    margin-top: 5px;
    width: 45px;
    height: 40px;
    background-color: #6956d6;
    border-radius: 9px;
    border: 1px solid #1de9b6;
    text-transform: uppercase;
    font-size: 12px;
    color: #1de9b6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    letter-spacing: 1px;
    &:active {
      transform: scale(0.9);
    }
  }
  .widget-options {
    background-color: #6956d6;
    margin-top: 10px;
    width: 220px;
    border-radius: 6px;
    height: calc(100% - 115px);
    padding: 20px;

    overflow: auto;
    position: absolute;
    top: 43px;
    .all-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #ffffff;
      margin-bottom: 30px;
      font-size: 14px;

      &.beforeActive {
        .loader {
          margin-left: 15px;
          border: 2px solid #f3f3f3;
          border-radius: 50%;
          border-top: 2px solid #1de9b6;
          width: 15px;
          height: 15px;
          -webkit-animation: spin 2s linear infinite; /* Safari */
          animation: spin 2s linear infinite;
        }

        /* Safari */
        @-webkit-keyframes spin {
          0% {
            -webkit-transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
          }
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
      .dot {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        border: 1px solid #1de9b6;
        margin-right: 15px;

        &.selected {
          background-color: #1de9b6;
        }
      }
    }
    .opt-wrapper {
      margin-bottom: 18px;

      .single-option {
        display: flex;
        align-items: center;
        cursor: pointer;

        color: #ffffff;
        .arrows-content {
          margin-left: auto;
          .material-icons-round.collapsed {
            color: #1de9b6;
          }
        }

        &.active {
          color: #959bc9;
          pointer-events: none;
        }
        .dot {
          width: 16px;
          height: 16px;
          border-radius: 100%;
          border: 1px solid #1de9b6;
          margin-right: 15px;
          flex-shrink: 0;

          &.selected {
            background-color: #1de9b6;
          }
          &.active {
            opacity: 0.5;
          }
        }
      }
      .option-children-content {
        padding-left: 5px;
        margin-top: 5px;
      }

      .single-child {
        // word-break: break-word;
        display: flex;
        align-items: center;
        margin-top: 7px;
        cursor: pointer;
        // display: flex;
        // align-items: center;
        color: #ffffff;

        &:last-child {
          margin-bottom: 0;
        }
        &.active {
          color: #959bc9;
          pointer-events: none;
        }
        .opt-name {
          font-size: 14px;
        }
        .dot {
          width: 14px;
          height: 14px;
          border-radius: 100%;
          border: 1px solid #1de9b6;
          margin-right: 15px;
          flex-shrink: 0;

          &.selected {
            background-color: #1de9b6;
          }
          &.active {
            opacity: 0.5;
          }
        }
      }
    }
    .opt-name {
      font-size: 14px;

      font-weight: 400;
    }
  }
}
table {
  border-collapse: collapse;
  border-spacing: unset;
  width: 100%;
  // height: 100%;
  table-layout: fixed;

  // width: 150rem;
  thead {
    border-radius: 5px;
    position: sticky;
    top: 0;
    z-index: 10;

    .header-tr {
      height: 50px;
      border-radius: 5px;
      background-color: #6956d6;
      color: #ffffff;
      font-size: 12px;
      text-align: left;
      .amount-wrapper {
        font-weight: 300;
        span:nth-child(1) {
          margin-right: 5px;
        }
        span:nth-child(2) {
          margin-left: 5px;
        }
      }
      th:nth-child(1),
      th:nth-child(2),
      th:nth-child(3) {
        position: sticky;
        background-color: #6956d6;
      }
      th:nth-last-child(2) {
        border-radius: 0 5px 5px 0;

        // padding-right: 30px;
      }
      th:nth-child(2) {
        left: 70px;
        width: 50px;
      }
      th:nth-child(3) {
        left: 120px;
      }
      th:nth-child(4) {
        padding-left: 2px;
      }
      th:last-child {
        border-radius: 0 5px 5px 0;
        width: 0px;
        // padding-right: 30px;
      }

      th:first-child {
        border-radius: 5px 0px 0px 5px;

        width: 70px;
        left: 0px;
      }
      th {
        width: 175px;
        z-index: 2;
        .sort-content {
          position: relative;
          display: inline-block;
        }

        img {
          margin-left: 5px;
          cursor: pointer;
        }
        .sorter {
          position: absolute;
          top: 33px;

          right: -50px;
        }
        // overflow: hidden;
      }
    }
  }
  tbody {
    width: 100%;

    .row-details {
      height: 0px;
      background-color: #3b469d;
      transition: height 0.5s ease-in-out;
      overflow: hidden;
      width: 100%;
      &.collapsed {
        height: 350px;
      }
      .details-tabs {
        border: 1px solid #1de9b6;
        border-left: 0;
        border-right: 0;

        :nth-child(2) {
          border-right: 1px solid #1de9b6;
          border-left: 1px solid #1de9b6;
        }
        div {
          width: 33.9%;
          color: #dcdde6;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.selected {
            color: #283593;
            background-color: #1de9b6;
            font-weight: 600;
          }
        }

        font-size: 14px;
        height: 30px;
        display: flex;
        justify-content: space-between;
      }
    }
    ::v-deep .alternativeBody {
      top: 0;
    }

    tr:not(.additional-tr) {
      height: 42px;
      background-color: #fff;

      border-bottom: 1px solid #6956d621;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;

      .arrow-content {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }

      &:hover {
        background-color: #e2e2ec;
      }
      &:hover > td:nth-child(3) {
        background-color: #e2e2ec;
      }
      &:hover > td:nth-child(2) {
        background-color: #e2e2ec;
      }
      &:hover > td:nth-child(1) {
        background-color: #e2e2ec;
      }

      td:nth-child(1),
      td:nth-child(2),
      td:nth-child(3) {
        position: sticky;
        background-color: #ffffff;
        &.selected {
          background-color: #3b469d;
        }
      }
      td:first-child {
        left: 0;
        padding-left: 15px;

        span {
          font-size: 18px;
          color: #5963ab;
          opacity: 0.5;
          &.locked {
            opacity: 1;
          }
        }
      }
      td:nth-child(2) {
        left: 70px;
      }
      td:nth-child(3) {
        left: 120px;
      }
      td {
        font-size: 14px;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        &.selected {
          background-color: #3b469d;
          color: #ffffff;
          // position: initial;
        }
        // padding: 0.75rem 0 0.75rem 2.5rem;
        color: #283593;
        .text {
          font-size: 14px;
          display: block;
          width: 93%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
.details-container {
  .results-details {
    padding-top: 60px;
    display: flex;
    justify-content: space-around;
    .currency {
      color: #afb3d5;
      margin-left: 3px;
      font-weight: 500;
      font-size: 14px;
    }
    .single-res {
      display: flex;
      margin-bottom: 8px;
    }
    .column-one {
      .title {
        width: 88px;
      }
      .value {
        margin-left: 17px;
      }
    }
    .column-two {
      .title {
        width: 131px;
        margin-right: 17px;
      }
    }
    .column-three {
      max-height: 260px;
      overflow: auto;
      margin-top: -27px;
      .title {
        width: 170px;
        margin-right: 17px;
        &.invisible {
          opacity: 0;
        }
      }
      .value-content {
        display: flex;
        .salary-cost {
          color: #ffffff;
        }
        .company-cost {
          width: 90px;
        }
      }

      // .person-icon {
      //   margin-left: auto;
      // }

      .currency {
        color: #afb3d5;
        font-size: 14px;
        margin-left: 3px;
        // margin-right: 30px;
      }
      .single-factor {
        display: flex;
        margin-bottom: 5px;
        font-size: 14px;
        > :nth-child(1) {
          text-align: right;
          width: 170px;
          margin-right: 17px;
          color: #afb3d5;
        }
        // :nth-child(3) {
        //   margin-left: auto;
        // }
        :nth-child(2) {
          // margin-right: 15px;
        }
      }
    }
    .column-four {
      .title {
        width: 170px;
        margin-right: 17px;
      }
      .single-factor {
        display: flex;
        margin-bottom: 8px;
        .title {
          text-align: right;
          width: 170px;
          margin-right: 17px;
          color: #afb3d5;
        }
        .value:not(:last-of-type) {
          margin-right: 25px;
        }
        .value {
          display: flex;
          align-items: center;
          width: 90px;

          img {
            margin-right: 3px;
          }
        }
      }
    }
    .column-five {
      .title {
        width: 120px;
        margin-right: 17px;
      }
    }
    .title {
      color: #afb3d5;
      font-size: 14px;
      text-align: right;
    }
    .value {
      color: #ffffff;
      font-size: 14px;
    }
  }
}
.transaction-details {
  .button {
    color: #ffffff;
    width: 135px;
    height: 40px;
    background-color: #6956d6;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 40px;
    font-size: 15px;
    margin-top: 7px;
    cursor: pointer;
  }
}
.parameter-details {
  ::v-deep .grid .table-helper table tbody {
    height: 250px !important;
    &::-webkit-scrollbar-thumb {
      background-color: red;
    }
  }
}
.sms-button-content {
  position: fixed;
  z-index: 1;
  /* height: 100%; */
  height: 100%;

  .button {
    background-color: #6956d6;
    border-radius: 0.3125rem;
    width: 132px;
    position: absolute;
    top: 20px;
    left: 40px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
