<template>
  <div class="company-costs">
    <div class="title">Company costs</div>
    <div class="costs-header">
      <div class="switcher-content">
        <div class="visibleLabel">
          <label class="visibleRadio public">
            <input
              type="radio"
              id="public"
              :value="true"
              v-model="chartVisible"
            />
            <span>Chart</span>
          </label>
          <label class="visibleRadio private">
            <input
              type="radio"
              id="private"
              :value="true"
              v-model="listVisible"
            />
            <span>List</span>
          </label>
        </div>
      </div>
      <div class="filter-content">
        <div class="filter-div">
          <custom-select
            class="input"
            title="Company"
            v-model="companyFilter"
            :options="companyListOptions"
            @clear="companyFilter = ''"
            :clearVisible="true"
          />
        </div>
        <div class="filter-div">
          <date-range
            :initialFrom="fromDate"
            :initialTo="toDate"
            @from="(from) => (fromDate = from)"
            @to="(to) => (toDate = to)"
            class="date_range"
          />
        </div>
      </div>
    </div>

    <div class="wrapper" v-if="listVisible">
      <div class="chart"></div>
      <div v-if="computedData.length" class="list">
        <div class="table">
          <div class="header">
            <div class="company">
              <span>Company</span>
            </div>
            <template v-for="(month, index) in months">
              <div class="month" :key="index">
                <span>
                  {{ month.Month.slice(0, 3) }}
                </span>
              </div>
            </template>
            <div class="total">
              <span>Total</span>
            </div>
          </div>
          <div class="body">
            <template v-for="(company, index) in computedData">
              <div class="company" :key="index">
                <div class="company-name">
                  <span>
                    {{
                      company.SalaryFactorsDestinationTypeName
                        ? company.SalaryFactorsDestinationTypeName
                        : 'null'
                    }}
                  </span>
                </div>
                <template v-for="(month, ind) in company.MonthlyCosts">
                  <div class="cost" :key="ind">
                    <span>
                      {{ formatNumber(month.Cost) }}
                    </span>
                  </div>
                </template>
                <div :key="index" class="total">
                  <span>
                    {{ formatNumber(company.TotalCost) }}
                  </span>
                </div>
              </div>
            </template>
            <div class="monthly-totals">
              <div class="row-name">
                <div class="wrapper">
                  <span>Total</span>
                  <span>(Monthly)</span>
                </div>
              </div>
              <template v-for="(total, indx) in monthlyTotal">
                <div class="monthly-costs" :key="indx">
                  <span>
                    {{ total.MonthlyTotalCost }}
                  </span>
                </div>
              </template>
              <div :key="index" class="total">
                <span>
                  {{ total }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <template></template>
      </div>
      <div v-else class="no-data">
        <span>No Data</span>
      </div>
    </div>
    <div class="chart-container" v-show="!listVisible">
      <bar-chart
        v-if="loaded && chartVisible"
        :width="700"
        :chart-data="chartDataList"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance'
import DateRange from '@/components/ReusableComponents/DateRange/DateRange'
import BarChart from '@/components/ReusableComponents/Charts/BarChart.js'
import CustomSelect from '@/components/ReusableComponents/CustomSelect/CustomSelect.vue'

export default {
  created() {
    this.getData()
    this.getCompanies()
  },
  components: {
    // Grid,
    DateRange,
    BarChart,
    CustomSelect,
  },
  data() {
    return {
      chartVisible: false,
      listVisible: true,
      data: [],
      monthlyTotal: [],
      fromDate: '',
      toDate: '',
      filterfromDate: '',
      filtertoDate: '',
      companyFilter: '',
      companyListOptions: [],
      total: '',
      loaded: false,
      chartDataList: null,
      chartOptions: {
        scales: {
          yAxes: [
            {
              stacked: true,
            },
          ],
          xAxes: [
            {
              stacked: true,
            },
          ],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    }
  },
  mounted() {
    this.fillData()
  },
  methods: {
    getData() {
      axios
        .get('salary/GetCompanyCosts', {
          params: {
            FromDate: this.fromDate ? new Date(this.fromDate) : null,
            ToDate: this.toDate ? new Date(this.toDate) : null,
            DestinationTypeID: this.companyFilter,
          },
        })
        .then((resp) => {
          this.data = resp.data.Value.DestMonthlyCosts
          this.monthlyTotal = resp.data.Value.MonthlyTotals
          this.total = resp.data.Value.Total
        })
    },
    formatNumber(val) {
      if (!val) {
        val = '-'
      }
      if (!isNaN(val) && val.toString().indexOf('.') != -1) {
        val = val.toFixed(2)
      }
      return val
    },
    async fillData() {
      this.loaded = false
      try {
        axios
          .get(
            `salary/GetCompanyCostsUsageForChart?destinationTypeID=${this.companyFilter}&FromDate=${this.filterfromDate}&ToDate=${this.filtertoDate}`,
          )
          .then((resp) => {
            this.chartDataList = {
              labels: resp.data.Value.Months,
              datasets: [
                {
                  label: resp.data.Value.Datasets[0].Name,
                  backgroundColor: resp.data.Value.Datasets[0].Color,
                  data: resp.data.Value.Datasets[0].Amounts,
                },
              ],
            }
            this.loaded = true
          })
      } catch (e) {
        console.error(e)
      }
    },
    getCompanies() {
      axios.get(`salary/GetSalaryFactorsDestinationTypes`).then((response) => {
        this.companyListOptions = response.data.Value.DestinationTypes.map(
          (el) => ({
            title: el.Name,
            value: el.ID,
          }),
        )
      })
    },
  },
  computed: {
    months() {
      // return "test";
      if (!this.data.length) return
      return this.data[0].MonthlyCosts
    },
    computedData() {
      if (!this.data.length) []
      return this.data
    },
  },
  watch: {
    fromDate() {
      this.filterfromDate = new Date(this.fromDate)
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ')
      this.filtertoDate = new Date(this.toDate)
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ')
      this.fillData()
      this.getData()
    },
    companyFilter(to, from) {
      this.companyFilter = to
      this.getData()
    },
    chartVisible() {
      if (this.chartVisible) {
        this.listVisible = false
        this.fillData()
      }
    },
    listVisible() {
      if (this.listVisible) {
        this.chartVisible = false
        this.getData()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.chart-container {
  background-color: #ffffff;
  border-radius: 2px;
  padding: 20px;
}
.no-data {
  color: rgba($blue-primary, 0.5);
  font-size: 55px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.background {
  .company-costs {
    margin-top: 1.875rem;
    .costs-header {
      display: flex;
      justify-content: space-between;
      margin: 15px 0;
      .filter-content {
        display: flex;
        justify-content: end;
        .filter-div {
          width: 12.938rem;
          margin: 0 1.275rem 0.0625rem 0;
          margin-bottom: 0.9375rem;
          margin-top: 1.875rem;
        }
      }
    }
  }
  .title {
    text-align: center;

    // display: flex;
    font-size: 1.125rem;
    color: $blue-primary;
  }
}
.list {
  .table {
    overflow: auto;
    width: 100%;

    .header {
      white-space: nowrap;
      height: 3.5625rem;
      background-color: #6956d6;
      border-radius: 0.375rem;

      .company {
        border-radius: 0.375rem 0 0 0.375rem;
        font-size: 0.8125rem;
        position: sticky;
        left: 0;
        background-color: #6956d6;
        border-right: 0.0625rem solid #ededed;
        width: 9.6875rem;
      }
      .total {
        width: 9.6875rem;
        position: sticky;
        right: 0;
        background-color: #6956d6;
      }

      > div {
        display: inline-block;
        width: 6.875rem;
        background-color: #6956d6;
        font-size: 0.875rem;
        font-weight: 500;
        color: #ffffff;
        border-left: 0.0625rem solid #ededed;
        &:nth-child(2) {
          border-left: 0;
        }
        > span {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
        // border-right: 0.0625rem solid #ededed;

        height: 100%;
      }
    }
    .body {
      .monthly-costs {
        position: relative;
        top: -1px;
      }
      .company,
      .monthly-totals {
        color: #5963ab;
        white-space: nowrap;

        height: 3.375rem;
        background-color: #ffffff;
        border-bottom: 0.0625rem solid #ededed;
        font-weight: 500;
        :nth-child(2) {
          border-left: 0 !important;
        }
        .company-name {
          border-right: 0.0625rem solid #ededed;
          z-index: 2;
          position: sticky;
          left: 0;
          display: inline-block;
          height: 100%;
          background-color: #ffffff;
          vertical-align: middle;
          width: 9.6875rem;
          font-size: 0.8125rem;
          font-weight: 500;
          padding-left: 0.9375rem;
          span {
            display: flex;
            align-items: center;
            height: 100%;
            white-space: pre-line;
            // justify-content: center;
          }
        }
        .total {
          height: 100%;
          text-align: center;
          width: 9.6875rem;
          font-size: 0.875rem;
          border-left: 0.0625rem solid #ededed;
          display: inline-block;
          position: sticky;
          right: 0;
          //   z-index: 2;
          background-color: #ffffff;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }
        }
        .cost,
        .monthly-costs {
          display: inline-block;
          font-weight: 500;
          width: 6.875rem;
          height: 100%;
          background-color: #ffffff;

          font-size: 0.875rem;
          border-left: 0.0625rem solid #ededed;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }
        }
        .row-name {
          border-right: 0.0625rem solid #ededed;
          z-index: 2;
          position: sticky;
          left: 0;
          display: inline-block;
          height: 100%;
          background-color: #ffffff;

          width: 9.6875rem;
          font-size: 0.8125rem;
          font-weight: 500;
          padding-left: 0.9375rem;
          .wrapper {
            display: flex;

            align-items: center;
            height: 100%;
            color: #959bc9;
            font-weight: 500;
            span:nth-child(1) {
              font-size: 18px;
            }
            span:nth-child(2) {
              font-size: 13px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
.switcher-content {
  display: inline-block;
  .visibleLabel {
    padding: 0.1563rem;
    border-radius: 0.5rem;
    border: solid 0.0625rem rgba(105, 86, 214, 0.15);
    background-color: #ffffff;
    margin-top: 0.3125rem;
    display: flex;
    input {
      display: none;
    }
  }
  .visibleLabel input {
    display: none;
  }
  .visibleLabel label {
    display: inline-block;

    cursor: pointer;
    border-radius: 0.5rem;
  }

  .visibleLabel span {
    display: block;
    padding: 0.3125rem 0.9375rem 0.3125rem 0.9375rem;
    border-radius: 0.3125rem;
    position: relative;
    transition: all 0.25s linear;
    color: #6956d6;
    font-size: 0.875rem;
  }
  .visibleLabel input:checked + span {
    background-color: #6956d6;
    box-shadow: 0 0 0.3125rem 0.125rem rgba(0, 0, 0, 0.1);
  }
  .visibleLabel input:checked + span {
    color: #fff;
    border-color: #6956d6;
  }
  .visibleLabel input:checked + span:before {
    background-color: #6956d6;
  }
}
</style>
