<template>
    <div class="add-new" @click="$emit('clicked')"></div>
</template>

<script>
export default {
    props: {
        title: String,
    },
}
</script>

<style lang="scss" scoped>
.add-new {
    border-radius: 0.1875rem;
    width: 2.625rem;
    height: 2.3125rem;
    margin-left: 0.25rem;
    position: relative;
    cursor: pointer;
    background: rgba($blue-primary, 0.6);

    &::before {
        content: "";
        position: absolute;
        width: 1.4375rem;
        height: 0.125rem;
        top: 1.125rem;
        left: 0.625rem;
        background-color: #fff;
    }

    &::after {
        content: "";
        position: absolute;
        height: 1.4375rem;
        width: 0.125rem;
        top: 0.4375rem;
        left: 1.3125rem;
        background-color: #fff;
    }
}
</style>