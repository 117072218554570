<template>
  <div class="background">
    <Structure :userPage="userPage" />
  </div>
</template>

<script>
import Structure from "@/components/Departments/Structure";
export default {
  components: {
    Structure,
  },
  data() {
    return {
      userPage: true,
    };
  },
};
</script>
<style lang='scss' scoped>
.background {
  overflow: hidden;
}
</style>