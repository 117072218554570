<template>
  <div class="">
    <table>
      <thead>
        <tr>
          <th v-if="options.withCheckbox" class="checkbox-th"></th>
          <th class="index"></th>
          <th
            :class="[field.class, { 'td--small': options.withSmallTd }]"
            v-for="(field, index) in fields"
            :key="index"
          >
            {{ field.title }}
          </th>
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr v-for="index in 1" :key="index">
          <td v-if="options.withCheckbox" class="checkbox-th"></td>
          <td class="index">
            <span class="loader loader--index"></span>
          </td>
          <td
            :class="[
              'loader--source',
              { 'source--small': options.withSmallSource },
            ]"
          >
            <span class="loader loader--source"></span>
          </td>
          <td
            v-if="options.widthPersonCount"
            :class="{ 'td--small': options.withSmallTd }"
          >
            <span class="loader loader--user"></span>
          </td>
          <td
            v-if="!options.withoutSessionDuration"
            :class="{ 'td--small': options.withSmallTd }"
          >
            <span class="loader loader--sessionDuration"></span>
          </td>
          <td
            v-if="options.withAverageSessionDuration"
            :class="{ 'td--small': options.withSmallTd }"
          >
            <span class="loader loader--averageSession"></span>
          </td>
          <td v-if="options.withTrafficShareGraph" class="traffic-share">
            <span class="loader loader--traffic-share"></span>
          </td>
          <td
            v-if="options.withTrafficShare"
            :class="{ 'td--small': options.withSmallTd }"
          >
            <span class="loader loader--traffic-share-small"></span>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="data && data.length > 0">
        <tr v-for="(object, index) in data" :key="index">
          <td v-if="options.withCheckbox" class="checkbox-th">
            <custom-round-checkbox
              :id="`${object.ProgramName}-${index}`"
              :value="object.checked"
              @changed="$emit('add-tag', object)"
            />
          </td>
          <td class="index">
            {{ `${index + 1}` }}
          </td>
          <td
            :class="[
              'source',
              {
                'source--small': options.withSmallSource,
              },
            ]"
          >
            <template>
              <a
                target="_blank"
                v-if="object.URL"
                :href="`https://${object.URL}`"
              >
                <div class="img-wrapper">
                  <img
                    @load="(e) => loadHandler(e, object)"
                    :src="getObjectIcon(object.URL)"
                    alt=""
                    :class="{
                      'more-width': object.moreWidthThanHeight,
                      'more-height': !object.moreWidthThanHeight,
                    }"
                  />
                </div>
                {{ object.URL }}
              </a>
              <span v-else-if="!object.URL">
                <div class="img-wrapper">
                  <img
                    @load="(e) => loadHandler(e, object)"
                    @click="
                      imageModalIsActive = true;
                      currentObject = object;
                    "
                    @error="object.blankIcon = true"
                    :src="getProgramLogo(object.ProgramName)"
                    :class="{
                      'more-width': object.moreWidthThanHeight,
                      'more-height': !object.moreWidthThanHeight,
                    }"
                    alt=""
                  />
                </div>
                {{ object.ProgramName }}
              </span>
              <span class="clickable" v-else>
                <div class="img-wrapper">
                  <img
                    @load="(e) => loadHandler(e, object)"
                    @click="
                      imageModalIsActive = true;
                      currentObject = object;
                    "
                    :src="require('@/assets/icons/icon_traficapp_default.svg')"
                    alt=""
                    :class="{
                      'more-width': object.moreWidthThanHeight,
                      'more-height': !object.moreWidthThanHeight,
                    }"
                  />
                </div>
                {{ object.ProgramName }}
              </span>
            </template>
          </td>
          <td
            v-if="options.widthPersonCount"
            @click="
              userPopupActive = true;
              currentObject = object;
              currentObjectIndex = index;
            "
            :class="['users', { 'td--small': options.withSmallTd }]"
          >
            <span class="user">
              {{ object.PersonCount ? object.PersonCount : object.Count }}
            </span>
          </td>
          <td v-if="!options.withoutSessionDuration">
            {{ timestampToHours(object.SessionDuration) }}
          </td>
          <td
            v-if="options.withAverageSessionDuration"
            :class="{ 'td--small': options.withSmallTd }"
          >
            {{ timestampToHours(object.AverageSessionDuration) }}
          </td>
          <td
            v-if="options.withTrafficShareGraph"
            class="traffic-share"
            :style="{ width: `${object.width}px` }"
          >
            <div
              class="traffic-share__active"
              v-if="
                calculatePercentage(
                  object.ActivePersonCount,
                  object.PersonCount
                ) > 0
              "
              :style="{
                width: `${calculatePercentage(
                  object.ActivePersonCount,
                  object.PersonCount
                )}%`,
              }"
            >
              <span class="traffic-share__percentage">
                {{
                  `${Math.floor(
                    calculatePercentage(
                      object.ActivePersonCount,
                      object.PersonCount
                    )
                  )}%`
                }}
              </span>
            </div>
            <div
              class="traffic-share__inactive"
              v-if="
                100 -
                  calculatePercentage(
                    object.ActivePersonCount,
                    object.PersonCount
                  ) >
                0
              "
              :style="{
                width: `${
                  100 -
                  calculatePercentage(
                    object.ActivePersonCount,
                    object.PersonCount
                  )
                }%`,
              }"
            >
              <span
                class="traffic-share__percentage traffic-share__percentage--down"
                >{{
                  `${
                    100 -
                    Math.floor(
                      calculatePercentage(
                        object.ActivePersonCount,
                        object.PersonCount
                      )
                    )
                  }%`
                }}</span
              >
            </div>
          </td>
          <td
            v-if="options.withTrafficShare"
            :class="{ 'td--small': options.withSmallTd }"
          >
            {{
              calculateTrafficShare(object.SessionDuration, object.TotalTime)
            }}
          </td>
        </tr>
      </tbody>
      <div v-else class="no-record">No Records</div>
    </table>
    <overlay
      :visible="imageModalIsActive"
      @close="
        imageModalIsActive = false;
        file = {};
      "
    >
      <popup
        title="Add App Logo"
        @close="
          imageModalIsActive = false;
          file = {};
        "
        :visible="imageModalIsActive"
        :flexible-size="true"
      >
        <custom-file-input
          title="Choose File"
          :value="file"
          @change="changeFile"
        />
        <div class="upload-button" @click="uploadLogo">Upload</div>
      </popup>
    </overlay>

    <custom-snackbar
      v-if="uploadSuccess"
      @close="uploadSuccess = false"
      text="Logo was uploaded successfully"
    />
    <custom-popup
      v-if="userPopupActive"
      :visible="userPopupActive"
      :flexible-size="true"
      :title="currentObject.URL || currentObject.ProgramName"
      @close="
        userPopupActive = false;
        users = [];
      "
    >
      <div class="users-modal__wrapper">
        <div class="users-modal__title-wrapper">
          <div class="users-modal__title">Users {{ currentObject.Count }}</div>
          <custom-input
            class="users-modal__search"
            title="Filter by Persons"
            v-model="usersModalSearchInput"
          />
        </div>
        <div class="table">
          <div class="row row--user-modal headers">
            <div class="col">#</div>
            <div class="col">User</div>
            <div class="col">Session Duration</div>
          </div>
          <div class="table-body">
            <template v-if="computedUsers.length > 0">
              <div
                class="row row--user-modal"
                v-for="(user, index) in computedUsers"
                :key="index"
              >
                <div class="col">{{ index + 1 }}</div>
                <div class="col">
                  {{ `${user.FirstName} ${user.LastName}` }}
                </div>
                <div class="col">
                  {{ timestampToHours(user.SessionDuration) }}
                </div>
              </div>
            </template>
            <div v-else class="no-data">No Records</div>
          </div>
        </div>
      </div>
    </custom-popup>
  </div>
</template>

<script>
import CustomRoundCheckbox from "@/components/ReusableComponents/CustomRoundCheckbox/CustomRoundCheckbox";
import axios from "@/Helpers/AxiosInstance";
import { getDateString } from "@/Helpers/HelperFunctions";
import Overlay from "@/components/ReusableComponents/Overlay/Overlay";
import Popup from "@/components/ReusableComponents/Popup/Popup";
import CustomFileInput from "@/components/ReusableComponents/CustomFileInput/CustomFileInput";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput";

export default {
  components: {
    CustomInput,
    CustomSnackbar,
    CustomFileInput,
    Popup,
    Overlay,
    CustomRoundCheckbox,
    CustomPopup,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    highestSession: {
      type: Number,
      required: false,
    },
    period: {
      type: Object,
    },
    loading: Boolean,
    filters: Object,
  },
  computed: {
    dataComputed() {
      const data = JSON.parse(JSON.stringify(this.data));
      data.forEach((el) => {
        el.checked = false;
      });
      return data;
    },
    computedUsers() {
      if (this.usersModalSearchInput) {
        return this.users.filter(
          (el) =>
            el.FirstName.toLowerCase().includes(
              this.usersModalSearchInput.toLowerCase()
            ) ||
            el.LastName.toLowerCase().includes(
              this.usersModalSearchInput.toLowerCase()
            ) ||
            `${el.FirstName.toLowerCase()} ${el.LastName.toLowerCase()}`.includes(
              this.usersModalSearchInput.toLowerCase()
            )
        );
      }
      return this.users;
    },
  },
  data() {
    return {
      users: [],
      currentObject: null,
      userPopupActive: false,
      currentObjectIndex: null,
      imageModalIsActive: null,
      file: {},
      uploadSuccess: false,
      usersModalSearchInput: "",
    };
  },
  methods: {
    loadHandler(e, object) {
      const width = e.target.naturalWidth;
      const height = e.target.naturalHeight;
      object.moreWidthThanHeight = width > height;
      this.$set(this.dataComputed, this.data.indexOf(object), object);
    },
    getProgramLogo(name) {
      let isLocal = ~location.href.indexOf('localhost')
      let programName = name.trim()
      let source = `https://intranet.upgaming.${isLocal ? 'dev':'com'}/api/attendance/GetProgramLogoImg?ProgramName=${programName}` 
      return source;
    },
    getObjectIcon(url) {
      return `https://s2.googleusercontent.com/s2/favicons?domain_url=${url}`;
    },
    timestampToHours(timestamp) {
      const sec_num = parseInt(timestamp, 10);
      const hours = Math.floor(sec_num / 3600);
      const minutes = Math.floor(sec_num / 60) % 60;
      const seconds = sec_num % 60;

      return [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .join(":");
    },
    calculatePercentage(number, numberOf) {
      return (number / numberOf) * 100;
    },
    calculateTrafficShare(sessionDuration, allTime) {
      const number = (sessionDuration / allTime) * 100;
      return `${Math.round((number + Number.EPSILON) * 100) / 100}%`;
    },
    async getUsersData(object) {
      const response = await axios.get("attendance/GetProgramActivePersons", {
        params: {
          program: object.ProgramName,
          url: object.URL,
          fromDate: getDateString(this.period.from, "yyyy/MM/dd"),
          toDate: getDateString(this.period.to, "yyyy/MM/dd"),
          department: this.filters ? this.filters.departments : null,
          job: this.filters ? this.filters.jobs : null,
          person: this.filters ? this.filters.persons : null,
          IsUrl: false,
        },
      });

      this.users = response.data.Value;
    },
    changeFile(file) {
      this.file = file;
    },
    async uploadLogo() {
      const response = await axios.post("attendance/CreateProgramLogo", {
        ProgramName: this.currentObject.ProgramName,
        Base64: this.file.base64,
      });
      if (response) {
        this.uploadSuccess = true;
      }
      this.imageModalIsActive = false;
    },
    urlToName(url) {
      const splittedURL = url.split("/");
      return splittedURL
        ? splittedURL[2]
          ? splittedURL[2]
          : "Wrong URL"
        : "Wrong URL";
    },
  },
  watch: {
    userPopupActive(val, previous) {
      if (val) {
        if (this.currentObject === previous) return;
        this.getUsersData(this.currentObject);
      } else {
        this.users = [];
        this.currentObjectIndex = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
table {
  height: 100%;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;

  .no-record {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    font-size: 50px;
    color: rgba(#283593, 0.5);
  }

  .source {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 350px;
    padding: 0 0 20px 0;
    text-align: left;
    display: flex;
    align-items: center;

    a,
    span {
      width: 350px;
      text-align: left;
      display: flex;
      align-items: center;
    }

    &--small {
      width: 295px;
    }

    &--td {
      padding-bottom: 20px;
    }
  }

  .td--small {
    padding: 0 20px 20px 0 !important;
  }

  .index {
    width: 65px;
    padding: 0 10px 20px 10px;
    text-align: center;
    font-size: 16 * $rem;
    overflow: hidden;
    color: #9696b9;
  }

  .checkbox-th {
    width: 10px;
    padding: 0 0 20px 0 !important;
  }

  thead {
    th {
      padding: 0 70px 20px 0;
      color: #283593;
      font-weight: 400;
    }
  }

  tbody {
    tr {
      td {
        height: 40px;
        padding: 0 70px 20px 0;
        line-height: 20px;
        font-size: 14px;
        color: #283593;
      }
    }
  }
}

a {
  display: flex;
  align-items: center;
  text-decoration: none;

  &:link {
    color: #1db3e9;
  }

  &:visited {
    color: #1db3e9;
  }
}

.traffic-share {
  width: 550px;
  display: flex;
  line-height: 20px;
  border-radius: 3px;

  position: relative;

  &__inactive {
    height: 100%;
    opacity: 0.5;
    background-color: #283593;
    width: 30%;
    position: relative;
    border: 1px solid #ededfc;
    border-radius: 3px;
  }

  &__active {
    border: 1px solid #ededfc;
    height: 100%;
    width: 70%;
    background-color: #1de9b6;
    opacity: 0.6;
    position: relative;
    border-radius: 3px;
  }

  &__percentage {
    white-space: nowrap;
    z-index: 2;
    position: absolute;
    top: -18px;
    right: 0;

    &--down {
      //top: unset;
      //bottom: -18px;
    }
  }
}

.traffic-share-header {
  text-align: left !important;
}

.users {
  position: relative;

  span {
    cursor: pointer;
  }
}

.clickable {
  cursor: pointer;
}

.upload-button {
  cursor: pointer;
  width: 197 * $rem;
  display: inline-block;
  height: 40 * $rem;
  line-height: 40 * $rem;
  padding: 0 15 * $rem 0 15 * $rem;
  outline: none;
  text-align: center;
  color: white;
  margin-top: 10px;
  border-radius: 5 * $rem;
  box-shadow: none;
  background-color: #1de9b6;
}

.loader {
  display: block;
  height: 12px;
  width: 100px;
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  border-radius: 5px;

  &--index {
    width: 25px;
  }

  &--source {
    width: 100%;
  }

  &--traffic-share {
    width: 100%;
  }

  &--traffic-share-small {
    width: 100%;
  }
}

@-webkit-keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }
  100% {
    background-position: 250px 0;
  }
}

.img-wrapper {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.625rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 3 * $rem;

  img {
    border-radius: 4 * $rem;
    width: 24 * $rem;
    max-height: 90%;
    object-fit: contain;
  }
}

.table {
  display: flex;
  flex-direction: column;
  width: 100%;

  .headers {
    margin-bottom: 10px;
    color: #283593;
    font-weight: 500;
  }

  .user {
    cursor: pointer;
  }

  .row {
    color: #283593;
    display: grid;
    height: 25px;
    grid-template-columns: 30px 40px 320px 80px 150px 113px;
    align-items: center;
    text-align: left;
    margin-bottom: 10px;

    &--urls {
      margin-left: 90px;
      grid-template-columns: 300px 80px 150px 113px;
    }

    &--user-modal {
      grid-template-columns: 30px 300px 113px;
    }

    .col {
      display: flex;
      text-overflow: ellipsis;
      white-space: nowrap;

      a {
        overflow: hidden;
      }

      .flex {
        overflow: hidden;
      }

      &--center {
        justify-content: left;
      }
    }
  }
}

.users-modal {
  &__search {
    margin-left: auto;
  }

  &__title {
    color: #283593;
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }
}

.table-body {
  max-height: 480px;
  overflow-y: auto;
}

.no-data {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $blue-primary;
}

.tbody {
  max-height: 700px;
  overflow: auto;
}
</style>
