<template>
  <div class="select-content">
    <div class="container" @click.stop="itemsVisible = !itemsVisible">
      <div class="title-content">
        <span class="title">{{ title }} {{ isRequired ? "*" : "" }}</span>
      </div>
      <div class="selected-items-content">
        <input class="for-validate" :required="isRequired" />
        <div class="items">
          <template v-for="(item, index) in filteredItems">
            <div
              v-if="item.selected"
              :key="index"
              @click.stop="deleteSelected(index, item)"
              class="single-item"
            >
              <div class="desc-icon-wrapper">
                <input
                  v-if="index === itemToEditIndex"
                  :value="
                    focusedItem ? item.DescriptionTranslated : item.Description
                  "
                  ref="descInput"
                  @input="inputHandler(item)"
                  @focus="test"
                  @blur="(itemToEditIndex = null), (focusedItem = false)"
                  type="text"
                  :size="item.Description.length"
                  @click.stop
                />
                <span v-else>{{
                  item.DescriptionTranslated
                    ? item.DescriptionTranslated
                    : item.Description
                }}</span>
                <span
                  class="material-icons"
                  @click.stop="editClickHandler(item, index)"
                >
                  edit
                </span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="arrows-content">
        <i class="material-icons" v-show="!itemsVisible">keyboard_arrow_down</i>
        <i class="material-icons arrow-up" v-show="itemsVisible"
          >keyboard_arrow_up</i
        >
      </div>
    </div>

    <div class="items-content" @click.stop v-show="itemsVisible">
      <input placeholder="search..." type="text" v-model="search" />
      <div class="wrapper">
        <div
          class="single-item"
          @click="itemClickHanlder(item)"
          v-for="(item, index) in filteredItems"
          :key="index"
        >
          <div
            @mouseenter="showIcon(item)"
            @mouseleave="hoveredItem = 0"
            class="item-name"
            :class="{
              selected: item.selected,
            }"
          >
            <span>{{
              item.DescriptionTranslated
                ? item.DescriptionTranslated
                : item.Description
            }}</span>

            <div class="remove-item-wrapper">
              <span
                v-show="hoveredItem === item.ID"
                @click.stop="$emit('delete-item', item, index)"
                class="material-icons-outlined"
              >
                clear
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="add-new-content">
        <span @click="showInput = true" v-if="!showInput">+ Add new</span>
        <div class="input-content" v-else>
          <input v-model="newItem" type="text" placeholder="Type ..." />
          <span class="material-icons success" @click="addNewItem">check</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    document.addEventListener("click", this.documentClick);
  },
  components: {},
  props: {
    isOpened: Boolean,

    isRequired: {
      type: Boolean,
      default: false,
    },
    selectedLang: Number,
    componentTypeId: Number,
    objectTypeId: Number,
    items: Array,
    positionEditMode: Boolean,
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      itemsVisible: false,
      showInput: false,
      search: "",
      hoveredItem: "",
      focusedItem: false,
      newItem: "",
      itemToEditIndex: null,

      jobs: [],
    };
  },

  methods: {
    itemClickHanlder(el) {
      this.$set(el, "selected", !el.selected);
      if (!el.newItem) this.$emit("select-id", el.ID);
    },

    inputHandler(item) {
      if (item.languageID !== item.defaultLangId) {
        item.DescriptionTranslated = event.target.value;
      } else {
        item.DescriptionTranslated = event.target.value;
        item.Description = event.target.value;
      }
    },
    test() {
      this.focusedItem = true;
    },
    editClickHandler(item, index) {
      this.$set(item, "editActive", !item.editActive);
      this.itemToEditIndex = null;
      this.itemToEditIndex = index;
      setTimeout(() => {
        this.$refs.descInput[this.$refs.descInput.length - 1].focus();
      }, 100);
    },
    deleteSelected(index, clickedItem) {
      clickedItem.selected = false;
      if (!clickedItem.newItem)
        this.$emit("delete-selected-id", clickedItem.ID);
    },
    showIcon(el) {
      this.hoveredItem = el.ID;
    },
    documentClick() {
      this.itemsVisible = false;
    },
    addNewItem() {
      this.$emit("add-new-item", this.newItem, this.componentTypeId);
      this.showInput = false;
      this.newItem = "";
    },
  },
  computed: {
    filteredItems() {
      if (!this.search.length) return this.items;
      return this.items.filter((el) =>
        el.Description.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  watch: {
    selectedLang() {
      if(this.positionEditMode){
        setTimeout(() => {
         if (!this.items.filter((el) => el.newItem).length) return;

        let itemsToTranslate = JSON.parse(
          JSON.stringify(
            this.items.filter(
              (el) => el.newItem && el.selected && el.DescriptionTranslated.length
            )
          )
        );
        this.$emit("single-lang-items", itemsToTranslate);
        this.items
          .filter((el) => el.newItem)
          .forEach((element) => {
            element.DescriptionTranslated = "";
            element.languageID = this.selectedLang;
          }); 
        }, 1000);
      }
      if (!this.items.filter((el) => el.newItem).length) return;

      let itemsToTranslate = JSON.parse(
        JSON.stringify(
          this.items.filter(
            (el) => el.newItem && el.selected && el.DescriptionTranslated.length
          )
        )
      );
      this.$emit("single-lang-items", itemsToTranslate);
      this.items
        .filter((el) => el.newItem)
        .forEach((element) => {
          element.DescriptionTranslated = "";
          element.languageID = this.selectedLang;
        });
    },
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style lang="scss" scoped>
.select-content {
  position: relative;
}
.selected {
  background: #6956d6;
  border: 0.5px solid #959bc9;
  color: white;
}
.selected-items-content {
  width: 100%;
  .for-validate {
    opacity: 0;
    position: absolute;
  }
  .items {
    width: 95%;
    white-space: break-spaces;
  }

  .single-item {
    position: relative;
    display: inline-block;
    padding: 5px;
    font-size: 13px;
    font-weight: 300;
    border-radius: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
    border: 0.5px solid #959bc9;
    background: #6956d6;
    color: #fff;
    .desc-icon-wrapper {
      display: flex;
      align-items: center;
      input {
        background: transparent;
        border: 0;
        outline: none;
        white-space: nowrap;
        color: #fff;
        font-size: 12px;
      }
      .material-icons {
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }
}
.container {
  position: relative;
  display: flex;
  padding-top: 9px;

  .arrows-content {
    position: absolute;
    right: 0.9375rem;
    top: 9px;
    .material-icons {
      font-size: 1.125rem;
    }
  }
  width: 100%;
  min-height: 40 * $rem;
  padding-left: 15 * $rem;
  padding-right: 15 * $rem;
  border-radius: 6px;
  border: $rem solid #dcdde6;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  cursor: pointer;
  white-space: nowrap;
  .title {
    font-size: 14px;
    font-weight: 300;
    color: rgba($blue-primary, 0.7);
  }

  i {
    font-size: 24 * $rem;
    color: rgba($blue-primary, 0.6);
  }
}
.wrapper {
  max-height: 200px;
  overflow: auto;
}
.items-content {
  display: flex;
  flex-direction: column;

  position: absolute;
  list-style: none;
  background-color: #fff;
  z-index: 150;
  width: 100%;
  max-height: 17rem;

  background-color: white;
  border: 0.0625rem solid rgba(#6956d6, 0.5);
  border-bottom: 0.0625rem solid rgba(#6956d6, 0.1);
  color: #dadcea;
  border-radius: 0.375rem;
  box-shadow: 6 * $rem 5 * $rem 12 * $rem 0 rgba($blue-primary, 0.08);
  padding: 12 * $rem 0;
  user-select: none;

  animation: show 0.1s linear;
  padding: 15px 0 15px 15px;
  > input {
    width: calc(100% - 15px);
    // margin: 0 0 0 1.125rem;
    padding-left: 0.75rem;
    border: 0.0625rem solid rgba(40, 53, 147, 0.2);
    border-radius: 0.1875rem;
    font-weight: 200;
    font-size: 0.8125rem;
    outline: none;
    color: #9696b9;

    height: 1.875rem;
    margin-bottom: 0.3125rem;
  }
  .add-new-content {
    position: absolute;
    bottom: -31px;
    border: 0.0625rem solid rgba(#6956d6, 0.5);
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: $blue-primary;
    font-weight: 300;
    font-size: 14px;
    padding-left: 15px;
    height: 30px;
    border-top: 0;
    border-radius: 0.375rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-right: 15px;
    padding-left: 15px;
    box-shadow: 6 * $rem 5 * $rem 12 * $rem 0 rgba($blue-primary, 0.08);
    // height: 50px;
    > span {
      cursor: pointer;
    }
    .input-content {
      display: flex;
      width: 100%;
      input {
        outline: none;
        font-size: 13px;
        border: 0;
        color: rgba(40, 53, 147, 0.7);
        width: 100%;
        &::placeholder {
          font-size: 12px;
          color: rgba(40, 53, 147, 0.8);
        }
      }
      .material-icons {
        font-size: 18px;
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
  .single-item {
    display: inline-block;
    margin-right: 15px;

    cursor: pointer;
    color: rgba($blue-primary, 0.7);
    margin-top: 10px;
    .item-name {
      position: relative;
      padding: 5px;
      font-size: 13px;
      font-weight: 300;
      border-radius: 5px;
      display: flex;
      align-items: center;

      border: 0.5px solid #959bc9;
      .remove-item-wrapper {
        padding-left: 5px;
        width: 1rem;
        height: 1rem;
        display: grid;

        span {
          font-size: 14px;
        }
      }
    }
  }
}
</style>