<template>
  <div class="background">
    <custom-popup :visible="true" v-if="offer">
      <p v-html="offer.Title"></p>
      <div
        class="banner-content"
        :style="{
          backgroundImage: `url(data:image/jpeg;base64,${offer.File})`,
        }"
      ></div>
      <div class="description-content">
        <p>Terms</p>
        <div class="desc-text">
          <span v-html="offer.Description"></span>
        </div>
      </div>
      <div class="attachment-content">
        <div class="url-content">
          <span
            >Visit web page: <a>{{ offer.URL }}</a></span
          >
        </div>
        <div class="download-content">
          <span>Download Attachment</span>
          <span class="material-icons"> download </span>
        </div>
      </div>
      <div class="checkboxes">
        <div class="agree">
          <span></span>
          <span>I agree</span>
        </div>
        <div class="dont-agree">
          <span></span>
          <span>Do not agree</span>
        </div>
      </div>
      <div class="options-content">
        <p>Options</p>
        <div class="options">
          <div
            v-for="(opt, index) in offerOpt"
            :key="opt.OfferOptionIDx"
            class="single-opt"
          >
            <div class="opt-info-wrapper">
              <div class="left-side">
                <span>{{ index + 1 }}</span>
              </div>
              <div class="right-side">
                <span>{{ opt.OfferOptionDescription }}</span>
                <span v-if="opt.FoundedByCompany != 0">{{
                  opt.FoundedByCompany
                }}</span>
                <span v-if="opt.FoundedByCompany != 0">Funded by company</span>
                <span v-if="opt.EmployeeShare != 0">{{
                  opt.EmployeeShare
                }}</span>
                <span v-if="opt.EmployeeShare != 0">My share</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </custom-popup>
  </div>
</template>

<script>
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";

import axios from "@/Helpers/AxiosInstance";
export default {
  components: { CustomPopup },
  created() {
    this.getOffer();
  },

  data() {
    return {
      offer: null,
      offerOpt: null,
      restrictions: null,
    };
  },
  methods: {
    getOffer() {
      axios
        .get(`News/GetOfferDetails?Offer=${this.$route.params.id}`)
        .then((resp) => {
          this.offer = resp.data.Value.Offer;
          this.offerOpt = resp.data.Value.OfferOptions;
          this.restrictions = resp.data.Value.Restrictions;
        });
    },
    htmlToText(html) {
      const e = document.createElement("p");
      e.innerHTML = html;

      return e.textContent;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 20px;
  font-weight: 500;
  color: #292929;
}
::v-deep .overlay .popup {
  width: 1342px;
  height: initial;
  left: 56%;
  top: 54%;
  h3.title {
    color: #292929;
    font-weight: 500;
    font-size: 20px;
  }
}
.banner-content {
  height: 250px;
  border-radius: 10px;
  margin-bottom: 40px;
}
.description-content {
  .desc-text {
    margin-top: 10px;
    height: 155px;
    border: 1px solid #dcdde6;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 10px;
    font-weight: 300;
    color: #757575;
    overflow: auto;
  }
}
.attachment-content {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  .url-content {
    a {
      color: #1db3e9;
    }
  }
  .download-content {
    display: flex;
    align-items: center;
    span:nth-child(2) {
      color: $blue-primary;
      font-size: 20px;
      margin-left: 5px;
    }
  }
}
.checkboxes {
  display: flex;
  margin-top: 55px;
  margin-bottom: 50px;
  .agree,
  .dont-agree {
    display: flex;
    align-items: center;
    margin-right: 30px;
    span:nth-child(1) {
      width: 14px;
      height: 14px;
      border: 1px solid $blue-primary;
      border-radius: 3px;
      margin-right: 10px;
    }
    span:nth-child(2) {
      font-size: 16px;
      font-weight: 300;
      color: #292929;
    }
  }
}
.options {
  margin-top: 25px;
  white-space: nowrap;
  width: 100%;
  overflow-x: scroll;
  .single-opt {
    width: 19.9%;
    height: 74px;
    background-color: #ededfc;
    display: inline-block;
    margin-left: 1px;
    border-radius: 5px;
    .opt-info-wrapper {
      display: flex;
      height: 100%;

      .left-side {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 20px;
        margin-right: 20px;
        span {
          font-size: 30px;
          font-weight: 500;
          color: #9696b9;
        }
      }
      .right-side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        span {
          font-size: 14px;
          font-weight: 300;
          color: #292929;
        }
      }
    }
  }
}
</style>