var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background",class:{ offersVisible: !_vm.offersVisible },on:{"click":function($event){_vm.audienceVisible = 0}}},[_c('section',{staticClass:"banner-section"},[_c('div',{staticClass:"left-side",style:({
          backgroundImage: `url(data:image/jpeg;base64,${_vm.mainBanner})`,
        })})]),_c('section',{staticClass:"offers-section"},[_c('div',{staticClass:"offer-header"},[_c('span',[_vm._v("Corporate Offers")]),_c('div',{staticClass:"offers-popup-button",on:{"click":function($event){_vm.offersVisible = !_vm.offersVisible}}},[_c('div'),_c('div'),_c('div')])]),_c('div',{staticClass:"offer-blocks-content"},[_c('swiper',{ref:"swiper",attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.offersByTime),function(offer){return _c('swiper-slide',{key:offer.Offer,staticClass:"single-offer"},[_c('div',{staticClass:"offer-wrapper",on:{"click":function($event){return _vm.getSingleOffer(offer.Offer)}}},[_c('div',{staticClass:"offer-banner",style:({
                  backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${offer.Offer}&PlacementType=${offer.PlacementType})`,
                })},[(offer.BannerTitle)?_c('span',{staticClass:"banner-title",domProps:{"innerHTML":_vm._s(offer.BannerTitle)}}):_vm._e()]),_c('div',{staticClass:"offer-info"},[_c('p',{staticClass:"offer-title"},[_vm._v(_vm._s(offer.Title))]),_c('div',{staticClass:"offer-desc"},[_vm._v(" "+_vm._s(_vm.htmlToText(offer.Description))+" ")])])])])}),1),(_vm.offers.length > 2 && _vm.offersVisible)?_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},on:{"click":function($event){return _vm.next(true)}},slot:"button-next"}):_vm._e(),(_vm.offers.length > 2 && _vm.offersVisible)?_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},on:{"click":function($event){return _vm.prev(true)}},slot:"button-prev"}):_vm._e()],1)]),_c('section',{ref:"newsContainer",staticClass:"news-polls-section"},[_c('ul',{staticClass:"column-one"},_vm._l((_vm.itemsForColumnOne),function(el,index){return _c('li',{key:index,class:{ itemWithBanner: index % 2 }},[_c('div',{staticClass:"persons-banner-wrapper",on:{"click":function($event){return _vm.getSinglePollOrNews(el)}}},[(el.isPoll)?_c('img',{attrs:{"src":require('@/assets/icons/poll-flag-user.svg')}}):_vm._e(),(index % 2)?_c('div',{staticClass:"banner",style:({
                backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${
                  el.Poll || el.News
                }&PlacementType=${el.PlacementType})`,
              })}):_vm._e(),_c('div',{staticClass:"info-wrapper"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.getDateString(el.PublishDate)))]),_c('h2',[_vm._v(_vm._s(el.Title))]),_c('p',[_vm._v(_vm._s(_vm.htmlToText(el.Description)))])])]),_c('div',{staticClass:"persons-content",on:{"click":function($event){$event.stopPropagation();return _vm.audienceClickHandler(el)}}},[_vm._l((el.Visibility.slice(0, 6)),function(person){return _c('div',{key:person.Person,staticClass:"avatar",style:({
                'background-image': `url(data:image/jpeg;base64,${person.File})`,
              })})}),(el.Visibility.length > 6)?_c('div',{staticClass:"count"},[_vm._v(" +"+_vm._s(el.Visibility.length - 6)+" ")]):_vm._e(),_c('audience-list',{attrs:{"data":_vm.listOfPersons,"placementId":el.News || el.Poll,"audienceVisible":_vm.audienceVisible}})],2)])}),0),_c('ul',{staticClass:"column-two"},_vm._l((_vm.itemsForColumnTwo),function(el,index){return _c('li',{key:index,class:{ itemWithBanner: !(index % 2) }},[_c('div',{staticClass:"persons-banner-wrapper",on:{"click":function($event){return _vm.getSinglePollOrNews(el)}}},[(el.isPoll)?_c('img',{attrs:{"src":require('@/assets/icons/poll-flag-user.svg')}}):_vm._e(),(!(index % 2))?_c('div',{staticClass:"banner",style:({
                backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${
                  el.Poll || el.News
                }&PlacementType=${el.PlacementType})`,
              })}):_vm._e(),_c('div',{staticClass:"info-wrapper"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.getDateString(el.PublishDate)))]),_c('h2',[_vm._v(_vm._s(el.Title))]),_c('p',[_vm._v(_vm._s(_vm.htmlToText(el.Description)))])])]),_c('div',{staticClass:"persons-content",on:{"click":function($event){$event.stopPropagation();return _vm.audienceClickHandler(el)}}},[_vm._l((el.Visibility.slice(0, 6)),function(person){return _c('div',{key:person.Person,staticClass:"avatar",style:({
                'background-image': `url(data:image/jpeg;base64,${person.File})`,
              })})}),(el.Visibility.length > 6)?_c('div',{staticClass:"count"},[_vm._v(" +"+_vm._s(el.Visibility.length - 6)+" ")]):_vm._e(),_c('audience-list',{attrs:{"data":_vm.listOfPersons,"placementId":el.News || el.Poll,"audienceVisible":_vm.audienceVisible}})],2)])}),0),_c('ul',{staticClass:"column-three"},_vm._l((_vm.itemsForColumnThree),function(el,index){return _c('li',{key:index},[_c('div',{staticClass:"persons-banner-wrapper",on:{"click":function($event){return _vm.getSinglePollOrNews(el)}}},[(el.isPoll)?_c('img',{attrs:{"src":require('@/assets/icons/poll-flag-user.svg')}}):_vm._e(),_c('div',{staticClass:"info-wrapper"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.getDateString(el.PublishDate)))]),_c('h2',[_vm._v(_vm._s(el.Title))]),_c('p',[_vm._v(_vm._s(_vm.htmlToText(el.Description)))])])]),_c('div',{staticClass:"persons-content",on:{"click":function($event){$event.stopPropagation();return _vm.audienceClickHandler(el)}}},[_vm._l((el.Visibility.slice(0, 6)),function(person){return _c('div',{key:person.Person,staticClass:"avatar",style:({
                'background-image': `url(data:image/jpeg;base64,${person.File})`,
              })})}),(el.Visibility.length > 6)?_c('div',{staticClass:"count"},[_vm._v(" +"+_vm._s(el.Visibility.length - 6)+" ")]):_vm._e(),_c('audience-list',{attrs:{"data":_vm.listOfPersons,"placementId":el.News || el.Poll,"audienceVisible":_vm.audienceVisible}})],2)])}),0),_c('ul',{staticClass:"column-four"},_vm._l((_vm.itemsForColumnFour),function(el,index){return _c('li',{key:index,class:{ itemWithBanner: index % 2 }},[_c('div',{staticClass:"persons-banner-wrapper",on:{"click":function($event){return _vm.getSinglePollOrNews(el)}}},[(el.isPoll)?_c('img',{attrs:{"src":require('@/assets/icons/poll-flag-user.svg')}}):_vm._e(),(index % 2)?_c('div',{staticClass:"banner",style:({
                backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${
                  el.Poll || el.News
                }&PlacementType=${el.PlacementType})`,
              })}):_vm._e(),_c('div',{staticClass:"info-wrapper"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.getDateString(el.PublishDate)))]),_c('h2',[_vm._v(_vm._s(el.Title))]),_c('p',[_vm._v(_vm._s(_vm.htmlToText(el.Description)))])])]),_c('div',{staticClass:"persons-content",on:{"click":function($event){$event.stopPropagation();return _vm.audienceClickHandler(el)}}},[_vm._l((el.Visibility.slice(0, 6)),function(person){return _c('div',{key:person.Person,staticClass:"avatar",style:({
                'background-image': `url(data:image/jpeg;base64,${person.File})`,
              })})}),(el.Visibility.length > 6)?_c('div',{staticClass:"count"},[_vm._v(" +"+_vm._s(el.Visibility.length - 6)+" ")]):_vm._e(),_c('audience-list',{attrs:{"data":_vm.listOfPersons,"placementId":el.News || el.Poll,"audienceVisible":_vm.audienceVisible}})],2)])}),0)]),_c('div',{staticClass:"offer-popup",on:{"click":function($event){$event.stopPropagation();_vm.downloadAllVisible = false}}},[(_vm.offerDetails.offer)?_c('custom-popup',{attrs:{"visible":_vm.offerDetailVisible},on:{"close":function($event){_vm.offerDetailVisible = false}}},[_c('div',{staticClass:"banner-desc-content"},[_c('div',{staticClass:"banner-content",style:({
              backgroundImage: `url(data:image/jpeg;base64,${_vm.offerDetails.offer.Banner})`,
            })}),_c('div',{staticClass:"description-content"},[_c('p',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.offerDetails.offer.Title)}}),_c('div',{staticClass:"desc-text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.offerDetails.offer.Description)}})])])]),_c('div',{staticClass:"attachment-content"},[_c('div',{staticClass:"files"},[_c('div',{staticClass:"title"},[_vm._v(" Attachments ("+_vm._s(_vm.offerDetails.offer.Attachments.length)+") "),(_vm.offerDetails.offer.Attachments.length > 0)?_c('div',{staticClass:"dots-content",on:{"click":function($event){$event.stopPropagation();_vm.downloadAllVisible = !_vm.downloadAllVisible}}},[_c('div'),_c('div'),_c('div'),(_vm.downloadAllVisible)?_c('div',{staticClass:"download-button",on:{"click":function($event){$event.stopPropagation();return _vm.downloadAllHandler.apply(null, arguments)}}},[_c('span',[_vm._v("Download all")])]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"files-content"},_vm._l((_vm.offerDetails.offer.Attachments),function(att,index){return _c('a',{key:index,attrs:{"target":"_blank"},on:{"click":function($event){return _vm.attClickHandler(att)}}},[_c('file-block',{attrs:{"uploadedFile":att}})],1)}),0)]),_c('div',{staticClass:"url-content"},[(_vm.offerDetails.offer.URL)?_c('span',[_vm._v("Visit web page: "),_c('a',{attrs:{"href":_vm.offerDetails.offer.URL,"target":"_blank"}},[_vm._v(_vm._s(_vm.offerDetails.offer.URL))])]):_vm._e()])]),_c('div',{staticClass:"options-content"},[_c('p',[_vm._v("Options")]),_c('div',{staticClass:"options"},[_c('swiper',{ref:"swiperTwo",attrs:{"options":_vm.swiperOptionsTwo}},_vm._l((_vm.offerDetails.offerOpt),function(opt,index){return _c('swiper-slide',{key:opt.OfferOptionID,staticClass:"single-opt",class:{ clickedOptStyle: opt.active }},[_c('div',{staticClass:"for-event",on:{"click":function($event){return _vm.optClicked(opt)}}},[_c('div',{staticClass:"opt-info-wrapper",attrs:{"title":opt.OfferOptionDescription.length > 25
                        ? opt.OfferOptionDescription
                        : null}},[_c('div',{staticClass:"left-side"},[_c('span',[_vm._v(_vm._s(index + 1))])]),_c('div',{staticClass:"right-side"},[_c('span',[_vm._v(_vm._s(opt.OfferOptionDescription))]),(opt.FoundedByCompany != 0)?_c('span',[_vm._v(_vm._s(opt.FoundedByCompany + " " + opt.CurrencyCode))]):_vm._e(),(opt.FoundedByCompany != 0)?_c('span',[_vm._v("Funded by company")]):_vm._e(),(opt.EmployeeShare != 0)?_c('span',[_vm._v(_vm._s(opt.EmployeeShare + " " + opt.CurrencyCode))]):_vm._e(),(opt.EmployeeShare != 0)?_c('span',[_vm._v("My share")]):_vm._e()])])])])}),1),(_vm.offerDetails.offerOpt.length > 5)?_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},on:{"click":function($event){return _vm.next()}},slot:"button-next"}):_vm._e(),(_vm.offerDetails.offerOpt.length > 5)?_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},on:{"click":function($event){return _vm.prev()}},slot:"button-prev"}):_vm._e()],1)]),_c('div',{staticClass:"comment-content"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.offerComment),expression:"offerComment"}],attrs:{"placeholder":"Type your comment","cols":"30","rows":"10"},domProps:{"value":(_vm.offerComment)},on:{"input":function($event){if($event.target.composing)return;_vm.offerComment=$event.target.value}}})]),_c('div',{staticClass:"button-content"},[_c('button',{on:{"click":_vm.sendResponseOnOffer}},[_vm._v("Send Response")])])]):_vm._e()],1),_c('div',{staticClass:"poll-popup"},[(_vm.poll)?_c('custom-popup',{attrs:{"visible":_vm.pollDetailsVisible,"flexible-size":true},on:{"close":function($event){_vm.pollDetailsVisible = false}}},[_c('div',{staticClass:"poll"},[_c('div',{staticClass:"poll__content"},[_c('div',{staticClass:"banner-details-content"},[_c('div',{staticClass:"poll__banner",style:({
                  'background-image': `url(data:image/jpeg;base64,${_vm.poll.file})`,
                })}),_c('div',{staticClass:"poll__details"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"poll-date"},[_vm._v(" Expiry date: "+_vm._s(_vm.getDateString(_vm.poll.expiryDate, "dd MMM yyyy"))+" ")]),_c('div',{staticClass:"poll__question"},[_vm._v(" "+_vm._s(_vm.poll.question || "question")+" ")]),_c('div',{staticClass:"poll__options"},_vm._l((_vm.poll.options),function(option,i){return _c('div',{key:i,staticClass:"poll__option"},[_c('div',{staticClass:"poll__option-wrapper"},[_c('div',{staticClass:"poll__option-index"},[_vm._v(_vm._s(i + 1))]),_c('div',{staticClass:"poll__option-name"},[_vm._v(_vm._s(option.option))])]),_c('div',{class:[
                          'poll__option-checkbox',
                          {
                            'poll__option-checkbox--selected': _vm.selectedOptions.includes(
                              option.id
                            ),
                          },
                        ],on:{"click":function($event){return _vm.optionClickHandler(option)}}},[(_vm.selectedOptions.includes(option.id))?_c('svg',{staticClass:"checkbox__mark",attrs:{"xmlns":"http://www.w3.org/2000/svg","x":"0px","y":"0px","viewBox":"0 0 512 512","xml:space":"preserve"}},[_c('path',{attrs:{"fill":"#283593","d":"M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0\n\t\t\tc-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7\n\t\t\tC514.5,101.703,514.499,85.494,504.502,75.496z"}})]):_vm._e()])])}),0)]),(!_vm.alreadySelected && !_vm.poll.anonymous)?_c('div',{staticClass:"poll__comment"}):_vm._e()])]),_c('div',{staticClass:"desc-comment-content"},[_c('div',{staticClass:"poll__description"},[_vm._v(_vm._s(_vm.poll.description))]),(!_vm.poll.anonymous)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.pollComment),expression:"pollComment"}],staticClass:"cstm-input",attrs:{"placeholder":"Type your comment","type":"text"},domProps:{"value":(_vm.pollComment)},on:{"input":function($event){if($event.target.composing)return;_vm.pollComment=$event.target.value}}}):_vm._e()])]),(!_vm.alreadySelected)?_c('div',{staticClass:"poll__button",on:{"click":_vm.makePollChoice}},[_vm._v(" Send ")]):_vm._e()])]):_vm._e()],1),_c('div',{staticClass:"news-popup"},[_c('custom-popup',{attrs:{"visible":_vm.newsDetailsVisible,"flexible-size":true},on:{"close":function($event){_vm.newsDetailsVisible = false}}},[(_vm.newsDetails)?_c('div',{staticClass:"news"},[_c('div',{staticClass:"desc-banner-content"},[(_vm.newsDetails.banner)?_c('div',{staticClass:"news__banner",style:({
                backgroundImage: `url(data:image/jpeg;base64,${_vm.newsDetails.banner})`,
              })}):_vm._e(),_c('div',{staticClass:"desc-content"},[_c('div',{staticClass:"news__title"},[_vm._v(" "+_vm._s(_vm.newsDetails.title)+" ")]),_c('div',{class:[
                  'news__text',
                  { 'news__text--without-banner': !_vm.newsDetails.banner },
                ],domProps:{"innerHTML":_vm._s(_vm.newsDetails.text)}})])]),_c('div',{staticClass:"news__audience"},[_c('div',{staticClass:"news__audience-header"},[_c('div',{staticClass:"news__audience-header-title"},[_c('b',[_vm._v("Audience")]),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.audience.length)+" ")])])]),_c('div',{directives:[{name:"dragscroll",rawName:"v-dragscroll"}],staticClass:"news__audience-items"},_vm._l((_vm.audience),function(person){return _c('div',{key:person.PersonID,staticClass:"news__audience-items-item"},[(person)?_c('div',{staticClass:"news__audience-items-item-avatar",style:({
                    'background-image': `url(data:image/jpeg;base64,${person.File})`,
                  })}):_vm._e(),_c('div',{staticClass:"news__audience-items-item-name"},[_c('p',[_vm._v(_vm._s(person.FirstName))]),_c('p',[_vm._v(_vm._s(person.LastName))])])])}),0)])]):_c('span')])],1),(_vm.snackbarVisible)?_c('custom-snackbar',{attrs:{"purpose":_vm.snackbarPurpose,"text":_vm.snackbarText},on:{"close":function($event){_vm.snackbarVisible = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }