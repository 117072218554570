<template>
  <div class="background">
    <!-- <div class="team_header">
      <form autocomplete="off" class="searchTeam">
        <input
          type="text"
          v-model="filterTeam"
          name="SearchTeams"
          placeholder="Search Teams"
          id=""
        />
      </form>
      <add-button
        title="Add Team"
        @clicked="addNewClickHandler"
        v-if="accessPermissions.createTeam"
        class="addBtn"
      />
    </div> -->
    <div class="teams">
      <grid
        title="Teams"
        :gridData="gridData"
        :columns="columns"
        :canAdd="true"
        :passedPageSize="gridParams.pageSize"
        :dataCount="gridParams.totalCount"
        identificator="ID"
        @add-click="addNewClickHandler"
        :canDelete="accessPermissions.createTeam"
        @rowDelete="showDeletePopup"
        :canEdit="accessPermissions.createTeam"
        @rowEdit="editRow"
        @trClicked="trClickHandler"
      />
      <overlay :visible="popupVisible" @close="popupVisible = false">
        <popup
          title="Add Team"
          @close="popupVisible = false"
          class="add-new-popup"
          :flexibleSize="true"
        >
          <form class="add-form" @submit.prevent="addTeam">
            <div class="row">
              <custom-input
                v-model="newTeam.name"
                title="Name"
                @clear="newTeam.name = ''"
                :isRequired="true"
              />
              <date-picker
                title="Start Date"
                v-model="newTeam.startTime"
                @clear="newTeam.startTime = ''"
              />
              <button class="btn">Save</button>
            </div>

            <div class="row">
              <choose-persons
                :allPersons="allPersons"
                @person-clicked="personClickHanlder"
                :isOpened="isOpened"
                title="Select team lead/s"
              />
            </div>

            <div class="row">
              <custom-text-area
                title="Description"
                v-model="newTeam.description"
                :isRequired="true"
              />
            </div>
          </form>
        </popup>
      </overlay>
      <team-members
        v-if="membersVisible"
        :teamID="selectedTeamID"
        @close="membersVisible = false"
      />
      <delete-item
        @delete="deleteRow"
        @close="deletePopupVisible = false"
        v-if="deletePopupVisible"
        :text="deleteText"
      />
      <custom-snackbar
        v-if="snackbarVisible"
        :purpose="snackbarPurpose"
        :text="snackbarText"
        @close="snackbarVisible = false"
      />
    </div>
  </div>
</template>

<script>
import Grid from "@/components/ReusableComponents/Grid/Grid.vue";
// import AddButton from "@/components/ReusableComponents/AddButton/AddButton.vue";
import Overlay from "@/components/ReusableComponents/Overlay/Overlay.vue";
import Popup from "@/components/ReusableComponents/Popup/Popup.vue";
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput.vue";
import CustomTextArea from "@/components/ReusableComponents/CustomTextArea/CustomTextArea.vue";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import axios from "@/Helpers/AxiosInstance.js";
import {
  CETToTimestamp,
  checkPermission,
  timestampToDateTime,
} from "@/Helpers/HelperFunctions.js";
import DeleteItem from "@/components/ReusableComponents/DeleteItem/DeleteItem.vue";
import TeamMembers from "./TeamMembers.vue";
import choosePersons from "@/components/ReusableComponents/ChoosePersons/choosePersons.vue";
export default {
  components: {
    Grid,
    // AddButton,
    Overlay,
    CustomInput,
    CustomTextArea,
    Popup,
    DatePicker,
    CustomSnackbar,
    DeleteItem,
    TeamMembers,
    choosePersons,
  },
  created() {
    this.getData();
    this.getPersons();
  },
  data() {
    return {
      accessPermissions: {
        getSchedules: checkPermission("GetSchedules"),
        createTeam: checkPermission("CreateTeam"),
        assignTeamPersons: checkPermission("AssignTeamPersons"),
      },
      gridData: [],
      popupVisible: false,
      newTeam: {
        name: "",
        teamleader: "",
        description: "",
        startTime: new Date().getTime(),
      },
      allPersons: [],
      selectedPersons: [],
      filterTeam: "",
      isOpened: false,
      selectedPerson: "",
      snackbarPurpose: "error",
      snackbarText: "",
      snackbarVisible: false,
      cities: [],
      deletePopupVisible: false,
      selectedTeamID: "",
      gridParams: {
        currentPage: 1,
        pageSize: 20,
        orderByColumn: "ID",
        ascending: false,
        totalCount: 0,
      },
      membersVisible: false,
    };
  },
  methods: {
    getPersons() {
      axios.get("/Person/GetPersons").then((resp) => {
        this.allPersons = resp.data.Value.Persons.map((el) => ({
          title: el.FirstName + " " + el.LastName,
          value: el.ID,
          selected: el.selected,
        }));
        this.allPersons.forEach((el) => {
          this.$set(el, "selected", false);
        });
        this.allPersonsForDeputy = resp.data.Value.Persons.map((el) => ({
          title: el.FirstName + " " + el.LastName,
          value: el.ID,
          selected: el.selected,
        }));
        this.allPersonsForDeputy.forEach((el) => {
          this.$set(el, "selected", false);
        });
      });
    },
    getData() {
      axios
        .get("Company/GetTeams", {
          params: {
            Page: this.gridParams.currentPage,
            PageSize: this.gridParams.pageSize,
            OrderByColumn: this.gridParams.orderByColumn,
            Ascending: this.gridParams.ascending,
            Team: this.filterId ? this.filterId : "",
          },
        })
        .then((resp) => {
          this.gridData = resp.data.Value.Teams.filter((el) => !el.Deleted).map(
            (el) => {
              el.TeamInfo.StartTime = CETToTimestamp(el.TeamInfo.StartTime);
              if (el.TeamInfo.EndTime == 0) el.TeamInfo.EndTime = null;
              return el.TeamInfo;
            }
          );
          this.gridParams.totalCount = resp.data.Value.Teams.length;
        });
    },
    personClickHanlder(el) {
      el.selected = !el.selected;
      const exist = this.selectedPersons.includes(el.value);
      if (exist) {
        this.selectedPersons = this.selectedPersons.filter(
          (element) => element != el.value
        );
      } else {
        this.selectedPersons.push(el.value);
      }
    },
    addNewClickHandler() {
      this.newTeam.name = "";
      this.newTeam.startTime = "";
      this.newTeam.description = "";
      this.selectedPersons = [];
      this.getPersons();
      this.popupVisible = true;
    },
    addTeam() {
      let arrayToSend = this.selectedPersons.map((el) => {
        return {
          Person: el,
        };
      });
      axios
        .post("Company/CreateTeam", {
          Name: this.newTeam.name,
          TeamLeader: arrayToSend,
          Description: this.newTeam.description,
          StartTime: this.newTeam.startTime,
        })
        .then((resp) => {
          this.getData();
          if (!resp.data.ErrorMsg) {
            this.popupVisible = false;
            this.snackbarVisible = true;
            this.snackbarText = "Successfully created new team!";
            this.snackbarPurpose = "success";
            this.isOpened = true;
          } else {
            this.popupVisible = false;
            this.snackbarPurpose = "error";
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
          }
        })
        .catch(() => {
          this.popupVisible = false;
          this.snackbarPurpose = "error";
          this.snackbarVisible = true;
          this.snackbarText = "An Error Occured While Adding New Team!";
        });
    },
    showDeletePopup(id) {
      this.selectedTeamID = id;
      this.deletePopupVisible = true;
    },
    deleteRow() {
      var item = this.gridData.filter((el) => el.ID == this.selectedTeamID)[0];
      var data = {
        ID: this.selectedTeamID,
        Name: item.Name,
        Description: item.Description,
        TeamLeader: [],
        Deleted: true,
        EndTime: item.EndTime,
      };
      this.$confirm("Would you like to delete this team?").then(() => {
        axios
          .post("Company/UpdateTeam", data)
          .then((resp) => {
            this.deletePopupVisible = false;
            if (!resp.data.ErrorMsg) {
              this.snackbarText = "Deleted Successfully!";
              this.snackbarPurpose = "success";
              this.snackbarVisible = true;
              this.getData();
            } else {
              this.snackbarVisible = true;
              this.snackbarText = resp.data.ErrorMsg;
              this.snackbarPurpose = "error";
            }
          })
          .catch(() => {
            this.snackbarVisible = true;
            this.snackbarText = "Error";
            this.snackbarPurpose = "error";
          });
      });
    },
    editRow(values) {   
      values["Deleted"] = false; 
      values["TeamLeader"] = [];    
      axios
        .post("Company/UpdateTeam", values)
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.getData();
            this.snackbarText = "Updated Successfully!";
            this.snackbarPurpose = "success";
            this.snackbarVisible = true;
          } else {
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
            this.snackbarPurpose = "error";
          }
        })
        .catch(() => {
          this.snackbarVisible = true;
          this.snackbarText = "Error";
          this.snackbarPurpose = "error";
        });
    },
    trClickHandler(element, e) {
      if (e.target.matches(".view-members")) {
        this.membersVisible = true;
        this.selectedTeamID = element.ID;
      } else if (e.target.matches(".view-schedule")) {
        axios
          .get("Company/GetTeamPersons", {
            params: {
              Team: element.ID,
              Page: 1,
              PageSize: 1000000,
            },
          })
          .then((resp) => {
            var ids = resp.data.Value.Persons.map((el) => el.Person);
            this.$router.push({
              path: "/flexibleschedule",
              query: { ids: ids },
            });
          });
      }
    },
  },
  watch: {
    filterTeam(value) {
      if (!value.length) {
        this.getData();
      }
      let val = value.toLowerCase().split(" ").join("");
      let filteredData = this.gridData.filter((el) => {
        let TeamName = el.Name.toLowerCase().split(" ").join("");
        return TeamName.includes(val);
      });
      this.gridData = filteredData;
    },
  },
  computed: {
    deleteText() {
      if (this.gridData.length == 0 || !this.selectedTeamID) return "";
      var name = this.gridData.filter((el) => el.ID == this.selectedTeamID)[0]
        .Name;
      return `Would you like to delete team <strong>${name}</strong>?`;
    },
    columns() {
      return [
        {
          title: "ID",
          key: "ID",
          smallWidthTd: true,
        },
        {
          title: "Name",
          key: "Name",
          editable: true,
          editType: "text",
        },
        {
          title: "Description",
          key: "Description",
          editable: true,
          editType: "text",
        },
        {
          title: "Members",
          key: "",
          hidden: !this.accessPermissions.assignTeamPersons,
          customTemplate: () =>
            '<i class="material-icons view-members">group</i>',
        },
        {
          title: "Start Time",
          key: "StartTime",
          customTemplate: (item, val) => timestampToDateTime(val),
        },
        {
          title: "End Time",
          key: "EndTime",
          customTemplate: (item, val) => (val ? timestampToDateTime(val) : "-"),
          editable: true,
          editType: "date",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.teams {
  padding: 30 * $rem;

  .button-container {
    margin-bottom: 15 * $rem;
  }

  ::v-deep .add-new-popup {
    height: 50vh !important;
  }

  form {
    .row {
      width: 555 * $rem;
      margin: auto;
      display: flex;
      align-items: center;
      margin-top: 15 * $rem;
    }

    label,
    .select-wrapper {
      margin-right: 15 * $rem;
    }
  }

  ::v-deep .table-wrapper {
    // overflow-x: initial !important;
  }

  ::v-deep .view-members {
    font-size: 28 * $rem;
    cursor: pointer;
    padding-left: 10 * $rem;

    &:hover {
      filter: brightness(1.1);
    }
  }

  ::v-deep .view-schedule {
    cursor: pointer;
    font-size: 28 * $rem;
    &:hover {
      filter: brightness(1.1);
    }
  }
  ::v-deep .select-content {
    background: #fff;

    .container {
      width: 25.225rem;
      border-radius: 0.375rem;
    }
    .persons-content {
      border-radius: 0;
      width: 25.225rem;
    }
  }
}
.team_header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 6.825rem;
  position: relative;
  top: 0.9375rem;

  form {
    input[name="SearchTeams"] {
      width: 17.0625rem;
      height: 2.3125rem;
      border: none;
      border-radius: 0.1875rem;
      padding-left: 1.4375rem;
      font-size: 0.875rem;
      color: #283593;
      background-color: #f8f8fe;
      outline: none;
      position: relative;
    }
  }
}
</style>
