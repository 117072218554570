<template>
  <div class="analitics">
    <div class="background">
      <div
        class="arrow-back"
        v-if="selectedAnaliticsTab"
        @click="selectedAnaliticsTab = false"
      >
        <span class="material-icons"> arrow_left </span>
      </div>
      <template v-if="selectedAnaliticsTab">
        <bonus-limit-combo-chart v-if="selectedAnaliticsTab == 1" />
        <bonus-limit-line-chart v-if="selectedAnaliticsTab == 2" />
        <bonus-limit-bar-chart v-if="selectedAnaliticsTab == 3" />
        <bonus-limit-pie-chart v-if="selectedAnaliticsTab == 4" />
        <company-costs v-if="selectedAnaliticsTab == 5" />
      </template>
      <div class="analitics-container" v-if="!selectedAnaliticsTab">
        <div class="analitics-list">
          <div
            class="analitics-box"
            @click="selectedAnaliticsTab = 1"
            v-if="!isManager"
          >
            <div class="analitics-box-title">
              <span>Payroll Cost by periods</span>
            </div>
            <div class="analitics-box-img">
              <img src="@/assets/images/analitics/chart-combo.svg" alt="hr" />
            </div>
          </div>
          <!-- end analitics-box -->

          <div
            class="analitics-box"
            @click="selectedAnaliticsTab = 2"
            v-if="!isManager"
          >
            <div class="analitics-box-title">
              <span>Salary Factor Costs</span>
            </div>
            <div class="analitics-box-img">
              <img src="@/assets/images/analitics/chart-line.svg" alt="hr" />
            </div>
          </div>
          <!-- end analitics-box -->

          <div class="analitics-box" @click="selectedAnaliticsTab = 3">
            <div class="analitics-box-title">
              <span>Bonus Limits &amp; Enrollments </span>
            </div>
            <div class="analitics-box-img">
              <img src="@/assets/images/analitics/chart-bar.svg" alt="hr" />
            </div>
          </div>
          <!-- end analitics-box -->

          <div class="analitics-box" @click="selectedAnaliticsTab = 4">
            <div class="analitics-box-title">
              <span>Bonus Limits by teams</span>
            </div>
            <div class="analitics-box-img">
              <img src="@/assets/images/analitics/chart-pie.svg" alt="hr" />
            </div>
          </div>
          <div
            class="analitics-box"
            @click="selectedAnaliticsTab = 5"
            v-if="!isManager"
          >
            <div class="analitics-box-title">
              <span>Company Costs</span>
            </div>
            <div class="analitics-box-img">
              <img src="@/assets/images/analitics/chart-combo.svg" alt="hr" />
            </div>
          </div>
          <!-- end analitics-box -->
        </div>
      </div>
    </div>

    <!-- end analitics-list -->

    <!-- end background -->
  </div>
</template>

<script>
import BonusLimitComboChart from "@/components/PayRoll/Analitics/BonusLimitComboChart.vue";
import BonusLimitLineChart from "@/components/PayRoll/Analitics/BonusLimitLineChart.vue";
import BonusLimitPieChart from "@/components/PayRoll/Analitics/BonusLimitPieChart.vue";
import BonusLimitBarChart from "@/components/PayRoll/Analitics/BonusLimitBarChart.vue";
import CompanyCosts from "./CompanyCosts.vue";
export default {
  components: {
    BonusLimitComboChart,
    BonusLimitLineChart,
    BonusLimitPieChart,
    BonusLimitBarChart,
    CompanyCosts,
  },
  data() {
    return {
      selectedAnaliticsTab: false,
    };
  },
  computed: {
    isSuperAdmin() {
      return this.$store.getters.hasRole("Super Admin");
      // return true;
    },
    isCfo() {
      return this.$store.getters.hasRole("CFO");
    },

    isManager() {
      return this.$store.getters.hasRole("Manager");
      // return true;
    },
  },
  mounted: function () {
    this.$root.$on("customEvent", (status) => {
     
      this.selectedAnaliticsTab = status;
    });
  },
};
</script>

<style scoped lang="scss">
.background {
  position: relative;
  padding: 1.875rem;
  padding-top: 0;
  height: calc(100% - 33px);

  > .container {
    margin-top: 1.875rem !important;
  }
  .arrow-back {
    position: absolute;
    color: $blue-primary;
    opacity: 0.5;
    left: 2%;
    top: 2%;
    cursor: pointer;
    span {
      font-size: 2.1875rem;
    }
  }
}

.analitics {
  display: inline;

  & > .overlay {
    .popup {
      padding: 0;

      .title {
        display: none;
      }
    }
    .welcome {
      width: 1200 * $rem;
      height: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("~@/assets/images/welcome.png");
      position: relative;
      padding-top: 53.2%;
    }
  }
  .analitics-list {
    width: 75rem;
    margin: 0 auto;
    height: calc(100% - 1.25rem);
    overflow: auto;
    padding-bottom: 1.875rem;
    .analitics-box {
      display: inline-block;
      width: 34.0625rem;
      height: fit-content;
      max-height: 24.3125rem;
      border-radius: 0.375rem;
      background-color: #fff;
      overflow: hidden;
      margin: 1.6875rem;
      cursor: pointer;
      box-shadow: 0 0 0.625rem 0 rgba(29, 233, 182, 0.5);
      border: solid 0.0313rem #1de9b6;
      .analitics-box-title {
        width: 100%;
        height: 3.125rem;
        display: flex;
        justify-content: space-between;
        span {
          width: 100%;
          display: inline-block;
          padding: 0.9375rem 1.875rem 0 1.875rem;
          -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
          font-size: 1.5rem;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.35;
          letter-spacing: normal;
          color: #283593;
          overflow: hidden;
        }
      }
      .analitics-box-img {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          max-height: 12.5rem;
          margin: 5rem 0 3.125rem 0;
        }
      }
    }
  }
}
</style>
