<template>
  <div class="schedules-header">
    <div class="container add" @click="$emit('add-click')">
      <i class="material-icons add-btn">add</i>
    </div>
    <div class="schedules-list">
      <div
        class="container"
        v-for="schedule in schedules"
        :key="schedule.ID"
        :class="{ selected: selectedSchedule == schedule.ID }"
        @click="() => $emit('change-selected', schedule.ID)"
        :style="{
          transform: `translateX(calc(${scrollPosition} * 12.1875rem)`,
        }"
      >
        <div class="actions">
          <i class="material-icons" @click.stop="$emit('edit', schedule.ID)"
            >create</i
          >
          <i
            class="material-icons"
            @click.stop="deleteClickHandler(schedule.ID, schedule.Name)"
            >close</i
          >
        </div>
        <span class="schedule-name">{{ schedule.Name }}</span>
        <p class="person" v-if="schedule.PersonCount > 0">
          {{ getFullName(schedule.Persons[0]) }}
        </p>
        <p class="person" v-if="schedule.PersonCount > 1">
          {{ getFullName(schedule.Persons[1]) }}
        </p>
        <div
          @click="schedulePersonList(selectedSchedule)"
          class="dots"
          v-if="schedule.PersonCount > 2"
        >
          ...
        </div>
      </div>
    </div>

    <i
      class="material-icons arrow left"
      @click="scrollPosition++"
      v-show="scrollPosition < 0"
      >keyboard_arrow_left</i
    >
    <i
      class="material-icons arrow right"
      @click="scrollPosition--"
      v-show="scrollPosition + schedules.length > numOfSchedulesToShow"
      >keyboard_arrow_right</i
    >
    <p class="total">
      Weekly Working Hours <span>{{ totalHr }}</span>
    </p>

    <custom-popup
      v-if="popupVisible"
      :visible="true"
      :flexible-size="true"
      :title="this.scheduleTitle"
      @close="
        popupVisible = false;
        Persons = [];
      "
    >
      <div class="schedulPersonPopup">
        <div class="title-wrapper">
          <div class="title">Users {{ this.Persons.length }}</div>
          <div class="input-wrapper">
            <custom-input
              class="users-modal__search"
              title="Filter by Persons"
              v-model="personModalSearchInput"
            />
          </div>
        </div>
        <div class="table">
          <div class="row headers">
            <div class="col">#</div>
            <div class="col">User</div>
          </div>
          <div class="table-body">
            <template>
              <div
                class="row"
                v-for="(person, index) in personSchedules"
                :key="index"
              >
                <div class="col index">{{ index + 1 }}</div>
                <div class="col details">
                  {{ `${person.FirstName} ${person.LastName}` }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </custom-popup>
  </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput";

export default {
  components: {
    CustomPopup,
    CustomInput,
  },
  props: {
    schedules: Array,
    selectedSchedule: Number,
    totalHr: Number,
  },
  created() {
    // this.schedulePersonList();
  },
  data() {
    return {
      scrollPosition: 0,
      numOfSchedulesToShow: 6,
      popupVisible: false,
      Persons: [],
      scheduleTitle: "",
      personModalSearchInput: "",
    };
  },
  methods: {
    getFullName(person) {
      const capitalize = (name) => name[0].toUpperCase() + name.substr(1);
      return `${capitalize(person.FirstName)} ${capitalize(person.LastName)}`;
    },
    deleteClickHandler(scheduleID, name) {
      this.$confirm(
        `Are you sure you want to remove template ${name}`
      ).then(() => this.$emit("delete", scheduleID));
    },

    schedulePersonList(id) {
      this.popupVisible = true;
      setTimeout(() => {
        id = this.selectedSchedule;
        var currentSchedule = this.schedules.filter((el) => el.ID == id);
        let parsedShedule = JSON.parse(JSON.stringify(currentSchedule));
        parsedShedule[0].Persons.forEach((el) => {
          this.Persons.push(el);
        });

        this.scheduleTitle = parsedShedule[0].Name;
      }, 300);
    },
  },

  computed: {
    personSchedules() {
      if (this.personModalSearchInput) {
        return this.Persons.filter(
          (el) =>
            el.FirstName.toLowerCase().includes(
              this.personModalSearchInput.toLowerCase()
            ) ||
            el.LastName.toLowerCase().includes(
              this.personModalSearchInput.toLowerCase()
            ) ||
            `${el.FirstName.toLowerCase()} ${el.LastName.toLowerCase()}`.includes(
              this.personModalSearchInput.toLowerCase()
            )
        );
      }
      return this.Persons;
    },
  },
};
</script>

<style scoped lang="scss">
.schedulPersonPopup {
  .table {
    overflow: auto;
    max-height: 25rem;
  }

  .title-wrapper {
    display: inline-flex;
    .input-wrapper {
      margin-left: 7.5rem;
    }
    .title {
      color: $blue-primary;
      margin-top: 0.625rem;
    }
  }

  .row {
    display: flex;
    &.headers {
      .col {
        color: $blue-primary;
        font-weight: 500;
        &:nth-child(2) {
          margin-left: 0.4375rem;
        }
      }
    }
  }

  .table-body {
    margin-top: 0.625rem;
    .row {
      color: $blue-primary;

      .col {
        margin-top: 0.625rem;

        &.details {
          margin-left: 0.625rem;
        }
      }
    }
  }
}

.schedules-header {
  display: flex;
  margin-top: -35 * $rem;
  position: relative;

  & > p.total {
    margin-top: 35 * $rem;
    font-weight: 300;
    color: $blue-primary;
    font-size: 18 * $rem;

    span {
      margin-left: 5 * $rem;
      font-weight: 700;
    }
  }

  .schedules-list {
    display: flex;
    width: 1150 * $rem;
    overflow-x: hidden;
  }

  & > i.arrow {
    position: absolute;
    font-size: 32 * $rem;
    bottom: 0;
    color: rgba($blue-primary, 0.7);
    top: 80 * $rem;
    cursor: pointer;

    &.left {
      left: 160 * $rem;
    }

    &.right {
      right: 13.1125rem;
    }
  }

  .container {
    flex-shrink: 0;
    width: 160 * $rem;
    height: 113 * $rem;
    border: $rem solid #dedff4;
    background-color: #fff;
    border-radius: 6 * $rem;
    box-shadow: $box-shadow2;
    margin-right: 35 * $rem;
    margin-top: 35 * $rem;
    padding: 25 * $rem 10 * $rem 10 * $rem 25 * $rem;
    position: relative;
    cursor: pointer;
    transition: transform 0.2s ease-out;

    &.add {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 25 * $rem;
    }

    &.selected {
      border-color: $blue-primary;
    }

    i.add-btn {
      background-color: #7e86be;
      border-radius: 2 * $rem;
      color: white;
      font-size: 32 * $rem;
      width: 46 * $rem;
      height: 46 * $rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .schedule-name {
      color: $blue-primary;
      font-weight: 400;
      font-size: 16 * $rem;
      white-space: nowrap;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }

    .actions {
      display: none;
      position: absolute;
      top: 5px;
      right: 5px;

      i {
        color: #bec2de;
        font-size: 16 * $rem;
        transition: color 0.2s ease-out;

        &:hover {
          color: $blue-primary;
        }
      }
      i + i {
        margin-left: 5 * $rem;
      }
    }

    &:hover {
      .actions {
        display: block;
      }
    }

    .person {
      color: #afafc6;
      font-weight: 300;
      font-size: 14 * $rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:first-of-type {
        margin-top: 15 * $rem;
      }
    }

    .dots {
      content: "...";
      bottom: 4 * $rem;
      position: absolute;
      left: 25 * $rem;
    }
  }
}
</style>