<template>
  <div class="vacations-statistics">
    <div class="filters">
      <div class="filter-wrapper">
        <StructureDropDown
          :data="strucTureData"
          @clear-selected="clearSelected"
          @selected="filterPersons"
        />
      </div>
      <div class="filter-wrapper">
        <date-range
          :initialFrom="fromDate"
          :initialTo="toDate"
          @from="(from) => (fromDate = from)"
          @to="(to) => (toDate = to)"
          class="date_range"
        />
      </div>
    </div>
    <div ref="table-container" @scroll="callMoreData" class="table-container">
      <vacation-terms-table
        v-if="data.length"
        :data="data"
        @scrolled-bottom="callMoreData"
        :vacationStatistics="true"
        :canEdition="false"
        :withFilters="false"
        :scrollTop="scrollTop"
        @scrolled-top="scrollTop = false"
      />
      <div class="no-data" v-else>
        No Data
      </div>
    </div>
  </div>
</template>

<script>
import VacationTermsTable from '@/components/VacationAdmin/VacationTermsTable'
import axios from '@/Helpers/AxiosInstance'

import DateRange from '@/components/ReusableComponents/DateRange/DateRange'
import StructureDropDown from '@/components/ReusableComponents/StructureDropDown/StructureDropDown'

export default {
  components: {
    VacationTermsTable,
    StructureDropDown,
    DateRange,
  },
  created() {
    this.getData(this.selectedPersons, true)
    this.getStructure()
  },
  data() {
    return {
      data: [],
      selectedPersons: [],
      strucTureData: [],
      fromDate: null,
      toDate: null,
      page: 1,
      pageSize: 15,
      maxPages: null,
      scrollTop: false,
    }
  },

  methods: {
    getData(persons, firstCall) {
      axios
        .post('vacations/GetVacationLeftDays', {
          persons: persons ? persons : [],
          toDate: this.toDate,
          fromDate: this.fromDate,
          page: this.page,
          pageSize: this.pageSize,
        })
        .then((resp) => {
          this.maxPages = resp.data.Value.Paging.TotalPageCount
          if (firstCall) {
            this.data = resp.data.Value.PersonVacs
          } else {
            if (!resp.data.Value.PersonVacs.length) return
            resp.data.Value.PersonVacs.forEach((el) => {
              this.data.push(el)
            })
          }
          this.data.forEach((el) => {
            el.canEdit = false
          })
        })
    },
    clearSelected() {
      this.selectedPersons = []
      this.scrollTop = true
      this.page = 1
      this.getData(this.selectedPersons, true)
    },
    getStructure() {
      axios.get('departments/GetStructureForFilter').then((resp) => {
        this.strucTureData = resp.data.Value.Structure
      })
    },
    filterPersons(persons) {
      this.scrollTop = true
      this.page = 1

      let personsArr = persons.filter((el) => el.DepType === null)
      let mapped = personsArr.map((el) => {
        return {
          ID: el.ID,
        }
      })
      this.selectedPersons = mapped
      this.getData(mapped, true)
    },
    callMoreData() {
      if (this.page > this.maxPages - 1) return
      this.page += 1
      this.getData(this.selectedPersons)
    },
  },
  watch: {
    fromDate() {
      this.scrollTop = true
      this.page = 1
      this.getData(this.selectedPersons, true)
    },
  },
}
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  padding: 30px;
  .filter-wrapper {
    margin-right: 30px;
  }
}
.table-container {
  overflow: auto;
}
.no-data {
  width: 100%;
  text-align: center;
  color: $blue-primary;
  font-size: 81px;
  opacity: 0.5;
}
</style>
