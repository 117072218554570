var render = function render(){var _vm=this,_c=_vm._self._c;return _c('custom-popup',{attrs:{"visible":true,"title":"Time Picker"},on:{"close":function($event){return _vm.$emit('close')}}},[_c('schedule-time-templates',{attrs:{"fromTime":_vm.fromTime,"toTime":_vm.toTime},on:{"select":_vm.selectTemplateHandler}}),_c('schedule-time-picker',{attrs:{"fromTime":_vm.fromTime,"toTime":_vm.toTime,"breakTime":_vm.totalBreak,"totalTime":_vm.totalTime,"canChangeTotal":true},on:{"change-total":total => (_vm.totalTime = total),"change-from":_vm.changeFromHandler,"change-to":_vm.changeToHandler,"change-break":_vm.changeBreakHandler}}),_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.$emit('update-schedule', {
                day: _vm.schedule.day,
                fromTime: _vm.fromTime,
                toTime: _vm.toTime,
                totalTime: _vm.totalTime,
                totalBreak: _vm.totalBreak,
            })}}},[_vm._v(" Save ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }