<template>
  <div class="node-item">
    <div class="container" @click="popupVisible = true">
      <div
        class="checkbox"
        v-if="forAssign"
        @click.stop="$emit('select-node', node)"
      >
        <svg
          v-if="!value && node.PermissionCount > 0 && node.PermissionActiveCount > 1"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <g id="Area" fill="rgba(29, 233, 182, 0.35)" stroke="rgba(29, 233, 182, 0.35)" stroke-width="2">
            <rect width="18" height="18" rx="9" stroke="none" />
            <rect x="1" y="1" width="16" height="16" rx="8" fill="none" />
          </g>
          <rect
            id="Dot"
            width="8"
            height="8"
            rx="4"
            transform="translate(7 7)"
            fill="none"
            opacity="0"
          />
        </svg>
        <svg
          v-else-if="!value"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <g id="Area" fill="none" stroke="#283593" stroke-width="2">
            <rect width="18" height="18" rx="9" stroke="none" />
            <rect x="1" y="1" width="16" height="16" rx="8" fill="none" />
          </g>
          <rect
            id="Dot"
            width="8"
            height="8"
            rx="4"
            transform="translate(7 7)"
            fill="none"
            opacity="0"
          />
        </svg>
        <svg
          v-show="value && node.PermissionCount === node.PermissionActiveCount "
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19"
        >
          <g id="Area" fill="#1de9b6">
            <path
              d="M 9.5 18 C 4.813079833984375 18 1 14.18692016601563 1 9.5 C 1 4.813079833984375 4.813079833984375 1 9.5 1 C 14.18692016601563 1 18 4.813079833984375 18 9.5 C 18 14.18692016601563 14.18692016601563 18 9.5 18 Z"
              stroke="none"
            />
            <path
              d="M 9.5 2 C 5.364489555358887 2 2 5.364489555358887 2 9.5 C 2 13.6355094909668 5.364489555358887 17 9.5 17 C 13.6355094909668 17 17 13.6355094909668 17 9.5 C 17 5.364489555358887 13.6355094909668 2 9.5 2 M 9.5 0 C 14.74670028686523 0 19 4.253290176391602 19 9.5 C 19 14.74670028686523 14.74670028686523 19 9.5 19 C 4.253290176391602 19 0 14.74670028686523 0 9.5 C 0 4.253290176391602 4.253290176391602 0 9.5 0 Z"
              stroke="none"
              fill="#1de9b6"
            />
          </g>
          <rect
            id="Dot"
            width="8.465"
            height="8.465"
            rx="4.233"
            transform="translate(5.172 4.922)"
            fill="#1de9b6"
          />
        </svg>
      </div>
      <div class="item-cointaner" @click="popupVisible = true">
        <div class="node-item__title">
          <span class="node-item__name"> {{ node.Name }} </span>
          <div class="node-item__options" v-if="!forAssign">
            <img
              :src="require('@/assets/icons/icon_add.svg')"
              @click.stop="editNode"
            />
            <img
              :src="require('@/assets/icons/icon_delete.svg')"
              @click.stop="deleteNode"
            />
          </div>
        </div>
        <div class="node-item__counts">
          {{ node.PermissionCount }}
          <span v-if="forAssign"> / {{ node.PermissionActiveCount }} </span>
        </div>
      </div>
    </div>
    <custom-popup
      :flexible-size="true"
      v-show="popupVisible"
      :title="node.Name"
      class="node-popup"
      @close="popupVisible = false"
    >
      <div class="node-item__permissions" v-if="!forAssign">
        <template v-for="(group, index) in groupedPermissions">
          <div
            class="node-item__permissions-group"
            :key="index"
            v-if="group.length"
          >
            <div
              class="node-item__permissions-permission"
              v-for="(permission, index) in group"
              :key="index"
            >
              <div class="header">
                <span class="number">{{ permission.ID }} </span>
                <span class="name">{{ permission.Name }} </span>
              </div>
              <img
                class="delete"
                @click="deletePermission(permission.ID)"
                :src="require('@/assets/icons/icon_delete.svg')"
              />
              <div class="node-name" >
                <div class="triangle"></div>
                <span> {{ permission.Name }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="node-item__permissions" v-else>
        <template v-for="(group, index) in groupedPermissions">
          <div
            class="node-item__permissions-group"
            :key="index"
            v-if="group.length"
          >
            <div class="columns">
              <div
                class="permission-column"
                v-for="permission in group"
                @click="
                  $emit('select-permission-from-node', {
                    node: node.ID,
                    permission: permission.ID,
                  })
                "
                :key="permission.ID"
              >
                <permission-checkbox
                  :number="permission.ID"
                  :name="permission.Name"
                  :value="selectedPermissions.has(permission.ID)"
                />
              </div>
            </div>
            <div
              class="accept-all"
              :class="{ reject: allAccepted(group) }"
              @click="acceptRejectAllClickHandler(group)"
            >
              {{ allAccepted(group) ? "Decline All" : "Accept All To Node" }}
            </div>
          </div>
        </template>
      </div>
    </custom-popup>
  </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance";
import PermissionCheckbox from "@/components/Permissions/PermissionCheckbox";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";

export default {
  components: { CustomPopup, PermissionCheckbox },
  props: {
    selectedPermissions: Set,
    value: Boolean,
    forAssign: Boolean,
    parentNodeName: String,
    node: {
      type: Object,
      required: true,
    },
  },
  computed: {
    groupedPermissions() {
      var alreadyGroupedPermissions = [];
      const keywords = [
        "permission",
        "role",
        "job",
        "schedule",
        "department",
        "office",
        "team",
        "vacation",
        "attendance",
        "salar",
        "person",
      ];
      let copyFilteredList = [...this.node.Permissions];
      var grouped = [];
      keywords.forEach((keyword) => {
        let group = copyFilteredList.filter(
          (p) =>
            p.Name.toLowerCase().includes(keyword) &&
            !alreadyGroupedPermissions.includes(p.ID)
        );
        grouped.push(group);
        group.forEach((el) => alreadyGroupedPermissions.push(el.ID));
      });
      grouped.push(
        copyFilteredList.filter(
          (p) => !alreadyGroupedPermissions.includes(p.ID)
        )
      );
      return grouped;
    },
  },
  data() {
    return {
      popupVisible: false,
    };
  },
  methods: {
    deleteNode() {
      this.$confirm(
        `Are you sure you want to remove Node ${this.node.Name}`
      ).then(async () => {
        const response = await axios.post("Permission/DeletePermissionNode", {
          ID: this.node.ID,
        });
        if (response) {
          this.$emit("updatePermissions");
        }
      });
    },
    editNode() {
      this.$emit("edit-node", this.node);
    },
    deletePermission(ID) {
      this.$confirm(
        `Are you sure you want to remove permission from Node ${this.node.Name}`
      ).then(async () => {
        const response = await axios.post("Permission/UpdatePermission", {
          Parent: 0,
          PermissionsTVP: [
            {
              Permission: ID,
              IsActive: true,
            },
          ],
        });
        if (response) {
          this.$emit("updatePermissions");
        }
      });
    },
    allAccepted(group) {
      return group.every((p) => this.selectedPermissions.has(p.ID));
    },
    acceptRejectAllClickHandler(group) {
      if (this.allAccepted(group))
        this.$emit("remove-group", { group: group, node: this.node.ID });
      else this.$emit("add-group", { group: group, node: this.node.ID });
    },
  },
};
</script>

<style scoped lang="scss">
.node-item {
  cursor: pointer;
  box-sizing: border-box;
  width: 257px;
  height: 50px;
  padding: 7px 5px 7px 15px;
  border: 1.5px solid rgba(#283593, 0.5);
  border-radius: 10px;
  color: #283593;
  margin-left: 40px;
  margin-bottom: 25px;

  .container {
    position: relative;
    display: flex;
    align-items: center;
  }

  .checkbox {
    margin-right: 10px;
  }

  &:hover {
    .node-item__options {
      display: flex;
    }
  }

  &__title {
    width: 215px;
    cursor: default;
    height: 20px;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--assign {
      width: 190px;
    }
  }

  &__name {
    width: 190px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__counts {
    font-size: 12px;

    span {
      color: #ff5f58;
    }
  }

  &__options {
    position: absolute;
    display: none;
    right: 0;
    margin-left: 30px;

    img {
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  &__permissions {
    height: 500px;
    width: 1400px;
    overflow-y: auto;

    &-group {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 50px;
      align-items: center;
    }

    &-permission {
      width: 255px;
      margin-right: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      position: relative;

      .header {
        display: flex;
      }

      &:hover {
        .delete {
          display: block;
        }
        .node-name{
          display: block;
        }  
      }

      .number {
        margin-right: 10px;
        color: #9696b9;
      }

      .name {
        display: block;
        width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .node-name{
        position: absolute;
        top: 1.5625rem;
        left: 1.875rem;
        color: #fff;
        background: rgba($blue-primary, .8);
        padding: 0.625rem 1.25rem;
        border-radius: 0.3125rem;
        display: none;
        z-index: 999;

          .triangle {
            $border-size: 8 * $rem;
            position: absolute;
            top: -8 * $rem;
            left: 25 * $rem;
            width: 0;
            height: 0;
            border-left: $border-size solid transparent;
            border-right: $border-size solid transparent;
            border-bottom: $border-size solid rgba($blue-primary, 0.7);
          }
      }

      .delete {
        display: none;
      }
    }
  }
}

.permission-column {
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  margin-bottom: 10px;
  width: 250px;
  height: 50px;
  font-size: 14px;

  .permission-checkbox {
    width: 250px;
    margin-right: 10 * $rem;
    margin-top: 10 * $rem;
  }
}

.columns {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}

.accept-all {
  border-radius: 6 * $rem;
  border: $rem solid rgba($blue-primary, 0.7);
  width: 170 * $rem;
  height: 35 * $rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba($blue-primary, 0.7);
  cursor: pointer;

  &.reject {
    color: #ff5f58;
    border-color: #ff5f58;
  }
}
.node-popup {
  ::v-deep .popup {
    padding: 70px 50px 0 50px;
  }
}
</style>
