<template>
<div class="background">
    <section class="holidays">
        <div class="holidays-essentials">
            <add-button
                title="Add Holiday"
                @clicked="popupVisible = true"
            />
            <div class="filter">
                <custom-select
                    v-model="filtered.country"
                    title="Countries"
                    :options="countryTypes"
                />
                <custom-select
                    v-model="filtered.year"
                    title="Year"
                    :options="years"
                />

            </div>
        </div>

        <overlay
            :visible="popupVisible"
            @close="popupVisible = false"
        >
            <popup
                :visible="popupVisible"
                @close="popupVisible = false"
                :flexibleSize=true
            >
                <form @submit.prevent="addHoliday">
                    <div class="inputs">
                        <custom-select
                            v-model="newHoliday.country"
                            title="Countries"
                            :options="countryTypes"
                        />
                        <custom-input
                            title="Name"
                            v-model="newHoliday.name"
                        />
                        <date-picker
                            v-model="newHoliday.fromDate"
                            title="From"
                        />
                        <date-picker
                            v-model="newHoliday.toDate"
                            title="To"
                        />
                    </div>

                    <button class="btn"> Add </button>
                </form>
            </popup>
        </overlay>
        <delete-item
            @delete="deleteHoliday"
            @close="deletePopupVisible = false"
            v-if="deletePopupVisible"
            text="Are you sure that you want delete this Holiday?"
        />
        <grid
            identificator="ID"
            title="Holidays"
            :gridData="holidays"
            :columns="columns"
            :canDelete="true"
            @rowDelete="deletePopupVisible = true"
            @trClicked="trClickHandler"
        />
    </section>
</div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance'
import AddButton from '@/components/ReusableComponents/AddButton/AddButton'
import Overlay from '@/components/ReusableComponents/Overlay/Overlay'
import Popup from '@/components/ReusableComponents/Popup/Popup'
import CustomSelect from '@/components/ReusableComponents/CustomSelect/CustomSelect'
import DatePicker from '@/components/ReusableComponents/DatePicker/DatePicker'
import CustomInput from '@/components/ReusableComponents/CustomInput/CustomInput'
import Grid from '@/components/ReusableComponents/Grid/Grid'
import DeleteItem from '@/components/ReusableComponents/DeleteItem/DeleteItem'
import {
    timestampToDateTime,
    getProperDateForBack,
    checkPermission,
} from '@/Helpers/HelperFunctions'
export default {
    components: {
        AddButton,
        Overlay,
        Popup,
        DatePicker,
        CustomSelect,
        CustomInput,
        DeleteItem,
        Grid,
    },
    data() {
        return {
            permissions: {
                CreateHolidays: checkPermission('CreateHolidays'),
            },
            popupVisible: false,
            newHoliday: {
                country: 1,
                name: '',
                fromDate: 0,
                toDate: 0,
            },
            holidays: [],
            deletePopupVisible: false,
            countryTypes: [],
            selectedHoliday: null,
            filtered: {
                year: new Date().getFullYear(),
                country: 1,
            },
        }
    },
    methods: {
        getHolidays() {
            axios
                .get('holidays/Holiday', {
                    params: {
                        CountryID: this.filtered.country,
                        Year: this.filtered.year,
                    },
                })
                .then(r => (this.holidays = r.data.Value))
        },
        deleteHoliday() {
            this.$confirm('Would you like to delete this holiday?').then(() => {
                axios
                    .post('holidays/UpdateHoliday', {
                        HolidayID: this.selectedHoliday.ID,
                        Delete: true,
                    })
                    .then(r => {
                        this.deletePopupVisible = false
                        if (r.data.ErrorMsg) return
                        this.getHolidays()
                    })
            })
        },
        addHoliday() {
            axios
                .post('holidays/Holiday', {
                    CountryID: this.newHoliday.country,
                    Name: this.newHoliday.name,
                    FromDate: getProperDateForBack(this.newHoliday.fromDate),
                    ToDate:
                        this.newHoliday.toDdate == this.newHoliday.fromDate
                            ? getProperDateForBack(
                                  this.addDayToTime(this.newHoliday.toDate),
                              )
                            : getProperDateForBack(this.newHoliday.toDate),
                })
                .then(r => {
                    this.popupVisible = false
                    if (r.data.ErrorMsg) return
                    this.getHolidays()
                })
        },
        getCountries() {
            axios.get('Company/GetCountries').then(
                r =>
                    (this.countryTypes = r.data.Value.map(el => ({
                        title: el.Name,
                        value: el.ID,
                    }))),
            )
        },
        trClickHandler(element) {
            this.selectedHoliday = element
        },
        addDayToTime(time) {
            let nowDate = new Date(time)
            nowDate.setDate(nowDate.getDate() + 1)
            return nowDate
        },
    },
    created() {
        this.getHolidays()
        this.getCountries()
    },
    watch: {
        filtered: {
            handler() {
                this.getHolidays()
            },
            deep: true,
        },
    },
    computed: {
        columns() {
            return [
                {
                    title: 'ID',
                    key: 'ID',
                },
                {
                    title: 'Country',
                    key: 'CountryID',
                },
                {
                    title: 'Name',
                    key: 'Name',
                },
                {
                    title: 'From Date',
                    key: 'FromDate',
                    customTemplate: (item, val) => timestampToDateTime(val),
                },
                {
                    title: 'To Date',
                    key: 'ToDate',
                    customTemplate: (item, val) => timestampToDateTime(val),
                    editable: true,
                    editType: 'date',
                },
            ]
        },
        years() {
            const current = new Date().getFullYear()
            let options = []
            for (let i = current - 10; i <= current; i++) {
                options.push({
                    value: i,
                    title: i,
                })
            }
            return options.reverse()
        },
    },
}
</script>

<style lang="scss" scoped>
.holidays {
    &-essentials {
        margin-bottom: 10 * $rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        .button-container {
            margin-right: auto;
            justify-self: flex-start;
        }
        .filter {
            align-items: center;
            display: flex;
            // justify-self: flex-end;
        }
    }

    padding: 30 * $rem;

    form {
        .inputs {
            display: flex;
        }
        display: flex;
        flex-direction: column;

        button {
            margin-top: 10 * $rem;
            align-self: flex-end;
        }
    }
}
</style>
