<template>
  <div class="dropdown-content" ref="structureDropDown">
    <div class="container" @click.stop="dropDownClickHandler">
      <span>Structure filter</span>
      <i v-if="!listVisible" class="material-icons">
        keyboard_arrow_down
      </i>
      <i v-else class="material-icons">
        keyboard_arrow_up
      </i>
    </div>
    <div class="tree-container" v-show="listVisible">
      <div class="clear" @click.stop>
        <span @click="clearFilter(data), $emit('clear-selected')">
          Clear filter
        </span>
      </div>
      <input @click.stop placeholder="search..." type="text" v-model="search" />
      <StructureList @change="sync_change" :data="data" />
    </div>
  </div>
</template>

<script>
import StructureList from './StructureList.vue'
export default {
  components: {
    StructureList,
  },
  props: {
    data: Array,
  },

  created() {
    document.addEventListener('click', this.documentClick)
  },
  data() {
    return {
      listVisible: false,
      selected_items_arr: [],
      search: '',
      searchTimeout: null,
      structureFilterIndex: null,
    }
  },
  methods: {
    sync_change() {
      let selected_options_array = []
      let loop = (arr) => {
        arr.forEach((item) => {
          if (item.selected && !item.ChildDeps) {
            selected_options_array.push(item)
          }
          if (item.ChildDeps) {
            if (item.selected) {
              const omit = (key, obj) => {
                const { [key]: omitted, ...rest } = obj
                return rest
              }
              selected_options_array.push(omit('children', item))
            }
            loop(item.ChildDeps)
          }
        })
      }
      loop(this.data)
      this.selected_items_arr = selected_options_array
      // calculate_tags_overflow(true)
      this.$emit('selected', this.selected_items_arr)
    },
    dropDownClickHandler() {
      this.listVisible = !this.listVisible
      this.showAll()
      this.structureFilterIndex = 0
      this.collapseAll(this.data)
      this.search = ''
    },
    collapseAll(arr) {
      arr.forEach((item) => {
        if (item.ChildDeps.length > 0) {
          item.collapsed = this.structureFilterIndex === 0
          this.structureFilterIndex++
          this.collapseAll(item.ChildDeps)
        }
      })
    },
    showAll(arr = null) {
      if (!arr) arr = this.data

      arr.forEach((item) => {
        this.$set(item, 'visible', true)

        if (item.ChildDeps.length > 0) {
          this.showAll(item.ChildDeps)
        }
      })
    },
    clearFilter(arr) {
      // console.log(arr)

      this.selected_items_arr = []
      arr.forEach((item) => {
        this.$set(item, 'selected', false)
        if (item.ChildDeps.length > 0) {
          this.clearFilter(item.ChildDeps)
        }
      })
    },
    documentClick(event) {
      let specifiedElement = this.$refs.structureDropDown
      let targetEl = event.target

      if (targetEl != specifiedElement) {
        this.listVisible = false
      }
    },
  },
  watch: {
    search(value) {
      this.showAll()
      this.structureFilterIndex = 0
      this.collapseAll(this.data)

      let recursiveFilter = (arr) => {
        let foundChild = false

        arr.forEach((item) => {
          if (item.ChildDeps.length > 0) {
            if (recursiveFilter(item.ChildDeps) == true) {
              item.collapsed = true
              foundChild = true
            } else {
              this.$set(item, 'visible', false)
              this.$forceUpdate()
            }
          } else {
            if (item.Name.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
              // item.collapsed = true
              foundChild = true
            } else {
              this.$set(item, 'visible', false)
              this.$forceUpdate()
              // item.visible = false
            }
          }
        })

        if (foundChild) {
          return true
        }

        return false
      }

      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
        this.searchTimeout = null
      }

      this.searchTimeout = setTimeout(() => {
        if (value == '') return
        this.searchTimeout = null
        recursiveFilter(this.data)
      }, 300)
    },
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick)
  },
}
</script>

<style scoped lang="scss">
.container {
  width: 350 * $rem;
  position: relative;
  padding-left: 15 * $rem;
  padding-right: 15 * $rem;
  border-radius: 6 * $rem;
  border: $rem solid #dcdde6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  height: 2.5rem;
  border: 0.0625rem solid #dcdde6;
  border-radius: 0.375rem;
  background-color: white;

  span {
    font-weight: 300;
    font-size: 16 * $rem;
    color: rgba($blue-primary, 0.7);
  }

  i {
    font-size: 20 * $rem;
    color: rgba($blue-primary, 0.7);
  }
}
.tree-container {
  margin: auto;
  position: absolute;
  list-style: none;
  background-color: #fff;
  z-index: 150;
  overflow-y: auto;
  // min-width: 350 * $rem;
  width: 350 * $rem;
  // width: auto;
  height: 22.8125rem;
  background-color: white;
  border: 0.0625rem solid #dcdde6;
  color: #dadcea;
  border-radius: 0.375rem;
  box-shadow: $box-shadow2;
  padding: 12 * $rem 0;
  user-select: none;
  overflow-y: auto;
  animation: show 0.1s linear;
  padding: 10px 15px 10px 15px;

  input {
    width: calc(100%);
    padding-left: 0.75rem;
    background-color: #f4f4f8;
    border-radius: 0.1875rem;
    font-weight: 200;
    font-size: 0.8125rem;
    outline: none;
    color: #9696b9;
    border: none;
    height: 1.875rem;
    margin-bottom: 0.3125rem;
  }
  .clear {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
    color: rgba($blue-primary, 0.6);
    font-size: 15px;
    margin-top: 5px;
    span {
      cursor: pointer;
    }
  }
}
</style>
