<template>
  <div class="background">
    <div class="signify-docs-content">
      <div class="header">
        <div class="filter">
          <date-range
            :addCustom="'datetimerange'"
            :initialFrom="filters.FromDate"
            :withClear="true"
            :initialTo="filters.ToDate"
            @from="(from) => (filters.FromDate = from)"
            @to="(to) => (filters.ToDate = to)"
            class="date_range"
          />
        </div>
        <div class="button" @click="popupHandler">
          <span>Generate New Contract</span>
        </div>
      </div>
      <grid
        title="Signify docs"
        :gridData="documents"
        :columns="columns"
        @trClicked="trClickHandler"
        @scolled-bottom="onScroll"
        identificator="ID"
        :pagination="false"
        @search="(value) => (searchTerm = value)"
      >
        <template v-slot:columnFilter="column">
          <employee-filter
            :onlySearch="true"
            :list="[]"
            @clearFilterValue="filters[column.prop.key] = ''"
            @search="(val) => searchHandler(val, column.prop)"
          />
        </template>
        >
      </grid>
    </div>
    <custom-popup
      :visible="popupVisible"
      @close="closeHandler"
      title="Generate Contract"
      class="document-popup"
      :class="[fields && fields.length > 5 ? 'many-fields' : null]"
    >
      <!-- :class="[
              totals.worked >= totals.totalWork ? 'positive' : 'negative',
            ]" -->
      <div
        class="forms-wrapper"
        :class="[fields && fields.length > 5 ? 'many-fields' : null]"
      >
        <div
          class="main-forms"
          :class="[fields && fields.length > 5 ? 'many-fields' : null]"
        >
          <div class="single-form">
            <search-input
              :options="allPersons"
              v-model="mainFields.selectedPerson"
              @clear="mainFields.selectedPerson = ''"
              :title="'Employee'"
            />

            <div class="job" v-if="job">
              {{ 'Job Name:' + ' ' + job }}
            </div>
          </div>
          <div class="single-form">
            <search-input
              :options="docTemplates"
              v-model="mainFields.selectedTemplate"
              @clear="mainFields.selectedTemplate = ''"
              :title="'Choose Document Template'"
            />
          </div>
        </div>
        <div
          class="dynamic-forms"
          :style="{ flexWrap: fields.length > 12 ? 'nowrap' : null }"
        >
          <div
            class="single-form"
            :class="[fields && fields.length > 5 ? 'many-fields' : null]"
            v-for="field in fields"
            :key="field.KeyName"
          >
            <custom-input
              v-model="field.Value"
              :title="field.DisplayName"
              @clear="field.selected = ''"
            />
          </div>
        </div>
      </div>
      <div
        class="send-button"
        :class="{ disabled: buttonDisabled }"
        @click="createDocument"
      >
        <div class="send">SEND</div>
      </div>
    </custom-popup>
    <CustomSnackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :timeout="snackbarTime"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
  </div>
</template>

<script>
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
import CustomPopup from '@/components/ReusableComponents/CustomPopup/CustomPopup'
import SearchInput from '@/components/ReusableComponents/SearchInput/SearchInput.vue'
import CustomInput from '@/components/ReusableComponents/CustomInput/CustomInput.vue'
import axios from '@/Helpers/AxiosInstance.js'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import EmployeeFilter from '@/components/Employees/EmployeeFilter.vue'
import DateRange from '@/components/ReusableComponents/DateRange/DateRange'
import { getDateString } from '@/Helpers/HelperFunctions'
export default {
  components: {
    Grid,
    CustomPopup,
    SearchInput,
    CustomInput,
    EmployeeFilter,
    DateRange,
    CustomSnackbar,
  },

  data() {
    return {
      popupVisible: false,
      test: '',
      mainFields: {
        selectedPerson: '',
        selectedTemplate: '',
      },
      // selectedPerson: '',
      // selectedTemplate: '',
      allPersons: [],
      docTemplates: [],
      snackbarPurpose: '',
      snackbarText: '',
      snackbarTime: '',
      snackbarVisible: false,
      fields: [],
      documents: [],

      columns: [
        {
          title: 'Doc ID',
          key: 'FileID',
          hasFilter: true,
        },
        {
          title: 'Doc Type',
          key: 'DocType',
          hasFilter: true,
        },
        {
          title: 'Employee',
          key: 'FullName',
          hasFilter: true,
          customTemplate: (item, val) => {
            return `<div class="job-name-wrapper">
            <span class="name">${val}</span>
            <span class="job-name">${item.JobName}</span>
            </div>`
          },
        },
        {
          title: 'Generate date & time',
          key: 'CreateTime',
          customTemplate: (item, val) =>
            getDateString(val, 'yyyy-MM-dd  HH:mm'),
        },
        {
          title: 'Status ',
          key: 'Status',
          hasFilter: true,
          customTemplate: (item, val) => {
            return `<span class="${
              val === 'FINISHED' ? 'green' : 'yellow'
            }">${val}</span>`
          },
        },
        {
          title: 'Document',
          key: 'FileID',
          customTemplate: () => {
            return `<span  class="download-doc">Download Document </span>`
          },
        },
        {
          title: 'Signify ID',
          key: 'SignifyDocumentID',
          hasFilter: true,
          customTemplate: (item, val) => {
            return `<span title="click to copy" class="copy-content">${val} </span>`
          },
        },
      ],
      filters: {
        FileID: '',
        DocType: '',
        FullName: '',
        FromDate: null,
        ToDate: null,
        SignifyDocumentID: '',
        Status: '',
      },
      selectedPersonID: '',
      page: 1,
      totalPage: null,
      job: '',
      buttonDisabled: false,
    }
  },
  mounted() {
    this.getDocuments(true)
  },
  methods: {
    popupHandler() {
      this.popupVisible = true
      this.getPersons()
      this.getSchedules()
    },

    // downloadTemplate(){

    // }
    onScroll() {
      this.page++
      if (this.page <= this.totalPage) {
        this.getDocuments()
      }
    },

    getPersons() {
      axios.get('/Person/GetPersonsUnderStructure').then((resp) => {
        this.allPersons = resp.data.Value.map((el) => ({
          title: el.FirstName + ' ' + el.LastName,
          value: el.ID,
        }))
      })
    },

    trClickHandler(element, event) {
      if (event.target.matches('.download-doc')) {
        const downloadLink = document.createElement('a')
        const isProduction = location.href.indexOf('.upgaming.com') > 0
        downloadLink.href = `https://intranet.upgaming.${
          isProduction ? 'com' : 'dev'
        }/api/files/Download?GUID=${element.GUID}`
        downloadLink.click()
      } else if (event.target.matches('.copy-content')) {
        const copyContent = async () => {
          try {
            await navigator.clipboard.writeText(event.target.innerHTML)
            this.snackbarVisible = true
            this.snackbarPurpose = 'success'
            this.isOpened = false
            this.snackbarTime = 1000
            this.snackbarText = 'Copied'
          } catch (err) {
            console.error('Failed to copy: ', err)
          }
        }
        copyContent()
      }
    },

    searchHandler(val, column) {
      this.filters[column.key] = val
    },

    createDocument() {
      this.buttonDisabled = true
      axios
        .post('signify/CreateDocument', {
          TemplateID: this.mainFields.selectedTemplate,
          PersonID: this.mainFields.selectedPerson,
          TemplateFields: this.fields,
        })
        .then((resp) => {
          // if (!resp.data.Value) return
          if (
            resp.status !== 200 ||
            (resp.data.ErrorMsg && resp.data.ErrorMsg.length)
          ) {
            this.buttonDisabled = false
            return
          }

          this.snackbarTime = 6000
          this.snackbarVisible = true
          this.snackbarPurpose = 'success'
          this.snackbarText =
            "The document was successfully generated and sent to reciepients. It'll be uploaded to the list, after all signers sign."
          this.buttonDisabled = false
          this.popupVisible = false
          this.mainFields.selectedPerson = ''
          this.mainFields.selectedTemplate = ''
          this.fields = []
          this.job = ''
        })
    },

    getDocuments(firstLoad) {
      axios
        .post('signify/GetDocuments', {
          ...this.filters,
          page: this.page,
          pageSize: 20,
        })
        .then((resp) => {
          if (firstLoad) {
            this.documents = resp.data.Documents
          } else {
            this.documents = [...this.documents, ...resp.data.Documents]
          }
          this.totalPage = resp.data.PagingRes.TotalPageCount
        })
    },
    closeHandler() {
      this.popupVisible = false
      this.mainFields.selectedPerson = ''
      this.mainFields.selectedTemplate = ''
      this.fields = []
      this.job = ''
    },
    selectPerson(value) {
      this.getPersonJob(value)
      // this.mainFields.selectedPerson = value
    },
    getSchedules() {
      axios.post('signify/GetTemplates').then((resp) => {
        this.docTemplates = resp.data.Items.map((el) => ({
          title: el.Name,
          value: el.ID,
        }))
      })
    },

    getTemplateFields() {
      axios
        .post('signify/GetTemplateFields', {
          templateID: this.mainFields.selectedTemplate,
          personID: this.mainFields.selectedPerson,
        })
        .then((resp) => {
          if (resp.data.Error) return
          this.fields = resp.data.Items
        })
    },
    getPersonJob(id) {
      axios
        .get('Person/GetPersonsActiveJob', {
          params: {
            PersonID: id,
          },
        })
        .then((resp) => {
          this.job = resp.data.Value ? resp.data.Value[0].JobName || '' : null
        })
    },
  },
  watch: {
    mainFields: {
      deep: true,
      handler: function (to, from) {
        // Access the 'to' and 'from' values

        if (
          this.mainFields.selectedPerson !== '' &&
          this.mainFields.selectedTemplate !== ''
        ) {
          this.getTemplateFields()
        }

        if (this.mainFields.selectedPerson) {
          this.selectedPersonID = this.mainFields.selectedPerson
        }
      },
    },
    selectedPersonID() {
      this.getPersonJob(this.selectedPersonID)
    },

    filters: {
      deep: true,
      handler: function () {
        clearTimeout(this.filtersTimeout)
        this.filtersTimeout = setTimeout(() => {
          this.page = 1
          this.documents = []
          this.getDocuments()
        }, 500)
      },
    },
  },

  // created(())
}
</script>

<style scoped lang="scss">
.signify-docs-content {
  padding: 1.25rem;
}
.job {
  color: #959bc9;
  font-size: 14px;
  margin-top: 3px;
}

.button {
  width: 175px;
  background-color: #6956d6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-left: auto;
  border-radius: 0.3125rem;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  margin-left: auto;
}
.document-popup {
  ::v-deep .popup {
    /* height: auto; */
  }
  &.many-fields {
    ::v-deep .popup {
      width: 46rem;
    }
  }
  .dynamic-forms {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: calc(100% - 50px);
    flex-wrap: wrap;
    overflow: auto;
    padding: 10px 0;
  }
  ::v-deep .popup {
    width: 23.125rem;
    /* height: calc(100% - 2.3125rem); */
    overflow: auto;
    position: relative;
    &.many-fields {
      width: 34.125rem;
    }
    .forms-wrapper {
      height: calc(100% - 92px);
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      &.many-fields {
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
        gap: 10px;
      }
      .main-forms {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .single-form {
          flex: 1;
        }
        &.many-fields {
          flex-direction: unset;
        }
      }
    }
    .single-form {
      flex: 1;
      flex-grow: 0;
      /* &.many-fields {
        width: 50%;
      } */
      label {
        width: 100%;
      }
    }
    h3.title {
      font-size: 1.25rem;
      color: #959bc9;
      margin-bottom: 1.125rem;
      font-weight: 500;
    }
  }
}
.send-button {
  background-color: #0ad6a3;
  color: #fcfcfc;
  height: 3rem;
  margin-left: auto;
  width: 6.25rem;
  border-radius: 0.375rem;
  font-size: 1rem;
  cursor: pointer;
  display: grid;
  place-items: center;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
::v-deep .grid .table-helper table tbody td > span {
  &.green {
    color: #10d0a0;
  }
  &.yellow {
    color: rgb(245, 157, 24);
  }
}
::v-deep .grid {
  .actions {
    display: none !important;
  }

  .table-wrapper {
    padding-bottom: 0;
  }
  .job-name-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .job-name {
      font-size: 13px;
      margin-top: 5px;
      opacity: 0.8;
    }
  }
  .download-doc {
    cursor: pointer;
  }
  .copy-content {
    cursor: pointer;
  }
}
::v-deep .alternativeHeader,
::v-deep .alternativeBody {
  top: 0rem;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
</style>
