import { render, staticRenderFns } from "./UploadedFilesList.vue?vue&type=template&id=8c0d7178&scoped=true"
import script from "./UploadedFilesList.vue?vue&type=script&lang=js"
export * from "./UploadedFilesList.vue?vue&type=script&lang=js"
import style0 from "./UploadedFilesList.vue?vue&type=style&index=0&id=8c0d7178&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c0d7178",
  null
  
)

export default component.exports