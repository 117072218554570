<template>
  <div class="background">
    <div v-if="scheduleType == 1">
      <div class="wrapper">
        <h1 class="user-details">{{ this.personName }}</h1>
        <p class="total">
          Total Working Hours<span>{{ totalWorkingHours }}</span>
        </p>
      </div>
      <week-schedule
        :scheduleID="scheduleID"
        v-if="scheduleID"
        :noEdit="true"
        @totalHours="(hr) => (totalWorkingHours = hr)"
      />
    </div>

    <div v-if="noSchedule" class="overlay-noSchedule">
      <h1>No Schedule Assigned</h1>
    </div>

    <flexible-schedule
      v-if="scheduleType == 2"
      :noEdit="true"
      :employeeID="parseInt($route.params.id)"
    />
  </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance";
import WeekSchedule from "@/components/WeekSchedule/WeekSchedule";
import FlexibleSchedule from "@/components/FlexibleSchedule/FlexibleSchedule";

export default {
  components: {
    WeekSchedule,
    FlexibleSchedule,
  },
  created() {
    this.getPersonSchedule();
    this.getPersonName();
  },
  data() {
    return {
      personName: "",
      scheduleType: "",
      scheduleID: "",
      totalWorkingHours: "",
      noSchedule: false,
    };
  },
  methods: {
    getPersonName(id) {
      id = this.$route.params.id;
      axios.get(`Person/GetPersonsDetails?Person=${id}`).then((resp) => {
        let firstName = resp.data.Value.FirstName;
        let lastName = resp.data.Value.LastName;
        let UpperCaseName =
          firstName.charAt(0).toUpperCase() + firstName.slice(1);
        let UpperCaseLastName =
          lastName.charAt(0).toUpperCase() + lastName.slice(1);
        this.personName = `${UpperCaseName} ${UpperCaseLastName}`;
      });
    },
    getPersonSchedule() {
      axios
        .get("Schedules/GetPersonSchedules", {
          params: { Person: this.$route.params.id },
        })
        .then((resp) => {
          if (resp.data.ErrorMsg || !resp.data.Value || !resp.data.Value.length)
            this.noSchedule = true;
          this.scheduleID = resp.data.Value[0].Schedule;
          this.scheduleType = resp.data.Value[0].ScheduleType;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.background {
  height: calc(100% - 43 * #{$rem});
  padding: 30 * $rem 10 * $rem 0 10 * $rem;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p.total {
    font-weight: 300;
    color: $blue-primary;
    font-size: 18 * $rem;
    text-align: right;

    span {
      font-weight: 700;
      margin-left: 10 * $rem;
    }
  }

  .flexible-schedule {
    align-items: initial;
  }
  .wrapper {
    width: 90%;
    margin-top: 15 * $rem;
    margin-bottom: 30 * $rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .user-details {
    font-family: "Roboto";
    color: #283593;
    font-size: 1.125rem;
    font-weight: 400;
  }

  .overlay-noSchedule {
    widows: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: Roboto;
      font-size: 3.125rem;
      color: rgba(#283593, 0.5);
    }
  }
}
</style>
