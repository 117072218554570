<template>
  <div class="background">
    <div class="jobs" v-if="accessPermissions.getJobs">
      <grid
        title="Jobs"
        :gridData="filteredGridData"
        :columns="columns"
        identificator="ID"
        :pagination="false"
        :canDelete="accessPermissions.createJob"
        @add-click="addNewClickHandler"
        @rowDelete="showDeletePopup"
        :canEdit="accessPermissions.createJob"
        @rowEdit="editRow"
        @trClicked="trClickHandler"
        :canAdd="true"
        :canSearch="true"
        @search="(value) => (searchTerm = value)"
      />
      <custom-popup
        :visible="popupVisible"
        @close="popupVisible = false"
        title="Add Job"
        :flexibleSize="true"
      >
        <form class="add-form" @submit.prevent="addJob">
          <div class="flex">
            <custom-input
              v-model="newJob.name"
              title="Job title"
              :isRequired="true"
              @clear="newJob.name = ''"
            />
            <date-picker
              title="Start Date"
              v-model="newJob.startTime"
              :isRequired="true"
              @clear="newJob.startTime = ''"
            />
          </div>
          <div class="add-language" @click="showLanguages = !showLanguages, newJob.language = '', newJob.translation = ''">
            <img src="@/assets/icons/icon-global-refresh.svg" />
          </div>
          <div v-if="showLanguages" class="flex">
            <custom-select
                title="Language" 
                class="langauge select"
                :options="langList"
                v-model="newJob.language"
                :clearVisible="true"
            />
            <custom-input
              v-model="newJob.translation"
              title="Translation"
              @clear="newJob.translation = ''"
            />
          </div>
          <div class="save-btn"><button class="btn">Save</button></div>
        </form>
      </custom-popup>
      <custom-popup
        :visible="permissionsPopupVisible"
        @close="permissionsPopupVisible = false"
        class="permissions-overlay"
        title="Job Permissions"
      >
        <permissions
          getURL="Permission/GetJobPermissions"
          assignURL="Permission/AssignJobPermission"
          :itemID="selectedJobID"
          itemName="Job"
        />
      </custom-popup>
      <custom-popup
        v-if="personPopupVisible"
        :visible="true"
        @close="personPopupVisible = false"
        class="person-overlay"
        :flexibleSize="true"
        :bigSize="true"
      >
        <job-employees :jobID="selectedJobID" />
      </custom-popup>
      <custom-popup
        v-if="rolesPopupVisible"
        :visible="true"
        @close="rolesPopupVisible = false"
        class="roles-overlay"
        title="Job Roles"
      >
        <job-roles :jobID="selectedJobID" />
      </custom-popup>

      <delete-item
        @delete="deleteRow"
        @close="deletePopupVisible = false"
        v-if="deletePopupVisible"
        :text="deleteText"
      />
      <custom-snackbar
        v-if="snackbarVisible"
        :purpose="snackbarPurpose"
        :text="snackbarText"
        @close="snackbarVisible = false"
      />
    </div>
  </div>
</template>

<script>
import Grid from "@/components/ReusableComponents/Grid/Grid.vue";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup.vue";

import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput.vue";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import axios from "@/Helpers/AxiosInstance.js";
import {
  timestampToCET,
  checkPermission,
  getDateString,
  CETToTimestamp,
  translationLang,
} from "@/Helpers/HelperFunctions.js";
import DeleteItem from "@/components/ReusableComponents/DeleteItem/DeleteItem.vue";
import Permissions from "@/components/Permissions/Permissions.vue";
import JobEmployees from "./JobEmployees.vue";
import JobRoles from "./JobRoles.vue";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue"
export default {
  components: {
    Grid,
    CustomPopup,
    CustomInput,
    DatePicker,
    CustomSnackbar,
    DeleteItem,
    Permissions,
    JobRoles,
    JobEmployees,
    CustomSelect,
  },
  created() {
    this.getData()
    this.getLanguages()
  },
  data() {
    return {
      accessPermissions: {
        getJobs: checkPermission("GetJobs"),
        createJob: checkPermission("CreateJob"),
        updatePermission: checkPermission("UpdatePermission"),
        assignRoles: checkPermission("AssignRoles"),
        assignPersonJob: checkPermission("AssignPersonJob"),
      },
      gridData: [],
      popupVisible: false,
      newJob: {
        name: "",
        startTime: "",
        language: "",
        translation: "",
      },
      langList:[],
      showLanguages: false,
      snackbarPurpose: "error",
      snackbarText: "",
      snackbarVisible: false,
      deletePopupVisible: false,
      selectedJobID: 0,
      gridParams: {
        orderByColumn: "ID",
        ascending: false,
      },
      permissionsPopupVisible: false,
      rolesPopupVisible: false,
      personPopupVisible: false,
      searchTerm: "",
    };
  },
  methods: {
    getData() {
      axios.get("jobs/GetJobs").then((resp) => {
        this.gridData = resp.data.Value.Jobs.filter((el) => !el.Deleted).map(
          (el) => {
            if (el.EndTime == null) el.EndTime = 0;
            return el;
          }
        );
      });
    },
    addNewClickHandler() {
      this.popupVisible = true;
    },
    getLanguages(){
        axios.get("openposition/GetLanguages")
        .then(resp => {
            this.langList = resp.data.Value.map(lang => ({
                title: lang.Code,
                value: lang.LanguageID
            }))
            
        })
    },
    addJob() {
      let data = {
        Name: this.newJob.name,
        StartTime: timestampToCET(this.newJob.startTime),
        translations: this.newJob.language ? [
          {
            languageID: this.newJob.language,
            objectTranslation: this.newJob.translation,
          }
        ] : []
      }
      axios
        .post("jobs/CreateJob", data)
        .then((resp) => {
          this.getData();

          if (!resp.data.ErrorMsg) {
            this.popupVisible = false;
            this.snackbarVisible = true;
            this.snackbarText = "Successfully created new job!";
            this.snackbarPurpose = "success";
          } else {
            this.popupVisible = false;
            this.snackbarPurpose = "error";
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
          }
        })
        .catch(() => {
          this.popupVisible = false;
          this.snackbarPurpose = "error";
          this.snackbarVisible = true;
          this.snackbarText = "An Error Occured While Adding New Job!";
        });
    },
    showDeletePopup(id) {
      this.selectedJobID = id;
      this.deletePopupVisible = true;
    },
    deleteRow() {
      var item = this.gridData.filter((el) => el.ID == this.selectedJobID)[0];
      var data = {
        Id: this.selectedJobID,
        Name: item.Name,
        Deleted: true,
        EndTime: item.EndTime,  
        translations: [],      
      };

      axios
        .post("jobs/UpdateJob", data)
        .then((resp) => {
          this.deletePopupVisible = false;
          if (!resp.data.ErrorMsg) {
            this.snackbarText = "Deleted Successfully!";
            this.snackbarPurpose = "success";
            this.snackbarVisible = true;
            this.getData();
          } else {
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
            this.snackbarPurpose = "error";
          }
        })
        .catch(() => {
          this.snackbarVisible = true;
          this.snackbarText = "Error";
          this.snackbarPurpose = "error";
        });
    },
    editRow(values) {
      var data = {
        Id: values["ID"],
        Name: values["Name"],
        NameGeo: values["NameGeo"],
        Deleted: null,
        EndTime: values["EndTime"] == 0 ? null : values["EndTime"],
        translations: [],
      };

      axios
        .post("jobs/UpdateJob", data)
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.getData();
            this.snackbarText = "Updated Successfully!";
            this.snackbarPurpose = "success";
            this.snackbarVisible = true;
          } else {
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
            this.snackbarPurpose = "error";
          }
        })

        .catch(() => {
          this.snackbarVisible = true;
          this.snackbarText = "Error";
          this.snackbarPurpose = "error";
        });
    },
    trClickHandler(element, e) {
      if (e.target.matches(".i-permissions")) {
        this.$router.push({
          name: "Permissions",
          params: { job: element, currentSection: "2" },
        });
      } else if (e.target.matches(".i-roles")) {
        this.rolesPopupVisible = true;
        this.selectedJobID = element.ID;
      } else if (e.target.matches(".i-person")) {
        this.personPopupVisible = true;
        this.selectedJobID = element.ID;
      }else if(e.target.matches('#assignGrade') || e.target.matches('#reAssignGrade')){
        this.$router.push(`/Grades/GradeNodes?jobID=${element.ID}`)
      }
    },
  },
  computed: {
    deleteText() {
      if (this.gridData.length == 0 || !this.selectedJobID) return "";
      var name = this.gridData.filter((el) => el.ID == this.selectedJobID)[0]
        .Name;
      return `Would you like to delete job <strong>${name}</strong>?`;
    },
    filteredGridData() {
      if (!this.searchTerm) return this.gridData;
      return this.gridData.filter((el) => {
        return el.Name.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    },
    columns() {
      return [
        {
          title: "Name",
          key: "Name",
          editable: true,
          editType: "text",
        },
        {        
          title: "Name KA",
          key: "NameGeo",
          editable: true,
          editType: "text",
        },
        {
          title: "Start Time",
          key: "StartTime",
          customTemplate: (item, val) => getDateString(CETToTimestamp(val)),
        },
        {
          title: "Assigned Department",
          key: "DepartmentName",
          customTemplate: (item, val) => (val ? val : "-"),
        },
        {
          title: "Employees",
          key: "",
          hidden: !this.accessPermissions.assignPersonJob,
          customTemplate: () =>
            `<img class="custom-icon i-person" src="${require("@/assets/teams.svg")}" ></img>`,
          tdClass: "icon",
        },
        {
          title: "Roles",
          key: "",
          hidden: !this.accessPermissions.assignRoles,
          customTemplate: () =>
            `<img class="custom-icon i-roles" src="${require("@/assets/roles.svg")}" ></img>`,
          tdClass: "icon",
        },
        {
          title: "Permissions",
          key: "",
          hidden: !this.accessPermissions.updatePermission,
          customTemplate: () =>
            `<img class="custom-icon i-permissions" src="${require("@/assets/permission.svg")}" ></img>`,
          tdClass: "icon",
        },
        {
          title:'Grades',
          key: "",
          customTemplate: (item,val) => item.GradeGroupID ? `<span id="reAssignGrade">${item.GradeGroupName}</span>` : `<img id="assignGrade" class="gradebtn" src="${require("@/assets/grades/addpurple.svg")}">`,
          tdClass:'icon'
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.jobs {
  .button-container {
    margin-bottom: 15 * $rem;
  }

  form {
    align-items: center;

    label,
    .select-wrapper {
      width: 100%;
      margin-right: 15 * $rem;
    }
  }
  .flex {
    width: 26rem;
    display: flex;
    justify-content: space-between;
  }
  .add-language {
    margin: 1.25rem 0;
    cursor: pointer;
  }
  .save-btn {
    text-align: right;
    margin-top: 1.5rem;
  }

  ::v-deep .table-wrapper {
    overflow-x: initial !important;
  }

  ::v-deep .permissions-overlay .popup {
    height: 90vh;
    width: 90%;
  }

  ::v-deep .roles-overlay .popup {
    height: 60vh;
  }

  ::v-deep .person-overlay .popup {
    max-height: 80vh;
  }

  ::v-deep .i-permissions,
  ::v-deep .i-roles,
  ::v-deep .i-person {
    font-size: 28 * $rem;
    cursor: pointer;

    &:hover {
      filter: brightness(1.1);
    }
  }
}
</style>
