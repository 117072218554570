c<template>
    <div class="multi-select" ref="dropdown">
        <div class="btn-wrapper">
            <div class="dropdown-btn" @click="$emit('open')">
                <img src="@/assets/grades/ratingOverall/team.svg" alt="">
                <span>{{title}}</span>
            </div>
        </div>
        <div class="dropdown-list" v-if="visible" >
            <ul>
                <li class="noHover search">
                    <img src="@/assets/grades/ratingOverall/search.svg" alt="">
                    <input type="text" v-model="searchValue" placeholder="Search .....">
                </li>
                <li v-show="filterObj(option.title)" v-for="(option,ind) in options" :key="ind" @click="$emit('select',!option.selected,option.id)">
                    <div class="check-box-wrapper">
                        <check-box 
                            :checked="option.selected"
                            @value="(val) => $emit('select',val,option.id)"
                        />
                    </div>
                    <span>{{option.title}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import CheckBox from '@/components/Grades/GradeComponents/CheckBox.vue'
export default {
    props: {
        title: String,
        options: Array,
        visible: {
            type:Boolean,
            default: false,
        }
    },
    components: {
        CheckBox
    },
    data(){
        return{ 
            searchValue: ""
        }
    },
    created(){
        document.addEventListener('click',this.clickHandler)
    },
    destroyed(){
        document.removeEventListener('click',this.clickHandler)
    },
    selectObj(selectState,id){

    },
    methods:{
        clickHandler($event){
            if($event.path.includes(this.$refs.dropdown)) return
            else{
                if(this.visible){
                    this.$emit('close')
                }
            }
        },
        filterObj(title){
            let visible = true
            if(
                this.searchValue && this.searchValue !== '' && this.searchValue !== null
            ){
                if(title.toLowerCase().split(' ').join('').trim().includes(this.searchValue.toLowerCase().split(' ').join('').trim())) visible = true
                else visible = false

            }else visible = true

            return visible
        },
    }
}
</script>

<style lang="scss" scoped>

ul{
    list-style: none;
}

.multi-select{
    z-index: 9;

    position: relative;
    .btn-wrapper{
        .dropdown-btn{
            width: 10rem !important;
            @include purpleBtn;
            @include flex(center,center,row);
            border-radius: 1.5625rem !important;
            img{
                width: 1.25rem;
            }

            span{
                padding-left: 0.625rem;
                color: #fff;
                font-size: 0.75rem;
            }
        }
    }

    .dropdown-list{
        width: 15rem;
        max-height: 35rem;
        overflow: auto;
        position: absolute;
        top: calc(100% + 0.625rem);
        right: 0;
        background: #fff;
        box-shadow: 0 0 1.25rem rgba(#3d3d3d,.2) ;
        border-radius: 0.5rem;

        ul{
            width: 100%;

            li.noHover{
                width: 100%;
                height: 2.5rem;
                margin-bottom: 0.5625rem;
                margin-top: 0.3125rem;
            
                &.search{
                    @include centerElement;
                    position: relative;
                    input{
                        width: calc(100% - 2rem);
                        height: calc(100% - .5rem);
                        outline: none;
                        border: 0.0625rem solid rgba(#3d3d3d,.2);
                        color: #4D5E80;
                        font-size: 0.875rem;
                        padding: 0 0.5rem;
                        padding-right: 1.5625rem;
                        border-radius: 0.25rem;
                        &::placeholder{
                            font-size: 0.8125rem;
                            opacity: .8;
                        }

                    }
                    img{
                        width: 0.9375rem;
                        position: absolute;
                        right: 1.4rem;
                        top: 50%;
                        transform: translate(0,-50%);

                    }
                }
            }

            li:not(.noHover){
                transition: all .2s;
                padding: 0.5rem 0.9375rem;
                @include flex(flex-start,flex-start,row);
                position: relative;

                .check-box-wrapper{
                    position: absolute;
                    left: 8px;
                    top: 10px;
                    width: 0.9375rem;
                    height: 0.9375rem;
                    ::v-deep{
                        .checkbox{
                            width: 100%;
                            height: 100%;
                        }
                    }

                }

                span{
                    transition: all .2s;
                    color: #4D5E80;
                    font-size: 0.9375rem;
                    margin-left: 1.125rem;
                }

                &:hover{
                    background: $purple;
                    span{
                        color: #ffff;
                    }
                }

                &.selected{
                    background: rgba($purple,.7);
                    span{
                        color: #ffff;
                    }
                }

            }
        }
    }
}
</style>