<template>
  <div class="date-range">
    <div class="clear" v-if="(initialFrom !== null && initialTo !== null) && withClear" @click="claerValues">
      <img src="@/assets/delete.png" alt="">
    </div>
    <div class="container" @click="$refs.picker.focus()">
      <img v-if="!alternativeCalendar" src="@/assets/icons/calendar.svg" />

      <div v-if="!alternativeCalendar" class="text-container">
        <span class="from">{{ from }}</span>
        <span>-</span>
        <span class="to">{{ to }}</span>
      </div>
      <div v-else class="alternative-text-container">
        <div v-if="value">
          <span class="from">{{ from }}</span>
          <span>-</span>
          <span class="to">{{ to }}</span>
        </div>

        <span class="title" v-else>Select Period</span>
      </div>
      <img
        class="alternative-calendar"
        v-if="alternativeCalendar"
        src="@/assets/icons/calendarSecondIcon.svg"
      />
    </div>
    <el-date-picker
      ref="picker"
      v-model="value"
      :type="addCustom ? addCustom : 'daterange'"
    />
  </div>
</template>

<script>
import { getDateString } from "@/Helpers/HelperFunctions";
export default {
  props: {
    initialFrom: {
      type: [Number, String],
      required: false,
      default: "",
    },
    initialTo: {
      type: [Number, String],
      required: false,
      default: "",
    },
    withClear: {
      type: Boolean,
      default: false,
    },
    addCustom: [Boolean, String],
  },
  created() {
    if (this.initialFrom && this.initialTo) {
      this.value = [new Date(this.initialFrom), new Date(this.initialTo)];      
    }
    this.$watch("initialFrom", () => {
      if(!this.initialFrom || this.initialFrom === 0 || this.initialFrom === null)
         this.value = null
    });
    this.$watch("value", () => {
      if(this.value === null || this.value === '') {
        this.$emit("from", this.value);
        this.$emit("to", this.value);
      } else {
        this.$emit("from", this.value[0].getTime());
        this.$emit("to", this.value[1].getTime());
        this.$emit('change')
      }
    });
  },
  data() {
    return {
      value: "",
      alternativeCalendar: false,
    };
  },
  methods:{
    claerValues(){
      this.$emit('from',null)
      this.$emit('to',null)
    }
  },
  computed: {
    from() {
      return this.value
        ? getDateString(this.value[0], "dd/MM/yy")
        : !this.alternativeCalendar
        ? "From"
        : null;
    },
    to() {
      return this.value
        ? getDateString(this.value[1], "dd/MM/yy")
        : !this.alternativeCalendar
        ? "To"
        : null;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  display: inline-flex;
  height: 40 * $rem;
  align-items: center;
  justify-content: center;
  border: $rem solid #dedff4;
  border-radius: 5 * $rem;
  background-color: #fff;
  cursor: pointer;
  padding-right: 10 * $rem;
  padding-left: 5 * $rem;
  .alternative-text-container {
    color: #9696b9;
    font-size: 14px;
    display: flex;
    align-items: center;
    .title {
      color: #959bc9;
      font-weight: 300;
    }
    > div {
      display: flex;
      align-items: center;
    }
  }
  .alternative-calendar {
    height: 25px;
    width: 25px;
  }
  .text-container {
    display: flex;
    align-items: center;
  }
  .from,
  .to {
    width: 65 * $rem;
    font-weight: 300;
    font-size: 15 * $rem;
    color: rgba($blue-primary, 0.7);
    margin: 5 * $rem;
    display: flex;
    justify-content: center;
  }

  img {
    height: 36 * $rem;
  }
}

.el-range-editor {
  visibility: hidden;
  position: absolute;
  left: 0;
}

.date-range {
  position: relative;

  .clear{
    cursor: pointer;
    position: absolute;
    right: -0.25rem;
    top: -0.375rem;
    img{
      width: 0.875rem;
    }
  }
}

.wrapper {
  display: flex;
}
</style>
