var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analitics"},[_c('div',{staticClass:"background"},[(_vm.selectedAnaliticsTab)?_c('div',{staticClass:"arrow-back",on:{"click":function($event){_vm.selectedAnaliticsTab = false}}},[_c('span',{staticClass:"material-icons"},[_vm._v(" arrow_left ")])]):_vm._e(),(_vm.selectedAnaliticsTab)?[(_vm.selectedAnaliticsTab == 1)?_c('bonus-limit-combo-chart'):_vm._e(),(_vm.selectedAnaliticsTab == 2)?_c('bonus-limit-line-chart'):_vm._e(),(_vm.selectedAnaliticsTab == 3)?_c('bonus-limit-bar-chart'):_vm._e(),(_vm.selectedAnaliticsTab == 4)?_c('bonus-limit-pie-chart'):_vm._e(),(_vm.selectedAnaliticsTab == 5)?_c('company-costs'):_vm._e()]:_vm._e(),(!_vm.selectedAnaliticsTab)?_c('div',{staticClass:"analitics-container"},[_c('div',{staticClass:"analitics-list"},[(!_vm.isManager)?_c('div',{staticClass:"analitics-box",on:{"click":function($event){_vm.selectedAnaliticsTab = 1}}},[_vm._m(0),_vm._m(1)]):_vm._e(),(!_vm.isManager)?_c('div',{staticClass:"analitics-box",on:{"click":function($event){_vm.selectedAnaliticsTab = 2}}},[_vm._m(2),_vm._m(3)]):_vm._e(),_c('div',{staticClass:"analitics-box",on:{"click":function($event){_vm.selectedAnaliticsTab = 3}}},[_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"analitics-box",on:{"click":function($event){_vm.selectedAnaliticsTab = 4}}},[_vm._m(6),_vm._m(7)]),(!_vm.isManager)?_c('div',{staticClass:"analitics-box",on:{"click":function($event){_vm.selectedAnaliticsTab = 5}}},[_vm._m(8),_vm._m(9)]):_vm._e()])]):_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analitics-box-title"},[_c('span',[_vm._v("Payroll Cost by periods")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analitics-box-img"},[_c('img',{attrs:{"src":require("@/assets/images/analitics/chart-combo.svg"),"alt":"hr"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analitics-box-title"},[_c('span',[_vm._v("Salary Factor Costs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analitics-box-img"},[_c('img',{attrs:{"src":require("@/assets/images/analitics/chart-line.svg"),"alt":"hr"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analitics-box-title"},[_c('span',[_vm._v("Bonus Limits & Enrollments ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analitics-box-img"},[_c('img',{attrs:{"src":require("@/assets/images/analitics/chart-bar.svg"),"alt":"hr"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analitics-box-title"},[_c('span',[_vm._v("Bonus Limits by teams")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analitics-box-img"},[_c('img',{attrs:{"src":require("@/assets/images/analitics/chart-pie.svg"),"alt":"hr"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analitics-box-title"},[_c('span',[_vm._v("Company Costs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analitics-box-img"},[_c('img',{attrs:{"src":require("@/assets/images/analitics/chart-combo.svg"),"alt":"hr"}})])
}]

export { render, staticRenderFns }