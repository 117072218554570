<template>
    <transition name="fade">
        <div class="overlay" v-if="visible" @click.self="$emit('close')">
            <slot></slot>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        visible: Boolean,
    },
    watch: {
        visible(val) {
            if(val) {
                document.body.style.overflow = 'hidden'
            }
            else {
                document.body.style.overflow = 'initial'
            }
        },
    },
}
</script>

<style scoped lang="scss">
.overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 80;
}

//animations
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
