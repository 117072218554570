<template>
    <div :class="['checkbox',{'checked':checked},{'disabled' : disabled,'alternativeStyle':alternativeStyle}]" @click="state">
        <img v-if="alternativeStyle" src="@/assets/grades/ratingOverall/arrow.svg" alt="">
    </div>
</template>

<script>
export default {
    props:{
        checked:{
            type: Boolean,
            default: true,
        },
        disabled:{
            type: Boolean,
            default: false,
        },
        alternativeStyle: {
            type:Boolean,
            default: false,
        },
    },
    methods:{
        state(){
            if(this.mandatory) return
            else (this.$emit('value', !this.checked))
        }
    },
}
</script>

<style scoped lang="scss">

.checkbox {
    width: 1.125rem;
    height: 1.125rem;
    border: 0.125rem solid #D5D9E7;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: ease .2s;
    &.checked{
        background: $green-primary;
        border: 0.0625rem solid transparent;
    }
    &.disabled{
        opacity: .9;
        pointer-events: none;
        user-select: none;
    }

    &.alternativeStyle{
        border: 0.0625rem solid #FF6633;
        box-shadow: 0 0 1.25rem 0.3125rem rgba(#FF9233,.2);
        @include centerElement;
        &.checked{
            background: #FF6633;
        }
        img{
            transition: ease .2s;
            width: 0.5625rem;
        }
    }
}

</style>