<template>
    <div class="wrapper" ref="multiFilter">
        <div class="filter" @click="$emit('collapse',!visible)">
            <img src="@/assets/grades/ratingOverall/filter.svg" alt="">
            <span>Filter</span>
        </div>
        <div :class="['filter-container',{'hiddenPart':!selectedParentAvailable}]" v-if="visible">

            <div class="filter-header">
                <div class="back" @click="$emit('closeParent')">
                    <img src="@/assets/grades/ratingOverall/arrowleft.svg" alt="">
                    <span>
                        Back
                    </span>
                </div>
                <div class="current-parent">
                    <span>
                        {{selectedParentAvailable ? this.selectedParentName : ''}}
                    </span>
                </div>
                <div class="close" @click="$emit('close')">
                    <img src="@/assets/grades/ratingOverall/close-gray.svg" alt="">
                </div>
            </div>

            <div class="filter-wrapper">
                <div class="left-side">
                    <div class="search-parent">
                        <input type="text" placeholder="Search..." v-model="parentSearchValue">
                        <img src="@/assets/grades/ratingOverall/search.svg" alt="">
                    </div>
                    <ul>
                        <li v-show="filterParent(parent.title)" :class="[{'active': parent.selected,'marked':filteredParentState(parent.typeName)}]" @click="selectedParent(parent.typeName,parent.title)" v-for="(parent,ind) in data" :key="ind">
                            <span>{{parent.title}}</span>
                            <img class="arrow" src="@/assets/grades/ratingOverall/arrowleft.svg" alt="">
                        </li>
                    </ul>
                </div>
                <div class="right-side">
                    <div class="search-parent">
                        <input type="text" placeholder="Search..." v-model="childrenSearchValue">
                        <img src="@/assets/grades/ratingOverall/search.svg" alt="">
                    </div>
                    <div v-if="selectedParentName === 'Score'" class="score-range-field">
                            <div class="title">
                                <span>Score Range</span>
                            </div>
                            <div class="range-fields">
                                <div class="range-field">
                                    <input type="number" placeholder="Score From" v-model="minScore" >
                                </div>
                                <div class="range-field">
                                    <input type="number" placeholder="Score To" v-model="maxScore">
                                </div>
                            </div>
                    </div>
                    <ul v-if="selectedParentName !== 'Score'">
                        <li v-show="filterChildren(child.title)" :class="{'selected': child.selected}" @click="selectTag(child)" v-for="(child,ind) in selectedParentChildrens" :key="ind">
                            <span>
                                {{
                                    child.title
                                }}
                            </span>
                            <check-box 
                                :checked="child.selected"
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CheckBox from '@/components/Grades/GradeComponents/CheckBox.vue'
export default {
    components: {
        CheckBox,
    },
    data(){
        return{
            parentSearchValue: '',
            childrenSearchValue: '',
            minScore: null,
            maxScore : null,
        }
    },
    watch:{
        minScore(val){
            this.$emit('minScore',val)
        },
        maxScore(val){
            this.$emit('maxScore',val)
        },
        triggerClearRangeValues(){
            this.minScore = null
            this.maxScore = null
            this.$emit('minScore',this.minScore)
            this.$emit('maxScore',this.maxScore)
        }
    },
    props:{
        visible: Boolean,
        data: Array,
        selectedParentChildrens:Array,
        selectedParentName: String,
        triggerClearRangeValues: Boolean,
        scoreMin: [Number,String],
        scoreMax: [Number,String]

    },
    created(){
        document.addEventListener('click',this.clickHandler)
        this.minScore = this.scoreMin
        this.maxScore = this.scoreMax
        this.$emit('minScore',this.minScore)
        this.$emit('maxScore',this.maxScore)
    },
    destroyed(){
        document.removeEventListener('click',this.clickHandler)
    },
    methods:{
        clickHandler($event){
            if($event.path.includes(this.$refs.multiFilter)) return
            else{
                if(this.visible){
                    this.$emit('close')
                }
            }
        },
        selectedParent(typeName,title){
            this.childrenSearchValue = ''
            this.$emit('selectedParent',typeName,title)
        },
        selectTag(child){
            this.$emit('selectChild',child)
        },
        filteredParentState(typename){
            return this.data.find(el => el.typeName === typename).children.some(el => el.selected)
        },
        filterChildren(title){
            let visible = false
            if(title.toLowerCase().includes(this.childrenSearchValue.toLowerCase())) visible = true
            else visible = false
            return visible
        },
        filterParent(title){
            let visible = false
            if(title.toLowerCase().includes(this.parentSearchValue.toLowerCase())) visible = true
            else visible = false
            return visible
        },
    },
    computed:{
        selectedParentAvailable(){
            let state = this.data.some(el => el.selected)
            return state
        },
    },
}
</script>

<style lang="scss" scoped>

.filter{
    width: 5rem;
    margin-left: 1.3rem;
    margin-top: -1rem;
    padding: .5rem 0;
    position: relative;
    cursor: pointer;
    @include flex(flex-start,center,row);
    z-index: 23;
    span{
        font-size: 0.875rem;
        color: #6B7A99;
        font-weight: 700;
        user-select: none;
        margin-left: 0.5625rem;
    }

    img{
        width: 1.5rem;
    }
}

.filter-container{
    width: 51.3125rem;
    height: 40.4375rem;
    background: #fff;
    box-shadow: 0 0 1.25rem #CCD6F6;
    border-radius: 0.9375rem;
    position: absolute;
    top: 100%;
    animation: fadeIn .5s forwards;
    @keyframes fadeIn {
        from{
            transform: translateY(1rem) rotate(-1deg);
            opacity: 0;
        }
        to{
            transform: translateY(0) rotate(0);
            opacity: 1;
        }
    }

    &.hiddenPart{
        width: 23.3125rem;
        .right-side{
            width: 0;
            opacity: 0;
        }
        .left-side{
            border-right: 0.0625rem solid transparent !important;
        }
    }

    z-index: 22;

    .filter-header{
        width: 100%;
        padding: 1.25rem;
        @include flex(flex-end,center,row);
        position: relative;
        overflow: hidden;

        &::after{
            content: '';
            position: absolute;
            width: calc(100% - 3.4375rem);
            height: 0.0625rem;
            background: rgba(#97A1C2,.3);
            left: 50%;
            bottom: 0;
            transform: translate(-50%,0);
        }

        .back{
            cursor: pointer;
            user-select: none;
            @include flex(flex-start,center,row);
            margin-right: 15.625rem;
            span{
                font-size: 0.875rem;
                margin-left: 0.5rem;
                color: #97A1C2;
                font-weight: 500;
            }
            img{
                width: 0.4375rem;
                transform: rotate(180deg);
            }
        }

        .current-parent{
            span{
                font-size: 0.9375rem;
                color: #97A1C2;
                font-weight: 500;
                margin-right: 6.25rem;
            }
        }

        .close{
            cursor: pointer;
            img{
                width: 0.8125rem;
            }
        }

    }

    .filter-wrapper{
        width: 100%;
        height: calc(100% - 5rem); 
        margin-top: 0.9375rem;
        @include flex(flex-start,center,row);

        .search-parent{
            width: 100%;
            height: 2.4rem;
            position: relative;

            input{
                width: 100%;
                height: 100%;
                outline: none;
                border: 0.0625rem solid rgba(#959BC9,.3);
                border-radius: 0.375rem;
                font-size: 0.875rem;
                color: rgba(#959BC9,1);
                padding: 0 0.5rem;

                &::placeholder{
                    font-size: 0.8125rem;
                    color: rgba(#959BC9,1);
                }
            }

            img{
                position: absolute;
                width: 1.0625rem;
                right: 0.9375rem;
                top: 50%;
                transform: translate(0,-50%);
            }
        }
        .left-side{
            width: calc(51.3125rem / 2.1);
            height: 100%;
            @include flex(flex-start,left,column);
            padding: 0 1.875rem;
            border-right: 0.0625rem solid rgba(#97A1C2 ,.3);
            transition: ease .5s;

            ul{
                width: 100%;
                margin-top: 0.9375rem;
                height: calc(100% - 5rem);
                overflow: auto;
                list-style: none;

                li{
                    width: 100%;
                    cursor: pointer;
                    @include flex(space-between,center,row);
                    padding: 1.5625rem 1.5625rem;
                    transition: ease .2s;
                    background: rgba(#D4D7E9,.2);
                    border-bottom: 0.0625rem solid rgba(#D4D7E9,.5);
                    position: relative;

                    &.active{
                        background: $purple;
                        span{
                            color: #fff;
                        }
                    }

                    &.marked{
                        span{
                            color: $green-primary;
                        }
                    }

                    span{
                        color: $purple;
                        font-weight: 500;
                        text-transform: uppercase;
                        font-size: 0.875rem;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        width: 17rem;
                    }
                    img{
                        position: absolute;
                        top: 50%;
                        transform: translate(0,-50%);
                        right: 1rem;
                        width: 0.375rem;
                    }
                    &:hover{
                        background: $purple;
                        span{
                            color: #fff;
                        }
                    }
                }
            }

        }

        .right-side{
            width: calc(51.3125rem / 2);
            margin-left: 0.625rem;
            height: 100%;
            overflow: hidden;
            padding: 0 1.25rem;
            transition: ease .2s;


            .score-range-field{
                width: 100%;
                position: relative;
                margin-top: 0.9375rem;
                @include flex(left,left,column);

                .title{
                    span{
                        color: #6B7A99;
                        font-size: 1rem;
                    }

                }

                .range-fields{
                    @include flex(space-between,center,row);
                    margin-top: 0.9375rem;
    
                    .range-field{
                        input{
                            border-radius: 0.375rem;
                            background: transparent;
                            outline: none;
                            height: 2.2rem;
                            border: 0.0625rem solid rgba(#9696B9,.4);
                            padding: 0 0.4375rem;
                            color: #6B7A99;
                            
                            &::placeholder{
                                color: #9696B9;
                            }
                            
                        }
    
                        input[type="number"]::-webkit-inner-spin-button,
                        input[type="number"]::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }
                }
            }

            ul{
                width: 100%;
                overflow: auto;
                height: calc(100% - 4rem);
                list-style: none;
                @include flex(left,center,column);
                margin-top: 0.9375rem;
                li{
                    cursor: pointer;
                    width: calc(100% - 1rem);
                    padding: 0.7rem 0.9375rem;
                    margin-top: 0.3125rem;
                    @include flex(space-between,center,row);
                    border: 0.0625rem solid rgba(#9696B9, .3);
                    border-radius: 0.4375rem;
                    transition: ease .2s;

                    &.selected{
                        background: $purple;
                        span{
                            color: #ffffff;
                        }                        
                    }

                    &:first-child{
                        margin-top: 0;
                    }
                    span{
                        color: #9696B9;
                        font-size: 0.875rem;
                        font-weight: 400;
                        width: calc(100% - 1.2rem);
                    }

                    &:hover{
                        background: $purple;
                        span{
                            color: #ffffff;
                        }
                    }
                }
            }
        }

    }
}
</style>