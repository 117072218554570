<template>
  <daily-performance :userID="parseInt($route.params.id)" />
</template>

<script>
import DailyPerformance from "@/components/DailyPerformance/DailyPerformance";
export default {
  components: {
    DailyPerformance,
  },
};
</script>

<style scoped lang="scss">
.background {
  height: calc(100% - 43 * #{$rem});
}
</style>
