<template>
    <div class="background">
        <custom-snackbar
            :text="snackbar.text"
            :purpose="snackbar.purpose"
            v-if="snackbar.visible"
            @close="snackbar.visible = false"
        />
        <div class="top-menu">
            <switch-header  :routes="routes" class="switch-header"/>
        </div>
        <div class="content-wrapper">
            <div class="no-records" v-if="packageList.length < 1 && !loading" >
                <span>There are no Records</span>
            </div>
            <custom-loader v-if="loading" />
            <div class="no-records" v-if="alternateText">
                <span>There are no Records</span>
            </div>
            <custom-popup
                :title="'Create Vending Package'"
                :visible="createPackageVisible"
                @close="createPackageVisible = false"
                class="create-package"
            >
                <div class="wrapper">
                    <form @submit.prevent="createPackage">
                        <ul>
                            <li v-for="(field,ind) in createVendingPackage.fields" :key="ind">
                                <custom-input 
                                    :title="field.title"
                                    v-model="field.value"
                                    :isRequired="field.required"
                                    :inputType="field.inputType"
                                />
                            </li>
                            <li class="save-package">
                                <button type="submit" class="save">
                                    Save
                                </button>
                            </li>
                        </ul>
                    </form>
                </div>
            </custom-popup>
            <div class="content-header">
                <div class="top">
                    <div class="header-title">
                        <span>
                            Packages
                        </span>
                    </div>
                    <div class="header-filters">
                        <div class="search">
                            <img src="@/assets/grades/search.svg" alt="search">
                            <input v-model="searchPackage" type="text" placeholder="Search" spellcheck="false">
                        </div>
                    </div>
                    <div class="header-actions">
                        <div class="purple-btn add-new-group" @click="createPackageVisible = true,editPackageMode = false">
                            <img :class="{'down':false}" src="@/assets/grades/add.svg" alt="add">
                            <span class="">Package</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="middle">
                <div class="package-grid">
                    <node-block
                        v-for="(item,ind) in packageList"
                        :key="ind"
                        :title="item.LimitGroupName"
                        :editMode="item.editMode"
                        @closeToolbar="item.editMode = false"
                        :packageNode="true"
                        :toolbarOptions="packageToolbarOptions"
                        :discount="item.DiscountPercentAmount"
                        :dailyLimit="item.DailyLimitFixedAmount"
                        :monthlyLimit="item.MonthlyLimitFixedAmount"
                        :nodeID="item.LimitGroupID"
                        @editClicked="item.editMode = !item.editMode"
                        :defaultCurrency="'GEL'"
                        @Edit="updatePackage(item.LimitGroupID)"
                        @Delete="deletePackage(item.LimitGroupID)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance.js";
import SwitchHeader from '@/components/ReusableComponents/SwitchHeader/SwitchHeader.vue'
import NodeBlock from '@/components/Grades/GradeComponents/NodeBlock.vue'
import CustomPopup from '@/components/ReusableComponents/CustomPopup/CustomPopup.vue'
import CustomInput from '@/components/ReusableComponents/CustomInput/CustomInput.vue'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import CustomLoader from '@/components/ReusableComponents/Loader/CustomLoader.vue'

export default {
    components:{
        SwitchHeader,
        NodeBlock,
        CustomPopup,
        CustomInput,
        CustomSnackbar,
        CustomLoader,
    },
    data(){
        return{
            routes:[
                {
                    title: 'Packages',
                    path: '/VendingPackages',
                    hidden: this.$store.getters.hasRole('Member'),
                },
                {
                    title: 'Users',
                    path: '/Users',
                    hidden: this.$store.getters.hasRole('Member'),
                },
                {
                    title: 'Transactions',
                    path: '/Transactions',
                }
            ],
            packageToolbarOptions:[
                {
                    name: 'Edit',
                    icon: '/grades/edit.svg'
                },
                {
                    name: 'Delete',
                    icon: '/grades/trash.svg'
                }
            ],

            createVendingPackage:{
                fields:[
                    {
                        title: 'Name Package',
                        value: '',
                        required: true,
                        type:1,
                        inputType: 'text'
                    },
                    {
                        title: 'Discount %',
                        value: '',
                        required: true,
                        type:2,
                        inputType: 'number'

                    },
                    {
                        title: 'Daily Limit (GEL)',
                        value: '',
                        required: false,
                        type:3,
                        inputType: 'number'


                    },
                    {
                        title: 'Monthly Limit (GEL)',
                        value: '',
                        required: false,
                        type:4,
                        inputType: 'number'

                    },
                ],
            },
            snackbar:{
                text:'',
                purpose: '',
                visible: false,
            },

            packageList:[],
            searchPackage: '',
            createPackageVisible: false,
            editPackageMode: false,
            updatePackageID: null,
            alternateText: false,
            loading: false,
        }
    },
    watch:{
        createPackageVisible(val){
            if(!val){
                this.createVendingPackage.fields.forEach(el => {
                    el.value = ''
                })
            }
        },
        searchPackage(val){
            this.getPackages()
        }
    },
    async created(){
        await this.getPackages()
    },
    methods:{
        async getPackages(){
            this.loading = true
            await axios.get('salary/GetQuickPickLimitGroups',{
                params:{
                    SearchTerm: this.searchPackage ? this.searchPackage : null,
                }
            }).then(resp => {
                this.loading = false
                let data = resp.data.Value.sort((a,b) => b.LimitGroupID - a.LimitGroupID)
                data.forEach(el => {
                    this.$set(el,'editMode',false)
                })
                this.packageList = data
                if(!resp.data.ErrorMsg && this.packageList.length < 1){
                    this.alternateText = true
                }else{
                    this.alternateText = false
                }
            })
        },
        createPackage(){
            this.loading = true
            let packageData = this.createVendingPackage.fields
            let objData = {
                limitGroupName: packageData.find(el => el.type === 1).value || '',
                discountPercentAmount: packageData.find(el => el.type === 2).value || null,
                dailyLimitFixedAmount: packageData.find(el => el.type === 3).value || null,
                monthlyLimitFixedAmount: packageData.find(el => el.type === 4).value || null,
            }
            if(this.editPackageMode){
                let updateObj = {
                    quickPickLimitGroupID: this.updatePackageID || null,
                    name: packageData.find(el => el.type === 1).value || '',
                    discountPercentAmount: packageData.find(el => el.type === 2).value || null,
                    dailyLimitFixedAmount: packageData.find(el => el.type === 3).value || null,
                    monthlyLimitFixedAmount: packageData.find(el => el.type === 4).value || null,
                }
                axios.post('salary/UpdateQuickPickLimitGroup',updateObj).then(resp => {
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Package Save Successfully','success')
                        this.createVendingPackage.fields.forEach(el => {
                            el.value = ''
                        })
                        this.createPackageVisible = false
                        this.getPackages()
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                    this.loading = false

                }).catch(err => {
                    this.loading = false
                })
            }else{
                axios.post('salary/CreateQuickPickLimitGroup',objData).then(resp => {
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Package Save Successfully','success')
                        this.createVendingPackage.fields.forEach(el => {
                            el.value = ''
                        })
                        this.createPackageVisible = false
                        this.getPackages()
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                    this.loading = false
                }).catch(err => {
                    this.loading = false
                })
            }
        },
        snackbarMessage(text,purpose){
            this.snackbar.visible = true
            this.snackbar.text = text
            this.snackbar.purpose = purpose
        },
        updatePackage(id){
            this.updatePackageID = id
            this.createPackageVisible = true
            this.editPackageMode = true
            let currentPackage = this.packageList.find(el => el.LimitGroupID === id)
            this.createVendingPackage.fields.forEach(el => {
                if(el.type === 1) el.value = currentPackage.LimitGroupName
                if(el.type === 2) el.value = currentPackage.DiscountPercentAmount
                if(el.type === 3) el.value = currentPackage.DailyLimitFixedAmount
                if(el.type === 4) el.value = currentPackage.MonthlyLimitFixedAmount
            })
        },
        deletePackage(id){
            this.$confirm('Would You Like To Delete This Package').then(() => {
                this.loading = true
                axios.post('salary/UpdateQuickPickLimitGroup',{
                    quickPickLimitGroupID: id,
                    delete:true,
                    dailyLimitFixedAmount: null,
                    monthlyLimitFixedAmount: null,
                    name: '',
                    discountPercentAmount: null,
                }).then(resp => {
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Package Deleted Successfully','success')
                        this.getPackages()
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                    this.loading = false
                }).catch(err => {
                    this.loading = false
                })
            })
        },
    },
}
</script>


<style lang="scss" scoped>
.background{
    overflow: hidden;
}

.no-records{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%,-50%);
    pointer-events: none;
    user-select: none;
    span{
        font-size: 1.875rem;
        color: rgba($grayBlue, .8);
    }
}

ul{
    list-style: none;
}

.no-records{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%,-50%);
    pointer-events: none;
    user-select: none;
    span{
        font-size: 1.875rem;
        color: rgba($grayBlue, .8);
    }
}

.top-menu{
    top: 0;
    z-index: 10;
    background: #fff;
    padding-bottom: 0.625rem;
    padding-left: 1.875rem;
}

.content-wrapper{
    width: calc(100% - (1.875rem * 2));
    height: calc(100% - 1.5rem);
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    background: #fff;
    overflow: hidden;
    margin: 1.5rem auto;
    box-sizing: border-box;

    .create-package{
        ::v-deep{
            .popup{
                width: 28.125rem;
                padding: 2.9375rem 5rem;
                height: 31.125rem; 
                h3.title{
                    color: $purple;
                    font-size: 0.875rem;
                    font-weight: 500;
                }
            }
        }

        .wrapper{
            width: 100%;
            form{
                width: 100%;

                ul{
                    width: 100%;
                    @include flex(left,left,column);
                    
                    li{
                        width: 100%;
                        margin-top: 0.9375rem;
                        &.save-package{
                            text-align: right;
                            margin-top: 5.3125rem;
                            .save{
                                @include purpleBtn;
                            }
                        }

                        ::v-deep{
                            .title,input{
                                color: #7D8FB3;
                                font-size: 0.8125rem;
                            }
                            label{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .content-header{
        width: 100%;
        
        .top{
            padding: 1.75rem 3.125rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #FAFBFC;
            border-bottom: 0.25rem solid #F5F6F7;
            height: 6rem;

            .header-title{
                span{
                    color: $grayBlue;
                    font-size: 1rem;
                    font-weight: 600;
                }
            }

            .header-actions{
                @include flex(flex-start,center,row);
                
                .list-view-btn{
                    margin-left: 1.5625rem;
                    cursor: pointer;
                    img{
                        animation: show .5s forwards;
                        width: 1.46rem;
                        filter: invert(29%) sepia(79%) saturate(6678%) hue-rotate(259deg) brightness(104%) contrast(101%);
                        &.grid{
                            width: 1.5rem;
                            filter: invert(30%) sepia(79%) saturate(6681%) hue-rotate(217deg) brightness(89%) contrast(93%);
                        }
                    }

                }
            }
            .header-filters{
                
                .search{
                    width: 13.75rem;
                    height: 40px;
                    position: relative;
                    @include centerElement;

                    img{
                        width: 1.1rem ;
                        position: absolute;
                        top: 50%;
                        transform: translate(0,-50%);
                        left: 0.9375rem;
                    }

                    input{
                        width: 100%;
                        height: 100%;
                        outline: none;
                        border: none;
                        padding-left: 2.6875rem;
                        color: $grayBlue;
                        font-size: 14px;
                        transition: ease .3s;
                        border-radius: 0.375rem;
                        border: 0.0625rem solid rgba(#959BC9,.4);
                        
                        &::placeholder{
                            color: rgba($grayBlue, .6);
                        }

                        &:focus{
                            box-shadow: 0rem 0rem 0.9375rem rgba($grayBlue, .16);
                        }
                        
                    }
                }

                ul{
                    list-style: none;
                    @include flex(flex-start,center,row);
                    li{
                        width: 13.75rem;
                        height: 2.5rem;
                        margin-left: 0.9375rem;
                        &:first-child{
                            margin-left: 0;
                        }

                        ::v-deep{
                            label{
                                width: 100%;
                            }
                            .title{
                                color: $grayBlue;
                                font-size: 0.875rem;
                            }
                            .selected-option{
                                color: $grayBlue;
                                font-size: 0.875rem;
                                font-weight: 400;
                            }

                            .material-icons{
                                color: $grayBlue;
                            }
                        }
                    }
                }

            }

            .add-new-group{
                img{
                    transition: ease .3s;
                }
                img.down{
                    transform: rotate(45deg);
                }
            }
        }
    }
}

.middle{
    width: 100%;
    margin-top: 1.25rem;
    overflow: auto;
    height: calc(100% - 160px);

    .package-grid{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1.4375rem;
        padding: 1rem 3.125rem;
        animation: show .3s forwards;
        position: relative;
    }
}

</style>