<template>
  <div class="traffic__data">
    <div class="traffic__data-header">Top Active apps/webs</div>
    <div class="traffic__grid">
      <traffic-grid
        :filters="filters"
        :loading="loading"
        :period="period"
        :data="computedData"
        :fields="fields"
        :options="options"
        :highest-session="highestSession"
      />
    </div>
  </div>
</template>

<script>
import TrafficGrid from "@/components/Traffic/TrafficGrid";

export default {
  components: {
    TrafficGrid,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: Boolean,
    period: Object,
    filters: Object,
  },
  computed: {
    highestSession() {
      return this.data[0] ? this.data[0].SessionDuration : null;
    },
    computedData() {
      const data = JSON.parse(JSON.stringify(this.data));
      data.forEach((object) => {
        object.width =
          object.sessionDuration === 0
            ? 0
            : this.calculateTrafficSharePercentage(object.SessionDuration);
      });
      const allWidths = data.map((el) => el.width);
      if (allWidths[allWidths.length - 1] < 170) {
        data.forEach((el) => {
          el.width = el.sessionDuration === 0 ? 0 : el.width + 150;
        });
      }
      return data;
    },
  },
  data() {
    return {
      fields: [
        { title: "Source", class: "source" },
        { title: "Users" },
        { title: "Session Duration" },
        { title: "Avg Session Duration" },
        { title: "Traffic Share", class: "traffic-share" },
      ],
      options: {
        widthPersonCount: true,
        withAverageSessionDuration: true,
        withTrafficShareGraph: true,
      },
    };
  },
  methods: {
    calculatePercentage(number, numberOf) {
      return (number / numberOf) * 100;
    },
    calculateTrafficSharePercentage(sessionDuration) {
      const objectSessionPercentageOf =
        (sessionDuration / this.highestSession) * 100;
      return (objectSessionPercentageOf / 100) * 400;
    },
    calculateTrafficShare(sessionDuration, allTime) {
      return `${Math.floor((sessionDuration / allTime) * 100)}%`;
    },
  },
};
</script>

<style scoped lang="scss">
.traffic__data {
  margin-top: 10px;
  padding: 15px;
  border-radius: 10px;
  overflow-y: auto;
  height: calc(100% - 60px);

  &-header {
    margin-bottom: 13px;
    color: #283593;
    margin-left: 70px;
    padding-top: 20px;
  }
}
</style>