<template>
  <div class="background">
    <div class="payroll-factories">
      <div class="essentials">
        <div class="add">
          <add-button
            title="Add Manual Entry"
            @clicked="popUpVisible(true), (create = true)"
          />
          <span @click="popUpVisible(true), (create = true)">Add Factor</span>
        </div>
        <div class="search">
          <input type="text" v-model="searchFactory" placeholder="Search" />
          <img
            class="search-icon"
            src="@/assets/icons/searchAppIcon.svg"
            alt="hr"
          />
        </div>
      </div>
      <!-- end essentials -->

      <div class="factories-list">
        <div
          class="factories-box"
          v-for="el in facoriesList"
          :key="el.ID"
          :class="
            el.EndDate && new Date(el.EndDate) < new Date()
              ? 'enddate'
              : 'startdate'
          "
        >
          <div
            class="factories-box-header"
            :class="el.IsTax ? 'isTax' : 'noTax'"
          >
            <div class="factories-box-title">
              <span :title="el.Name">{{ el.Name }}</span>
            </div>
            <div class="factories-box-edit" v-if="el.CanUpdate">
              <img
                src="@/assets/icons/edit.svg"
                alt="hr"
                @click="editFactory(el.ID)"
              />
              <img
                src="@/assets/icons/close.svg"
                alt="hr"
                @click="deleteFactory(el.ID)"
              />
            </div>
          </div>
          <!-- end factories-box-header -->
          <div class="factories-info">
            <div class="factories-info-div">
              <span>Factory type</span>
              <span>
                {{
                  el.SalaryFactorTypeTypeName
                    ? el.SalaryFactorTypeTypeName
                    : '-'
                }}
              </span>
            </div>
            <div class="factories-info-div">
              <span>Company</span>
              <span
                :title="el.DestinationTypeName ? el.DestinationTypeName : '-'"
              >
                {{ el.DestinationTypeName ? el.DestinationTypeName : '-' }}
              </span>
            </div>
            <div class="factories-info-div">
              <span>Assign type</span>
              <span>
                {{
                  el.SalaryFactorCalculationTypeName
                    ? el.SalaryFactorCalculationTypeName
                    : '-'
                }}
              </span>
            </div>
            <div class="factories-info-div">
              <span>Payment type</span>
              <span>{{ el.PaymentTypeName ? el.PaymentTypeName : '-' }}</span>
            </div>
            <div class="factories-info-div">
              <span>Company cost</span>
              <span>
                {{ el.CompanyCost ? el.CompanyCost : '-' }}
                {{ el.CompanyCost && el.PaymentTypeID === 2 ? '%' : '' }}
              </span>
            </div>
            <div class="factories-info-div">
              <span>Employee cost</span>
              <span>
                {{ el.SalaryCost ? el.SalaryCost : '-' }}
                {{ el.SalaryCost && el.PaymentTypeID === 2 ? '%' : '' }}
              </span>
            </div>
            <div class="factories-info-div">
              <span>Pay day</span>
              <span>{{ el.PayDay ? el.PayDay : '-' }}</span>
            </div>
            <div class="factories-info-div">
              <span>Start date</span>
              <span>{{ el.StartDate ? convertDate(el.StartDate) : '-' }}</span>
            </div>
            <div class="factories-info-div">
              <span>End date</span>
              <span>{{ el.EndDate ? convertDate(el.EndDate) : '-' }}</span>
            </div>
            <div class="factories-info-div">
              <span>Assigned to</span>
              <span>{{ el.PersonCount }} Employee</span>
            </div>
          </div>
          <!-- end factories-info -->
        </div>
        <!-- end factories-box -->
      </div>
      <!-- end factories-list -->

      <custom-snackbar
        v-if="snackbarVisible"
        :purpose="snackbarPurpose"
        :text="snackbarText"
        @close="snackbarVisible = false"
      />
      <form class="assign-form" @submit.prevent="createFactory">
        <custom-popup
          :visible="popupVisible"
          @close="popUpVisible(false)"
          :title="create ? 'Add Factor' : 'Edit Factor'"
          :flexibleSize="false"
        >
          <div class="form-field">
            <custom-select
              class="input"
              title="Factor type"
              :isRequired="true"
              v-model="newFactory.salaryFactorTypeType"
              @select="selectFactoryType"
              :options="salaryFactorTypeTypesList"
              @clear="newFactory.salaryFactorTypeType = ''"
              :clearVisible="true"
            />
          </div>

          <div class="flex">
            <div class="form-field">
              <custom-input
                class="input"
                v-model="newFactory.name"
                title="Name factor"
                :isRequired="true"
                @clear="newFactory.name = ''"
              />
            </div>
          </div>
          <!-- end flex -->

          <div class="flex-2">
            <div class="form-field custom-checkbox-start">
              <custom-checkbox
                :value="newFactory.PayedByMoney"
                @input="(val) => (newFactory.PayedByMoney = val)"
              />
              <span>Paid by money</span>
            </div>
          </div>
          <!-- end flex-2 -->

          <div class="flex-2">
            <div class="form-field">
              <custom-select
                class="input"
                title="Company"
                :isRequired="true"
                v-model="newFactory.destinationType"
                :options="companiesListOptions"
                @clear="newFactory.destinationType = ''"
                :clearVisible="true"
              />
            </div>
            <div class="form-field">
              <custom-select
                class="input"
                title="Assign type"
                :isRequired="true"
                v-model="newFactory.calculationType"
                :options="factoryTypeOptions"
                @clear="newFactory.calculationType = ''"
                :clearVisible="true"
              />
            </div>
          </div>
          <!-- end flex -->
          <div class="flex-2">
            <div class="form-field">
              <custom-select
                class="input"
                title="Payment type"
                :isRequired="true"
                v-model="newFactory.paymentType"
                :options="paymentTypeOptions"
                @clear="newFactory.paymentType = ''"
                :clearVisible="true"
              />
            </div>
            <div class="form-field">
              <custom-select
                class="input"
                title="Currency"
                :isRequired="true"
                v-model="newFactory.currency"
                :options="salaryCurrencyOptions"
                @clear="newFactory.currency = ''"
                :clearVisible="true"
              />
            </div>
          </div>
          <!-- end flex -->
          <div class="flex-2">
            <div class="form-field">
              <custom-input
                class="input"
                v-model="newFactory.companyCost"
                title="Company cost"
                @clear="newFactory.companyCost = ''"
              />
              <span class="percent-icon">
                {{ newFactory.paymentType == 2 ? '%' : '' }}
              </span>
              <div class="custom-checkbox">
                <custom-checkbox
                  :value="newFactory.disableCompanyCost"
                  @input="(val) => (newFactory.disableCompanyCost = val)"
                />
                <span>Non Editable</span>
              </div>
            </div>
            <div class="form-field">
              <custom-input
                class="input"
                v-model="newFactory.salaryCost"
                title="Employee cost"
                @clear="newFactory.salaryCost = ''"
              />
              <span class="percent-icon">
                {{ newFactory.paymentType == 2 ? '%' : '' }}
              </span>
              <div class="custom-checkbox">
                <custom-checkbox
                  :value="newFactory.disableSalaryCost"
                  @input="(val) => (newFactory.disableSalaryCost = val)"
                />
                <span>Non Editable</span>
              </div>
            </div>
          </div>
          <!-- end flex -->
          <div :class="[!IsTypeOfSalaryFactorStatus ? 'flex-3' : 'flex-2']">
            <div class="form-field" v-if="!IsTypeOfSalaryFactorStatus">
              <custom-input
                class="input"
                v-model="newFactory.payDay"
                title="Pay day"
                :isRequired="IsTypeOfSalaryFactorStatus"
                @clear="newFactory.payDay = ''"
              />
              <div class="custom-checkbox">
                <custom-checkbox
                  :value="newFactory.disablePayDay"
                  @input="(val) => (newFactory.disablePayDay = val)"
                />
                <span>Non Editable</span>
              </div>
            </div>
            <div class="form-field">
              <date-picker
                class="date"
                title="Factor start date"
                :isRequired="true"
                v-model="newFactory.StartDate"
                @clear="newFactory.StartDate = ''"
              />
              <div class="custom-checkbox">
                <custom-checkbox
                  :value="newFactory.disableFactorStartDate"
                  @input="(val) => (newFactory.disableFactorStartDate = val)"
                />
                <span>Non Editable</span>
              </div>
            </div>
            <div class="form-field">
              <date-picker
                class="date"
                title="Factor end date"
                v-model="newFactory.EndDate"
                @clear="newFactory.EndDate = ''"
              />
              <div class="custom-checkbox">
                <custom-checkbox
                  :value="newFactory.disableFactorEndDate"
                  @input="(val) => (newFactory.disableFactorEndDate = val)"
                />
                <span>Non Editable</span>
              </div>
            </div>
          </div>
          <!-- end flex -->

          <div class="flex-top">
            <div class="form-field">
              <select-persons
                :alternativeText="true"
                :initialSelectedPersons="newFactory.selectedPersons"
                :departments="departmentPeople"
                @select="(people) => (newFactory.selectedPersons = people)"
                ref="SelectPersons"
              />
            </div>
          </div>
          <div class="save-button-div">
            <button class="save-button">Save</button>
          </div>
        </custom-popup>
      </form>

      <delete-item
        @delete="deleteRow"
        @close="deletePopupVisible = false"
        v-if="deletePopupVisible"
        :text="`Would you like to delete the factory?`"
      />
    </div>
    <!-- end payroll-factories -->
  </div>
</template>
<script>
import { getDateString, CETToTimestamp } from '@/Helpers/HelperFunctions'
import AddButton from '@/components/ReusableComponents/AddButton/AddButton.vue'
import CustomPopup from '@/components/ReusableComponents/CustomPopup/CustomPopup.vue'
import CustomInput from '@/components/ReusableComponents/CustomInput/CustomInput.vue'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import DeleteItem from '@/components/ReusableComponents/DeleteItem/DeleteItem.vue'
import CustomSelect from '@/components/ReusableComponents/CustomSelect/CustomSelect.vue'
import DatePicker from '@/components/ReusableComponents/DatePicker/DatePicker.vue'
import CustomCheckbox from '@/components/ReusableComponents/CustomCheckbox/CustomCheckbox'
import SelectPersons from '@/components/FixedSchedules/SelectPersons.vue'
import axios from '@/Helpers/AxiosInstance.js'

export default {
  created() {
    this.getFacories()
    this.getPaymentTypes()
    this.getCompanies()
    this.getFactoryTypes()
    this.getCurrencyType()
    this.getSalaryFactorTypeTypes()
    this.getDepartmentJobs()
  },
  components: {
    AddButton,
    CustomPopup,
    CustomInput,
    CustomSnackbar,
    DeleteItem,
    CustomSelect,
    DatePicker,
    CustomCheckbox,
    SelectPersons,
  },
  data() {
    return {
      create: false,
      popupVisible: false,
      deletePopupVisible: false,
      snackbarVisible: false,
      snackbarPurpose: 'error',
      snackbarText: '',
      searchFactory: '',

      paymentTypeOptions: [],
      facoriesList: [],
      selectedfactory: '',
      serviceName: '',
      companiesListOptions: [],
      factoryTypeOptions: [],
      salaryCurrencyOptions: [],
      salaryFactorTypeTypesList: [],
      departmentPeople: [],
      selectedPersonsData: [],
      allPersons: [],

      newFactory: {
        salaryFactorTypeType: null,
        name: null,
        PayedByMoney: false,
        currency: null,
        payDay: null,
        paymentType: null,
        companyCost: null,
        salaryCost: null,
        destinationType: null,
        calculationType: null,
        StartDate: null,
        EndDate: null,
        disableCompanyCost: false,
        disableSalaryCost: false,
        disablePayDay: false,
        disableFactorStartDate: false,
        disableFactorEndDate: false,
        selectedPersons: [],
      },

      IsTypeOfSalaryFactorStatus: true,
    }
  },
  methods: {
    selectFactoryType(value) {
      if (value === 1 || value === 2) {
        this.newFactory.calculationType = 2
        this.newFactory.PayedByMoney = true
      } else {
        this.newFactory.PayedByMoney = false
        this.newFactory.calculationType = 1
      }
    },
    popUpVisible(status) {
      this.newFactory.salaryFactorTypeType = null
      this.newFactory.PayedByMoney = false
      this.newFactory.name = null
      this.newFactory.currency = null
      this.newFactory.payDay = null
      this.newFactory.paymentType = null
      this.newFactory.companyCost = null
      this.newFactory.salaryCost = null
      this.newFactory.destinationType = null
      this.newFactory.calculationType = null
      this.newFactory.StartDate = null
      this.newFactory.EndDate = null
      this.newFactory.disableCompanyCost = false
      this.newFactory.disableSalaryCost = false
      this.newFactory.disablePayDay = false
      this.newFactory.disableFactorStartDate = false
      this.newFactory.disableFactorEndDate = false
      this.newFactory.selectedPersons = []
      this.popupVisible = status
    },
    getFacories() {
      this.serviceName = `salary/GetSalaryFactorTypes`
      if (this.searchFactory)
        this.serviceName = `salary/GetSalaryFactorTypes?Name=${this.searchFactory}`

      axios.get(this.serviceName).then((response) => {
        this.facoriesList = response.data.Value.FactorsWithPersons
      })
    },
    createFactory() {
      let CheckPersonsArray = []
      if (this.newFactory.selectedPersons) {
        CheckPersonsArray = this.newFactory.selectedPersons.map((el) => ({
          PersonID: el,
        }))
      }
      let objectData = {
        salaryFactorTypeType: this.newFactory.salaryFactorTypeType,
        name: this.newFactory.name,
        PayedByMoney: this.newFactory.PayedByMoney,
        currency: this.newFactory.currency,
        payDay: this.newFactory.payDay,
        paymentType: this.newFactory.paymentType,
        companyCost: this.newFactory.companyCost,
        salaryCost: this.newFactory.salaryCost,
        destinationType: this.newFactory.destinationType,
        calculationType: this.newFactory.calculationType,
        StartDate: this.newFactory.StartDate,
        EndDate: this.newFactory.EndDate,
        disableCompanyCost: this.newFactory.disableCompanyCost,
        disableSalaryCost: this.newFactory.disableSalaryCost,
        disablePayDay: this.newFactory.disablePayDay,
        disableFactorStartDate: this.newFactory.disableFactorStartDate,
        disableFactorEndDate: this.newFactory.disableFactorEndDate,
        persons: CheckPersonsArray,
      }

      this.serviceName = 'salary/CreateSalaryFactorTypes'
      if (!this.create) {
        objectData.id = this.selectedfactory
        this.serviceName = 'salary/UpdateSalaryFactorType'
      }

      axios.post(this.serviceName, objectData).then((resp) => {
        if (!resp.data.ErrorMsg) {
          this.popupVisible = false
          this.newFactory.salaryFactorTypeType = false
          this.newFactory.name = null
          this.newFactory.PayedByMoney = false
          this.newFactory.currency = null
          this.newFactory.payDay = null
          this.newFactory.paymentType = null
          this.newFactory.companyCost = null
          this.newFactory.salaryCost = null
          this.newFactory.destinationType = null
          this.newFactory.calculationType = null
          this.newFactory.StartDate = null
          this.newFactory.EndDate = null
          this.newFactory.disableCompanyCost = false
          this.newFactory.disableSalaryCost = false
          this.newFactory.disablePayDay = false
          this.newFactory.disableFactorStartDate = false
          this.newFactory.disableFactorEndDate = false
          this.getFacories()
        } else {
          this.snackbarVisible = true
          this.snackbarText = resp.data.ErrorMsg
          this.snackbarPurpose = 'error'
        }
      })
    },
    editFactory(id) {
      this.create = false
      this.selectedfactory = id
      axios.get(`salary/GetSalaryFactorTypes?ID=${id}`).then((response) => {
        this.newFactory = {
          salaryFactorTypeType:
            response.data.Value.FactorsWithPersons[0].SalaryFactorTypeTypeID,
          name: response.data.Value.FactorsWithPersons[0].Name,
          PayedByMoney: response.data.Value.FactorsWithPersons[0].PayedByMoney,
          currency: response.data.Value.FactorsWithPersons[0].CurrencyID,
          payDay: response.data.Value.FactorsWithPersons[0].PayDay,
          paymentType: response.data.Value.FactorsWithPersons[0].PaymentTypeID,
          companyCost: response.data.Value.FactorsWithPersons[0].CompanyCost,
          salaryCost: response.data.Value.FactorsWithPersons[0].SalaryCost,
          destinationType:
            response.data.Value.FactorsWithPersons[0].DestinationTypeID,
          calculationType:
            response.data.Value.FactorsWithPersons[0]
              .SalaryFactorCalculationTypeID,
          StartDate: response.data.Value.FactorsWithPersons[0].StartDate,
          EndDate: response.data.Value.FactorsWithPersons[0].EndDate,
          disableCompanyCost:
            response.data.Value.FactorsWithPersons[0].DisableCompanyCost,
          disableSalaryCost:
            response.data.Value.FactorsWithPersons[0].DisableSalaryCost,
          disablePayDay:
            response.data.Value.FactorsWithPersons[0].DisablePayDay,
          disableFactorStartDate:
            response.data.Value.FactorsWithPersons[0].DisableFactorStartDate,
          disableFactorEndDate:
            response.data.Value.FactorsWithPersons[0].DisableFactorEndDate,
          selectedPersons: [],
        }
        if (response.data.Value.FactorsWithPersons[0].Persons.length > 0) {
          response.data.Value.FactorsWithPersons[0].Persons.forEach(
            (person) => {
              this.newFactory.selectedPersons.push(person.PersonID)
            },
          )
        }
        this.popupVisible = true
      })
    },
    deleteFactory(id) {
      this.selectedfactory = id
      this.deletePopupVisible = true
    },
    deleteRow() {
      var data = {
        id: this.selectedfactory,
        delete: true,
        persons: [],
      }
      axios
        .post('salary/UpdateSalaryFactorType', data)
        .then((resp) => {
          this.deletePopupVisible = false
          if (!resp.data.ErrorMsg) {
            this.snackbarText = 'Deleted Successfully!'
            this.snackbarPurpose = 'success'
            this.snackbarVisible = true
            this.getFacories()
          } else {
            this.snackbarVisible = true
            this.snackbarText = resp.data.ErrorMsg
            this.snackbarPurpose = 'error'
          }
        })
        .catch(() => {
          this.snackbarVisible = true
          this.snackbarText = 'Error'
          this.snackbarPurpose = 'error'
        })
    },
    getCompanies() {
      axios.get(`salary/GetSalaryFactorsDestinationTypes`).then((resp) => {
        this.companiesListOptions = resp.data.Value.DestinationTypes.map(
          (el) => ({
            title: el.Name,
            value: el.ID,
          }),
        )
      })
    },
    getFactoryTypes() {
      axios.get(`salary/GetSalaryFactorCalculationTypes`).then((resp) => {
        this.factoryTypeOptions = resp.data.Value.map((el) => ({
          title: el.Name,
          value: el.ID,
        }))
      })
    },
    getPaymentTypes() {
      axios.get('salary/GetSalaryFactorTypePaymentTypes').then((resp) => {
        this.paymentTypeOptions = resp.data.Value.map((el) => ({
          title: el.Name,
          value: el.ID,
        }))
      })
    },
    getCurrencyType() {
      axios.get('Company/CurrencyTypes').then((resp) => {
        this.salaryCurrencyOptions = resp.data.Value.map((el) => ({
          title: el.Code,
          value: el.ID,
        }))
      })
    },
    getSalaryFactorTypeTypes() {
      axios
        .get('salary/GetSalaryFactorTypeTypes?WithoutPrepeid=true')
        .then((resp) => {
          this.salaryFactorTypeTypesList = resp.data.Value.map((el) => ({
            title: el.Name,
            value: el.ID,
            status: el.IsTypeOfSalaryFactor,
          }))
        })
    },
    getPersons() {
      axios.get('/Person/GetPersons').then((resp) => {
        this.allPersons = resp.data.Value.Persons
      })
    },
    getSelectedPersons(ids, persons) {
      let parsedids = []
      ids.forEach((id) => {
        parsedids.push(parseInt(id))
      })
      let arr = persons.filter(function (person) {
        return parsedids.indexOf(person.ID) !== -1
      })
      this.selectedPersonsData = arr
    },
    getDepartmentJobs() {
      axios.get('salary/GetPersonsWithActiveSalary').then((resp) => {
        if (resp.data.ErrorMsg) return
        this.departmentPeople = resp.data.Value.DepartmentJobPersons.map(
          (dep) => {
            return {
              id: dep.DepartmentID == null ? 0 : dep.DepartmentID,
              name:
                dep.DepartmentName == null
                  ? 'Jobs without department'
                  : dep.DepartmentName,
              jobs: dep.JobPersons.map((job) => {
                return {
                  id: job.JobID,
                  name: job.JobName,
                  people: job.Persons.map((person) => {
                    return {
                      id: person.PersonID,
                      name: person.FirstName + ' ' + person.LastName,
                    }
                  }),
                }
              }),
            }
          },
        )
      })
    },
    convertDate(date) {
      return getDateString(CETToTimestamp(date), 'dd MMM yyyy')
    },
  },
  watch: {
    searchFactory(to, from) {
      this.searchFactory = to
      this.getFacories()
    },
    'newFactory.selectedPersons': function (to, from) {
      if (this.selectedPersons)
        this.getSelectedPersons(this.selectedPersons, this.allPersons)
    },
    'newFactory.paymentType': function (to, from) {
      this.newFactory.paymentType = to
    },
    'newFactory.salaryFactorTypeType': function (to, from) {
      let SalaryFactorStatus = this.salaryFactorTypeTypesList.filter(
        (el) => el.value == to,
      )
      if (SalaryFactorStatus.length > 0)
        this.IsTypeOfSalaryFactorStatus = SalaryFactorStatus[0].status
    },
  },
}
</script>
<style lang="scss" scoped>
.background {
  overflow: hidden;
}
.payroll-factories {
  height: 100%;
  .factories-list {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    height: calc(100% - 8.125rem);
    overflow: auto;
    .factories-box.enddate {
      opacity: 0.5;
    }
    .factories-box {
      width: 21.375rem;
      height: fit-content;
      min-height: 20.3125rem;
      border-radius: 0.375rem;
      background-color: #fff;
      overflow: hidden;
      margin: 1.6875rem;
      padding-bottom: 0.625rem;
      .isTax {
        background-color: #fbb6b6;
      }
      .noTax {
        background-color: #b6e7f8;
      }
      .factories-box-header {
        width: 100%;
        height: 3.125rem;
        display: flex;
        justify-content: space-between;
        .factories-box-title {
          max-width: 16.25rem;
          padding-left: 1.875rem;
          -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
          font-size: 1rem;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.35;
          letter-spacing: normal;
          color: #5963ab;
          overflow: hidden;
          span {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 0.875rem;
            display: inline-block;
          }
        }
        .factories-box-edit {
          img:first-child {
            width: 1.6875rem;
            margin: 0.75rem 0.3125rem 0 0rem;
            cursor: pointer;
          }
          img:last-child {
            width: 1.6875rem;
            margin: 0.75rem 0.9375rem 0 0rem;
            cursor: pointer;
          }
        }
      }
      .factories-info {
        padding: 0 1.875rem;
        .factories-info-div {
          span {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 0.875rem;
            display: inline-block;
          }
          span:first-child {
            width: 45%;
            font-size: 0.8125rem;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: left;
            color: #5963ab;
            display: inline-block;
            vertical-align: middle;
          }
          span:last-child {
            width: 55%;
            font-size: 0.8125rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: left;
            color: #5963ab;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .essentials {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15 * $rem;
    padding: 0 1.75rem;
    margin-top: 1.875rem;

    form {
      margin-right: 15 * $rem;
    }

    & > .add {
      display: flex;
      align-items: center;

      span {
        display: inline-block;
        margin-left: 10 * $rem;
        font-weight: 400;
        font-size: 18 * $rem;
        color: $blue-primary;
        cursor: pointer;
      }
    }

    .search {
      position: relative;
      input {
        width: 12.5rem;
        height: 2.375rem;
        background-color: #fff;
        border: 0;
        outline: none;
        border-radius: 0.375rem;
        font-weight: 300;
        font-size: 0.875rem;
        color: #9696b9;
        padding: 0 2.1875rem 0 0.9375rem;
      }
      img {
        position: absolute;
        height: 1.3125rem;
        width: 1.3125rem;
        right: 0.625rem;
        top: 0.5rem;
      }
    }
  }
  .flex {
    margin-top: 1.25rem;
  }
  .flex-top {
    margin-top: 2.5rem;
  }
  .flex-2 {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
    .form-field {
      width: 48%;
    }
    .custom-checkbox-start {
      display: flex;
      justify-content: start;
      margin-top: 0.625rem;
      span {
        color: #283593;
        font-size: 0.875rem;
        display: block;
        margin-left: 0.625rem;
      }
    }
  }
  .flex-3 {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
    .form-field {
      width: 30%;
    }
    ::v-deep .el-input {
      width: 100%;
    }
  }
  .form-field {
    width: 100%;
    position: relative;
    label {
      width: 100%;
    }
    .percent-icon {
      font-weight: 500;
      color: rgba(40, 53, 147, 0.7);
      font-size: 0.9375rem;
      position: absolute;
      right: 19px;
      top: 12px;
    }
  }
  .custom-checkbox {
    display: flex;
    justify-content: end;
    margin-top: 0.625rem;
    span {
      color: #283593;
      font-size: 0.875rem;
      display: block;
      margin-left: 0.625rem;
    }
  }
  .save-button-div {
    width: 100%;
    text-align: right;
  }
  .save-button {
    background-color: #6956d6;
    color: #fcfcfc;
    height: 3rem;
    width: 6.25rem;
    border-radius: 0.375rem;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 3.75rem;
  }
  .assign-form {
    ::v-deep .popup {
      height: auto;
    }
    ::v-deep .select-tree {
      margin-left: 0;
      .container {
        width: auto;
      }
    }
  }
}
</style>
