<template>
  <div class="background" @click="commentId = 0">
    <custom-popup
      style="z-index: 999"
      :visible="bannerUploadPopup"
      :flexible-size="true"
      title="Add Banner"
      @close="bannerUploadPopup = false"
    >
      <custom-file-input
        :value="poll.file"
        @change="(file) => (poll.file = file)"
      />
      <div class="upload-button" @click="bannerUploadPopup = false">Upload</div>
    </custom-popup>
    <div class="preview-popup">
      <custom-popup
        :visible="showPreviewPopup"
        @close="showPreviewPopup = false"
      >
        <div class="poll">
          <div class="poll__content">
            <div class="banner-details-content">
              <div
                class="poll__banner"
                :style="{
                  'background-image': `url(data:image/jpeg;base64,${poll.file.base64})`,
                }"
              />
              <div class="poll__details">
                <div class="wrapper">
                  <div class="poll-date">
                    Expiry date:
                    {{ getDateString(poll.expiryDate, "dd MMM yyyy") }}
                  </div>
                  <div class="poll__question">
                    {{ poll.question || "question" }}
                  </div>
                  <div class="poll__options">
                    <div
                      class="poll__option"
                      v-for="(option, i) in poll.options"
                      :key="i"
                    >
                      <div class="poll__option-wrapper">
                        <div class="poll__option-index">{{ i + 1 }}</div>
                        <div class="poll__option-name">{{ option.option }}</div>
                      </div>
                      <div :class="['poll__option-checkbox']"></div>
                    </div>
                  </div>
                </div>

                <div class="poll__comment"></div>
              </div>
            </div>
            <div class="desc-comment-content">
              <div class="poll__description">{{ poll.description }}</div>
              <textarea
                placeholder="Type your comment"
                type="text"
                class="cstm-input"
              />
            </div>
          </div>
          <div class="poll__button">Send</div>
        </div>
      </custom-popup>
    </div>
    <div class="left-container">
      <h1>Details</h1>
      <div class="poll-detail">
        <div class="header"></div>
        <div class="poll-detail__top">
          <div class="poll-detail__title">
            <input
              class="cstm-input cstm-input--title"
              v-model="poll.title"
              placeholder="Hero Title"
              :disabled="isPollFinished"
            />
          </div>
          <div class="poll-detail__description">
            <textarea
              rows="5"
              class="cstm-input cstm-input--description"
              v-model="poll.description"
              placeholder="Description"
              :disabled="isPollFinished"
            />
          </div>
        </div>
        <div class="padding-wrapper">
          <div class="poll-detail__header">
            <div class="poll-detail__expire">
              <date-picker
                :disabled="isPollFinished"
                v-model="poll.expiryDate"
                title="Expiry date"
              />
            </div>
            <div class="poll-detail__select">
              <select-persons
                :initialSelectedPersons="selectedValues.person"
                :placementTitle="true"
                :departments="departmentPeople"
                @select="(people) => (selectedValues.person = people)"
              />
            </div>
            <div
              class="priority-content"
              @click="poll.priority = !poll.priority"
            >
              <span class="material-icons-round"> priority_high </span>
              <span>High Priority</span>
              <span v-if="highPriority" class="material-icons success"
                >check</span
              >
            </div>
            <div class="poll-detail__button" @click="bannerUploadPopup = true">
              <img
                :src="require('@/assets/icons/icon_add_image.svg')"
                alt="add-image"
              />
              <span>Upload Banner</span>
              <span v-if="poll.file.base64" class="material-icons success"
                >check</span
              >
            </div>
            <div class="poll-detail__header-wrapper"></div>
          </div>
          <div class="poll-detail__question">
            <div class="cstm-input cstm-input--question" v-if="isPollFinished">
              {{ poll.question }}
            </div>
            <textarea
              v-else
              class="cstm-input cstm-input--question"
              v-model="poll.question"
              placeholder="Question"
            />
          </div>
          <div
            class="poll-detail__type"
            :style="[poll.published ? { pointerEvents: 'none' } : null]"
          >
            <span class="poll-detail-title"> Select Response Type </span>
            <div class="poll-detail__type-wrapper" @click="poll.type = 1">
              <img
                v-if="poll.type === 1"
                :src="require('@/assets/icons/check-fill.svg')"
              />
              <img v-else :src="require('@/assets/icons/check-unfilled.svg')" />
            </div>
            <div class="poll-detail__type-wrapper" @click="poll.type = 2">
              <img
                v-if="poll.type === 2"
                :src="require('@/assets/icons/icon_multiple-choice-active.svg')"
              />
              <img
                v-else
                :src="
                  require('@/assets/icons/icon_multiple-choice-deselected.svg')
                "
              />
            </div>
          </div>
          <!-- <div class="poll-detail__type">
            <span class="poll-detail__type-title"> Select Response Type </span>
            <div
              class="poll-detail__type-wrapper"
              @click="
                isPollFinished || poll.published ? () => {} : (poll.type = 1)
              "
            >
              <img
                v-if="poll.type === 1"
                :src="require('@/assets/icons/icon_single-choice-active.svg')"
              />
              <img
                v-else
                :src="
                  require('@/assets/icons/icon_single-choice-deselected.svg')
                "
              />
            </div>
            <div
              class="poll-detail__type-wrapper"
              @click="
                isPollFinished || poll.published ? () => {} : (poll.type = 2)
              "
            >
              <img
                v-if="poll.type === 2"
                :src="require('@/assets/icons/icon_multiple-choice-active.svg')"
              />
              <img
                v-else
                :src="
                  require('@/assets/icons/icon_multiple-choice-deselected.svg')
                "
              />
            </div>
          </div> -->
          <div class="poll-detail__options">
            <div
              class="poll-detail__options-option"
              v-for="(opt, index) in poll.options"
              :key="index"
            >
              <div class="poll-detail__options-option-index">
                {{ index + 1 }}
              </div>
              <div
                class="poll-detail__options-option-name"
                :title="opt.option.length > 70 ? opt.option : null"
              >
                <input
                  :disabled="poll.published"
                  class="cstm-input cstm-input--option"
                  :placeholder="`Option ${index + 1}`"
                  v-model="opt.option"
                  @focus="optionClick(index)"
                />
              </div>
              <div
                class="poll-detail__options-option-delete"
                @click="deleteOptionClick(index)"
                v-if="
                  poll.options.length > 2 && !isPollFinished && !poll.published
                "
              >
                <img :src="require('@/assets/icons/icon_delete.svg')" />
              </div>
            </div>
            <div
              v-if="!isPollFinished && !poll.published"
              class="poll-detail__options-add-option"
              @click="addOptionClick"
            >
              <img :src="require('@/assets/icons/icon_add.svg')" />
              <span> Add Option </span>
            </div>
          </div>
          <div class="poll-detail__buttons">
            <switcher
              :disabled="poll.published"
              v-model="poll.anonymous"
              title="Anonymous"
            />
            <div
              class="preview-button"
              @click="showPreviewPopup = !showPreviewPopup"
            >
              <img src="@/assets/preview-icon.svg" />
              <span>Preview</span>
            </div>
            <div
              v-if="!isPollFinished"
              class="send-notification"
              @click.stop="sendNotification = !sendNotification"
            >
              <span>Send Notification</span>
              <div class="dot" :class="{ selected: sendNotification }"></div>
            </div>
          </div>
          <div class="poll-detail__buttons">
            <div class="wrapper-button">
              <div
                v-if="!poll.published"
                @click="savePoll(false)"
                class="button--unfilled"
              >
                Save & Draft
              </div>
              <div
                class="button"
                v-if="!isPollFinished"
                @click="savePoll(true)"
              >
                Publish
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="poll-statistics">
      <button class="export-excel showbutton" @click="getExcelFile()">
        Export
      </button>
      <h1>Response</h1>
      <poll-statistic-anonymous
        :non-active-persons="nonActivePersons"
        :published="poll.published"
        :finished="isPollFinished"
        :persons="persons"
        :chart-statistics="optionStatistics"
        v-if="poll.anonymous"
        @reminder-clicked="sendMailNotification"
      />
      <poll-statistic-visible
        v-else
        :published="poll.published"
        :finished="isPollFinished"
        :persons="persons"
        :person-choices="personChoicesFiltered"
        @comment-clicked="commentClickHanlder"
        @reminder-clicked="sendMailNotification"
        :commentId="commentId"
        :chart-statistics="optionStatistics"
        :inactive-persons="inactivePersons"
        :reminderActive="reminderActive"
      />
    </div>
    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
  </div>
</template>

<script>
import PollStatisticVisible from "@/components/Polls/Statistic/PollStatisticVisible";
import PollStatisticAnonymous from "@/components/Polls/Statistic/PollStatisticAnonymous";
import Switcher from "@/components/ReusableComponents/Switcher/Switcher";
import CustomFileInput from "@/components/ReusableComponents/CustomFileInput/CustomFileInput";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import axios from "@/Helpers/AxiosInstance";

import SelectPersons from "@/components/FixedSchedules/SelectPersons.vue";

export default {
  components: {
    Switcher,
    CustomFileInput,
    DatePicker,
    CustomSnackbar,
    CustomPopup,
    PollStatisticAnonymous,
    PollStatisticVisible,
    SelectPersons,
  },
  async created() {
    const { id } = this.$route.params;
    await this.getSinglePoll(id);
    // await this.getDepartments();
    await this.getPersonChoices();
    await this.getPollOptionStatistics();
    await this.getPollPersons();
    this.getDepartmentJobs();
    this.checkExpiryDate(this.poll.expiryDate);
  },
  computed: {
    departmentsSelect() {
      return this.departments.map((department) => ({
        value: department.ID,
        title: department.Name,
        field: "department",
        children: department.jobs.map((job) => ({
          parentField: "department",
          parentValue: department.ID,
          field: "job",
          title: job.JobName,
          value: job.Job,
          children: job.persons.map((person) => ({
            parentField: "job",
            parentValue: job.Job,
            field: "person",
            title: `${person.FirstName} ${person.LastName}`,
            value: person.PersonID,
          })),
        })),
      }));
    },
    waitingList() {
      let result = [];
      this.data.forEach((el) => {
        if (!el.Voted) {
          result.push(el.Person);
        }
      });
      return result;
    },
    isPollFinished() {
      var myDate = new Date();
      var myPastDate = new Date(myDate);
      let boolean = false;
      if (this.poll.published) {
        boolean =
          myPastDate.setDate(myPastDate.getDate()) >
          new Date(this.poll.expiryDate);
      }
      return boolean;
    },
    personChoicesFiltered() {
      const persons = [];
      this.personChoices.forEach((el) => {
        if (persons.includes(el)) return;

        persons.push({
          name: el.FirstName + " " + el.LastName,
          id: el.Person,
          commentVisible: el.commentVisible,
          comment: el.Comment,
        });
      });
      persons.forEach((person) => {
        person.choices = this.personChoices
          .filter((el) => el.Person === person.id)
          .map((el) => el.Choices)[0];
      });

      return persons;
    },
    inactivePersons() {
      const persons = [];
      this.personChoices.forEach((el) => {
        if (persons.includes(el)) return;

        persons.push(el.Person);
      });

      return this.personsList.filter((el) => !persons.includes(el.id));
    },
  },
  data() {
    return {
      commentId: 0,
      data: [],
      highPriority: false,
      sendNotification: true,
      poll: {
        title: "",
        file: {},
        anonymous: false,
        type: 1,
        question: "",
        description: "",
        published: false,
        options: [
          {
            option: "",
          },
          {
            option: "",
          },
        ],
        expiryDate: new Date(),
      },
      selectedValues: {
        person: [],
        department: [],
        job: [],
      },
      showPreviewPopup: false,
      bannerUploadPopup: false,
      departments: [],
      persons: [],
      personChoices: [],
      activePersons: [],
      optionStatistics: [],
      nonActivePersons: 0,
      personsList: [],
      departmentPeople: [],
      snackbarPurpose: "",
      snackbarText: "",
      reminderActive: true,
      snackbarVisible: false,
    };
  },
  methods: {
    getExcelFile() {
      let showButton;
      const isLocal = ~location.href.indexOf("localhost");
      const link = document.createElement("a");

      link.href = `https://intranet.upgaming.${
        isLocal ? "dev" : "com"
      }/api/News/GetPollResultsForExcel?Poll=${this.$route.params.id}`;
      link.setAttribute("download", `PollResults.xlsx`);
      document.body.appendChild(link);

      link.click();
    },
    checkExpiryDate(strDate) {
      let expiryTimestamp = Date.parse(strDate) / 1000;
      let currentTimeStamp = ~~(Date.now() / 1000);

      if (currentTimeStamp > expiryTimestamp) {
        this.reminderActive = false;
      } else {
        this.reminderActive = true;
      }
    },
    sendMailNotification() {
      axios
        .post("notifications/SendMailNotification", {
          Placement: this.poll.id,
          PlacementType: "Poll",
          Persons: this.waitingList,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.snackbarPurpose = "success";
            this.snackbarVisible = true;
            this.snackbarText = "Successfully sent";
          }
        });
    },
    commentClickHanlder(person) {
      this.commentId = person.id;
    },
    getDepartmentJobs() {
      axios.get("News/GetPlacementDropDownPersons").then((resp) => {
        if (resp.data.ErrorMsg) return;
        this.departmentPeople = resp.data.Value.DepartmentJobPersons.map(
          (dep) => {
            return {
              id: dep.DepartmentID == null ? 0 : dep.DepartmentID,
              name:
                dep.DepartmentName == null
                  ? "Jobs without department"
                  : dep.DepartmentName,
              jobs: dep.JobPersons.map((job) => {
                return {
                  id: job.JobID,
                  name: job.JobName,
                  people: job.Persons.map((person) => {
                    return {
                      id: person.PersonID,
                      name: person.FirstName + " " + person.LastName,
                    };
                  }),
                };
              }),
            };
          }
        );
      });
    },
    getDateString(date, format = "yyyy-MM-dd") {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      if (date === "Invalid Date") return;

      const MONTHS = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      var days = ("0" + date.getDate()).slice(-2),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        year = date.getFullYear(),
        hours = ("0" + date.getHours()).slice(-2),
        minutes = ("0" + date.getMinutes()).slice(-2),
        seconds = ("0" + date.getSeconds()).slice(-2);

      return format
        .replace("dd", days)
        .replace("MMM", MONTHS[parseInt(month) - 1])
        .replace("MM", month)
        .replace("yyyy", year)
        .replace("yy", String(year).slice(-2))
        .replace("HH", hours)
        .replace("mm", minutes)
        .replace("ss", seconds)
        .replace("ww", WEEKDAYS[date.getDay()]);
    },
    async getSinglePoll(id) {
      const data = await axios
        .get("News/GetPollDetails", {
          params: {
            Poll: id,
          },
        })
        .then((resp) => {
          const { Value } = resp.data;
          return Value;
        });

      const {
        Poll: {
          PollID,
          Anonymous,
          Description,
          ExpiryDate,
          File,
          FileID,
          FileType,
          Filename,
          Question,
          Title,
          Type,
          Published,
        },
        PollOptions: options,
        PollVisibilities: restrictions,
      } = data;
      this.poll = {
        id: PollID,
        title: Title,
        file: {
          filename: Filename,
          type: FileType,
          base64: File,
        },
        fileId: FileID,
        published: Published,
        anonymous: Anonymous,
        type: Type,
        question: Question,
        description: Description,
        options: options.map((el) => ({ option: el.Option })),
        expiryDate: ExpiryDate ? new Date(ExpiryDate) : "",
      };
      restrictions.forEach((el) => {
        if (el.VisibilityRestrictionType === 1)
          this.selectedValues["person"].push(el.VisibleForEntity);
        else if (el.VisibilityRestrictionType === 2)
          this.selectedValues["job"].push(el.VisibleForEntity);
        else if (el.VisibilityRestrictionType === 4)
          this.selectedValues["department"].push(el.VisibleForEntity);
      });
    },
    async getPollPersons() {
      const { Persons, PersonsVotedQuantity } = await axios
        .get("News/GetPollPersons", {
          params: {
            Poll: this.$route.params.id,
          },
        })
        .then((resp) => {
          this.data = resp.data.Value.Persons;
          const { Value } = resp.data;
          return Value;
        });
      this.persons = Persons.map(({ Person }) => Person);
      this.personsList = Persons.map(({ Person, FirstName, LastName }) => ({
        id: Person,
        name: `${FirstName} ${LastName}`,
      }));
      this.nonActivePersons = Persons.length - PersonsVotedQuantity;
    },
    async getPersonChoices() {
      const data = await axios
        .get("News/GetPollPersonChoices", {
          params: {
            Poll: this.$route.params.id,
          },
        })
        .then((resp) => {
          const { Value } = resp.data;

          return Value.PersonChoices;
        });
      this.personChoices = data;

      // data.map(
      //   ({
      //     Option,
      //     FirstName,
      //     LastName,
      //     Person,
      //     Comment,
      //     PollPersonChoiceID,
      //   }) => ({
      //     id: Person,
      //     comment: Comment,
      //     name: `${FirstName} ${LastName}`,
      //     option: Option,
      //     PollPersonChoiceID: PollPersonChoiceID,
      //   })
      // );
      this.personChoices.forEach((el) => {
        this.$set(el, "commentVisible", false);
      });
    },
    async getPollOptionStatistics() {
      const data = await axios
        .get("News/GetPOllOptionsStatistics", {
          params: {
            Poll: this.$route.params.id,
          },
        })
        .then((resp) => {
          const { Value } = resp.data;
          return Value;
        });

      this.optionStatistics = data.map(
        ({ AllVotesQuantity, OptionDesc, Quantity, OptionID }) => ({
          quantity: Quantity,
          option: OptionDesc,
          id: OptionID,
          allQuantity: AllVotesQuantity,
        })
      );
    },

    async getPerson(id) {
      const data = await axios
        .get("Person/GetPersons", {
          params: {
            Person: id,
          },
        })
        .then((resp) => resp.data.Value);

      const person = data.Persons[0];
      return `${person.FirstName} ${person.LastName}`;
    },
    optionClick(index) {
      if (this.isPollFinished) return;
      if (this.poll.options.length - 1 === index) {
        this.addOptionClick();
      }
    },
    async savePoll(published) {
      const { poll } = this;
      const restrictions = [
        ...this.selectedValues["person"].map((el) => ({
          VisibilityRestrictionType: 1,
          VisibleForEntity: el,
        })),
        ...this.selectedValues["job"].map((el) => ({
          VisibilityRestrictionType: 2,
          VisibleForEntity: el,
        })),
        ...this.selectedValues["department"].map((el) => ({
          VisibilityRestrictionType: 4,
          VisibleForEntity: el,
        })),
      ];
      const options = poll.options
        .filter((el) => el.option.length > 0)
        .map((el) => ({
          Option: el.option,
        }));

      const response = await axios.post("News/UpdatePoll", {
        Poll: poll.id,
        Description: poll.description,
        Question: poll.question,
        IsPriority: this.highPriority,
        // ResponseExpiry: getDateString(poll.expiryDate, "yyyy/MM/dd"),
        ExpiryDate: new Date(poll.expiryDate).getTime(),
        Type: poll.type,
        Title: poll.title,
        FileID: poll.fileId,
        sendNotification: this.sendNotification,
        Published: published,
        Anonymous: poll.anonymous,
        FileName: poll.file.filename,
        FileType: poll.file.type,
        Base64: poll.file.base64,
        Restrictions: restrictions,
        PollOptions: options,
      });
      this.$router.push({ path: "/Polls" });
    },
    deleteOptionClick(index) {
      this.$set(
        this.poll,
        "options",
        this.poll.options.filter((e, i) => index !== i)
      );
    },
    handleChangedValue(selectedList) {
      this.selectedValues = selectedList;
    },
    shouldParentBeSelected(parent) {
      if (!parent.children || parent.children <= 0) return;
      const childrenLength = parent.children.length;
      let selectedChildrenLength = 0;
      parent.children.forEach((child) => {
        if (this.selectedValues[child.field].includes(child.value))
          selectedChildrenLength += 1;
      });

      if (selectedChildrenLength === childrenLength)
        this.selectedValues[parent.field].push(parent.value);
      else
        this.selectedValues[parent.field] = this.selectedValues[
          parent.field
        ].filter((el) => el !== parent.value);

      parent.children.forEach((el) => this.shouldParentBeSelected(el));
      this.$emit("value-changed", this.selectedValues);
    },
    async getDepartments() {
      const { data: departmentsList } = await axios.get(
        "departments/GetDepartments"
      );
      const departments = departmentsList.Value;
      for (let department of departments) {
        const { data: jobsList } = await axios.get(
          "departments/GetDepartmentJobs",
          {
            params: {
              DepartmentID: department.ID,
            },
          }
        );
        const jobs = jobsList.Value;
        for (let job of jobs) {
          const { data: personList } = await axios.get("jobs/JobPersons", {
            params: {
              JobID: job.Job,
            },
          });
          job.persons = personList.Value;
        }
        department.jobs = jobs;
      }
      this.departments = departments;
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
}
.send-notification {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  color: $blue-primary;
  cursor: pointer;
  font-weight: 300;
  font-size: 15px;
  .dot {
    width: 15px;
    height: 15px;
    border: 1px solid #1de9b6;
    border-radius: 100%;
    margin-left: 5px;
    &.selected {
      background-color: #1de9b6;
    }
  }
}
.background {
  padding: 30px 70px 0 70px;
  display: flex;
  justify-content: space-between;
}
.priority-content {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  span:nth-child(1) {
    color: $blue-primary;
    opacity: 0.7;
  }
  span:nth-child(2) {
    color: $blue-primary;
  }
  span:nth-child(3) {
    color: #1de9b6;
    font-size: 20px;
    margin-left: 3px;
  }
}
h1 {
  font-size: 20px;
  color: $blue_primary;
  font-weight: 400;
  margin-bottom: 15px;
}
.poll-statistics {
  width: 43%;
}
.left-container {
  width: 55%;
}
.poll-detail {
  border-radius: 10px;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  height: calc(100% - 69px);

  &__top {
    background-color: #959bc9;
    padding: 50px 25px;
    border-radius: 10px;
    height: 173px;
  }

  &__header {
    margin-top: 15px;
    display: flex;

    &-wrapper {
      display: flex;
      margin-left: auto;
    }
  }

  &__type {
    display: flex;
    font-size: 14px;
    color: #9696b9;
    align-items: center;
    font-weight: 300;
    &-wrapper {
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 12px;
        margin-left: 10px;
      }
      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    &-title {
      margin-right: 10px;
    }
  }

  &__question {
    margin-top: 30px;
  }

  &__expire,
  &__select {
    margin-right: 10px;
  }

  &__options {
    margin-top: 20px;
    height: 270px;
    overflow-y: scroll;
    width: calc(100% + 22px);
    padding-right: 30px;

    &-option {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &-index {
        font-size: 13px;
        color: #9696b9;
      }

      &-delete {
        margin-left: auto;
        cursor: pointer;
      }
    }

    &-add-option {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #9696b9;

      img {
        height: 12px;
        width: 12px;
        margin-right: 7px;
      }
    }
  }

  &__switch {
    margin-top: 35px;
  }

  &__title {
    position: relative;
    width: 750 * $rem;
    margin-top: -30px;

    &-input {
      width: 750 * $rem;
    }

    &-count {
      position: absolute;
      right: 10px;
      top: 12px;
      color: $blue_primary;
      font-size: 14px;
      font-weight: lighter;
      background-color: #fff;
    }
  }

  &__buttons {
    margin-top: auto;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    .switcher-container {
      border: 0;
      ::v-deep .switcher {
        margin-left: 15px;
      }
    }

    .button {
      cursor: pointer;
      width: 150 * $rem;
      height: 50 * $rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: 1px solid rgba($blue-primary, 0.6);
      background-color: rgba($blue-primary, 0.6);
      color: white;
      transition: 0.2s ease;

      &:hover {
        background-color: rgba($blue-primary, 0.8);
        border-color: rgba($blue-primary, 0.8);
      }

      &--unfilled {
        background-color: transparent;
        color: rgba($blue-primary, 0.6);
        border: 1px solid rgba($blue-primary, 0.6);
        width: 150 * $rem;
        height: 50 * $rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin-right: 5px;
        cursor: pointer;

        &:hover {
          border-color: rgba($blue-primary, 0.8);
          background-color: rgba($blue-primary, 0.8);
          color: white;
        }
      }

      &:not(:last-child) {
        margin-right: 10 * $rem;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $blue_primary;
    font-weight: 300;
    cursor: pointer;
    padding-left: 1.875rem;
    span {
      white-space: nowrap;
    }
    .material-icons {
      color: #1de9b6;
      font-size: 18px;
      margin-left: 5px;
    }
  }
}
.preview-button {
  color: $blue-primary;
  font-weight: 300;
  cursor: pointer;
  span {
    margin-left: 7px;
  }
}
.preview-popup {
  ::v-deep .overlay {
    z-index: 100;
    .popup {
      width: 72%;
      height: 82%;
      left: 55%;
    }
    .banner-details-content {
      display: flex;
      width: 100%;
      height: 410px;
    }
    .desc-comment-content {
      margin-top: 30px;
    }
    .cstm-input {
      background-color: transparent;
      border: 0;
      font-size: 14 * $rem;
      width: 100%;
      color: #283593;
      height: 60px;
      max-width: 100%;
      font-weight: 300;
      border: 1px solid #dcdde6;
      padding-left: 10px;
      padding-top: 5px;
      border-radius: 5px;

      &::placeholder {
        color: #9696b9;
      }

      &:focus {
        outline: 0;
      }
    }

    .poll {
      display: flex;
      flex-direction: column;

      &__content {
        display: flex;
        height: 550px;
        flex-direction: column;
      }

      &__banner {
        width: 50%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 10px;
        margin-top: 24px;
      }

      &__details {
        margin-left: 50px;
        color: #9696b9;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 50%;
        .wrapper {
          // width: 106%;
        }
      }

      &__description {
        height: 60px;
        margin-bottom: 15px;
        font-weight: 300;
        font-size: 14px;
        word-break: break-all;
        overflow: auto;
        color: $blue-primary;
      }

      &__question {
        height: 50px;
        overflow-y: auto;
        margin-bottom: 60px;
        color: $blue-primary;
      }

      &__options {
        max-height: 300px;
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;
        overflow-y: auto;
        padding-right: 20px;
      }

      &__option {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 10px;

        &-wrapper {
          display: flex;
          align-items: flex-start;
        }

        &-index {
          margin-right: 10px;
          font-weight: 500;
          color: #9696b9;
        }

        &-name {
          color: #283593;
          max-width: 490px;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 300;
        }

        &-checkbox {
          margin-left: auto;
          width: 16px;
          height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
          border: 1px solid #959bc9;
          background-color: transparent;
          cursor: pointer;

          &--selected {
            border: 1px solid #283593 !important;
          }

          svg {
            width: 12px;
            height: 10px;
          }
        }
      }
      .poll-date {
        text-align: right;
        padding-right: 10px;
        margin-bottom: 10px;
        color: $blue-primary;
      }

      &__button {
        cursor: pointer;
        margin-top: 105px;
        margin-left: auto;
        background-color: #959bc9;
        color: white;
        border-radius: 6px;
        width: 145px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.upload-button {
  cursor: pointer;
  width: 197 * $rem;
  display: inline-block;
  height: 40 * $rem;
  line-height: 40 * $rem;
  padding: 0 15 * $rem 0 15 * $rem;
  outline: none;
  text-align: center;
  color: white;
  margin-top: 10px;
  border-radius: 5 * $rem;
  box-shadow: none;
  background-color: #1de9b6;
}

.padding-wrapper {
  height: 100%;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.cstm-input {
  background-color: transparent;
  border: 0;
  font-size: 14px;
  width: 100%;
  color: #283593;

  &::placeholder {
    color: #9696b9;
  }

  &:focus {
    outline: 0;
  }

  &--title {
    color: white;
    font-weight: 500;
    font-size: 16px;

    &::placeholder {
      color: white;
    }
  }

  &--question {
    font-size: 16px;
    height: 45px;
    word-break: break-word;
    overflow: auto;
    margin-bottom: 20px;
  }

  &--description {
    color: white;
    margin-top: 20px;

    &::placeholder {
      color: white;
    }
  }

  &--option {
    width: 700px;
    margin-left: 10px;
    font-weight: lighter;
    font-size: 13px;
    text-overflow: ellipsis;
  }
}

.export-excel {
  cursor: pointer;
  width: 7rem;
  height: 2.5rem;
  border-radius: 5px;
  background-color: rgba(40, 53, 147, 0.6);
  color: white;
  transition: 0.2s ease;
  position: absolute;
  top: 1.2rem;
  right: 4.6rem;
}

.wrapper-button {
  margin: 10px 0 0 auto;
  display: flex;
  align-items: center;
  .button {
    border: 0;
  }
}
</style>
