<template>
  <div class="tabs">
    <ul>
      <li>
        <router-link
          tag="span"
          :to="{ path: '/Traffic' }"
          class="history-button"
        >
          Details
        </router-link>
      </li>

      <li>
        <router-link
          tag="span"
          :to="{ path: '/TrafficHistory' }"
          class="history-button"
        >
          History
        </router-link>
      </li>

      <li>
        <span class="traffic__workspace">
          <router-link to="TrafficWorkspace" tag="span">
            Workspace
          </router-link>
        </span>
      </li>
      <li>
        <span class="traffic__requests">
          <router-link to="TrafficRequests" tag="span">
            <!-- <img
                    :src="require('@/assets/icons/red/icon_notification_red.svg')"
                    alt=""
                /> -->
            Requests
          </router-link>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2.0375rem;

  li {
    cursor: pointer;
    margin-left: 1.875rem;
    font-size: 1.0625rem;
    color: #283593;
    border-bottom: solid 0.1875rem transparent;
    padding-bottom: 5px;
    position: relative;

    span.router-link-active {
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1875rem;
        background: #1de9b6;
        transform: translateY(100%);
      }
    }

    img {
      width: 0.9375rem;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}
</style>>