<template>
  <div
    class="flexible-schedule"
    :class="{ background: !noEdit }"
    @click="dragging = false"
  >
    <custom-loader v-if="!schedulesLoaded" />
    <div class="schedule" @click.stop="closePaste">
      <div
        class="left-scroll"
        @wheel.prevent
        @touchmove.prevent
        @scroll.prevent
        ref="leftSide"
      >
        <div class="left-side">
          <div class="persons-header">
            <select-persons
              ref="selectPerson"
              v-if="!noEdit"
              :withoutDeps="true"
              :initialSelectedPersons="visiblePeople"
              :departments="departmentPeople"
              @select="(people) => (visiblePeople = [...people])"
            />
          </div>
          <div
            class="job"
            v-for="(job, index) in peopleGroupedInJobs"
            :key="job.jobID"
          >
            <!-- :style="{ transform: `translateY(-${tableScrollTop}px)` }" -->
            <div
              class="job-name"
              v-show="job.persons.some((p) => personIsVisible(p))"
              :class="{ 'dif-background': index % 2 }"
              :style="{
                height:
                  job.persons.filter((p) => personIsVisible(p)).length * 3.4 +
                  'rem',
              }"
            >
              <span>{{ job.jobName }}</span>
            </div>
            <div class="job-name-tooltip">
              <div class="triangle"></div>
              <span>{{ job.jobName }}</span>
            </div>
            <div class="names">
              <div
                class="name"
                v-for="person in job.persons"
                v-show="personIsVisible(person)"
                :key="person.PersonID"
                :class="{ 'odd-color': personIsOdd(person) }"
              >
                {{ person.FirstName + ' ' + person.LastName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-wrapper" ref="tableWrapper">
        <div
          class="table"
          ref="table"
          @scroll="tableScrollHandler"
          :class="{ 'no-edit': noEdit || noEditFromData }"
          @mouseup="mouseUpHandler"
        >
          <div
            class="day"
            v-for="day in visibleDaysInCalendar"
            :key="day.getTime()"
          >
            <div
              class="day-description"
              :class="{ today: isToday(day) }"
              draggable="false"
            >
              {{ getDayString(day) }}
            </div>
            <div v-for="job in peopleGroupedInJobs" :key="job.jobID">
              <div
                class="cell"
                v-for="person in job.persons"
                v-show="personIsVisible(person)"
                :key="person.PersonID"
                @click="cellClickHandler(person, day)"
                :class="{
                  'odd-color': personIsOdd(person),
                }"
                @contextmenu.prevent="rightClickHandler(person, day, $event)"
                @mousedown="mouseDownHandler(person, day, $event)"
                @mousemove="mouseMoveHandler(day, $event)"
              >
                <div
                  class="day-schedule"
                  :class="{
                    night: isNightTime(person, day),
                  }"
                  v-if="
                    person.ScheduleDays && hasScheduleInThisDay(person, day)
                  "
                  :style="{
                    width: dayScheduleWidth(person, day) + 'px',
                    left: dayScheduleLeftOffset(person, day) + 'px',
                  }"
                >
                  <span class="time">
                    {{
                      scheduleString(person, day, dayScheduleWidth(person, day))
                    }}
                  </span>
                  <div class="day-schedule-wrapper">
                    <div class="actions">
                      <i
                        class="material-icons"
                        @click="editClickHandler(person, day)"
                      >
                        create
                      </i>
                      <i
                        class="material-icons"
                        @click="copyHandler(person, day)"
                      >
                        content_copy
                      </i>
                      <i
                        class="material-icons"
                        @click="deleteHandler(person, day)"
                      >
                        close
                      </i>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    cycle.isDragging &&
                    cycle.day == day.getTime() &&
                    cycle.personID == person.PersonID &&
                    cycle.multiplier > 0
                  "
                  click.stop
                  class="cycle-overlay"
                  :style="{
                    width: `calc(${cycle.multiplier} * 101%)`,
                  }"
                ></div>
              </div>
            </div>
          </div>
          <div
            class="totals"
            @wheel.prevent
            @touchmove.prevent
            @scroll.prevent
            ref="totals"
          >
            <div v-for="job in peopleGroupedInJobs" :key="job.jobID">
              <!-- :style="{ transform: `translateY(-${tableScrollTop}px)` }" -->
              <div
                class="total"
                v-for="person in job.persons"
                v-show="personIsVisible(person)"
                :key="person.PersonID"
              >
                {{ getMonthlyTotalForPerson(person) }}
              </div>
            </div>
          </div>
          <div
            v-if="cycle.isDragging"
            class="left scroller-for-dragging"
            @mouseenter="startSlowlyScrollingLeft"
            @mouseleave="stopSlowlyScrolling"
          ></div>
          <div
            v-if="cycle.isDragging"
            class="right scroller-for-dragging"
            @mouseenter="startSlowlyScrollingRight"
            @mouseleave="stopSlowlyScrolling"
          ></div>
          <div
            class="paste"
            v-show="showPaste"
            ref="paste"
            @click="pasteHandler"
          >
            Paste
          </div>
        </div>
      </div>
      <div
        class="cycle-indicator-wrapper"
        ref="cycleIndicatorWrapper"
        v-show="!noEdit"
      >
        <div v-for="job in peopleGroupedInJobs" :key="job.jobID">
          <!-- :style="{ transform: `translateY(-${tableScrollTop}px)` }" -->
          <i
            class="cycle-indicator material-icons"
            v-for="person in job.persons"
            v-show="personIsVisible(person)"
            :key="person.PersonID"
            :class="{
              'not-visible':
                cycles.filter((c) => c.Person == person.PersonID).length == 0,
            }"
            @click="showCycleInfo(person.PersonID)"
          >
            history
          </i>
        </div>
      </div>
      <create-flexible-schedule
        :visible="createScheduleVisible"
        @close="createScheduleVisible = false"
        @create="createHandler"
      />
      <edit-flexible-schedule
        v-if="selectedSchedule"
        :visible="editScheduleVisible"
        @edit="(data) => createHandler(data, true)"
        @close="editScheduleVisible = false"
        :startHr="selectedScheduleStartHour"
        :startMm="selectedScheduleStartMinute"
        :endHr="selectedScheduleEndHour"
        :endMm="selectedScheduleEndMinute"
        :breakTime="selectedScheduleBreak"
      />
    </div>
    <custom-popup
      :visible="cycleInfo.popupVisible"
      @close="cycleInfo.popupVisible = false"
      title="Current Cycle Info"
    >
      <div class="start">
        <span>Start Date: {{ cycleStartString }}</span>
      </div>
      <div class="btn stop" @click="stopCurrentCycle">Stop Cycle</div>
      <div
        class="days"
        :style="{ height: `calc(48px * ${peopleCount} + '172px')` }"
      >
        <div class="day" v-for="(day, index) in cycleInfo.days" :key="index">
          <span>Day {{ index + 1 }}</span>
          <span class="time">{{ cycleDayToString(day) }}</span>
        </div>
      </div>
      <p>
        * The cycle will be automatically updated, permanently for the future
        period.
      </p>
      <p>
        * The command of editing or deleting the schedule in the cycle, will
        only conduct the change for the selected date.
      </p>
    </custom-popup>
  </div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance'
import CreateFlexibleSchedule from './CreateFlexibleSchedule'
import EditFlexibleSchedule from './EditFlexibleSchedule'
import SelectPersons from '@/components/FixedSchedules/SelectPersons'
import CustomPopup from '@/components/ReusableComponents/CustomPopup/CustomPopup'
import { debounce } from '@/Helpers/HelperFunctions'
import { showSuccess } from '@/Helpers/Messages'
import { getDateString } from '@/Helpers/HelperFunctions'
import CustomLoader from '@/components/ReusableComponents/Loader/CustomLoader.vue'

const startHourInCell = 0
export default {
  components: {
    CreateFlexibleSchedule,
    EditFlexibleSchedule,
    SelectPersons,
    CustomPopup,
    CustomLoader,
  },
  props: {
    noEdit: Boolean,
    employeeID: Number,
  },
  created() {
    this.getPersons().then(() => {
      this.getSchedulesWithinThePeriod(true)
      this.getCycles()
    })
    this.getFlexiblePeople()
    this.setDefaultCalendarDates()
    document.addEventListener('click', this.closePaste)
  },
  data() {
    return {
      tableScrollTop: 0,
      showingCalendarStartDate: 0,
      scrollPosition: '',
      showingCalendarToDate: 0,
      createScheduleVisible: false,
      editScheduleVisible: false,
      people: [],
      clickedDay: '',
      clickedPerson: '',
      selectedSchedule: null,
      interval: null,
      visiblePeople: [],
      leftmostVisibleDayMonth: 0,
      monthlyTotals: [],
      showPaste: false,
      copiedSchedule: null,
      pasteDay: null,
      pastePerson: null,
      departmentPeople: [],
      schedulesLoaded: false,
      cycle: {
        isDragging: false,
        start: 0,
        positionLeft: 0,
        multiplier: 0,
        day: null,
        personID: 0,
      },
      noEditFromData: false,
      cycles: [],
      cycleInfo: {
        popupVisible: false,
        days: [],
        cycleStart: 0,
        personID: 0,
      },
      autoScrolling: {
        interval: null,
      },
    }
  },
  watch: {
    scrollPosition() {
      this.$refs.totals.scrollTop = this.scrollPosition
      this.$refs.leftSide.scrollTop = this.scrollPosition
      this.$refs.table.scrollTop = this.scrollPosition
      this.$refs.cycleIndicatorWrapper.scrollTop = this.scrollPosition
    },
  },
  methods: {
    formatTime(time) {
      return (
        ('0' + time.getHours()).slice(-2) +
        ':' +
        ('0' + time.getMinutes()).slice(-2) +
        ':'
      )
    },
    getFlexiblePeople() {
      axios
        .get('Schedules/GetFlexibleSchedulesDropDownPersons')
        .then((resp) => {
          let data = [
            {
              id: 777,
              name: '',
              jobs: resp.data.Value.JobPersons.map((el) => {
                return {
                  id: el.JobID,
                  name: el.JobName,
                  people: el.Persons.map((pers) => {
                    return {
                      name: pers.FirstName + ' ' + pers.LastName,
                      id: pers.PersonID,
                    }
                  }),
                }
              }),
            },
          ]

          this.departmentPeople = data
        })
    },
    // getDepartmentJobs() {
    //   axios.get("departments/GetDepartmentPeople").then((resp) => {
    //     if (resp.data.ErrorMsg) return;
    //     let departments = [];
    //     resp.data.Value.forEach((dep) => {
    //       let depId = dep.DepartmentID;
    //       let department = departments.filter((el) => el.id == depId)[0];
    //       if (!department) {
    //         department = {
    //           id: depId,
    //           name: dep.DepartmentName,
    //           jobs: [],
    //         };
    //         departments.push(department);
    //       }
    //       let jobid = dep.JobID;
    //       let job = department.jobs.filter((el) => el.id == jobid)[0];
    //       if (!job) {
    //         department.jobs.push({
    //           id: jobid,
    //           name: dep.JobName,
    //           people: [],
    //         });
    //       }
    //       department.jobs.forEach((el) => {
    //         if (el.id === dep.JobID) {
    //           if (!dep.HasFixedSchedule) {
    //             el.people.push({
    //               id: dep.PersonID,
    //               name: dep.FirstName + " " + dep.LastName,
    //             });
    //           }
    //         }
    //       });
    //     });
    //     let withoutDep = {
    //       id: 0,
    //       name: "Jobs without department",
    //       jobs: [],
    //     };
    //     axios.get("departments/GetJobPersonsWithoutDepartment").then((resp) => {
    //       resp.data.Value.forEach((el) => {
    //         let jobid = el.JobID;
    //         let job = withoutDep.jobs.filter((job) => jobid == job.id)[0];
    //         if (!job && !el.HasFixedSchedule) {
    //           withoutDep.jobs.push({
    //             id: el.JobID,
    //             name: el.JobName,
    //             people: [],
    //           });
    //         }
    //         withoutDep.jobs.forEach((job) => {
    //           if (job.id === el.JobID) {
    //             job.people.push({
    //               id: el.ID,
    //               name: el.FirstName + " " + el.LastName,
    //             });
    //           }
    //         });
    //       });
    //     });

    //     departments.push(withoutDep);

    //     this.departmentPeople = departments;
    //   });
    // },
    diff(start, end) {
      start = start.split(':')
      end = end.split(':')
      var startDate = new Date(0, 0, 0, start[0], start[1], 0)
      var endDate = new Date(0, 0, 0, end[0], end[1], 0)
      var diff = endDate.getTime() - startDate.getTime()
      var hours = Math.floor(diff / 1000 / 60 / 60)
      diff -= hours * 1000 * 60 * 60
      var minutes = Math.floor(diff / 1000 / 60)
      // If using time pickers with 24 hours format, add the below line get exact hours
      if (hours < 0) hours = hours + 24
      return (
        (hours <= 9 ? '0' : '') +
        hours +
        ':' +
        (minutes <= 9 ? '0' : '') +
        minutes
      )
    },
    startSlowlyScrollingLeft() {
      const table = this.$refs.table
      const scrollStep = 5
      this.autoScrolling.interval = setInterval(() => {
        table.scrollLeft -= scrollStep
      }, 10)
    },
    startSlowlyScrollingRight() {
      const table = this.$refs.table
      const scrollStep = 5
      this.autoScrolling.interval = setInterval(() => {
        table.scrollLeft += scrollStep
      }, 10)
    },
    stopSlowlyScrolling() {
      clearInterval(this.autoScrolling.interval)
    },
    mouseDownHandler(person, day, e) {
      if (e.which == 2) return //middleclick
      this.cycle.isDragging = true
      var schedule = this.getScheduleWithPersonAndDay(person, day)
      if (schedule) this.cycle.start = schedule.ScheduleStartTime
      else this.cycle.start = day.getTime()
      this.cycle.positionLeft = this.dayScheduleLeftOffset(person, day)
      this.cycle.multiplier = 0
      this.cycle.day = day.getTime()
      this.cycle.personID = person.PersonID
    },
    mouseUpHandler() {
      const clear = () => {
        this.cycle.isDragging = false
        this.cycle.multiplier = 0
        this.cycle.positionLeft = 0
        this.cycle.start = 0
        this.cycle.day = null
        this.cycle.personID = 0
        this.noEditFromData = false
      }
      if (this.cycle.multiplier > 0) {
        this.noEditFromData = true
        this.$confirm('Would you like to cycle selected period?')
          .then(() => {
            this.noEditFromData = false
            axios
              .post('Schedules/CreateFlexibleScheduleCycle', {
                Person: this.cycle.personID,
                From: this.cycle.start,
                To: new Date(this.cycle.start).setDate(
                  new Date(this.cycle.start).getDate() + this.cycle.multiplier,
                ),
              })
              .then((resp) => {
                if (resp.data.ErrorMsg) return
                showSuccess('Schedule Cycle Was Successfull!')
                this.getSchedulesWithinThePeriod()
                this.getCycles()
                clear()
              })
          })
          .catch(clear)
      } else clear()
    },
    mouseMoveHandler(day, e) {
      if (!this.cycle.isDragging) return
      var dayDiffInMs = day.getTime() - this.cycle.day
      if (dayDiffInMs <= 0) {
        this.multiplier = 0
      } else {
        this.cycle.multiplier = dayDiffInMs / (24 * 60 * 60 * 1000)
      }
    },
    pasteHandler() {
      const adjust = (date) => date - new Date().getTimezoneOffset() * 60000 // adjusting with offset
      var startTime = new Date(adjust(this.copiedSchedule.ScheduleStartTime))
      var endTime = new Date(adjust(this.copiedSchedule.ScheduleEndTime))
      var scheduleStart = new Date(this.pasteDay).setHours(
        startTime.getHours(),
        startTime.getMinutes(),
        0,
        0,
      )
      var scheduleEnd = new Date(this.pasteDay)
      if (startTime.getHours() > endTime.getHours()) {
        scheduleEnd.setDate(scheduleEnd.getDate() + 1)
      }
      scheduleEnd = scheduleEnd.setHours(
        endTime.getHours(),
        endTime.getMinutes(),
        0,
        0,
      )
      if (
        new Date(scheduleStart).getHours() * 60 +
          new Date().getTimezoneOffset() <
        0
      ) {
        scheduleStart = new Date(scheduleStart).setDate(
          new Date(scheduleStart).getDate() + 1,
        )
        scheduleEnd = new Date(scheduleEnd).setDate(
          new Date(scheduleEnd).getDate() + 1,
        )
      }
      this.postCreate(
        this.pastePerson.PersonID,
        scheduleStart,
        scheduleEnd,
        this.copiedSchedule.BreakEnd - this.copiedSchedule.BreakStart,
      )
    },
    closePaste() {
      this.$refs.selectPerson.collapsed = false
      this.showPaste = false
    },
    copyHandler(person, day) {
      this.closePaste()
      var schedule = this.getScheduleWithPersonAndDay(person, day)
      this.copiedSchedule = schedule
    },
    rightClickHandler(person, day, e) {
      var schedule = this.getScheduleWithPersonAndDay(person, day)
      if (schedule || !this.copiedSchedule) return
      this.closePaste()
      this.pasteDay = day
      this.pastePerson = person
      this.showPaste = true
      var containerRect = this.$refs.tableWrapper.getBoundingClientRect()
      var clickX = Math.floor(e.clientX - containerRect.x)
      var clickY = Math.floor(e.clientY - containerRect.y)
      this.$refs.paste.style.left = `${clickX}px`
      this.$refs.paste.style.top = `${clickY}px`
    },
    personIsVisible(person) {
      this.peopleIDs = this.visiblePeople
      return this.visiblePeople.includes(person.PersonID + '')
    },
    personIsOdd(person) {
      var res = false
      let ct = 0
      this.peopleGroupedInJobs.forEach((job) => {
        job.persons.forEach((p) => {
          if (!this.personIsVisible(p)) return
          if (p.PersonID == person.PersonID) res = ct % 2 ? true : false
          ct++
        })
      })
      return res
    },
    getDayString(date) {
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      const days = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']
      return `${days[(date.getDay() + 6) % 7]} ${date.getDate()} ${
        months[date.getMonth()]
      }`
    },
    getPersons() {
      if (this.employeeID) {
        return axios
          .get('Schedules/GetPersonDepartmentFlexibleSchedules', {
            params: { Person: this.employeeID },
          })
          .then((resp) => {
            this.people = resp.data.Value
            this.visiblePeople = resp.data.Value.map((el) => el.PersonID + '')
          })
      } else {
        return axios
          .get('Schedules/GetJobPersonsWithoutFixedSchedule')
          .then((resp) => {
            if (resp.data.ErrorMsg) return
            this.people = resp.data.Value
            this.visiblePeople = resp.data.Value.map((el) => el.PersonID + '')
          })
      }
    },
    setDefaultCalendarDates() {
      var starting = new Date()
      starting.setDate(starting.getDate() - 8) // 8 past days -> today -> 12 future days
      starting.setHours(0)
      starting.setMinutes(0)
      starting.setSeconds(0)
      this.showingCalendarStartDate = starting.getTime()
      var ending = new Date()
      ending.setDate(ending.getDate() + 12) // 8 past days -> today -> 12 future days
      ending.setHours(23)
      ending.setMinutes(59)
      ending.setSeconds(59)
      this.showingCalendarToDate = ending.getTime()
    },
    getSchedulesWithinThePeriod(scrollRightABit) {
      axios
        .post('Schedules/GetFlexibleScheduleDays', {
          From: this.showingCalendarStartDate,
          To: this.showingCalendarToDate,
          PersonTVP: this.peopleIDs.map((id) => ({ ID: id })),
        })
        .then((resp) => {
          if (resp.data.ErrorMsg) return
          resp.data.Value.PersonSchedules.forEach((p) => {
            var person = this.people.find((el) => el.PersonID == p.Person)
            person.ScheduleDays = p.ScheduleDays
            var index = this.people.indexOf(person)
            this.$set(this.people, index, person)
            this.schedulesLoaded = true
          })
          let table = this.$refs.table
          if (scrollRightABit) {
            this.$nextTick(() => {
              let startDays = this.showingCalendarStartDate / 86400000
              let endDays = this.showingCalendarToDate / 86400000
              let ratio = 7 / (endDays - startDays)
              table.scrollLeft = table.scrollWidth * ratio
              this.registerScrollListener()
            })
          }
        })
    },
    registerScrollListener() {
      this.$refs.table.addEventListener(
        'scroll',
        debounce((e) => {
          var currentDayCount = Math.ceil(
            (this.showingCalendarToDate - this.showingCalendarStartDate) /
              (3600000 * 24),
          ) //days in calendar
          var day = parseInt(
            this.$refs.table.scrollLeft /
              (this.$refs.table.scrollWidth / currentDayCount),
          ) // leftmost day which is visible in table
          let oldMonth = this.leftmostVisibleDayMonth
          var startDate = new Date(this.showingCalendarStartDate)
          var visibleDayDate = startDate.setDate(startDate.getDate() + day)
          this.leftmostVisibleDayMonth = new Date(visibleDayDate).getMonth() + 1
          if (oldMonth != this.leftmostVisibleDayMonth) this.getTotals()
        }, 500),
      )
    },
    getTotals() {
      axios
        .post('Schedules/GetFlexibleSchedulesMonthlyTime', {
          Month: this.leftmostVisibleDayMonth,
          TVP: this.peopleIDs.map((id) => ({ PersonID: id })),
        })
        .then((resp) => {
          if (resp.data.ErrorMsg) return
          this.monthlyTotals = resp.data.Value
        })
    },
    getMonthlyTotalForPerson(person) {
      var total = this.monthlyTotals.find((el) => el.Person == person.PersonID)
      return total
        ? `${parseInt(total.WholeTimeInMonth / 60)} h` //was in minutes, we need hours
        : ''
    },
    getScheduleWithPersonAndDay(person, day) {
      if (!person.ScheduleDays) return null
      var nextDay = new Date(day)
      nextDay.setDate(day.getDate() + 1)
      nextDay.setHours(0, 0, 0, 0)
      return person.ScheduleDays.filter((el) => {
        day.setSeconds(0)
        day.setMilliseconds(0)
        return (
          el.ScheduleStartTime >= day.getTime() &&
          el.ScheduleStartTime < nextDay.getTime()
        )
      })[0]
    },
    isNightTime(person, day) {
      var schedule = this.getScheduleWithPersonAndDay(person, day)
      if (!schedule) return false
      var startHour = new Date(schedule.ScheduleStartTime).getHours()
      var endTime = new Date(schedule.ScheduleEndTime).getHours()
      return startHour > endTime && endTime !== 0
    },
    hasScheduleInThisDay(person, day) {
      var schedule = this.getScheduleWithPersonAndDay(person, day)
      if (schedule) return true
      return false
    },
    dayScheduleWidth(person, day) {
      const schedule = this.getScheduleWithPersonAndDay(person, day)
      if (!schedule) return 0
      //adjust start
      const startTime = schedule.ScheduleStartTime
      //adjust end
      const endTime = schedule.ScheduleEndTime
      const startTimeString = this.formatTime(new Date(startTime))
      const endDateString = this.formatTime(new Date(endTime))
      const timeToDecimal = this.timeToDecimal(
        this.diff(startTimeString, endDateString),
      )
      return timeToDecimal > 5 ? timeToDecimal * 10 : 5 * 10
    },
    dayScheduleLeftOffset(person, day) {
      var schedule = this.getScheduleWithPersonAndDay(person, day)
      if (!schedule) return 0
      var startTime = schedule.ScheduleStartTime
      var date = new Date(startTime)
      var timeToDecimal = this.timeToDecimal(this.formatTime(date))
      return timeToDecimal > 5 ? timeToDecimal * 10 : 5 * 10
      // 24 hours
      // 1 hour - 10px
      // 10:00 - 18:00 - 80px
      // 15.5px
      // 15:30
    },
    scheduleString(person, day, time) {
      var schedule = this.getScheduleWithPersonAndDay(person, day)
      if (!schedule) return 0
      var start = new Date(schedule.ScheduleStartTime)
      var end = new Date(schedule.ScheduleEndTime)
      const double = (str) => ('0' + str).slice(-2)
      return (
        ('0' + start.getHours()).slice(-2) +
        ':' +
        ('0' + double(start.getMinutes())).slice(-2) +
        `${time > 70 ? ' - ' : '\n'}` +
        ('0' + end.getHours()).slice(-2) +
        ':' +
        ('0' + double(end.getMinutes())).slice(-2)
      )
    },
    timeToDecimal(t) {
      var arr = t.split(':')
      var dec = parseInt((arr[1] / 6) * 10, 10)
      return parseFloat(
        parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec,
      )
    },
    isToday(day) {
      var d = new Date()
      return (
        d.getFullYear() == day.getFullYear() &&
        d.getMonth() == day.getMonth() &&
        d.getDate() == day.getDate()
      )
    },
    cellClickHandler(person, day) {
      if (this.cycle.isDragging) return
      var schedule = this.getScheduleWithPersonAndDay(person, day)
      this.clickedDay = day
      this.clickedPerson = person.PersonID
      if (!schedule) {
        this.createScheduleVisible = true
      }
    },
    createHandler(data, isEdit = false) {
      var startDate = new Date(this.clickedDay)
      startDate.setHours(data.startHour)
      startDate.setMinutes(data.startMinute)
      var endDate = new Date(this.clickedDay)
      if (data.startHour > data.endHour) endDate.setDate(endDate.getDate() + 1)
      endDate.setHours(data.endHour)
      endDate.setMinutes(data.endMinute)
      startDate.setHours(
        startDate.getHours() - new Date().getTimezoneOffset() / 60,
      )
      endDate.setHours(endDate.getHours() - new Date().getTimezoneOffset() / 60)
      startDate = startDate.getTime()
      endDate = endDate.getTime()
      if (!isEdit) {
        this.postCreate(
          this.clickedPerson,
          startDate,
          endDate,
          data.break * 60000,
        )
      } else {
        axios
          .post('Schedules/DeleteFlexibleScheduleDay', {
            FlexibleScheduleDayID: this.selectedSchedule.ScheduleDayID,
          })
          .then((resp) => {
            if (resp.data.ErrorMsg) return
            this.postCreate(
              this.clickedPerson,
              startDate,
              endDate,
              data.break * 60000,
            )
          })
      }
    },
    postCreate(person, start, end, breakTime) {
      axios
        .post('Schedules/CreateFlexibleScheduleDay', {
          Person: person,
          ScheduleStartTime: start,
          ScheduleEndTime: end,
          BreakStart: start,
          BreakEnd: start + breakTime, // settings BreakEnd to get the total of "breakTime" minutes
        })
        .then((resp) => {
          if (resp.data.ErrorMsg) return
          this.getSchedulesWithinThePeriod()
          this.getCycles()
          this.createScheduleVisible = false
          this.editScheduleVisible = false
          this.getTotals()
        })
    },
    deleteHandler(person, day) {
      var schedule = this.getScheduleWithPersonAndDay(person, day)

      if (!schedule) return
      this.$confirm('Would you like to delete this schedule?').then(() => {
        axios
          .post('Schedules/DeleteFlexibleScheduleDay', {
            FlexibleScheduleDayID: schedule.ScheduleDayID,
          })
          .then((resp) => {
            if (resp.data.ErrorMsg) return
            showSuccess('Schedule has been successfully deleted!')
            this.getSchedulesWithinThePeriod()
            this.getCycles()
            this.createScheduleVisible = false
            this.editScheduleVisible = false
            this.getTotals()
          })
      })
    },
    tableScrollHandler(event) {
      var table = event.target
      if (table.scrollLeft == 0) {
        var currentStartTime = new Date(this.showingCalendarStartDate)
        currentStartTime.setDate(currentStartTime.getDate() - 7)
        this.showingCalendarStartDate = currentStartTime.getTime()
        this.getSchedulesWithinThePeriod(true)
      }
      if (table.scrollWidth < table.offsetWidth + table.scrollLeft) {
        var currentToTime = new Date(this.showingCalendarToDate)
        currentToTime.setDate(currentToTime.getDate() + 7)
        this.showingCalendarToDate = currentToTime.getTime()
        this.getSchedulesWithinThePeriod()
      }
      this.scrollPosition = table.scrollTop

      //scrollTop
      // this.tableScrollTop = event.target.scrollTop;
    },
    editClickHandler(person, day) {
      var schedule = this.getScheduleWithPersonAndDay(person, day)
      this.editScheduleVisible = true
      this.selectedSchedule = schedule
    },
    getCycles() {
      let object = this.peopleIDs.map((id) => ({ PersonID: id }))
      axios
        .post('Schedules/GetFlexibleScheduleCycle', {
          PersonsTVP: object,
        })
        .then((resp) => {
          if (resp.data.ErrorMsg) return
          this.cycles = resp.data.Value.PersonCycles
        })
    },
    showCycleInfo(personID) {
      var days = []
      var cycleToShowInfoOf = this.cycles.filter((c) => c.Person == personID)[0]
      var cycleFrom = new Date(cycleToShowInfoOf.CycleFrom)
      cycleFrom.setMinutes(
        cycleFrom.getMinutes() + cycleFrom.getTimezoneOffset(),
      )
      var cycleTo = new Date(cycleToShowInfoOf.CycleTo)
      cycleTo.setMinutes(cycleTo.getMinutes() + cycleTo.getTimezoneOffset())
      let dayInMs = 24 * 60 * 60 * 1000
      for (let i = cycleFrom.getTime(); i < cycleTo.getTime(); i += dayInMs) {
        let day = cycleToShowInfoOf.Days.filter(
          (d) => d.ScheduleStartTime >= i && d.ScheduleStartTime < i + dayInMs,
        )[0]
        if (day) {
          days.push({ start: day.ScheduleStartTime, end: day.ScheduleEndTime })
        } else {
          days.push({ start: null, end: null })
        }
      }
      this.cycleInfo.days = days
      this.cycleInfo.popupVisible = true
      this.cycleInfo.cycleStart = cycleFrom
      this.cycleInfo.personID = personID
    },
    cycleDayToString(day) {
      if (!day.start || !day.end) return 'Empty'
      const double = (str) => ('0' + str).slice(-2)
      return `${double(new Date(day.start).getHours())}:${double(
        new Date(day.start).getMinutes(),
      )} -
                    ${double(new Date(day.end).getHours())}:${double(
        new Date(day.end).getMinutes(),
      )}`
    },
    stopCurrentCycle(id) {
      this.$confirm('Do you really want to end the current cycle?').then(() => {
        axios
          .post('Schedules/DeleteFlexibleScheduleCycle', {
            PersonID: this.cycleInfo.personID,
          })
          .then((resp) => {
            if (resp.data.ErrorMsg) return
            this.cycleInfo.popupVisible = false
            this.cycleInfo.cycleStart = 0
            this.cycleInfo.personID = 0
            this.cycleInfo.days = []
            this.getCycles()
          })
      })
    },
  },
  computed: {
    peopleCount() {
      return this.personsArrayForSelectComponent.reduce(
        (count, el) => (count += el.people.length),
        0,
      )
    },
    personsArrayForSelectComponent() {
      return this.peopleGroupedInJobs.map((job) => {
        return {
          name: job.jobName,
          people: job.persons.map((p) => ({
            id: p.PersonID,
            name: p.FirstName + ' ' + p.LastName,
          })),
        }
      })
    },
    peopleGroupedInJobs() {
      var grouped = []
      this.people.forEach((person) => {
        var group = grouped.find((el) => el.jobID == person.JobID)
        if (!group) {
          group = {
            jobID: person.JobID,
            jobName: person.JobName,
            persons: [],
          }
          grouped.push(group)
        }
        group.persons.push(person)
      })
      return grouped
    },
    visibleDaysInCalendar() {
      var days = []
      var startDay = new Date(this.showingCalendarStartDate)
      var toDay = new Date(this.showingCalendarToDate)
      for (
        let d = startDay;
        d.getTime() <= toDay.getTime();
        d.setDate(d.getDate() + 1)
      ) {
        days.push(new Date(d))
      }
      return days
    },
    selectedScheduleStartHour() {
      if (!this.selectedSchedule) return 0
      return new Date(this.selectedSchedule.ScheduleStartTime).getHours()
    },
    selectedScheduleStartMinute() {
      if (!this.selectedSchedule) return 0
      return new Date(this.selectedSchedule.ScheduleStartTime).getMinutes()
    },
    selectedScheduleEndHour() {
      if (!this.selectedSchedule) return 0
      return new Date(this.selectedSchedule.ScheduleEndTime).getHours()
    },
    selectedScheduleEndMinute() {
      if (!this.selectedSchedule) return 0
      return new Date(this.selectedSchedule.ScheduleEndTime).getMinutes()
    },
    selectedScheduleBreak() {
      if (!this.selectedSchedule) return 0
      return (
        (this.selectedSchedule.BreakEnd - this.selectedSchedule.BreakStart) /
        60000
      )
    },
    haveNonZeroSchedules() {
      return this.people.length
    },
    // peopleIDs() {
    //   return this.people.map((el) => el.PersonID)
    // },
    cycleStartString() {
      return getDateString(this.cycleInfo.cycleStart, 'ww dd MMM yyyy')
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closePaste)
  },
}
</script>

<style scoped lang="scss">
$cell-width: 240 * $rem;
$cell-height: 54.4 * $rem;
$header-height: 72 * $rem;
$border-color: #dedff4;
$container-border-rad: 10 * $rem;

.background {
  padding: 1.25rem 1.75rem 0 1.25rem;
}
.flexible-schedule {
  color: $grey-text;
  position: relative;
  overflow: hidden;
  height: 100%;
  overflow: hidden;
  .loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .loader {
      margin: 20rem auto;
    }
  }
  .schedule {
    max-height: 100%;
    display: flex;
    position: relative;
    *,
    *::after,
    *::before {
      -webkit-user-select: none;
    }

    .left-side .job:nth-child(2) {
      margin-top: 72px;
    }

    .left-scroll::-webkit-scrollbar {
      display: none;
    }
    .left-scroll {
      overflow: scroll;
      max-height: 100%;
    }

    .left-side {
      border: $rem solid $border-color;
      width: 340 * $rem;
      border-right: none;
      background-color: white;
      border-top-left-radius: $container-border-rad;
      border-bottom-left-radius: $container-border-rad;
      margin-right: -100 * $rem;
      padding-right: 100 * $rem;

      .persons-header {
        height: $header-height;
        border-bottom: $rem solid $border-color;
        border-right: $rem solid $border-color;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        background-color: white;
        width: 12.5%;
        margin-top: -1px;

        position: fixed;
        ::v-deep .container {
          padding: 0;
          width: 180 * $rem;
          border: none;
          padding: 0 1.3rem;
          color: #9696b9;
          & > span {
            white-space: nowrap;
            overflow: hidden;
            direction: rtl;
          }
        }
        ::v-deep .tree {
          z-index: 40;
          background-color: white;
        }
      }
      .name {
        height: $cell-height;
        display: flex;
        align-items: center;
        width: 200 * $rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15 * $rem;
        font-weight: 300;
        background-color: #f1f1fb;
        color: $blue-primary;
        padding-left: 10 * $rem;
        &.odd-color {
          background-color: #f4f4fd;
        }
      }

      .job {
        display: flex;
        position: relative;

        .job-name-tooltip {
          position: absolute;
          top: 0.5rem;
          left: 3.125rem;
          background: rgba($blue-primary, 0.8);
          color: #fff;
          padding: 0.3125rem 1.5625rem;
          border-radius: 0.3125rem;
          font-size: 0.8125rem;
          display: none;
          z-index: 999;

          .triangle {
            $border-size: 8 * $rem;
            position: absolute;
            top: 12 * $rem;
            left: -12 * $rem;
            width: 0;
            height: 0;
            border-left: $border-size solid transparent;
            border-right: $border-size solid transparent;
            border-bottom: $border-size solid rgba($blue-primary, 0.7);
            transform: rotate(-90deg);
          }
        }

        .job-name {
          width: 44 * $rem;
          font-size: 0.72rem;
          color: rgba($blue-primary, 0.7);
          font-weight: 300;
          background-color: #d0d2ec;
          writing-mode: tb-rl;
          transform: rotate(-180deg);
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          overflow: hidden;

          span {
            display: inline-block;
            height: 60%;
            overflow: hidden;
          }

          &:hover {
            ~ .job-name-tooltip {
              display: block;
            }
          }

          &.dif-background {
            background-color: #d9daf1;
          }
        }
      }
    }
    .table-wrapper {
      width: calc(100% - 240 * #{$rem});
      position: relative;
      background-color: #fff;
      border-top-right-radius: $container-border-rad;
      border-bottom-right-radius: $container-border-rad;
      overflow: hidden;
      .table {
        overflow-x: auto;
        display: flex;
        border: $rem solid $border-color;
        border-left: none;
        margin-bottom: -13px;
        border-top-right-radius: $container-border-rad;
        border-bottom-right-radius: $container-border-rad;
        height: 100%;
        &.no-edit::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100% - 4 * #{$rem});
          z-index: 6;
          background-color: transparent;
        }
        .day {
          width: $cell-width;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          // justify-content: center;
          border-bottom: $rem solid $border-color;
          align-self: flex-start;
          .day-description {
            width: 100%;
            height: $header-height;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #9696b9;
            border-bottom: $rem solid $border-color;
            user-select: none;
            font-weight: 300;
            flex-shrink: 0;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 7;
            background-color: white;
            align-self: stretch;
            &.today {
              font-weight: 400;
            }
          }
          .cell {
            position: relative;
            height: $cell-height;
            width: $cell-width;
            cursor: pointer;
            border-right: 2 * $rem solid $border-color;
            background-color: #e7e7f9;
            .cycle-overlay {
              position: absolute;
              top: 0;
              height: 100%;
              background-color: rgba($blue-primary, 0.5);
              z-index: 5;
              border: 2 * $rem solid rgb(29, 233, 182);
            }
            &.odd-color {
              background-color: #ededfc;
            }
            &:last-child {
              border-bottom: none;
            }
            .day-schedule {
              position: relative;
              background-color: rgba($green-primary, 0.3);
              height: calc(100% - 4 * #{$rem});
              top: 2 * $rem;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              color: $blue-primary;
              user-select: none;
              font-size: 13 * $rem;
              border-radius: 3 * $rem;
              z-index: 5;
              font-weight: 300;
              cursor: initial;
              &:hover {
                .actions {
                  display: inline-flex;
                }
              }
              &.night {
                background-color: rgba($blue-primary, 0.5);
                color: white;
                &:before {
                  position: absolute;
                  content: '';
                  width: 12 * $rem;
                  height: 12 * $rem;
                  border-radius: 50%;
                  background-color: $green-primary;
                  top: 2 * $rem;
                  right: 4 * $rem;
                }
                &:after {
                  position: absolute;
                  content: '';
                  width: 13 * $rem;
                  height: 13 * $rem;
                  border-radius: 50%;
                  background-color: #868dc5;
                  top: 0 * $rem;
                  right: 7 * $rem;
                }
                i {
                  color: rgba(255, 255, 255, 0.774);
                }
              }
              .time {
                text-align: center;
              }
              .actions {
                position: absolute;
                display: inline-flex;
                display: none;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                top: 0px;
                right: -21px;
                i {
                  font-size: 16 * $rem;
                  cursor: pointer;
                  color: rgba($blue-primary, 0.6) !important;
                }
                i:nth-child(2) {
                  margin: 0 3px;
                }
                &--active {
                  display: inline-flex;
                }
              }
            }
          }
          &:last-child {
            border-right: none;
          }
        }
        .totals::-webkit-scrollbar {
          display: none;
        }
        .totals {
          background-color: rgba($blue-primary, 0.8);
          position: absolute;
          top: $header-height;
          right: 7 * $rem;
          width: 60 * $rem;
          height: calc(100% - #{$header-height});
          z-index: 6;
          overflow: scroll;
          .total {
            height: $cell-height;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16 * $rem;
            color: white;
            font-weight: 400;
          }
        }
        .scroller-for-dragging {
          z-index: 10;
          position: absolute;
          top: $header-height;
          width: 60 * $rem;
          height: calc(100% - #{$header-height});
          &.right {
            right: 0 * $rem;
            width: 120 * $rem;
          }
          &.left {
            left: 0;
          }
        }
        .paste {
          position: absolute;
          font-size: 14 * $rem;
          background-color: #baf3e8;
          padding: 8 * $rem 10 * $rem;
          border-radius: 3 * $rem;
          font-weight: 400;
          color: rgba($blue-primary, 0.6);
          cursor: pointer;
          z-index: 5;
          animation: fade-down 0.3s ease-out;
        }
      }
    }
    .cycle-indicator-wrapper::-webkit-scrollbar {
      display: none;
    }
    .cycle-indicator-wrapper {
      overflow: scroll;
      display: flex;
      flex-direction: column;
      margin-top: $header-height;
      // position: absolute;
      // right: -22 * $rem;

      .cycle-indicator {
        display: flex;
        height: $cell-height;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18 * $rem;
        cursor: pointer;
        &.not-visible {
          visibility: hidden;
        }
      }
    }
  }
  h2 {
    padding-top: 30 * $rem;
  }
}
.overlay {
  .days {
    margin-top: 60 * $rem;
    display: flex;
    height: 273 * $rem;
    overflow: auto;
    flex-wrap: wrap;
    align-content: flex-start;
    .day {
      width: calc(100% / 3);
      display: flex;
      margin-bottom: 20 * $rem;
      color: $blue-primary;
      font-weight: 500;
      align-items: center;
      justify-content: space-between;
      .time {
        color: $blue-primary;
        margin-right: 90 * $rem;
        width: 92 * $rem;
        font-weight: 300;
      }
    }
  }
  p {
    font-size: 15px;
    margin-bottom: 10px;
    font-style: italic;
    &:first-of-type {
      margin-top: 13 * $rem;
    }
  }
  .start {
    margin-top: -20 * $rem;
    color: $blue-primary;
  }
  .stop {
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff5f58;
    position: relative;
    top: -40 * $rem;
  }
}
</style>
