<template>
  <div class="background">
    <div class="activity">
      <slider-icon />
      <div class="activity-content">
        <div class="programmes">
          <h3>Programmes</h3>
          <div class="programmes-container">
            <div class="column">
              <div
                class="app-container"
                V
                :key="index"
                v-for="(App, index) in Apps"
              >
                <div :style="{ backgroundColor: App.color }" class="dot"></div>
                <span class="name" :style="{ color: App.color }">{{
                  App.name
                }}</span>
                <span class="time">{{ App.time }}</span>
              </div>
            </div>
            <div class="column">
              <div
                class="app-container"
                :key="index"
                v-for="(App, index) in Apps"
              >
                <div :style="{ backgroundColor: App.color }" class="dot"></div>
                <span class="name" :style="{ color: App.color }">{{
                  App.name
                }}</span>
                <span class="time">{{ App.time }}</span>
              </div>
            </div>
            <div class="column">
              <div
                class="app-container"
                :key="index"
                v-for="(App, index) in Apps"
              >
                <div :style="{ backgroundColor: App.color }" class="dot"></div>
                <span class="name" :style="{ color: App.color }">{{
                  App.name
                }}</span>
                <span class="time">{{ App.time }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="web-pages">
          <h3>Web Pages</h3>
          <div class="programmes-container">
            <div class="column">
              <div
                class="app-container"
                :key="index"
                v-for="(webPage, index) in webPages"
              >
                <div
                  :style="{ backgroundColor: webPage.color }"
                  class="dot"
                ></div>
                <span class="name">{{ webPage.name }}</span>
                <span class="time">{{ webPage.time }}</span>
              </div>
            </div>
            <div class="column">
              <div
                class="app-container"
                :key="index"
                v-for="(webPage, index) in webPages"
              >
                <div
                  :style="{ backgroundColor: webPage.color }"
                  class="dot"
                ></div>
                <span class="name">{{ webPage.name }}</span>
                <span class="time">{{ webPage.time }}</span>
              </div>
            </div>
            <div class="column">
              <div
                class="app-container"
                :key="index"
                v-for="(webPage, index) in webPages"
              >
                <div
                  :style="{ backgroundColor: webPage.color }"
                  class="dot"
                ></div>
                <span class="name">{{ webPage.name }}</span>
                <span class="time">{{ webPage.time }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rightside">
        <activity-calendar />
        <span>Total Active Hour</span>
        <div class="circle-div">
          <span>07 hr</span>
          <span>13 min</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SliderIcon from "@/components/Dashboard/SliderIcon";
import ActivityCalendar from "@/components/Dashboard/ActivityCalendar";

export default {
  name: "Activity",

  components: {
    SliderIcon,
    ActivityCalendar,
  },
  data() {
    return {
      Apps: [
        {
          name: "Photoshop 3hr",
          color: "#805b9e",
          time: " 07 h 13 m",
        },
        {
          name: "testesttestesttesta",
          color: "#a2c037",
          time: " 07 h 13 m",
        },
        {
          name: "word",
          color: "#d06779",
          time: " 07 h 13 m",
        },
        {
          name: "Ms Office",
          color: "#64b7eb",
          time: " 07 h 13 m",
        },
        {
          name: "illustrator",
          color: "#c08137",
          time: " 07 h 13 m",
        },
        {
          name: "word",
          color: "#d06779",
          time: " 07 h 13 m",
        },
        {
          name: "Photoshop 3hr",
          color: "#805b9e",
          time: " 07 h 13 m",
        },
        {
          name: "illustrator",
          color: "#a2c037",
          time: " 07 h 13 m",
        },
        {
          name: "word",
          color: "#d06779",
          time: " 07 h 13 m",
        },
      ],
      webPages: [
        {
          name: "material.io",
          color: "#805b9e",
          time: "07 h 13 m",
        },
        {
          name: "testesttesttesttest",
          color: "#a2c037",
          time: "07 h 13 m",
        },
        {
          name: "medium.com",
          color: "#d06779",
          time: "07 h 13 m",
        },
        {
          name: "pinterest.com",
          color: "#64b7eb",
          time: "07 h 13 m",
        },
        {
          name: "dragoncasino.io",
          color: "#c08137",
          time: "07 h 13 m",
        },
        {
          name: "google.com",
          color: "#9ccff3",
          time: "07 h 13 m",
        },
        {
          name: "material.io",
          color: "#805b9e",
          time: "07 h 13 m",
        },
        {
          name: "figma.com",
          color: "#a2c037",
          time: "07 h 13 m",
        },
        {
          name: "medium.com",
          color: "#d06779",
          time: "07 h 13 m",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.activity {
  width: 1692px;
  height: 908px;
  border-radius: 10px;
  background-color: #ededfc;
  margin: 0 0 35px 90px;
  display: flex;
  padding: 0 0 0 17px;
}

.dot {
  height: 8.62px;
  width: 8.57px;
  border-radius: 100%;
  //   opacity: 60%;
}
.app-container {
  display: flex;
  align-items: center;
}
.name {
  opacity: 77%;
  font-weight: 300;
  width: 108px;
  margin-left: 10px;
  color: $blue-primary;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.time {
  font-weight: 300;
  margin-left: 77px;
}
.column {
  width: 274px;
  height: 295px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 43px;
}
.programmes-container {
  display: flex;
  width: 1075px;
  justify-content: space-between;
}
.programmes {
  margin-left: 77px;
  padding-top: 46px;
}
h3 {
  color: $blue-primary;
  font-weight: 300;
  font-size: 20px;
}
// ---------webpage styles

.web-pages {
  margin-top: 99px;
  margin-left: 77px;
}

// =-------
.activity-calendar {
  display: inline-flex;
  margin: 46px auto 0 96px;
}
.circle-div {
  align-self: center;
  height: 260px;
  width: 260px;
  border-radius: 100%;
  border: 2px #2ee9b4 solid;
  margin-top: 253px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

  span {
    font-size: 37.14px;
    color: $blue-primary;
    margin-left: 30%;
    opacity: 77%;
  }
}
.rightside {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  > span {
    position: absolute;
    top: 290px;
    right: 121px;
    font-size: 14px;
    font-weight: 300;
    opacity: 50%;
    color: #000000;
  }
}
</style>
