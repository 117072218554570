<template>
  <div
    class="header"
    :class="[
      { admin: !$store.state.showTimeLine },
      { bookmarkHeaderStyle: showBookmarks },
      {'dark': $route.fullPath === '/Grades/CareerMapping'}
    ]"
  >
    <div class="page-title">{{ pageTitle }}</div>
    <div class="right-side">
      <img
        src="@/assets/icons/blue/search.svg"
        alt="search"
        class="icon"
        v-show="false"
      />
      <img
        src="@/assets/icons/blue/message.svg"
        alt="inbox"
        class="icon"
        v-show="false"
      />
      <img
        src="@/assets/icons/blue/notification.svg"
        alt="notification"
        class="icon"
        v-show="false"
      />
      <div class="news-content">
        <div class="new-hires-container" v-if="newHires.length">
          <img
            class="cake-gif"
            @click="newHiresVisible"
            src="@/assets/gifs/newhires.svg"
            alt=""
            srcset=""
          />
        </div>
        <div class="birthdays-container" v-if="birthdays.length">
          <img
            class="cake-gif"
            @click="showBirthdays = !showBirthdays"
            src="@/assets/gifs/cakeGif.svg"
            alt=""
            srcset=""
          />
          <div
            class="birthday-block"
            :style="{
              'background-image': `url(${require('@/assets/birthdayBackground.png')})`,
            }"
            :class="{ show: showBirthdays }"
          >
            <div
              class="swiper-pagination"
              :class="{ hide: !showBirthdays }"
              v-show="birthdays.length > 1"
              slot="pagination"
            ></div>
            <p :class="{ hide: !showBirthdays }">TODAYS HERO</p>

            <swiper :options="swiperThreeOptions">
              <swiper-slide
                class="person-content"
                v-for="el in birthdays"
                :key="el.index"
              >
                <div class="forevent">
                  <div
                    class="avatar"
                    :style="{
                      backgroundImage: `url(${getAvatarSrc(el.PersonID)})`,
                    }"
                  ></div>
                  <div>
                    <img src="@/assets/cake.png" alt="cake" />
                  </div>
                  <div class="name">
                    <span>{{ el.FirstName + " " + el.LastName }}</span>
                  </div>
                  <div class="jobname">{{ el.JobName }}</div>
                </div>
                <div class="input-content">
                  <textarea
                    rows="2"
                    cols="25"
                    wrap="soft"
                    type="text"
                    v-model="el.congratSText"
                    placeholder="Say congrats..."
                  />
                  <img
                    @click="sendCongratsForBirthday(el)"
                    src="@/assets/Outbox.png"
                    alt=""
                  />
                </div>
              </swiper-slide>
            </swiper>

            <!-- <custom-snackbar
          v-if="snackbarVisible"
          :purpose="snackbarPurpose"
          :text="snackbarText"
          @close="snackbarVisible = false"
        /> -->
          </div>
        </div>
      </div>
      <!-- <a
        href="mailto:gtetradze@upgaming.com"
        class="mail-content"
        title="Open Outlook Email"
      >
        <img src="@/assets/outlook.svg" />
      </a> -->
      <div class="avatar-and-dropdown" @click.stop>
        <name-and-avatar @click.native.stop="avatarClickHandler" />
        <div class="dropdown" v-show="showAvatarDropdown">
          <div class="row" @click="profileClickHandler">
            <img src="@/assets/icons/blue/roles.svg" class="dropdown-icon" />
            <span>Profile</span>
          </div>
          <div class="row" @click="logoutClickHandler">
            <img src="@/assets/icons/blue/logout.svg" class="dropdown-icon" />
            <span>Log Out</span>
          </div>
        </div>
        <img
          class="notification-icon"
          v-if="unseenCount < 1"
          src="@/assets/notification-icon.svg"
          alt=""
          @click.stop="showNotifications"
        />
        <div v-else class="notification-count" @click.stop="showNotifications">
          <span>{{ unseenCount }}</span>
        </div>
        <div class="notifications-content" v-if="notificationsVisible">
          <div class="notification-header">
            <span>Notifications</span>
          </div>
          <div class="notifications" @scroll="scrolledToBottom">
            <div
              class="single-notification"
              v-for="not in notifications"
              :key="not.index"
              :style="{ backgroundColor: !not.Seen ? '#f2f3f7' : null }"
              @click="notificationClick(not)"
            >
              <div class="for-border">
                <div class="type-time">
                  <span>{{ not.TypeName }}</span>
                  <span>{{ notificationDates(not.CreationTime) }}</span>
                </div>
                <div
                  class="msg"
                  :style="[
                    not.Message.length > 90 ? { fontSize: '11px' } : null,
                  ]"
                >
                  <span>{{ not.Message }}</span>
                </div>
              </div>
            </div>

            <div class="gradient"></div>
          </div>
        </div>
      </div>
    </div>
    <custom-popup
      class="new-members-popup"
      :visible="newMembersPopup"
      @close="newMembersPopup = false"
    >
      <div class="container">
        <div class="left-side">
          <div class="person-content">
            <p class="welcome-text">Welcome aboard</p>
            <div
              class="avatar"
              :style="{
                backgroundImage: `url(${getAvatarSrc(choosenPerson.PersonID)})`,
              }"
            ></div>
            <div class="name">
              {{ choosenPerson.FirstName + " " + choosenPerson.LastName }}
            </div>
            <div class="jobname">
              {{ choosenPerson.JobName }}
            </div>

            <div class="input-content">
              <textarea
                type="text"
                v-model="congratsTextForHire"
                placeholder="Say Congrats..."
              />
              <img
                src="@/assets/Outbox.png"
                @click="sendCongratsToHire"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="right-side">
          <div
            class="persons-wrapper"
            v-for="person in newHiresList"
            :key="person.PersonID"
          >
            <div class="person-content" @click="personClicked(person)">
              <div
                class="avatar"
                :class="{ border: choosenPerson.PersonID == person.PersonID }"
                :style="{
                  backgroundImage: `url(${getAvatarSrc(person.PersonID)})`,
                }"
              ></div>
              <div class="name">
                {{ person.FirstName + " " + person.LastName }}
              </div>

              <div class="jobname">
                {{ person.JobName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </custom-popup>
    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
  </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance.js";
import eventBus from "@/Helpers/EventBus";
import CustomSnackbar from "../ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import CustomPopup from "../ReusableComponents/CustomPopup/CustomPopup.vue";

import NameAndAvatar from "@/components/SiteHeader/NameAndAvatar";
export default {
  components: {
    NameAndAvatar,
    CustomSnackbar,
    CustomPopup,
  },
  created() {
    this.pageTitle = this.$route.meta.mainTitle
      ? this.$route.meta.mainTitle
      : this.$route.name;

    this.dateRange.toDate = this.getDateString(new Date(), "yyyy-MM-dd");
    this.dateRange.fromDate = this.getDateString(
      new Date().setDate(new Date().getDate() - 3),
      "yyyy-MM-dd"
    );
    this.getMail(this.$store.state.profile.ID);
    this.getHiresAndBirthdays(this.dateRange.toDate, this.dateRange.fromDate);

    document.addEventListener("click", () => (this.showAvatarDropdown = false));

    this.getNotificationsCount(1, true, 5);

    setInterval(() => {
      this.getNotifications(1, true, 5);
    }, 160000);
    eventBus.$on("change-title", (title) => (this.pageTitle = title));
  },
  props: {
    hub: Object,
    notificationsVisible: Boolean,
  },
  data() {
    return {
      pageTitle: "",
      chatExpanded: false,
      showAvatarDropdown: false,
      userMail: "",
      snackbarPurpose: "",
      snackbarText: "",
      snackbarVisible: false,
      dateRange: {
        fromDate: "",
        toDate: "",
      },
      swiperThreeOptions: {
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      newHires: [],
      birthdays: [],
      showBirthdays: false,
      notifications: [],
      choosenPerson: {},
      maxPageCount: null,
      newMembersPopup: false,
      newHiresList: [],
      unseenCount: "",
      congratSText: "",
      congratsTextForHire: "",
      unseenNotifications: [],
      currentPage: 1,
      avatar: "",
      collapsed: false,
    };
  },
  watch: {
    $route(to) {
      this.pageTitle = !to.meta.mainTitle ? to.name : to.meta.mainTitle;
    },
    notifications(to, from) {
      this.notifications.forEach((el) => {
        if (el.Seen == false) {
          setTimeout(() => {
            el.Seen = true;
          }, 4000);
        }
      });
      setTimeout(() => {
        this.unseenCount = this.unseenCount - this.unseenNotifications.length;
      }, 4000);
    },
    unseenNotifications() {
      if (this.notificationsVisible) {
        let ids = [];
        this.unseenNotifications.forEach((el) => {
          ids.push({
            Notification: el.ID,
          });
        });
        if (this.unseenNotifications.length > 0) {
          this.markNotificationAsSeen(ids);
        }
      }
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin || this.$store.getters.isSuperAdmin;
    },
    showBookmarks() {
      return this.$store.state.showBookmarks;
    },
    loggedPerson() {
      return this.$store.state.profile;
    },
  },
  methods: {
    profileClickHandler() {
      this.$router.push({
        path: `/Profile/${this.$store.state.profile.ID}`,
      });
      this.showAvatarDropdown = true;
    },
    newHiresVisible() {
      this.getNewMembers();
      this.newMembersPopup = true;
      this.choosenPerson = this.newHires[0];
    },
    getNewMembers() {
      this.newMembersPopup = true;
      axios.get("Person/GetPersonsCreatedInLastPeriod").then((resp) => {
        this.newHiresList = resp.data.Value.sort(function (a, b) {
          return b.CreationTime - a.CreationTime;
        });
      });
    },
    getMail(id) {
      axios.get(`Person/GetPersonsBasicContacts?Person=${id}`).then((resp) => {
        this.userMail = resp.data.Value.Mail;
      });
    },
    personClicked(el) {
      this.choosenPerson = el;
    },
    sendCongratsToHire() {
      axios
        .post("notifications/SendMailNotificationToPerson", {
          Person: this.choosenPerson.PersonID,
          Text: this.congratsTextForHire,
          Mail: this.userMail,
          FullName:
            this.loggedPerson.FirstName + " " + this.loggedPerson.LastName,
        })
        .then((resp) => {
          this.snackbarVisible = true;
          this.snackbarPurpose = "success";
          this.snackbarText = "Successfully sent";
          this.congratsTextForHire = "";
        });
    },
    sendCongratsForBirthday(el) {
      axios
        .post("notifications/SendMailNotificationToPerson", {
          Person: el.PersonID,
          Text: el.congratSText,
          Mail: this.userMail,
          FullName:
            this.loggedPerson.FirstName + " " + this.loggedPerson.LastName,
        })
        .then((resp) => {
          this.snackbarVisible = true;
          this.snackbarPurpose = "success";
          this.snackbarText = "Successfully sent";
          el.congratSText = "";
        });
    },
    getAvatarSrc(id) {
      return this.$store.state.getAvatar + id;
    },
    getHiresAndBirthdays(todate, fromdate) {
      axios
        .get("Person/GetUsersTimeLineInfo", {
          params: {
            From: fromdate,
            To: todate,
          },
        })
        .then((resp) => {
          this.newHires = resp.data.Value.NewHireList.sort(function (a, b) {
            return b.CreationTime - a.CreationTime;
          });
          this.birthdays = resp.data.Value.BirthDayPersonList;
          this.birthdays.forEach((el) => {
            this.$set(el, "congratsText", "");
          });
        });
    },
    getNotificationsCount(page, isFirst, pageSize) {
      axios
        .get("notifications/GetNotifications", {
          params: {
            Page: page,
            PageSize: pageSize,
          },
        })
        .then((resp) => {
          if (resp.data.Value[0]) {
            this.unseenCount = resp.data.Value[0].UnSeenNotificationCount;
          }
        });
    },
    markNotificationAsSeen(array) {
      axios
        .post("notifications/MarkNotificationsAsSeen", {
          Notifications: array,
        })
        .then((resp) => {});
    },
    notificationDates(el) {
      if (el < new Date(Date.now()).setHours(0, 0, 0, 0)) {
        return this.getDateString(el, "ww, dd MMM");
      } else {
        return this.getDateString(el, "HH:mm");
      }
    },
    showNotifications() {
      this.$emit("show-notifications");
      this.getNotifications(1, true, 5);
      let ids = [];
      this.unseenNotifications.forEach((el) => {
        ids.push({
          Notification: el.ID,
        });
      });
    },

    notificationClick(el) {
      if (
        el.TypeName == "News" ||
        el.TypeName == "Poll" ||
        el.TypeName == "Offer"
      ) {
        this.$store.state.showTimeLine = true;
        this.$router.push({
          path: "/Dashboard",
        });
      } else if (el.TypeName == "Vacation") {
        this.$router.push({
          path: "/VacationsUser",
        });
      } else if (el.TypeName == "In/Out") {
        this.$store.state.showTimeLine = true;
        this.$router.push({
          path: "/AttendanceUser",
        });
      } else if (el.TypeName == "Vacation Request") {
        this.$store.state.showTimeLine = false;
        this.$router.push({
          path: "/VacationsView/VacationsAdmin",
          // redirect: "VacationsView/VacationsAdmin",
        });
      } else if (el.TypeName == "Workspace") {
        this.$router.push({
          path: "/MyWorkspace",
        });
      } else if (el.TypeName == "Workspace Request") {
        this.$router.push({
          path: "/TrafficRequests",
        });
      } else if (el.TypeName == "Poll Remainder") {
        this.$store.state.showTimeLine = true;
        this.$router.push({
          path: "/Dashboard",
        });
      } else if (el.TypeName == "Offer Remainder") {
        this.$store.state.showTimeLine = true;
        this.$router.push({
          path: "/Dashboard",
        });
      } else if (el.TypeName == "Vacancy") {
        this.$store.state.showTimeLine = true;
        this.$router.push({
          path: "/Career",
        });
      } else if (el.TypeName == "New Applicant") {
        this.$store.state.showTimeLine = true;
        this.$router.push({
          path: "/ApplicantTracking",
        });
      }
    },
    getDateString(date, format = "yyyy-MM-dd") {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      if (date === "Invalid Date") return;

      const MONTHS = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      var days = ("0" + date.getDate()).slice(-2),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        year = date.getFullYear(),
        hours = ("0" + date.getHours()).slice(-2),
        minutes = ("0" + date.getMinutes()).slice(-2),
        seconds = ("0" + date.getSeconds()).slice(-2);

      return format
        .replace("dd", days)
        .replace("MMM", MONTHS[parseInt(month) - 1])
        .replace("MM", month)
        .replace("yyyy", year)
        .replace("yy", String(year).slice(-2))
        .replace("HH", hours)
        .replace("mm", minutes)
        .replace("ss", seconds)
        .replace("ww", WEEKDAYS[date.getDay()]);
    },
    logoutClickHandler() {
      axios.post("authorization/LogOut").then((resp) => {
        if (resp.data.ErrorMsg) return;
        this.$emit("logout");
      });
    },
    getNotifications(page, isFirst, pageSize) {
      axios
        .get("notifications/GetNotifications", {
          params: {
            Page: page,
            PageSize: pageSize,
          },
        })
        .then((resp) => {
          if (isFirst) {
            this.notifications = resp.data.Value;
            this.maxPageCount = resp.data.Paging.TotalPageCount;

            this.unseenNotifications = resp.data.Value.filter((el) => {
              return !el.Seen;
            });
          } else {
            this.unseenNotifications = resp.data.Value.filter((el) => {
              return !el.Seen;
            });

            resp.data.Value.forEach((el) => {
              this.notifications.push(el);
            });
          }
        });
    },
    scrolledToBottom(event) {
      var el = event.srcElement;

      if (el.scrollTop >= el.scrollHeight - el.clientHeight) {
        this.currentPage += 1;

        if (this.currentPage <= this.maxPageCount) {
          this.getNotifications(this.currentPage, false, 5);
        }
      }
    },

    notificationRedirect(type) {
      switch (type) {
        case 1:
          this.$router.push("Jobs");
          this.showNotifications = false;
          break;
        case 2:
          this.$router.push("Teams");
          this.showNotifications = false;
          break;
        case 3:
          this.$router.push("Salarys");
          this.showNotifications = false;
          break;
        case 4:
          this.$router.push("Schedules");
          this.showNotifications = false;
          break;
        case 5:
          this.$router.push("News");
          this.showNotifications = false;
          break;
      }
    },

    avatarClickHandler() {
      this.showAvatarDropdown = !this.showAvatarDropdown;
    },
  },
};
</script>

<style lang="scss" scoped>
.mail-content {
  margin-right: 10px;
  // width: 30px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(1.3);
  }
  img {
    height: 20px;
  }
}
.news-content {
  // position: relative;
  display: flex;
  .birthdays-container {
    position: relative;
  }
}
.cake-gif {
  height: 55px;
  width: 55px;
  cursor: pointer;
}
a {
  text-decoration: none;
  color: inherit;
}
.noPointerEvents {
  pointer-events: none;
}
.notification-count {
  width: 26px;
  height: 26px;
  border-radius: 100%;
  color: #ffffff;
  position: absolute;
  background-color: #dc3f59;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 5px;
  bottom: -4px;
  cursor: pointer;
}
.notifications-content {
  position: absolute;
  border-radius: 10px;
  right: 0;
  top: 55px;
  width: 235px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(89, 99, 171, 0.1) 0px 12px 20px 0px;

  .notification-header {
    height: 34px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #5963ab;
    color: #ffffff;
    font-size: 14px;
  }
  .notifications {
    min-height: 200px;
    max-height: 434px;
    width: 100%;
    background-color: #ffffff;
    // padding-top: 14px;
    overflow: auto;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-bottom: 50px;
    .gradient {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 90px;
      background: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 100%);
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba($blue-primary, 0.2);
    }
    .single-notification {
      padding-left: 22px;
      padding-right: 22px;
      padding-top: 14px;
      height: 93px;
      cursor: pointer;
      .for-border {
        border-bottom: 1px solid #d9dbe0;
        height: 100%;
      }
      .type-time {
        color: rgba($blue-primary, 0.7);
        font-weight: 600;
        font-size: 11px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;
        text-transform: uppercase;
        white-space: nowrap;
      }
      .msg {
        font-size: 12px;
        color: #8f8f8f;
        word-break: break-word;
        height: calc(100% - 27px);
        display: flex;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        // align-items: center;
      }
    }
  }
}

.header {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  width: calc(100% - 96 * #{$rem});
  background-color: #fff;
  margin-left: 96 * $rem;
  height: 73 * $rem;
  z-index: 12;
  padding-left: 39 * $rem;
  padding-right: 39 * $rem;
  transition: all 0.2s ease-out;
  z-index: 15;

  &.dark{
    background: #21103A;
    transition: all .2s;
    .right-side{
      ::v-deep{
        .full-name{
          color: #fff !important;
        }
      }
    }
    .page-title{
      color: #fff !important;
    }
  }

  &.admin {
    margin-left: 245 * $rem;
    width: calc(100% - 245 * #{$rem});
  }

  &.expanded-left {
    margin-left: 0;
    width: 100%;
    padding-left: 10 * $rem;
  }

  .right-side {
    margin-left: auto;
    display: flex;

    .border {
      border: 2px solid #5963ab;
    }
    .icon {
      height: 24 * $rem;
      fill: $blue-primary;
      margin-right: 25 * $rem;
      cursor: pointer;
    }

    .avatar-and-dropdown {
      position: relative;
      .notification-icon {
        position: absolute;
        right: 11px;
        bottom: -3px;
        cursor: pointer;
      }
      .dropdown {
        position: absolute;
        border: $rem solid $blue-border;
        border-radius: 5 * $rem;
        display: flex;
        flex-direction: column;
        background-color: white;
        box-shadow: $box-shadow2;
        right: 0;
        top: 60 * $rem;
        width: 150 * $rem;
        padding: 3 * $rem 10 * $rem;
        animation: fade-down 0.3s ease-out;

        .row {
          display: flex;
          align-items: center;
          font-size: 14 * $rem;
          color: $blue-primary;
          cursor: pointer;

          img.dropdown-icon {
            margin-right: 5 * $rem;
            height: 40 * $rem;
          }

          span {
            position: relative;
            top: 2 * $rem;
          }
        }
      }
    }
  }
  .new-members-popup {
    ::v-deep .popup {
      height: 566 * $rem;
      width: 1132 * $rem;
      overflow: hidden;
    }
    .container {
      display: flex;
      overflow: hidden;
      height: 100%;
    }
    .left-side {
      height: 91%;

      .person-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #5963ab;
        height: 100%;
        border-radius: 15px;
        width: 300px;
        padding: 76px 40px;
        position: relative;
        .name {
          color: #ffffff;
          font-weight: 500;
          font-size: 16px;
          margin-bottom: 15px;
        }
        .welcome-text {
          color: #ffffff;
          font-weight: 500;
          font-size: 20px;
          position: absolute;
          top: 30px;
        }
        .status {
          color: #ffffff;
          font-size: 15px;
          font-weight: 300;
        }
        .avatar {
          height: 110px;
          width: 110px;
          background-color: $green-primary;
          border-radius: 100%;
          margin-bottom: 40px;
          background-size: cover;
          border: 2px solid #ffffff;
        }
        .jobname {
          width: 100%;
          text-align: center;
          color: #ffffff;
          font-weight: 300;
          font-size: 15px;
          margin-bottom: auto;
          white-space: nowrap;
        }
        .date {
          margin-bottom: 35px;
          span:nth-child(1) {
            font-size: 15px;
            font-weight: 300;
            color: #ffffff;
          }
          span:last-child {
            font-size: 15px;
            font-weight: 300;
            color: #ffffff;
            margin-left: 5px;
          }
        }
        .input-content {
          position: relative;
          position: relative;
          background-color: white;
          width: 220px;
          border-radius: 10px;
          border: 2px solid #eaeaec;
          height: 80px;

          textarea {
            outline: none;
            resize: none;
            padding-bottom: 5px;
            width: 87%;
            height: 100%;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 5px;
            color: rgba($blue-primary, 1);
            border-radius: 10px;
            border: 0;
            &::placeholder {
              color: #9696b9;
            }
            &::-webkit-scrollbar-thumb {
              background: rgba($blue-primary, 0.1);
            }
            &::-webkit-scrollbar {
              width: 4px;
            }
          }
          img {
            position: absolute;
            right: -12px;
            top: 48%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            &:active {
              height: 30px;
            }
          }
        }
      }
    }
    .right-side {
      height: 91%;
      overflow: scroll;
      margin-left: 45px;
      flex-wrap: wrap;

      .persons-wrapper {
        display: inline-block;
        margin-left: 20px;
        margin-bottom: 30px;
        cursor: pointer;
      }
      .person-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        text-align: center;

        width: 200px;

        .avatar {
          width: 90px;
          height: 90px;
          background-color: $green-primary;
          border-radius: 100%;
          margin-bottom: 10px;
          background-size: cover;
        }
        .name {
          font-size: 16px;
          color: rgba($blue-primary, 1);
          font-weight: 300;
          margin-bottom: 5px;
        }
        .jobname {
          color: #9696b9;
          font-weight: 300;
          font-size: 12px;
        }
      }
    }
  }
  .show {
    transition: 0.4s ease-in;
    height: 370px !important;
  }
  .hide {
    opacity: 0;
  }
  .birthday-block {
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    // position: relative;
    transition: 0.4s ease-in;
    height: 370px;
    height: 0;
    border-radius: 10px;
    width: 310px;
    position: absolute;
    box-shadow: rgba(89, 99, 171, 0.1) 0px 12px 20px 0px;
    background-color: white;
    top: 56px;
    left: -98px;
    z-index: 20;
    .swiper-container {
      width: 100%;
    }
    .swiper-slide {
      width: 100% !important;
    }
    ::v-deep .swiper-pagination {
      top: 32px;
      .swiper-pagination-bullet-active {
        background-color: #fff !important;
      }
      .swiper-pagination-bullet {
        margin-left: 3px;
        background: transparent;
        border: 1px solid #ffffff;
      }
    }
    .forevent {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
    }
    .input-content {
      margin-top: 35px;
      position: relative;
      transition: 0.4s ease-in;

      background-color: #fff;
      padding-right: 30px;
      color: #283593;
      width: 250px;
      border-radius: 10px;
      padding-left: 15px;
      padding-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      textarea {
        outline: none;
        border: none;
        height: 5rem;
        padding-right: 30px;
        color: $blue-primary;
        width: 220px;
        border-radius: 10px;
        padding-left: 5px;
        padding-top: 8px;
        display: block;
        resize: none;
      }
      img {
        position: absolute;
        right: 5px;
        top: 50%;
        cursor: pointer;
        transform: translate(0%, -50%);
        &:active {
          height: 30px;
        }
      }
    }

    .person-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      img {
        cursor: pointer;
      }

      .avatar {
        height: 100px;
        width: 100px;
        background-color: $green-primary;
        border-radius: 100%;
        margin-bottom: 10px;
        cursor: pointer;
        filter: drop-shadow(0px 10px 10px rgba(89, 99, 171, 0.15));
        border: 2px solid #fff;
      }
    }
    p {
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      margin-top: 20px;
      font-weight: 900;
      // margin-bottom: 5px;
    }
    .name {
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .jobname {
      color: #dcdde6;
      font-size: 13px;
      word-break: break-word;
      white-space: break-spaces;
      text-align: center;
      max-width: 90%;
    }
  }
  .page-title {
    font-weight: 700;
    font-size: 24 * $rem;
    color: $blue-primary;
    text-transform: uppercase;
  }

  i.min-max {
    margin-right: 15 * $rem;
    color: $grey-text;
    transform: rotate(180deg);
    cursor: pointer;
    transition: transform 0.3s ease-out;

    &.rotated {
      transform: rotate(0);
    }
  }

  @keyframes show {
    0% {
      opacity: 0.5;
      top: 30 * $rem;
    }
  }

  @keyframes fade-down {
    from {
      opacity: 50%;
      top: 50 * $rem;
    }

    to {
      opacity: 100%;
      top: 60 * $rem;
    }
  }
}
.bookmarkHeaderStyle {
  margin-top: 25px;
  z-index: 15;
}
</style>
