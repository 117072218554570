var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('editor',{attrs:{"api-key":_vm.key,"value":_vm.value,"placeholder":"Description ...","init":{
      height: 300,
      resize: false,
      branding: false,
      menubar: false,
      statusbar: false,

      toolbar:
        ' bold italic |  fontsizeselect fontselect | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link  | ' +
        'forecolor backcolor emoticons | help ',
      plugins: [
        'advlist autoresize autolink link image lists charmap print preview hr anchor pagebreak',
        ' searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
        'table emoticons template paste help',
      ],
      font_formats:
        'roboto; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif',
      branding: true,
      skin: 'borderless',
    }},on:{"input":_vm.inputHandler}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }