<template>
  <div
    class="field"
    :type="type"
    @click.stop
    :class="{ 'read-only': readOnly }"
  >
    <div class="label-container">
      <custom-checkbox
        v-if="showPublicToggle"
        :value="publicToggleSettings.value"
        :disabled="publicToggleSettings.disabled"
        @input="$emit('change-public-status')"
      />
      <span class="label">{{ required ? `${label} *` : label }}</span>
      <i class="material-icons" v-if="type == 'select'">keyboard_arrow_down</i>
    </div>
    <div v-if="inputTypes.includes(type)" class="input">
      <div v-show="isEditMode">
        <input
          :type="type"
          v-model="inputValue"
          :placeholder="placeholder"
          ref="input"
          @input="$emit('input', inputValue)"
          @blur="inputBlurHandler"
          spellcheck="false"
          :disabled="disabled"
          :required="required"
        />
      </div>
      <div v-show="!isEditMode">
        <div class="value-container">
          <img
            src="@/assets/icons/edit.svg"
            class="edit-icon"
            @click="editClickHandler"
          />
          <div class="value" @click="editClickHandler">
            {{ value || placeholder || "" }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="type == 'birthDate'" class="birth-date">
      <div v-if="!isEditMode">
        <div class="value-container">
          <img
            src="@/assets/icons/edit.svg"
            class="edit-icon"
            @click="editClickHandler"
          />
          <div
            :class="{ initial: dateValue }"
            class="value"
            @click="editClickHandler"
          >
            {{ getBirthDateValue() }}
          </div>
        </div>
      </div>
      <form v-else class="container" @submit.prevent="changeBirthDate">
        <div class="row">
          <custom-select
            v-model="birth.day"
            :options="dayOptions"
            :required="true"
          />
          <custom-select
            v-model="birth.month"
            :options="monthOptions"
            required="true"
          />
          <custom-select
            v-model="birth.year"
            :options="yearOptions"
            required="true"
          />
        </div>
        <div class="row">
          <button class="btn">OK</button>
        </div>
      </form>
    </div>
    <div v-if="type == 'select'" class="select">
      <div v-show="isEditMode" class="container">
        <custom-select
          ref="select"
          :options="selectOptions"
          v-model="selectValue"
        />
      </div>
      <div v-show="!isEditMode">
        <div class="value-container">
          <img
            src="@/assets/icons/edit.svg"
            class="edit-icon"
            @click="editClickHandler"
          />
          <div
            class="value"
            :class="{ initial: dateValue }"
            @click="editClickHandler"
          >
            {{
              value
                ? selectOptions.filter((el) => el.value == value)[0]
                  ? selectOptions.filter((el) => el.value == value)[0].title
                  : null || placeholder || ""
                : null || placeholder || ""
            }}
          </div>
        </div>
      </div>
      <input
        v-if="required"
        type="text"
        :value="value"
        class="hidden"
        required
      />
    </div>
    <div v-if="type == 'date'" class="date">
      <div v-if="isEditMode" class="container">
        <el-date-picker
          v-model="inputValue"
          type="date"
          :placeholder="placeholder"
          ref="dateInput"
          @blur="isEditMode = false"
        />
      </div>
      <div v-else>
        <div class="value-container" @click="editClickHandler">
          <div class="value" :class="{ initial: dateValue }">
            {{ dateValue || placeholder || "" }}
          </div>
        </div>
      </div>
      <input
        v-if="required"
        type="text"
        :value="value"
        class="hidden"
        required
      />
    </div>
    <span class="error" v-show="isError">{{ errorText }}</span>
    <div class="disabled-overlay"></div>
  </div>
</template>

<script>
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect";
import CustomCheckbox from "@/components/ReusableComponents/CustomCheckbox/CustomCheckbox";
export default {
  components: { CustomSelect, CustomCheckbox },
  props: {
    type: String,
    required: Boolean,
    disabled: Boolean,
    label: String,
    placeholder: String,
    editable: Boolean,
    value: {
      type: [String, Number, Date],
    },
    selectOptions: Array,
    isError: Boolean,
    errorText: String,
    showPublicToggle: Boolean,
    publicToggleSettings: Object,
    readOnly: Boolean,
  },
  created() {
    if (this.type == "birthDate" && this.value) {
      var date = new Date(this.value);
      this.birth.day = date.getDate();
      this.birth.month = date.getMonth() + 1;
      this.birth.year = date.getFullYear();
    }
    if (this.type == "select") {
      document.addEventListener("click", () => (this.isEditMode = false));
    }
    if (this.type == "text") {
      //this is for make it so that we can change the value from outside this component
      this.$watch("value", () => {
        if (this.inputValue != this.value) {
          this.inputValue = this.value;
        }
      });
    }
  },
  data() {
    return {
      inputTypes: ["text", "tel", "email"],
      isEditMode: this.determinInitialEditMode(),
      inputValue: this.value,
      selectValue: this.value,
      birth: {
        day: 1,
        month: 1,
        year: 2020,
      },
    };
  },
  computed: {
    dayOptions() {
      return Array.from(Array(31).keys()).map((e) => ({
        value: "" + (e + 1),
        title: ("0" + (e + 1)).slice(-2),
      }));
    },
    monthOptions() {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return Array.from(Array(12).keys()).map((e) => ({
        value: "" + (e + 1),
        title: months[e],
      }));
    },
    yearOptions() {
      const startYear = 1950;
      const endYear = new Date().getFullYear();
      return Array.from(Array(endYear - startYear + 1).keys())
        .reverse()
        .map((e) => ({
          value: "" + (e + startYear),
          title: e + startYear,
        }));
    },
    dateValue() {
      if (!this.value) return null;
      var date = new Date(this.value);
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return `${date.getDate()} ${
        months[date.getMonth()]
      }, ${date.getFullYear()}`;
    },
  },
  methods: {
    editClickHandler() {
      this.isEditMode = true;
      if (this.inputTypes.includes(this.type)) {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
      if (this.type == "select") {
        this.$nextTick(() => {
          this.$refs.select.$el.querySelector(".selected-box").click();
        });
      }
      if (this.type == "date") {
        this.$nextTick(() => {
          this.$refs.dateInput.focus();
        });
      }
    },
    getBirthDateValue() {
      if (this.value)
        return `${("0" + this.birth.day).slice(-2)}/${(
          "0" + this.birth.month
        ).slice(-2)}/${this.birth.year}`;
      return "day, month, year";
    },
    determinInitialEditMode() {
      if (["text", "tel", "email"].includes(this.type) && !this.value)
        return true;
      return false;
    },
    changeBirthDate() {
      this.isEditMode = false;
      this.$emit("change", this.birth);
    },
    inputBlurHandler() {
      if (this.inputValue) this.isEditMode = false;
    },
  },
  watch: {
    selectValue(val) {
      if (val) {
        this.isEditMode = false;
        this.$emit("input", val);
      }
    },
    inputValue() {
      if (this.type == "date") {
        this.$emit("input", this.inputValue);
        this.isEditMode = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.label-container {
  margin-bottom: 4 * $rem;
  display: flex;
  align-items: center;
  position: relative;

  .box {
    position: absolute;
    left: -23 * $rem;
  }

  .label {
    color: rgba(40, 53, 147, 0.5);
    font-weight: 300;
    font-size: 13 * $rem;
  }

  i {
    margin-left: 5 * $rem;
    font-size: 16 * $rem;
    color: rgba(40, 53, 147, 0.5);
  }
}

.input {
  input {
    border: none;
    border-bottom: 1px solid rgba($blue-primary, 0.5);
    padding-bottom: 5 * $rem;
    background-color: $purple-background;
    outline: none;
    color: rgba($blue-primary, 0.7);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      color: $blue-primary;
    }
  }
}

.birth-date {
  .container {
    transform: scale(0.9);
    left: -14px;
    position: relative;
    padding: 10 * $rem;
    background-color: white;
    border: $rem solid #dedff4;
    border-radius: 6 * $rem;
    box-shadow: $box-shadow;
    z-index: 2;
    font-size: 0.8125rem;

    .row {
      display: flex;
      justify-content: center;

      &:first-child {
        margin-bottom: 15 * $rem;
      }

      .select-wrapper {
        width: 4.5rem;
        margin-right: 8 * $rem;

        &:last-child {
          margin-right: 0;
        }

        ::v-deep .selected-box {
          padding-left: 8 * $rem;

          .material-icons {
            margin-left: auto;
          }
        }
      }
    }

    .btn {
      display: inline-flex;
      padding: 6 * $rem 15 * $rem;
      border-radius: 15 * $rem;
      justify-content: center;
      align-items: center;
      background-color: #8a91c7;
      color: #fff;
      margin-left: auto;
      cursor: pointer;
      outline: none;
      border: none;

      &:hover {
        filter: brightness(1.1);
      }
    }
  }
}

.select {
  .container {
    .select-wrapper {
      visibility: hidden;
      position: relative;
      top: -50 * $rem;
      left: -10 * $rem;
    }
  }
}

input.hidden {
  opacity: 0;
  height: 0;
  width: 0;
  position: relative;
  top: -10 * $rem;
}

.value-container {
  cursor: pointer;
  position: relative;

  .value {
    font-size: 13px;
    color: $blue-primary;
    font-weight: 300;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 35 * #{$rem});

    &.initial {
      font-size: 13 * $rem;
    }
  }
}

.edit-icon {
  position: absolute;
  left: -28 * $rem;
  font-size: 20 * $rem;
  width: 25 * $rem;
  cursor: pointer;
  color: $blue-primary;
  font-weight: 300;
  display: none;
  transform: translateY(-15%);
}

.field {
  position: relative;
  &:not(.read-only):hover {
    .edit-icon {
      display: block;
    }
  }

  .disabled-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 91%;
    height: 100%;
    z-index: 2;
    cursor: not-allowed;
    display: none;
  }

  &.read-only {
    .disabled-overlay {
      display: block;
    }
  }
}

.el-input {
  width: 0;
  height: 0;
  opacity: 0;
}

.error {
  color: #d06779;
  font-weight: 300;
  font-size: 11 * $rem;
}
</style>
