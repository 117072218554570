<template>
    <custom-popup :visible="true" title="Time Picker" @close="$emit('close')">
        <schedule-time-templates
            :fromTime="fromTime"
            :toTime="toTime"
            @select="selectTemplateHandler"
        />
        <schedule-time-picker
            :fromTime="fromTime"
            :toTime="toTime"
            :breakTime="totalBreak"
            :totalTime="totalTime"
            :canChangeTotal="true"
            @change-total="total => (totalTime = total)"
            @change-from="changeFromHandler"
            @change-to="changeToHandler"
            @change-break="changeBreakHandler"
        />
        <div
            class="btn"
            @click="
                $emit('update-schedule', {
                    day: schedule.day,
                    fromTime: fromTime,
                    toTime: toTime,
                    totalTime: totalTime,
                    totalBreak: totalBreak,
                })
            "
        >
            Save
        </div>
    </custom-popup>
</template>

<script>
const DEFAULTSTART = 12 * 60
const DEFAULTEND = 18 * 60
const DEFAULTBREAK = 60
import CustomPopup from '@/components/ReusableComponents/CustomPopup/CustomPopup.vue'
import ScheduleTimePicker from '@/components/ReusableComponents/ScheduleTimePicker/ScheduleTimePicker.vue'
import ScheduleTimeTemplates from '@/components/FixedSchedules/ScheduleTimeTemplates.vue'
export default {
    components: {
        CustomPopup,
        ScheduleTimePicker,
        ScheduleTimeTemplates,
    },
    props: {
        schedule: Object,
    },
    created() {
        let start = this.schedule.scheduleStartTime
        let end = this.schedule.scheduleEndTime
        let breakTime = this.schedule.totalBreakTime
        let totalTime = this.schedule.totalWorkTime
        this.fromTime = parseInt(start.split(':')[0]) * 60 + parseInt(start.split(':')[1])
        this.toTime = parseInt(end.split(':')[0]) * 60 + parseInt(end.split(':')[1])
        if (breakTime)
            this.totalBreak =
                parseInt(breakTime.split(':')[0]) * 60 + parseInt(breakTime.split(':')[1])
        if (totalTime) {
            this.totalTime =
                parseInt(totalTime.split(':')[0]) * 60 + parseInt(totalTime.split(':')[1])
        }
        else {
            this.totalTime = this.toTime - this.fromTime - this.totalBreak
        }
    },
    data() {
        return {
            fromTime: DEFAULTSTART,
            toTime: DEFAULTEND,
            totalBreak: DEFAULTBREAK,
            totalTime: DEFAULTEND - DEFAULTSTART - DEFAULTBREAK,
        }
    },
    methods: {
        selectTemplateHandler({ fromTime, toTime }) {
            this.fromTime = fromTime
            this.toTime = toTime
        },
        changeFromHandler(from) {
            this.fromTime = from
            this.setTotalTime()
        },
        changeToHandler(to) {
            this.toTime = to
            this.setTotalTime()
        },
        changeBreakHandler(breakT) {
            this.totalBreak = breakT
            this.setTotalTime()
        },
        setTotalTime() {
            this.totalTime = this.toTime - this.fromTime - this.totalBreak
        },
    },
}
</script>

<style lang="scss" scoped>
.schedule-time-picker {
    margin-top: 100 * $rem;
}

.btn {
    color: white;
    font-size: 16 * $rem;
    font-weight: 400;
    background-color: rgba($blue-primary, 0.5);
    border-radius: 3 * $rem;
    width: 100 * $rem;
    height: 40 * $rem;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25 * $rem;
    cursor: pointer;
}
</style>