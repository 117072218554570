var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('table',[_c('thead',[_c('tr',[(_vm.options.withCheckbox)?_c('th',{staticClass:"checkbox-th"}):_vm._e(),_c('th',{staticClass:"index"}),_vm._l((_vm.fields),function(field,index){return _c('th',{key:index,class:[field.class, { 'td--small': _vm.options.withSmallTd }]},[_vm._v(" "+_vm._s(field.title)+" ")])})],2)]),(_vm.loading)?_c('tbody',_vm._l((1),function(index){return _c('tr',{key:index},[(_vm.options.withCheckbox)?_c('td',{staticClass:"checkbox-th"}):_vm._e(),_vm._m(0,true),_c('td',{class:[
            'loader--source',
            { 'source--small': _vm.options.withSmallSource },
          ]},[_c('span',{staticClass:"loader loader--source"})]),(_vm.options.widthPersonCount)?_c('td',{class:{ 'td--small': _vm.options.withSmallTd }},[_c('span',{staticClass:"loader loader--user"})]):_vm._e(),(!_vm.options.withoutSessionDuration)?_c('td',{class:{ 'td--small': _vm.options.withSmallTd }},[_c('span',{staticClass:"loader loader--sessionDuration"})]):_vm._e(),(_vm.options.withAverageSessionDuration)?_c('td',{class:{ 'td--small': _vm.options.withSmallTd }},[_c('span',{staticClass:"loader loader--averageSession"})]):_vm._e(),(_vm.options.withTrafficShareGraph)?_c('td',{staticClass:"traffic-share"},[_c('span',{staticClass:"loader loader--traffic-share"})]):_vm._e(),(_vm.options.withTrafficShare)?_c('td',{class:{ 'td--small': _vm.options.withSmallTd }},[_c('span',{staticClass:"loader loader--traffic-share-small"})]):_vm._e()])}),0):(_vm.data && _vm.data.length > 0)?_c('tbody',_vm._l((_vm.data),function(object,index){return _c('tr',{key:index},[(_vm.options.withCheckbox)?_c('td',{staticClass:"checkbox-th"},[_c('custom-round-checkbox',{attrs:{"id":`${object.ProgramName}-${index}`,"value":object.checked},on:{"changed":function($event){return _vm.$emit('add-tag', object)}}})],1):_vm._e(),_c('td',{staticClass:"index"},[_vm._v(" "+_vm._s(`${index + 1}`)+" ")]),_c('td',{class:[
            'source',
            {
              'source--small': _vm.options.withSmallSource,
            },
          ]},[[(object.URL)?_c('a',{attrs:{"target":"_blank","href":`https://${object.URL}`}},[_c('div',{staticClass:"img-wrapper"},[_c('img',{class:{
                    'more-width': object.moreWidthThanHeight,
                    'more-height': !object.moreWidthThanHeight,
                  },attrs:{"src":_vm.getObjectIcon(object.URL),"alt":""},on:{"load":(e) => _vm.loadHandler(e, object)}})]),_vm._v(" "+_vm._s(object.URL)+" ")]):(!object.URL)?_c('span',[_c('div',{staticClass:"img-wrapper"},[_c('img',{class:{
                    'more-width': object.moreWidthThanHeight,
                    'more-height': !object.moreWidthThanHeight,
                  },attrs:{"src":_vm.getProgramLogo(object.ProgramName),"alt":""},on:{"load":(e) => _vm.loadHandler(e, object),"click":function($event){_vm.imageModalIsActive = true;
                    _vm.currentObject = object;},"error":function($event){object.blankIcon = true}}})]),_vm._v(" "+_vm._s(object.ProgramName)+" ")]):_c('span',{staticClass:"clickable"},[_c('div',{staticClass:"img-wrapper"},[_c('img',{class:{
                    'more-width': object.moreWidthThanHeight,
                    'more-height': !object.moreWidthThanHeight,
                  },attrs:{"src":require('@/assets/icons/icon_traficapp_default.svg'),"alt":""},on:{"load":(e) => _vm.loadHandler(e, object),"click":function($event){_vm.imageModalIsActive = true;
                    _vm.currentObject = object;}}})]),_vm._v(" "+_vm._s(object.ProgramName)+" ")])]],2),(_vm.options.widthPersonCount)?_c('td',{class:['users', { 'td--small': _vm.options.withSmallTd }],on:{"click":function($event){_vm.userPopupActive = true;
            _vm.currentObject = object;
            _vm.currentObjectIndex = index;}}},[_c('span',{staticClass:"user"},[_vm._v(" "+_vm._s(object.PersonCount ? object.PersonCount : object.Count)+" ")])]):_vm._e(),(!_vm.options.withoutSessionDuration)?_c('td',[_vm._v(" "+_vm._s(_vm.timestampToHours(object.SessionDuration))+" ")]):_vm._e(),(_vm.options.withAverageSessionDuration)?_c('td',{class:{ 'td--small': _vm.options.withSmallTd }},[_vm._v(" "+_vm._s(_vm.timestampToHours(object.AverageSessionDuration))+" ")]):_vm._e(),(_vm.options.withTrafficShareGraph)?_c('td',{staticClass:"traffic-share",style:({ width: `${object.width}px` })},[(
              _vm.calculatePercentage(
                object.ActivePersonCount,
                object.PersonCount
              ) > 0
            )?_c('div',{staticClass:"traffic-share__active",style:({
              width: `${_vm.calculatePercentage(
                object.ActivePersonCount,
                object.PersonCount
              )}%`,
            })},[_c('span',{staticClass:"traffic-share__percentage"},[_vm._v(" "+_vm._s(`${Math.floor( _vm.calculatePercentage( object.ActivePersonCount, object.PersonCount ) )}%`)+" ")])]):_vm._e(),(
              100 -
                _vm.calculatePercentage(
                  object.ActivePersonCount,
                  object.PersonCount
                ) >
              0
            )?_c('div',{staticClass:"traffic-share__inactive",style:({
              width: `${
                100 -
                _vm.calculatePercentage(
                  object.ActivePersonCount,
                  object.PersonCount
                )
              }%`,
            })},[_c('span',{staticClass:"traffic-share__percentage traffic-share__percentage--down"},[_vm._v(_vm._s(`${ 100 - Math.floor( _vm.calculatePercentage( object.ActivePersonCount, object.PersonCount ) ) }%`))])]):_vm._e()]):_vm._e(),(_vm.options.withTrafficShare)?_c('td',{class:{ 'td--small': _vm.options.withSmallTd }},[_vm._v(" "+_vm._s(_vm.calculateTrafficShare(object.SessionDuration, object.TotalTime))+" ")]):_vm._e()])}),0):_c('div',{staticClass:"no-record"},[_vm._v("No Records")])]),_c('overlay',{attrs:{"visible":_vm.imageModalIsActive},on:{"close":function($event){_vm.imageModalIsActive = false;
      _vm.file = {};}}},[_c('popup',{attrs:{"title":"Add App Logo","visible":_vm.imageModalIsActive,"flexible-size":true},on:{"close":function($event){_vm.imageModalIsActive = false;
        _vm.file = {};}}},[_c('custom-file-input',{attrs:{"title":"Choose File","value":_vm.file},on:{"change":_vm.changeFile}}),_c('div',{staticClass:"upload-button",on:{"click":_vm.uploadLogo}},[_vm._v("Upload")])],1)],1),(_vm.uploadSuccess)?_c('custom-snackbar',{attrs:{"text":"Logo was uploaded successfully"},on:{"close":function($event){_vm.uploadSuccess = false}}}):_vm._e(),(_vm.userPopupActive)?_c('custom-popup',{attrs:{"visible":_vm.userPopupActive,"flexible-size":true,"title":_vm.currentObject.URL || _vm.currentObject.ProgramName},on:{"close":function($event){_vm.userPopupActive = false;
      _vm.users = [];}}},[_c('div',{staticClass:"users-modal__wrapper"},[_c('div',{staticClass:"users-modal__title-wrapper"},[_c('div',{staticClass:"users-modal__title"},[_vm._v("Users "+_vm._s(_vm.currentObject.Count))]),_c('custom-input',{staticClass:"users-modal__search",attrs:{"title":"Filter by Persons"},model:{value:(_vm.usersModalSearchInput),callback:function ($$v) {_vm.usersModalSearchInput=$$v},expression:"usersModalSearchInput"}})],1),_c('div',{staticClass:"table"},[_c('div',{staticClass:"row row--user-modal headers"},[_c('div',{staticClass:"col"},[_vm._v("#")]),_c('div',{staticClass:"col"},[_vm._v("User")]),_c('div',{staticClass:"col"},[_vm._v("Session Duration")])]),_c('div',{staticClass:"table-body"},[(_vm.computedUsers.length > 0)?_vm._l((_vm.computedUsers),function(user,index){return _c('div',{key:index,staticClass:"row row--user-modal"},[_c('div',{staticClass:"col"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticClass:"col"},[_vm._v(" "+_vm._s(`${user.FirstName} ${user.LastName}`)+" ")]),_c('div',{staticClass:"col"},[_vm._v(" "+_vm._s(_vm.timestampToHours(user.SessionDuration))+" ")])])}):_c('div',{staticClass:"no-data"},[_vm._v("No Records")])],2)])])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"index"},[_c('span',{staticClass:"loader loader--index"})])
}]

export { render, staticRenderFns }