<template>
  <div class="day" :class="{ first: firstInList }">
    <div class="date" v-if="!personsAttendance">
      <span class="week-day">{{ weekDay }}</span>
      <span class="day-and-month">{{ dayMonth }}</span>
    </div>
    <div class="avatar-content" v-else>
      <div
        class="avatar"
        :style="{
          'background-image': `url(https://intranet.upgaming.com/api/Person/GetPersonAvatar2?ID=${personID})`,
        }"
      ></div>
      <div class="name">{{ fullName }}</div>
    </div>

    <div class="performace-grid">
      <div class="times">
        <span
          v-for="(hour, index) in times"
          :key="index"
          class="time"
          :class="{ included: timeIncludedInSchedule(hour) }"
        >
          {{ `${('0' + hour).slice(-2)} : 00` }}
        </span>
      </div>
      <div class="performance-background">
        <p-separator
          color="blue"
          :left="scheduleStart"
          v-if="scheduleStart"
          :isNightTime="isNightSchedule"
        />
        <p-separator
          color="blue"
          :left="scheduleEnd"
          v-if="scheduleEnd"
          :isNightTime="isNightSchedule"
        />
        <div v-if="firstIn !== null" class="p-time" :style="firstInTextStyle">
          {{ firstInString }}
        </div>
        <div v-if="lastOut" class="p-time" :style="lastOutTextStyle">
          {{ lastOutString }}
        </div>

        <template v-if="isPastDay">
          <p-section
            :from="scheduleStart"
            :to="scheduleEnd"
            type="schedule"
            v-if="scheduleStart && scheduleEnd && !vacation"
            :isNightTime="isNightSchedule"
          />
          <!-- went home on another day, overtime -->
          <template v-if="isFirstOut && inOuts.length > 1">
            <p-separator
              color="green"
              :left="0"
              :isNightTime="isNightSchedule"
            />
            <p-section
              type="overtime"
              :from="0"
              :to="isFirstOut"
              :isNightTime="isNightSchedule"
            />
            <p-separator
              color="green"
              :left="isFirstOut"
              :isNightTime="isNightSchedule"
            />
          </template>
          <!-- went home late, overtime -->
          <template
            v-if="
              firstIn !== null &&
              lastOut !== null &&
              firstIn < scheduleEnd &&
              lastOut > scheduleEnd
            "
          >
            <p-separator
              color="green"
              :left="lastOut"
              :isNightTime="isNightSchedule"
            />
            <p-section
              type="overtime"
              :from="scheduleEnd"
              :to="lastOut"
              :isNightTime="isNightSchedule"
            />
          </template>
          <!-- came in early, overtime -->
          <template
            v-if="
              firstIn !== null &&
              lastOut !== null &&
              firstIn < scheduleStart &&
              lastOut > scheduleStart
            "
          >
            <p-separator
              color="green"
              :left="firstIn"
              :isNightTime="isNightSchedule"
            />
            <p-section
              type="overtime"
              :from="firstIn"
              :to="scheduleStart"
              :isNightTime="isNightSchedule"
            />
          </template>
          <!-- went home late, overtime -->
          <template
            v-if="
              firstIn !== null &&
              lastOut !== null &&
              firstIn < scheduleEnd &&
              lastOut > scheduleEnd
            "
          >
            <p-separator
              color="green"
              :left="lastOut"
              :isNightTime="isNightSchedule"
            />
            <p-section
              type="overtime"
              :from="scheduleEnd"
              :to="lastOut"
              :isNightTime="isNightSchedule"
            />
          </template>
          <!-- came in late fixed -->
          <template
            v-if="
              scheduleType === 2 &&
              firstIn !== null &&
              scheduleStart &&
              firstIn > scheduleStart + 15
            "
          >
            <p-separator
              :color="scheduleType === 2 || !vacation ? 'red' : 'green'"
              :left="firstIn"
              :isNightTime="isNightSchedule"
            />
            <p-section
              :type="missed"
              :from="scheduleStart"
              :to="firstIn"
              :isNightTime="isNightSchedule"
            />
          </template>
          <!-- came in late -->
          <template
            v-if="
              scheduleType !== 2 &&
              firstIn !== null &&
              firstIn > scheduleStart &&
              firstIn < scheduleEnd
            "
          >
            <p-separator
              :color="scheduleType === 2 || !vacation ? 'red' : 'green'"
              :left="firstIn"
              :isNightTime="isNightSchedule"
            />
            <p-section
              type="missed"
              :from="scheduleStart"
              :to="firstIn"
              :isNightTime="isNightSchedule"
            />
          </template>
          <!-- went home early fixed missed the hours -->
          <template
            v-if="
              scheduleType === 2 &&
              lastOut &&
              lastOut > scheduleStart &&
              lastOut < scheduleEnd - 15
            "
          >
            <p-separator
              :color="scheduleType == 2 || !vacation ? 'red' : 'green'"
              :left="lastOut"
              :isNightTime="isNightSchedule"
            />
            <p-section
              :type="!isToday ? 'missed' : 'today'"
              :from="lastOut"
              :to="scheduleEnd"
              :isNightTime="isNightSchedule"
            />
            <!-- is forgiven because came in office early enough -->
            <!-- <template v-else>
                <p-section
                    type="blank"
                    :from="lastOut"
                    :to="scheduleEnd"
                    :isNightTime="isNightSchedule"
                />
            </template> -->
          </template>
          <!-- went home early, missed the hours -->
          <template
            v-if="
              scheduleType !== 2 &&
              lastOut &&
              lastOut > scheduleStart &&
              lastOut < scheduleEnd
            "
          >
            <p-separator
              :color="scheduleType == 2 || !vacation ? 'red' : 'green'"
              :left="lastOut"
              :isNightTime="isNightSchedule"
            />
            <p-section
              :type="!isToday ? 'missed' : 'today'"
              :from="lastOut"
              :to="scheduleEnd"
              :isNightTime="isNightSchedule"
            />
            <!-- is forgiven because came in office early enough -->
            <!-- <template v-else>
                <p-section
                    type="blank"
                    :from="lastOut"
                    :to="scheduleEnd"
                    :isNightTime="isNightSchedule"
                />
            </template> -->
          </template>
          <!-- didn't have a schedule but worked anyway (overtime) -->
          <template
            v-if="
              (!scheduleStart || !scheduleEnd) &&
              firstIn !== null &&
              lastOut !== null
            "
          >
            <p-section
              :from="firstIn"
              :to="lastOut"
              type="overtime"
              :isNightTime="isNightSchedule"
            />
            <p-separator
              color="green"
              :left="firstIn"
              :isNightTime="isNightSchedule"
            />
            <p-separator
              color="green"
              :left="lastOut"
              :isNightTime="isNightSchedule"
            />
          </template>
          <!-- worked completely different hours -->
          <template
            v-if="
              scheduleStart !== null &&
              scheduleEnd !== null &&
              firstIn !== null &&
              lastOut !== null &&
              ((firstIn < scheduleStart && lastOut < scheduleStart) ||
                (firstIn > scheduleEnd && lastOut > scheduleEnd))
            "
          >
            <p-section
              :type="personsAttendance && isToday ? 'today' : 'missed'"
              :from="scheduleStart"
              :to="scheduleEnd"
              :isNightTime="isNightSchedule"
            />
            <p-section
              type="overtime"
              :from="firstIn"
              :to="lastOut"
              :isNightTime="isNightSchedule"
            />
            <p-separator
              :left="firstIn"
              color="green"
              :isNightTime="isNightSchedule"
            />
            <p-separator
              :left="lastOut"
              color="green"
              :isNightTime="isNightSchedule"
            />
          </template>
          <!-- inactivities -->
          <template v-for="(inactivity, index) in inactivities">
            <p-section
              v-if="inactivity.duration > 60"
              :key="index"
              type="inactive"
              :from="inactivity.start / 60"
              :to="(inactivity.start + inactivity.duration) / 60"
              :isNightTime="isNightSchedule"
            />
          </template>
          <!-- in-outs -->
          <template v-for="(pair, index) in outInPairs">
            <p-section
              :key="index"
              type="out"
              :from="pair.from"
              :to="pair.to"
              v-if="pair.to - pair.from > 1"
              :isNightTime="isNightSchedule"
            />
          </template>
          <!-- missed a whole day -->
          <template
            v-if="
              (firstIn === null || lastOut === null) &&
              !vacation &&
              scheduleStart &&
              scheduleEnd
            "
          >
            <p-section
              type="missed"
              :from="scheduleStart"
              :to="scheduleEnd"
              :isNightTime="isNightSchedule"
            />
          </template>
          <!-- special days (holiday, dayoff, sickday, vacation)-->
          <template v-if="vacation && scheduleStart && scheduleEnd">
            <p-section
              :from="scheduleStart"
              :to="scheduleEnd"
              type="vacation"
              :text="vacation"
              :isNightTime="isNightSchedule"
            />
          </template>
          <!-- missed a special day but had flexible schedule -->
          <template
            v-if="
              scheduleType === 2 &&
              vacationType === 2 &&
              scheduleStart &&
              scheduleEnd &&
              (firstIn === null || lastOut === null)
            "
          >
            <p-section
              :from="scheduleStart"
              :to="scheduleEnd"
              type="missed"
              :isNightTime="isNightSchedule"
            />
          </template>
        </template>
      </div>
    </div>
    <one-day-totals
      :scheduleStart="scheduleStart"
      :scheduleEnd="scheduleEnd"
      :breakDuration="breakDuration"
      :scheduleType="scheduleType"
      :isVacation="!!vacation"
      :isvacationType="vacationType"
      :inOuts="inOuts"
      :inactivities="inactivities"
      :totalAttendance="totalAttendance"
      :totalActive="totalActive"
      :plannedTime="plannedTime"
      :firstIn="firstIn"
      :lastOut="lastOut"
      :outIns="outInPairs"
      :firstInList="firstInList"
      :totalWork="totalWork"
      :idToSend="idToSend"
      :scheduleId="scheduleId"
      @notype="$emit('notype')"
    >
      <custom-checkbox
        title="By clicking this check-box,  command will recalculate attendance time, to count overtime for this day"
        :value="isRecalculated"
        @input="checkboxClickHandler"
      />
    </one-day-totals>
  </div>
</template>

<script>
import PSeparator from './Separator'
import PSection from './Section'
import OneDayTotals from './OneDayTotals'
import CustomCheckbox from '@/components/ReusableComponents/CustomCheckbox/CustomCheckbox'

export default {
  components: {
    PSeparator,
    PSection,
    OneDayTotals,
    CustomCheckbox,
  },
  props: {
    date: Date,
    scheduleStart: Number, //minutes
    scheduleEnd: Number, //minutes
    breakDuration: Number, //minutes
    scheduleType: Number,
    endTimeString: String,
    startTimeString: String,
    vacation: String,
    fullName: String,
    totalAttendance: Number,
    isRecalculated: Boolean,
    plannedTime: Number,
    totalActive: Number,
    inOuts: Array,
    personsAttendance: Boolean,
    inactivities: Array,
    firstInList: Boolean,
    vacationType: Number,
    totalWork: Number, //minues
    idToSend: [Number, String],
    scheduleId: [Number, String],
    personID: Number,
  },
  data() {
    return {
      /*
          If a person has a fixed schedule and came in office before 'forgivingMinutesToCome',
          it's not considered as late
      */
      forgivingMinutesToCome: 12 * 60, //12:00
      checked: false,
    }
  },
  methods: {
    checkboxClickHandler(val) {
      this.$confirm('Are you sure, you want to recalculate attendance?').then(
        (resp) => {
          this.checked = val
          this.$emit('checked-attendance', val, this.date)
        },
      )
    },
    isMoreThanTwelveHour(timeString) {
      if (!timeString) return
      const time = timeString.split(':')

      const startDate = new Date()
      startDate.setHours(time[0])
      startDate.setMinutes(time[1])
      startDate.setSeconds(time[2])
      return new Date() > startDate
    },

    timeIncludedInSchedule(hour) {
      if (!this.scheduleStart && !this.scheduleEnd) return false
      var inMinutes = hour * 60
      return inMinutes >= this.scheduleStart && inMinutes <= this.scheduleEnd
    },
    adjust(time) {
      return (time + 12 * 60) % (24 * 60)
    },
  },
  computed: {
    firstInTextStyle() {
      let furthestLeft = this.scheduleStart
        ? Math.min(this.firstIn, this.scheduleStart)
        : this.firstIn
      return {
        left:
          ((this.isNightSchedule ? this.adjust(furthestLeft) : furthestLeft) *
            100) /
            (24 * 60) -
          5 +
          '%',
      }
    },
    isToday() {
      var d = new Date()
      return (
        d.getFullYear() == this.date.getFullYear() &&
        d.getMonth() == this.date.getMonth() &&
        d.getDate() == this.date.getDate()
      )
    },

    lastOutTextStyle() {
      let furthestRight = Math.max(this.lastOut, this.scheduleEnd)
      return {
        left:
          ((this.isNightSchedule ? this.adjust(furthestRight) : furthestRight) *
            100) /
            (24 * 60) +
          0.7 +
          '%',
      }
    },

    firstInString() {
      return `${parseInt(this.firstIn / 60)}h ${this.firstIn % 60}m`
    },
    lastOutString() {
      return `${parseInt(this.lastOut / 60)}h ${this.lastOut % 60}m`
    },
    isPastDay() {
      if (this.personsAttendance) return true
      return this.date.setHours(23, 59, 59, 0) < new Date().getTime()
    },
    weekDay() {
      const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
      return days[this.date.getDay()]
    },
    dayMonth() {
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      return `${this.date.getDate()} ${months[this.date.getMonth()]}`
    },
    firstIn() {
      if (!this.inOuts || !this.inOuts.length) return null
      var found = this.inOuts.find((el) => el.type == 1)
      if (!found) return null
      var first = new Date(found.time)
      const firstInTime = first.getHours() * 60 + first.getMinutes()
      return firstInTime === 0 ? 1 : firstInTime
    },
    lastOut() {
      if (!this.inOuts || !this.inOuts.length) return null
      var found = [...this.inOuts].reverse().find((el) => el.type == 2)
      if (!found) return null
      var last = new Date(found.time)
      return last.getHours() * 60 + last.getMinutes()
    },
    outInPairs() {
      const alreadyPairs = []
      if (!this.inOuts) return []
      var pairs = []
      var out = null
      if (this.inOuts.length >= 2) {
        // case - first in 00:00:00 and second element is in also
        const firstEl = this.inOuts[0]
        const lastEl = this.inOuts[this.inOuts.length - 1]
        if (firstEl.type === 1) {
          const d = new Date(this.inOuts[0].time)
          if (
            d.getHours() === 0 &&
            d.getMinutes() === 0 &&
            d.getSeconds() === 0
          ) {
            if (this.inOuts[1].type === 1) {
              alreadyPairs.push(this.inOuts[0])
              alreadyPairs.push(this.inOuts[1])
              const fD = new Date(firstEl.time)
              const sD = new Date(this.inOuts[1].time)
              pairs.push({
                from: fD.getHours() * 60 + fD.getMinutes(),
                to: sD.getHours() * 60 + sD.getMinutes(),
              })
            }
          }
        }
        if (lastEl.type === 2) {
          const d = new Date(lastEl.time)
          if (
            d.getHours() === 23 &&
            d.getMinutes() === 59 &&
            d.getSeconds() === 59
          ) {
            if (this.inOuts[this.inOuts.length - 2].type === 2) {
              alreadyPairs.push(this.inOuts[this.inOuts.length - 1])
              alreadyPairs.push(this.inOuts[this.inOuts.length - 2])
              const fD = new Date(lastEl.time)
              const sD = new Date(this.inOuts[this.inOuts.length - 2].time)
              pairs.push({
                to: fD.getHours() * 60 + fD.getMinutes(),
                from: sD.getHours() * 60 + sD.getMinutes(),
              })
            }
          }
        }
      }
      this.inOuts.forEach((entry) => {
        if (alreadyPairs.includes(entry)) return

        if (entry.type == 1) {
          if (out) {
            let d = new Date(entry.time)
            let inMinutes = d.getHours() * 60 + d.getMinutes()
            pairs.push({ from: out, to: inMinutes })
            out = null
          }
        } else {
          let d = new Date(entry.time)
          out = d.getHours() * 60 + d.getMinutes()
        }
      })

      return pairs
    },
    isNightSchedule() {
      return false
    },
    times() {
      return Array.from(Array(25)).map((el, i) => i)
    },
    isFirstOut() {
      if (
        this.inOuts &&
        this.inOuts.length &&
        this.inOuts[0] &&
        this.inOuts[0].type === 2
      ) {
        const time = new Date(this.inOuts[0].time)
        return time.getHours() * 60 + time.getMinutes()
      }
      return false
    },
  },
}
</script>

<style scoped lang="scss">
.day {
  display: flex;
  border-bottom: $rem solid #dedee4;
  overflow: hidden;
  height: 105 * $rem;

  &.first {
    height: 138 * $rem;

    .performace-grid {
      padding-top: 15 * $rem;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  .date,
  .avatar-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 105 * $rem;
    background-color: #fff;
    flex-direction: column;
    box-shadow: 0.375rem 0 1rem rgba(40, 52, 147, 0.08);
    z-index: 1;
    overflow: hidden;

    .week-day {
      font-weight: 300;
      font-size: 15 * $rem;
      color: #7880be;
    }

    .day-and-month {
      font-weight: 400;
      font-size: 15 * $rem;
      color: $blue-primary;
    }
    .avatar {
      width: 70px;
      height: 70px;
      border-radius: 100%;
      background-color: #1de9b6;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .name {
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      font-size: 14 * $rem;
      color: $blue-primary;
      text-align: center;
      margin-top: 5px;
    }
  }
  .avatar-content {
    width: 145 * $rem;
  }

  .performace-grid {
    width: 100%;
    background-color: #f7f7fe;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    $grid-width: 1075 * $rem;

    .times {
      margin-left: 15 * $rem;

      .time {
        display: inline-block;
        font-weight: 300;
        width: 35 * $rem;
        text-align: center;
        font-size: 11 * $rem;
        color: rgba($blue-primary, 0.4);
        margin-right: calc((100% - 82px - (24 * 35 *#{$rem})) / 24);

        &:last-child {
          margin-right: none;
        }

        &.included {
          color: rgba($blue-primary, 0.7);
        }
      }
    }

    .performance-background {
      background-color: #ebebf6;
      margin-left: 30 * $rem;
      width: calc(100% - 95px);
      height: 75 * $rem;
      position: relative;

      .p-time {
        color: #9b9ba5;
        font-weight: 300;
        font-size: 12 * $rem;
        position: absolute;
        display: flex;
        align-items: center;
        height: 100%;
        top: 0;
      }
    }
  }
}
</style>
