<template>
  <div class="background">
    <div
      class="choose-page"
      :style="{ 'background-image': `url(${background})` }"
    >
      <div
        v-for="(page, index) in links"
        :key="index"
        @click="$router.push(page.url)"
        class="page"
      >
        {{ page.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    links: Array,
    background: String,
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

.choose-page {
  height: 100%;
  background-repeat: no-repeat;
  position: relative;
  //   top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .page {
    text-transform: uppercase;
    font-family: "Anton", sans-serif;
    font-size: 56 * $rem;
    color: rgba($blue-primary, 0.5);
    width: 400 * $rem;
    height: 160 * $rem;
    border-radius: 10 * $rem;
    background-color: #fefeff;
    box-shadow: $box-shadow2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-top: 40 * $rem;
    cursor: pointer;
    &:nth-child(1) {
      margin-top: 0;
    }
    &:hover {
      border: $rem solid $green-primary;
    }
  }
}

.background {
  padding: 0 4.0625rem 0 0;
}
</style>
