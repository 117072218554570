<template>
    <div class="background">
        <custom-snackbar
            :text="snackbar.text"
            :purpose="snackbar.purpose"
            v-if="snackbar.visible"
            @close="snackbar.visible = false"
        />
        <div class="top-menu">
            <switch-header  :routes="routes" class="switch-header"/>
        </div>
        <div class="content-wrapper">
            <custom-loader v-if="loading" />
            <div class="no-records" v-if="usersData.length < 1 && !loading" >
                <span>There are no Records</span>
            </div>
            <custom-popup
                :visible="editUserVisible"
                @close="editUserVisible = false"
                :title="'Edit User'"
                class="editPopup"
            > 
                <div class="wrapper">
                    <form @submit.prevent="updateUserData(editUserInteralID)">
                        <ul>
                            <li v-for="(i,ind) in editUserFields" :key="ind" v-show="i.typeName !== 'email'">
                                <custom-input 
                                    :title="i.title"
                                    v-model="i.value"
                                    v-if="i.inputType === 'text' || i.inputType === 'number'"
                                    :inputType="i.inputType"
                                    :disabled="i.readOnly"
                                    :alternativeDisable="true"
                                />
                                <custom-select 
                                    :title="i.title"
                                    :options="i.options"
                                    v-model="i.selected"
                                    v-if="i.inputType === 'select'"
                                    @select="filterPackageValues(true,i.selected,i.typeName)"
                                />
                                <div class="children-wrap" v-if="i.children">
                                    <div class="wrapper" v-for="(child,ind) in i.children" :key="ind">
                                        <custom-input 
                                            :title="child.title"
                                            v-model="child.value"
                                            v-if="child.inputType === 'text'"
                                            :disabled="child.readOnly"
                                            :alternativeDisable="true"
                                        />
                                    </div>
                                </div>
                            </li>
                            <li class="submit">
                                <button type="submit">
                                    Save
                                </button>
                            </li>
                        </ul>
                    </form>
                </div>

            </custom-popup>
            <div class="content-header">
                <div class="top">
                    <div class="header-filters">
                        <ul>
                            <li class="assign">
                                <div ref="packageBtn" class="purple-btn assign-package" @click="packageListVisible = !packageListVisible">
                                    <img :class="{'down':packageListVisible}" src="@/assets/grades/add.svg" alt="add">
                                    <span class="">Package</span>
                                </div>  
                                <div ref="packages" class="packages" v-show="packageListVisible">
                                    <div class="no-list-item" v-if="packageList.length < 1">
                                        <span>
                                            No Packages
                                        </span>
                                    </div>
                                    <ul>
                                        <li class="search-package noHover"> 
                                            <input type="text" v-model="searchPackageList" placeholder="search">
                                        </li>
                                        <li v-show="filterPackageList(p.title) && p.title !== 'Blank'" v-for="(p,ind) in packageList" :key="ind" @click="assignPackage(p.value)"> 
                                            <span>
                                                {{p.title}}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="filters" v-for="(f,ind) in filters" :key="ind">
                                <!-- <custom-select
                                    :title="f.title"
                                    :options="f.options"
                                    v-model="f.selected"
                                    :clearVisible="true"
                                    @select="getUsers()"
                                    v-if="f.type === 'select' && f.filterType !== 1 && f.filterType !== 4"
                                /> -->
                                <input-search
                                    v-if="f.type !== 'date' "
                                    @select="(val) => (f.selected = val,getUsers())"
                                    :options="f.options"
                                    :title="f.title"
                                    :valueCleared="clearChildCompValues"
                                />

                                <date-range
                                    @from="(from) => f.from = from"
                                    @to="(to) => f.to = to"
                                    :initialFrom="f.from"
                                    :initialTo="f.to"
                                    v-if="f.type === 'date'"
                                    @change="getUsers(f.from,f.to)"
                                />
                            </li>
                            <li class="clear-filters" @click.stop="clearAllFilters">
                                <div>
                                    <span>Clear Filters</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="middle">
                <div class="users-list">
                    <ul>
                        <li :class="['list-header','noHover']">
                            <div :class="['header-col-title',title.class]" v-for="(title,ind) in listColumnTitles" :key="ind">
                                <div class="checkbox" v-if="title.title === 'User'">
                                    <check-box
                                        :checked="selectAllPersons"
                                        @value="(val) => selectAllPersons = val"
                                    />
                                </div>
                                <span>{{title.title}}</span>
                            </div>
                        </li>
                        <li :class="['list-item',{'active':user.toolbarVisible}]" v-for="(user,ind) in usersData" :key="ind">
                            <div class="main-content">
                                <div class="col-value ind">
                                    <span>
                                        {{ind + 1}}
                                    </span>
                                </div>
                                <div :class="['col-value', 'user',{'insert_user':user.StatusName === 'Pending' || user.statusTypeID === 3}]">
                                    <div class="checkbox">
                                        <check-box
                                            :checked="user.selected"
                                            @value="(state) => user.selected = !user.selected"
                                        />
                                    </div>
                                    <span :title="user.first_name && user.last_name ? `${user.first_name } ${user.last_name }` : ''" @click="collapseUserslist(user.dropdownVisible,user.id)">
                                        {{user.first_name && user.last_name ? `${user.first_name } ${user.last_name }` : '-'}}
                                    </span>
                                </div>
                                <div class="col-value register-date">
                                    <span>
                                        {{user.RegisterDate ?  dateSting(user.RegisterDate) : '-'}}
                                    </span>
                                </div>
                                <div class="col-value quickpick-id">
                                    <span>
                                        {{user.id}}
                                    </span>
                                </div>
                                <div class="col-value email" :title="user.email">
                                    <span>
                                       {{user.email ? user.email : '-'}}
                                    </span>
                                </div>
                                <div class="col-value active-package" :title="user.LimitGroupName">
                                    <span>
                                        {{user.LimitGroupName ? user.LimitGroupName : '-'}}
                                    </span>
                                </div>
                                <div class="col-value block-limit">
                                    <span>
                                        {{user.RequestBlockLimit ? `${user.RequestBlockLimit}GEL` : '-'}}
                                    </span>
                                </div>
                                <div class="col-value status">
                                    <span :class="
                                        [
                                            {'active':user.StatusName === 'Active'},
                                            {'blocked':user.StatusName === 'Blocked'},
                                            {'pending':user.StatusName === 'Pending'},
                                            {'noStatus': user.StatusName === 'Former'}
                                        ]
                                    ">
                                        {{
                                            user.StatusName
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="toolbar">
                                <div class="dots" @click.stop="toolbar(user.id)">
                                    <img src="@/assets/icons/toolbardots-gray.svg" alt="">
                                </div>
                                <div v-if="user.toolbarVisible" class="actions">
                                    <!-- <div class="delete">
                                        <img src="@/assets/grades/trash.svg" alt="">
                                    </div> -->
                                    <div class="edit" @click="editUser(user.PersonInternalID,user.id)">
                                        <img src="@/assets/grades/edit.svg" alt="">
                                    </div>
                                    <div v-if="$store.getters.hasRole('HR')" @click="blockUser(user.id,user.StatusName)" class="block">
                                        <img v-if="user.StatusName === 'Active'" class="block-img" src="@/assets/block.svg" alt="">
                                        <img v-if="user.StatusName === 'Blocked'" class="unblock-img" src="@/assets/icons/unblock.svg" alt="">
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SwitchHeader from '@/components/ReusableComponents/SwitchHeader/SwitchHeader.vue'
import CustomSelect from '@/components/ReusableComponents/CustomSelect/CustomSelect.vue'
import CheckBox from '@/components/Grades/GradeComponents/CheckBox.vue'
import CustomPopup from '@/components/ReusableComponents/CustomPopup/CustomPopup.vue'
import CustomInput from '@/components/ReusableComponents/CustomInput/CustomInput.vue'
import axios from "@/Helpers/AxiosInstance.js";
import CustomLoader from '@/components/ReusableComponents/Loader/CustomLoader.vue'
import { getDateString } from "@/Helpers/HelperFunctions";
import DateRange from "@/components/ReusableComponents/DateRange/DateRange.vue"
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'



import InputSearch from '@/components/ReusableComponents/InputSearch/InputSearch.vue'

// import DateRange from "@/components/ReusableComponents/DateRange/DateRange.vue"


export default {
    components:{
        SwitchHeader,
        CustomSelect,
        CheckBox,
        CustomPopup,
        CustomInput,
        CustomLoader,
        InputSearch,
        DateRange,
        CustomSnackbar,
    },
    data(){
        return{
            snackbar:{
                text:'',
                purpose: '',
                visible: false,
            },
            routes:[
                {
                    title: 'Packages',
                    path: '/VendingPackages',
                    hidden: this.$store.getters.hasRole('Member') || this.$store.getters.hasRole('Lawyer'),
                },
                {
                    title: 'Users',
                    path: '/Users',
                    hidden: this.$store.getters.hasRole('Member') || this.$store.getters.hasRole('Lawyer'),
                },
                {
                    title: 'Transactions',
                    path: '/Transactions'
                }
            ],
            filters:[
                {
                    title:'User',
                    options: [],
                    selected: null,
                    type: 'select',
                    filterType: 1,
                },
                {
                    title:'Register Date',
                    options: [],
                    selected: null,
                    type: 'date',
                    from: null,
                    to: null,
                    filterType: 2,
                },
                {
                    title:'QuicPick ID',
                    options: [],
                    selected: null,
                    type: 'select',
                    filterType: 3,
                },
                {
                    title:'Email',
                    options: [],
                    selected: null,
                    type: 'select',
                    filterType: 4,
                },
                {
                    title:'Package',
                    options: [],
                    selected: null,
                    type: 'select',
                    filterType: 5,
                },
                {
                    title:'Status',
                    options: [],
                    selected: null,
                    type: 'select',
                    filterType: 6,
                }
            ],
            listColumnTitles: [
                {
                    title: "#",
                    class: "ind",                   
                },
                {
                    title: "User",
                    class: "user",
                },
                {
                    title: "Register Date",
                    class: "register-date",
                },
                {
                    title: "QuicPik ID",
                    class: "quickpick-id",                   
                },
                {
                    title: "Email",
                    class: "email",                  
                },
                {
                    title: "Active Package",
                    class: "active-package",
                },
                {
                    title: "Block Limit",
                    class: "block-limit",                 
                },
                {
                    title: "Status",
                    class: "status",                 
                }
            ],
            editUserInteralID: null,
            packageData: [],
            editUserFields: [
                {
                    title: 'Name Surname',
                    inputType: 'text',
                    value: '',
                    readOnly: true,
                    typeName:'name'
                },
                {
                    title: 'Register Date',
                    inputType: 'text',
                    value: '',
                    readOnly: true,
                    typeName:'registerDate',
                },
                {
                    title: 'QuickPik ID',
                    inputType: 'text',
                    value: '',
                    readOnly: true,
                    typeName:'quickPikID'

                },
                {
                    title: 'Email',
                    inputType: 'text',
                    value: '',
                    readOnly: true,
                    typeName:'email'
                },
                {
                    title: 'Active Package',
                    inputType: 'select',
                    options: [],
                    selected: null,
                    value: '',
                    readOnly: false,
                    typeName:'package',
                    children: [
                        {
                            title:'Discount %',
                            inputType: 'text',
                            value: '',
                            readOnly: true,
                            typeName:'discount',

                        },
                        {
                            title:'Daily Limit(GEL)',
                            inputType: 'text',
                            value: '',
                            readOnly: true,
                            typeName:'dailylimit',
                        },
                        {
                            title:'Monthly Limit(GEL)',
                            inputType: 'text',
                            value: '',
                            readOnly: true,
                            typeName:'monthlylimit',
                        }
                    ]
                },
                {
                    title: 'Block Limit(GEL)',
                    inputType: 'number',
                    value: '',
                    readOnly: false,
                    typeName:'blocklimit',
                },
                {
                    title: 'Status',
                    inputType: 'select',
                    selected: null,
                    options: [
                        {
                            title: 'Active',
                            value: 1,
                        },
                        {
                            title: 'Blocked',
                            value: 3,
                        }
                    ],
                    value: '',
                    readOnly: false,
                    typeName:'status',
                },      

            ],
            selectAllPersons: false,
            editUserVisible: false,
            packageListVisible: false,
            searchPackageList: '',
            packageList:[],
            usersData:[],
            usersDataForFilters: [],
            loading:false,
            personsList: [],
            clearChildCompValues: false,
        }
    },
    watch:{
        selectAllPersons(val){
            if(val){
                this.usersData.forEach(el => {
                    el.selected = true
                })
            }
            if(!val){
                this.usersData.forEach(el => {
                    el.selected = false
                })
            }
        },
    },
    async created(){
        await this.getPackages()
        await this.getUsers()
        await this.getUsersForFilters()
        document.addEventListener("click", this.clickHandler);
    },
    methods:{
        snackbarMessage(text,purpose){
            this.snackbar.visible = true
            this.snackbar.text = text
            this.snackbar.purpose = purpose
        },
        async getUsers(from,to){
            this.loading = true
            await axios.get('salary/GetQuickPickUsers',{
                params:{
                   FromDate: from ? getDateString(from) : null,
                   ToDate: to ? getDateString(to) : null,
                }
            }).then(resp => {
                this.loading = false
                let data = resp.data.Value

                let filterAvailable = this.filters.some(el => el.selected)
                if(filterAvailable){
                    let filteredFields =  this.filters.filter(el => el.selected)
                    filteredFields.forEach(el => {
                        if(el.filterType === 3) data = data.filter(user => user.id === el.selected)
                        if(el.filterType === 1 && el.selected === 'blankName') data = data.filter(user => user.first_name === null)
                        if(el.filterType === 1 && el.selected !== 'blankName') data = data.filter(user => user.id === el.selected)
                        if(el.filterType === 6) data = data.filter(user => user.Status === el.selected)
                        if(el.filterType === 4 && el.selected ==='blankEmail') data = data.filter(user => !user.email)
                        if(el.filterType === 4 && el.selected !== 'blankEmail') data = data.filter(user =>  user.id === el.selected)
                        if(el.filterType === 5){
                            if(el.selected === 'blank') {
                                data = data.filter(user => user.LimitGroupID === 0 || !user.LimitGroupID || user.LimitGroupID === null)
                            }else{
                                data = data.filter(user => user.LimitGroupID === el.selected)
                            }
                        } 
                    })
                }

                data.forEach(el => {
                    this.$set(el,'toolbarVisible',false)
                    this.$set(el,'selected',false)
                    this.$set(el,'dropdownVisible',false)

                    if(el.Status === 1) this.$set(el,'StatusName','Active')
                    if(el.Status === 2) this.$set(el,'StatusName','Pending')
                    if(el.Status === 3) this.$set(el,'StatusName','Blocked')
                    if(el.Status === 4) this.$set(el,'StatusName','Former')

                })
                this.usersData = data
                // this.setUserFilterOptions()
                // this.setUserEmails()
                // this.setQuickPickIDs()
                // this.setStatusOptions()
            }).catch(err => {
                this.loading = false
            })
        },
        async getUsersForFilters(){
            axios.get('salary/GetQuickPickUsers').then(resp => {
                let data = resp.data.Value

                this.usersDataForFilters = data
                this.setUserFilterOptions()
                this.setUserEmails()
                this.setQuickPickIDs()
                this.setStatusOptions()
            })
        },
        editUser(internalID,pickID){
            this.editUserVisible = true  
            this.editUserInteralID = internalID

            if(internalID !== 0 && internalID){
                this.editUserFields.find(el => el.typeName === 'email').readOnly = false
            }else{
                this.editUserFields.find(el => el.typeName === 'email').readOnly = true
            }


            this.filterPackageValues(false)
            this.editUserFields.forEach(el => {
                if(el.inputType === 'text' || el.inputType === 'number'){
                    el.value = ''
                }
                if(el.inputType === 'select'){
                    el.selected = null
                    if(el.typeName === 'status'){
                        let status = this.usersData.find(el => el.PersonInternalID === internalID).Status
                        el.selected = status && status === 1 ? 1 : status && status === 3 ? 3 : null
                    }
                }
                if(el.children){
                    el.children.forEach(child => {
                        child.value = ''
                    })
                }
            })

            let person = this.usersData.filter(el => el.id === pickID)[0]
            this.editUserFields.forEach(el => {
                let type = el.typeName 
                if(type === 'name') {
                    if(person.first_name === null || person.last_name === null){
                        el.value = ''
                        el.readOnly = true
                    }else{
                        el.value = person.first_name + ' ' + person.last_name
                    }
                }
                if(type === 'registerDate') el.value = person.RegisterDate ? getDateString(person.RegisterDate,'dd-MM-yyyy') : '-'
                if(type === 'quickPikID') el.value = person.id
                if(type === 'email') el.value = person.email ? person.email : ''
                if(type === 'package'){
                    el.selected = person.LimitGroupID ? person.LimitGroupID : null
                    if(person.LimitGroupID !== 0 || !person.LimitGroupID) this.filterPackageValues(true,el.selected,'package')
                }
                if(type === 'blocklimit') el.value = person.RequestBlockLimit ? person.RequestBlockLimit : null
                if(type === 'status') el.value = person.StatusName
            })

        },
        clickHandler(event){
            if(event.path.includes(this.$refs.packages) || event.path.includes(this.$refs.packageBtn)) return
            this.packageListVisible = false
            this.usersData.forEach(el => {
                el.dropdownVisible = false
            })
        },
        assignPackage(id){
            let noneSelected = this.usersData.every(el => !el.selected)
            if(noneSelected) return this.snackbarMessage('Please Select Users','error')
            let users = []
            let data = []
            this.usersData.forEach(el => {
                if(el.selected){
                    if(el.PersonInternalID === null || !el.PersonInternalID || el.PersonInternalID === 0){
                        return
                    }else{
                        users.push(el.PersonInternalID)
                    }
                }
            })
            users.forEach(el => {
                let obj = {
                    entityID: el,
                    entityTypeID: 1,
                }
                data.push(obj)
            })
            this.$confirm('Would You Like To Assign This Package').then(() => {
            this.loading = true
                axios.post('salary/CreateQuickPickLimit',{
                    quickPickLimitID: id,
                    limitEntities: data,
                }).then(resp => {
                    this.loading = false
                    if(!resp.data.ErrorMsg){
                        this.packageListVisible = false
                        this.snackbarMessage('Package Assigned Successfully','success')
                        this.getUsers()
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                }).catch(err => {
                    this.loading = false
                })
            })
        },
        filterPackageList(title){
            let state = false            
            if(title.toLowerCase().split(' ').join('').includes(this.searchPackageList.toLowerCase().split(' ').join(''))){
                state = true
            }else state = false
            return state
        },
        async getPackages(){
            // this.loading = true
            await axios.get('salary/GetQuickPickLimitGroups',{
                params:{
                    SearchTerm: this.searchPackage ? this.searchPackage : null,
                }
            }).then(resp => {
                // this.loading = false
                let data = resp.data.Value

                this.packageList = data.map(el => ({
                    title: el.LimitGroupName || 'Title Not Available',
                    value: el.LimitGroupID,
                }))

                this.editUserFields.find(el => el.typeName === 'package').options = data.map(el => ({
                    title: el.LimitGroupName || 'Title Not Available',
                    value: el.LimitGroupID,
                }))

                this.packageList.unshift({
                    title: 'Blank',
                    value: 'blank',
                })

                this.filters.find(el => el.filterType === 5).options = this.packageList
                this.packageData = data
                if(!resp.data.ErrorMsg && this.packageList.length < 1){
                    // this.alternateText = true
                }else{
                    // this.alternateText = false
                }
            })
        },
        toolbar(id){
            this.usersData.forEach(el => {
                if(el.id === id) el.toolbarVisible = !el.toolbarVisible
                else el.toolbarVisible = false
            })
        },
        setUserFilterOptions(){
            let users = this.usersDataForFilters.map(el => ({
                title: el.first_name + ' ' + el.last_name,
                value: el.id
            }))
            users.unshift({
                title: 'Not Matched',
                value: 'blankName'
            })
            let lostUserData = users.some(el => el.title === null || !el.title || el.title === undefined || el.title === "null null")
            if(lostUserData){
                users = users.filter(el => el.title !== "null null")   
            }
            users = [...new Map(users.map(e=> [e['value'], e])).values()]
            this.filters.find(e => e.filterType === 1).options = users
        },
        setUserEmails(){
            let emails = this.usersDataForFilters.map(el => ({
                title: el.email,
                value: el.id,
            }))
            emails.unshift({
                title: 'Not Matched',
                value: 'blankEmail',
            })
            let lostUserData = emails.some(el => el.title === null || !el.title || el.title === undefined || el.title === "")
            if(lostUserData){
                emails = emails.filter(el => el.title !== null)
            }
            emails = [...new Map(emails.map(e=> [e['value'], e])).values()]
            this.filters.find(e => e.filterType === 4).options = emails
        },
        setQuickPickIDs(){
            let ids = this.usersDataForFilters.map(el => ({
                title: `# ${el.id}`,
                value: el.id,
            }))
            ids = [...new Map(ids.map(e=> [e['value'], e])).values()]
            this.filters.find(e => e.filterType === 3).options = ids
        },
        // userStatus(status,fName,lName,mail,id,call){
        //     let state
        //     let typeID
        //     if(status){
        //         state = 'Blocked'
        //         typeID = 1
        //     }
        //     if(!status){
        //         state = 'Active'
        //         typeID = 2
        //     }
        //     if(fName === null && mail || lName === null && mail){
        //         state = 'Pending'
        //         typeID = 3
        //     }
        //     if(fName === null && mail === null || lName === null && mail === null){
        //         state = 'No Status'
        //         typeID = 4
        //     }

        //     this.usersData.find(el => el.id === id)['statusName'] = state
        //     this.usersData.find(el => el.id === id)['statusTypeID'] = typeID

        //     return state
        // },
        setStatusOptions(){
            let statuses = ['Active','Pending','Blocked','Former']
            let options = []
            statuses.forEach((el,ind) => {
                options.push({
                    title: el,
                    value: ind + 1,
                })
            })

            this.filters.find(el => el.filterType === 6).options = options
        },
        clearAllFilters(){
            this.clearChildCompValues = !this.clearChildCompValues
            this.filters.forEach(el => {
                el.selected = null
                if(el.type === 'date'){
                    el.from = null
                    el.to = null
                }
                this.getUsers()
            })
        },
        dateSting(date){
            return getDateString(date,"dd.MM.yy")
        },
        filterPackageValues(filter,packageID,type){
            if(type === 'status') return
            if(filter){
                let pckg = this.packageData.filter(p => p.LimitGroupID === packageID)[0]
                this.editUserFields.find(el => el.typeName === type).children.forEach(child => {
                    if(child.typeName === "discount") child.value = pckg.DiscountPercentAmount
                    if(child.typeName === "dailylimit") child.value = pckg.DailyLimitFixedAmount
                    if(child.typeName === "monthlylimit") child.value = pckg.MonthlyLimitFixedAmount
                })
            }else return
        },
        collapseUserslist(visible,userID){
            this.usersData.forEach(el => {
                if(el.id === userID){
                    el.dropdownVisible = !el.dropdownVisible
                } else{
                    el.dropdownVisible = false
                }
            })
        },
        updateUserData(id){
            let selectedPackage = this.editUserFields.find(el => el.typeName === 'package').selected
            let limitBlock = parseInt(this.editUserFields.find(el => el.typeName === 'blocklimit').value)
            let email = this.editUserFields.find(el => el.typeName === 'email').value

            if(id !== 0 && id){
                axios.post('salary/CreateQuickPickExternalID',{
                    personID: id,
                    externalID: email ? email : null
                }).then(resp => {
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Email Updated Successfully','success')
                        this.getUsers()
                        this.editUserVisible = false
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                })
            }
            if(selectedPackage){
                if(id === 0){
                    this.snackbarMessage('This User is Not Active','error')
                    return
                }
                this.loading = true
                axios.post('salary/CreateQuickPickLimit',{
                    quickPickLimitID: selectedPackage,
                    limitEntities: [{
                        entityID: id,
                        entityTypeID: 1,
                        requestBlockLimit: limitBlock,
                    }],
                }).then(resp => {
                    this.loading = false
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Package Assigned Successfully','success')
                        this.editUserVisible = false
                        this.getUsers()
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                }).catch(err => {
                    this.loading = false
                })
            }

            let quickPickUserID = this.usersData.find(el => el.PersonInternalID === id).id
            let status = this.editUserFields.find(el => el.typeName === 'status').selected

            this.blockUser(quickPickUserID,status)



            // if(limitBlock){
            //     this.loading = true

            //     axios.post('salary/UpdateQuickPickLimit',{
            //         quickPickLimitID: id,
            //         requestBlockLimit: limitBlock ? limitBlock : null
            //     }).then(resp =>{
            //         this.loading = false
            //         if(!resp.data.ErrorMsg){
            //             this.editUserVisible = false

            //             this.snackbarMessage('Limit Assigned Successfully','success')
            //             this.getUsers()
            //         }else{
            //             this.snackbarMessage(resp.data.ErrorMsg,'error')
            //         }                       
            //     }).catch(err => {
            //         this.loading = false
            //     })
            // }
        },
        blockUser(id,status){
            if(status === 'Active' || status === 'Pending' || status === 'Former' || status === 1){
                this.$confirm('Would You Like To Block This User').then(() => {
                    this.loading = true
                    axios.post('salary/BlockQuickPickUser',{
                        blocked: true,
                        user_ids: [id]
                    }).then(resp => {
                        this.loading = false
                        if(!resp.data.ErrorMsg){
                            this.snackbarMessage('User Blocked Succesfully','success')
                            this.getUsers()
                        }
                        else{
                            this.snackbarMessage(resp.data.ErrorMsg,'error')
                        }
                    }).catch(err => {
                        this.loading = false
                        console.log(err);
                    })
                })
            }
            if(status === 'Blocked' || status === 3){
                this.loading = true
                axios.post('salary/BlockQuickPickUser',{
                    blocked: false,
                    user_ids: [id]
                }).then(resp => {
                    this.loading = false
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('User Unblocked Succesfully','success')
                        this.getUsers()
                    }
                    else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                }).catch(err => {
                    this.loading = false
                    console.log(err);
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.background{
    overflow: hidden;
}

ul{
    list-style: none;
}

.no-records{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%,-50%);
    pointer-events: none;
    user-select: none;
    span{
        font-size: 1.875rem;
        color: rgba($grayBlue, .8);
    }
}

.top-menu{
    top: 0;
    z-index: 10;
    background: #fff;
    padding-bottom: 0.625rem;
    padding-left: 1.875rem;
}

.content-wrapper{
    width: calc(100% - (1.875rem * 2));
    height: calc(100% - 1.5rem);
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    background: #fff;
    overflow: hidden;
    margin: 1.5rem auto;
    box-sizing: border-box;

    .editPopup{
        ::v-deep{
            .popup{
                width: 53.125rem;
                height: 37.5rem;
                padding: 2.9375rem 5rem;

                h3.title{
                    color: $purple;
                    font-size: 0.875rem;
                    font-weight: 500;
                }
            }
        }
        .wrapper{
            width: 100%;
            form{
                ul{
                    width: 100%;
                    @include flex(flex-start,left,column);

                    li{
                        width: 17.5rem;
                        margin-top: 0.875rem;
                        position: relative;

                        &.submit{
                            width: 100%;
                            @include flex(flex-end,center,row);
                            margin-top: 3.125rem;
                            button[type='submit']{
                                @include purpleBtn;
                            }
                        }

                        &:first-child{
                            margin-top: 0;
                        }
                        ::v-deep{
                            label{
                                width: 100%;
                            }
                            .title{
                                font-size: 0.875rem;
                                color: $grayBlue;
                            }
                            input{
                                color: $grayBlue;
                                font-size: 0.875rem;
                            }
                        }

                        .children-wrap{
                            position: absolute;
                            top: 0;
                            right: calc(-25rem - 0.625rem);
                            width: 25rem;
                            @include flex(flex-start,center,row);
                            .wrapper{
                                width: 35rem;
                                &:first-child{
                                    margin-left: 0;
                                }
                                margin-left: 0.625rem;
                                &:last-child{
                                    ::v-deep{
                                        label{
                                            width: calc(100% + 1rem);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .content-header{
        width: 100%;
        
        .top{
            padding: 1.75rem 3.125rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #FAFBFC;
            border-bottom: 0.25rem solid #F5F6F7;
            height: 6rem;

            &.user-mode{
                justify-content: flex-start;
            }

            .header-title{
                span{
                    color: $grayBlue;
                    font-size: 1rem;
                    font-weight: 600;
                }
            }

            .header-filters{
                
                .search{
                    width: 13.75rem;
                    height: 40px;
                    position: relative;
                    @include centerElement;

                    img{
                        width: 1.1rem ;
                        position: absolute;
                        top: 50%;
                        transform: translate(0,-50%);
                        left: 0.9375rem;
                    }

                    input{
                        width: 100%;
                        height: 100%;
                        outline: none;
                        border: none;
                        padding-left: 2.6875rem;
                        color: $grayBlue;
                        font-size: 14px;
                        transition: ease .3s;
                        border-radius: 0.375rem;
                        border: 0.0625rem solid rgba(#959BC9,.4);
                        
                        &::placeholder{
                            color: rgba($grayBlue, .6);
                        }

                        &:focus{
                            box-shadow: 0rem 0rem 0.9375rem rgba($grayBlue, .16);
                        }
                        
                    }
                }

                ul{
                    list-style: none;
                    @include flex(flex-start,center,row);
                    li{
                        width: 12rem;
                        height: 2.5rem;
                        margin-left: 0.9375rem;
                        &:first-child{
                            margin-left: 0;
                            width: unset !important;
                        }
                        &.clear-filters{
                            cursor: pointer;
                            width: 4rem;
                            margin-left: 1.25rem;
                            white-space: nowrap;

                            &:hover{
                                span{
                                    color:rgba($red, .6)
                                }
                            }
                            & > div{
                                height: 100%;
                                @include centerElement;
                                position: relative;
                                &::before{
                                    content: '';
                                    position: absolute;
                                    width: 0.0625rem;
                                    height: calc(100% - .8rem);
                                    background: rgba($grayBlue,.5);
                                    transition: ease .3s;
                                    top: 50%;
                                    transform: translate(0,-50%);
                                    left: -0.375rem;
                                }

                                &:active{
                                    &::before{
                                        height: calc(100% - 2rem);
                                    }
                                }

                                span{
                                    color: $grayBlue;
                                    transition: ease .3s;
                                    font-size: 0.875rem;
                                    font-weight: 400;


                                }
                            }
                        }

                        ::v-deep .date-range{
                            .container{
                                width: 12rem !important;
                            }
                        }

                        &.assign{
                            .no-list-item{
                                position: absolute;
                                top: 60%;
                                left: 50%;
                                transform: translate(-50%,-50%);
                                pointer-events: none;
                                span{
                                    color: $grayBlue;
                                    font-size: 0.9375rem;
                                }
                            }
                            position: relative;
                            .packages{
                                min-width: 14rem;
                                max-width: 22rem;
                                height: 26rem;
                                position: absolute;
                                top: calc(100% + 0.625rem);
                                left: 0;
                                min-height: 12.5rem;
                                max-height: 31.25rem;
                                z-index: 9;
                                background: #ffffff;
                                box-shadow: 0 0 1.25rem rgba($grayBlue, .3);
                                border-radius: 0.375rem;
                                overflow: hidden;

                                ul{
                                    overflow: auto;
                                    width: 100;
                                    height: 100%;
                                    @include flex(flex-start,left,column);

                                    li{
                                        width: 100%;
                                        height: unset !important;
                                        margin-left: 0;
                                        padding: 0.75rem 0.625rem;
                                        transition: ease .1s;
                                        cursor: pointer;
                                        margin-top: 0.3125rem;
                                        border-bottom: 0.0625rem solid rgba($grayBlue,.1);

                                        span{
                                            padding-left: 0.125rem;
                                        }

                                        &:first-child{
                                            cursor: auto;
                                            padding: 0;
                                            width:100% !important;
                                        }
                                        &:hover:not(.noHover){
                                            background: rgba($purple,.9);
                                            span{
                                                color: #ffffff;
                                            }
                                        }

                                        &.search-package{
                                            width: 100% !important;
                                            position: sticky;
                                            top: 0;
                                            background: #ffffff;
                                            padding: 0.75rem 1.5625rem;
                                            z-index: 8;
                                            input{
                                                border: 0.0625rem rgba($grayBlue, .3) solid;
                                                width: 100%;
                                                height: 2.2rem;
                                                padding: 0 0.625rem;
                                                border-radius: 0.25rem;
                                                outline: none;
                                                color: $grayBlue;

                                                &::placeholder{
                                                    color: rgba($grayBlue, .6);
                                                }
                                            }
                                        }
                                        span{
                                            color: $grayBlue;
                                            font-size: 0.9375rem;
                                        }
                                    }
                                }
                            }

                        }

                        .assign-package{
                            width: 8rem;
                            height: 2.5rem;
                            border-radius: 0.3125rem;
                            img{
                                transition: ease .2s;
                                &.down{
                                    transform: rotate(45deg);
                                }
                            }
                        }

                        ::v-deep{
                            label{
                                width: 100%;
                            }
                            .title{
                                color: $grayBlue;
                                font-size: 0.875rem;
                            }
                            .selected-option{
                                color: $grayBlue;
                                font-size: 0.875rem;
                                font-weight: 400;
                            }

                            .material-icons{
                                color: $grayBlue;
                            }
                        }
                    }
                }

            }

        }
    }
}
.middle{
    width: 100%;
    margin-top: 1.25rem;
    overflow: auto;
    height: calc(100% - 160px); 

    .users-list{
        width: 100%;
        padding: 0 3.125rem;
        animation: show .5s forwards;

        ul{
            width: 100%;
            @include flex(flex-start,flex-start,column);

            li{
                width: 100%;
                @include flex(flex-start,center,row);
                height: 2.5rem;
                margin-top: 0.9375rem;

                border: 0.0625rem solid rgba(#959BC9,.4);
                border-radius: 0.375rem;
                padding: 0 0.9375rem;
                background: #fff;
                transition: ease .1s;
                position: relative;
                cursor: default;

                &.active{
                    background: $purple;
                    color: #ffffff;
                    .col-value{
                        span{
                            color: #ffffff !important;
                        }
                    }
                }

                &:hover:not(.noHover){
                    background: rgba($purple,.9);
                    .col-value{
                        span{
                            color: #ffffff !important;
                        }
                    }
                }

                &:first-child{
                    margin-top: 0;
                    border: 0.0625rem solid transparent;
                }
                &:nth-child(2){
                    margin-top: 0.3125rem;
                }

                &.list-header{
                    position: sticky;
                    top: 0;
                    z-index: 2;
                    background: #fff;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    .header-col-title{
                        color: $purple;
                        font-size: 0.875rem;
                        font-weight: 500;
                        white-space: nowrap;
                        margin-left: 1.375rem;
                        white-space: nowrap;
                        position: relative;
                        &:first-child{
                            margin-left: 0;
                        }

                        .checkbox{
                            position: absolute;
                            left: -1.5625rem;
                            top: 50%;
                            transform: translate(0,-50%);
                        }
                    }
                }

                &.list-header,&.list-item{
                    .header-col-title,.col-value{
                        text-align: left;
                    }
                    .ind{
                        width: 3rem;
                    }
                    
                    .user{
                        width: 12rem;
                        margin-left: 2.8rem !important;
                    }
                    .register-date{
                        width: 10rem;
                    }
                    .quickpick-id{
                        width: 10rem;
                    }
                    .email{
                        width: 14rem;
                    }
                    .active-package{
                        width: 9rem;
                    }
                    .block-limit{
                        width: 6rem;
                    }
                    .status{
                        width: 4rem;
                    }

                }
                &.list-item{
                    // overflow: hidden;

                    .toolbar{
                        user-select: none;
                        position: relative;
                        .dots{
                            cursor: pointer;
                            @include centerElement;
                            img{
                                width: 0.25rem;
                            }
                        }
                        
                        .actions{
                            width: 6.5rem;
                            height: 2rem;
                            position: absolute;
                            left: -7rem;
                            top: -.3rem;
                            @include flex(center,center,row);
                            padding: 0.1875rem 0.3125rem;
                            animation: slideLeft .4s forwards;
                            @keyframes slideLeft {
                                from{
                                    opacity: 0;
                                    transform: translateX(2.5rem);
                                }
                                to  {
                                    opacity: 1;
                                    transform: translateX(0);
                                }
                            }

                            > div{
                                cursor: pointer;
                                margin-left: 0.875rem;
                                &:first-child{
                                    margin-left: 0;
                                }
                                // @include centerElement;
                            }
                            div > img{
                                width: 1.125rem;
                            }
                            .block{
                                margin-bottom: 0.125rem;
                                img{
                                    width: 1rem;

                                    &.block-img{
                                        filter: invert(34%) sepia(98%) saturate(4299%) hue-rotate(316deg) brightness(100%) contrast(107%);
                                    }
                                    &.unblock-img{
                                        width: 1.2rem;
                                    }
                                }
                            }
                        }
                    }

                    .main-content{
                        width: 100%;
                        @include flex(flex-start,center,row);
                        
                        .col-value{
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            margin-left: 1.375rem;
                            position: relative;
                            &.user{
                                overflow: visible;
                                span{
                                }
                                &.insert_user{
                                    span{
                                        color: rgba($grayBlue, .6);
                                    }
                                }
                            }
                            &.status{
                                font-weight: 500;
                                span{
                                    &.active{
                                        color: $green-primary !important;
                                    }
                                    &.blocked{
                                        color: #FE228B !important;
                                    }
                                    &.pending{
                                        color: #FF9233 !important;
                                    }
                                    &.noStatus{
                                        color: #7D8FB3 !important;
                                    }
                                }
                            }
                            .checkbox{
                                position: absolute;
                                left: -1.5625rem;
                                top: 50%;
                                transform: translate(0,-50%);
                            }
                            span{
                                color: #7D8FB3;
                                font-size: 0.8125rem;
                                font-weight: 400;
                            }
                            &:first-child{
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>