<template>
  <div class="background">
    <div class="payroll-persons-factors">
      <div class="ess-container">
        <div class="filter-div">
          <choose-persons
            :allPersons="personsListOptions"
            @person-clicked="personClickHanlder"
            v-model="personsFilter"                  
            title="Persons"
          />
        </div>
        <div class="filter-div">
          <custom-select
              class="input"
              title="Factor Name"
              v-model="factorFilter"
              :options="factorListOptions"
              @clear="factorFilter = ''"
              :clearVisible="true"
          />
        </div>
        <div class="filter-div">        
          <date-range
              :initialFrom="fromDate"
              :initialTo="toDate"
              @from="(from) => (fromDate = from)"
              @to="(to) => (toDate = to)"
              class="date_range"
          />
        </div>
        <div class="filter-div">
          <custom-select
              class="input"
              title="Assign type"
              v-model="factorTypeFilter"
              :options="factorTypeListOptions"
              @clear="factorTypeFilter = ''"
              :clearVisible="true"
          />
        </div>
        <div class="filter-div">
          <custom-select
              class="input"
              title="Payment type"
              v-model="paymentTypeFilter"
              :options="paymentTypeListOptions"
              @clear="paymentTypeFilter = ''"
              :clearVisible="true"
          />
        </div>
        <div class="filter-div">
          <date-picker
              v-model="paymentDayFilter"
              title="Payment day"
          />
        </div>  
        <div class="filter-div">
          <custom-select
              class="input"
              title="Status"
              v-model="statusFilter"
              :options="statusListOptions"
              @clear="statusFilter = ''"
              :clearVisible="true"
          />
        </div>      
      </div>
      <!-- end ess-container -->

      <div class="person-list">
        <table class="person-list-table">
          <thead class="table-head">
            <tr class="person-list-table-th">
              <th>ID</th>
              <th>Employee</th>
              <th>Job</th>
              <th>Salary</th>
              <th>Salary Start Time</th>       
              <th>Salary End Time</th>
              <th>IBAN</th>
              <th></th>  
              <th></th>  
            </tr>
          </thead>
          <tbody class="table-body">    
            <template v-for="(personList, index) in getPersonsList">  
            <tr class="person-list-table-td" :class="{ 'person-list-table-td-active' : personList.SalaryID === currentId && toggleClass && !popupVisibleIban}" :key="'1tr-'+personList.PersonID"
                @click.stop="getPersonsFactories(personList.SalaryID)">
              <td><span>ID {{personList.PersonID}}</span></td>
              <td>{{personList.FirstName}} {{personList.LastName}}</td>
              <td>{{personList.JobName}}</td>
              <td>{{personList.Amount}} <span>{{personList.CurrencyCode}}</span></td>
              <td>{{personList.StartTime ? convertDate(personList.StartTime) : '-'}}</td>
              <td>{{personList.EndTime ? convertDate(personList.EndTime) : '-'}}</td>
              <td  @mouseover="hover = index" @mouseleave="hover = false" class="iban" >
                {{personList.BankAccountIban ? personList.BankAccountIban : hover !== index ? '-' : ''}}
                <img
                  v-if="hover === index && personList.BankAccountIban"
                  src="@/assets/icons/edit.svg"
                  alt="hr"
                  @click="editSalaryIban(personList.BankAccountIban)"
                />
                <img
                  v-if="hover === index && !personList.BankAccountIban"
                  src="@/assets/icons/add-icon.svg"
                  alt="hr"
                  @click="editSalaryIban(personList.BankAccountIban)"
                />
              </td>
              <td><span>{{personList.HasFactorAssigned ? '' : 'No Factors Assigned'}}</span></td>
              <td><span class="material-icons arrow-down" v-if="personList.HasFactorAssigned">keyboard_arrow_down</span></td>
            </tr>
            <tr :key="'2tr-'+personList.PersonID">
              <td colspan="8">
                <table class="salary-factor-list" v-if="personList.SalaryID === currentId && personList.HasFactorAssigned && toggleClass && !popupVisibleIban">
                  <thead>
                    <tr class="salary-factor-list-th">
                      <th>#</th>
                      <th>Factor</th>
                      <th>Company</th>
                      <th>Assign Type</th>
                      <th>Payment Type</th>       
                      <th>Currency</th>
                      <th>Company Cost</th>   
                      <th>Employee Cost</th>
                      <th>Pay day</th>
                      <th>Start Date</th>
                      <th>End Date</th> 
                      <th></th>
                    </tr>
                  </thead>
                  <tbody v-for="factories in facoriesList" :key="factories.ID">
                    <tr  class="salary-factor-list-td" :class="{ 'salary-factor-list-td-deactive' : !factories.canEdit}">
                      <td>{{factories.SalaryFactorID}}</td>
                      <td>{{factories.SalaryFactorTypeName}}</td>
                      <td>{{factories.CompanyName}}</td>
                      <td>{{factories.CalculationTypeName}}</td>
                      <td>{{factories.PaymentTypeName}}</td>
                      <td>{{factories.SalaryFactorCurrency}}</td>
                      <td>{{factories.CompanyCost}} {{factories.PaymentTypeID === 2 ? '%' : ''}}</td>
                      <td>{{factories.SalaryCost}} {{factories.PaymentTypeID === 2 ? '%' : ''}}</td>
                      <td>{{factories.PayDay}}</td>
                      <td>{{factories.FromDate ? convertDate(factories.FromDate) : '-'}}</td>
                      <td>{{factories.ToDate ? convertDate(factories.ToDate) : '-'}}</td>
                      <td><img v-if="factories.canEdit" src="@/assets/icons/white/edit.svg" alt="hr" @click="editFactory(factories.SalaryFactorID, factories.SalaryID)" /></td>
                    </tr>                    
                  </tbody>
                </table>
              </td>
            </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!-- end person-list -->

      <form class="iban-form" @submit.prevent="updateIban">      
        <custom-popup
          :visible="popupVisibleIban"
          @close="popUpVisibleIban(false)"
          title="Edit IBAN"
          :flexibleSize="false">
          <div class="form-field">
            <custom-input
              class="input"
              v-model="IbanValue"
              title="IBAN"
              @clear="IbanValue = ''"
            />
          </div>
          <div class="save-button-div">
            <button class="save-button">Save</button>
          </div>
        </custom-popup>
      </form>

      <custom-snackbar
        v-if="snackbarVisible"
        :purpose="snackbarPurpose"
        :text="snackbarText"
        @close="snackbarVisible = false"
      />
      <form class="assign-form" @submit.prevent="updateFactory">      
        <custom-popup
          :visible="popupVisible"
          @close="popUpVisible(false)"
          title="Edit Factor"
          :flexibleSize="false"
        >
          <div class="user_info">
            <div class="user_info_img"><img :src="this.$store.state.getAvatar + this.$store.state.profile.ID"  /></div>
            <div class="user_info_text"><div class="user_info_name">{{personName}}</div><div class="user_info_job">{{personJobName}}</div></div>
          </div>
          <div class="user_info_details">
             <div class="user_info_details_box">
               <div><span class="boxtitle">Salary (Basic):</span><span class="value"><span>{{salaryList.Amount}}</span>{{salaryList.Currency}}</span></div>
               <div><span class="boxtitle">Job start date:</span><span class="value">{{salaryList.JobStartDate ? convertDate(salaryList.JobStartDate) : '-'}}</span></div>
             </div>
             <div class="user_info_details_box">
               <div><span class="boxtitle">Salary Start Date:</span><span class="value">{{salaryList.StartTime ? convertDate(salaryList.StartTime) : '-'}}</span></div>
               <div><span class="boxtitle">Salary End Date:</span><span class="value">{{salaryList.EndTime ? convertDate(salaryList.EndTime) : '-'}}</span></div>
             </div>
          </div>
          <!-- end user_info_details -->
          <div class="flex-2">
            <div class="form-field">
               <search-input
                :options="factorListOptions"
                title="Factor"
                :isRequired="false"
                :disabled="true"
                @clear="mainfactorytype = ''"
                v-model="mainfactorytype"
              />
            </div>
          </div>
          <div class="static-info-content">
            <div class="form-field-value"><div>Company</div><div>{{company ? company : '-'}}</div></div>
            <div class="form-field-value"><div>Assign type</div><div>{{factortype ? factortype : '-'}}</div></div>
            <div class="form-field-value"><div>Payment type</div><div>{{paymenttype ? paymenttype : '-'}}</div></div>
          </div>         
          <div class="flex-2 margin-top">
            <div class="form-field">
              <custom-input
                class="input"
                v-model="companyCost"
                title="Company cost"
                @clear="companyCost = ''"
              />
            </div>
            <div class="form-field">
              <custom-input
                class="input"
                v-model="salaryCost"
                title="Employee cost"
                @clear="salaryCost = ''"
              />
            </div>
          </div>
          <!-- end flex -->
          <div class="flex-3">
            <div class="form-field">
              <custom-input
                class="input"
                v-model="payDay"
                title="Pay day"
                :isRequired="true"
                @clear="payDay = ''"
              />
            </div>
            <div class="form-field">
              <date-picker
                class="date"
                title="Factor start date"
                :isRequired="true"
                v-model="StartDate"
                @clear="StartDate = ''"
              />
            </div>
            <div class="form-field">
              <date-picker
                class="date"
                title="Factor end date"
                v-model="EndDate"
                @clear="EndDate = ''"
              />
            </div>
          </div>
          <!-- end flex -->
          <div class="save-button-div"><button class="save-button">Edit</button></div>
        </custom-popup>
      </form>
    </div>
    <!-- end payroll-persons-factors -->
  </div>
</template> 

<script>
import { getDateString, CETToTimestamp } from "@/Helpers/HelperFunctions"; 
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup.vue"; 
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue";
import choosePersons from "@/components/ReusableComponents/ChoosePersons/choosePersons.vue";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue";
import DateRange from "@/components/ReusableComponents/DateRange/DateRange";
import SearchInput from "@/components/ReusableComponents/SearchInput/SearchInput.vue";
import axios from "@/Helpers/AxiosInstance.js";

export default {
  components: {
    CustomSelect,
    choosePersons,
    DatePicker,
    DateRange,
    CustomPopup,
    CustomInput,
    CustomSnackbar,
    SearchInput,
  },
  async created() {
    this.getPersonsFactor()
    await this.getPersons()   
    await this.getFacories()
    await this.getFactoryTypes()
    await this.getPaymentTypes()
    await this.getPersonName()
  },
  data() {
    return {         
      personsFilter: [],
      personsListOptions: [],
      factorFilter: '',
      factorListOptions: [],
      fromDate: '',
      toDate: '',
      filterfromDate: null,
      filtertoDate: null,
      factorTypeFilter: '',
      factorTypeListOptions: [],
      paymentTypeFilter: '',
      paymentTypeListOptions: [],
      paymentDayFilter: '',
      paymentDayFilterValue: '',
      statusFilter: '',
      statusListOptions: [{title:"Assigned", value: 'true'}, {title:"Not Assigned", value: 'false'}],

      popupVisible: false,
      popupVisibleIban: false,
      snackbarVisible: false,
      snackbarPurpose: "error",
      snackbarText: "",
      IbanValue: "",

      personName: '',
      personJobName: '',
      salaryList: [],
      serviceName: '',
      selectedfactory: '',
      getPersonsList: [],
      facoriesList: [],
      selectedsalaryID: '',
      factoriestypeListAll: [],
      currentId: '',
      toggleClass: false,
      hover: false,

      mainfactorytype: null,
      companyCost: null,
      salaryCost: null, 
      payDay: null,
      StartDate: null,
      EndDate: null,
      company: null,
      factortype:null,
      paymenttype: null,
    }
  },
  methods: {
    popUpVisible (status) {
      this.company = null
      this.factortype = null
      this.paymenttype = null
      this.companyCost = null
      this.salaryCost = null
      this.payDay = null
      this.StartDate = null
      this.EndDate = null
      this.popupVisible = status
    },
    popUpVisibleIban (status) {
      this.popupVisibleIban = status
      this.toggleClass = false
    },
    editSalaryIban(value) {
      this.IbanValue = value
      this.popupVisibleIban = true
    },
    updateIban() {
      let objectData = {
        salaryID: this.selectedsalaryID,
        bankAccountIban: this.IbanValue,
      }
      axios.post(`salary/UpdateSalaryIban`, objectData).then((resp) => {
        this.popupVisibleIban = false
        this.toggleClass = false
        this.getPersonsFactor()
      });    

    },
    getPersonsFactor() {
      let personsFilterObj = []
      if(this.personsFilter.length > 0) {
          personsFilterObj = this.personsFilter.map((el) => ({
          personID:  el,
        }));
      } 
      let objectData = {
        factorTypeID: this.factorFilter,
        factorFromDate: this.filterfromDate,
        factorToDate: this.filtertoDate,
        factorCalculationType: this.factorTypeFilter,
        factorPaymentType: this.paymentTypeFilter,
        factorPaymentDate: this.paymentDayFilterValue,
        factorAssignmentStatus: this.statusFilter,
        persons: personsFilterObj,
      }
      axios.post(`salary/GetPersonsCurrentSalaries`, objectData).then((resp) => {
        this.getPersonsList = resp.data.Value
      });        
    },
    async getPersonsFactories(selectedsalaryID) { 
      await axios.get(`salary/GetSalaryFactors?SalaryID=${selectedsalaryID}`).then((response) => {
        this.facoriesList = response.data.Value
        if(this.facoriesList) {
          this.facoriesList.forEach((el) => {
            this.$set(el, "canEdit", el.CanUpdate);
            this.$set(el, "canDelete", el.CanUpdate);
          });
        } 
        if(this.currentId !== selectedsalaryID){this.toggleClass = false} 
        this.currentId = selectedsalaryID        
        this.selectedsalaryID = selectedsalaryID
        this.toggleClass = !this.toggleClass     
      });
    },
    async getPersons() {
      await axios.get("/Person/GetPersons").then((resp) => {
        this.personsListOptions = resp.data.Value.Persons.map((el) => ({
          title: el.FirstName + " " + el.LastName,
          value: el.ID,
        }));
      });
    },  
    async getFacories() {
      this.serviceName = `salary/GetSalaryFactorTypes`
      if (this.mainfactorytype) { 
        this.serviceName = `salary/GetSalaryFactorTypes?ID=${this.mainfactorytype}`}

      await axios.get(this.serviceName).then((resp) => {
        this.factoriestypeListAll = resp.data.Value.FactorsWithPersons
        this.factorListOptions = resp.data.Value.FactorsWithPersons.map((el) => ({
          title: el.Name,
          value: el.ID,
        }));     
      });
    },   
    async getFactoryTypes() {
      await axios.get(`salary/GetSalaryFactorCalculationTypes`).then((resp) => {
        this.factorTypeListOptions = resp.data.Value.map((el) => ({
          title:  el.Name,
          value: el.ID,
        }));      
      });
    },
    async getPaymentTypes() {
      await axios.get("salary/GetSalaryFactorTypePaymentTypes").then((resp) => {
        this.paymentTypeListOptions = resp.data.Value.map((el) => ({
          title:  el.Name,
          value: el.ID,
        }));
      });
    },  
    async getPersonName() {
      let id = this.$store.state.profile.ID
      await axios.get(`Person/GetPersonsDetails?Person=${id}`).then((resp) => {
        let firstName = resp.data.Value.FirstName;
        let lastName = resp.data.Value.LastName;
        let UpperCaseName =
          firstName.charAt(0).toUpperCase() + firstName.slice(1);
        let UpperCaseLastName =
          lastName.charAt(0).toUpperCase() + lastName.slice(1);
        this.personName = `${UpperCaseName} ${UpperCaseLastName}`;
        this.personJobName = resp.data.Value.JobName
      });
    },
    async getSalary () {   
      await axios.get(`salary/GetSalaries?SalaryID=${this.selectedsalaryID}`).then((response) => {
        this.salaryList = response.data.Value.Salaries[0]
      });
    },
    async editFactory (id, salaryID)
    {  
      this.selectedfactory = id  
      this.selectedsalaryID = salaryID  
      await this.getSalary()
      await axios.get(`salary/GetSalaryFactors?ID=${id}`).then((response) => {        
        this.mainfactorytype = response.data.Value[0].SalaryFactorTypeID
        this.companyCost = response.data.Value[0].CompanyCost
        this.salaryCost = response.data.Value[0].SalaryCost
        this.payDay = response.data.Value[0].PayDay
        this.StartDate = response.data.Value[0].FromDate
        this.EndDate = response.data.Value[0].ToDate
        this.company = response.data.Value[0].CompanyName
        this.factortype = response.data.Value[0].CalculationTypeName
        this.paymenttype = response.data.Value[0].PaymentTypeName
        this.popupVisible = true
      });
    },
    updateFactory () {
      let objectData = {
        salaryFactorID: this.selectedfactory,
        salaryID: this.selectedsalaryID,
        SalaryFactorTypeID: this.mainfactorytype,
        companyCost: this.companyCost,
        salaryCost: this.salaryCost,
        payDay: this.payDay,
        startDate: this.StartDate,
        endDate: this.EndDate,
      }

      axios.post(`salary/UpdateSalaryFactor`, objectData).then((resp) => {
        if (!resp.data.ErrorMsg) {
          this.popupVisible = false;
          this.mainfactorytype = null
          this.companyCost = null
          this.salaryCost = null
          this.payDay = null
          this.StartDate = null
          this.EndDate = null
          this.getPersonsFactor()
          this.getPersonsFactories(this.selectedsalaryID)
        } else {
          this.snackbarVisible = true;
          this.snackbarText = resp.data.ErrorMsg;
          this.snackbarPurpose = "error";
        }  
      });   
    },
    personClickHanlder(el) {
      el.selected = !el.selected;
      const exist = this.personsFilter.includes(el.value);
      if (exist) {
        this.personsFilter = this.personsFilter.filter(
          (element) => element != el.value
        );
      } else {
        this.personsFilter.push(el.value);
      }
    },
    convertDate (date) {
       return getDateString(CETToTimestamp(date), "dd MMM yyyy")
    },
  },
   watch: {    
    personsFilter(to, from) {  
      this.personsFilter = to
      this.getPersonsFactor()
    },
    factorFilter (to, from) {
      this.factorFilter = to
      this.getPersonsFactor()
    },
    fromDate() {
      this.filterfromDate = new Date(this.fromDate).toISOString().slice(0, 19).replace('T', ' ')
      this.filtertoDate = new Date(this.toDate).toISOString().slice(0, 19).replace('T', ' ')
      this.getPersonsFactor()
    },
    factorTypeFilter (to, from) {
      this.factorTypeFilter = to
      this.getPersonsFactor()
    },
    paymentTypeFilter(to, from) {
      this.paymentTypeFilter = to
      this.getPersonsFactor()
    },
    paymentDayFilter(to, from) {
      this.paymentDayFilterValue = new Date(to).toISOString().slice(0, 19).replace('T', ' ')
      this.getPersonsFactor()
    },
    statusFilter(to, from) {
      this.statusFilter = to
      this.getPersonsFactor()
    },
  },
}
</script>
<style lang="scss" scoped>
.background {
  overflow-y: visible;
}
.payroll-persons-factors {
  .ess-container {
      display: flex;
      justify-content: end;
      .filter-div {
          width: 12.938rem;
          margin: 0 1.125rem 0.0625rem 0;
          margin-bottom: 0.9375rem;;
          margin-top: 1.875rem;
          label {
            width: 100%;
            ::v-deep .el-input {
              width: 100%;
            }            
          }
        ::v-deep .container {
          width: 12.938rem;
          background: #ffffff;
        }
      }
  }  
  .person-list {
    width: calc(100% - 2.5rem);
    max-height: 100%;
    overflow: auto;
    margin-left: 1.25rem;
    .person-list-table {
      width: 100%;
      table-layout: fixed;
      position: relative;
      border-collapse: collapse;
      .person-list-table-th {
        width: 100%;
        height: 2.5rem;
        th:first-child {
          width: 5%;
          -webkit-border-top-left-radius: 0.1875rem;
          -webkit-border-bottom-left-radius: 0.1875rem;
          -moz-border-radius-topleft: 0.1875rem;
          -moz-border-radius-bottomleft: 0.1875rem;
          border-top-left-radius: 0.1875rem;
          border-bottom-left-radius: 0.1875rem;
        }
        th:last-child {
          width: 5%;
          -webkit-border-top-right-radius: 0.1875rem;
          -webkit-border-bottom-right-radius: 0.1875rem;
          -moz-border-radius-topright: 0.1875rem;
          -moz-border-radius-bottomright: 0.1875rem;
          border-top-right-radius: 0.1875rem;
          border-bottom-right-radius: 0.1875rem
        }
        th {  
          font-size: 0.75rem;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #ededfc;            
          background-color: #6956d6;  
          padding-left: 0.625rem;   
        }
      }  
      tr:nth-child(4n+1) {
        background-color: #dfe0f4;
      }  
      .table-head {
        width: 100%;
        display: table;        
        table-layout: fixed;
      } 
      .table-body {        
        height: 100%;
        display: block;
        overflow-y: auto;
        max-height: 44.0625rem;
      }
      .person-list-table-td { 
        width: 100%; 
        display: table; 
        table-layout: fixed;
        cursor: pointer;  
        td:first-child {
          width: 5%;
        } 
        td:last-child {
          width: 5%;
          text-align: right;
          padding: 0.625rem 0.625rem 0 0.625rem;
        }  
        .iban {
          padding: 1px;
          img {
            width: 24px;
            vertical-align: middle;
          }
        }          
        td {
          height: 2.5rem;          
          font-size: 0.875rem;
          font-weight: 500;          
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #283593;
          padding: 0.625rem 0 0.625rem 0.625rem;          
          span {
            color: #959bc9;
          }              
        }
      }
      .person-list-table-td-active {
        background-color: #283593 !important;
        td {
          color: #ffffff;
          padding-left: 0.625rem;
          span {
            color: #ffffff;
            transform: rotate(180deg);
          }
        }
      }
      .salary-factor-list {
        width: 100%;
        table-layout: fixed;
        position: relative;
        border-collapse: collapse;
        background-color: #3b469d;
        .salary-factor-list-th {
          height: 2.0625rem;
          background-color: #3b469d;
          th:first-child {
            width: 5%;
          }
          th {
            font-size: 0.75rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #959bc9;
            border-top: solid 0.0313rem #1de9b6;
            border-bottom: solid 0.0313rem #1de9b6;
            padding: 0.625rem;
          }
        }
        .salary-factor-list-td {
          height: 2.0625rem;
          background-color: #3b469d;
          td:first-child {
            width: 5%;
          }
          td {
            font-size: 0.875rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
            margin-top: 0.875rem;
            padding: 0.625rem;
            img {
              width: 1rem;
              height: 1rem;
              cursor: pointer;
            }
          }
        }
        .salary-factor-list-td-deactive {
          td {
            color: #959bc9;
          }
        }
      }
    }
  }
  .iban-form {
    ::v-deep .popup {
      width: 21.1875rem;
    }
  }
  .assign-form, .iban-form {
    ::v-deep .popup {
      height: auto;
    }
    .flex-2 {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
    .form-field {
      width: 48%;
    }
  }
  .flex-3 {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
    .form-field {
        width: 30%;
      }
      ::v-deep .el-input {
        width: 100%;
      }
    }
    .static-info-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 1.25rem;
    }
    .form-field {
      width: 100%;    
      label {
        width: 100%;
      }
    }  
    .save-button-div {
      width: 100%;
      text-align: right;  
    }
    .save-button {
      background-color: #6956d6;
      color: #fcfcfc;
      height: 3rem;
      width: 6.25rem;
      border-radius: 0.375rem;
      font-size: 1rem;
      cursor: pointer;
      margin-top: 3.75rem;
    }
    .form-field-value {
    margin-top: 1.75rem;
    div:first-child {
      font-size: 0.75rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #959bc9;
    }
    div:last-child {
      font-size: 0.875rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: left;
      color: #283593;
    }
    }
    .user_info {
      margin: 0.4188rem 0.3125rem 0 0;
      display: flex;

      .user_info_img {
        width: 3.75rem;
        height: 3.75rem;
        background-color: rgba(40, 53, 147, 0.5);
        border-radius: 50%;
        cursor: pointer;
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        img {
           width: 3.75rem;
        }
      }
      .user_info_text {
        margin: 0.75rem 0 0.375rem 0.75rem;
      }
      .user_info_name{
          -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
          font-size: 1rem;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.31;
          letter-spacing: normal;
          color: #283593;
      }
      .user_info_job {
          -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
          font-size: 0.75rem;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: #9696b9;
      }
    }
    .user_info_details {
      width: 100%;
      margin-top: 0.75rem;
      display: flex;
      justify-content: space-between;
      padding-bottom: 1.25rem;
      border-bottom: solid 0.0625rem #ededfc;
      .user_info_details_box {
        width: 50%;
        text-align: left;
        div {
          margin-top: 0.4375rem;
          .boxtitle {
            width: 33%;
            display: inline-block;
            -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
            font-size: 0.875rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal;
            color: #959bc9;
          }
          .value {
            width: 33%;
            display: inline-block;
            -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
            font-size: 0.875rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal;
            color: #283593;
            span {
              color: #c44f7d;
              display: inline-block;
              padding-right: 0.3125rem;
            }
          }
        }

      }
    }
  }
}
</style>