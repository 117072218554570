<template>
    <div class="schedule-time-picker">
        <div class="intervals" ref="intervals">
            <div
                class="interval"
                v-for="(hour, index) in hours"
                :key="index"
                :class="{ big: hourIsSpecified(hour), included: hourIsIncluded(index) }"
            >
                <span v-if="hourIsSpecified(hour)">{{ hour }}</span>
            </div>
            <div
                class="from"
                :style="{ left: fromLeftOffset }"
                v-if="mountHappened"
                :class="{ dragging: dragging }"
                @mousedown="mouseDownHandler($event, 'from')"
                @mouseup="mouseUpHandler"
                ref="from"
            >
                <span class="title">From</span>
                <span class="time">{{ fromTimeString }}</span>
            </div>
            <div
                class="to"
                :style="{ left: toLeftOffset }"
                v-if="mountHappened"
                :class="{ dragging: dragging }"
                @mousedown="mouseDownHandler($event, 'to')"
                @mouseup="mouseUpHandler"
                ref="to"
            >
                <span class="title">To</span>
                <span class="time">{{ toTimeString }}</span>
            </div>
        </div>
        <div class="pickers">
            <time-picker
                :time="fromTime"
                title="From"
                @change="time => $emit('change-from', time)"
            />
            <time-picker :time="toTime" title="To" @change="time => $emit('change-to', time)" />
            <time-picker
                :time="breakTime"
                title="Break"
                @change="breakT => $emit('change-break', breakT)"
            />
            <template v-if="canChangeTotal">
                <time-picker
                    :time="totalTime"
                    title="Total"
                    @change="time => $emit('change-total', time)"
                />
            </template>
            <template v-else>
                <div class="total">
                    <span class="total-time">{{ totalTimeString }}</span>
                    <span>Total</span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import TimePicker from '@/components/ReusableComponents/TimePicker/TimePicker'
export default {
    components: {
        TimePicker,
    },
    props: {
        fromTime: Number,
        toTime: Number,
        totalTime: Number,
        canChangeTotal: Boolean,
        breakTime: {
            type: Number,
            default: 60,
        },
    },
    created() {
        document.addEventListener('mouseup', this.removeDrag)
    },
    mounted() {
        this.mountHappened = true
    },
    data() {
        return {
            mountHappened: false,
            hours: [...Array(25).keys()].map(e => ('0' + (e % 24)).slice(-2)), //00, 01 ... 23, 24
            dragging: false,
            dragStartObjectPosition: 0,
            dragStartMousePosition: 0,
            dragEndMousePosition: 0,
            dragMouseDifference: 0,
            dragObject: null,
        }
    },
    methods: {
        hourIsSpecified(hour) {
            return ['00', '09', '12', '15', '18', '21'].includes(hour)
        },
        hourIsIncluded(index) {
            //daytime
            if (this.fromTime <= this.toTime) {
                return this.fromTime <= index * 60 && parseInt(this.toTime / 60) >= index
            }
            //night time
            else {
                return this.fromTime <= index * 60 || parseInt(this.toTime / 60) >= index
            }
        },
        mouseMoveHandler(e) {
            if (!this.dragging) return
            this.dragMouseDifference = this.dragStartMousePosition - e.pageX
        },
        mouseDownHandler(e, target) {
            this.dragging = true
            this.dragStartMousePosition = e.pageX
            this.dragMouseDifference = 0
            this.dragStartObjectPosition =
                target == 'from' ? this.$refs.from.offsetLeft : this.$refs.to.offsetLeft
            this.dragObject = target == 'from' ? this.$refs.from : this.$refs.to
            document.addEventListener('mousemove', this.mouseMoveHandler)
        },
        mouseUpHandler(e) {
            this.dragEndMousePosition = e.pageX
            this.dragging = false
            document.removeEventListener('mousemove', this.mouseMoveHandler)
        },
        removeDrag() {
            this.dragging = false
        },
        getNewFromTime(newFromPos) {
            this.$emit(
                'change-from',
                30 * parseInt(newFromPos / (this.$refs.intervals.clientWidth / 48)),
            )
        },
        getNewToTime(newToPos) {
            this.$emit(
                'change-to',
                30 * parseInt(newToPos / (this.$refs.intervals.clientWidth / 48)),
            )
        },
    },
    computed: {
        fromTimeString() {
            const appendZero = num => ('0' + num).slice(-2)
            var hr = parseInt(this.fromTime / 60)
            var mm = parseInt(this.fromTime % 60)
            return `${appendZero(hr)}:${appendZero(mm)}`
        },
        toTimeString() {
            const appendZero = num => ('0' + num).slice(-2)
            var hr = parseInt(this.toTime / 60)
            var mm = parseInt(this.toTime % 60)
            return `${appendZero(hr)}:${appendZero(mm)}`
        },
        fromLeftOffset() {
            if (!this.dragging || this.dragObject != this.$refs.from) {
                var entireWidth = this.$refs.intervals.clientWidth
                var minuteLength = entireWidth / (24 * 60)
                var accumulatedLittleIntervalWidthOffset = parseInt(this.fromTime / 60) * 0.25
                return `${minuteLength * this.fromTime - accumulatedLittleIntervalWidthOffset}px`
            } else {
                let maxLeftPos = this.$refs.intervals.clientWidth
                let newObjectPosition = this.dragStartObjectPosition - this.dragMouseDifference
                if (newObjectPosition < 0) newObjectPosition = 0
                else if (newObjectPosition > maxLeftPos) newObjectPosition = maxLeftPos
                this.getNewFromTime(newObjectPosition)
                return `${newObjectPosition}px`
            }
        },
        toLeftOffset() {
            if (!this.dragging || this.dragObject != this.$refs.to) {
                var entireWidth = this.$refs.intervals.clientWidth
                var minuteLength = entireWidth / (24 * 60)
                var accumulatedLittleIntervalWidthOffset = parseInt(this.toTime / 60) * 0.25
                return `${minuteLength * this.toTime - accumulatedLittleIntervalWidthOffset}px`
            } else {
                let maxLeftPos = this.$refs.intervals.clientWidth
                let newObjectPosition = this.dragStartObjectPosition - this.dragMouseDifference
                if (newObjectPosition < 0) newObjectPosition = 0
                else if (newObjectPosition > maxLeftPos) newObjectPosition = maxLeftPos
                this.getNewToTime(newObjectPosition)
                return `${newObjectPosition}px`
            }
        },
        totalTimeString() {
            const zeroed = num => ('0' + num).slice(-2)
            //daytime
            if (this.fromTime <= this.toTime) {
                let difference = this.toTime - this.fromTime
                difference -= this.breakTime //minus break
                return `${zeroed(parseInt(difference / 60))}:${zeroed(difference % 60)}`
            }
            //nighttime
            let tillMidnight = 24 * 60 - this.fromTime
            let fromMidnight = this.toTime
            let total = tillMidnight + fromMidnight
            total -= this.breakTime //minus break
            return `${zeroed(parseInt(total / 60))}:${zeroed(total % 60)}`
        },
    },
    destroyed() {
        document.removeEventListener('mouseup', this.removeDrag)
    },
}
</script>

<style scoped lang="scss">
.intervals {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;

    .interval {
        height: 22 * $rem;
        width: 3 * $rem;
        background-color: #bec2de;
        position: relative;

        &.big {
            height: 30 * $rem;
            width: 4 * $rem;
        }

        &.included {
            background-color: #97f4d9;
        }

        span {
            position: absolute;
            bottom: -24 * $rem;
            left: -5 * $rem;
            font-size: 14 * $rem;
            font-weight: 300;
            color: #afafc6;
        }
    }

    .from,
    .to {
        position: absolute;
        width: 40 * $rem;
        height: 40 * $rem;
        background-color: #97f4d9;
        color: #7e86be;
        font-size: 14 * $rem;
        transform: rotate(45deg) translateX(-66%);
        top: -40 * $rem;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
        border-top-right-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        cursor: ew-resize;
        left: 0;

        &:not(.dragging) {
            transition: left 0.5s ease-out;
        }

        &.dragging {
            z-index: 2;
        }

        span.title {
            position: absolute;
            transform: rotate(-45deg);
            top: -6 * $rem;
            left: -15 * $rem;
            font-size: 11 * $rem;
            font-weight: 300;
        }

        span.time {
            font-weight: 500;
            transform: rotate(-45deg);
            font-size: 12 * $rem;
        }

        &.to .title {
            top: -7 * $rem;
            left: -7 * $rem;
        }
    }
}

.pickers {
    margin-top: 55 * $rem;
    padding: 0 80 * $rem;
    display: flex;
    justify-content: space-between;

    .total {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: default;

        span.total-time {
            color: rgba(40, 52, 147, 0.5);
            font-weight: 500;
            font-size: 20px;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                height: $rem;
                width: 56 * $rem;
                background-color: rgba($blue-primary, 0.3);
                left: 0 * $rem;
                bottom: -5 * $rem;
            }
        }

        span + span {
            margin-top: 0.75rem;
            font-weight: 300;
            font-size: 0.8125rem;
            color: #b2b2c2;
        }
    }
}
</style>