<template>
  <div class="vacation-plan">
    <div class="vacation-plan__header" v-show="!userID">
      <span class="divider">
        <!--        <date-range-->
        <!--          :initialFrom="filters.period.from"-->
        <!--          :initialTo="filters.period.to"-->
        <!--          @from="(from) => (filters.period.from = from)"-->
        <!--          @to="(to) => (filters.period.to = to)"-->
        <!--          title="Filter by period"-->
        <!--        />-->
        <date-picker v-model="filters.period.from" title="From" />
      </span>
      <span class="divider">
        <input-search
          title="Filter By Department"
          :options="depSelectOptions"
          :icon="require('@/assets/icons/icon_arrow_down.svg')"
          @select="(value) => (filters.department = value)"
        />
      </span>
      <span class="divider">
        <input-search
          title="Jobs"
          :icon="require('@/assets/icons/icon_arrow_down.svg')"
          :options="jobSelectOptions"
          @select="(value) => (filters.job = value)"
        />
      </span>
      <span class="divider">
        <input-search
          title="Person"
          :icon="require('@/assets/icons/icon_arrow_down.svg')"
          @select="(value) => (filters.person = value)"
          :options="personSelectOptions"
        />
      </span>
    </div>

    <div class="content">
      <div class="header">
        <div class="content__left">
          <div class="content__left">
            <div class="content__left-header">
              <div class="content__left-used-days">Used Days Count</div>
            </div>
          </div>
        </div>
        <div
          class="content__right"
          ref="content-right"
          @scroll="handleScrollHeader"
          @mousedown="onMouseDown($event, true)"
          @mouseleave="onMouseLeave"
          @mouseup="onMouseUp"
          @mousemove="onMouseMove($event, true)"
        >
          <div class="content__right-header">
            <div class="dates">
              <div
                class="date"
                v-for="(day, index) in visibleDaysInCalendar"
                :key="index"
              >
                <div class="weekday">
                  {{ getWeekdayString(day) }}
                </div>
                <div class="day">
                  {{ getDayString(day) }}
                </div>
                <div class="month">
                  {{ getMonthString(day) }}
                </div>
                <span> </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="body">
        <custom-loader v-if="loaderVisible" />

        <div
          v-if="data.length < 1 && !loaderVisible"
          class="alternative-message"
        >
          <h1>There is no Records!</h1>
        </div>

        <div
          class="content__left content__left--body"
          ref="content-left-body"
          @scroll="handleWheelBodyContentPersons"
        >
          <div class="content__left-persons">
            <template v-for="(department, d) in data">
              <div class="div" :key="d">
                <div class="department">{{ department.DepartmentName }}</div>

                <template v-for="(person, p) in department.PersonVacations">
                  <div
                    class="content__left-persons-person"
                    @click="showUserInfo(person)"
                    :key="p"
                  >
                    <div class="left">
                      <div
                        :style="{
                          backgroundImage: `url(${getAvatarSrc(
                            person.PersonID
                          )})`,
                        }"
                        class="avatar"
                      ></div>
                      <div class="wrapper">
                        <div
                          class="name"
                          :title="person.FirstName + ' ' + person.LastName"
                        >
                          {{ `${person.FirstName} ${person.LastName}` }}
                        </div>
                        <div class="job" :title="person.JobName">
                          {{ person.JobName }}
                        </div>
                      </div>
                    </div>
                    <div class="used-days-count">
                      {{
                        person.UsedDaysCount === null ? 0 : person.UsedDaysCount
                      }}
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
        <div
          class="content__right content__right--body"
          ref="content-right-body"
          @scroll="handleScrollBody"
          @mousedown="onMouseDown($event, false)"
          @mouseleave="onMouseLeave"
          @mouseup="onMouseUp"
          @mousemove="onMouseMove($event, false)"
        >
          <div class="content__right__persons">
            <template v-for="(day, g) in visibleDaysInCalendar">
              <div :key="g" class="content__right__persons-div">
                <template v-for="(department, d) in data">
                  <div class="div" :key="d">
                    <div class="department"></div>
                    <template v-for="(person, h) in department.PersonVacations">
                      <div :key="h" class="content__right__person">
                        <template v-for="(vacation, i) in person.Vacations">
                          <!--                    v-if="checkIfInRange(day, vacation.BetweenDates)"-->
                          <div class="content__right-person-vacation" :key="i">
                            <div
                              class="vacation"
                              v-if="checkIfInRange(day, vacation.BetweenDates)"
                            >
                              <div
                                :class="[
                                  'vacation__div',
                                  {
                                    'vacation__div--paid':
                                      vacation.VacationTypeID === 1,
                                    'vacation__div--not-paid':
                                      vacation.VacationTypeID === 2,
                                    'vacation__div--day-off':
                                      vacation.VacationTypeID === 3,
                                    'vacation__div--sick-days':
                                      vacation.VacationTypeID === 4,
                                    'vacation__div--maternity-leave':
                                      vacation.VacationTypeID === 5,
                                    'vacation__div--working-home':
                                      vacation.VacationTypeID === 6,

                                    'vacation__div--pending':
                                      vacation.VacationStatus === null,
                                    'vacation__div--start': checkIfStartDate(
                                      day,
                                      vacation.BetweenDates
                                    ),
                                    'vacation__div--end': checkIfLastDate(
                                      day,
                                      vacation.BetweenDates
                                    ),
                                    'vacation__div--both':
                                      checkIfStartDate(
                                        day,
                                        vacation.BetweenDates
                                      ) &&
                                      checkIfLastDate(
                                        day,
                                        vacation.BetweenDates
                                      ),
                                  },
                                ]"
                              >
                                <div
                                  :class="[
                                    'vacation__left',
                                    {
                                      'vacation__left--upper':
                                        checkLengthFromStartDate(
                                          vacation.BetweenDates
                                        ) < 2,
                                    },
                                  ]"
                                  v-if="
                                    checkIfStartDate(
                                      day,
                                      vacation.BetweenDates
                                    ) ||
                                    checkIfStartDate(
                                      day,
                                      vacation.BetweenDates
                                    ) ||
                                    (checkIfStartDate(
                                      day,
                                      visibleDaysInCalendar
                                    ) &&
                                      checkLengthFromStartDate(
                                        vacation.BetweenDates
                                      ) === 1)
                                  "
                                >
                                  {{ getMonthYearString(day) }}
                                </div>
                                <div
                                  class="vacation__right"
                                  v-if="
                                    checkIfLastDate(
                                      day,
                                      vacation.BetweenDates
                                    ) &&
                                    checkLengthFromStartDate(
                                      vacation.BetweenDates
                                    ) > 1
                                  "
                                >
                                  {{ getMonthYearString(day) }}
                                </div>
                                <div
                                  class="vacation__text"
                                  v-if="
                                    (checkIfStartDate(
                                      day,
                                      vacation.BetweenDates
                                    ) ||
                                      checkIfStartDate(
                                        day,
                                        visibleDaysInCalendar
                                      )) &&
                                    checkLengthFromStartDate(
                                      vacation.BetweenDates
                                    ) < 2
                                  "
                                >
                                  <p class="vacation__text-title">
                                    {{
                                      getVacationTypeName(
                                        vacation.VacationTypeName
                                      )
                                    }}
                                  </p>
                                  <p>
                                    {{ `${vacation.TotalDaysUsed}d` }}
                                  </p>
                                </div>
                                <div
                                  :class="
                                    vacation.TotalDaysUsed > 2
                                      ? 'vacation__long-text'
                                      : 'vacation__short-text'
                                  "
                                  v-else-if="
                                    checkIfStartDate(
                                      day,
                                      vacation.BetweenDates
                                    ) ||
                                    checkIfStartDate(day, visibleDaysInCalendar)
                                  "
                                >
                                  <p
                                    :title="
                                      getVacationTypeName(
                                        vacation.VacationTypeName
                                      )
                                    "
                                  >
                                    {{
                                      getVacationTypeName(
                                        vacation.VacationTypeName
                                      )
                                    }}
                                  </p>
                                  <p
                                    class="days"
                                    :title="
                                      vacation.TotalDaysUsed + ' working days'
                                    "
                                    v-if="
                                      checkLengthFromStartDate(
                                        vacation.BetweenDates
                                      ) > 2
                                    "
                                  >
                                    {{
                                      `${vacation.TotalDaysUsed} working days`
                                    }}
                                  </p>
                                  <p
                                    :title="vacation.TotalDaysUsed + 'd'"
                                    v-else
                                  >
                                    {{ `${vacation.TotalDaysUsed}d` }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <custom-popup
      v-if="selectedPerson && showUserModal"
      :visible="showUserModal && selectedPerson !== null"
      :bigSize="true"
      @close="
        showUserModal = false;
        selectedPerson = null;
      "
      :title="`${selectedPerson.FirstName} ${selectedPerson.LastName} Requests`"
      :flexibleSize="true"
      class="person-requests-popup"
    >
      <!-- <grid
        :columns="columns"
        :alternativeStyle="true"
        :gridData="personVacation"
      /> -->
      <div class="header">
        <span>#</span>
        <span>Request date & time</span>
        <span>From date - to date</span>
        <span>Days</span>
        <span>Vacation Type</span>
        <span>Last Vacation</span>
        <span>Status</span>
      </div>

      <div class="body">
        <div
          class="row"
          v-for="(vacation, index) in personVacation"
          :key="index"
        >
          <div
            :style="{
              backgroundColor:
                clickedVacationID == vacation.VacationID ? '#FCFCFF' : null,
            }"
            class="backrgound-wrapper"
          >
            <span>{{ index + 1 }}</span>
            <span>{{
              getDateString(vacation.CreateTime, "dd MMM yyyy HH:mm")
            }}</span>
            <span
              >{{ getDateString(vacation.FromDate, "dd MMM yy") }} -
              {{ getDateString(vacation.ToDate, "dd MMM yy") }}</span
            >
            <span>{{ vacation.TotalDays }}</span>
            <span>{{ vacationTypeText(vacation.VacationType) }}</span>
            <span>{{
              getDurationString(vacation.LastVacationDate, vacation.FromDate)
            }}</span>
            <div class="status-content">
              <div class="wrapper">
                <vacation-confirmation
                  :confirmNumb="vacation.ApprovesNum"
                  :rejectNumb="vacation.RejectsNum"
                  :mustConfirmNumb="vacation.MustBeApprovedByNum"
                  :approved="vacation.Approved"
                />
              </div>
            </div>
            <span
              v-if="vacation.confirmationInfoVisible == false"
              @click.stop="GetVacationApprovedBy(vacation)"
              class="material-icons arrow-down"
              >keyboard_arrow_down</span
            >
            <span
              v-if="vacation.confirmationInfoVisible == true"
              class="material-icons arrow-up"
              @click.stop="
                (vacation.confirmationInfoVisible = false),
                  (clickedVacationID = 0)
              "
              >keyboard_arrow_up</span
            >
          </div>
          <div
            :class="{ show: vacation.confirmationInfoVisible }"
            class="confirmation-info"
          >
            <div class="persons-content">
              <div
                class="person"
                v-for="el in vacation.confInfo"
                :key="el.index"
              >
                <span>{{ el.FirstName + " " + el.LastName }}</span>
                <span>{{
                  el.Approved
                    ? "Approved"
                    : el.Approved == false
                    ? "Rejected"
                    : "Pending"
                }}</span>
                <span>{{
                  el.StartDate
                    ? getDateString(el.StartDate, "dd MMM yyyy HH:mm")
                    : ""
                }}</span>
                <span class="comment">{{ el.Comment }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </custom-popup>
  </div>
</template>

<script>
import Grid from "@/components/ReusableComponents/Grid/Grid";
import axios from "@/Helpers/AxiosInstance.js";
import { CETToTimestamp, getDateString } from "@/Helpers/HelperFunctions";
import InputSearch from "@/components/ReusableComponents/InputSearch/InputSearch";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker";
import VacationConfirmation from "./VacationConfirmation.vue";
import CustomLoader from "@/components/ReusableComponents/Loader/CustomLoader";

export default {
  components: {
    InputSearch,
    DatePicker,
    CustomPopup,
    VacationConfirmation,
    CustomLoader,
    // Grid,
  },

  props: {
    shouldUpdate: {
      type: Boolean,
      required: true,
    },
    userID: {
      type: Number,
      required: false,
    },
  },
  async mounted() {
    await this.getDepartmentVacations();
    this.getJobs();
    this.getPersons();
    this.getDeps();
    this.setDefaultCalendarDates();
  },

  computed: {
    columns() {
      return [
        {
          title: "From Date",
          key: "FromDate",
          customTemplate: (item, val) => getDateString(val, "dd MMM yyyy"),
        },
        {
          title: "To Date",
          key: "ToDate",
          customTemplate: (item, val) => getDateString(val, "dd MMM yyyy"),
        },
        {
          title: "Days",
          key: "TotalDays",
        },
        {
          title: "Vacation Type",
          key: "VacationType",
          customTemplate: (item, val) => {
            if (val === 1) {
              return "Paid";
            } else if (val === 2) {
              return "Not Paid";
            } else if (val === 3) {
              return "Day Off";
            } else if (val === 4) {
              return "Sick Days";
            } else {
              return "";
            }
          },
        },
        {
          title: "Last Vacation",
          key: "LastVacationDate",
          customTemplate: (item, val) =>
            val ? this.getDurationString(val, item.FromDate) : "-",
        },
        {
          title: "Status",
          Key: "Status",
          customTemplate: (item) => {
            if (item.Approved == true) {
              return "Approved";
            } else if (item.Approved == false) {
              return "Rejected";
            } else {
              return "Pending";
            }
          },
        },
      ];
    },
    depSelectOptions() {
      return this.deps.map((el) => ({
        title: el.Name,
        value: el.ID,
      }));
    },
    jobSelectOptions() {
      return this.jobs.map((el) => ({
        title: el.Name,
        value: el.ID,
      }));
    },
    personSelectOptions() {
      return this.persons.map((el) => ({
        title: el.FirstName + " " + el.LastName,
        value: el.ID,
      }));
    },
    visibleDaysInCalendar() {
      var days = [];

      var startDay = new Date(this.showingCalendarStartDate);
      var toDay = new Date(this.showingCalendarToDate);
      for (
        let d = startDay;
        d.getTime() <= toDay.getTime();
        d.setDate(d.getDate() + 1)
      ) {
        days.push(new Date(d));
      }

      return days;
    },
  },

  data() {
    return {
      data: [],
      personVacations: [],
      filters: {
        period: {
          from: "",
          to: "",
        },
        department: null,
        job: null,
        person: null,
      },
      showingCalendarStartDate: 0,
      showingCalendarToDate: 0,
      deps: [],
      jobs: [],
      clickedVacationID: "",
      confirmationInfo: [],
      persons: [],
      isDown: false,
      startX: 0,
      scrollLeft: 0,
      showUserModal: false,
      periodTo: "",
      selectedPerson: null,
      personVacation: [],
      loaderVisible: false,
    };
  },

  methods: {
    getDateString,
    getDurationString(end, start) {
      var duration = Math.abs(start - end);
      var fullDays = parseInt(duration / (24 * 3600000));
      var days = fullDays % 30;
      var months = parseInt(fullDays / 30);
      var res = "";
      if (months) {
        if (res) res += ", ";
        res += `${months}month`;
      }
      if (days) {
        if (res) res += " & ";
        res += `${days}days`;
      }
      return res;
    },
    checkLengthFromStartDate(betweenDates) {
      const date = new Date(this.showingCalendarStartDate);
      const today = betweenDates.find(
        (el) =>
          date.getDate() === el.getDate() &&
          date.getFullYear() === el.getFullYear() &&
          date.getMonth() === el.getMonth()
      );
      const index = betweenDates.indexOf(today);
      return betweenDates.filter((value, i) => i > index).length;
    },
    setDefaultCalendarDates() {
      var starting =
        this.filters.period.from !== ""
          ? new Date(this.filters.period.from)
          : new Date();

      this.showingCalendarStartDate = starting.getTime();

      var ending = new Date(starting.setMonth(starting.getMonth() + 5));
      this.periodTo = ending;
      this.showingCalendarToDate = ending.getTime();
    },
    getAvatarSrc(id) {
      return this.$store.state.getAvatar + id;
    },
    handleScrollHeader(event) {
      const bodyElement = this.$refs["content-right-body"];
      bodyElement.scrollLeft = event.target.scrollLeft;
    },
    handleScrollBody(event) {
      const bodyElement = this.$refs["content-right"];
      bodyElement.scrollLeft = event.target.scrollLeft;
    },
    handleWheelBodyContentPersons(event) {
      const bodyElement = this.$refs["content-right-body"];
      bodyElement.scrollTop = event.target.scrollTop;
    },
    handleWheelBodyContentDates(event) {
      const bodyElement = this.$refs["content-left-body"];
      bodyElement.scrollTop = event.target.scrollTop;
    },

    vacationTypeText(val) {
      let result;
      if (val == 1) {
        result = "Paid";
      } else if (val == 2) {
        result = "Not Paid";
      } else if (val == 3) {
        result = "Day Off";
      } else if (val == 4) {
        result = "Sick Days";
      } else if (val == 5) {
        result = "Maternity Leave";
      } else {
        result = "";
      }
      return result;
    },

    GetVacationApprovedBy(el) {
      axios
        .get(`vacations/GetVacationApprovedBy?Vacation=${el.VacationID}`)
        .then((resp) => {
          this.clickedVacationID = el.VacationID;

          el.confirmationInfoVisible = true;
          this.$set(el, "confInfo", resp.data.Value);
          this.confirmationInfo = resp.data.Value;
        });
    },

    dateRange(startDate, endDate) {
      var dates = [];
      var currentDate = new Date(startDate);
      var addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }

      return dates;
    },
    checkIfInRange(dateTimestamp, dateRange) {
      const date = new Date(dateTimestamp);
      const exists = dateRange.filter(
        (el) =>
          date.getDate() === el.getDate() &&
          date.getFullYear() === el.getFullYear() &&
          date.getMonth() === el.getMonth()
      );
      return exists.length > 0;
    },
    checkIfStartDate(dateTimestamp, dateRange) {
      const date = new Date(dateTimestamp);
      return (
        date.getDate() === dateRange[0].getDate() &&
        date.getMonth() === dateRange[0].getMonth() &&
        date.getFullYear() === dateRange[0].getFullYear()
      );
    },
    checkIfLastDate(dateTimestamp, dateRange) {
      const date = new Date(dateTimestamp);
      return (
        date.getDate() === dateRange[dateRange.length - 1].getDate() &&
        date.getMonth() === dateRange[dateRange.length - 1].getMonth() &&
        date.getFullYear() === dateRange[dateRange.length - 1].getFullYear()
      );
    },

    getVacationTypeName(name) {
      switch (name) {
        case "NotPaid":
          return "Not Paid";
        case "SickDays":
          return "Sick Days";
        case "DayOff":
          return "Day Off";
        default:
          return name;
      }
    },
    async getDepartmentVacations() {
      this.loaderVisible = true;
      const response = await axios.get("vacations/GetDepartmentsVacations", {
        params: {
          from:
            this.filters.period.from === ""
              ? null
              : getDateString(this.filters.period.from, "yyyy/MM/dd"),
          to:
            this.periodTo === ""
              ? null
              : getDateString(this.periodTo, "yyyy/MM/dd"),
          Person: this.userID ? this.userID : this.filters.person,
          Job: this.filters.job,
          Department: this.filters.department,
        },
      });
      const data = response.data.Value.DepartmentVacations;
      this.loaderVisible = false;

      data.forEach((dep) => {
        dep.PersonVacations.forEach((person) => {
          person.Vacations.forEach((vacation) => {
            vacation.BetweenDates = this.dateRange(
              vacation.VacationFrom,
              vacation.VacationTo
            );
          });
        });
      });
      this.data = data.reverse();
      this.$emit("update-finished");
    },
    getJobs() {
      axios.get("jobs/GetJobs").then((resp) => {
        this.jobs = resp.data.Value.Jobs;
      });
    },
    getPersons() {
      axios.get("Person/GetPersons").then((resp) => {
        this.persons = resp.data.Value.Persons;
      });
    },
    getDeps() {
      axios.get("departments/GetDepartments").then((resp) => {
        this.deps = resp.data.Value;
      });
    },
    getMonthYearString(date) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      return `${date.getDate()} ${months[date.getMonth()]}`;
    },
    getWeekdayString(date) {
      const days = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
      return days[(date.getDay() + 6) % 7];
    },
    getDayString(date) {
      return date.getDate();
    },
    getMonthString(date) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return months[date.getMonth()];
    },
    getFullDayString(date) {
      date = new Date(date);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const days = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
      return `${days[(date.getDay() + 6) % 7]} ${date.getDate()} ${
        months[date.getMonth()]
      }`;
    },
    onMouseDown(e, isHeader) {
      const slider = isHeader
        ? this.$refs["content-right"]
        : this.$refs["content-right-body"];
      this.isDown = true;
      slider.classList.add("active");
      this.startX = e.pageX - slider.offsetLeft;
      this.scrollLeft = slider.scrollLeft;
    },
    onMouseLeave() {
      this.isDown = false;
    },
    onMouseUp() {
      this.isDown = false;
    },
    onMouseMove(e, isHeader) {
      const slider = isHeader
        ? this.$refs["content-right"]
        : this.$refs["content-right-body"];
      if (!this.isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - this.startX) * 3; //scroll-fast
      slider.scrollLeft = this.scrollLeft - walk;
    },
    // getPersonVacationInfo(id) {
    //   axios.get(`vacations/Vacations?PersonID=${id}`).then((response) => {
    //     this.personVacation = response.data.Value;
    //   });
    // },
    getPersonVacationInfo(id) {
      // id = this.clickedPerson.PersonID;

      axios
        .get(`vacations/GetPersonsVacations?PersonID=${id}`)
        .then((response) => {
          this.personVacation = response.data.Value;
          this.personVacation.forEach((el) => {
            this.$set(el, "confirmationInfoVisible", false);
          });
        });
    },
    showUserInfo(person) {
      this.selectedPerson = person;
      this.getPersonVacationInfo(person.PersonID);
      this.showUserModal = true;
    },
  },

  watch: {
    filters: {
      async handler() {
        this.setDefaultCalendarDates();
        await this.getDepartmentVacations();
      },
      deep: true,
    },
    async shouldUpdate(val) {
      if (val) {
        await this.getDepartmentVacations();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vacation-plan {
  padding: 20px 5px 0px 0px;

  &__header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 20px;

    .divider {
      margin-right: 10px;
    }
  }

  .content {
    user-select: none;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .header,
    .body {
      display: flex;
      width: 100%;
      overflow: hidden;
    }

    .header {
      height: 80px;
    }

    .body {
      height: calc(100vh - 285px);
      overflow-y: auto;
    }

    &__left {
      width: 333px;
      display: flex;
      flex-direction: column;

      &-header {
        height: 80px;
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
      }

      &-used-days {
        writing-mode: vertical-rl;
        max-height: 80px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(40, 53, 147, 0.6);
        transform: scale(-1);
        padding-top: 5px;
      }

      &-persons {
        direction: ltr;

        &-person {
          &:nth-child(even) {
            background-color: #e7e7f9;
          }

          cursor: pointer;
          width: 330px;
          height: 100px;
          display: flex;
          align-items: center;
          padding: 0 10px 0 10px;

          .left {
            display: flex;
            align-items: center;

            .wrapper {
              margin-left: 20px;

              .name {
                max-width: 100px;
                color: #283593;
                height: 38px;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              .job {
                font-size: 14px;
                color: #9696b9;
                max-width: 120px;
                height: 36px;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }

          .avatar {
            height: 70px;
            width: 70px;
            border-radius: 100%;
            background-color: white;
            background-size: cover;
            background-repeat: no-repeat;

            background-position: center;
          }

          .used-days-count {
            margin-right: 12px;
            margin-left: auto;
            width: 35px;
            height: 35px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(66, 78, 161, 0.1);
            color: #283593;
          }
        }
      }

      &--body {
        direction: rtl;
        height: 100%;
        overflow-y: auto;
      }
    }

    &__right {
      width: calc(100% - 333px);
      overflow-x: auto;
      -ms-overflow-style: none !important; /* IE and Edge */
      scrollbar-width: none !important; /* Firefox */
      display: inline-flex;

      &::-webkit-scrollbar {
        width: 0px !important; /* Remove scrollbar space */
        background: transparent !important; /* Optional: just make scrollbar invisible */
      }

      &::-webkit-scrollbar-thumb {
        background: transparent !important;
      }

      &-header {
        height: 80px;
      }

      .dates {
        display: inline-flex;

        .date {
          width: 50px;
          writing-mode: vertical-rl;
          height: 80px;
          font-weight: lighter;
          font-size: 14px;
          color: #9696b9;
          transform: scale(-1);

          span {
            font-size: 14px;
            display: block;
          }
        }
      }

      &__persons {
        display: inline-flex;
      }

      &__person {
        &:nth-child(even) {
          background-color: #e7e7f9;
        }

        height: 100px;
        width: 50px;
        display: flex;
        align-items: center;
      }

      &--body {
        height: 100%;
        overflow-y: hidden;
        margin-left: -5px;
      }
    }
  }

  .department {
    font-weight: 500;
    color: $blue_primary;
    font-size: 18px;
    display: flex;
    height: 50px;
    padding-left: 40px;
    align-items: center;
    margin-top: 100px;

    &:first-child {
      margin-top: 0;
    }
  }

  .vacation {
    &__left {
      position: absolute;
      bottom: -19px;
      left: 0px;
      font-size: 14px;
      white-space: nowrap;
      color: #9696b9;

      &--upper {
        bottom: 0;
        top: -19px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__right {
      color: #9696b9;
      position: absolute;
      top: -19px;
      right: 0px;
      font-size: 14px;
      white-space: nowrap;
    }

    &__div {
      position: relative;
      width: 50px;
      height: 60px;

      &--paid {
        background-color: #89e9e6;
      }

      &--not-paid {
        background-color: #f0c6d6;
      }

      &--day-off {
        background-color: #c5c5ec;
      }

      &--sick-days {
        background-color: #efdbce;
      }

      &--start {
        border-radius: 5px 0px 0px 5px;
        border-left: #e7e7f9 1px solid;
      }

      &--end {
        border-radius: 0px 5px 5px 0px;
      }

      &--maternity-leave {
        background-color: #dfecc5;
      }

      &--working-home {
        background-color: #f5dd7a;
      }

      &--pending {
        background-color: #d8d8ed;
      }
    }

    &__text {
      position: absolute;
      left: 5px;
      top: 5px;
      color: #283593;

      p:first-child {
        font-size: 12px;
        width: 40px;
        margin-top: 3px;
      }
      p:last-child {
        font-size: 12px;
        width: 40px;
        margin-top: 14px;
      }
    }
    &__long-text {
      width: 150px;
    }
    &__short-text {
      width: 83px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__long-text,
    &__short-text {
      height: 100%;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 0;
      left: 15px;
      z-index: 22;

      p {
        color: #283593;
        font-size: 12px;
      }

      .days {
        font-size: 12px;
        font-weight: lighter;
      }
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    width: 80vw;

    .headers {
      margin-bottom: 10px;
      color: #283593;
      font-weight: 500;
    }

    &-body {
      max-height: 50vh;
      overflow-y: scroll;
    }

    .row {
      color: #283593;
      display: grid;
      height: 25px;
      grid-template-columns: repeat(7, 200px);
      align-items: center;
      text-align: left;
      margin-bottom: 10px;

      .col {
        display: flex;
        text-overflow: ellipsis;
        white-space: nowrap;

        a {
          overflow: hidden;
        }

        .flex {
          overflow: hidden;
        }

        &--center {
          justify-content: left;
        }
      }
    }
  }

  .date {
    display: flex;
    text-align: center;
    padding-top: 5px;

    .weekday {
      height: 20px;
      text-align: left;
    }

    .day {
      height: 25px;
      text-align: center;
    }

    .month {
      height: 20px;
      text-align: center;
    }
  }
}

.person-requests-popup {
  .body {
    height: 85%;
    overflow: auto;
  }
  .header {
    color: $blue-primary;
    font-weight: 400;
    margin-bottom: 20px;
    span:nth-child(1) {
      margin-right: 15px;
    }
    span {
      margin-right: 80px;
    }
  }
  .row {
    color: $blue-primary;
    font-weight: 300;
    margin-top: 0.5rem;

    font-size: 15px;
    .status-content {
      display: inline-block;
      .wrapper {
        display: flex;
        align-items: center;
        .material-icons {
          width: auto;
          margin-left: 10px;
          color: rgba($blue-primary, 0.3);
          font-size: 25px;
          cursor: pointer;
        }
      }
    }
    .material-icons {
      margin-left: auto;
      cursor: pointer;
      opacity: 0.5;
    }

    .confirmation-info {
      max-height: 0;
      overflow: hidden;
      transition: 0.4s ease-in;
      display: flex;
      // align-items: flex-end;
      padding-left: 30px;
      flex-direction: column;
      background-color: #fefeff;
      // background-color: black;

      &.show {
        max-height: 500px !important;
        transition: 0.4s ease-in;
      }
      .person {
        display: flex;
        margin-top: 8px;
        span {
          margin-right: 22px;
          font-size: 13px;

          &:not(.comment) {
            flex-shrink: 0;
            white-space: nowrap;
          }
          &:nth-child(1) {
            width: 130px;
            display: inline-block;
            margin-right: 50px;
          }
          &:nth-child(2) {
            width: 62px;
            display: inline-block;
          }
          &:last-child {
            margin: 0;
          }
        }
        .comment {
          padding-left: 75px;
          padding-right: 10px;
        }
      }
    }

    ::v-deep .confirmation-content {
      padding-top: 0;
    }
    .backrgound-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      height: 30px;
      span {
        display: inline-block;
      }
      > span:nth-child(1) {
        margin-right: 15px;
        width: 17px;
        color: rgba($blue-primary, 0.5);
      }

      > span:nth-child(2) {
        // margin-right: 130px;
        width: 215px;
      }
      > span:nth-child(3) {
        width: 217px;
      }
      span:nth-child(4) {
        width: 117px;
      }
      span:nth-child(5) {
        width: 177px;
      }
      span:nth-child(6) {
        width: 148px;
      }
    }
  }
}
</style>
