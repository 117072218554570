var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_c('div',{staticClass:"loading-wrapper"},[(!_vm.loading)?_c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"slider"},[_c('div',{staticClass:"dashboard-content",class:{
            noBanner: !_vm.noAnyNews && !_vm.hasBanner,
            noNewsStyle: _vm.noAnyNews,
            onlyOffer: _vm.haveOnlyOffer,
            onlyOfferWithBanner: _vm.haveOnlyOfferAndBanner,
          }},[(_vm.priorityNews.length > 0)?_c('swiper',{ref:"swiperOne",staticClass:"main-news-content",attrs:{"options":_vm.swiperOneOptions}},[_vm._l((_vm.priorityNews),function(news){return _c('swiper-slide',{key:news.index,staticClass:"news",style:([
                {
                  backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${
                    news.Offer || news.Poll || news.News
                  }&PlacementType=${news.PlacementType})`,
                },
              ])},[_c('div',{staticClass:"forevent",on:{"click":function($event){return _vm.getNewsDetails(news)}}},[_c('div',{staticClass:"gradient-div"}),_c('div',{staticClass:"info-content"},[_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(news.Title)}}),_c('div',{staticClass:"desc",domProps:{"innerHTML":_vm._s(news.Description)}})])])])}),(_vm.priorityNews.length > 1)?_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},on:{"click":function($event){return _vm.next(true)}},slot:"button-next"}):_vm._e(),(_vm.priorityNews.length > 1)?_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},on:{"click":function($event){return _vm.prev(true)}},slot:"button-prev"}):_vm._e()],2):_vm._e(),(!_vm.noAnyNews)?_c('news-and-polls',{ref:"childcomp",attrs:{"noBanner":_vm.hasBanner,"news":_vm.news,"polls":_vm.polls,"onlyOffer":_vm.haveOnlyOffer,"haveOnlyOfferAndBanner":_vm.haveOnlyOfferAndBanner}}):_vm._e(),_c('div',{staticClass:"wrapper",class:{ alternativeStyle: _vm.noAnyNews == true }},[_c('user-attendance',{attrs:{"bigVersion":_vm.noAnyNews}}),_c('user-activity',{attrs:{"bigVersion":_vm.noAnyNews}})],1),(_vm.offers.length)?_c('section',{staticClass:"offers-section"},[_vm._m(0),_c('div',{staticClass:"offer-blocks-content"},_vm._l((_vm.offersByTime),function(offer){return _c('div',{key:offer.Offer,staticClass:"single-offer"},[_c('div',{staticClass:"offer-wrapper",on:{"click":function($event){return _vm.getSingleOffer(offer.Offer)}}},[_c('div',{staticClass:"offer-banner",style:({
                      backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${offer.Offer}&PlacementType=${offer.PlacementType})`,
                    })},[(offer.BannerTitle)?_c('span',{staticClass:"banner-title",domProps:{"innerHTML":_vm._s(offer.BannerTitle)}}):_vm._e()]),_c('div',{staticClass:"offer-info"},[_c('p',{staticClass:"offer-title"},[_vm._v(_vm._s(offer.Title))]),_c('div',{staticClass:"offer-desc"},[_vm._v(" "+_vm._s(_vm.htmlToText(offer.Description))+" ")])])])])}),0)]):_vm._e()],1)])]):_c('div',{staticClass:"holder"},[_c('custom-loader',{attrs:{"alternativeSize":true}})],1)]),_c('div',{staticClass:"offer-popup",on:{"click":function($event){$event.stopPropagation();_vm.downloadAllVisible = false}}},[(_vm.offerDetails.offer)?_c('custom-popup',{attrs:{"visible":_vm.offerDetailVisible},on:{"close":function($event){_vm.offerDetailVisible = false}}},[_c('div',{staticClass:"banner-desc-content"},[_c('div',{staticClass:"banner-content",style:({
            backgroundImage: `url(data:image/jpeg;base64,${_vm.offerDetails.offer.Banner})`,
          })}),_c('div',{staticClass:"description-content"},[_c('p',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.offerDetails.offer.Title)}}),_c('div',{staticClass:"desc-text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.offerDetails.offer.Description)}})])])]),_c('div',{staticClass:"attachment-content"},[_c('div',{staticClass:"files"},[_c('div',{staticClass:"title"},[_vm._v(" Attachments ("+_vm._s(_vm.offerDetails.offer.Attachments.length)+") "),(_vm.offerDetails.offer.Attachments.length > 1)?_c('div',{staticClass:"dots-content",on:{"click":function($event){$event.stopPropagation();_vm.downloadAllVisible = !_vm.downloadAllVisible}}},[_c('div'),_c('div'),_c('div'),(_vm.downloadAllVisible)?_c('div',{staticClass:"download-button",on:{"click":function($event){$event.stopPropagation();return _vm.downloadAllHandler.apply(null, arguments)}}},[_c('span',[_vm._v("Download all")])]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"files-content"},_vm._l((_vm.offerDetails.offer.Attachments),function(att,index){return _c('a',{key:index,attrs:{"target":"_blank"},on:{"click":function($event){return _vm.attClickHandler(att)}}},[_c('file-block',{attrs:{"uploadedFile":att}})],1)}),0)]),_c('div',{staticClass:"url-content"},[(_vm.offerDetails.offer.URL)?_c('span',[_vm._v("Visit web page: "),_c('a',{attrs:{"href":_vm.offerDetails.offer.URL,"target":"_blank"}},[_vm._v(_vm._s(_vm.offerDetails.offer.URL))])]):_vm._e()])]),_c('div',{staticClass:"options-content"},[_c('p',[_vm._v("Options")]),_c('div',{staticClass:"options"},[_c('swiper',{ref:"swiperTwo",attrs:{"options":_vm.swiperOptionsTwo}},_vm._l((_vm.offerDetails.offerOpt),function(opt,index){return _c('swiper-slide',{key:opt.OfferOptionID,staticClass:"single-opt",class:{ clickedOptStyle: opt.active }},[_c('div',{staticClass:"for-event",on:{"click":function($event){return _vm.optClicked(opt)}}},[_c('div',{staticClass:"opt-info-wrapper",attrs:{"title":opt.OfferOptionDescription.length > 25
                      ? opt.OfferOptionDescription
                      : null}},[_c('div',{staticClass:"left-side"},[_c('span',[_vm._v(_vm._s(index + 1))])]),_c('div',{staticClass:"right-side"},[_c('span',[_vm._v(_vm._s(opt.OfferOptionDescription))]),(opt.FoundedByCompany != 0)?_c('span',[_vm._v(_vm._s(opt.FoundedByCompany + " " + opt.CurrencyCode))]):_vm._e(),(opt.FoundedByCompany != 0)?_c('span',[_vm._v("Funded by company")]):_vm._e(),(opt.EmployeeShare != 0)?_c('span',[_vm._v(_vm._s(opt.EmployeeShare + " " + opt.CurrencyCode))]):_vm._e(),(opt.EmployeeShare != 0)?_c('span',[_vm._v("My share")]):_vm._e()])])])])}),1),(_vm.offerDetails.offerOpt.length > 5)?_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},on:{"click":function($event){return _vm.next()}},slot:"button-next"}):_vm._e(),(_vm.offerDetails.offerOpt.length > 5)?_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},on:{"click":function($event){return _vm.prev()}},slot:"button-prev"}):_vm._e()],1)]),_c('div',{staticClass:"comment-content"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.offerComment),expression:"offerComment"}],attrs:{"placeholder":"Type your comment","cols":"30","rows":"10"},domProps:{"value":(_vm.offerComment)},on:{"input":function($event){if($event.target.composing)return;_vm.offerComment=$event.target.value}}})]),_c('div',{staticClass:"button-content"},[_c('button',{on:{"click":_vm.sendResponseOnOffer}},[_vm._v("Send Response")])])]):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"offer-header"},[_c('span',[_vm._v("Offers")])])
}]

export { render, staticRenderFns }