<template>
  <div class="upload-documents">
    <h5>Documents</h5>
    <div class="document-container">
      <file-block
        :initial="true"
        @fileUpload="fileUploadHandler"
        v-if="
          $store.getters.hasRole('HR') ||
          $store.getters.hasRole('Admin') ||
          $store.getters.isSuperAdmin
        "
      />
      <div
        v-for="(file, index) in uploadedFiles"
        :key="index"
        >
        <file-block  @viewFile="attClickHandler(file)" :uploadedFile="file" @delete="$emit('delete', index)"/>
      </div>
    </div>
  </div>
</template>

<script>
import FileBlock from "./FileBlock";
import axios from "@/Helpers/AxiosInstance.js";
export default {
  components: {
    FileBlock,
  },
  props: {
    uploadedFiles: Array,
  },
  methods: {
    fileUploadHandler(file) {
      this.$emit("upload", file);
    },
    attClickHandler(el) {
      let file_url = (id) => this.$store.state.isProduction ?  `https://intranet.upgaming.com/api/files/GetFileByID?FileID=${id}` : `https://intranet.upgaming.dev/api/files/GetFileByID?FileID=${id}`
      let a = document.createElement('a')
      a.href = file_url(el.attachmentID)
      a.target = 'blank'
      a.click()
      // axios.get('files/GetFileByID',{
      //   params:{
      //     FileID: el.attachmentID ? el.attachmentID : null,
      //   }
      // }).then(resp => {
      //   console.log(resp.data)
      //     var a = document.createElement("a");
      //     a.href =
      //       `data:${el.AttachmentType};base64,` + resp.data.Value[0].File;
      //     a.click();
      // })
      // axios
      //   .post("files/GetFiles", {
      //     Files: [
      //       {
      //         FileID: el.attachmentID,
      //       },
      //     ],
      //   })

      //   .then((resp) => {
      //     var a = document.createElement("a");
      //     a.href =
      //       `data:${el.AttachmentType};base64,` + resp.data.Value[0].File;
      //     a.download = el.name;
      //     a.click();
      //   });
    },
  },
};
</script>

<style scoped lang="scss">
.upload-documents {
  margin-top: 120 * $rem;
  max-height: 410px;
  overflow: auto;

  h5 {
    margin-bottom: 28 * $rem;
    font-size: 20 * $rem;
    color: $blue-primary;
    font-weight: 400;
  }

  .document-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: -50 * $rem;
  }
}
</style>