<template>
  <div class="employee-table">
    <table :class="{ expanded: showAdditionalColumns }">
      <thead>
        <tr>
          <th class="number">
            <span class="column-title">#</span>
          </th>
          <th class="avatar"></th>
          <th class="first-name">
            <div class="wrapper">
              <span class="column-title">First Name</span>
              <employee-filter
                :list="filteredList.map((el) => el.FirstName)"
                @select="(val) => (filters.firstName = val)"
                @clearFilterValue="filters.firstName = ''"
              />
            </div>
          </th>
          <th class="last-name">
            <div class="wrapper">
              <span class="column-title">Last Name</span>
              <employee-filter
                :list="filteredList.map((el) => el.LastName)"
                @select="(val) => (filters.lastName = val)"
                @clearFilterValue="filters.lastName = ''"
                :filteredIconVisible="filters.lastName.length > 0"
              />
            </div>
          </th>
          <th class="role">
            <span class="column-title">Role</span>
          </th>
          <th class="job">
            <div class="wrapper">
              <span class="column-title">Job</span>
              <employee-filter
                :list="filteredList.map((el) => el.JobName)"
                @select="(val) => (filters.job = val)"
                @clearFilterValue="filters.job = ''"
              />
            </div>
          </th>
          <th class="permissions">
            <span class="column-title">Permissions</span>
          </th>
          <th class="domain">
            <span class="column-title">Domain Name</span>
          </th>
          <th class="status">
            <div class="wrapper">
              <span class="column-title">Status</span>
              <employee-filter
                :list="filteredList.map((el) => el.StatusName)"
                @select="(val) => (filters.status = val)"
                @clearFilterValue="filters.status = ''"
              />
            </div>
          </th>
          <th class="show">
            <div
              class="show-columns"
              :class="{ hide: showAdditionalColumns }"
              @click="showAdditionalColumns = !showAdditionalColumns"
            ></div>
          </th>
          <th class="duration" v-show="showAdditionalColumns">
            <span class="column-title">Employment Duration</span>
          </th>
          <th class="personal-number" v-show="showAdditionalColumns">
            <div class="wrapper">
              <span class="column-title">Pers.Number</span>
              <employee-filter
                :list="filteredList.map((el) => el.PersonalNumber)"
                @select="(val) => (filters.persnum = val)"
                @clearFilterValue="filters.persnum = ''"
              />
            </div>
          </th>
          <th class="birth-date" v-show="showAdditionalColumns">
            <span class="column-title">Birth Date</span>
          </th>
          <th class="gender" v-show="showAdditionalColumns">
            <div class="wrapper">
              <span class="column-title">Gender</span>
              <employee-filter
                :list="
                  filteredList.map((el) => (el.Gender == 1 ? 'Male' : 'Female'))
                "
                @select="(val) => (filters.gender = val)"
                @clearFilterValue="filters.gender = ''"
              />
            </div>
          </th>
          <th class="mob" v-show="showAdditionalColumns">
            <span class="column-title">Mob1</span>
          </th>
          <th class="mob" v-show="showAdditionalColumns">
            <span class="column-title">Mob2</span>
          </th>
          <th class="mob-family" v-show="showAdditionalColumns">
            <span class="column-title">Family Member Phone</span>
          </th>
          <th class="email" v-show="showAdditionalColumns">
            <span class="column-title">E-mail</span>
          </th>
          <th class="email" v-show="showAdditionalColumns">
            <span class="column-title">Personal E-mail</span>
          </th>
          <th class="address" v-show="showAdditionalColumns">
            <span class="column-title">Address</span>
          </th>
          <th class="bank" v-show="showAdditionalColumns">
            <span class="column-title">Bank Account #</span>
          </th>
          <th class="bank" v-show="showAdditionalColumns">
            <span class="column-title">Bank Code</span>
          </th>
          <th class="delete" v-show="showAdditionalColumns"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(person, index) in filteredList">
          <tr :key="person.ID">
            <td>
              <span class="value">{{ index + 1 }}</span>
            </td>
            <td>
              <div class="avatar-wrapper" @click="routerPushToBlank(person)">
                <img
                  :src="getAvatarSrc(person.ID, person.HasAvatar)"
                  @error="failedAvatars.push(person.ID)"
                  v-show="!failedAvatars.includes(person.ID)"
                />
                <div
                  class="fallback-img"
                  v-show="failedAvatars.includes(person.ID)"
                >
                  {{ person.FirstName[0] + person.LastName[0] }}
                </div>
              </div>
            </td>
            <td>
              <span class="value">{{ person.FirstName }}</span>
            </td>
            <td>
              <span class="value">{{ person.LastName }}</span>
            </td>
            <td>
              <img
                src="@/assets/icons/roles.svg"
                class="icon"
                @click="$emit('roles-click', person.ID)"
              />
            </td>
            <td>
              <span class="value job" @click="jobClickHandler(person.JobID)">
                {{ person.JobName }}
              </span>
            </td>
            <td>
              <img
                src="@/assets/icons/permissions.svg"
                class="icon"
                @click="$emit('permissions-click', person)"
              />
            </td>
            <td>
              <span class="value">{{ person.DomainName }}</span>
            </td>
            <td>
              <span class="value">{{ person.StatusName }}</span>
            </td>
            <td></td>
            <td v-show="showAdditionalColumns">
              <span class="value">
                {{ getDurationString(person.StartTime) }}
              </span>
            </td>
            <td v-show="showAdditionalColumns">
              <span class="value">{{ person.PersonalNumber }}</span>
            </td>
            <td v-show="showAdditionalColumns">
              <span class="value">
                {{ getBirthDateString(person.BirthDate) }}
              </span>
            </td>
            <td v-show="showAdditionalColumns">
              <span class="value">{{ getGender(person.Gender) }}</span>
            </td>
            <td v-show="showAdditionalColumns">
              <span class="value">{{ person.MobOne }}</span>
            </td>
            <td v-show="showAdditionalColumns">
              <span class="value">{{ person.MobTwo }}</span>
            </td>
            <td v-show="showAdditionalColumns">
              <span class="value">{{ person.FamilyMemberPhone }}</span>
            </td>
            <td v-show="showAdditionalColumns">
              <span class="value">{{ person.Mail }}</span>
            </td>
            <td v-show="showAdditionalColumns">
              <span class="value">{{ person.PersonalMail }}</span>
            </td>
            <td v-show="showAdditionalColumns">
              <span class="value">{{ person.Address }}</span>
            </td>
            <td v-show="showAdditionalColumns">
              <span class="value">{{ person.BankAccountNum }}</span>
            </td>
            <td v-show="showAdditionalColumns">
              <span class="value">{{ person.BankAccountIban }}</span>
            </td>
            <td v-show="showAdditionalColumns">
              <img
                src="@/assets/icons/delete.svg"
                class="icon delete"
                @click="$emit('delete', person.ID)"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <custom-popup
      v-if="showJobEmployees && !$store.getters.hasRole('Lawyer')"
      :visible="true"
      @close="showJobEmployees = false"
      :flexibleSize="true"
      :bigSize="true"
    >
      <job-employees :jobID="selectedJob" />
    </custom-popup>
  </div>
</template>

<script>
import EmployeeFilter from './EmployeeFilter'
import CustomPopup from '@/components/ReusableComponents/CustomPopup/CustomPopup'
import JobEmployees from '@/components/Jobs/JobEmployees'
export default {
  components: {
    CustomPopup,
    JobEmployees,
    EmployeeFilter,
  },
  props: {
    employees: Array,
    salaryID: Number,
  },
  data() {
    return {
      showAdditionalColumns: false,
      failedAvatars: [],
      showJobEmployees: false,
      selectedJob: 0,
      filters: {
        firstName: '',
        lastName: '',
        job: '',
        status: '',
        gender: '',
      },
    }
  },
  methods: {
    getAvatarSrc(personID, avatar) {
      if (avatar) {
        return this.$store.state.getAvatar + personID
      }
    },
    routerPushToBlank(person) {
      let routeData = this.$router.resolve({
        name: 'Edit Profile',
        params: { id: person.ID },
      })

      window.open(routeData.href, '_blank')
    },
    getDurationString(start) {
      var duration = Date.now() - start
      var fullDays = parseInt(duration / (24 * 3600000))
      var days = fullDays % 30
      var months = parseInt(fullDays / 30)
      var res = ''
      if (months) {
        if (res) res += ', '
        res += `${months} month`
      }
      if (days) {
        if (res) res += ' & '
        res += `${days} days`
      }
      return res
    },
    getBirthDateString(time) {
      if (time == 0) return ''
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      var d = new Date(time)
      return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`
    },
    getGender(gender) {
      if (gender == null) return ''
      switch (gender) {
        case 1:
          return 'Male'
        case 2:
          return 'Female'
      }
    },
    jobClickHandler(jobID) {
      this.showJobEmployees = true
      this.selectedJob = jobID
    },
  },
  computed: {
    filteredList() {
      return this.employees.filter((el) => {
        let keys = Object.keys(this.filters)
        return keys.every((key) => {
          let value = this.filters[key]
          if (value == '') return true
          switch (key) {
            case 'firstName':
              return value == el.FirstName
            case 'lastName':
              return value == el.LastName
            case 'job':
              return value == el.JobName
            case 'status':
              return value == el.StatusName
            case 'gender':
              var genderEnum = el.Gender
              if (genderEnum == 1) return value == 'Male'
              if (genderEnum == 2) return value == 'Female'
          }
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
$paddingLeft: 40 * $rem;
$cell-height: 80 * $rem;

.employee-table {
  overflow-x: auto;
}

table {
  border-spacing: unset;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;

  &.expanded {
    width: 250rem;
  }

  thead {
    th {
      font-weight: 400;
      font-size: 18 * $rem;
      color: $blue-primary;
      align-items: center;
      height: $cell-height;
      text-align: left;
      background-color: #ededfc;

      .wrapper {
        display: flex;
        align-items: center;
      }

      &:first-child {
        padding-left: $paddingLeft;
      }

      &.number {
        width: 88 * $rem;
        position: sticky;
        z-index: 2;
        left: 0 * $rem;
      }

      &.avatar {
        width: 90 * $rem;
        position: sticky;
        z-index: 2;
        left: 88 * $rem;
      }

      &.first-name {
        width: 155 * $rem;
        position: sticky;
        z-index: 2;
        left: 178 * $rem;
      }

      &.last-name {
        width: 190 * $rem;
        position: sticky;
        z-index: 2;
        left: 333 * $rem;
      }

      &.role {
        width: 90 * $rem;
      }

      &.job {
        width: 272 * $rem;
      }

      &.permissions {
        width: 150 * $rem;
      }

      &.domain {
        width: 200 * $rem;
      }

      &.status {
        width: 178 * $rem;
      }

      &.show {
        width: 75 * $rem;

        .show-columns {
          width: 32 * $rem;
          height: 32 * $rem;
          border: 2 * $rem solid rgba($blue-primary, 0.6);
          border-radius: 50%;
          position: relative;
          cursor: pointer;

          &::before {
            content: '';
            position: absolute;
            width: 20 * $rem;
            height: 2 * $rem;
            top: 13 * $rem;
            left: 4 * $rem;
            background-color: rgba($blue-primary, 0.6);
          }

          &:not(.hide)::after {
            content: '';
            position: absolute;
            width: 2 * $rem;
            height: 20 * $rem;
            top: 4 * $rem;
            left: 13 * $rem;
            background-color: rgba($blue-primary, 0.6);
          }
        }
      }

      &.duration {
        width: 260 * $rem;
      }

      &.personal-number {
        width: 200 * $rem;
      }

      &.birth-date {
        width: 165 * $rem;
      }

      &.gender {
        width: 135 * $rem;
      }

      &.mob {
        width: 155 * $rem;
      }

      &.mob-family {
        width: 200 * $rem;
      }

      &.email {
        width: 260 * $rem;
      }

      &.address {
        width: 340 * $rem;
      }

      &.bank {
        width: 260 * $rem;
      }

      &.delete {
        width: 80 * $rem;
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 16 * $rem;
        font-weight: 300;
        color: $blue-primary;
        height: $cell-height;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 260 * $rem;

        &:first-child {
          padding-left: $paddingLeft;
        }

        &:nth-child(1) {
          position: sticky;
          z-index: 2;
          left: 0 * $rem;
        }

        &:nth-child(2) {
          position: sticky;
          z-index: 2;
          left: 88 * $rem;
        }

        &:nth-child(3) {
          position: sticky;
          z-index: 2;
          left: 178 * $rem;
        }

        &:nth-child(4) {
          position: sticky;
          z-index: 2;
          left: 333 * $rem;
        }

        .avatar-wrapper {
          width: 60 * $rem;
          height: 60 * $rem;
          background-color: #fff;
          border-radius: 50%;
          cursor: pointer;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            margin: -1 * $rem 0 0 -1 * $rem;
          }

          .fallback-img {
            background-color: $green-primary;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            color: white;
            font-weight: 400;
          }
        }

        .icon {
          margin-left: -10 * $rem;
          width: 60 * $rem;
          cursor: pointer;

          &.delete {
            width: 40 * $rem;
          }
        }

        .job {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      &:nth-child(2n + 1) td {
        background-color: #e7e7f9;
      }

      &:nth-child(2n) td {
        background-color: #eeedfc;
      }
    }
  }
}
</style>
