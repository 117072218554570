<template>
  <div class="traffic-workspace background">
    <div class="header">
      <router-link tag="a" :to="{ path: 'MyTraffic' }">
        <img :src="require('@/assets/icons/icon_back.svg')" />
      </router-link>
    </div>
    <div class="traffic__data">
      <div class="traffic__grid" id="appData">
        <traffic-grid :data="appData" :fields="appFields" :options="options" />
      </div>
      <div class="traffic__grid" id="webData">
        <traffic-grid :data="webData" :fields="webFields" :options="options" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance";
import TrafficGrid from "@/components/Traffic/TrafficGrid";

export default {
  components: { TrafficGrid },
  async created() {
    await this.getData();
  },
  data() {
    return {
      options: {
        withCheckbox: false,
        withoutSessionDuration: true,
      },
      appData: [],
      webData: [],
    };
  },
  computed: {
    appFields() {
      return [{ title: `Apps ${this.appData.length}`, class: "source" }];
    },
    webFields() {
      return [{ title: `Webs ${this.webData.length}`, class: "source" }];
    },
  },
  methods: {
    async getData() {
      const response = await axios.get("attendance/GetPersonsPrograms", {
        params: {
          person: this.$store.state.profile.ID,
          // person: 161,
        },
      });
      const { Value } = response.data;
      const webData = Value.filter((el) => el.URL !== null);
      const appData = Value.filter((el) => el.URL === null);
      this.webData = webData.map((el) => ({
        checked: true,
        ProgramName: el.ProgramName,
        File: el.File,
        URL: el.URL,
      }));
      this.appData = appData.map((el) => ({
        checked: true,
        ProgramName: el.ProgramName,
        File: el.File,
      }));
    },
  },
};
</script>

<style scoped lang="scss">
.traffic-workspace {
  height: 100%;
  padding: 25px;
  overflow: hidden;
}

.traffic__data {
  display: flex;
  justify-content: space-between;
  padding: 0 1.875rem;
  border-radius: 10px;
  height: calc(100% - 30px);
  &-header {
    margin-bottom: 13px;
    color: #283593;
    margin-left: 70px;
  }
}

.traffic__grid {
  width: 50%;
  overflow-y: auto;
  height: 100%;
  position: relative;
  margin-top: -21px;
}

.no-records {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  font-size: 50px;
  color: rgba(#283593, 0.5);
}
</style>
