<template>
    <div class="background">
        <div class="top-header">
            <switch-header  :routes="routes" class="switch-header"/>
            <!-- <div class="team-title">
                <span>
                    IT Team
                </span>
            </div> -->
        </div>
        <custom-snackbar
            :text="snackbar.text"
            :purpose="snackbar.purpose"
            v-if="snackbar.visible"
            @close="snackbar.visible = false"
        />
        <div class="content-wrapper">
            <custom-loader v-if="loading" />
            <transition name="fade">            
                <div :class="['middle',{'fullSize':!chartContainerVisible}]">
                    <div class="no-records" v-if="teamHistory.length < 1 && !loading">
                        <span>There are no Records</span>
                    </div>
                    <div class="middle-header">
                        <div class="middle-header-top">
                            <div class="score-info">
                                <h2>Monthly Average Score</h2>
                                <span>Rated  <span style="color:#2EBD59;font-weight: 700">{{totalRatedPersons}}</span> from <span style="color:#7D8FB3;font-weight: 700"> {{totalPersons}} </span> Employee</span>
                            </div>
                            <div class="scores">
                                <ul>
                                    <li :class="{'current':i.score === teamAvgScore}" :style="`background:${i.color}`" v-for="(i,ind) in scoreRanges()" :key="ind">
                                        <span>
                                            {{i.score}}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div class="actions-wrapper">
                                <div class="chart-page-btn">
                                    <router-link 
                                    :to="{path: '/Grades/TeamOverallChart'}"
                                    >
                                    <img src="@/assets/grades/chart.svg" alt="">
                                    </router-link>
                                </div>
                                <div class="compare" @click="comparePersons">
                                    <img src="@/assets/grades/ratingOverall/team.svg" alt="">
                                    <span>Compare</span>
                                </div>
                                <div class="new-rate" ref="rateDropdownBtn">
                                    <div :class="['new-rate-btn',{'active':personsDropdownVisible}] " @click="personsDropdownVisible = !personsDropdownVisible">
                                        <img class="" src="@/assets/grades/ratingOverall/rate.svg" alt="">
                                        <span class="">Rate</span>
                                    </div>
                                    <transition name="fade" ref="rateDropdown">
                                        <div class="persons-dropdown " v-if="personsDropdownVisible">
                                            <ul v-if="!findDetailedPersonJobs" class="">
                                                <li class="search-persons-list no-hover ">
                                                    <input class="" type="text" placeholder="Search..." v-model="personsListSearchValue" >
                                                </li>
                                                <li @click.stop="personAvailableJobs(person.personID,person.jobID,person.personJobID,person.personGradingHistoryID,true)" v-show="filterPersonsList(person.name,personsListSearchValue)" class="single-person " v-for="(person,ind) in teamPersonsList" :key="ind">
                                                    <div class="person-avatar" :style="`background-image:url(${getPersonAvatar(person.personID)})`" >
                                                    </div>
                                                    <span>
                                                        {{person.name}}
                                                    </span>
                                                </li>
                                            </ul>
                                            <ul class="" v-if="findDetailedPersonJobs">
                                                <li class="no-hover ">
                                                    <div class="back-to-list " @click="(findDetailedPersonJobs = false,personsListJobSearchValue = '')">
                                                        <img class="" src="@/assets/grades/back.svg" alt="">
                                                        <span class="" >Back</span>
                                                    </div>
                                                </li>
                                                <li class="search-persons-list no-hover ">
                                                    <input class="" type="text" placeholder="Search..." v-model="personsListJobSearchValue" >
                                                </li>
                                                <li @click="personAvailableJobs(personDataQuery.id,personJob.value,personDataQuery.personJobID,personDataQuery.gradeHistoryID,true,true)" v-show="filterPersonsList(personJob.title,personsListJobSearchValue)" class="" v-for="(personJob,ind) in selectedPersonJobs" :key="ind">
                                                    <span>
                                                        {{personJob.title}}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </transition>
                                </div>
                            </div>
                        </div>
                        <div class="middle-header-bottom"> 
                            <div class="filter-tags">
                                <!-- <div class="filter">
                                    <img src="@/assets/grades/ratingOverall/filter.svg" alt="">
                                    <span>Filter</span>
                                </div> -->
                                <multi-filter 
                                    :class="['multi-filter',{'position':filteredObjectsData.length < 1}]"
                                    :visible="filterCollapsed"
                                    @collapse="(val) => filterCollapsed = val"
                                    @close="(val) => (filterCollapsed = val)"
                                    :data="filterTagsData"
                                    @selectedParent="selectParent"
                                    :selectedParentName="selectedParentName"
                                    :selectedParentChildrens="selectedParentChilds"
                                    @selectChild="selectTag"
                                    @closeParent="clearParentArr"
                                    @minScore="(val) => filterTagsData.find(el => el.typeName === 'score' ).minScore = val"
                                    @maxScore="(val) => filterTagsData.find(el => el.typeName === 'score' ).maxScore = val"
                                    :scoreMin="filterTagsData.find(el => el.typeName === 'score' ).minScore"
                                    :scoreMax="filterTagsData.find(el => el.typeName === 'score' ).maxScore"

                                    :triggerClearRangeValues="clearRangeValues"
                                />
                                <ul v-if="filteredObjectsData.length > 0" v-dragscroll>
                                    <li class="clear-all-filters" @click="clearAllFilteredTags">
                                        <span>Clear All</span>
                                    </li>
                                    <li class="single-tag" v-for="(option,ind) in filteredObjectsData" :key="ind">
                                        <div class="remove-tag" @click.stop="removeFilteredTag(option)">
                                            <img src="@/assets/grades/ratingOverall/close.svg" alt="">
                                        </div>
                                        <span>
                                            {{option.title ? option.title : '-'}}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="middle-content">
                        <div class="select-all-persons" v-if="teamHistory.length > 0">
                            <check-box
                                :checked="allpersonsSelected"
                                @value="(val) => allpersonsSelected = val"
                            />
                            <span @click="allpersonsSelected = !allpersonsSelected">
                                Select All
                            </span>
                        </div>
                        <div class="persons-list">
                            <ul>
                                <li  :class="{'selected': p.selected}" v-for="(p,ind) in teamHistory " :key="ind">
                                    <div class="delete" v-if="!p.Status && p.Graded" @click="deleteDraftedHistory(p.PersonGradingHistoryID)">
                                        <img src="@/assets/grades/trash-gray.svg" alt="">
                                    </div>
                                    <div class="index">
                                        {{ind + 1}}
                                    </div>
                                    <div class="checkbox-wrapper">
                                        <check-box
                                            :checked="p.selected"
                                            @value="(state) => (p.selected = state, addPersonToChartAnalytics(state,p))"
                                        />
                                    </div>
                                    <div class="avatar-wrapper" @click="personDetails(p.PersonID,p.JobID,p.PersonJobID,p.PersonGradingHistoryID,false,p.Graded)">
                                        <div class="avatar-placeholder" :style="`background-image:url(${getPersonAvatar(p.PersonID)})`">
                                            <!-- <img :src="getPersonAvatar(p.PersonID)" alt=""> -->
                                        </div>
                                    </div>
                                    <div class="person-details">
                                        <div class="top">
                                            <h2>
                                                {{!p.FirstName && !p.LastName ? '-' : p.FirstName + ' ' + p.LastName}}
                                            </h2>
                                            <span>
                                                {{p.JobName ? p.JobName : '-'}}
                                            </span>
                                        </div>
                                        <div :class="['bottom-score',{'notRated':!p.Graded}]">
                                            <div :class="['single-score',{'blank': p.AvgScore < i.score}]" :style="`background:${i.color}`"  v-for="(i,ind) in personScoreRanges()" :key="ind"> </div>
                                        </div>
                                    </div>

                                    <div class="person-badge">
                                        <div class="single-badge" v-if="p.Graded" >
                                            <div class="badge">
                                                <img :src="getBadge(p.CurrentGradeID)" alt="">
                                            </div>
                                            <div class="badge-details">
                                                <span>{{p.CurrentGradePartDescription ? p.CurrentGradePartDescription : '-'}}</span>
                                                <h2>{{p.CurrentGradeBadgeName ? p.CurrentGradeBadgeName : '-'}}</h2>
                                            </div>
                                        </div>
                                        <!-- <div class="single-badge" v-if="p.Graded && false">
                                            <div class="badge-checkbox">
                                                <check-box 
                                                    :checked="false"
                                                />
                                            </div>
                                            <div class="badge">
                                                <img src="@/assets/grades/rambo.svg" alt="">
                                            </div>
                                            <div class="badge-details">
                                                <span>Senior</span>
                                                <h2>Rambo</h2>
                                            </div>
                                        </div> -->
                                    </div>

                                    <div class="person-comment">
                                        <div :class="['comment-btn']" v-if="p.SomethingHasUnseen">
                                            <div :class="['img-wrapper',{'marked': p.SomethingHasUnseen}]" @click="personDetails(p.PersonID,p.JobID,p.PersonJobID,p.PersonGradingHistoryID,false,p.Graded)">
                                                <img src="@/assets/grades/ratingOverall/comment.svg" alt="">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="person-total-score">
                                        <div class="left-side">
                                            <div v-if="p.Graded">
                                                <span :style="comparedScoreColor(p.AvgScore,p.BeforeGradeAvgScore)">
                                                    {{compareAvgScore(p.AvgScore,p.BeforeGradeAvgScore).result.toFixed(1) }}
                                                </span>
                                                <img :src="require(compareAvgScore(p.AvgScore,p.BeforeGradeAvgScore).compareStatus === 'increased' ? '@/assets/grades/ratingOverall/increase.svg' : compareAvgScore(p.AvgScore,p.BeforeGradeAvgScore).compareStatus === 'decreased' ? '@/assets/grades/ratingOverall/decrease.svg' : '@/assets/grades/ratingOverall/line.svg')" alt="">
                                            </div>
                                            <span style="white-space:nowrap" v-if="p.Graded">
                                                Rated {{calculateTimeDiff(new Date(p.CreateTime), new Date())}}
                                            </span>
                                            <span style=""  v-else>Not Rated</span>
                                        </div>
                                        <div class="right-side">
                                            <div :class="['current-score',{'notRated':!p.Graded}]" :style="personCurrentScoreColor(recalculateAveragePersonScore(p.AvgScore),p.Status ? false : true)">
                                                <span v-if="p.Graded" :style="personCurrentScoreColor(recalculateAveragePersonScore(p.AvgScore),p.Status ? false : true,p.Status ? false : true)">
                                                    {{ recalculateAveragePersonScore(p.AvgScore)}}
                                                </span>
                                                <span v-else>
                                                    -
                                                </span>
                                            </div>
                                            <div class="toDetails" @click="personDetails(p.PersonID,p.JobID,p.PersonJobID,p.PersonGradingHistoryID,false,p.Graded)">
                                                <img src="@/assets/grades/ratingOverall/arrowleft.svg" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="fade">
                <div class="right-content" v-if="chartContainerVisible">
                    <div class="content-header">
                        <div class="title">
                            <span>Engagement by Criterias</span>
                        </div>
                    </div>
                    <div class="right-middle-content">
                        <div class="no-records" v-if="chartDataNotAvailable">
                            <span>Empty</span>
                        </div>
                        <radar-chart
                            v-if="this.chartData.datasets.length > 0"
                            :chartData="chartData"
                            :options="chartOptions"
                            :triggerChartUpdate="triggerChartUpdate"
                        />
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance.js";
import CustomLoader from '@/components/ReusableComponents/Loader/CustomLoader.vue'
import CheckBox from '@/components/Grades/GradeComponents/CheckBox.vue'
import Score from '@/components/Grades/classes/GradingScore.js'
import MultiFilter from '@/components/Grades/GradeComponents/MultiFilter/MultiFilter.vue'
import RadarChart from "@/components/ReusableComponents/Charts/RadarChart.js";
import RandomColor from '@/components/Grades/classes/ColorGenerator.js'
import { dragscroll } from "vue-dragscroll";
import getDateString from '@/Helpers/HelperFunctions.js'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import SwitchHeader from '@/components/ReusableComponents/SwitchHeader/SwitchHeader.vue'



// import RadarChart from '@/components/ReusableComponents/Charts/RadarChart.vue'

export default {
    components:{
        CheckBox,
        CustomLoader,
        MultiFilter,
        RadarChart,
        CustomSnackbar,
        SwitchHeader
    },
    directives:{
        dragscroll
    },
    data(){
        return{
            routes:[
                {
                    title:'Grade Nodes',
                    path:'/Grades/GradeNodes'
                },
                {
                    title:'Criterias',
                    path:'/Grades/Criterias'
                },
                {
                    title:'Team Rating',
                    path:'/Grades/TeamRatingOverall'
                }
            ],
            snackbar:{
                text:'',
                purpose: '',
                visible: false,
            },
            loading: false,
            teamHistory: [],
            teamPersonsList: [],
            teamJobsList:[],
            selectedPersonJobs:[],
            findDetailedPersonJobs: false,
            personsDropdownVisible: false,
            personsListSearchValue: '',
            personsListJobSearchValue: '',
            personDataQuery: {},

                //filters
            filterCollapsed: false,
            allpersonsSelected: false,
            selectedParentName: '',
            selectedParentChilds: [],

            selectedFilters:{
                persons:[],
                jobs: [],
                departments: [],
                grades: [],
            },
            filteredObjectsData: [],
            clearRangeValues: false,
            filterTagsData: [
                {
                    title: 'Department/Team',
                    typeName: 'department',
                    children: [],
                    selected: false,
                },
                {
                    title: 'Job',
                    typeName: 'job',
                    children: [],
                    selected: false,
                },
                {
                    title: 'Persons',
                    typeName: 'person',
                    children: [],
                    selected: false,
                },
                {
                    title: 'Grade',
                    typeName: 'grade',
                    children: [],
                    selected: false,
                },
                {
                    title: 'Status',
                    typeName: 'status',
                    children: [
                        {
                            title: 'Pending',
                            value: false,
                            selected: false,
                            parentTypeName: 'status'
                        },
                        {
                            title: 'Sent',
                            value: true,
                            selected: false,
                            parentTypeName: 'status'
                        }
                    ],
                    selected: false,
                },
                {
                    title: 'Score',
                    typeName: 'score',
                    children: [],
                    selected: false,
                    minScore: null,
                    maxScore: null,
                },
                {
                    title: 'Graded',
                    typeName: 'graded',
                    children: [
                        {
                            title:'Graded',
                            value: true,
                            selected: false,
                            parentTypeName:"graded"
                        },
                        {
                            title:'Not Graded',
                            value: false,
                            selected: false,
                            parentTypeName:"graded"
                        }
                    ],
                    selected: false,
                },
                {
                    title: 'Unread Comment',
                    typeName: 'comment',
                    children: [
                        {
                            title:'Seen',
                            value: false,
                            selected: false,
                            parentTypeName:"comment"
                        },
                        {
                            title:'Not Seen',
                            value: true,
                            selected: false,
                            parentTypeName:"comment"
                        }
                    ],
                    selected: false,
                },
            ],
            chartData:{
                datasets:[],
                labels: [],
            },
            chartOptions: {
                title: {
                    display: true,
                    text: 'Team Rating Analytics'
                },
                scale: {
                    ticks: {
                        beginAtZero: true,
                        showLabelBackdrop: false
                    },
                    pointLabels: {
                        callback: (label) => {
                            return label.length > 5 ? label.substr(0, 5) + '...' : label;
                        },
                    },
                },
                pointLabels:{
                    callbacks: {
                        label: function(label) {

                        }
                    }
                },
                legend: {
                    labels: {
                        usePointStyle: true,
                        boxWidth: 6,
                    }
                },        
            },
            chartDataNotAvailable:false,
            chartContainerVisible: false,
            chartDataPlaceholder: [],
            selectedPersonsForChartDisplay: [],
            triggerChartUpdate: false,
            teamAvgScore: null,
            totalRatedPersons: null,
            totalPersons: null,
        }
    },
    async created(){
        await this.getFilterLists()
        let lc = window.localStorage
        if(lc.getItem('overallFilterTags')){
            this.filteredObjectsData = JSON.parse(lc.getItem('overallFilterTags'))
            this.filteredObjectsData.forEach(el => {
                if(el.parentObjType !== 'score'){
                    this.filterTagsData.find(f => f.typeName === el.parentObjType).children.find(c => c.value === el.value).selected = true
                    let savedObj = this.filterTagsData.find(f => f.typeName === el.parentObjType).children.find(c => c.value === el.value)
                    if(el.parentObjType === 'person') this.selectedFilters.persons.push(savedObj)
                    if(el.parentObjType === 'job') this.selectedFilters.jobs.push(savedObj)
                    if(el.parentObjType === 'grades') this.selectedFilters.grades.push(savedObj)
                    if(el.parentObjType === 'departments') this.selectedFilters.departments.push(savedObj)
                }else{
                    let minScore = el.title.split('-')[0]
                    let maxScore = el.title.split('-')[1] ? el.title.split('-')[1] : null
                    this.filterTagsData.find(el => el.typeName === 'score').minScore = minScore ? minScore : null
                    this.filterTagsData.find(el => el.typeName === 'score').maxScore = maxScore ? maxScore : null
                }
            })
            this.getTeamGradeHistory(true)
        }else{
            await this.getTeamGradeHistory()
        }

        document.addEventListener('click',this.clickHandler)
    },
    beforeDestroy(){
        document.removeEventListener('click',this.clickHandler)
        this.chartContainerVisible = false
    },
    watch:{
        allpersonsSelected(val){
            this.selectAllPersons(val)
        },
        filterCollapsed(val){
            if(!val)
            this.getTeamGradeHistory(true)
        },
        selectedPersonsForChartDisplay(val){
            this.triggerChartUpdate = !this.triggerChartUpdate
            if(val.length > 0){
                this.chartContainerVisible = true
                if(this.chartDataPlaceholder.length > 0){
                    this.chartData.datasets = this.chartDataPlaceholder.filter(el => this.selectedPersonsForChartDisplay.includes(el.personID))
                }
            }else{
                this.chartContainerVisible = false
            }
        },
        immediate:false,
    },
    methods:{
        clickHandler($event){
            if($event.path.includes(this.$refs.rateDropdown) || $event.path.includes(this.$refs.rateDropdownBtn)) return
            else{
                this.personsDropdownVisible = false
            }
        },
        dateString(timeStamp){
            return getDateString(timeStamp,'dd-MM-yyyy')
        },
        calculateTimeDiff(end,start){
            let months;
            months = (start.getFullYear() - end.getFullYear()) * 12
            months -= end.getMonth()
            months += start.getMonth()
            const diffTime = Math.abs(start - end);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return months <= 0 && (diffDays - 1) === 0 ? 'Recently' : (diffDays - 1) !== 0 && months <= 0 ? (diffDays - 1) + ' Days Ago'  : months + ' Months Ago'
        },
        async getTeamGradeHistory(filterMode,withLoader){
            this.loading = true
            let departmentFilters = this.modifyFilterDataArr(this.selectedFilters.departments)
            let gradeFilters = this.modifyFilterDataArr(this.selectedFilters.grades)
            let jobFilters = this.modifyFilterDataArr(this.selectedFilters.jobs)
            let personFilters = this.modifyFilterDataArr(this.selectedFilters.persons)

            let minScore = this.filterTagsData.find(el => el.typeName === 'score' ).minScore
            let maxScore = this.filterTagsData.find(el => el.typeName === 'score' ).maxScore

            let status = this.filterTagsData.find(el => el.typeName === 'status').children.find(el => el.selected)
            let graded = this.filterTagsData.find(el => el.typeName === 'graded').children.find(el => el.selected)
            let comment = this.filterTagsData.find(el => el.typeName === 'comment').children.find(el => el.selected)

            if(filterMode && this.chartContainerVisible) this.chartContainerVisible = false
            await axios.post('grading/GetTeamsGradingHistory',{
                departments: filterMode ? departmentFilters : [],
                jobs: filterMode ? jobFilters : [],
                persons: filterMode ? personFilters :  [],
                grades: filterMode ? gradeFilters : [],
                status: filterMode && status ? status.value : null,
                scoreFrom: filterMode ? parseInt(minScore) : null,
                scoreTo: filterMode ? parseInt(maxScore) : null,
                graded: filterMode && graded ? graded.value : null,
                SomethingHasUnseen: filterMode && comment ? comment.value : null
            }).then(resp => {
                if(filterMode) this.chartContainerVisible = false
                let data = resp.data.Value.PersonGradingHistories
                let criteriaData = resp.data.Value.GradingHistoryCriterias
                let chartData = resp.data.Value.Chart
                if(chartData.dataSets.length < 1 && chartData.labels.length < 1) this.chartDataNotAvailable = true
                else this.chartDataNotAvailable = false

                this.teamAvgScore = Math.abs(Math.ceil(resp.data.Value.TeamsAvgScore))
                this.totalRatedPersons = resp.data.Value.GradedTotal ? resp.data.Value.GradedTotal : null
                this.totalPersons = resp.data.Value.TeamTotal ? resp.data.Value.TeamTotal : null
                if(this.teamAvgScore > 10) this.teamAvgScore = 10


                this.generateChartData(chartData)
                data.forEach(el => {
                    this.$set(el,'selected',false)
                    this.$set(el,'colorPalette',new RandomColor(90).adjustColor(130))
                })
                this.teamHistory = data
                this.loading = false
                this.collectFilteredDataValues()
                let personsList = data.map(el => ({
                    name: el.FirstName + ' ' + el.LastName,
                    jobID: el.JobID,
                    personID: el.PersonID,
                    personGradingHistoryID: el.PersonGradingHistoryID,
                    personJobID: el.PersonJobID,
                }))
                this.teamPersonsList = [...new Map(personsList.map(e=> [e['personID'], e])).values()]
            })
        },
        getTeamPersons(){

        },
        generateChartData(array){
            // let chartData = {
            //     labels: [
            //     'კოდირება, ცოდნა, კომპეტენცია',
            //     '"დისციპლინა და ეთიკა',
            //     ],
            //     datasets: [
            //         {
            //             label: 'admin Janelidze',
            //             backgroundColor: '#e5e0e07a',
            //             borderColor: new RandomColor(90).adjustColor(90),
            //             pointBackgroundColor: 'rgba(179,181,198,1)',
            //             pointBorderColor: '#fff',
            //             pointHoverBackgroundColor: '#fff',
            //             pointHoverBorderColor: 'rgba(179,181,198,1)',
            //             data: [7,5]
            //         },
            //         {
            //             label: 'axali tipi ',
            //             backgroundColor: '#e5e0e07a',
            //             borderColor: new RandomColor(90).adjustColor(90),
            //             pointBackgroundColor: 'rgba(255,99,132,1)',
            //             pointBorderColor: '#fff',
            //             pointHoverBackgroundColor: '#fff',
            //             pointHoverBorderColor: 'rgba(255,99,132,1)',
            //             data: [2,10]
            //         }
            //     ]
            // }
            let chartColors = []
            array.dataSets.forEach((el,ind) => {
                let color = this.getRandomColor()
                if(chartColors.some(c => c === color)){
                    color = this.getRandomColor()
                }else{
                    chartColors.push(color)
                }
                el['backgroundColor'] = chartColors[ind]
                el['borderColor'] = chartColors[ind]
                el['pointBackgroundColor'] = chartColors[ind]
                el['pointBorderColor'] = chartColors[ind]
                el['pointHoverBackgroundColor'] = chartColors[ind]
                el['pointHoverBorderColor'] = chartColors[ind]
            })
            // array.labels = array.labels.map(el => el.split(' ')[0])
            this.chartData.datasets = array.dataSets
            this.chartDataPlaceholder = array.dataSets
            this.chartData.labels = array.labels
        },
        getRandomColor() {
            var letters = '0123456789ABCDEF'.split('');
            var color = '#';
            for (var i = 0; i < 6; i++ ) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`;
            return setOpacity(color,.3);
        },
        collectFilteredDataValues(){
            let allFilters = []
            let findObj = (typeName)  => this.filterTagsData.find(el => el.typeName === typeName)
            let availableFilterTypes = ['department','job','person','grade','status','graded','comment']

            let minScore =  this.filterTagsData.find(el => el.typeName === 'score').minScore
            let maxScore =  this.filterTagsData.find(el => el.typeName === 'score').maxScore

            if(minScore && maxScore){
                allFilters.push(
                    {
                        title: `${minScore}-${maxScore}`,
                        parentObjType: 'score'
                    }
                )

            }

            if(minScore && !maxScore){
                allFilters.push(
                    {
                        title: `${minScore}`,
                        parentObjType: 'score'
                    }
                )
            }

            if(maxScore && !minScore){
                allFilters.push(
                    {
                        title: `${maxScore}`,
                        parentObjType: 'score'
                    }
                )
            }

            availableFilterTypes.forEach(el =>{
                findObj(el).children.forEach(obj => {
                    if(obj.selected){
                        allFilters.push({
                            title: obj.title,
                            value: obj.value,
                            parentObjType: el,
                        })
                    }else return
                })
            })

            this.filteredObjectsData = allFilters
            if(allFilters.length > 0){
                window.localStorage.setItem('overallFilterTags',JSON.stringify(allFilters))
            }
        },
        snackbarMessage(text,purpose){
            this.snackbar.visible = true
            this.snackbar.text = text
            this.snackbar.purpose = purpose
        },
        modifyFilterDataArr(arr){
            if(arr.length === 0 || !arr) return []
            let dataArr = []
            arr.forEach(el => {
                if(el.selected) dataArr.push({id:el.value});
                else return
            })
            return [...new Map(dataArr.map(e=> [e['id'], e])).values()]
        },
        getPersonAvatar(id){
            let isProd = location.href.indexOf('.upgaming.com')>0;
            let reqSrc
            if(isProd){
                reqSrc = 'https://intranet.upgaming.com/api/Person/GetPersonAvatar2?ID='
            }else{
                reqSrc = 'https://intranet.upgaming.dev/api/Person/GetPersonAvatar2?ID='
            }

            return reqSrc + id
        },
        personAvailableJobs(id,jobID,personJobID,gradeHistoryID,createNewGrading,directMove){
            if(directMove){
                this.personDetails(id,jobID,personJobID,gradeHistoryID,createNewGrading)
            }else{
                axios.get('jobs/GetPersonJobs',{
                    params:{
                        Person: id,
                    }
                }).then(resp => {
                    let moreThanOneJob = false
                    let personJobs = resp.data.Value
                    if(personJobs.length > 1){
                        moreThanOneJob = true
                        this.personDataQuery = {
                            id: id,
                            jobID: jobID,
                            personJobID: personJobID,
                            gradeHistoryID: gradeHistoryID,
                        }
                        this.findDetailedPersonJobs = true
                        this.selectedPersonJobs = personJobs.map(job => ({
                            title: job.JobName,
                            value: job.Job,
                        }))
                    }else{
                        this.findDetailedPersonJobs = false
                        this.personDetails(id,jobID,personJobID,gradeHistoryID,createNewGrading)
                    }
                    
                })
            }
        },
        personDetails(id,jobID,personJobID,gradeHistoryID,createNewGrading,graded){
            if(!id || id === 0) return
            else{
                if(createNewGrading){
                    this.$router.push({path: `PersonRatingOverall/${id}`,query:{jobID:jobID,personJobID:personJobID,gradeHistoryID:gradeHistoryID,createGrading:true}})
                }else{
                    if(!graded){
                        this.$router.push({path: `PersonRatingOverall/${id}`,query:{jobID:jobID,personJobID:personJobID,gradeHistoryID:gradeHistoryID,graded:graded,createGrading:true}})
                    }else{
                        this.$router.push({path: `PersonRatingOverall/${id}`,query:{jobID:jobID,personJobID:personJobID,gradeHistoryID:gradeHistoryID,graded:graded}})
                    }
                }
            }
        },
        scoreRanges(){
            let scoresArr = []
            for (let i = 1; i <= 10; i++){
                scoresArr.push(new Score(i).scoreObj())
            }
            return scoresArr
        },
        personScoreRanges(){
            let personScoresArr = []
            for (let i = 1; i <= 10; i++){
                personScoresArr.push(new Score(i).scoreObj(true))
            }
            return personScoresArr
        },
        personCurrentScoreColor(score,border,text){
            let style = ''
            if(!border){
                style = `background:${new Score(score).currentScoreColor()}`
            }else{
                style = `border: 2px solid ${new Score(score).currentScoreColor()}`
            }

            if(text){
                style = `color: ${new Score(score).currentScoreColor()}`
            }
            return  style
        },
        isInt(n) {
            return n % 1 === 0;
        },
        recalculateAveragePersonScore(score){
            if(this.isInt(score)) return score
            else{
                if(score.toString().split('.')[1] === '5') return score
                else{
                    if(parseInt(score.toString().split('.')[1]) > 5){
                        return Math.ceil(score)
                    }else{
                        return Math.round(score)
                    }
                }
            }
        },
        filterPersonsList(name,searchString){
            let visible = false
            if(
                name.toLowerCase().split(' ').join('').trim().includes(searchString.toLowerCase().split(' ').join('').trim())
            ) visible = true
            else visible = false
            return visible
        },
        selectAllPersons(val){
            this.teamHistory.forEach(el => el.selected = val)
        },
        getBadge(id){
            let isProd = location.href.indexOf('.upgaming.com')>0;
            let reqSrc
            if(isProd){
                reqSrc = 'https://intranet.upgaming.com/api/grading/GetGradeBadgeNameIcon?GradeID='
            }else{
                reqSrc = 'https://intranet.upgaming.dev/api/grading/GetGradeBadgeNameIcon?GradeID='
            }

            return reqSrc + id
        },
        async getFilterLists(){
            await axios.get('grading/GetGradingHistoryFilterLists').then(resp => {
                let filterData = resp.data.Value
                let filterParent = (typename) => this.filterTagsData.find(el => el.typeName === typename)
                
                filterParent('department').children = filterData.Departments.map(el => ({
                    title: el.DepartmentName,
                    value: el.DepartmentID,
                    selected: false,
                    parentTypeName: 'department'
                }))

                filterParent('job').children = filterData.Jobs.map(el => ({
                    title: el.JobName,
                    value: el.JobID,
                    selected: false,
                    parentTypeName: 'job'
                }))

                filterParent('grade').children = filterData.Grades.map(el => ({
                    title: el.GradeDescription,
                    value: el.GradeID,
                    selected: false,
                    parentTypeName: 'grade'
                }))

                filterParent('person').children = filterData.Persons.map(el => ({
                    title: el.FirstName + ' ' + el.LastName,
                    value: el.PersonID,
                    selected: false,
                    parentTypeName: 'person'
                }))
            })
        },
        selectParent(val,title){
            this.selectedParentName = title
            this.filterTagsData.forEach(el => {
                if(el.typeName === val) el.selected = true
                else el.selected = false
            })
            this.selectedParentChilds = this.filterTagsData.find(el => el.typeName === val).children
        },
        selectTag(child){
            
            if(child.parentTypeName === 'status' || child.parentTypeName === 'graded' || child.parentTypeName === 'comment'){
                if(child.parentTypeName === 'status'){
                    this.filterTagsData.find(el => el.typeName === 'status').children.forEach(obj => {
                        if(obj.title === child.title) obj.selected = !obj.selected
                        else obj.selected = false
                    })
                }
                if(child.parentTypeName === 'graded'){
                     this.filterTagsData.find(el => el.typeName === 'graded').children.forEach(obj => {
                        if(obj.title === child.title) obj.selected = !obj.selected
                        else obj.selected = false
                    })
                }
                if(child.parentTypeName === 'comment'){
                     this.filterTagsData.find(el => el.typeName === 'comment').children.forEach(obj => {
                        if(obj.title === child.title) obj.selected = !obj.selected
                        else obj.selected = false
                    })
                }

            }else{
                child.selected = !child.selected
            }

            ////////
            if(child.parentTypeName === 'job'){
                if(child.selected){
                    this.selectedFilters.jobs.push(child)
                }else{
                    this.selectedFilters.jobs = this.selectedFilters.jobs.filter(el => el.value !== child.value)
                }
            } 
                
            if(child.parentTypeName === 'person'){
                if(child.selected){
                    this.selectedFilters.persons.push(child)
                }else{
                    this.selectedFilters.persons = this.selectedFilters.persons.filter(el => el.value !== child.value)
                }
            } 

            if(child.parentTypeName === 'department'){
                if(child.selected){
                    this.selectedFilters.departments.push(child)
                }else{
                    this.selectedFilters.departments = this.selectedFilters.departments.filter(el => el.value !== child.value)
                }
            } 

            if(child.parentTypeName === 'grade'){
                if(child.selected){
                    this.selectedFilters.grades.push(child)
                }else{
                    this.selectedFilters.grades = this.selectedFilters.grades.filter(el => el.value !== child.value)
                }
            }
        },
        clearParentArr(){
            this.filterTagsData.forEach(el => el.selected = false)
        },
        removeFilteredTag(tag){
            if(tag.parentObjType === 'score'){
                let scoreObj = this.filterTagsData.find(el => el.typeName === 'score') 
                scoreObj.maxScore = null
                scoreObj.minScore = null
                this.clearRangeValues = !this.clearRangeValues
                this.getTeamGradeHistory(true)
                if(this.filteredObjectsData.length === 1){
                    window.localStorage.setItem('overallFilterTags',[])
                }                
                return
            }

            this.filterTagsData.find(el => el.typeName === tag.parentObjType)
            .children.find(obj => obj.value === tag.value).selected = false
            if(this.filteredObjectsData.length === 1){
                window.localStorage.setItem('overallFilterTags',[])
            }

            this.getTeamGradeHistory(true)
        },
        clearAllFilteredTags(){
            this.filterTagsData.forEach(el => {
                if(el.typeName === 'score'){
                    el.minScore = null
                    el.maxScore = null
                }
                el.children.forEach(obj => {
                    obj.selected = false

                })
            })

            this.clearRangeValues = !this.clearRangeValues

            this.selectedFilters.persons = []
            this.selectedFilters.jobs = []
            this.selectedFilters.departments = []
            this.selectedFilters.grades = []
            window.localStorage.setItem('overallFilterTags',[])
            this.getTeamGradeHistory(true)
        },
        compareAvgScore(current,beforeCurrent){
            let compareResult = {
                result: null,
                compareStatus: null,
                compareStatusImgSrc: null,
            }
            if(current > beforeCurrent){
                compareResult.result = Math.abs(current) - Math.abs(beforeCurrent)
                compareResult.compareStatus = 'increased'

            }
            if(current < beforeCurrent){
                compareResult.result = Math.abs(beforeCurrent) - Math.abs(current)
                compareResult.compareStatus = 'decreased'
            }

            if(current === beforeCurrent){
                compareResult.result = Math.abs(current)
                compareResult.compareStatus = 'null'
            }
            return compareResult
        },
        comparedScoreColor(avg,beforeAvg){
            let style = {}
            if(this.compareAvgScore(avg,beforeAvg).compareStatus === 'increased'){
                style = {color: '#29CC39'}
            }
            if(this.compareAvgScore(avg,beforeAvg).compareStatus === 'decreased'){
                style = {color: '#FF3356'}
            }
            if(this.compareAvgScore(avg,beforeAvg).compareStatus !== 'decreased' && this.compareAvgScore(avg,beforeAvg).compareStatus !== 'increased'){
                style = {color: '#FF9233'}
            }
            return style
        },
        addPersonToChartAnalytics(checked,person){
            if(checked){
                let exists = this.selectedPersonsForChartDisplay.some(el => el === person.PersonID)
                if(!exists){
                    this.selectedPersonsForChartDisplay.push(person.PersonID)
                }else return
            }else{
                this.selectedPersonsForChartDisplay = this.selectedPersonsForChartDisplay.filter(el => el !== person.PersonID)
            }
        },
        comparePersons(){
            let personsForCompare = []
            this.teamHistory.forEach(el => {
                if(el.selected && el.Graded){
                    personsForCompare.push(el.PersonGradingHistoryID)
                }
            })
            personsForCompare = [... new Set(personsForCompare)]

            if(personsForCompare.length > 0){
                if(personsForCompare.length === 1){
                    this.snackbarMessage('Select At Least 2 Persons for Compare','warning')
                    return
                }
                this.$router.push({path:'/Grades/OverallCompare',query:{
                    gradingHistoryIDs: personsForCompare
                }})
            }else{
                this.snackbarMessage('Select At Least 2 Persons for Compare','warning')
                return
            }
        },
        deleteDraftedHistory(id){
            this.$confirm('Would you like To Delete this data?').then(() => {
                axios.post('grading/UpdatePersonGradingHistoryStatus',{
                    personGradingHistoryID: id ? id : null,
                    delete: true,
                }).then(resp => {
                    if(!resp.data.ErrorMsg){
                        this.getTeamGradeHistory(false)
                        this.snackbarMessage('Success','success')
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                })
            })
        }
        
    },
}
</script>


<style lang="scss" scoped>

*{
    font-family: Roboto,firaGo;
}

ul{
    list-style: none;
}

.background{
    overflow: hidden;
    background: rgba(#ededfc,.6);
}

.top-header{
    width: 100%;
    background: #ffffff;
    padding: 0.125rem 0.625rem;
    .team-title{
        span{
            color: #4D5E80;
            font-size: 1rem;
            font-weight: 700;
        }
    }
}

.no-records{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    pointer-events: none;
    user-select: none;
    span{
        font-size: 1.5625rem;
        color: rgba($grayBlue, .8);
        white-space: nowrap;
    }
}

::v-deep .switch-header{
    .route{
        span::after{
            bottom: -0.3125rem !important;
        }
    }
}

.multi-filter{
    width: fit-content;
    ::v-deep{
        .filter-container{
            top: calc(100% - 5rem);
        }

        .range-field{
            input{
                width: 10rem;
            }
        }
    }

    &.position{
        ::v-deep{
            .filter-container{
                top: calc(100% - 3rem);
            }
        }   
    }
}

.content-wrapper{
    width: 100%;
    height: 100%;
    padding: 1.75rem 1.875rem;
    @include flex(space-between,flex-start,row);
    overflow: hidden;

    .middle-header,.middle-content,.right-content{
        background: #ffffff;
        border-radius: 0.625rem;
        box-shadow: 0 0 0.25rem rgba(#4D5E80, .1);
    }

    .middle{
        width: calc(100% - (28.1875rem + 1.875rem));
        height: 100%;
        @include flex(flex-start,center,column);

        &.fullSize{
            width: 100% !important;

            .persons-dropdown{
                left: unset !important;
                right: 0 !important;
            }

            .persons-list ul > li{
                grid-template-columns: 2rem 9.5rem 25rem 20rem 16rem 20.5rem !important;
            }
        }

        .middle-header{
            width: 100%;
            min-height: 6rem;
            max-height: 10.625rem;
            // height: 10.625rem;
            @include flex(flex-start,flex-start,column);

            .middle-header-top{
                padding: 1.4375rem;
                width: 100%;
                @include flex(space-between,flex-start,row);

                .score-info{
                    h2{
                        font-size: 1rem;
                        color: #4D5E80;
                    }
                    span{
                        font-size: 0.8125rem;
                        color: #ADB8CC;
                    }
                }

                .scores{
                    ul{
                        @include flex(flex-start,center,row);
                        li{
                            width: 1.75rem;
                            height: 1.75rem;
                            border-radius: 50%;
                            background: #C3CAD9;
                            @include centerElement;
                            margin-left: 0.3125rem;
                            overflow: hidden;
                            animation: bounceUp .1s forwards;
                            transition: ease .3s;
                            opacity: 0;
                            @keyframes bounceUp {
                                from{
                                    transform: translateY(1.25rem);
                                }
                                to{
                                    transform: translateY(0);
                                    opacity: 1;
                                }
                            }

                            &:nth-child(1){
                                animation-delay: .1s;
                            }
                            &:nth-child(2){
                                animation-delay: .2s;
                            }
                            &:nth-child(3){
                                animation-delay: .3s;
                            }
                            &:nth-child(4){
                                animation-delay: .4s;
                            }
                            &:nth-child(5){
                                animation-delay: .5s;
                            }
                            &:nth-child(6){
                                animation-delay: .6s;
                            }
                            &:nth-child(7){
                                animation-delay: .7s;
                            }
                            &:nth-child(8){
                                animation-delay: .8s;
                            }
                            &:nth-child(9){
                                animation-delay: .9s;
                            }
                            &:nth-child(10){
                                animation-delay: 1s;
                            }

                            &.current{
                                width: 2.375rem;
                                height: 2.375rem;
                                padding: 0 0.625rem !important;
                                span{
                                    font-size: 0.8125rem;
                                }
                            }


                            &:first-child{
                                margin-left: 0;
                            }

                            span{
                                color: #ffffff;
                                font-size: 0.6875rem;
                                user-select: none;
                                font-weight: 600;
                            }
                        }
                    }
                }

                .actions-wrapper{
                    @include flex(flex-start,center,row);
                }

                .chart-page-btn{
                    cursor: pointer;
                    padding-right: 1.5625rem;
                    img{
                        width: 1.5625rem;
                    }
                }

                .compare{
                    margin-right: 0.4375rem;
                    cursor: pointer;
                    width: 8.312rem;
                    height: 2.5rem;
                    background: #FF9233;
                    border-radius: 1.25rem;
                    @include flex(center,center,row);
                    user-select: none;
                    transition: ease .2s;
                    box-shadow: 0.0625rem 0.0625rem 1.25rem rgba(#FF9233,.1);

                    &:hover{
                        box-shadow: 0.0625rem 0.0625rem 1.25rem rgba(#6B7A99,.4);
                    }
                    span{
                        color: #fff;
                        font-size: 0.8125rem;
                        font-weight: 600;
                    }

                    img{
                        width: 1.25rem;
                        margin-right: 0.625rem;
                    }
                }

                .new-rate{
                    position: relative;
                    width: 6.25rem;
                    .new-rate-btn{
                        top: -1.25rem;
                        height: 2.4rem !important;
                        left: 0.3125rem;
                        position: absolute;
                        @include purpleBtn;
                        @include flex(center,center,row);
                        border-radius: 1.5625rem;
                        font-size: 0.875rem;
                        font-weight:500;

                        &.active{
                            transition: none;
                            background: $green-primary;
                        }

                        img{
                            width: 1.125rem;
                            margin-right: 0.3125rem;
                            margin-bottom: 0.125rem;
                        }
                    }

                    .persons-dropdown{
                        width: 15rem;
                        max-height: 26.5rem;
                        overflow: auto;
                        position: absolute;
                        top: calc(100% + 2.4rem);
                        left: 0;
                        z-index: 1;
                        background: #ffffff;
                        box-shadow: 0 0 1.25rem rgba($grayBlue, .3);
                        border-radius: 0.375rem;
                        padding-bottom: 0.3125rem;

                        ul{
                            width: 100%;

                            .search-persons-list{
                                width: 100%;
                                padding: 0.625rem 0.9375rem;
                                position: sticky;
                                top: 0;
                                z-index: 1;
                                background: #ffffff;
                                input{
                                    width: 100%;
                                    outline: none;
                                    border: 0.0625rem solid rgba($grayBlue, .4);
                                    border-radius: 0.125rem;
                                    height: 2.2rem;
                                    background: transparent;
                                    padding: 0 0.4375rem;
                                    font-size: 0.875rem;
                                    color: #6B7A99;
                                }                                
                            }

                            li > .back-to-list{
                                cursor: pointer;
                                user-select: none;
                                padding-left: 0.625rem;
                                padding-top: 0.3125rem;
                                @include flex(flex-start,center,row);
                                img{
                                    width: 1.0625rem;
                                }
                                span{
                                    margin-left: 0.3125rem;
                                    font-size: 0.8125rem;
                                    color: #97A1C2;
                                }
                            }

                            li:not(.no-hover){
                                cursor: pointer;
                                width: 100%;
                                padding: 0.625rem 0.9375rem;
                                @include flex(flex-start,center,row);
                                transition: ease .2s;
                                span{
                                    color: #6B7A99;
                                    font-size: 0.9375rem;
                                    margin-left: 0.9375rem;
                                }
                                .person-avatar{
                                    width: 1.25rem;
                                    height: 1.25rem;
                                    background-size: cover;
                                    border-radius: 50%;
                                }
                                &:hover{
                                    background: rgba($purple,.9);
                                    span{
                                        color: #ffffff;
                                    }
                                }   
                            }
                        }

                    }

                }
            }

            .middle-header-bottom{
                width: 100%;
                padding-bottom: 1.3125rem;
                .filter-tags{
                    width: 100%;
                    @include flex(flex-start,left,column);
                    position: relative;
                    ul{
                        width: 100%;
                        @include flex(flex-start,center,row);
                        flex-wrap: wrap;
                        margin-left: 0.75rem;
                        max-height: 3rem;
                        overflow: auto;

                        li{
                            transition: ease .3s;
                            @include flex(flex-start,center,row);
                            background: $purple;
                            border-radius: 1.875rem;
                            padding: 0 0.5rem;
                            height: 1.875rem;
                            min-width: 3rem;
                            max-width: 12rem;
                            margin-left: 0.3125rem;
                            margin-top: 0.3125rem;
                            overflow: hidden;
                            cursor: pointer;
                            position: relative;
                            transition: ease .3s;
                            user-select: none;
                            &:hover:not(.clear-all-filters){
                                background: rgba($purple,.8);
                            }

                            .remove-tag{
                                margin-top: 0.1875rem;
                                img{
                                    width: 1.225rem;
                                }
                            }
                            span{
                                color: #ffffff;
                                font-size: 0.6875rem;
                                font-weight: 500;
                                margin-left: 0.4375rem;
                                padding-right: 0.3125rem;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }

                            &.clear-all-filters{
                                padding: 0 0.75rem;
                                background: #DEE3EB;
                                span{
                                    color: #6B7A99;
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }
            
        }
        .middle-content{
            width: 100%;
            height: calc(100% - 10.625rem);
            margin-top: 0.8125rem;
            background: transparent;
            box-shadow: none;
            animation: scale 1s forwards;
            @keyframes scale {
                from{
                    transform: scaleY(.5);
                }
                to{
                    transform: scaleY(1);
                }
            }
            .select-all-persons{
                padding-left: 1.875rem;
                margin-bottom: 0.3125rem;
                @include flex(flex-start,center,row);
                cursor: pointer;
                user-select: none;
                span{
                    color: rgba(#7D8FB3,.7);
                    font-size: 0.8125rem;
                    margin-left: 0.625rem;
                    font-weight: 500;
                }
            }
            
            .persons-list{
                width: 100%;
                height: 100%;
                padding-bottom: 1.9125rem;
                overflow-y: auto;
                overflow-x: hidden;

                ul{
                    @include flex(flex-start,left,column);
                    
                    li{
                        width: 100%;
                        height: 7.15rem;
                        border-radius: 0.625rem;
                        background: #ffffff;
                        margin-top: 0.6125rem;
                        box-shadow: 0 0 0.25rem rgba(#4D5E80, .1);
                        position: relative;
                        // @include flex(flex-start,center,row);
                        display: grid;
                        align-items: center;
                        grid-template-columns: 2rem 7.5rem 21rem 12rem 4rem 15.5rem;
                        padding: 0 1.875rem;
                        border: 0.125rem solid transparent;
                        transition: ease .3s;
                        &:hover{
                            .delete{
                                opacity: 1 !important;
                            }
                        }
                        .delete{
                            position: absolute;
                            right: 1rem;
                            opacity: 0;
                            top: 1rem;
                            cursor: pointer;
                            transition: ease .2s;
                            img{
                                width: 1.375rem;
                                filter: invert(27%) sepia(39%) saturate(5016%) hue-rotate(336deg) brightness(117%) contrast(101%);
                            }
                        }

                        &.selected{
                            border: 2px solid rgba(#29CC39,.5)
                        }

                        &:first-child{
                            margin-top: 0;
                        }

                        .index{
                            position: absolute;
                            top: 0.75rem;
                            left: 0.75rem;
                            color: #C3CAD9;
                            font-size: 0.75rem;
                        }
                        
                        .avatar-wrapper{
                            margin-left: 1.25rem;
                            cursor: pointer;
                            position: relative;
                            .avatar-placeholder{
                                width: 5.3125rem;
                                height: 5.3125rem;
                                border: 0.0625rem solid #E4E7F0;
                                border-radius: 50%;
                                overflow: hidden;
                                position: relative;
                                background-size: cover;
                                background-position: auto;
                                @include centerElement;

                                img{
                                    width: 100%;
                                    pointer-events: none;
                                    user-select: none;
                                }

                            }
                            &::after{
                                content: '';
                                position: absolute;
                                width: 5.9375rem;
                                height: 5.9375rem;
                                left: -0.375rem;
                                top: -0.375rem;
                                border: 0.0625rem solid #E4E7F0;
                                border-radius: 50%;
                            }
                        }

                        .person-details{
                            margin-left: 2.5rem;
                            @include flex(flex-start,left,column);
                            .top{
                                h2{
                                    color: #6B7A99;
                                    font-size: 0.8125rem;
                                    font-weight: 700;
                                }
                                span{
                                    font-size: 0.75rem;
                                    color: #97A1C2;
                                }
                            }
                            .bottom-score{
                                width: calc(16.25rem + 3.125rem);
                                margin-top: 1.4375rem;

                                &.notRated{
                                    & > div{
                                        background: #CCD6F6 !important;
                                        box-shadow: none !important;
                                    }
                                }


                                .single-score{
                                    width: 1.4375rem;
                                    height: 0.375rem;
                                    display: inline-flex;
                                    border-radius: 0.625rem;
                                    background: #CCD6F6;
                                    margin-left: 0.3125rem;
                                    transition: ease .2s;
                                    pointer-events: none;

                                    &.blank{
                                        background: #CCD6F6 !important;
                                    }

                                    &:first-child{
                                        margin-left: 0;
                                    }
                                }
                            }
                        }

                        .person-badge{
                            margin-left: 2.75rem;
                            @include flex(flex-start,center,row);

                            .single-badge{
                                position: relative;
                                margin-left: 2.3125rem;
                                &:first-child{
                                    margin-left: 0;
                                }

                                .badge-checkbox{
                                    position: absolute;
                                    top: 0;
                                    left: -1.7rem;
                                }
                                .badge{
                                    img{
                                        width: 1.5625rem;
                                    }
                                }
                                .badge-details{
                                    span{
                                        font-size: 0.75rem;
                                        color: #97A1C2;
                                    }
                                    h2{
                                        margin-top: 0.1875rem;
                                        font-size: 0.875rem;
                                        color: $purple;
                                    }
                                }
                            }
                        }

                        .person-comment{
                            margin-left: 3.1875rem;
                            .comment-btn{
                                .img-wrapper{
                                    width: 2rem;
                                    position: relative;
                                    cursor: pointer;
                                    img{
                                        width: 1.5rem;
                                    }
                                    &.marked{
                                        &::after{
                                            content: '';
                                            position: absolute;
                                            top: 0;
                                            right: 0.375rem;
                                            width: 0.5rem;
                                            height: 0.5rem;
                                            border-radius: 50%;
                                            background: #FF3356;
                                        }
                                    }
                                    
                                }

                            }
                        }

                        .person-total-score{
                            margin-left: 4.375rem;
                            width: 100%;
                            height: 100%;
                            @include flex(flex-start,center,row);
                            .left-side{
                                width: 6.5rem;
                                & > div{
                                    @include flex(flex-end,center,row);
                                    span{
                                        font-size: 0.8125rem;
                                        font-weight: 600;
                                        color: #29CC39;

                                        &.increased{
                                            color: #29CC39;
                                        }
                                        &.decreased{
                                            color: #FF3356;
                                        }
                                    }
                                    img{
                                        margin-left: 0.1875rem;
                                        width: 0.5625rem;
                                    }
                                }
                                span{
                                    color: #97A1C2;
                                    font-size: 0.75rem;
                                }
                            }

                            .right-side{
                                position: relative;
                                margin-left: 0.875rem;
                                .current-score{
                                    width: 2.5rem;
                                    height: 2.5rem;
                                    @include centerElement;
                                    border-radius: 50%;
                                    background: transparent;

                                    &.notRated{
                                        background: transparent !important;
                                        border: 0.0625rem solid #FF9233;
                                        span{
                                            color: #97A1C2;
                                        }
                                    }

                                    span{
                                        color: #ffffff;
                                        font-size: 0.9375rem;
                                        font-weight: 600;
                                    }
                                }

                                .toDetails{
                                    height: 100%;
                                    @include centerElement;
                                    cursor: pointer;
                                    position: absolute;
                                    right: -1.5rem;
                                    top: 50%;
                                    transform: translate(0,-50%);
                                    img{
                                        width: 0.3125rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .right-content{
        width: 28.1875rem;
        max-height: 100%;
        min-height: 80%;
        overflow: auto;
        position: relative;

        .content-header{
            width: 100%;
            padding: 0.625rem 0.9375rem;
            .title{
                span{
                    font-size: 0.9375rem;
                    color: #97A1C2;
                }
            }
        }
        .right-middle-content{
            margin-top: 1.5625rem;
        }

    }

}
</style>