<template>
  <div class="switcher-container" @click="clickHandler">
    <span :title="title">{{ title }}</span>
    <div class="switcher" :class="{ active: value }"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      defaalt: false,
    },
  },
  methods: {
    clickHandler() {
      if (this.disabled) return;
      this.$emit("input", !this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.switcher-container {
  display: flex;
  background-color: white;
  border: 1 * $rem solid $grey-border;
  height: 40 * $rem;
  align-items: center;
  width: 197 * $rem;
  border-radius: 5 * $rem;
  padding-left: 15 * $rem;
  cursor: pointer;
  color: #283593;
  font-size: 14 * $rem;
  font-weight: lighter;

  .switcher {
    margin-left: auto;
    margin-right: 15 * $rem;
  }
}
</style>
