<template>
  <div class="signify-views-content">
    <div class="tabs-content">
      <ul>
        <router-link
          tag="li"
          :to="tab.path"
          v-for="(tab, index) in tabs"
          :key="index"
        >
          {{ tab.title }}
        </router-link>
      </ul>
    </div>

    <div class="background">
      <transition name="fade" mode="out-in" appear>
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
// import VacationAdminTabs from "./VacationAdminTabs.vue";

export default {
  components: {
    // VacationAdminTabs,
  },
  data() {
    return {
      tabs: [
        {
          title: 'Generated',
          path: '/signifyDocs',
        },
        {
          title: 'Templates',
          path: '/signifyTemplates',
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.signify-views-content {
  height: 100%;
}

.tabs-content {
  margin-left: 102 * $rem;
  ul {
    display: flex;
  }
  li {
    list-style: none;
    margin-right: 50 * $rem;
    font-size: 16 * $rem;
    color: $blue-primary;
    cursor: pointer;
    padding-bottom: 5px;
  }
}
.router-link-exact-active {
  border-bottom: 4px solid #1de9b6 !important;
  border: transparent;
  font-weight: 500;
}
</style>
