var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statistics"},[_c('div',{staticClass:"statistics__responses"},[_c('div',{staticClass:"statistics__responses-top"},[_c('div',{staticClass:"statistics__responses-top-left"},[_vm._v(" "+_vm._s(_vm.personChoices.length)+" Responses ")]),_c('div',{staticClass:"statistics__responses-top-right"},[_c('div',{ref:"content-right",staticClass:"options-head",on:{"scroll":_vm.handleScrollHeader}},_vm._l((_vm.chartStatistics),function(option,i){return _c('div',{key:i,staticClass:"option-head"},[_vm._v(" "+_vm._s(i + 1)+" ")])}),0)])]),_c('div',{staticClass:"statistics__responses-body"},_vm._l((_vm.personChoices),function(person,i){return _c('div',{key:i,staticClass:"statistics__responses-response"},[_c('div',{staticClass:"statistics__responses-left"},[_c('div',{staticClass:"persons"},[_c('div',{staticClass:"person"},[(person.comment && person.comment.length > 0)?_c('img',{staticClass:"person-icon",attrs:{"src":require("@/assets/icons/icon_comment.svg"),"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('comment-clicked', person)}}}):_vm._e(),_c('div',{staticClass:"person-name"},[_vm._v(_vm._s(person.name))]),(_vm.commentId === person.id)?_c('div',{staticClass:"person-hover"},[_vm._v(" "+_vm._s(person.comment)+" ")]):_vm._e()])])]),_c('div',{staticClass:"statistics__responses-right"},[_c('div',{ref:"content-right-body",refInFor:true,staticClass:"options",on:{"scroll":_vm.handleScrollBody}},_vm._l((_vm.chartStatistics),function(option,i){return _c('div',{key:i,staticClass:"option"},[_c('div',{class:[
                  'checkbox',
                  {
                    'checkbox--active': _vm.idsArray(person.choices).includes(
                      option.id
                    ),
                  },
                ]})])}),0)])])}),0)]),_c('div',{staticClass:"statistics__waiting"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"statistics__waiting-title"},[_vm._v(" Waiting for "+_vm._s(_vm.inactivePersons.length)+" responses ")]),(_vm.published && !_vm.finished && _vm.inactivePersons.length > 0)?_c('div',{staticClass:"statistics__waiting-button",class:{ disabled: !_vm.reminderActive },on:{"click":function($event){return _vm.$emit('reminder-clicked')}}},[_vm._v(" Send Reminder ")]):_vm._e()]),_c('div',{staticClass:"users"},_vm._l((_vm.inactivePersons),function(user,i){return _c('div',{key:i,staticClass:"user"},[_vm._v(" "+_vm._s(user.name)+" ")])}),0)]),_c('div',{staticClass:"statistics__chart"},[_c('div',{staticClass:"canvas-container"},[_c('canvas',{ref:"statisticsChart"})]),_c('div',{staticClass:"statistics__chart-options"},_vm._l((_vm.chartStatistics),function(option,i){return _c('div',{key:i,staticClass:"statistics__chart-options-option"},[_c('div',{staticClass:"statistics__chart-options-option-chart",style:({ backgroundColor: _vm.colors[i] })}),_c('div',{staticClass:"statistics__chart-options-option-name"},[_vm._v(" "+_vm._s(option.option)+" ")]),_c('div',{staticClass:"statistics__chart-options-option-percentage"},[_vm._v(" "+_vm._s(option.quantity === 0 ? 0 : ((option.quantity / option.allQuantity) * 100).toFixed(1))+"% ")])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }