import { render, staticRenderFns } from "./UserTrafficListView.vue?vue&type=template&id=5efb7da4&scoped=true"
import script from "./UserTrafficListView.vue?vue&type=script&lang=js"
export * from "./UserTrafficListView.vue?vue&type=script&lang=js"
import style0 from "./UserTrafficListView.vue?vue&type=style&index=0&id=5efb7da4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5efb7da4",
  null
  
)

export default component.exports