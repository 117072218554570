var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[(!this.$store.state.showTimeLine)?_c('div',{staticClass:"traffic_additional_header"},[_c('traffic-tabs')],1):_vm._e(),(this.$store.state.showTimeLine)?_c('router-link',{staticClass:"back-arrow",attrs:{"tag":"a","to":{ path: 'MyTraffic' }}},[_c('img',{attrs:{"src":require('@/assets/icons/icon_back.svg')}})]):_vm._e(),_c('div',{staticClass:"history-header"},[_c('div',{staticClass:"left-side"},_vm._l((_vm.personStatus),function(person){return _c('div',{key:person.index,staticClass:"request-person"},[_c('div',{staticClass:"avatar",style:([
            {
              backgroundImage: `url(${_vm.getAvatarSrc(person.PersonID)})`,
            },
            {
              border: person.OnVacation
                ? '3px solid #1DB3E9 '
                : person.OnWeekend && person.ScheduleAssigned
                ? '3px solid #1DE9B6'
                : !person.ScheduleAssigned
                ? '3px solid  #FFA346'
                : '3px solid #283593',
            },
          ])}),_c('div',{staticClass:"request-person-info"},[_c('span',{staticClass:"person-info-name"},[_vm._v(_vm._s(person.FirstName + " " + person.LastName))]),_c('span',{staticClass:"job-name"},[_vm._v(_vm._s(person.JobName))]),_c('span',{staticClass:"status-text",style:([
              {
                backgroundColor: person.OnVacation
                  ? '#1DB3E9'
                  : person.OnWeekend && person.ScheduleAssigned
                  ? '#1DE9B6'
                  : !person.ScheduleAssigned
                  ? '#FFA346'
                  : '#283593',
              },
              {
                width: !person.ScheduleAssigned ? '145px' : '65px',
              },
            ])},[_vm._v(_vm._s(_vm.statusText))])])])}),0),_c('div',{staticClass:"right-side"},[(_vm.activityTotalTime)?_c('div',{staticClass:"total-time-content"},[_c('span',[_vm._v("Active Time : ")]),_c('span',[_vm._v(_vm._s(_vm.getTimeTextFromSeconds(_vm.activityTotalTime)))])]):_vm._e(),_c('div',{staticClass:"time-picker-content"},[_c('vue-timepicker',{attrs:{"placeholder":"Pick time","close-on-complete":""},model:{value:(_vm.pickedHour),callback:function ($$v) {_vm.pickedHour=$$v},expression:"pickedHour"}})],1),_c('div',{staticClass:"date-content",class:{ userStyleDateContent: this.$store.state.showTimeLine }},[(_vm.dateChanged == false)?_c('span',[_vm._v(_vm._s(_vm.defoultDateText))]):_vm._e(),(_vm.dateChanged == true)?_c('span',[_vm._v(_vm._s(_vm.dateText))]):_vm._e(),_c('activity-calendar',{attrs:{"monitoringCalendar":true,"trafficCalendar":true},on:{"dateChange":_vm.getDateValue}})],1),(!this.$store.state.showTimeLine)?_c('input-search',{attrs:{"title":"Select Person","options":_vm.personOptions},on:{"select":(person) => (this.selectedPerson = person)}}):_vm._e(),_c('div',{staticClass:"app-search-content"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchApp),expression:"searchApp"}],attrs:{"type":"text","placeholder":"Search App/Web"},domProps:{"value":(_vm.searchApp)},on:{"input":function($event){if($event.target.composing)return;_vm.searchApp=$event.target.value}}}),_c('img',{staticClass:"search-icon",attrs:{"src":require("@/assets/icons/searchAppIcon.svg"),"alt":""}})])],1)]),(_vm.data.length)?_c('div',{staticClass:"history-timeline-content"},_vm._l((_vm.filteredTimes),function(t){return _c('div',{key:t,staticClass:"single-time-container"},[_c('p',{ref:"times",refInFor:true,staticClass:"times",class:{
          iddleTimeColor: _vm.inactiveTimes.some((el) => el.time == t),
        }},[_vm._v(" "+_vm._s(t)+" ")]),(_vm.appsByTime[t])?_c('ul',{directives:[{name:"dragscroll",rawName:"v-dragscroll"}],staticClass:"apps-container",style:({ cursor: _vm.appsByTime[t].length >= 6 ? 'e-resize' : 'auto' })},_vm._l((_vm.filteredApps[t]),function(app){return _c('li',{key:app.index,style:([
            {
              height: app.heightNumber * 43 + 'px',
            },
            { border: !app.WorkingProgram ? '1px solid #FFA346 ' : null },
          ])},[(!app.URL)?_c('div',{staticClass:"name-icon-wrapper"},[(app.HasLogo == true)?_c('img',{staticClass:"app-icon",attrs:{"src":`data:image/jpeg;base64, ${app.File}`}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(app.ProgramName)+" ")])]):_c('div',{staticClass:"name-icon-wrapper"},[_c('img',{staticClass:"web-icon",attrs:{"src":_vm.getObjectIcon(app.URL)}}),_c('span',{attrs:{"title":app.URL}},[_vm._v(" "+_vm._s(app.URL)+" ")])])])}),0):_vm._e()])}),0):(!_vm.dataIsLoaded)?_c('div',{staticClass:"no-data-text"},[_vm._v(" There is no records ")]):_vm._e(),(_vm.dataIsLoaded)?_c('custom-loader'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }