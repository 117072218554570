<template>
  <div class="container" id="chart">
    <div class="title">Payroll cost by periods</div>
    <div class="ess-container">
      <div class="filter-div">
        <date-range
          :initialFrom="fromDate"
          :initialTo="toDate"
          @from="(from) => (fromDate = from)"
          @to="(to) => (toDate = to)"
          class="date_range"
        />
      </div>
      <div class="filter-div">
        <custom-select
          class="input"
          title="Department"
          v-model="departmentFilter"
          :options="departmentListOptions"
          @clear="departmentFilter = ''"
          :clearVisible="true"
        />
      </div>
    </div>
    <div class="chart-container">
      <line-chart
        v-if="loaded"
        :width="700"
        :chart-data="chartDataList"
        :options="chartOptions"
      />
    </div>
  </div>
  <!-- end ess-container -->
</template>

<script>
import LineChart from "@/components/ReusableComponents/Charts/LineChart.js";
import DateRange from "@/components/ReusableComponents/DateRange/DateRange";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue";
import axios from "@/Helpers/AxiosInstance.js";
import getDateString from "@/Helpers/HelperFunctions";

export default {
  // name: "Line",
  components: {
    LineChart,
    DateRange,
    CustomSelect,
  },
  data: () => ({
    fromDate: "",
    toDate: "",
    filterfromDate: "",
    filtertoDate: "",
    departmentFilter: "",
    departmentListOptions: [],
    loaded: false,
    chartDataList: {},
    chartOptions: {
      scales: {
        y: {
          stacked: true,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  }),
  mounted() {
    // this.fillData();
    this.getDepartments();
    this.getChartData();
  },
  methods: {
    getChartData() {
      this.loaded = false;
      axios
        .get("salary/GetPayrollCostsForChart", {
          params: {
            FromDate: this.filterfromDate ? this.filterfromDate : null,
            ToDate: this.filtertoDate ? this.filtertoDate : null,
            DepartmentID: this.departmentFilter ? this.departmentFilter : null,
          },
        })
        .then((resp) => {
          let data = resp.data.Value;
          this.$set(this.chartDataList, "datasets", resp.data.Value.Datasets);
          this.$set(this.chartDataList, "labels", resp.data.Value.Months);
          this.loaded = true;
        });
    },
    getDateString(date, format = "yyyy-MM-dd") {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      if (date === "Invalid Date") return;

      const MONTHS = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      var days = ("0" + date.getDate()).slice(-2),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        year = date.getFullYear(),
        hours = ("0" + date.getHours()).slice(-2),
        minutes = ("0" + date.getMinutes()).slice(-2),
        seconds = ("0" + date.getSeconds()).slice(-2);

      return format
        .replace("dd", days)
        .replace("MMM", MONTHS[parseInt(month) - 1])
        .replace("MM", month)
        .replace("yyyy", year)
        .replace("yy", String(year).slice(-2))
        .replace("HH", hours)
        .replace("mm", minutes)
        .replace("ss", seconds)
        .replace("ww", WEEKDAYS[date.getDay()]);
    },
    // fillData() {
    //   this.loaded = false;
    //   try {
    //     // axios.get(`salary/GetDepartmentsBonusLimitsForChart?DepartmentID=${this.departmentFilter}&FromDate=${this.filterfromDate}&ToDate=${this.filtertoDate}`).then((resp) => {
    //     //     this.chartDataList = {
    //     //         labels: resp.data.Value.DepNames,
    //     //         datasets: [
    //     //             {
    //     //                 backgroundColor: resp.data.Value.Dataset.Colors,
    //     //                 data:  resp.data.Value.Dataset.Amounts,
    //     //             }
    //     //         ]
    //     //     };
    //     // });
    //     this.chartDataList = {
    //       labels: [
    //         "Jan",
    //         "Feb",
    //         "Mar",
    //         "Apr",
    //         "May",
    //         "Jun",
    //         "Jul",
    //         "Aug",
    //         "Sep",
    //         "Oct",
    //         "Nov",
    //         "Dec",
    //       ],
    //       datasets: [
    //         {
    //           label: "Dataset bar / Sum Cost",
    //           data: [
    //             25,
    //             200,
    //             300,
    //             400,
    //             1000,
    //             405,
    //             180,
    //             100,
    //             130,
    //             126,
    //             200,
    //             215,
    //           ],
    //           backgroundColor: "#41B883",
    //           stack: "combined",
    //           type: "bar",
    //         },
    //         {
    //           label: "Dataset line / Employ Count",
    //           data: [10, 20, 30, 40, 50, 45, 80, 115, 65, 120, 130, 155],
    //           borderColor: "#E46651",
    //           backgroundColor: "rgba(255,0,0,0)",
    //           stack: "combined",
    //           type: "",
    //         },
    //       ],
    //     };
    //     this.loaded = true;
    //   } catch (e) {
    //     console.error(e);
    //   }
    // },
    getDepartments() {
      axios.get(`departments/GetDepartments`).then((resp) => {
        this.departmentListOptions = resp.data.Value.map((el) => ({
          title: el.Name,
          value: el.ID,
        }));
      });
    },
  },
  watch: {
    fromDate() {
      this.filterfromDate = this.getDateString(this.fromDate);

      this.filtertoDate = this.getDateString(this.toDate);
      this.getChartData();
    },
    departmentFilter(to, from) {
      this.departmentFilter = to;
      this.getChartData();
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-container {
  background-color: #ffffff;
  border-radius: 2px;
  padding: 20px;
}
#chart {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 3.75rem;
}
.title {
  // display: flex;
  font-size: 1.125rem;
  color: $blue-primary;
}
.ess-container {
  display: flex;
  justify-content: end;
  .filter-div {
    width: 12.938rem;
    margin: 0 1.275rem 0.0625rem 0;
    margin-bottom: 0.9375rem;
    margin-top: 1.875rem;
  }
}
</style>