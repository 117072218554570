<template>
    <div>
        <div class="work-chart" v-show="work != totalWork || !work" :class="{ empty: !work }">
            <div class="circle"></div>
            <canvas ref="chartWork"></canvas>
        </div>
        <div class="overtime-chart" v-show="work == totalWork && work">
            <div class="circle"></div>
            <canvas ref="chartOvertime"></canvas>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js'
export default {
    props: {
        work: {
            type: Number,
            required: true,
        },
        overtime: {
            type: Number,
            required: true,
        },
        totalWork: {
            type: Number,
            required: true,
        },
    },
    mounted() {
        this.drawChart()
        this.$watch('work', () => this.drawChart())
        this.$watch('overtime', () => this.drawChart())
        this.$watch('totalWork', () => this.drawChart())
    },
    data() {
        return {
            workChart: null,
            overtimeChart: null,
        }
    },
    methods: {
        drawChart() {
            if (this.work != this.totalWork || !this.work) {
                this.drawWorkChart()
            } else if (this.work == this.totalWork && this.work) {
                this.drawOvertimeChart()
            }
        },
        drawWorkChart() {
            var data = [
                {
                    backgroundColor: ['rgba(40, 53, 147, 0.9)', 'rgba(0, 0, 0, 0)'],
                    weight: 0.1,
                },
            ]
            var work = this.work
            data[0].data = [work, this.totalWork - work]

            if (this.workChart) {
                //update
                this.workChart.data.datasets = data
                this.workChart.update()
                return
            }

            var options = {
                elements: {
                    arc: {
                        borderWidth: 0,
                    },
                },
                tooltips: {
                    enabled: false,
                },
                hover: {
                    mode: null,
                },
                legend: {
                    display: false,
                },
                showTooltips: false,
            }

            var ctx = this.$refs.chartWork.getContext('2d')
            this.workChart = new Chart(ctx, {
                type: 'pie',
                data: {
                    datasets: data,
                },
                options: options,
            })
        },
        drawOvertimeChart() {
            var data = [
                {
                    backgroundColor: ['#1de9b6', 'rgba(0, 0, 0, 0)'],
                    weight: 0.1,
                },
            ]
            var overtime = this.overtime
            data[0].data = [overtime, this.totalWork - overtime]

            if (this.overtimeChart) {
                //update
                this.overtimeChart.data.datasets = data
                this.overtimeChart.update()
                return
            }

            var options = {
                elements: {
                    arc: {
                        borderWidth: 0,
                    },
                },
                tooltips: {
                    enabled: false,
                },
                hover: {
                    mode: null,
                },
                legend: {
                    display: false,
                },
                showTooltips: false,
            }

            var ctx = this.$refs.chartOvertime.getContext('2d')
            this.overtimeChart = new Chart(ctx, {
                type: 'pie',
                data: {
                    datasets: data,
                },
                options: options,
            })
        },
    },
}
</script>

<style scoped lang="scss">
.work-chart {
    position: relative;
    height: 100%;

    .circle {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 94px;
        height: 94px;
        border: 3px solid #1de9b6;
        border-radius: 50%;
        transform: translate(-50%, -50%);
    }

    &.empty .circle {
        top: unset;
        bottom: -17 * $rem;
    }

    canvas {
        z-index: 10;
        position: relative;
        top: 0;
        transform: scale(0.85);
    }
}

.empty-chart .circle {
    width: 94px;
    height: 94px;
    border: 3px solid #1de9b6;
    border-radius: 50%;
    margin-bottom: 30 * $rem;
}

.overtime-chart {
    position: relative;
    height: 100%;

    .circle {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 94px;
        height: 94px;
        border-radius: 50%;
        background-color: rgba(40, 53, 147, 0.902);
        transform: translate(-50%, -50%);
    }

    canvas {
        z-index: 10;
        position: relative;
        top: 8 * $rem;
        transform: scale(0.85);
    }
}
</style>