<template>
    <div class="wrapper" ref="wrapper">
        <div class="scrollbar" :style="{'top': scrollPosition}"></div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        scrollEvent: Event,
    },
    data() {
        return {
            scrollPosition: 0,
        }
    },
    watch: {
        scrollEvent(e) {
            var scrollPercentage = e.target.scrollTop / (e.target.scrollHeight - e.target.clientHeight) * 100
            this.scrollPosition = (this.$refs.wrapper.clientHeight - 68) * scrollPercentage / 100 + 'px'
        },
    },
}
</script>

<style scoped lang="scss">
.wrapper {
    position: relative;
    height: 100%;

    .scrollbar {
        position: absolute;
        height: 68 * $rem;
        width: 6 * $rem;
        background-color: #a4a9d3;
        border-radius: 3 * $rem;
    }
}
</style>