<template>
  <div class="background">
    <div class="profile-info">
      <div class="columns">
        <div class="left">
          <welcome-text-and-avatar
            @change="(file) => (avatar = file)"
            :personID="personID"
          />
          <upload-documents
            :uploadedFiles="uploadedFiles"
            @upload="uploadFileHandler"
            @delete="deleteFileHandler"
          />
        </div>
        <div class="right">
          <profile-fields
            @save="saveHandler"
            :personID="personID"
            @got-documents="saveFilesInData"
            @old-values="getOldValues"
            :triggerUpdate="triggerUploadedFilesUpdate"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WelcomeTextAndAvatar from "./WelcomeTextAndAvatar";
import UploadDocuments from "./UploadDocuments";
import ProfileFields from "./ProfileFields";
import axios from "@/Helpers/AxiosInstance";
import { getDateString, toBase64 } from "@/Helpers/HelperFunctions";
import { showError, showSuccess } from "@/Helpers/Messages";
export default {
  components: {
    WelcomeTextAndAvatar,
    UploadDocuments,
    ProfileFields,
  },
  created() {
    var id = this.$route.params.id;
    this.personID = id ? parseInt(id) : null;
  },
  data() {
    return {
      personID: "", //this has value when its edit mode.
      avatar: "",
      uploadedFiles: [],
      previousValues: {
        job: "",
        role: "",
        office: "",
      },
      triggerUploadedFilesUpdate: false,
    };
  },
  methods: {
    getOldValues(data) {
      this.previousValues.job = data.job;
      this.previousValues.role = data.role;
      this.previousValues.office = data.office;
    },
    saveHandler(fields) {
      var data = {
        FirstName: fields.firstName,
        LastName: fields.lastName,
        OfficeID: fields.office,
        Microsoftuser: fields.domainName,
        PersonalNumber: fields.personalNumber,
        Gender: parseInt(fields.gender) ? true : false,
        PhoneNumOne: fields.phone1,
        PhoneNumTwo: fields.phone2,
        MailOne: fields.email1,
        MailTwo: fields.email2,
        Address: fields.address,
        RelatedContactsOwnersName: fields.relation,
        RelatedPersonsPhoneNum: fields.phone3,
        // BankAccountNum: fields.bankCode,
        // BankAccountIban: fields.iban,
        BankAccountNum: null,
        BankAccountIban: null,
        Skype: fields.skype,
        Status: fields.status,
        JobStartDate: new Date(fields.startDate).getTime(),
        translations: [
          {
            description: fields.firstName,
            descriptionTranslated: fields.firstNameGeo,
            languageID: 1,
            objectTypeID: 23,
          },
          {
            description: fields.lastName,
            descriptionTranslated: fields.lastNameGeo,
            languageID: 1,
            objectTypeID: 24,
          }
        ]
      };
      if (fields.gender == "") delete data.Gender;
      if (fields.birthDate)
        data.BirthDate = new Date(fields.birthDate).getTime();
      //create
      if (!this.isEdit) {
        axios.post("Person/CreatePerson", data).then((resp) => {
          this.personID = resp.data.Value[0].PersonID;
          if (!this.personID) return;
          let promises = [];
          if (fields.role) {
            promises.push(this.assignRole(fields.role));
          }
          if (fields.job) {
            promises.push(this.assignJob(fields.job, fields.startDate));
          }
          if (this.avatar) {
            promises.push(this.updateAvatar());
          }
          if (this.uploadedFiles.length) this.uploadDocuments();
          Promise.all(promises).then(() => {
            this.$router.push({
              path: "/Employees",
            });
          });
        });
      }
      //edit as admin or HR
      else if (
        this.isEdit &&
        (this.$store.getters.isAdmin ||
          this.$store.getters.hasRole("HR") ||
          this.$store.getters.isSuperAdmin)
      ) {
        data.ID = this.personID;
        data.BirthDateVisibility = fields.birthDateVisibility;
        data.PhoneNumOneVisibility = fields.phoneNumOneVisibility;
        data.PhoneNumTwoVisibility = fields.phoneNumTwoVisibility;
        data.MailTwoVisibility = fields.mailTwoVisibility;
        data.SkypeVisibility = fields.skypeVisibility;
        delete data["OfficeID"];
        const editPromiseArray = [];
        //profile fields
        const updateProfileFields = axios.post("Person/UpdatePerson", data);
        editPromiseArray.push(updateProfileFields);
        //Update job. (1 - get personjob instance. 2 - delete that instance. 3 - create new)
        if (this.previousValues.job != fields.job) {
          const updateJob = axios
            .get("jobs/GetPersonJobs", { params: { Person: this.personID } })
            .then((personJobs) => {
              let personJobInstance = personJobs.data.Value.find(
                (pj) => pj.Job == this.previousValues.job
              );

              if (personJobInstance) {
                // showError('PersonJob instance was not found')
                // return

                return axios.post("jobs/UpdatePersonJob", {
                  ID: personJobInstance.ID,
                  Deleted: null,
                  EndTime: Date.now(),
                });
              }
            })
            .then(() => {
              return axios.post("jobs/AssignPersonjob", {
                Person: this.personID,
                Job: fields.job,
                StartTime: Date.parse(fields.startDate),
              });
            });
          editPromiseArray.push(updateJob);
        }
        //Update office (1 - get old instance. 2 - delete that instance. 3 - create new)
        if (this.previousValues.office != fields.office) {
          const updateOffice = axios
            .get("offices/OfficePersons", {
              params: { OfficeID: this.previousValues.office },
            })
            .then((officePersons) => {
              let officePersonInstance = officePersons.data.Value.find(
                (op) => op.Job == this.previousValues.office
              );
              if (!officePersonInstance) {
                showError("OfficePerson instance was not found");
                return;
              }
              return axios.post("offices/UpdateOfficePerson", {
                OfficePersonID: officePersonInstance.OfficePersonID,
                Deleted: true,
                EndTime: Date.now(),
              });
            })
            .then(() => {
              return axios.post("offices/AssignOfficePerson", {
                OfficeID: fields.office,
                PersonID: this.personID,
              });
            });
          editPromiseArray.push(updateOffice);
        }
        //Update role (1 - deactivate old role. 2 - activate new role)
        if (this.previousValues.role != fields.role) {
          const updateRole = axios
            .post("Permission/AssignPersonRole", {
              Person: this.personID,
              Role: this.previousValues.role ? this.previousValues.role : "0",
              Activate: false,
            })
            .then(() =>
              axios.post("Permission/AssignPersonRole", {
                Person: this.personID,
                Role: fields.role,
                Activate: true,
              })
            );

          editPromiseArray.push(updateRole);
        }
        Promise.all(editPromiseArray).then(() => {
          showSuccess("Edit Was Successful");
        });
      }
      //edit as user ('my profile')
      else if (
        this.isEdit &&
        !this.$store.getters.isAdmin &&
        !this.$store.getters.hasRole("HR")
      ) {
        data.ID = this.personID;
        data.Gender = data.Gender ? true : false;
        data.BirthDateVisibility = fields.birthDateVisibility;
        data.PhoneNumOneVisibility = fields.phoneNumOneVisibility;
        data.PhoneNumTwoVisibility = fields.phoneNumTwoVisibility;
        data.MailTwoVisibility = fields.mailTwoVisibility;
        data.SkypeVisibility = fields.skypeVisibility;
        delete data["OfficeID"];
        axios
          .post("Person/UpdatePerson", data)
          .then(() => showSuccess("Edit Was Successful"));
      }
    },
    assignRole(role) {
      return axios.post("Permission/AssignPersonRole", {
        Person: this.personID,
        Role: role,
        Activate: true,
      });
    },
    assignJob(job, startDate) {
      var date = (startDate || new Date()).getTime();
      return axios.post("jobs/AssignPersonjob", {
        Person: this.personID,
        Job: job,
        StartTime: date,
      });
    },
    updateAvatar() {
      return axios.post("Person/UpdateAvatar", {
        Base64: this.avatar,
        PersonID: this.personID,
      });
    },
    uploadDocuments() {
      this.uploadedFiles.forEach(async (file) => {
        this.uploadFile(file);
      });
    },
    async uploadFile(file) {
      let base64 = await toBase64(file);
      axios
        .post("Person/UploadDocument", {
          Person: this.personID,
          Base64: base64,
          Name: file.name,
          FileType: file.type,
          Description: "",
          CanBeDeletedByPerson: false,
          DocumentStartTime: new Date().getTime(),
        })
        .then((resp) => {
          if (resp.data.ErrorMsg) return;
          file.id = resp.data.Value[0].PersonDocumentID;
          this.uploadedFiles.push(file);
          this.triggerUploadedFilesUpdate = !this.triggerUploadedFilesUpdate
        });
    },
    deleteFileHandler(index) {
      if (!this.isEdit) {
        this.uploadedFiles.splice(index, 1);
      } else {
        var id = this.uploadedFiles[index].id;
        this.$confirm("Would you like to delete this document?").then(() => {
          axios
            .post("Person/UpdateDocument", {
              PersonDocumentID: id,
              Delete: true,
            })
            .then((resp) => {
              if (resp.data.ErrorMsg) return;
              this.uploadedFiles.splice(index, 1);
            });
        });
      }
    },
    saveFilesInData(files) {
      this.uploadedFiles = files.map((el) => ({
        name: el.FileName,
        dateCreated: getDateString(el.UploadTime, "dd-MM-yyyy"),
        size: el.Size,
        id: el.PersonDocumentID,
        AttachmentType: el.FileType,
        attachmentID: el.FileID,
      }));
    },
    uploadFileHandler(file) {
      if (!this.isEdit) {
        this.uploadedFiles.push(file);
      } else {
        this.uploadFile(file);
      }
    },
  },
  computed: {
    isEdit() {
      return this.personID ? true : false;
    },
  },
  watch: {
    avatar() {
      if (!this.isEdit) return;
      this.updateAvatar();
    },
  },
};
</script>

<style scoped lang="scss">
.background {
  // height: calc(100% - 43 * #{$rem});
}
.profile-info {
  height: 100%;
  padding: 0px 1.875rem 0 4rem;
  background-color: $purple-background;
  border-radius: 0.625rem;
  padding-top: 58px;
  overflow-y: auto;

  .columns {
    display: flex;

    .left {
      max-width: 500 * $rem;
      width: 100%;
    }

    .right {
      width: 100%;
    }
  }
}
</style>
