<template>
  <div class="background">
    <div
      class="traffic_additional_header"
      v-if="!this.$store.state.showTimeLine"
    >
      <traffic-tabs />
    </div>
    <router-link
      v-if="this.$store.state.showTimeLine"
      class="back-arrow"
      tag="a"
      :to="{ path: 'MyTraffic' }"
    >
      <img :src="require('@/assets/icons/icon_back.svg')" />
    </router-link>
    <div class="history-header">
      <div class="left-side">
        <div
          class="request-person"
          v-for="person in personStatus"
          :key="person.index"
        >
          <div
            :style="[
              {
                backgroundImage: `url(${getAvatarSrc(person.PersonID)})`,
              },
              {
                border: person.OnVacation
                  ? '3px solid #1DB3E9 '
                  : person.OnWeekend && person.ScheduleAssigned
                  ? '3px solid #1DE9B6'
                  : !person.ScheduleAssigned
                  ? '3px solid  #FFA346'
                  : '3px solid #283593',
              },
            ]"
            class="avatar"
          ></div>
          <div class="request-person-info">
            <span class="person-info-name">{{
              person.FirstName + " " + person.LastName
            }}</span>

            <span class="job-name">{{ person.JobName }}</span>
            <span
              :style="[
                {
                  backgroundColor: person.OnVacation
                    ? '#1DB3E9'
                    : person.OnWeekend && person.ScheduleAssigned
                    ? '#1DE9B6'
                    : !person.ScheduleAssigned
                    ? '#FFA346'
                    : '#283593',
                },
                {
                  width: !person.ScheduleAssigned ? '145px' : '65px',
                },
              ]"
              class="status-text"
              >{{ statusText }}</span
            >
          </div>
        </div>
      </div>
      <div class="right-side">
        <div class="total-time-content" v-if="activityTotalTime">
          <span>Active Time : </span>
          <span>{{ getTimeTextFromSeconds(activityTotalTime) }}</span>
        </div>
        <div class="time-picker-content">
          <vue-timepicker
            placeholder="Pick time"
            v-model="pickedHour"
            close-on-complete
          ></vue-timepicker>
        </div>

        <div
          class="date-content"
          :class="{ userStyleDateContent: this.$store.state.showTimeLine }"
        >
          <span v-if="dateChanged == false">{{ defoultDateText }}</span>
          <span v-if="dateChanged == true">{{ dateText }}</span>
          <activity-calendar
            :monitoringCalendar="true"
            :trafficCalendar="true"
            @dateChange="getDateValue"
          />
        </div>
        <input-search
          v-if="!this.$store.state.showTimeLine"
          title="Select Person"
          @select="(person) => (this.selectedPerson = person)"
          :options="personOptions"
        />
        <div class="app-search-content">
          <input type="text" v-model="searchApp" placeholder="Search App/Web" />
          <img
            class="search-icon"
            src="@/assets/icons/searchAppIcon.svg"
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="history-timeline-content" v-if="data.length">
      <div class="single-time-container" v-for="t in filteredTimes" :key="t">
        <p
          class="times"
          ref="times"
          :class="{
            iddleTimeColor: inactiveTimes.some((el) => el.time == t),
          }"
        >
          {{ t }}
        </p>

        <ul
          class="apps-container"
          v-if="appsByTime[t]"
          :style="{ cursor: appsByTime[t].length >= 6 ? 'e-resize' : 'auto' }"
          v-dragscroll
        >
          <li
            v-for="app in filteredApps[t]"
            :style="[
              {
                height: app.heightNumber * 43 + 'px',
              },
              { border: !app.WorkingProgram ? '1px solid #FFA346 ' : null },
            ]"
            :key="app.index"
          >
            <div v-if="!app.URL" class="name-icon-wrapper">
              <img
                class="app-icon"
                v-if="app.HasLogo == true"
                :src="`data:image/jpeg;base64, ${app.File}`"
              />
              <span>
                {{ app.ProgramName }}
              </span>
            </div>
            <div class="name-icon-wrapper" v-else>
              <img class="web-icon" :src="getObjectIcon(app.URL)" />
              <span :title="app.URL">
                {{ app.URL }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="no-data-text" v-else-if="!dataIsLoaded">
      There is no records
    </div>
    <custom-loader v-if="dataIsLoaded" />
  </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance";
import { getDateString } from "@/Helpers/HelperFunctions";
import ActivityCalendar from "@/components/Dashboard/ActivityCalendar";
import InputSearch from "@/components/ReusableComponents/InputSearch/InputSearch.vue";
import VueTimepicker from "vue2-timepicker";
import { dragscroll } from "vue-dragscroll";
import TrafficTabs from "@/components/ReusableComponents/ViewChanger/TrafficTabs.vue";
import en from "element-ui/lib/locale/lang/en";
import "vue2-timepicker/dist/VueTimepicker.css";
import CustomLoader from "@/components/ReusableComponents/Loader/CustomLoader.vue";

export default {
  components: {
    ActivityCalendar,
    InputSearch,
    VueTimepicker,
    TrafficTabs,
    CustomLoader,
  },
  directives: {
    dragscroll,
  },
  created() {
    this.$store.state.showBookmarks = false;
    this.getPersonTraffic();
    this.getPersonMonitoringStat();
    this.getPersons();
    this.everyNminutes(1);
  },
  data() {
    return {
      selectedPerson: "",
      pickedHour: "",
      data: [],
      searchApp: "",
      personStatus: [],
      newDate: "",
      // date: getDateString(new Date(), 'ww dd MMM yy'),
      dateToSend: getDateString(new Date(), "yyyy/MM/dd"),
      dateChanged: false,
      selectedDate: "",
      allPersons: [],
      times: [],
      idleTime: [],
      programLogo: Object,
      activeTime: [],
      dataIsLoaded: false,
    };
  },
  watch: {
    data: function () {
      for (let i = 0; i < this.data.length - 1; i++) {
        this.data[i].heightNumber = new Date(
          this.data[i + 1].ActionTime - this.data[i].ActionTime
        ).getMinutes();
        if (this.data[i].heightNumber == 0) {
          this.data[i].heightNumber = 1;
        }
      }
      if (!this.data.length) {
        this.dataIsLoaded = false;
      }
    },
    selectedDate: function (to, from) {
      if (from) {
        this.dateChanged = true;
        const date = new Date(to);
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let year = date.getFullYear();
        let day = date.getDate();
        let dateToSend = year + "-" + month + "-" + day;
        let personId = "";

        if (this.selectedPerson) {
          personId = this.selectedPerson;
        } else if (this.$route.query.personId) {
          personId = this.$route.query.personId;
        } else {
          personId = this.$store.state.profile.ID;
        }
        this.dataIsLoaded = true;

        axios
          .get(
            `attendance/GetPersonsTrafficHistory?Person=${personId}&Day=${dateToSend}`
          )
          .then((resp) => {
            this.data = resp.data.Value.TrafficHistory;
            this.idleTime = resp.data.Value.IdleTimesHistory;
            this.dataIsLoaded = false;
          });
        axios
          .get(
            `attendance/GetPersonsPCMonitringSoftStatus?Person=${personId}&Day=${dateToSend}`
          )
          .then((resp) => {
            this.personStatus = resp.data.Value;
            this.dataIsLoaded = false;
          });

        axios
          .get("attendance/GetSoftMonitoringRecords", {
            params: {
              Person: personId,
              From: dateToSend,
              To: dateToSend,
            },
          })
          .then((resp) => {
            this.activeTime = resp.data.Value.map((el) => ({
              programName: el.ProgramName,
              seconds: el.TimeSpent - el.IdleTime,
            }));
            this.dataIsLoaded = false;
          });
      }
    },
    selectedPerson: function (to, from) {
      if (to) {
        this.selectedDate = this.dateToSend;
        axios
          .get(
            `attendance/GetPersonsTrafficHistory?Person=${this.selectedPerson}&Day=${this.dateToSend}`
          )
          .then((resp) => {
            this.data = resp.data.Value.TrafficHistory;
            this.idleTime = resp.data.Value.IdleTimesHistory;
          });
        axios
          .get(
            `attendance/GetPersonsPCMonitringSoftStatus?Person=${this.selectedPerson}&Day=${this.dateToSend}`
          )
          .then((resp) => {
            this.personStatus = resp.data.Value;
          });
      }
    },
  },
  methods: {
    getAvatarSrc(id) {
      return this.$store.state.getAvatar + id;
    },
    getTimeTextFromSeconds(seconds) {
      if (!seconds) return;
      var minutes = parseInt(seconds / 60);
      var hr = Math.floor(minutes / 60);
      var mm = minutes % 60;
      if (mm == 0) return `${hr}hr`;
      if (hr == 0) return `${mm}m`;
      return `${hr}hr ${mm}m`;
    },
    getObjectIcon(url) {
      return `https://s2.googleusercontent.com/s2/favicons?domain_url=${url}`;
    },
    addIddleColor(node) {
      let result = false;

      return result;
    },

    getPersonTraffic() {
      let id;
      if (this.$route.query.personId) {
        id = this.$route.query.personId;
      } else {
        id = this.$store.state.profile.ID;
      }
      this.dataIsLoaded = true;
      axios
        .get(
          `attendance/GetPersonsTrafficHistory?Person=${id}&Day=${this.dateToSend}`
        )
        .then((resp) => {
          this.data = resp.data.Value.TrafficHistory;
          this.idleTime = resp.data.Value.IdleTimesHistory;
          this.dataIsLoaded = true;
        });
    },
    getPersonMonitoringStat() {
      let id;
      if (this.$route.query.personId && !this.selectedPerson) {
        id = this.$route.query.personId;
      } else if (!this.selectedPerson && !this.$route.query.personId) {
        id = this.$store.state.profile.ID;
      } else {
        id = this.selectedPerson;
      }

      axios
        .get(
          `attendance/GetPersonsPCMonitringSoftStatus?Person=${id}&Day=${this.dateToSend}`
        )
        .then((resp) => {
          this.personStatus = resp.data.Value;
        });
    },
    getPersons() {
      axios.get("/Person/GetPersons").then((resp) => {
        this.allPersons = resp.data.Value.Persons;
      });
    },
    getDateValue(newValue) {
      this.selectedDate = newValue;
    },
    everyNminutes(n) {
      for (var hours = 0; hours < 24; hours++) {
        for (var minutes = 0; minutes < 60; minutes = minutes + n) {
          var h = "";
          var m = "";
          if (hours < 10) {
            h = "0" + hours;
          } else {
            h = hours;
          }
          if (minutes < 10) {
            m = "0" + minutes;
          } else {
            m = minutes;
          }
          this.times.push(h + ":" + m);
        }
      }
    },
  },

  computed: {
    statusText() {
      let result = "";
      this.personStatus.forEach((el) => {
        if (!el.ScheduleAssigned) {
          result = "No schedule assigned";
        } else if (el.OnVacation) {
          result = "Vacation";
        } else if (el.OnWeekend) {
          result = "Weekend";
        } else {
          result = "Active";
        }
      });
      return result;
    },
    activityTotalTime() {
      return this.activeTime.reduce((sum, el) => (sum += el.seconds), 0);
    },
    timesToCompare() {
      let result = [];
      this.idleTime.forEach((time) => {
        let idleStart = new Date(time.IdleStartTime);

        let hours = idleStart.getHours();
        let minutes = idleStart.getMinutes();
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        let timeToCompare = hours + ":" + minutes;
        result.push(timeToCompare);
      });
      return result;
    },
    appsByTime() {
      let result = {};
      for (let i = 0; i < this.data.length - 1; i++) {
        var key = this.data[i].ActionTimeHourPart.slice(0, 5);
        if (!result[key]) {
          this.$set(result, key, []);
        }
        result[key].push(this.data[i]);
      }
      return result;
    },

    filteredApps() {
      if (this.searchApp) {
        let result = {};
        for (let [key, value] of Object.entries(this.appsByTime)) {
          const arrays = this.appsByTime[key];

          const found = arrays.filter((el) => {
            if (el.URL) {
              return el.URL.toLowerCase().includes(
                this.searchApp.toLowerCase()
              );
            } else {
              return el.ProgramName.toLowerCase().includes(
                this.searchApp.toLowerCase()
              );
            }
          });
          result[key] = found;
        }
        return result;
      }
      return this.appsByTime;
    },

    filteredTimes() {
      if (!this.data.length || !this.times.length) return;

      var appTimes = Object.keys(this.appsByTime).sort((a, b) => {
        return a - b;
      });
      var minTime = appTimes[0];
      var maxTime = appTimes.pop();

      var pickedTimeRegex = /^[0-9]{2}:[0-9]{2}$/;
      if (pickedTimeRegex.test(this.pickedHour)) {
        if (this.filteredApps[this.pickedHour]) {
          minTime = this.pickedHour;
        } else {
          let times = Object.keys(this.filteredApps);
          let pickedMinTime = "";
          times.forEach((el) => {
            if (el < this.pickedHour && el > pickedMinTime) {
              pickedMinTime = el;
            }
          });

          if (pickedMinTime) {
            minTime = pickedMinTime;
          } else {
            minTime = this.pickedHour;
          }
        }
      }

      return this.times.filter((el) => {
        return el >= minTime && el <= maxTime;
      });
    },

    personOptions() {
      return this.allPersons.map((el) => ({
        title: el.FirstName + " " + el.LastName,
        value: el.ID,
      }));
    },
    defoultDateText() {
      return getDateString(new Date(), "Today, ww, dd MMM  yy ");
    },
    dateText() {
      return getDateString(this.selectedDate, "ww, dd MMM yy ");
    },
    isBookmarkShown() {
      return this.$store.state.showBookmarks;
      // const path = this.$route.path
    },
    inactiveTimes() {
      let inactiveTimes = [];
      this.idleTime.forEach((el) => {
        const startTime = new Date(el.IdleStartTime);
        const endTime = new Date(el.IdleStartTime + el.IdleTime);
        const finalEndTime = new Date(
          endTime.setMinutes(endTime.getMinutes() - 1)
        );
        let currentTime = new Date(
          startTime.setMinutes(startTime.getMinutes() - 1)
        );

        while (currentTime < finalEndTime) {
          inactiveTimes.push(currentTime);
          currentTime.setMinutes(currentTime.getMinutes() + 1);
          currentTime = new Date(currentTime);
        }
      });
      inactiveTimes = inactiveTimes.map((el) => {
        let hour = el.getHours();
        let minutes = el.getMinutes();
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        return {
          time: hour + ":" + minutes,
        };
      });

      return inactiveTimes;
    },
  },
};
</script>

<style scoped lang="scss">
.background {
  padding: 25px 75px 70px 75px;
  overflow: hidden;
}
.back-arrow {
  position: absolute;
  left: 20px;
  top: 30px;
}
.iddleTimeColor {
  color: #9696b9 !important;
  opacity: 0.7;
}
.bookmarkStyle {
  top: 100px;
}
.history-header {
  display: flex;
  justify-content: space-between;
  height: 75px;
}
.request-person {
  display: flex;
  align-items: center;
  // width: 16.8rem;

  .request-person-info {
    display: flex;
    flex-direction: column;

    margin-left: 12px;
    .status-text {
      height: 22px;
      color: white;
      font-size: 13px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
      white-space: nowrap;
      // width: 100%;
    }
  }

  .circle {
    width: 88px;
    height: 88px;
    border-radius: 100%;
    background-color: #ffffff;
  }
  .person-info-name {
    color: $blue-primary;
    font-size: 18px;
    font-weight: 500;
  }
  .job-name {
    font-size: 14px;
    color: #9696b9;
    margin-top: 2px;

    white-space: normal;
  }
  .avatar {
    width: 75px;
    height: 75px;
    border-radius: 100%;
    background-color: #ffffff;
    background-size: cover;
    background-repeat: no-repeat;

    background-position: center;
  }
}
.no-data-text {
  text-align: center;
  font-size: 50px;
  margin-top: 53px;
  color: #283593;
  opacity: 0.2;
  font-weight: 500;
}
.right-side {
  display: flex;
  align-items: center;

  .total-time-content {
    margin-right: 30px;
    span:nth-child(1) {
      color: #9696b9;
      font-size: 14px;
    }

    span:nth-child(2) {
      color: $blue-primary;
      font-size: 14px;
    }
  }
  .date-content {
    margin-right: 20px;
  }
  .userStyleDateContent {
    margin-right: 0;
  }
  .app-search-content {
    margin-left: 30px;
  }

  ::v-deep.input-search {
    font-weight: 300;
    label {
      width: 12.938rem;
      height: 48px;
      .title {
        color: #9696b9;
        top: 0.95rem;
        font-weight: 300;
        font-size: 14px;

        &.focused {
          top: -6 * $rem;
          font-size: 12 * $rem;
          padding-left: 2 * $rem;
          padding-right: 2 * $rem;
          left: 0;
        }
      }

      input {
        border: 0;

        width: 12.938rem;
        height: 48px;
      }
    }
  }
  .app-search-content {
    position: relative;
    input {
      width: 12.938rem;
      height: 48px;
      background-color: #fff;
      border: 0;
      outline: none;
      border-radius: 0.375rem;
      font-weight: 300;
      font-size: 14px;
      color: #9696b9;
      padding-left: 15px;
      &::placeholder {
        font-weight: 300;
        color: #9696b9;
        font-size: 14px;
      }
    }
    .search-icon {
      position: absolute;
      height: 21px;
      width: 21px;
      right: 10px;
      top: 12px;
    }
  }
  .date-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 12.938rem;
    height: 48px;
    background-color: #fff;
    border-radius: 0.375rem;
    span {
      font-weight: 300;
      font-size: 14px;
      padding-left: 8px;
      padding-right: 15px;
      white-space: nowrap;
      color: #9696b9;
    }
  }
}
.history-timeline-content {
  height: 95%;
  overflow-y: scroll;
  margin-top: 30px;
  width: calc(100% + 50px);
  .single-time-container {
    height: 45px;
  }

  p {
    display: block;
    font-size: 13px;
    // font-weight: 300;
    // margin-top: 20px;
    color: $blue-primary;
  }

  .apps-container {
    list-style-type: none;
    margin-left: 50px;
    width: 94%;
    overflow: hidden;
    white-space: nowrap;
    margin-top: -9px;

    .name-icon-wrapper {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
    .app-icon {
      height: 18px;
      width: 18px;
      margin-right: 3px;
    }
    .web-icon {
      height: 18px;
      width: 18px;
      margin-right: 3px;
    }
    li {
      width: 235px;
      height: 30px;
      border: 1px solid $blue-primary;
      border-radius: 6px;
      color: $blue-primary;
      font-size: 15px;
      font-weight: 300;
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      vertical-align: top;
      user-select: none;
      div {
        overflow: hidden;
      }
      & + li {
        margin-left: 15px;
      }
    }
  }
}

.time-picker-content {
  margin-right: 20px;
  position: relative;
}
::v-deep .vue__time-picker input.display-time {
  outline: none;
  height: 48px;
  border: 0;
  border-radius: 0.375rem;
  color: #9696b9;

  &::placeholder {
    color: #9696b9;
    font-size: 15px;
    font-weight: 300;
  }
}
::v-deep .vue__time-picker .controls .char {
  margin-right: 5px;
}
::v-deep .vue__time-picker .dropdown ul li.active {
  background-color: #a4a9d3;
}
::v-deep .vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background-color: #a4a9d3;
}
::v-deep .vue__time-picker .dropdown ul li {
  color: #9696b9;
}

.traffic_additional_header {
  width: 120%;
  height: 3.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background: #fff;
  top: -2.025rem;
  left: -6.55rem;
  padding: 0 1.875rem;
}
</style>