<template>
  <attendance-log :personID="person" :show-header="false" />
</template>

<script>
import AttendanceLog from "@/components/AttendanceLog/AttendanceLog";
export default {
  created() {
    this.person = this.$route.params.id
      ? this.$route.params.id
      : this.$store.state.profile.ID;
  },
  data() {
    return {
      person: "",
    };
  },
  components: {
    AttendanceLog,
  },
};
</script>
 
<style lang="scss" scoped>
.background {
  //   height: calc(100% - 43 * #{$rem});
  ::v-deep .log {
    height: 100%;
    .log-entries {
      height: 98%;
    }
    .log-entries-header {
      margin-top: 25px;
    }
  }
}
</style>