<template>
  <div class="background">
    <div class="offices">
      <grid
        title="Offices"
        :gridData="gridData"
        :columns="columns"
        :passedPageSize="gridParams.pageSize"
        :dataCount="gridParams.totalCount"
        identificator="ID"
        :pagination="false"
        @add-click="addNewClickHandler"
        @trClicked="trClickHandler"
        :canDelete="accessPermissions.createOffice"
        @rowDelete="showDeletePopup"
        :canEdit="accessPermissions.createOffice"
        @rowEdit="editRow"
        :canAdd="true"
      />

      <custom-popup
        :visible="popupVisible"
        @close="popupVisible = false"
        title="Add Office"
        :flexibleSize="true"
      >
        <form class="add-form" @submit.prevent="addOffice">
          <custom-input
            v-model="newOffice.name"
            title="Name"
            @clear="newOffice.name = ''"
          />
          <custom-input
            v-model="newOffice.address"
            title="Address"
            @clear="newOffice.address = ''"
          />
          <custom-select
            title="City"
            v-model="newOffice.city"
            :options="cityOptions"
            @clear="newOffice.city = ''"
          />
          <date-picker
            title="Start Date"
            v-model="newOffice.startTime"
            @clear="newOffice.startTime = ''"
          />
          <button class="btn">Save</button>
        </form>
      </custom-popup>
      <custom-popup
        :bigSize="true"
        v-if="employeePopup"
        @close="employeePopup = false"
        :visible="true"
        class="add-office-members"
        ><office-members :officeID="selectedOfficeID" />
      </custom-popup>

      <delete-item
        @delete="deleteRow"
        @close="deletePopupVisible = false"
        v-if="deletePopupVisible"
        :text="deleteText"
      />
      <custom-snackbar
        v-if="snackbarVisible"
        :purpose="snackbarPurpose"
        :text="snackbarText"
        @close="snackbarVisible = false"
      />
    </div>
  </div>
</template>

<script>
import Grid from "@/components/ReusableComponents/Grid/Grid.vue";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup.vue";
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput.vue";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import axios from "@/Helpers/AxiosInstance.js";
import DeleteItem from "@/components/ReusableComponents/DeleteItem/DeleteItem.vue";
import OfficeMembers from "./OfficeMembers";
import {
  getDateString,
  checkPermission,
  utcString,
  CETToTimestamp,
} from "@/Helpers/HelperFunctions.js";
export default {
  components: {
    Grid,
    CustomPopup,
    CustomInput,
    DatePicker,
    CustomSelect,
    CustomSnackbar,
    DeleteItem,
    OfficeMembers,
  },
  created() {
    axios.get("Company/GetCities").then((resp) => {
      this.cities = resp.data.Value;
      this.getData();
    });
  },
  data() {
    return {
      accessPermissions: {
        createOffice: checkPermission("CreateOffice"),
      },
      gridData: [],
      popupVisible: false,
      newOffice: {
        name: "",
        address: "",
        city: "",
        startTime: "",
      },
      snackbarPurpose: "error",
      snackbarText: "",
      snackbarVisible: false,
      cities: [],
      deletePopupVisible: false,
      selectedOfficeID: 0,
      gridParams: {
        currentPage: 1,
        pageSize: 20,
        orderByColumn: "ID",
        ascending: false,
        totalCount: 0,
      },
      employeePopup: false,
    };
  },
  methods: {
    getData() {
      axios.get("Company/GetOffices").then((resp) => {
        this.gridData = resp.data.Value.map((el) => {
          if (el.EndTime == null) el.EndTime = 0;
          return el;
        });
      });
    },
    addNewClickHandler() {
      this.popupVisible = true;
    },
    addOffice() {
      axios
        .post("Company/CreateOffice", {
          Name: this.newOffice.name,
          Address: this.newOffice.address,
          City: this.newOffice.city,
          StartTime: this.newOffice.startTime,
        })
        .then((resp) => {
          this.getData();
          if (!resp.data.ErrorMsg) {
            this.popupVisible = false;
            this.snackbarVisible = true;
            this.snackbarText = "Successfully created new office!";
            this.snackbarPurpose = "success";
            this.clearCreateForm();
          } else {
            this.popupVisible = false;
            this.snackbarPurpose = "error";
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
          }
        })
        .catch(() => {
          this.popupVisible = false;
          this.snackbarPurpose = "error";
          this.snackbarVisible = true;
          this.snackbarText = "An Error Occured While Adding New Office!";
        });
    },
    showDeletePopup(id) {
      this.selectedOfficeID = id;
      this.deletePopupVisible = true;
    },
    trClickHandler(element, e) {
      if (e.target.matches(".i-employees")) {
        this.employeePopup = true;
        this.selectedOfficeID = element.ID;
      }
    },
    deleteRow() {
      var item = this.gridData.filter(
        (el) => el.ID == this.selectedOfficeID
      )[0];
      var data = {
        Id: this.selectedOfficeID,
        City: item.City,
        Name: item.Name,
        Address: item.Address,
        Deleted: true,
        EndTime: item.EndTime ? item.EndTime : null,
      };
      axios
        .post("Company/UpdateOffice", data)
        .then((resp) => {
          this.deletePopupVisible = false;
          if (!resp.data.ErrorMsg) {
            this.snackbarText = "Deleted Successfully!";
            this.snackbarPurpose = "success";
            this.snackbarVisible = true;
            this.getData();
          } else {
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
            this.snackbarPurpose = "error";
          }
        })
        .catch(() => {
          this.snackbarVisible = true;
          this.snackbarText = "Error";
          this.snackbarPurpose = "error";
        });
    },
    utcNow() {
      return new Date().getTime();
    },
    editRow(values) {
      values["Deleted"] = false;
      values["EndTime"] == 0 ? (values["EndTime"] = null) : values["EndTime"];

      axios
        .post("Company/UpdateOffice", values)
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.getData();
            this.snackbarText = "Updated Successfully!";
            this.snackbarPurpose = "success";
            this.snackbarVisible = true;
          } else {
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
            this.snackbarPurpose = "error";
          }
        })
        .catch(() => {
          this.snackbarVisible = true;
          this.snackbarText = "Error";
          this.snackbarPurpose = "error";
        });
    },
    clearCreateForm() {
      this.newOffice.name = "";
      this.newOffice.address = "";
      this.newOffice.city = "";
      this.newOffice.startTime = new Date().getTime();
    },
  },
  computed: {
    cityOptions() {
      return this.cities.map((el) => ({
        title: el.Name,
        value: el.CountryID.toString(),
      }));
    },
    deleteText() {
      if (this.gridData.length == 0 || !this.selectedOfficeID) return "";
      var name = this.gridData.filter((el) => el.ID == this.selectedOfficeID)[0]
        .Name;
      return `Would you like to delete office <strong>${name}</strong>?`;
    },
    columns() {
      return [
        {
          title: "City",
          key: "City",
          customTemplate: (item, val) => {
            let city = this.cities.filter((el) => el.CountryID == val)[0];
            if (city) return city.Name;
            return "-";
          },
          editable: true,
          editType: "select",
          editOptions: this.cityOptions,
        },
        {
          title: "Time Zone",
          key: "City",
          customTemplate: (item, val) => {
            let city = this.cities.filter((el) => el.CountryID == val)[0];
            if (city) {
              return utcString(city.UTCmiliseconds);
            }
            return "-";
          },
        },
        {
          title: "Name",
          key: "Name",
          editable: true,
          editType: "text",
        },
        {
          title: "Address",
          key: "Address",
          editable: true,
          editType: "text",
        },
        {
          title: "Start Time",
          key: "StartTime",
          customTemplate: (item, val) => getDateString(CETToTimestamp(val)),
        },
        {
          title: "End Time",
          key: "EndTime",
          customTemplate: (item, val) =>
            val ? getDateString(CETToTimestamp(val)) : "-",
          editable: true,
          editType: "date",
        },
        {
          title: "Employees",
          key: "",
          customTemplate: () =>
            `<img class="custom-icon i-employees" src="${require("@/assets/teams.svg")}" ></img>`,
          tdClass: "icon",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.offices {
  .button-container {
    margin-bottom: 15 * $rem;
  }

  form {
    margin-top: 30 * $rem;
    display: flex;
    align-items: center;

    label,
    .select-wrapper {
      margin-right: 15 * $rem;
    }
  }

  ::v-deep .table-wrapper {
    overflow-x: initial !important;
  }

  ::v-deep .popup {
    width: 70rem;
    height: 80vh;
    overflow: hidden;
  }
}
::v-deep .overlay .popup h3.title {
  display: none;
}
</style>
