import Vue from 'vue'
import App from './App.vue'
import { routes } from './routes.js'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import { store } from './store.js'
import PortalVue from 'portal-vue'
import './plugins/element.js'
import './styles/element-ui-theme-customization.scss'
// import Calendar from 'v-calendar'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VueFileAgent from 'vue-file-agent'
Vue.use(VueFileAgent)
import VueI18n from 'vue-i18n'
import VueParticles from 'vue-particles'
Vue.use(VueParticles)

import Vuetify from 'vuetify'
Vue.config.productionTip = false
Vue.use(Vuetify)

Vue.use(Vuex)
Vue.use(VueI18n)
Vue.use(VueRouter)
Vue.use(PortalVue)
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.prototype.$eventBus = new Vue()
Vue.prototype.$confirm = function (text) {
  //Dispatches vuex action that triggers global confirm from App.vue
  return store.dispatch('confirm', text) //returns promise. Resolved if user presses 'yes'
}
// Vue.component('calendar', Calendar)

const router = new VueRouter({
  routes,
  mode: 'hash',
})
// Vue.mixin({
//     methods: {
//         log(label,arg){
//             console.log(`%c${label}`,'color:#ffffff;padding: 7px 10px;background: rgb(41, 204, 57);border-radius: 3px;font-weight:600;',arg)
//         }
//     },
// })

new Vue({
  render: (h) => h(App),
  VueI18n,
  router,
  store,
  //   Calendar,
  vuetify: new Vuetify(),
}).$mount('#app')
