import { render, staticRenderFns } from "./SingleBookmark.vue?vue&type=template&id=22729f59&scoped=true"
import script from "./SingleBookmark.vue?vue&type=script&lang=js"
export * from "./SingleBookmark.vue?vue&type=script&lang=js"
import style0 from "./SingleBookmark.vue?vue&type=style&index=0&id=22729f59&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22729f59",
  null
  
)

export default component.exports