import { store } from '@/store'

function getDateString(date, format = 'yyyy-MM-dd') {
    if (date === '' || date === null) return date

    if (!(date instanceof Date)) {
        date = new Date(date)
    }
    if (date === 'Invalid Date') return

    const MONTHS = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]
    const WEEKDAYS = ['Sun','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

    var days = ('0' + date.getDate()).slice(-2),
        month = ('0' + (date.getMonth() + 1)).slice(-2),
        year = date.getFullYear(),
        hours = ('0' + date.getHours()).slice(-2),
        minutes = ('0' + date.getMinutes()).slice(-2),
        seconds = ('0' + date.getSeconds()).slice(-2)

    return format
        .replace('dd', days)
        .replace('MMM', MONTHS[parseInt(month) - 1])
        .replace('MM', month)
        .replace('yyyy', year)
        .replace('yy', String(year).slice(-2))
        .replace('HH', hours)
        .replace('mm', minutes)
        .replace('ss', seconds)
        .replace('ww', WEEKDAYS[date.getDay()])
}

function copyToClipboard(str) {
    const el = document.createElement('textarea') // Create a <textarea> element
    el.value = str // Set its value to the string that you want copied
    el.setAttribute('readonly', '') // Make it readonly to be tamper-proof
    el.style.position = 'absolute'
    el.style.left = '-9999px' // Move outside the screen to make it invisible
    document.body.appendChild(el) // Append the <textarea> element to the HTML document
    const selected =
        document.getSelection().rangeCount > 0 // Check if there is any content selected previously
            ? document.getSelection().getRangeAt(0) // Store selection if found
            : false // Mark as false to know no selection existed before
    el.select() // Select the <textarea> content
    document.execCommand('copy') // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el) // Remove the <textarea> element
    if (selected) {
        // If a selection existed before copying
        document.getSelection().removeAllRanges() // Unselect everything on the HTML document
        document.getSelection().addRange(selected) // Restore the original selection
    }
}

function exportToExcel(response, fileName) {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.xlsx`)
    document.body.appendChild(link)
    link.click()
}

function subtractHours(date, hours) {
    if (!date) return ''
    var d = new Date(date)
    d.setHours(0)
    d.setHours(d.getHours() - hours)
    return getDateString(d, 'yyyy-MM-dd HH:mm')
}

function addHours(date, hours, isTimestamp = false) {
    if (!date) return ''
    var d = new Date(date)
    d.setHours(d.getHours() + hours)
    return isTimestamp ? d.getTime() : getDateString(d, 'yyyy-MM-dd HH:mm')
}

function timestampToDateTime(timestamp, correctByTimeZone, timezoneOffset, isTimestamp) {
    if (!correctByTimeZone) {
        return getDateString(timestamp, 'yyyy-MM-dd')
    } else {
        return addHours(new Date(timestamp), timezoneOffset, isTimestamp)
    }
}

function timestampToHour(timestamp) {
    const date = new Date(timestamp)
    return date.getHours()
    // const ampm = hours >= 12 ? 'PM' : 'AM';
    // hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    // return unicode ? `${hours} ${ampm}` : hours;
}

function timestampToWeekday(timestamp) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    return days[new Date(timestamp).getDay()]
}

function dateTimeToTimestamp(date, correctByTimeZone, timezoneOffset) {
    if (!correctByTimeZone) {
        return new Date(date).getTime()
    } else {
        return new Date(subtractHours(date, timezoneOffset)).getTime()
    }
}

function utcNow() {
    var d = new Date()
    var st = d.toUTCString()
    st = st.substr(0, st.length - 3)
    return new Date(st).getTime()
}

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result.split(',')[1])
        reader.onerror = (error) => reject(error)
    })

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

function timestampToCET(timestamp) {
    let date = new Date(timestamp)
    date.setHours(date.getHours() + date.getTimezoneOffset() / 60)
    return date.getTime()
}

function CETToTimestamp(cetTime) {
    let date = new Date(cetTime)
    date.setHours(date.getHours() - date.getTimezoneOffset() / 60)
    return date.getTime()
}

function checkPermission(value) {
    return !!store.state.permissions.filter((el) => el.PermissionName === value).length
}

function checkProfileParam(routerId) {
    return store.state.profile.ID === routerId
}

function getProperDateForBack(date) {
    return date - new Date().getTimezoneOffset() * 60000
}

function utcString(milliseconds) {
    let hours = parseInt(milliseconds / 3600000)
    if (hours === 0) return 'UTC'
    return hours > 0 ? 'UTC ' + '+' + hours : 'UTC ' + hours
}

function getFullName(person) {
    const capitalize = (name) => name[0].toUpperCase() + name.substr(1)
    return `${capitalize(person.FirstName)} ${capitalize(person.LastName)}`
}

function debounce(func, duration) {
    let timeout

    return function (...args) {
        const effect = () => {
            timeout = null
            return func.apply(this, args)
        }

        clearTimeout(timeout)
        timeout = setTimeout(effect, duration)
    }
}

function minutesToTimeString(mins) {
    const zeroed = (a) => ('0' + Math.abs(a)).slice(-2)
    return `${mins < 0 ? '-' : ''}${zeroed(parseInt(mins / 60))}:${zeroed(parseInt(mins % 60))}`
}

Object.defineProperty(String.prototype, 'capitalize', {
    value: function(){
      return this.charAt(0).toUpperCase() + this.slice(1);
    },
    writable: true
});

export { getDateString, debounce, minutesToTimeString }
export { copyToClipboard }
export { exportToExcel }
export { subtractHours }
export { addHours }
export { timestampToDateTime }
export { dateTimeToTimestamp }
export { utcNow }
export { toBase64 }
export { formatBytes }
export { timestampToHour }
export { timestampToWeekday }
export { timestampToCET }
export { CETToTimestamp }
export { checkPermission }
export { checkProfileParam }
export { getProperDateForBack }
export { utcString, getFullName }
export class capitalize {}