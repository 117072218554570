<template>
    <div class="job-roles">
        <form @submit.prevent="addRole">
            <input-search
                title="Choose Role"
                :options="inputOptions"
                :isRequired="true"
                @select="(value) => (this.selectedRoleID = value)"
            />
            <button class="btn">Add</button>
        </form>
        <div class="roles-container">
            <div v-for="role in jobRoles" :key="role.ID" class="role-container">
                <span class="name">{{ role.Name }}</span>
                <!-- <i class="material-icons" @click="deleteRole(role.ID)">delete_forever</i> -->
                <img
                    class="material-icons"
                    @click="deleteRole(role.ID)"
                    src="@/assets/icons/delete.svg"
                    alt="delete"
                />
            </div>
        </div>
    </div>
</template>

<script>
import InputSearch from '@/components/ReusableComponents/InputSearch/InputSearch.vue'
import axios from '@/Helpers/AxiosInstance.js'
export default {
    components: {
        InputSearch,
    },
    props: {
        jobID: {
            type: Number,
            required: true,
        },
    },
    created() {
        this.getJobRoles()
        this.getAllRoles()
    },
    data() {
        return {
            jobRoles: [],
            allRoles: [],
            selectedRoleID: '',
        }
    },
    methods: {
        getJobRoles() {
            axios
                .get('Permission/GetJobRoles', {
                    params: {
                        ID: this.jobID,
                    },
                })
                .then((resp) => {
                    this.jobRoles = resp.data.Value.filter((el) => el.IsActive)
                })
        },
        getAllRoles() {
            axios
                .get('Permission/GetRoles', {
                    params: {
                        Page: 1,
                        PageSize: 1000000,
                        OrderByColumn: 'ID',
                        Ascending: false,
                    },
                })
                .then((resp) => (this.allRoles = resp.data.Value))
        },
        addRole() {
            axios
                .post('Permission/AssignJobRole', {
                    Job: this.jobID,
                    Role: this.selectedRoleID,
                    Activate: true,
                })
                .then(() => {
                    this.getJobRoles()
                })
        },
        deleteRole(roleID) {
            axios
                .post('Permission/AssignJobRole', {
                    Job: this.jobID,
                    Role: roleID,
                    Activate: false,
                })
                .then(() => {
                    this.getJobRoles()
                })
        },
    },
    computed: {
        inputOptions() {
            return this.allRoles
                .filter((el) => !this.jobRoles.find((jr) => jr.ID == el.ID))
                .map((el) => {
                    el.value = el.ID
                    el.title = el.Name
                    return el
                })
        },
    },
}
</script>

<style lang="scss" scoped>
form {
    width: 297 * $rem;
    display: flex;
    justify-content: center;

    margin-bottom: 30 * $rem;

    .input-search {
        margin-right: 15 * $rem;
    }
}

.roles-container {
    display: flex;
    align-items: center;
    .name {
        color: $blue-primary;
    }
    .role-container {
        display: flex;
        align-items: center;
        width: 221 * $rem;
        height: 45 * $rem;
        background-color: #fff;
        border-radius: 5 * $rem;
        margin-right: 15 * $rem;
        border: $rem solid $grey-border;
        padding: 0 15 * $rem;

        i {
            margin-left: auto;
            color: $red;
            cursor: pointer;

            &:hover {
                filter: brightness(1.05);
            }
        }
    }
}
.btn {
    background-color: rgba($blue-primary, 0.5);
    color: #ffffff;
    height: 40px;
    width: 100px;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
}
.material-icons {
    margin-left: auto;
    cursor: pointer;
    height: 40px;

    &:hover {
        filter: brightness(1.05);
    }
}
</style>