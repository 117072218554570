var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filter",on:{"mousedown":function($event){$event.stopPropagation();}}},[_c('img',{attrs:{"src":_vm.iconSrc},on:{"click":function($event){_vm.filterVisible = !_vm.filterVisible}}}),(_vm.filterVisible)?_c('portal',{attrs:{"to":"table-filter"}},[(_vm.listWithoutDuplicates.length)?_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterVisible),expression:"filterVisible"}],staticClass:"table-filter",style:(_vm.position),on:{"mousedown":function($event){$event.stopPropagation();}}},[_c('div',[_c('span',{staticClass:"clear",on:{"click":function($event){_vm.clearFilter,
              _vm.$emit('clearFilterValue'),
              (_vm.inputValue = ''),
              (_vm.value = '')}}},[_vm._v(" Clear Filter ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],attrs:{"type":"text","placeholder":"Search..."},domProps:{"value":(_vm.inputValue)},on:{"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value}}}),_vm._l((_vm.filteredList),function(option,ind){return _c('li',{key:ind,class:{ selected: _vm.value == option || _vm.value === option.value },on:{"click":function($event){return _vm.selectValue(option)}}},[_vm._v(" "+_vm._s(_vm.alternateType ? option.title : option)+" ")])})],2):_vm._e()]):_vm._e(),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.onlySearch && _vm.filterVisible),expression:"onlySearch && filterVisible"}],staticClass:"table-filter search"},[_c('div',[_c('span',{staticClass:"clear",on:{"click":function($event){_vm.clearFilter,
            _vm.$emit('clearFilterValue'),
            (_vm.inputValue = ''),
            (_vm.value = '')}}},[_vm._v(" Clear Filter ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],attrs:{"type":"text","placeholder":"Search..."},domProps:{"value":(_vm.inputValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value},(val) => _vm.$emit('search', _vm.inputValue)]}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }