<template>
  <div class="vacation-admin-content">
    <div class="vacation-requests-content" v-show="selectedTab == 0">
      <div class="vacation-header-content">
        <div class="recent-requests-title">
          <div class="titles">
            <span>Recent Requests</span>
            <span>{{ notApprovedVacationData.length }}</span>
          </div>
          <div class="export">
            <date-range
              :initialFrom="filterDate.from"
              :initialTo="filterDate.to"
              @from="(val) => (filterDate.from = val)"
              @to="
                (val) => (
                  (filterDate.to = val),
                  (scrollTop = false),
                  (scrollTop = true),
                  getVacations(filtered, true),
                  getPendingVacations(),
                  (clickedPendingPerson = null)
                )
              "
              :withClear="true"
            />
            <div
              class="export_report"
              v-if="exportVisibility"
              @click="excelReport"
            >
              <span>Export</span>
            </div>
          </div>
        </div>
        <div class="vacation-header-rightside">
          <div class="filter-checkbox" @click="getFilteredData">
            <span>Filtered</span>
            <custom-checkbox :value="filtered" />
          </div>
          <div
            class="add-button-content"
            v-if="isAdminOrHr"
            @click="popupVisible = true"
          >
            <add-button @clicked="popupVisible = true" />
            <span>Add holiday</span>
          </div>
          <form class="search-form">
            <input
              type="text"
              placeholder="Search"
              @input="searchPerson"
              v-model="searchName"
            />
          </form>
          <div
            class="request-vacation-button"
            @click=";(requestPopupVisible = true), (submitButtonFlag = true)"
          >
            <span>Request Vacation</span>
          </div>
        </div>
      </div>
      <div class="recent-requests-content">
        <hooper :itemsToShow="5">
          <slide
            class="request-person-content"
            v-for="(data, index) in notApprovedVacationData"
            :key="data.ID"
            :index="index"
          >
            <div
              class="request-person"
              ref="person"
              @click="getClickedPerson(data, data.ID)"
            >
              <span
                v-if="data.displayX == true"
                @click.stop="Xclicked(data)"
                class="material-icons cancel"
              >
                close
              </span>
              <div
                :style="{
                  backgroundImage: `url(${getAvatarSrc(data.ID)})`,
                }"
                class="avatar"
              ></div>
              <!-- </div> -->
              <div class="request-person-info">
                <span class="person-info-name">{{ data.FirstName }}</span>
                <span class="person-info-name">{{ data.LastName }}</span>
                <span class="job-name">{{ data.JobName }}</span>
              </div>
              <div class="border"></div>
            </div>
          </slide>
          <hooper-navigation
            v-if="notApprovedVacationData.length > 5"
            slot="hooper-addons"
          ></hooper-navigation>
        </hooper>
      </div>

      <custom-popup
        :visible="popupVisible"
        @close="popupVisible = false"
        title="Register Holidays Per Month"
      >
        <div class="container">
          <form>
            <div class="custom-select-wrapper">
              <custom-select
                title="Select Type"
                :options="typeOptions"
                v-model="holidayType"
              />
            </div>

            <div class="custom-select-wrapper">
              <custom-select
                title="Select City"
                v-model="countryId"
                :options="cityOptions"
              />
            </div>
          </form>
          <div class="calendar-content">
            <vacation-calendar
              @add-date="addDate"
              @remove-date="removeDate"
              :days="holidayDates"
              @monthIndex="monthIndex"
            />
          </div>
          <div class="holiday-dates-content">
            <div class="holidays-container">
              <div
                class="date-content"
                v-for="(value, date) in holidays"
                :key="date"
              >
                <div class="dot"></div>

                <div class="input-content">
                  <div>
                    <span class="date">{{ formatDate(date) }}</span>
                    <input
                      class="comment-title"
                      v-show="holidays[date].edit"
                      v-model="holidays[date].title"
                      @blur="inputBlurHandler"
                      type="text"
                      placeholder="Enter title..."
                    />
                    <div
                      @click="editClickHandler"
                      v-show="!holidays[date].edit"
                      class="value"
                      @mouseenter="holidays[date].activeIcon = true"
                      @mouseleave="holidays[date].activeIcon = false"
                    >
                      {{ value.title || '' }}
                    </div>
                  </div>

                  <span
                    @click="editClickHandler"
                    v-if="
                      holidays[date].activeIcon == true &&
                      holidays[date].title != ''
                    "
                    class="edit-span"
                  >
                    <img
                      class="edit-icon"
                      src="@/assets/icons/edit.svg"
                      alt="edit"
                    />
                  </span>
                </div>
              </div>
              <div class="created-holidays-content">
                <div
                  class="date-content"
                  v-for="(el, ind) in holidaysOfThisMonth"
                  :key="ind"
                >
                  <div class="dot"></div>
                  <div class="input-content">
                    <div>
                      <span class="date">{{ formatDate(el.date) }}</span>
                      <input
                        class="comment-title"
                        @blur="inputBlurHandler"
                        type="text"
                        v-model="el.newTitle"
                        placeholder="Enter title..."
                        v-if="el.editIcon == true"
                      />
                      <div
                        class="value holiday-value"
                        v-if="el.editIcon == false"
                      >
                        {{ el.title || '' }}
                      </div>
                    </div>
                    <div class="actions">
                      <span class="edit-span" v-if="el.editIcon == false">
                        <img
                          class="edit-icon"
                          src="@/assets/icons/edit.svg"
                          alt="edit"
                          @click="$set(el, 'editIcon', true)"
                        />
                      </span>
                      <div
                        class="material-icons-content"
                        v-if="el.editIcon == true"
                      >
                        <span
                          @click="checkClickHandler(el)"
                          class="material-icons success"
                        >
                          check
                        </span>
                        <span
                          @click="cancelClickHandler(el)"
                          class="material-icons cancel"
                        >
                          close
                        </span>
                      </div>
                      <span @click="deleteHoliday(el)" class="delete-icon">
                        <img src="@/assets/delete.svg" alt="delete" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button @click="createHoliday" class="btn">Save</button>
      </custom-popup>
      <custom-popup
        :visible="requestPopupVisible"
        @close="requestPopupVisible = false"
        :vacationSize="requestPopupVisible"
        title="Request Vacation"
      >
        <div class="request-vacation-container">
          <form>
            <div class="custom-select-wrapper">
              <input-search
                title="Select Person"
                @select="(person) => (this.selectedPerson = person)"
                :options="personOptions"
              />
            </div>

            <div class="custom-select-wrapper">
              <custom-select
                title="Vacation Type"
                v-model="selectedVacationType"
                :options="vacationTypeOptions"
              />
            </div>

            <div class="custom-select-wrapper">
              <date-range
                :initialFrom="dateRange.fromDate"
                :initialTo="dateRange.toDate"
                @from="(from) => (dateRange.fromDate = from)"
                @to="(to) => (dateRange.toDate = to)"
              />
            </div>

            <div class="total-days">
              Total Days
              <div>{{ getVacationsTotalDays() }}</div>
            </div>
          </form>
          <div class="person-content" v-if="selectedPerson != ''">
            <div
              :style="{
                backgroundImage: `url(${getAvatarSrc(selectedPerson)})`,
              }"
              class="avatar"
            ></div>
            <div
              class="person-info"
              v-for="person in selectedPersonData"
              :key="person.ID"
            >
              <span class="person-name">
                {{ person.FirstName + ' ' + person.LastName }}
              </span>
              <span class="job-name">{{ person.JobName }}</span>
            </div>
          </div>
        </div>
        <button @click.stop="submitButtonFlag ? createVacation() : ''">
          Send Request
        </button>
      </custom-popup>
      <custom-popup
        :title="popUptitle"
        :bigSize="true"
        :visible="requestStoryPopup"
        @close="requestStoryPopup = false"
        class="person-requests-popup"
      >
        <div class="header">
          <span>#</span>
          <span>Request date & time</span>
          <span>From date - to date</span>
          <span>Days</span>
          <span>Vacation Type</span>
          <span>Last Vacation</span>
          <span>Status</span>
        </div>
        <div class="body">
          <div
            class="row"
            v-for="(vacation, index) in personVacation"
            :key="index"
          >
            <div
              :style="{
                backgroundColor: vacation.confirmationInfoVisible
                  ? '#FCFCFF'
                  : null,
              }"
              class="background-wrapper"
            >
              <span>{{ index + 1 }}</span>
              <span>
                {{ getDateString(vacation.CreateTime, 'dd MMM yyyy HH:mm') }}
              </span>
              <span>
                {{ getDateString(vacation.FromDate, 'dd MMM yy') }} -
                {{ getDateString(vacation.ToDate, 'dd MMM yy') }}
              </span>
              <span>{{ vacation.TotalDays }}</span>
              <span>{{ vacationTypeText(vacation.VacationType) }}</span>
              <span>
                {{
                  getDurationString(
                    vacation.LastVacationDate,
                    vacation.FromDate,
                  )
                }}
              </span>
              <div class="status-content">
                <div class="wrapper">
                  <vacation-confirmation
                    :confirmNumb="vacation.ApprovesNum"
                    :rejectNumb="vacation.RejectsNum"
                    :mustConfirmNumb="vacation.MustBeApprovedByNum"
                    :approved="vacation.Approved"
                  />
                </div>
              </div>
              <span
                v-if="vacation.confirmationInfoVisible == false"
                @click.stop="GetVacationApprovedBy(vacation)"
                class="material-icons arrow-down"
              >
                keyboard_arrow_down
              </span>
              <span
                v-if="vacation.confirmationInfoVisible == true"
                class="material-icons arrow-up"
                @click.stop="
                  ;(vacation.confirmationInfoVisible = false),
                    (clickedVacationID = 0)
                "
              >
                keyboard_arrow_up
              </span>
            </div>
            <div
              :class="{ show: vacation.confirmationInfoVisible }"
              class="confirmation-info"
            >
              <div class="persons-content">
                <div
                  class="person"
                  v-for="el in vacation.confInfo"
                  :key="el.index"
                >
                  <span>{{ el.FirstName + ' ' + el.LastName }}</span>
                  <span>
                    {{
                      el.Approved
                        ? 'Approved'
                        : el.Approved == false
                        ? 'Rejected'
                        : 'Pending'
                    }}
                  </span>
                  <span>
                    {{
                      el.StartDate
                        ? getDateString(el.StartDate, 'dd MMM yyyy HH:mm')
                        : ''
                    }}
                  </span>
                  <span class="comment">{{ el.Comment }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </custom-popup>
    </div>
    <vacations-table
      v-if="selectedTab == 0"
      @show-popup="requestStoryVisible"
      @scolled-bottom="getDataOnScroll"
      :maxPageCount="maxPage"
      :page="page"
      :scrollTop="scrollTop"
      @approve="approveVacation"
      @delete-request="deleteRequest"
      @show-statuses="statusVisibleHandler"
      :departments="groupByDep"
    >
      <vacation-comment
        @input="commentHandler"
        @cancel="cancelComment"
      ></vacation-comment>
    </vacations-table>
    <vacation-teams
      @update-finished="shouldUpdateVacationTeams = false"
      :shouldUpdate="shouldUpdateVacationTeams"
      v-show="selectedTab == 1"
    />
    <vacation-terms
      v-show="selectedTab == 2"
      v-if="isAdmin || isHR || $store.getters.isSuperAdmin"
    />

    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      @close="snackbarVisible = false"
      :text="snackbarText"
    />
  </div>
</template>

<script>
import AddButton from '@/components/ReusableComponents/AddButton/AddButton.vue'
import VacationTeams from './VacationTeams.vue'
// import VacationAdminTabs from "./VacationAdminTabs.vue";
import VacationTerms from './VacationTerms.vue'
import VacationConfirmation from './VacationConfirmation.vue'
import CustomPopup from '@/components/ReusableComponents/CustomPopup/CustomPopup.vue'
import CustomSelect from '@/components/ReusableComponents/CustomSelect/CustomSelect.vue'
import InputSearch from '@/components/ReusableComponents/InputSearch/InputSearch.vue'
import VacationCalendar from '@/components/VacationAdmin/VacationCalendar.vue'
import VacationComment from './VacationComment.vue'
import DateRange from '@/components/ReusableComponents/DateRange/DateRange'
import CustomCheckbox from '@/components/ReusableComponents/CustomCheckbox/CustomCheckbox'
// import Grid from "@/components/ReusableComponents/Grid/Grid.vue";
import axios from '@/Helpers/AxiosInstance.js'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import { debounce } from '@/Helpers/HelperFunctions.js'
import VacationsTable from './VacationsTable.vue'
import {
  getDateString,
  CETToTimestamp,
  timestampToCET,
} from '@/Helpers/HelperFunctions.js'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'

export default {
  components: {
    AddButton,
    CustomPopup,
    CustomSelect,
    VacationCalendar,
    DateRange,
    Hooper,
    Slide,
    HooperNavigation,
    VacationConfirmation,
    CustomCheckbox,
    // Grid,
    VacationsTable,
    VacationComment,
    // VacationAdminTabs,
    VacationTeams,
    VacationTerms,
    InputSearch,
    CustomSnackbar,
  },
  created() {
    this.getHolidayTypes()
    this.getCities()
    this.getHolidays()
    this.getPersons()
    this.getVacationTypes()
    this.getVacations(this.filtered, true)
    this.getPendingVacations()

    this.loggedPerson = this.$store.state.profile.ID
  },
  data() {
    return {
      popupVisible: false,
      loggedPerson: '',
      maxPage: 0,
      page: 1,
      requestPopupVisible: false,
      timeout: null,
      selectedMonth: '',
      calendarYear: '',
      requestStoryPopup: false,
      selectedPerson: '',
      filtered: true,
      scrollTop: false,
      comment: '',
      searchName: '',
      searchTerm: '',
      selectedTab: '',
      isEditMode: true,
      choosenHolidayType: '',
      snackbarText: '',
      snackbarVisible: false,
      selectedVacationType: '',
      clickedVacationID: '',
      clickedPerson: [],
      personVacation: [],
      holidayHistory: {},
      holidaysData: [],
      holidayTypes: [],
      vacationTypes: [],
      notApprovedVacationData: [],
      clickedPersonVacationId: '',
      clickedPersonVacationData: [],
      clickedPendingPerson: null,
      confirmationInfo: [],
      allPersons: [],
      cities: [],
      vacationsData: [],
      failedAvatars: [],
      vacationTotalDays: '',
      personFullName: '',
      imgSrc: '',
      selectedPersonData: [],
      newHoliday: {
        city: '1',
        name: '',
      },
      statusTypeVisiblity: '',
      holidayType: '',
      countryId: '1',
      holidays: {},
      dateRange: {
        fromDate: 0,
        toDate: 0,
      },
      shouldUpdateVacationTeams: false,
      holidaysOfThisMonth: [],
      mapedHolidaysData: [],
      submitButtonFlag: false,
      filterDate: {
        from: null,
        to: null,
      },
      exportVisibility:
        this.$store.getters.hasRole('HR') ||
        this.$store.getters.hasRole('Admin') ||
        this.$store.getters.hasRole('Super Admin'),
    }
  },
  watch: {
    selectedPerson: function (personID) {
      this.selectedPerson = personID
      if (personID) {
        this.getAvatar(personID)

        axios.get(`Person/GetPersons?Person=${personID}`).then((resp) => {
          this.selectedPersonData = resp.data.Value.Persons
        })
      }
    },
    calendarYear: function (to, from) {
      this.getHolidays(this.holidayType, this.calendarYear, to)
    },
    holidayType: function () {
      let toWatch = this.holidayType
      let currentYear = new Date().getFullYear()
      let countryId = this.countryId
      this.holidays = {}
      this.getHolidays(toWatch, currentYear, countryId)
    },
    countryId: function () {
      let toWatch = this.holidayType
      let currentYear = new Date().getFullYear()
      let countryId = this.countryId
      this.holidays = {}
      this.getHolidays(toWatch, currentYear, countryId)
    },
    popupVisible: function () {
      if (this.popupVisible == false) {
        this.holidays = {}
      }
    },

    holidaysData: function (to, from) {
      this.mapedHolidaysData = to.map((el) => {
        return {
          date: new Date(el.FromDate),
          title: el.Name,
          editIcon: el.editIcon,
          newTitle: el.newTitle,
          holidayId: el.ID,
        }
      })

      this.holidaysOfThisMonth = this.mapedHolidaysData.filter((el) => {
        return (
          el.date.getMonth() + 1 == this.selectedMonth &&
          el.date.getFullYear() == this.calendarYear
        )
      })
    },
    selectedMonth: function () {
      this.holidaysOfThisMonth = this.mapedHolidaysData.filter((el) => {
        return (
          el.date.getMonth() + 1 == this.selectedMonth &&
          el.date.getFullYear() == this.calendarYear
        )
      })
    },
    clickedPersonVacationData: function (to, from) {
      if (Object.entries(from).length > 0) {
        from.displayX = false
      }
    },
  },
  computed: {
    isAdminOrHr() {
      return (
        this.$store.getters.hasRole('HR') ||
        this.$store.getters.hasRole('Admin') ||
        this.$store.getters.hasRole('Super Admin')
      )
    },
    isManager() {
      return this.$store.getters.hasRole('Manager')
    },
    columns() {
      return [
        {
          title: 'From Date',
          key: 'FromDate',
          customTemplate: (item, val) => getDateString(val, 'dd MMM yyyy'),
        },
        {
          title: 'To Date',
          key: 'ToDate',
          customTemplate: (item, val) => getDateString(val, 'dd MMM yyyy'),
        },
        {
          title: 'Days',
          key: 'TotalDays',
        },
        {
          title: 'Vacation Type',
          key: 'VacationType',
          customTemplate: (item, val) => {
            if (val == 1) {
              return 'Paid'
            } else if (val == 2) {
              return 'Not Paid'
            } else if (val == 3) {
              return 'Day Off'
            } else if (val == 4) {
              return 'Sick Days'
            } else {
              return ''
            }
          },
        },
        {
          title: 'Last Vacation',
          key: 'LastVacationDate',
          customTemplate: (item, val) =>
            val ? this.getDurationString(val, item.FromDate) : '-',
        },
        {
          title: 'Status',
          Key: 'Status',
          customTemplate: (item) => {
            if (item.Approved == true) {
              return 'Approved'
            } else if (item.Approved == false) {
              return 'Rejected'
            } else {
              return 'Pending'
            }
          },
        },
      ]
    },
    regularHolidayType() {
      let result = []
      let id = ''
      result = this.holidayTypes.filter((el) => el.Name == 'Regular')
      id = result[0].ID
      return id
    },
    filteredList() {
      if (!this.vacationsData) return
      // let result = [];

      // result = this.vacationsData.filter((person) => {
      //   if (
      //     this.clickedPersonVacationId &&
      //     this.clickedPersonVacationId != person.VacationID
      //   )
      //     return false;

      //   var fullName = person.FirstName + " " + person.LastName;
      //   return fullName.toLowerCase().includes(this.searchName.toLowerCase());
      // });

      return this.vacationsData
    },
    popUptitle() {
      let title =
        this.clickedPerson.FirstName +
        ' ' +
        this.clickedPerson.LastName +
        ' ' +
        'Requests'
      return title
    },
    typeOptions() {
      return this.holidayTypes.map((el) => ({
        title: el.Name,
        value: el.ID,
      }))
    },
    cityOptions() {
      return this.cities.map((el) => ({
        title: el.Name,
        value: el.CountryID,
      }))
    },
    holidayDates() {
      let result = []

      this.holidaysData.map((holiday) => {
        let date = getDateString(holiday.FromDate, 'yyyy-MM-dd')
        result.push(date)
      })

      for (const date in this.holidays) {
        result.push(date)
      }
      return result
    },
    personOptions() {
      return this.allPersons.map((el) => ({
        title: el.FirstName + ' ' + el.LastName,
        value: el.ID,
      }))
    },
    vacationTypeOptions() {
      return this.vacationTypes
        .filter((el) => el.ID !== 6)
        .map((el) => {
          return {
            title: el.Name,
            value: el.ID,
          }
        })
    },
    groupByDep() {
      var deps = {}

      this.filteredList.forEach((user) => {
        if (deps.hasOwnProperty(user.DepartmentName)) {
          deps[user.DepartmentName].push(user)
        } else {
          deps[user.DepartmentName] = []
          deps[user.DepartmentName].push(user)
        }
      })

      return deps
    },
    isAdmin() {
      return this.$store.getters.isAdmin || this.$store.getters.isSuperAdmin
    },
    isHR() {
      return this.$store.getters.hasRole('HR')
    },
    tabTitles() {
      if (this.isAdmin || this.isHR || this.$store.getters.isSuperAdmin)
        return ['Requests', 'Team’s Vacation Plan', 'Terms']
      return ['Requests', 'Team’s Vacation Plan']
    },
  },

  methods: {
    vacationTypeText(val) {
      let result
      if (val == 1) {
        result = 'Paid'
      } else if (val == 2) {
        result = 'Not Paid'
      } else if (val == 3) {
        result = 'Day Off'
      } else if (val == 4) {
        result = 'Sick Days'
      } else if (val == 5) {
        result = 'Maternity Leave'
      } else {
        result = ''
      }
      return result
    },

    searchPerson(event) {
      clearTimeout(this.timeout)
      this.scrollTop = false
      this.clickedPendingPerson = null
      this.timeout = setTimeout(() => {
        this.searchTerm = event.target.value
        this.page = 1
        this.scrollTop = true
        this.getVacations(this.filtered, true)
        this.getPendingVacations()
      }, 500)
    },

    statusVisibleHandler(person) {
      axios
        .get(`vacations/GetVacationsStatusInfo?VacationID=${person.VacationID}`)

        .then((resp) => {
          this.statusTypes = resp.data.Value
          this.$set(person, 'statusTypes', this.statusTypes)
          this.statusTypes.forEach((el) => {
            if (el.Title === 'Approve') this.$set(el, 'color', '#18cc9f')
            if (el.Title === 'Reject') this.$set(el, 'color', '#d06779')
            if (el.Title === 'Pending') this.$set(el, 'color', '#9696b9')
          })
        })
    },
    getDataOnScroll() {
      this.page += 1
      this.getVacations(this.filtered)
    },

    getFilteredData() {
      this.scrollTop = false
      this.page = 1
      this.maxPage = this.page
      this.vacationsData = []
      this.filtered = !this.filtered
      this.scrollTop = true
      this.clickedPendingPerson = null

      this.getVacations(this.filtered, true)
      this.getPendingVacations()
    },
    GetVacationApprovedBy(el) {
      axios
        .get(`vacations/GetVacationApprovedBy?Vacation=${el.VacationID}`)
        .then((resp) => {
          this.clickedVacationID = el.VacationID

          el.confirmationInfoVisible = true
          this.$set(el, 'confInfo', resp.data.Value)
          this.confirmationInfo = resp.data.Value
        })
    },
    formatDate(date) {
      return getDateString(date, 'dd MMM yyyy')
    },
    getDateString(date, format = 'yyyy-MM-dd') {
      if (!(date instanceof Date)) {
        date = new Date(date)
      }
      if (date === 'Invalid Date') return

      const MONTHS = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

      var days = ('0' + date.getDate()).slice(-2),
        month = ('0' + (date.getMonth() + 1)).slice(-2),
        year = date.getFullYear(),
        hours = ('0' + date.getHours()).slice(-2),
        minutes = ('0' + date.getMinutes()).slice(-2),
        seconds = ('0' + date.getSeconds()).slice(-2)

      return format
        .replace('dd', days)
        .replace('MMM', MONTHS[parseInt(month) - 1])
        .replace('MM', month)
        .replace('yyyy', year)
        .replace('yy', String(year).slice(-2))
        .replace('HH', hours)
        .replace('mm', minutes)
        .replace('ss', seconds)
        .replace('ww', WEEKDAYS[date.getDay()])
    },
    getAvatarSrc(id) {
      return this.$store.state.getAvatar + id
    },

    getAvatar(personID) {
      axios.get(`/Person/GetPersonAvatar2?ID=${personID}`).then((resp) => {
        this.imgSrc = `/Person/GetPersonAvatar2?ID=${personID}`
      })
    },
    updateVacationTeamsPage() {
      this.shouldUpdateVacationTeams = true
    },

    getVacationTypes() {
      axios.get('/vacations/Types').then((resp) => {
        this.vacationTypes = resp.data.Value
      })
    },
    getPersons() {
      axios.get('/Person/GetPersonsUnderStructure').then((resp) => {
        this.allPersons = resp.data.Value
      })
    },
    getSelectedPerson(personID) {
      this.selectedPerson = personID
      if (this.selectedPerson != '') {
        axios.get(`Person/GetPersons?Person=${personID}`).then((resp) => {
          this.selectedPersonData = resp.data.Value.Persons
        })
      }
    },
    getHolidayTypes() {
      axios.get('/vacations/GetHolidaysTypes').then((resp) => {
        this.holidayTypes = resp.data.Value
        let getRegularHolidayId = resp.data.Value.filter(
          (el) => el.Name == 'Regular',
        )
        this.holidayType = getRegularHolidayId[0].ID
      })
    },
    getCities() {
      axios.get('/Company/GetCities').then((resp) => {
        this.cities = resp.data.Value
      })
    },
    getHolidays(type, currentYear, countryId) {
      currentYear = this.calendarYear
      type = this.holidayType
      countryId = this.countryId
      if (this.countryId && this.holidayType && this.calendarYear) {
        axios
          .get(
            `vacations/GetHolidays?CountryID=${countryId}&Type=${type}&Year=${currentYear}`,
          )
          .then((resp) => {
            this.holidaysData = resp.data.Value
            this.holidaysData.forEach((holiday) => {
              this.$set(holiday, 'editIcon', false)
              this.$set(holiday, 'newTitle', '')
            })
          })
      }
    },

    async getVacations(filter, withoutPaging) {
      const resp = await axios.get('vacations/Vacations', {
        params: {
          FromDate: this.filterDate.from
            ? getDateString(this.filterDate.from)
            : null,
          ToDate: this.filterDate.to ? getDateString(this.filterDate.to) : null,
          Filter: filter,
          Page: this.page,
          PageSize: 20,
          SearchTerm: this.searchTerm,
          PersonID: this.clickedPendingPerson,
        },
      })

      if (withoutPaging) {
        this.vacationsData = resp.data.Value.Value
      } else {
        this.vacationsData.push(...resp.data.Value.Value)
      }
      this.maxPage = resp.data.Value.PagingData.TotalPageCount
      this.vacationsData.forEach((el) => {
        this.$set(el, 'commentVisible', false)
      })
      const result = []
      // this.notApprovedVacationData = result

      this.vacationsData.map((el, index) => {
        if (el.Approved == null) {
          result.push(el)
          return result
        }
      })
    },
    getPendingVacations() {
      axios
        .get('Vacations/PendingVacations', {
          params: {
            FromDate: this.filterDate.from
              ? getDateString(this.filterDate.from)
              : null,
            ToDate: this.filterDate.to
              ? getDateString(this.filterDate.to)
              : null,
            Filter: this.filtered,
            SearchTerm: this.searchTerm,
          },
        })
        .then((resp) => {
          this.notApprovedVacationData = resp.data.Value
          this.notApprovedVacationData.forEach((el) => {
            this.$set(el, 'displayX', false)
          })
        })
    },
    getPersonVacationInfo(id) {
      id = this.clickedPerson.PersonID

      axios
        .get(`vacations/GetPersonsVacations?PersonID=${id}`)
        .then((response) => {
          this.personVacation = response.data.Value
          this.personVacation.forEach((el) => {
            this.$set(el, 'confirmationInfoVisible', false)
          })
        })
    },
    getVacationsTotalDays(person, type, fromDate, toDate) {
      person = this.selectedPerson
      type = this.selectedVacationType
      fromDate = getDateString(this.dateRange.fromDate)
      toDate = getDateString(this.dateRange.toDate)

      if (
        this.selectedPerson &&
        this.selectedVacationType &&
        this.dateRange.fromDate &&
        this.dateRange.toDate
      ) {
        axios
          .get(
            `vacations/GetPersonsVacationsTotalDays?PersonID=${person}&VacationType=${type}&FromDate=${fromDate}&ToDate=${toDate}`,
          )
          .then((resp) => {
            if (resp.data.Value[0].TotalDays == 0) {
              this.vacationTotalDays = ''
            } else {
              this.vacationTotalDays = resp.data.Value[0].TotalDays
            }
          })
      }

      return this.vacationTotalDays
    },

    // P O S T       R E Q U E S T S///////////////////////////////////
    approveVacation(choosenStatus, person) {
      axios
        .post('vacations/ApproveVacation', {
          VacationID: person.VacationID,
          Approve: choosenStatus.Value,
          comment: this.comment,
        })
        .then((resp) => {
          if (resp.data.ErrorMsg) return

          person.Approved = choosenStatus.Value

          this.comment = ''
          this.snackbarVisible = true
          this.snackbarPurpose = 'success'
          this.snackbarText = 'Status has been updated successfully'
          if (choosenStatus.Value === true) {
            person.ApprovesNum = person.ApprovesNum + 1
            person.RejectsNum = 0
          } else if (choosenStatus.Value === false) {
            person.ApprovesNum = 0
            person.RejectsNum = 1
          } else if (choosenStatus.Value === null) {
            person.ApprovesNum = 0
            person.RejectsNum = 0
          }
          this.updateVacationTeamsPage()
        })
    },
    updateHoliday(el) {
      axios
        .post('vacations/UpdateHoliday', {
          HolidayID: el.holidayId,
          Delete: false,
          Name: el.newTitle,
        })
        .then((resp) => {
          this.getHolidays()
        })
    },
    deleteHoliday(el) {
      axios
        .post('vacations/UpdateHoliday', {
          HolidayID: el.holidayId,
          Delete: true,
        })
        .then((resp) => {
          this.getHolidays()
        })
    },
    deleteRequest(person) {
      this.$confirm('Are you sure you want to delete comment?').then(() => {
        axios
          .post('vacations/UpdateVacation', {
            VacationID: person.VacationID,
            Deleted: true,
          })
          .then((resp) => {
            if (resp.data.ErrorMsg) return
            this.vacationsData = this.vacationsData.filter(
              (el) => el.VacationID !== person.VacationID,
            )
            this.getPendingVacations()
            // this.getVacations()
          })
      })
    },

    createHoliday() {
      const mapedHoliday = []
      for (let x in this.holidays) {
        mapedHoliday.push({
          FromDate: this.holidays[x].fullDate,
          ToDate: this.holidays[x].fullDate,
          Name: this.holidays[x].title,
        })
      }

      axios
        .post('vacations/CreateHoliday', {
          CountryID: this.countryId,
          Type: this.holidayType,
          HolidaysTVP: mapedHoliday,
        })
        .then((response) => {
          if (!response.data.ErrorMsg) {
            this.getHolidays()
            this.snackbarVisible = true
            this.snackbarPurpose = 'success'
            this.snackbarText = 'Holiday Saved successfully.!'
            this.requestPopupVisible = false
            this.popupVisible = false
          }
        })
    },
    createVacation() {
      this.submitButtonFlag = false
      axios
        .post('vacations/CreateVacation', {
          Person: this.selectedPerson,
          VacationType: this.selectedVacationType,
          FromDate:
            this.dateRange.fromDate === 0 ? '' : this.dateRange.fromDate,
          ToDate: this.dateRange.toDate === 0 ? '' : this.dateRange.toDate,
        })
        .then((response) => {
          if (!response.data.ErrorMsg) {
            this.getVacations(this.filtered, true)
            this.getPendingVacations()
            this.updateVacationTeamsPage()
            this.snackbarVisible = true
            this.snackbarPurpose = 'success'
            this.snackbarText = 'Success!'
            this.requestPopupVisible = false
          }
        })
    },

    // ////////////////////////////
    addDate(date) {
      this.$set(this.holidays, date.date, {
        title: '',
        edit: true,
        activeIcon: false,
        fullDate: Date.parse(date.fulldate),
      })
    },
    removeDate(date) {
      this.$delete(this.holidays, date.date)
    },
    editClickHandler() {
      var x
      for (x in this.holidays) {
        this.holidays[x].edit = true
      }
    },
    selectTab(tab) {
      this.selectedTab = tab
    },

    inputBlurHandler() {
      var x
      for (x in this.holidays) {
        if (this.holidays[x].title != '') this.holidays[x].edit = false
      }
    },
    requestStoryVisible(person) {
      this.requestStoryPopup = true
      this.clickedPerson = person
      if (this.clickedPerson) {
        this.getPersonVacationInfo()
      }
    },
    commentHandler(value) {
      this.comment = value
    },
    cancelComment() {
      this.comment = ''
    },
    monthIndex(month, year) {
      this.selectedMonth = month
      this.calendarYear = year
    },
    getDurationString(end, start) {
      var duration = Math.abs(start - end)
      var fullDays = parseInt(duration / (24 * 3600000))
      var days = fullDays % 30
      var months = parseInt(fullDays / 30)
      var res = ''
      if (months) {
        if (res) res += ', '
        res += `${months}month`
      }
      if (days) {
        if (res) res += ' & '
        res += `${days}days`
      }
      return res
    },
    getClickedPerson(person, targetId) {
      this.scrollTop = false
      this.page = 1
      this.scrollTop = true
      this.clickedPersonVacationId = targetId
      this.clickedPersonVacationData = person
      this.clickedPendingPerson = person.ID
      this.getVacations(this.filtered, true)
      // setTimeout(() => {
      // }, 1000)

      person.displayX = true
    },
    Xclicked(person) {
      person.displayX = false
      this.scrollTop = false
      this.clickedPersonVacationId = null
      this.clickedPendingPerson = null
      this.page = 1
      this.scrollTop = true
      this.getVacations(this.filtered, true)
    },
    checkClickHandler(el) {
      el.editIcon = false
      this.updateHoliday(el)
    },
    cancelClickHandler(el) {
      el.editIcon = false
    },
    excelReport() {
      axios
        .get('vacations/GetVacationsReportForHR', {
          params: {
            From:
              this.filterDate.from && this.filterDate.from !== null
                ? getDateString(this.filterDate.from, 'MM/dd/yyyy')
                : null,
            To:
              this.filterDate.to && this.filterDate.to !== null
                ? getDateString(this.filterDate.to, 'MM/dd/yyyy')
                : null,
          },
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            const srcLink = resp.request.responseURL
            const downloadLink = document.createElement('a')
            downloadLink.href = srcLink
            downloadLink.click()
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-checkbox {
  display: flex;
  align-items: center;
  color: $blue-primary;
  margin-right: 1.5625rem;
  cursor: pointer;
  span {
    margin-right: 5px;
  }
}
.custom-select-wrapper {
  width: 200px;
  margin-bottom: 15px;
}
.background {
  overflow: hidden;
}
.vacation-admin-content {
  height: 100%;
}
.show {
  max-height: 500px !important;
  transition: 0.4s ease-in;
}
.person-requests-popup {
  .body {
    height: 85%;
    overflow: auto;
  }
  .header {
    color: $blue-primary;
    font-weight: 400;
    margin-bottom: 20px;
    span:nth-child(1) {
      margin-right: 15px;
    }
    span {
      margin-right: 80px;
    }
  }
  .row {
    color: $blue-primary;
    font-weight: 300;

    font-size: 15px;
    .status-content {
      display: inline-block;
      .wrapper {
        display: flex;
        align-items: center;
        .material-icons {
          width: auto;
          margin-left: 10px;
          color: rgba($blue-primary, 0.3);
          font-size: 25px;
          cursor: pointer;
        }
      }
    }
    .material-icons {
      margin-left: auto;
      cursor: pointer;
      opacity: 0.5;
    }

    .confirmation-info {
      max-height: 0;
      overflow: hidden;
      transition: 0.4s ease-in;
      display: flex;
      // align-items: flex-end;
      padding-left: 30px;
      flex-direction: column;
      background-color: #fefeff;

      // background-color: black;
      .persons-content:last-child {
        margin-bottom: 20px;
      }
      .person {
        display: flex;
        margin-top: 8px;
        span {
          margin-right: 22px;
          font-size: 13px;

          &:not(.comment) {
            flex-shrink: 0;
            white-space: nowrap;
          }
          &:nth-child(1) {
            width: 130px;
            display: inline-block;
            margin-right: 50px;
          }
          &:nth-child(2) {
            width: 62px;
            display: inline-block;
          }
          &:last-child {
            margin: 0;
          }
        }
        .comment {
          padding-left: 75px;
          padding-right: 10px;
        }
      }
    }

    ::v-deep .confirmation-content {
      padding-top: 0;
    }
    .background-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      height: 30px;
      span {
        display: inline-block;
      }
      > span:nth-child(1) {
        margin-right: 15px;
        width: 17px;
        color: rgba($blue-primary, 0.5);
      }

      > span:nth-child(2) {
        // margin-right: 130px;
        width: 215px;
      }
      > span:nth-child(3) {
        width: 217px;
      }
      span:nth-child(4) {
        width: 117px;
      }
      span:nth-child(5) {
        width: 177px;
      }
      span:nth-child(6) {
        width: 148px;
      }
    }
  }
}
.vacation-request .total-days {
  color: $blue-primary;
  font-weight: 300;
  div {
    color: #9696b9;
    font-weight: 400;
  }
}
button {
  width: 174px;
  height: 50px;
  background: rgba($blue-primary, 0.6);
  color: #ffffff;
  font-size: 16px;
  float: right;
  margin-top: 25px;

  border-radius: 3px;
  cursor: pointer;
}
.avatar {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.holidays-container {
  height: 328 * $rem;
  overflow: scroll;
}
.container {
  display: flex;
  > div {
    margin-left: 40px;
  }
  .holiday-dates-content {
    span {
      color: $blue-primary;
    }
    input {
      display: block;
      border: 0;

      outline: none;
      color: $blue-primary;
      font-weight: 300;
      width: 257px;
      font-size: 16px;
      &::placeholder {
        color: $blue-primary;
        font-size: 15px;
      }
    }
    .date-content {
      margin-bottom: 9px;
      display: flex;
      align-items: center;
      .actions {
        cursor: pointer;
        display: flex;
        align-items: center;

        .delete-icon {
          img {
            height: 30px;
          }
        }
        .material-icons-content {
          display: flex;
        }
      }
    }
    .input-content {
      display: flex;
      align-items: flex-end;
    }
  }
}
.btn {
  background: rgba(40, 53, 147, 0.5);
  color: #ffffff;
  height: 40px;
  width: 100px;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
  float: right;
}

.date {
  font-size: 15px;
}
.edit-icon {
  height: 30px;
  margin-left: 20px;
}
.edit-span {
  cursor: pointer;
}
::v-deep .overlay .popup {
  width: 61.25rem;
  overflow: auto;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: $green-primary;
  border-radius: 100%;
  margin-right: 15px;
  opacity: 0.6;
}
.value {
  color: $blue-primary;
  width: 20rem;
  cursor: pointer;
  font-weight: 300;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.request-vacation-button {
  width: 174 * $rem;
  height: 2.3125rem;
  background: rgba($blue-primary, 0.6);
  border-radius: 0.1875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    color: #ffffff;
    font-size: 16px;
  }
}
.request-vacation-container {
  display: flex;
  .person-content {
    display: flex;
    margin-left: 80px;
    flex-direction: column;
    align-items: center;
    .avatar {
      width: 122px;
      height: 122px;
      border-radius: 100%;
      background-color: #ebebf2;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  form {
    width: 450px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .total-days {
    width: 200px;
    color: $blue-primary;
    font-weight: 400;
  }
  .custom-select-wrapper {
    width: 200px;
    margin-bottom: 121px;
  }
  .circle {
    width: 122px;
    height: 122px;
    border-radius: 100%;
    background-color: #ebebf2;
  }
}
.person-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  .person-name {
    color: $blue-primary;
    font-size: 16px;
    margin-top: 30px;
  }
  .job-name {
    font-size: 15px;
    color: #9696b9;
    margin-top: 10px;
    max-width: 180px;
  }
}
::v-deep .container {
  background-color: #ffffff;
}
.vacation-header-content {
  display: flex;
  justify-content: space-between;
  padding-top: 30 * $rem;
  padding-left: 85 * $rem;
  padding-right: 85 * $rem;

  .vacation-header-rightside {
    display: flex;
    .request-vacation-button {
      margin-left: 25 * $rem;
    }
  }
  .add-button-content {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $blue-primary;
    font-size: 14px;
    span {
      margin-left: 10px;
    }
  }
}
.recent-requests-title {
  @include flex(flex-start, center, row);

  .titles {
    span {
      color: $blue-primary;
      font-size: 20px;
      margin-right: 10 * $rem;
    }
  }

  .export {
    margin-left: 1.5625rem;
    @include flex(flex-start, center, row);
    .export_report {
      width: 7.5rem;
      height: 2.5rem;
      @include centerElement;
      margin-left: 0.9375rem;
      background: rgba($blue-primary, 0.6);
      border-radius: 0.375rem;
      cursor: pointer;
      span {
        user-select: none;
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
.recent-requests-content {
  padding: 40px 80px 0 80px;
  white-space: nowrap;
  margin-bottom: 40 * $rem;
  height: 125px;

  &::-webkit-scrollbar {
    height: 5px;
  }
}
.request-person-content {
  display: inline-block;
  width: 266 * $rem !important;
  margin-right: 2rem;
  &:last-child {
    .border {
      display: none;
    }
  }
  .request-person {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    .border {
      height: 35px;
      width: 1px;
      margin-left: auto;
      background-color: #9696b9;
    }
    .material-icons {
      position: absolute;
      color: #ff2828;
      top: 2px;
      left: -4px;
    }

    .request-person-info {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
    }

    .circle {
      width: 88px;
      height: 88px;
      border-radius: 100%;
      background-color: #ffffff;
    }
    .person-info-name {
      color: $blue-primary;
      font-size: 16px;
      font-weight: 300;
    }
    .job-name {
      font-size: 13px;
      color: #9696b9;
      margin-top: 5px;
      max-width: 150px;
      overflow: hidden;
      white-space: normal;
    }
  }
}
.hooper {
  outline: none;
  position: relative;
}
::v-deep .hooper-list {
  overflow: hidden;
}

::v-deep .hooper-liveregion.hooper-sr-only {
  display: none;
}
::v-deep .hooper-navigation {
  .hooper-prev {
    position: absolute;
    bottom: 40%;
    left: -50px;
    background-color: #ededfc;
    opacity: 0.3;
    cursor: pointer;
  }
  .hooper-next {
    position: absolute;
    right: -50px;
    bottom: 40%;
    background-color: #ededfc;
    opacity: 0.3;
    cursor: pointer;
  }
}
::v-deep label {
  width: 207 * $rem;
}
.search-form {
  margin-left: 25 * $rem;
  input {
    width: 178px;
    height: 38px;

    outline: none;
    border: 0;
    color: $blue-primary;
    border: 1px solid #dedff4;
    padding-left: 5px;
    font-size: 16px;
    border-radius: 3px;
    &::placeholder {
      color: #9696b9;
      font-size: 14px;
    }
  }
}
</style>
