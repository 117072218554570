<template>
  <div class="permission-section">
    <span class="selected-count">Selected {{ selectedPermissions.size }}</span>
    <div class="selected-container" v-show="selectedPermissions.size">
      <selected-permission
        v-for="selected in selectedPermissions"
        :key="selected"
        :name="getName(selected)"
        @click.native="remove(selected)"
      />
    </div>
    <div class="permission-container">
      <template v-for="(group, index) in groupedPermissions">
        <div class="group" v-if="group.length" :key="index">
          <template>
            <div class="columns">
              <permission-checkbox
                v-for="permission in group"
                :key="permission.ID"
                :number="permission.ID"
                :name="permission.Name"
                @click.native="
                  !permission.ParentNode && selectPermission(permission.ID)
                "
                :value="selectedPermissions.has(permission.ID)"
                :disabled="!!permission.ParentNode"
                :parentNodeName="getParentNodeName(permission)"
              />
            </div>
            <div
              class="accept-all"
              :class="{ reject: allAccepted(group) }"
              @click="acceptRejectAllClickHandler(group)"
              v-if="group.some((el) => !el.ParentNode)"
            >
              {{ allAccepted(group) ? "Decline All" : "Accept All To Node" }}
            </div>
          </template>
        </div>
      </template>
    </div>
    <custom-popup
      title="Create Node & Add Permissions"
      v-show="popupVisible"
      @close="$emit('close-popup')"
    >
      <custom-input v-model="newNodeName" title="Node Name" />
      <div class="popup__selected-container">
        <selected-permission
          v-for="selected in selectedPermissions"
          :key="selected"
          :name="getName(selected)"
          @click.native="remove(selected)"
        />
      </div>
      <div class="save" @click="addNode">Save</div>
    </custom-popup>
  </div>
</template>

<script>
import PermissionCheckbox from "../PermissionCheckbox";
import SelectedPermission from "../SelectedPermission";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup.vue";
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput.vue";
import { showSuccess } from "@/Helpers/Messages";
import axios from "@/Helpers/AxiosInstance";

export default {
  components: {
    PermissionCheckbox,
    SelectedPermission,
    CustomPopup,
    CustomInput,
  },
  props: {
    permissionsList: Array,
    searchTerm: String,
    popupVisible: Boolean,
    nodePopup: Boolean,
    nodes: Array,
    nodeToEdit: Object,
  },
  data() {
    return {
      selectedPermissions: new Set([]),
      newNodeName: "",
    };
  },
  methods: {
    getParentNodeName(permission) {
      var parentID = permission.ParentNode;
      if (!parentID) return "";
      const node = this.nodes.filter((n) => n.ID == parentID)[0];
      return node ? node.Name : "";
    },
    selectPermission(id) {
      if (this.selectedPermissions.has(id)) {
        this.selectedPermissions.delete(id);
      } else {
        this.selectedPermissions.add(id);
      }
      this.selectedPermissions = new Set(this.selectedPermissions);
    },
    getName(id) {
      return this.permissionsList.filter((el) => el.ID == id)[0].Name;
    },
    async remove(id) {
      this.selectedPermissions.delete(id);
      this.selectedPermissions = new Set(this.selectedPermissions);
      if (this.nodeToEdit) {
        await this.removeParentNode(id);
        this.$emit("get-permissions");
      }
    },
    add(id) {
      this.selectedPermissions.add(id);
      this.selectedPermissions = new Set(this.selectedPermissions);
    },
    allAccepted(group) {
      return group
        .filter((el) => !el.ParentNode)
        .every((p) => this.selectedPermissions.has(p.ID));
    },
    acceptRejectAllClickHandler(group) {
      if (this.allAccepted(group))
        group
          .filter((el) => !el.ParentNode)
          .forEach(async (p) => {
            await this.remove(p.ID);
          });
      else group.filter((el) => !el.ParentNode).forEach((p) => this.add(p.ID));
    },
    async removeParentNode(id) {
      if (!this.nodeToEdit) return;
      await axios.post("Permission/UpdatePermission", {
        Parent: 0,
        PermissionsTVP: [
          {
            Permission: id,
            IsActive: true,
          },
        ],
      });
    },
    updatePermission() {
      if (!this.nodeToEdit) return;
      axios
        .post("Permission/UpdatePermissionNode", {
          Node: this.nodeToEdit.ID,
          Name: this.newNodeName,
          IsActive: true,
        })
        .then(() => {
          axios
            .post("Permission/UpdatePermission", {
              Parent: this.nodeToEdit.ID,
              PermissionsTVP: [...this.selectedPermissions].map((id) => ({
                Permission: id,
                IsActive: true,
              })),
            })
            .then(() => {
              showSuccess("Node Saved Successfully");
              this.selectedPermissions = new Set([]);
              this.$emit("close-popup");
              this.newNodeName = "";
              this.$emit("get-permissions");
              this.$emit("close-edit-node");
            });
        });
    },
    addNode() {
      if (!this.nodeToEdit) {
        axios
          .post("Permission/CreatePermissionNode", {
            Name: this.newNodeName,
          })
          .then((resp) => {
            if (resp.data.ErrorMsg) return;
            let ID = resp.data.Value[0].ID;
            axios
              .post("Permission/UpdatePermission", {
                Parent: ID,
                PermissionsTVP: [...this.selectedPermissions].map((id) => ({
                  Permission: id,
                  IsActive: true,
                })),
              })
              .then(() => {
                showSuccess("Node Created Successfully");
                this.selectedPermissions = new Set([]);
                this.$emit("close-popup");
                this.newNodeName = "";
                this.$emit("get-permissions");
              });
          });
      } else {
        this.updatePermission();
      }
    },
  },
  computed: {
    filteredList() {
      if (!this.searchTerm) return this.permissionsList;
      return this.permissionsList.filter((el) =>
        el.Name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    groupedPermissions() {
      var alreadyGroupedPermissions = [];
      const keywords = [
        "permission",
        "role",
        "job",
        "schedule",
        "department",
        "office",
        "team",
        "vacation",
        "attendance",
        "salar",
        "person",
        "contact",
        "news",
        "monitoring",
        "password",
        "aavatar",
        "document",
        "poll",
        "holiday",
      ];
      let copyFilteredList = [...this.filteredList];
      var grouped = [];
      keywords.forEach((keyword) => {
        let group = copyFilteredList.filter(
          (p) =>
            p.Name.toLowerCase().includes(keyword) &&
            !alreadyGroupedPermissions.includes(p.ID)
        );
        grouped.push(group);
        group.forEach((el) => alreadyGroupedPermissions.push(el.ID));
      });
      grouped.push(
        copyFilteredList.filter(
          (p) => !alreadyGroupedPermissions.includes(p.ID)
        )
      );
      return grouped;
    },
  },
  watch: {
    nodeToEdit: {
      handler(node) {
        if (!node) return;

        this.selectedPermissions = new Set(node.Permissions.map((el) => el.ID));
        this.newNodeName = node.Name;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.permission-section {
  display: flex;
  flex-direction: column;
  margin-top: 22 * $rem;
  height: calc(100% - 62 * #{$rem});

  .selected-count {
    color: $blue-primary;
    font-size: 16 * $rem;
    margin-bottom: 18 * $rem;
  }

  .selected-container {
    display: flex;
    max-height: 200 * $rem;
    flex-direction: column;
    flex-wrap: wrap;
    height: 170 * $rem;
    align-content: flex-start;
    overflow: auto;

    .selected-permission {
      width: 220 * $rem;
      margin-top: 11 * $rem;
      flex-shrink: 0;
      margin-right: 11 * $rem;
    }
  }

  .permission-container {
    height: 100%;
    overflow: auto;
    padding-top: 35 * $rem;
    padding-left: 35 * $rem;
    padding-right: 35 * $rem;
    background-color: #fff;
    border-radius: 10 * $rem;
    padding-bottom: 50 * $rem;

    .group {
      margin-top: 30 * $rem;
      display: flex;
      align-items: center;

      &:first-child {
        margin-top: 0;
      }

      .columns {
        display: flex;
        flex-wrap: wrap;
        margin-top: -10 * $rem;
        width: calc(100% - 175 * #{$rem});

        .permission-checkbox {
          width: calc(100% / 4 - 15 * #{$rem});
          margin-right: 10 * $rem;
          margin-top: 10 * $rem;
        }
      }

      .accept-all {
        border-radius: 6 * $rem;
        border: $rem solid rgba($blue-primary, 0.7);
        width: 170 * $rem;
        height: 35 * $rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba($blue-primary, 0.7);
        cursor: pointer;

        &.reject {
          color: #ff5f58;
          border-color: #ff5f58;
        }
      }
    }
  }

  ::v-deep .popup {
    width: 1010 * $rem;
  }

  .popup__selected-container {
    display: flex;
    flex-wrap: wrap;
    height: 300 * $rem;
    overflow: auto;
    align-content: flex-start;
    margin-top: 20 * $rem;

    .selected-permission {
      width: calc(100% / 4 - 40 * #{$rem});
      margin-top: 20 * $rem;
      margin-right: 50 * $rem;

      &:nth-child(4n) {
        margin-right: 10 * $rem;
      }
    }
  }

  .save {
    height: 40 * $rem;
    width: 100 * $rem;
    border-radius: 3 * $rem;
    font-size: 16 * $rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($blue-primary, 0.6);
    color: white;
    margin-left: auto;
    margin-top: 10 * $rem;
  }
}
</style>

