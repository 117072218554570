<template>
  <div class="background">
    <AttendanceReport :userPage="true" />
  </div>
</template>

<script>
import AttendanceReport from "@/components/AttendanceReport/AttendanceReport.vue";
export default {
  components: {
    AttendanceReport,
  },
};
</script>

<style>
</style>