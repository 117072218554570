<template>
  <div class="welcome-text-and-avatar">
    <div class="image-wrapper">
      <img
        :src="imageSrc"
        v-show="!showFallbackImage"
        @error="showFallbackImage = true"
        @load="loadHandler"
        :class="{
          'more-width': moreWidthThanHeight,
          'more-height': !moreWidthThanHeight,
        }"
      />
      <img
        v-show="showFallbackImage"
        src="@/assets/images/choose-image.png"
        alt=""
      />
    </div>
    <div
      class="vertical"
      :class="{ 'show-welcome': personID == $store.state.profile.ID }"
    >
      <h3>Hi {{ firstName }},</h3>
      <h1>Welcome Back!</h1>
      <div
        class="btn reset-password"
        v-if="
          (personID && $store.getters.isAdmin) ||
          (personID && $store.getters.isSuperAdmin) ||
          (personID && $store.getters.hasRole('HR'))
        "
        @click="resetPassword"
      >
        Reset Password
      </div>
      <div
        class="change-password btn"
        @click="changePasswordForm.visible = true"
        v-if="personID && personID == $store.state.profile.ID"
      >
        Change Password
      </div>
      <label class="edit-avatar">
        <i class="material-icons">camera_alt</i>
        <input
          type="file"
          style="visibility: hidden; width: 0"
          accept="image/*"
          ref="avatar"
          @change="avatarChange"
        />
      </label>
    </div>
    <custom-popup
      title="Change Password"
      :visible="changePasswordForm.visible"
      :flexible-size="true"
      @close="changePasswordForm.visible = false"
    >
      <form class="change-password-form" @submit.prevent="changePassword">
        <custom-input
          title="Current Password"
          v-model="changePasswordForm.currentPassword"
          input-type="password"
          :is-required="true"
          :triggerChangeOnBlur="false"
        />
        <div class="wrapper">
          <custom-input
            title="New Password"
            v-model="changePasswordForm.newPassword"
            input-type="password"
            :is-required="true"
            :triggerChangeOnBlur="false"
          />
          <ul class="validation-terms">
            <li
              :class="{
                success: validationTerms.passlength,
                matchError: validationErrors.length,
              }"
            >
              Password length must be greater than 4 characters
            </li>
            <li
              :class="{
                success: validationTerms.cases,
                matchError: validationErrors.caseError,
              }"
            >
              Must contain minimum 1 lowercase & 1 uppercase, or number, or
              symbol
            </li>
            <li class="matchError" v-if="validationTerms.match">
              New password & repeat doesn't match
            </li>
          </ul>
        </div>
        <custom-input
          title="Repeat"
          v-model="changePasswordForm.repeat"
          input-type="password"
          :is-required="true"
          :triggerChangeOnBlur="false"
        />
        <button class="btn save">Save</button>
      </form>
    </custom-popup>
  </div>
</template>

<script>
import { toBase64 } from "@/Helpers/HelperFunctions";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput";
import axios from "@/Helpers/AxiosInstance";
import { showError, showSuccess } from "@/Helpers/Messages";
export default {
  components: { CustomPopup, CustomInput },
  props: {
    personID: {
      type: [Number, String],
    },
  },
  created() {
    if (this.personID)
      this.imageSrc = this.$store.state.getAvatar + this.personID;
  },
  data() {
    return {
      imageSrc: "",
      showFallbackImage: false,
      moreWidthThanHeight: true,
      changePasswordForm: {
        visible: false,
        currentPassword: "",
        newPassword: "",
        repeat: "",
      },
      validationTerms: {
        passlength: false,
        cases: false,
        match: false,
        regex: /^(?=.*?[A-Z\d\W])(?=(.*[a-z]){1,})(?!.*\s).{4,}/,
      },
      validationErrors: {
        length: false,
        caseError: false,
      },
    };
  },
  watch: {
    changePasswordForm: {
      handler(val, newVal, repeatVal) {
        val = this.changePasswordForm.currentPassword;
        newVal = this.changePasswordForm.newPassword;
        repeatVal = this.changePasswordForm.repeat;

        this.validationErrors.caseError = false;
        this.validationErrors.length = false;

        if (newVal.match(this.validationTerms.regex)) {
          this.validationTerms.cases = true;
        }

        if (!newVal.match(this.validationTerms.regex))
          this.validationTerms.cases = false;

        if (newVal.length > 4) this.validationTerms.passlength = true;

        if (newVal.length < 5) {
          this.validationTerms.passlength = false;
        }

        if (newVal == repeatVal) this.validationTerms.match = false;
      },
      deep: true,
    },
  },
  methods: {
    async resetPassword() {
      await this.$confirm("Would you like to send a new password to user?");
      axios
        .get("authorization/ResetPassword", {
          params: {
            Person: this.personID,
          },
        })
        .then((resp) => {
          if (resp.data.ErrorMsg) return;
          showSuccess("The new password has been sent!");
        });
    },
    changePassword(newVal, repeatVal) {
      newVal = this.changePasswordForm.newPassword;
      repeatVal = this.changePasswordForm.repeat;

      if (
        this.changePasswordForm.newPassword != this.changePasswordForm.repeat
      ) {
        this.validationTerms.match = true;

        if (!newVal.match(this.validationTerms.regex)) {
          this.validationErrors.caseError = true;
        }

        if (newVal.length <= 4) {
          this.validationErrors.length = true;
          return;
        }
        return;
      }
      axios
        .post("authorization/ChangePassword", {
          ID: this.personID,
          OldPassword: this.changePasswordForm.currentPassword,
          NewPassword: this.changePasswordForm.newPassword,
        })
        .then((resp) => {
          if (resp.data.ErrorMsg) return;
          this.changePasswordForm.visible = false;
          this.changePasswordForm.currentPassword = "";
          this.changePasswordForm.newPassword = "";
          this.changePasswordForm.repeat = "";
          showSuccess("Password has been successfully changed!");
        });
    },
    avatarChange(evt) {
      var tgt = evt.target || window.event.srcElement,
        files = tgt.files;

      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = () => {
          this.imageSrc = fr.result;
          this.showFallbackImage = false;
        };
        fr.readAsDataURL(files[0]);
      }

      toBase64(files[0]).then((file) => this.$emit("change", file));
    },

    loadHandler(e) {
      var width = e.target.naturalWidth;
      var height = e.target.naturalHeight;
      if (width > height) this.moreWidthThanHeight = true;
      else this.moreWidthThanHeight = false;
    },
  },
  computed: {
    firstName() {
      return (
        this.$store.state.profile.FirstName.charAt(0).toUpperCase() +
        this.$store.state.profile.FirstName.slice(1)
      );
    },
  },
};
</script>

<style scoped lang="scss">
.welcome-text-and-avatar {
  display: flex;

  .image-wrapper {
    width: 175 * $rem;
    height: 175 * $rem;
    border-radius: 50%;
    background-color: #fff;
    overflow: hidden;
    margin-right: 55px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      &.more-width {
        width: 100%;
        height: auto;
      }

      &.more-height {
        width: auto;
        height: 100%;
      }
    }
  }

  .vertical {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative !important;

    h3 {
      margin-top: 5 * $rem;
      font-size: 25 * $rem;
      font-weight: 400;
      display: none;
      color: $blue-primary;
    }

    h1 {
      margin-top: 4 * $rem;
      font-size: 32 * $rem;
      font-weight: 700;
      display: none;
      color: $blue-primary;
    }

    .change-password,
    .reset-password {
      display: flex;
      border: $rem solid $blue-primary;
      border-radius: 3 * $rem;
      align-items: center;
      justify-content: center;
      height: 27 * $rem;
      font-size: 14 * $rem;
      width: 143 * $rem;
      color: $blue-primary;
      cursor: pointer;
      margin-top: 5 * $rem;
    }

    .reset-password {
      margin-top: 1.7625rem;
    }

    .edit-avatar {
      margin-top: 10 * $rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      i {
        color: $green-primary;
        background-color: rgba(#8a91c7, 0.2);
        border-radius: 50%;
        padding: 9 * $rem;
        padding: 0.5625rem;
        margin-right: 0.6875rem;
        font-size: 1.55rem;
        position: absolute;
        left: -6.6875rem;
        top: 8.3125rem;
      }

      span {
        font-size: 15 * $rem;
        font-weight: 400;
        color: $blue-primary;
      }
    }

    &.show-welcome {
      justify-content: flex-start;

      h1,
      h3 {
        display: block;
      }

      .edit-avatar {
        margin-top: 10 * $rem;
      }
    }
  }

  .change-password-form {
    label {
      margin-right: 10 * $rem;
      margin-top: 1.25rem;
    }

    .wrapper {
      display: flex;
      ul {
        margin-top: 1.7375rem;
        margin-left: 1.5625rem;
        position: absolute;
        left: 269px;
        li {
          color: rgba($blue-primary, 0.5);
          font-size: 12px;

          &.matchError {
            color: red;
          }

          &.success {
            color: limegreen;
          }
        }
      }
    }
  }

  .btn.save {
    position: absolute;
    bottom: 35px;
    right: 69px;
    cursor: pointer;
  }
}

::v-deep .overlay .popup.flexible {
  width: 44rem;
  height: 22rem;
  display: flex;
  flex-direction: column;
  padding: 2.5625rem 3.6875rem;
  position: relative;
  .title {
    margin-bottom: unset;
  }
}

::v-deep form label input {
  border: 1px solid rgba($blue-primary, 0.5);
}
</style>
