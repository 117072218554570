<template>
  <div class="profile">
    <template>
      <router-view></router-view>
      <profile-salaries-list />
    </template>
  </div>
</template>

<script>
import ProfileSalariesList from "../ProfileSalaries/ProfileSalariesList";

export default {
  components: {
    ProfileSalariesList,
    // ProfileSalariesFactor,
  },
  created() {},
  data() {
    return {
      salaryId: "",
    };
  },
  computed: {},
  methods: {
    changeComponent(val) {
      this.salaryId = val;
      this.selectedSalaryTab = 1;
    },
  },
};
</script>

<style lang="scss">
.profile {
  display: inline;

  & > .overlay {
    .popup {
      padding: 0;

      .title {
        display: none;
      }
    }
    .welcome {
      width: 1200 * $rem;
      height: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("~@/assets/images/welcome.png");
      position: relative;
      padding-top: 53.2%;
    }
  }
}
</style>
