<template>
    <section class="background">
        <div class="top">
            <div class="search-jobs">
                <input type="text" v-model="filters.searchJob" placeholder="Search" />
                <img class="search-icon" src="@/assets/icons/search.svg" alt="" />
            </div>
        </div>
        <div class="table-wrapper" @scroll="callMoreData">
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Open Position</th>
                        <th>QTY</th>
                        <th>Hire Deadline</th>
                        <th>Structural Unit</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(career,ind) in filteredData" :key="ind" :class="{'applied':career.AlreadyApplied}">
                        <td> {{ind + 1}} </td>
                        <td class="job-name">
                            <div>
                                {{
                                    career.JobName ? career.JobName : '-'
                                }}
                            </div>
                            <div v-if="career.JobName" class="job-title-tooltip"> {{career.JobName}} </div>
                        </td>
                        <td> {{career.Quantity ? career.Quantity : '-'}} </td>
                        <td> {{convertDateToString(career.DeadLine)}} </td>
                        <td class="dep-titles">
                            <div> {{career.DepartmentName ? career.DepartmentName : '-'}} </div>
                            <div v-if="career.DepartmentName" class="dep-title-tooltip">
                                {{career.DepartmentName ? career.DepartmentName : ''}} </div>
                        </td>
                        <td>
                            <button :class="['apply-btn',{'disabled': career.AlreadyApplied}]"
                                @click="getPositionDetails(career.OpenPositionID)">
                                Apply
                            </button>
                        </td>
                    </tr>
                </tbody>
                <div v-if="filteredData.length < 1" class="alternative-message">
                    <h1>There is no Records!</h1>
                </div>
            </table>
        </div>
        <custom-snackbar v-if="snackbar.visible" :purpose="snackbar.purpose" :text="snackbar.text"
            @close="snackbar.visible = false" />

        <custom-popup 
            :visible="positionDetailsVisible"
            @close="(positionDetailsVisible = false,currentPositionID = null,langs = allLangs)" 
            title="Apply for"
            class="position-details-popup">
            <language-switch 
                :langs="this.langs"
                @selected="(lang) => this.getPositionDetails(this.currentPositionID,lang.LanguageID)" 
            />

            <div class="header">
                <div class="job-name">
                    {{
                        this.currentDetailsLang !== 2 ? Position.jobPositionTranslate : Position.positionName
                    }}
                </div>
                <div class="deadline">
                    <span> Hire deadline </span>
                    <span>
                        {{convertDateToString(Position.deadline)}}
                    </span>
                </div>
            </div>
            <div class="details-content">
                <div class="details-wrapper">
                    <div class="single-detail">
                        <div class="left-side">{{ $t("forCompanyOrOfice") }}</div>
                        <div class="right-side">
                            {{Position.company}}/{{Position.office}}
                        </div>
                    </div>
                    <div class="single-detail">
                        <div class="left-side">{{$t("jobInDep")}}</div>
                        <div class="right-side">
                            {{
                                Position.jobPosition ? Position.jobPosition : '-'
                            }}
                        </div>
                    </div>
                    <div class="single-detail">
                        <div class="left-side">{{ $t("positionQuantity") }}</div>
                        <div class="right-side">
                            <div class="quantity" v-if="Position.quantity">
                                {{Position.quantity}}
                            </div>
                            <div v-else>
                                <span> - </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="details-wrapper">
                    <div class="single-detail">
                        <div class="left-side">{{ $t("contractType") }}</div>
                        <div class="right-side">
                            {{                                
                                this.currentDetailsLang !== 2 ? Position.contractTypeTranslated : Position.contractType ? Position.contractType : '-'
                            }}
                        </div>
                    </div>
                    <div class="single-detail">
                        <div class="left-side">{{ $t("emplStatus") }}</div>
                        <div class="right-side">
                            {{
                                this.currentDetailsLang !== 2 ? Position.statusTranslated : Position.status ? Position.status : '-'
                            }}
                        </div>
                    </div>
                    <div class="single-detail">
                        <div class="left-side">{{ $t("salaryRangeForTest") }}</div>
                        <div class="right-side">
                            {{Position.testPeriodSalaryRange  ? Position.testPeriodSalaryRange : '-'}}
                        </div>
                    </div>
                    <div class="single-detail">
                        <div class="left-side">{{ $t("salaryRangeForAfterTest") }}</div>
                        <div class="right-side">
                            {{Position.afterTestPeriodSalaryRange ? Position.afterTestPeriodSalaryRange : '-'}}
                        </div>
                    </div>
                </div>
                <div class="details-wrapper">
                    <div class="single-detail">
                        <div class="left-side">{{ $t("Education") }}</div>
                        <div class="right-side" v-if="selectedPositionEducations.OpenPositionItems ? selectedPositionEducations.OpenPositionItems.length > 0 : false">
                            <div 
                                class="single-item"
                                v-for="(education,ind) in selectedPositionEducations.OpenPositionItems" :key="ind">

                                {{education.Description}}
                            </div>
                        </div>
                        <div v-else class="right-side">
                            <span> - </span>
                        </div>
                    </div>
                    <div class="single-detail">
                        <div class="left-side">{{ $t("workExp") }}</div>
                        <div class="right-side" v-if="selectedPositionExperience.OpenPositionItems ? selectedPositionExperience.OpenPositionItems.length > 0 : false">
                            <template v-for="(exp,ind) in selectedPositionExperience.OpenPositionItems">
                                <div class="single-item" :key="ind">
                                    {{exp.Description}}
                                </div>
                            </template>
                        </div>
                        <div v-else class="right-side">
                            <span> - </span>
                        </div>
                    </div>
                    <div class="single-detail">
                        <div class="left-side">{{ $t("requiredKnowledge") }}</div>
                        <div class="right-side" v-if="selectedPositionSkills.required ? selectedPositionSkills.required.length > 0 : false">
                            <template v-for="(skill,ind) in selectedPositionSkills.required">
                                <div class="single-skill" :key="ind">
                                    {{skill.Description}}
                                </div>
                            </template>
                        </div>
                        <div v-else class="right-side">
                            <span> - </span>
                        </div>
                    </div>
                    <div class="single-detail">
                        <div class="left-side">{{ $t("preferredKnowledge") }}</div>
                        <div class="right-side" v-if="selectedPositionSkills.preferred ? selectedPositionSkills.preferred.length > 0 : false">
                            <template v-for="(skill,ind) in selectedPositionSkills.preferred">
                                <div class="single-skill" :key="ind">
                                    {{skill.Description}}
                                </div>
                            </template>
                        </div>
                        <div v-else class="right-side">
                            <span>-</span>
                        </div>
                    </div>
                    <div class="single-detail">
                        <div class="left-side">{{ $t("responsibilities") }}</div>
                        <div class="right-side" v-if="selectedPositionResponsibilities.OpenPositionItems ? selectedPositionResponsibilities.OpenPositionItems.length > 0 : false">
                            <div class="single-item"
                                v-for="(responsibility, ind) in selectedPositionResponsibilities.OpenPositionItems"
                                :key="ind">
                                {{responsibility.Description}}
                            </div>
                        </div>
                        <div v-else class="right-side">
                            <span> - </span>
                        </div>
                    </div>
                    <div class="single-detail">
                        <div class="left-side">{{ $t("skills") }}</div>
                        <div class="right-side" v-if="selectedPositionSkills.common ? selectedPositionSkills.common.length > 0 : false">
                            <template v-for="(skill,ind) in selectedPositionSkills.common">
                                <div class="single-skill" :key="ind">
                                    {{skill.Description}}
                                </div>
                            </template>
                        </div>
                        <div v-else class="right-side">
                            <span> - </span>
                        </div>
                    </div>
                    <div class="single-detail">
                        <div class="left-side">{{ $t("weOffer") }}</div>
                        <div class="right-side" v-if="Position.weOffer ? Position.weOffer.length > 0 : false">
                            <div class="single-item" v-for="(item,ind) in Position.weOffer" :key="ind">
                                {{item.Description}}
                            </div>
                        </div>
                        <div v-else class="right-side">
                            <span> - </span>
                        </div>
                    </div>
                </div>
                <div class="details-wrapper">
                    <div class="single-detail">
                        <div class="left-side"> {{ $t("workingSchedule") }}</div>
                        <div class="right-side">
                            {{Position.schedule ? Position.schedule : '-'}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="apply-wrapper">
                <button
                    @click="(positionDetailsVisible = false,applyFormVisible = true,applyForm.positionID = currentPositionID,getAppliedInfo(currentPositionID,Position.alreadyApplied))"
                    class="apply-position">Apply</button>
            </div>
        </custom-popup>

        <custom-popup :visible="applyFormVisible" @close="(applyFormVisible = false, resetApplyDetails())"
            title="Apply for" class="position-apply-popup">
            <custom-loader v-if="loaderVisible"/>
            <div class="header">
                <div class="job-name">{{Position.positionName}}</div>
                <div class="deadline">
                    <span> Hire deadline </span>
                    <span>
                        {{convertDateToString(Position.deadline)}}
                    </span>
                </div>
            </div>

            <div :class="['form-wrapper',{'disabled': loaderVisible}]">
                <form>
                    <ul>
                        <li>
                            <custom-input title="Name Surname" v-model="applyForm.nameLastname" :isRequired="true" :readOnly="Position.alreadyApplied ? true : false"/>
                        </li>
                        <li class="input-wrapper">
                            <custom-input title="code" v-model="applyForm.code" :isRequired="true" :readOnly="Position.alreadyApplied ? true : false" />
                            <custom-input title="Mobile Number" v-model="applyForm.mobile" :isRequired="true" inputType="number" :readOnly="Position.alreadyApplied ? true : false" />
                        </li>
                        <li>
                            <custom-input title="Email" v-model="applyForm.email" :isRequired="true" :readOnly="Position.alreadyApplied ? true : false"
                                class="country-code" />
                        </li>
                        <li>
                            <custom-input title="LinkedIn URL" v-model="applyForm.linkedin" :isRequired="false" :readOnly="Position.alreadyApplied ? true : false"
                                class="country-code" />
                        </li>
                        <li>
                            <custom-input title="Portfolio URL" v-model="applyForm.portfolio" :isRequired="false" :readOnly="Position.alreadyApplied ? true : false"
                                class="country-code" />
                        </li>
                        <li v-if="!Position.alreadyApplied" class="upload-resume">
                            <div class="additional-btn-wrapper">
                                <custom-file-input title="RESUME *" :value="file" @change="changeFile"
                                    :isRequired="true" />
                                <img :class="{inserted: file.filename,uploaderLogo:!file.filename}"
                                    src="@/assets/icons/upload.svg" alt="">
                            </div>
                        </li>
                        <li v-else class="upload-resume view-resume">
                            <div class="additional-btn-wrapper"  @click="downloadFile()">
                                <custom-file-input title="Download" :value="file" @change="changeFile"
                                    :isRequired="true" :disabled="true" />
                                <img :class="{inserted: file.filename,uploaderLogo:!file.filename}"
                                    src="@/assets/icons/upload.svg" alt="">
                            </div>
                        </li>
                        <li class="select-wrapper">
                            <custom-input title="Required Salary" v-model="applyForm.salary" :isRequired="false" :readOnly="Position.alreadyApplied ? true : false"
                                class="country-code" inputType="number" />
                            <custom-select title="Currency" :options="currency" v-model="applyForm.currency"
                                class="custom-select" />
                        </li>

                        <li class="message-wrapper">
                            <custom-text-area title="Your Message" v-model="applyForm.message" :isRequired="false" :readOnly="Position.alreadyApplied ? true : false" /> 
                        </li>

                        <li class="sendApply">
                            <input type="submit" @click.prevent="applyForPosition()" :disabled="Position.alreadyApplied"
                                :class="{'disabled': Position.alreadyApplied}"
                                :value="Position.alreadyApplied ? 'Already Applied' : 'Apply'">
                        </li>

                    </ul>
                </form>
            </div>

        </custom-popup>

    </section>
</template>

<script>
    import axios from "@/Helpers/AxiosInstance";
    import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
    import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup"
    import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput"
    import CustomFileInput from "@/components/ReusableComponents/CustomFileInput/CustomFileInput";
    import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue"
    import CustomTextArea from "@/components/ReusableComponents/CustomTextArea/CustomTextArea.vue"
    import LanguageSwitch from "@/components/ReusableComponents/LanguageSwitch/LanguageSwitch.vue"
    import CustomLoader from "@/components/ReusableComponents/Loader/CustomLoader.vue"


    export default {
        components: {
            CustomSnackbar,
            CustomPopup,
            CustomInput,
            CustomFileInput,
            CustomSelect,
            CustomTextArea,
            LanguageSwitch,
            CustomLoader
        },
        created() {
            this.getCurrentUserData()
            this.getCareerData(1, true)
            this.getLangs()
            this.getSource()
            this.getCurrencyTypes()

        },
        data() {
            return {
                careerData: [],
                filteredData: [],
                page: 1,
                pageSize: 15,
                maxPages: null,
                locale: this.$i18n.locale,
                filters: {
                    searchJob: ''
                },
                snackbar: {
                    text: "",
                    purpose: "",
                    visible: false,
                },
                currentPositionID: null,
                applyFormVisible: false,
                positionDetailsVisible: false,
                langs: [],
                allLangs: [],
                currentDetailsLang: null,
                selectedPosition: [],
                selectedPositionSkills: {
                    required: [],
                    preferred: [],
                    common: [],
                },
                selectedPositionEducations: [],
                selectedPositionResponsibilities: [],
                selectedPositionExperience: [],
                alreadyAppliedList: [],
                Position: {
                    positionName: '',
                    jobPosition: '',
                    jobPositionTranslate: '',
                    quantity: '',
                    contractType: '',
                    contractTypeTranslated: '',
                    office: '',
                    company: '',
                    status: '',
                    statusTranslated: '',
                    deadLine: null,
                    testPeriodSalaryVisible: null,
                    afterTestPeriodSalaryVisible: null,
                    testPeriodSalaryRange: '',
                    afterTestPeriodSalaryRange: '',
                    weOffer: [],
                    schedule: '',
                    // education: '',
                    alreadyApplied: false,

                },
                file: {},
                currency: [],
                applyForm: {
                    nameLastname: '',
                    mobile: '',
                    code: '+995',
                    email: '',
                    linkedin: '',
                    portfolio: '',
                    currency: '',
                    salary: '',
                    message: '',
                    positionID: null,
                    sourceID: null,
                },
                defaultLangID: 2,
                loaderVisible: false,
            }
        },
        i18n: {
            silentTranslationWarn: true,
            messages: {
                ge: {
                    title: "ვაკანსიის მოთხოვნის ფორმა",
                    positionName: "ვაკანსიის/პოზიციის დასახელება",
                    forCompanyOrOfice: "კომპანია/ოფისი (დამკვეთი)",
                    jobInDep: "ვაკანტური პოზიცია სტრუქტურულ ერთეულში",
                    positionQuantity: "რაოდენობა",
                    addOption: "დაამატე ობცია",
                    hireDeadline: "კადრის აყვანის სასურველი დრო (დედლაინი)",
                    Education: "განათლება",
                    workExp: "სამუშაო გამოცდილება",
                    requiredKnowledge: "აუცილებელი ცოდნა",
                    preferredKnowledge: "უპირატესობად ჩაითვლება",
                    skills: "აუცილებელი უნარები",
                    visibility: "ხილვადობა",
                    responsibilities: "ფუნქცია-მოვალეობები",
                    contractType: "ხელშეკრულების ტიპი",
                    emplStatus: "დასაქმების სტატუსი",
                    reasonRequest: "ვაკანსიის მოთხოვნის მიზეზი",
                    newPosition: "ახალი პოზიციის შექმნა",
                    additionalFunctions: "ფუნქციების დამატება",
                    volumeOfWork: "სამუშაოს მოცულობის გაზრდა",
                    other: "არსებული თანამშრომლის წასვლა",
                    salaryRangeForTest: " ხელფასი გამოსაცდელ პერიოდში",
                    salaryRangeForAfterTest: "ხელფასი გამოსაცდელი პერიოდის შემდეგ",
                    workingSchedule: "სამუშაო დღეები/საათები",
                    weOffer: "ბენეფიტები",
                    weekDays: {
                        Mon: "ორშ",
                        Tue: "სამშ",
                        Wed: "ოთხ",
                        Thu: "ხუთ",
                        Fri: "პარ",
                        Sat: "შაბ",
                        Sun: "კვი",
                    },
                    weeklyXHr: "კვირაში x საათი",
                },
                en: {
                    title: "Request Job Position",
                    addOption: "Add option",
                    positionName: "Position Name",
                    visibility: "Visibility",
                    forCompanyOrOfice: "For Company/Office",
                    jobInDep: "Job Position in",
                    positionQuantity: "Position Quantity",
                    hireDeadline: "Hire Deadline",
                    Education: "Education",
                    workExp: "Work experience",
                    requiredKnowledge: "Required Knowledge",
                    preferredKnowledge: "Preferred Knowledge",
                    skills: "Skills",
                    responsibilities: "Responsibilities",
                    contractType: "Contract type",
                    emplStatus: "Employment status",
                    reasonRequest: "Reason of request",
                    newPosition: "New Position",
                    additionalFunctions: "Additional functions",
                    volumeOfWork: "Oncreased volume of work",
                    other: "Other",
                    salaryRangeForTest: "Net salary range for the test period",
                    salaryRangeForAfterTest: "Net salary range after test period",
                    workingSchedule: "Working schedule",
                    weOffer: "We offer",
                    weekDays: {
                        Mon: "Mon",
                        Tue: "Tue",
                        Wed: "Wed",
                        Thu: "Thu",
                        Fri: "Fri",
                        Sat: "Sat",
                        Sun: "Sun",
                    },
                    weeklyXHr: "Weekly x hr",
                },
                tr: {
                    title: "Açık iş başvuru formu",
                    positionName: "Pozisyon adı",
                    visibility: "Görünürlük",
                    forCompanyOrOfice: "Şirket/ofis için",
                    jobInDep: "Departmandaki iş pozisyonu ",
                    positionQuantity: "Pozisyon miktarı",
                    hireDeadline: "İstenen İşe Alma Süresi (Son Tarih)",
                    Education: "Eğitim",
                    workExp: "İş deneyimi",
                    requiredKnowledge: "Gerekli bilgi",
                    preferredKnowledge: "Tercih edilen bilgi",
                    skills: "Gerekli beceriler",
                    responsibilities: "Fonksiyon-görevler",
                    addOption: "Seçenek Ekle",
                    contractType: "Sözleşme türünü seçin",
                    emplStatus: "Çalışma durumu",
                    reasonRequest: "Boş yer talep etme nedeni",
                    newPosition: "Yeni pozisyon",
                    additionalFunctions: "İlave fonksiyonlar",
                    volumeOfWork: "Artan iş hacmi",
                    other: "Diğer",
                    salaryRangeForTest: "Test dönemi için net maaş aralığı seçin",
                    salaryRangeForAfterTest: "Test döneminden sonra net maaş aralığını seçin",
                    workingSchedule: "Çalışma takvimi",
                    weOffer: "Sunuyoruz",
                    weekDays: {
                        Mon: "Pzt",
                        Tue: "Sal",
                        Wed: "Çrş",
                        Thu: "Per",
                        Fri: "Cuma",
                        Sat: "Cumartesi",
                        Sun: "Pazar",
                    },
                    weeklyXHr: "Haftalık x saat",
                },
                de: {
                    title: "Stellenangebot beantragen",
                    addOption: "Option hinzufügen",
                    positionName: "Stellenname",
                    forCompanyOrOfice: "Unternehmen / Büro",
                    jobInDep: "Arbeitsplatz in der Abteilung",
                    positionQuantity: "Anzahl der Positionen",
                    hireDeadline: "Anstellungsfrist",
                    Education: "Ausbildung",
                    workExp: "Berufserfahrung",
                    requiredKnowledge: "Erforderliche Kenntnisse",
                    preferredKnowledge: "Bevorzugte Kenntnisse",
                    skills: "Fähigkeiten",
                    responsibilities: "Verantwortlichkeit",
                    contractType: "Vertragstyp wählen",
                    emplStatus: "Beschäftigungsstatus",
                    reasonRequest: "Grund der Anfrage",
                    newPosition: "Neue Stelle",
                    additionalFunctions: "Zusätzliche Funktionen",
                    volumeOfWork: "Erhöhtes Arbeitsaufkommen",
                    other: "Sonstiges",
                    salaryRangeForTest: "Wählen Sie den Nettogehaltsbereich für den Testzeitraum",
                    salaryRangeForAfterTest: "Wählen Sie den Bereich des Nettogehalts nach dem Testzeitraum",
                    workingSchedule: "Arbeitsplan",
                    weOffer: "Wir bieten",
                    weekDays: {
                        Mon: "Mo",
                        Tue: "Die",
                        Wed: "Mi",
                        Thu: "Do",
                        Fri: "Fr",
                        Sat: "Sa",
                        Sun: "So",
                    },
                    weeklyXHr: "Wöchentlich x Stunden",
                },
                ru: {
                    title: "Форма запроса на вакансию",
                    addOption: "добавить вариант",
                    positionName: "Название должности/позиции",
                    forCompanyOrOfice: "Компания/офис (работодатель)",
                    jobInDep: "Вакантная должность в структурном подразделении, отделе",
                    positionQuantity: "Количество должностей",
                    hireDeadline: "Желаемое время приема",
                    Education: "Образование",
                    workExp: "Опыт работы",
                    requiredKnowledge: "Необходимые знания",
                    preferredKnowledge: "Предпочтительные знания",
                    skills: "Необходимые навыки",
                    visibility: "видимость",
                    responsibilities: "Функции-обязанности",
                    contractType: "Выберите тип контракта",
                    emplStatus: "Статус занятости",
                    reasonRequest: "Причина запроса вакансии",
                    newPosition: "Новая позиция",
                    additionalFunctions: "Дополнительные функции",
                    volumeOfWork: "Увеличенный объем работы",
                    other: "Другое / Уход действующего сотрудника",
                    salaryRangeForTest: "диапазон чистой заработной платы за тестовый период",
                    salaryRangeForAfterTest: "диапазон чистой заработной платы после тестового периода",
                    workingSchedule: "Рабочее расписание",
                    weOffer: "Мы предлагаем",
                    weekDays: {
                        Mon: "Пн",
                        Tue: "Вт",
                        Wed: "Ср",
                        Thu: "Чт",
                        Fri: "Пт",
                        Sat: "Сб",
                        Sun: "Вос",
                    },
                    weeklyXHr: "Еженедельно х час",
                },
            },
        },
        watch: {
            filters: {
                handler() {
                    let searchValue = this.filters.searchJob
                    let trimmed = searchValue.trim().toLowerCase().split(' ').join('')
                    if (trimmed.length) {
                        let filteredData = this.careerData.filter(el => el.JobName.toLowerCase().split(' ').join('')
                            .includes(trimmed))
                        this.filteredData = filteredData
                    } else {
                        this.filteredData = this.careerData
                    }
                },

                deep: true,
            },
            locale(val) {
                this.$i18n.locale = val;
            },
        },
        methods: {
            getCareerData(page, firstCall) {
                axios.get('openposition/GetOpenPositionsGeneralInfoInternal', {
                        params: {
                            Page: page || this.page,
                            PageSize: this.pageSize,
                        }
                    })
                    .then((resp) => {
                        this.maxPages = resp.data.Paging.TotalPageCount
                        if (firstCall) {
                            this.careerData = resp.data.Value
                            this.filteredData = this.careerData
                        } else {
                            resp.data.Value.forEach(obj => {
                                this.careerData.push(obj)
                                this.filteredData = this.careerData
                            })
                        }

                        this.alreadyAppliedList = []

                        this.careerData.forEach(el => {
                            if (el.AlreadyApplied) {
                                this.alreadyAppliedList.push(el.OpenPositionID)
                            }
                        });
                    })
            },
            getAppliedInfo(positionID,applied){
                axios.get('openposition/GetOpenPositionAppliedInfo',{
                    params:{
                        OpenPositionID:positionID
                    }
                }).then(resp => {
                    let data = resp.data.Value[0]
                    if(applied){
                        this.applyForm.linkedin = data.LinkedInUrl
                        this.applyForm.portfolio = data.Portfolio
                        this.applyForm.currency = data.CurrencyID
                        this.applyForm.salary = data.RequiredSalary
                        this.applyForm.message = data.Message
                        this.file['base64'] = data.File 
                        this.file['type'] = data.FileType 
                    }
                })
            },
            downloadFile(){
                const linkSource = `data:${this.file.type};base64,${this.file.base64}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = 'Resume';
                downloadLink.click();
            },
            getSource() {
                axios.get('openposition/GetOpenPositionCreationSources')
                    .then(resp => {
                        let source = resp.data.Value
                        this.applyForm.sourceID = source[0].SourceID
                    })
            },
            applyForPosition() {
                if (!this.applyForm.mobile.length || !this.file.base64) {
                    this.snackbar.visible = true
                    this.snackbar.purpose = "error"
                    this.snackbar.text = 'Please Fill In Required Fields'
                    return
                } else {
                    this.loaderVisible = true
                    axios.post('openposition/ApplyToNewJobPositionFromIntranet', {
                        openPositionID: this.applyForm.positionID,
                        phoneNum: `${this.applyForm.code} ${this.applyForm.mobile}`,
                        mail: this.applyForm.email ? this.applyForm.email : null,
                        linkedInUrl: this.applyForm.linkedin ? this.applyForm.linkedin : null,
                        portfolio: this.applyForm.portfolio ? this.applyForm.portfolio : null,
                        requiredSalary: this.convertSringToInt(this.applyForm.salary),
                        currency: this.applyForm.currency,
                        message: this.applyForm.message ? this.applyForm.message : null,
                        source: this.applyForm.sourceID,
                        resumeFileBase64: this.file.base64,
                        resumeFileType: this.file.type,
                        resumeFileName: this.file.filename,
                    }).then((resp) => {
                        this.loaderVisible = false
                        if (!resp.data.ErrorMsg) {
                            this.snackbar.visible = true
                            this.snackbar.purpose = "success"
                            this.snackbar.text = 'Your request has been sent successfully'
                            this.resetApplyDetails()
                            this.applyFormVisible = false
                            this.getCareerData(this.page, true)
                        } else {
                            this.snackbar.visible = true;
                            this.snackbar.purpose = "error";
                            this.snackbar.text = resp.data.ErrorMsg;
                        }
                    })
                }
            },
            changeFile(file) {
                this.file = file;
            },
            getLangs() {
                axios.get("openposition/GetLanguages").then((resp) => {
                    this.langs = resp.data.Value;
                    this.langs.forEach((el) => {
                        this.$set(el, "selected", false);
                        if (el.Code == "EN") {
                            this.$set(el, "selected", true);
                        }
                    });
                    this.allLangs = this.langs
                });
            },
            convertSringToInt(value) {
                return parseInt(value)
            },
            getCurrentUserData() {
                axios.get('Person/GetPersonsDetails', {
                    params: {
                        Person: this.$store.state.profile.ID
                    }
                }).then(resp => {
                    const profileDetails = resp.data.Value
                    let name = profileDetails.FirstName
                    let lastName = profileDetails.LastName

                    let email = profileDetails.PersonContacts.filter(el => el.ContactTypeName === 'Mail')
                    let mobile = profileDetails.PersonContacts.filter(el => el.ContactTypeName === 'Phone')
                    let mobile1 = mobile[0] ? mobile[0].ContactValue : ''
                    let mobile2 = mobile[1] ? mobile[1].ContactValue : ''
                    let mail1 = email[0] ? email[0].ContactValue : ''
                    let mail2 = email[1] ? email[1].ContactValue : ''

                    // set form default data 

                    this.applyForm.email = mail1.length ? mail1 : mail2
                    this.applyForm.mobile = mobile1.length ? mobile1 : mobile2
                    this.applyForm.nameLastname = `${name} ${lastName}`
                })
            },
            convertDateToString(timestamp) {
                let t = new Date(timestamp)
                let months = [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec'
                ]
                let year = t.getFullYear()
                let month = months[t.getMonth()]
                let date = t.getDate()
                let time = `${date} ${month} ${year}`
                return time
            },
            callMoreData(ev) {
                if (this.page > (this.maxPages - 1)) return
                var el = ev.srcElement;
                if (el.scrollTop >= el.scrollHeight - el.clientHeight) {
                    this.page += 1
                    this.getCareerData()
                }
            },
            getCurrencyTypes() {
                axios.get("Company/CurrencyTypes")
                    .then(resp => {
                        let currencyTypes = resp.data.Value
                        this.currency = currencyTypes.map(curr => ({
                            title: curr.Code,
                            value: curr.ID,
                            code: curr.Code
                        }))
                    })
            },
            resetApplyDetails() {
                this.applyForm.linkedin = ''
                this.applyForm.portfolio = ''
                this.applyForm.currency = ''
                this.applyForm.salary = ''
                this.applyForm.message = ''
                this.file = {}
            },
            getPositionDetails(positionID, lang) {
                axios.get('openposition/GetOpenPositionByID', {
                    params: {
                        ID: positionID,
                        LanguageID: lang ? lang : this.defaultLangID,
                    }
                }).then((resp) => {
                    let data = resp.data.Value
                    const openposition = data.OpenPositions[0]
                    let lang = openposition.LanguageID;

                    this.currentDetailsLang = lang

                    this.currentPositionID = positionID
                    this.locale = this.langs.filter((el) => el.LanguageID === lang)[0].Code.toLowerCase();

                    let currentPositonLangIDs = data.OpenPositionLanguages.map(el => el.LanguageID)

                    this.langs.forEach((el) => {
                        if (el.LanguageID === lang) {
                            el.selected = true;
                        } else {
                            el.selected = false;
                        }
                    })

                    this.langs = this.langs.filter(el => currentPositonLangIDs.includes(el.LanguageID))

                    this.selectedPosition = openposition
                    this.selectedPositionResponsibilities = data.Responsibilities
                    this.selectedPositionExperience = data.WorkExperiences
                    this.selectedPositionEducations = data.Educations

                    let requiredKnowledge = data.OpenPositionSkills.filter(skill => skill.KnowledgeTypeID === 1)
                    let preferredKnowledge = data.OpenPositionSkills.filter(skill => skill.KnowledgeTypeID === 2)
                    let commonKnowledge = data.OpenPositionSkills.filter(skill => skill.KnowledgeTypeID === null)

                    this.selectedPositionSkills.required = requiredKnowledge
                    this.selectedPositionSkills.preferred = preferredKnowledge
                    this.selectedPositionSkills.common = commonKnowledge

                    this.Position.positionName = openposition.JobName
                    this.Position.jobPosition = openposition.DepartmentName
                    this.Position.quantity = openposition.Quantity
                    this.Position.deadline = openposition.DeadLine
                    this.Position.status = openposition.EmploymentStatusName
                    this.Position.office = openposition.OfficeName
                    this.Position.company = openposition.CompanyName
                    this.Position.contractType = openposition.ContractTypeName
                    this.Position.testPeriodSalaryRange = openposition.SalaryInTestPeriodRange
                    this.Position.testPeriodSalaryVisible = openposition.SalaryTestPeriodVisibility
                    this.Position.afterTestPeriodSalaryRange = openposition.SalaryAfterTestPeriodRange
                    // this.Position.financialIncentives = openposition.FinancialIncentives
                    this.Position.schedule = openposition.ScheduleDescription
                    this.Position.education = openposition.EducationDescription
                    this.Position.weOffer = data.FinancialIncentives.OpenPositionItems
                    
                    //translated objects
                    this.Position.jobPositionTranslate = openposition.JobNameTranslated
                    this.Position.contractTypeTranslated = openposition.ContractTypeNameTranslated
                    this.Position.statusTranslated = openposition.EmploymentStatusNameTranslated
                    
                    if (this.alreadyAppliedList.includes(positionID)) {
                        this.Position.alreadyApplied = true
                    } else {
                        this.Position.alreadyApplied = false
                    }


                })
                this.positionDetailsVisible = true
            },
        },
    }
</script>

<style scoped lang="scss">
    $tableDataColor : #283593;

    .background {
        overflow: hidden;
    }

    .top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 1.925rem;

        .search-jobs {
            width: 12.9375rem;
            height: 2.5rem;
            position: relative;
            margin-right: 4.625rem;
            margin-top: 1.625rem;

            input {
                width: 100%;
                height: 100%;
                background-color: #fff;
                border: 0;
                outline: none;
                border-radius: 0.375rem;
                font-weight: 300;
                font-size: 0.875rem;
                color: #9696b9;
                padding: 0 35px 0 15px;

                &::placeholder {
                    font-weight: 500;
                    color: #9696b9;
                    font-size: 0.8125rem;
                }
            }

            .search-icon {
                position: absolute;
                height: 1.3125rem;
                width: 0.875rem;
                top: 50%;
                right: 0.9375rem;
                transform: translate(0, -50%)
            }
        }
    }


    .position-apply-popup {
        ::v-deep .popup {
            width: 37.125rem;
            height: calc(100% - 2.3125rem);
            overflow: hidden;
            position: relative;

            h3.title {
                font-size: 1.25rem;
                color: #959BC9;
                margin-bottom: 1.125rem;
                font-weight: 500;
            }
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 0.0625rem solid rgba(149, 155, 201, 0.2);
            padding-bottom: 1.25rem;
            margin-top: 1.9rem;

            .deadline {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                span:nth-child(1) {
                    font-weight: 300;

                    color: #959bc9;
                }

                span:nth-child(2) {
                    font-size: 1.25rem;
                    font-weight: 500;
                    color: #959bc9;
                }
            }

            .job-name {
                padding: 0.75rem 1.875rem;
                color: #ffffff;
                text-transform: uppercase;
                font-weight: 900;
                background-color: #6956d6;
                border-radius: 0.1875rem;
                display: inline-block;
                max-width: 22rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            img.close {
                width: 1.875rem;
                height: 1.875rem;
                position: absolute;
                right: 0.625rem;
                top: 0.625rem;
                cursor: pointer;
            }
        }

        .form-wrapper {
            width: 100%;
            padding: 1.25rem 0;

            &.disabled{
                pointer-events: none;
                opacity: .6;
            }

            form {
                width: 100%;

                ul {
                    list-style: none;

                    li {
                        margin-top: 1.4375rem;

                        &.input-wrapper {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            ::v-deep {
                                label {
                                    &:first-child {
                                        width: 7.1875rem;
                                        padding-right: 0.9375rem;
                                    }
                                }
                            }
                        }

                        &.select-wrapper {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            ::v-deep label {
                                width: 100%;
                                padding-right: 0.9375rem;

                                input {
                                    width: 100%;
                                }
                            }

                            ::v-deep .selected-box {
                                height: 2.5rem;
                                position: relative;

                                .arrow-down,
                                .arrow-down {
                                    font-size: 1.5625rem;
                                }
                            }
                        }

                        &.message-wrapper {
                            ::v-deep textarea {
                                resize: none;
                                height: 11.25rem;
                            }
                        }

                        &.sendApply {
                            input {
                                &.disabled {
                                    width: 9rem;
                                    opacity: .5;
                                    pointer-events: none;
                                }
                            }
                        }

                        &:first-child {
                            margin-top: 0;
                        }

                        ::v-deep input {
                            width: 100%;
                            height: 2.5rem;
                        }

                        ::v-deep label {
                            width: 100%;
                        }

                        &.upload-resume {

                            &.view-resume{
                                img {
                                    &.uploaderLogo,&.inserted {
                                        transform: rotate(0) !important;
                                    }
                                }
                            }
                            .additional-btn-wrapper {
                                width: 9.075rem;
                                height: 3.125rem;
                                position: relative;

                                ::v-deep label {
                                    width: 100%;
                                    height: 100%;

                                    .select-button {
                                        width: 100%;
                                        height: 100%;
                                        background: #6956D6;
                                        display: grid;
                                        place-items: center;
                                        border: 0.0625rem solid #1DE9B6;
                                        border-radius: 0.375rem;
                                    }

                                    span {
                                        width: 6rem;
                                        color: #fff;
                                        margin-left: 1.5625rem;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        text-align: center;

                                    }
                                }

                                img {
                                    &.uploaderLogo {
                                        width: 1.25rem;
                                        position: absolute;
                                        top: 0.9rem;
                                        left: 1.6rem;
                                        transform: rotate(180deg);
                                    }

                                    &.inserted {
                                        width: 1.25rem;
                                        position: absolute;
                                        top: 0.9rem;
                                        left: 1rem;
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                        }

                        input[type="submit"] {
                            width: 5.9375rem;
                            height: 3.125rem;
                            background: #6956D6;
                            font-size: 1rem;
                            color: #fff;
                            border-radius: 0.375rem;
                            border: none;
                            outline: none;
                            float: right;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }


    .position-details-popup {
        ::v-deep .popup {
            height: calc(100% - 1.3125rem);
            // height: 33.4375rem;
            width: 69.1875rem;
            padding-top: 3.125rem;
            padding-bottom: 3.125rem;
            padding-left: 6.25rem;
            padding-right: 6.25rem;
            overflow: hidden;

            h3.title {
                text-transform: capitalize;
            }
        }

        ::v-deep .lang-switcher {
            position: absolute;
            top: 3.3rem;
            right: 6.25rem;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 0.0625rem solid rgba(149, 155, 201, 0.2);
            padding-bottom: 1.25rem;

            .deadline {
                display: flex;
                flex-direction: column;
                // justify-content: flex-start;
                align-items: flex-end;

                span:nth-child(1) {
                    font-weight: 300;

                    color: #959bc9;
                }

                span:nth-child(2) {
                    font-size: 1.25rem;
                    font-weight: 500;
                    color: #959bc9;
                }
            }

            .job-name {
                padding: 0.75rem 1.875rem;
                color: #ffffff;
                text-transform: uppercase;
                font-weight: 900;
                background-color: #6956d6;
                border-radius: 0.1875rem;
                display: inline-block;
                max-width: 40rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .details-content {
            height: calc(100% - 9.5rem);
            overflow: auto;
            width: 102%;
            padding-right: 0.3125rem;

            >.single-detail {
                margin-top: 1.875rem;

                .left-side {
                    color: #959bc9;
                    font-size: 0.875rem;
                    font-weight: 500;
                }
            }

            .details-wrapper {
                padding-top: 1.875rem;
                padding-bottom: 1.875rem;

                border-bottom: 0.0625rem solid rgba(149, 155, 201, 0.2);

                &:last-of-type {
                    border: 0;
                    padding-bottom: 0;
                }

                .single-detail {
                    display: flex;
                    margin-bottom: 0.9375rem;

                    // &:not(:last-child) {
                    // }
                    .left-side {
                        color: #959bc9;
                        font-size: 0.875rem;
                        font-weight: 500;
                        width: 21.25rem;
                        word-break: break-word;
                        margin-right: 5rem;
                    }

                    .right-side {
                        color: #3f46bf;
                        font-size: 0.9375rem;
                        font-weight: 400;
                        width: 100%;

                        .quantity {
                            padding: 0.3125rem 0.75rem;
                            background-color: #6956d6;
                            color: #fff;
                            font-weight: 500;
                            display: inline-block;
                            border-radius: 0.3125rem;
                        }

                        .single-item,
                        .single-skill {
                            display: inline-block;
                            padding: 0.3125rem;
                            font-size: 0.8125rem;
                            font-weight: 300;
                            border-radius: 0.3125rem;
                            margin-right: 0.3125rem;
                            margin-bottom: 0.3125rem;
                            border: 0.0313rem solid rgba(#959bc9, .6);
                        }

                        .single-resp:not(:last-child) {
                            margin-bottom: 0.625rem;
                        }
                    }
                }
            }
        }

        .apply-wrapper {
            width: 100%;
            padding: 0.625rem 0;
            display: flex;
            justify-content: flex-end;

            .apply-position {
                width: 5.6875rem;
                height: 2.825rem;
                font-size: 1rem;
                color: #fff;
                line-height: 1.3125rem;
                background: #6956D6;
                outline: none;
                border: none;
                cursor: pointer;
                border-radius: 0.375rem;

                &.disabled {
                    opacity: .5;
                    pointer-events: none;
                    user-select: none;
                }
            }
        }
    }

    .table-wrapper {
        width: 100%;
        height: 100%;
        overflow: scroll;
        padding-bottom: 8rem;

        table {
            width: 100%;
            text-align: left;
            margin: auto;
            border-collapse: collapse;

            tr {

                td,
                th {
                    width: 14rem;

                    &:first-child {
                        padding-left: 5rem;
                        width: 11rem !important;
                    }

                    &:last-child {
                        padding-right: 4.5rem;
                    }
                }
            }

            thead {
                tr {
                    background: #ededfc;
                    z-index: 5;
                    height: 3.125rem;
                    position: sticky;
                    top: 0;

                    th {
                        color: $tableDataColor;
                        font-size: 0.875rem;
                        font-weight: 500;
                    }
                }
            }

            tbody {
                tr {
                    height: 3.75rem;
                    position: relative;
                    transition: ease .5s;
                    border-bottom: 0.0625rem solid rgba(#6956D6, .05);

                    &:hover {
                        background: rgba(#959BC9, .15);

                        td {
                            font-weight: 500;
                        }
                    }

                    td {
                        color: $tableDataColor;
                        font-size: 0.9375rem;
                        font-weight: 400;
                        position: relative;


                        &:last-child {
                            text-align: right;
                        }

                        button {

                            &.apply-btn {
                                width: 6.5rem;
                                height: 2.6125rem;
                                outline: none;
                                background: #5963AB;
                                font-size: 1rem;
                                font-weight: 400;
                                line-height: 1.3125rem;
                                color: #FFFFFF;
                                border-radius: 0.1875rem;
                                cursor: pointer;

                                &.disabled {
                                    opacity: .5;
                                }

                            }
                        }

                        &.job-name {
                            width: 18rem;

                            div:first-child {
                                width: 17.5rem;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            .job-title-tooltip {
                                position: absolute;
                                background: #5963AB;
                                color: #ffffff;
                                max-width: 18rem;
                                min-height: 0.125rem;
                                padding: 0.3125rem 0.625rem;
                                top: 80%;
                                left: 0;
                                z-index: 2;
                                border-radius: 0.25rem;
                                display: none;
                            }

                            &:hover {
                                .job-title-tooltip {
                                    display: block;
                                }
                            }
                        }

                        &.dep-titles {
                            >div:first-child {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                max-width: 23rem;
                            }

                            >.dep-title-tooltip {
                                position: absolute;
                                background: #5963AB;
                                color: #ffffff;
                                max-width: 20rem;
                                min-height: 0.125rem;
                                padding: 0.3125rem 0.625rem;
                                top: 80%;
                                left: 0;
                                z-index: 2;
                                border-radius: 0.25rem;
                                display: none;
                            }

                            &:hover {
                                .dep-title-tooltip {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .custom-select {
        ::v-deep .selected-box {
            width: 8rem;
        }
    }

    .alternative-message{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
        h1{
            font-size: 2.1875rem;
            color: rgba($blue-primary, 0.5);
        }
    }
</style>