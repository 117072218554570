<template>
  <custom-popup
    :visible="true"
    @close="$emit('close')"
    :title="title"
    :flexibleSize="true"
  >
    <div class="row">
      <p class="text" v-html="text"></p>
    </div>
    <div class="button-row">
      <button class="btn cancel" @click="$emit('close')">Cancel</button>
      <button class="btn delete" @click="$emit('delete')">
        {{ deleteText }}
      </button>
    </div>
  </custom-popup>
</template>

<script>
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup.vue";
export default {
  components: {
    CustomPopup,
  },
  props: {
    title: {
      type: String,
      default: "Delete",
    },
    deleteText: {
      type: String,
      default: "Delete",
    },
    text: String,
  },
  data() {
    return {
      popupVisible: true,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .popup {
  min-width: 700 * $rem;
  height: initial;

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60 * $rem;

    p {
      font-size: 18 * $rem;
      color: $blue-primary;
    }

    .icon {
      color: $red;
      font-size: 46 * $rem;
    }
  }

  .button-row {
    display: flex;
    align-items: center;

    button.cancel {
      // background-color: rgb(230, 230, 230);
      color: white;
      margin-left: auto;
      margin-right: 15 * $rem;
      width: 100px;
      height: 40px;
      font-size: 16px;
      border-radius: 3px;
      cursor: pointer;
    }
    .btn.delete {
      background-color: rgba(208, 103, 121, 0.9);
      color: #ffffff;
      width: 100px;
      height: 40px;
      font-size: 16px;
      border-radius: 3px;
      cursor: pointer;
    }

    button.delete {
      background-color: $red;
    }

    button:hover {
      filter: brightness(1.05);
    }
  }
}
</style>