<template>
  <section class="traffic background">
    <div
      class="traffic_additional_header"
      v-if="!this.$store.state.showTimeLine"
    >
      <traffic-tabs />
    </div>
    <div class="traffic__header">
      <div class="traffic__title">
        Requests <span> {{ data.length }} </span>
      </div>
      <div class="traffic__filters">
        <date-range
          style="margin-right: 3px"
          :initialFrom="filters.period.from"
          :initialTo="filters.period.to"
          @from="(from) => (filters.period.from = from)"
          @to="(to) => (filters.period.to = to)"
          title="Filter By Period"
        />
        <custom-select
          title="Filter By Status"
          style="margin-bottom: 3px"
          :options="statusOptions"
          @input="(opt) => (filters.status = opt)"
          :value="filters.status"
        />

        <div class="button" v-if="data.length" style="margin-left: 15px">
          <div
            style="margin-left: 25px"
            class="custom-button custom-button--header"
            @click="approveAll"
          >
            Approve All
          </div>
        </div>
        <div class="button" v-if="data.length">
          <div
            style="margin-left: 8px"
            class="custom-button custom-button--reject custom-button--header"
            @click="rejectAll"
          >
            Reject All
          </div>
        </div>
      </div>
    </div>
    <div class="traffic__data">
      <div class="header">
        <span class="index"> </span>
        <span class="date"> Date </span>
        <span class="sender"> Sender </span>
        <span class="source"> Requested Apps & Webs </span>
        <span class="button"> </span>
        <span class="button"> </span>
      </div>
      <div class="data" v-if="loading">
        <div class="row" v-for="i in 10" :key="i">
          <span class="index loader"></span>
          <span class="date loader"></span>
          <span class="sender loader"> </span>
          <span class="source loader"> </span>
        </div>
      </div>
      <div class="data" v-else-if="data.length">
        <div class="row" v-for="(request, index) in data" :key="index">
          <span class="index"> {{ request.ID }}</span>
          <span class="date">
            {{ getDateString(request.StartTime, "dd/MM/yyyy") }}
          </span>
          <span class="sender">
            {{ `${request.FirstName} ${request.LastName}` }}
          </span>
          <span class="source">
            <a
              target="_blank"
              :href="request.URL ? `https://${request.URL}` : null"
            >
              <div class="img-wrapper">
                <img
                  v-if="request.url"
                  @load="(e) => loadHandler(e, request)"
                  :src="getObjectIcon(request.URL)"
                  alt=""
                  :class="{
                    'more-width': request.moreWidthThanHeight,
                    'more-height': !request.moreWidthThanHeight,
                  }"
                />
                <img
                  v-if="!request.url"
                  @load="(e) => loadHandler(e, request)"
                  :src="getObjectIcon(request.URL,request.ProgramName)"
                  alt=""
                  :class="{
                    'more-width': request.moreWidthThanHeight,
                    'more-height': !request.moreWidthThanHeight,
                  }"
                />
                <img
                  v-else
                  @load="(e) => loadHandler(e, request)"
                  :src="`data:image/jpeg;base64, ${request.File}`"
                  :class="{
                    'more-width': request.moreWidthThanHeight,
                    'more-height': !request.moreWidthThanHeight,
                  }"
                />
              </div>
              {{ request.URL ? request.URL : request.ProgramName }}
            </a>
          </span>
          <span class="button">
            <span
              :class="[
                'custom-button',
                { 'custom-button--statused': request.Status === true },
              ]"
              @click="approve(request)"
            >
              Approve
            </span>
          </span>
          <span class="button">
            <span
              :class="[
                'custom-button custom-button--reject',
                { 'custom-button--reject--statused': request.Status === false },
              ]"
              @click="reject(request)"
            >
              Reject
            </span>
          </span>
        </div>
      </div>
      <div v-else class="no-record">No Records</div>
    </div>
  </section>
</template>

<script>
import DateRange from "@/components/ReusableComponents/DateRange/DateRange";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect";
import axios from "@/Helpers/AxiosInstance";
import TrafficTabs from "@/components/ReusableComponents/ViewChanger/TrafficTabs.vue";
import { getDateString } from "@/Helpers/HelperFunctions";

export default {
  components: { TrafficTabs, DateRange, CustomSelect },
  data() {
    return {
      filters: {
        period: {
          to: new Date().getTime(),
          from: new Date(
            new Date().setDate(new Date().getDate() - 10)
          ).getTime(),
        },
        status: 1,
      },
      data: [],
      loading: true,
      statusOptions: [
        {
          value: 0,
          title: "Show All",
        },
        {
          value: 1,
          title: "Pending",
        },
        {
          value: 2,
          title: "Rejected",
        },
        {
          value: 3,
          title: "Approved",
        },
      ],
    };
  },
  methods: {
    getDateString,
    loadHandler(e, object) {
      const width = e.target.naturalWidth;
      const height = e.target.naturalHeight;
      object.moreWidthThanHeight = width > height;
      this.$set(this.data, this.data.indexOf(object), object);
    },
    async getData() {
      this.loading = true;
      const response = await axios.get("attendance/GetPersonProgramRequests", {
        params: {
          fromDate: getDateString(this.filters.period.from, "yyyy/MM/dd"),
          toDate: getDateString(this.filters.period.to, "yyyy/MM/dd"),
        },
      });
      this.data = response.data.Value.sort((a, b) => b.ID - a.ID);
      this.loading = false;
    },
    async reject(object) {
      const status = false;
      let responceObject = {
          RequestID: object.ID,
          ProgramName: object.ProgramName,
          URL: object.URL,
          Status: status,
      }
      const response = await axios.post("attendance/UpdatePersonsProgramRequest", responceObject);

      if (!response.data.ErrorMsg) {
        const index = this.data.indexOf(object);
        object.Status = status;
        this.$set(this.data, index, object);
      }
    },
    async approve(object) {
      const status = true;
      let responceObject = {
          RequestID: object.ID,
          ProgramName: object.ProgramName,
          URL: object.URL,
          Status: status,
        }
      const response = await axios.post("attendance/UpdatePersonsProgramRequest", responceObject);

      if (!response.data.ErrorMsg) {
        const index = this.data.indexOf(object);
        object.Status = status;
        this.$set(this.data, index, object);
      }
    },
    approveAll() {
      this.data.forEach((el) => {
        this.approve(el);
      });
    },
    rejectAll() {
      this.data.forEach((el) => {
        this.reject(el);
      });
    },
    getObjectIcon(url,name) {
      const isProduction = location.href.indexOf('.upgaming.com')>0;

      if (url) {
        return `https://s2.googleusercontent.com/s2/favicons?domain_url=${url}`;
      }
      else if(!url){
        return `https://intranet.upgaming.${isProduction ? 'com' : 'dev' }/api/attendance/GetProgramLogoImg?ProgramName=${name}`
      }
      else {
        return require("@/assets/icons/icon_traficapp_default.svg");
      }
    },
  },
  watch: {
    filters: {
      async handler(val) {
        await this.getData();
        if (val.status) {
          switch (val.status) {
            case 0:
              break;
            case 1:
              this.data = this.data
                .filter((el) => el.Status === null)
                .sort((a, b) => b.ID - a.ID);
              break;
            case 2:
              this.data = this.data
                .filter((el) => el.Status === false)
                .sort((a, b) => b.ID - a.ID);
              break;
            case 3:
              this.data = this.data
                .filter((el) => el.Status === true)
                .sort((a, b) => b.ID - a.ID);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.traffic {
  height: 100%;
  padding: 25px;
  overflow: hidden;

  &__header {
    display: flex;
  }

  .icon {
    margin-right: 15px;
    height: 24px;
    width: 24px;
  }

  &__title {
    margin-left: 40px;
    color: #283593;
    font-size: 18px;

    span {
      color: #ff5f58;
    }
  }

  &__filters {
    display: flex;
    margin-left: auto;
    align-items: center;
    justify-content: space-between;

    .traffic__workspace {
      cursor: pointer;
      color: #283593;
      margin-right: 16.5px;
    }

    .traffic__requests {
      color: red;
      margin-right: 16.5px;
      display: flex;
      align-items: center;

      img {
        height: 16px;
        width: 16px;
        margin-right: 5px;
      }
    }
  }

  .traffic__data {
    color: #283593;
    position: relative;
    height: calc(100% - 41px);
    margin-top: 2.1875rem;

    .header,
    .row {
      margin-bottom: 25px;
      display: flex;
    }

    .header {
      font-weight: 500;
    }

    .data {
      max-height: calc(100% - 80px);
      overflow-y: auto;
      padding-bottom: 5px;
    }
  }
}

.index {
  width: 150px;
}

.date {
  width: 250px;
}

.sender {
  width: 350px;
}

.source {
  width: 700px;
}

.button {
  width: 150px;
}

.custom-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 30px;
  border: 1px solid #283593;
  color: #283593;
  border-radius: 10px;
  transition: 0.15s ease;

  &--header {
    width: 6.25rem;
    height: 2.0625rem;
  }

  &:hover {
    background-color: rgba(#283593, 0.6);
    color: white;
  }

  &--statused {
    background-color: rgba(#283593, 0.6);
    color: white;
  }

  &--reject {
    border: 1px solid #ff5f58;
    color: #ff5f58;

    &:hover {
      background-color: rgba(#ff5f58, 0.6) !important;
      color: white;
    }

    &--statused {
      background-color: rgba(#ff5f58, 0.6) !important;
      color: white;
    }
  }
}

a {
  display: flex;
  align-items: center;
  text-decoration: none;

  &:link {
    color: #1db3e9;
  }

  &:visited {
    color: #1db3e9;
  }
}

.no-record {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  font-size: 50px;
  color: rgba(#283593, 0.5);
}

.loader {
  display: block;
  height: 25px;
  //width: 100px;
  margin-right: 15px;
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  border-radius: 5px;
}

@-webkit-keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }
  100% {
    background-position: 250px 0;
  }
}

.img-wrapper {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  height: 24px;
  width: 24px;

  img {
    &.more-width {
      width: 100%;
      height: auto;
    }

    &.more-height {
      width: auto;
      height: 100%;
    }
  }
}

.traffic__title {
  margin-left: 0 !important;
}
.traffic_additional_header {
  width: 120%;
  height: 3.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background: #fff;
  top: -2.025rem;
  left: -3.35rem;
  padding: 0 1.875rem;
}
</style>
