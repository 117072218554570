var render = function render(){var _vm=this,_c=_vm._self._c;return _c('custom-popup',{attrs:{"visible":true,"flexible-size":true}},[_c('div',{staticClass:"poll"},[_c('div',{staticClass:"poll__content"},[_c('div',{staticClass:"poll__banner",style:({
            'background-image': `url(data:image/jpeg;base64,${_vm.poll.file})`,
          })}),_c('div',{staticClass:"poll__details"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"poll__description"},[_vm._v(_vm._s(_vm.poll.description))]),_c('div',{staticClass:"poll-date"},[_vm._v(" Expiry date: "+_vm._s(_vm.getDateString(_vm.poll.expiryDate, "dd MMM yyyy"))+" ")]),_c('div',{staticClass:"poll__question"},[_vm._v(_vm._s(_vm.poll.question || "question"))]),_c('div',{staticClass:"poll__options"},_vm._l((_vm.poll.options),function(option,i){return _c('div',{key:i,staticClass:"poll__option"},[_c('div',{staticClass:"poll__option-wrapper"},[_c('div',{staticClass:"poll__option-index"},[_vm._v(_vm._s(i + 1))]),_c('div',{staticClass:"poll__option-name"},[_vm._v(_vm._s(option.option))])]),_c('div',{class:[
                    'poll__option-checkbox',
                    {
                      'poll__option-checkbox--selected': _vm.selectedOptions.includes(
                        option.id
                      ),
                    },
                  ],on:{"click":function($event){return _vm.optionClickHandler(option)}}},[(_vm.selectedOptions.includes(option.id))?_c('svg',{staticClass:"checkbox__mark",attrs:{"xmlns":"http://www.w3.org/2000/svg","x":"0px","y":"0px","viewBox":"0 0 512 512","xml:space":"preserve"}},[_c('path',{attrs:{"fill":"#283593","d":"M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0\n\t\t\tc-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7\n\t\t\tC514.5,101.703,514.499,85.494,504.502,75.496z"}})]):_vm._e()])])}),0)]),(!_vm.alreadySelected && !_vm.poll.anonymous)?_c('div',{staticClass:"poll__comment"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comment),expression:"comment"}],staticClass:"cstm-input",attrs:{"placeholder":"Type your comment","type":"text"},domProps:{"value":(_vm.comment)},on:{"input":function($event){if($event.target.composing)return;_vm.comment=$event.target.value}}})]):_vm._e()])]),(!_vm.alreadySelected)?_c('div',{staticClass:"poll__button",on:{"click":_vm.makePollChoice}},[_vm._v(" Send ")]):_vm._e()]),(_vm.snackbarVisible)?_c('custom-snackbar',{attrs:{"purpose":_vm.snackbarPurpose,"text":_vm.snackbarText},on:{"close":function($event){_vm.snackbarVisible = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }