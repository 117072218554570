<template>
  <div class="background">
    <div class="payroll-factories">
      <div class="essentials">
        <div class="user_info">
          <div class="user_info_img">
            <img :src="this.$store.state.getAvatar + this.$route.params.id" />
          </div>
          <div class="user_info_text">
            <div class="user_info_name">{{ personName }}</div>
            <div class="user_info_job">{{ personJobName }}</div>
          </div>
        </div>
        <!-- end user_info -->
        <div class="ess-container">
          <div
            class="add"
            @click="popUpVisible(true), (create = true)"
            v-if="this.salaryList.IsActiveSalary"
          >
            <span>Assign Factor</span>
          </div>
          <div class="filter-div">
            <date-range
              :initialFrom="fromDate"
              :initialTo="toDate"
              @from="(from) => (fromDate = from)"
              @to="(to) => (toDate = to)"
              class="date_range"
            />
          </div>
          <div class="search">
            <input type="text" v-model="searchFactory" placeholder="Search" />
            <img
              class="search-icon"
              src="@/assets/icons/searchAppIcon.svg"
              alt="hr"
            />
          </div>
        </div>
      </div>
      <!-- end essentials -->

      <div class="factories-list">
        <div
          class="factories-box"
          v-for="el in facoriesList"
          :key="el.ID"
          :class="
            el.ToDate && new Date(el.ToDate) < new Date()
              ? 'enddate'
              : 'startdate'
          "
        >
          <div
            class="factories-box-header"
            :class="[
              el.IsTax ? 'isTax' : 'noTax',
              el.IsDefault ? 'isDefault' : 'noDefault',
            ]"
          >
            <div class="factories-box-title">
              <span :title="el.SalaryFactorTypeName">
                {{ el.SalaryFactorTypeName }}
              </span>
            </div>
            <div class="factories-box-edit">
              <img
                v-if="el.canEdit"
                src="@/assets/icons/edit.svg"
                alt="hr"
                @click="editFactory(el.SalaryFactorID)"
              />
              <!-- <img
                v-if="el.canDelete"
                src="@/assets/icons/close.svg"
                alt="hr"
                @click="deleteFactory(el.SalaryFactorID)"
              /> -->
            </div>
          </div>
          <!-- end factories-box-header -->
          <div class="factories-info">
            <div class="factories-info-div">
              <span>Company</span>
              <span :title="el.CompanyName ? el.CompanyName : '-'">
                {{ el.CompanyName ? el.CompanyName : '-' }}
              </span>
            </div>
            <div class="factories-info-div">
              <span>Assign type</span>
              <span>
                {{ el.CalculationTypeName ? el.CalculationTypeName : '-' }}
              </span>
            </div>
            <div class="factories-info-div">
              <span>Payment type</span>
              <span>{{ el.PaymentTypeName ? el.PaymentTypeName : '-' }}</span>
            </div>
            <div class="factories-info-div">
              <span>Company cost</span>
              <span>
                {{ el.CompanyCost ? el.CompanyCost : '-' }}
                {{ el.CompanyCost && el.PaymentTypeID === 2 ? '%' : '' }}
              </span>
            </div>
            <div class="factories-info-div">
              <span>Employee cost</span>
              <span>
                {{ el.SalaryCost ? el.SalaryCost : '-' }}
                {{ el.SalaryCost && el.PaymentTypeID === 2 ? '%' : '' }}
              </span>
            </div>
            <div class="factories-info-div">
              <span>Pay day</span>
              <span>{{ el.PayDay ? el.PayDay : '-' }}</span>
            </div>
            <div class="factories-info-div">
              <span>Start date</span>
              <span>{{ el.FromDate ? convertDate(el.FromDate) : '-' }}</span>
            </div>
            <div class="factories-info-div">
              <span>End date</span>
              <span>{{ el.ToDate ? convertDate(el.ToDate) : '-' }}</span>
            </div>
          </div>
          <!-- end factories-info -->
        </div>
        <!-- end factories-box -->
      </div>
      <!-- end factories-list -->

      <custom-snackbar
        v-if="snackbarVisible"
        :purpose="snackbarPurpose"
        :text="snackbarText"
        @close="snackbarVisible = false"
      />
      <form class="assign-form" @submit.prevent="createFactory">
        <custom-popup
          :visible="popupVisible"
          @close="popUpVisible(false)"
          :title="create ? 'Assign Factor' : 'Edit Factor'"
          :flexibleSize="false"
        >
          <div class="user_info">
            <div class="user_info_img">
              <img :src="this.$store.state.getAvatar + this.$route.params.id" />
            </div>
            <div class="user_info_text">
              <div class="user_info_name">{{ personName }}</div>
              <div class="user_info_job">{{ personJobName }}</div>
            </div>
          </div>
          <div class="user_info_details">
            <div class="user_info_details_box">
              <div>
                <span class="boxtitle">Salary (Basic):</span>
                <span class="value">
                  <span>{{ salaryList.Amount }}</span>
                  {{ salaryList.Currency }}
                </span>
              </div>
              <div>
                <span class="boxtitle">Job start date:</span>
                <span class="value">
                  {{
                    salaryList.JobStartDate
                      ? convertDate(salaryList.JobStartDate)
                      : '-'
                  }}
                </span>
              </div>
            </div>
            <div class="user_info_details_box">
              <div>
                <span class="boxtitle">Salary Start Date:</span>
                <span class="value">
                  {{
                    salaryList.StartTime
                      ? convertDate(salaryList.StartTime)
                      : '-'
                  }}
                </span>
              </div>
              <div>
                <span class="boxtitle">Salary End Date:</span>
                <span class="value">
                  {{
                    salaryList.EndTime ? convertDate(salaryList.EndTime) : '-'
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- end user_info_details -->
          <div class="flex-2">
            <div class="form-field">
              <search-input
                :options="facoriestypeList"
                title="Factor"
                :isRequired="create"
                :disabled="!create"
                @clear="mainfactorytype = ''"
                v-model="mainfactorytype"
              />
            </div>
          </div>
          <div class="static-info-content">
            <div class="form-field-value">
              <div>Company</div>
              <div>{{ company ? company : '-' }}</div>
            </div>
            <div class="form-field-value">
              <div>Assign type</div>
              <div>{{ factortype ? factortype : '-' }}</div>
            </div>
            <div class="form-field-value">
              <div>Payment type</div>
              <div>{{ paymenttype ? paymenttype : '-' }}</div>
            </div>
          </div>
          <div class="flex-2 margin-top">
            <div class="form-field">
              <custom-input
                class="input"
                v-model="companyCost"
                title="Company cost"
                :disabled="disableStatus.DisableCompanyCost"
                @clear="companyCost = ''"
              />
              <span class="percent-icon">
                {{ paymenttypeID == 2 ? '%' : '' }}
              </span>
            </div>
            <div class="form-field">
              <custom-input
                class="input"
                v-model="salaryCost"
                title="Employee cost"
                :disabled="disableStatus.DisableSalaryCost"
                @clear="salaryCost = ''"
              />
              <span class="percent-icon">
                {{ paymenttypeID == 2 ? '%' : '' }}
              </span>
            </div>
          </div>
          <!-- end flex -->
          <div class="flex-3">
            <div class="form-field">
              <custom-input
                class="input"
                v-model="payDay"
                title="Pay day"
                :disabled="disableStatus.DisablePayDay"
                :isRequired="!disableStatus.DisablePayDay"
                @clear="payDay = ''"
              />
            </div>
            <div class="form-field">
              <date-picker
                class="date"
                title="Factor start date"
                :isRequired="!disableStatus.DisableFactorStartDate"
                :disabled="disableStatus.DisableFactorStartDate"
                v-model="StartDate"
                @clear="StartDate = ''"
              />
            </div>
            <div class="form-field">
              <date-picker
                class="date"
                title="Factor end date"
                v-model="EndDate"
                :disabled="disableStatus.DisableFactorEndDate"
                @clear="EndDate = ''"
              />
            </div>
          </div>
          <!-- end flex -->
          <div class="save-button-div">
            <button class="save-button">{{ create ? 'Save' : 'Edit' }}</button>
          </div>
        </custom-popup>
      </form>

      <delete-item
        @delete="deleteRow"
        @close="deletePopupVisible = false"
        v-if="deletePopupVisible"
        :text="`Would you like to delete the factory?`"
      />
    </div>
    <!-- end payroll-factories -->
  </div>
</template>
<script>
import { getDateString, CETToTimestamp } from '@/Helpers/HelperFunctions'
import CustomPopup from '@/components/ReusableComponents/CustomPopup/CustomPopup.vue'
import CustomInput from '@/components/ReusableComponents/CustomInput/CustomInput.vue'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import DeleteItem from '@/components/ReusableComponents/DeleteItem/DeleteItem.vue'
import DatePicker from '@/components/ReusableComponents/DatePicker/DatePicker.vue'
import DateRange from '@/components/ReusableComponents/DateRange/DateRange'
import SearchInput from '@/components/ReusableComponents/SearchInput/SearchInput.vue'
import axios from '@/Helpers/AxiosInstance.js'

export default {
  created() {
    this.getFacories()
    this.getSalary()
    this.getFacoriesTypes()

    this.getPersonName()
    this.getPaymentTypes()
    this.getCompanies()
    this.getFactoryTypes()
    this.getCurrencyType()
  },
  components: {
    CustomPopup,
    CustomInput,
    CustomSnackbar,
    DeleteItem,
    DatePicker,
    DateRange,
    SearchInput,
  },
  data() {
    return {
      create: false,
      popupVisible: false,
      deletePopupVisible: false,
      snackbarVisible: false,
      snackbarPurpose: 'error',
      snackbarText: '',

      salaryList: [],
      paymentTypeOptions: [],
      facoriesList: [],
      facoriestypeList: [],
      factoriestypeListAll: [],
      searchFactory: '',
      fromDate: null,
      toDate: null,
      personName: '',
      personJobName: '',
      filterfromDate: '',
      filtertoDate: '',

      selectedfactory: '',
      serviceName: '',
      companiesListOptions: [],
      factoryTypeOptions: [],
      salaryCurrencyOptions: [],

      mainfactorytype: null,
      companyCost: null,
      salaryCost: null,
      payDay: null,
      StartDate: null,
      EndDate: null,
      company: null,
      factortype: null,
      paymenttype: null,
      paymenttypeID: null,

      disableStatus: {
        DisableCompanyCost: false,
        DisableSalaryCost: false,
        DisablePayDay: false,
        DisableFactorStartDate: false,
        DisableFactorEndDate: false,
      },
    }
  },
  methods: {
    popUpVisible(status) {
      this.mainfactorytype = null
      this.company = null
      this.factortype = null
      this.paymenttype = null
      this.companyCost = null
      this.salaryCost = null
      this.payDay = null
      this.StartDate = null
      this.EndDate = null
      this.popupVisible = status
    },
    getFacories() {
      axios
        .get(
          `salary/GetSalaryFactors?SalaryID=${this.$route.params.salaryId}&Name=${this.searchFactory}&FromDate=${this.filterfromDate}&ToDate=${this.filtertoDate}`,
        )
        .then((response) => {
          this.facoriesList = response.data.Value
          if (this.facoriesList) {
            this.facoriesList.forEach((el) => {
              this.$set(el, 'canEdit', el.CanUpdate)
              this.$set(el, 'canDelete', el.CanUpdate)
            })
          }
        })
    },
    createFactory() {
      let objectData = {
        salaryID: this.$route.params.salaryId,
        SalaryFactorTypeID: this.mainfactorytype,
        companyCost: this.companyCost,
        salaryCost: this.salaryCost,
        payDay: this.payDay,
        startDate: this.StartDate,
        endDate: this.EndDate,
      }

      this.serviceName = 'salary/CreateSalaryFactors'
      if (!this.create) {
        objectData.salaryFactorID = this.selectedfactory
        this.serviceName = 'salary/UpdateSalaryFactor'
      }

      axios.post(this.serviceName, objectData).then((resp) => {
        if (!resp.data.ErrorMsg) {
          this.popupVisible = false
          this.mainfactorytype = null
          this.companyCost = null
          this.salaryCost = null
          this.payDay = null
          this.StartDate = null
          this.EndDate = null
          this.getFacories()
        } else {
          this.snackbarVisible = true
          this.snackbarText = resp.data.ErrorMsg
          this.snackbarPurpose = 'error'
        }
      })
    },
    editFactory(id) {
      this.create = false
      this.selectedfactory = id
      axios.get(`salary/GetSalaryFactors?ID=${id}`).then((response) => {
        this.mainfactorytype = response.data.Value[0].SalaryFactorTypeID
        this.companyCost = response.data.Value[0].CompanyCost
        this.salaryCost = response.data.Value[0].SalaryCost
        this.payDay = response.data.Value[0].PayDay
        this.StartDate = response.data.Value[0].FromDate
        this.EndDate = response.data.Value[0].ToDate
        this.company = response.data.Value[0].CompanyName
        this.factortype = response.data.Value[0].CalculationTypeName
        this.paymenttype = response.data.Value[0].PaymentTypeName
        this.paymenttypeID = response.data.Value[0].PaymentTypeID
        this.getSalaryFactorTypesById(response.data.Value[0].SalaryFactorTypeID)
        this.popupVisible = true
      })
    },
    async getSalaryFactorTypesById(id) {
      await axios.get(`salary/GetSalaryFactorTypes?ID=${id}`).then((resp) => {
        this.salaryFactorTypesListById = resp.data.Value.FactorsWithPersons
        this.disableStatus.DisableCompanyCost = this.salaryFactorTypesListById[0].DisableCompanyCost
        this.disableStatus.DisableSalaryCost = this.salaryFactorTypesListById[0].DisableSalaryCost
        this.disableStatus.DisablePayDay = this.salaryFactorTypesListById[0].DisablePayDay
        this.disableStatus.DisableFactorStartDate = this.salaryFactorTypesListById[0].DisableFactorStartDate
        this.disableStatus.DisableFactorEndDate = this.salaryFactorTypesListById[0].DisableFactorEndDate
      })
    },
    deleteFactory(id) {
      this.selectedfactory = id
      this.deletePopupVisible = true
    },
    deleteRow() {
      var data = {
        SalaryFactorID: this.selectedfactory,
        delete: true,
      }
      axios
        .post('salary/UpdateSalaryFactor', data)
        .then((resp) => {
          this.deletePopupVisible = false
          if (!resp.data.ErrorMsg) {
            this.snackbarText = 'Deleted Successfully!'
            this.snackbarPurpose = 'success'
            this.snackbarVisible = true
            this.getFacories()
          } else {
            this.snackbarVisible = true
            this.snackbarText = resp.data.ErrorMsg
            this.snackbarPurpose = 'error'
          }
        })
        .catch(() => {
          this.snackbarVisible = true
          this.snackbarText = 'Error'
          this.snackbarPurpose = 'error'
        })
    },
    getCompanies() {
      axios.get(`salary/GetSalaryFactorsDestinationTypes`).then((resp) => {
        this.companiesListOptions = resp.data.Value.DestinationTypes.map(
          (el) => ({
            title: el.Name,
            value: el.ID,
          }),
        )
      })
    },
    getFactoryTypes() {
      axios.get(`salary/GetSalaryFactorCalculationTypes`).then((resp) => {
        this.factoryTypeOptions = resp.data.Value.map((el) => ({
          title: el.Name,
          value: el.ID,
        }))
      })
    },
    getPaymentTypes() {
      axios.get('salary/GetSalaryFactorTypePaymentTypes').then((resp) => {
        this.paymentTypeOptions = resp.data.Value.map((el) => ({
          title: el.Name,
          value: el.ID,
        }))
      })
    },
    getCurrencyType() {
      axios.get('Company/CurrencyTypes').then((resp) => {
        this.salaryCurrencyOptions = resp.data.Value.map((el) => ({
          title: el.Code,
          value: el.ID,
        }))
      })
    },
    convertDate(date) {
      return getDateString(CETToTimestamp(date), 'dd MMM yyyy')
    },
    getDateStringIn(date) {
      return getDateString(date)
    },
    getPersonName(id) {
      id = this.$route.params.id
      axios.get(`Person/GetPersonsDetails?Person=${id}`).then((resp) => {
        let firstName = resp.data.Value.FirstName
        let lastName = resp.data.Value.LastName
        let UpperCaseName =
          firstName.charAt(0).toUpperCase() + firstName.slice(1)
        let UpperCaseLastName =
          lastName.charAt(0).toUpperCase() + lastName.slice(1)
        this.personName = `${UpperCaseName} ${UpperCaseLastName}`
        this.personJobName = resp.data.Value.JobName
      })
    },
    getSalary() {
      axios
        .get(`salary/GetSalaries?SalaryID=${this.$route.params.salaryId}`)
        .then((response) => {
          this.salaryList = response.data.Value.Salaries[0]
        })
    },
    getFacoriesTypes() {
      this.serviceName = `salary/GetSalaryFactorTypes?JustAutomatic=false`
      if (this.mainfactorytype) {
        this.serviceName = `salary/GetSalaryFactorTypes?ID=${this.mainfactorytype}`
      }

      axios.get(this.serviceName).then((response) => {
        this.factoriestypeListAll = response.data.Value.FactorsWithPersons
        this.facoriestypeList = response.data.Value.FactorsWithPersons.map(
          (el) => ({
            title: el.Name,
            value: el.ID,
          }),
        )
      })
    },
  },
  watch: {
    fromDate() {
      this.filterfromDate = this.getDateStringIn(this.fromDate)
      this.filtertoDate = this.getDateStringIn(this.toDate)
      this.getFacories()
    },
    searchFactory(to, from) {
      this.searchFactory = to
      this.getFacories()
    },
    mainfactorytype(to, from) {
      if (!to) {
        this.company = null
        this.factortype = null
        this.paymenttype = null
        this.companyCost = null
        this.salaryCost = null
        this.payDay = null
        this.StartDate = null
        this.EndDate = null
      }

      if (this.create) {
        this.mainfactorytype = to
        this.getFacoriesTypes()
        let factoryData = this.factoriestypeListAll.filter((el) => el.ID == to)
        if (factoryData) {
          this.company = factoryData[0].DestinationTypeName
          this.factortype = factoryData[0].SalaryFactorCalculationTypeName
          this.paymenttype = factoryData[0].PaymentTypeName
          this.companyCost = factoryData[0].CompanyCost
          this.salaryCost = factoryData[0].SalaryCost
          this.payDay = factoryData[0].PayDay
          this.StartDate = factoryData[0].StartDate
          this.EndDate = factoryData[0].EndDate

          this.disableStatus.DisableCompanyCost =
            factoryData[0].DisableCompanyCost
          this.disableStatus.DisableSalaryCost =
            factoryData[0].DisableSalaryCost
          this.disableStatus.DisablePayDay = factoryData[0].DisablePayDay
          this.disableStatus.DisableFactorStartDate =
            factoryData[0].DisableFactorStartDate
          this.disableStatus.DisableFactorEndDate =
            factoryData[0].DisableFactorEndDate
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.background {
  overflow: hidden;
}
.payroll-factories {
  height: 100%;

  .factories-list {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    height: calc(100% - 8.125rem);
    overflow: auto;
    .factories-box.enddate {
      opacity: 0.5;
    }
    .factories-box {
      width: 21.375rem;
      height: fit-content;
      min-height: 19.6875rem;
      border-radius: 0.375rem;
      background-color: #fff;
      overflow: hidden;
      margin: 1.6875rem;
      padding-bottom: 0.625rem;
      .isTax.isDefault {
        background-color: #fbb6b6;
      }
      .noTax.isDefault {
        background-color: #b6e7f8;
      }
      .isTax.noDefault {
        background-color: #fed4b3;
      }
      .noTax.noDefault {
        background-color: #b6f8e8;
      }
      .factories-box-header {
        width: 100%;
        height: 3.125rem;
        display: flex;
        justify-content: space-between;
        .factories-box-title {
          max-width: 16.25rem;
          padding-left: 1.875rem;
          -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
          font-size: 1rem;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.35;
          letter-spacing: normal;
          color: #5963ab;
          overflow: hidden;
          span {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 0.875rem;
            display: inline-block;
          }
        }
        .factories-box-edit {
          img:first-child {
            width: 1.6875rem;
            margin: 0.75rem 0.3125rem 0 0rem;
            cursor: pointer;
          }
          img:last-child {
            width: 1.6875rem;
            margin: 0.75rem 0.9375rem 0 0rem;
            cursor: pointer;
          }
        }
      }
      .factories-info {
        padding: 0 1.875rem;
        .factories-info-div {
          span {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 0.875rem;
            display: inline-block;
          }
          span:first-child {
            width: 45%;
            font-size: 0.8125rem;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: left;
            color: #5963ab;
            display: inline-block;
          }
          span:last-child {
            width: 55%;
            font-size: 0.8125rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: left;
            color: #5963ab;
            display: inline-block;
          }
        }
      }
    }
  }
  .essentials {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15 * $rem;
    padding: 0 1.75rem;
    margin-top: 1.875rem;

    form {
      margin-right: 15 * $rem;
    }
    .ess-container {
      display: flex;
      justify-content: space-between;

      .filter-div {
        margin: 0 1.275rem 0.0625rem 0;
      }
      & > .add {
        height: 2.375rem;
        margin: 0.0625rem 1.275rem 0.0625rem 0;
        padding: 0.5rem 2rem 0rem;
        border-radius: 0.1875rem;
        background-color: #3f46bf;
        cursor: pointer;

        span {
          -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
          font-size: 0.75rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: #ededfc;
        }
      }
    }

    .search {
      position: relative;
      input {
        width: 12.5rem;
        height: 2.375rem;
        background-color: #fff;
        border: 0;
        outline: none;
        border-radius: 0.375rem;
        font-weight: 300;
        font-size: 0.875rem;
        color: #9696b9;
        padding: 0 2.1875rem 0 0.9375rem;
      }
      img {
        position: absolute;
        height: 1.3125rem;
        width: 1.3125rem;
        right: 0.625rem;
        top: 0.5rem;
      }
    }
  }
  .flex-2 {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
    .form-field {
      width: 48%;
    }
  }
  .flex-3 {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
    .form-field {
      width: 30%;
    }
    ::v-deep .el-input {
      width: 100%;
    }
  }
  .static-info-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
  }
  .form-field {
    width: 100%;
    position: relative;
    label {
      width: 100%;
    }
    .percent-icon {
      font-weight: 500;
      color: rgba(40, 53, 147, 0.7);
      font-size: 0.9375rem;
      position: absolute;
      right: 19px;
      top: 12px;
    }
  }
  .save-button-div {
    width: 100%;
    text-align: right;
  }
  .save-button {
    background-color: #6956d6;
    color: #fcfcfc;
    height: 3rem;
    width: 6.25rem;
    border-radius: 0.375rem;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 3.75rem;
  }
  .assign-form {
    ::v-deep .popup {
      height: auto;
    }
  }

  .user_info {
    margin: 0.4188rem 0.3125rem 0 0;
    display: flex;

    .user_info_img {
      width: 3.75rem;
      height: 3.75rem;
      background-color: rgba(40, 53, 147, 0.5);
      border-radius: 50%;
      cursor: pointer;
      overflow: hidden;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      img {
        width: 3.75rem;
      }
    }
    .user_info_text {
      margin: 0.75rem 0 0.375rem 0.75rem;
    }
    .user_info_name {
      -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
      font-size: 1rem;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #283593;
    }
    .user_info_job {
      -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
      font-size: 0.75rem;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #9696b9;
    }
  }
  .user_info_details {
    width: 100%;
    margin-top: 0.75rem;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.25rem;
    border-bottom: solid 0.0625rem #ededfc;
    .user_info_details_box {
      width: 50%;
      text-align: left;
      div {
        margin-top: 0.4375rem;
        .boxtitle {
          width: 33%;
          display: inline-block;
          -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
          font-size: 0.875rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.36;
          letter-spacing: normal;
          color: #959bc9;
        }
        .value {
          width: 33%;
          display: inline-block;
          -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
          font-size: 0.875rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.36;
          letter-spacing: normal;
          color: #283593;
          span {
            color: #c44f7d;
            display: inline-block;
            padding-right: 0.3125rem;
          }
        }
      }
    }
  }
  .form-field-value {
    margin-top: 1.75rem;
    div:first-child {
      font-size: 0.75rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #959bc9;
    }
    div:last-child {
      font-size: 0.875rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: left;
      color: #283593;
    }
  }
  .margin-top {
    margin-top: 1.625rem;
  }
}
</style>
