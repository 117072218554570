<template>
    <div class="background">
        <custom-snackbar
            :text="snackbar.text"
            :purpose="snackbar.purpose"
            v-if="snackbar.visible"
            @close="snackbar.visible = false"
        />
        <div class="top-menu">
            <switch-header  :routes="routes" class="switch-header"/>
        </div>

        <div class="content-wrapper">
            <custom-loader 
                v-if="loading"
            />
            <div class="content-header">
                <div class="top">
                    <div class="header-title">
                        <span>
                            Criterias
                        </span>
                    </div>
                    <div class="header-filters">
                        <ul>
                            <li>
                                <custom-select 
                                    :title="'Criteria'"
                                    :options="criteriaOptions"
                                    v-model="filteredCriteria"
                                    :clearVisible="true"

                                />
                            </li>
                            <li>
                                <custom-select 
                                    :title="'Is Common'"
                                    :options="[{title: 'Common',value: 1},{title:'Not Common',value: 2}]"
                                    v-model="filteredCommon"
                                    :clearVisible="true"
                                />
                            </li>
                            <li class="clear-filters" @click="clearAllFilters">
                                <div>
                                    <span>Clear Filters</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="header-actions">
                        <div class="add-new-criteria">
                            <div class="purple-btn add-new-criteria" @click="createCriteriaVisible = !createCriteriaVisible">
                                <img :class="{'down':createCriteriaVisible}" src="@/assets/grades/add.svg" alt="add">
                                <span class="">Criteria</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="['bottom',{'visible':createCriteriaVisible}]">
                    <div class="create-criteria">
                        <div class="title">
                            <span>Create Criteria</span>
                        </div>
                        <div class="form-wrapper">
                            <form @submit.prevent="createNewCriteria">
                                <ul>
                                    <li>
                                        <div class="parent withCheckbox">
                                            <div class="left">
                                                <custom-input
                                                    :title="'Criteria title'"
                                                    v-model="createCriteria.title"
                                                    :isRequired="true"
                                                    class="parentField withCheckbox"
                                                />
                                                <div class="checkbox-wrapper">
                                                    <check-box
                                                        :checked="createCriteria.isCommon"
                                                        @value="(val) => createCriteria.isCommon = val"
                                                    />
                                                    <span>
                                                        Is Common
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="child">
                                                <custom-input
                                                    :title="'Max Score'"
                                                    v-model="createCriteria.maxScrore"
                                                    :isRequired="true"
                                                    :inputType="'number'"
                                                    class="childField withWarning"
                                                    :warning="createCriteria.warning"
                                                    :warningText="createCriteria.warningText"
                                                />
                                            </div>
                                        </div>
                                    </li>
                                    <li v-for="(el,ind) in createCriteria.descriptions" :key="ind">
                                        <div class="parent withDelete">
                                            <div class="top-field">
                                                <custom-input 
                                                    :title="'Description'"
                                                    v-model="el.description"
                                                    class="parentField"
                                                />
                                                <div @click="removeDescriptionField(ind)" class="remove-description-field">
                                                    <img src="@/assets/grades/trash-gray.svg" alt="">
                                                </div>
                                            </div>
                                            <div class="child">
                                                <ul class="child-fields">
                                                    <li class="child-field">
                                                        <custom-input
                                                            :title="'Min Score'"
                                                            v-model="el.minScore"
                                                            :inputType="'number'"
                                                            class="childField"
                                                        />
                                                    </li>
                                                    <li class="child-field">
                                                        <custom-input
                                                            :title="'Max Score'"
                                                            v-model="el.maxScore"
                                                            :inputType="'number'"
                                                            class="childField"
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div class="add-description-field">
                                    <div class="add-description">
                                        <div @click="addDescriptionField">
                                            <img src="@/assets/grades/addpurple.svg" alt="">
                                            <span> Add Description </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="submit-criteria">
                                    <button type="submit">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="['middle',{'moreHeight':createCriteriaVisible}]">
                <div class="no-records" v-if="!loading && criterias.length < 1 && !createCriteriaVisible">
                    <span>There are no Records</span>
                </div>

                <div class="criterias-list">
                    <ul>
                        <li class="titles">
                            <div class="left-titles">
                                <div class="col-title">
                                    <span>ID</span>
                                </div>
                                <div class="col-title">
                                    <span>Criterias</span>
                                </div>
                            </div>
                            <div class="right-titles">
                                <div class="col-title">
                                    <span>Max Score</span>
                                </div>
                                <div class="col-title">
                                    <span>Is Common</span>
                                </div>
                            </div>
                        </li>
                        <li v-show="filterbyOptions(criteria)" :class="{'collapsed': criteria.dropdownVisible}" v-for="(criteria,ind) in criterias" :key="ind">
                            <div :class="['main-label',{'collapsed':criteria.dropdownVisible}]">
                                <div class="left">
                                    <div class="col-ind col-value">
                                        {{criteria.CriteriaID ? criteria.CriteriaID : ind}}
                                    </div>
                                    <div class="col-value col-name">
                                        {{criteria.Description ? criteria.Description : '-'}}
                                    </div>
                                </div>
                                <div class="right">
                                    <div class="col-score col-value">
                                        {{criteria.ScoringRangeMax ? criteria.ScoringRangeMax : '-'}}
                                    </div>
                                    <div class="col-isCommon col-value">
                                        <check-box 
                                            :checked="criteria.CriteriaTypeID === 1 ? true : criteria.CriteriaTypeID === 2 ? false : false"
                                            :disabled="false"
                                            @value="(val) => updateCommonStatus(criteria.CriteriaID,val)"
                                        />
                                    </div>
                                    <div @click="getCriteriaDetails(criteria.CriteriaID)" class="col-dropdown">
                                        <div :class="['dropdown-arrow',{'down':criteria.dropdownVisible}]">
                                            <img src="@/assets/grades/dropdown.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="descriptions-wrapper">
                                <div class="no-records" v-if="criteria.dropdownVisible && selectedCriteriaDetails.descriptions.length < 1 && !criteria.addNew">
                                    <span>There are no Records</span>
                                </div>
                                <div :class="['edit-criteria-toolbar',{'active':criteria.toolbarVisible}]">
                                    <ul>
                                        <li class="toolar-dots" @click="showToolbar(criteria.CriteriaID)">
                                            <button>
                                                <img v-if="criteria.toolbarVisible" src="@/assets/grades/toolbardots.svg" alt="">
                                                <img v-else src="@/assets/grades/toolbardots-gray.svg" alt="">
                                            </button>
                                        </li>
                                        <li v-if="criteria.toolbarVisible">
                                            <button @click="editCriteria(criteria.CriteriaID)">
                                                <img src="@/assets/grades/edit.svg" alt="">
                                                <span>Edit</span>
                                            </button>
                                        </li>
                                        <li v-if="criteria.toolbarVisible">
                                            <button @click="deleteCriteria(criteria.CriteriaID)">
                                                <img src="@/assets/grades/trash.svg" alt="">
                                                <span>Delete</span>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div v-if="criteria.editMode" class="criteria-edit-field">
                                    <div class="fields">
                                        <div class="field">
                                            <custom-input
                                                :title="'Criteria title'"
                                                v-model="editSingleCriteria.description"
                                            />
                                        </div>
                                        <div class="field">
                                            <custom-input
                                                :title="'Max Score'"
                                                :inputType="'number'"
                                                v-model="editSingleCriteria.scoringRangeMax"
                                            />
                                        </div>
                                        <div class="field">
                                            <check-box 
                                                :checked="editSingleCriteria.isCommon"
                                                @value="(val) => editSingleCriteria.isCommon = val"
                                            />
                                            <span>Is Common</span>
                                            <div class="actions">
                                                <div class="save" @click="updateCriteria(criteria.CriteriaID)">
                                                    <img src="@/assets/grades/done.svg" alt="">
                                                </div>
                                                <div class="exit" @click="cancelCriteriaEdit(criteria.CriteriaID)">
                                                    <img src="@/assets/grades/exit.svg" alt="">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div :class="['single-description',{'noHover':item.editMode}]" v-for="(item,ind) in selectedCriteriaDetails.descriptions" :key="ind">
                                    <div class="description-score">
                                        <span v-if="!item.editMode">
                                            {{item.CriteriaDescriptionMinScore}}-{{item.CriteriaDescriptionMaxScore}}
                                        </span>
                                        <div class="score-edit" v-if="item.editMode">
                                            <div class="input-wrapper">
                                                <custom-input 
                                                    :title="'Min Score'"
                                                    :inputType="'number'"
                                                    v-model="editSingleDescription.minScore"
                                                />
                                            </div>
                                            <div class="input-wrapper">
                                                <custom-input 
                                                    :title="'Max Score'"
                                                    :inputType="'number'"
                                                    v-model="editSingleDescription.maxScore"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="description">
                                        <span v-if="!item.editMode">
                                            {{item.CriteriaDescriptionDescription ? item.CriteriaDescriptionDescription : '-'}}
                                        </span>
                                        <div v-if="item.editMode" class="input-wrapper">
                                            <custom-text-area
                                                :title="'Description'"
                                                :fixedSize='true'
                                                v-model="editSingleDescription.description"
                                            />
                                            <div class="edit-actions">
                                                <div @click="cancelCriteriaDescriptionEdit(item.CriteriaDescriptionID)" class="exit">
                                                    <img src="@/assets/grades/exit.svg" alt="">
                                                </div>
                                                <div @click="updateCriteriaDescription(item.CriteriaDescriptionID)" class="save">
                                                    <img src="@/assets/grades/done.svg" alt="">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="description-actions">
                                            <div @click="editCriteriaDescription(item.CriteriaDescriptionID)" class="edit">
                                                <img src="@/assets/grades/edit-gray.svg" alt="">
                                            </div>
                                            <div class="delete" @click="deleteCriteriaDescription(item.CriteriaDescriptionID)">
                                                <img src="@/assets/grades/trash-gray.svg" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="add-new-description">
                                    <div class="btn-wrapper" @click="addnewDescriptionField(criteria.CriteriaID)">
                                        <img src="@/assets/grades/addpurple.svg" alt="">
                                        <span>Add Description</span>
                                    </div>
                                    <div class="add-new-description-field" v-show="criteria.addNew">
                                        <form @submit.prevent="addNewDescription(criteria.CriteriaID)">
                                            <ul>
                                                <li>
                                                    <custom-input
                                                        :title="'Description'"
                                                        v-model="addedCriteria.description"
                                                        :isRequired="true"
                                                    />
                                                </li>
                                                <li>
                                                    <custom-input
                                                        :title="'Min Score'"
                                                        v-model="addedCriteria.minScore"
                                                        :isRequired="true"
                                                    />
                                                </li>
                                                <li>
                                                    <custom-input
                                                        :title="'Max Score'"
                                                        v-model="addedCriteria.maxScore"
                                                        :isRequired="true"
                                                    />
                                                </li>
                                                <li>
                                                    <button class="save-description">
                                                        <span>Save</span>
                                                    </button>
                                                </li>
                                            </ul>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance.js";
import SwitchHeader from '@/components/ReusableComponents/SwitchHeader/SwitchHeader.vue'
import CheckBox from '@/components/Grades/GradeComponents/CheckBox.vue'
import CustomInput from '@/components/ReusableComponents/CustomInput/CustomInput.vue'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import CustomTextArea from '@/components/ReusableComponents/CustomTextArea/CustomTextArea.vue'
import CustomLoader from '@/components/ReusableComponents/Loader/CustomLoader.vue'
import CustomSelect from '@/components/ReusableComponents/CustomSelect/CustomSelect.vue'


export default {
    components:{
        SwitchHeader,
        CheckBox,
        CustomInput,
        CustomSnackbar,
        CustomTextArea,
        CustomLoader,
        CustomSelect,
    },
    data(){
        return{
            routes:[
                {
                    title:'Grade Nodes',
                    path:'/Grades/GradeNodes'
                },
                {
                    title:'Criterias',
                    path:'/Grades/Criterias'
                },
                {
                    title:'Team Rating',
                    path:'/Grades/TeamRatingOverall'
                }
            ],
            snackbar:{
                text:'',
                purpose: '',
                visible: false,
            },
            createCriteria:{
                title: '',
                maxScrore: null,
                warning: false,
                warningText: 'Max Score Must Be Between 1 and 10',
                isCommon: false,
                descriptions:[
                    {
                        description: '',
                        minScore: null,
                        maxScore: null,
                    },
                ]
            },
            createCriteriaVisible: false,
            criterias:[],
            selectedCriteriaDetails: {
                descriptions: []
            },
            selectedCriteriaID: null,
            editSingleDescription:{
                minScore: null,
                maxScore: null,
                description: '',
            },
            editSingleCriteria:{
                description:'',
                scoringRangeMax: null,
                isCommon: false,
            },
            loading: false,
            detailsLoading: false,
            criteriaOptions: [],

             ////filters
             filteredCriteria: '',
             filteredCommon: '',

             addedCriteria:{
                description: '',
                minScore: null,
                maxScore: null,
             }
        }
    },
    async created(){
        await this.getCriterias()
    },
    methods:{
        async getCriterias(keepCollapsed){
            this.loading = true
            await axios.get('grading/GetCriterias').then(resp => {
                this.loading = false
                let data = resp.data.Value

                this.criteriaOptions = data.map(el => ({
                    title: el.Description,
                    value: el.CriteriaID
                }))

                data.forEach(el => {
                    this.$set(el,'editMode',false)
                    this.$set(el,'toolbarVisible',false)
                    this.$set(el,'addNew',false)

                    if(keepCollapsed){
                        if(el.CriteriaID === this.selectedCriteriaID){
                            this.$set(el,'dropdownVisible',true)
                        }else{
                            this.$set(el,'dropdownVisible',false)
                        }
                    }else{
                        this.$set(el,'dropdownVisible',false)
                    }
                })

                this.criterias = data
            })
        },
        async getCriteriaDetails(id,keepCollapsed){
            this.selectedCriteriaID = id
            this.criterias.forEach(el => {
                
                if(el.CriteriaID === id){
                    if(keepCollapsed) el.dropdownVisible = true
                    else el.dropdownVisible = !el.dropdownVisible
                }else{
                    el.dropdownVisible = false
                }
                el.toolbarVisible = false
                el.editMode = false
            })

            await axios.get('grading/GetGradePartCriteriaDescriptions',{
                params:{
                    CriteriaID:id
                }
            }).then(resp => {

                if(!resp.data.ErrorMsg){
                    let descriptions = resp.data.Value
                    descriptions.forEach(el => {
                        this.$set(el,'editMode',false)
                    })
                    this.selectedCriteriaDetails.descriptions = descriptions
                }else{
                    this.snackbarMessage(resp.data.ErrorMsg,'error')
                }
            })
        },
        addDescriptionField(){
            let obj = {
                description: '',
                minScore: null,
                maxScore: null,
            }
            this.createCriteria.descriptions.push(obj)
            this.$nextTick(() => {
                this.scrolltoBottom('.bottom')
            })
        },
        addnewDescriptionField(id){
            this.criterias.forEach(el => {
                if(el.CriteriaID === id) el.addNew = !el.addNew
                else el.addNew = false
            })
        },
        addNewDescription(id){
            axios.post('grading/CreateCriteriaDescription',{
                criteriaID: id ? id : null,
                description: this.addedCriteria.description ? this.addedCriteria.description : '',
                minScore: this.addedCriteria.minScore ? this.addedCriteria.minScore : null,
                maxScore: this.addedCriteria.maxScore ? this.addedCriteria.maxScore : null,
            }).then(resp => {
                if(!resp.data.ErrorMsg){
                    this.snackbarMessage('Criteria Added Successfully','success')
                    this.getCriteriaDetails(id,true)
                    this.addedCriteria.description = ''
                    this.addedCriteria.maxScore = null
                    this.addedCriteria.minScore = null
                    this.criterias.find(el => el.CriteriaID === id).addNew = false
                }else{
                    this.snackbarMessage(resp.data.ErrorMsg,'error')
                }
            })
        },
        async createNewCriteria(){
            let criteria = {
                description: this.createCriteria.title,
                criteriaTypeID: this.createCriteria.isCommon ? 1 : 2,
                scoringRangeMax: parseInt(this.createCriteria.maxScrore),
            }
            if(criteria.scoringRangeMax > 10) {
                this.createCriteria.warning = true
                return
            }else{
                this.loading = true
                await axios.post('grading/CreateCriteria',criteria)
                .then(resp => {
                    this.loading = false
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Criteria Created Successfully','success')
                        let criteriaID = resp.data.Value[0].CriteriaID
                        let descriptions = this.createCriteria.descriptions
                        let descriptionsFilled = descriptions.every(el => el.description.length > 0 && el.minScore && el.maxScore)
    
                        if(descriptionsFilled){
                            axios.post('grading/CreateCriteriaDescriptions',{
                                criteriaID: criteriaID,
                                criteriaDescriptions: descriptions
                            }).then(resp => {
                                if(!resp.data.ErrorMsg){
                                    this.snackbarMessage('Criteria Created Successfully','success')
                                    this.getCriterias()
                                    this.resetCriteriaFields()
                                    this.createCriteriaVisible = false
                                }else{
                                    this.snackbarMessage(resp.data.ErrorMsg,'error')
                                }
                            })
                        }else{
                            this.getCriterias()
                            this.resetCriteriaFields()
                            this.createCriteriaVisible = false
                        }
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                }).catch(err => {
                    this.loading = false
                })
            }
        },
        resetCriteriaFields(){
            this.createCriteria.title = ''
            this.createCriteria.maxScrore = null
            this.createCriteria.isCommon = false
            this.createCriteria.descriptions = [
                {
                    description: '',
                    minScore: null,
                    maxScore: null,
                },
            ]
        },
        removeDescriptionField(elInd){
            if(elInd === 0){
                let field = this.createCriteria.descriptions.find((el,ind) => ind === elInd)
                field.description = ''
                field.minScore = null
                field.maxScore = null
                return
            }
            this.createCriteria.descriptions = this.createCriteria.descriptions.filter((el,ind) => ind !== elInd)
        },
        scrolltoBottom(domElement){
            let domEl = document.querySelector(domElement) 
            domEl.scrollTo({top: domEl.scrollHeight,behavior:'smooth'})
        },
        snackbarMessage(text,purpose){
            this.snackbar.visible = true
            this.snackbar.text = text
            this.snackbar.purpose = purpose
        },
        showToolbar(id){
            this.criterias.forEach(el => {
                if(el.CriteriaID === id){
                    el.toolbarVisible = !el.toolbarVisible
                }else{
                    el.toolbarVisible = false
                }
            })
        },
        deleteCriteriaDescription(id){
            this.$confirm("Would You Like To Delete This Description").then(() => {
                axios.post('grading/UpdateCriteriaDescription',{
                    criteriaDescriptionID: id,
                    delete: true,
                }).then(resp => {
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Criteria Description Deleted Successfully','success')
                        this.getCriteriaDetails(this.selectedCriteriaID,true)
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                })
            })
        },
        deleteCriteria(id){
            this.$confirm("Would You Like To Delete This Criteria").then(() => {
                axios.post('grading/UpdateGradePartCriteria',{
                    criteriaID: id,
                    delete: true,
                }).then(resp => {
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Criteria Deleted Successfully','success')
                        this.getCriterias()
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                })
            })
        },
        editCriteriaDescription(id){
            let currentDescription = this.selectedCriteriaDetails.descriptions.find(el => el.CriteriaDescriptionID === id)
            this.selectedCriteriaDetails.descriptions.forEach(el => {
                if(el.CriteriaDescriptionID === id) el.editMode = !el.editMode
                else el.editMode = false
            })
            this.editSingleDescription.description = currentDescription.CriteriaDescriptionDescription
            this.editSingleDescription.minScore = currentDescription.CriteriaDescriptionMinScore
            this.editSingleDescription.maxScore = currentDescription.CriteriaDescriptionMaxScore


        },
        updateCriteriaDescription(id){
            axios.post('grading/UpdateCriteriaDescription',{
                criteriaDescriptionID: id,
                minScore: this.editSingleDescription.minScore,
                maxScore: this.editSingleDescription.maxScore,
                description: this.editSingleDescription.description,
            }).then(resp => {
                if(!resp.data.ErrorMsg){
                    this.snackbarMessage('Criteria Description Updated Successfully','success')
                    this.getCriteriaDetails(this.selectedCriteriaID,true)
                }else{
                    this.snackbarMessage(resp.data.ErrorMsg,'error')
                }
            })
        },
        cancelCriteriaDescriptionEdit(id){
            this.resetSingleCriteriaDescriptionValues()
            this.selectedCriteriaDetails.descriptions.forEach(el => {
                el.editMode = false
            })
        },
        resetSingleCriteriaDescriptionValues(){
            this.editSingleDescription.minScore = null
            this.editSingleDescription.maxScore = null
            this.editSingleDescription.description = ''
        },
        editCriteria(id){
            this.criterias.forEach(el => {
                if(el.CriteriaID === id){
                    el.editMode = !el.editMode
                }else{
                    el.editMode = false
                }
            })
            let criteria =  this.criterias.find(el => el.CriteriaID === id)
            this.editSingleCriteria.description = criteria.Description
            this.editSingleCriteria.scoringRangeMax = criteria.ScoringRangeMax
            this.editSingleCriteria.isCommon = criteria.CriteriaTypeID === 1 ? true : criteria.CriteriaTypeID === 2 ? false : false
        },
        cancelCriteriaEdit(id){
            this.editSingleCriteria.description = ''
            this.editSingleCriteria.scoringRangeMax = null
            this.editSingleCriteria.isCommon = false

            this.criterias.forEach(el => {
                el.editMode = false
            })
        },
        updateCriteria(id){
            axios.post('grading/UpdateGradePartCriteria',{
                criteriaID: id,
                description: this.editSingleCriteria.description,
                criteriaTypeID: this.editSingleCriteria.isCommon ? 1 : !this.editSingleCriteria.isCommon ? 2 : null,
                scoringRangeMax: this.editSingleCriteria.scoringRangeMax
            }).then(resp => {
                if(!resp.data.ErrorMsg){
                    this.snackbarMessage('Criteria Updated Successfully','success')
                    this.getCriterias(true)
                }else{
                    this.snackbarMessage(resp.data.ErrorMsg,'error')
                }
            })
        },
        filterbyOptions(criteria){
            let state = false
            let criteriaID = criteria.CriteriaID
            let isCommon = criteria.CriteriaTypeID
            if(this.filteredCriteria){
                if(criteriaID === this.filteredCriteria){
                    state = true
                }
            }
            if(this.filteredCommon){
                if(isCommon === this.filteredCommon){
                    state = true
                }
            }
            if(this.filteredCommon && this.filteredCriteria){
                if(criteriaID === this.filteredCriteria && isCommon === this.filteredCommon){
                    state = true
                }else{
                    state = false
                }
            }
            if(!this.filteredCommon && !this.filteredCriteria){
                state = true
            }
            return state
        },
        clearAllFilters(){
            this.filteredCommon = ''
            this.filteredCriteria = ''
        },
        updateCommonStatus(id,val){
            let status = val ? 1 : !val ? 2 : null
            axios.post('grading/UpdateGradePartCriteria',{
                criteriaID: id ? id : null,
                criteriaTypeID: status,
            }).then(resp => {
                if(!resp.data.ErrorMsg){
                    let keepCollapsed = id === this.selectedCriteriaID ? true : false
                    this.getCriterias(keepCollapsed)
                    this.snackbarMessage('Status Updated','success')
                }else{
                    this.snackbarMessage(resp.data.ErrorMsg,'error')
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
*{
    font-family: Roboto,firaGo;
}
.no-records{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-40%,-50%);
    pointer-events: none;
    user-select: none;
    span{
        font-size: 1.875rem;
        color: rgba($grayBlue, .8);
    }
}

.card-move {
  opacity: 1;
  transition: all 0.5s;
}

.background{
    overflow: hidden;
}

.top-menu{
    top: 0;
    z-index: 10;
    background: #fff;
    padding-bottom: 0.625rem;
    padding-left: 1.875rem;
}

.content-wrapper{
    width: calc(100% - (1.875rem * 2));
    height: calc(100% - 1.5rem);
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    background: #fff;
    overflow: hidden;
    margin: 1.5rem auto;
    box-sizing: border-box;

    .content-header{
        width: 100%;

        .top{
            padding: 1.75rem 3.125rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #FAFBFC;
            border-bottom: 0.25rem solid #F5F6F7;
            height: 6rem;

            .header-title{
                span{
                    color: $grayBlue;
                    font-size: 1rem;
                    font-weight: 600;
                }
            }

            .add-new-criteria{
                img{
                    transition: ease .3s;
                }
                img.down{
                    transform: rotate(45deg);
                }
            }

            .header-filters{
                ul{
                    list-style: none;
                    @include flex(flex-start,center,row);
                    li{
                        width: 13.75rem;
                        height: 2.5rem;
                        margin-left: 0.9375rem;

                        &:first-child{
                            margin-left: 0;
                        }

                        ::v-deep{
                            .selected-option{
                                color: $grayBlue;
                                font-weight: 400;
                            }
                        }

                        &.clear-filters{
                            cursor: pointer;
                            width: auto;
                            margin-left: 1.25rem;

                            &:hover{
                                span{
                                    color:rgba($red, .4)
                                }
                            }
                            & > div{
                                height: 100%;
                                @include centerElement;
                                position: relative;
                                &::before{
                                    content: '';
                                    position: absolute;
                                    width: 0.0625rem;
                                    height: calc(100% - .8rem);
                                    background: rgba($grayBlue,.5);
                                    transition: ease .3s;
                                    top: 50%;
                                    transform: translate(0,-50%);
                                    left: -0.5rem;
                                }

                                &:active{
                                    &::before{
                                        height: calc(100% - 2rem);
                                    }
                                }

                                span{
                                    color: $grayBlue;
                                    transition: ease .3s;
                                    font-size: 0.8125rem;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }
        .bottom{
            width: 100%;
            height: 0;
            display: none;
            padding: 1.75rem 3.125rem;
            background: transparent;
            transition: ease .3s;
            overflow: hidden;

            &.visible{
                display: block;
                height: 24rem;
                animation: show .3s forwards;
                overflow: auto;
            }

            .create-criteria{
                .title{
                    color: $purple;
                    font-size: 0.875rem;
                    line-height: 1.5rem;
                    font-weight: 500
                }

                .form-wrapper{
                    width: 100%;
                    form{
                        width: 100%;
                    }
                    ul{
                        width: 100%;
                        list-style: none;
                        position: relative;

                        &:not(.child-fields)::before{
                            content: '';
                            width: 0.0625rem;
                            height: 100%;
                            background: #D4D7E9;
                            position: absolute;
                            bottom: 0;
                            left: 0.875rem;
                        }

                        li:not(:first-child){

                            .parent{
                                position: relative;

                                &::before{
                                    content: '';
                                    position: absolute;
                                    // width: 0.9375rem;
                                    width: 1.6375rem;
                                    height: 0.0625rem;
                                    background: #D4D7E9;
                                    display: block;
                                    left: 0.9375rem;
                                    top: 1.25rem;
                                }

                                &::after{
                                    content: '';
                                    position: absolute;
                                    // width: 0.9375rem;
                                    width: 0.0625rem;
                                    height: 2.1875rem;
                                    background: #D4D7E9;
                                    display: block;
                                    left: 3.4375rem;
                                    top: 2.5rem;
                                } 

                                .child{
                                    ul li{
                                        margin-left: 1.1875rem;
                                        &:first-child{
                                            margin-left: 0;
                                        }
                                        position: relative;

                                        &::before{
                                            content: '';
                                            position: absolute;
                                            width: 0.9375rem;
                                            height: 0.0625rem;
                                            background: #D4D7E9;
                                            display: block;
                                            left: -1.1875rem;
                                            top: 1.3125rem;  
                                        }

                                    }

                                }
                            }
                        }

                        ::v-deep{
                            .warning-text{
                                position: absolute;
                                left: 100%;
                                top: 50%;
                                transform: translate(0,-50%);

                            }
                        }

                        li:first-child:not(.child-field){

                            &::after{
                                content: '';
                                position: absolute;
                                // width: 0.9375rem;
                                width: 0.0625rem;
                                height: 2.1875rem;
                                background: #D4D7E9;
                                display: block;
                                left: 3.4375rem;
                                top: 2.5rem;
                            }

                            .child{
                                position: relative;
                                &::before{
                                    content: '';
                                    position: absolute;
                                    width: 0.9375rem;
                                    height: 0.0625rem;
                                    background: #D4D7E9;
                                    display: block;
                                    left: -1.0625rem;
                                    top: 1.3125rem;  
                                } 
                            }
                        }

                        li{
                            margin-top: 0.8125rem;
                            &:first-child{
                                .parent{
                                    padding-left: 0;
                                }
                                .child{
                                    margin-left: 4.5625rem !important;
                                }
                            }
                            .parent{
                                width: 100%;
                                padding-left: 2.1875rem;
                                &:hover{
                                    .top-field .remove-description-field{
                                        opacity: 1;
                                        pointer-events: all;
                                    }
                                }
                                .top-field{
                                    @include flex(unset,center,row);

                                    ::v-deep{
                                        label{
                                            width : calc(100% - 10.5rem) !important;
                                        }
                                    }
                                    .remove-description-field{
                                        opacity: 0;
                                        pointer-events: none;
                                        transition: ease .3s;
                                        cursor: pointer;
                                        margin-left: 0.9375rem;
                                        :active{
                                            transform: scale(1.2);
                                        }
                                        img{
                                            width: 1.25rem;
                                        }
                                    }
                                }

                                .child{
                                    margin-left: 2.5rem;
                                    ::v-deep{
                                        label{
                                            width: 13.75rem !important;
                                        }
                                    }
                                }


                                &.withCheckbox{

                                    .child{
                                        margin-top: 0.8125rem;
                                    }
                                    .left{
                                        @include flex (flex-start,center,row);

                                        .checkbox-wrapper{
                                            margin-left: 1.375rem;
                                            @include flex(flex-start,center,row);

                                            span{
                                                color: $grayBlue;
                                                font-size: 0.75rem;
                                                font-weight: 500;
                                                margin-left: 0.625rem;
                                            }
                                        }


                                    }

                                }
                                .parentField{
                                    width: 100%;
                                    &.withCheckbox{
                                        width: calc(100% - 170px);
                                    }
                                    ::v-deep{
                                        label{
                                            width: 100%;
                                        }
                                    }
                                }
                                @include flex(flex-start,left,column);

                                .child{
                                    @include flex(flex-start,left,row);
                                    ul{
                                        @include flex(flex-start,left,row);
                                    }
                                }
                            }
                        }
                    }

                    .submit-criteria{
                        width: 100%;
                        @include flex(flex-end,center,row);

                        button{
                            @include purpleBtn;
                            margin-right: 2.8125rem;
                        }
                    }

                    .add-description-field{
                        width: 100%;
                        margin-top: 1.0625rem;
                        margin-left: 0.3125rem;

                        .add-description{
                            width: 10rem;
                            div{
                                user-select: none;
                                cursor: pointer;
                                background: transparent;
                                @include flex(flex-start,center,row);

                                img{
                                    width:1.5rem;
                                    transition: ease .3s;
                                    &.down{
                                        transform: rotate(45deg);
                                    }
                                }
                                span{
                                    font-size: 0.75rem;
                                    color: #ffffff;
                                    padding: 0.4375rem 0.75rem;
                                    background: $purple;
                                    border-radius: 0.375rem;
                                    margin-left: 0.3125rem;
                                }
                            }
                        }
                    }
                }

            }
        }
    }
}

.middle{
    width: 100%;
    margin-top: 1.25rem;
    overflow: auto;
    height: calc(100% - 160px);
    
    &.moreHeight{
        height: 30%!important;
    }

    .criterias-list{
        width: 100%;
        display: grid;
        padding: 1rem 3.125rem;
        animation: show .3s forwards;
        ul{
            list-style: none;
        }

        ul{
            width: 100%;
            @include flex(flex-start,center,column);

            li{
                width: 100%;
                height: 2.5rem;
                margin-top: 0.9375rem;
                border: 0.0625rem solid rgba($soft-blue,.4);
                border-radius: 0.375rem;
                transition: ease .3s;
                overflow: hidden;



                &.collapsed{
                    min-height: 15rem;
                    height: unset !important;
                    animation: show .5s forwards;
                }

                &:first-child{
                    margin-top: 0;
                    border: 0.0625rem solid transparent;
                }

                &.titles{
                    padding: 0 0.9375rem;
                    @include flex(space-between,center,row);

                    .left-titles,.right-titles{
                        @include flex(flex-start,center,row);
                        .col-title{
                            font-size: 0.875rem;
                            font-weight: 500;
                            color: $purple;
                        }
                    }

                    .left-titles{
                        .col-title{
                            margin-left: 1.5625rem;
                            &:first-child{
                                margin-left: 0;
                            }
                        }
                    }
                    .right-titles{
                        text-align: center;
                        .col-title{
                            width: 7rem;
                            margin-right: 3.5625rem;
                            text-align: center;

                        }
                    }
                }
                .main-label{
                    width: 100%;
                    height: 2.5rem;
                    padding: 0 0.9375rem;
                    transition: ease .2s;

                    &.collapsed{
                        background: $purple;
                        border-radius: 0.375rem;

                        .left,.right{
                            .col-value{
                                color: #ffffff !important;
                            }
                        }
                    }

                    @include flex(space-between,left,row);
                    .left,.right{
                        @include flex(flex-start,center,row);

                        .col-value{
                            font-size: 0.875rem;
                            color: $grayBlue;
                            transition: ease .2s;

                            &.col-name,&.col-score{
                                font-weight: 500;
                            }

                            &.col-ind{
                                user-select: none;
                            }
                        }
                    }

                    .left{
                        .col-value{
                            margin-left: 1.5625rem;
                            &:first-child{
                                margin-left: 0;
                            }
                        }
                    }

                    .right{
                        .col-value{
                            @include centerElement;
                            width: 7rem;
                            margin-right: 2.8125rem;
                        }
                    }

                    .col-dropdown{
                        cursor: pointer;
                        .dropdown-arrow{
                            transition: ease .2s;
                            &.down{
                                transform: rotate(180deg);
                            }
                            img{
                                width: 1.5rem;
                            }
                        }
                    }
                }

                .descriptions-wrapper{
                    width: 100%;
                    height: 100%;
                    padding: 1.1875rem 1.5625rem;
                    position: relative;
                    padding-bottom: 2rem;

                    .edit-criteria-toolbar{
                        width: 3.25rem;
                        height: 2.9rem;
                        position: absolute;
                        right: .3rem;
                        top: .6rem;
                        border-radius: 0.625rem;
                        z-index: 9;
                        transition: ease .2s;

                        &.active{
                            background: $purple;
                            height: 10rem;
                        }

                        ul{
                            width: 100%;
                            list-style: none;
                            @include flex(center,center,column);
                            padding: 0.625rem  1rem;

                            li{
                                width: unset !important;
                                margin-top: 1.25rem;
                                border-radius: 0;
                                border: none !important;
                                height: unset !important;

                                &.toolar-dots{
                                    button img{
                                        width: 1.5rem;
                                    }
                                }
                                &:first-child{
                                    margin-top: 0;
                                }
                                button{
                                    cursor: pointer;
                                    background: transparent;
                                    @include flex(center,center,column);

                                    img{
                                        width: 1.25rem;
                                    }
                                    span{
                                        margin-top: 0.3125rem;
                                        font-size: 0.625rem;
                                        font-weight: 700;
                                        color: #ffffff; 
                                    }
                                }
                            }
                        }
                    }
                    .criteria-edit-field{
                        width: 100%;                        
                        margin-left: 1.5625rem;
                        @include flex(flex-start,center,column);
                        .fields{
                            width: 100%;
                            @include flex(flex-start,center,row);
                        }
                        .field{
                            &:first-child{
                                width: 800px;
                                margin-left: 0;

                            }
                            &:nth-child(2){
                                margin-left: 0.9375rem;
                                width: 17.625rem;
                            }
                            ::v-deep{
                                label{
                                    width: 100%;
                                    .title{
                                        font-size: 0.8125rem;
                                        color: $grayBlue;
                                    }
                                    input{
                                        color: $grayBlue;
                                        font-size: 0.8125rem;
                                    }
                                }
                            }

                            &:nth-child(3){
                                margin-left: 0.9375rem;
                                @include flex(center,center,row);
                                span{
                                    margin-left: 0.5rem;
                                    color: $grayBlue;
                                    font-size: 0.8125rem;
                                }
                            }
                            .actions{
                                margin-left: 1.875rem;
                                @include flex(flex-start,center,row);
                                .save,.exit{
                                    cursor: pointer;
                                    img{
                                        width: 0.875rem;
                                    }
                                }
                                .exit{
                                    margin-left: 0.9375rem;
                                    &:hover{
                                        img{
                                            filter: invert(70%) sepia(44%) saturate(7415%) hue-rotate(337deg) brightness(175%) contrast(93%);
                                        }
                                    }
                                }
                                .save{
                                    &:hover{
                                        img{
                                            filter: invert(100%) sepia(53%) saturate(5667%) hue-rotate(83deg) brightness(133%);
                                        }
                                    }
                                    img{
                                        width: 1.1875rem;
                                    }
                                }
                            }
                        }
                    }

                    .single-description{
                        margin-left: 1.5625rem;
                        width: 100%;
                        position: relative;
                        @include flex(flex-start,center,row);
                        margin-top: 1.875rem;
                        &:nth-child(2){
                            margin-top: 0;
                        }
                        &:hover:not(.noHover){
                            .description > .description-actions{
                                opacity: 1;
                                pointer-events: all;
                            }
                        }
                        
                        &.noHover{
                            & > .description{
                                width: calc(100% - 23.125rem);
                                max-width: unset !important;
                            }
                        }

                        .description-score{
                            // width: 3rem;
                            align-self: flex-start;
                            margin-top: 0.3125rem;
                            span{
                                font-size: 0.875rem;
                                font-weight: 700;
                                color: #4D5E80;
                            }

                            .score-edit{
                                @include flex(flex-start,center,row);
                                .input-wrapper{
                                    margin-left: 0.625rem;
                                    &:first-child{
                                        margin-left: 0;
                                    }
                                    width: 5.4375rem;
                                    ::v-deep{
                                        .title{
                                            font-size: 0.8125rem !important;
                                        }
                                        label{
                                            width: 100%;
                                            input{
                                                font-size: 0.875rem;
                                                color: $grayBlue;

                                                &:focus{
                                                    border: 0.0625rem solid rgba(#7D8FB3,.5);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        & > .description{
                            max-width: calc(100% - 23.125rem);
                            position: relative;
                            margin-left: 1.25rem;
                            span{
                                font-size: 0.8125rem;
                                color: $grayBlue;
                                line-height: 1.0625rem;
                            }

                            .input-wrapper{
                                width: 100%;
                                position: relative;
                                ::v-deep{
                                    .title{
                                        color: #7D8FB3;
                                        font-weight: 300 !important;
                                    }
                                    textarea{
                                        height: 5.625rem;
                                        width: 100%;
                                        font-size: 0.8125rem;
                                        color: #7D8FB3;
                                        line-height: 1.0625rem;
                                        transition: ease .3s;
                                        &:focus{
                                            border: 0.0625rem solid rgba(#7D8FB3,.5);
                                        }
                                    }
                                }
                                .edit-actions{
                                    position: absolute;
                                    right: -2rem;
                                    top: 25px;
                                    img{
                                        width: 0.8125rem;
                                    }
                                    .exit{
                                        &:hover{
                                            img{
                                                filter: invert(70%) sepia(44%) saturate(7415%) hue-rotate(337deg) brightness(175%) contrast(93%);
                                            }
                                        }
                                    }
                                    .save,.exit{
                                        cursor: pointer;
                                    }
                                    .save{
                                        &:hover{
                                            img{
                                                filter: invert(100%) sepia(53%) saturate(5667%) hue-rotate(83deg) brightness(133%);
                                            }
                                        }
                                        margin-top: 0.5rem;
                                        img{
                                            width: 0.9375rem;
                                        }
                                    }
                                }
                            }
                            .description-actions{
                                opacity: 0;
                                pointer-events: none;
                                transition: ease .3s;
                                position: absolute;
                                right: -5rem;
                                top: 0.3125rem;
                                @include flex(flex-start,center,row);

                                & > div{
                                    cursor: pointer;
                                    margin-left: 0.9375rem;

                                    &:first-child{
                                        margin-left: 0;
                                    }
                                }
                            }
                        }
                    }

                    .add-new-description{
                        width: 100%;
                        margin-top: 1.5625rem;
                        margin-left: 1.5625rem;

                        .btn-wrapper{
                            @include flex(flex-start,center,row);
                            cursor: pointer;
                            span{
                                color: #4D5E80;
                                font-size: 0.875rem;
                                font-weight: 400;
                                margin-left: 0.3125rem;
                            }

                            img{
                                width: 1.25rem;
                            }
                        }

                        .add-new-description-field{
                            width: 100%;
                            margin-top: 1.25rem;
                            form{
                                ul{
                                    @include flex(flex-start,center,row);
                                    li{
                                        width: 9rem;
                                        border: none;
                                        margin-top: 0;
                                        margin-left: 1.5625rem;
                                        overflow: visible !important;
                                        &:first-child{
                                            margin-left: 0;
                                            width: 30rem;
                                        }

                                        ::v-deep{
                                            label{
                                                width: 100% !important;
                                            }
                                        }

                                        &:last-child{
                                            .save-description{
                                                width: 5rem !important;
                                                @include purpleBtn;
                                                @include centerElement;
                                                span{
                                                    user-select: none;
                                                    font-size: 0.875rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

</style>