<template>
    <div class="back">
        <div class="bdays-calendar-main">
            <div class="filter">
                <div class="filterByYear">
                <span class="prevYear" @click="currentYear--">
                    <svg class="changerIcon" width="6.959" height="9.764" viewBox="0 0 6.959 9.764">
                        <path id="Path_2413" data-name="Path 2413"
                              d="M-371.868-265.9l4.065-5.327a.816.816,0,0,0-.648-1.311h-8.13a.816.816,0,0,0-.648,1.311l4.065,5.327A.816.816,0,0,0-371.868-265.9Z"
                              transform="translate(-265.576 377.399) rotate(90)" fill="#ADB8CC"/>
                    </svg>
                </span>
                    <div> {{ currentYear }} </div>
                    <span class="nextYear" @click="currentYear++">
                    <svg class="changerIcon" width="6.959" height="9.764" viewBox="0 0 6.959 9.764">
                        <path id="Path_2414" data-name="Path 2414"
                              d="M-371.868-265.9l4.065-5.327a.816.816,0,0,0-.648-1.311h-8.13a.816.816,0,0,0-.648,1.311l4.065,5.327A.816.816,0,0,0-371.868-265.9Z"
                              transform="translate(272.535 -367.635) rotate(-90)" fill="#ADB8CC"/>
                    </svg>
                </span>
                </div>
                <div class="filterByMonth">
                    <custom-select
                        class="customSelect"
                        title="Month"
                        :options="months"
                        v-model="selectedMonth"
                    />
                    <span>{{ birthdaysCount }}</span>
                </div>
            </div>
            <div class="bdays-months-l">
                <div
                    class="month"
                    v-for="month in months"
                    :key="month.value">
                    <span class="months-i">{{ month.title }}</span>
                    <div
                        class="weekdays-l">
                        <div
                            class="weekday"
                            v-for="weekday in weekdays"
                            :key="weekday.value">
                            {{ weekday.title }}
                        </div>
                    </div>
                    <div class="days-l">
                        <div
                            style="position: relative"
                            class="day"
                            :class="{ isCurrent: day.obj.isCurrent,
                            isHoliday: day.obj.isHoliday, isFilteredBirthday: day.obj.isFilteredBirthday }"
                            v-for="day in getDays(month)"
                            :key="day.value"
                            @click="showBirthdays(month, day)">
                            <div
                                class="birthdaysList"
                                :class="{birthdaysListBottom: getMonthIndex(month) < 4,
                                birthdaysListTop: getMonthIndex(month) >= 4,
                                isPrivate: day.obj.birthdays.find(el => el.BirthDateVisibility === false)}"
                                v-if="day.obj.showBirthdays"
                            >
                                <div class="content"
                                     v-for="birthday in day.obj.birthdays"
                                     :key="birthday.PersonID">
                                    <img
                                        :src="getAvatarSrc(birthday.PersonID, birthday.HasAvatar)"
                                        alt=""
                                        :class="{isPrivate: day.obj.birthdays.find(el => el.BirthDateVisibility === false && el.PersonID === birthday.PersonID)}"
                                        @error="failedAvatars.push(birthday.PersonID)"
                                        v-if="!failedAvatars.includes(birthday.PersonID)">
                                    <div>
                                        <span
                                            class="personName">
                                            {{
                                                capFirstLetter(birthday.FirstName)
                                            }} {{ capFirstLetter(birthday.LastName) }}</span>
                                        <span class="jobName">{{ capFirstLetter(birthday.JobName) }}</span>
                                    </div>
                                </div>
                            </div>

                            <span
                                :class="{
                                birthdays: day.obj.birthdays.length > 0,
                                selected: day.obj.showBirthdays,
                                single: day.obj.day < 10,
                                double: day.obj.day > 9,
                                isPrivate: day.obj.birthdays.find(el => el.BirthDateVisibility === false),
                                currentDayStyle: day.obj.currentDay
                            }"
                            >
                                {{ day.obj.day }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="search">
            <img src="@/assets/icons/searchAppIcon.svg" alt="search-icon"/>
            <input type="text" placeholder="Search..." v-model="searchFilter" @keypress.enter="getBirthdaysInMonth"
            />
        </div>
    </div>

</template>

<script>
import Day from "@/components/BirthdaysCalendar/scripts/Day";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue";
import axios from "@/Helpers/AxiosInstance";

export default {
    name: "BirthdaysCalendar",
    components: {
        CustomSelect,
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            monthsNames: [...Array(12).keys()]
                .map(i => new Date(2021, i, 1)
                    .toLocaleString('default', {month: 'long'})),
            weekdaysNames: [...Array(7).keys()]
                .map(i => new Date(2021, 1, i + 1)
                    .toLocaleString('ge-ka', {weekday: 'long'}))
                .map(w => `${w[0]}${w[1]}`.toUpperCase()),
            selectedMonth: new Date().getMonth(),
            birthdaysInMonth: 0,
            birthdays: [],
            daysWithMonths: [],
            searchFilter: null,
            birthdaysCount: null,
            filteredBirthdays: null,
            failedAvatars: [],
        }
    },
    async created() {
        await this.getBirthdays()
        await this.getBirthdaysInMonth(this.selectedMonth)
        this.monthsNames.forEach(el => this.days(el))
    },
    mounted() {
    },
    computed: {
        lastDays() {
            return [...Array(12).keys()].map(i => new Date(this.currentYear, i + 1, 0).getDate())
        },
        months() {
            return this.monthsNames.map((el) => ({value: this.monthsNames.indexOf(el), title: el}))
        },
        weekdays() {
            return this.weekdaysNames.map((el) => ({value: this.weekdaysNames.indexOf(el), title: el}))
        },
    },
    methods: {
        days(month) {

            let index = this.monthsNames.indexOf(month)
            let lastDay = this.lastDays[index]
            let firstDayIndex = new Date(this.currentYear, index, 1).getDay() - 1;
            firstDayIndex = firstDayIndex >= 0 ? firstDayIndex : 6
            let prevLastDay = index ? this.lastDays[index - 1] : 31

            let days = []

            for (let x = firstDayIndex; x > 0; x--) {
                days.push(new Day(prevLastDay - x + 1, false, false, []))
            }

            for (let x = 1; x <= lastDay; x++) {
                let date = new Date(this.currentYear, index, x).getDay()
                let currentDate = new Date();
                let currentMonth = currentDate.getMonth()
                let currentDay = currentDate.getDate()
                let currentYear = currentDate.getFullYear()
                let day = new Day(x, true, false, []);
                if (index === currentMonth && currentYear === this.currentYear && currentDay === x) {
                    day.currentDay = true;
                }
                if (date === 6 || date === 0) {
                    day.isHoliday = true
                }

                if (this.birthdays.find(el => new Date(el.BirthDate).getMonth() === index)) {
                    let birthdayThisMonth = Array.from(this.birthdays.filter(el => new Date(el.BirthDate).getMonth() === index));

                    birthdayThisMonth.forEach((el) => {
                        if (new Date(el.BirthDate).getDate() === x) {
                            day.birthdays.push(el)
                        }
                    })
                }
                days.push(day)
            }
            let daysCount = JSON.parse(JSON.stringify(days))
            for (let x = 1; x <= 42 - daysCount.length; x++) {
                days.push(new Day(x, false, false, []))
            }

            this.daysWithMonths.push({month: index, days: days.map((el) => ({value: days.indexOf(el), obj: el}))})
        },
        getBirthdaysInMonth(month) {
            let url = `/appointment/GetBirthDayList`
            if (this.searchFilter) {
                url = `${url}?SearchTerm=${this.searchFilter}`
            } else {
                url = `${url}?Month=${month + 1}`
            }
            axios.get(url)
                .then((resp) => {
                    this.birthdaysInMonth = resp.data.Value.BirthDayCts[0].BirthDayCount
                    this.birthdaysCount = this.birthdaysInMonth
                    if (this.searchFilter) {
                        this.filteredBirthdays = resp.data.Value.BirthDays
                        this.filter()
                    }
                })
        },
        async getBirthdays() {
            await axios.get('/appointment/GetBirthDayList')
                .then((resp) => {
                    this.birthdays = resp.data.Value.BirthDays
                })
        },
        capFirstLetter(word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        },
        getAvatarSrc(personID, avatar) {
            if (avatar) {
                return this.$store.state.getAvatar + personID;
            }
        },
        getMonthIndex(month) {
            return this.months.indexOf(month)
        },
        showBirthdays(month, day) {
            let isOpened = day.obj.showBirthdays
            if (isOpened) {
                this.$set(day.obj, 'showBirthdays', !day.obj.showBirthdays)
            }
            else {
                if (day.obj.birthdays.length > 0) {
                    for (let month of this.daysWithMonths) {
                        for (let x of month.days) {
                            this.$set(x.obj, 'showBirthdays', false)
                        }
                    }
                    this.$set(day.obj, 'showBirthdays', !day.obj.showBirthdays)
                }
            }
        },
        filter() {
            for (let bd of this.filteredBirthdays) {
                let day = new Date(bd.BirthDate)
                let month = day.getMonth()
                let dayNumber = day.getDate()
                let monthDays = Array.from(this.daysWithMonths).find(el => el.month === month).days
                for (let d of monthDays) {
                    if (d.obj.day === dayNumber) {
                        this.$set(d.obj, 'isFilteredBirthday', true)
                    }
                }
            }
        },
        getDays(month) {
            return Array.from(this.daysWithMonths).find(el => el.month === this.getMonthIndex(month)).days
        },

    },
    watch: {
        selectedMonth() {
            this.getBirthdaysInMonth(this.selectedMonth)
        },
        searchFilter() {
            if (this.searchFilter === null || this.searchFilter === undefined || this.searchFilter === "") {
                for (let month of this.daysWithMonths) {
                    for (let day of month.days) {
                        this.$set(day.obj, 'isFilteredBirthday', false)
                    }
                }
            }
        },
        currentYear() {
            this.daysWithMonths = []
            this.monthsNames.forEach(el => this.days(el))
        },
    },
}
</script>

<style scoped lang="scss">
$purple: #8733FF;
$red: #E62E66;
$green: #1DE9B6;
$blue: #3361FF;

* {
    box-sizing: border-box;
    user-select: none;
}

.header {
    z-index: 0;
}

.back {
    display: flex;
    background-color: #F2F4F8;
    width: 83.5vw;
    margin: 0 2.375rem;
    border-radius: 1rem;
}


.bdays-calendar-main {
    color: #6B7A99;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 0.5rem 1.5rem 2rem 1rem;
}

.filter {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    margin: 2rem 0 0 1rem;
    gap: 1.3rem;

    .filterByYear {
        display: flex;
        align-items: center;
        font-weight: 500;
        gap: 1rem;

        .prevYear,
        .nextYear {
            cursor: pointer;
        }
    }

    .filterByMonth {
        display: flex;
        align-items: center;

        .customSelect {
            width: 9rem;
        }

        span {
            width: 3rem;
            background-color: white;
            padding: 0.6rem 1rem;
            border-radius: 0.4rem;
            margin-left: 0.2rem;
            text-align: center;
        }
    }
}


.bdays-months-l {
    width: 60vw;
    height: 90vh;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    border-radius: 1rem;
    color: #6B7A99;

    .months-i {
        padding-left: 0.6rem;
        letter-spacing: 0.5px;
    }

    .month {
        width: 25%;
        height: 30vh;
        font-size: 0.8rem;
        font-weight: 700;
        padding: 1rem 0.8rem;
        border: 1px solid #F4F5F7;

        .weekdays-l {
            display: flex;
            color: #7D8FB3;
            opacity: 0.8;
            font-size: 0.7rem;
            margin: 1.2rem 0 0.5rem 0;

            .weekday {
                width: 14.28%;
                text-align: center;
            }
        }

        .days-l {
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            font-size: 0.6rem;
            color: #ADB8CC;

            .selected {
                color: white;
                background-color: $purple;
            }

            .selected.isPrivate {
                background-color: $red;
            }

            .isCurrent {
                color: #4D5E80;
            }

            .isCurrent.isFilteredBirthday .birthdays {
                color: white;
                background-color: $green;
                border-color: $green;
            }

            .day {
                width: 14.28%;
                padding: 0.6rem 0;
                margin-bottom: 0.3rem;
                font-weight: 700;

                .birthdaysListBottom {
                    top: 105%;
                }

                .birthdaysListTop {
                    bottom: 105%;
                }


                .birthdaysList {
                    position: absolute;
                    left: -150%;
                    width: 17rem;
                    overflow-y: scroll;
                    border-radius: 0.5rem;
                    max-height: 10rem;
                    background-color: #F2E9FF;
                    border: 2px solid $purple;
                    padding: 0.7rem;
                    color: #4D5E80;
                    z-index: 999;

                    .content {
                        display: flex;
                        align-items: center;
                        text-align: left;

                        &:not(:first-child) {
                            margin-top: 0.6rem;
                        }

                        div:last-child {
                            display: flex;
                            flex-direction: column;
                        }

                        img {
                            width: 2rem;
                            height: 2rem;
                            object-fit: cover;
                            border-radius: 0.5rem;
                            margin-right: 0.3rem;
                        }
                    }

                    .personName {
                        font-size: 0.75rem;
                        padding-bottom: 0.1rem;
                        user-select: text;
                    }

                    .jobName {
                        user-select: text;
                        font-weight: 200;
                    }
                }
            }
            .birthdaysList.isPrivate {
                border: 2px solid $red;
            }
            .isHoliday {
                color: #7D8FB3;
            }
        }
    }
}

.search {
    display: flex;
    align-items: flex-start;
    margin: 5rem 0 0 -0.5rem;

    input {
        width: 9rem;
        border: none;
        outline: none;
        color: #4D5E80;
        padding-left: 0.75rem;
        user-select: text;
        background-color: #F2F4F8;

        &::placeholder {
            color: #7D8FB3;
            font-weight: 700;
        }
    }
}


.single {
    padding: 0.5rem 0.7rem;
}

.double {
    padding: 0.5rem;
}

.birthdays {
    border: 2px solid $purple;
    border-radius: 100%;
    cursor: pointer;
}

.publicBirthday {
    border: 2px solid $purple;
}

.isPrivate {
    border: 2px solid $red;
}

.currentDayStyle {
    border: 2px solid $blue;
    border-radius: 100%;
}

</style>