<template>
  <div class="background">
    <div class="payroll-bonus-limit">
      <div class="essentials">
        <div class="ess-container">
          <div class="add" @click="popUpVisible(true), (create = true)">
            <span>Assign Limit</span>
          </div>
          <div class="filter-div">
            <date-range
              :addCustom="'monthrange'"
              :initialFrom="fromDate"
              :initialTo="toDate"
              @from="(from) => (fromDate = from)"
              @to="(to) => (toDate = to)"
              class="date_range"
            />
          </div>
          <div class="filter-div">
            <custom-select
              class="input"
              title="Department"
              v-model="departmentFilter"
              :options="departmentListOptions"
              @clear="departmentFilter = ''"
              :clearVisible="true"
            />
          </div>
          <div class="filter-div">
            <custom-select
              class="input"
              title="Status"
              v-model="statusFilter"
              :options="statusListOptions"
              @clear="statusFilter = ''"
              :clearVisible="true"
            />
          </div>
          <div class="search">
            <input
              type="text"
              v-model="searchBonusLimit"
              placeholder="Search"
            />
            <img
              class="search-icon"
              src="@/assets/icons/searchAppIcon.svg"
              alt="hr"
            />
          </div>
        </div>
      </div>
      <!-- end essentials -->

      <div class="bonus-limit-list">
        <div
          class="bonus-limit-box" :class="el.StatusID == 1 ? 'bonus-limit-box-fade' : ''"
          v-for="el in bonusLimitList"
          :key="el.BonusLimitID">
          <div class="bonus-limit-box-header">
            <div class="bonus-limit-box-title">
              <span :title="el.DepartmentName">{{ el.DepartmentName }}</span>
            </div>
            <div class="factories-box-edit" v-if="el.CanUpdate">
               <img src="@/assets/icons/edit.svg" alt="hr" @click="editBonusLimit(el.BonusLimitID)" />
               <img src="@/assets/icons/close.svg" alt="hr" @click="deleteBonusLimit(el.BonusLimitID)" />
             </div>
            <div class="bonus-limit-box-img">
               <img :src="require(`@/assets/icons/bonus-limit-${el.StatusID}.svg`)" /> 
            </div>
          </div>
          <!-- end bonus-limit-box-header -->
          <div class="bonus-limit-info">
            <div class="bonus-limit-info-div">
              <span>Monthly Limit</span
              ><span
                >{{ el.Amount + " GEL" ? el.Amount : "-" }}
                {{ el.CurrencyCode }}</span
              >
            </div>
            <div class="bonus-limit-info-div">
              <span>Start Date</span
              ><span>{{
                el.PaymentStartDate ? convertDate(el.PaymentStartDate) : "-"
              }}</span>
            </div>
            <div class="bonus-limit-info-div">
              <span>End Date</span
              ><span>{{
                el.PaymentEndDate ? convertDate(el.PaymentEndDate) : "-"
              }}</span>
            </div>
            <div class="bonus-limit-info-div">
              <span>Note</span
              ><span :title="el.Note">{{ el.Note ? el.Note : "-" }}</span>
            </div>
            <div class="bonus-limit-progress-bar">
              <span :style="{ left: el.UsedBonusPercent + '%' }"
                >{{ el.UsedBonusPercent }} %</span
              >
              <div
                class="bonus-limit-progress-bar-content"
                :style="{ width: el.UsedBonusPercent + '%' }"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <!-- end bonus-limit-box -->
    </div>
    <!-- end bonus-limit-list -->

    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
    <form class="assign-form" @submit.prevent="createBonusLimit">
      <custom-popup
        :visible="popupVisible"
        @close="popUpVisible(false)"
        title="Determine Bonus Limits  "
        :flexibleSize="false"
      >
        <div class="flex-2">
          <div class="form-field">
            <custom-select
              class="input"
              title="Department"
              :isRequired="true"
              v-model="department"
              :options="departmentListOptions"
              @clear="department = ''"
              :clearVisible="true"
            />
          </div>
          <div class="bonus-total-salary">
            <div class="bonus-title">Total Monthly Salary Budget</div>
            <div class="bonus-value red">
              {{ departmentGrossSalary ? departmentGrossSalary : "-" }} GEL
            </div>
          </div>
        </div>
        <!-- end flex-2 -->

        <div class="flex-2" v-if="department">
          <div class="form-field">
            <custom-input
              class="input"
              v-model="monthlyBudget"
              title="Monthly budget (Gross)"
              @clear="monthlyBudget = ''"
            />
          </div>
          <div class="flex-between bonus-total-salary">
            <div class="bonus-fields">
              <div class="bonus-title">Income Tax</div>
              <div class="bonus-value">
                {{ monthlyBudgetIncomeTax ? monthlyBudgetIncomeTax : "-" }}
              </div>
            </div>
            <div class="bonus-fields">
              <div class="bonus-title">Pension</div>
              <div class="bonus-value">
                {{ monthlyBudgetPension ? monthlyBudgetPension : "-" }}
              </div>
            </div>
            <div class="bonus-fields">
              <div class="bonus-title">NET</div>
              <div class="bonus-value">
                {{ monthlyBudgetNet ? monthlyBudgetNet : "-" }}
              </div>
            </div>
          </div>
        </div>
        <!-- end flex-2 -->

        <div
          class="flex-2"
          v-for="(limitApprove, index) in bonusLimitApprovers"
          :key="index"
        >
          <div class="form-field form-field-delete">
            <custom-input
              v-model="limitApprove.Limit"
              title="Limit edge to approve"
              @clear="limitApprove.Limit = ''"
            />
          </div>
          <div class="form-field form-field-delete">
            <choose-persons
              :allPersons="limitApprove.personsArray"
              :customEvent="true"
              :isOpened="isOpened"
              @person-clicked="personClickHanlder"
              @custom-click="customClick(index)"
              v-model="limitApprove.Person"
              title="Approver/s"
            />
          </div>
          <div class="remove-approver">
            <img
              src="@/assets/icons/close.svg"
              alt="hr"
              @click="deleteApprover(limitApprove.ID)"
            />
          </div>
        </div>
        <!-- end flex-2 -->

        <div class="add-limit-approver" @click="addAprover()">
          + Add limit &amp; approver
        </div>

        <div class="flex-2">
          <div class="form-field">
            <date-picker
              class="date"
              title="Factor start month"
              :addCustom="'month'"
              :isRequired="isRequiredStatus"
              v-model="StartDate"
              @clear="StartDate = null"
            />
          </div>
          <div class="form-field">
            <date-picker
              class="date"
              title="Factor end month"
              :addCustom="'month'"
              :isRequired="isRequiredStatus"
              v-model="EndDate"
              @clear="EndDate = null"
            />
          </div>
        </div>
        <!-- end flex-2 -->

        <div class="bonus-note">
          <custom-text-area title="Note here ..." :rows="5" v-model="addNote" />
        </div>

        <div class="flex-end">
          <div class="save-button-div">
            <button class="save-button" @click="saveDraft">Save Draft</button>
          </div>
          <div class="save-button-div-color">
            <button class="save-button" @click="Commit">Commit</button>
          </div>
        </div>
      </custom-popup>
    </form>

    <delete-item
      @delete="deleteRow"
      @close="deletePopupVisible = false"
      v-if="deletePopupVisible"
      :text="`Would you like to delete the Bonus limit?`"
    />
  </div>
</template>   
<script>
import { getDateString, CETToTimestamp } from "@/Helpers/HelperFunctions";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup.vue";
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import DeleteItem from "@/components/ReusableComponents/DeleteItem/DeleteItem.vue";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue";
import ChoosePersons from "@/components/ReusableComponents/ChoosePersons/choosePersons.vue";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue";
import DateRange from "@/components/ReusableComponents/DateRange/DateRange";
import CustomTextArea from "@/components/ReusableComponents/CustomTextArea/CustomTextArea.vue";
import axios from "@/Helpers/AxiosInstance.js";
export default {
  created() {
    this.getDepartments();
    this.getBonusLimit();
    this.getSalaryFactorStatuses();
  },
  components: {
    CustomPopup,
    CustomInput,
    CustomSnackbar,
    DeleteItem,
    CustomSelect,
    ChoosePersons,
    DatePicker,
    DateRange,
    CustomTextArea,
  },
  data() {
    return {
      create: false,
      popupVisible: false,
      deletePopupVisible: false,
      snackbarVisible: false,
      snackbarPurpose: "error",
      snackbarText: "",
      customClickEvent: "",
      fromDate: "",
      toDate: "",
      filterfromDate: "",
      filtertoDate: "",
      statusListOptions: [],
      statusFilter: "",
      departmentFilter: "",
      searchBonusLimit: "",
      isRequiredStatus: true,

      bonusLimitList: [],
      departmentListOptions: [],
      departmentGrossSalary: "",
      departmentHeadListOptions: [],
      departmentsSalaryDetails: "",
      monthlyBudgetIncomeTax: "",
      monthlyBudgetPension: "",
      monthlyBudgetNet: "",
      bonusLimitStatus: "",
      selectedBonusLimit: "",
      serviceName: "",
      isOpened: false,

      department: "",
      monthlyBudget: "",
      bonusLimitApprovers: [],
      StartDate: null,
      EndDate: null,
      addNote: "",
    };
  },
  methods: {
    popUpVisible(status) {
      this.department = "";
      this.monthlyBudget = "";
      this.bonusLimitApprovers = [];
      this.StartDate = null;
      this.EndDate = null;
      this.addNote = "";
      this.departmentGrossSalary = "";
      this.popupVisible = status;
    },
    getBonusLimit() {  
      axios.get(`salary/GetDepartmentBonusLimits?DepartmentID=${this.departmentFilter}&SearchTerm=${this.searchBonusLimit}&FromDate=${this.filterfromDate}&ToDate=${this.filtertoDate}&Status=${this.statusFilter}`).then((response) => {
         this.bonusLimitList = response.data.Value.BonusLimitWithApprovers 
      });
    },
    createBonusLimit() {
      let objectData = {
        departmentID: this.department,
        amount: this.monthlyBudget,
        paymentStartDate: this.StartDate,
        paymentEndDate: this.EndDate,
        note: this.addNote,
        status: this.bonusLimitStatus,
        bonusLimitsWithApprovers: this.bonusLimitApprovers,
      };

      this.serviceName = "salary/CreateDepartmentBonusLimit";
      if (!this.create) {
        objectData.bonusLimitID = this.selectedBonusLimit;
        this.serviceName = "salary/UpdateDepartmentBonusLimit";
      }

      axios.post(this.serviceName, objectData).then((resp) => {
        if (!resp.data.ErrorMsg) {
          this.popupVisible = false;
          this.department = "";
          this.monthlyBudget = "";
          this.bonusLimitApprovers = [];
          this.StartDate = null;
          this.EndDate = null;
          this.addNote = "";
          this.getBonusLimit();
        } else {
          this.snackbarVisible = true;
          this.snackbarText = resp.data.ErrorMsg;
          this.snackbarPurpose = "error";
        }
      });
    },
    editBonusLimit(id) {
      this.create = false;
      this.selectedBonusLimit = id;
      this.isOpened = true;
      axios.get(`salary/GetDepartmentBonusLimits?ID=${id}`).then((response) => {
        this.department =
          response.data.Value.BonusLimitWithApprovers[0].DepartmentID;
        this.monthlyBudget =
          response.data.Value.BonusLimitWithApprovers[0].Amount;
        this.bonusLimitApprovers =
          response.data.Value.BonusLimitWithApprovers[0].Approvers;
        this.StartDate =
          response.data.Value.BonusLimitWithApprovers[0].PaymentStartDate;
        this.EndDate =
          response.data.Value.BonusLimitWithApprovers[0].PaymentEndDate;
        this.addNote = response.data.Value.BonusLimitWithApprovers[0].Note;
        this.loadedDepartmentHead();
        this.popupVisible = true;
      });
    },
    deleteBonusLimit (id)
    {
      this.selectedBonusLimit = id;
      this.deletePopupVisible = true;
    },
    deleteRow() {
      var data = {
        BonusLimitID: this.selectedBonusLimit,
        delete: true,
      };   
      axios.post("salary/UpdateDepartmentBonusLimit", data).then((resp) => {
          this.deletePopupVisible = false;
          if (!resp.data.ErrorMsg) {
            this.snackbarText = "Deleted Successfully!";
            this.snackbarPurpose = "success";            
            this.snackbarVisible = true;
            this.getBonusLimit();
          } else {
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
            this.snackbarPurpose = "error";
          }
        })
        .catch(() => {
          this.snackbarVisible = true;
          this.snackbarText = "Error";
          this.snackbarPurpose = "error";
        });
    },
    getDepartments() {
      axios.get(`departments/GetDepartments`).then((resp) => {
        this.departmentListOptions = resp.data.Value.map((el) => ({
          title: el.Name,
          value: el.ID,
        }));
      });
    },
    getDepartmentsGrossSalary() {
      if (this.department) {
        axios
          .get(
            `salary/GetDepartmentsGrossSalary?DepartmentID=${this.department}`
          )
          .then((resp) => {
            this.departmentGrossSalary = resp.data.Value[0].DepSalary;
          });
      }
    },
    async getDepartmentHead() {
      if (this.department) {
        await axios
          .get(`salary/GetDepartmentHead?DepartmentID=${this.department}`)
          .then((resp) => {
            this.departmentHeadListOptions = resp.data.Value.map((el) => ({
              title: el.HeadFirstName + " " + el.HeadLastName,
              value: el.HeadID,
              selected: this.bonusLimitApprovers.find(
                ({ Person }) => Person === el.HeadID
              )
                ? true
                : false,
            }));
          });
      }
    },
    getDepartmentsSalaryDetails() {
      if (this.department && this.monthlyBudget) {
        axios
          .get(
            `salary/GetDepartmentsSalaryDetails?DepartmentID=${this.department}&GrossSalary=${this.monthlyBudget}`
          )
          .then((resp) => {
            this.departmentsSalaryDetails = resp.data.Value.DepSalary;
            this.monthlyBudgetIncomeTax = resp.data.Value[0].IncomeTax;
            this.monthlyBudgetPension = resp.data.Value[0].Pension;
            this.monthlyBudgetNet = resp.data.Value[0].Net;
          });
      }
    },
    getSalaryFactorStatuses() {
      axios.get(`salary/GetDepartmentBonusLimitStatuses`).then((resp) => {
        this.statusListOptions = resp.data.Value.map((el) => ({
          title: el.StatusName,
          value: el.ID,
        }));
      });
    },
    addAprover() {
      this.bonusLimitApprovers.push({
        Persons: [],
        Limit: "",
        personsArray: this.departmentHeadListOptions,
      });
      this.getDepartmentHead();
    },
    saveDraft() {
      this.bonusLimitStatus = null
      this.isRequiredStatus = false
    },
    Commit() {
      this.bonusLimitStatus = true
      this.isRequiredStatus = true
    },
    convertDate(date) {
      return getDateString(CETToTimestamp(date), "dd MMM yyyy");
    },
    deleteApprover(id) {
      let index = this.bonusLimitApprovers
        .map((x) => {
          return x.ID;
        })
        .indexOf(id);
      this.bonusLimitApprovers.splice(index, 1);
    },
    customClick(index) {
      this.customClickEvent = index;
    },
    personClickHanlder(el) {
      el.selected = !el.selected;
      let indexKey = this.customClickEvent;
      const exist = this.bonusLimitApprovers[indexKey].Persons.includes(
        el.value
      );
      if (exist) {
        this.bonusLimitApprovers[indexKey].Persons = this.bonusLimitApprovers[
          indexKey
        ].Persons.filter((element) => element != el.value);
      } else {
        this.bonusLimitApprovers[indexKey].Persons.push(el.value);
      }
    },
    loadedDepartmentHead() {
      this.bonusLimitApprovers.forEach((el, index) => {
        el.personsArray = this.departmentHeadListOptions.map((elem) => ({
          selected: el.Persons.includes(elem.value) ? true : false,
          title: elem.title,
          value: elem.value,
        }));
      });
    },
  },
  watch: {
    fromDate() {
      this.filterfromDate = new Date(this.fromDate)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      this.filtertoDate = new Date(this.toDate)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      this.getBonusLimit();
    },
    departmentFilter(to, from) {
      this.departmentFilter = to;
      this.getBonusLimit();
    },
    searchBonusLimit(to, from) {
      this.searchBonusLimit = to;
      this.getBonusLimit();
    },
    department(to, from) {
      this.department = to;
      this.monthlyBudgetIncomeTax = ''
      this.monthlyBudgetPension = ''
      this.monthlyBudgetNet = ''
      if (from) this.bonusLimitApprovers = [];
      this.getDepartmentsGrossSalary();
      this.getDepartmentHead().then(this.loadedDepartmentHead);
    },
    monthlyBudget(to, from) {
      this.monthlyBudget = to;
      this.getDepartmentsSalaryDetails();
    },
    statusFilter(to, from) {
      this.statusFilter = to;
      this.getBonusLimit();
    },
  },
};
</script>
<style lang="scss" scoped>
.background {
  overflow: hidden;
}
.payroll-bonus-limit {
  height: 100%;
  ::v-deep .container {
    width: 100%;
  }
  ::v-deep .popup {
    height: auto;
  }
  .essentials {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 15 * $rem;
    padding: 0 1.75rem;
    margin-top: 1.875rem;

    form {
      margin-right: 15 * $rem;
    }
    .ess-container {
      display: flex;
      justify-content: space-between;

      .filter-div {
        width: 12.938rem;
        margin: 0 1.275rem 0.0625rem 0;
      }
      & > .add {
        height: 2.375rem;
        margin: 0.0625rem 1.275rem 0.0625rem 0;
        padding: 0.5rem 2rem 0rem;
        border-radius: 0.1875rem;
        background-color: #3f46bf;
        cursor: pointer;

        span {
          -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
          font-size: 0.75rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: #ededfc;
        }
      }
    }

    .search {
      position: relative;
      input {
        width: 12.5rem;
        height: 2.375rem;
        background-color: #fff;
        border: 0;
        outline: none;
        border-radius: 0.375rem;
        font-weight: 300;
        font-size: 0.875rem;
        color: #9696b9;
        padding: 0 2.1875rem 0 0.9375rem;
      }
      img {
        position: absolute;
        height: 1.3125rem;
        width: 1.3125rem;
        right: 0.625rem;
        top: 0.5rem;
      }
    }
  }
}
  .flex-2 {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
    .form-field {
      width: 48%;
    }
    .form-field-delete {
      width: 47%;
    }
    ::v-deep .el-input {
      width: 100%;
    }
    ::v-deep .container {
      width: 100%;
    }
  }
  .flex-3 {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
    .form-field {
      width: 30%;
    }
    ::v-deep .el-input {
      width: 100%;
    }
  }
  .form-field,
  .form-field-delete {
    width: 100%;
    label {
      width: 100%;
    }
  }
  .remove-approver {
    width: 1.25rem;
    cursor: pointer;
  }
  .save-button-div {
    width: 20%;
    text-align: right;
    .save-button {
      background-color: #ffffff;
      color: #6956d6;
      border: #6956d6 0.0625rem solid;
    }
  }
  .save-button-div-color {
    width: 20%;
    text-align: right;
    .save-button {
      background-color: #6956d6;
      color: #fcfcfc;
    }
  }
  .save-button {
    height: 3rem;
    width: 6.25rem;
    border-radius: 0.375rem;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 3.75rem;
  }
  .bonus-title {
    font-size: 0.75rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #283593;
  }
  .flex-between {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .flex-end {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: end;
  }
  .bonus-total-salary {
    width: 48%;
    .bonus-value {
      font-size: 0.875rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #959bc9;
      margin-top: 0.625rem;
    }
    .red {
      font-size: 0.875rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #c44f7d;
    }
  }
  .add-limit-approver {
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #283593;
    margin: 1.25rem 0 3.75rem 1rem;
    cursor: pointer;
    display: inline-block;
  }
  .bonus-note {
    margin-top: 1.25rem;
  }
  .bonus-limit-list {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    height: calc(100% - 8.125rem);
    overflow: auto;
    .bonus-limit-box-fade {
      opacity: 0.5;
    }
    .bonus-limit-box {
      width: 21.375rem;
      height: fit-content;
      min-height: 14.0625rem;
      border-radius: 0.375rem;
      background-color: #fff;
      overflow: hidden;
      margin: 1.6875rem;
      padding-bottom: 0.625rem;
      .bonus-limit-box-header {
        width: 100%;
        height: 3.125rem;
        display: flex;
        justify-content: space-between;
        background-color: #dcdde6;
        position: relative;
        .bonus-limit-box-title {
          max-width: 16.25rem;
          padding-left: 1.875rem;
          -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
          font-size: 1rem;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.35;
          letter-spacing: normal;
          color: #5963ab;
          overflow: hidden;
          span {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 0.875rem;
                display: inline-block;
          }
        }
        .factories-box-edit {
           img:first-child {
             width: 1.6875rem;
             margin: 0.75rem 0.3125rem 0 0rem;
             cursor: pointer;
           }
           img:last-child {
             width: 1.6875rem;
             margin: 0.75rem 0.9375rem 0 0rem;
             cursor: pointer;
           }
         }
        .bonus-limit-box-img {
          position: absolute;
          right: 0rem;
          top: 3.125rem;
        }
      }
      .bonus-limit-info {
        padding: 0 1.875rem;
        .bonus-limit-info-div {
          margin-top: 0.625rem;
          span:first-child {
            width: 45%;
            font-size: 0.8125rem;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: left;
            color: #5963ab;
            display: inline-block;
          }
          span:last-child {
            width: 55%;
            font-size: 0.8125rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: left;
            color: #5963ab;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .bonus-limit-progress-bar {
          position: relative;
          width: 100%;
          height: 0.625rem;
          border: solid 0.0625rem #1de9b6;
          border-radius: 0.375rem;
          background-color: #fff;
          margin-top: 1.8125rem;
          span {
            position: absolute;
            top: -1.375rem;
            font-size: 1 0.25rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal;
            color: #959bc9;
          }
          .bonus-limit-progress-bar-content {
            height: 0.5rem;
            background-color: #1de9b6;
          }
        }
      }
    }
  }

</style>