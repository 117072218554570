import { render, staticRenderFns } from "./SlideBar.vue?vue&type=template&id=81deacdc&scoped=true"
import script from "./SlideBar.vue?vue&type=script&lang=js"
export * from "./SlideBar.vue?vue&type=script&lang=js"
import style0 from "./SlideBar.vue?vue&type=style&index=0&id=81deacdc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81deacdc",
  null
  
)

export default component.exports