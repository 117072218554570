<template>
  <div v-show="isActive" class="tabs-content">
    <ul>
      <router-link
        tag="li"
        :to="route.path"
        v-for="(route, index) in routes"
        :key="index"
      >
        {{ route.title }}
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: true,
      tabs: [
        {
          title: 'Payroll',
          path: 'PayrollDashboard',
          permissions: ['Super Admin', 'CFO', 'HR', 'Manager'],
        },
        {
          title: 'Salary Factors',
          path: 'SalaryFactors',
          permissions: ['Super Admin', 'CFO'],
        },
        {
          title: 'Persons & Factors',
          path: 'Persons&Factors',
          permissions: ['Super Admin', 'CFO', 'HR', 'Manager'],
        },
        {
          title: 'Bonus Limits',
          path: 'BonusLimits',
          permissions: ['Super Admin'],
        },
        {
          title: 'Payroll Preparation',
          path: 'PayrollPreparation',
          permissions: ['Super Admin', 'CFO', 'HR', 'Manager'],
        },
        {
          title: 'Companies',
          path: 'Companies',
          permissions: ['Super Admin', 'CFO'],
        },
        {
          title: 'Analytics',
          path: 'Analytics',
          permissions: ['Super Admin', 'CFO', 'Manager'],
        },
        {
          title: 'Uploaded Files',
          path: 'filesList',
          permissions: ['Super Admin', 'CFO'],
        },
      ],
    }
  },
  methods: {},
  computed: {
    routes() {
      return this.tabs.filter((tab) => {
        if (this.isSuperAdmin) return tab.permissions.includes('Super Admin')
        if (this.isHR) return tab.permissions.includes('HR')
        if (this.isManager) return tab.permissions.includes('Manager')
        if (this.isCfo) return tab.permissions.includes('CFO')
      })
    },

    isSuperAdmin() {
      return this.$store.getters.hasRole('Super Admin')
      // return true;
    },
    isCfo() {
      return this.$store.getters.hasRole('CFO')
    },
    isHR() {
      return this.$store.getters.hasRole('HR')
    },
    isMember() {
      return this.$store.getters.hasRole('Member')
    },
    isManager() {
      return this.$store.getters.hasRole('Manager')
      // return true;
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs-content {
  margin-left: 2.575rem;
  ul {
    display: flex;
  }
  li {
    list-style: none;
    margin-right: 110 * $rem;
    font-size: 16 * $rem;
    color: $blue-primary;
    cursor: pointer;
    padding-bottom: 0.625rem;
    &.router-link-active {
      border-bottom: 4px solid #1de9b6 !important;
      border: transparent;
      font-weight: 500;
    }
  }
}
</style>
