<template>
  <div class="terms-table-content">
    <!-- <div class="thead-wrapper">
      <table>
        <thead>
          <tr>
            <th v-if="userView == false && profileTerms == false">#</th>
            <th v-if="userView == false && profileTerms == false">Employee</th>
            <th>Year</th>
            <th>Paid</th>
            <th>Not Paid</th>
            <th>Day Off</th>
            <th>Sick Days</th>
            <th>Maternity Leave</th>
            <th v-if="canEdition"></th>
          </tr>
        </thead>
      </table>
    </div> -->

    <div class="tbody-wrapper" ref="gridContainer" @scroll="scrollHandler">
      <table>
        <thead :class="{ fixedHeader: vacationStatistics }">
          <tr>
            <th v-if="userView == false && profileTerms == false">#</th>
            <th v-if="userView == false && profileTerms == false">Employee</th>
            <th class="withFilter" v-if="!vacationStatistics">
              Year
              <employee-filter
                v-if="withFilters"
                slot="searchYear"
                class="filter"
                @select="(val) => $emit('filterParam', val)"
                :list="
                  data
                    .sort((a, b) => a.Person.Year - b.Person.Year)
                    .map((el) => el.Person.Year.toString())
                "
              />
            </th>
            <th v-if="vacationStatistics">Used Days Count</th>
            <th>Paid</th>
            <th>Not Paid</th>
            <th>Day Off</th>
            <th>Sick Days</th>
            <th>Maternity Leave</th>
            <th>Working from home</th>
            <th v-if="vacationStatistics">Business trip</th>
            <th v-if="vacationStatistics" class="for-background"></th>
            <th v-if="canEdition"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(el, ind) in data" :key="el.index">
            <td v-if="userView == false && profileTerms == false">
              {{ ind + 1 }}
            </td>
            <td v-if="userView == false && profileTerms == false">
              <span v-if="!vacationStatistics">
                {{ el.Person.FirstName + ' ' + el.Person.LastName }}
              </span>
              <span v-else>
                {{ el.FirstName + ' ' + el.LastName }}
              </span>
            </td>
            <td>
              <span v-if="el.canEdit == false && !vacationStatistics">
                {{ el.Person.Year }}
              </span>
              <span v-if="vacationStatistics">{{ el.UsedDaysCnt }}</span>
              <div class="input-content" v-if="el.canEdit == true">
                <custom-input
                  :title="el.Person.Year.toString()"
                  v-model="newType.Year"
                />
              </div>
            </td>
            <td
              v-for="rest in (el.Vacs || el.Restrictions[0].Restrictions )"
              :key="rest.VacationRestrictionID || el.index"
            >
              <div
                v-if="el.canEdit == false"
                class="dot"
                :class="[
                  { paidColor: rest.VacationTypeName == 'Paid' },
                  { notpaidColor: rest.VacationTypeName == 'NotPaid' },
                  { dayoffColor: rest.VacationTypeName == 'DayOff' },
                  { sickdaysColor: rest.VacationTypeName == 'SickDays' },
                  {
                    maternityLeaveColor:
                      rest.VacationTypeName == 'Maternity Leave',
                  },
                  {
                    fromHomeColor: rest.VacationType == 6,
                  },
                ]"
              ></div>

              <span v-if="el.canEdit == false && !vacationStatistics">
                {{ rest.AnnualQuantity }}
              </span>
              <div
                class="vacation-stats"
                v-if="el.canEdit == false && vacationStatistics"
              >
                <template v-if="rest.VacationType !== 7">
                  <span class="free-days">{{ rest.Leftdays }}</span>
                  <span>/</span>
                  <span class="used-days">{{ rest.UsedDays }}</span>
                </template>
                <template v-else>
                  <span class="used-days">
                    {{ rest.UsedDays }}
                  </span>
                </template>
              </div>
              <div
                class="input-content"
                v-if="el.canEdit == true && userView == false"
              >
                <custom-input
                  :title="rest.AnnualQuantity.toString()"
                  v-model="newType.paid"
                  v-if="rest.VacationTypeName == 'Paid'"
                />
                <custom-input
                  :title="rest.AnnualQuantity.toString()"
                  v-model="newType.notPaid"
                  v-if="rest.VacationTypeName == 'NotPaid'"
                />
                <custom-input
                  :title="rest.AnnualQuantity.toString()"
                  v-model="newType.dayOff"
                  v-if="rest.VacationTypeName == 'DayOff'"
                />
                <custom-input
                  :title="rest.AnnualQuantity.toString()"
                  v-model="newType.sickDays"
                  v-if="rest.VacationTypeName == 'SickDays'"
                />
                <custom-input
                  :title="rest.AnnualQuantity.toString()"
                  v-model="newType.maternityLeave"
                  v-if="rest.VacationTypeName == 'Maternity Leave'"
                />
              </div>
            </td>
            <td class="td-actions">
              <div
                class="actions"
                v-if="
                  (userView == false || canEdition == true) &&
                  el.Person.Year >= currentYear
                "
              >
                <span class="icon-span" v-if="el.canEdit == false">
                  <img
                    @click="$emit('active-edit', el)"
                    src="@/assets/icons/edit.svg"
                    alt="edit"
                  />
                </span>
                <div class="material-icons-content" v-if="el.canEdit == true">
                  <span
                    @click="$emit('checked', el, el.Restrictions, newType)"
                    class="material-icons success"
                  >
                    check
                  </span>
                  <span
                    @click="$emit('cancel', el)"
                    class="material-icons cancel"
                  >
                    close
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import CustomInput from '@/components/ReusableComponents/CustomInput/CustomInput.vue'
import EmployeeFilter from '@/components/Employees/EmployeeFilter.vue'

export default {
  props: {
    data: Array,
    userView: Boolean,
    activeEditICon: Boolean,
    canEdition: Boolean,
    profileTerms: Boolean,
    scrollTop: Boolean,
    vacationStatistics: Boolean,
    withFilters: {
      type: Boolean,
      default: false,
    },
    newRestriction: {
      restId: null,
      quantity: null,
      Year: null,
    },
  },
  data() {
    return {
      newType: {
        paid: '',
        notPaid: '',
        dayOff: '',
        sickDays: '',
        maternityLeave: '',
        Year: '',
      },
    }
  },
  components: {
    CustomInput,
    EmployeeFilter,
  },

  methods: {
    scrollHandler(ev) {
      var el = ev.srcElement
      if (el.scrollTop >= el.scrollHeight - el.clientHeight) {
        this.$emit('scrolled-bottom')
      }
    },
  },
  computed: {
    currentYear() {
      let year = new Date().getFullYear()
      return year
    },
  },
  watch: {
    scrollTop() {
      if (!this.scrollTop) return

      this.$refs.gridContainer.scrollTop = 0

      this.$emit('scrolled-top')
      // setTimeout(() => {}, 300)
    },
  },
}
</script>

<style lang="scss" scoped>
.vacation-stats {
  display: inline-block;
  margin-left: 2px;
}
table {
  color: $blue-primary;
  // table-layout: fixed;
  text-align: left;
  border-collapse: collapse;
  thead {
    // text-align: left;

    &.fixedHeader {
      background: rgb(214, 216, 227);
      position: sticky;
      top: 0;
      z-index: 10;
    }
    tr {
      height: 60px;
      th {
        font-weight: 400;
        white-space: nowrap;
        &.withFilter {
          position: relative;
          .filter {
            left: 2rem;
            top: 50%;
            transform: translate(0, -50%);
            position: absolute;
          }
        }
      }
      th:nth-child(1) {
        padding-left: 80px;
        width: 100px;
      }
      th:nth-child(2) {
        padding-left: 20px;
        width: 300px;
      }
      th:nth-child(3) {
        width: 215px;
      }
      th:nth-child(4) {
        width: 156px;
      }
      th:nth-child(5) {
        width: 159px;
      }
      th:nth-child(6) {
        width: 160px;
      }
      th:nth-child(7) {
        width: 164px;
      }
    }
  }
  tbody {
    tr {
      height: 60px;

      td {
        font-weight: 300;
        width: 190px;
      }
      td:nth-child(1) {
        // width: 50px;
        padding-left: 78px;
      }
      td:nth-child(2) {
        padding-left: 20px;
        // width: 350px;
      }
      td:nth-child(3) {
        width: 250px;
      }
    }
    tr:nth-child(2n + 1) {
      background: rgba(40, 53, 147, 0.03);
    }
    .actions {
      display: flex;
      cursor: pointer;

      .icon-span {
        height: 35px;
        width: 35px;
      }
    }
    .td-actions {
      width: 12.5rem;
      text-align: right;
      padding-left: 3.25rem;
      padding-right: 1rem;
    }
  }
}
.tbody-wrapper {
  height: 660px;
  overflow: auto;
  padding-left: 7px;
}
.input-content {
  label {
    width: 80px;
  }
}
::v-deep label {
  span.title {
    font-size: 14px;
    margin-left: 7px;
  }
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 100%;

  display: inline-block;
}
.paidColor {
  background-color: #89e9e6;
}
.notpaidColor {
  background-color: #f0c6d6;
}
.dayoffColor {
  background-color: #c5c5ec;
}
.sickdaysColor {
  background-color: #efdbce;
}
.maternityLeaveColor {
  background-color: #dfecc5;
}
.fromHomeColor {
  background-color: #adadb9;
}
.used-days {
  color: #0e8f0e;
  font-weight: 400;
}
</style>
