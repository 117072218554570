<template>
  <div class="background">
    <div class="schedules-container" v-if="scheduleID">
      <div class="week-schedule-content" v-if="scheduleType == 1">
        <div class="total">
          Weekly Working Hours

          <span>
            {{ totalWorkingHours }}
          </span>
        </div>
        <week-schedule
          :scheduleID="scheduleID"
          v-if="scheduleID"
          :noEdit="true"
          @totalHours="(hr) => (totalWorkingHours = hr)"
        />
      </div>
      <div class="flexible" v-if="scheduleType == 2">
        <flexible-schedule
          :noEdit="true"
          :employeeID="$store.state.profile.ID"
        />
      </div>
    </div>
    <div v-else class="no-schedule-container">
      <span>No Schedule Assigned</span>
    </div>
  </div>
</template>

<script>
import WeekSchedule from "@/components/WeekSchedule/WeekSchedule";
import FlexibleSchedule from "@/components/FlexibleSchedule/FlexibleSchedule";
import axios from "@/Helpers/AxiosInstance";

export default {
  created() {
    this.getPersonSchedule();
  },
  components: {
    WeekSchedule,
    FlexibleSchedule,
  },
  data() {
    return {
      scheduleID: "",
      scheduleType: "",
      totalWorkingHours: 0,
    };
  },
  methods: {
    getPersonSchedule() {
      axios
        .get("Schedules/GetPersonSchedules", {
          params: { Person: this.$store.state.profile.ID },
        })
        .then((resp) => {
          if (resp.data.ErrorMsg || !resp.data.Value || !resp.data.Value.length)
            return;
          this.scheduleID = resp.data.Value[0].Schedule;
          this.scheduleType = resp.data.Value[0].ScheduleType;
        });
    },
  },
};
</script>

<style scoped lang='scss'>
.background {
  padding: 30px;
}
.week-schedule-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .total {
    font-weight: 300;
    color: #283593;
    font-size: 1.125rem;
    margin-bottom: 20px;
    span {
      font-weight: 700;
    }
  }
}
.schedules-container {
  width: 100%;
  height: 100%;
}
.no-schedule-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: $blue-primary;
    font-weight: 600;
    font-size: 60px;
    opacity: 0.5;
  }
}
</style>