<template>
  <div
    class="background"
    :class="{ offersVisible: !offersVisible }"
    @click="audienceVisible = 0"
  >
    <section class="banner-section">
      <div
        :style="{
          backgroundImage: `url(data:image/jpeg;base64,${mainBanner})`,
        }"
        class="left-side"
      ></div>
    </section>
    <section class="offers-section">
      <div class="offer-header">
        <span>Corporate Offers</span>
        <div
          class="offers-popup-button"
          @click="offersVisible = !offersVisible"
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="offer-blocks-content">
        <swiper ref="swiper" :options="swiperOptions">
          <swiper-slide
            class="single-offer"
            v-for="offer in offersByTime"
            :key="offer.Offer"
          >
            <div class="offer-wrapper" @click="getSingleOffer(offer.Offer)">
              <div
                class="offer-banner"
                :style="{
                  backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${offer.Offer}&PlacementType=${offer.PlacementType})`,
                }"
              >
                <span
                  v-if="offer.BannerTitle"
                  class="banner-title"
                  v-html="offer.BannerTitle"
                ></span>
              </div>

              <div class="offer-info">
                <p class="offer-title">{{ offer.Title }}</p>
                <div class="offer-desc">
                  {{ htmlToText(offer.Description) }}
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div
          v-if="offers.length > 2 && offersVisible"
          class="swiper-button-next"
          slot="button-next"
          @click="next(true)"
        ></div>
        <div
          v-if="offers.length > 2 && offersVisible"
          class="swiper-button-prev"
          slot="button-prev"
          @click="prev(true)"
        ></div>
      </div>
    </section>
    <section ref="newsContainer" class="news-polls-section">
      <ul class="column-one">
        <li
          v-for="(el, index) in itemsForColumnOne"
          :key="index"
          :class="{ itemWithBanner: index % 2 }"
        >
          <div class="persons-banner-wrapper" @click="getSinglePollOrNews(el)">
            <img
              :src="require('@/assets/icons/poll-flag-user.svg')"
              v-if="el.isPoll"
            />
            <div
              class="banner"
              v-if="index % 2"
              :style="{
                backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${
                  el.Poll || el.News
                }&PlacementType=${el.PlacementType})`,
              }"
            ></div>
            <div class="info-wrapper">
              <div class="date">{{ getDateString(el.PublishDate) }}</div>
              <h2>{{ el.Title }}</h2>
              <p>{{ htmlToText(el.Description) }}</p>
            </div>
          </div>
          <div class="persons-content" @click.stop="audienceClickHandler(el)">
            <div
              class="avatar"
              v-for="person in el.Visibility.slice(0, 6)"
              :key="person.Person"
              :style="{
                'background-image': `url(data:image/jpeg;base64,${person.File})`,
              }"
            ></div>
            <div class="count" v-if="el.Visibility.length > 6">
              +{{ el.Visibility.length - 6 }}
            </div>
            <audience-list
              :data="listOfPersons"
              :placementId="el.News || el.Poll"
              :audienceVisible="audienceVisible"
            />
          </div>
        </li>
      </ul>
      <ul class="column-two">
        <li
          v-for="(el, index) in itemsForColumnTwo"
          :key="index"
          :class="{ itemWithBanner: !(index % 2) }"
        >
          <div class="persons-banner-wrapper" @click="getSinglePollOrNews(el)">
            <img
              :src="require('@/assets/icons/poll-flag-user.svg')"
              v-if="el.isPoll"
            />
            <div
              class="banner"
              v-if="!(index % 2)"
              :style="{
                backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${
                  el.Poll || el.News
                }&PlacementType=${el.PlacementType})`,
              }"
            ></div>

            <div class="info-wrapper">
              <div class="date">{{ getDateString(el.PublishDate) }}</div>
              <h2>{{ el.Title }}</h2>
              <p>{{ htmlToText(el.Description) }}</p>
            </div>
          </div>
          <div class="persons-content" @click.stop="audienceClickHandler(el)">
            <div
              class="avatar"
              v-for="person in el.Visibility.slice(0, 6)"
              :key="person.Person"
              :style="{
                'background-image': `url(data:image/jpeg;base64,${person.File})`,
              }"
            ></div>
            <div class="count" v-if="el.Visibility.length > 6">
              +{{ el.Visibility.length - 6 }}
            </div>

            <audience-list
              :data="listOfPersons"
              :placementId="el.News || el.Poll"
              :audienceVisible="audienceVisible"
            />
          </div>
        </li>
      </ul>
      <ul class="column-three">
        <li v-for="(el, index) in itemsForColumnThree" :key="index">
          <div class="persons-banner-wrapper" @click="getSinglePollOrNews(el)">
            <img
              :src="require('@/assets/icons/poll-flag-user.svg')"
              v-if="el.isPoll"
            />
            <div class="info-wrapper">
              <div class="date">{{ getDateString(el.PublishDate) }}</div>
              <h2>{{ el.Title }}</h2>
              <p>{{ htmlToText(el.Description) }}</p>
            </div>
          </div>

          <div class="persons-content" @click.stop="audienceClickHandler(el)">
            <div
              class="avatar"
              v-for="person in el.Visibility.slice(0, 6)"
              :key="person.Person"
              :style="{
                'background-image': `url(data:image/jpeg;base64,${person.File})`,
              }"
            ></div>
            <div class="count" v-if="el.Visibility.length > 6">
              +{{ el.Visibility.length - 6 }}
            </div>
            <audience-list
              :data="listOfPersons"
              :placementId="el.News || el.Poll"
              :audienceVisible="audienceVisible"
            />
          </div>
        </li>
      </ul>
      <ul class="column-four">
        <li
          v-for="(el, index) in itemsForColumnFour"
          :key="index"
          :class="{ itemWithBanner: index % 2 }"
        >
          <div class="persons-banner-wrapper" @click="getSinglePollOrNews(el)">
            <img
              :src="require('@/assets/icons/poll-flag-user.svg')"
              v-if="el.isPoll"
            />
            <div
              class="banner"
              v-if="index % 2"
              :style="{
                backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${
                  el.Poll || el.News
                }&PlacementType=${el.PlacementType})`,
              }"
            ></div>
            <div class="info-wrapper">
              <div class="date">{{ getDateString(el.PublishDate) }}</div>
              <h2>{{ el.Title }}</h2>
              <p>{{ htmlToText(el.Description) }}</p>
            </div>
          </div>
          <div class="persons-content" @click.stop="audienceClickHandler(el)">
            <div
              class="avatar"
              v-for="person in el.Visibility.slice(0, 6)"
              :key="person.Person"
              :style="{
                'background-image': `url(data:image/jpeg;base64,${person.File})`,
              }"
            ></div>
            <div class="count" v-if="el.Visibility.length > 6">
              +{{ el.Visibility.length - 6 }}
            </div>
            <audience-list
              :data="listOfPersons"
              :placementId="el.News || el.Poll"
              :audienceVisible="audienceVisible"
            />
          </div>
        </li>
      </ul>
    </section>
    <div class="offer-popup" @click.stop="downloadAllVisible = false">
      <custom-popup
        :visible="offerDetailVisible"
        @close="offerDetailVisible = false"
        v-if="offerDetails.offer"
      >
        <div class="banner-desc-content">
          <div
            class="banner-content"
            :style="{
              backgroundImage: `url(data:image/jpeg;base64,${offerDetails.offer.Banner})`,
            }"
          ></div>
          <div class="description-content">
            <p class="title" v-html="offerDetails.offer.Title"></p>
            <div class="desc-text">
              <span v-html="offerDetails.offer.Description"></span>
            </div>
          </div>
        </div>
        <div class="attachment-content">
          <div class="files">
            <div class="title">
              Attachments ({{ offerDetails.offer.Attachments.length }})
              <div
                class="dots-content"
                @click.stop="downloadAllVisible = !downloadAllVisible"
                v-if="offerDetails.offer.Attachments.length > 0"
              >
                <div></div>
                <div></div>
                <div></div>
                <div
                  class="download-button"
                  @click.stop="downloadAllHandler"
                  v-if="downloadAllVisible"
                >
                  <span>Download all</span>
                </div>
              </div>
            </div>
            <div class="files-content">
              <a
                v-for="(att, index) in offerDetails.offer.Attachments"
                @click="attClickHandler(att)"
                :key="index"
                target="_blank"
              >
                <file-block :uploadedFile="att" />
              </a>
            </div>
          </div>
          <!-- <a
            target="_blank"
            href="http://docs.google.com/gview?url=http://example.com/path/to/your/document.doc&embedded=true"
            >Open your doc file</a
          > -->
          <!-- <iframe src="http://docs.google.com/gview?url=http://example.com/path/to/your/document.doc&embedded=true"></iframe> -->
          <!-- <a
            v-if="offerDetails.offer.Attachment"
            :href="`data:${AttachmentType};base64,` + offerDetails.offer.Attachment"
            download="file name"
            target="_blank"
            class="download-content"
          >
            <span>Download Attachment</span>
            <span class="material-icons"> download </span>
          </a> -->
          <div class="url-content">
            <span v-if="offerDetails.offer.URL"
              >Visit web page:
              <a :href="offerDetails.offer.URL" target="_blank">{{
                offerDetails.offer.URL
              }}</a></span
            >
          </div>
        </div>

        <div class="options-content">
          <p>Options</p>
          <div class="options">
            <swiper ref="swiperTwo" :options="swiperOptionsTwo">
              <swiper-slide
                v-for="(opt, index) in offerDetails.offerOpt"
                :key="opt.OfferOptionID"
                class="single-opt"
                :class="{ clickedOptStyle: opt.active }"
              >
                <div class="for-event" @click="optClicked(opt)">
                  <div
                    class="opt-info-wrapper"
                    :title="
                      opt.OfferOptionDescription.length > 25
                        ? opt.OfferOptionDescription
                        : null
                    "
                  >
                    <div class="left-side">
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div class="right-side">
                      <span>{{ opt.OfferOptionDescription }}</span>
                      <span v-if="opt.FoundedByCompany != 0">{{
                        opt.FoundedByCompany + " " + opt.CurrencyCode
                      }}</span>
                      <span v-if="opt.FoundedByCompany != 0"
                        >Funded by company</span
                      >
                      <span v-if="opt.EmployeeShare != 0">{{
                        opt.EmployeeShare + " " + opt.CurrencyCode
                      }}</span>
                      <span v-if="opt.EmployeeShare != 0">My share</span>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <!-- <hooper-navigation
                v-if="offerDetails.offerOpt.length > 5"
                slot="hooper-addons"
              >
              </hooper-navigation> -->
            </swiper>
            <div
              v-if="offerDetails.offerOpt.length > 5"
              class="swiper-button-next"
              slot="button-next"
              @click="next()"
            ></div>
            <div
              v-if="offerDetails.offerOpt.length > 5"
              class="swiper-button-prev"
              slot="button-prev"
              @click="prev()"
            ></div>
          </div>
        </div>
        <div class="comment-content">
          <textarea
            placeholder="Type your comment"
            cols="30"
            rows="10"
            v-model="offerComment"
          ></textarea>
        </div>
        <div class="button-content">
          <button @click="sendResponseOnOffer">Send Response</button>
        </div>
      </custom-popup>
    </div>
    <div class="poll-popup">
      <custom-popup
        :visible="pollDetailsVisible"
        v-if="poll"
        :flexible-size="true"
        @close="pollDetailsVisible = false"
      >
        <div class="poll">
          <div class="poll__content">
            <div class="banner-details-content">
              <div
                class="poll__banner"
                :style="{
                  'background-image': `url(data:image/jpeg;base64,${poll.file})`,
                }"
              />
              <div class="poll__details">
                <div class="wrapper">
                  <div class="poll-date">
                    Expiry date:
                    {{ getDateString(poll.expiryDate, "dd MMM yyyy") }}
                  </div>
                  <div class="poll__question">
                    {{ poll.question || "question" }}
                  </div>
                  <div class="poll__options">
                    <div
                      class="poll__option"
                      v-for="(option, i) in poll.options"
                      :key="i"
                    >
                      <div class="poll__option-wrapper">
                        <div class="poll__option-index">{{ i + 1 }}</div>
                        <div class="poll__option-name">{{ option.option }}</div>
                      </div>
                      <div
                        :class="[
                          'poll__option-checkbox',
                          {
                            'poll__option-checkbox--selected': selectedOptions.includes(
                              option.id
                            ),
                          },
                        ]"
                        @click="optionClickHandler(option)"
                      >
                        <svg
                          v-if="selectedOptions.includes(option.id)"
                          class="checkbox__mark"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 512 512"
                          xml:space="preserve"
                        >
                          <path
                            fill="#283593"
                            d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
			c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
			C514.5,101.703,514.499,85.494,504.502,75.496z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="poll__comment"
                  v-if="!alreadySelected && !poll.anonymous"
                ></div>
              </div>
            </div>
            <div class="desc-comment-content">
              <div class="poll__description">{{ poll.description }}</div>
              <textarea
                v-if="!poll.anonymous"
                placeholder="Type your comment"
                type="text"
                class="cstm-input"
                v-model="pollComment"
              />
            </div>
          </div>
          <div
            class="poll__button"
            v-if="!alreadySelected"
            @click="makePollChoice"
          >
            Send
          </div>
        </div>
      </custom-popup>
    </div>
    <div class="news-popup">
      <custom-popup
        :visible="newsDetailsVisible"
        @close="newsDetailsVisible = false"
        :flexible-size="true"
      >
        <div class="news" v-if="newsDetails">
          <div class="desc-banner-content">
            <div
              v-if="newsDetails.banner"
              class="news__banner"
              :style="{
                backgroundImage: `url(data:image/jpeg;base64,${newsDetails.banner})`,
              }"
            />
            <div class="desc-content">
              <div class="news__title">
                {{ newsDetails.title }}
              </div>
              <div
                :class="[
                  'news__text',
                  { 'news__text--without-banner': !newsDetails.banner },
                ]"
                v-html="newsDetails.text"
              />
            </div>
          </div>
          <div class="news__audience">
            <div class="news__audience-header">
              <div class="news__audience-header-title">
                <b>Audience</b> <span> {{ audience.length }} </span>
              </div>
            </div>
            <div class="news__audience-items" v-dragscroll>
              <div
                class="news__audience-items-item"
                v-for="person in audience"
                :key="person.PersonID"
              >
                <div
                  v-if="person"
                  class="news__audience-items-item-avatar"
                  :style="{
                    'background-image': `url(data:image/jpeg;base64,${person.File})`,
                  }"
                />
                <div class="news__audience-items-item-name">
                  <p>{{ person.FirstName }}</p>
                  <p>{{ person.LastName }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <span v-else></span>
      </custom-popup>
    </div>

    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
  </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";

// import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import CustomSnackbar from "../ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import FileBlock from "@/components/Profile/FileBlock";
import AudienceList from "./AudienceList.vue";
import { dragscroll } from "vue-dragscroll";
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";

export default {
  directives: {
    dragscroll,
  },
  components: {
    // Swiper,
    // SwiperSlide,
    // Hooper,
    // Slide,
    // HooperNavigation,
    CustomSnackbar,
    CustomPopup,
    AudienceList,
    FileBlock,
  },
  created() {
    this.getOffers();
    this.getNews();
    this.getPolls();
    this.getMainBanner();
    this.loggedUser = this.$store.state.profile.ID;
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 28,
        slidesPerView: 2,
        observer: true,
        observeParents: true,
        parallax: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionsTwo: {
        spaceBetween: 1,
        slidesPerView: 5,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      mainBanner: "",
      loggedUser: "",
      offers: [],
      news: [],
      polls: [],
      offersVisible: true,
      offerDetailVisible: false,
      pollDetailsVisible: false,
      newsDetailsVisible: false,
      audienceVisible: 0,
      offerDetails: {
        offer: null,
        offerOpt: null,
        restrictions: null,
      },
      poll: {},
      audience: [],
      newsDetails: {},
      snackbarPurpose: "",
      snackbarText: "",
      snackbarVisible: false,
      choosenOfferOpt: "",
      offerComment: "",
      pollComment: "",
      alreadySelected: false,
      selectedOptions: [],
      listOfPersons: [],
      topPosition: "",
      leftPosition: "",
      file: "",
      downloadAllVisible: false,
    };
  },
  watch: {
    choosenOfferOpt(to, from) {
      if (Object.entries(from).length > 0) {
        from.active = false;
      }
    },
  },

  methods: {
    getOffers() {
      axios.get("/News/GetOffers").then((resp) => {
        this.offers = resp.data.Value;
        this.offers.forEach((el) => {
          el.PlacementType = "Offer";
        });
      });
    },
    prev(swiperOne) {
      if (swiperOne) {
        this.$refs.swiper.$swiper.slidePrev();
      } else {
        this.$refs.swiperTwo.$swiper.slidePrev();
      }
    },
    next(swiperOne) {
      if (swiperOne) {
        this.$refs.swiper.$swiper.slideNext();
      } else {
        this.$refs.swiperTwo.$swiper.slideNext();
      }
    },

    getMainBanner() {
      axios.get("News/GetPlacementMainBanner").then((resp) => {
        this.mainBanner = resp.data.Value[0].File;
      });
    },
    audienceClickHandler(el) {
      let targetEl = event.currentTarget;
      this.audienceVisible = el.News || el.Poll;

      // this.leftPosition = targetEl.getBoundingClientRect().left;
      // this.topPosition = targetEl.getBoundingClientRect().top;

      this.listOfPersons = [];
      el.Visibility.forEach((person) => {
        this.listOfPersons.push(person);
      });
    },

    getNews() {
      axios.get("News/GetNews").then((resp) => {
        this.news = resp.data.Value.News;
        this.news.forEach((el) => {
          this.$set(el, "isNews", true);
          this.$set(el, "audienceVisible", false);
          el.PlacementType = "News";
        });
      });
    },
    getPolls() {
      axios.get("News/GetPolls").then((resp) => {
        this.polls = resp.data.Value.Polls;
        this.polls.forEach((el) => {
          this.$set(el, "isPoll", true);
          this.$set(el, "audienceVisible", false);
          el.PlacementType = "Poll";
        });
      });
    },
    attClickHandler(el) {
      axios
        .post("files/GetFiles", {
          Files: [
            {
              FileID: el.AttachmentID,
            },
          ],
        })

        .then((resp) => {
          var a = document.createElement("a");
          a.href =
            `data:${el.AttachmentType};base64,` + resp.data.Value[0].File;
          a.download = el.name;
          a.click();
        });
    },
    downloadAllHandler() {
      let files = [];
      this.offerDetails.offer.Attachments.forEach((el) => {
        files.push({
          FileID: el.AttachmentID,
        });
      });
      axios
        .post("files/GetFiles", {
          Files: files,
        })
        .then((resp) => {
          resp.data.Value.forEach((el) => {
            var a = document.createElement("a");
            a.href = `data:${el.FileType};base64,` + el.File;
            a.download = el.FileName;
            a.click();
          });
        });
    },
    sendResponseOnOffer() {
      axios
        .post("News/MakeChoiceOnOffer", {
          OfferOption: this.choosenOfferOpt.OfferOptionID,
          Person: this.loggedUser,
          Comment: this.offerComment,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.choosenOfferOpt = {};
            this.offerComment = "";
            this.snackbarVisible = true;
            this.snackbarPurpose = "success";
            this.snackbarText = "Response was successfully sent";
            this.offerDetailVisible = false;
          }
        });
    },
    getPerson(id) {
      axios
        .get("News/GetVisibilityInfoAboutNews", {
          params: {
            News: id,
          },
        })
        .then((resp) => {
          this.audience = resp.data.Value;
        });
    },
    optClicked(el) {
      this.choosenOfferOpt = el;
      el.active = !el.active;
    },
    getSingleOffer(id) {
      axios.get(`News/GetOfferDetails?Offer=${id}`).then((resp) => {
        this.offerDetails.offer = resp.data.Value.Offer;
        this.offerDetails.offerOpt = resp.data.Value.OfferOptions;
        this.offerDetails.offerOpt.forEach((el) => {
          this.$set(el, "active", false);
        });
        this.offerDetails.restrictions = resp.data.Value.Restrictions;
      });
      this.offerDetailVisible = true;
    },
    optionClickHandler(option) {
      const exists = this.selectedOptions.includes(option.id);
      if (this.poll.type === 1) {
        if (exists) {
          this.selectedOptions = [];
        } else {
          this.selectedOptions = [option.id];
        }
      } else {
        if (exists) {
          this.selectedOptions = this.selectedOptions.filter(
            (el) => el !== option.id
          );
        } else {
          this.selectedOptions.push(option.id);
        }
      }
    },
    async makePollChoice() {
      const { ID: userId } = this.$store.state.profile;
      const response = await axios.post("News/MakePollChoice", {
        Person: userId,
        Comment: this.pollComment,
        TVP: this.selectedOptions.map((el) => ({ PollOption: el })),
      });

      if (!response.data.ErrorMsg) {
        this.snackbarVisible = true;
        this.snackbarText = "Successfully saved";
        this.snackbarPurpose = "success";
        this.pollDetailsVisible = false;
        this.pollComment = "";
      }
    },
    async getSinglePollOrNews(el) {
      if (el.isPoll) {
        this.pollDetailsVisible = true;
        let id = el.Poll;
        const data = await axios
          .get("News/GetPollDetails", {
            params: {
              Poll: id,
            },
          })
          .then((resp) => {
            const { Value } = resp.data;
            return Value;
          });

        const {
          Poll: {
            Poll,
            Anonymous,
            Description,
            ExpiryDate,
            File,
            Question,
            Title,
            Type,
          },
          PollOptions: options,
        } = data;
        this.poll = {
          id: Poll,
          title: Title,
          file: File,
          anonymous: Anonymous,
          type: Type,
          question: Question,
          description: Description,
          options: options.map(({ Option, PollOPtionID }) => ({
            option: Option,
            id: PollOPtionID,
          })),
          expiryDate: new Date(ExpiryDate),
        };
      } else {
        let id = el.News;
        this.getPerson(id);
        this.newsDetailsVisible = true;
        const data = await axios
          .get("News/GetNewsDetails", {
            params: {
              News: id,
            },
          })
          .then((resp) => resp.data.Value);
        const news = data.News;
        const restrictions = data.Restrictions;
        const persons = [];

        this.newsDetails = {
          title: news.Title,
          banner: news.File,
          text: news.Description,

          restrictions: persons,
        };
      }
    },
    htmlToText(html) {
      const e = document.createElement("p");
      e.innerHTML = html;

      return e.textContent;
    },
    getDateString(date, format = "dd MMM, yyyy") {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      if (date === "Invalid Date") return;

      const MONTHS = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      var days = ("0" + date.getDate()).slice(-2),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        year = date.getFullYear(),
        hours = ("0" + date.getHours()).slice(-2),
        minutes = ("0" + date.getMinutes()).slice(-2),
        seconds = ("0" + date.getSeconds()).slice(-2);

      return format
        .replace("dd", days)
        .replace("MMM", MONTHS[parseInt(month) - 1])
        .replace("MM", month)
        .replace("yyyy", year)
        .replace("yy", String(year).slice(-2))
        .replace("HH", hours)
        .replace("mm", minutes)
        .replace("ss", seconds)
        .replace("ww", WEEKDAYS[date.getDay()]);
    },
  },
  computed: {
    newsAndPolls() {
      return [...this.polls, ...this.news];
    },
    newsAndPollsByTime() {
      return this.newsAndPolls.slice().sort(function (a, b) {
        return b.PublishDate - a.PublishDate;
      });
    },
    offersByTime() {
      return this.offers.slice().sort(function (a, b) {
        return b.PublishDate - a.PublishDate;
      });
    },
    itemsForColumnOne() {
      let result = [];
      let data = this.newsAndPollsByTime;
      for (let i = 0; i < data.length; i += 4) {
        result.push(data[i]);
      }

      return result;
    },
    itemsForColumnTwo() {
      let result = [];
      let data = this.newsAndPollsByTime;
      for (let i = 1; i < data.length; i += 4) {
        result.push(data[i]);
      }

      return result;
    },
    itemsForColumnThree() {
      let result = [];
      let data = this.newsAndPollsByTime;
      for (let i = 2; i < data.length; i += 4) {
        result.push(data[i]);
      }

      return result;
    },
    itemsForColumnFour() {
      let result = [];
      let data = this.newsAndPollsByTime;
      for (let i = 3; i < data.length; i += 4) {
        result.push(data[i]);
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  padding: 30px 80px 0 80px;

  &.offersVisible {
    .offer-blocks-content {
      height: 0;
      // opacity: 0;
      transition: 0.8s ease;
    }
  }
}
::v-deep .swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  pointer-events: visible;
  cursor: pointer;
}
.banner-section {
  height: 250px;

  .left-side {
    width: 100%;
    height: 100%;
    // opacity: 0.6;
    background-color: $blue-primary;
    border-radius: 6px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.offers-section {
  margin-top: 28px;
  position: relative;
  .offer-header {
    display: flex;
    align-items: center;
    span {
      font-weight: 500;
      font-size: 20px;
      color: $blue-primary;
    }
  }
  .offer-blocks-content {
    white-space: nowrap;
    overflow: hidden;
    margin-top: 5px;
    height: 200px;
    // padding-right: 7px;
    // padding-left: 7px;

    transition: 0.8s ease;
    .swiper-button-prev {
      color: rgba($blue-primary, 0.3);
      left: -35px;
      top: 58%;
      &::after {
        font-size: 22px;
      }

      // height: 20px;
      // width: 20px;
    }
    .swiper-button-next {
      color: rgba($blue-primary, 0.3);
      right: -35px;
      top: 58%;
      // height: 20px;
      // width: 20px;
      &::after {
        font-size: 22px;
      }
    }
    .single-offer {
      height: 190px;
      display: inline-block;
      overflow: hidden;
      border-radius: 6px;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }
    }
    .offer-wrapper {
      height: 100%;
      display: flex;
    }
    .banner-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(#ffffff, 0.5);
      width: 250px;
      text-align: center;

      word-break: break-word;
      white-space: pre-wrap;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 40px;
      border-radius: 3px;
      color: #ffffff;
      font-weight: 500;
    }
    .offer-banner {
      height: 100%;
      width: 305px;
      position: relative;

      background: rgba($blue-primary, 0.6);
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .offer-info {
      background-color: #fff;
      height: 100%;
      width: 52%;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
      // border-bottom-left-radius: 6px;
      padding: 10px 30px 10px 30px;

      .offer-title {
        font-size: 20px;
        font-weight: 500;
        color: #283593;
        overflow: hidden;
        white-space: break-spaces;
        word-break: break-word;
        text-overflow: ellipsis;
        margin-bottom: 15px;
        height: 30%;
        opacity: 0.7;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .offer-desc {
        font-size: 14px;
        font-weight: 300;
        color: $blue-primary;
        display: -webkit-box;
        word-break: break-word;
        white-space: break-spaces;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 53%;
      }
    }
  }
}

.news-polls-section {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  ul {
    width: calc(25% - 30px * 3 / 4);

    &:last-child {
      margin-right: 0;
    }
    li {
      // width: 100%;
      cursor: pointer;
      list-style: none;
      padding: 30px;
      margin-bottom: 30px;
      height: 240px;
      background-color: #fff;
      color: $blue-primary;
      // overflow: hidden;
      border-radius: 6px;
      position: relative;
      img {
        position: absolute;
        top: 0;
        right: 10px;
      }
      .persons-content {
        padding-left: 0;
        margin-left: -15px;
      }

      .banner {
        height: 150px;
        width: 250px;
        background-color: #ededfc;
        border-radius: 6px;
        margin-bottom: 30px;
      }
      .date {
        font-size: 12px;
        font-weight: 300;
        margin-bottom: 20px;
      }
      h2 {
        font-weight: 500;
        font-size: 19px;
        margin-bottom: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: 46px;
        opacity: 0.7;
        word-break: break-word;
      }
      p {
        font-weight: 300;
        font-size: 13px;
        height: 35px;

        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    .itemWithBanner {
      height: 420px;
      padding: 0;
      .banner {
        width: 100%;
        height: 205px;
        margin-bottom: 0;
        background: rgba($blue-primary, 0.6);
        background-size: cover;
        background-repeat: no-repeat;
      }
      .persons-content {
        padding-left: 12px;
        margin-left: 0px;
        margin-top: 0;
      }
      .info-wrapper {
        padding: 12px;
        p {
          height: 35px;
          word-break: break-word;
        }
        h2 {
          margin-bottom: 10px;
        }
      }
    }
    .persons-content {
      display: flex;
      align-items: center;
      border-top: 1px solid #ededfc;
      margin-top: 10px;
      padding-left: 12px;
      position: relative;

      .avatar {
        height: 40px;
        width: 40px;
        border-radius: 100%;
        background-color: #ededfc;
        border: 3px solid white;
        margin-left: -11px;
        margin-top: 10px;
        background-size: contain;
        background-repeat: no-repeat;

        &:first-child {
          z-index: 5;
          margin-left: 1px;
        }
        &:nth-child(2) {
          z-index: 4;
        }
        &:nth-child(3) {
          z-index: 3;
        }
        &:nth-child(4) {
          z-index: 2;
        }
        &:nth-child(5) {
          z-index: 1;
        }
        &:nth-child(6) {
          z-index: 0;
        }
      }
      .count {
        height: 40px;
        width: 40px;
        border-radius: 100%;
        border: 1px solid #ededfc;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.6875rem;
        color: #9696b9;
        margin-top: 10px;
        margin-left: -11px;
        padding-left: 5px;
      }
    }
  }

  // .column-one {
  //     li {
  //         background-color: #fff;
  //     }
  // }
}
.offers-popup-button {
  cursor: pointer;
  height: 44px;
  width: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  div {
    height: 2px;
    background: rgba($blue-primary, 0.3);
    width: 30px;
    margin-bottom: 3px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.hooper {
  outline: none;
}
::v-deep .hooper-liveregion.hooper-sr-only {
  display: none;
}
.hooper-navigation {
  outline: none;
  ::v-deep button {
    position: absolute;
    background: transparent;
    opacity: 0.3;
    cursor: pointer;
  }
  ::v-deep button:nth-child(1) {
    top: 55%;
    left: -3%;
  }
  ::v-deep button:nth-child(2) {
    right: -3%;
    top: 55%;
  }
}
.offer-popup {
  p {
    font-size: 20px;
    font-weight: 500;
    color: $blue-primary;
  }

  ::v-deep .overlay .popup {
    width: 1342px;
    height: initial;
    left: 46%;
    top: 54%;
    overflow: auto;
    h3.title {
      // color: #292929;
      // font-weight: 500;
      // font-size: 20px;
      display: none;
    }
    .title {
      padding-left: 15px;
    }
  }
  .options-content {
    position: relative;
    margin-top: 15px;
    .hooper-navigation {
      ::v-deep button:nth-child(1) {
        top: 60%;
        left: -3%;
      }
      ::v-deep button:nth-child(2) {
        top: 60%;
        right: -3%;
      }
    }
  }
  .banner-desc-content {
    display: flex;
    height: 410px;
    justify-content: space-between;
    .banner-content {
      height: 100%;
      width: calc(50% - 15px);
      border-radius: 10px;
      margin-bottom: 40px;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .description-content {
      width: calc(50% - 15px);

      .desc-text {
        margin-top: 10px;
        max-height: 378px;
        border-radius: 5px;
        padding-right: 10px;
        padding-top: 10px;
        font-weight: 300;
        color: rgba($blue-primary, 1);
        overflow: scroll;
        margin-bottom: 15px;
        padding-left: 15px;
        font-size: 14px;
        word-break: break-word;
      }
    }
  }
  .comment-content {
    margin-top: 30px;
    textarea {
      height: 75px;
      font-size: 14px;
      width: 100%;
      outline: none;
      border: 1px solid #dcdde6;
      resize: none;
      border-radius: 6px;
      padding: 10px;
      color: $blue-primary;
      &::placeholder {
        color: rgba($blue-primary, 0.8);
      }
    }
  }
  .button-content {
    button {
      width: 125px;
      height: 45px;
      border-radius: 6px;
      color: #ffffff;
      background: rgba($blue-primary, 0.7);
      float: right;
      margin-top: 25px;
      cursor: pointer;
      font-size: 16px;
    }
  }
  .attachment-content {
    margin-top: 10px;
    min-height: 30px;
    display: flex;

    .files {
      width: 50%;

      .files-content {
        width: 100%;
        overflow-x: scroll;
        white-space: nowrap;
        padding-bottom: 10px;
        display: flex;
        &::-webkit-scrollbar-thumb {
          background: rgba($blue-primary, 0.1);
        }
      }
      .title {
        display: flex;
        font-size: 15px;
        color: rgba($blue-primary, 1);
        align-items: center;
        .dots-content {
          display: flex;
          align-items: center;
          position: relative;
          .download-button {
            position: absolute;
            background-color: #ffffff;
            height: 30px;
            width: 150px;
            border-radius: 6px;
            top: 15px;
            left: 0px;
            z-index: 100;
            box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.23);
            opacity: 1;
            display: flex;
            // justify-content: center;
            padding-left: 5px;
            align-items: center;
            color: rgba($blue-primary, 0.8);
          }
          height: 20px;
          width: 30px;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
          margin-left: 10px;
          div {
            height: 4px;
            width: 4px;
            border-radius: 100%;
            background-color: #292929;
            opacity: 0.7;
            margin-left: 3px;
            &:nth-child(1) {
              margin-left: 0;
            }
          }
        }
      }
      ::v-deep .file-block {
        height: 3.1625rem;
        width: 9.5625rem;
        margin-top: 10px;
        margin-right: 5px;
        display: inline-block;
        box-shadow: none;
        cursor: pointer;

        padding: 0;
        .uploaded {
          margin-top: 10px;
          margin-left: 3px;
          .uploaded-logo {
            margin-right: 5px;
          }
        }
        img {
          display: none;
        }
        .top {
          // flex-shrink: 0;

          span {
            font-weight: 300;
            white-space: nowrap;
            max-width: calc(100% - 70 * -0.9375rem);
            font-size: 12px;
          }
          div {
            height: 28px;
            width: 28px;
            font-size: 12px;
          }
        }
        .bottom {
          display: none;
        }
      }
    }
    .url-content {
      margin-left: 18px;
      span {
        display: flex;
        align-items: center;
        color: rgba($blue-primary, 0.8);
        white-space: nowrap;
        font-size: 15px;
      }
      a {
        color: #1db3e9;
        max-width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 5px;
      }
    }
    .download-content {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #292929;
      margin-bottom: 15px;
      span:nth-child(2) {
        color: $blue-primary;
        font-size: 20px;
        margin-left: 5px;
      }
    }
  }

  .options {
    margin-top: 15px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    .swiper-button-prev {
      color: rgba($blue-primary, 0.3);
      left: -30px;
      top: 66%;
      &::after {
        font-size: 20px;
      }

      // height: 20px;
      // width: 20px;
    }
    .swiper-button-next {
      color: rgba($blue-primary, 0.3);
      right: -30px;
      top: 66%;
      // height: 20px;
      // width: 20px;
      &::after {
        font-size: 20px;
      }
    }
    .single-opt {
      // width: 19.9% !important;
      height: 74px;
      background-color: #ededfc;
      display: inline-block;

      border-radius: 5px;
      cursor: pointer;
      .for-event {
        height: 100%;
      }
      .opt-info-wrapper {
        display: flex;
        height: 100%;

        .left-side {
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 10px;
          margin-right: 10px;
          span {
            font-size: 30px;
            font-weight: 500;
            color: #9696b9;
          }
        }
        .right-side {
          display: flex;
          flex-direction: column;
          justify-content: center;
          span {
            font-size: 14px;
            font-weight: 400;
            color: rgba($blue-primary, 1);
            max-width: 205px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
.news-popup {
  ::v-deep .popup {
    height: 90% !important;
    width: 70% !important;
    top: 55%;
    left: 46%;
    overflow: hidden;
    .title {
      display: none;
    }
  }
  .desc-content {
    width: calc(50% - 15px);
  }

  .news {
    padding: 30px 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .desc-banner-content {
      height: 410px;
      display: flex;
      justify-content: space-between;
    }
    &__title {
      font-size: 20px;
      font-weight: 500;
      color: #283593;
      margin-bottom: 15px;
      padding-left: 15px;
    }

    &__banner {
      width: calc(50% - 15px);
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: 25px;
      border-radius: 6px;
    }

    &__text {
      font-size: 14px;
      font-weight: 300;
      overflow-y: auto;
      padding-left: 15px;
      max-height: 370px;
      word-break: break-word;
      color: #283593;

      &--without-banner {
        max-height: calc(250px + 25px + 210px);
      }
    }

    &__audience {
      margin-top: auto;
      &-header {
        margin-bottom: 25px;

        &-title {
          font-size: 18px;
          color: #283593;

          b {
            font-weight: 500;
          }
          span {
            font-weight: lighter;
          }
        }
      }

      &-items {
        display: flex;
        overflow: hidden;
        cursor: e-resize;
        padding-bottom: 20px;

        &-item {
          margin-right: 65px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          &-avatar {
            width: 90px;
            height: 90px;
            background-color: #ededfc;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 100%;
            margin-bottom: 5px;
          }

          &-name {
            color: #283593;
            font-weight: lighter;
          }
        }
      }
    }
  }
}
.poll-popup {
  ::v-deep .popup {
    width: 70% !important;
    height: initial;
    top: 52%;
    left: 46%;
    h3.title {
      display: none;
    }
  }
  .banner-details-content {
    display: flex;
    width: 100%;
    height: 410px;
  }
  .desc-comment-content {
    margin-top: 30px;
  }
  .cstm-input {
    background-color: transparent;
    border: 0;
    font-size: 14 * $rem;
    width: 100%;
    color: #283593;
    height: 60px;
    max-width: 100%;
    font-weight: 300;
    border: 1px solid #dcdde6;
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 5px;

    &::placeholder {
      color: #9696b9;
    }

    &:focus {
      outline: 0;
    }
  }

  .poll {
    display: flex;
    flex-direction: column;

    &__content {
      display: flex;
      height: 550px;
      flex-direction: column;
    }

    &__banner {
      width: 50%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 10px;
      margin-top: 24px;
    }

    &__details {
      margin-left: 50px;
      color: #9696b9;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 50%;
      .wrapper {
        // width: 106%;
      }
    }

    &__description {
      margin-top: 15px;
      height: 75px;
      margin-bottom: 15px;
      font-weight: 300;
      font-size: 14px;
      word-break: break-word;
      overflow: auto;
      color: $blue-primary;
    }

    &__question {
      height: 50px;
      overflow-y: auto;
      margin-bottom: 60px;
      color: $blue-primary;
    }

    &__options {
      max-height: 300px;
      display: flex;
      flex-direction: column;
      margin-bottom: 60px;
      overflow-y: auto;
      padding-right: 20px;
    }

    &__option {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 10px;

      &-wrapper {
        display: flex;
        align-items: flex-start;
      }

      &-index {
        margin-right: 10px;
        font-weight: 500;
        color: #9696b9;
      }

      &-name {
        color: #283593;
        max-width: 490px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 300;
      }

      &-checkbox {
        margin-left: auto;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        border: 1px solid #959bc9;
        background-color: transparent;
        cursor: pointer;

        &--selected {
          border: 1px solid #283593 !important;
        }

        svg {
          width: 12px;
          height: 10px;
        }
      }
    }
    .poll-date {
      text-align: right;
      padding-right: 10px;
      margin-bottom: 10px;
      color: $blue-primary;
    }

    &__button {
      cursor: pointer;
      margin-top: 105px;
      margin-left: auto;
      background-color: #959bc9;
      color: white;
      border-radius: 6px;
      width: 145px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.clickedOptStyle {
  // background-color: #283593 !important;
  background: rgba($blue-primary, 0.6) !important;
  span {
    color: #ffffff !important;
  }
}
</style>