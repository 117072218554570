<template>
  <div class="background">
    <div class="employees">
      <div class="actions-container">
        <div
          class="reset-passwords"
          v-if="$store.getters.isAdmin"
          @click="resetPasswords"
        >
          Reset All Passwords
        </div>
        <div class="right-side">
          <form class="search-form">
            <input placeholder="Search ..." v-model="searchName" />
            <div class="plus" @click="redirectToProfile"></div>
          </form>
          <div class="export" @click="exportsAsExcel">
            <span class="material-icons-outlined">file_download</span>
            <span>Export</span>
          </div>
        </div>
      </div>
      <employee-table
        :employees="employees"
        @rowDelete="showDeletePopup"
        @roles-click="rolesClickHandler"
        @permissions-click="permissionsClickHandler"
        @delete="deleteClickHandler"
      />
      <overlay
        :visible="permissionsPopupVisible"
        @close="permissionsPopupVisible = false"
        class="permissions-overlay"
      >
        <popup
          title="Employee Permissions"
          @close="permissionsPopupVisible = false"
        >
          <permissions
            getURL="Permission/GetPersonPermissions"
            assignURL="Permission/AssignPersonPermission"
            :itemID="selectedEmployeeID"
            itemName="Person"
          />
        </popup>
      </overlay>
      <overlay
        :visible="jobsPopupVisible"
        @close="jobsPopupVisible = false"
        class="jobs-overlay"
      >
        <popup title="Employee Jobs" @close="jobsPopupVisible = false">
          <employee-jobs :person="{ id: selectedEmployeeID }" />
        </popup>
      </overlay>
      <overlay
        :visible="rolesPopupVisible"
        @close="rolesPopupVisible = false"
        class="roles-overlay"
      >
        <popup title="Employee Roles" @close="rolesPopupVisible = false">
          <employee-roles :employeeID="selectedEmployeeID" />
        </popup>
      </overlay>
      <delete-item
        @delete="deleteRow"
        @close="deletePopupVisible = false"
        v-if="deletePopupVisible"
        :text="deleteText"
      />
      <custom-snackbar
        v-if="snackbarVisible"
        :purpose="snackbarPurpose"
        :text="snackbarText"
        @close="snackbarVisible = false"
      />
    </div>
  </div>
</template>

<script>
import Overlay from '@/components/ReusableComponents/Overlay/Overlay.vue'
import Popup from '@/components/ReusableComponents/Popup/Popup.vue'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import axios from '@/Helpers/AxiosInstance.js'
import {
  timestampToDateTime,
  checkPermission,
  debounce,
} from '@/Helpers/HelperFunctions.js'
import DeleteItem from '@/components/ReusableComponents/DeleteItem/DeleteItem.vue'
import Permissions from '@/components/Permissions/Permissions.vue'
import EmployeeJobs from './EmployeeJobs.vue'
import EmployeeRoles from './EmpoloyeeRoles.vue'
import EmployeeTable from './EmployeeTable.vue'
import { showSuccess } from '@/Helpers/Messages'
export default {
  components: {
    Overlay,
    Popup,
    CustomSnackbar,
    DeleteItem,
    Permissions,
    EmployeeJobs,
    EmployeeRoles,
    EmployeeTable,
  },
  created() {
    this.getData()
    this.getOffices()
  },
  data() {
    return {
      accessPermissions: {
        createPerson: checkPermission('CreatePerson'),
        assignPersonJob: checkPermission('AssignPersonJob'),
        getRoles: checkPermission('CreateRole'),
        getPermissions: checkPermission('UpdatePermission'),
        updatePerson: checkPermission('CreatePerson'),
      },
      employees: [],
      snackbarPurpose: 'error',
      snackbarText: '',
      snackbarVisible: false,
      deletePopupVisible: false,
      selectedEmployeeID: '',
      permissionsPopupVisible: false,
      jobsPopupVisible: false,
      rolesPopupVisible: false,
      searchName: '',
      offices: [],
    }
  },
  methods: {
    getData() {
      axios
        .get('Person/GetPersons', {
          params: {
            Name: this.searchName,
          },
        })
        .then((resp) => {
          this.employees = resp.data.Value.Persons.map((el) => {
            if (el.BirthDate == null) el.BirthDate = 0
            if (el.Gender == true) el.Gender = 1
            else if (el.Gender == false) el.Gender = 2

            return el
          })
        })
    },
    exportsAsExcel() {
      const isLocal = ~location.href.indexOf('localhost')

      let OfferId = this.clickedOfferId
      const link = document.createElement('a')
      link.href = `https://intranet.upgaming.${
        isLocal ? 'dev' : 'com'
      }/api/Person/GetPersonsExcelExport?Name=${this.searchName}`
      link.setAttribute('download', `employees.xlsx`)
      document.body.appendChild(link)
      link.click()
    },
    showDeletePopup(id) {
      if (this.$store.getters.hasRole('Lawyer')) return
      this.selectedEmployeeID = id
      this.deletePopupVisible = true
    },
    rolesClickHandler(personID) {
      if (this.$store.getters.hasRole('Lawyer')) return
      this.rolesPopupVisible = true
      this.selectedEmployeeID = personID
    },
    permissionsClickHandler(person) {
      if (this.$store.getters.hasRole('Lawyer')) return
      else {
        this.$router.push({
          name: 'Permissions',
          params: { person: person, currentSection: '2' },
        })
      }
    },
    redirectToProfile() {
      if (this.$store.getters.hasRole('Lawyer')) return
      this.$router.push('/Profile/info')
    },
    copyToClipboard() {
      let copyText = document.getElementById('password')
      let textArea = document.createElement('textarea')
      textArea.value = copyText.textContent
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('Copy')
      textArea.remove()
      this.snackbarText = 'Copied to clipboard'
      this.snackbarPurpose = 'success'
      this.snackbarVisible = true
    },
    getOffices() {
      axios.get('Company/GetOffices').then((resp) => {
        if (resp.data.ErrorMsg) return
        this.offices = resp.data.Value
      })
    },
    deleteClickHandler(personID) {
      var person = this.employees.find((el) => el.ID == personID)
      this.$confirm(
        `Would you like to delete ${person.FirstName} ${person.LastName}?`,
      ).then(() => {
        axios
          .post('Person/UpdatePerson', {
            Deleted: true,
            ID: personID,
            Translations: [],
          })
          .then(() => this.getData())
      })
    },
    async resetPasswords() {
      await this.$confirm(
        'This will reset all employee passwords! Would you like to continue?',
      )
      axios.get('authorization/ResetPassword').then((resp) => {
        if (resp.data.ErrorMsg) return
        showSuccess('Emails with new passwords have been sent!')
      })
    },
  },
  computed: {
    deleteText() {
      if (this.gridData.length == 0 || !this.selectedEmployeeID) return ''
      var employee = this.gridData.filter(
        (el) => el.ID == this.selectedEmployeeID,
      )[0]
      var name = employee.FirstName + ' ' + employee.LastName
      return `Would you like to delete the employee <strong>${name}</strong>?`
    },
    columns() {
      return [
        {
          title: '',
          key: '',
          customTemplate: () =>
            '<i class="material-icons i-forward">person</i>',
        },
        {
          title: 'ID',
          key: 'ID',
        },

        {
          title: 'First Name',
          key: 'FirstName',
          editable: true,
          editType: 'text',
        },
        {
          title: 'Last Name',
          key: 'LastName',
          editable: true,
          editType: 'text',
        },
        {
          title: 'Nick Name',
          key: 'NickName',
          editable: true,
          editType: 'text',
        },
        {
          title: 'Birthday',
          key: 'BirthDate',
          customTemplate: (item, val) => timestampToDateTime(val),
          editable: true,
          editType: 'date',
        },
        {
          title: 'Gender',
          key: 'Gender',
          customTemplate: (item, val) => {
            let gender = this.genderOptions.filter((el) => el.value == val)[0]
            return gender ? gender.title : '-'
          },
          editable: true,
          editType: 'select',
          editOptions: this.genderOptions,
        },

        {
          title: 'Roles',
          key: '',
          hidden: !this.accessPermissions.getRoles,
          customTemplate: () =>
            `<i class="material-icons i-roles">description</i>`,
        },
        {
          title: 'Jobs',
          key: '',
          hidden: !this.accessPermissions.assignPersonJob,
          customTemplate: () =>
            '<i class="material-icons i-jobs">work_outline</i>',
        },
        {
          title: 'Permissions',
          hidden: !this.accessPermissions.getPermissions,
          key: '',
          customTemplate: () =>
            '<i class="material-icons i-permissions">lock_open</i>',
        },
      ]
    },
    officeOptions() {
      return this.offices.map((el) => ({ title: el.Name, value: el.ID }))
    },
  },
  watch: {
    searchName: {
      handler: debounce(function () {
        this.getData()
      }, 300),
    },
  },
}
</script>

<style lang="scss" scoped>
.employees {
  height: calc(100% - 30 * #{$rem});

  .actions-container {
    margin-top: 30 * $rem;
    display: flex;
    margin-bottom: 15 * $rem;
    align-items: center;
    padding: 0 30px;
    justify-content: space-between;

    .reset-passwords {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($blue-primary, 0.6);
      color: white;
      height: 38 * $rem;
      border-radius: 3 * $rem;
      padding: 0 10 * $rem;
      /* margin-left: 30 * $rem; */
      cursor: pointer;
    }
    .right-side {
      display: flex;
      align-items: center;
      /* margin-right: 20px; */
      .export {
        width: 120px;
        background-color: #6956d6;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;
        border-radius: 0.3125rem;
        cursor: pointer;
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        margin-left: auto;
        .material-icons-outlined {
          margin-right: 5px;
          color: #1de9b6;
        }
      }
    }
    form {
      display: flex;

      height: 38 * $rem;
      margin-right: 30px;

      input {
        outline: none;
        border: none;
        border-radius: 3 * $rem;
        padding-left: 23 * $rem;
        font-size: 14 * $rem;
        color: $blue-primary;
        background-color: #f8f8fe;
        width: 272 * $rem;

        &::placeholder {
          color: rgba($blue-primary, 0.6);
        }
      }

      .plus {
        display: inline-block;
        background-color: rgba($blue-primary, 0.6);
        border-radius: 3 * $rem;
        width: 42 * $rem;
        height: 37 * $rem;
        margin-left: 4 * $rem;
        position: relative;
        cursor: pointer;

        &::before {
          content: '';
          position: absolute;
          width: 23 * $rem;
          height: 2 * $rem;
          top: 18 * $rem;
          left: 10 * $rem;
          background-color: #fff;
        }

        &::after {
          content: '';
          position: absolute;
          height: 23 * $rem;
          width: 2 * $rem;
          top: 7 * $rem;
          left: 21 * $rem;
          background-color: #fff;
        }
      }
    }
  }

  .employee-table {
    height: calc(100% - 53 * #{$rem});
    overflow: auto;
  }

  ::v-deep .permissions-overlay .popup {
    height: 90vh;
    width: 90%;
  }

  ::v-deep .jobs-overlay .popup {
    height: 80vh;
  }

  ::v-deep .i-permissions,
  ::v-deep .i-jobs {
    font-size: 28 * $rem;
    cursor: pointer;

    &:hover {
      filter: brightness(1.1);
    }
  }

  ::v-deep .i-roles,
  ::v-deep .i-copy,
  ::v-deep .i-jobs,
  ::v-deep .i-forward {
    font-size: 28 * $rem;
    cursor: pointer;

    &:hover {
      filter: brightness(1.1);
    }
  }

  ::v-deep .i-forward {
    color: $green-primary;
  }

  ::v-deep .grid {
    label,
    .select-wrapper {
      transform: scale(0.9);
      transform-origin: left center;
    }
  }
}
</style>
