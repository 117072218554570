<template>
  <div
    id="container"
    class="audience-content"
    v-if="audienceVisible == placementId"
    ref="audienceContainer"
  >
    <ul>
      <li v-for="person in data" :key="person.Person">
        <div class="name-avatar">
          <div
            class="avatar"
            :style="{
              'background-image': `url(https://intranet.upgaming.com/api/Person/GetPersonAvatar2?ID=${person.Person})`,
            }"
          ></div>
          <div class="name">
            <span>{{ person.FirstName + " " + person.LastName }}</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
    audienceVisible: Number,
    placementId: Number,
  },
};
</script>

<style scoped lang="scss">
.audience-content {
  max-height: 300px;
  overflow: auto;
  position: absolute;
  width: 300px;
  border-radius: 6px;
  top: 50px;
  z-index: 100;
  background-color: white;
  border: 1px solid #e9e9eb;
  box-shadow: 2px 4px 17px 1px rgba(0, 0, 0, 0.4);
  .avatar {
    height: 35px;
    width: 35px;
    border-radius: 100%;
    background-size: cover;
    background-color: #ededfc;
    background-repeat: no-repeat;
    background-position: center;
  }
  ul {
    width: 100%;
    padding: 15px;
    // padding-top: 0;
    li {
      width: 100%;
      display: flex;
      height: 45px;
      //   margin-bottom: -12px;
      align-items: center;
      margin-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      font-size: 15px;
      font-weight: 300;
      padding: 0;
      margin-bottom: 5px;

      .name-avatar {
        display: flex;
        align-items: center;
        .name {
          margin-left: 5px;
        }
      }
    }
  }
}
</style>