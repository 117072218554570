<template>
    <div class="time-picker">
        <div class="top">
            <span class="minus" @click="minusInterval">_</span>
            <input type="number" class="hours" @click.stop v-model="hours" ref="hours" @input="hourChange">
            <span>:</span>
            <input type="number" class="minutes" @click.stop v-model="minutes" @input="minuteChange">
            <span class="plus" @click="plusInterval">+</span>
        </div>
        <span class="text">{{ title }}</span>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        time: {
            type: [Number, String],
        },
        minimum: Number, //in minutes
    },
    created() {
        if(this.time) {
            var hr = parseInt(this.time / 60)
            var mm = this.time % 60
            this.hours = ('0' + hr).slice(-2)
            this.minutes = ('0' + mm).slice(-2)
        }
    },
    data() {
        return {
            hours: '00',
            minutes: '00',
            adjustInterval: 30,
        }
    },
    methods: {
        hourChange() {
            var value = this.hours
            if(value < 0) {
                this.hours = '00'
                this.emitTime()
                return
            }
            if(value > 23) {
                this.hours = 23
                this.emitTime()
                return
            }
            this.hours = ('0' + value).slice(-2)
            this.emitTime()
        },
        minuteChange() {
            var value = this.minutes
            if(value < 0) {
                this.minutes = '00'
                this.emitTime()
                return
            }
            if(value > 59) {
                this.minutes = 59
                this.emitTime()
                return
            }
            this.minutes = ('0' + value).slice(-2)
            this.emitTime()
        },
        emitTime() {
            this.$emit('change', parseInt(this.hours)*60+parseInt(this.minutes))
        },
        minusInterval() {
            var newTime = parseInt(this.hours)*60+parseInt(this.minutes) - this.adjustInterval
            if(newTime < 0) newTime = 0
            this.$emit('change', newTime)
        },
        plusInterval() {
            var newTime = parseInt(this.hours)*60+parseInt(this.minutes) + this.adjustInterval
            if(newTime > 24*60) newTime = 24*60
            this.$emit('change', newTime)
        },
    },
    watch: {
        time(value) {
            var hr = parseInt(value / 60)
            var mm = value % 60
            this.hours = ('0' + hr).slice(-2)
            this.minutes = ('0' + mm).slice(-2)
        },
    },
}
</script>

<style lang="scss" scoped>
    .time-picker {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .top {
            display: flex;
            align-items: center;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                height: $rem;
                width: 56 * $rem;
                background-color: rgba($blue-primary, 0.3);
                left: 21 * $rem;
                bottom: -5 * $rem;
            }

            input {
                width: 25px;
                border: none;
                outline: none;
                color: rgba(40, 52, 147, 0.5);
                font-weight: 500;
                font-size: 20px;

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                &.hours {
                    text-align: right;
                    padding-right: 2 * $rem;
                }

                &.minutes {
                    padding-left: 2 * $rem;
                }
            }

            .plus, .minus {
                font-weight: 500;
                font-size: 20 * $rem;
                color: rgba($blue-primary, 0.6);
                user-select: none;
                cursor: pointer;

                &.minus {
                    margin-right: 12 * $rem;
                    position: relative;
                    top: -6 * $rem;
                }

                &.plus {
                    margin-left: 12 * $rem;
                }
            }
        }

        .text {
            margin-top: 12 * $rem;
            font-weight: 300;
            font-size: 13 * $rem;
            color: #b2b2c2;
        }
    }
</style>