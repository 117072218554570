<template>
  <choose-page
    :links="links"
    :background="require('@/assets/images/news-polls-offers-page.jpg')"
  />
</template>

<script>
import ChoosePage from "@/components/ReusableComponents/ChoosePage/ChoosePage";
export default {
  components: { ChoosePage },
  data() {
    return {
      links: [
        {
          url: "/Statements",
          title: "Statements",
        },
        {
          url: "/News",
          title: "News",
        },
        {
          url: "/Offers",
          title: "Offers",
        },
        {
          url: "/Polls",
          title: "Polls",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
