<template>
  <div class="select-content">
    <div class="container" @click.stop="jobsVisible = !jobsVisible">
      <span>{{ title }}</span>
      <i class="material-icons">keyboard_arrow_down</i>
    </div>
    <div class="jobs-content" @click.stop v-show="jobsVisible">
      <input placeholder="search..." type="text" v-model="search" />
      <div
        class="single-job"
        @click="personClickHanlder(job)"
        v-for="job in filteredJobs"
        :key="job.index"
      >
        <div class="job-name">
          <div
            class="arrows-content"
            @click.stop="job.showPersons = !job.showPersons"
          >
            <span class="material-icons arrow-up" v-show="job.showPersons"
              >keyboard_arrow_up</span
            >
            <span class="material-icons arrow-down" v-show="!job.showPersons"
              >keyboard_arrow_down</span
            >
          </div>
          <span>{{ job.title }}</span>
          <custom-checkbox :value="job.selected" />
        </div>
        <div class="persons-content" v-show="job.showPersons">
          <div
            class="single-person"
            v-for="person in job.jobPersons"
            :key="person.PersonID"
          >
            {{ person.FirstName + " " + person.LastName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomCheckbox from "@/components/ReusableComponents/CustomCheckbox/CustomCheckbox";
import axios from "@/Helpers/AxiosInstance.js";
export default {
  created() {
    this.getJobs();
    document.addEventListener("click", this.documentClick);
  },
  components: {
    CustomCheckbox,
  },
  props: {
    isOpened: Boolean,
    selectedJobs: Array,
    title: {
      type: String,
      default: "Select Job/s",
    },
  },
  data() {
    return {
      jobsVisible: false,

      search: "",
      jobs: [],
    };
  },
  watch: {
    isOpened() {
      if (this.isOpened == false) {
        this.jobs.forEach((el) => {
          if (el.selected == true) {
            el.selected = false;
          }
        });
      }
    },
  },
  methods: {
    personClickHanlder(el) {
      el.selected = !el.selected;
      const exist = this.selectedJobs.includes(el.value);

      if (exist) {
        this.selectedJobs = this.selectedJobs.filter(
          (element) => element != el.value
        );
      } else {
        this.selectedJobs.push(el.value);
      }
      this.$emit("selected-jobs", this.selectedJobs);
    },
    documentClick() {
      this.jobsVisible = false;
    },
    getJobs() {
      axios.get("jobs/GetStructureDropDownJobsPerson").then((resp) => {
        if (resp.data.ErrorMsg) return;
        this.jobs = resp.data.Value.JobWithPersonsList.map((el) => ({
          title: el.JobName,
          value: el.JobID,
          showPersons: false,
          selected: el.selected,
          jobPersons: el.Persons,
        }));
        this.jobs.forEach((el) => {
          this.$set(el, "selected", false);
        });
      });
    },
  },
  computed: {
    filteredJobs() {
      return this.jobs.filter((el) =>
        el.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style lang="scss" scoped>
.select-content {
  position: relative;
}

.container {
  width: 250 * $rem;
  height: 38 * $rem;
  padding-left: 15 * $rem;
  padding-right: 15 * $rem;
  border-radius: 6 * $rem;
  border: $rem solid #dcdde6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  white-space: nowrap;

  span {
    font-weight: 300;
    font-size: 16 * $rem;
    color: rgba($blue-primary, 0.7);
  }

  i {
    font-size: 20 * $rem;
    color: rgba($blue-primary, 0.7);
  }
}
.jobs-content {
  position: absolute;
  list-style: none;
  background-color: #fff;
  z-index: 150;
  overflow-y: auto;
  width: 15.625rem;
  max-height: 17rem;
  background-color: white;
  border: 0.0625rem solid #dcdde6;
  color: #dadcea;
  border-radius: 0.375rem;
  box-shadow: $box-shadow2;
  padding: 12 * $rem 0;
  user-select: none;
  overflow-y: auto;
  animation: show 0.1s linear;
  top: 45px;

  .single-job {
    display: flex;
    // justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    cursor: pointer;
    color: rgba($blue-primary, 0.7);
    margin-top: 10px;
    .job-name {
      display: flex;
      justify-content: space-between;
      position: relative;
      padding-left: 5px;
    }
    .arrows-content {
      position: absolute;
      left: -20px;
      // top: px;
      top: -1px;
      opacity: 0.7;
    }
    .single-person {
      white-space: nowrap;
      font-weight: 300;
      margin-bottom: 3px;
      font-size: 15px;
    }
    .persons-content {
      padding-top: 5px;
      padding-left: 10px;
    }
  }
  input {
    width: calc(100% - 35px);
    margin: 0 0 0 1.125rem;
    padding-left: 0.75rem;
    background-color: #f4f4f8;
    border-radius: 0.1875rem;
    font-weight: 200;
    font-size: 0.8125rem;
    outline: none;
    color: #9696b9;
    border: none;
    height: 1.875rem;
    margin-bottom: 0.3125rem;
  }
}
</style>