<template>
  <div class="payroll">
    <pay-roll-info-tabs />
    <transition name="fade" mode="out-in" appear>
      <router-view> </router-view>
    </transition>
  </div>
</template>

<script>
import PayRollInfoTabs from "./PayRollInfoTabs";

export default {
  created() {
    this.$store.state.showTimeLine = false;
  },
  data() {
    return {
      transitionName: "",
    };
  },

  components: {
    PayRollInfoTabs,
  },
  watch: {},
};
</script>

<style lang="scss">
.payroll {
  display: inline;

  .tabs-content {
    li {
      margin-right: 100px;
    }
  }

  & > .overlay {
    .popup {
      padding: 0;

      .title {
        display: none;
      }
    }
    .welcome {
      width: 1200 * $rem;
      height: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("~@/assets/images/welcome.png");
      position: relative;
      padding-top: 53.2%;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s linear;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
