<template>
  <div class="background">
    <news-polls-offers-wrapper
      type="news"
      :data="filteredByStatus"
      @search-value="(value) => (search = value)"
      @ongoing-clicked="onGoing = !onGoing"
      @draft-clicked="draft = !draft"
      :draftClicked="draft"
      :onGoingClicked="onGoing"
      :completedClicked="completed"
      @get-data="getData"
      :maxPageCount="maxPageCount"
      @completed-clicked="completed = !completed"
      @clicked="getSingleNews"
      @add-item="addPopupVisible = true"
      @edit-item="getSingleNews"
      @delete-item="deleteClickHandler"
    />
    <custom-popup
      style="z-index: 999"
      :visible="bannerUploadPopup"
      :flexible-size="true"
      title="Add Banner"
      @close="bannerUploadPopup = false"
    >
      <custom-file-input
        title="Select File"
        :value="news.banner"
        @change="(file) => (news.banner = file)"
      />
      <div class="upload-button" @click="bannerUploadPopup = false">Upload</div>
    </custom-popup>

    <div class="preview-popup">
      <custom-popup
        :visible="showPreviewPopup"
        @close="showPreviewPopup = false"
      >
        <div class="desc-banner-content">
          <div
            v-if="Object.keys(news.file).length > 0"
            class="banner-content"
            :style="{
              backgroundImage: `url(data:image/jpeg;base64,${news.file.base64})`,
            }"
          ></div>
          <div class="preview-banner" v-else>Banner here</div>

          <div class="desc-content">
            <div class="preview-header">
              {{ news.title ? news.title : "Title here" }}
            </div>
            <div class="preview-desc" v-if="news.text" v-html="news.text"></div>
            <div class="preview-desc" v-else>Description here</div>
          </div>
        </div>
        <div class="news__audience">
          <div class="news__audience-header">
            <div class="news__audience-header-title">
              <b>Audience</b> <span> {{ audience.length }} </span>
            </div>
          </div>
          <div class="news__audience-items" v-dragscroll>
            <div
              class="news__audience-items-item"
              v-for="person in audience"
              :key="person.PersonID"
            >
              <div
                v-if="person"
                class="news__audience-items-item-avatar"
                :style="{
                  backgroundImage: `url(data:image/jpeg;base64,${person.File})`,
                }"
              />
              <div class="news__audience-items-item-name">
                <p>{{ person.FirstName }}</p>
                <p>{{ person.LastName }}</p>
              </div>
            </div>
          </div>
        </div>
      </custom-popup>
    </div>
    <custom-popup
      :title="!isEditMode ? 'Add News' : 'Edit News'"
      class="popup"
      :visible="addPopupVisible"
      :flexible-size="true"
      @close="
        addPopupVisible = false;
        isEditMode = false;
        fileRecords = [];
        fileRecordsForUpload = [],
        news.banner.file = null,
        news.banner.fileName = null
        news.banner.fileType = null
        news.banner.id = null

      "
    >
      <div class="add-news">
        <div class="add-news__header">
          <div class="add-news__title">
            <custom-input
              :trigger-change-on-blur="false"
              class="add-news__title-input"
              title="Title ..."
              v-model="news.title"
            />
            <!-- <custom-editor-inline /> -->

            <div class="add-news__title-count">
              <span
                :class="[
                  'count',
                  {
                    'count--passed': news.title.length < 50,
                    'count--error': news.title.length > 50,
                  },
                ]"
              >
                {{ news.title.length }}
              </span>
              <span> / 50 </span>
            </div>
          </div>
          <div class="add-news__select">
            <select-persons
              v-if="addPopupVisible"
              :initialSelectedPersons="selectedValues.person"
              :placementTitle="true"
              :departments="departmentPeople"
              @select="(people) => (selectedValues.person = people)"
            />
          </div>
          <div class="priority-content" @click="highPriority = !highPriority">
            <span class="material-icons-round"> priority_high </span>
            <span>High Priority</span>
            <span v-if="highPriority" class="material-icons success"
              >check</span
            >
          </div>
          <div class="add-news__button" @click="bannerUploadPopup = true">
            <img
              :src="require('@/assets/icons/icon_add_image.svg')"
              alt="add-image"
            />
            <span>Upload banner</span>
            <span v-if="news.banner.file !== null" class="material-icons success"
              >check</span
            >
          </div>
        </div>
        <div class="add-news__editor">
          <custom-editor :value="news.text" v-model="news.text" />

          <div class="add-news__editor-count">
            <div class="preview-button" @click="previewClickHandler">
              <img src="@/assets/preview-icon.svg" />
              <span>Preview</span>
            </div>
            <span
              :class="[
                'count',
                {
                  'count--passed': stripHTML(news.text).length < 3000,
                  'count--error': stripHTML(news.text).length > 3000,
                },
              ]"
            >
              {{ stripHTML(news.text).length }}
            </span>
            <span> / 3000 </span>
          </div>
        </div>
        <div class="news-attachments">
          <div class="edit-attached-files">
            <div class="editable-attached-file" @click="downloadAttachment(file.FileID)" :class="{'border':file.FileName}" v-for="(file,ind) in news.newsAttachments" :key="ind">
                <div v-if="file.FileName" @click.stop="deleteAttachedFile(file.FileID,news.id)" class="delete">X</div>
                <div v-if="file.FileName" class="file-block" :type="file.FileName ? file.FileName.split('.').pop() : '' ">
                    {{file.FileName ? file.FileName.split(".").slice(-1)[0] : ''}}
                </div>
                  <div v-if="file.FileName" class="file-name">
                    {{file.FileName ? file.FileName : ''}}
                </div>
            </div>
          </div>
        </div>
        <div class="file-uploader-content">
          <div class="uploader-wrapper eventCalendar">
            <div class="upload-files">
              <span class="title"> Attach documents </span>
              <div class="additional-uploader-wrapper">
                <VueFileAgent
                  ref="vueFileAgent"
                  :theme="'default'"
                  :multiple="true"
                  :deletable="true"
                  :meta="true"
                  :accept="'image/*,.zip,.pdf,.docx,.doc,.xlsx,.xls,.pptx,.ppt'"
                  :maxSize="'3MB'"
                  :maxFiles="3"
                  :helpText="' '"
                  :errorText="{
                    type: 'Invalid file type',
                    size: 'Files should not exceed 3MB in size',
                  }"
                  @select="filesSelected($event)"
                  @beforedelete="onBeforeDelete($event)"
                  @delete="fileDeleted($event)"
                  v-model="fileRecords"
                >
                </VueFileAgent>
              </div>
            </div>
          </div>
        </div>
        <div class="add-news__buttons" v-if="!isEditMode">
          <div class="button button--unfilled" @click="createNews(false)">
            Save Draft
          </div>
          <div class="button" @click="createNews(true)">Publish</div>
        </div>

        <div class="add-news__buttons" v-else>
          <div
            class="button button--unfilled"
            v-if="!news.published"
            @click="saveNews(false)"
          >
            Save Draft
          </div>
          <div class="button" @click="saveNews(true)">Publish</div>
        </div>
      </div>
    </custom-popup>
    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
  </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance";
// import CustomMultiSelect from "@/components/ReusableComponents/CustomMultiSelect/CustomMultiSelect";
import CustomEditor from "@/components/ReusableComponents/CustomEditor/CustomEditor";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";
import NewsPollsOffersWrapper from "@/components/ReusableComponents/NewsPollsOffersWrapper/NewsPollsOffersWrapper";
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput";
import CustomFileInput from "@/components/ReusableComponents/CustomFileInput/CustomFileInput";
import { dragscroll } from "vue-dragscroll";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import SelectPersons from "@/components/FixedSchedules/SelectPersons.vue";
// import CustomEditorInline from '../ReusableComponents/CustomEditorInline/CustomEditorInline.vue'
import { toBase64 } from "@/Helpers/HelperFunctions";

export default {
  components: {
    CustomFileInput,
    // CustomMultiSelect,
    CustomEditor,
    CustomInput,
    CustomPopup,
    NewsPollsOffersWrapper,
    CustomSnackbar,
    SelectPersons,

    // CustomEditorInline,
  },
  async created() {
    await this.getData(1, true);
    // await this.getDepartments();
    this.getDepartmentJobs();
  },
  data() {
    return {
      search: "",
      data: [],
      fileRecords: [],
      fileRecordsForUpload: [],

      currentPage: 1,
      maxPageCount: null,
      news: {
        title: "",
        text: "",
        file: [],
        banner: {
          file: null,
          fileName: null,
          fileType:null,
          id: null,
        },
        newsAttachments:[],
      },
      addPopupVisible: false,
      bannerUploadPopup: false,
      editing: false,
      choosenStatus: "",
      departments: [],
      departmentPeople: [],
      audience: [],
      highPriority: false,
      selectedValues: {
        person: [],
        department: [],
        job: [],
      },
      isEditMode: false,
      onGoing: false,
      draft: false,
      completed: false,
      showPreviewPopup: false,
      snackbarPurpose: "",
      snackbarText: "",
      snackbarVisible: false,
    };
  },
  directives: {
    dragscroll,
  },

  computed: {
    departmentsSelect() {
      return this.departments.map((department) => ({
        value: department.ID,
        title: department.Name,
        field: "department",
        children: department.jobs.map((job) => ({
          parentField: "department",
          parentValue: department.ID,
          field: "job",
          title: job.JobName,
          value: job.Job,
          children: job.persons.map((person) => ({
            parentField: "job",
            parentValue: job.Job,
            field: "person",
            title: `${person.FirstName} ${person.LastName}`,
            value: person.PersonID,
          })),
        })),
      }));
    },
    filteredData() {
      let result = [];
      if (!this.search) {
        result = this.data;
      } else {
        result = this.data.filter((el) =>
          el.title.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return result;
    },
    filteredByStatus() {
      let result = [];

      if (this.onGoing) {
        result = this.filteredData.filter((el) => el.status == "ongoing");
      } else if (this.draft) {
        result = this.filteredData.filter((el) => el.status == "draft");
      } else if (this.completed) {
        result = this.filteredData.filter((el) => el.status == "completed");
      } else {
        result = this.filteredData;
      }

      return result;
    },
  },
  methods: {
    previewClickHandler() {
      this.showPreviewPopup = !this.showPreviewPopup;
      this.audience = [];
      let TVP = [];
      if (this.selectedValues.person.length) {
        TVP = this.selectedValues.person.map((el) => {
          return {
            PersonID: el,
          };
        });
        axios
          .post("Person/GetManyPersons", {
            TVP: TVP,
          })
          .then((resp) => {
            this.audience = resp.data.Value;
          });
      }
    },
    uploadFiles: function () {
      this.$refs.vueFileAgent.upload(
        this.uploadUrl,
        this.uploadHeaders,
        this.fileRecordsForUpload
      );
      this.fileRecordsForUpload = [];
    },
    deleteUploadedFile: function (fileRecord) {
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    async filesSelected(fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
        validFileRecords
      );
    },
    downloadAttachment(fileID){
      if(!fileID) return
      const downloadLink = document.createElement("a");
      downloadLink.href = `https://intranet.upgaming.com/api/News/GetNewsFileByID?FileID=${fileID}`
      downloadLink.target = 'blank'
      downloadLink.click();
      
    },
    deleteAttachedFile(fileID,newsID){
      let fileParams = {
        newsID:newsID,
        fileID:fileID,
      }
      axios.post("News/RemoveNewsAttachment",fileParams)
      .then(resp =>{
        this.$confirm("Are you sure you want to Delete this File?").then(() => {
          this.getSingleNews(false,this.news.id)
          this.getData(1, true)
        })
      })
    },
    readURL(file) {
        const reader = new FileReader();
        reader.onload = (event) => event.target.result;
        reader.readAsDataURL(file);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    getDepartmentJobs() {
      axios.get("News/GetPlacementDropDownPersons").then((resp) => {
        if (resp.data.ErrorMsg) return;
        this.departmentPeople = resp.data.Value.DepartmentJobPersons.map(
          (dep) => {
            return {
              id: dep.DepartmentID == null ? 0 : dep.DepartmentID,
              name:
                dep.DepartmentName == null
                  ? "Jobs without department"
                  : dep.DepartmentName,
              jobs: dep.JobPersons.map((job) => {
                return {
                  id: job.JobID,
                  name: job.JobName,
                  people: job.Persons.map((person) => {
                    return {
                      id: person.PersonID,
                      name: person.FirstName + " " + person.LastName,
                    };
                  }),
                };
              }),
            };
          }
        );
      });
    },
    async getData(page, getFirst) {
      const { data: data } = await axios.get("News/GetNewsAsAdmin", {
        params: {
          Page: page || this.currentPage,
          PageSize: 12,
        },
      });
      this.maxPageCount = data.Paging.TotalPageCount;
      let mappedData = data.Value.map((el, index) => ({
        title: el.Title,
        description: el.Description,
        id: el.ID,
        creationTime: el.CreationTime,
        status: el.Published ? "ongoing" : "draft",
        banner: el.File,
      }));
      if (getFirst) this.data = mappedData;
      else {
        this.data.push(...mappedData);
      }
    },
    async getSingleNews(item,id) {
      const response = await axios
        .get("News/GetNewsDetails", {
          params: {
            News: item.id ? item.id : id,
          },
        })
        .then((resp) => resp.data);

      const data = response.Value;
      this.resetSelectedValues();
      data.Restrictions.forEach((el) => {
        if (el.VisibilityRestrictionType === 1)
          this.selectedValues["person"].push(el.VisibleForEntity);
        else if (el.VisibilityRestrictionType === 2)
          this.selectedValues["job"].push(el.VisibleForEntity);
        else if (el.VisibilityRestrictionType === 4)
          this.selectedValues["department"].push(el.VisibleForEntity);
      });
      const news = data.News;
      this.highPriority = news.IsPriority;
      this.news = {
        id: news.NewsID,
        title: news.Title,
        text: news.Description,
        published: news.Published,
        file: [],
        newsAttachments: news.NewsFiles,
        banner: {
          fileName: news.FileName,
          fileType: news.FileType,
          file: news.File,
          id: news.FileID 
        }
      };

      this.addPopupVisible = true;
      this.isEditMode = true;
    },
    async createNews(publish) {
      if (!this.validate()) return;
      const restrictions = [
        ...this.selectedValues["person"].map((el) => ({
          VisibilityRestrictionType: 1,
          VisibleForEntity: el,
        })),
        ...this.selectedValues["job"].map((el) => ({
          VisibilityRestrictionType: 2,
          VisibleForEntity: el,
        })),
        ...this.selectedValues["department"].map((el) => ({
          VisibilityRestrictionType: 4,
          VisibleForEntity: el,
        })),
      ];
      let attachments = []
        if(this.fileRecordsForUpload.length > 0) {
          for (let i = 0; i < this.fileRecordsForUpload.length; i++) {
            if (this.fileRecordsForUpload[i]['file']) {
                this.readURL(this.fileRecordsForUpload[i]['file'])
                let attachObject = {
                    attachmentType: this.fileRecordsForUpload[i]['file'].type,
                    attachmentName: this.fileRecordsForUpload[i]['file'].name,
                    base64attachment: await toBase64(this.fileRecordsForUpload[i]['file']),
                };
                attachments.push(attachObject)
            }
          }
        }
      const object = {
        title: this.news.title,
        description: this.news.text,
        base64: this.news.banner.base64,
        fileName: this.news.banner.filename,
        fileType: this.news.banner.type,
        isPriority: this.highPriority,
        publish: publish,
        restrictions: restrictions,
        attachments: attachments,
      }
      if (
        publish &&
        this.news.title &&
        this.news.text &&
        this.selectedValues.person.length
      ) {
        const response = await axios.post("News/CreateNews", object);
        this.resetSelectedValues();
        this.addPopupVisible = false;
        this.snackbarVisible = true;
        this.snackbarText = "Success";
        this.snackbarPurpose = "success";
        this.getData(1, true);
        
      } else if (!publish) {
        axios.post("News/CreateNews", object).then((resp) => {
          this.getData(1, true);
        });
        this.news = {
          title: "",
          text: "",
          file: {},
        };
        this.snackbarVisible = true;
        this.addPopupVisible = false;
        this.snackbarText = "Saved to draft";
        this.snackbarPurpose = "success";
      } else if (!this.news.title) {
        this.snackbarVisible = true;
        this.snackbarText = "Title must be filled";
        this.snackbarPurpose = "error";
      } else if (!this.news.text) {
        this.snackbarVisible = true;
        this.snackbarText = "Description must be filled";
        this.snackbarPurpose = "error";
      } else if (!this.selectedValues.person.length) {
        this.snackbarVisible = true;
        this.snackbarText = "Choose audience";
        this.snackbarPurpose = "error";
      }
      this.fileRecords = []
      this.fileRecordsForUpload = []
    },
    async saveNews(publish) {
      if (!this.validate()) return;
      const restrictions = [
        ...this.selectedValues["person"].map((el) => ({
          VisibilityRestrictionType: 1,
          VisibleForEntity: el,
        })),
        ...this.selectedValues["job"].map((el) => ({
          VisibilityRestrictionType: 2,
          VisibleForEntity: el,
        })),
        ...this.selectedValues["department"].map((el) => ({
          VisibilityRestrictionType: 4,
          VisibleForEntity: el,
        })),
      ];
      let attachments = []
      if(this.fileRecordsForUpload.length > 0) {
        for (let i = 0; i < this.fileRecordsForUpload.length; i++) {
          if (this.fileRecordsForUpload[i]['file']) {
              this.readURL(this.fileRecordsForUpload[i]['file'])
              let attachObject = {
                  attachmentType: this.fileRecordsForUpload[i]['file'].type,
                  attachmentName: this.fileRecordsForUpload[i]['file'].name,
                  base64attachment: await toBase64(this.fileRecordsForUpload[i]['file']),
              };
              attachments.push(attachObject)
          }
        }
      }
      const object = {
        news: this.news.id,
        Title: this.news.title,
        description: this.news.text,
        base64: this.news.banner.base64,
        fileName: this.news.banner.filename,
        fileType: this.news.banner.type,
        isPriority: this.highPriority,
        publish: publish,
        restrictions: restrictions,
        attachments: attachments,
      };
      const response = await axios.post("News/UpdateNews", object);
      this.resetSelectedValues();
      this.getData(1, true);
      this.addPopupVisible = false;
      this.fileRecords = []
      this.fileRecordsForUpload = []
    },
    async getDepartments() {
      const { data: departmentsList } = await axios.get(
        "departments/GetDepartments"
      );
      const departments = departmentsList.Value;
      for (let department of departments) {
        const { data: jobsList } = await axios.get(
          "departments/GetDepartmentJobs",
          {
            params: {
              DepartmentID: department.ID,
            },
          }
        );
        const jobs = jobsList.Value;
        for (let job of jobs) {
          const { data: personList } = await axios.get("jobs/JobPersons", {
            params: {
              JobID: job.Job,
            },
          });
          job.persons = personList.Value;
        }
        department.jobs = jobs;
      }
      this.departments = departments;
      this.resetSelectedValues();
    },
    editClickHandler(item) {
      this.editing = true;
      this.addPopupVisible = true;
    },
    deleteClickHandler(item) {
      this.$confirm("Would you like to delete this news?").then(() => {
        axios
          .post("News/DeleteNews", {
            News: item.id,
          })
          .then((response) => this.getData(1, true));
      });
    },
    handleChangedValue(selectedList) {
      this.selectedValues = selectedList;
    },
    validate() {
      return (
        this.stripHTML(this.news.text).length <= 3000 &&
        this.news.title.length <= 50
      );
    },
    stripHTML(html) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },
    resetSelectedValues() {
      this.highPriority = false;
      this.selectedValues = {
        person: [],
        department: [],
        job: [],
      };
    },
    redirectToNews({ id }) {
      this.$router.push(`/News/${id}`);
    },
  },
  watch: {
    onGoing() {
      if (this.onGoing == true) {
        this.draft = false;
      }
    },
    draft() {
      if (this.draft == true) {
        this.onGoing = false;
      }
    },
    isEditMode() {
      if (!this.isEditMode) {
        //     news: {
        //   title: "",
        //   text: "",
        //   file: {},
        // },
        this.news.title = "";
        this.resetSelectedValues();
        this.news.text = "";
        this.news.file.base64 = "";
        (this.news.file.filename = ""),
          (this.news.file.size = ""),
          (this.news.file.type = "");
        this.news.id = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  height: 100%;
}

.upload-button {
  cursor: pointer;
  width: 197 * $rem;
  display: inline-block;
  height: 40 * $rem;
  line-height: 40 * $rem;
  padding: 0 15 * $rem 0 15 * $rem;
  outline: none;
  text-align: center;
  color: white;
  margin-top: 10px;
  border-radius: 5 * $rem;
  box-shadow: none;
  background-color: #1de9b6;
}

.news {
  &__audience {
    margin-top: auto;

    &-header {
      margin-bottom: 25px;

      &-title {
        font-size: 18px;
        color: #283593;

        b {
          font-weight: 500;
        }

        span {
          font-weight: lighter;
        }
      }
    }

    &-items {
      display: flex;
      overflow: hidden;
      cursor: e-resize;
      padding-bottom: 20px;

      &-item {
        margin-right: 65px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        &-avatar {
          width: 90px;
          height: 90px;
          background-color: rgba(40, 53, 147, 0.05);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 100%;
          margin-bottom: 5px;
        }

        &-name {
          color: #283593;
          font-weight: lighter;
        }
      }
    }
  }
}

.add-news {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    margin-bottom: 15 * $rem;
    align-items: center;
  }
  .priority-content {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
    span:nth-child(1) {
      color: $blue-primary;
      opacity: 0.7;
    }
    span:nth-child(2) {
      color: $blue-primary;
    }
  }
  &__title {
    position: relative;
    width: 750 * $rem;
    margin-right: 20px;

    &-input {
      width: 750 * $rem;
    }

    &-count {
      position: absolute;
      right: 10px;
      top: 12px;
      color: $blue_primary;
      font-size: 14px;
      font-weight: lighter;
      background-color: #fff;
    }
  }

  .material-icons {
    color: $green-primary;
    font-size: 21px;
    margin-left: 3px;
  }

  &__buttons {
    margin-top: 35 * $rem;
    display: flex;
    margin-left: auto;

    .button {
      cursor: pointer;
      width: 150 * $rem;
      height: 50 * $rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      background-color: rgba($blue-primary, 0.6);
      color: white;
      transition: 0.2s ease;

      &:hover {
        background-color: rgba($blue-primary, 0.8);
        border-color: rgba($blue-primary, 0.8);
      }

      &--unfilled {
        background-color: transparent;
        color: rgba($blue-primary, 0.6);
        border: 1px solid rgba($blue-primary, 0.6);

        &:hover {
          border-color: rgba($blue-primary, 0.8);
          background-color: rgba($blue-primary, 0.8);
          color: white;
        }
      }

      &:not(:last-child) {
        margin-right: 10 * $rem;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $blue_primary;
    margin-left: 20px;
    font-weight: 300;
    cursor: pointer;
    span {
      white-space: nowrap;
    }
  }

  &__editor {
    display: flex;
    flex-direction: column;
    ::v-deep .tox-tinymce {
      height: 300px !important;
      overflow-y: scroll !important;
    }

    &-count {
      margin-top: 10 * $rem;
      font-weight: lighter;
      font-size: 14 * $rem;
      // margin-left: auto;
      display: flex;
    }
  }
}

.preview-button {
  display: flex;
  align-items: center;
  margin-right: auto;

  cursor: pointer;
  font-size: 14px;
  font-weight: 300;

  span {
    color: rgba($blue-primary, 0.8);
    margin-left: 5px;
  }
}

.count {
  &--error {
    color: $red;
  }

  &--passed {
    color: green;
  }
}

.popup {
  ::v-deep .popup {
    // width: 1300px !important;
    left: 57%;
  }
}

.preview-popup {
  // width: 100%;
  ::v-deep .overlay {
    z-index: 100;

    .popup {
      width: 73%;
      height: 90%;
      top: 55%;
      left: 56%;
      display: flex;
      flex-direction: column;
    }
    .title {
      display: none;
    }
  }
  .desc-content {
    width: calc(50% - 15px);
    .preview-header {
      font-size: 20px;
      font-weight: 500;
      color: #283593;
      margin-bottom: 15px;
    }
    .preview-desc {
      height: 90%;
      overflow: auto;
      color: $blue-primary;
      font-weight: 300;
      font-size: 14px;
    }
  }
  .banner-content {
    width: calc(50% - 15px);
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 25px;
    border-radius: 6px;
  }

  .desc-banner-content {
    height: 410px;
    display: flex;
    justify-content: space-between;
  }
  .news {
    padding: 30px 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    &__title {
      font-size: 20px;
      font-weight: 500;
      color: #283593;
      margin-bottom: 15px;
    }

    &__text {
      font-size: 14px;

      overflow-y: auto;

      max-height: 370px;
      word-break: break-all;
      color: #283593;

      &--without-banner {
        max-height: calc(250px + 25px + 210px);
      }
    }

    &__audience {
      margin-top: auto;
      &-header {
        margin-bottom: 25px;

        &-title {
          font-size: 18px;
          color: #283593;

          b {
            font-weight: 500;
          }
          span {
            font-weight: lighter;
          }
        }
      }

      &-items {
        display: flex;
        overflow: hidden;
        cursor: e-resize;
        padding-bottom: 20px;

        &-item {
          margin-right: 65px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          &-avatar {
            width: 90px;
            height: 90px;
            background-color: #ededfc;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 100%;
            margin-bottom: 5px;
          }

          &-name {
            color: #283593;
            font-weight: lighter;
          }
        }
      }
    }
  }
}
.file-uploader-content{
  max-width: 19rem;
}
.upload-files {
  margin-top: 0.625rem;
  .title {
    font-size: 0.8125rem;
    color: #959bc9;
  }

  .additional-uploader-wrapper {
    margin-top: 0.6875rem;
  }
}

::v-deep .eventCalendar .file-preview-new .file-preview span:not(.help-text) {
  top: 1.5rem !important;
}

.edit-attached-files {
  width: 100%;
  display: inline-flex;
  margin-top: 2rem;

  .editable-attached-file {
    width: 10rem;
    height: 3.1625rem;
    border-radius: 0.375rem;
    margin-left: 0.625rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &.border {
      border: 0.0625rem solid #dedff4;
    }
    .file-name {
      width: 5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $blue-primary;
      font-size: 0.875rem;
    }

    .file-block {
      width: 1.875rem;
      height: 1.875rem;
      font-size: 0.8125rem;
      display: grid;
      place-items: center;
      color: #fff;
      margin-right: 0.625rem;
      border-radius: 0.375rem;
      cursor: pointer;

      &[type="pdf"] {
        background-color: #ff6760;
      }

      &[type="xlsx"] {
        background-color: rgba(17, 123, 65, 0.871);
      }
      &[type="PNG"],
      [type="png"] {
        background-color: $green-primary;
      }
      &[type="SVG"],
      [type="svg"] {
        background-color: $green-primary;
      }
      &[type="jpg"] {
        background-color: rgba(17, 123, 65, 0.871);
      }
      &[type="txt"] {
        background-color: #364ba2;
      }

      &[type="docx"],
      &[type="doc"] {
        background-color: #364ba2;
      }
    }

    .delete {
      position: absolute;
      top: 0.125rem;
      right: 0.3125rem;
      font-size: 0.8125rem;
      color: $blue-primary;
      cursor: pointer;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}

.file-block{
    width: 1.875rem;
    height: 1.875rem;
    font-size: 0.8125rem;
    display: grid;
    place-items: center;
    color: #fff;
    margin-right: 0.625rem;
    border-radius: 0.375rem;

    &[type="pdf"] {
        background-color: #ff6760;
    }

    &[type="xlsx"] {
        background-color: rgba(17, 123, 65, 0.871);
    }
    &[type="PNG"],[type="png"] {
        background-color: $green-primary;
    }
    &[type="SVG"],[type="svg"] {
        background-color: $green-primary;
    }
    &[type="jpg"] {
        background-color: rgba(17, 123, 65, 0.871);
    }
    &[type="txt"] {
        background-color: #364ba2;
    }

    &[type="docx"],&[type="doc"] {
        background-color: #364ba2;
    }
    &[type="jpeg"]{
        background-color: $green-primary;
    }
}
</style>