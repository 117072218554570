<template>
  <div class="comment-popup" v-if="commentVisible" ref="commentPopup">
    <table>
      <thead>
        <tr>
          <th>Comment</th>
          <th>Comment By</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(el, ind) in data" :key="el.index">
          <td>
            <span class="comment">{{ ind + 1 + "." + el.Comment }}</span>
          </td>
          <td>
            <span class="name"> {{ el.FirstName + " " + el.LastName }}</span>
          </td>
          <td>
            <span>{{ dateText(el.StartDate) }}</span>
          </td>
          <td class="actions" v-if="el.ResponsedBy == loggedPerson && isAdmin">
            <span class="icon-span" @click="$emit('delete-icon', el)">
              <img src="@/assets/delete.svg" alt="delete" />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getDateString, CETToTimestamp } from "@/Helpers/HelperFunctions";
export default {
  created() {
    document.addEventListener("keydown", (e) => {
      const event = e || window.event;
      let isEscape;
      if ("key" in event) {
        isEscape = event.key === "Escape" || event.key === "Esc";
      } else {
        isEscape = event.keyCode === 27;
      }
      if (isEscape) {
        this.$emit("close");
      }
    });
    this.loggedPerson = this.$store.state.profile.ID;

    document.addEventListener("click", this.documentClick);
  },
  props: {
    commentVisible: {
      type: Boolean,
      required: true,
    },
    data: Array,
  },
  data() {
    return {
      loggedPerson: "",
    };
  },

  methods: {
    dateText(date) {
      return getDateString(date, "dd-MM-yyyy" + " " + "HH:mm");
    },
    documentClick(event) {
      let specifiedElement = this.$refs.commentPopup;
      let targetEl = event.target;

      if (targetEl != specifiedElement) {
        this.$emit("close");
      }
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin || this.$store.getters.isSuperAdmin;
    },
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style lang="scss" scoped>
.comment-popup {
  // height: 200px;
  width: 700px;
  background-color: #fff;
  border-radius: 10px;
  // position: absolute;
  // z-index: 15;
  padding-top: 10px;
  padding-left: 20px;

  // right: 25%;

  table {
    width: 100%;
    thead {
      text-align: left;
      tr {
        th {
          font-weight: 400;
        }
      }
    }
    tbody {
      text-align: left;
      tr {
        height: 40px;
        .comment {
          width: 320px;
          word-break: break-word;
          display: block;
        }
        td {
          font-weight: 300;
        }
      }
    }
  }
  .popup-header {
    font-size: 16px;
    span:nth-child(2) {
      margin-left: 250px;
    }
    span:nth-child(3) {
      margin-left: 100px;
    }
  }
  .comment {
    font-size: 15px;
    font-weight: 300;
    span {
      display: inline-block;
    }
    span:nth-child(2) {
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.icon-span {
  height: 30px;
  width: 30px;
  display: block;
}
</style>