<template>
  <div class="input-content" @click.stop>
    <div class="arrows-content">
      <i class="material-icons" v-if="!collapsed">keyboard_arrow_down</i>
      <i class="material-icons" v-else>keyboard_arrow_up</i>
    </div>
    <label for="input">
      <span
        class="title"
        @click="doFocus"
        :class="{ focused: focused || inputText != '' }"
      >
        {{ title }}
        {{ isRequired ? "*" : "" }}
      </span>
      <input
        :required="isRequired"
        :disabled="disabled"
        v-model="inputText"
        type="text"
        @focus="doFocus"
        @blur="doBlur"
      />
    </label>
    <div :class="['options-content', { menuTop: menuTop }]" v-if="collapsed">
      <template v-if="filteredOptions.length">
        <div
          class="single-option"
          @click="optClickHandler(opt)"
          v-for="(opt, ind) in filteredOptions"
          :key="ind"
        >
          {{ opt.title }}
        </div>
      </template>
      <template v-else>
        <div></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
    isRequired: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: String,
    value: [Number, String],
    menuTop: Boolean,
  },
  created() {
    document.addEventListener("click", this.documentClick);
  },
  data() {
    return {
      collapsed: false,
      focused: false,
      inputText: "",
    };
  },
  methods: {
    documentClick() {
      this.collapsed = false;
      this.focused = false;
    },
    doFocus() {
      this.focused = true;
      this.collapsed = true;
    },
    doBlur() {
      this.focused = false;
    },
    optClickHandler(opt) {
      this.collapsed = false;
      this.$emit("input", opt.value);
    },
  },
  computed: {
    filteredOptions() {
      if (!this.inputText.length) return this.options;
      return this.options.filter((el) => {
        return el.title.toLowerCase().includes(this.inputText.toLowerCase());
      });
    },
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.inputText = this.options.find((el) => el.value === value).title;
        }
        if (value === "" || value === null) {
          this.inputText = "";
        }
      },
      immediate: true,
    },
    inputText() {
      if (this.inputText == "") {
        this.$emit("clear");
      }
    },
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style scoped lang="scss">
.input-content {
  position: relative;
  width: 100%;
  .options-content {
    position: absolute;
    top: 45px;
    min-width: 13.625rem;
    max-height: 17rem;
    z-index: 100;
    background-color: white;
    border: 0.0625rem solid #dcdde6;
    color: #dadcea;
    border-radius: 0.375rem;
    padding: 0.75rem 0;
    overflow-y: auto;
    -webkit-animation: show 0.1s linear;
    animation: show 0.1s linear;

    &.menuTop {
      top: unset;
      bottom: calc(100% + 0.6rem);
    }

    .single-option {
      min-height: 1.875rem;
      padding: 0 1.875rem;
      display: flex;
      align-items: center;
      font-weight: 300;
      cursor: pointer;
      color: rgba(40, 53, 147, 0.7);
      &:hover {
        background-color: #f4f4f8;
      }
    }
  }

  .arrows-content {
    position: absolute;
    right: 0.9375rem;
    z-index: 1;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0.6;
    .material-icons {
      font-size: 1.125rem;
    }
  }
  label {
    display: block;
    width: 100%;
    height: 2.5rem;
    position: relative;
    .title {
      color: rgba(40, 53, 147, 0.7);
      position: absolute;
      top: 0.625rem;
      font-size: 14px;

      font-weight: 300;
      margin-left: 0.9375rem;
      -webkit-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      background-color: white;
      white-space: nowrap;
      cursor: text;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 3px;
      &.focused {
        top: -6 * $rem;
        font-size: 12 * $rem;
        padding-left: 2 * $rem;
        padding-right: 2 * $rem;
        left: 0;
      }
    }
    input {
      width: 100%;
      border: 0;
      height: 2.5rem;
      color: rgba(40, 53, 147, 0.7);
      font-size: 0.9375rem;
      padding: 0 0.9375rem 0 0.9375rem;
      outline: none;
      border-radius: 6px;
      border: 0.0625rem solid #dcdde6;
    }
  }
}
</style>