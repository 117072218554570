<template>
    <div :class="['checkbox',{'mandatory': mandatory,'disabled' : disabled}]" @click="state">
        <img v-if="checked || mandatory" class="checkMark" src="@/assets/icons/done.svg">    
    </div>
</template>

<script>
export default {
    props:{
        checked:{
            type: Boolean,
            default: false,
        },
        mandatory:{
            type: Boolean,
            default: false,
        },
        disabled:{
            type: Boolean,
            default: false,
        }
    },
    methods:{
        state(){
            if(this.mandatory) return
            else (this.$emit('value', !this.checked))
        }
    },
}
</script>

<style scoped lang="scss">

.checkbox {
    width: 0.875rem;
    height: 0.875rem;
    border: 0.0625rem solid $blue-primary;
    border-radius: 0.1875rem;
    cursor: pointer;
    position: relative;

    > .checkMark{
        width: 1.125rem;
        position: absolute;
        top: -0.125rem;
        left: 0.0625rem;
        user-select: none;
        pointer-events: none;
    }

    &.mandatory{
        border: 0.0625rem solid transparent;
        cursor: default;
    }

    &.disabled{
        opacity: .5;
        pointer-events: none;
        user-select: none;
    }
}

</style>