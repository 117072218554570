<template>
  <div class="container" id="chart">
    <div class="title">Bonus Limits by teams</div>
    <div class="filter-div">
      <div class="visible">
        <div class="visibleLabel">
          <label class="visibleRadio public">
            <input type="radio" id="public" :value="true" v-model="assigned" />
            <span @click="fillData">Assigned</span>
          </label>
          <label class="visibleRadio private">
            <input type="radio" id="private" :value="true" v-model="spent" />
            <span @click="getSecondData">Spent</span>
          </label>
        </div>
      </div>
      <date-range
        :initialFrom="fromDate"
        :initialTo="toDate"
        @from="(from) => (fromDate = from)"
        @to="(to) => (toDate = to)"
        class="date_range"
      />
    </div>
    <div class="filter-div"></div>
    <pie-chart
      v-if="loaded"
      :width="700"
      :chart-data="chartDataList"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import PieChart from "@/components/ReusableComponents/Charts/PieChart.js";
import DateRange from "@/components/ReusableComponents/DateRange/DateRange";
import axios from "@/Helpers/AxiosInstance.js";

export default {
  name: "Pie",
  components: {
    PieChart,
    DateRange,
  },
  data: () => ({
    fromDate: "",
    assigned: true,
    spent: false,
    toDate: "",
    filterfromDate: "",
    filtertoDate: "",
    loaded: false,
    chartDataList: null,
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
    },
  }),

  mounted() {
    this.fillData();
    // this.getSecondData();
  },
  methods: {
    fillData() {
      this.loaded = false;
      try {
        axios
          .get(
            `salary/GetDepartmentsBonusLimitsForChart?FromDate=${this.filterfromDate}&ToDate=${this.filtertoDate}`
          )
          .then((resp) => {
            this.chartDataList = {
              labels: resp.data.Value.DepNames,
              datasets: [
                {
                  backgroundColor: resp.data.Value.Dataset.Colors,
                  data: resp.data.Value.Dataset.Amounts,
                },
              ],
            };
          });
        this.loaded = true;
      } catch (e) {
        console.error(e);
      }
    },
    getSecondData() {
      this.loaded = false;

      axios
        .get("salary/GetDepartmentsBonusLimitsUsageForChart2")
        .then((resp) => {
          this.chartDataList = {
            labels: resp.data.Value.DepNames,
            datasets: [
              {
                backgroundColor: resp.data.Value.Dataset.Colors,
                data: resp.data.Value.Dataset.Amounts,
              },
            ],
          };
        });
      this.loaded = true;
    },
    getDateString(date, format = "yyyy-MM-dd") {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      if (date === "Invalid Date") return;

      const MONTHS = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      var days = ("0" + date.getDate()).slice(-2),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        year = date.getFullYear(),
        hours = ("0" + date.getHours()).slice(-2),
        minutes = ("0" + date.getMinutes()).slice(-2),
        seconds = ("0" + date.getSeconds()).slice(-2);

      return format
        .replace("dd", days)
        .replace("MMM", MONTHS[parseInt(month) - 1])
        .replace("MM", month)
        .replace("yyyy", year)
        .replace("yy", String(year).slice(-2))
        .replace("HH", hours)
        .replace("mm", minutes)
        .replace("ss", seconds)
        .replace("ww", WEEKDAYS[date.getDay()]);
    },
  },
  watch: {
    assigned() {
      if (this.assigned) {
        this.spent = false;
      }
    },
    spent() {
      if (this.spent) {
        this.assigned = false;
      }
    },
    fromDate() {
      this.filterfromDate = this.getDateString(this.fromDate);
      this.filtertoDate = this.getDateString(this.toDate);
      this.fillData();
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  // display: flex;
  font-size: 1.125rem;
  color: $blue-primary;
}
#chart {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 3.75rem;
}
.filter-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.9375rem;
  padding: 0 1.75rem;
  margin-top: 1.875rem;
}
.file_visibility {
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
}
.visible_title {
  font-size: 0.875rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #283593;
}
.visibleLabel {
  padding: 0.1563rem;
  border-radius: 0.5rem;
  border: solid 0.0625rem rgba(105, 86, 214, 0.15);
  background-color: #ffffff;
  margin-top: 0.3125rem;
  display: flex;
  input {
    display: none;
  }
}
.visibleLabel input {
  display: none;
}
.visibleLabel label {
  display: inline-block;

  cursor: pointer;
  border-radius: 0.5rem;
}

.visibleLabel span {
  display: block;
  padding: 0.3125rem 0.9375rem 0.3125rem 0.9375rem;
  border-radius: 0.3125rem;
  position: relative;
  transition: all 0.25s linear;
  color: #6956d6;
  font-size: 0.875rem;
}
.visibleLabel input:checked + span {
  background-color: #6956d6;
  box-shadow: 0 0 0.3125rem 0.125rem rgba(0, 0, 0, 0.1);
}
.visibleLabel input:checked + span {
  color: #fff;
  border-color: #6956d6;
}
.visibleLabel input:checked + span:before {
  background-color: #6956d6;
}
</style>