<template>
  <div
    :class="['block', { 'block--without-banner': !item.banner }]"
    @click="$emit('clicked', item)"
  >
    <img
      :src="getBlockTypeSourceIcon(item.status)"
      alt="status"
      class="block__type"
    />
    <div
      :style="{
        'background-image': `url(data:image/jpeg;base64,${item.banner})`,
      }"
      v-if="item.banner"
      class="block__banner"
    />
    <div class="block__header">
      <div class="block__title" v-html="item.title"></div>
    </div>

    <p class="block__description">
      {{ stripHTML(item.description) }}
    </p>
    <div class="block__date">
      {{ getDateString(item.creationTime, "dd MMM yyyy") }}
      <div class="block__options">
        <img
          :src="require('@/assets/icons/edit.svg')"
          alt=""
          class="block__edit"
          @click.stop="$emit('edit-item', item)"
        />
        <img
          :src="require('@/assets/icons/delete.svg')"
          alt=""
          class="block__delete"
          @click.stop="$emit('delete-item', item)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { timestampToDateTime } from "@/Helpers/HelperFunctions";
import { getDateString } from "@/Helpers/HelperFunctions";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getDateString,
    getBlockTypeSourceIcon(status) {
      switch (status) {
        case "completed":
          return require("@/assets/icons/icon_completed.svg");
        case "ongoing":
          return require("@/assets/icons/icon_ongoing.svg");
        case "draft":
          return require("@/assets/icons/icon_draft.svg");
        default:
          return null;
      }
    },
    stripHTML(html) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      const data = tmp.textContent || tmp.innerText || "";
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  width: 305 * $rem;
  height: 290 * $rem;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  //   padding-bottom: 30 * $rem;
  margin-right: 50 * $rem;
  margin-bottom: 20 * $rem;
  &:nth-child(4n + 0) {
    margin-right: 0;
  }

  &--without-banner {
    padding-top: 60 * $rem;
    padding-bottom: 35 * $rem;
  }

  &:hover {
    .block__options {
      visibility: visible;
    }
  }
  .desc-content {
    // width: 100%;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
  &__header,
  &__description,
  &__date {
    padding-left: 10px;
    display: flex;
    align-items: center;
  }

  &__type {
    position: absolute;
    left: 10 * $rem;
    top: 0;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__banner {
    width: 100%;
    height: 205 * $rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  &__options {
    margin-left: auto;
    display: flex;
    visibility: hidden;

    img {
      margin-right: 1px;
      cursor: pointer;
      width: 28px;
      height: 28px;
    }
  }

  &__title {
    font-weight: 500;
    color: $blue_primary;
    text-transform: capitalize;
    // max-width: 230 * $rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__description {
    display: -webkit-box;

    color: #9696b9;
    font-size: 14 * $rem;
    max-height: 62 * $rem;
    max-width: 290px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__date {
    color: #9696b9;
    font-size: 14 * $rem;
  }
}
</style>
