<template>
    <div class="employee-jobs">
        <div class="helper">
            <h3>Job Employees</h3>
            <form @submit.prevent="addPerson" v-if="permission">
                <input-search
                    :options="persons"
                    title="Choose Person"
                    :isRequired="true"
                    @select="(person) => (this.selectedPerson = person)"
                />
                <date-picker v-model="startTime" title="Start Date" />
                <button class="btn">Add</button>
            </form>
        </div>

        <grid
            identificator="PersonJobID"
            :gridData="jobEmployees"
            :columns="columns"
            :pagination="false"
            :canDelete="permission"
            @rowDelete="deleteRow"
            :alternativeStyle="true"
        />
    </div>
</template>

<script>
import InputSearch from '@/components/ReusableComponents/InputSearch/InputSearch.vue'
import DatePicker from '@/components/ReusableComponents/DatePicker/DatePicker.vue'
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
import axios from '@/Helpers/AxiosInstance.js'
export default {
    components: {
        InputSearch,
        DatePicker,
        Grid,
    },
    props: {
        jobID: Number,
        permission: {
            type: Boolean,
            default: true,
        },
        pagination: {
            type: Boolean,
            default: true,
        },
        showHistory: Boolean,
    },
    created() {
        this.getPersons()
        this.getJobEmployees()
    },
    data() {
        return {
            allJobs: [],
            jobEmployees: [],
            selectedPerson: '',
            startTime: '',
            persons: [],
        }
    },
    methods: {
        getPersons() {
            axios.get('jobs/GetPersonsWithoutActiveJob').then((resp) => {
                if (!resp.data.Value) return
                this.persons = resp.data.Value.map((el) => ({
                    title: `${el.FirstName} ${el.LastName}`,
                    value: el.ID,
                }))
            })
        },
        getJobEmployees() {
            axios
                .get('jobs/JobPersons', {
                    params: {
                        jobID: this.jobID,
                    },
                })
                .then((resp) => {
                    if (!resp.data.Value) return
                    this.jobEmployees = resp.data.Value.map((el) => {
                        if (!el.EndTime) el.EndTime = 0
                        return el
                    })
                })
        },
        addPerson() {
            axios
                .post('jobs/AssignPersonjob', {
                    Person: this.selectedPerson,
                    Job: this.jobID,
                    StartTime: this.startTime,
                })
                .then(() => {
                    this.getJobEmployees()
                })
        },
        deleteRow(ID) {
            this.$confirm('Would you like to remove this person from the job?').then(() => {
                axios
                    .post('jobs/UpdatePersonJob', {
                        ID: ID,
                        Deleted: true,
                    })
                    .then(() => this.getJobEmployees())
            })
        },
    },
    computed: {
        columns() {
            return [
                {
                    title: 'First Name',
                    key: 'FirstName',
                },
                {
                    title: 'Last Name',
                    key: 'LastName',
                },
            ]
        },
    },
    watch: {
        showHistory(newVal, oldVal) {
            if (newVal != oldVal) this.getEmployeeJobs()
        },
    },
}
</script>

<style lang="scss" scoped>
form {
    display: inline-flex;

    margin-bottom: 30 * $rem;

    .input-search,
    label {
        margin-right: 15 * $rem;
    }
}
.btn {
    background-color: rgba($blue-primary, 0.5);
    color: #ffffff;
    height: 40px;
    width: 100px;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
}
.helper {
    display: flex;
    justify-content: space-between;
    padding-left: 40 * $rem;
    padding-right: 40 * $rem;
    h3 {
        font-size: 1.25rem;
        color: #283593;
        font-weight: 400;
    }
}
</style>