<template>
  <div class="background">
    <div class="header">
      <div class="totals-content">
        <div class="cost">
          <span>Current Total Cost</span>
          <span>{{ totalCost }}</span>
        </div>
        <div class="employee">
          <span>Total Employees</span>
          <span>{{ totalEmploye }}</span>
        </div>
      </div>
      <div class="filters">
        <div class="filter-wrapper" v-if="!$store.getters.hasRole('Manager')">
          <div class="recalculate" @click="recalculate">
            <span>Recalculate</span>
          </div>
        </div>
        <div class="filter-wrapper">
          <div class="export" @click="exportForExcel">
            <span class="material-icons-outlined">file_download</span>
            <span>Export</span>
          </div>
        </div>
        <div class="filter-wrapper">
          <date-range
            ref="dateRange"
            :initialFrom="dateRangeFrom"
            :initialTo="dateRangeFrom"
            @from="(from) => (dateRangeFrom = from)"
            @to="(to) => (dateRangeTo = to)"
            :alternativeCalendar="true"
          />
        </div>
        <div class="filter-wrapper">
          <custom-select
            title="Office"
            v-model="selectedOffice"
            :options="officeOptions"
          />
        </div>
        <div class="filter-wrapper">
          <choose-persons
            ref="personsSelect"
            :allPersons="allPersons"
            @person-clicked="personClickHanlder"
            @clear="clearPersons"
            :title="'Person/s'"
          />
        </div>
        <div class="filter-wrapper">
          <div class="clear-all" @click="clearAllFilters">
            <span>Clear All</span>
          </div>
        </div>
      </div>
    </div>

    <div class="payroll-dashboard" v-if="filteredData.length">
      <dashboard-table
        @show-details="getDetails"
        @send-sms="smsPopupVissible = true"
        @show-params="getRowParams"
        :data="filteredData"
        :results="rowDetails.results"
        :columns="filteredColumns"
        :columnsForParameters="columnsForParameters"
        :dataForParameters="rowDetails.parameters"
        :dataForTransactions="rowDetails.transactions"
        :columnsForTransactions="columnsForTransactions"
        @make-payment="makePayment"
        @show-transactions="getTransactions"
        @sort="sortHandler"
        @update-transaction="updateTransaction"
        :footerData="footerData"
        :dataForFooterFactor="footerFactors"
      />
    </div>
    <div class="no-data" v-else>
      <span>No Data</span>
    </div>
    <custom-popup :visible="popupVisible" @close="popupVisible = false">
      <form @submit.prevent="recalculateSave">
        <div class="recalculate-content">
          <div class="title">Set Recalculate Date</div>
          <div class="recalculate-flex">
            <div class="flex">
              <date-picker
                class="date"
                title="Select calculation date"
                :isRequired="true"
                v-model="recalculateData.SelectDate"
                @clear="recalculateDate.SelectDate = ''"
              />
            </div>
            <div class="flex">
              <date-picker
                class="date"
                title="Set recalculate date"
                :isRequired="true"
                v-model="recalculateData.SetDate"
                @clear="recalculateDate.SetDate = ''"
              />
            </div>
          </div>
          <div class="recalculate-person">
            <choose-persons
              ref="personsSelect"
              :allPersons="recalculateData.allPersons"
              @person-clicked="personClickHanlder"
              :title="'Person/s'"
            />
          </div>
          <div class="recalculate-info">
            <img src="@/assets/info-circle.svg" alt="" />
            <span>
              In such case, If You won't select any person in the list, then the
              re-calculate will be done automatically for all persons!
            </span>
          </div>
          <div class="save-button-div">
            <button class="save-button">Save</button>
          </div>
        </div>
      </form>
    </custom-popup>
    <custom-popup
      class="sms-popup"
      @close="smsPopupVissible = false"
      :title="'Send SMS'"
      :visible="smsPopupVissible"
    >
      <div class="send-sms-content">
        <div class="sms-options-wrapper">
          <custom-select
            title="Office"
            v-model="smsOffice"
            :options="JSON.parse(JSON.stringify(officeOptions))"
          />
        </div>
        <div class="sms-options-wrapper">
          <date-picker v-model="smsDate" title="Date" />
        </div>
        <div class="sms-options-wrapper">
          <choose-persons
            ref="personsSelect"
            :allPersons="smsPersons"
            @person-clicked="selectForsmsHendler"
            @clear="clearPersons(true)"
            :title="'Person/s'"
          />
        </div>
        <button class="send-button" @click="sendSms">
          Send
        </button>
      </div>
    </custom-popup>
    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
  </div>
</template>
<script>
import DashboardTable from './DashboardTable.vue'
import axios from '@/Helpers/AxiosInstance.js'
import DateRange from '@/components/ReusableComponents/DateRange/DateRange'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import CustomSelect from '@/components/ReusableComponents/CustomSelect/CustomSelect.vue'
import choosePersons from '@/components/ReusableComponents/ChoosePersons/choosePersons.vue'
import CustomPopup from '@/components/ReusableComponents/CustomPopup/CustomPopup.vue'
import DatePicker from '@/components/ReusableComponents/DatePicker/DatePicker.vue'

export default {
  components: {
    DashboardTable,
    DateRange,
    CustomSelect,
    choosePersons,
    CustomSnackbar,
    CustomPopup,
    DatePicker,
  },
  async created() {
    await this.getSalaryResults()
    await this.getSalaryFactors()
    this.pushFactors()
    this.getOffices()
    this.getPersons()
  },
  data() {
    return {
      searchTerm: '',
      sortOrder: '',
      sortTerm: '',
      snackbarVisible: false,
      smsPopupVissible: false,
      snackbarText: '',
      smsDate: '',
      smsOffice: '',
      snackbarPurpose: '',
      popupVisible: false,
      allPersons: [],
      smsPersons: [],
      selectedForSms: [],
      data: [],
      footerData: {},
      footerFactors: [],
      selectedPersons: [],
      filterSettings: [
        {
          filter: '',
          from: '',
          to: '',
        },
      ],
      offices: [],
      selectedOffice: '',
      dateRangeFrom: 0,
      dateRangeTo: 0,
      rowDetails: {
        results: {
          columnOne: [],
          columnTwo: [],
          columnThree: {
            factors: [],
          },
          columnFour: {
            factors: [],
          },
          columnFive: [],
        },
        parameters: [],
        transactions: [],
      },
      recalculateData: {
        SelectDate: null,
        SetDate: null,
        allPersons: [],
      },
      factors: [],
      columns: [
        {
          title: 'Name Surname',
          visible: true,
          alwaysActive: true,
          key: 'FirstName',
          customTemplate: (row, val) => val + ' ' + row.LastName,
        },
        {
          title: 'Payroll Date',
          visible: false,
          key: 'SalaryDate',
          customTemplate: (row, val) => this.getDateString(val),
        },
        {
          title: 'Personal ID',
          visible: false,
          key: 'PersonalNumber',
        },
        {
          title: 'IBAN',
          visible: false,
          key: 'BankAccountIban',
        },
        // {
        //   title: "Bank Code",
        //   visible: false,
        //   key: "BankAccountNum",
        // },
        {
          title: 'Start Date',
          visible: false,
          key: 'JobStartDate',
          customTemplate: (row, val) => this.getDateString(val),
        },
        {
          title: 'Worked Days',
          visible: false,
        },
        {
          title: 'Pay period',
          visible: true,
          alwaysActive: true,
          key: 'PayPeriod',
        },

        {
          title: 'Net Salary',
          visible: true,
          key: 'NetSalary',
          alwaysActive: true,
          haveTotal: true,
          canSort: true,
        },

        {
          title: 'Exchange rate',
          visible: false,
          key: 'CurrencyRate',
          customTemplate: (row, val) =>
            row.CurrencyCode !== 'GEL' ? val : '-',
        },
        {
          title: 'Basic Salary',
          visible: true,
          alwaysActive: true,
          key: 'BasicSalary',
          haveTotal: false,
          canSort: true,
        },

        {
          title: 'Currency',
          visible: true,
          key: 'CurrencyCode',
          alwaysActive: true,
          canSort: true,
        },

        {
          haveTotal: true,
          title: 'Taxes',
          visible: true,
          alwaysActive: true,
          key: 'Taxes',
          canSort: true,
        },
        {
          title: 'Bonuses',
          visible: false,
          haveTotal: true,
          key: 'BonusTotal',
          customOptions: true,

          showChilds: false,
          childrens: [],
        },
        {
          title: 'Penalty',
          key: 'PenaltyTotal',
          visible: false,
          customOptions: true,
          haveTotal: true,

          childrens: [],
          showChilds: false,
        },

        {
          title: 'Services',
          visible: true,
          alwaysActive: true,
          key: 'FactorServices',
          haveTotal: true,
          canSort: true,
        },
        // {
        //   title: 'Enrollment Net',
        //   visible: true,
        //   alwaysActive: true,
        //   key: 'Enrollment',
        //   haveTotal: true,
        //   canSort: true,
        // },
        {
          title: 'Gross Salary',
          visible: true,
          alwaysActive: true,
          key: 'GrossSalary',
          haveTotal: true,
          canSort: true,
        },
      ],
    }
  },

  methods: {
    personClickHanlder(el) {
      el.selected = !el.selected
      const exist = this.selectedPersons.includes(el.value)
      if (exist) {
        this.selectedPersons = this.selectedPersons.filter(
          (element) => element != el.value,
        )
      } else {
        this.selectedPersons.push(el.value)
      }
    },
    selectForsmsHendler(el) {
      el.selected = !el.selected
      const exist = this.selectedForSms.includes(el.value)
      if (exist) {
        this.selectedForSms = this.selectedForSms.filter(
          (element) => element != el.value,
        )
      } else {
        this.selectedForSms.push(el.value)
      }
    },
    recalculate() {
      this.recalculateData.allPersons.forEach((el) => {
        if (el.selected) {
          el.selected = false
        }
      })
      this.popupVisible = true
    },

    sendSms() {
      //
      axios
        .post('salary/SendPayrollInfoSms', {
          OfficeID: this.smsOffice,
          Date: this.smsDate,
          PersonsJson: JSON.stringify(this.selectedForSms),
        })
        .then((resp) => {
          if (!resp) return
          if (resp.data.ErrorMsg) return
          this.smsOffice = ''
          this.smsDate = ''
          this.selectedForSms = []

          this.smsPopupVissible = false
          this.snackbarVisible = true
          this.snackbarPurpose = 'success'
          this.snackbarText = 'Successfully sent'
        })
    },
    recalculateSave() {
      let objectData = {
        date: this.recalculateData.SelectDate,
        recalculationDate: this.recalculateData.SetDate,
        persons: this.recalculateData.allPersons.map((el) => ({
          id: el.value,
        })),
      }

      axios
        .post('salary/CreateSalaryResultsRecalculation', objectData)
        .then((resp) => {
          if (resp.data.ErrorMsg) return
          this.popupVisible = false
          this.snackbarVisible = true
          this.snackbarPurpose = 'success'
          this.snackbarText = 'Successfully saved'
        })
    },
    exportForExcel() {
      axios
        .post('salary/GetSalaryResultsForExcel', {
          SalaryInfo: this.data,
          FactorTotalInfo: this.footerFactors,
          TotalInfo: this.footerData,
        })
        .then((resp) => {
          this.downloadFile(resp.data)
        })
    },
    downloadFile(el) {
      var a = document.createElement('a')
      let fileName = 'SalaryResultsExcel.xlsx'
      let fileType = 'application/vnd.ms-excel'
      a.href = `data:${fileType};base64,` + el
      a.download = fileName
      a.click()
    },
    clearPersons(sms) {
      sms ? (this.selectedForSms = []) : (this.selectedPersons = [])
    },
    clearAllFilters() {
      this.selectedOffice = ''
      this.selectedPersons = []
      this.dateRangeFrom = ''
      this.dateRangeTo = ''
      this.sortTerm = ''
      this.filterSettings[0].filter = ''
      this.filterSettings[0].from = ''
      this.filterSettings[0].to = ''
      this.$refs.personsSelect.checkedTitle = ''
      this.$refs.personsSelect.checkedPerson = false
      this.$refs.dateRange.value = ''
      this.columns.forEach((el) => {
        if (el.sorted) {
          el.sorted = false
        }
      })
      this.allPersons.forEach((el) => {
        if (el.selected) {
          el.selected = false
        }
      })
      this.getSalaryResults()
    },
    getPersons() {
      axios.get('/Person/GetPersons').then((resp) => {
        this.allPersons = resp.data.Value.Persons.map((el) => ({
          title: el.FirstName + ' ' + el.LastName,
          value: el.ID,
          selected: el.selected,
        }))
        this.smsPersons = JSON.parse(JSON.stringify(this.allPersons))
        this.recalculateData.allPersons = resp.data.Value.Persons.map((el) => ({
          title: el.FirstName + ' ' + el.LastName,
          value: el.ID,
          selected: true,
        }))
      })
    },
    sortHandler(obj) {
      // this.resetValues();

      this.sortOrder = obj.ascSelected
        ? 'ASC'
        : obj.descSelected
        ? 'DESC'
        : null
      this.sortTerm = obj.key
      this.filterSettings[0].filter = obj.key
      this.filterSettings[0].from = obj.from
      this.filterSettings[0].to = obj.to

      this.getSalaryResults()
    },
    getSalaryResults() {
      let persons = this.selectedPersons.map((el) => {
        return {
          personID: el,
        }
      })
      let from = this.dateRangeFrom > 0 ? this.dateRangeFrom : null
      let to = this.dateRangeTo > 0 ? this.dateRangeTo : null
      axios
        .post('salary/GetSalaryResultsGeneralInfo', {
          Persons: persons.length ? persons : [],
          fromDate: from,
          toDate: to,
          office: this.selectedOffice,
          sortOrder: this.sortOrder ? this.sortOrder : null,
          sortTerm: this.sortTerm ? this.sortTerm : null,
          filterSettings:
            this.filterSettings[0].from.length ||
            this.filterSettings[0].to.length
              ? this.filterSettings
              : [],
        })
        .then((resp) => {
          this.data = resp.data.Value.SalaryInfo
          this.footerData = resp.data.Value.TotalInfo
          this.footerFactors = resp.data.Value.FactorTotalInfo

          this.data.forEach((el) => {
            // this.$set(el, "detailsVisible", false);
          })
        })
    },
    getOffices() {
      axios.get('Company/GetOffices').then((resp) => {
        this.offices = resp.data.Value
      })
    },
    async getSalaryFactors() {
      const resp = await axios.get('/salary/GetSalaryFactorsBasicInfo')

      this.factors = resp.data.Value
    },
    pushFactors() {
      let bonuses = this.factors
        .filter((el) => el.SalaryFactorTypeTypeID === 1)
        .map((element) => {
          return {
            title: element.Name,
            id: element.ID,
            visible: false,
            haveTotal: true,
            factor: true,
            key: 'Factors',
            customTemplate: function (row, val) {
              let currentValue = val.filter((v) => {
                return v.SalaryFactorTypeID === element.ID
              })[0]
              let companyCost = currentValue ? currentValue.CompanyCost : '-'
              let salaryCost = currentValue ? currentValue.SalaryCost : '-'
              companyCost =
                companyCost.toString().indexOf('.') != -1
                  ? companyCost.toFixed(2)
                  : null
              salaryCost =
                salaryCost.toString().indexOf('.') != -1
                  ? salaryCost.toFixed(2)
                  : null
              return `<div style=display:flex class="container">  <span style=width:69px;overflow:hidden;text-overflow:ellipsis>${
                companyCost > 0
                  ? companyCost
                  : salaryCost > 0
                  ? salaryCost
                  : '-'
              }</span> </div>`
            },
          }
        })

      let penalties = this.factors
        .filter((el) => el.SalaryFactorTypeTypeID === 2)
        .map((element) => {
          return {
            title: element.Name,
            id: element.ID,
            haveTotal: true,
            factor: true,
            visible: false,
            key: 'Factors',
            customTemplate: function (row, val) {
              let currentValue = val.filter((v) => {
                return v.SalaryFactorTypeID === element.ID
              })[0]
              let companyCost = currentValue ? currentValue.CompanyCost : '-'
              let salaryCost = currentValue ? currentValue.SalaryCost : '-'
              companyCost =
                companyCost.toString().indexOf('.') != -1
                  ? companyCost.toFixed(2)
                  : null
              salaryCost =
                salaryCost.toString().indexOf('.') != -1
                  ? salaryCost.toFixed(2)
                  : null
              return `<div style=display:flex class="container">  <span style=width:69px;overflow:hidden;text-overflow:ellipsis>${
                companyCost > 0
                  ? companyCost
                  : salaryCost > 0
                  ? salaryCost
                  : '-'
              }</span> </div>`
            },
          }
        })

      let ohterNoTaxFactors = this.factors
        .filter(
          (el) =>
            el.SalaryFactorTypeTypeID !== 2 &&
            el.SalaryFactorTypeTypeID !== 1 &&
            el.IsTax === false,
        )
        .map((element) => {
          return {
            title: element.Name,
            id: element.ID,
            customStyle: true,
            haveTotal: true,
            factor: true,
            visible: false,
            key: 'Factors',
            customTemplate: function (row, val) {
              let currentValue = val.filter((v) => {
                return v.SalaryFactorTypeID === element.ID
              })[0]
              let companyCost = currentValue ? currentValue.CompanyCost : '-'
              let salaryCost = currentValue ? currentValue.SalaryCost : '-'

              salaryCost =
                salaryCost.toString().indexOf('.') != -1
                  ? salaryCost.toFixed(2)
                  : salaryCost
              companyCost =
                companyCost.toString().indexOf('.') != -1
                  ? companyCost.toFixed(2)
                  : companyCost
              return `<div style=display:flex class="container">  <span style=width:69px;overflow:hidden;text-overflow:ellipsis>${
                companyCost > 0 ? companyCost : '-'
              }</span> <span>${salaryCost > 0 ? salaryCost : '-'}</span> </div>`
            },
          }
        })
      let otherTaxFactors = this.factors
        .filter(
          (el) =>
            el.SalaryFactorTypeTypeID !== 2 &&
            el.SalaryFactorTypeTypeID !== 1 &&
            el.IsTax === true,
        )
        .map((element) => {
          return {
            title: element.Name,
            id: element.ID,
            customStyle: true,
            haveTotal: true,
            factor: true,
            visible: false,
            key: 'Factors',
            customTemplate: function (row, val) {
              let currentValue = val.filter((v) => {
                return v.SalaryFactorTypeID === element.ID
              })[0]
              let companyCost = currentValue ? currentValue.CompanyCost : '-'
              let salaryCost = currentValue ? currentValue.SalaryCost : '-'
              salaryCost =
                salaryCost.toString().indexOf('.') != -1
                  ? salaryCost.toFixed(2)
                  : null
              companyCost =
                companyCost.toString().indexOf('.') != -1
                  ? companyCost.toFixed(2)
                  : null

              return `<div style=display:flex class="container">  <span style=width:69px;overflow:hidden;text-overflow:ellipsis>${
                companyCost > 0 ? companyCost : '-'
              }</span> <span>${salaryCost > 0 ? salaryCost : '-'}</span> </div>`
            },
          }
        })

      let bonusesObj = this.columns.filter((el) => el.title === 'Bonuses')[0]
        .childrens
      bonuses.forEach((el) => {
        bonusesObj.push(el)
      })
      let penaltyObj = this.columns.filter((el) => el.title === 'Penalty')[0]
        .childrens

      penalties.forEach((el) => {
        penaltyObj.push(el)
      })

      ohterNoTaxFactors.forEach((el) => {
        this.columns.splice(15, 0, el)
      })

      otherTaxFactors.forEach((el) => {
        this.columns.splice(14, 0, el)
      })
    },
    makePayment(row) {
      axios
        .post('/salary/CreatePaymentTransaction', {
          salaryResultID: row.SalaryResultID,
        })
        .then((resp) => {
          axios
            .get(
              `salary/GetPaymentTransactions?SalaryResultID=${row.SalaryResultID}`,
            )
            .then((resp) => {
              if (!resp.data.ErrorMsg) {
                this.snackbarVisible = true
                this.snackbarPurpose = 'success'
                this.snackbarText = 'Successfully Paid'
              }
              this.rowDetails.transactions = resp.data.Value
            })
        })
    },
    updateTransaction(row) {
      this.$confirm('Are you sure you want delete this transaction?').then(
        () => {
          axios
            .post('/salary/UpadtePaymentTransactions', {
              status: false,
              paymentTransactionID: row.PaymentTransactionID,
            })
            .then((resp) => {
              axios
                .get(
                  `salary/GetPaymentTransactions?SalaryResultID=${row.SalaryResultID}`,
                )
                .then((resp) => {
                  this.rowDetails.transactions = resp.data.Value
                })
            })
        },
      )
    },

    getDetails(row) {
      this.rowDetails.parameters = []
      this.rowDetails.results.columnOne = []
      this.rowDetails.results.columnTwo = []
      this.rowDetails.results.columnFive = []
      this.rowDetails.results.columnThree.factors = []
      this.rowDetails.results.columnFour.factors = []
      row.detailsVisible = true
      axios
        .get(
          `salary/GetSalaryResultsDetails?SalaryResultID=${row.SalaryResultID}`,
        )
        .then((resp) => {
          resp.data.Value.SalaryInfo.forEach((el) => {
            this.rowDetails.results.columnOne.push(
              {
                title: 'Personal ID',
                value: el.PersonalNumber,
              },
              {
                title: 'IBAN',
                value: el.BankAccountIban,
              },
              // {
              //   title: "Bank Code",
              //   value: el.BankAccountNum,
              // },
              {
                title: 'Start Date',
                value: this.getDateString(el.JobStartDate),
              },
              {
                title: 'Worked Days',
                value: el.WorkedDays + ' (' + el.WorkedDaysCountTypeName + ')',
              },
              {
                title: 'Payment Type',
                value: el.PaymentTypeName,
              },
              {
                title: 'Contract Type',
                value: el.ContractTypeName,
              },
              {
                title: 'Company',
                value: 'Upgaming',
              },
            )
            this.rowDetails.results.columnTwo.push(
              {
                title: 'Basic Salary',
                value: el.BasicSalary,
                color: '#1DE9B6',
                currency: el.NetSalaryCurrencyCode,
              },
              {
                title: 'Net By Working Days',
                value: el.NetSalaryByWorkingDays,
                color: '#1DE9B6',
                currency: el.NetSalaryCurrencyCode,
              },
              {
                title: 'Exchange Rate',
                value: el.ExchangeRate,
              },
              {
                title: 'Net in GEL',
                value: el.NetInGel,
                currency: 'GEL',
              },
            )
            let factors = el.Factors
            this.rowDetails.results.columnThree[0] = {
              title: 'Total Expenses',
              value: el.TotalExpenses,
            }
            this.rowDetails.results.columnThree.factors = factors
              .filter((el) => !el.IsTax)
              .map((fac) => {
                if (!fac.IsTax)
                  return {
                    title: fac.Name,
                    companyCost: fac.CompanyCost === 0 ? '-' : fac.CompanyCost,
                    salaryCost: fac.SalaryCost === 0 ? '-' : fac.SalaryCost,
                  }
              })

            this.rowDetails.results.columnFour[0] = {
              title: 'Total Taxes',
              value: el.TotalTaxes,
              currency: 'GEL',
            }
            this.rowDetails.results.columnFour.factors = factors
              .filter((el) => el.IsTax)
              .map((fac) => {
                return {
                  title: fac.Name,
                  companyCost: fac.CompanyCost === 0 ? '-' : fac.CompanyCost,
                  salaryCost: fac.SalaryCost === 0 ? '-' : fac.SalaryCost,
                }
              })
            this.rowDetails.results.columnFive.push(
              {
                title: 'Gross',
                value: el.Gross,
                color: '#FF5E67',
                currency: 'GEL',
              },
              {
                title: 'Paid Amount',
                value: el.PaidAmount,
                color: '#1DE9B6',
                currency: 'GEL',
              },
              {
                title: 'Remaining Amount',
                value: el.RemainingAmount,
                color: '#FF5E67',
                currency: 'GEL',
              },
              {
                title: 'Last Transfer Date',
                value: this.getDateString(el.LastTransferDate),
              },
            )
          })
          this.$set(row, 'isResponse', true)
        })
    },
    getRowParams(row) {
      axios
        .get(
          `salary/GetSalaryResultsParametersDetails?SalaryResultID=${row.SalaryResultID}`,
        )
        .then((resp) => {
          this.rowDetails.parameters = resp.data.Value
          if (this.rowDetails.parameters) {
            this.rowDetails.parameters.forEach((el) => {
              this.$set(
                el,
                'CompanyCostName',
                el.PaymentTypeID == 2 ? el.CompanyCost + ' %' : el.CompanyCost,
              )
              this.$set(
                el,
                'EmployeeShareName',
                el.PaymentTypeID == 2
                  ? el.EmployeeShare + ' %'
                  : el.EmployeeShare,
              )
            })
          }
        })
    },
    getTransactions(row) {
      axios
        .get(
          `salary/GetPaymentTransactions?SalaryResultID=${row.SalaryResultID}`,
        )
        .then((resp) => {
          this.rowDetails.transactions = resp.data.Value
        })
    },

    getDateString(date, format = 'dd MMM yyyy') {
      if (!(date instanceof Date)) {
        date = new Date(date)
      }
      if (date === 'Invalid Date') return

      const MONTHS = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

      var days = ('0' + date.getDate()).slice(-2),
        month = ('0' + (date.getMonth() + 1)).slice(-2),
        year = date.getFullYear(),
        hours = ('0' + date.getHours()).slice(-2),
        minutes = ('0' + date.getMinutes()).slice(-2),
        seconds = ('0' + date.getSeconds()).slice(-2)

      return format
        .replace('dd', days)
        .replace('MMM', MONTHS[parseInt(month) - 1])
        .replace('MM', month)
        .replace('yyyy', year)
        .replace('yy', String(year).slice(-2))
        .replace('HH', hours)
        .replace('mm', minutes)
        .replace('ss', seconds)
        .replace('ww', WEEKDAYS[date.getDay()])
    },
  },
  computed: {
    filteredColumns() {
      if (!this.columns) return

      return this.columns
    },
    totalCost() {
      if (!this.footerData) return
      if (!this.footerData.TotalCost) return
      return this.footerData.TotalCost.toLocaleString()
    },
    totalEmploye() {
      if (!this.footerData) return
      return this.footerData.EmployeesNum
    },
    filteredData() {
      if (!this.searchTerm) return this.data

      return this.data.filter(
        (el) =>
          (el.FirstName.toLowerCase() + el.LastName.toLowerCase()).includes(
            this.searchTerm.toLowerCase(),
          ) ||
          (
            el.FirstName.toLowerCase() +
            ' ' +
            el.LastName.toLowerCase()
          ).includes(this.searchTerm.toLowerCase()),
      )
    },
    officeOptions() {
      return this.offices.map((el) => ({
        title: el.Name,
        value: el.ID,
      }))
    },
    columnsForTransactions() {
      return [
        {
          title: 'ID',
          key: 'PaymentTransactionID',
        },
        {
          title: 'Date & Time',
          key: 'TransactionDate',
          customTemplate: (item, val) =>
            this.getDateString(val, 'dd/MM/yyyy HH:mm:ss'),
        },
        {
          title: 'Amount',
          key: 'Amount',
        },
        {
          title: 'Purpose',
          key: 'Purpose',
        },
        {
          title: 'Financial Manager',
          key: 'CreatorFirstName',
          customTemplate: (item, val) => val + ' ' + item.CreatorLastName,
        },
        {
          title: 'Status Update Date & Time',
          key: 'StatusUpdateDate',
          customTemplate: (item, val) =>
            val ? this.getDateString(val, 'dd/MM/yyyy HH:mm:ss') : '-',
        },
        {
          title: 'Status' + ' ',
          key: 'Status',
          customTemplate: (item, val) => (val == true ? 'Paid' : 'Deleted'),
        },
      ]
    },
    columnsForParameters() {
      return [
        {
          title: 'Factor Name',
          key: 'FactorName',
        },
        {
          title: 'Company',
          key: 'CompanyName',
        },
        {
          title: 'Payment Type',
          key: 'PaymentTypeName',
        },
        {
          title: 'Company Cost',
          key: 'CompanyCostName',
        },
        {
          title: 'Employee Cost',
          key: 'EmployeeShareName',
        },
        {
          title: 'Pay Day',
          key: 'PayDay',
        },
        {
          title: 'Start Date',
          key: 'StartDate',
          customTemplate: (item, val) => this.getDateString(val),
        },
        {
          title: 'End Date',
          key: 'EndDate',
          customTemplate: (item, val) => (val ? this.getDateString(val) : '-'),
        },
        {
          title: 'Status' + ' ',
          key: 'Status',
          customTemplate: (item, val) => (val == true ? 'Active' : 'Completed'),
        },
      ]
    },
  },
  watch: {
    dateRangeFrom() {
      this.getSalaryResults()
    },
    selectedOffice() {
      this.getSalaryResults()
    },
    selectedPersons() {
      this.getSalaryResults()
    },
  },
}
</script>
<style lang="scss" scoped>
.background {
  padding: 25px 35px;
}
.no-data {
  height: calc(100% - 93px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 100px;
  color: #6956d6;
  font-weight: 500;
  opacity: 0.3;
}
.filter-wrapper {
  ::v-deep .select-wrapper .selected-box {
    height: 60px;
    /* border: 0; */
    span.title {
      color: #959bc9;
      font-size: 14px;
      top: 21px;
    }
  }
}
.header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .totals-content {
    width: 320px;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    padding: 6px 15px;
    div {
      display: flex;
      flex-direction: column;
      span:nth-child(1) {
        font-size: 11px;
        color: #959bc9;
      }
      span:nth-child(2) {
        font-size: 18px;
        color: $blue-primary;
        font-weight: 600;
      }
    }
    .cost {
      width: 65%;
      border-right: 1px solid #ededfc;
    }
    .employee {
      padding-left: 15px;
      > span:first-child {
        white-space: nowrap;
      }
    }
  }
  .filters {
    display: flex;
    .filter-wrapper {
      &:last-child {
        width: 100px;
      }
      .clear-all {
        cursor: pointer;
        font-size: 14px;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        padding-left: 10px;
        color: #959bc9;
        border-left: 1px solid #dcdde6;
      }
      width: 12.938rem;
      margin-left: 15px;
      ::v-deep .select-content {
        height: 100%;
        width: 100%;
        .container {
          width: 100%;
          height: 100%;
          background: #ffffff;
          border: 0;
          .title {
            top: 21px;
            color: #959bc9;
            font-size: 14px;
            &.focused {
              top: -0.375rem;
            }
          }
        }
      }

      .recalculate {
        width: 132px;
        background-color: #dc3f59;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-radius: 0.3125rem;
        cursor: pointer;
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        margin-left: 65px;
      }
      .export {
        width: 132px;
        background-color: #6956d6;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-radius: 0.3125rem;
        cursor: pointer;
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        margin-left: auto;
        .material-icons-outlined {
          margin-right: 10px;
          color: #1de9b6;
        }
      }
    }
  }
  ::v-deep .date-range {
    .container {
      height: 60px;
      width: 100%;
      justify-content: space-between;
      padding: 0 15px;
      border: 0;
    }
  }
}

.recalculate-content {
  width: 500px;
  margin: 0 auto;
  .title {
    font-size: 1.25rem;
    color: #283593;
    font-weight: 400;
    margin-bottom: 30px;
  }
  .recalculate-flex {
    display: flex;
    justify-content: space-between;
    .flex {
      width: 230px;
      .date {
        width: 100%;
      }
    }
    .flex:last-child {
      margin-left: 50px;
    }
  }
  .recalculate-person {
    width: 100%;
    margin-top: 20px;
    ::v-deep .select-content {
      .container {
        width: 100%;
      }
    }
  }
  .recalculate-info {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    img {
      display: inline-block;
      vertical-align: middle;
    }
    span {
      font-size: 12px;
      color: #ff9233;
      display: inline-block;
      vertical-align: middle;
      margin-left: 7px;
    }
  }
  .save-button-div {
    width: 100%;
    text-align: right;
    .save-button {
      background-color: #6956d6;
      color: #fcfcfc;
      height: 3rem;
      width: 6.25rem;
      border-radius: 0.375rem;
      font-size: 1rem;
      cursor: pointer;
      margin-top: 3.75rem;
    }
  }
}
.search-content {
  position: relative;

  input {
    width: 100%;
    height: 48px;
    background-color: #fff;
    border: 0;
    outline: none;
    border-radius: 0.375rem;
    font-weight: 300;
    font-size: 14px;

    color: #959bc9;
    &::placeholder {
      color: #959bc9;
    }

    padding-left: 15px;
  }
  img {
    position: absolute;
    height: 21px;
    width: 21px;
    right: 10px;
    top: 14px;
  }
}
.payroll-dashboard {
  height: calc(100% - 95px);
  .search-content {
    position: relative;
  }
}
::v-deep .grid {
  .background-for-header {
    display: none;
  }
  .table-helper table thead th.actions {
    width: 1rem;
  }

  .table-helper table thead th {
    white-space: nowrap;

    &:first-child {
      width: 5% !important;
    }
  }
  .table-helper table tbody {
    td.actions {
      width: 5rem;
    }
    td:first-child {
      width: 5% !important;
    }
    img {
      height: 16px;
    }
  }
  // }
  // .table-helper {
  //   max-height: 320px;
  // }
  .table-wrapper {
    max-height: 283px;
  }
  .table-helper table tr {
    border: 0;
  }
  .table-helper table tbody tr {
    height: 2rem;
  }
  .table-helper table thead {
    border: 0;
    pointer-events: none;
    tr {
      height: 2.5rem;
    }
    th {
      padding: 0.1375rem 0.9375rem 0.1375rem 2.5rem;
      height: 1.75rem;
      color: #959bc9;
      font-size: 14px;
      .th-wrapper {
        .title {
          color: #959bc9;
          font-size: 14px;
          font-weight: 400;
        }
        .sort-icons {
          display: none;
        }
      }
    }
  }
  .table-helper table tbody td {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    padding: 0.4rem 0 0.4rem 2.5rem;
  }

  tbody {
    top: 0px;
  }
  .alternativeHeader {
    background-color: #3b469d;
    top: 0;
  }
}
.send-sms-content {
  height: 90%;
  display: flex;
  flex-direction: column;
  .sms-options-wrapper {
    margin-top: 30px;
    width: 100%;
    label {
      width: 100%;
    }
  }
  .send-button {
    margin-top: auto;
    height: 50px;
    border-radius: 10px;
    background: #1de9b6;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    &:active {
      filter: brightness(0.93);
      transform: scale(0.99);
    }
  }
}
.sms-popup {
  ::v-deep {
    .popup {
      overflow: visible;
      width: 339px;
    }
  }
}
</style>
