<template>
    <label>
        <span
            :class="{ focused: focused || value != '' }"
            class="title"
            :style="{ 'background-color': titleBackgroundColor }"
            >{{ title }}</span
        >
        <textarea
            @change="inputHandler"
            :value="value"
            @focus="focused = true"
            @blur="focused = false"
            :name="name"
            :required="isRequired"
            :rows="rows"
            :cols="cols"
            :maxlength="maxLength"
            :spellcheck="spellcheck"
            :class="{'fixedSize': fixedSize}"
            :readonly="readOnly"
        />
    </label>
</template>

<script>
export default {
    props: {
        value: String,
        title: String,
        name: String,
        spellcheck: Boolean,
        isRequired: {
            type: Boolean,
            default: false,
        },
        titleBackgroundColor: String,
        cols: {
            type: Number,
            default: 100,
        },
        rows: {
            type: Number,
            default: 10,
        },
        maxLength: {
            type: Number,
            default: 1000,
        },
        fixedSize:{
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        inputHandler(e) {
            this.$emit("input", e.target.value)
        },
    },
    data() {
        return {
            focused: false,
        }
    },
}
</script>

<style scoped lang="scss">
$border-color: $blue-primary;
label {
    width: 100%;
    position: relative;
    display: inline-block;

    span.title {
        // color: rgb(70, 70, 70);
        color: $blue-primary;
        position: absolute;
        top: 10 * $rem;
        font-size: 14 * $rem;
        margin-left: 15 * $rem;
        transition: all 0.2s ease-out;
        background-color: #fff;
        white-space: nowrap;
        cursor: text;
        max-width: 190 * $rem;
        overflow: hidden;
        text-overflow: ellipsis;

        &.focused {
            top: -6 * $rem;
            font-size: 14 * $rem;
            color: $border-color;
            padding-left: 2 * $rem;
            padding-right: 2 * $rem;
            left: 0;
        }
    }

    textarea {
        width: 100%;
        border-radius: 5 * $rem;
        box-shadow: none;
        border: 1 * $rem solid $grey-border;
        color: $blue-primary;
        font-size: 15 * $rem;
        padding: 15 * $rem;
        outline: none;

        &.fixedSize{
            resize: none;
        }

        &:focus {
            border: 1 * $rem solid $border-color;
        }
    }
}
</style>
