<template>
  <div class="hr">
    <div class="top-header">
      <ul>
        <li class="route">
          <router-link
            tag="span"
            :to="{ path: '/CareerAdmin' }"
            active-class="active"
          >
            Requested Jobs
          </router-link>
        </li>

        <li class="route" v-if="!this.$store.getters.hasRole('Manager')">
          <router-link
            tag="span"
            :to="{ path: '/ApplicantTracking' }"
            active-class="active"
          >
            Applicant Tracking
          </router-link>
        </li>
      </ul>
    </div>
    <div class="background" @click="clickedRowId = 0">
      <!-- <div class="header"><add-button @clicked="jobRequestPopup = true" /></div> -->
      <custom-loader v-if="loaderVisible" />
      <div v-if="emptyData && data.length < 1" class="alternative-message">
        <h1>There is no Records!</h1>
      </div>

      <grid
        :gridData="filteredGridData"
        :columns="columns"
        identificator="OpenPositionID"
        @trClicked="positionClickHandler"
        @edit-clicked="editClickHandler"
        :canDelete="true"
        @add-click="addPopupVisible"
        :pagination="false"
        @rowDelete="deleteOpenPosition"
        :canAdd="true"
        :canSearchByColumn="true"
        :alternativeEdit="true"
        :customTd="true"
        :alternateText="true"
        :links="links"
        :savedLinks="savedLinks"
        @plus-clicked="addLinkHandler"
        @delete-saved-link="deleteSavedLink"
        @delete-link="deleteLink"
        @save-link="updateLinks"
        :clickedRowId="clickedRowId"
        @link-value="getLinksValues"
        @clicked-row="clickedRowHandler"
        :selectOptions="setStatusOptions()"
        @opt-clicked="updateStatus"
        @show-options="showOptions"
      >
        <employee-filter
          slot="searchByJob"
          :list="filteredGridData.map((el) => el.JobName)"
          @select="(val) => (filters.jobName = val)"
        />
        <employee-filter
          slot="searchByCreator"
          :list="filteredGridData.map((el) => el.FullName)"
          @select="(val) => (filters.requestFrom = val)"
        />
        <employee-filter
          slot="searchByStatus"
          @select="(val) => (filters.status = val)"
          :list="filteredGridData.map((el) => el.OpenPositionStatusName)"
        />        
        <!-- <employee-filter
          slot="searchByPost"
          @select="(val) => (filters.posted = val)"
          :list="filteredGridData.map((el) => el.Posted ? 'Yes' : 'No')"
        /> -->
      </grid>

      <custom-popup
        :visible="jobRequestPopup"
        @close="(jobRequestPopup = false,setDefaultDescription(),defaultSelectedOptions())"
        class="job-request-popup"
      >
        <div class="send-loader" v-if="sendLoaderVisibile">
          <div class="loader-wrapper">
            <img src="@/assets/gifs/dualBall.svg" alt="loader">
          </div>
        </div>
        <form @submit.prevent="publishOrDraftPosition">
          <div class="popup-header">
            <h2 v-if="editMode">Edit Job Position Request</h2>
            <h2 v-else>{{ $t("title") }}</h2>

            <div class="lang-switcher">
              <div class="langs-content">
                <div
                  class="selected-lang"
                  v-for="lang in langs"
                  :key="lang.index"
                  v-show="lang.Code.toLowerCase() == locale.split('-')[0].toString()"
                >
                  {{ lang.Code }}
                </div>
                <div class="langs-options">                  
                  <div
                    class="lang"
                    :style="[lang.selected ? { opacity: '1' } : null]"
                    @click="langClickHandler(lang)"
                    v-for="lang in langs"
                    :key="lang.index"
                  >
                    {{ lang.Code }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="fields-content">
            <div class="wrapper left-field">
              <div class="field">
                <div class="langs-selected-wrapper">
                 <span> {{$t("chooseLang")}} </span>
                  <ul>
                    <li :class="{'selected': item.selected}" v-for="(item,ind) in multiSelectedLangs" :key="ind" @click="selectPositionLanguages(item)">
                        {{item.lang}}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="field">
                <div class="translate-wrapper">
                  <custom-check
                    v-if="isSuperAdminOrHr"
                    class="visibility-checkbox"
                    :checked="checkColumnVisibility('positionName')"
                    :mandatory="true"
                  />
                  <search-input
                    :options="jobOptions"
                    :isRequired="true"
                    v-model="selectedJob"
                    @clear="selectedJob = ''"
                    :title="$t('positionName')"
                  />
                  <img
                    v-if="currentLanguageID !== 2"
                    @click="showJobTranslate = !showJobTranslate"
                    src="@/assets/translate-icon.png"
                  />
                </div>
                <div
                  :class="{ shown: showJobTranslate }"
                  class="translate-input">
                    <input placeholder="Translate..." v-model="selectedListItemTranslation[0].value" type="text" />
                </div>
              </div>
              <div class="field">
                <custom-check
                  v-if="isSuperAdminOrHr"
                  class="visibility-checkbox"
                  :mandatory="true"
                  :checked="checkColumnVisibility('forCompanyOrOfice')"
                  
                />
                <search-input
                  :options="officesOptions"
                  :isRequired="editMode || createPositionStatusID === 2 ? false : true"
                  v-model="selectedOffice"
                  @clear="selectedOffice = ''"
                  :title="$t('forCompanyOrOfice')"
                />
              </div>
              <div class="field">
                <search-input
                  :options="depOptions"
                  v-model="selectedDep"
                  @clear="selectedDep = ''"
                  :title="$t('jobInDep')"
                />
              </div>
              <div class="field">
                <custom-input
                  class="text-input"
                  v-model="positionQuantity"
                  :isRequired="editMode || createPositionStatusID === 2 ? false : true"
                  :title="$t('positionQuantity')"
                  @clear="positionQuantity = ''"
                />
              </div>
              <div class="field">
                <custom-check
                  v-if="isSuperAdminOrHr"
                  :mandatory="true"
                  class="visibility-checkbox"
                />
                <input class="hidden-date" type="text" v-model="deadLineTime" :required="editMode || createPositionStatusID === 2 ? false : true">
                <date-picker
                  class="date-picker"
                  v-model="deadLineTime"
                  :isRequired="editMode || createPositionStatusID === 2 ? false : true"
                  :title="$t('hireDeadline')"
                />
                <span class="warning-message">
                  The request must be sent at least 1 month before hiring!
                </span>
              </div>
              <div class="field" v-for="item in itemsArray" :key="item.typeId">
                <custom-check
                  v-if="isSuperAdminOrHr"
                  class="visibility-checkbox"
                  :checked="item.visible"
                  :mandatory="item.required"
                  @value="(val) => item.visible = val"
                />
                <select-item
                  :title="$t(`${item.title}`)"
                  :componentTypeId="item.typeId"
                  @select-id="(id) => selectItemIds(id, item)"
                  @delete-selected-id="(id) => deleteSelectedId(id, item)"
                  @add-new-item="addNewItem"
                  :selectedLang="selectedLang"
                  @single-lang-items="saveSingleLangItems"
                  :items="item.array"
                  @delete-item="(item,ind) => deleteSavedItem(item,ind)"
                  :positionEditMode="editMode"
                />
              </div>
            </div>
            <div class="wrapper right-field">
              <div class="field">
                <div class="translate-wrapper">
                  <custom-check
                    v-if="isSuperAdminOrHr"
                    class="visibility-checkbox"
                    :checked="checkColumnVisibility('contractType')"
                    @value="(val) => setColumnVisibility('contractType',val)"
                  />
                  <search-input
                    :isRequired="editMode || createPositionStatusID === 2 ? false : true"
                    :options="contractOptions"
                    v-model="selectedContract"
                    :title="$t('contractType')"
                  />
                  <img
                    v-if="currentLanguageID !== 2"
                    @click="showContractTranslate = !showContractTranslate"
                    src="@/assets/translate-icon.png"
                  />
                </div>
                <div
                  class="translate-input"
                  :class="{ shown: showContractTranslate }">
                    <input placeholder="Translate..." v-model="selectedListItemTranslation[1].value" type="text" />
                </div>
                <date-range
                  v-if="selectedContract !== 1 && selectedContract !== ''"
                  class="date-range"
                  title="select contract range"
                  :initialFrom="contract.from"
                  :initialTo="contract.to"
                  @from="(from) => (contract.from = from)"
                  @to="(to) => (contract.to = to)"
                />
              </div>
              <div class="field">
                <div class="translate-wrapper">
                  <custom-check
                    v-if="isSuperAdminOrHr"
                    :checked="checkColumnVisibility('emplStatus')"
                    @value="(val) => setColumnVisibility('emplStatus',val)"
                    class="visibility-checkbox"
                  />
                  <search-input
                    :isRequired="editMode || createPositionStatusID === 2 ? false : true"
                    :options="statusOptions"
                    v-model="selectedStatus"
                    :title="$t('emplStatus')"
                  />
                  <img
                    v-if="currentLanguageID !== 2"
                    @click="showJobTypeTranslate = !showJobTypeTranslate"
                    src="@/assets/translate-icon.png"
                  />
                </div>
                <div
                  class="translate-input"
                  :class="{ shown: showJobTypeTranslate }">
                    <input placeholder="Translate..." v-model="selectedListItemTranslation[2].value" type="text" />
                </div>
              </div>
              <div class="field">
                <p>{{ $t("reasonRequest") }} *</p>
                <input
                  type="text"
                  class="reason-input"
                  v-model="selectedReasonID"
                  :required="
                    (selectedReasonID === '' ? true : false) || 
                    (editMode || createPositionStatusID === 2 ? false : true)
                  "
                />
                <div
                  class="single-reason"
                  v-for="el in reasonOfRequests"
                  :key="el.index"
                  @click="reasonClickHandler(el)"
                >
                  <div
                    :style="[
                      el.selected ? { backgroundColor: '#6956D6' } : null,
                    ]"
                    class="dot"
                  >
                  </div>
                  <div class="text">{{ el.DescriptionTranslated }}</div>
                </div>
              </div>
              <div class="field salary-inputs">
                <div class="salary-content">
                  <custom-check
                    v-if="isSuperAdminOrHr"
                    class="visibility-checkbox"
                    :checked="checkColumnVisibility('salaryRangeForTest')"
                    @value="(val) => setColumnVisibility('salaryRangeForTest',val)"
                  />
                  <custom-input
                    class="text-input salary-input"
                    v-model="salaryRangeForTest"
                    :title="$t('salaryRangeForTest')"
                    @clear="salaryRangeForTest = ''"
                  />

                  <!-- <div
                    class="visiblity-content"
                    @click="testSalaryVisibility = !testSalaryVisibility"
                  >
                    <span> {{ $t("visibility") }}</span>
                    <div
                      :class="{ selected: testSalaryVisibility }"
                      class="dot"
                    ></div>
                  </div> -->
                </div>
              </div>
              <div class="field">
                <div class="salary-content">
                  <custom-check
                    v-if="isSuperAdminOrHr"
                    class="visibility-checkbox"
                    :checked="checkColumnVisibility('salaryRangeForAfterTest')"
                    @value="(val) => setColumnVisibility('salaryRangeForAfterTest',val)"
                  />
                  <custom-input
                    class="text-input salary-input"
                    v-model="salaryRangeForAfterTest"
                    :title="$t('salaryRangeForAfterTest')"
                    @clear="salaryRangeForAfterTest = ''"
                  />
                  <!-- <div
                    class="visiblity-content"
                    @click="salaryVisibility = !salaryVisibility"
                  >
                    <span style="color: transparent">
                      {{ $t("visibility") }}</span
                    >
                    <div
                      :class="{ selected: salaryVisibility }"
                      class="dot"
                    ></div>
                  </div> -->
                </div>
              </div>
              <!-- <div class="field">
                <div class="salary-content">
                  <custom-input
                    class="text-input salary-input"
                    v-model="bonuses"
                    :title="$t('bonuses')"
                    @clear="bonuses = ''"
                  />
                  <div
                    class="visiblity-content"
                    @click="bonusVisibility = !bonusVisibility"
                  >
                    <span style="color: transparent">
                      {{ $t("visibility") }}</span
                    >
                    <div
                      :class="{ selected: bonusVisibility }"
                      class="dot"
                    ></div>
                  </div>
                </div>
              </div> -->
              <div class="field schedule">
                <custom-check
                  v-if="isSuperAdminOrHr"
                  class="visibility-checkbox"
                  :checked="checkColumnVisibility('workingSchedule')"
                  @value="(val) => setColumnVisibility('workingSchedule',val)"
                />
                <custom-input
                  class="text-input"
                  :isRequired="editMode || createPositionStatusID === 2 ? false : true"
                  v-model="selectedSchedule"
                  :title="$t('workingSchedule')"
                  @clear="selectedSchedule = ''"
                />
                <span class="note-message">
                  Mon -Fri, 10:00 AM - 19:00 PM (Weekly 40 hr)
                </span>
              </div>
              <div class="description-content field">
                <custom-check
                  v-if="isSuperAdminOrHr"
                  class="visibility-checkbox"
                  :checked="checkColumnVisibility('Decription')"
                  @value="(val) => setColumnVisibility('Decription',val)"
                />
                <custom-text-area
                  :title="$t('description')"
                  :maxLength="9999999999"
                  :fixedSize="true"
                  v-model="additionalDescription"
                  :spellcheck="false"
                />
                <div class="clear-description" @click="additionalDescription = ''">
                  <img src="@/assets/icons/close.svg" alt="close">
                </div>
              </div>
              <div class="field">
                <div class="status-content post-date">
                    <search-input
                      :isRequired="editMode || createPositionStatusID === 2 ? false : true"
                      :options="setPositionStatusListForRoles()"
                      v-model="createPositionStatusID"
                      :title="$t('status')"
                      :menuTop="true"
                      @clear="(createPositionStatusID = null,postDate = null)"
                    />
                    <div class="postDate">
                      <date-picker
                        class="date-picker"
                        v-model="postDate"
                        :title="$t('externalPost')"
                        :disabled="createPositionStatusID === 8 || createPositionStatusID === 9 ? false : true"
                      />
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="['popup-footer',{'disabled':sendLoaderVisibile}]">
            <button @click="draft = false" v-if="!isEndedPosition">
              Save
            </button>
            <!-- <button @click="draft = true, isRequiredStatus = false" class="draft">
              Save Draft
            </button> -->
            <!-- <button v-else @click="draft = false">Repeat</button> -->
          </div>
        </form>
      </custom-popup>
      <custom-popup
        :visible="positionDetailsVisible"
        @close="positionDetailsVisible = false,defaultSelectedOptions()"
        title="Requirments for Job position"
        class="position-details-popup"
      >
        <div class="header">
          <div class="langSwitch-wrapper">
            <language-switch
              :langs="positionLangs"
              @selected="(lang) => (lang.selected = true,this.getPositionDetails(this.currentPositionID,lang.LanguageID))"
            />
          </div>
          <div class="job-name">
            {{ 
              this.currentDetailsLangID !== 2 ? this.jobTranslated : this.selectedJobName
            }}
          </div>
          <div class="deadline">
            <span>Hire deadline</span>
            <span>{{ getDateString(deadLineTime) }}</span>
          </div>
        </div>
        <div class="details-content">
          <div class="details-wrapper">
            <div class="single-detail">
              <div class="left-side">{{ $t("forCompanyOrOfice") }}</div>
              <div class="right-side">
                {{
                  this.companyDetail ? this.companyDetail : '-'
                }}
              </div>
            </div>
            <div class="single-detail">
              <div class="left-side">{{ $t("jobInDep") }}</div>
              <div class="right-side">
                {{
                  this.jobInDepDetail ? this.jobInDepDetail : '-'
                }}
              </div>
            </div>
            <div class="single-detail">
              <div class="left-side">{{ $t("positionQuantity") }}</div>
              <div class="right-side">
                <div v-if="positionQuantity" class="quantity">{{ positionQuantity }}</div>
                <div v-else> - </div>
              </div>
            </div>
          </div>

          <div class="details-wrapper">
            <div class="single-detail">
              <div class="left-side">{{ $t("contractType") }}</div>
              <div class="right-side">
                {{
                  this.currentDetailsLangID !== 2 ? this.contractTranslated : this.contractDetail ? this.contractDetail : '-'
                }}
              </div>
            </div>
            <div class="single-detail">
              <div class="left-side">{{ $t("emplStatus") }}</div>
              <div class="right-side">
                {{
                  this.currentDetailsLangID !== 2 ? this.empStatusTranslated : this.empStatusDetail ? this.empStatusDetail : '-' 
                }}
              </div>
            </div>
            <div class="single-detail">
              <div class="left-side">{{ $t("salaryRangeForTest") }}</div>
              <div class="right-side">
                {{ salaryRangeForTest ? salaryRangeForTest : '-' }}
              </div>
            </div>
            <div class="single-detail">
              <div class="left-side">{{ $t("salaryRangeForAfterTest") }}</div>
              <div class="right-side">
                {{ salaryRangeForAfterTest ? salaryRangeForAfterTest : '-' }}
              </div>
            </div>
          </div>
          <div class="details-wrapper">
            <div class="single-detail">
              <div class="left-side">{{ $t("Education") }}</div>
              <div class="right-side" v-if="educationDetails.length > 0">
                {{ education }}
                <div class="single-item" v-for="(item,ind) in educationDetails" :key="ind">
                  {{item ? item.Description : ''}}
                </div>
              </div>
              <div v-else class="right-side">
                <span> - </span>
              </div>
            </div>
            <div class="single-detail">
              <div class="left-side">{{ $t("workExp") }}</div>
              <div class="right-side" v-if="experienceDetails.length > 0">
                <template v-for="(item,ind) in experienceDetails">
                  <div class="single-item" :key="ind">
                    {{ item.Description }}
                  </div>
                </template>
              </div>
              <div v-else class="right-side">
                <span> - </span>
              </div>
            </div>
            <div class="single-detail">
              <div class="left-side">{{ $t("requiredKnowledge") }}</div>
              <div class="right-side" v-if="skillDetails.required.length > 0">
                <template v-for="(item,ind) in skillDetails.required">
                  <div class="single-skill" :key="ind">
                    {{ item.Description }}
                  </div>
                </template>
              </div>
              <div v-else class="right-side">
                <span> - </span>
              </div>
            </div>
            <div class="single-detail">
              <div class="left-side">{{ $t("preferredKnowledge") }}</div>
              <div class="right-side" v-if="skillDetails.preferred.length > 0">
                <template v-for="(item,ind) in skillDetails.preferred">
                  <div class="single-skill" :key="ind">
                      {{ item.Description }}
                  </div>
                </template>
              </div>
              <div v-else class="right-side">
                <span> - </span>
              </div>
            </div>
            <div class="single-detail">
              <div class="left-side">{{ $t("responsibilities") }}</div>
              <div class="right-side" v-if="responsibilitiesDetails.length > 0">
                <div
                  class="single-resp"
                  v-for="(item,ind) in responsibilitiesDetails"
                  :key="ind"
                >
                  <div class="dot"></div>
                  <div class="single-item">
                   <span>{{ item.Description }}</span>
                  </div>
                </div>
              </div>
              <div v-else class="right-side">
                <span> - </span>
              </div>
            </div>
            <div class="single-detail">
              <div class="left-side">{{ $t("skills") }}</div>
              <div class="right-side" v-if="skillDetails.common.length > 0">
                <template v-for="el in skillDetails.common">
                  <div class="single-skill" :key="el.index">
                    {{ el.Description }}
                  </div>
                </template>
              </div>
              <div v-else class="right-side">
                <span> - </span>
              </div>
            </div>
            <div class="single-detail">
              <div class="left-side">{{ $t("weOffer") }}</div>
              <div class="right-side" v-if="weOffer.length > 0">
                  <div class="single-item" v-for="(item,ind) in weOffer" :key="ind">
                    {{ item.Description}}
                  </div>
              </div>
              <div v-else class="right-side">
                <span> - </span>
              </div>
            </div>
          </div>
          <div class="details-wrapper">
            <div class="single-detail">
              <div class="left-side">{{ $t("workingSchedule") }}</div>
              <div class="right-side">{{ selectedSchedule ? selectedSchedule : '-' }}</div>
            </div>
          </div>
        </div>
      </custom-popup>
      <custom-snackbar
        v-if="snackbarVisible"
        :purpose="snackbarPurpose"
        :text="snackbarText"
        @close="snackbarVisible = false"
      />
    </div>
  </div>
</template>

<script>
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";
// import AddButton from "@/components/ReusableComponents/AddButton/AddButton.vue";
import EmployeeFilter from "@/components/Employees/EmployeeFilter.vue";

import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput.vue";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue";
import selectItem from "@/components/ReusableComponents/Select/selectItem.vue";
import axios from "@/Helpers/AxiosInstance.js";
// import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue";
import Grid from "@/components/ReusableComponents/Grid/Grid.vue";
import DateRange from "@/components/ReusableComponents/DateRange/DateRange";
import SearchInput from "@/components/ReusableComponents/SearchInput/SearchInput.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import CustomTextArea from "@/components/ReusableComponents/CustomTextArea/CustomTextArea.vue";
import CustomCheck from "@/components/ReusableComponents/CustomCheckbox/CustomCheck.vue"
import LanguageSwitch from "@/components/ReusableComponents/LanguageSwitch/LanguageSwitch.vue"
import CustomLoader from "@/components/ReusableComponents/Loader/CustomLoader.vue"

export default {
  components: {
    // AddButton,
    CustomPopup,
    CustomSnackbar,
    CustomInput,
    DatePicker,
    selectItem,
    // CustomSelect,
    EmployeeFilter,
    Grid,
    DateRange,
    SearchInput,
    CustomTextArea,
    CustomCheck,
    LanguageSwitch,
    CustomLoader,
  },
  created() {
    // this.setPositionStatusListForRoles()
    this.getDropDownLists(2);
    this.getLangs();
    this.getKnowledgeTypes();
    this.getOpenPositions();
    this.getStatuses();
    this.setDefaultDescription()
  },

  data() {
    return {
      translatedTags:[],
      data: [],
      savedLinks: [],
      selectedNewItems: [],
      allNewITems: [],
      selectedItemIds: [],
      loaderVisible: false,
      emptyData: false,
      hrRoleIds: [],
      links: [
        {
          link: "",
        },
      ],
      filters: {
        jobName: "",
        requestFrom: "",
        status: "",
        posted: "",
      },
      itemsArray: [
        {
          typeId: 2,
          title: "Education",
          array: [],
          selected: [],
          columnName: 'Education',
          visible: false,
        },
        {
          typeId: 3,
          title: "workExp",
          array: [],
          selected: [],
          columnName: 'workExp',
          visible: false,
        },
        {
          typeId: 12,
          title: "requiredKnowledge",
          array: [],
          selected: [],
          columnName: 'requiredKnowledge',
          visible: true,
          required: true,
        },
        {
          typeId: 13,
          title: "preferredKnowledge",
          array: [],
          selected: [],
          columnName: 'preferredKnowledge',
          visible: false,
        },

        {
          typeId: 14,
          title: "skills",
          array: [],
          selected: [],
          columnName: 'skills',
          visible: false,
        },
        {
          typeId: 5,
          title: "responsibilities",
          array: [],
          selected: [],
          columnName: 'responsibilities',
          visible: true,
          required: true,
        },
        {
          typeId: 11,
          title: "weOffer",
          array: [],
          selected: [],
          columnName: 'WeOffer',
          visible: false,
        },
      ],

      columnVisibilities:[
        {
          columnName: 'positionName',
          visible: true,
          required: true,
          ObjectTypeID: 1
        },
        {
          columnName: 'forCompanyOrOfice',
          visible: true,
          required: true,
          ObjectTypeID: 6,
        },
        {
          columnName: 'hireDeadline',
          visible: true,
          required: true,
          ObjectTypeID: 16
        },
        {
          columnName: 'contractType',
          visible: false,
          ObjectTypeID: 8
        },
        {
          columnName: 'salaryRangeForTest',
          visible: false,
          ObjectTypeID: 17
        },
        {
          columnName: 'salaryRangeForAfterTest',
          visible: false,
          ObjectTypeID: 18
        },
        {
          columnName: 'emplStatus',
          visible: false,
          ObjectTypeID: 9
        },
        {
          columnName: 'workingSchedule',
          visible: false,
          ObjectTypeID: 19
        },        
        {
          columnName: 'Decription',
          visible: false,
          ObjectTypeID: 15
        },
      ],
      languageIDs: [
        {
          id: 2 //initial EN id
        },
      ],
      currentLanguageID: 2,
      currentPositionID: null,
      selectedListItemTranslation:[
        {
          value: '',
          typeID: 1
        },
        {
          value: '',
          typeID: 8,
        },
        {
          value: '',
          typeID: 9,
        },
        {
          value: '',
          typeID: 15,
        },
      ],
      selectedDropdownItemsTranslated: [],
      multiSelectedLangs:[],
      clickedRowId: 0,
      changeRespTitle: false,
      clickedOptionId: 0,
      statusTypes: [],
      openPositionID: "",
      requiredKnowledges: [],
      testSalaryVisibility: false,
      salaryVisibility: false,
      statusesVisible: false,
      preferredKnowledges: [],
      skills: [],
      selectedKnowledges: [],
      selectedExps: [],
      selectedReasonID: null,
      selectedPreferredKnowledges: [],

      selectedSkills: [],
      translatedJobOptions: [],
      translatedContractTypeOptions: [],
      translatedJobTypeOptions: [],
      translatedDescription: [],
      createPositionStatusID: null,

      dropDownLists: {},
      jobs: [],
      defaultJobs: [],
      offices: [],
      statuses: [],
      defaultStatuses: [],
      contracts: [],
      defaultContracts: [],
      currencies: [],
      experiences: [],
      schedules: [],
      departments: [],
      reasonOfRequests: [],
      knowLedgeTypes: [],
      editMode: false,
      positionDetailsVisible: false,
      locale: this.$i18n.locale,
      jobRequestPopup: false,
      withArrows: true,
      selectedJob: "",
      selectedOffice: "",
      selectedCurrencyAfterTest: "",
      selectedCurrencyForTest: "",
      positionQuantity: "",
      selectedContract: "",
      selectedJobName: "",
      selectedReason: "",
      selectedSchedule: "",
      customReason: "",
      selectedDep: "",
      deadLineTime: null,
      selectedStatus: "",
      jobPositionInDep: "",

      currentDetailsLangID: null,

      companyDetail: '',
      jobInDepDetail: '',
      jobTranslated: '',
      empStatusDetail: '',
      empStatusTranslated: '',
      postDate: null,
      education: '',
      contractDetail: '',
      contractTranslated: '',
      langsVisible: false,
      reasonInputVisible: false,
      showInput: false,
      additionalDescription: "",
      educationDetails: [],
      experienceDetails: [],
      skillDetails:{
        required: [],
        preferred: [],
        common: [],
      },
      responsibilitiesDetails: [],
      reasonDetail: '',


      contract: {
        from: 0,
        to: 0,
      },
      salaryRangeForTest: "",

      salaryRangeForAfterTest: "",
      weOffer: [],
      positionLangs: [],
      showAllLangs: false,
      responsibilities: [
        {
          description: "",
        },
      ],
      langs: [],
      snackbarPurpose: "",
      snackbarText: "",
      snackbarVisible: false,
      detailsMode: false,
      bonusVisibility: false,
      showJobTranslate: false,
      showContractTranslate: false,
      showJobTypeTranslate: false,
      showDescriptionTranslate: false,
      sendLoaderVisibile: false,
      isRequiredStatus: true,
    };
  },

  i18n: {
    silentTranslationWarn: true,
    messages: {
      ge: {
        title: "ვაკანსიის მოთხოვნის ფორმა",
        positionName: "პოზიციის დასახელება",
        forCompanyOrOfice: "კომპანია/ოფისი (დამკვეთი)",
        jobInDep: "ვაკანტური პოზიცია სტრუქტურულ ერთეულში",
        positionQuantity: "რაოდენობა",
        addOption: "დაამატე ობცია",
        hireDeadline: "კადრის აყვანის ბოლო ვადა",
        Education: "განათლება",
        workExp: "სამუშაო გამოცდილება",
        requiredKnowledge: "აუცილებელი ცოდნა",
        preferredKnowledge: "უპირატესობად ჩაითვლება",
        skills: "აუცილებელი უნარები",
        visibility: "ხილვადობა",
        responsibilities: "ფუნქცია-მოვალეობები",
        contractType: "ხელშეკრულების ტიპი",
        emplStatus: "დასაქმების სტატუსი",
        reasonRequest: "ვაკანსიის მოთხოვნის მიზეზი",
        newPosition: "ახალი პოზიციის შექმნა",
        additionalFunctions: "ფუნქციების დამატება",
        volumeOfWork: "სამუშაოს მოცულობის გაზრდა",
        other: "არსებული თანამშრომლის წასვლა",
        salaryRangeForTest: " ხელფასი გამოსაცდელ პერიოდში",
        salaryRangeForAfterTest: "ხელფასი გამოსაცდელი პერიოდის შემდეგ",
        weOffer: "ბენეფიტები",
        workingSchedule: "სამუშაო დღეები/საათები",
        description: "აღწერა",
        chooseLang: "აირჩიეთ რომელ ენებზე გსურთ განაცხადის შევსება",
        weekDays: {
          Mon: "ორშ",
          Tue: "სამშ",
          Wed: "ოთხ",
          Thu: "ხუთ",
          Fri: "პარ",
          Sat: "შაბ",
          Sun: "კვი",
        },
        status: "სტატუსი",
        externalPost: "External post date",
        weeklyXHr: "კვირაში x საათი",
      },
      en: {
        title: "Request Job Position",
        addOption: "Add option",
        positionName: "Position Name",
        visibility: "Visibility",
        forCompanyOrOfice: "Work Place",
        jobInDep: "Job Position in",
        positionQuantity: "Position Quantity",
        hireDeadline: "Hire Deadline",
        Education: "Education",
        workExp: "Work experience",
        requiredKnowledge: "Requirements",
        preferredKnowledge: "Preferred Knowledge",
        skills: "Skills",
        responsibilities: "Responsibilities",
        contractType: "Contract type",
        emplStatus: "Job type",
        reasonRequest: "Reason of request",
        newPosition: "New Position",
        additionalFunctions: "Additional functions",
        volumeOfWork: "Oncreased volume of work",
        other: "Current employee leave",
        salaryRangeForTest: "Net salary range for the test period",
        salaryRangeForAfterTest: "Net salary range after test period",
        weOffer: "We offer",
        workingSchedule: "Working schedule",
        description: "Description",
        chooseLang: "Select lang codes for multilanguage request",
        weekDays: {
          Mon: "Mon",
          Tue: "Tue",
          Wed: "Wed",
          Thu: "Thu",
          Fri: "Fri",
          Sat: "Sat",
          Sun: "Sun",
        },
        status: "Status",
        externalPost: "External post date",
        weeklyXHr: "Weekly x hr",
      },
      tr: {
        title: "Açık iş başvuru formu",
        positionName: "Pozisyon adı",
        visibility: "Görünürlük",
        forCompanyOrOfice: "Şirket/ofis için",        
        jobInDep: "Departmandaki iş pozisyonu ",
        positionQuantity: "Pozisyon miktarı",
        hireDeadline: "İstenen İşe Alma Süresi (Son Tarih)",
        Education: "Eğitim",
        workExp: "İş deneyimi",
        requiredKnowledge: "Gerekli bilgi",
        preferredKnowledge: "Tercih edilen bilgi",
        skills: "Gerekli beceriler",
        responsibilities: "Fonksiyon-görevler",
        addOption: "Seçenek Ekle",
        contractType: "Sözleşme türünü seçin",
        emplStatus: "Çalışma durumu",
        reasonRequest: "Boş yer talep etme nedeni",
        newPosition: "Yeni pozisyon",
        additionalFunctions: "İlave fonksiyonlar",
        volumeOfWork: "Artan iş hacmi",
        other: "Mevcut bir çalışanın gitmesi",
        salaryRangeForTest: "Test dönemi için net maaş aralığı seçin",
        salaryRangeForAfterTest:
          "Test döneminden sonra net maaş aralığını seçin",
        weOffer: "Sunuyoruz",
        workingSchedule: "Çalışma takvimi",
        description: "Açıklama",
        chooseLang: "Çoklu dil talebi için dil kodlarını seçin",
        weekDays: {
          Mon: "Pzt",
          Tue: "Sal",
          Wed: "Çrş",
          Thu: "Per",
          Fri: "Cuma",
          Sat: "Cumartesi",
          Sun: "Pazar",
        },
        status: "Durum",
        externalPost: "Harici gönderi tarihi",
        weeklyXHr: "Haftalık x saat",
      },
      de: {
        title: "Stellenangebot beantragen",
        addOption: "Option hinzufügen",
        positionName: "Stellenname",
        forCompanyOrOfice: "Unternehmen / Büro",        
        jobInDep: "Arbeitsplatz in der Abteilung",
        positionQuantity: "Anzahl der Positionen",
        hireDeadline: "Anstellungsfrist",
        Education: "Ausbildung",
        workExp: "Berufserfahrung",
        requiredKnowledge: "Erforderliche Kenntnisse",
        preferredKnowledge: "Bevorzugte Kenntnisse",
        skills: "Fähigkeiten",
        visibility: "Sichtbarkeit",
        responsibilities: "Verantwortlichkeit",
        contractType: "Vertragstyp wählen",
        emplStatus: "Beschäftigungsstatus",
        reasonRequest: "Grund der Anfrage",
        newPosition: "Neue Stelle",
        additionalFunctions: "Zusätzliche Funktionen",
        volumeOfWork: "Erhöhtes Arbeitsaufkommen",
        other: "Kündigung des Mitarbeiters",
        salaryRangeForTest:
          "Wählen Sie den Nettogehaltsbereich für den Testzeitraum",
        salaryRangeForAfterTest:
          "Wählen Sie den Bereich des Nettogehalts nach dem Testzeitraum",
        weOffer: "Wir bieten",
        workingSchedule: "Arbeitsplan",
        description: "Beschreibung",
        chooseLang: "Wählen Sie Sprachcodes für mehrsprachige Anfragen",
        weekDays: {
          Mon: "Mo",
          Tue: "Die",
          Wed: "Mi",
          Thu: "Do",
          Fri: "Fr",
          Sat: "Sa",
          Sun: "So",
        },
        status: "Status",
        externalPost: "Datum der externen Veröffentlichung",
        weeklyXHr: "Wöchentlich x Stunden",
      },
      ru: {
        title: "Форма запроса на вакансию",
        addOption: "добавить вариант",
        positionName: "Название должности/позиции",
        forCompanyOrOfice: "Компания/офис (работодатель)",        
        jobInDep: "Вакантная должность в структурном подразделении, отделе",
        positionQuantity: "Количество должностей",
        hireDeadline: "Желаемое время приема",
        Education: "Образование",
        workExp: "Опыт работы",
        requiredKnowledge: "Необходимые знания",
        preferredKnowledge: "Предпочтительные знания",
        skills: "Необходимые навыки",
        visibility: "видимость",
        responsibilities: "Функции-обязанности",
        contractType: "Выберите тип контракта",
        emplStatus: "Статус занятости",
        reasonRequest: "Причина запроса вакансии",
        newPosition: "Новая позиция",
        additionalFunctions: "Дополнительные функции",
        volumeOfWork: "Увеличенный объем работы",
        other: "Другое / Уход действующего сотрудника",
        salaryRangeForTest:
          "Диапазон чистой заработной платы за тестовый период",
        salaryRangeForAfterTest:
          "Диапазон чистой заработной платы после тестового периода",
        weOffer: "Мы предлагаем",
        workingSchedule: "Рабочее расписание",
        description: "Описание",
        chooseLang: "Выберите языковые коды для многоязычного запроса",
        weekDays: {
          Mon: "Пн",
          Tue: "Вт",
          Wed: "Ср",
          Thu: "Чт",
          Fri: "Пт",
          Sat: "Сб",
          Sun: "Вос",
        },
        status: "Статус",
        externalPost: "Дата внешней публикации",
        weeklyXHr: "Еженедельно х час",
      },
      fr: {
        title: "demande de la poste vacante",
        addOption: "Add option",
        positionName: "le nom de la poste vacante",
        visibility: "Visibility",
        forCompanyOrOfice: "pour l'entreprise",
        jobInDep: "La poste vacante dans le département",
        positionQuantity: "La quantité de posts vacantes",
        hireDeadline: "La Date limite d'embauche",
        Education: "Une Education",
        workExp: "Une Expérience professionnelle",
        requiredKnowledge: "Les Connaissances requises",
        preferredKnowledge: "Les Connaissances préférées",
        skills: "Les compétences",
        responsibilities: "Les Responsabilités",
        contractType: "Le type de contrat",
        emplStatus: "La Situation professionnelle",
        reasonRequest: "Le Motif de la demande",
        newPosition: "Une poste nouvelle",
        additionalFunctions: "Les fonctions supplémentaires",
        volumeOfWork: "L'Augmentation du volume de travail",
        other: "Les Autres",
        salaryRangeForTest: "La fourchette de salaire net pour la période d'essai",
        salaryRangeForAfterTest: "La fourchette de salaire net après lq pèriode d'essai",
        weOffer: "Nous offrons",
        workingSchedule: "L'horraire du travail",
        description: "La Description",
        chooseLang: "Sélectionnez sur quelles langues voulez-vous de remplir la demande",
        weekDays: {
          Mon: "Lun",
          Tue: "Mar",
          Wed: "Mer",
          Thu: "Jeu",
          Fri: "Ven",
          Sat: "Sam",
          Sun: "Dim",
        },
        status: "statut",
        externalPost: "Date de publication externe",
        weeklyXHr: "Semaine x hr",
      },
    },
  },

  methods: {
    clearPreviousPositionData(){
      this.langs.forEach(el => {
        if(!el.IsDefault){
          el.selected = false
        }else{
          el.selected = true
        }
      })

      this.locale = this.langs
        .filter((el) => el.LanguageID === 2)[0]
        .Code.toLowerCase();

      this.languageIDs = [
        {
          id: 2,
        }
      ]

      this.multiSelectedLangs.forEach(el => {
        if(el.id !== 2){
          el.selected = false
        }
      })

      this.allNewITems = []
      this.selectedNewItems = []

      this.itemsArray.forEach(obj => {
          obj.array.forEach(item => {
              if(item.selected) item.selected = false
          })
          obj.selected = []
      })
      
      this.getDropDownLists(2)
    },
    deleteSavedItem(item,ind){
      let currentLang = this.langs.filter(lang => lang.selected == true)[0].LanguageID
      let objectType = item.ObjectTypeID
      let object = item.ID
      let newItem = item.newItem
      if(newItem){ //new item without object ID
        this.itemsArray.filter(el => el.typeId === item.objectTypeID)[0].array =  
        this.itemsArray.filter(el => el.typeId === item.objectTypeID)[0].array.filter(el => el.Description !== item.Description)
        this.allNewITems = this.allNewITems.filter(el => el.Description !== item.Description)
      }

      let openPisitionObjects = [
        {
          objectID: item.ID,
          objectTypeID: item.ObjectTypeID,
        }
      ]  

      if(!newItem){
      axios.post('openposition/DeleteOpenPositionObjects',{
        openPisitionObjects
          }).then(resp => {
              if(!resp.data.ErrorMsg){
                this.snackbarVisible = true;
                this.snackbarPurpose = "success";
                this.snackbarText = "Successfully Removed";
                this.getDropDownLists(currentLang);
              }
              else{
                console.log(resp.ErrorMsg);
              }
          })
      }
    },
    deleteObject(objectID,reqTo){
      axios.post(`openposition/${reqTo}`,{
          id: objectID,
      }).then(resp => {
          if(!resp.data.ErrorMsg){
            this.snackbarVisible = true;
            this.snackbarPurpose = "success";
            this.snackbarText = "Successfully Removed";
          }
      })
    },
    checkColumnVisibility(columnName){
      let currentObj = this.columnVisibilities.filter(el => el.columnName == columnName)
      return currentObj[0].visible
    },
    setColumnVisibility(columnName,val){
      let currentObj = this.columnVisibilities.filter(el => el.columnName == columnName)
      currentObj[0].visible = val
    },
    collectColumnVisibilities(){
      let openPositionColumnVisibilities = this.itemsArray.map((el) => ({
          columnName: el.columnName,
          visible: el.visible,
          ObjectTypeID: el.typeId

        })
      )

      for(let item of this.columnVisibilities){
        openPositionColumnVisibilities.push(item)
      }

      return openPositionColumnVisibilities
    },
    collectObjectsWithTranslation(){
      let currentLangID = this.langs.filter((el) => el.selected)[0].LanguageID;

      let objectsWithTranslations = this.selectedNewItems.map(obj => ({
        description: obj.Description,
        descriptionTranslated: obj.DescriptionTranslated,
        languageID: obj.languageID,
        objectTypeID: obj.objectTypeID,
        objectID: obj.objectID
      }))

      let objectLangs = []
      for (let i of objectsWithTranslations){
        objectLangs.push(i.languageID)
      }
      objectLangs = [...new Set(objectLangs)]

      if(objectLangs.length < this.languageIDs.length){
        this.allNewITems.forEach(item => {
          objectsWithTranslations.push({
            description: item.Description,
            descriptionTranslated: item.DescriptionTranslated,
            languageID: item.languageID,
            objectTypeID: item.objectTypeID,
            objectID: item.objectID
          })
        })
      }
      this.itemsArray.forEach(item => {
        item.array.forEach(obj => {
          if(obj.selected && !obj.newItem){
            objectsWithTranslations.push({
              description: obj.Description,
              descriptionTranslated: obj.DescriptionTranslated,
              languageID: currentLangID,
              objectTypeID: obj.ObjectTypeID,
              objectID: obj.ID
            })
          }
        })
      })

      // reasons object
      let selectedReason = this.reasonOfRequests.filter(r => r.selected)

        let selectedReasonObj = selectedReason.map(reason => ({
            description: reason.Description,
            descriptionTranslated : reason.DescriptionTranslated,
            languageID: 2,
            objectTypeID: reason.ObjectTypeID,
            objectID: reason.ID,
        }))[0]

        objectsWithTranslations.push(selectedReasonObj)

      this.concatTranslateOptions(this.currentLanguageID, true)
      objectsWithTranslations.push(...this.translatedJobOptions)
      objectsWithTranslations.push(...this.translatedContractTypeOptions)
      objectsWithTranslations.push(...this.translatedJobTypeOptions)
      objectsWithTranslations.push(...this.translatedDescription)

      this.translatedTags = objectsWithTranslations
      this.sendLoaderVisibile = true
      setTimeout(() => {
          if (!this.editMode) {
            this.createPosition(this.draft);
          } else {
            this.updatePosition(this.draft);
          }
      }, 1500);
    },

    customEventHanlder() {
      let targetEl = document.querySelectorAll(".links-content");
      let th = this;
      targetEl.forEach((el) => {
        el.addEventListener("click", function (e) {
          th.showInput = true;

          e.stopPropagation();
        });
      });
    },
    deleteSelectedId(id, item) {
      item.selected = item.selected.filter((el) => el != id);
    },
    selectItemIds(id, item) {
      let exist = item.selected.includes(id);
      if (exist) {
        item.selected = item.selected.filter((el) => el != id);
      } else {
        item.selected.push(id);
      }
    },
    saveSingleLangItems(items) {
      items.forEach((el) => {
        this.selectedNewItems.push(el);
      });
    },
    publishOrDraftPosition() {
      this.collectObjectsWithTranslation()
    },
    validate(array) {
      if (array.filter((el) => el.newSkill).length) {
        return true;
      } else {
        return false;
      }
    },
    showOptions(el) {
      if(el.statusRestricted) return

      el.optionsVisible = !el.optionsVisible;
      this.data.forEach((element) => {
        if (element.OpenPositionID === el.OpenPositionID) return;
        this.$set(element, "optionsVisible", false);
      });
      // let loggedPerson = this.$store.state.profile.ID;
      // if (
      //   (el.OpenPositionStatusID === 4 || el.OpenPositionStatusID === 5) &&
      //   !this.isAdminOrHr
      // ) {
      //   return;
      // } else if (
      //   el.OpenPositionStatusID !== 6 &&
      //   loggedPerson === el.StatusUpdaterID &&
      //   !this.isAdminOrHr
      // ) {
      //   el.optionsVisible = !el.optionsVisible;
      // }

    },
    getStatuses() {
      axios.get("openposition/GetOpenPositionStatusTypes").then((resp) => {
        resp.data.Value.forEach(el => {
          if(el.StatusName === "Internal vacancy"){
            el.StatusName = "Internal"
          }
          if(el.StatusName === "External vacancy"){
            el.StatusName = "External"
          }
        })
        this.statusTypes = resp.data.Value;
        this.statusTypes.forEach((el) => {
          switch (el.StatusID) {
            case 1:
              el.color = "#283593";
              break;
            case 2:
              el.color = "#8D91B1";
              break;
            case 3:
              el.color = "#F59D18";
              break;
            case 4:
              el.color = "#1BC2C2";
              break;
            case 5:
              el.color = "#FF5F58";
              break;
            case 6:
              el.color = "#FF5F58";
              break;
            case 7:
              el.color = "#1DB3E9";
              break;
            case 8:
              el.color = "#F59D18"
              break;
            case 9:
              el.color = "#F59D18"
              break
          }
        });
      });
    },
    setPositionStatusListForRoles(){
      let statusList = []
      if(this.$store.getters.hasRole("Manager")){
        if(this.editMode){
          let managerEditStatuses = [1,2,6,7]
          statusList = this.setStatusArr(this.statusTypes,managerEditStatuses)
        }
        if(!this.editMode){
          let managerCreateStatuses = [1,2]
          statusList = this.setStatusArr(this.statusTypes,managerCreateStatuses)
        }
      }

      if(this.$store.getters.hasRole("Admin") || this.$store.getters.hasRole("HR")){
        if(this.editMode){
          let hrAdminCreateStatuses = [1,2,4,6,5]
          statusList = this.setStatusArr(this.statusTypes,hrAdminCreateStatuses)
        }
        if(!this.editMode){
          let hrAdminEditStatuses = [1,2,3,8,9]
          statusList = this.setStatusArr(this.statusTypes,hrAdminEditStatuses)
        }
      }

      if(this.$store.getters.hasRole("Super Admin")){
        statusList = this.statusTypes.map(el => ({
          title: el.StatusName,
          value: el.StatusID,

        }))
      }
        return statusList
    },
    setStatusOptions(){
      let hrOrAdminStatuses = [1,2,3,4,5,6,8,9]
      let managerStatuses = [1,2,6]
      let options = []
      if(this.$store.getters.hasRole("Admin") || this.$store.getters.hasRole("HR")){
        options = this.statusTypes.filter(status => hrOrAdminStatuses.includes(status.StatusID)).map(el => ({
          title: el.StatusName,
          value: el.StatusID,
          statusType: 1,
          color: el.color
        }))
      }
      if(this.$store.getters.hasRole("Manager")){
        options = this.statusTypes.filter(status => managerStatuses.includes(status.StatusID)).map(el => ({
          title: el.StatusName,
          value: el.StatusID,
          statusType: 2,
          color: el.color
        }))
      }

      if(this.$store.getters.hasRole("Super Admin")){
        options = this.statusTypes.map(el => ({
          title: el.StatusName,
          value: el.StatusID,
          statusType: 3,
          color: el.color
        }))
      }
      return options
    },
    setStatusArr(srcArray,statusArr){
      let result = srcArray.filter(el => statusArr.includes(el.StatusID))
      .map(status => ({
          title: status.StatusName,
          value: status.StatusID,
      }))
      return result
    },
    clickedRowHandler(row) {
      this.clickedRowId = row.OpenPositionID;
      this.savedLinks = row.UrlEntities;
    },
    getLinksValues(value, index) {
      this.links.forEach((el, ind) => {
        if (index == ind) {
          el.link = value;
        }
      });
    },
    updateStatus(row, id) {
      axios
        .post("openposition/UpdateOpenPositionStatus", {
          status: id,
          id: row.OpenPositionID,
        })
        .then((resp) => {
          this.getOpenPositions();
        });
    },
    updateLinks(array) {
      let links = [];
      this.links.forEach((el) => {
        if (el.link.length) {
          links.push({
            Url: el.link,
          });
        }
      });
      this.savedLinks.forEach((el) => {
        if (el.Url.length) {
          links.push({
            Url: el.Url,
          });
        }
      });

      axios
        .post("/openposition/AddUrlsToOpenPosition", {
          openPositionID: this.clickedRowId,
          OpenPositionUrlsTVP: links,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.links = [
              {
                link: "",
              },
            ];
            this.getOpenPositions();
            this.snackbarVisible = true;
            this.snackbarPurpose = "success";
            this.snackbarText = "Successfully saved";
          }

          this.clickedRowId = 0;
        });
    },
    addNewExp(value) {
      this.selectedExps.push({
        SkillName: value,
        selected: true,
        newSkill: true,
      });
    },
    addNewRequiredSkill(value) {
      this.selectedKnowledges.push({
        SkillName: value,
        KnowledgeType: 1,
        selected: true,
        newSkill: true,
      });
    },
    addNewPreferredSkill(value) {
      this.selectedPreferredKnowledges.push({
        SkillName: value,
        KnowledgeType: 2,
        selected: true,
        newSkill: true,
      });
    },

    addNewItem(value, id) {
      let langId = this.langs.filter((el) => el.selected)[0].LanguageID;
      let langDefoult = this.langs.filter((el) => el.IsDefault)[0].LanguageID;
      let newITem = {
        Description: value,
        DescriptionTranslated: value,
        languageID: langId,
        objectTypeID: id,
        objectID: null,
        newItem: true,
        defaultLangId: langDefoult,
        selected: true,
      };
      this.itemsArray.filter((el) => el.typeId === id)[0].array.push(newITem);
      this.allNewITems.push(newITem);
      // switch (id) {
      //   case 2:
      //     this.newItemObject(value, id, langId)

      //     break;
      //   case 3:
      //   this.newItemObject(value, id, langId)
      //   break;
      //   case 12:
      //    this.newItemObject(value, id, langId)
      // }
    },
    addNewSkill(value) {
      this.selectedSkills.push({
        SkillName: value,
        KnowledgeType: null,
        selected: true,
        newSkill: true,
      });
    },
    deleteSavedLink(index) {
      this.savedLinks = this.savedLinks.filter((el, i) => index !== i);
    },
    deleteLink(ind) {
      this.links = this.links.filter((el, index) => index !== ind);
    },
    addLinkHandler() {
      this.links.push({ link: "" });
    },

    addPopupVisible() {
      this.langs.forEach((lang) => {
        lang.selected = false;
        if (lang.LanguageID == 2) {
          lang.selected = true;
        }
      });

      this.multiSelectedLangs.forEach(el => {
        if(el.id !== 2){
          el.selected = false
        }
      })
      this.selectedJob = ''
      this.selectedOffice = ''
      this.selectedDep = ''
      this.positionQuantity = ''
      this.deadLineTime = null
      this.itemsArray.forEach(obj => {
          obj.array.forEach(item => {
              if(item.selected) item.selected = false
          })
          obj.selected = []
      })
      this.selectedContract = ''
      this.contract = {
        from: 0,
        to: 0,
      },
      this.selectedStatus = ''
      this.salaryRangeForTest = ''
      this.salaryRangeForAfterTest = ''
      this.selectedSchedule = ''
      this.setDefaultDescription()

      this.currentLanguageID = 2
      this.showJobTranslate = false
      this.selectedListItemTranslation[0].value = ''
      this.showContractTranslate = false
      this.selectedListItemTranslation[1].value = ''
      this.showJobTypeTranslate = false
      this.selectedListItemTranslation[2].value = ''

      //let langId = this.langs.filter((el) => el.selected)[0].LanguageID;     
      let langId = 2
      this.locale = 'en'
      this.getDropDownLists(langId);

      if (this.editMode || this.detailsMode) {
        //this.getDropDownLists(langId);
        this.updateFields();
      }

      this.editMode = false;
      this.detailsMode = false;
      this.jobRequestPopup = true;
    },
    getOpenPositions() {

      axios.get('openposition/GetPersonsWithHrRole')
      .then(resp => {
        let data = resp.data.Value
        let ids = []
        data.forEach(val => {
          ids.push(val.PersonID)
        })
        this.hrRoleIds = [...ids]

        this.loaderVisible = true
        let loggedPerson = this.$store.state.profile.ID;
        axios.get("/openposition/GetOpenPositions").then((resp) => {
          this.data = resp.data.Value.OpenPositionWithUrls;
          this.loaderVisible = false
          let managerRestrictedStatuses = [3,4,5,6,8,9]

          if(this.data.length < 1){
            this.emptyData = true
          }else{
            this.emptyData = false
          }

          this.data.forEach((el) => {          
            this.$set(el, "inputVisible", false);
            this.$set(el, "optionsVisible", false);

            if(this.$store.getters.hasRole("Manager")){
              if(this.hrRoleIds.includes(el.StatusUpdaterID) && managerRestrictedStatuses.includes(el.OpenPositionStatusID)){
                this.$set(el, "statusRestricted", true);
              }else{
                this.$set(el, "statusRestricted", false);
              }
            }


            let languageCodeString = ''
            el.OpenPositionLanguages.forEach((elem) => {
              languageCodeString += elem.LanguageCode +', '           
            })
            this.$set(el, "languageCodeString", languageCodeString.slice(0, -2))

            if(el.OpenPositionStatusName === "Internal vacancy") el.OpenPositionStatusName = "Internal"
            if(el.OpenPositionStatusName === "External vacancy") el.OpenPositionStatusName = "External"
            
            switch (el.OpenPositionStatusID) {
              case 1:
                el.color = "#283593";
                break;
              case 2:
                el.color = "#8D91B1";
                break;
              case 3:
                el.color = "#F59D18";
                break;
              case 4:
                el.color = "#1BC2C2";
                break;
              case 5:
                el.color = "#FF5F58";
                break;
              case 6:
                el.color = "#FF5F58";
                break;
              case 7:
                el.color = "#1DB3E9";
                break;
              case 8:
                el.color = "#F59D18";
                break;
              case 9:
                el.color = "#F59D18";
                break;
            }
          });
        });
      })
    },
    updateFields() {
      this.selectedOffice = "";
      this.jobPositionInDep = "";
      this.positionQuantity = "";
      this.selectedDep = "";
      this.customReason = "";
      this.selectedContract = "";
      this.selectedStatus = "";
      this.salaryRangeForTest = "";
      this.selectedSchedule = "";
      this.salaryRangeForAfterTest = "";
      (this.bonusVisibility = false), (this.weOffer = "");
      this.education = "";
      // this.selectedExperience = "";
      this.selectedJob = "";
      this.deadLineTime = null;

      this.responsibilities = [];
      this.selectedSkills = [];
      this.selectedKnowledges = [];
      this.selectedExps = [];
      this.selectedPreferredKnowledges = [];

      this.testSalaryVisibility = false;
      this.salaryVisibility = false;
      this.reasonOfRequests.forEach((el) => {
        if (el.selected == true) {
          el.selected = false;
        }
      });
      this.itemsArray.forEach((el) => {
        el.array = [];
      });
      this.experiences.forEach((el) => {
        if (el.selected == true) {
          el.selected = false;
        }
      });
      this.requiredKnowledges.forEach((el) => {
        if (el.selected == true) {
          el.selected = false;
        }
      });
      this.preferredKnowledges.forEach((el) => {
        if (el.selected == true) {
          el.selected = false;
        }
      });
      this.skills.forEach((el) => {
        if (el.selected == true) {
          el.selected = false;
        }
      });
    },
    defaultSelectedOptions(){
      this.languageIDs = [
        {
          id:2
        }
      ]
      this.reasonOfRequests.forEach(r => {
        if(r.selected){
            r.selected = false
        }
      })
      this.selectedReasonID = null
      this.createPositionStatusID = null

      this.columnVisibilities.forEach(col => {
        if(col.required) col.visible = true
        else col.visible = false
      })
      this.itemsArray.forEach(item => {
        if(item.required) item.visible = true
        else item.visible = false
        item.selected = []
      })

      this.allNewITems = []

      this.postDate = null
    },
    async getPositionDetails(id,langID) {
      
      this.updateFields();
      
      this.openPositionID = id;
      const resp = await axios.get(
        `openposition/GetOpenPositionByID?ID=${id}&LanguageID=${langID ? langID : 2}`
      );

      this.currentDetailsLangID = langID || 2

      let lang = resp.data.Value.OpenPositions[0].ReasonOfRequestLanguageID;
      
      let data = resp.data.Value.OpenPositions[0];

      let dataObjects = resp.data.Value

      let skills = resp.data.Value.OpenPositionSkills;

      skills.forEach((el) => {
        if (el.Chosen) this.$set(el, "selected", true);
        this.itemsArray
          .filter((element) => element.typeId === el.ObjectTypeID)[0]
          .array.push(el);
      });

      let itemsData = resp.data.Value;

      for (var key in itemsData) {
        if (itemsData[key].ObjectTypeID) {
          let mappedData = itemsData[key].OpenPositionItems.map((el) => {
            this.$set(el, "selected", true);
            return el;
          });

          this.itemsArray.filter(
            (element) => element.typeId === mappedData[0].ObjectTypeID
          )[0].array = itemsData[key].OpenPositionItems;
        }
      }
      itemsData.OpenPositionSkills.forEach(itemData => {
        this.itemsArray.find(item => item.typeId === itemData.ObjectTypeID).selected.push(itemData.ID)
      })
      
      this.itemsArray.forEach(el => {
        el.selected = [...new Set(el.selected)]
      })

      if(!this.detailsMode && !this.editMode || !langID && !this.editMode){
        this.locale = this.langs
          .filter((el) => el.LanguageID === 2)[0]
          .Code.toLowerCase();
  
        this.langs.forEach((el) => {
          if (el.LanguageID === 2) {
            el.selected = true;
          } else {
            el.selected = false;
          }
        });
      }else{
        this.positionLangs.forEach(lang => {
          if(lang.LanguageID === langID){
            lang.selected = true
            this.locale = lang.Code.toLowerCase()
          }else{
            lang.selected = false
          }
        })
      }

      this.languageIDs = dataObjects.OpenPositionLanguages.map(l => ({
        id: l.LanguageID
      }))

      this.currentPositionID = data.OpenPositionID
      this.selectedOffice = data.OfficeID;
      this.selectedDep = data.DepartmentID;
      this.selectedJobName = data.JobName;
      this.selectedJob = data.JobID;
      this.selectedContract = data.ContractTypeID;
      this.contract.from = data.ContractStartDate;
      this.contract.to = data.ContractEndDate;

      this.positionQuantity = data.Quantity;
      this.selectedStatus = data.EmploymentStatusID;
      this.bonusVisibility = data.financialIncentivesVisibility;
      this.salaryRangeForTest = data.SalaryInTestPeriodRange;
      this.salaryRangeForAfterTest = data.SalaryAfterTestPeriodRange;

      this.selectedSchedule = data.ScheduleDescription;
      this.salaryVisibility = data.SalaryAfterTestPeriodVisibility;
      this.testSalaryVisibility = data.SalaryTestPeriodVisibility;
      let reasonOfRequestID = data.ReasonID;
      this.selectedReasonID = data.ReasonID
      this.reasonOfRequests.forEach((el) => {
        el.selected = false;
        if (el.ID == reasonOfRequestID) {
          el.selected = true;
        }
      });
      this.createPositionStatusID = data.OpenPositionStatusID
      this.weOffer = dataObjects.FinancialIncentives.OpenPositionItems;
      this.education = data.EducationDescription;
      let positionLanguages = dataObjects.OpenPositionLanguages.map(el => (el.LanguageID))
      this.positionLangs = this.langs.filter(el => positionLanguages.includes(el.LanguageID))
      // this.selectedExperience = data.WorkExperienceID;

      //position details popup missed things //
      this.educationDetails = dataObjects.Educations.OpenPositionItems
      this.experienceDetails = dataObjects.WorkExperiences.OpenPositionItems

      this.companyDetail = data.OfficeName
      this.jobInDepDetail = data.DepartmentName
      this.jobTranslated = data.JobNameTranslated
      this.contractDetail = data.ContractTypeName
      this.contractTranslated = data.ContractTypeNameTranslated
      this.empStatusDetail = data.EmploymentStatusName
      this.empStatusTranslated = data.EmploymentStatusNameTranslated
      this.postDate = data.PostDate

      if(this.editMode){
        this.selectedListItemTranslation[0].value = data.JobNameTranslated
        this.selectedListItemTranslation[1].value = data.ContractTypeNameTranslated
        this.selectedListItemTranslation[2].value = data.EmploymentStatusNameTranslated
      }

      let requiredSkills = []
      let preferredSkills = [] 
      let common = []
      dataObjects.OpenPositionSkills.forEach(item => {
        if(item.KnowledgeTypeName === 'Required'){
            requiredSkills.push(item)
        }
        if(item.KnowledgeTypeName === 'Preferred'){
          preferredSkills.push(item)
        }
        if(item.KnowledgeTypeName === null){
          common.push(item)
        }
      })

      this.skillDetails.required = requiredSkills
      this.skillDetails.preferred = preferredSkills
      this.skillDetails.common = common
      this.responsibilitiesDetails = dataObjects.Responsibilities.OpenPositionItems
      this.deadLineTime = data.DeadLine;
      this.multiSelectedLangs.forEach(lang => {
        lang.selected = false
      })

      if(this.editMode){
        let currentPositionLangs = []
        for(let lang of dataObjects.OpenPositionLanguages){
          currentPositionLangs.push(lang.LanguageID)
        }
        this.multiSelectedLangs.forEach(lang => {
          if(currentPositionLangs.includes(lang.id)) lang.selected = true
        })
        this.setSavedandSelectedTags(id,this.currentLanguageID)

        dataObjects.Visibilities.forEach(el => {
          this.columnVisibilities.forEach(col => {
            if(col.columnName === el.ColumnName){
              if(col.required) return
              col.visible = el.Visible
            }
          })
          this.itemsArray.forEach(item => {
            if(item.columnName === el.ColumnName){
              if(item.required) return
              item.visible = el.Visible
            }
          })
        })
      }
    },
    setSavedandSelectedTags(id,langID){
      axios.get('openposition/GetOpenedPositionPagesDropDownsLists',{
        params:{
          Language:langID,
        }
      }).then((resp) => {
        let data = resp.data.Value
        let keys = ['WorkExperienceObject','EducationObject','JobResponsibilityObject','FinancialIncentiveObject','SkillObject']
        keys.forEach(key => {
          data[key].OpenPositionItems.forEach(item => {
            if(item.ObjectTypeID === 12 || item.ObjectTypeID === 13 || item.ObjectTypeID === 14){
              this.itemsArray.find(i => i.typeId === 12).array.push(JSON.parse(JSON.stringify(item)))
              this.itemsArray.find(i => i.typeId === 13).array.push(JSON.parse(JSON.stringify(item)))
              this.itemsArray.find(i => i.typeId === 14).array.push(JSON.parse(JSON.stringify(item)))
            }
            let targetArr = this.itemsArray.find(id => id.typeId === item.ObjectTypeID).array
            if(targetArr.some(el => el.ID === item.ID)) return
            else{
              this.itemsArray.find(id => id.typeId === item.ObjectTypeID).array.push(item)
            }
          })
        })

        let skillobjectTypeIdsArr = [12,13,14]

        skillobjectTypeIdsArr.forEach(el =>{
          this.itemsArray.find(item => item.typeId === el).array = JSON.parse(JSON.stringify([...new Map(this.itemsArray.find(item => item.typeId === 12).array.map(e=> [e['ID'], e])).values()]))
        })
        
        this.reasonOfRequests = data.ReasonOfRequestObject.OpenPositionItems

        this.reasonOfRequests.forEach(reason =>{
          if(reason.ID === this.selectedReasonID){
            reason['selected'] = true
          }else{
            reason['selected'] = false
          }
        })

        this.allNewITems.forEach(item => {
          this.itemsArray.find(el => el.typeId === item.objectTypeID).array.push(item)
        })

        this.itemsArray.forEach(item => {
          item.array.forEach(obj => {
            if(item.selected.includes(obj.ID)){
              obj.selected = true
            }
            obj.ObjectTypeID = item.typeId
          })
        })
      })
    },

    positionClickHandler(el) {
      this.positionDetailsVisible = true;
      this.detailsMode = true;
      this.editMode = false
      this.updateFields();
      this.getPositionDetails(el.OpenPositionID);
    },
    createPosition(draft) {
      // let selectedItems = [];
      // this.itemsArray.forEach((el) => {
      //   el.array.forEach((element) => {
      //     selectedItems.push(element);
      //   });
      // });
      
      let object = {
        job: this.selectedJob,
        company: 1,
        office: this.selectedOffice,
        departmentID: this.selectedDep,
        quantity: this.positionQuantity,
        deadLine: this.deadLineTime,
        postDate: this.postDate,
        educationDescription: this.education,
        contractType: this.selectedContract,
        salaryInTestPeriodRange: this.salaryRangeForTest,
        salaryAfterTestPeriodRange: this.salaryRangeForAfterTest,
        financialIncentivesVisibility: this.bonusVisibility,
        employmentStatus: this.selectedStatus,
        salaryTestPeriodVisibility: this.testSalaryVisibility,
        salaryAfterTestPeriodVisibility: this.salaryVisibility,
        scheduleDescription: this.selectedSchedule,
        isDraft: draft ? true : false,
        contractStartDate: this.contract.from ? this.contract.from : null,
        contractEndDate: this.contract.to ? this.contract.to : null,
        languageID: this.langs.filter((el) => el.selected == true)[0]
          .LanguageID,
        newObjectsWithTranslations: this.translatedTags,
        openPositionColumnVisibilities: this.collectColumnVisibilities(),
        languageIDs: this.languageIDs,
        description: this.additionalDescription,
        status: this.createPositionStatusID
      }
      axios
        .post("openposition/CreateOpenPosition", object)
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.jobRequestPopup = false;
            this.snackbarVisible = true;
            this.snackbarPurpose = "success";
            this.snackbarText = "Successfully Created";
            this.updateFields();
            this.clearPreviousPositionData()
            this.getOpenPositions();
            this.defaultSelectedOptions()
          }
          this.sendLoaderVisibile = false
        });
    },
    deleteOpenPosition(el) {
      this.$confirm("Are you sure  you want to delete?").then(() => {
        axios
          .post("openposition/UpdateOpenPosition", {
            openPositionID: el,
            delete: true,
            newObjectsWithTranslations:[],
            openPositionColumnVisibilities: [],
            languageIDs: [],
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.snackbarPurpose = "success";
              this.snackbarText = "Successfully deleted";
              this.snackbarVisible = true;
              this.getOpenPositions();
            }
          });
      });
    },
    // collectEditedPositionData(){
    //   let editedPositionData = []
    //   this.itemsArray.forEach(item => {
    //     if(item.array.length > 0){
    //       item.array.forEach(obj => {
    //         editedPositionData.push(
    //           {
    //             description: obj.Description,
    //             // descriptionTranslated: "string",
    //             objectTypeID: obj.ObjectTypeID,
    //             objectID: obj.ID
    //           }
    //         )
    //       })
    //     }
    //   })
    //   return editedPositionData
    // },
    updatePosition(draft) {
      
      let object = {
        job: this.selectedJob,
        openPositionID: this.currentPositionID,
        company: 1,
        office: this.selectedOffice,
        departmentID: this.selectedDep,
        quantity: this.positionQuantity,
        deadLine: this.deadLineTime,
        postDate: this.postDate,
        educationDescription: this.education,
        contractType: this.selectedContract,
        salaryInTestPeriodRange: this.salaryRangeForTest,
        salaryAfterTestPeriodRange: this.salaryRangeForAfterTest,
        financialIncentivesVisibility: this.bonusVisibility,
        employmentStatus: this.selectedStatus,
        salaryTestPeriodVisibility: this.testSalaryVisibility,
        salaryAfterTestPeriodVisibility: this.salaryVisibility,
        scheduleDescription: this.selectedSchedule,
        isDraft: draft ? true : false,
        contractStartDate: this.contract.from ? this.contract.from : null,
        contractEndDate: this.contract.to ? this.contract.to : null,
        newObjectsWithTranslations: this.translatedTags,
        openPositionColumnVisibilities: this.collectColumnVisibilities(),
        languageIDs: this.languageIDs,
        description: this.additionalDescription,
        status: this.createPositionStatusID,
      }

      axios
        .post("openposition/UpdateOpenPosition",object)
        .then((resp) => {
          this.sendLoaderVisibile = false
          if (!resp.data.ErrorMsg) {
            this.snackbarVisible = true;
            this.snackbarPurpose = "success";
            this.snackbarText = "Successfully Created";
            this.jobRequestPopup = false;
            this.getOpenPositions();
            this.defaultSelectedOptions()
          }
        });
    },
    editClickHandler(el) {
      this.editMode = true;
      this.jobRequestPopup = true;
      this.getPositionDetails(el);
    },
    async getDropDownLists(langID) {
      const resp = await axios.get(
        "openposition/GetOpenedPositionPagesDropDownsLists",
        {
          params: {
            Language: langID,
          },
        }
      );

      this.multiSelectedLangs.forEach(lang => {
        if(lang.id == 2 && !lang.selected) lang.selected = true
      })  //default selected language 

      this.dropDownLists = resp.data.Value;
      this.jobs = resp.data.Value.JobObject.OpenPositionItems;
      if(langID == 2){this.defaultJobs = resp.data.Value.JobObject.OpenPositionItems;}
      this.offices = resp.data.Value.CompanyObject.OpenPositionItems;
      this.statuses = resp.data.Value.StatusObject.OpenPositionItems;
      if(langID == 2){this.defaultStatuses = resp.data.Value.StatusObject.OpenPositionItems;}
      this.contracts = resp.data.Value.ContractTypeObject.OpenPositionItems;
      if(langID == 2){this.defaultContracts = resp.data.Value.ContractTypeObject.OpenPositionItems;}
      // this.currencies = resp.data.Value.OpenPositionItems;
      this.departments = resp.data.Value.DepartmentObject.OpenPositionItems;
      this.reasonOfRequests = resp.data.Value.ReasonOfRequestObject.OpenPositionItems;

      let itemsData = resp.data.Value;
      let skills = resp.data.Value.SkillObject.OpenPositionItems;

      this.itemsArray
        .filter((el) => el.typeId === 13 || el.typeId === 12 || el.typeId === 14)
        .forEach((element) => {
          element.array = JSON.parse(JSON.stringify(skills));

          element.array.forEach((el) => {
            let includes = element.selected.includes(el.ID);
            if (includes) {
              this.$set(el, "selected", true);
            }
          });
      });



      for (var key in itemsData) {
        if (itemsData[key].OpenPositionItems.length) {
          let mappedData = itemsData[key].OpenPositionItems.map((el) => {
            return el;
          });

          let exist = this.itemsArray.some((element) => {
            return element.typeId === mappedData[0].ObjectTypeID;
          });
          if (exist) {
            this.itemsArray.filter(
              (element) => element.typeId === mappedData[0].ObjectTypeID
            )[0].array = itemsData[key].OpenPositionItems;
          }

          //  TO select already selected items
          let targetITem = this.itemsArray.filter(
            (element) => element.typeId === mappedData[0].ObjectTypeID
          );
          if (targetITem.length && targetITem[0].array.length) {
            this.itemsArray
              .filter(
                (element) => element.typeId === mappedData[0].ObjectTypeID
              )[0]
              .array.forEach((el) => {
                let includes = targetITem[0].selected.includes(el.ID);

                if (includes) {
                  this.$set(el, "selected", true);
                }
              });
          }
        } else {
          let exist = this.itemsArray.some((element) => {
            return element.typeId === itemsData[key].ObjectTypeID;
          });
          if (exist)
            this.itemsArray.filter(
              (element) => element.typeId === itemsData[key].ObjectTypeID
            )[0].array = itemsData[key].OpenPositionItems;
        }
      }
      this.reasonOfRequests.forEach(reason =>{
        if(reason.ID === this.selectedReasonID){
          reason['selected'] = true
        }else{
          reason['selected'] = false
        }
      })
      this.pushNewItemsAfterLangChange();

            
      // if(!this.editMode){
      //   let skillobjectTypeIdsArr = [12,13,14]
      //   skillobjectTypeIdsArr.forEach(el =>{
      //     this.itemsArray.find(item => item.typeId === el).array = JSON.parse(JSON.stringify([...new Map(this.itemsArray.find(item => item.typeId === 12).array.map(e=> [e['ID'], e])).values()]))
      //   })
      // }

      this.itemsArray.forEach(item => {
        item.array.forEach(el => {
          el.ObjectTypeID = item.typeId
        })
      })
    },
    getKnowledgeTypes() {
      axios.get("openposition/GetOpenPositionKnowledgeTypes").then((resp) => {
        this.knowLedgeTypes = resp.data.Value;
      });
    },
    getLangs() {
      axios.get("openposition/GetLanguages").then((resp) => {
        this.langs = resp.data.Value;
        this.langs.forEach((el) => {
          this.$set(el, "selected", false);
          if (el.Code == "EN") {
            this.$set(el, "selected", true);
          }
        });
        this.multiSelectedLangs = this.langs.map(el => ({
            lang: el.Code,
            id: el.LanguageID,
            selected: el.selected
        }))
      });
    },
    pushNewItemsAfterLangChange() {
      this.allNewITems.forEach((el) => {
        this.itemsArray
          .filter((element) => element.typeId == el.objectTypeID)[0]
          .array.push(el);
      });
    },
    reasonClickHandler(el) {
      this.reasonOfRequests.forEach((el) => {
        if (el.selected == true) {
          el.selected = false;
        }
      });
      el.selected = !el.selected;

      this.selectedReason = el.ID;
      if(el.selected){
        this.selectedReasonID = el.ID
      }
    },

    addOption() {
      this.responsibilities.push({
        description: "",
      });
    },
    deleteOption(index) {
      this.responsibilities = this.responsibilities.filter(
        (el, i) => index !== i
      );
    },

    deleteExpHandler(item, index) {
      this.experiences = this.experiences.filter((el, i) => index !== i);
      this.selectedExps = this.selectedExps.filter((el, i) => index !== i);

      axios
        .post("openposition/DeleteOpenPositionWorkExperience", {
          id: item.SkillID,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.snackbarVisible = true;
            this.snackbarPurpose = "success";
            this.snackbarText = "Successfully deleted";
          }
        });
    },
    // deleteSkillHandler(item, index) {
    //   if (item.KnowledgeType === 1) {
    //     this.requiredKnowledges = this.requiredKnowledges.filter(
    //       (el, i) => index !== i
    //     );
    //     this.selectedKnowledges = this.selectedKnowledges.filter(
    //       (el, i) => index !== i
    //     );
    //   } else if (item.KnowledgeType === 2) {
    //     this.preferredKnowledges = this.preferredKnowledges.filter(
    //       (el, i) => index !== i
    //     );
    //     this.selectedPreferredKnowledges = this.selectedPreferredKnowledges.filter(
    //       (el, i) => index !== i
    //     );
    //   } else {
    //     this.skills = this.skills.filter((el, i) => index !== i);
    //     this.selectedSkills = this.selectedSkills.filter(
    //       (el, i) => index !== i
    //     );
    //   }
    //   axios
    //     .post("openposition/DeleteOpenPositionSkill", {
    //       id: item.SkillID,
    //     })
    //     .then((resp) => {
    //       if (!resp.data.ErrorMsg) {
    //         this.snackbarVisible = true;
    //         this.snackbarPurpose = "success";
    //         this.snackbarText = "Successfully deleted";
    //       }
    //     });
    // },
    // expClickHandler(el) {
    //   const exist = this.selectedExps.some(
    //     (item) => item.SkillID == el.SkillID
    //   );
    //   if (exist) {
    //     this.selectedExps = this.selectedExps.filter(
    //       (element) => element.SkillID != el.SkillID
    //     );
    //   } else {
    //     this.selectedExps.push(el);
    //   }
    // },
    concatTranslateOptions(langID, status){
      let currentJobName = this.defaultJobs.filter(val => val.ID === this.selectedJob)
      let currentJobNameTranslate = this.selectedListItemTranslation.filter(val => val.typeID === 1)  
      let translatedJobIndex = this.translatedJobOptions.findIndex(el => el.languageID === langID && 
      el.objectTypeID === currentJobNameTranslate[0].typeID && el.objectID === this.selectedJob)
      if(translatedJobIndex > -1 && !status) this.translatedJobOptions.splice(translatedJobIndex, 1)
      if(currentJobNameTranslate[0].value !== ''){
        this.translatedJobOptions.push({
            description: currentJobName[0].Description,
            descriptionTranslated: currentJobNameTranslate[0].value,
            languageID: this.currentLanguageID,
            objectTypeID: currentJobNameTranslate[0].typeID,
            objectID: this.selectedJob,
        });
      }  
      
      let currentContractTypeName = this.defaultContracts.filter(val => val.ID === this.selectedContract)
      let currentContractTypeNameTranslate = this.selectedListItemTranslation.filter(val => val.typeID === 8)
      let translatedContractTypeIndex = this.translatedContractTypeOptions.findIndex(el => el.languageID === langID && 
      el.objectTypeID === currentContractTypeNameTranslate[0].typeID && el.objectID === this.selectedContract)
      if(translatedContractTypeIndex > -1 && !status) this.translatedContractTypeOptions.splice(translatedContractTypeIndex, 1)
      if(currentContractTypeNameTranslate[0].value !== ''){
        this.translatedContractTypeOptions.push({
            description: currentContractTypeName[0].Description,
            descriptionTranslated: currentContractTypeNameTranslate[0].value,
            languageID: this.currentLanguageID,
            objectTypeID: currentContractTypeNameTranslate[0].typeID,
            objectID: this.selectedContract,
        });
      }       
      
      let currentJobtTypeName = this.defaultStatuses.filter(val => val.ID === this.selectedStatus)
      let currentJobTypeNameTranslate = this.selectedListItemTranslation.filter(val => val.typeID === 9)
      let translatedJobTypeIndex = this.translatedJobTypeOptions.findIndex(el => el.languageID === langID && 
      el.objectTypeID === currentJobTypeNameTranslate[0].typeID && el.objectID === this.selectedStatus)
      if(translatedJobTypeIndex > -1 && !status) this.translatedJobTypeOptions.splice(translatedJobTypeIndex, 1)      
      if(currentJobTypeNameTranslate[0].value !== ''){
        this.translatedJobTypeOptions.push({
            description: currentJobtTypeName[0].Description,
            descriptionTranslated: currentJobTypeNameTranslate[0].value,
            languageID: this.currentLanguageID,
            objectTypeID: currentJobTypeNameTranslate[0].typeID,
            objectID: this.selectedStatus,
        });
      }  

      let currentDescriptionTranslate = this.selectedListItemTranslation.filter(val => val.typeID === 15)
      let translatedDescriptionIndex = this.translatedDescription.findIndex(el => el.languageID === langID && 
      el.objectTypeID === currentDescriptionTranslate[0].typeID)
      if(translatedDescriptionIndex > -1 && !status) this.translatedDescription.splice(translatedDescriptionIndex, 1)
      if(this.additionalDescription !== ''){
        this.translatedDescription.push({
            description: this.additionalDescription,
            descriptionTranslated: this.additionalDescription,
            languageID: this.currentLanguageID,
            objectTypeID: currentDescriptionTranslate[0].typeID,
            objectID: null,
        });
      } 
    },
    langClickHandler(el) {  
      if(this.editMode){
        this.getPositionDetails(this.currentPositionID,el.LanguageID)
        this.langs.forEach(lang => {
          if(lang.LanguageID === el.LanguageID){
            lang.selected = true
          }else{
            lang.selected = false
          }
          this.currentLanguageID = el.LanguageID
        })
        this.locale = el.Code.toLowerCase();
        // if(this.allNewITems.length > 0){
        //   this.allNewITems.forEach(el => {
        //     this.itemsArray.find(item => item.typeId === el.objectTypeID).array.push({
        //       Description: el.Description,
        //       DescriptionTranslated:el.DescriptionTranslated,
        //       ID: el.objectID,
        //       ObjectTypeID: el.objectTypeID,
        //       selected: el.selected
        //     })
        //   })
        // }
      }else{
        this.langs.forEach((lang) => {
          if (lang.selected == true) {
            lang.selected = false;
          }
        });
        this.getDropDownLists(el.LanguageID);
        this.concatTranslateOptions(el.LanguageID, false);
        this.locale = el.Code.toLowerCase();
        el.selected = true;
        this.currentLanguageID = el.LanguageID
      }
    },
    selectPositionLanguages(lang){
        if(lang.id !== 2) lang.selected = !lang.selected
          
        if(!lang.selected){
          this.languageIDs = this.languageIDs.filter(el => el.id !== lang.id)
          return
          }

        let exist = this.languageIDs.some((item) => item.id == lang.id)
        if(exist) return

        this.languageIDs.push({id:lang.id})
    },
    setDefaultDescription(){
      this.additionalDescription = "UPGAMING is a reliable software provider company consisting of versatile, high-quality and bold features fully adapted to any casinos for their professional functioning. It also ensures its growing and further success.\n \nThe company is distinguished by high social responsibility and special care for employees. We believe that a happy employee is the guarantee of a successful business, therefore, taking care of the personal and professional growth of the team is our daily activity.\n \n UPGAMING invites you to join your team."
    },
    getDateString(date, format = "dd MMM yyyy") {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      if (date === "Invalid Date") return;

      const MONTHS = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      var days = ("0" + date.getDate()).slice(-2),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        year = date.getFullYear(),
        hours = ("0" + date.getHours()).slice(-2),
        minutes = ("0" + date.getMinutes()).slice(-2),
        seconds = ("0" + date.getSeconds()).slice(-2);

      return format
        .replace("dd", days)
        .replace("MMM", MONTHS[parseInt(month) - 1])
        .replace("MM", month)
        .replace("yyyy", year)
        .replace("yy", String(year).slice(-2))
        .replace("HH", hours)
        .replace("mm", minutes)
        .replace("ss", seconds)
        .replace("ww", WEEKDAYS[date.getDay()]);
    },
  },
  computed: {
    isAdminOrHr() {
      return (
        this.$store.getters.hasRole("HR") ||
        this.$store.getters.hasRole("Admin") ||
        this.$store.getters.isSuperAdmin
      );
    },
    isSuperAdminOrHr() {
      return (
        this.$store.getters.hasRole("HR") ||
        this.$store.getters.hasRole("Admin") ||
        this.$store.getters.isSuperAdmin
      );
    },
    selectedLang() {
      if (!this.langs.length) return;
      let validLangID = this.langs.every(l => !l.selected)
      if(validLangID){
        this.langs.forEach(el => {
          if(el.LanguageID === 2) el.selected = true
        })
      }
      return this.langs.filter((el) => el.selected)[0].LanguageID;
    },
    isEndedPosition() {
      let dateNow = Date.now();
      if (this.deadLineTime == null) return;
      if (this.deadLineTime < dateNow) {
        return true;
      }
      return false;
    },
    filteredGridData() {
      let keys = Object.keys(this.filters);

      return this.data.filter((el) => {
        return keys.every((key) => {
          let value = this.filters[key];

          if (value === "") return true;
          switch (key) {
            case "jobName":
              return value == el.JobName;
            case "requestFrom":
              return value == el.FullName;
            case "status":
              return value == el.OpenPositionStatusName;
            // case "posted":
            //   return value == (el.Posted ? 'Yes' : 'No');
          }
        });
      });
    },
    columns() {
      return [
        {
          title: "Job title",
          key: "JobName",
          searchByJob: true,
        },
        {
          title: "Languages",
          key: "languageCodeString",
        },        
        {
          title: this.isAdminOrHr ? "Request from" : "Recruiter",
          key: this.isAdminOrHr ? "FullName" : "",
          searchByCreator: this.isAdminOrHr ? true : false,

          customTemplate: (item, val) =>
            !this.isAdminOrHr
              ? `<span title="${
                  item.StatusUpdaterFirstName
                    ? item.StatusUpdaterFirstName +
                      " " +
                      item.StatusUpdaterLastName
                    : ""
                }"> ${
                  item.StatusUpdaterFirstName
                    ? item.StatusUpdaterFirstName[0] +
                      item.StatusUpdaterLastName[0]
                    : ""
                }</span>`
              : `<span title="${val}"> ${
                  val.split(" ")[0][0] + val.split(" ")[1][0]
                }</span>`,
        },
        {
          title: "Order date",
          key: "StartTime",
          customTemplate: (item, val) => this.getDateString(val),
        },
        {
          title: "Status",
          searchByStatus: true,
          key: "OpenPositionStatusName",
          canSearch: true,
        },

        {
          title: "Post deadline",
          key: "DeadLine",
          customTemplate: (item, val) => this.getDateString(val),
        },
        {
          title: "Salary budget",
          key: "SalaryAfterTestPeriodRange",
        },
        // {
        //   title: "Post",
        //   key: "Posted",
        //   customTemplate: (item, val) => val ? 'Yes' : 'No',
        //   searchByPost: true,
        // },
      ];
    },
    jobOptions() {
      return this.jobs.map((el) => {
        return {
          title: el.Description,
          value: el.ID,
        };
      });
    },
    depOptions() {
      return this.departments.map((el) => ({
        title: el.Description,
        value: el.ID,
      }));
    },
    // scheduleOptions() {
    //   return this.schedules.map((el) => ({
    //     title: el.ScheduleName,
    //     value: el.ScheduleID,
    //   }));
    // },
    experiencesOptions() {
      return this.experiences.map((el) => ({
        title: el.WorkExperienceDescription,
        value: el.WorkExperienceID,
      }));
    },
    contractOptions() {
      return this.contracts.map((el) => ({
        title: el.Description,
        value: el.ID,
      }));
    },
    statusOptions() {
      return this.statuses.map((el) => ({
        title: el.Description,
        value: el.ID,
      }));
    },
    officesOptions() {
      return this.offices.map((el) => {
        return {
          title: el.Description,
          value: el.ID,
        };
      });
    },
    currencyOptions() {
      return this.currencies.map((el) => ({
        title: el.CurrencyCode,
        value: el.CurrencyID,
      }));
    },
  },
  watch: {
    locale(val) {
      this.$i18n.locale = val;

      let currentLang = this.multiSelectedLangs.filter(el => el.lang.toLowerCase() === val)
      let exist = this.languageIDs.some(lang => lang.id === currentLang[0].id)
      if(exist) return

      // split filled translate lang and selected posted
      //this.selectPositionLanguages(currentLang[0]) //check if lang is selected
    },
    selectedReason() {
      this.selectedReason.length ? (this.customReason = "") : null;
    },
    customReason() {
      this.customReason.length ? (this.selectedReason = "") : null;
    },
    data() {
      this.$nextTick(() => {
        this.customEventHanlder();
      });
    },
    currentLanguageID(val){
      if(val === 2){
        this.showJobTypeTranslate = false
        this.showJobTranslate = false
        this.showContractTranslate = false
      }
    }
  },
};
</script>

<style scoped lang='scss'>
.background {
  // padding: 25px;
  height: 97%;
  overflow: hidden;
}
.hr {
  height: calc(100%);
}
::v-deep .grid .table-helper table tbody td {
  max-width: 12.75rem;
  padding: 0.3rem 0 0.5rem 2.5rem;
  font-size: 14px;
  &:nth-child(2) {
    max-width: 16.75rem;
  }
  .links-content {
    img {
      margin-top: 10px;
    }
  }

  &:nth-child(4) {
    position: relative;
    span {
      position: absolute;
      left: 50%;
      bottom: 50%;
      transform: translate(-50%, +50%);
    }
  }
}
::v-deep .grid .table-helper table thead th {
  padding: 0.9375rem 0.9375rem 0.9375rem 2.5rem;
}
::v-deep .grid .table-helper table tbody td.actions {
  padding-right: 0.8rem;
}
::v-deep .grid .table-helper table thead th .th-wrapper {
  white-space: nowrap;
}
::v-deep .grid .table-helper table tbody tr {
  &:hover {
    font-weight: 300;
    background-color: #e6e6f8;
  }
}
::v-deep .table-wrapper {
  overflow-x: initial !important;
  tbody {
    tr {
      .custom-icon {
        height: 25px;
        cursor: pointer;
      }

      td:nth-child(4) {
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          width: 30px;
          border-radius: 100%;
          background: #fff;
          border: 1px solid #3f46bf;
          margin-top: 5px;
          text-transform: uppercase;
          font-weight: 300;
          font-size: 14px;
          color: #283593;
        }
      }
    }
  }
}
.popup-footer {
  // position:absolute;
  // right: 3rem;
  // bottom:2rem;
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
  user-select: none;
  > button {
    font-size: 1rem;
    transition: ease .3s;
    &:hover{
      box-shadow: 0 0 1.875rem rgba(#283593, 0.3);
    }
  }

  &.disabled{
    opacity: .6;
    pointer-events: none;
  }

  .draft {
    width: 100px;
    height: 40px;
    color: #3f46bf;
    border-radius: 6px;
    background: rgba(40, 53, 147, 0);
    border: 1px solid #3f46bf;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
  }

  button:nth-child(1) {
    width: 100px;
    height: 40px;
    border-radius: 6px;
    background: #6956d6;
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;

    justify-content: center;
  }
}
::v-deep label {
  span.title {
    font-size: 14px;
  }
}
.position-details-popup {
  ::v-deep .popup {
    height: calc(100% - 2rem);
    // height: 535px;
    width: 70.6875rem;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 100px;
    padding-right: 100px;
    overflow: hidden;
    h3.title {
      text-transform: capitalize;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(149, 155, 201, 0.2);
    padding-bottom: 20px;
    position: relative;


    .langSwitch-wrapper{
      position: absolute;
      right: 0;
      top: -3rem;
    }

    .deadline {
      display: flex;
      flex-direction: column;
      // justify-content: flex-start;
      align-items: flex-end;
      span:nth-child(1) {
        font-weight: 300;

        color: #959bc9;
      }
      span:nth-child(2) {
        font-size: 20px;
        font-weight: 500;
        color: #959bc9;
      }
    }
    .job-name {
      padding: 12px 30px;
      color: #ffffff;
      text-transform: uppercase;
      font-weight: 900;
      background-color: #6956d6;
      border-radius: 3px;
      display: inline-block;
      max-width: 50rem;
    }
  }

  .details-content {
    height: calc(100% - 120px);
    overflow: auto;
    width: 102%;
    padding-right: 5px;
    > .single-detail {
      margin-top: 30px;
      .left-side {
        color: #959bc9;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .details-wrapper {
      padding-top: 30px;
      padding-bottom: 0.9375rem;

      border-bottom: 1px solid rgba(149, 155, 201, 0.2);
      &:last-of-type {
        border: 0;
        padding-bottom: 0;
      }
      .single-detail {
        display: flex;
        margin-bottom: 15px;
        // &:not(:last-child) {
        // }
        .left-side {
          color: #959bc9;
          font-size: 14px;
          font-weight: 500;
          width: 340px;
          word-break: break-word;
          margin-right: 80px;
        }
        .right-side {
          color: #3f46bf;
          font-size: 15px;
          font-weight: 400;
          width: 100%;
          .quantity {
            padding: 5px 12px;
            background-color: #6956d6;
            color: #fff;
            font-weight: 500;
            display: inline-block;
            border-radius: 5px;
          }
          .single-skill,.single-item {
            display: inline-block;
            padding: 5px;
            font-size: 13px;
            font-weight: 300;
            border-radius: 5px;
            margin-right: 5px;
            margin-bottom: 5px;
            border: 0.5px solid #959bc9;
          }
          .single-resp:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
.job-request-popup {
  ::v-deep .popup {
    height: unset;
    max-height: calc(100% - 2.5625rem);
    // height: 535px;
  }

  .send-loader{
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    @include centerElement;
    background: rgba(#fff, .6);

    .loader-wrapper{
      img{
        width: 10rem;
        margin-right: 3rem;
      }
    }
  }
}
.fields-content {
  display: grid;
  grid-template-columns: auto auto;

  .responsibilities-content {
    margin-top: 15px;
    padding: 10px;

    p {
      color: rgba(40, 53, 147, 0.7);
    }
    .material-icons {
      font-size: 15px;
      cursor: pointer;
      margin-left: auto;
      transition: ease 0.3s;

      &:hover {
        color: rgba($red, 0.7);
      }
    }
    .resp-title {
      font-size: 14px;
      font-weight: 300;
      transition: ease-in-out 0.2s;
      &.active {
        font-size: 12px;
        transition: ease-in-out 0.2s;
      }
    }
    .addition-content {
      font-size: 14px;
      margin-top: 10px;
      font-weight: 300;
      cursor: pointer;
      .add-button {
        color: rgba(40, 53, 147, 0.7);
      }
      span {
        margin-right: 5px;
      }
    }
    .options-content {
      margin-top: 15px;
      max-height: 150px;
      width: 105%;
      padding-right: 9px;
      overflow: scroll;
    }
    .dot {
      width: 5px;
      height: 5px;
      background-color: #6956d6;
      border-radius: 100%;
      margin-right: 10px;
    }
    input {
      border: 0;
      outline: none;
      color: $blue-primary;
      font-size: 12px;
      font-weight: 300;
      width: 91%;
      &::placeholder {
        color: $blue-primary;
      }
    }
    .single-option {
      display: flex;
      align-items: center;
      transition: ease 0.3s;
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }

  .description-content {
    position: relative;
    margin-top: 1rem;
    cursor: pointer;
    
    .clear-description{
      position: absolute;
      top: .6rem;
      right: .6rem;
      
      img{
        width: 1.5rem;
      }
    }
    .visibility-checkbox{
      top: 1.2rem !important;
    }
    ::v-deep {
      .title {
        color: rgba(#283593, 0.7);
      }
      textarea {
        height: 11.4rem;
        font-size: 14px;
        color: rgba(#283593, 0.5);
        line-height: 1.1rem;

        &:focus {
          border: 0.0625rem solid rgba(#283593, 0.3);
        }
      }
    }
  }

  .wrapper {
    margin-left: 3rem;
    margin-top: 0.8125rem;

    &:first-child {
      margin-left: 0;
    }

    &.left-field,
    &.right-field {
      width: 37rem;
    }
  }
  .field {
    margin-top: 14px;
    position: relative;

    .post-date{
      display: flex;
      align-items: center;
      justify-content: space-between;
      ::v-deep .input-content{
        width: calc((100% / 2) - 0.625rem);
      }

      .postDate{
        width: calc((100% / 2) - 0.625rem);
      }
    }

    .hidden-date{
      position: absolute;
      bottom: 1rem;
      left: 0;
      opacity: 0;
      pointer-events: none;
    }

    .visibility-checkbox{
      position: absolute;
      top: 50%;
      transform: translate(0,-50%);
      left: -1.8rem;
    }

    
    .langs-selected-wrapper{
      display: flex;
      align-items: center;
      padding-bottom: .3rem;
      margin-top: 1rem;

        ul{
          list-style: none;
          margin-left: 1.25rem;
          display: flex;
          align-items: centers;
          li{
            width: 1.6875rem;
            height: 1.6875rem;
            @include centerElement;
            margin-left: 0.625rem;
            font-size: 0.8125rem;
            border-radius: 50%;
            color: #6956D6;
            border: 0.0625rem solid #6956D6;
            user-select: none;
            cursor: pointer;
            &.selected{
              background: #6956D6;
              color: #fff;
            }

            &:first-child{
              margin-left: 0;
            }
          }
        }

        span{
          font-size: 0.9375rem;
          color: $blue-primary;
        }

        .select-langs{
          padding-left: 1.25rem;
        }
    }

    &.schedule{
      .visibility-checkbox{
        top: 30% !important;
      }
    }
    .translate-wrapper-img {
        position: absolute;
        top: 0;
        right: -20px;
        cursor: pointer;
        top: 50%;
        transform: translate(50%, -50%);
    }
    .translate-wrapper {
      // display: flex;
      // align-items: center;
      position: relative;

      img {
        position: absolute;
        top: 0;
        right: -20px;
        cursor: pointer;
        top: 50%;
        transform: translate(50%, -50%);
      }
    }
    .translate-input {
      margin-top: 5px;
      overflow: hidden;
      height: 0;
      transition: height 0.3s ease-in-out;
      &.shown {
        height: 2.5rem;
      }
      input, label {
        border: 0.0625rem solid #dcdde6;
        border-radius: 6px;
        padding: 0 0.9375rem 0 0.9375rem;
        width: 100%;
        height: 2.5rem;
        font-size: 0.9375rem;
        color: rgba(40, 53, 147, 0.7);
        outline: none;
        &::placeholder {
          color: $blue-primary;
          opacity: 0.6;
          font-weight: 300;
          font-size: 13px;
        }
      }
    }
    .warning-message {
      color: #ffa346;
      font-size: 0.8125rem;
      font-weight: 100;
      padding-left: 0.4375rem;
    }

    .note-message {
      font-size: 0.8125rem;
      font-weight: 100;
      padding-left: 0.4375rem;
      color: rgba($blue-primary, 0.6);
    }

    .reason-input {
      opacity: 0;
      position: absolute;
    }
    ::v-deep .date-picker {
      .title {
        overflow: visible;
      }
    }
    > p {
      font-weight: 500;
      font-size: 14px;
      color: rgba(40, 53, 147, 0.7);
    }
    ::v-deep .date-range {
      .container {
        border: 0;
        // border-bottom: 0.0625rem solid #dedff4;
      }
    }
    .salary-content {
      position: relative;
      height: 2.5rem;
      display: flex;
      align-items: flex-end;

      .visiblity-content {
        position: absolute;
        right: 0rem;
        font-size: 13px;
        top: -0.6875rem;
        display: flex;
        cursor: pointer;
        color: rgba(40, 53, 147, 0.7);
        align-items: center;
        flex-direction: column;
        font-weight: 300;

        > span {
          margin-top: -1rem;
          margin-bottom: 1rem;
        }

        .dot {
          height: 1.0625rem;
          width: 1.0625rem;
          border-radius: 100%;
          margin-top: 3px;
          border: 1.5px solid #6956d6;
          box-shadow: 0rem 0rem 0.4375rem rgba(#6956d6, 0.3);

          filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.16));
          &.selected {
            background-color: rgb(105, 86, 214);
          }
        }
      }
      .currency-options {
        position: absolute;
        right: 80px;
        bottom: -3px;
        ::v-deep .selected-box {
          background: transparent;

          height: 15px;
          border: 0;
          font-weight: 300;

          // font-size: 12px;
          span.selected-option {
            font-size: 14px;
          }
          span.title {
            font-size: 14px;
            font-weight: 300;
          }
        }
      }
      .title {
        font-weight: 300;
        white-space: nowrap;
        font-size: 13px;
        position: absolute;
        top: 15px;
        z-index: 2;
        color: rgba(40, 53, 147, 0.7);
        transition: all 0.2s ease-out;
        &.focused {
          top: 0;
          transition: all 0.2s ease-out;
        }
      }

      input {
        border: 0;
        outline: none;
        color: $blue-primary;
        width: 100px;
        font-size: 13px;
        font-weight: 300;
        opacity: 0.8;

        &::placeholder {
          color: $blue-primary;
          font-weight: 300;
        }
      }
    }
    .single-reason {
      display: flex;
      margin-top: 0.8125rem;
      cursor: pointer;
      font-weight: 300;
      font-size: 14px;
      .custom-reason {
        border: 0;
        border-bottom: 0.0625rem solid #dcdde6;
        outline: none;
        margin-left: 15px;
        width: 70%;
        color: $blue-primary;
        font-weight: 300;
        font-size: 14px;
        padding-left: 5px;
        &::placeholder {
          font-weight: 300;
          font-size: 11px;
          color: $blue-primary;
          opacity: 0.8;
        }
      }
      &:last-child {
        margin-bottom: 20px;
      }
      .text {
        font-weight: 300;
        font-size: 14px;
        color: rgba(40, 53, 147, 0.7);
      }
      .dot {
        height: 18px;
        width: 18px;
        border-radius: 100%;
        margin-right: 10px;
        border: 1.5px solid #6956d6;
        // filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.16));
        box-shadow: 0rem 0rem 0.4375rem rgba(#6956d6, 0.3);
      }
      .selected {
        background-color: #6956d6;
      }
    }
  }
  ::v-deep .input-search {
    width: 100%;
    label {
      width: 100%;

      span.title {
        max-width: 100%;
      }
    }
  }
  ::v-deep .date-picker {
    width: 100%;
    .el-date-editor.el-input {
      width: 100%;
      //   border: 0;
    }

    .calendar-icon {
      right: 0.5rem;
    }
  }
  ::v-deep.text-input {
    width: 100%;

    span.title {
      max-width: 100%;
    }
  }
}
::v-deep .overlay.job-request-popup .popup {
  color: $blue-primary;
  width: 87.1875rem;
  h3 {
    display: none;
  }
  .popup-header {
    display: flex;
    justify-content: space-between;
    .lang-switcher {
      text-transform: uppercase;
      .langs-content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .selected-lang {
        cursor: pointer;

        width: 1.875rem;
        height: 1.875rem;
        background-color: #6956d6;
        border-radius: 50%;
        color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .langs-options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        transition: opacity 0.3s ease-in;
        top: -0.3rem;
        right: calc(100% + 1.5625rem);
        width: 13.7rem;
        font-size: 0.8125rem;
        padding: 0.3rem 0;

        .lang {
          width: 1.875rem;
          height: 1.875rem;
          cursor: pointer;
          font-weight: 300;
          user-select: none;
          border: 0.0625rem solid #6956d6;
          border-radius: 50%;
          opacity: 0.5;

          @include centerElement;
        }
      }
    }
    h2 {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
// .show {
//   opacity: 1 !important;
//   transition: opacity 0.3s ease-in;
//   .lang {
//     animation: langIn 0.7s forwards;
//     @keyframes langIn {
//       from {
//         transform: translateX(2rem);
//       }
//       to {
//         transform: translateX(0);
//       }
//     }
//   }
// }

.top-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
  padding-bottom: 0.625rem;
  margin-left: 2.5rem;
  ul {
    list-style: none;

    li.route {
      margin-left: 1.875rem;
      display: inline-flex;
      &:first-child {
        margin-left: 0;
      }
      span {
        font-size: 0.9375rem;
        color: $blue-primary;
        font-weight: 500;
        line-height: 1.1875rem;
        cursor: pointer;
        position: relative;

        &.active {
          &::after {
            content: "";
            width: 100%;
            height: 0.25rem;
            background: $green-primary;
            z-index: 999;
            position: absolute;
            display: block;
            bottom: -0.625rem;
            left: 0;
          }
        }
      }
    }
  }
}
</style>