var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.offerToolbar)?_c('editor',{attrs:{"api-key":_vm.key,"value":_vm.value,"placeholder":_vm.title,"init":{
            inline: true,
            height: 300,
            branding: false,
            menubar: false,
            statusbar: false,
            toolbar:
                'undo redo | styleselect | bold italic |  fontsizeselect fontselect | alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist outdent indent | link  | ' +
                'forecolor backcolor emoticons | help ',
            plugins: [
                'advlist autoresize autolink link image lists charmap print preview hr anchor pagebreak',
                ' searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
                'table emoticons template paste help',
            ],
            branding: true,
            skin: 'borderless',
        }},on:{"input":_vm.inputHandler}}):_c('editor',{attrs:{"api-key":_vm.key,"value":_vm.value,"placeholder":_vm.title,"init":{
            inline: true,
            height: 300,
            branding: false,
            menubar: false,
            statusbar: false,
            toolbar: 'bold italic |  fontselect | ' + 'forecolor backcolor emoticons',
            branding: true,
            skin: 'borderless',
        }},on:{"input":_vm.inputHandler}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }