<template>
  <div class="grid-view">
    <custom-loader v-if="loaderActive" />
    <div class="grid-view-header">
      <div class="count-content">
        <div>
          Total Job <span>{{ jobCount }}</span>
        </div>
        <div>
          Total Employee <span>{{ employeesCount }}</span>
        </div>
      </div>
      <div
        class="add-top-content"
        v-if="Object.keys(structureData).length < 1 && !userPage"
        @click="addTopVisible = true"
      >
        <span>Build Structure</span>
      </div>
      <div class="right-side-header">
        <form class="search-form">
          <input placeholder="Search ..." v-model="searchTerm" />
        </form>
      </div>
    </div>
    <div class="tree-container">
      <structureTree
        :data="structureData"
        @director-button-clicked="showDirectorPopup"
        @department-button-clicked="showDepartmentPopup"
        @division-button-clicked="showDivisionPopup"
        @sub-division-button-clicked="showSubDivPopup"
        @job-button-clicked="showJobPopup"
        @edit-button-clicked="showEditPopup"
        @delete-button-clicked="deleteStructureNode"
        @redirect="redirectToTeams"
        @delete-job="deleteJob"
        :userPage="userPage"
        :search="searchTerm"
      />
    </div>
    <custom-popup
      :title="!editMode ? 'Add Job' : 'Edit Job'"
      :visible="jobPopupVisible"
      @close="(jobPopupVisible = false), (isOpened = false), (editMode = false)"
    >
      <div class="inputs-wrapper">
        <choose-jobs
          style="margin-left: 0"
          :isOpened="isOpened"
          :selectedJobs="selectedJobs"
        />
      </div>
      <button v-if="!editMode" class="save-button" @click="assignJob">
        Save
      </button>
      <button v-else class="save-button" @click="updateStructure">Save</button>
    </custom-popup>
    <custom-popup
      :title="!editMode ? 'Add Sub_Division' : 'Edit Sub_Division'"
      :visible="subDivPopupVisible"
      @close="
        (subDivPopupVisible = false), (isOpened = false), (editMode = false)
      "
    >
      <div class="inputs-wrapper">
        <custom-input
          v-model="subDivisionName"
          title="Sub Division Name"
          @clear="subDivisionName = ''"
        />
        <choose-persons
          :allPersons="allPersons"
          @person-clicked="personClickHanlder"
          :isOpened="isOpened"
          :title="'Select Sub_Division Lead'"
        />
      </div>
      <button v-if="!editMode" class="save-button" @click="createSubDivision">
        Save
      </button>
      <button v-else class="save-button" @click="updateStructure">Save</button>
    </custom-popup>
    <custom-popup
      :title="!editMode ? 'Add Division' : 'Edit Division'"
      :visible="divisionPopup"
      @close="(divisionPopup = false), (isOpened = false), (editMode = false)"
    >
      <div class="inputs-wrapper">
        <custom-input
          v-model="divisionName"
          title="Division Name"
          @clear="divisionName = ''"
        />
        <choose-persons
          :allPersons="allPersons"
          @person-clicked="personClickHanlder"
          :isOpened="isOpened"
          :title="'Select Division Lead'"
        />
      </div>
      <button v-if="!editMode" class="save-button" @click="createDivision">
        Save
      </button>
      <button v-else class="save-button" @click="updateStructure">Save</button>
    </custom-popup>
    <custom-popup
      :title="!editMode ? 'Add Department' : 'Edit Department'"
      class="department-popup"
      :visible="departmentPopupVisible"
      @close="
        (departmentPopupVisible = false), (isOpened = false), (editMode = false)
      "
    >
      <div class="departments-inputs-wrapper">
        <div class="flex">
          <custom-input
            v-model="departmentName"
            title="Name"
            @clear="departmentName = ''"
          />
          <choose-persons
            :allPersons="allPersons"
            @person-clicked="depHeadPersonClick"
            :isOpened="isOpened"
            :title="'Select Head/s Of Department'"
          />
        </div>
        <div class="add-language" @click="showLanguages = !showLanguages, language = '', translation = ''">
          <img src="@/assets/icons/icon-global-refresh.svg" />
        </div>
        <div v-if="showLanguages" class="flex flex-language">
          <custom-select
              title="Language" 
              class="langauge select"
              :options="langList"
              v-model="language"
              :clearVisible="true"
          />
          <custom-input
            v-model="translation"
            title="Translation"
            @clear="translation = ''"
          />
        </div>
        <div class="flex">          
          <date-picker
            title="Start Date"
            v-model="departmentStartTime"
            @clear="departmentStartTime = ''"
          />
           <choose-persons
            :allPersons="allPersonsForDeputy"
            @person-clicked="depDeputyHeadPersonClick"
            :title="'Select Deputy Head/s Of Dep'"
            :isOpened="isOpened"
          />
        </div>
      </div>
      <button v-if="!editMode" class="save-button" @click="addDepartment">
        Save
      </button>
      <button v-else class="save-button" @click="updateStructure">Save</button>
    </custom-popup>
    <custom-popup
      :title="!editMode ? 'Add Director' : 'Edit Director'"
      class="director-popup"
      :visible="directorPopupVisible"
      @close="
        (directorPopupVisible = false), (isOpened = false), (editMode = false)
      "
    >
      <div class="inputs-wrapper">
        <custom-input
          v-model="directorName"
          title="Name"
          @clear="directorName = ''"
        />
        <choose-persons
          :isOpened="isOpened"
          :allPersons="allPersons"
          @person-clicked="personClickHanlder"
        />
      </div>
      <button v-if="!editMode" class="save-button" @click="addDirector">
        Save
      </button>
      <button v-else class="save-button" @click="updateStructure">Save</button>
    </custom-popup>
    <custom-popup
      @close="(addTopVisible = false), (isOpened = false), (editMode = false)"
      :title="!editMode ? 'Add top managment' : 'Edit top managment'"
      :visible="addTopVisible"
    >
      <div class="inputs-wrapper">
        <custom-input
          v-model="topManagmentName"
          title="Name"
          @clear="newDepartment.name = ''"
        />
        <choose-persons
          :allPersons="allPersons"
          @person-clicked="personClickHanlder"
          :isOpened="isOpened"
        />
      </div>
      <button v-if="!editMode" class="save-button" @click="createTopManagement">
        Save
      </button>
      <button v-else class="save-button" @click="updateStructure">Save</button>
    </custom-popup>
    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
    <!-- <div class="managment-content">
      <h1>top management</h1>
      <router-link :to="{ path: '/TeamsUsers', query: { jobId: 27 } }">
        <h2>CEO</h2>
      </router-link>
    </div>
    <div class="departments-content">
      <div class="leftside">
        <div
          v-for="values in filteredDepartmentDetails"
          :key="values.DepartmentID"
          class="department-box"
        >
          <router-link
            tag="div"
            :to="{
              path: '/TeamsUsers',
              query: { departmentId: values.DepartmentID },
            }"
            class="department-name"
          >
            <span
              class="dep-name"
              :class="{ 'smaller-version': values.DepartmentName.length > 25 }"
              >{{ values.DepartmentName }}</span
            >

            <span
              :class="{ 'smaller-version': values.DepartmentName.length > 25 }"
              class="people-count"
              v-if="values.DepartmentPersonCount > 1"
            >
              {{ values.DepartmentPersonCount }}
            </span>
          </router-link>
          <div class="job-name-box">
            <router-link
              tag="span"
              class="job-name"
              v-for="job in filterJobsWithSearch(values.Jobs)"
              :key="job.jobID"
              :to="{ path: '/TeamsUsers', query: { jobId: job.jobID } }"
              >{{ job.JobName }}</router-link
            >
          </div>
        </div>
      </div>
      <div class="rightside">
        <template v-for="values in filteredJobsWithoutDepartments">
          <router-link
            v-if="values.jobID != hardcodedCEOID"
            tag="div"
            :to="{ path: '/TeamsUsers', query: { jobId: values.jobID } }"
            :key="values.jobID"
            class="department-name"
          >
            <span
              :class="{ 'smaller-version': values.JobName.length > 25 }"
              class="dep-name"
              >{{ values.JobName }}</span
            >
            <span
              :class="{ 'smaller-version': values.JobName.length > 25 }"
              class="people-count"
              v-if="values.JobPersonCount > 1"
              >{{ values.JobPersonCount }}</span
            >
          </router-link>
        </template>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance.js";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup.vue";
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput.vue";
// import InputSearch from "@/components/ReusableComponents/InputSearch/InputSearch.vue";
import choosePersons from "@/components/ReusableComponents/ChoosePersons/choosePersons.vue";
import structureTree from "./structureTree.vue";
import chooseJobs from "./chooseJobs.vue";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import CustomLoader from "@/components/ReusableComponents/Loader/CustomLoader.vue";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue"

export default {
  created() {
    this.getData();
    // this.getDepartmentPersons();
    // this.getPersonsWithoutDep();
    this.getPersons();
    this.getStructureData();
    this.getLanguages()
  },
  data() {
    return {
      departmentDetails: [],
      depsInfo: [],
      departmentPeople: [],
      jobsWithoutDepartments: [],
      addTopVisible: false,
      directorPopupVisible: false,
      jobPopupVisible: false,
      departmentPopupVisible: false,
      subDivPopupVisible: false,
      peopleWithoutDep: [],
      allPersons: [],
      selectedPersons: [],
      selectedJobs: [],
      isOpened: false,
      editMode: false,
      selectedDepHeads: [],
      selectedDeputyDepHeads: [],
      allPersonsForDeputy: [],
      counts: [],
      clickedParentIdToEdit: "",
      divisionPopup: false,
      newName: "",
      topManagmentName: "",
      departmentName: "",
      divisionName: "",
      subDivisionName: "",
      directorName: "",
      departmentStartTime: "",
      searchTerm: "",
      hardcodedCEOID: 27,
      clickedParentId: "",
      structureData: {},
      snackbarPurpose: "",
      snackbarText: "",
      snackbarVisible: false,
      loaderActive: false,

      langList:[],
      showLanguages: false,
      language: '',
      translation: '',
    };
  },
  components: {
    CustomPopup,
    CustomInput,
    choosePersons,
    DatePicker,
    structureTree,
    chooseJobs,
    CustomSnackbar,
    CustomLoader,
    CustomSelect,
  },
  props: {
    userPage: Boolean,
  },

  methods: {
    async redirectToTeams(el) {
      let stringId = el.split("_")[1];
      let numberId = parseInt(el.split("_")[0]);
      this.clickedParentIdToEdit = el;
      if (stringId == "top") {
        await this.getTopInfo();
      } else if (stringId == "director") {
        await this.getDirectorsInfo();
      } else if (stringId == "department") {
        await this.getDepInfo();
      } else if (stringId == "division") {
        await this.getDivInfo();
      } else if (stringId == "subDivision") {
        await this.getSubDivInfo();
      } else if (stringId == "job") {
        await this.getJobInfo();
      }
      this.$router.push({
        name: "Teams & Users",
        params: {
          data: this.depsInfo,
        },
      });
    },
    getJobsAndEmployeesCount() {
      axios.get("Company/GetJobsAndEmployeesCount").then((resp) => {
        this.counts = resp.data.Value;
      });
    },
    getLanguages(){
        axios.get("openposition/GetLanguages")
        .then(resp => {
            this.langList = resp.data.Value.map(lang => ({
                title: lang.Code,
                value: lang.LanguageID
            }))
            
        })
    },
    getStructureData() {
      this.loaderActive = true;
      axios.get("departments/GetCompanyStructure").then((resp) => {
        this.loaderActive = false;
        const data = resp.data.Value.TopManagements[0];
        const topChildrens = [];

        let topDepartments = data.Departments.map((el) => {
          let jobs = el.Jobs.map((job) => {
            return {
              id: job.JobID + "_job",
              name: job.Name,
            };
          });

          jobs.forEach((el, index) => {
            let nextElement = jobs[index + 1];
            el.children = nextElement != undefined ? [nextElement] : [];
          });

          jobs = jobs[0];
          jobs = jobs != undefined ? [jobs] : [];

          let divisions = el.Divisions.map((el) => {
            let divisionJob = el.Jobs.map((job) => {
              return {
                id: job.JobID + "_job",
                name: job.Name,
              };
            });
            divisionJob.forEach((el, index) => {
              let nextElement = divisionJob[index + 1];
              el.children = nextElement != undefined ? [nextElement] : [];
            });
            divisionJob = divisionJob[0];
            divisionJob = divisionJob != undefined ? [divisionJob] : [];

            let subDivision = el.SubDivisions.map((el) => {
              let subDivisionJobs = el.Jobs.map((job) => {
                return {
                  id: job.JobID + "_job",
                  name: job.Name,
                };
              });

              subDivisionJobs.forEach((el, index) => {
                let nextElement = subDivisionJobs[index + 1];
                el.children = nextElement != undefined ? [nextElement] : [];
              });
              subDivisionJobs = subDivisionJobs[0];
              subDivisionJobs =
                subDivisionJobs != undefined ? [subDivisionJobs] : [];

              return {
                id: el.SubDivisionID + "_subDivision",
                name: el.Name,
                children: subDivisionJobs,
              };
            });
            return {
              id: el.DivisionID + "_division",
              name: el.Name,
              children: [...subDivision, ...divisionJob],
            };
          });
          return {
            id: el.DepartmentID + "_department",

            name: el.Name,
            children: [...divisions, ...jobs],
          };
        });
        let mappedTopJobs = data.Jobs.map((el) => {
          return {
            id: el.JobID + "_job",
            name: el.Name,
          };
        });
        mappedTopJobs.forEach((el, index) => {
          let nextElement = mappedTopJobs[index + 1];
          el.children = nextElement != undefined ? [nextElement] : [];
        });

        mappedTopJobs = mappedTopJobs[0];
        mappedTopJobs = mappedTopJobs != undefined ? [mappedTopJobs] : [];

        let directors = data.Directors.map((el) => {
          return {
            id: el.DirectorID + "_director",
            myId: el.DirectorID,
            name: el.Name,
            children: el.Departments.map((dep) => {
              let divisions = dep.Divisions.map((div) => {
                let subDivision = div.SubDivisions.map((subDiv) => {
                  let subDivisionJobs = subDiv.Jobs.map((job) => {
                    return {
                      id: job.JobID + "_job",
                      name: job.Name,
                    };
                  });
                  subDivisionJobs.forEach((el, index) => {
                    let nextElement = subDivisionJobs[index + 1];
                    el.children = nextElement != undefined ? [nextElement] : [];
                  });
                  subDivisionJobs = subDivisionJobs[0];
                  subDivisionJobs =
                    subDivisionJobs != undefined ? [subDivisionJobs] : [];
                  return {
                    name: subDiv.Name,
                    id: subDiv.SubDivisionID + "_subDivision",
                    children: subDivisionJobs,
                  };
                });

                let divisionJob = div.Jobs.map((job) => {
                  return {
                    name: job.Name,
                    id: job.JobID + "_job",
                  };
                });

                divisionJob.forEach((el, index) => {
                  let nextElement = divisionJob[index + 1];
                  el.children = nextElement != undefined ? [nextElement] : [];
                });
                divisionJob = divisionJob[0];
                divisionJob = divisionJob != undefined ? [divisionJob] : [];
                return {
                  id: div.DivisionID + "_division",
                  name: div.Name,
                  children: [...subDivision, ...divisionJob],
                };
              });
              let jobs = dep.Jobs.map((job) => {
                return {
                  id: job.JobID + "_job",
                  name: job.Name,
                };
              });
              jobs.forEach((el, index) => {
                let nextElement = jobs[index + 1];
                el.children = nextElement != undefined ? [nextElement] : [];
              });
              jobs = jobs[0];
              jobs = jobs != undefined ? [jobs] : [];
              return {
                id: dep.DepartmentID + "_department",
                name: dep.Name,
                children: [...divisions, ...jobs],
              };
            }),
          };
        });

        topChildrens.push(...directors, ...topDepartments, ...mappedTopJobs);

        this.structureData = {
          id: data.TopManagementID + "_top",
          name: data.Name,
          children: topChildrens,
        };
      });
      this.getJobsAndEmployeesCount();
    },
    resetSelected() {
      this.allPersons.forEach((el) => {
        if (el.selected) {
          el.selected = false;
        }
      });
    },
    getPersons() {
      axios.get("/Person/GetPersonsForStructureDropDown").then((resp) => {
        this.allPersons = resp.data.Value.map((el) => ({
          title: el.FirstName + " " + el.LastName,
          value: el.ID,
          selected: el.selected,
        }));
        this.allPersons.forEach((el) => {
          this.$set(el, "selected", false);
        });
        this.allPersonsForDeputy = resp.data.Value.map((el) => ({
          title: el.FirstName + " " + el.LastName,
          value: el.ID,
          selected: el.selected,
        }));
        this.allPersonsForDeputy.forEach((el) => {
          this.$set(el, "selected", false);
        });
      });
    },
    async getJobInfo() {
      let numberId = parseInt(this.clickedParentIdToEdit.split("_")[0]);
      const resp = await axios.get(`jobs/JobPersons?JobID=${numberId}`);
      let obj = {
        DepHeads: [],
        DepPersons: resp.data.Value,
      };
      this.depsInfo = obj;
    },
    async getTopInfo() {
      let numberId = parseInt(this.clickedParentIdToEdit.split("_")[0]);
      this.selectedPersons = [];
      const resp = await axios.get(
        `departments/GetTopManagementsPeopleInfo?TopManagementID=${numberId}`
      );

      this.depsInfo = resp.data.Value;

      this.topManagmentName = this.depsInfo.TopManagementName;

      this.depsInfo.DepHeads.forEach((el) => {
        this.selectedPersons.push(el.HeadID);
      });
    },
    async getDirectorsInfo() {
      let numberId = parseInt(this.clickedParentIdToEdit.split("_")[0]);
      this.selectedPersons = [];
      const resp = await axios.get(
        `departments/GetDirectorsPeopleInfo?DirectorID=${numberId}`
      );

      this.depsInfo = resp.data.Value;

      if (this.editMode) {
        this.directorName = this.depsInfo.DirectorName;

        this.depsInfo.DepHeads.forEach((el) => {
          this.selectedPersons.push(el.HeadID);
        });
      }
    },
    async getSubDivInfo() {
      let numberId = parseInt(this.clickedParentIdToEdit.split("_")[0]);
      this.selectedPersons = [];
      const resp = await axios.get(
        `departments/GetSubDivisionsPeopleInfo?SubDivisionID=${numberId}`
      );

      this.depsInfo = resp.data.Value;

      this.subDivisionName = this.depsInfo.SubDivisionName;
      this.depsInfo.DepHeads.forEach((el) => {
        this.selectedPersons.push(el.HeadID);
      });
    },
    async getDivInfo() {
      let numberId = parseInt(this.clickedParentIdToEdit.split("_")[0]);
      this.selectedPersons = [];
      const resp = await axios.get(
        `departments/GetDivisionsPeopleInfo?DivisionID=${numberId}`
      );

      this.depsInfo = resp.data.Value;

      this.divisionName = this.depsInfo.DivisionName;
      this.depsInfo.DepHeads.forEach((el) => {
        this.selectedPersons.push(el.HeadID);
      });
    },
    async getDepInfo() {
      let numberId = parseInt(this.clickedParentIdToEdit.split("_")[0]);
      this.selectedDeputyDepHeads = [];
      this.selectedDepHeads = [];
      const resp = await axios.get(
        `departments/GetDepartmentsPeopleInfo?DepartmentID=${numberId}`
      );

      this.depsInfo = resp.data.Value;

      this.departmentName = this.depsInfo.DepartmentName;
      this.departmentStartTime = this.depsInfo.DepartmentStartTime;
      this.depsInfo.DepHeads.forEach((el) => {
        if (el.HeadType === 1) {
          this.selectedDepHeads.push(el.HeadID);
        } else {
          this.selectedDeputyDepHeads.push(el.HeadID);
        }
      });
    },
    showEditPopup(el) {
      this.editMode = true;
      this.clickedParentIdToEdit = el;

      let stringId = this.clickedParentIdToEdit.split("_")[1];
      let numberId = parseInt(this.clickedParentIdToEdit.split("_")[0]);

      if (stringId == "top") {
        this.addTopVisible = true;
        this.getTopInfo();
      } else if (stringId == "director") {
        this.getDirectorsInfo();
        this.showDirectorPopup();
      } else if (stringId == "department") {
        this.getDepInfo();
        this.showDepartmentPopup();
      } else if (stringId == "division") {
        this.getDivInfo();
        this.showDivisionPopup();
      } else if (stringId == "subDivision") {
        this.getSubDivInfo();
        this.showSubDivPopup();
      }
    },
    createTopManagement() {
      let arrayToSend = this.selectedPersons.map((el) => {
        return {
          Person: el,
          HeadType: 1,
        };
      });
      axios
        .post("departments/CreateTopManagement", {
          Name: this.topManagmentName,
          Heads: arrayToSend,
        })
        .then((resp) => {
          this.addTopVisible = false;
          this.snackbarVisible = true;
          this.snackbarPurpose = "success";
          this.isOpened = false;
          this.snackbarText = "Successfully saved";
          this.getStructureData();
        });
    },
    showSuccessSnackbar() {
      this.snackbarVisible = true;
      this.snackbarPurpose = "success";
      this.snackbarText = "Successfully saved";
      this.getStructureData();
    },
    showDeleteSnackbar() {
      this.snackbarVisible = true;
      this.snackbarPurpose = "success";
      this.snackbarText = "Successfully deleted";
      this.getStructureData();
    },
    deleteJob(el) {
      let numberId = parseInt(el.split("_")[0]);
      this.$confirm(`Are you sure you want delete this job ?`).then(() => {
        axios
          .post(`departments/RemoveJobFromStructure?JobID=${numberId}`)
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.showDeleteSnackbar();
            }
          });
      });
    },
    updateStructure() {
      let arrayToSend = this.selectedPersons.map((el) => {
        return {
          Person: el,
          HeadType: 1,
        };
      });

      let stringId = this.clickedParentIdToEdit.split("_")[1];
      let numberId = parseInt(this.clickedParentIdToEdit.split("_")[0]);
      if (stringId == "top") {
        axios
          .post("departments/UpdateTopManagement", {
            ID: numberId,
            Name: this.topManagmentName,
            EndTime: null,
            Delete: false,
            Heads: arrayToSend,
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.addTopVisible = false;
              this.showSuccessSnackbar();
            }
          });
      } else if (stringId == "director") {
        axios
          .post("departments/UpdateDirector", {
            ID: numberId,
            Name: this.directorName,
            EndTime: null,
            Delete: false,
            Heads: arrayToSend,
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.directorPopupVisible = false;
              this.showSuccessSnackbar();
            }
          });
      } else if (stringId == "department") {
        let arrayToSendDeps = [];
        this.selectedDepHeads.forEach((el) => {
          arrayToSendDeps.push({
            Person: el,
            HeadType: 1,
          });
        });
        this.selectedDeputyDepHeads.forEach((el) => {
          arrayToSendDeps.push({
            Person: el,
            HeadType: 2,
          });
        });
        axios
          .post("departments/UpdateDepartment", {
            ID: numberId,
            Name: this.departmentName,
            EndTime: null,
            Delete: false,
            ManagementPartType: null,
            ManagementPartID: null,
            StartTime: this.departmentStartTime,
            Heads: arrayToSendDeps,
            translations: this.language ? [
              {
                languageID: this.language,
                objectTranslation: this.translation
              }
            ] : []
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.departmentPopupVisible = false;
              this.showSuccessSnackbar();
            }
          });
      } else if (stringId == "division") {
        axios
          .post("departments/UpdateDivision", {
            ID: numberId,
            Name: this.divisionName,
            EndTime: null,
            Delete: false,
            Heads: arrayToSend,
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.divisionPopup = false;
              this.showSuccessSnackbar();
            }
          });
      } else if (stringId == "subDivision") {
        axios
          .post("departments/UpdateSubDivision", {
            ID: numberId,
            Name: this.subDivisionName,
            EndTime: null,
            Delete: false,
            Heads: arrayToSend,
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.subDivPopupVisible = false;
              this.showSuccessSnackbar();
            }
          });
      }
    },
    deleteStructureNode(el) {
      let stringId = el.split("_")[1];
      let numberId = parseInt(el.split("_")[0]);
      this.$confirm(`Are you sure you want delete ${stringId} ?`).then(() => {
        if (stringId == "top") {
          axios
            .post("departments/UpdateTopManagement", {
              ID: numberId,
              Delete: true,
              Heads: [],
            })
            .then((resp) => {
              if (!resp.data.ErrorMsg) {
                this.showDeleteSnackbar();
              }
            });
        } else if (stringId == "director") {
          axios
            .post("departments/UpdateDirector", {
              ID: numberId,
              Delete: true,
              Heads: [],
            })
            .then((resp) => {
              if (!resp.data.ErrorMsg) {
                this.showDeleteSnackbar();
              }
            });
        } else if (stringId == "department") {
          axios
            .post("departments/UpdateDepartment", {
              ID: numberId,
              Deleted: true,
              Heads: [],
            })
            .then((resp) => {
              if (!resp.data.ErrorMsg) {
                this.showDeleteSnackbar();
              }
            });
        } else if (stringId == "division") {
          axios
            .post("departments/UpdateDivision", {
              ID: numberId,
              Delete: true,
              Heads: [],
            })
            .then((resp) => {
              if (!resp.data.ErrorMsg) {
                this.showDeleteSnackbar();
              }
            });
        } else if (stringId == "subDivision") {
          axios
            .post("departments/UpdateSubDivision", {
              ID: numberId,
              Delete: true,
              Heads: [],
            })
            .then((resp) => {
              if (!resp.data.ErrorMsg) {
                this.showDeleteSnackbar();
              }
            });
        }
      });
    },
    assignJob() {
      let stringId = this.clickedParentId.split("_")[1];
      let numberId = parseInt(this.clickedParentId.split("_")[0]);
      let jobsArray = this.selectedJobs.map((el) => {
        return {
          JobID: el,
        };
      });
      if (stringId == "top") {
        axios
          .post("departments/AssignTopManagementJob", {
            TopManagement: numberId,
            Jobs: jobsArray,
          })
          .then((resp) => {
            this.snackbarVisible = true;
            this.snackbarPurpose = "success";
            this.snackbarText = "Successfully saved";
            this.jobPopupVisible = false;
            this.isOpened = false;
            this.getStructureData();
          });
      } else if (stringId == "director") {
        axios
          .post("departments/AssignDirectorJob", {
            Director: numberId,
            Jobs: jobsArray,
          })
          .then((resp) => {
            this.directorPopupVisible = false;
            this.snackbarVisible = true;
            this.snackbarPurpose = "success";
            this.snackbarText = "Successfully saved";
            this.jobPopupVisible = false;
            this.isOpened = false;
            this.getStructureData();
          });
      } else if (stringId == "department") {
        axios
          .post("departments/AssignDepartmentJob", {
            DepartmentID: numberId,
            Jobs: jobsArray,
          })
          .then((resp) => {
            this.departmentPopupVisible = false;
            this.snackbarVisible = true;
            this.snackbarPurpose = "success";
            this.snackbarText = "Successfully saved";
            this.jobPopupVisible = false;
            this.isOpened = false;
            this.getStructureData();
          });
      } else if (stringId == "division") {
        axios
          .post("departments/AssignDivisionJob", {
            DivisionID: numberId,
            Jobs: jobsArray,
          })
          .then((resp) => {
            this.divisionPopup = false;
            this.snackbarVisible = true;
            this.snackbarPurpose = "success";
            this.jobPopupVisible = false;
            this.isOpened = false;
            this.snackbarText = "Successfully saved";
            this.getStructureData();
          });
      } else if (stringId == "subDivision") {
        axios
          .post("departments/AssignSubDivisionJob", {
            SubDivisionID: numberId,
            Jobs: jobsArray,
          })
          .then((resp) => {
            this.subDivPopupVisible = false;
            this.snackbarVisible = true;
            this.jobPopupVisible = false;
            this.isOpened = false;
            this.snackbarPurpose = "success";
            this.snackbarText = "Successfully saved";
            this.getStructureData();
          });
      }
    },

    createSubDivision() {
      let arrayToSend = this.selectedPersons.map((el) => {
        return {
          Person: el,
          HeadType: 1,
        };
      });
      axios
        .post("departments/CreateSubDivision", {
          Name: this.subDivisionName,
          Division: this.clickedParentId,
          Heads: arrayToSend,
        })
        .then((resp) => {
          this.subDivPopupVisible = false;
          this.snackbarVisible = true;
          this.isOpened = false;
          this.snackbarPurpose = "success";
          this.snackbarText = "Successfully saved";
          this.getStructureData();
        });
    },
    createDivision() {
      let arrayToSend = this.selectedPersons.map((el) => {
        return {
          Person: el,
          HeadType: 1,
        };
      });
      axios
        .post("departments/CreateDivision", {
          Name: this.divisionName,
          Department: parseInt(this.clickedParentId),
          Heads: arrayToSend,
        })
        .then((resp) => {
          this.divisionPopup = false;
          this.snackbarVisible = true;
          this.isOpened = false;
          this.snackbarPurpose = "success";
          this.snackbarText = "Successfully saved";

          this.getStructureData();
        });
    },

    showDirectorPopup(el) {
      this.clickedParentId = el;
      this.selectedPersons = [];
      this.directorName = "";
      this.resetSelected();
      this.directorPopupVisible = true;
      this.isOpened = true;
    },
    showDepartmentPopup(el) {
      this.clickedParentId = el;
      this.selectedDepHeads = [];
      this.departmentName = "";
      this.departmentStartTime = "";
      this.resetSelected();
      this.selectedDeputyDepHeads = [];
      this.selectedPersons = [];
      this.isOpened = true;
      this.departmentPopupVisible = true;
    },
    showDivisionPopup(el) {
      this.clickedParentId = el;
      this.selectedPersons = [];
      this.divisionName = "";
      this.resetSelected();
      this.isOpened = true;
      this.divisionPopup = true;
    },
    showSubDivPopup(el) {
      this.clickedParentId = el;
      this.selectedPersons = [];
      this.subDivisionName = "";
      this.resetSelected();
      this.subDivPopupVisible = true;
      this.isOpened = true;
    },
    showJobPopup(el) {
      this.clickedParentId = el;
      this.selectedJobs = [];

      this.jobPopupVisible = true;
      this.isOpened = true;
    },

    addDirector() {
      let arrayToSend = this.selectedPersons.map((el) => {
        return {
          Person: el,
          HeadType: 1,
        };
      });
      axios
        .post("departments/CreateDirector", {
          Name: this.directorName,
          TopManagement: parseInt(this.clickedParentId),
          Heads: arrayToSend,
        })
        .then((resp) => {
          this.directorPopupVisible = false;
          this.snackbarVisible = true;
          this.isOpened = false;
          this.snackbarPurpose = "success";
          this.snackbarText = "Successfully saved";
          this.getStructureData();
        });
    },
    addDepartment() {
      let parentiD = this.clickedParentId.split("_")[1];
      let arrayTosend = [];
      this.selectedDepHeads.forEach((el) => {
        arrayTosend.push({
          Person: el,
          HeadType: 1,
        });
      });
      this.selectedDeputyDepHeads.forEach((el) => {
        arrayTosend.push({
          Person: el,
          HeadType: 2,
        });
      });

      axios
        .post("departments/CreateDepartment", {
          Name: this.departmentName,
          StartTime: this.departmentStartTime,
          ManagementPartType: parentiD == "top" ? 5 : 4,
          ManagementPartID: parseInt(this.clickedParentId.split("_")[0]),
          Heads: arrayTosend,
          translations: this.language ? [
            {
              languageID: this.language,
              objectTranslation: this.translation
            }
          ] : []
        })
        .then((resp) => {
          this.departmentPopupVisible = false;
          this.snackbarVisible = true;
          this.snackbarPurpose = "success";
          this.snackbarText = "Successfully saved";
          this.getStructureData();
        });
    },

    getData() {
      axios.get("Departments/GetDepartmentJobsDetails").then((resp) => {
        this.departmentDetails = resp.data.Value.departmentJobs;

        this.jobsWithoutDepartments = resp.data.Value.JobsWithoutDepartment;
      });
    },
    personClickHanlder(el) {
      el.selected = !el.selected;
      const exist = this.selectedPersons.includes(el.value);
      if (exist) {
        this.selectedPersons = this.selectedPersons.filter(
          (element) => element != el.value
        );
      } else {
        this.selectedPersons.push(el.value);
      }
    },
    depHeadPersonClick(el) {
      el.selected = !el.selected;
      const exist = this.selectedDepHeads.includes(el.value);
      if (exist) {
        this.selectedDepHeads = this.selectedDepHeads.filter(
          (element) => element != el.value
        );
      } else {
        this.selectedDepHeads.push(el.value);
      }
    },
    depDeputyHeadPersonClick(el) {
      el.selected = !el.selected;
      const exist = this.selectedDeputyDepHeads.includes(el.value);
      if (exist) {
        this.selectedDeputyDepHeads = this.selectedDeputyDepHeads.filter(
          (element) => element != el.value
        );
      } else {
        this.selectedDeputyDepHeads.push(el.value);
      }
    },
    getSelectedJobs(arr) {
      this.selectedJobs = arr;
    },
    getDepartmentHeads(arr) {
      this.selectedDepHeads = arr;
    },
    getDeputyDepHeads(arr) {
      this.selectedDeputyDepHeads = arr;
    },

    getDepartmentPersons() {
      axios
        .get("departments/GetDepartmentPeople", {
          params: {
            department: this.$route.query.departmentId,
          },
        })
        .then((response) => {
          this.departmentPeople = response.data.Value;
        });
    },
    getPersonsWithoutDep() {
      axios.get("departments/GetJobPersonsWithoutDepartment").then((resp) => {
        this.peopleWithoutDep = resp.data.Value;
      });
    },

    employeeCount() {
      var sum = 0;
      for (var i = 0; i < this.jobsWithoutDepartments.length; i++) {
        sum += this.jobsWithoutDepartments[i].JobPersonCount;
      }
      return sum + this.departmentPeople.length;
    },
    filterJobsWithSearch(jobs) {
      if (!this.searchTerm) return jobs;
      const loweredSearchTerm = this.searchTerm.toLowerCase();
      return jobs.filter((job) => {
        let jobNameFound = job.JobName.toLowerCase().includes(
          loweredSearchTerm
        );
        let peopleFound = this.departmentPeople.some((p) => {
          return (
            (p.FirstName + " " + p.LastName)
              .toLowerCase()
              .includes(loweredSearchTerm) && p.JobID == job.jobID
          );
        });
        return jobNameFound || peopleFound;
      });
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    jobCount() {
      if (!this.counts.length) return;
      return this.counts[0].JobsCount;
    },
    employeesCount() {
      if (!this.counts.length) return;
      return this.counts[0].EmployeesCount;
    },
    personOptions() {
      return this.allPersons.map((el) => ({
        title: el.FirstName + " " + el.LastName,
        value: el.ID,
        selected: el.selected,
      }));
    },
    filteredDepartmentDetails() {
      if (!this.searchTerm) return this.departmentDetails;
      const loweredSearchTerm = this.searchTerm.toLowerCase();
      return this.departmentDetails.filter((dep) => {
        let depNameFound = dep.DepartmentName.toLowerCase().includes(
          loweredSearchTerm
        );
        let depJobNameFound = dep.Jobs.some((job) =>
          job.JobName.toLowerCase().includes(loweredSearchTerm)
        );
        let depPeopleFound = this.departmentPeople.some(
          (p) =>
            (p.FirstName + " " + p.LastName)
              .toLowerCase()
              .includes(loweredSearchTerm) && p.DepartmentID == dep.DepartmentID
        );
        return depNameFound || depJobNameFound || depPeopleFound;
      });
    },
    filteredJobsWithoutDepartments() {
      if (!this.searchTerm) return this.jobsWithoutDepartments;
      const loweredSearchTerm = this.searchTerm.toLowerCase();
      return this.jobsWithoutDepartments.filter((job) => {
        let jobNameFound = job.JobName.toLowerCase().includes(
          loweredSearchTerm
        );
        let personFound = this.peopleWithoutDep.some(
          (p) =>
            (p.FirstName + " " + p.LastName)
              .toLowerCase()
              .includes(loweredSearchTerm) && p.JobID == job.jobID
        );
        return jobNameFound || personFound;
      });
    },
  },
  watch: {
    selectedPersons() {
      this.allPersons.forEach((el) => {
        this.selectedPersons.forEach((id) => {
          if (id == el.value) {
            el.selected = true;
          }
        });
      });
    },
    selectedDepHeads() {
      this.allPersons.forEach((el) => {
        this.selectedDepHeads.forEach((id) => {
          if (id == el.value) {
            el.selected = true;
          }
        });
      });
    },
    selectedDeputyDepHeads() {
      this.allPersonsForDeputy.forEach((el) => {
        this.selectedDeputyDepHeads.forEach((id) => {
          if (id == el.value) {
            el.selected = true;
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-view {
  // padding-left: 80 * $rem;
  // padding-right: 80 * $rem;

  padding-top: 30 * $rem;
}
// .transparent {
//   background: transparent;
//   color: transparent !important;
//   pointer-events: none;
// }
.add-top-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 114px;
  height: 36px;
  border-radius: 6px;
  background: #3f46bf;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;

  color: #fff;
}
::v-deep .overlay .popup {
  height: auto;
  overflow: visible;
  width: auto;
}
.inputs-wrapper {
  display: flex;
  height: calc(100% - 54px);
  div {
    margin-left: 30px;
  }
}
.departments-inputs-wrapper {
  
  .flex {
    width: 30rem;
    display: flex;
    justify-content: space-between;
    label {
      display: block;
      &:last-child {
        margin-bottom: 0;
      }
    }
    div {
      display: block;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .langauge {
      width: 12.3125rem;
    }
  }
  .flex-language {
    margin-bottom: 20px;
    label {
      width: 15.625rem;
    }
  }
  .add-language {
    margin: 1.25rem 0;
    cursor: pointer;
  }
}

.save-button {
  width: 60px;
  height: 30px;
  background-color: #8a91c7;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15 * $rem;
  border-radius: 3 * $rem;
  margin-left: auto;
  margin-top: 30px;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }
}
.add-button {
  display: flex;
  align-items: center;
  color: #9696b9;
  font-size: 14px;
  margin-right: 20px;
  .add-new {
    margin-right: 10px;
  }
  cursor: pointer;
  .add-new {
    border-radius: 0.1875rem;
    width: 2.625rem;
    height: 2.3125rem;
    margin-left: 0.25rem;
    position: relative;
    cursor: pointer;
    background: rgba($blue-primary, 0.6);

    &::before {
      content: "";
      position: absolute;
      width: 1.4375rem;
      height: 0.125rem;
      top: 1.125rem;
      left: 0.625rem;
      background-color: #fff;
    }

    &::after {
      content: "";
      position: absolute;
      height: 1.4375rem;
      width: 0.125rem;
      top: 0.4375rem;
      left: 1.3125rem;
      background-color: #fff;
    }
  }
}
.grid-view-header {
  position: relative;
  display: flex;
  justify-content: space-between;

  align-items: center;
  padding-left: 80px;
  padding-right: 80px;
  .count-content {
    color: $blue-primary;
    font-size: 17px;
  }
  .right-side-header {
    // margin-left: auto;
    // display: flex;
    // margin-left: auto;
  }
}
.managment-content {
  height: 115 * $rem;
  background-color: #ffffff;
  color: $blue-primary;
  padding-left: 100 * $rem;
  padding-right: 100 * $rem;
  border-radius: 6px;
  -webkit-box-shadow: 4px 8px 8px -3px rgba(40, 53, 147, 0.08);
  -moz-box-shadow: 4px 8px 8px -3px rgba(40, 53, 147, 0.08);
  box-shadow: 4px 8px 8px -3px rgba(40, 53, 147, 0.08);

  a {
    text-decoration: none;
  }

  h2 {
    font-size: 22 * $rem;
    font-weight: 700;
    text-align: center;
    margin-top: 15 * $rem;
    color: $blue-primary;
  }
  h1 {
    font-size: 22 * $rem;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    padding-top: 25 * $rem;
    color: $blue-primary;
  }
  .teams {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 18px;
  }
}

.departments-content {
  display: flex;

  .leftside {
    width: 1335 * $rem;

    .department-name {
      height: 3.75rem;
      font-size: 18px;
      color: #ffffff;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(40, 53, 147, 0.6);
      width: 100%;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
    }
  }
  .rightside {
    .department-name {
      height: 3.7rem;
      font-size: 18px;
      color: #ffffff;
      font-weight: 500;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      background: rgba(40, 53, 147, 0.6);
      width: 260px;
      border-radius: 6px;
      margin-top: 20 * $rem;
      cursor: pointer;
      position: relative;
    }
  }
}
.department-box {
  width: calc((100% - 80px) / 4);
  height: 19rem;
  margin-right: 20px;
  border-radius: 6px;
  text-align: center;
  box-shadow: 4px 8px 8px -3px rgba(40, 53, 147, 0.08);
  border-radius: 6px;
  overflow: hidden;
  margin-top: 20 * $rem;
  display: inline-block;
  .team-name {
    color: white;
  }
  .job-name {
    display: block;
    padding-top: 30 * $rem;
    color: $blue-primary;
    font-size: 15px;
    font-weight: 300;
    cursor: pointer;
    &:hover {
      font-weight: 400;
    }
  }

  .job-name-box {
    height: 15.25rem;
    background-color: #ffffff;
    overflow-y: scroll;

    &:last-child {
      padding-bottom: 30px;
    }
  }
}
form {
  display: flex;
  margin-left: auto;
  height: 38 * $rem;

  input {
    outline: none;
    border: none;
    border-radius: 3 * $rem;
    padding-left: 23 * $rem;
    font-size: 14 * $rem;
    color: $blue-primary;
    background-color: #f8f8fe;
    width: 272 * $rem;

    &::placeholder {
      color: rgba($blue-primary, 0.6);
    }
  }
}
.people-count {
  font-size: 16px;
  color: #babae5;
  position: absolute;
  right: 15px;
  top: 7px;
}
.smaller-version {
  font-size: 15px;
  right: 5px;
  top: 5px;
}
.dep-name {
  padding-right: 5px;
}
.noPadding {
  padding-left: 0;
}
</style>
