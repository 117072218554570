export default class RandomColor{
    constructor(percent){
        this.color = this.generateRandomColor()
        this.amount = percent
    }

    generateRandomColor(){
        return '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
    }
    adjustColor(amount) {
        return '#' + this.color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
    }
}