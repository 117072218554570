<template>
  <div class="filter-container" @click.stop v-if="visible">
    <div class="filter-wrapper">
      <p>SORT</p>
      <div class="sorters-container">
        <div
          class="single-sorter"
          @click="
            (sortSettings.ascSelected = !sortSettings.ascSelected),
              (sortSettings.descSelected = false)
          "
        >
          <div
            :class="{ selected: sortSettings.ascSelected }"
            class="dot"
          ></div>
          <span>Asc</span>
        </div>
        <div
          @click="
            (sortSettings.descSelected = !sortSettings.descSelected),
              (sortSettings.ascSelected = false)
          "
          class="single-sorter"
        >
          <div
            :class="{ selected: sortSettings.descSelected }"
            class="dot"
          ></div>
          <span>Desc</span>
        </div>
      </div>
    </div>
    <div class="filter-wrapper">
      <p>LIMIT RANGE</p>
      <div class="inputs-wrapper">
        <input v-model="sortSettings.from" placeholder="From" />
        <input v-model="sortSettings.to" placeholder="To" />
      </div>
    </div>
    <div class="sort-button">
      <div @click="sortHandler">
        <span>SORT</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    // MultiRangeSlider,
  },
  props: {
    visible: Boolean,
    title: String,
  },
  data() {
    return {
      sortSettings: {
        to: "",
        from: "",
        ascSelected: false,
        descSelected: false,
        key: this.title,
      },
    };
  },
  methods: {
    sortHandler() {
      this.$emit("sort-options", this.sortSettings);
      this.sortSettings.to = "";
      this.sortSettings.from = "";
      // this.sortSettings.ascSelected = false;
      // this.sortSettings.ascSelected = false;
    },
    UpdateValues(e) {
      this.from = e.minValue;
      this.to = e.maxValue;
    },
  },
};
</script>

<style scoped lang="scss">
.filter-container {
  width: 220px;
  height: 170px;
  background-color: #6956d6;
  border-radius: 6px;
  padding: 15px;
  border: 1px solid #1de9b6;
  .sort-button {
    display: flex;
    justify-content: flex-end;
    > div {
      cursor: pointer;
      background-color: #1de9b6;
      font-weight: 400;
      border-radius: 3px;
      color: #ffffff;
      padding: 3px 10px;
      display: inline-block;
    }
  }

  .filter-wrapper {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 10px;
    }
    .inputs-wrapper {
      display: flex;
      justify-content: space-between;
      input {
        outline: none;
        border: 1px solid #1de9b6;
        background-color: transparent;
        width: 45%;
        padding-left: 3px;
        height: 25px;
        border-radius: 2px;
        font-size: 13px;
        color: #ffffff;
        font-weight: 300;
        &::placeholder {
          color: #959bc9;
        }
      }
    }
    .sorters-container {
      display: flex;
      //   justify-content: space-between;
    }
    .single-sorter {
      display: flex;
      align-items: center;
      margin-right: 50px;
      cursor: pointer;
      .dot {
        height: 13px;
        width: 13px;
        border-radius: 100%;
        border: 1px solid #959bc9;
        margin-right: 5px;
        &.selected {
          background: #1de9b6;
        }
      }
      span {
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
  p {
    font-size: 13px;
    color: #959bc9;
    font-weight: 500;
    margin-bottom: 8px;
  }
}
::v-deep.multi-range-slider {
  box-shadow: unset;
  border: 0;
  padding: 0;
  .label {
    // display: none;
    color: #ffffff;
    margin-top: 5px;
  }
  .thumb {
    .min-value,
    .max-value {
      background-color: red;
      font-size: 12px;
      box-shadow: unset;
      background-color: #1de9b6;
      width: 25px;
      height: 25px;
      bottom: 16px;
      line-height: 27px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
    }
    &::before {
      box-shadow: unset;
      border: 0;
      width: 15px;
      height: 15px;
      top: 54%;
    }
  }
  .ruler {
    display: none;
  }
  .bar-left,
  .bar-right,
  .bar-inner {
    background-color: transparent;
    box-shadow: unset;
    border: 1px solid #1de9b6;
    height: 10px;
  }
  .bar-inner {
    background-color: #1de9b6;
  }
}
</style>