<template>
  <label>
    <span
      :class="{ focused: focused || value || isMobile, disabled: disabled }"
      class="title"
    >
      {{ title }} {{ isRequired ? "*" : null }}
    </span>
    <el-date-picker
      :disabled="disabled"
      @input="(value) => $emit('input', value.getTime())"
      :value="value || ''"
      displayValue="'test'"
      @on-open="focused = true"
      @on-close="closeHandler"
      :type="addCustom ? addCustom : 'date'"
      :class="isRequired ? 'required' : ''"
      :editable="false"
    ></el-date-picker>
    <img
      v-if="!alternativeIcon"
      src="@/assets/icons/calendar.svg"
      class="calendar-icon"
    />
    <img
      class="calendar-icon other"
      v-else
      src="@/assets/calendar.svg"
      alt=""
    />
  </label>
</template>

<script>
// import flatPickr from "vue-flatpickr-component";

// import "flatpickr/dist/flatpickr.css";
export default {
  components: {
    // flatPickr,
    // ActivityCalendar,
  },
  created() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.isMobile = true;
    }
  },
  props: {
    title: String,
    alternativeIcon: Boolean,
    value: [Number, Date, String],
    enableTime: Boolean,
    addCustom: [Boolean, String],
    disabled: Boolean,
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focused: false,
      isMobile: false,
    };
  },

  methods: {
    closeHandler() {
      if (!this.value) {
        this.focused = false;
      }
    },
    changeHandler(date) {
      if (date.length == 0) return;
      var string = new Date(date).getTime();
      if (string == this.value) return;
      this.$emit("input", string);
    },
    changeDate() {
      if (this.addCustom) {
        return this.addCustom;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$border-color: $green-primary;

label {
  position: relative;
  display: inline-block;
  height: 40 * $rem;
  width: 197 * $rem;

  span.title {
    color: rgb(70, 70, 70);
    position: absolute;
    top: 10 * $rem;
    font-size: 15 * $rem;
    margin-left: 15 * $rem;
    transition: all 0.2s ease-out;
    background-color: white;
    white-space: nowrap;
    cursor: text;
    max-width: 190 * $rem;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    color: rgba($blue-primary, 0.7);
    z-index: 2;
    font-weight: 300;

    &.disabled {
      pointer-events: none;
      background: transparent;
    }

    &.focused {
      top: -6 * $rem;
      font-size: 12 * $rem;
      font-weight: 300;
      padding-left: 2 * $rem;
      padding-right: 2 * $rem;
      left: 0;
      z-index: 10;
      background: linear-gradient(
        to bottom,
        transparent 0%,
        transparent 35%,
        #fff 24%,
        #fff 100%
      );
    }
  }

  .calendar-icon {
    width: 30px;
    position: absolute;
    right: 15 * $rem;
    top: 4 * $rem;
    &.other {
      width: auto;
      top: 8px;
    }
  }

  .helper {
    position: absolute;
    top: 45 * $rem;
    left: 30 * $rem;
    font-size: 12 * $rem;
    color: #5c5c5c;
    width: calc(100% - 30 * #{$rem});
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
  }

  .error-message {
    position: absolute;
    top: 43 * $rem;
    left: 15 * $rem;
    font-size: 12 * $rem;
    color: rgb(175, 3, 3);
    width: calc(100% - 15 * #{$rem});
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
  }
}

::v-deep input[type="date"] {
  width: 100%;
  height: 100%;
  padding-left: 15 * $rem;
  font-size: 15 * $rem;
  border-radius: 0.3125rem;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
  height: 2.5rem;
}

::v-deep .el-input--prefix .el-input__inner {
  padding-left: 0.9375rem;
  border: 0.0625rem solid #dcdde6;
  border-radius: 0.375rem;
  color: rgba($blue-primary, 0.7);
  font-size: 0.9375rem;
}

::v-deep .el-input__icon {
  display: none;
}
::v-deep .el-input__inner:focus {
  outline: none;
}
</style>
