<template>
    <div class="permission-checkbox" :class="{ disabled: disabled }" :title="name">
        <template v-if="disabled == false">
            <svg
                v-show="!value"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
            >
                <g id="Area" fill="none" stroke="#283593" stroke-width="2">
                    <rect width="18" height="18" rx="9" stroke="none" />
                    <rect x="1" y="1" width="16" height="16" rx="8" fill="none" />
                </g>
                <rect
                    id="Dot"
                    width="8"
                    height="8"
                    rx="4"
                    transform="translate(7 7)"
                    fill="none"
                    opacity="0"
                />
            </svg>
            <svg
                v-show="value"
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
            >
                <g id="Area" fill="#1de9b6">
                    <path
                        d="M 9.5 18 C 4.813079833984375 18 1 14.18692016601563 1 9.5 C 1 4.813079833984375 4.813079833984375 1 9.5 1 C 14.18692016601563 1 18 4.813079833984375 18 9.5 C 18 14.18692016601563 14.18692016601563 18 9.5 18 Z"
                        stroke="none"
                    />
                    <path
                        d="M 9.5 2 C 5.364489555358887 2 2 5.364489555358887 2 9.5 C 2 13.6355094909668 5.364489555358887 17 9.5 17 C 13.6355094909668 17 17 13.6355094909668 17 9.5 C 17 5.364489555358887 13.6355094909668 2 9.5 2 M 9.5 0 C 14.74670028686523 0 19 4.253290176391602 19 9.5 C 19 14.74670028686523 14.74670028686523 19 9.5 19 C 4.253290176391602 19 0 14.74670028686523 0 9.5 C 0 4.253290176391602 4.253290176391602 0 9.5 0 Z"
                        stroke="none"
                        fill="#1de9b6"
                    />
                </g>
                <rect
                    id="Dot"
                    width="8.465"
                    height="8.465"
                    rx="4.233"
                    transform="translate(5.172 4.922)"
                    fill="#1de9b6"
                />
            </svg>
        </template>
        <template v-else>
            <svg
                v-show="!value"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
            >
                <g id="Area" fill="none" stroke="#28359380" stroke-width="2">
                    <rect width="18" height="18" rx="9" stroke="none" />
                    <rect x="1" y="1" width="16" height="16" rx="8" fill="none" />
                </g>
                <rect
                    id="Dot"
                    width="8"
                    height="8"
                    rx="4"
                    transform="translate(7 7)"
                    fill="none"
                    opacity="0"
                />
            </svg>
            <svg
                v-show="value"
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
            >
                <g id="Area" fill="#1de9b6">
                    <path
                        d="M 9.5 18 C 4.813079833984375 18 1 14.18692016601563 1 9.5 C 1 4.813079833984375 4.813079833984375 1 9.5 1 C 14.18692016601563 1 18 4.813079833984375 18 9.5 C 18 14.18692016601563 14.18692016601563 18 9.5 18 Z"
                        stroke="none"
                    />
                    <path
                        d="M 9.5 2 C 5.364489555358887 2 2 5.364489555358887 2 9.5 C 2 13.6355094909668 5.364489555358887 17 9.5 17 C 13.6355094909668 17 17 13.6355094909668 17 9.5 C 17 5.364489555358887 13.6355094909668 2 9.5 2 M 9.5 0 C 14.74670028686523 0 19 4.253290176391602 19 9.5 C 19 14.74670028686523 14.74670028686523 19 9.5 19 C 4.253290176391602 19 0 14.74670028686523 0 9.5 C 0 4.253290176391602 4.253290176391602 0 9.5 0 Z"
                        stroke="none"
                        fill="#1de9b6"
                    />
                </g>
                <rect
                    id="Dot"
                    width="8.465"
                    height="8.465"
                    rx="4.233"
                    transform="translate(5.172 4.922)"
                    fill="#1de9b6"
                />
            </svg>
        </template>
        <span class="number" :class="{ disabled: disabled }">{{ number }}</span>
        <span class="name" :class="{ disabled: disabled }">{{ name }}</span>

        <div class="parent-node-name" v-if="disabled">
            <div class="triangle"></div>
            <span>In Active Node "{{ parentNodeName }}"</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: String,
        number: Number,
        value: Boolean,
        disabled: Boolean,
        parentNodeName: String,
    },
}
</script>

<style scoped lang="scss">
.permission-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5 * $rem 8 * $rem;
    user-select: none;
    justify-content: flex-start;
    position: relative;

    &.disabled {
        cursor: initial;
    }

    svg {
        margin-right: 9 * $rem;
        flex-shrink: 0;
    }

    span.number {
        margin-right: 7 * $rem;
        color: #9696b9;

        &.disabled {
            color: rgba(#9696b9, 0.5);
        }
    }

    span.name {
        color: $blue-primary;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 60 * #{$rem});
        font-size: 14 * $rem;

        &.disabled {
            color: rgba($blue-primary, 0.5);
        }
    }

    .parent-node-name {
        display: none;
        position: absolute;
        bottom: -60 * $rem;
        height: 50 * $rem;
        background-color: rgba($blue-primary, 0.7);
        color: white;
        font-size: 12 * $rem;
        width: 80%;
        left: 10 * $rem;
        z-index: 1;
        padding-left: 10 * $rem;
        border-radius: 6 * $rem;
        animation: showName 0.3s ease-out;

        .triangle {
            $border-size: 8 * $rem;
            position: absolute;
            top: -8 * $rem;
            left: 25 * $rem;
            width: 0;
            height: 0;
            border-left: $border-size solid transparent;
            border-right: $border-size solid transparent;
            border-bottom: $border-size solid rgba($blue-primary, 0.7);
        }
    }

    &:hover {
        .parent-node-name {
            display: flex;
            align-items: center;
        }
    }
}

@keyframes showName {
    from {
        opacity: 50%;
        bottom: -50 * $rem;
    }

    to {
        opacity: 100%;
        bottom: -60 * $rem;
    }
}
</style>
