<template>
  <div class="select-tree" @click.stop>
    <div class="container" @click="collapsed = !collapsed" ref="box" v-on-click-outside="close">
      <span v-if="!alternativeText && !placementTitle && !inviteTitle && !attendanceTitle">
        {{ Object.keys(selectedPersons).length > 0 ? 'Selected ('+Object.keys(selectedPersons).length+')'  : 'Select People' }}
      </span>
      <span v-if="alternativeText">
        {{ Object.keys(selectedPersons).length > 0 ? 'Selected ('+Object.keys(selectedPersons).length+')'  : 'Select Person/s' }}        
      </span>
      <span v-if="placementTitle">        
        {{ Object.keys(selectedPersons).length > 0 ? 'Shared ('+Object.keys(selectedPersons).length+')'  : 'Share to *' }}  
      </span>
      <span v-if="inviteTitle">
        {{ Object.keys(selectedPersons).length > 0 ? 'Invited ('+Object.keys(selectedPersons).length+')'  : 'Invite Guest' }}
      </span>
      <span v-if="attendanceTitle">
        სტრუქტურა {{defineSelectedObjects() === 0 || defineSelectedObjects() === null ? '' : `(${defineSelectedObjects()})`}}
      </span>
      <i class="material-icons">keyboard_arrow_down</i>
    </div>
    <portal to="job-person-tree">
      <ul
        class="job-person-tree"
        v-show="collapsed"
        @click.stop
        :style="ulStying"
      >
        <input type="text" placeholder="Search ..." v-model="searchTerm" />
        <div class="job all" v-show="!searchTerm">
          <div class="row" @click="allClickHandler">
            <span class="name" :class="{ selected: allSelected }">ALL</span>
            <custom-checkbox :value="allSelected" />
          </div>
        </div>
        <div
          class="department"
          v-for="(dep, index) in departments"
          :key="index"
          v-show="showDep(dep)"
        >
          <div v-if="!listWithoutDeps"
            class="row"
            :class="{ hide: withoutDeps }"
            @click="$set(toggledDeps, dep.name, !toggledDeps[dep.name])"
          >
            <i class="material-icons">{{
              toggledDeps[dep.name]
                ? "keyboard_arrow_down"
                : "keyboard_arrow_up"
            }}</i>
            <span class="dep-name" :class="{ selected: depIsSelected(dep) }">{{
              dep.name
            }}</span>
            <custom-checkbox
              v-show="!searchTerm"
              :value="depIsSelected(dep)"
              @click.native.stop="depCheckHandler(index)"
            />
          </div>
          <div
            class="job"
            v-for="(job, indx) in dep.jobs"
            :key="indx"
            v-show="showJob(job)"
          >
            <div
              class="row"
              v-show="!toggledDeps[dep.name]"
              @click="$set(toggledJobs, job.name, !toggledJobs[job.name])"
            >
              <i class="material-icons">{{
                toggledJobs[job.name]
                  ? "keyboard_arrow_down"
                  : "keyboard_arrow_up"
              }}</i>
              <span class="name" :class="{ selected: jobIsSelected(job) }">{{
                job.name
              }}</span>
              <custom-checkbox
                v-show="!searchTerm"
                :value="jobIsSelected(job)"
                @click.native.stop="jobsCheckClickHandler(index, indx)"
              />
            </div>
            <div
              class="people"
              v-if="job.people && job.people.length > 0"
              v-show="!toggledJobs[job.name]"
            >
              <div class="child" v-for="person in job.people" :key="person.id">
                <div
                  class="child-row"
                  @click="personClickHandler(person)"
                  v-show="showPerson(person)"
                >
                  <span
                    class="name"
                    :class="{ selected: selectedPersons[person.id] }"
                    >{{ person.name }}</span
                  >
                  <custom-checkbox :value="selectedPersons[person.id]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ul>
    </portal>
  </div>
</template>

<script>
import CustomCheckbox from "@/components/ReusableComponents/CustomCheckbox/CustomCheckbox";
import { mixin as onClickOutside } from 'vue-on-click-outside'

export default {
  mixins: [onClickOutside],
  components: {
    CustomCheckbox,
  },
  props: {
    jobs: Array,
    placementTitle: Boolean,
    inviteTitle: Boolean,
    withoutDeps: Boolean,
    departments: Array,
    listWithoutDeps: Boolean,
    clear: {
      type: Boolean,
      default: false,
    },
    initialSelectedPersons: {
      type: Array,
      default: () => [],
    },
    alternativeText: Boolean,
    alternativeMandatory: Boolean,
    attendanceTitle: Boolean,
  },
  created() {
    document.addEventListener("click", this.documentClick);
    this.initialSelect();
    this.$watch(
      "selectedPersons",
      (persons) => {
        this.defineSelectedObjects()
        this.$emit(
          "select",
          Object.keys(persons).filter((key) => persons[key])
        ); // emitting selected persons ids in an array
      },
      { deep: true }
    );
  },
  data() {
    return {
      collapsed: false,
      allSelected: false,
      selectedPersons: {},
      selectedJobs: {},
      toggledJobs: {},
      toggledDeps: {},
      searchTerm: "",
      leftOffset: 0,
      topOffset: 0,
    };
  },
  methods: {
    defineSelectedObjects(){
      let selectedObjs = []
      Object.entries(this.selectedPersons).forEach(el => {
        if(el[1]){
          selectedObjs.push(el[0])
        }else return
      })
      return selectedObjs.length
    },
    close() {
      this.collapsed = !this.collapsed
    },
    allClickHandler() {
      this.allSelected = !this.allSelected;

      this.departments.forEach((dep) => {
        dep.jobs.forEach((job) => {
          job.people.forEach((p) => {
            this.$set(this.selectedPersons, p.id, this.allSelected);
          });
        });
      });
    },
    personClickHandler(person) {
      this.selectedPersons[person.id] = !this.selectedPersons[person.id];
      this.selectedPersons = { ...this.selectedPersons };
      //check if all are selected

      var allAreSelected = !this.departments.forEach((el) => {
        el.jobs.some((job) => {
          if (job.people.some((p) => !this.selectedPersons[p.id])) return true; //not every one is selected in a job
          return false;
        });
        this.allSelected = allAreSelected;
      });
    },
    documentClick() {
      this.collapsed = false;
      this.searchTerm = ''
    },
    initialSelect() {
      this.initialSelectedPersons.forEach(
        (id) => (this.selectedPersons[id] = true)
      );

      if (
        this.departments.forEach((el) => {
          el.jobs.reduce((ct, job) => (ct += job.people.length), 0) ==
            this.initialSelectedPersons.length;
        })
      ) {
        this.allSelected = true;
      }
    },

    jobsCheckClickHandler(depIndex, jobIndex) {
      var currentValue = this.jobIsSelected(
        this.departments[depIndex].jobs[jobIndex]
      );

      this.departments[depIndex].jobs[jobIndex].people.forEach((person) => {
        this.selectedPersons[person.id] = !currentValue;
      });
      this.selectedPersons = { ...this.selectedPersons };
    },
    depCheckHandler(depIndex) {
      var currentValue = this.depIsSelected(this.departments[depIndex]);

      this.departments[depIndex].jobs.forEach((job) => {
        job.people.forEach((p) => {
          this.selectedPersons[p.id] = !currentValue;
        });
        this.selectedPersons = { ...this.selectedPersons };
      });
    },
    jobIsSelected(job) {
      return !job.people.some((p) => !this.selectedPersons[p.id]);
    },
    depIsSelected(dep) {
      return !dep.jobs.some((job) => {
        return job.people.some((p) => !this.selectedPersons[p.id]);
      });
    },
    showDep(dep) {
      return dep.jobs && dep.jobs.some((job) => this.showJob(job));
    },
    showJob(job) {
      return job.people && job.people.some((person) => this.showPerson(person));
    },
    showPerson(person) {
      return (
        !this.searchTerm ||
        person.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
  },
  watch: {
    initialSelectedPersons: {
      immediate: true,
      handler(from, to) {
        this.initialSelect();
        let number = [];

        this.departments.forEach((dep) => {
          dep.jobs.forEach((job) => {
            number.push(job.people.length);
          });
        });
        let sum = number.reduce(function (a, b) {
          return a + b;
        }, 0);

        if (sum === this.initialSelectedPersons.length) {
          this.allSelected = true;
        } else {
          this.allSelected = false;
        }
        Object.keys(this.selectedPersons).forEach((key) => {
          if (
            this.initialSelectedPersons.filter((value) => value == key)
              .length <= 0
          )
            delete this.selectedPersons[key];
        });
      },
    },
    clear(){
      this.$emit('select',{})
      this.selectedPersons = {}
    },
    collapsed(val) {
      if (val) {
        var box = this.$refs.box;
        var clientBoundintRect = box.getBoundingClientRect();
        this.leftOffset = clientBoundintRect.left;
        this.topOffset = clientBoundintRect.top;
      }
    },
  },
  computed: {
    ulStying() {
      var styling = {
        left: this.leftOffset + "px",
        top: this.topOffset + 50 + "px",
      };
      if (this.topOffset > 600) styling["transform"] = "translateY(-100%)"; //open to the top
      return styling;
    },
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style scoped lang="scss">
.hide {
  display: none !important;
}
.select-tree {
  position: relative;
  margin-left: 5 * $rem;

  .container {
    width: 250 * $rem;
    height: 38 * $rem;
    padding-left: 15 * $rem;
    padding-right: 15 * $rem;
    border-radius: 6 * $rem;
    border: $rem solid #dcdde6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    span {
      font-weight: 300;
      font-size: 16 * $rem;
      color: rgba($blue-primary, 0.7);
    }

    i {
      font-size: 20 * $rem;
      color: rgba($blue-primary, 0.7);
    }
  }
}

@keyframes show {
  0% {
    opacity: 0.5;
  }
}
</style>