<template>
  <div class="wrapper">
    <div class="wrapper__header">
      <div class="page-title" v-if="type === 'poll'">Polls</div>
      <div class="page-title" v-if="type === 'offers'">Offers</div>
      <div class="page-title" v-if="type === 'news'">News</div>
      <!-- <div
        @click="popupVisible = true"
        class="wrapper__button"
        :style="[
          type == 'offers'
            ? { marginRight: ' 14.125rem' }
            : type == 'poll'
            ? { marginRight: ' 22.825rem' }
            : { marginRight: ' 24.825rem' },
        ]"
      >
        <img
          :src="require('@/assets/icons/icon_add_image.svg')"
          alt="add_image"
        />
        <span>Upload Main Banner</span>
      </div> -->
      <div
        class="wrapper__button"
        v-if="type === 'offers'"
        @click="$emit('responses-clicked')"
      >
        Responses
      </div>

      <div class="wrapper__types">
        <div class="wrapper__type" @click="$emit('ongoing-clicked')">
          <img
            :src="require('@/assets/icons/Flag Ongoing stroke.svg')"
            alt="ongoing"
            v-if="!onGoingClicked"
          />
          <img
            v-else
            :src="require('@/assets/icons/icon_ongoing.svg')"
            alt="ongoing"
          />
          <div class="wrapper__description">Ongoing</div>
        </div>
        <div class="wrapper__type" @click="$emit('draft-clicked')">
          <img
            v-if="!draftClicked"
            :src="require('@/assets/icons/Flag Draft stroke.svg')"
            alt="ongoing"
          />
          <img
            v-else
            :src="require('@/assets/icons/icon_draft.svg')"
            alt="draft"
          />
          <div class="wrapper__description">Draft</div>
        </div>
        <div
          class="wrapper__type"
          v-if="type !== 'news'"
          @click="$emit('completed-clicked')"
        >
          <img
            v-if="!completedClicked"
            :src="require('@/assets/icons/Flag Completed stroke.svg')"
            alt="ongoing"
          />

          <img
            v-else
            :src="require('@/assets/icons/icon_completed.svg')"
            alt="completed"
          />
          <div class="wrapper__description">Completed</div>
        </div>
      </div>
      <div class="wrapper__add">
        <add-button @clicked="$emit('add-item')" />
      </div>
      <div class="wrapper__search">
        <input
          class="search-input"
          placeholder="Search"
          v-model="search"
          type="text"
        />
      </div>
    </div>
    <div class="wrapper__items" @scroll="scrolledToBottom">
      <reusable-block
        v-for="(item, index) in data"
        :key="index"
        :item="item"
        @clicked="(item) => $emit('clicked', item)"
        @edit-item="(item) => $emit('edit-item', item)"
        @delete-item="(item) => $emit('delete-item', item)"
      />
    </div>
    <custom-popup
      title="Add main banner"
      :visible="popupVisible"
      @close="popupVisible = false"
    >
      <custom-file-input
        title="Select File"
        :value="mainBanner"
        @change="(file) => (mainBanner = file)"
      />

      <div class="upload-button" @click.stop="uploadMainBanner">Upload</div>
    </custom-popup>
  </div>
</template>

<script>
import AddButton from "@/components/ReusableComponents/AddButton/AddButton";
import InputSearch from "@/components/ReusableComponents/InputSearch/InputSearch.vue";
import ReusableBlock from "@/components/ReusableComponents/ReusableBlock/ReusableBlock";
import CustomPopup from "../CustomPopup/CustomPopup.vue";
import CustomFileInput from "@/components/ReusableComponents/CustomFileInput/CustomFileInput";
import axios from "@/Helpers/AxiosInstance.js";

export default {
  components: { ReusableBlock, AddButton, CustomPopup, CustomFileInput },
  props: {
    type: String,
    data: Array,
    draftClicked: Boolean,
    onGoingClicked: Boolean,
    completedClicked: Boolean,
    maxPageCount: Number,
  },
  data() {
    return {
      search: "",
      popupVisible: false,
      currentPage: 1,
      mainBanner: {},
    };
  },
  methods: {
    uploadMainBanner() {
      axios
        .post("News/UploadMainBanner", {
          FileName: this.mainBanner.filename,
          FileType: this.mainBanner.type,
          File: this.mainBanner.base64,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.popupVisible = false;
          }
        });
    },
    scrolledToBottom(event) {
      var el = event.srcElement;

      if (el.scrollTop >= el.scrollHeight - el.clientHeight) {
        this.currentPage += 1;
        if (this.currentPage <= this.maxPageCount) {
          this.$emit("get-data", this.currentPage);
        }
      }
    },
  },
  watch: {
    search() {
      this.$emit("search-value", this.search);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .popup {
  width: initial !important;
  height: initial !important;
}
.upload-button {
  cursor: pointer;
  width: 197 * $rem;
  display: inline-block;
  height: 40 * $rem;
  line-height: 40 * $rem;
  padding: 0 15 * $rem 0 15 * $rem;
  outline: none;
  text-align: center;
  color: white;
  margin-top: 10px;
  border-radius: 5 * $rem;
  box-shadow: none;
  background-color: #1de9b6;
}
.wrapper {
  padding: 37 * $rem 70 * $rem;
  padding-top: 37 * $rem;
  // padding-bottom: 10 * $rem;
  padding-right: 111 * $rem;
  padding-left: 111 * $rem;

  height: calc(100% - 50px);
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    .page-title {
      font-size: 1.5rem;
      font-weight: 400;
      color: #283593;
      text-transform: uppercase;
      margin-right: auto;
    }
  }

  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $blue_primary;
    margin-right: 50 * $rem;
    margin-bottom: 5px;
    span {
      margin-top: 5px;
    }
  }

  &__types {
    display: flex;
    align-items: center;
    margin-right: 40 * $rem;
  }

  &__description {
    padding: 10px;
    background-color: rgba(#000, 0.1);
    border-radius: 10px;
    color: $blue_primary;
    position: absolute;
    bottom: -40px;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s ease;
  }

  &__type {
    cursor: pointer;
    position: relative;
    margin-right: 10 * $rem;

    &:hover > .wrapper__description {
      visibility: visible;
      opacity: 100;
      font-size: 14px;
    }
  }

  &__add {
    margin-right: 20 * $rem;
    margin-bottom: 5px;
  }

  &__search {
    margin-bottom: 5px;
  }

  &__items {
    // max-height: 730px;
    height: 100%;
    overflow-y: auto;
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    height: 78vh;
    overflow-y: auto;
    width: 104%;
  }
}
.search-input {
  width: 200px;
  height: 38px;
  outline: none;
  border: 0;
  color: #9696b9;
  border: 1px solid #dedff4;
  padding-left: 10px;
  font-size: 16px;
  border-radius: 5px;
  &::placeholder {
    color: #9696b9;
  }
}
</style>
