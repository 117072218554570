import ProfileJobs from "@/components/Profile/ProfileJobs";
import ProfileInfo from "@/components/Profile/ProfileInfo";
import ProfilePerformance from "@/components/Profile/ProfilePerformance";
import ProfileInOuts from "@/components/Profile/ProfileInOuts";
import VacationTermsAndRequests from "@/components/Profile/VacationTermsAndRequests";
import ProfileSchedule from "@/components/Profile/ProfileSchedule";
import ProfileSalaries from "@/components/Profile/ProfileSalaries/ProfileSalaries"
import Profile from '@/components/Profile/Profile.vue'

import ProfileSalariesFactor from  "@/components/Profile/ProfileSalaries/ProfileSalariesFactor"

const routes = {
    name: 'Edit Profile',
    path: '/Profile/:id',
    redirect: '/Profile/:id/info',
    component: Profile,
    children: [
        {
            name: 'profile',
            path: 'info',
            component: ProfileInfo
        },
        {
            name: 'jobs',
            path: 'jobs',
            component: ProfileJobs

        },
        {
            name: 'salaries',
            path: 'salaries',
            component: ProfileSalaries,
            children: [
                {
                    name: 'salariesFactor',
                    path: 'salariesFactor/:salaryId',
                    component: ProfileSalariesFactor
                }
            ],
        },
        {
            name: 'performance',
            path: 'perfomance',
            component: ProfilePerformance,
        },
        {
            name: 'attendance',
            path: 'attendence',
            component: ProfileInOuts
        },
        {
            name: 'schedule',
            path: 'schedule',
            component: ProfileSchedule
        },
        {
            name: 'vacations',
            path: 'vacation',
            component: VacationTermsAndRequests
        }
    ]
}

export default routes