var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"holiday-calendar"},[_c('div',{staticClass:"year-and-month-selector"},[_c('div',{staticClass:"select-year"},[_c('div',{staticClass:"left material-icons",on:{"click":function($event){_vm.selectedYear--}}},[_vm._v(" keyboard_arrow_left ")]),_c('div',{staticClass:"year"},[_vm._v(_vm._s(_vm.selectedYear))]),_c('div',{staticClass:"right material-icons",on:{"click":function($event){_vm.selectedYear++}}},[_vm._v(" keyboard_arrow_right ")])]),_c('div',{staticClass:"select-month"},_vm._l((_vm.months),function(month,index){return _c('div',{key:index,staticClass:"month",class:{ selected: _vm.selectedMonth == index + 1 },on:{"click":function($event){_vm.selectedMonth = index + 1}}},[_c('span',[_vm._v(_vm._s(month))]),_c('span',[_vm._v(_vm._s(_vm.getHolidayCountForTheMonth(index)))])])}),0)]),_c('div',{staticClass:"calendar-content"},[_c('div',{staticClass:"calendar"},[_c('div',{staticClass:"select-month"},[_c('div',{staticClass:"left material-icons",on:{"click":function($event){_vm.selectedMonth--}}},[_vm._v(" keyboard_arrow_left ")]),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.months[_vm.selectedMonth - 1]))]),_c('div',{staticClass:"right material-icons",on:{"click":function($event){_vm.selectedMonth++}}},[_vm._v(" keyboard_arrow_right ")])]),_c('div',{staticClass:"week-days"},_vm._l((7),function(day){return _c('div',{key:day,staticClass:"week-day"},[_vm._v(" "+_vm._s(_vm.dayNames[day - 1])+" ")])}),0),_c('div',{staticClass:"days"},_vm._l((6),function(row){return _c('div',{key:row,staticClass:"row"},_vm._l((7),function(day){return _c('div',{key:(row - 1) * 7 + day,staticClass:"day",class:{
              'outside-of-selected-month': _vm.dateIsOutsideOfSelectedMonth(
                row,
                day,
              ),
              'is-holiday': _vm.isHoliday(row, day),
            }},[(_vm.isHoliday(row, day))?_c('div',{staticClass:"day-background",style:({
                'background-color':
                  _vm.colors[_vm.indexOfHoliday(row, day) % _vm.colors.length],
              })},[_c('span',[_vm._v(_vm._s(_vm.calculateDay(row, day)))])]):_c('span',[_vm._v(_vm._s(_vm.calculateDay(row, day)))])])}),0)}),0)]),_c('div',{staticClass:"right-side"},[_c('div',{staticClass:"vertical"}),(_vm.altText)?_c('div',{staticClass:"alt-text"},[_c('span',[_vm._v("No Rest, Just Work")])]):_vm._e(),_c('div',{staticClass:"list"},_vm._l((_vm.holidaysOfThisMonth),function(holiday,index){return _c('div',{key:index,staticClass:"holiday"},[_c('div',{staticClass:"color",style:({ 'background-color': _vm.colors[index % _vm.colors.length] })}),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.dateForList(holiday.date)))]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(holiday.name))])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }