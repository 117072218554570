<template>
  <div class="background">
    <custom-loader v-if="loaderActive" />
    <div v-if="alternativeMessage" class="alternative-message">
      <h1>There is no Records!</h1>
    </div>
    <div class="log">
      <div class="essentials">
        <div class="add" v-if="showHeader">
          <add-button title="Add Manual Entry" @clicked="addVisible = true" />
          <!-- <span @click="addVisible = true">Add Manual Entry</span> -->
          <div class="add-card" @click="addCardVisible = true">
            <span>Add Card</span>
          </div>
        </div>

        <div class="search">
          <div class="clearFilters" @click="clearAllFilters">
            <span>Clear Filters</span>
          </div>
          <input-search
            v-if="showHeader"
            title="Search Person"
            :options="employeeOptions"
            @select="(id) => (searchedPersonID = id)"
            :valueCleared="clearAllFiltersTrigger"
          />
          <date-range
            :addCustom="'datetimerange'"
            :initialFrom="fromDate"
            :initialTo="toDate"
            @from="(from) => (fromDate = from)"
            @to="(to) => (toDate = to)"
            class="date_range"
          />
          <form @submit.prevent="getEntriesBy" v-if="showHeader">
            <custom-input v-model="searchExternalID" title="External ID" />
          </form>
          <input-search
            v-if="showHeader"
            title="Filter By Office"
            @select="(id) => (searchedOfficeId = id)"
            :options="officeOptions"
            :valueCleared="clearAllFiltersTrigger"
          />
          <!-- <input-search
            title="Search By time"
            :options="teamOptions"
            @select="(id) => (searchedTeamID = id)"
          /> -->
        </div>
      </div>
      <div class="log-entries-header">
        <span>ID</span>
        <span>Name Surname</span>
        <span>Date & Time</span>
        <span>Action</span>
        <span>Comment</span>
        <span>Comment By</span>
        <span>Registrered</span>
      </div>
      <div class="log-entries" @scroll="scrolledToBottom">
        <div class="entry" v-for="entry in log" :key="entry.index">
          <!-- <img
                        :src="getAvatarSrc(entry.InternalPersonID)"
                        @error="failedAvatars.push(entry.InternalPersonID)"
                        v-show="!failedAvatars.includes(entry.InternalPersonID)"
                        class="circle"
                    />
                    <div
                        class="circle"
                        v-show="failedAvatars.includes(entry.InternalPersonID)"
                    ></div> -->
          <div
            class="circle"
            :style="{
              backgroundImage: `url(${getAvatarSrc(entry.InternalPersonID)})`,
            }"
          ></div>
          <span
            class="external-id"
            @click="showAssignPopup(entry.ID, entry.ExternalPersonID)"
          >
            {{ entry.ExternalPersonID }}
          </span>
          <span class="full-name">{{ entry.InternalPersonFullName }}</span>

          <span class="time">{{ dateText(entry.InteractionTime) }}</span>
          <div class="enter-leave" :class="{ enter: entry.ActionType == 'IN' }">
            <img
              v-if="entry.ActionType == 'IN'"
              src="@/assets/in.svg"
              class="in-icon"
              alt="in"
            />
            <img
              v-if="entry.ActionType == 'OUT'"
              src="@/assets/out.svg"
              class="out-icon"
              alt="out"
            />
          </div>
          <div class="comment-content">
            <span>{{ entry.Comment }}</span>
          </div>
          <div v-if="entry.RecordAuthorFullName" class="commentator-name">
            <span>{{ entry.RecordAuthorFullName }}</span>
          </div>
          <div v-if="entry.RecordAuthorFullName" class="registrered-time">
            <span>{{ dateText(entry.CreateTime) }}</span>
          </div>

          <img
            src="@/assets/delete.svg"
            class="material-icons delete"
            @click="deleteEntry(entry.ID)"
            alt="delete"
            v-if="!isTimeLineShown"
          />
        </div>
      </div>
      <overlay :visible="addVisible" @close="addVisible = false">
        <popup
          @close="addVisible = false"
          title="Add Attendance Entry"
          :flexibleSize="true"
        >
          <add-manual-entry
            :employees="employees"
            :devices="devices"
            :actionTypes="actionTypes"
            @add="addEntry"
          />
        </popup>
      </overlay>
      <overlay :visible="addCardVisible" @close="addCardVisible = false">
        <custom-loader v-if="cardLoaderActive" />
        <popup
          @close="addCardVisible = false"
          title="Add Access Card"
          :flexibleSize="false"
        >
          <div class="add-card-content">
            <span class="message" v-if="cardLoaderActive">
              Please place the card near the device
            </span>

            <div class="header">
              <input-search
                title="Search Person"
                :options="employeeOptions"
                @select="(id) => (cardPerson = id)"
                :valueCleared="clearAllFiltersTrigger"
              />
              <div class="add-card-button" @click="addCard">
                <span>Add Card</span>
              </div>
            </div>
            <div class="cards-content">
              <p>Cards</p>
              <template v-if="biostarData.cards && biostarData.cards.length">
                <div class="cards-wrapper">
                  <div
                    class="card"
                    v-for="card in biostarData.cards"
                    :key="card.index"
                  >
                    <span>
                      {{ card.card_id }}
                    </span>
                    <span
                      @click="deleteCard(card)"
                      class="material-icons-outlined icon"
                    >
                      delete
                    </span>
                  </div>
                </div>
              </template>
              <template
                v-else-if="biostarData.cards && !biostarData.cards.length"
              >
                <div class="no-cards">No card assigned</div>
              </template>
            </div>
          </div>
        </popup>
      </overlay>
      <delete-item
        v-if="deleteVisible"
        text="Would You Like To Delete This Entry?"
      />
      <delete-item
        v-if="deleteUnUnAssignVisible"
        @close="deleteUnUnAssignVisible = false"
        @delete="UnUnAssignPerson"
        title="Unassign"
        deleteText="Unassign"
        text="Would You Like To UnAssign This Person?"
      />
      <!-- <overlay
        :visible="assignPopupVisible"
        @close="assignPopupVisible = false"
      >
        <popup
          @close="assignPopupVisible = false"
          :title="assignTitle"
          class="assign-popup"
        >
          <form @submit.prevent="assign" class="assign-form">
            <input-search
              title="Employee"
              :options="employeeOptions"
              @select="(id) => (selectedPersonID = id)"
            />
            <button class="assign-button">Assign</button>
          </form>
        </popup>
        
      </overlay> -->
    </div>
    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
  </div>
</template>

<script>
import AddButton from '@/components/ReusableComponents/AddButton/AddButton.vue'
import Overlay from '@/components/ReusableComponents/Overlay/Overlay.vue'
import Popup from '@/components/ReusableComponents/Popup/Popup.vue'

import AddManualEntry from '@/components/AttendanceLog/AddManualEntry.vue'
import DeleteItem from '@/components/ReusableComponents/DeleteItem/DeleteItem.vue'
import InputSearch from '@/components/ReusableComponents/InputSearch/InputSearch.vue'
import axios from '@/Helpers/AxiosInstance.js'
import { checkPermission } from '../../Helpers/HelperFunctions'
import CustomInput from '@/components/ReusableComponents/CustomInput/CustomInput'
import DateRange from '@/components/ReusableComponents/DateRange/DateRange'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import CustomLoader from '@/components/ReusableComponents/Loader/CustomLoader.vue'
export default {
  components: {
    CustomInput,
    AddButton,
    Overlay,
    DateRange,
    Popup,
    AddManualEntry,
    DeleteItem,
    InputSearch,
    CustomSnackbar,
    CustomLoader,
  },
  props: {
    showHeader: {
      type: Boolean,
      default: true,
    },
    personID: Number,
  },
  created() {
    this.getDevices()
    this.getEmployees()
    this.getTeams()
    this.getActionTypes()
    this.getEntries(1, true)
    this.getOffices()
  },
  watch: {
    searchedPersonID() {
      this.getEntriesBy()
    },
    searchedTeamID() {
      this.getEntriesBy()
    },
    cardPerson() {
      this.getPersonsBiostarData()
    },
    searchedOfficeId() {
      this.getEntriesBy()
    },
    fromDate() {
      this.getEntries(1, true)
    },
  },
  data() {
    return {
      accessPermissions: {
        createAttendanceRecord: checkPermission('CreateAttendanceRecord'),
        getInOuts: checkPermission('GetInOuts'),
      },
      log: [],
      totalCount: 0,
      currentPage: 1,
      addVisible: false,
      devices: [],
      addCardVisible: false,
      actionTypes: [],
      employees: [],
      cardLoaderActive: false,
      teams: [],
      offices: [],
      datePickerValue: '',
      deleteVisible: false,
      assignPopupVisible: false,
      deleteUnUnAssignVisible: false,
      selectedRecordID: '',
      selectedExternalID: '',
      selectedPersonID: '',
      searchedPersonID: '',
      cardPerson: '',
      searchedTeamID: '',
      searchedOfficeId: '',
      selectedUnAssigndPerson: '',
      searchExternalID: '',
      failedAvatars: [],
      snackbarPurpose: '',
      snackbarText: '',
      snackbarVisible: false,
      loaderActive: false,
      alternativeMessage: false,
      fromDate: 0,
      toDate: 0,
      clearAllFiltersTrigger: false,
      biostarData: {},
    }
  },
  methods: {
    getAvatarSrc(id) {
      return this.$store.state.getAvatar + id
    },
    scrolledToBottom(event) {
      // if (this.searchedPersonID || this.searchedTeamID) return
      var el = event.srcElement
      if (el.scrollTop >= el.scrollHeight - el.clientHeight) {
        this.currentPage += 1
        this.getEntries()
      }
    },

    getPersonsBiostarData() {
      if (!this.cardPerson) return
      axios
        .post(`Person/GetPersonsBiostarData?userID=${this.cardPerson}`)
        .then((resp) => {
          if (resp.data.ErrorMsg) return
          console.log(resp)
          this.biostarData = resp.data.User
        })
    },
    addCard() {
      if (!this.cardPerson) return
      this.cardLoaderActive = true
      axios
        .post(`Person/AddPersonBiostarCard?userID=${this.cardPerson}`)
        .then((resp) => {
          if (resp.data.ErrorMsg) {
            this.cardLoaderActive = false
            return
          }
          if (resp.data.Response.code !== null) {
            this.snackbarVisible = true
            this.snackbarText = 'Card has successfully been added to a device'
            this.snackbarPurpose = 'success'
            // this.addCardVisible = false
            this.getPersonsBiostarData()
          } else {
            this.snackbarVisible = true
            this.snackbarText = 'Something went wrong, please try again'
            this.snackbarPurpose = 'error'
          }
          this.cardLoaderActive = false
        })
    },
    deleteCard(card) {
      let cards = this.biostarData.cards.filter(
        (el) => el.card_id !== card.card_id,
      )

      this.$confirm('Are you sure  you want to delete this card?').then(() => {
        axios
          .post('Person/UpdatePersonBiostarCard', {
            userID: this.cardPerson,
            cards: cards,
          })
          .then((resp) => {
            if (resp.data.Response.code !== null) {
              this.snackbarVisible = true
              this.snackbarText = 'Successfully deleted!'
              this.snackbarPurpose = 'success'
              this.getPersonsBiostarData()
            } else {
              this.snackbarVisible = true
              this.snackbarText = 'Error!'
              this.snackbarPurpose = 'error'
            }
          })
      })
    },

    fullname(entry) {
      return entry.firstName + ' ' + entry.lastName
    },
    dateText(time) {
      var date = new Date(time)
      if (date == 'Invalid Date') return '00:00'
      var timeText =
        this.doubleDigit(date.getHours()) +
        ':' +
        this.doubleDigit(date.getMinutes())
      // if (this.isToday(date)) {
      //   return "Today " + timeText;
      // }
      // if (this.isYesterday(date)) {
      //   return "Yesterday " + timeText;
      // }
      return (
        this.doubleDigit(date.getMonth() + 1) +
        '/' +
        this.doubleDigit(date.getDate()) +
        ' ' +
        timeText
      )
    },
    isToday(date) {
      return (
        date.getMonth() == new Date().getMonth() &&
        date.getDay() == new Date().getDay() &&
        new Date().getFullYear() == date.getFullYear()
      )
    },
    isYesterday(date) {
      var yesterdayDate = new Date().setDate(new Date().getDate() - 1)
      return (
        date.getMonth() == new Date().getMonth() &&
        date.getDate() == yesterdayDate &&
        new Date().getFullYear() == date.getFullYear()
      )
    },
    getDevices() {
      axios
        .get('attendance/GetDevices')
        .then((resp) => (this.devices = resp.data.Value))
    },
    getActionTypes() {
      axios
        .get('attendance/GetActionTypes')
        .then((resp) => (this.actionTypes = resp.data.Value))
    },
    getEmployees() {
      axios
        .get('Person/GetPersons', {
          params: {
            Page: 1,
            PageSize: 1000000,
          },
        })
        .then((resp) => (this.employees = resp.data.Value.Persons))
    },
    getTeams() {
      axios
        .get('Company/GetTeams', {
          params: {
            Page: 1,
            PageSize: 1000000,
          },
        })
        .then((resp) => (this.teams = resp.data.Value))
    },
    getOffices() {
      axios
        .get('Company/GetOffices', {
          params: {
            Page: 1,
            PageSize: 1000000,
          },
        })
        .then((resp) => (this.offices = resp.data.Value))
    },

    getEntries(page, getFirst) {
      this.loaderActive = true
      axios
        .get('attendance/InOuts', {
          params: {
            Page: page || this.currentPage,
            PageSize: 15,
            Person: this.personID ? this.personID : this.searchedPersonID,
            Team: this.searchedTeamID,
            Office: this.searchedOfficeId,
            ExternalID: this.searchExternalID,
            From: this.fromDate == 0 ? null : this.fromDate,
            To: this.toDate == 0 ? null : this.toDate,
          },
        })
        .then((resp) => {
          if (getFirst) this.log = resp.data.Value
          else {
            this.log.push(...resp.data.Value)
          }
          this.maxPageCount = resp.data.Paging.TotalPageCount
          this.loaderActive = false

          this.alternativeMessage = false
          if (!this.log.length) this.alternativeMessage = true
        })
    },
    getEntriesBy() {
      axios
        .get('attendance/InOuts', {
          params: {
            Page: 1,
            PageSize: 15,
            Team: this.searchedTeamID,
            Person: this.searchedPersonID,
            Office: this.searchedOfficeId,
            ExternalID: this.searchExternalID + '',
          },
        })
        .then((resp) => {
          this.log = resp.data.Value
          this.maxPageCount = resp.data.Paging.TotalPageCount
        })
    },
    doubleDigit(str) {
      return ('0' + str).slice(-2)
    },
    addEntry(data) {
      axios
        .post('attendance/CreateRecord', {
          Device: data.device,
          InternalPerson: data.employee,
          InteractionTime: data.time,
          ActionType: data.actionType,
          Comment: data.comment,
        })

        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.getEntries(1, true)
            this.addVisible = false
          }
        })
    },
    deleteEntry(id) {
      this.$confirm('Would you like to delete this record?').then(() => {
        axios
          .post('attendance/DeleteRecord', {
            Records: [{ ID: id }],
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.getEntries(1, true)
            }
          })
      })
    },
    showAssignPopup(recordID, externalID) {
      this.assignPopupVisible = true
      this.selectedRecordID = recordID
      this.selectedExternalID = externalID
    },
    assign() {
      if (!this.selectedPersonID) return
      axios
        .post('attendance/AssignID', {
          RecordID: this.selectedRecordID,
          PersonID: this.selectedPersonID,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.selectedPersonID = ''
            this.selectedRecordID = ''
            this.snackbarVisible = true
            this.snackbarText = 'Successfully Assigned!'
            this.snackbarPurpose = 'success'
            this.assignPopupVisible = false
            this.getEntries(1, true)
          }
        })
    },
    UnUnAssignPerson() {
      axios
        .post('attendance/UnassignID', {
          PersonID: +this.selectedUnAssigndPerson.personId,
          ExternalID: +this.selectedUnAssigndPerson.externalId,
        })
        .then((resp) => {
          if (resp.ErrorMsg) return
          this.snackbarVisible = true
          this.snackbarText = 'Successfully Unassigned!'
          this.snackbarPurpose = 'success'
          this.getEntries()
          this.deleteUnUnAssignVisible = false
        })
    },
    clearAllFilters() {
      this.clearAllFiltersTrigger = !this.clearAllFiltersTrigger
      this.searchedPersonID = ''
      this.fromDate = null
      this.toDate = null
      this.searchedOfficeId = null
      this.searchExternalID = ''
    },
  },
  computed: {
    assignTitle() {
      return `Assign ID - ${this.selectedExternalID} To An Employee`
    },
    isTimeLineShown() {
      return this.$store.state.showTimeLine
    },
    employeeOptions() {
      return this.employees.map((emp) => ({
        title: emp.FirstName + ' ' + emp.LastName,
        value: emp.ID,
      }))
    },
    teamOptions() {
      return this.teams.map((team) => ({
        title: team.Name,
        value: team.ID,
      }))
    },
    officeOptions() {
      return this.offices.map((team) => ({
        title: team.Name,
        value: team.ID,
      }))
    },
  },
}
</script>

<style lang="scss" scoped>
.background {
  overflow: hidden;
}
.add-card {
  background-color: rgba(40, 53, 147, 0.6);
  height: 2.3125rem;
  border-radius: 0.1875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin-left: 40px;
  cursor: pointer;
  span {
    color: #ffffff !important;
  }
}
.add-card-content {
  height: 100%;
  position: relative;
  .message {
    position: absolute;
    color: #ff991f;
    left: 50%;
    // top: 20px;
    bottom: 50px;
    transform: translate(-50%);
  }
  .header {
    display: flex;
    ::v-deep .input-search {
      span.title {
        overflow: visible;
      }
    }
  }
  .no-card {
    margin-top: 15px;
  }
  .cards-content {
    margin-top: 30px;
    p {
      color: #283593;
      font-size: 20px;
      color: #ff991f;
    }
    .cards-wrapper {
      display: flex;
      margin-top: 20px;
    }
    .card {
      padding: 0.375rem 0.5rem;
      background-color: #e2f4ee;
      border: 0.0625rem solid #3fc699;
      border-radius: 0.625rem;
      //   display: flex;
      //   align-items: center;
      display: inline-block;
      vertical-align: bottom;
      font-size: 0.875rem;
      font-family: 'helvetica-medium';
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      color: #15181a;
      font-weight: 500;
      .icon {
        color: #2a3032;
        cursor: pointer;
        vertical-align: middle;
        margin-left: 0.175rem;
      }
    }
  }
  .add-card-button {
    background-color: #1de9b6;
    height: 2.3125rem;
    border-radius: 0.1875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin-left: 40px;
    cursor: pointer;
    span {
      color: #ffffff !important;
    }
  }
}
.log {
  .essentials {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15 * $rem;
    padding-left: 70px;
    padding-right: 70px;
    margin-top: 30px;

    form {
      margin-right: 15 * $rem;
    }

    & > .add {
      display: flex;
      align-items: center;

      > span {
        display: inline-block;
        margin-left: 10 * $rem;
        font-weight: 400;
        font-size: 18 * $rem;
        color: $blue-primary;
        cursor: pointer;
      }
    }

    .btn.search {
      color: white;
      font-size: 1rem;
      font-weight: 400;
      background-color: rgba($blue-primary, 0.5);
      border-radius: 0.1875rem;
      width: 6.25rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 15 * $rem;
    }

    .button-container {
      margin-bottom: 0;
    }

    .search {
      display: flex;
      align-items: center;

      .clearFilters {
        margin-right: 0.9375rem;
        position: relative;
        cursor: pointer;

        span {
          color: rgba($blue-primary, 0.5);
          font-size: 0.9375rem;
          transition: ease 0.2s;
          font-weight: 400;
        }
        &:hover {
          span {
            color: rgba($red, 0.5);
          }
          &::after {
            height: 1.5rem;
          }
        }

        &::after {
          content: '';
          position: absolute;
          right: -0.3125rem;
          top: 50%;
          transform: translate(0, -50%);
          height: 1rem;
          width: 0.0625rem;
          background: rgba(#5769d6, 0.2);
          transition: ease 0.3s;
        }
      }
      ::v-deep .container {
        background-color: white;
        height: 40px;
        margin-top: 2px;
        margin-right: 20px;
      }
      .input-search:first-child {
        margin-right: 15 * $rem;
      }
      .input-search:nth-child(2) {
        margin-right: 15 * $rem;
      }
    }
  }

  .button-container {
    // width: 210 * $rem;
    margin-bottom: 30 * $rem;
  }

  .log-entries {
    height: 75vh;
    overflow-y: scroll;
    .entry {
      position: relative;
      background-color: #ededfc;
      font-weight: 300;
      height: 80 * $rem;
      padding: 10 * $rem 0 10 * $rem 20 * $rem;
      display: flex;
      align-items: center;
      color: $blue-primary;
      &:hover {
        font-weight: 400;
      }
      img.in-icon,
      img.out-icon {
        width: 75 * $rem;
        height: 75 * $rem;
        user-select: none;
      }

      .full-name {
        cursor: pointer;
        margin-left: 28px;
        width: 9.875rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .external-id {
        width: 27px;
        margin-right: 15 * $rem;
        // cursor: pointer;
        margin-left: 25 * $rem;
      }

      .enter-leave {
        margin-left: 62 * $rem;
        display: flex;
        align-items: center;
        color: $red;
        margin-right: 50 * $rem;

        i {
          transform: rotate(180deg);
          font-size: 26 * $rem;
        }

        span {
          margin-left: 5 * $rem;
        }

        &.enter {
          color: $green-primary;

          i {
            transform: none;
          }
        }
      }

      .time {
        margin-left: 1rem;
        width: 130 * $rem;
        text-align: right;
        font-size: 16 * $rem;
      }

      .manual {
        margin-left: auto;
        margin-right: 80 * $rem;
        color: $grey-text;

        .text-bold {
          color: black;
          font-weight: 500;
        }

        button {
          margin-left: 15 * $rem;
          width: 75px;
          height: 30px;
          min-height: initial;
          min-width: initial;
        }
      }

      .delete {
        position: absolute;
        right: 65px;
        height: 100%;
        top: 0;
        bottom: 0;
        width: 40 * $rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        cursor: pointer;
        transition: filter 0.2s ease-out;
        font-size: 28px;

        &:hover {
          filter: brightness(1.03);
        }
      }
    }
  }

  .assign-popup {
    width: 400 * $rem;
    min-width: initial;
    height: 50vh;
  }

  .assign-form {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-left: 15 * $rem;
    }
  }
}
.entry:nth-child(2n + 1) {
  background: rgba(40, 53, 147, 0.03) !important;
}
.circle {
  height: 63 * $rem;
  width: 63 * $rem;
  border-radius: 100%;
  background-color: #ffffff;
  margin-left: 36 * $rem;
  background-size: cover;
  background-repeat: no-repeat;
}
.log-entries-header {
  color: $blue-primary;
  font-size: 0.875rem;
  height: 40px;
  margin-top: 50px;
  :nth-child(1) {
    margin-left: 146 * $rem;
  }
  :nth-child(2) {
    margin-left: 55 * $rem;
  }
  :nth-child(3) {
    margin-left: 122 * $rem;
  }
  :nth-child(4) {
    margin-left: 80 * $rem;
  }
  :nth-child(5) {
    margin-left: 62 * $rem;
  }
  :nth-child(6) {
    margin-left: 325 * $rem;
  }
  :nth-child(7) {
    margin-left: 80 * $rem;
  }
}

.in-icon {
  // display: none;
  margin-left: -9 * $rem;
}
.out-icon {
  margin-right: -10 * $rem;
}
// .commentator-name {
//     margin-left: 0 * $rem;
// }
.registrered-time {
  margin-left: 58 * $rem;
}
.comment-content {
  width: 380px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.assign-button {
  background-color: rgba($blue-primary, 0.5);
  color: #ffffff;
  height: 40px;
  width: 100px;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
}
.alternative-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  h1 {
    font-size: 2.1875rem;
    color: rgba($blue-primary, 0.5);
  }
}
</style>
