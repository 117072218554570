<template>
  <div class="nodes">
    <permission-nodes-container
      :nodes="filteredNodes"
      @select-node="selectNode"
      @edit-node="(node) => $emit('edit-node', node)"
      @select-permission-from-node="selectPermissionFromNode"
      @add-group="addGroup"
      @remove-group="removeGroup"
      :for-assign="forAssign"
      :selected-permissions="selectedPermissions"
      @update-permissions="$emit('update-permissions')"
    />
    <div class="divider" v-if="forAssign"></div>
    <div class="header" v-if="forAssign">Permissions Without Nodes</div>
    <div class="without-permissions" v-if="forAssign">
      <div
        class="permission-column"
        v-for="permission in permissionWithoutNodes"
        :key="permission.ID"
      >
        <permission-checkbox
          :number="permission.ID"
          :name="permission.Name"
          @click.native="selectPermission(permission.ID)"
          :value="selectedPermissions.has(permission.ID)"
          :permission="permission"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance";
import PermissionNodesContainer from "@/components/Permissions/PermissionNodes/PermissionNodesContainer";
import PermissionCheckbox from "@/components/Permissions/PermissionCheckbox";

export default {
  components: { PermissionCheckbox, PermissionNodesContainer },
  props: {
    type: String,
    id: Number,
    forAssign: Boolean,
    permissionWithoutNodes: Array,
    nodes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedPermissions: new Set([]),
      firstLoad: true,
    };
  },
  computed: {
    filteredNodes() {
      return this.nodes;
    },
  },
  methods: {
    selectPermission(id) {
      if (this.selectedPermissions.has(id)) {
        this.selectedPermissions.delete(id);
      } else {
        this.selectedPermissions.add(id);
      }
      this.selectedPermissions = new Set(this.selectedPermissions);
      this.updatePermissions();
    },
    selectNode(node) {
      if (this.selectedPermissions.has(node.ID)) {
        this.selectedPermissions.delete(node.ID);
        node.Permissions.forEach((el) => {
          this.selectedPermissions.delete(el.ID);
        });
      } else {
        this.selectedPermissions.add(node.ID);
        node.Permissions.forEach((el) => {
          this.selectedPermissions.add(el.ID);
        });
      }
      this.selectedPermissions = new Set(this.selectedPermissions);
      this.updatePermissions();
    },
    selectPermissionFromNode({ node, permission }) {
      if (this.selectedPermissions.has(node)) {
        this.selectedPermissions.delete(node);
      }
      if (this.selectedPermissions.has(permission)) {
        this.selectedPermissions.delete(permission);
      } else {
        this.selectedPermissions.add(permission);
      }
      this.selectedPermissions = new Set(this.selectedPermissions);
      this.checkIfAllInNode(node);
      this.updatePermissions();
    },
    addGroup({ group, node }) {
      //add
      if (this.selectedPermissions.has(node)) {
        this.selectedPermissions.delete(node);
      }
      group.forEach((el) => this.selectedPermissions.add(el.ID));
      this.selectedPermissions = new Set(this.selectedPermissions);
      this.checkIfAllInNode(node);
      this.updatePermissions();
    },
    removeGroup({ group, node }) {
      if (this.selectedPermissions.has(node)) {
        this.selectedPermissions.delete(node);
      }
      group.forEach((el) => this.selectedPermissions.delete(el.ID));
      this.selectedPermissions = new Set(this.selectedPermissions);
      this.checkIfAllInNode(node);
      this.updatePermissions();
    },
    checkIfAllInNode(nodeId) {
      const node = this.nodes.filter((el) => el.ID === nodeId)[0];
      let nodeActivePermissions = 0;
      const nodePermissionCount = node.PermissionCount;

      node.Permissions.forEach((el) => {
        if (this.selectedPermissions.has(el.ID)) {
          nodeActivePermissions += 1;
        }
      });
      if (nodeActivePermissions === nodePermissionCount) {
        this.selectedPermissions.add(nodeId);
      }
      this.selectedPermissions = new Set(this.selectedPermissions);
    },
    async getPersonPermissions() {
      const response = await axios.get("Permission/GetPersonPermissions", {
        params: {
          ID: this.id,
        },
      });
      const { Value: data } = response.data;
      const nodeList = data.filter((el) => el.IsNode);
      const permissions = data.filter((el) => !el.IsNode);
      permissions.forEach((el) => this.selectedPermissions.add(el.ID));
      nodeList.forEach((el) => {
        const singleNode = this.nodes.filter((node) => node.ID === el.ID)[0];
        if (!singleNode) return;
        const nodePermissions = singleNode.Permissions;
        nodePermissions.forEach((permission) =>
          this.selectedPermissions.add(permission.ID)
        );
        this.checkIfAllInNode(el.ID);
      });
    },
    async getRolePermissions() {
      const response = await axios.get("Permission/GetRolePermmissions", {
        params: {
          ID: this.id,
        },
      });

      const { Value: data } = response.data;
      const nodeList = data.filter((el) => el.IsNode);
      const permissions = data.filter((el) => !el.IsNode);
      permissions.forEach((el) => this.selectedPermissions.add(el.ID));
      nodeList.forEach((el) => {
        const nodePermissions = this.nodes.filter(
          (node) => node.ID === el.ID
        )[0].Permissions;
        nodePermissions.forEach((permission) =>
          this.selectedPermissions.add(permission.ID)
        );
        this.checkIfAllInNode(el.ID);
      });
    },
    async getJobPermissions() {
      const response = await axios.get("Permission/GetJobPermissions", {
        params: {
          ID: this.id,
        },
      });

      const { Value: data } = response.data;
      const nodeList = data.filter((el) => el.IsNode);
      const permissions = data.filter((el) => !el.IsNode);
      permissions.forEach((el) => this.selectedPermissions.add(el.ID));
      nodeList.forEach((el) => {
        const nodePermissions = this.nodes.filter(
          (node) => node.ID === el.ID
        )[0].Permissions;
        nodePermissions.forEach((permission) =>
          this.selectedPermissions.add(permission.ID)
        );
        this.checkIfAllInNode(el.ID);
      });
    },
    async updatePermissions() {
      const selectedPermissions = new Set(this.selectedPermissions);
      this.nodes.forEach((el) => {
        if (selectedPermissions.has(el.ID)) {
          el.Permissions.forEach((perm) => {
            selectedPermissions.delete(perm.ID);
          });
        }
      });

      if (this.type === "person") {
        await axios.post("Permission/AssignPersonPermission", {
          Person: this.id,
          PermissionsTVP: [...selectedPermissions].map((el) => ({
            Permission: el,
          })),
        });
      } else if (this.type === "role") {
        await axios.post("Permission/AssignRolePermissions", {
          Role: this.id,
          PermissionsTVP: [...selectedPermissions].map((el) => ({
            Permission: el,
          })),
        });
      } else if (this.type === "job") {
        await axios.post("Permission/AssignJobPermission", {
          Job: this.id,
          PermissionsTVP: [...selectedPermissions].map((el) => ({
            Permission: el,
          })),
        });
      }
    },
  },
  watch: {
    type: {
      async handler() {
        if (this.forAssign) {
          if (this.type === "role") {
            this.firstLoad = false;
            await this.getRolePermissions();
          } else if (this.type === "job") {
            this.firstLoad = false;
            await this.getJobPermissions();
          } else if (this.type === "person") {
            this.firstLoad = false;
            await this.getPersonPermissions();
          }
        }
      },
      immediate: true,
    },
    selectedPermissions: {
      handler() {
        this.filteredNodes.forEach((node) => {
          let nodeActivePermissions = 0;
          node.Permissions.forEach((el) => {
            if (this.selectedPermissions.has(el.ID)) {
              nodeActivePermissions += 1;
            }
          });
          node.PermissionActiveCount = nodeActivePermissions;
          this.$set(this.filteredNodes, this.filteredNodes.indexOf(node), node);
        });
      },
    },
  },
};
</script>

<style scoped lang="scss">
.nodes {
  height: calc(100% - 50px);
  .header {
    color: #ff5f58;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .divider {
    width: 100%;
    background-color: rgba(#9696b9, 0.3);
    margin-bottom: 13px;
    height: 2px;
  }

  &__permissions {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    height: 220 * $rem;
  }

  .permission-column {
    display: flex;
    flex-wrap: wrap;
    margin-right: 20px;
    //margin-bottom: 10px;
    width: 250px;
    height: 50px;
    font-size: 14px;

    .permission-checkbox {
      width: 250px;
      margin-right: 10 * $rem;
      margin-top: 10 * $rem;
    }
  }
}

.without-permissions {
  flex-flow: row wrap;
  display: flex;
  max-height: calc(100% - 490px);
}
</style>
