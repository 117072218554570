<template>
  <div
    class="section"
    :style="{
      width:
        ((isNightTime ? adjustedTo - adjustedFrom : to - from) * 100) /
          (24 * 60) +
        '%',
      left: ((isNightTime ? adjustedFrom : from) * 100) / (24 * 60) + '%',
    }"
    :class="type"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    from: Number,
    to: Number,
    type: String,
    text: String,
    isNightTime: Boolean,
  },
  computed: {
    adjustedFrom() {
      return this.adjust(this.from)
    },
    adjustedTo() {
      return this.adjust(this.to)
    },
  },
  methods: {
    adjust(time) {
      return (time + 12 * 60) % (24 * 60)
    },
  },
}
</script>

<style scoped lang="scss">
.section {
  position: absolute;
  height: 100%;

  &.schedule {
    background-color: #8af0da;
  }

  &.missed {
    background-color: #f8d2d8;
  }
  &.today {
    background: #ebebf6;
  }

  &.inactive {
    background-color: #6cb8c5;
  }

  &.overtime {
    background-color: #b5f3e8;
  }

  &.out {
    background-color: #ededfc;
    border: $rem solid $blue-primary;
  }

  &.vacation {
    background-color: #a4a9d3;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f7f7fe;
    font-weight: 300;
    font-size: 14 * $rem;
    letter-spacing: 0.8 * $rem;
  }

  &.blank {
    background-color: #ebebf6;
  }
}
</style>
