import { store } from '@/store.js'

function showSuccess(message) {
    store.commit("showMessage", {
        text: message,
        purpose: "success",
    })
}

function showError(message) {
    store.commit("showMessage", {
        text: message,
        purpose: "error",
    })
}

export {
    showSuccess,
    showError,
}