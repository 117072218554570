<template>
  <div class="background">
    <div>
      <div class="team-users-header">
        <div class="left-side-header">
          <div>
            Total Employee <span class="count"> {{ peopleCount }}</span>
          </div>
          <!-- <span class="users-header" v-if="filteredList.length">{{
            usersHeader
          }}</span> -->
        </div>
        <div class="right-side-header">
          <form class="search-form">
            <input placeholder="Search ..." v-model="searchPeople" />
          </form>
        </div>
      </div>
      <div class="for-position">
        <div class="teams-users-content">
          <div class="heads-content">
            <div
              class="person-content"
              v-for="person in filteredHeadsList"
              :key="person.index"
              @click="selectId(person.personID)"
              @mouseenter="hoveredAvatar = person.personID"
              @mouseleave="(hoveredAvatar = null), (personId = '')"
            >
              <div class="avatar-wrapper">
                <img
                  v-if="hoveredAvatar !== person.personID"
                  @error="failedAvatars.push(person.personID)"
                  v-show="!failedAvatars.includes(person.personID)"
                  :src="getAvatar(person.personID)"
                />

                <img
                  v-else
                  :src="
                    !$store.state.isProduction
                      ? `https://hr.upgaming.dev/api/Person/GetPersonAvatarOriginal?ID=${person.personID}`
                      : `https://hr.upgaming.com/api/Person/GetPersonAvatarOriginal?ID=${person.personID}`
                  "
                />
                <div
                  v-show="failedAvatars.includes(person.personID)"
                  class="circle"
                ></div>
              </div>

              <span class="name"> {{ person.fullName }}</span>
              <div class="job-title">{{ person.subName }}</div>
              <div
                class="person-info-content"
                v-if="personId == person.personID"
              >
                <div class="name">
                  {{ personDetails.FirstName + " " + personDetails.LastName }}
                </div>
                <div class="job-name">{{ personDetails.JobName }}</div>
                <div class="info-type">
                  <span>Birth date </span>
                  <span v-if="personDetails.BirthDate" class="birth-date">{{
                    computedDateText(personDetails.BirthDate)
                  }}</span
                  ><span v-else>Not Public</span>
                </div>
                <div class="info-type">
                  <span>Mob 1</span>
                  <span v-if="personDetails.MobOne">{{
                    personDetails.MobOne
                  }}</span
                  ><span v-else>Not Public</span>
                </div>
                <div class="info-type">
                  <span>Mob 2</span>
                  <span v-if="personDetails.MobTwo">{{
                    personDetails.MobTwo
                  }}</span>
                  <span v-else>Not Public</span>
                </div>
                <div
                  @click="copyToClipboard(personDetails.Mail)"
                  class="info-type"
                >
                  <span>E-mail 1</span>
                  <span v-if="personDetails.Mail">{{
                    personDetails.Mail
                  }}</span>
                  <span class="not-public" v-else>Not Public</span>
                  <img
                    v-if="!snackbarVisible"
                    class="copy-icon"
                    src="@/assets/content_copy.svg"
                    alt=""
                  />
                </div>
                <div
                  @click="copyToClipboard(personDetails.MailTwo)"
                  class="info-type"
                >
                  <span>E-mail 2</span>
                  <span v-if="personDetails.MailTwo">{{
                    personDetails.MailTwo
                  }}</span>
                  <span class="not-public" v-else>Not Public</span>
                  <img
                    v-if="!snackbarVisible"
                    class="copy-icon"
                    src="@/assets/content_copy.svg"
                    alt=""
                  />
                </div>
                <div
                  @click="copyToClipboard(personDetails.Skype)"
                  class="info-type"
                >
                  <span>Skype</span>
                  <span id="skype" v-if="personDetails.Skype">{{
                    personDetails.Skype
                  }}</span>
                  <span class="not-public" v-else>Not Public</span>
                  <img
                    v-if="!snackbarVisible"
                    class="copy-icon"
                    src="@/assets/content_copy.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="employees-content">
            <div
              class="person-content"
              v-for="person in filteredList"
              :key="person.index"
              @click="selectId(person.personID)"
              @mouseenter="hoveredAvatar = person.personID"
              @mouseleave="(hoveredAvatar = null), (personId = '')"
            >
              <div class="avatar-wrapper">
                <img
                  v-if="hoveredAvatar !== person.personID"
                  @error="failedAvatars.push(person.personID)"
                  v-show="!failedAvatars.includes(person.personID)"
                  :src="getAvatar(person.personID)"
                />

                <img
                  v-else
                  :src="
                    !$store.state.isProduction
                      ? `https://hr.upgaming.dev/api/Person/GetPersonAvatarOriginal?ID=${person.personID}`
                      : `https://hr.upgaming.com/api/Person/GetPersonAvatarOriginal?ID=${person.personID}`
                  "
                />

                <div
                  v-show="failedAvatars.includes(person.personID)"
                  class="circle"
                ></div>
              </div>

              <span class="name"> {{ person.fullName }}</span>
              <div class="job-title">{{ person.subName }}</div>
              <div
                class="person-info-content"
                v-if="personId == person.personID"
              >
                <div class="name">
                  {{ personDetails.FirstName + " " + personDetails.LastName }}
                </div>
                <div class="job-name">{{ personDetails.JobName }}</div>
                <div class="info-type">
                  <span>Birth date </span>
                  <span v-if="personDetails.BirthDate" class="birth-date">{{
                    computedDateText(personDetails.BirthDate)
                  }}</span
                  ><span v-else>Not Public</span>
                </div>
                <div class="info-type">
                  <span>Mob 1</span>
                  <span v-if="personDetails.MobOne">{{
                    personDetails.MobOne
                  }}</span
                  ><span v-else>Not Public</span>
                </div>
                <div class="info-type">
                  <span>Mob 2</span>
                  <span v-if="personDetails.MobTwo">{{
                    personDetails.MobTwo
                  }}</span>
                  <span v-else>Not Public</span>
                </div>
                <div
                  @click="copyToClipboard(personDetails.Mail)"
                  class="info-type"
                >
                  <span>E-mail 1</span>
                  <span v-if="personDetails.Mail">{{
                    personDetails.Mail
                  }}</span>
                  <span class="not-public" v-else>Not Public</span>
                  <img
                    v-if="!snackbarVisible"
                    class="copy-icon"
                    src="@/assets/content_copy.svg"
                    alt=""
                  />
                </div>
                <div
                  @click="copyToClipboard(personDetails.MailTwo)"
                  class="info-type"
                >
                  <span>E-mail 2</span>
                  <span v-if="personDetails.MailTwo">{{
                    personDetails.MailTwo
                  }}</span>
                  <span class="not-public" v-else>Not Public</span>
                  <img
                    v-if="!snackbarVisible"
                    class="copy-icon"
                    src="@/assets/content_copy.svg"
                    alt=""
                  />
                </div>
                <div
                  @click="copyToClipboard(personDetails.Skype)"
                  class="info-type"
                >
                  <span>Skype</span>
                  <span id="skype" v-if="personDetails.Skype">{{
                    personDetails.Skype
                  }}</span>
                  <span class="not-public" v-else>Not Public</span>
                  <img
                    v-if="!snackbarVisible"
                    class="copy-icon"
                    src="@/assets/content_copy.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <custom-snackbar
            v-if="snackbarVisible"
            :purpose="snackbarPurpose"
            :text="snackbarText"
            @close="snackbarVisible = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance.js";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";

export default {
  components: {
    CustomSnackbar,
  },
  created() {
    console.log(this.$store.state.isProduction);
  },
  data() {
    return {
      hoveredAvatar: null,

      jobPersons: [],
      departmentPeople: [],
      personDetails: [],
      searchPeople: "",
      personId: "",
      failedAvatars: [],
      dateValue: "",
      snackbarVisible: false,
    };
  },
  props: {
    data: {
      type: Object,
    },
  },

  methods: {
    selectId(id) {
      this.personId = id;
      this.getPersonsInfo(this.personId);
    },
    getAvatarOriginal(id) {
      this.hoveredAvatar = id;

      // axios.get(`Person/GetPersonAvatarOriginal?ID=${id}`).then((resp) => {});
      // console.log("test");
    },
    getPersons() {
      axios
        .get("jobs/JobPersons", {
          params: {
            JobID: this.$route.query.jobId,
          },
        })
        .then((resp) => {
          this.jobPersons = resp.data.Value;
        });
    },
    getDepartmentPersons() {
      axios
        .get("departments/GetDepartmentPeople", {
          params: {
            department: this.$route.query.departmentId,
          },
        })
        .then((response) => {
          this.departmentPeople = response.data.Value;
        });
    },
    getPersonsInfo(id) {
      axios
        .get(`Person/GetPersonsBasicContacts?Person=${id}`)
        .then((response) => {
          this.personDetails = response.data.Value;
        });
    },
    getAvatar(personID) {
      return this.$store.state.getAvatar + personID;
    },
    computedDateText(x) {
      return new Date(x).toDateString().split(" ").slice(1).join(" ");
    },
    copyToClipboard(target) {
      let textArea = document.createElement("textarea");
      textArea.value = target;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("Copy");
      textArea.remove();
      if (target) {
        this.snackbarText = "Copied to clipboard";
        this.snackbarPurpose = "success";
        this.snackbarVisible = true;
      }
      setTimeout(() => {
        this.snackbarVisible = false;
      }, 700);
    },
  },
  computed: {
    peopleCount() {
      return this.data.DepHeads.length + this.data.DepPersons.length;
    },
    // usersHeader() {
    //   if (this.$route.query.jobId) {
    //     return this.jobPersons[0].DepartmentName;
    //   } else {
    //     return this.departmentPeople[0].DepartmentName;
    //   }
    // },
    filteredHeadsList() {
      return this.data.DepHeads.filter((el) => {
        let fullName = el.HeadFirstName + " " + el.HeadLastName;
        return fullName.toLowerCase().includes(this.searchPeople.toLowerCase());
      }).map((el) => {
        return {
          fullName: el.HeadFirstName + "  " + el.HeadLastName,
          subName: "",
          personID: el.HeadID,
        };
      });
    },
    filteredList() {
      if (this.data.DepPersons.length < 1) return;
      let result = this.data.DepPersons.filter((person) => {
        let fullName = person.FirstName + " " + person.LastName;
        return fullName.toLowerCase().includes(this.searchPeople.toLowerCase());
      }).map((el) => {
        return {
          fullName: el.FirstName + "  " + el.LastName,
          subName: el.JoBName,
          personID: el.PersonID,
        };
      });

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.employees-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 290px;
}
.background {
  overflow: hidden;
  > div {
    height: 100%;
  }
}
.heads-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 50px;
}
.person-content {
  text-align: center;
  &:hover {
    .avatar-wrapper {
      transition: transform 0.2s;
      transform: scale(1.2);
    }
  }
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  position: relative;
  cursor: pointer;
  .avatar-wrapper {
    width: 130px;
    height: 130px;
    background-color: #ffffff;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
  img {
    width: 100%;
  }

  .name {
    display: inline-block;
    margin-top: 20px;
    font-size: 16px;
    color: $blue-primary;
    font-weight: 400;
  }
  .job-title {
    margin-top: 8px;
    font-size: 15px;
    font-weight: 400;
    color: #9696b9;
  }
  .circle {
    border-radius: 100%;
    background-color: #ffffff;
    height: 130px;
    width: 130px;
  }
}
.person-content:nth-child(5n + 5) {
  .person-info-content {
    left: -85px;
    top: 129px;
  }
}
.teams-users-content {
  display: flex;
  flex-wrap: wrap;
  // height: calc(100% - 100px);
  // overflow-y: auto;
}
.team-users-header {
  color: $blue-primary;
  padding-left: 100px;
  padding-right: 100px;

  margin-top: 50px;
  display: flex;
  justify-content: space-between;

  div {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 15px;
    span {
      font-weight: 700;
    }
  }
  .users-header {
    font-weight: 500;
    font-size: 19px;
  }
}
form {
  display: flex;
  margin-left: auto;

  height: 38 * $rem;

  input {
    outline: none;
    border: none;
    border-radius: 3 * $rem;
    padding-left: 23 * $rem;
    font-size: 14 * $rem;
    color: $blue-primary;
    background-color: #f8f8fe;
    width: 272 * $rem;

    &::placeholder {
      color: rgba($blue-primary, 0.6);
    }
  }
}
.for-position {
  position: relative;
  height: calc(100% - 100px);
  overflow-y: auto;
}
.person-info-content {
  position: absolute;
  left: 96px;
  top: 150px;
  z-index: 5;
  text-align: left;
  background-color: white;
  overflow: auto;
  height: 325 * $rem;
  width: 375 * $rem;
  border-radius: 6px;
  padding-left: 30px;
  .name {
    font-size: 16px;
    font-weight: 400;
    margin-top: 26px;
  }
  .job-name {
    color: #9696b9;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 27px;
  }
  .info-type {
    span {
      margin-bottom: 18px;
    }
    span:nth-child(1) {
      color: rgba($blue-primary, 0.5);
      font-size: 13px;
      font-weight: 300;
      width: 62px;
      display: inline-block;
    }
    span:nth-child(2) {
      font-weight: 300;
      font-size: 15px;
      color: #283593;
    }

    &:hover .copy-icon {
      display: block;
    }

    .not-public + .copy-icon {
      display: none !important;
    }

    .copy-icon {
      width: 1rem;
      float: right;
      margin-right: 1.25rem;
      display: none;
      opacity: 0.5;
      filter: invert(21%) sepia(27%) saturate(3605%) hue-rotate(214deg)
        brightness(96%) contrast(104%);
    }
  }
}
.birth-date {
  margin-left: 5px;
}
</style>

