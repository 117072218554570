<template>
  <div class="day">
    <div class="day-header" :style="[!bigVersion ? { height: '5rem' } : null]">
      <span
        class="day"
        :style="[!bigVersion ? { 'margin-top': '0px' } : null]"
        :class="{ yesterday: isYesterday(date) }"
        >{{ computedDay }}</span
      >
      <span
        class="date"
        :style="[!bigVersion ? { 'font-size': '17px' } : null]"
        >{{ computedDate }}</span
      >
    </div>
    <div
      class="chart-container"
      :class="{ middle: middle, withBorder: bigVersion }"
      :style="[
        !bigVersion
          ? { height: 'calc(100% - 80 * 0.0625rem)', 'padding-bottom': '10px' }
          : null,
      ]"
    >
      <span v-if="!vacation" class="worked">{{ computedWork }}</span>
      <span
        class="worked"
        :style="[!bigVersion ? { 'font-size': '16px' } : null]"
        v-else
        >{{ vacationName }}</span
      >
      <span
        class="difference"
        v-if="
          (vacation == true && work > 0) ||
          (vacation == true && work == 0 && overtime > 0) ||
          vacation == false
        "
        :class="{ negative: differenceIsNegative() }"
        >{{ computedDifference }}</span
      >
      <attendance-chart
        :work="work"
        :overtime="overtime"
        :totalWork="totalWork"
      />
      <attendance-in-out
        :style="[!bigVersion ? { 'margin-top': '14px' } : null]"
        :in-outs="inOuts"
      />
    </div>
  </div>
</template>

<script>
import AttendanceInOut from "@/components/Dashboard/AttendanceInOut";
import AttendanceChart from "@/components/Dashboard/AttendanceChart";
export default {
  components: {
    AttendanceInOut,
    AttendanceChart,
  },
  props: {
    middle: Boolean,
    date: Date,
    work: Number,
    overtime: Number,
    totalWork: Number,
    vacationName: String,
    inOuts: Array,
    vacation: Boolean,
    bigVersion: Boolean,
  },
  computed: {
    computedDay() {
      const INITIALS = {
        0: "SUN",
        1: "MON",
        2: "TUE",
        3: "WED",
        4: "THU",
        5: "FRI",
        6: "SAT",
      };
      if (this.isYesterday(this.date)) return "Yesterday";
      return INITIALS[this.date.getDay()];
    },
    computedDate() {
      const M = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return `${M[this.date.getMonth()]} ${this.date.getDate()}`;
    },
    computedWork() {
      var hr = Math.floor(this.work / 60);
      var mm = this.work % 60;
      if (mm) return `${hr} hr ${mm} mm`;
      return hr ? `${hr} hr` : "";
    },
    computedDifference() {
      var difference = this.work + this.overtime - this.totalWork;

      var hr = Math.abs(parseInt(difference / 60));
      var mm = Math.abs(difference % 60);
      var sign = this.differenceIsNegative() ? "-" : "+";
      if (!hr && !mm) return "";
      if (mm) return `${sign} ${hr} hr ${mm} mm`;
      return `${sign} ${hr} hr`;
    },
  },
  methods: {
    isYesterday(date) {
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if (
        yesterday.getFullYear() == date.getFullYear() &&
        yesterday.getMonth() == date.getMonth() &&
        yesterday.getDate() == date.getDate()
      )
        return true;
      return false;
    },
    differenceIsNegative() {
      return this.work + this.overtime - this.totalWork < 0;
    },
  },
};
</script>

<style scoped lang="scss">
.day {
  width: 164 * $rem;
  display: flex;
  flex-direction: column;
  font-weight: 300;

  .day-header {
    height: 112 * $rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 16 * $rem;
    color: $blue-primary;
    align-items: center;
    text-align: center;
    border-bottom: 1 * $rem solid #eeeff7;

    .day {
      width: 110 * $rem;
      height: 44 * $rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8 * $rem;

      &.yesterday {
        background-color: #939ac9;
        border-radius: 22 * $rem;
        color: white;
      }
    }

    .date {
      font-size: 20 * $rem;
    }
  }

  .chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    height: calc(100% - 112 * #{$rem});
    justify-content: flex-end;
    padding-bottom: 25 * $rem;

    .worked {
      font-size: 20 * $rem;
      color: $blue-primary;
      margin-top: 30 * $rem;
    }

    .difference {
      font-size: 14 * $rem;
      margin-top: 15 * $rem;
      color: #0fcb9c;
      font-weight: 500;
      height: 17 * $rem;

      &.negative {
        color: #db526a;
        font-weight: 300;
      }
    }

    &.middle {
      border-left: 1 * $rem solid #eeeff7;
    }
  }
}
.day .chart-container.middle.withBorder {
  border-right: 1 * $rem solid #eeeff7;
}
</style>