<template>
  <div class="activity-calendar">
    <span v-if="monitoringCalendar == false" class="chosen-value">{{
      computedDateText
    }}</span>
    <el-date-picker
      v-model="dateValue"
      type="date"
      placeholder="Pick a day"
      ref="input"
    >
    </el-date-picker>
    <img
      v-if="trafficCalendar"
      class="traffic-calendar-icon"
      src="@/assets/icons/calendarSecondIcon.svg"
      @click.stop="iconClickHandler"
    />
    <img
      v-else
      class=""
      src="@/assets/icons/calendar.svg"
      @click.stop="iconClickHandler"
    />
  </div>
</template>

<script>
export default {
  props: {
    monitoringCalendar: Boolean,
    trafficCalendar: Boolean,
  },
  created() {
    var d = new Date();
    if (!this.trafficCalendar) {
      d.setDate(d.getDate() - 1); //yesterday
    } else {
      d.setDate(d.getDate());
    }
    this.$nextTick(() => {
      this.dateValue = d; //in "nextTick" to trigger "watch". Could also emit from "created" - no difference
    });
  },
  data() {
    return {
      dateValue: "",
    };
  },
  methods: {
    iconClickHandler() {
      this.$refs.input.focus();
    },
  },
  computed: {
    computedDateText() {
      return new Date(this.dateValue)
        .toDateString()
        .split(" ")
        .slice(1)
        .join(" ");
    },
  },
  watch: {
    dateValue(newValue) {
      this.$emit("dateChange", newValue);
    },
  },
};
</script>

<style scoped lang="scss">
.activity-calendar {
  display: flex;
  position: relative;
  align-items: center;

  .el-input {
    width: 0;
    position: absolute;
    opacity: 0;
    right: 62 * $rem;
  }

  HEAD .chosen-value {
    font-size: 20 * $rem;
    color: $blue-primary;
    font-weight: 300;
    margin-right: 15 * $rem;
  }
  .chosen-value {
    font-size: 20 * $rem;
    color: $blue-primary;
    font-weight: 300;
    // margin-right: 15 * $rem;
  }
  img {
    width: 45 * $rem;
    cursor: pointer;
    z-index: 1;
  }
}
.traffic-calendar-icon {
  height: 25px;
  width: 25px;
}
</style>