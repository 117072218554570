<template>
  <div
    class="bookmark-main-container"
    :class="{ 'user-main-container': isTimeLineShown }"
  >
    <div class="bookmarks" :class="{ 'user-bookmarks-width': isTimeLineShown }">
      <div
        class="for-slider"
        ref="slider"
        :style="{ 'margin-left': -bookmarkSliderIndex * 200 + 'px' }"
      >
        <div
          v-for="folder in bookmarks.Folders"
          :key="folder.index"
          class="folder-icon-content"
          @drop="diffDrop(folder)"
          @dragenter.prevent
          @dragover.prevent
        >
          <div
            class="clickable-content"
            @mouseenter="folder.showDeleteIcon = true"
            @mouseleave="folder.showDeleteIcon = false"
            @click="getFolderBookmarks(folder.FolderID, folder)"
          >
            <span class="material-icons"> folder </span>
            <span class="folder-name">{{ folder.Name }}</span>
            <span
              v-if="folder.showDeleteIcon"
              @click.stop.prevent="deleteFolder(folder.FolderID)"
              class="material-icons close"
            >
              close</span
            >
          </div>
          <folder-bookmarks
            @close-bookmarks="folder.showBookmarks = false"
            v-if="folder.showBookmarks"
            :data="folderLinks"
            @folder-bookmark-drag-started="startFolderBookmarkDrag"
            @delete-clicked="deleteFolderBookmark"
          />
        </div>
        <div
          class="single-bookmark-content"
          @dragenter.prevent
          @dragover.prevent
          @drop="dropOutsideFolder"
        >
          <div class="invisible-content">'</div>
          <single-bookmark
            v-for="(bookmark, ind) in bookmarks.BookMarksWithoudFolder"
            :key="ind"
            :icon="getObjectIcon(bookmark.URL)"
            :link="bookmark.URL"
            :title="bookmark.Title"
            @delete-clicked="deleteBookmark(bookmark.ID)"
            draggable="true"
            @drag-started="startDrag(bookmark)"
          />
        </div>
      </div>

      <custom-snackbar
        v-if="snackbarVisible"
        :purpose="snackbarPurpose"
        :text="snackbarText"
        @close="snackbarVisible = false"
      />
    </div>
    <div class="bookmark-addition-content">
      <div
        class="arrows"
        v-if="
          bookmarks.BookMarksWithoudFolder &&
          bookmarks.Folders &&
          bookmarks.BookMarksWithoudFolder.length + bookmarks.Folders.length > 7
        "
      >
        <span @click="leftArrowClick" class="material-icons">
          chevron_left
        </span>
        <span @click="rightArrowClick" class="material-icons">
          chevron_right
        </span>
      </div>
      <img
        class="bookmark-icon"
        src="@/assets/bookmarkIcon.svg"
        @click="starClicked = false"
        v-if="starClicked == true"
      />
      <img
        class="bookmark-icon"
        v-if="starClicked == false && !isTimeLineShown"
        src="@/assets/bookmarkWhiteICon.svg"
        @click.stop="starClickHandler"
      />
      <img
        class="bookmark-icon"
        name="userstar"
        v-if="starClicked == false && isTimeLineShown"
        src="@/assets/bookmarkWhiteICon.svg"
        @click.stop="userStarClickHandler"
      />
      <div
        class="bookmark-type-content"
        v-if="starClicked == true && !isTimeLineShown && getRole"
        :style="bookmarkTypeStyle"
      >
        <span @click.stop="userBookmarkClick"> Add bookmark to my account</span>
        <span @click.stop="jobBookmarkCreation = true">
          Add bookmarks to job</span
        >
      </div>
      <user-bookmark-creation
        v-if="userBookmark"
        @createBookmark="createBookmark"
        @close="closeBookmarkCreation"
        :position="elementPosition"
      >
        <custom-select
          title="Select folder"
          :options="folderOptions"
          v-model="choosenFolder"
        />
      </user-bookmark-creation>
      <job-bookmark-creation
        :position="elementPosition"
        v-if="jobBookmarkCreation"
        @assignToJob="assignToJob"
        @close="closeBookmarkCreation"
      >
        <input-search
          class="custom-search"
          title="Select job"
          :options="jobs"
          @select="(id) => (selectedJob = id)"
        />
        <custom-select
          class="custom-select"
          title="Select folder"
          :options="bookmarkFolders"
          v-model="choosenFolder"
        />
      </job-bookmark-creation>
    </div>
  </div>
</template>

<script>
import SingleBookmark from "@/components/Bookmarks/SingleBookmark";
import axios from "@/Helpers/AxiosInstance";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue";
import userBookmarkCreation from "@/components/Bookmarks/userBookmarkCreation.vue";
import jobBookmarkCreation from "@/components/Bookmarks/jobBookmarkCreation.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import FolderBookmarks from "@/components/Bookmarks/FolderBookmarks.vue";
import InputSearch from "@/components/ReusableComponents/InputSearch/InputSearch.vue";

export default {
  components: {
    SingleBookmark,
    CustomSelect,
    userBookmarkCreation,
    jobBookmarkCreation,
    CustomSnackbar,
    FolderBookmarks,
    InputSearch,
  },

  mounted() {
    this.getData();
    this.getJobs();
  },

  data() {
    return {
      bookmarks: [],
      bookmarkFolders: [],
      bookmarkSliderIndex: 0,
      folderLinks: [],
      jobBookmarkCreation: false,
      starClicked: false,
      newFolder: false,
      snackbarPurpose: "",
      snackbarText: "",
      snackbarVisible: false,
      choosenFolder: "",
      userBookmark: false,
      showBookmarks: false,
      type: "",
      link: "",
      showLinkInput: false,
      linkName: "",
      jobs: [],
      selectedJob: "",
      elementPosition: "",
      lastDraggedClass: "",
    };
  },

  methods: {
    getJobs() {
      axios.get("jobs/GetJobs").then((resp) => {
        this.jobs = resp.data.Value.Jobs.map((el) => ({
          title: el.Name,
          value: el.ID,
        }));
      });
    },
    getData() {
      axios
        .get("attendance/GetBookMarks", {
          params: {
            Person: this.$store.state.profile.ID,
          },
        })
        .then((resp) => {
          if (resp.data.ErrorMsg) return;
          this.bookmarks = resp.data.Value;
          this.bookmarks.Folders.forEach((folder) => {
            this.$set(folder, "showBookmarks", false);
            this.$set(folder, "showDeleteIcon", false);
          });
          this.bookmarkFolders = resp.data.Value.Folders.map((el) => {
            return {
              title: el.Name,
              value: el.FolderID,
            };
          });
        });
    },
    getFolderBookmarks(id, el) {
      axios
        .get(`attendance/GetFoldersBookMarks?FolderID=${id}`)
        .then((resp) => {
          el.showBookmarks = !el.showBookmarks;
          this.folderLinks = resp.data.Value;
          this.folderLinks.forEach((el) => {
            this.$set(el, "showDeleteICon", false);
          });
        });
    },
    userBookmarkClick() {
      this.userBookmark = true;
    },
    createBookmark(newBookmark) {
      if (newBookmark.newFolderName == "") {
        if (newBookmark.link != "") {
          axios
            .post("attendance/CreateBookMark", {
              Person: this.$store.state.profile.ID,
              URL: newBookmark.link,
              Title: newBookmark.linkName,
              FolderID: this.choosenFolder,
            })
            .then((resp) => {
              if (!resp.data.ErrorMsg) {
                this.userBookmark = false;
                this.starClicked = false;
                this.snackbarVisible = true;
                this.snackbarText = "Successfully saved!";
                this.snackbarPurpose = "success";
                this.choosenFolder = "";
                this.getData();
              } else {
                this.userBookmark = true;
                this.snackbarVisible = true;
                this.snackbarText = "Error!";
                this.snackbarPurpose = "error";
              }
            });
        }
      } else {
        axios
          .post("attendance/CreateBookMarksFolder", {
            Person: this.$store.state.profile.ID,
            Name: newBookmark.newFolderName,
            URL: newBookmark.link ? newBookmark.link : null,
            Title: newBookmark.linkName ? newBookmark.linkName : null,
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.userBookmark = false;
              this.snackbarVisible = true;
              this.snackbarText = "Successfully saved!";
              this.snackbarPurpose = "success";
              this.choosenFolder = "";
              this.starClicked = false;
              this.getData();
            } else {
              this.userBookmark = true;
              this.snackbarVisible = true;
              this.snackbarText = "Error!";
              this.snackbarPurpose = "error";
            }
          });
      }
    },
    assignToJob(linkName, link) {
      axios
        .post("attendance/AssignBookmarksToJob", {
          Job: this.selectedJob,
          FolderID: this.choosenFolder ? this.choosenFolder : null,
          Url: link,
          Title: linkName,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.jobBookmarkCreation = false;
            this.starClicked = false;
            this.snackbarVisible = true;
            this.snackbarText = "Successfully saved!";
            this.snackbarPurpose = "success";
            this.selectedJob = "";
            this.choosenFolder = "";
            this.getData();
          } else {
            this.jobBookmarkCreation = true;
            this.snackbarVisible = true;
            this.snackbarText = "Error!";
            this.snackbarPurpose = "error";
          }
        });
    },

    deleteBookmark(id) {
      this.$confirm("Are you sure  you want to delete this bookmark?").then(
        () => {
          axios
            .post("attendance/UpdatePersonBookMark", {
              PersonBookMark: id,
              Delete: true,
            })
            .then((resp) => {
              if (!resp.data.ErrorMsg) {
                this.snackbarVisible = true;
                this.snackbarText = "Successfully deleted!";
                this.snackbarPurpose = "success";
                this.getData();
              } else {
                this.snackbarVisible = true;
                this.snackbarText = "Error!";
                this.snackbarPurpose = "error";
              }
            });
        }
      );
    },
    deleteFolder(id) {
      this.$confirm("Are you sure  you want to delete this bookmark?").then(
        () => {
          axios
            .post("attendance/UpdateBookMarksFolder", {
              FolderID: id,
              Delete: true,
            })
            .then((resp) => {
              if (!resp.data.ErrorMsg) {
                this.snackbarVisible = true;
                this.snackbarText = "Successfully deleted!";
                this.snackbarPurpose = "success";
                this.getData();
              } else {
                this.snackbarVisible = true;
                this.snackbarText = "Error!";
                this.snackbarPurpose = "error";
              }
            });
        }
      );
    },
    deleteFolderBookmark(el) {
      this.$confirm("Are you sure  you want to delete this bookmark?").then(
        () => {
          axios
            .post("attendance/UpdatePersonBookMark", {
              Delete: true,
              PersonBookMark: el.BookMarkID,
            })
            .then((resp) => {
              if (!resp.data.ErrorMsg) {
                this.snackbarVisible = true;
                this.snackbarText = "Successfully deleted!";
                this.snackbarPurpose = "success";
              } else {
                this.snackbarVisible = true;
                this.snackbarText = "Error!";
                this.snackbarPurpose = "error";
              }
            });
        }
      );
    },
    getObjectIcon(url) {
      return `https://s2.googleusercontent.com/s2/favicons?domain_url=${url}`;
    },

    closeBookmarkCreation() {
      this.userBookmark = false;
      this.jobBookmarkCreation = false;
      this.starClicked = false;
      this.selectedJob = "";
      this.choosenFolder = "";
    },
    rightArrowClick() {
      var sliderRight = this.$el
        .querySelector(".for-slider")
        .getBoundingClientRect().right;
      var bookmarksRight = this.$el
        .querySelector(".bookmarks")
        .getBoundingClientRect().right;
      if (sliderRight < bookmarksRight) return;

      this.bookmarkSliderIndex++;
    },
    leftArrowClick() {
      if (this.bookmarkSliderIndex == 0) return;
      this.bookmarkSliderIndex--;
    },
    starClickHandler() {
      this.starClicked = true;
      let specifiedEl = event.target;
      this.elementPosition = specifiedEl.getBoundingClientRect().left;
      if (!this.getRole) {
        this.userBookmark = true;
      }
    },
    userStarClickHandler() {
      this.starClicked = true;
      this.userBookmark = true;
      let specifiedEl = event.target;
      this.elementPosition = specifiedEl.getBoundingClientRect().left;
    },
    startDrag(item) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("itemID", item.ID);

      this.lastDraggedClass = event.target.parentNode.className;
    },
    startFolderBookmarkDrag(el) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("folderID", el.FolderID);
      event.dataTransfer.setData("itemID", el.BookMarkID);

      this.lastDraggedClass = event.target.parentNode.className;
    },
    dropInOtherFolder(folder) {
      const itemID = event.dataTransfer.getData("itemID");
      const oldFolderId = event.dataTransfer.getData("folderID");

      axios
        .post("attendance/UpdateBookMarkFolder", {
          OldFolder: parseInt(oldFolderId),
          NewFolder: folder.FolderID,
          BookMark: parseInt(itemID),
        })

        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.snackbarVisible = true;
            this.snackbarText = "Successfully saved!";
            this.snackbarPurpose = "success";
            this.getFolderBookmarks(oldFolderId);
            this.getFolderBookmarks(folder.FolderID);
          } else {
            this.snackbarVisible = true;
            this.snackbarText = "Error!";
            this.snackbarPurpose = "error";
          }
        });
    },
    diffDrop(folder) {
      var parentClass = event.target.parentNode.className;
      if (this.lastDraggedClass == "single-bookmark-content") {
        this.dropInFolder(folder);
      } else if (this.lastDraggedClass == "bookmarks-content") {
        this.dropInOtherFolder(folder);
      }
    },
    dropInFolder(folder) {
      const itemID = event.dataTransfer.getData("itemID");
      axios
        .post("attendance/AssignBookMarksInFolder", {
          FolderID: folder.FolderID,
          BookMarkID: itemID,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.snackbarVisible = true;
            this.snackbarText = "Successfully saved!";
            this.snackbarPurpose = "success";
            this.getData();
          } else {
            this.snackbarVisible = true;
            this.snackbarText = "Error!";
            this.snackbarPurpose = "error";
          }
        });
    },
    dropOutsideFolder() {
      const itemID = event.dataTransfer.getData("itemID");
      const oldFolderId = event.dataTransfer.getData("folderID");
      axios
        .post("attendance/UpdateBookMarkFolder", {
          BookMark: parseInt(itemID),
          OldFolder: parseInt(oldFolderId),
          NewFolder: null,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.snackbarVisible = true;
            this.snackbarText = "Successfully saved!";
            this.snackbarPurpose = "success";
            this.getData();
          } else {
            this.snackbarVisible = true;
            this.snackbarText = "Error!";
            this.snackbarPurpose = "error";
          }
        });
    },
  },

  computed: {
    folderOptions() {
      return this.bookmarks.Folders.map((el) => ({
        title: el.Name,
        value: el.FolderID,
      }));
    },
    bookmarkTypeStyle() {
      return "left:" + (this.elementPosition - 200) + "px";
    },
    isTimeLineShown() {
      return this.$store.state.showTimeLine;
    },
    getRole() {
      if (
        this.$store.getters.hasRole("Admin") ||
        this.$store.getters.hasRole("Manager")
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bookmark-main-container {
  z-index: 35;
  position: fixed;
  top: 0;
  left: 245px;
  width: calc(100% - 245px);
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 31px;
}
.invisible-content {
  visibility: hidden;
}
.user-main-container {
  left: 93px;
  transition: 0.3s ease;
  width: 100%;
}
::v-deep .custom-search {
  width: 100% !important;
  margin-bottom: 15px;
  label {
    width: 100%;
  }
}
.user-bookmarks-width {
  width: 85% !important;
}
.single-bookmark-content {
  display: flex;
  min-width: 100%;
}
.bookmarks {
  display: flex;
  align-items: center;

  width: 90%;

  overflow-x: hidden;
  .for-slider {
    display: flex;
    align-items: center;
    transition: 0.3s ease;
    white-space: nowrap;
    min-width: 100%;
  }
}
.bookmark-addition-content {
  // margin-left: 61px;
  width: 10%;
  height: 100%;
  position: relative;

  position: relative;
  .bookmark-icon {
    cursor: pointer;
    right: 31px;
    top: 41%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  .bookmark-type-content {
    display: flex;
    flex-direction: column;
    position: fixed;
    box-shadow: 0.125rem 0.125rem 0.625rem 0.125rem rgba(40, 52, 147, 0.08);
    min-width: 245px;
    color: $blue-primary;
    background-color: white;
    border-radius: 5px;
    height: 60px;
    top: 25px;

    span {
      padding-left: 10px;
      margin-top: 7px;
      cursor: pointer;
      font-weight: 300;
      font-size: 15px;
      &:hover {
        font-weight: 400;
      }
    }
  }
  .save-button {
    margin-top: auto;
  }
}
.bookmarkFolder-content {
  margin-left: 5px;
  position: relative;
}
.material-icons {
  color: #f6eb9b;
}

.folder-icon-content {
  transition: all 0.2s ease-out;
  border-radius: 5px;
  position: relative;
  // margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
  &:not(:last-child) {
    &:after {
      content: "";
      position: absolute;
      right: -3px;
      height: 16px;
      width: 1px;
      top: 4px;
      background: rgba(0, 0, 0, 0.19);
    }
  }

  &:hover {
    background-color: #eeeeef;
    cursor: pointer;
  }
  .clickable-content {
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 25px;
    padding-left: 10px;
  }
  .clickable-content:last-child {
    .bookmark-content {
      left: -91px;
    }
  }
  .material-icons.close {
    color: $blue-primary;
    position: absolute;
    right: 2px;
    top: 0;
    font-size: 16px;
    color: $blue-primary;
  }
}

.folder-name {
  color: $blue-primary;
  font-size: 13px;
  font-weight: 300;
  margin-left: 3px;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.save-button {
  width: 60px;
  height: 30px;
  background-color: #8a91c7;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15 * $rem;
  border-radius: 3 * $rem;
  margin-left: auto;

  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }
}
.arrows {
  position: absolute;
  display: flex;
  right: 60px;
  top: 1px;
  span {
    cursor: pointer;
    z-index: 20;
    color: $blue-primary;
    opacity: 0.6;
  }
}
</style>