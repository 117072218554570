var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{ref:"newsContainer",staticClass:"news-polls-section",style:([!_vm.noBanner ? { height: '100%' } : null])},[(!_vm.onlyOffer && !_vm.haveOnlyOfferAndBanner)?_c('div',{staticClass:"news-and-polls",style:([!_vm.noBanner ? { height: '98%' } : null])},[_c('ul',{staticClass:"column-one"},_vm._l((_vm.itemsForColumnOne),function(el,index){return _c('li',{key:index,class:{ itemWithBanner: index % 2 }},[_c('div',{staticClass:"persons-banner-wrapper",on:{"click":function($event){return _vm.getSinglePollOrNews(el)}}},[(el.isPoll)?_c('img',{attrs:{"src":require('@/assets/icons/poll-flag-user.svg')}}):_vm._e(),(index % 2)?_c('div',{staticClass:"banner",style:({
                backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${
                  el.Poll || el.News
                }&PlacementType=${el.PlacementType})`,
              })}):_vm._e(),_c('div',{staticClass:"info-wrapper"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.getDateString(el.PublishDate)))]),_c('h2',[_vm._v(_vm._s(el.Title))]),_c('p',[_vm._v(_vm._s(_vm.htmlToText(el.Description)))])])]),_c('div',{staticClass:"persons-content",on:{"click":function($event){$event.stopPropagation();return _vm.audienceClickHandler(el)}}},[_vm._l((el.Visibility.slice(0, 6)),function(person){return _c('div',{key:person.Person,staticClass:"avatar",style:({
                'background-image': `url(https://intranet.upgaming.com/api/Person/GetPersonAvatar2?ID=${person.Person})`,
              })})}),(el.Visibility.length > 6)?_c('div',{staticClass:"count"},[_vm._v(" +"+_vm._s(el.Visibility.length - 6)+" ")]):_vm._e(),_c('audience-list',{attrs:{"data":_vm.listOfPersons,"placementId":el.News || el.Poll,"audienceVisible":_vm.audienceVisible}})],2)])}),0),_c('ul',{staticClass:"column-two"},_vm._l((_vm.itemsForColumnTwo),function(el,index){return _c('li',{key:index,class:{ itemWithBanner: !(index % 2) }},[_c('div',{staticClass:"persons-banner-wrapper",on:{"click":function($event){return _vm.getSinglePollOrNews(el)}}},[(el.isPoll)?_c('img',{attrs:{"src":require('@/assets/icons/poll-flag-user.svg')}}):_vm._e(),(!(index % 2))?_c('div',{staticClass:"banner",style:({
                backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${
                  el.Poll || el.News
                }&PlacementType=${el.PlacementType})`,
              })}):_vm._e(),_c('div',{staticClass:"info-wrapper"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.getDateString(el.PublishDate)))]),_c('h2',[_vm._v(_vm._s(el.Title))]),_c('p',[_vm._v(_vm._s(_vm.htmlToText(el.Description)))])])]),_c('div',{staticClass:"persons-content",on:{"click":function($event){$event.stopPropagation();return _vm.audienceClickHandler(el)}}},[_vm._l((el.Visibility.slice(0, 6)),function(person){return _c('div',{key:person.Person,staticClass:"avatar",style:({
                'background-image': `url(https://intranet.upgaming.com/api/Person/GetPersonAvatar2?ID=${person.Person})`,
              })})}),(el.Visibility.length > 6)?_c('div',{staticClass:"count"},[_vm._v(" +"+_vm._s(el.Visibility.length - 6)+" ")]):_vm._e(),_c('audience-list',{attrs:{"data":_vm.listOfPersons,"placementId":el.News || el.Poll,"audienceVisible":_vm.audienceVisible}})],2)])}),0),_c('ul',{staticClass:"column-three"},_vm._l((_vm.itemsForColumnThree),function(el,index){return _c('li',{key:index,class:{ itemWithBanner: index % 2 }},[_c('div',{staticClass:"persons-banner-wrapper",on:{"click":function($event){return _vm.getSinglePollOrNews(el)}}},[(el.isPoll)?_c('img',{attrs:{"src":require('@/assets/icons/poll-flag-user.svg')}}):_vm._e(),(index % 2)?_c('div',{staticClass:"banner",style:({
                backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${
                  el.Poll || el.News
                }&PlacementType=${el.PlacementType})`,
              })}):_vm._e(),_c('div',{staticClass:"info-wrapper"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.getDateString(el.PublishDate)))]),_c('h2',[_vm._v(_vm._s(el.Title))]),_c('p',[_vm._v(_vm._s(_vm.htmlToText(el.Description)))])])]),_c('div',{staticClass:"persons-content",on:{"click":function($event){$event.stopPropagation();return _vm.audienceClickHandler(el)}}},[_vm._l((el.Visibility.slice(0, 6)),function(person){return _c('div',{key:person.Person,staticClass:"avatar",style:({
                'background-image': `url(https://intranet.upgaming.com/api/Person/GetPersonAvatar2?ID=${person.Person})`,
              })})}),(el.Visibility.length > 6)?_c('div',{staticClass:"count"},[_vm._v(" +"+_vm._s(el.Visibility.length - 6)+" ")]):_vm._e(),_c('audience-list',{attrs:{"data":_vm.listOfPersons,"placementId":el.News || el.Poll,"audienceVisible":_vm.audienceVisible}})],2)])}),0)]):(_vm.haveOnlyOfferAndBanner)?_c('div',{staticClass:"no-data-text-bottom",style:({
        'background-image': `url(${require('@/assets/noPlacementBackground.png')})`,
      })},[_c('span',[_vm._v("No news or poll was found for you")])]):_c('div',{staticClass:"no-data-text",style:({
        'background-image': `url(${require('@/assets/noPlacementBackground.png')})`,
      })},[_c('span',[_vm._v("No news or poll was found for you")])]),_c('div',{staticClass:"poll-popup"},[(_vm.poll)?_c('custom-popup',{attrs:{"visible":_vm.pollDetailsVisible,"flexible-size":true},on:{"close":function($event){_vm.pollDetailsVisible = false}}},[_c('div',{staticClass:"poll"},[_c('div',{staticClass:"poll__content"},[_c('div',{staticClass:"banner-details-content"},[_c('div',{staticClass:"poll__banner",style:({
                  backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${_vm.poll.id}&PlacementType=Poll)`,
                })}),_c('div',{staticClass:"poll__details"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"poll-date"},[_vm._v(" Expiry date: "+_vm._s(_vm.getDateString(_vm.poll.expiryDate, "dd MMM yyyy"))+" ")]),_c('div',{staticClass:"poll__question"},[_vm._v(" "+_vm._s(_vm.poll.question || "question")+" ")]),_c('div',{staticClass:"poll__options"},_vm._l((_vm.poll.options),function(option,i){return _c('div',{key:i,staticClass:"poll__option"},[_c('div',{staticClass:"poll__option-wrapper"},[_c('div',{staticClass:"poll__option-index"},[_vm._v(_vm._s(i + 1))]),_c('div',{staticClass:"poll__option-name"},[_vm._v(_vm._s(option.option))])]),_c('div',{class:[
                          'poll__option-checkbox',
                          {
                            'poll__option-checkbox--selected': _vm.selectedOptions.includes(
                              option.id
                            ),
                          },
                        ],on:{"click":function($event){return _vm.optionClickHandler(option)}}},[(_vm.selectedOptions.includes(option.id))?_c('svg',{staticClass:"checkbox__mark",attrs:{"xmlns":"http://www.w3.org/2000/svg","x":"0px","y":"0px","viewBox":"0 0 512 512","xml:space":"preserve"}},[_c('path',{attrs:{"fill":"#283593","d":"M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0\n\t\t\tc-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7\n\t\t\tC514.5,101.703,514.499,85.494,504.502,75.496z"}})]):_vm._e()])])}),0)]),(!_vm.alreadySelected && !_vm.poll.anonymous)?_c('div',{staticClass:"poll__comment"}):_vm._e()])]),_c('div',{staticClass:"desc-comment-content"},[_c('div',{staticClass:"poll__description"},[_vm._v(_vm._s(_vm.poll.description))]),(!_vm.poll.anonymous)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.pollComment),expression:"pollComment"}],staticClass:"cstm-input",attrs:{"placeholder":"Type your comment","type":"text"},domProps:{"value":(_vm.pollComment)},on:{"input":function($event){if($event.target.composing)return;_vm.pollComment=$event.target.value}}}):_vm._e()])]),(!_vm.alreadySelected)?_c('div',{staticClass:"poll__button",on:{"click":_vm.makePollChoice}},[_vm._v(" Send ")]):_vm._e()])]):_vm._e()],1),_c('div',{staticClass:"news-popup"},[_c('custom-popup',{attrs:{"visible":_vm.newsDetailsVisible,"flexible-size":true},on:{"close":function($event){_vm.newsDetailsVisible = false}}},[(_vm.newsDetails)?_c('div',{staticClass:"news"},[_c('div',{staticClass:"desc-banner-content"},[_c('div',{staticClass:"news__banner",style:({
                backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${_vm.newsDetails.id}&PlacementType=News)`,
              })}),_c('div',{staticClass:"desc-content"},[_c('div',{staticClass:"news__title"},[_vm._v(" "+_vm._s(_vm.newsDetails.title)+" ")]),_c('div',{class:[
                  'news__text',
                  { 'news__text--without-banner': !_vm.newsDetails.banner },
                ],domProps:{"innerHTML":_vm._s(_vm.newsDetails.text)}})])]),_c('div',{staticClass:"news-attachments"},[_c('div',{staticClass:"attached-files"},_vm._l((_vm.newsDetails.newsAttachments),function(file,ind){return _c('div',{key:ind,class:['single-file',{'border':file.FileName}],on:{"click":function($event){return _vm.downloadAttachment(file.FileID)}}},[(file.FileName)?_c('div',{staticClass:"file-block",attrs:{"type":file.FileName ? file.FileName.split('.').pop() : ''}},[_vm._v(" "+_vm._s(file.FileName ? file.FileName.split(".").slice(-1)[0] : '')+" ")]):_vm._e(),(file.FileName)?_c('div',{staticClass:"file-name"},[_vm._v(" "+_vm._s(file.FileName ? file.FileName : '')+" ")]):_vm._e()])}),0)]),_c('div',{staticClass:"news__audience"},[_c('div',{staticClass:"news__audience-header"},[_c('div',{staticClass:"news__audience-header-title"},[_c('b',[_vm._v("Audience")]),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.audience.length)+" ")])])]),_c('div',{directives:[{name:"dragscroll",rawName:"v-dragscroll"}],staticClass:"news__audience-items"},_vm._l((_vm.audience),function(person){return _c('div',{key:person.PersonID,staticClass:"news__audience-items-item"},[(person)?_c('div',{staticClass:"news__audience-items-item-avatar",style:({
                    'background-image': `url(data:image/jpeg;base64,${person.File})`,
                  })}):_vm._e(),_c('div',{staticClass:"news__audience-items-item-name"},[_c('p',[_vm._v(_vm._s(person.FirstName))]),_c('p',[_vm._v(_vm._s(person.LastName))])])])}),0)])]):_c('span')])],1),(_vm.snackbarVisible)?_c('custom-snackbar',{attrs:{"purpose":_vm.snackbarPurpose,"text":_vm.snackbarText},on:{"close":function($event){_vm.snackbarVisible = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }