<template>
    <div class="messanger" :class="{'left': position == 2, 'middle': position == 1}" @mousewheel.stop>
        <div class="chat" :class="{'small-version': !collapsed}">
            <div class="chat-header" @click="collapsed = !collapsed">
                <img :src="avatarSrc" class="avatar" v-if="isOneToOne">
                <i v-else class="material-icons group-icon">group</i>
                <span class="chat-name">
                    {{ chatName }}
                <span class="unread-msg-count" v-show="!collapsed && unreadMessages">{{ unreadMessages }}</span>
                </span>
                <i class="material-icons close" @click.stop="$emit('close')">close</i>
            </div>
            <div class="messages" ref="chat" @scroll="handleChatScroll" @mousewheel="handleWheel">
                <div class="loader-mini" v-show="loading"><div></div><div></div><div></div><div></div></div>
                <div class="message" v-for="message in sortedMessages" :key="message.ID">
                    <p :class="{'my-message': message.Sender == myProfileID}">{{ message.Message }}</p>
                </div>
            </div>
            <input type="text" v-model="currentMessage" @keydown.enter="sendMessage" placeholder="Type text here" ref="input">
            <i class="material-icons enter-icon" @click="sendMessage" v-show="collapsed">subdirectory_arrow_left</i>
        </div>
    </div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance.js'
export default {
    props: {
        topicID: Number,
        hub: Object,
        position: Number,
        isOneToOne: {
            type: Boolean,
            default: true,
        },
        title: String,
    },
    created() {
        this.hub.on('sendMessage', this.receiveMessageHandler)
        this.getMessages()
        this.getPersons()
    },
    mounted() {
        this.$refs.input.focus()
    },
    data() {
        return {
            messages: [],
            currentMessage: '',
            collapsed: true,
            persons: [],
            unreadMessages: 0,
            loading: false,
            lastMessageID: '',
        }
    },
    computed: {
        myProfileID() {
            return this.$store.state.profile.ID
        },
        chatName() {
            if(this.persons.length == 2) {
                //one to one chat
                var recipient = this.persons.filter(el => el.ID != this.myProfileID)[0]
                if(!recipient) return 'Chat'
                return recipient.FirstName + ' ' + recipient.LastName
            }
            return this.title || 'Chat'
        },
        sortedMessages() {
            var messages = [...this.messages]
            return messages.sort((a,b) => a.ID - b.ID)
        },
        avatarSrc() {
            if(this.persons.length == 0) return ''
            return this.$store.state.getAvatar + this.persons.filter(el => el.ID != this.myProfileID)[0].ID
        },
    },
    watch: {
        collapsed(value) {
            if(value) {
                this.unreadMessages = 0
            }
        },
    },
    methods: {
        getMessages() {
            axios
                .get('Topics/GetTopicMessages', { params: {
                    Topic: this.topicID,
                }})
                .then(resp => {
                    this.messages = resp.data.Value.TopicMessages
                    this.lastMessageID = resp.data.Value.LastMessage
                    this.$nextTick(() => this.scrollMessagesToTheBottom())
                })
        },
        getPersons() {
            axios
                .get('Topics/GetTopicPersons', { params: {
                    Topic: this.topicID,
                }})
                .then(resp => {
                    this.persons = resp.data.Value
                })
        },
        sendMessage() {
            if(this.currentMessage == '') return
            this.hub.invoke('SendMessage', this.currentMessage, this.topicID.toString())
            this.currentMessage = ''
        },
        receiveMessageHandler(response) {
            if(response.topicID != this.topicID) return
            this.messages.push({
                ID: response.id,
                Message: response.message,
                Sender: response.sender,
                SendTime: response.sendTime,
            })
            this.$nextTick(() => this.scrollMessagesToTheBottom())
            if(!this.collapsed) this.unreadMessages++
        },
        scrollMessagesToTheBottom() {
            var div = this.$refs.chat
            div.scrollTop = div.scrollHeight;
        },
        handleChatScroll(e) {
            if(e.target.scrollTop == 0) {
                if(this.messages.length < 30) return //Isn't more than 30 anyway... return
                if(this.sortedMessages[0].ID == this.lastMessageID) return //no more messages left
                this.loadMoreMessages()
            }
        },
        loadMoreMessages() {
            this.loading = true
            axios
                .get('Topics/GetTopicMessages', {params: {
                    Topic: this.topicID,
                    LastMessageID: this.sortedMessages[0].ID,
                }})
                .then(resp => {
                    if(resp.data.Errormsg) return
                    this.$refs.chat.scrollTop = 1 //works perfectly
                    this.messages = this.messages.concat(resp.data.Value.TopicMessages)
                    this.loading = false
                })
        },
        handleWheel(e) {
            //prevents outside window scrolling
            var chat = this.$refs.chat
            if(chat.scrollTop + chat.offsetHeight == chat.scrollHeight && e.deltaY > 0 || chat.scrollTop == 0 && e.deltaY < 0) e.preventDefault()
        },
    },
    beforeDestroy() {
        //remove event for performance
        this.hub.off('sendMessage', this.receiveMessageHandler)
    },
}
</script>

<style lang="scss" scoped>
    .messanger {
        position: fixed;
        bottom: 0;
        right: 320 * $rem;
        z-index: 20;
        transition: right .2s ease-out;

        &.middle {
            right: 635 * $rem;
        }

        &.left {
            right: 950 * $rem;
        }

        .chat {
            height: 350 * $rem;
            border: 1 * $rem solid $grey-border;
            border-top-left-radius: 5 * $rem;
            border-top-right-radius: 5 * $rem;
            z-index: 5;
            width: 285 * $rem;
            background-color: rgb(255, 255, 255);
            box-shadow: 0 0 5 * $rem rgba(0, 0, 0, 0.2);
            transition: all .3s ease-out;

            .chat-header {
                height: 40 * $rem;
                display: flex;
                align-items: center;
                border-bottom: 1 * $rem solid $grey-border;
                cursor: pointer;

                img {
                    width: 35 * $rem;
                    height: 35 * $rem;
                    margin-left: 10 * $rem;
                    border-radius: 50%;
                }

                i.group-icon {
                    margin-left: 10 * $rem;
                    color: $grey-text;
                }

                .chat-name {
                    position: relative;
                    margin-left: 15 * $rem;
                    color: $grey-text;
                    width: 150 * $rem;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    .unread-msg-count {
                        position: absolute;
                        right: 20px;
                        padding: 2 * $rem 6 * $rem;
                        border-radius: 10 * $rem;
                        background-color: #ff2828;
                        color: white;
                        font-size: 13 * $rem;
                    }
                }

                .close {
                    margin-left: auto;
                    margin-right: 10 * $rem;
                }
            }

            .messages {
                height: calc(100% - 80 * #{$rem});
                overflow: auto;

                .message {
                    width: 100%;
                    margin-top: 5 * $rem;
                    padding: 0 5 * $rem;

                    &:last-child {
                        margin-bottom: 5 * $rem;
                    }

                    p {
                        padding: 10 * $rem;
                        background-color: white;
                        border-radius: 7 * $rem;
                        width: 45%;
                        word-break: break-all;
                        background-color: #eaeaea;
                        font-size: 15 * $rem;

                        &.my-message {
                            background-color: $green-primary;
                            color: white;
                            margin-left: auto;
                        }
                    }
                }
            }

            input {
                outline: none;
                height: 40 * $rem;
                padding-left: 10 * $rem;
                border: none;
                border-top: 1 * $rem solid $grey-border;
                width: 100%;
                padding-right: 30 * $rem;
            }

            &.small-version {
                height: 40px;

                .messages {
                    height: 0;
                }

                input {
                    height: 0;
                    border: none;
                }
            }
        }

        .enter-icon {
            position: absolute;
            bottom: 0;
            right: 10 * $rem;
            font-weight: 400;
            color: $grey-text;
            line-height: 40 * $rem;
            transform: scaleX(1.5);
            font-size: 16 * $rem;
            cursor: pointer;
        }
    }


    .loader-mini {
        transform: translateY(-30px);
        margin: 10px auto;
        border: 6px solid $grey-border;
        border-top: 4px solid $green-primary;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        animation: spin 1s linear infinite;
    }

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>