import WeekSchedule from './components/WeekSchedule/WeekSchedule.vue'
import Employees from './components/Employees/Employees.vue'
import Roles from './components/Roles/Roles.vue'
import Offices from './components/Offices/Offices.vue'
import Departments from './components/Departments/Departments.vue'
import DepartmentStructure from './components/Departments/DepartmentStructureAsPage'
import Teams from './components/Teams/Teams.vue'
import Jobs from './components/Jobs/Jobs.vue'
import Permissions from './components/Permissions/Permissions.vue'
import FixedSchedules from './components/FixedSchedules/FixedSchedules.vue'
import AttendanceLog from './components/AttendanceLog/AttendanceLog.vue'
import Vacation from './components/Vacation/Vacation.vue'
import FlexibleSchedule from './components/FlexibleSchedule/FlexibleSchedule.vue'
import Dashboard from './components/Dashboard/Dashboard.vue'
import Holiday from './components/Holiday/Holiday.vue'
import Activity from './components/Activity/Activity.vue'
// import ProfileInfo from './components/Profile/ProfileInfo.vue'
// import Profile from './components/Profile/Profile.vue'
import ChooseScheduleType from './components/ChooseScheduleType/ChooseScheduleType.vue'
import TeamsUsers from './components/Departments/TeamsUsers'
import DailyPerformance from '@/components/DailyPerformance/DailyPerformance'
import PersonsPerformance from '@/components/DailyPerformance/PersonsPerformance'

import TrafficWorkspace from '@/components/Traffic/Workspace/TrafficWorkspace'
import Traffic from '@/components/Traffic/Traffic'
import UserTraffic from '@/components/Traffic/UserTraffic/UserTraffic'
import UserWorkspace from '@/components/Traffic/UserTraffic/UserWorkspace'
import TrafficRequests from '@/components/Traffic/TrafficRequests'
// import VacationAdmin from './components/VacationAdmin/VacationAdmin.vue'
import VacationsUser from './components/vacationUser/VacationUser'
import NewsSingle from '@/components/News/NewsSingle'
import News from '@/components/News/News'
import Polls from '@/components/Polls/Polls'
import PollSingle from '@/components/Polls/PollSingle'
import PollDetail from '@/components/Polls/PollDetail'
import FeedPageChooser from '@/components/FeedPageChooser/FeedPageChooser'
import Monitoring from '@/components/Monitoring/Monitoring.vue'
import TrafficHistory from '@/components/Traffic/TrafficHistory.vue'
import Offers from '@/components/Offers/Offers.vue'
import OfferSingle from '@/components/Offers/OfferSingle.vue'
// import { placements } from '@popperjs/core'
import PlacementUser from '@/components/PlacementUser/PlacementUser.vue'
import OfferAllResponses from '@/components/Offers/OfferAllResponses.vue'
import ProfileInOuts from '@/components/Profile/ProfileInOuts.vue'
import EventCalendar from '@/components/EventCalendar/EventCalendar.vue'
import ScheduleForUser from '@/components/Schedule/ScheduleForUser.vue'
import Career from '@/components/Career/Career.vue'
import ApplicantTracking from '@/components/Hr/ApplicantTracking.vue'
import Statements from '@/components/Statements/Statements.vue'
import FollowUp from '@/components/Hr/FollowUp.vue'
// import VacationTeams from "@/components/VacationAdmin/VacationTeams.vue";
// import VacationTerms from "@/components/VacationAdmin/VacationTerms.vue";

// import UserTraffic from "@/components/Traffic/UserTraffic/UserTraffic";
import CareerAdmin from '@/components/Hr/CareerAdmin.vue'

import PayrollRoutes from './routes/payroll.js'
import profileRoutes from './routes/profile.js'
import vacationRoutes from './routes/vacations.js'
import { store } from './store.js'
import BirthdaysCalendar from '@/components/BirthdaysCalendar/BirthdaysCalendar'
import AttendanceReport from '@/components/AttendanceReport/AttendanceReport.vue'
import GradeNodes from '@/components/Grades/GradeNodes.vue'
import Criterias from '@/components/Grades/Criterias.vue'
import TeamRatingOverall from '@/components/Grades/TeamRatingOverall.vue'
import VendingMobile from '@/components/VendingMobile/VendingMobile.vue'
import VendingPackages from '@/components/Vending/Packages.vue'
import Transactions from '@/components/Vending/Transactions.vue'
import VendingUsers from '@/components/Vending/Users.vue'
import PersonRatingOverall from '@/components/Grades/PersonRatingOverall.vue'
import TeamOverallChart from '@/components/Grades/TeamOverallChart.vue'
import OverallCompare from '@/components/Grades/OverallCompare.vue'
import CareerMapping from '@/components/Grades/CareerMapping.vue'

// import { component } from 'vue/types/umd'
import PerfAttendance from '@/components/PerofrmanceAttendance/PerfAttendance.vue'
import AttendanceReportUser from '@/components/AttendanceReportUser/AttendanceReportUser.vue'
import InoutAttendanceReport from '@/components/InoutAttendanceReport/InoutAttendanceReport.vue'
import SignifyVue from './components/Signify/Signify.vue'
import SignifyTemplates from './components/Signify/SignifyTemplates.vue'

import SignifyViews from './components/Signify/SignifyViews.vue'

export const routes = [
  {
    name: 'Attendance',
    path: '/InoutAttendanceReport',
    redirect: 'AttendanceReportUser',

    component: InoutAttendanceReport,
    children: [
      {
        name: 'Attendance',
        path: '/AttendanceReportUser',
        component: AttendanceReportUser,
      },
      {
        name: 'Attendance',
        path: '/AttendanceUser',
        component: ProfileInOuts,
      },
    ],
  },
  {
    name: 'VendingMobile',
    path: '/VendingMobile',
    component: VendingMobile,
  },
  {
    name: 'Schedule',
    path: '/ScheduleUSer',
    component: ScheduleForUser,
  },
  {
    name: 'Signify Docs',
    path: '/',
    component: SignifyViews,
    beforeEnter: function (to, from, next) {
      if (
        store.getters.hasRole('Super Admin') ||
        store.getters.hasRole('HR') ||
        store.getters.hasRole('Admin')
      ) {
        next(true)
      } else next(false)
    },
    children: [
      {
        name: 'Signify Docs',
        path: '/signifyDocs',
        component: SignifyVue,
        beforeEnter: function (to, from, next) {
          if (
            store.getters.hasRole('Super Admin') ||
            store.getters.hasRole('HR') ||
            store.getters.hasRole('Admin')
          ) {
            next(true)
          } else next(false)
        },
      },
      {
        name: 'Signify Templates',
        path: '/signifyTemplates',
        component: SignifyTemplates,
        beforeEnter: function (to, from, next) {
          if (
            store.getters.hasRole('Super Admin') ||
            store.getters.hasRole('HR') ||
            store.getters.hasRole('Admin')
          ) {
            next(true)
          } else next(false)
        },
      },
    ],
  },
  {
    name: 'Homepage',
    path: '/Dashboard',
    component: Dashboard,
    alias: '',
  },
  {
    name: 'Placement',
    path: '/AllResponses',
    component: OfferAllResponses,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Monitoring',
    path: '/Monitoring',
    component: Monitoring,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Employees',
    path: '/Employees',
    component: Employees,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Roles',
    path: '/Roles',
    component: Roles,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Offices',
    path: '/Offices',
    component: Offices,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Structure',
    path: '/Departments',
    component: Departments,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Structure',
    path: '/DepartmentStructure',
    component: DepartmentStructure,
  },
  {
    name: 'Teams',
    path: '/Teams',
    component: Teams,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Jobs',
    path: '/Jobs',
    component: Jobs,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Permissions',
    path: '/Permissions',
    component: Permissions,
    props: true,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Schedules',
    path: '/FixedSchedules',
    component: FixedSchedules,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Week Schedule',
    path: '/WeekSchedule/:id',
    component: WeekSchedule,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },

  // {
  //     name: 'Vacations',
  //     path: '/VacationsAdmin',
  //     component: VacationAdmin,
  //     beforeEnter: function(to, from, next){
  //         if (store.getters.hasRole("Member")){
  //             next(false)
  //         }else{
  //          next()
  //         }
  //     },
  //     children: [
  //         {
  //             name: 'Vacation_Teams',
  //             path: 'vacationTeams',
  //             component: VacationTeams
  //         },
  //         {
  //             name: 'Vacation_terms',
  //             path: 'Vacationterms',
  //             component: VacationTerms
  //         }
  //     ],
  // },
  {
    name: 'Vacations',
    path: '/VacationsUser',
    component: VacationsUser,
  },
  {
    name: 'Placement',
    path: '/PlacementUser',
    component: PlacementUser,
  },

  {
    name: 'Attendance',
    path: '/Attendance',
    component: AttendanceLog,
  },
  {
    name: 'Holiday',
    path: '/Holiday',
    component: Holiday,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Flexible Schedule',
    path: '/FlexibleSchedule',
    component: FlexibleSchedule,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Activity',
    path: '/Activity',
    component: Activity,
  },

  {
    name: 'Schedule',
    path: '/ChooseScheduleType',
    component: ChooseScheduleType,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Teams & Users',
    path: '/TeamsUsers',
    component: TeamsUsers,
    props: true,
  },
  {
    name: 'Traffic History',
    path: '/TrafficHistory',
    component: TrafficHistory,
    props: { personId: null },
  },

  {
    name: 'Attendance',
    path: '/PerformanceAttendance',
    redirect: 'DailyPerformance',
    component: PerfAttendance,
    children: [
      {
        name: 'Attendance',
        path: '/DailyPerformance',
        component: DailyPerformance,
        beforeEnter: function (to, from, next) {
          if (store.getters.hasRole('Member')) {
            next(false)
          } else {
            next()
          }
        },
      },

      {
        name: 'Attendance',
        path: '/AttendanceReport',
        component: AttendanceReport,
      },
      {
        name: 'Attendance',
        path: '/PersonsPerformance',
        component: PersonsPerformance,
      },
    ],
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Traffic',
    path: '/Traffic',
    component: Traffic,
    props: true,
  },
  {
    name: 'Workspace',
    path: '/TrafficWorkspace',
    component: TrafficWorkspace,
  },
  {
    name: 'Traffic Requests',
    path: '/TrafficRequests',
    component: TrafficRequests,
  },
  {
    name: 'My Traffic',
    path: '/MyTraffic',
    component: UserTraffic,
    props: true,
  },

  {
    name: 'Workspace',
    path: '/MyWorkspace',
    component: UserWorkspace,
  },

  {
    name: 'Placement',
    path: '/News',
    component: News,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'News Single',
    path: '/News/:id',
    component: NewsSingle,
  },
  {
    name: 'Placement',
    path: '/Polls',
    component: Polls,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Placement',
    path: '/Offers',
    component: Offers,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Offers',
    path: '/Offers/:id',
    component: OfferSingle,
  },
  {
    name: 'Polls',
    path: '/Polls/Edit/:id',
    component: PollDetail,
  },
  {
    name: 'Polls Single',
    path: '/Polls/:id',
    component: PollSingle,
  },

  {
    name: 'Placement',
    path: '/Placement',
    component: FeedPageChooser,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Calendar',
    path: '/EventCalendar',
    component: EventCalendar,
  },
  {
    name: 'Birthdays',
    path: '/BirthdaysCalendar',
    component: BirthdaysCalendar,
  },
  {
    name: 'Career',
    path: '/CareerAdmin',
    component: CareerAdmin,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member') || store.getters.hasRole('Lawyer')) {
        next(false)
      } else {
        next()
      }
    },
  },

  {
    name: 'Career',
    path: '/Career',
    component: Career,
  },
  {
    name: 'Applicant Tracking',
    path: '/ApplicantTracking',
    component: ApplicantTracking,
    beforeEnter: function (to, from, next) {
      if (
        store.getters.hasRole('Member') ||
        store.getters.hasRole('Manager') ||
        store.getters.hasRole('Lawyer')
      ) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'APPLICANTTRACKING',
    path: '/FollowUp',
    component: FollowUp,
    beforeEnter: function (to, from, next) {
      if (
        store.getters.hasRole('Member') ||
        store.getters.hasRole('Manager') ||
        store.getters.hasRole('Lawyer')
      ) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Statements',
    path: '/Statements',
    component: Statements,
    beforeEnter: function (to, from, next) {
      if (store.getters.hasRole('Member')) {
        next(false)
      } else {
        next()
      }
    },
  },
  {
    name: 'Setup Grades',
    path: '/Grades/GradeNodes',
    component: GradeNodes,
    beforeEnter: function (to, from, next) {
      if (
        store.getters.hasRole('Super Admin') ||
        store.getters.hasRole('HR') ||
        store.getters.hasRole('Admin')
      )
        next(true)
      else next(false)
    },
  },
  {
    name: 'Criterias',
    path: '/Grades/Criterias',
    component: Criterias,
    beforeEnter: function (to, from, next) {
      if (
        store.getters.hasRole('Super Admin') ||
        store.getters.hasRole('HR') ||
        store.getters.hasRole('Admin')
      )
        next(true)
      else next(false)
    },
  },
  {
    name: 'Team Rating Overall',
    path: '/Grades/TeamRatingOverall',
    component: TeamRatingOverall,
    beforeEnter: function (to, from, next) {
      if (
        store.getters.hasRole('Super Admin') ||
        store.getters.hasRole('HR') ||
        store.getters.hasRole('Admin')
      )
        next(true)
      else next(false)
    },
  },
  {
    name: 'Person Rating Overall',
    path: '/Grades/PersonRatingOverall/:id',
    component: PersonRatingOverall,
    beforeEnter: function (to, from, next) {
      if (
        store.getters.hasRole('Super Admin') ||
        store.getters.hasRole('HR') ||
        store.getters.hasRole('Admin')
      )
        next(true)
      else next(false)
    },
  },
  {
    name: 'Team Overall Chart',
    path: '/Grades/TeamOverallChart',
    component: TeamOverallChart,
    beforeEnter: function (to, from, next) {
      if (
        store.getters.hasRole('Super Admin') ||
        store.getters.hasRole('HR') ||
        store.getters.hasRole('Admin')
      )
        next(true)
      else next(false)
    },
  },
  {
    name: 'Overall Compare',
    path: '/Grades/OverallCompare',
    component: OverallCompare,
    beforeEnter: function (to, from, next) {
      if (
        store.getters.hasRole('Super Admin') ||
        store.getters.hasRole('HR') ||
        store.getters.hasRole('Admin')
      )
        next(true)
      else next(false)
    },
  },
  {
    name: 'Career Mapping',
    path: '/Grades/CareerMapping',
    component: CareerMapping,
  },
  {
    name: 'Vending Machine',
    path: '/VendingPackages',
    component: VendingPackages,
    beforeEnter: function (to, from, next) {
      if (
        store.getters.hasRole('Member') ||
        store.getters.hasRole('Manager') ||
        store.getters.hasRole('Lawyer')
      )
        next(false)
      else next(true)
    },
  },

  {
    name: 'Vending Machine',
    path: '/Transactions',
    component: Transactions,
    // beforeEnter: function(to, from, next){
    //     if(store.getters.hasRole('Member')) next(false)
    //     else next(true)
    // }
  },
  {
    name: 'Vending Machine',
    path: '/Users',
    component: VendingUsers,
    beforeEnter: function (to, from, next) {
      if (
        store.getters.hasRole('Member') ||
        store.getters.hasRole('Manager') ||
        store.getters.hasRole('Lawyer')
      )
        next(false)
      else next(true)
    },
  },
  {
    name: 'Attendance Report',
    path: '/AttendanceReport',
    component: AttendanceReport,
  },

  // /////////////////////////////////////////
  // { path: '*', redirect: '/VendingMobile' },
  // NOT FOUND ///////////////////////////

  modifyRouteObj(PayrollRoutes),
  modifyRouteObj(profileRoutes),
  modifyRouteObj(vacationRoutes),
]

function modifyRouteObj(routeObj) {
  if (!routeObj.children) return routeObj
  routeObj.children.forEach(function (childRoute) {
    childRoute.name = routeObj.name + '__' + childRoute.name
    childRoute.meta = {
      mainTitle: routeObj.name.split('__')[0],
    }

    modifyRouteObj(childRoute)
  })

  return routeObj
}
