<template>
  <div class="background">
    <div class="departments">
      <!-- <div class="views">
        <img
          v-if="changeView == true"
          @click="changeView = false"
          src="@/assets/icons/list-icon.svg"
          alt="grid-icon"
        />

        <img
          v-if="changeView == false"
          src="@/assets/icons/list-icon-clicked.svg"
          alt="grid-icon"
        />

        <img
          v-if="changeView == false"
          @click="changeView = true"
          src="@/assets/icons/grid-icon.svg"
          alt="grid-icon"
        />

        <img
          v-if="changeView == true"
          src="@/assets/icons/grid-icon-clicked.svg"
          alt="grid-icon"
        />
      </div> -->

      <structure v-if="changeView == true"></structure>
      <department-jobs
        @close="popupDepartmentVisible = false"
        :departmentID="selectedDepartmentID"
        v-if="popupDepartmentVisible"
      />

      <grid
        title="Departments"
        v-if="changeView == false"
        :gridData="filteredGridData"
        :columns="columns"
        :passedPageSize="gridParams.pageSize"
        :dataCount="gridParams.totalCount"
        identificator="ID"
        @add-click="addNewClickHandler"
        :canDelete="accessPermissions.createDepartment"
        @rowDelete="showDeletePopup"
        :canEdit="accessPermissions.createDepartment"
        @rowEdit="editRow"
        @trClicked="trClickHandler"
        :can-add="true"
        :can-search="true"
        class="departments-list-grid"
        @search="(term) => (searchTerm = term)"
      />
      <custom-popup
        :visible="popupVisible"
        @close="popupVisible = false"
        title="Add Department"
        :flexibleSize="true"
      >
        <form class="add-form" @submit.prevent="addDepartment">
          <custom-input
            v-model="newDepartment.name"
            title="Name"
            @clear="newDepartment.name = ''"
          />
          <date-picker
            title="Start Date"
            v-model="newDepartment.startTime"
            @clear="newDepartment.startTime = ''"
          />
          <button class="btn">Save</button>
        </form></custom-popup
      >

      <delete-item
        @delete="deleteRow"
        @close="deletePopupVisible = false"
        v-if="deletePopupVisible"
        :text="deleteText"
      />
      <custom-snackbar
        v-if="snackbarVisible"
        :purpose="snackbarPurpose"
        :text="snackbarText"
        @close="snackbarVisible = false"
      />
    </div>
  </div>
</template>

<script>
import Grid from "@/components/ReusableComponents/Grid/Grid.vue";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup.vue";
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput.vue";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import DeleteItem from "@/components/ReusableComponents/DeleteItem/DeleteItem.vue";
import DepartmentJobs from "./DepartmentJobs";
import axios from "@/Helpers/AxiosInstance.js";
import Structure from "./Structure";
import {
  checkPermission,
  getDateString,
  CETToTimestamp,
} from "@/Helpers/HelperFunctions.js";
export default {
  components: {
    Grid,
    CustomPopup,
    CustomInput,
    DatePicker,
    CustomSnackbar,
    DeleteItem,
    DepartmentJobs,
    Structure,
  },
  created() {
    this.getData();
  },
  data() {
    return {
      searchTerm: "",
      accessPermissions: {
        createDepartment: checkPermission("CreateDepartment"),
      },
      gridData: [],
      popupVisible: false,
      newDepartment: {
        name: "",
        startTime: "",
      },
      snackbarPurpose: "error",
      snackbarText: "",
      snackbarVisible: false,
      cities: [],
      deletePopupVisible: false,
      selectedDepartmentID: "",
      gridParams: {
        currentPage: 1,
        pageSize: 20,
        orderByColumn: "ID",
        ascending: false,
        totalCount: 0,
      },
      popupDepartmentVisible: false,
      changeView: true,
    };
  },
  methods: {
    getData() {
      axios
        .get("Departments/GetDepartments", {
          params: {
            Page: this.gridParams.currentPage,
            PageSize: this.gridParams.pageSize,
            OrderByColumn: this.gridParams.orderByColumn,
            Ascending: this.gridParams.ascending,
          },
        })
        .then((resp) => {
          this.gridData = resp.data.Value.filter((el) => !el.Deleted).map(
            (el) => {
              if (el.EndTime == null) el.EndTime = 0;
              return el;
            }
          );
          this.gridParams.totalCount = resp.data.Paging.TotalItemCount;
        });
    },
    addNewClickHandler() {
      this.popupVisible = true;
    },

    addDepartment() {
      axios
        .post("Departments/CreateDepartment", {
          Name: this.newDepartment.name,
          StartTime: this.newDepartment.startTime,
        })
        .then((resp) => {
          this.getData();
          if (!resp.data.ErrorMsg) {
            this.popupVisible = false;
            this.snackbarVisible = true;
            this.snackbarText = "Successfully created new department!";
            this.snackbarPurpose = "success";
          } else {
            this.popupVisible = false;
            this.snackbarPurpose = "error";
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
          }
        })
        .catch(() => {
          this.popupVisible = false;
          this.snackbarPurpose = "error";
          this.snackbarVisible = true;
          this.snackbarText = "An Error Occured While Adding New Office!";
        });
    },
    trClickHandler(element, e) {
      if (e.target.matches(".view-jobs")) {
        this.selectedDepartmentID = element.ID;
        this.popupDepartmentVisible = true;
      }
    },
    showDeletePopup(id) {
      this.selectedDepartmentID = id;
      this.deletePopupVisible = true;
    },
    deleteRow() {
      var item = this.gridData.filter(
        (el) => el.ID == this.selectedDepartmentID
      )[0];
      var data = {
        Id: this.selectedDepartmentID,
        Name: item.Name,
        Deleted: true,
        EndTime: item.EndTime,
      };

      axios
        .post("Departments/UpdateDepartment", data)
        .then((resp) => {
          this.deletePopupVisible = false;
          if (!resp.data.ErrorMsg) {
            this.snackbarText = "Deleted Successfully!";
            this.snackbarPurpose = "success";
            this.snackbarVisible = true;
            this.getData();
          } else {
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
            this.snackbarPurpose = "error";
          }
        })
        .catch(() => {
          this.snackbarVisible = true;
          this.snackbarText = "Error";
          this.snackbarPurpose = "error";
        });
    },
    editRow(values) {
      values["Deleted"] = false;
      if (!values["EndTime"]) delete values["EndTime"];
      axios
        .post("Departments/UpdateDepartment", values)
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.getData();
            this.snackbarText = "Updated Successfully!";
            this.snackbarPurpose = "success";
            this.snackbarVisible = true;
          } else {
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
            this.snackbarPurpose = "error";
          }
        })
        .catch(() => {
          this.snackbarVisible = true;
          this.snackbarText = "Error";
          this.snackbarPurpose = "error";
        });
    },
  },

  computed: {
    filteredGridData() {
      if (!this.searchTerm) return this.gridData;
      return this.gridData.filter((dep) =>
        dep.Name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    deleteText() {
      if (this.gridData.length == 0 || !this.selectedDepartmentID) return "";
      var name = this.gridData.filter(
        (el) => el.ID == this.selectedDepartmentID
      )[0].Name;
      return `Would you like to delete department <strong>${name}</strong>?`;
    },
    columns() {
      return [
        {
          title: "Name",
          key: "Name",
          editable: true,
          editType: "text",
        },
        {
          title: "Jobs",
          key: "",
          // hidden: !this.accessPermissions.assignTeamPersons,
          customTemplate: () =>
            `<img class="custom-icon view-jobs" src="${require("@/assets/jobs.svg")}" >`,
          tdClass: "icon",
        },
        {
          title: "Start Time",
          key: "StartTime",
          customTemplate: (item, val) => getDateString(CETToTimestamp(val)),
        },
        {
          title: "End Time",
          key: "EndTime",
          customTemplate: (item, val) =>
            val ? getDateString(CETToTimestamp(val)) : "-",
          editable: true,
          editType: "date",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.departments {
  // padding-bottom: 10 * $rem;

  .button-container {
    margin-bottom: 15 * $rem;
    width: 210 * $rem;
  }

  form {
    display: flex;
    align-items: center;

    label,
    .select-wrapper {
      margin-right: 15 * $rem;
    }
  }

  ::v-deep .view-jobs {
    cursor: pointer;
  }
}
.views {
  top: 1.9rem;
  left: 4rem;
  position: absolute;
  z-index: 1;
  display: flex;

  img {
    height: 36px;
    cursor: pointer;
  }
}

.actions-container {
  margin-top: 30 * $rem;
  display: flex;
  margin-bottom: 15 * $rem;
  align-items: center;

  form {
    display: flex;
    margin-left: auto;
    margin-right: 150 * $rem;
    height: 38 * $rem;

    input {
      height: 36px;
      outline: none;
      border: none;
      border-radius: 3 * $rem;
      padding-left: 23 * $rem;
      font-size: 14 * $rem;
      color: $blue-primary;
      background-color: #f8f8fe;
      width: 272 * $rem;

      &::placeholder {
        color: rgba($blue-primary, 0.6);
      }
    }

    .plus {
      display: inline-block;
      background-color: rgba($blue-primary, 0.6);
      border-radius: 3 * $rem;
      width: 42 * $rem;
      height: 37 * $rem;
      margin-left: 4 * $rem;
      position: relative;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        width: 23 * $rem;
        height: 2 * $rem;
        top: 18 * $rem;
        left: 10 * $rem;
        background-color: #fff;
      }

      &::after {
        content: "";
        position: absolute;
        height: 23 * $rem;
        width: 2 * $rem;
        top: 7 * $rem;
        left: 21 * $rem;
        background-color: #fff;
      }
    }
  }
}
.iconbackground {
  background: rgba(40, 53, 147, 0.6);
}
</style>
