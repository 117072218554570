<template>
  <div
    class="job-bookmark-creation"
    ref="bookmarkCreation"
    :style="componentPosition"
    @click.stop
  >
    <slot></slot>

    <div class="link-content">
      <span @click="showLinkInput = true" v-if="showLinkInput == false"
        >+ Paste link</span
      >
      <div class="link-input-content" v-if="showLinkInput">
        <div>
          <input type="text" v-model="link" placeholder="Paste link" />
          <input
            class="link-name"
            v-model="linkName"
            type="text"
            placeholder="Name bookmark ..."
          />
        </div>
        <span @click="closeClickHandler" class="material-icons"> close</span>
      </div>
    </div>
    <button class="save-button" @click="$emit('assignToJob', linkName, link)">
      Save
    </button>
  </div>
</template>

<script>
export default {
  created() {
    document.addEventListener("click", this.documentClick);
  },
  data() {
    return {
      showLinkInput: false,
      position: Number,
    };
  },
  methods: {
    closeClickHandler() {
      this.showLinkInput = false;
      this.linkName = "";
      this.link = "";
    },
    documentClick(event) {
      let specifiedElement = this.$refs.bookmarkCreation;

      let targetEl = event.target;

      if (
        targetEl.classList.contains("custom-select-options") ||
        targetEl.parentNode.classList.contains("custom-select-options")
      )
        return;
      this.$emit("close");
    },
  },
  computed: {
    componentPosition() {
      return "left:" + (this.$attrs.position - 348) + "px";
    },
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style scoped lang="scss">
.job-bookmark-creation {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 397px;
  height: 280px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0.125rem 0.125rem 0.625rem 0.125rem rgba(40, 52, 147, 0.08);
  position: fixed;
  right: 15px;
  top: 25px;
  .material-icons {
    font-size: 18px;
    padding-right: 25px;
  }
  .custom-select {
    margin-bottom: 20px;
  }
  ::v-deep .select-wrapper {
    .selected-box {
      width: 356px;
    }
  }
  .link-content {
    color: $blue-primary;
    span {
      font-weight: 300;
      cursor: pointer;
    }
    .link-input-content {
      display: flex;
      align-items: baseline;

      padding-left: 5px;
      border-radius: 6px;
      .link-name {
        width: 180px;
        border: 0;
      }
      input {
        color: $blue-primary;
        padding-left: 5px;
        border: none;
        border-bottom: 0.0625rem solid #dcdde6;
        outline: none;
        width: 317px;
        padding-bottom: 5px;
        margin-bottom: 5px;
        &:nth-child(2) {
          margin-bottom: 0;
        }
        &::placeholder {
          color: $blue-primary;
          font-weight: 300;
        }
      }
    }
  }
}

.save-button {
  width: 60px;
  height: 30px;
  background-color: #8a91c7;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15 * $rem;
  border-radius: 3 * $rem;
  margin-left: auto;
  margin-top: auto;

  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }
}
</style>