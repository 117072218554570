<template>
  <div class="filter" @mousedown.stop>
    <img :src="iconSrc" @click="filterVisible = !filterVisible" />
    <portal to="table-filter" v-if="filterVisible">
      <ul
        v-show="filterVisible"
        v-if="listWithoutDuplicates.length"
        :style="position"
        class="table-filter"
        @mousedown.stop
      >
        <div>
          <span
            class="clear"
            @click="
              clearFilter,
                $emit('clearFilterValue'),
                (inputValue = ''),
                (value = '')
            "
          >
            Clear Filter
          </span>
        </div>
        <input type="text" v-model="inputValue" placeholder="Search..." />
        <li
          v-for="(option, ind) in filteredList"
          :key="ind"
          @click="selectValue(option)"
          :class="{ selected: value == option || value === option.value }"
        >
          {{ alternateType ? option.title : option }}
        </li>
      </ul>
    </portal>
    <!-- <portal to="table-filter" v-if="onlySearch && filterVisible"> -->
    <ul class="table-filter search" v-show="onlySearch && filterVisible">
      <div>
        <span
          class="clear"
          @click="
            clearFilter,
              $emit('clearFilterValue'),
              (inputValue = ''),
              (value = '')
          "
        >
          Clear Filter
        </span>
      </div>
      <input
        type="text"
        @input="(val) => $emit('search', inputValue)"
        v-model="inputValue"
        placeholder="Search..."
      />
    </ul>
    <!-- </portal> -->
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    onlySearch: Boolean,
    alternateType: {
      type: Boolean,
      default: false,
    },
    clearTriggered: {
      Type: Boolean,
      default: false,
    },
    filteredIconVisible: Boolean,
  },
  created() {
    document.addEventListener('mousedown', this.closeFilter)
  },
  data() {
    return {
      filterVisible: false,
      ulStyling: {},
      value: '',
      inputValue: '',
      position: '',
    }
  },
  computed: {
    listWithoutDuplicates() {
      if (this.alternateType) {
        return [...new Map(this.list.map((e) => [e['value'], e])).values()]
      } else {
        return [...new Set(this.list.filter((e) => e))]
      }
    },
    filteredList() {
      return this.listWithoutDuplicates.filter((el) =>
        this.alternateType
          ? el.title.toLowerCase().includes(this.inputValue.toLowerCase())
          : el.toLowerCase().includes(this.inputValue.toLowerCase()),
      )
    },
    iconSrc() {
      if (this.alternateType) {
        return this.filteredIconVisible
          ? require('@/assets/icons/white/ClearFilter.svg')
          : require('@/assets/icons/white/Filter.svg')
      } else {
        return this.value
          ? require('@/assets/icons/white/ClearFilter.svg')
          : require('@/assets/icons/white/Filter.svg')
      }
    },
  },
  methods: {
    clearFilter() {
      if (this.alternateType) {
        this.inputValue = ''
        this.value = ''
        this.$emit('select', '')
      }
      this.inputValue = ''
      this.value = ''
      this.$emit('select', '')
      this.filterVisible = false
    },
    selectValue(val) {
      if (this.alternateType) {
        this.value = val.value
        this.$emit('select', val.value)
      } else {
        this.value = val
        this.$emit('select', val)
      }
      this.filterVisible = false
    },
    closeFilter() {
      this.filterVisible = false
    },
    calcPosition() {
      let fullWidth = window.innerWidth - 300
      var rect = this.$el.getBoundingClientRect()

      if (rect.x > fullWidth) {
        this.position = {
          right: '50px',
          top: `${rect.y + 35}px`,
        }
      } else {
        this.position = {
          left: `${rect.x}px`,
          top: `${rect.y + 35}px`,
        }
      }
    },
  },
  watch: {
    filterVisible(visible) {
      if (visible) {
        this.calcPosition()
      }
    },
    clearTriggered() {
      this.clearFilter()
    },
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.closeFilter)
  },
}
</script>

<style scoped lang="scss">
.filter {
  cursor: pointer;
  position: relative;

  img {
    width: 30 * $rem;
  }
}
.table-filter {
  &.search {
    right: -66px;
  }
}
</style>
