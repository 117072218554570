var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flexible-schedule",class:{ background: !_vm.noEdit },on:{"click":function($event){_vm.dragging = false}}},[(!_vm.schedulesLoaded)?_c('custom-loader'):_vm._e(),_c('div',{staticClass:"schedule",on:{"click":function($event){$event.stopPropagation();return _vm.closePaste.apply(null, arguments)}}},[_c('div',{ref:"leftSide",staticClass:"left-scroll",on:{"wheel":function($event){$event.preventDefault();},"touchmove":function($event){$event.preventDefault();},"scroll":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"left-side"},[_c('div',{staticClass:"persons-header"},[(!_vm.noEdit)?_c('select-persons',{ref:"selectPerson",attrs:{"withoutDeps":true,"initialSelectedPersons":_vm.visiblePeople,"departments":_vm.departmentPeople},on:{"select":(people) => (_vm.visiblePeople = [...people])}}):_vm._e()],1),_vm._l((_vm.peopleGroupedInJobs),function(job,index){return _c('div',{key:job.jobID,staticClass:"job"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(job.persons.some((p) => _vm.personIsVisible(p))),expression:"job.persons.some((p) => personIsVisible(p))"}],staticClass:"job-name",class:{ 'dif-background': index % 2 },style:({
              height:
                job.persons.filter((p) => _vm.personIsVisible(p)).length * 3.4 +
                'rem',
            })},[_c('span',[_vm._v(_vm._s(job.jobName))])]),_c('div',{staticClass:"job-name-tooltip"},[_c('div',{staticClass:"triangle"}),_c('span',[_vm._v(_vm._s(job.jobName))])]),_c('div',{staticClass:"names"},_vm._l((job.persons),function(person){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.personIsVisible(person)),expression:"personIsVisible(person)"}],key:person.PersonID,staticClass:"name",class:{ 'odd-color': _vm.personIsOdd(person) }},[_vm._v(" "+_vm._s(person.FirstName + ' ' + person.LastName)+" ")])}),0)])})],2)]),_c('div',{ref:"tableWrapper",staticClass:"table-wrapper"},[_c('div',{ref:"table",staticClass:"table",class:{ 'no-edit': _vm.noEdit || _vm.noEditFromData },on:{"scroll":_vm.tableScrollHandler,"mouseup":_vm.mouseUpHandler}},[_vm._l((_vm.visibleDaysInCalendar),function(day){return _c('div',{key:day.getTime(),staticClass:"day"},[_c('div',{staticClass:"day-description",class:{ today: _vm.isToday(day) },attrs:{"draggable":"false"}},[_vm._v(" "+_vm._s(_vm.getDayString(day))+" ")]),_vm._l((_vm.peopleGroupedInJobs),function(job){return _c('div',{key:job.jobID},_vm._l((job.persons),function(person){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.personIsVisible(person)),expression:"personIsVisible(person)"}],key:person.PersonID,staticClass:"cell",class:{
                'odd-color': _vm.personIsOdd(person),
              },on:{"click":function($event){return _vm.cellClickHandler(person, day)},"contextmenu":function($event){$event.preventDefault();return _vm.rightClickHandler(person, day, $event)},"mousedown":function($event){return _vm.mouseDownHandler(person, day, $event)},"mousemove":function($event){return _vm.mouseMoveHandler(day, $event)}}},[(
                  person.ScheduleDays && _vm.hasScheduleInThisDay(person, day)
                )?_c('div',{staticClass:"day-schedule",class:{
                  night: _vm.isNightTime(person, day),
                },style:({
                  width: _vm.dayScheduleWidth(person, day) + 'px',
                  left: _vm.dayScheduleLeftOffset(person, day) + 'px',
                })},[_c('span',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.scheduleString(person, day, _vm.dayScheduleWidth(person, day)))+" ")]),_c('div',{staticClass:"day-schedule-wrapper"},[_c('div',{staticClass:"actions"},[_c('i',{staticClass:"material-icons",on:{"click":function($event){return _vm.editClickHandler(person, day)}}},[_vm._v(" create ")]),_c('i',{staticClass:"material-icons",on:{"click":function($event){return _vm.copyHandler(person, day)}}},[_vm._v(" content_copy ")]),_c('i',{staticClass:"material-icons",on:{"click":function($event){return _vm.deleteHandler(person, day)}}},[_vm._v(" close ")])])])]):_vm._e(),(
                  _vm.cycle.isDragging &&
                  _vm.cycle.day == day.getTime() &&
                  _vm.cycle.personID == person.PersonID &&
                  _vm.cycle.multiplier > 0
                )?_c('div',{staticClass:"cycle-overlay",style:({
                  width: `calc(${_vm.cycle.multiplier} * 101%)`,
                }),attrs:{"click.stop":""}}):_vm._e()])}),0)})],2)}),_c('div',{ref:"totals",staticClass:"totals",on:{"wheel":function($event){$event.preventDefault();},"touchmove":function($event){$event.preventDefault();},"scroll":function($event){$event.preventDefault();}}},_vm._l((_vm.peopleGroupedInJobs),function(job){return _c('div',{key:job.jobID},_vm._l((job.persons),function(person){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.personIsVisible(person)),expression:"personIsVisible(person)"}],key:person.PersonID,staticClass:"total"},[_vm._v(" "+_vm._s(_vm.getMonthlyTotalForPerson(person))+" ")])}),0)}),0),(_vm.cycle.isDragging)?_c('div',{staticClass:"left scroller-for-dragging",on:{"mouseenter":_vm.startSlowlyScrollingLeft,"mouseleave":_vm.stopSlowlyScrolling}}):_vm._e(),(_vm.cycle.isDragging)?_c('div',{staticClass:"right scroller-for-dragging",on:{"mouseenter":_vm.startSlowlyScrollingRight,"mouseleave":_vm.stopSlowlyScrolling}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPaste),expression:"showPaste"}],ref:"paste",staticClass:"paste",on:{"click":_vm.pasteHandler}},[_vm._v(" Paste ")])],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noEdit),expression:"!noEdit"}],ref:"cycleIndicatorWrapper",staticClass:"cycle-indicator-wrapper"},_vm._l((_vm.peopleGroupedInJobs),function(job){return _c('div',{key:job.jobID},_vm._l((job.persons),function(person){return _c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.personIsVisible(person)),expression:"personIsVisible(person)"}],key:person.PersonID,staticClass:"cycle-indicator material-icons",class:{
            'not-visible':
              _vm.cycles.filter((c) => c.Person == person.PersonID).length == 0,
          },on:{"click":function($event){return _vm.showCycleInfo(person.PersonID)}}},[_vm._v(" history ")])}),0)}),0),_c('create-flexible-schedule',{attrs:{"visible":_vm.createScheduleVisible},on:{"close":function($event){_vm.createScheduleVisible = false},"create":_vm.createHandler}}),(_vm.selectedSchedule)?_c('edit-flexible-schedule',{attrs:{"visible":_vm.editScheduleVisible,"startHr":_vm.selectedScheduleStartHour,"startMm":_vm.selectedScheduleStartMinute,"endHr":_vm.selectedScheduleEndHour,"endMm":_vm.selectedScheduleEndMinute,"breakTime":_vm.selectedScheduleBreak},on:{"edit":(data) => _vm.createHandler(data, true),"close":function($event){_vm.editScheduleVisible = false}}}):_vm._e()],1),_c('custom-popup',{attrs:{"visible":_vm.cycleInfo.popupVisible,"title":"Current Cycle Info"},on:{"close":function($event){_vm.cycleInfo.popupVisible = false}}},[_c('div',{staticClass:"start"},[_c('span',[_vm._v("Start Date: "+_vm._s(_vm.cycleStartString))])]),_c('div',{staticClass:"btn stop",on:{"click":_vm.stopCurrentCycle}},[_vm._v("Stop Cycle")]),_c('div',{staticClass:"days",style:({ height: `calc(48px * ${_vm.peopleCount} + '172px')` })},_vm._l((_vm.cycleInfo.days),function(day,index){return _c('div',{key:index,staticClass:"day"},[_c('span',[_vm._v("Day "+_vm._s(index + 1))]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.cycleDayToString(day)))])])}),0),_c('p',[_vm._v(" * The cycle will be automatically updated, permanently for the future period. ")]),_c('p',[_vm._v(" * The command of editing or deleting the schedule in the cycle, will only conduct the change for the selected date. ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }