<template>
  <div class="background">
    <div class="news" v-if="news">
      <div class="news__title">
        {{ news.title }}
      </div>
      <div
        v-if="news.banner"
        class="news__banner"
        :style="{
          backgroundImage: `url(data:image/jpeg;base64,${news.banner})`,
        }"
      />
      <div
        :class="['news__text', { 'news__text--without-banner': !news.banner }]"
        v-html="news.text"
      />
      <div class="news__audience">
        <div class="news__audience-header">
          <div class="news__audience-header-title">
            <b>Audience</b> <span> {{ audience.length }} </span>
          </div>
        </div>
        <div class="news__audience-items" v-dragscroll>
          <div
            class="news__audience-items-item"
            v-for="person in audience"
            :key="person.PersonID"
          >
            <div
              v-if="person"
              class="news__audience-items-item-avatar"
              :style="{
                backgroundImage: `url(${$store.state.getAvatar + person.id})`,
              }"
            />
            <div class="news__audience-items-item-name">
              <p>{{ person.FirstName }}</p>
              <p>{{ person.LastName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span v-else></span>
  </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance";
import { dragscroll } from "vue-dragscroll";

export default {
  async created() {
    const { id } = this.$route.params;
    await this.getNews(id);
    this.getPerson(this.$route.params.id);
  },
  directives: {
    dragscroll,
  },
  data() {
    return {
      news: null,
      audience: [],
    };
  },
  methods: {
    async getNews(id) {
      const data = await axios
        .get("News/GetNewsDetails", {
          params: {
            News: id,
          },
        })
        .then((resp) => resp.data.Value);
      const news = data.News;
      const restrictions = data.Restrictions;
      const persons = [];

      this.news = {
        title: news.Title,
        banner: news.File,
        text: news.Description,

        restrictions: persons,
      };
    },
    getPerson(id) {
      axios
        .get("News/GetVisibilityInfoAboutNews", {
          params: {
            News: id,
          },
        })
        .then((resp) => {
          this.audience = resp.data.Value;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  padding: 45px 75px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 20px;
    font-weight: 500;
    color: #283593;
    margin-bottom: 15px;
  }

  &__banner {
    width: 100%;
    height: 250px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 25px;
    border-radius: 6px;
  }

  &__text {
    font-size: 14px;
    height: 210px;
    max-height: 210px;
    max-width: 100%;
    word-break: break-all;
    color: #283593;

    &--without-banner {
      max-height: calc(250px + 25px + 210px);
    }
  }

  &__audience {
    margin-top: auto;
    &-header {
      margin-bottom: 25px;

      &-title {
        font-size: 18px;
        color: #283593;

        b {
          font-weight: 500;
        }
        span {
          font-weight: lighter;
        }
      }
    }

    &-items {
      display: flex;
      overflow: hidden;
      cursor: e-resize;
      padding-bottom: 20px;

      &-item {
        margin-right: 65px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        &-avatar {
          width: 90px;
          height: 90px;
          background-color: white;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 100%;
          margin-bottom: 5px;
        }

        &-name {
          color: #283593;
          font-weight: lighter;
        }
      }
    }
  }
}
</style>
