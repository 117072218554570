import axios from 'axios'

const isLocal = ~location.href.indexOf('localhost')

export default axios.create({
  // baseURL: isLocal ? 'http://localhost:5000/api/' : '/api/',

  baseURL: isLocal ? 'https://intranet.upgaming.dev/api/' : '/api/',
  timeout: 1200000,
  withCredentials: true,
})
