import PayRoll from '@/components/PayRoll/PayRoll.vue'
import PayrollDashboard from '@/components/PayRoll/PayrollDashboard.vue'
import SalaryFactors from '@/components/PayRoll/SalaryFactors.vue'
import PersonsFactor from '@/components/PayRoll/PersonsFactor.vue'
import BonusLimits from '@/components/PayRoll/BonusLimits.vue'
import PayrollPrepEration from '@/components/PayRoll/PayrollPreperation.vue'
import Companies from '@/components/PayRoll/Companies.vue'
import Analitics from '@/components/PayRoll/Analitics/Analitics'
import { store } from '@/store.js'
import UploadedFilesList from '@/components/PayRoll/UploadedFilesList.vue'

const routes = {
  name: 'PAYROLL',
  path: '/Payroll',
  component: PayRoll,
  redirect: (to) => {
    let role = (val) => {
      return store.getters.hasRole(val)
    }
    if (role('Super Admin') || role('CFO') || role('HR') || role('Manager'))
      return '/Payroll/PayrollDashboard'
  },

  beforeEnter: function (to, from, next) {
    if (
      !store.getters.hasRole('Super Admin') &&
      !store.getters.hasRole('CFO') &&
      !store.getters.hasRole('Manager') &&
      !store.getters.hasRole('HR') &&
      from.path !== '/'
    ) {
      next(false)
    } else {
      next()
    }
  },
  children: [
    {
      path: 'PayrollDashboard',
      component: PayrollDashboard,
      name: 'DASHBOARD',
      beforeEnter: function (to, from, next) {
        if (
          !store.getters.hasRole('Super Admin') &&
          !store.getters.hasRole('CFO') &&
          !store.getters.hasRole('Manager') &&
          !store.getters.hasRole('HR') &&
          from.path !== '/'
        ) {
          next(false)
        } else {
          next()
        }
      },
    },
    {
      path: 'SalaryFactors',
      component: SalaryFactors,
      name: 'SALARY_FACTORS',
      beforeEnter: function (to, from, next) {
        if (
          !store.getters.hasRole('Super Admin') &&
          !store.getters.hasRole('CFO') &&
          from.path !== '/'
        ) {
          next(false)
        } else {
          next()
        }
      },
    },
    {
      path: 'Persons&Factors',
      component: PersonsFactor,
      name: 'Person factors',
    },

    {
      path: 'BonusLimits',
      component: BonusLimits,
      name: 'bonus limits',
      beforeEnter: function (to, from, next) {
        if (
          !store.getters.hasRole('Super Admin') &&
          !store.getters.hasRole('Manager') &&
          from.path !== '/'
        ) {
          next(false)
        } else {
          next()
        }
      },
    },
    {
      path: 'PayrollPreparation',
      component: PayrollPrepEration,
      name: 'payroll preparation',
    },
    {
      path: 'Companies',
      component: Companies,
      name: 'companies',
      beforeEnter: function (to, from, next) {
        if (
          !store.getters.hasRole('Super Admin') &&
          !store.getters.hasRole('CFO') &&
          from.path !== '/'
        ) {
          next(false)
        } else {
          next()
        }
      },
    },

    {
      path: 'Analytics',
      component: Analitics,
      name: 'analytics',
      beforeEnter: function (to, from, next) {
        if (
          !store.getters.hasRole('Super Admin') &&
          !store.getters.hasRole('CFO') &&
          !store.getters.hasRole('Manager') &&
          from.path !== '/'
        ) {
          next(false)
        } else {
          next()
        }
      },
    },
    {
      path: 'filesList',
      component: UploadedFilesList,
      name: 'UploadedFilesList',
      beforeEnter: function (to, from, next) {
        if (
          !store.getters.hasRole('Super Admin') &&
          !store.getters.hasRole('CFO') &&
          from.path !== '/'
        ) {
          next(false)
        } else {
          next()
        }
      },
    },
  ],
}

export default routes
