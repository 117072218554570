<template>
  <div class="background">
    <div class="salaries-content">
      <div class="essentials">
        <div class="user_info">
          <div class="user_info_img">
            <img :src="this.$store.state.getAvatar + this.$route.params.id" />
          </div>
          <div class="user_info_text">
            <div class="user_info_name">{{ personName }}</div>
            <div class="user_info_job">{{ personJobName }}</div>
          </div>
        </div>
        <!-- end user_info -->

        <div class="ess-container">
          <div class="add" @click="addNewClickHandler">
            <span>Assign New Salary</span>
          </div>
        </div>
      </div>
      <grid
        :columns="columns"
        :canDelete="true"
        @rowDelete="showDeletePopup"
        :canEdit="true"
        @rowEdit="editRow"
        @trClicked="trClickHandler"
        identificator="SalaryID"
        :gridData="gridData"
      />
      <custom-snackbar
        v-if="snackbarVisible"
        :purpose="snackbarPurpose"
        :text="snackbarText"
        @close="snackbarVisible = false"
      />
      <custom-popup
        :visible="popupVisible"
        @close="popupVisible = false"
        title="Assign Salary To "
        :flexibleSize="true"
      >
        <div class="user_info">
          <div class="user_info_img">
            <img :src="this.$store.state.getAvatar + this.$route.params.id" />
          </div>
          <div class="user_info_text">
            <div class="user_info_name">{{ personName }}</div>
            <div class="user_info_job">{{ personJobName }}</div>
          </div>
        </div>
        <!-- end user_info -->
        <form class="assign-form" @submit.prevent="assignSalary">
          <div class="flex">
            <custom-input
              class="input"
              v-model="newSalary.basicAmount"
              title="Salary basic amount"
              :isRequired="true"
              @clear="newSalary.basicAmount = ''"
            />
          </div>
          <div class="helper">
            <custom-select
              class="input"
              title="Currency"
              :isRequired="true"
              v-model="newSalary.currencyType"
              :options="salaryCurrencyOptions"
              @clear="newSalary.currencyType = ''"
              :clearVisible="true"
            />
          </div>
          <div class="helper">
            <custom-select
              class="input"
              title="Payout Currency"
              :isRequired="false"
              v-model="newSalary.payoutCurrencyType"
              :options="salaryCurrencyOptions"
              @clear="newSalary.payoutCurrencyType = ''"
              :clearVisible="true"
            />
          </div>
          <div class="helper">
            <custom-select
              class="input"
              title="Payment type"
              :isRequired="true"
              v-model="newSalary.paymentType"
              :options="paymentTypeOptions"
              @clear="newSalary.paymentType = ''"
              :clearVisible="true"
            />
          </div>

          <div class="helper">
            <custom-select
              class="input"
              title="Contract type"
              :isRequired="true"
              v-model="newSalary.contractType"
              :options="contractTypeOptions"
              @clear="newSalary.contractType = ''"
              :clearVisible="true"
            />
          </div>
          <div class="flex-2">
            <div class="helper">
              <custom-input
                class="input"
                v-model="newSalary.payDay"
                :isRequired="true"
                title="Pay day 1, 28"
                @clear="newSalary.payDay = ''"
              />
            </div>
            <div class="helper">
              <custom-select
                class="input"
                title="Count working days by"
                :isRequired="true"
                v-model="newSalary.countDay"
                :options="countDayOptions"
                @clear="newSalary.countDay = ''"
                :clearVisible="true"
              />
            </div>
          </div>

          <div class="flex-2">
            <div class="helper">
              <date-picker
                class="date"
                title="Start date"
                :isRequired="true"
                v-model="newSalary.startTime"
                @clear="newSalary.startTime = ''"
              />
            </div>
            <div class="helper">
              <date-picker
                class="date"
                title="End date"
                v-model="newSalary.endTime"
                @clear="newSalary.endTime = ''"
              />
            </div>
          </div>
          <div class="checkboxes-content" v-if="gridData && gridData.length">
            <div
              class="checkbox"
              :title="item.Name"
              v-for="item in factors"
              :key="item.ID"
              @click="factorClickHandler(item)"
            >
              <CustomCheckbox :value="item.Selected" />
              <span>{{ item.Name }}</span>
            </div>
            <!-- <div
              class="checkbox"
              :title="item.Name"
              v-for="item in factors"
              :key="item.ID"
              @click="factorClickHandler(item)"
            >
              <CustomCheckbox :value="item.Selected" />
              <span>{{ item.Name }}</span>
            </div> -->
          </div>
          <div class="assign-button-div">
            <button class="assign-button">Save</button>
          </div>
        </form>
      </custom-popup>
    </div>
    <delete-item
      @delete="deleteRow"
      @close="deletePopupVisible = false"
      v-if="deletePopupVisible"
      :text="deleteText"
    />
  </div>
</template>

<script>
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
import DatePicker from '@/components/ReusableComponents/DatePicker/DatePicker.vue'
import CustomInput from '@/components/ReusableComponents/CustomInput/CustomInput.vue'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import CustomSelect from '@/components/ReusableComponents/CustomSelect/CustomSelect.vue'
import CustomPopup from '@/components/ReusableComponents/CustomPopup/CustomPopup.vue'
import axios from '@/Helpers/AxiosInstance.js'
import CustomCheckbox from '@/components/ReusableComponents/CustomCheckbox/CustomCheckbox'
import DeleteItem from '@/components/ReusableComponents/DeleteItem/DeleteItem.vue'
import {
  getDateString,
  CETToTimestamp,
  timestampToCET,
  checkPermission,
  checkProfileParam,
} from '@/Helpers/HelperFunctions.js'

export default {
  created() {
    this.getSalaries()
    this.getContractType()
    this.getCountDay()
    this.getCurrencyType()
    this.getPersonJobs()
    this.getPersonName()
    this.getPaymentTypes()
    this.showHistory = this.accessPermissions.seeAllSalaries
  },
  components: {
    Grid,
    DeleteItem,
    CustomPopup,
    CustomSnackbar,
    DatePicker,
    CustomInput,
    CustomSelect,
    CustomCheckbox,
  },

  data() {
    return {
      accessPermissions: {
        seeAllSalaries: checkProfileParam(this.$route.params.userId)
          ? true
          : checkPermission('Access.SeeAllSalaries'),
        assignSalary: checkPermission('AssignSalary'),
      },
      gridData: [],
      salaryType: [],
      personName: '',
      personJobName: '',
      contractTypeOptions: [],
      selectedFactors: [],
      countDayOptions: [],
      paymentTypeOptions: [],
      salaryCurrencyOptions: [],
      factors: [],
      jobID: '',
      snackbarText: '',
      snackbarPurpose: 'error',
      snackbarVisible: false,
      popupVisible: false,
      deletePopupVisible: false,
      selectedAssignSalary: '',

      newSalary: {
        basicAmount: null,
        currencyType: null,
        payoutCurrencyType: null,
        contractType: null,
        paymentType: null,
        payDay: null,
        countDay: null,
        startTime: null,
        endTime: null,
      },
      showHistory: true,
    }
  },

  computed: {
    deleteText() {
      if (this.gridData.length == 0 || !this.selectedAssignSalary) return ''
      return `Would you like to delete the assign salary?`
    },
    columns() {
      return [
        {
          title: 'Job',
          key: 'JobName',
        },
        {
          title: 'Basic Amount',
          key: 'BasicAmount',
          editable: true,
          editType: 'Number',
          customTemplate: (item, val) => val,
        },
        {
          title: 'Gross Amount',
          key: 'Amount',
        },
        {
          title: 'Currency',
          key: 'CurrencyID',
          customTemplate: (item, val) => {
            let currency = this.salaryCurrencyOptions.filter(
              (el) => el.value == val,
            )[0]
            return currency ? currency.title : '-'
          },
          editable: true,
          editType: 'select',
          editOptions: this.salaryCurrencyOptions,
        },
        {
          title: 'Payout Currency',
          key: 'PayOutCurrencyID',
          customTemplate: (item, val) => {
            let currency = this.salaryCurrencyOptions.filter(
              (el) => el.value == val,
            )[0]
            console.log('1111', currency)
            return currency ? currency.title : '-'
          },
          editable: true,
          editType: 'select',
          editOptions: this.salaryCurrencyOptions,
        },
        {
          title: 'Payment Type',
          key: 'PaymentTypeID',
          customTemplate: (item, val) => {
            let payment = this.paymentTypeOptions.filter(
              (el) => el.value == val,
            )[0]
            return payment ? payment.title : '-'
          },
          editable: true,
          editType: 'select',
          editOptions: this.paymentTypeOptions,
        },
        {
          title: 'Pay Day',
          key: 'PayDayStr',
          editable: true,
          editType: 'text',
          customTemplate: (item, val) => val,
        },
        {
          title: 'Working Day',
          key: 'WorkedDaysCountTypeID',
          customTemplate: (item, val) => {
            let count = this.countDayOptions.filter((el) => el.value == val)[0]
            return count ? count.title : '-'
          },
          editable: true,
          editType: 'select',
          editOptions: this.countDayOptions,
        },
        {
          title: 'Contract Type',
          key: 'ContractTypeID',
          customTemplate: (item, val) => {
            let contract = this.contractTypeOptions.filter(
              (el) => el.value == val,
            )[0]
            return contract ? contract.title : '-'
          },
          editable: true,
          editType: 'select',
          editOptions: this.contractTypeOptions,
        },
        {
          title: 'Start Date',
          key: 'StartTime',
          customTemplate: (item, val) =>
            getDateString(CETToTimestamp(val), 'dd MMM yyyy'),
          editable: true,
          editType: 'date',
        },
        {
          title: 'End Date',
          key: 'EndTime',
          customTemplate: (item, val) =>
            val ? getDateString(CETToTimestamp(val), 'dd MMM yyyy') : '-',
          editable: true,
          editType: 'date',
        },
        {
          title: '',
          key: '',
          customTemplate: (item, val) => {
            return item.IsActiveSalary
              ? '<span class="enableButton">Factors</span>'
              : '<span class="disableButton">Factors</span>'
          },
        },
      ]
    },
  },
  methods: {
    trClickHandler(row, e) {
      if (
        e.target.matches('.disableButton') ||
        e.target.matches('.enableButton')
      ) {
        this.$router.push({
          path: `/Profile/${this.$route.params.id}/salaries/salariesFactor/${row.SalaryID}/`,
        })
      }
    },
    getPersonName(id) {
      id = this.$route.params.id
      axios.get(`Person/GetPersonsDetails?Person=${id}`).then((resp) => {
        let firstName = resp.data.Value.FirstName
        let lastName = resp.data.Value.LastName
        let UpperCaseName =
          firstName.charAt(0).toUpperCase() + firstName.slice(1)
        let UpperCaseLastName =
          lastName.charAt(0).toUpperCase() + lastName.slice(1)
        this.personName = `${UpperCaseName} ${UpperCaseLastName}`
        this.personJobName = resp.data.Value.JobName
      })
    },
    addNewClickHandler() {
      this.popupVisible = true
      if (this.gridData && this.gridData.length) {
        this.getPersonSalaryDetails()
      } else {
        this.newSalary = {
          basicAmount: null,
          amount: null,
          currencyType: null,
          payoutCurrencyType: null,
          contractType: null,
          paymentType: null,
          payDay: null,
          countDay: null,
          startTime: null,
          endTime: null,
        }
      }
    },
    async getPersonSalaryDetails() {
      const resp = await axios.get(
        `salary/GetPersonsFactors?PersonID=${this.$route.params.id}`,
      )
      if (!resp.data) return
      let factors = resp.data.Value.Factors
      let info = resp.data.Value.Salaryinfo

      this.factors = factors
      this.newSalary = {
        basicAmount: null,
        amount: null,
        currencyType: info.CurrencyID,
        payoutCurrencyType: info.PayOutCurrencyID,
        contractType: info.ContractTypeID,
        paymentType: info.PaymentTypeID,
        payDay: info.PayDayStr,
        countDay: info.WorkedDaysCountTypeID,
        startTime: null,
        endTime: null,
      }

      this.selectedFactors = factors.filter((el) => el.Selected)
    },
    getSalaries() {
      let id = this.$route.params.id
      axios.get(`salary/GetSalaries?Person=${id}`).then((response) => {
        this.gridData = response.data.Value.Salaries
        this.gridData.forEach((el) => {
          if (el.EndTime) {
            this.$set(el, 'canEdit', false)
            this.$set(el, 'canDelete', false)
          }
        })
      })
    },
    getContractType() {
      axios.get('salary/GetSalaryContractTypes').then((response) => {
        this.contractTypeOptions = response.data.Value.map((el) => ({
          title: el.Name,
          value: el.ID,
        }))
      })
    },
    getCountDay() {
      axios.get('salary/GetWorkedDaysCountTypes ').then((response) => {
        this.countDayOptions = response.data.Value.map((el) => ({
          title: el.Name,
          value: el.ID,
        }))
      })
    },
    getCurrencyType() {
      axios.get('Company/CurrencyTypes').then((resp) => {
        this.salaryCurrencyOptions = resp.data.Value.map((el) => ({
          title: el.Code,
          value: el.ID,
        }))
      })
    },
    getPaymentTypes() {
      axios.get('salary/GetPaymentTypes').then((resp) => {
        this.paymentTypeOptions = resp.data.Value.map((el) => ({
          title: el.Name,
          value: el.ID,
        }))
      })
    },
    showDeletePopup(id) {
      this.selectedAssignSalary = id
      this.deletePopupVisible = true
    },

    factorClickHandler(item) {
      const exist = this.selectedFactors.some((el) => el.ID === item.ID)
      if (!exist) {
        this.selectedFactors.push(item)
      } else {
        this.selectedFactors = this.selectedFactors.filter(
          (el) => el.ID !== item.ID,
        )
      }
      item.Selected = !item.Selected
    },
    deleteRow() {
      var data = {
        id: this.selectedAssignSalary,
        delete: true,
      }
      axios
        .post('salary/UpdateSalary', data)
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.snackbarText = 'Deleted Successfully!'
            this.snackbarPurpose = 'success'
            this.snackbarVisible = true
            this.deletePopupVisible = false
            this.getSalaries()
          } else {
            this.snackbarVisible = true
            this.snackbarText = resp.data.ErrorMsg
            this.snackbarPurpose = 'error'
          }
        })
        .catch(() => {
          this.snackbarVisible = true
          this.snackbarText = 'Error'
          this.snackbarPurpose = 'error'
        })
    },
    editRow(values, row) {
      let updateDate = {
        id: values.SalaryID,
        currency: values.CurrencyID,
        payOutCurrency: values.PayOutCurrencyID,
        basicAmount: values.BasicAmount,
        amount: null,
        startTime: values.StartTime,
        endTime: values.EndTime,
        paymentType: values.PaymentTypeID,
        contractType: values.ContractTypeID,
        payDayString: values.PayDayStr,
        workedDaysCountTypeID: values.WorkedDaysCountTypeID,
      }
      axios
        .post('salary/UpdateSalary', updateDate)
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.getSalaries()
            this.snackbarText = 'Updated Successfully!'
            this.snackbarPurpose = 'success'
            this.snackbarVisible = true
          } else {
            this.snackbarVisible = true
            this.snackbarText = resp.data.ErrorMsg
            this.snackbarPurpose = 'error'
          }
        })
        .catch(() => {
          this.snackbarVisible = true
          this.snackbarText = 'Error'
          this.snackbarPurpose = 'error'
        })
    },
    assignSalary() {
      let assignData = {
        person: this.$route.params.id,
        currency: this.newSalary.currencyType,
        payOutCurrency: this.newSalary.payoutCurrencyType,
        basicAmount: this.newSalary.basicAmount,
        amount: null,
        startTime: this.newSalary.startTime,
        endTime: this.newSalary.endTime,
        paymentType: this.newSalary.paymentType,
        contractType: this.newSalary.contractType,
        payDayString: this.newSalary.payDay,
        workedDaysCountTypeID: this.newSalary.countDay,
        FactorsJson: this.selectedFactors.length
          ? JSON.stringify(this.selectedFactors.map((el) => el.ID))
          : null,
      }

      axios.post('salary/AssignSalary', assignData).then((resp) => {
        if (!resp.data.ErrorMsg) {
          this.popupVisible = false
          this.newSalary.currencyType = null
          this.newSalary.payoutCurrencyType = null
          this.newSalary.basicAmount = null
          this.newSalary.amount = null
          this.newSalary.startTime = null
          this.newSalary.endTime = null
          this.newSalary.paymentType = null
          this.newSalary.contractType = null
          this.newSalary.payDay = null
          this.newSalary.countDay = null
          this.getSalaries()
        } else {
          this.snackbarVisible = true
          this.snackbarText = resp.data.ErrorMsg
          this.snackbarPurpose = 'error'
        }
      })
    },
    getPersonJobs(id) {
      id = this.$route.params.id

      axios
        .get(`jobs/GetPersonJobs?Person=${id}&ShowHistory=true`)
        .then((resp) => {
          this.jobID = resp.data.Value.map((el) => {
            if (el.EndTime == null) {
              return el.ID
            }
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.background {
  height: calc(100% - 43 * #{$rem});
  overflow: hidden;
}
.salaries-content {
  ::v-deep .background-for-header {
    display: none;
  }
  ::v-deep .alternativeHeader,
  ::v-deep .alternativeBody {
    top: 0rem;
  }

  ::v-deep td:nth-child(2) {
    max-width: 9.5rem !important;
  }
  ::v-deep td {
    width: auto;
    label,
    .el-input {
      width: 6.5rem !important;
    }
    label .calendar-icon {
      right: -0.25rem;
    }
    .el-input--prefix .el-input__inner {
      padding-left: 0.3125rem;
      width: 7rem !important;
    }
  }
}
.essentials {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15 * $rem;
  padding: 0 1.75rem;
  margin-top: 1.875rem;

  form {
    margin-right: 15 * $rem;
  }
  .ess-container {
    display: flex;
    justify-content: space-between;
    .filter-div {
      width: 12.938rem;
      margin: 0 1.275rem 0.0625rem 0;
      label {
        width: 100%;
        ::v-deep .el-input {
          width: 100%;
        }
      }
      ::v-deep .container {
        width: 12.938rem;
        background: #ffffff;
      }
    }
    & > .add {
      height: 2.375rem;
      margin: 0.0625rem 1.275rem 0.0625rem 0;
      padding: 0.5rem 2rem 0rem;
      border-radius: 0.1875rem;
      background-color: #3f46bf;
      cursor: pointer;

      span {
        -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
        font-size: 0.75rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #ededfc;
      }
    }
  }
}
.assign-content {
  display: flex;
  justify-content: space-between;
  padding-right: 5%;

  .assign-button {
    border-radius: 0.1875rem;
    width: 2.3125rem;
    height: 2.3125rem;
    position: relative;
    cursor: pointer;
    background: rgba($blue-primary, 0.6);
    margin-left: 2.5rem;

    &::before {
      content: '';
      position: absolute;
      width: 1.4375rem;
      height: 0.125rem;
      top: 1.125rem;
      left: 0.4375rem;
      background-color: #fff;
    }

    &::after {
      content: '';
      position: absolute;
      height: 1.4375rem;
      width: 0.125rem;
      top: 0.4375rem;
      left: 1.125rem;
      background-color: #fff;
    }
  }
}
.user_info {
  width: auto;
  padding: 0.75rem 1.6062rem 0.6875rem 1.5rem;
  display: flex;

  .user_info_img {
    width: 3.75rem;
    height: 3.75rem;
    background-color: rgba(40, 53, 147, 0.5);
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    img {
      width: 3.75rem;
    }
  }
  .user_info_text {
    margin: 0.75rem 0 0.375rem 0.75rem;
  }
  .user_info_name {
    -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
    font-size: 1rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: #283593;
  }
  .user_info_job {
    -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
    font-size: 0.75rem;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #959bc9;
  }
}
.assign-form {
  width: 30.9375rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .flex {
    width: 100%;
    .input {
      width: 100%;
    }
  }
  .flex-3 {
    display: flex;
    justify-content: space-between;
    .helper {
      width: 30%;
    }
  }
  .flex-2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .helper {
      width: 45%;
    }
  }
  .or {
    -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
    font-size: 1.125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #283593;
    margin-top: 2rem;
  }
  .helper {
    width: 14.0625rem;
    margin-top: 1.25rem;
    ::v-deep .title {
      font-size: 0.875rem !important;
    }
    label {
      width: 100%;
      ::v-deep span.title {
        font-size: 0.875rem;
        color: #959bc9;
        top: 0.8rem;
      }
      ::v-deep span.title.focused {
        top: -0.375rem;
      }
    }
  }
  .helper-small {
    width: 8.75rem;
    margin-top: 1.875rem;
    label {
      width: 100%;
      ::v-deep .el-date-editor.el-input {
        width: 100%;
        font-size: 0.875rem;
      }
      ::v-deep span.title {
        font-size: 0.875rem;
        color: #959bc9;
        top: 0.8rem;
      }
      ::v-deep span.title.focused {
        top: -0.375rem;
      }
    }
  }
}

.assign-button-div {
  width: 100%;
  text-align: right;
}
.assign-button {
  background-color: #6956d6;
  color: #fcfcfc;
  height: 3rem;
  width: 6.25rem;
  border-radius: 0.375rem;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1.875rem;
}
::v-deep .table-helper table tbody td .select-wrapper {
  width: unset !important;
}
::v-deep .table-helper table tbody td .calendar-icon {
  display: none;
}
.checkboxes-content {
  color: rgba($blue-primary, 0.9);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 130px;
  overflow-x: auto;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  align-content: flex-start;
  column-gap: 100px;
  .checkbox {
    display: flex;
    align-items: center;

    margin-top: 5px;
    cursor: pointer;
    > span {
      margin-left: 10px;
      font-size: 15px;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
