<template>
    <div class="permissions-header">
        <h2 v-html="title"></h2>
        <div class="right">
            <div class="add" v-if="currentSection == 1" @click="$emit('add-click')">
                <add-button />
                <span>{{ nodeToEdit ? 'Edit Node' : 'Add To Node' }}</span>
            </div>
            <custom-select
                :disabled="forAssign"
                v-model="filterBy"
                :options="[
                    { title: 'Permissions', value: '1' },
                    { title: 'Nodes', value: '2' },
                ]"
                title="Filter By"
            />
            <custom-input
                title="Search"
                v-model="search"
            />
        </div>
    </div>
</template>

<script>
import AddButton from '@/components/ReusableComponents/AddButton/AddButton'
import CustomSelect from '@/components/ReusableComponents/CustomSelect/CustomSelect'
import CustomInput from '@/components/ReusableComponents/CustomInput/CustomInput'
export default {
    components: {
        AddButton, CustomSelect, CustomInput,
    },
  props: {
        currentSection: [Number,String],
        title: String,
        nodeToEdit: Boolean,
        forAssign: Boolean,
    },
    data() {
        return {
            filterBy: '1',
            search: '',
        }
    },
    watch: {
        currentSection: {
          handler() {
            this.filterBy = this.currentSection
          },
          immediate: true,
        },
        search(val) {
            this.$emit('search', val)
        },
        filterBy(val) {
            this.$emit('change-section', val)
        },
    },
}
</script>

<style scoped lang="scss">
.permissions-header {
    display: flex;
    align-items: center;

    h2 {
        font-size: 24 * $rem;
        font-weight: 400;
        color: $blue-primary;
        text-transform: uppercase;
    }

    .right {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .add {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            span {
                margin-left: 12 * $rem;
                font-size: 16 * $rem;
                color: $blue-primary;
                font-weight: 400;
            }
        }

        .select-wrapper {
            margin: 0 6 * $rem 0 20 * $rem;
            min-width: 207 * $rem;
        }
    }
}
</style>
