<template>
  <div class="attendance-perofrmance-content">
    <div class="top-header">
      <ul>
        <li class="route">
          <router-link
            tag="span"
            :to="{ path: '/DailyPerformance' }"
            active-class="active"
          >
            Daily Performance
          </router-link>
        </li>

        <li
          class="route"
          v-if="
            this.$store.state.profile.OfficeID === 38 ||
            this.$store.state.profile.OfficeID === 3
          "
        >
          <router-link
            tag="span"
            :to="{ path: '/AttendanceReport' }"
            active-class="active"
          >
            Attendance Report
          </router-link>
        </li>

        <li
          class="route"
          v-if="
            this.$store.state.profile.OfficeID === 38 ||
            this.$store.state.profile.OfficeID === 3
          "
        >
          <router-link
            tag="span"
            :to="{ path: '/PersonsPerformance' }"
            active-class="active"
          >
            Attendance by Current Day
          </router-link>
        </li>
      </ul>
    </div>
    <div class="background">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// import AttendanceReport from "@/components/AttendanceReport/AttendanceReport.vue";

export default {}
</script>

<style scoped lang="scss">
.attendance-perofrmance-content {
  display: inline;
}

.top-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
  padding-bottom: 0.625rem;
  margin-left: 2.5rem;
  ul {
    list-style: none;

    li.route {
      margin-left: 1.875rem;
      display: inline-flex;
      &:first-child {
        margin-left: 0;
      }
      span {
        font-size: 0.9375rem;
        color: $blue-primary;
        font-weight: 500;
        line-height: 1.1875rem;
        cursor: pointer;
        position: relative;

        &.active {
          &::after {
            content: '';
            width: 100%;
            height: 0.25rem;
            background: $green-primary;
            z-index: 999;
            position: absolute;
            display: block;
            bottom: -0.625rem;
            left: 0;
          }
        }
      }
    }
  }
}
</style>
