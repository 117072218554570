<template>
  <div class="traffic-workspace background">
    <div
      class="traffic_additional_header"
      v-if="!this.$store.state.showTimeLine"
    >
      <traffic-tabs />
    </div>
    <div class="header">
      <router-link
        tag="a"
        :to="{ path: 'Traffic', params: { currentView: false } }"
      >
        <img :src="require('@/assets/icons/icon_back.svg')" />
      </router-link>
      <input-search
        title="Filter By Persons"
        :options="persons"
        @select="(value) => (selectedPerson = value)"
      />
    </div>
    <div
      class="traffic__data"
      v-if="selectedPerson && appData.length > 0 && webData.length > 0"
    >
      <div class="traffic__grid" id="appData">
        <traffic-grid
          :data="appData"
          @add-tag="removeProgram"
          :fields="appFields"
          :options="options"
        />
      </div>
      <div class="traffic__grid" id="webData">
        <traffic-grid
          :data="webData"
          :fields="webFields"
          @add-tag="removeProgram"
          :options="options"
        />
      </div>
    </div>
    <div class="no-records" v-else>No Records</div>
    <custom-snackbar
      v-if="success"
      @close="success = false"
      text="Successfuly unsigned"
    />
  </div>
</template>

<script>
import InputSearch from "@/components/ReusableComponents/InputSearch/InputSearch";
import axios from "@/Helpers/AxiosInstance";
import TrafficGrid from "@/components/Traffic/TrafficGrid";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar";
import TrafficTabs from "@/components/ReusableComponents/ViewChanger/TrafficTabs.vue";

export default {
  components: { TrafficTabs, CustomSnackbar, TrafficGrid, InputSearch },
  async created() {
    await this.getPersons();
  },
  data() {
    return {
      options: {
        withCheckbox: true,
        withoutSessionDuration: true,
      },
      persons: [],
      selectedPerson: null,
      appData: [],
      webData: [],
      success: false,
    };
  },
  computed: {
    appFields() {
      return [{ title: `Apps ${this.appData.length}`, class: "source" }];
    },
    webFields() {
      return [{ title: `Webs ${this.webData.length}`, class: "source" }];
    },
  },
  methods: {
    async getPersons() {
      const response = await axios.get("Person/GetPersons");
      this.persons = response.data.Value.Persons.map((el) => ({
        title: `${el.FirstName} ${el.LastName}`,
        value: el.ID,
      }));
    },
    async getData() {
      const response = await axios.get("attendance/GetPersonsPrograms", {
        params: {
          person: this.selectedPerson,
        },
      });
      const { Value } = response.data;
      const webData = Value.filter((el) => el.URL !== null);
      const appData = Value.filter((el) => el.URL === null);
      this.webData = webData.map((el) => ({
        checked: true,
        ProgramName: el.ProgramName,
        File: el.File,
        URL: el.URL,
      }));
      this.appData = appData.map((el) => ({
        checked: true,
        ProgramName: el.ProgramName,
        File: el.File,
      }));
    },
    async removeProgram(object) {
      object.checked = !object.checked;
      const response = await axios.post("attendance/UpdatePersonPrograms", {
        Person: this.selectedPerson,
        ProgramName: object.ProgramName,
        URL: object.URL,
        Status: false,
      });
      if (!response.data.ErrorMsg) {
        this.success = true;
      }
    },
  },
  watch: {
    async selectedPerson(newVal) {
      if (newVal) {
        await this.getData();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.traffic-workspace {
  height: 100%;
  padding: 25px;
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;

    a {
      margin-right: 30px;
    }
  }
}

.traffic__data {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 15px;
  border-radius: 10px;
  height: 100%;

  &-header {
    margin-bottom: 13px;
    color: #283593;
    margin-left: 70px;
  }
}

.traffic__grid {
  width: 49%;
  padding-top: 20px;
  overflow-y: auto;
  height: 100%;
}

.no-records {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  font-size: 50px;
  color: rgba(#283593, 0.5);
}
.traffic_additional_header {
  width: 120%;
  height: 3.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background: #fff;
  top: -2.025rem;
  left: -3.35rem;
  padding: 0 1.875rem;
}
</style>
