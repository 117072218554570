<template>
  <custom-popup :visible="true" :flexible-size="true">
    <div class="poll">
      <div class="poll__content">
        <div
          class="poll__banner"
          :style="{
            'background-image': `url(data:image/jpeg;base64,${poll.file})`,
          }"
        />
        <div class="poll__details">
          <div class="wrapper">
            <div class="poll__description">{{ poll.description }}</div>
            <div class="poll-date">
              Expiry date: {{ getDateString(poll.expiryDate, "dd MMM yyyy") }}
            </div>
            <div class="poll__question">{{ poll.question || "question" }}</div>
            <div class="poll__options">
              <div
                class="poll__option"
                v-for="(option, i) in poll.options"
                :key="i"
              >
                <div class="poll__option-wrapper">
                  <div class="poll__option-index">{{ i + 1 }}</div>
                  <div class="poll__option-name">{{ option.option }}</div>
                </div>
                <div
                  :class="[
                    'poll__option-checkbox',
                    {
                      'poll__option-checkbox--selected': selectedOptions.includes(
                        option.id
                      ),
                    },
                  ]"
                  @click="optionClickHandler(option)"
                >
                  <svg
                    v-if="selectedOptions.includes(option.id)"
                    class="checkbox__mark"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    xml:space="preserve"
                  >
                    <path
                      fill="#283593"
                      d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
			c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
			C514.5,101.703,514.499,85.494,504.502,75.496z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div class="poll__comment" v-if="!alreadySelected && !poll.anonymous">
            <textarea
              placeholder="Type your comment"
              type="text"
              class="cstm-input"
              v-model="comment"
            />
          </div>
        </div>
      </div>
      <div class="poll__button" v-if="!alreadySelected" @click="makePollChoice">
        Send
      </div>
    </div>
    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
  </custom-popup>
</template>

<script>
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";
import axios from "@/Helpers/AxiosInstance";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import getDateString from "@/Helpers/HelperFunctions";

export default {
  components: {
    CustomPopup,
    CustomSnackbar,
  },

  async created() {
    const { id } = this.$route.params;
    await this.getSinglePoll(id);
    await this.getChoicesForPoll(id);
  },
  data() {
    return {
      poll: {},
      selectedOptions: [],
      alreadySelected: false,
      comment: "",
      snackbarPurpose: "",
      snackbarText: "",
      snackbarVisible: false,
    };
  },

  methods: {
    async getSinglePoll(id) {
      const data = await axios
        .get("News/GetPollDetails", {
          params: {
            Poll: id,
          },
        })
        .then((resp) => {
          const { Value } = resp.data;
          return Value;
        });

      const {
        Poll: {
          Poll,
          Anonymous,
          Description,
          ExpiryDate,
          File,
          Question,
          Title,
          Type,
        },
        PollOptions: options,
      } = data;
      this.poll = {
        id: Poll,
        title: Title,
        file: File,
        anonymous: Anonymous,
        type: Type,
        question: Question,
        description: Description,
        options: options.map(({ Option, PollOPtionID }) => ({
          option: Option,
          id: PollOPtionID,
        })),
        expiryDate: new Date(ExpiryDate),
      };
    },
    getDateString(date, format = "yyyy-MM-dd") {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      if (date === "Invalid Date") return;

      const MONTHS = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      var days = ("0" + date.getDate()).slice(-2),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        year = date.getFullYear(),
        hours = ("0" + date.getHours()).slice(-2),
        minutes = ("0" + date.getMinutes()).slice(-2),
        seconds = ("0" + date.getSeconds()).slice(-2);

      return format
        .replace("dd", days)
        .replace("MMM", MONTHS[parseInt(month) - 1])
        .replace("MM", month)
        .replace("yyyy", year)
        .replace("yy", String(year).slice(-2))
        .replace("HH", hours)
        .replace("mm", minutes)
        .replace("ss", seconds)
        .replace("ww", WEEKDAYS[date.getDay()]);
    },
    optionClickHandler(option) {
      const exists = this.selectedOptions.includes(option.id);
      if (this.poll.type === 1) {
        if (exists) {
          this.selectedOptions = [];
        } else {
          this.selectedOptions = [option.id];
        }
      } else {
        if (exists) {
          this.selectedOptions = this.selectedOptions.filter(
            (el) => el !== option.id
          );
        } else {
          this.selectedOptions.push(option.id);
        }
      }
    },
    async makePollChoice() {
      const { ID: userId } = this.$store.state.profile;
      const response = await axios.post("News/MakePollChoice", {
        Person: userId,
        Comment: this.comment,
        TVP: this.selectedOptions.map((el) => ({ PollOption: el })),
      });

      if (response.ErrorMsg) {
        this.snackbarVisible = true;
        this.snackbarText = "Successfully saved";
        this.snackbarPurpose = "success";
      }
      this.$emit("close");
    },
    async getChoicesForPoll(id) {
      const { ID: userId } = this.$store.state.profile;
      const response = await axios.get("News/GetPersonChoicesOnPoll", {
        params: {
          Person: userId,
          Poll: id,
        },
      });

      const { Value: data } = response.data;

      this.selectedOptions = data.map(({ OptionID }) => OptionID);
      if (this.selectedOptions.length > 0) this.alreadySelected = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.cstm-input {
  background-color: transparent;
  border: 0;
  font-size: 14 * $rem;
  width: 100%;
  color: #283593;
  max-height: 50px;
  max-width: 100%;
  font-weight: 300;

  &::placeholder {
    color: #9696b9;
  }

  &:focus {
    outline: 0;
  }
}

.poll {
  display: flex;
  flex-direction: column;
  height: 700px;
  width: 1200px;

  &__content {
    display: flex;
    height: 550px;
  }

  &__banner {
    width: 50%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
  }

  &__details {
    margin-left: 50px;
    color: #9696b9;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 50%;
  }

  &__description {
    height: 50px;
    overflow-y: auto;
    margin-bottom: 60px;
    color: $blue-primary;
  }

  &__question {
    height: 50px;
    overflow-y: auto;
    margin-bottom: 60px;
    color: $blue-primary;
  }

  &__options {
    max-height: 300px;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    overflow-y: auto;
    padding-right: 10px;
  }

  &__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-index {
      margin-right: 10px;
      font-weight: 500;
      color: #9696b9;
    }

    &-name {
      color: #283593;
      max-width: 490px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-checkbox {
      margin-left: auto;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      border: 1px solid #959bc9;
      background-color: transparent;

      &--selected {
        border: 1px solid #283593 !important;
      }

      svg {
        width: 12px;
        height: 10px;
      }
    }
  }

  &__button {
    cursor: pointer;
    margin-top: 85px;
    margin-left: auto;
    background-color: #959bc9;
    color: white;
    border-radius: 6px;
    width: 145px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
