<template>
    <custom-popup @close="$emit('close')" title="Create Schedule" :visible="true" v-if="visible">
        <schedule-time-templates
            :fromTime="from"
            :toTime="to"
            @select="selectTemplateHandler"
        />
        <schedule-time-picker 
            :fromTime="from"
            :toTime="to"
            :breakTime="breakTime"
            @change-from="fromTime => from = fromTime"
            @change-to="toTime => to = toTime"
            @change-break="breakT => breakTime = breakT"
        />
        <div class="btn" @click="saveClickHandler">Save</div>
    </custom-popup>
</template>

<script>
import CustomPopup from '@/components/ReusableComponents/CustomPopup/CustomPopup';
import ScheduleTimePicker from '@/components/ReusableComponents/ScheduleTimePicker/ScheduleTimePicker.vue';
import ScheduleTimeTemplates from '@/components/FixedSchedules/ScheduleTimeTemplates.vue'
export default {
    components: {
        CustomPopup,
        ScheduleTimeTemplates,
        ScheduleTimePicker,
    },
    props: {
        visible: Boolean,
    },
    data() {
        return {
            from: 600,
            to: 1140,
            breakTime: 60,
        };
    },
    methods: {
        saveClickHandler() {
            this.$emit('create', {
                startHour: parseInt(this.from / 60),
                endHour: parseInt(this.to / 60),
                startMinute: this.from % 60,
                endMinute: this.to % 60,
                break: this.breakTime,
            })
        },
        changeHandler(data) {
            this.from = data.fromTime
            this.to = data.toTime
        },
        selectTemplateHandler({ fromTime, toTime }) {
            this.from = fromTime
            this.to = toTime
        },
    },
    watch: {
        visible(value) {
            if(value) {
                this.startHour = 0
                this.startMinute = 0
                this.endHour = 0
                this.endMinute = 0
            }
        },
    },
};
</script>

<style scoped lang="scss">
.schedule-time-picker {
    margin-top: 100 * $rem;
}
.btn {
    color: white;
    font-size: 16 * $rem;
    font-weight: 400;
    background-color: rgba($blue-primary, 0.5);
    border-radius: 3 * $rem;
    width: 100 * $rem;
    height: 40 * $rem;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30 * $rem;
    cursor: pointer;
}
</style>
