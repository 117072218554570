<template>
  <div
    class="file-block"
    :class="{ upload: initial }"
    @click="$emit('fileBlock-clicked')"
  >
    <label v-if="initial">
      <div class="top">
        <div class="upload-logo">
          <i class="material-icons">publish</i>
        </div>
        <span>Upload File</span>
      </div>
      <div class="bottom">
        <i class="material-icons">info_outlined</i>
        <span>Max file size {{ maxFileSizeInMb }} MB</span>
      </div>
      <input type="file" @change="upload" />
    </label>
    <div v-else class="uploaded">
      <div class="top" @click="$emit('viewFile')">
        <div class="uploaded-logo" :type="fileType">{{ fileInitials }}</div>
        <span :title="uploadedFile.name">{{ uploadedFile.name }}</span>
      </div>
      <div class="bottom" @click.stop="">
        <span>{{ uploadedFile.dateCreated || "today" }}</span>
        <span>{{ (uploadedFile.size / 1e6).toFixed(2) }} MB</span>
      </div>
      <img src="@/assets/icons/close.svg" @click="$emit('delete')" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initial: Boolean,
    uploadedFile: {
      type: [File, Object],
    },
  },
  data() {
    return {
      maxFileSizeInMb: 10,
    };
  },
  methods: {
    upload(e) {
      var file = e.target.files[0];
      var sizeInMb = file.size / 1e6;
      if (sizeInMb > this.maxFileSizeInMb) {
        //some message here
        return;
      }
      this.$emit("fileUpload", file);
    },
  },
  computed: {
    fileType() {
      return this.uploadedFile.name.split(".").slice(-1)[0];
    },
    fileInitials() {
      switch (this.fileType) {
        case "pdf":
          return "PDF";
        case "xlsx":
          return "E";
        case "docx":
          return "W";
        case "doc":
          return "W";
        case "PNG":
          return "PNG";
        case "png":
          return "PNG";
        case "SVG":
          return "SVG";
        case "svg":
          return "SVG";
        case "txt":
          return "TXT";
        case "jpg":
          return "JPG";
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.file-block {
  width: 185 * $rem;
  height: 105 * $rem;
  border-radius: 6 * $rem;
  border: $rem solid #dedff4;
  background-color: white;
  margin-top: 50 * $rem;
  padding: 16 * $rem;
  margin-right: 45 * $rem;
  box-shadow: 0.375rem 0.375rem 0.625rem rgba(40, 52, 147, 0.08);

  &.upload {
    cursor: pointer;

    .top {
      display: flex;
      align-items: center;

      .upload-logo {
        width: 45 * $rem;
        height: 45 * $rem;
        border-radius: 6 * $rem;
        background-color: #e9eaf4;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 12 * $rem;

        i {
          color: #cccfe6;
        }
      }

      span {
        font-size: 16 * $rem;
        font-weight: 700;
        color: rgba(40, 53, 147, 0.4);
        width: min-content;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      margin-top: 9 * $rem;

      i {
        color: #eabb71;
        font-size: 20 * $rem;
        margin-right: 6 * $rem;
        width: 20px;
      }

      span {
        font-size: 9.5 * $rem;
        font-weight: 300;
        color: #9c9cae;
      }
    }

    label:hover {
      cursor: pointer;
    }

    input {
      visibility: none;
      width: 0;
    }
  }

  .uploaded {
    position: relative;

    .top {
      display: flex;
      align-items: center;

      .uploaded-logo {
        width: 45 * $rem;
        height: 45 * $rem;
        border-radius: 6 * $rem;
        background-color: #e9eaf4;
        margin-right: 12 * $rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &[type="pdf"] {
          background-color: #ff6760;
          font-size: 17 * $rem;
          color: white;
          font-weight: 700;
          letter-spacing: $rem;
        }

        &[type="xlsx"] {
          background-color: rgba(17, 123, 65, 0.871);
          font-size: 20 * $rem;
          color: white;
          font-weight: 700;
        }
        &[type="PNG"],
        [type="png"] {
          background-color: $green-primary;
          font-size: 15 * $rem;
          color: white;
          font-weight: 700;
          letter-spacing: $rem;
        }
        &[type="SVG"],
        [type="svg"] {
          background-color: $green-primary;
          font-size: 15 * $rem;
          color: white;
          font-weight: 700;
          letter-spacing: $rem;
        }
        &[type="jpg"] {
          background-color: rgba(17, 123, 65, 0.871);
          font-size: 17 * $rem;
          color: white;
          font-weight: 700;
        }
        &[type="txt"] {
          background-color: #364ba2;
          font-size: 17 * $rem;
          color: white;
          font-weight: 700;
        }

        &[type="docx"],
        &[type="doc"] {
          background-color: #364ba2;
          font-size: 20 * $rem;
          color: white;
          font-weight: 700;
        }
      }

      span {
        font-size: 15 * $rem;
        font-weight: 700;
        color: #283593;
        max-width: calc(100% - 70 * #{$rem});
        text-overflow: ellipsis;
        white-space: break-spaces;
        overflow: hidden;
        max-height: 55 * $rem;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 9 * $rem;

      span {
        font-size: 9.5 * $rem;
        font-weight: 300;
        color: #9c9cae;
      }

      span:first-child {
        margin-right: auto;
      }
    }

    img {
      position: absolute;
      top: -10 * $rem;
      right: -10 * $rem;
      width: 20 * $rem;
      cursor: pointer;
    }
  }
}
</style>