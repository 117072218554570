<template>
  <div class="vacation-table-content" ref="tableContainer" @scroll="onScroll">
    <template v-for="(dep, key) in departments">
      <div class="dep-container" :key="key">
        <h2 class="dep-name" v-if="key == 'null'"></h2>
        <h2 class="dep-name" v-if="key != 'null'">
          {{ key }}
        </h2>

        <table>
          <tbody>
            <tr v-for="person in dep" :key="person.index">
              <td>
                <div class="request-person-container">
                  <div v-if="person.Comment" class="person-commetn">
                    <div class="person-request-comment">
                      {{ person.Comment }}
                    </div>
                    <img
                      class="user-comment-icon"
                      src="@/assets/icons/red/comment.svg"
                    />
                  </div>
                  <div
                    class="request-person"
                    @click="$emit('show-popup', person)"
                  >
                    <div
                      :style="{
                        backgroundImage: `url(${getAvatarSrc(
                          person.PersonID,
                        )})`,
                      }"
                      class="avatar"
                    ></div>
                    <div class="request-person-info">
                      <span class="person-info-name">
                        {{ person.FirstName }}
                      </span>
                      <span class="person-info-name">
                        {{ person.LastName }}
                      </span>
                      <span class="job-name">{{ person.JobName }}</span>
                    </div>
                    <div class="border-div"></div>
                  </div>
                </div>
              </td>
              <td>
                <div class="value-container">
                  <div class="value-content-container">
                    <span class="value-title">Start Date</span>
                    <span class="value">
                      {{ computedDate(person.FromDate) }}
                    </span>
                  </div>
                  <div class="dot"></div>
                  <div class="value-content-container">
                    <span class="value-title">End Date</span>
                    <span class="value">{{ computedDate(person.ToDate) }}</span>
                  </div>
                  <div class="border-div"></div>
                </div>
              </td>
              <td>
                <div class="value-container">
                  <div class="value-content-container">
                    <span class="value-title">Total</span>
                    <span class="value">
                      {{ person.TotalDays + ' ' + 'Days' }}
                    </span>
                  </div>
                  <div class="border-div"></div>
                </div>
              </td>
              <td>
                <div class="value-container">
                  <div class="value-content-container">
                    <span class="value-title">Vacation Type</span>
                    <span class="value">{{ person.VacationTypeName }}</span>
                  </div>
                  <div class="border-div"></div>
                </div>
              </td>
              <td>
                <div class="value-container">
                  <div class="value-content-container">
                    <span class="value-title">Last Vacation</span>
                    <span v-if="person.LastVacationDate" class="value">
                      {{
                        getDurationString(
                          person.LastVacationDate,
                          person.FromDate,
                        )
                      }}
                    </span>
                    <span class="value" v-if="!person.LastVacationDate">-</span>
                  </div>
                  <div class="border-div"></div>
                </div>
              </td>
              <td>
                <div class="value-container">
                  <div class="value-content-container">
                    <span class="value-title">Days Left</span>
                    <span class="value">{{ person.LeftDays }}</span>
                  </div>
                  <div class="border-div"></div>
                </div>
              </td>
              <td class="comment-td">
                <div class="comment-container">
                  <div
                    class="comment-icon-content"
                    @click.stop="
                      getComment(person.VacationID),
                        (person.commentVisible = true)
                    "
                  >
                    <img
                      class="comment-icon"
                      src="@/assets/icons/red/comment.svg"
                    />
                    <span>{{ person.CommentsNum }}</span>
                    <comment-popup
                      @delete-icon="deleteComment"
                      v-if="person.CommentsNum > 0"
                      :commentVisible="person.commentVisible"
                      @close="person.commentVisible = false"
                      :data="comments"
                    ></comment-popup>
                  </div>
                  <slot></slot>
                </div>
              </td>
              <td>
                <div class="conf-container">
                  <vacation-confirmation
                    :confirmNumb="person.ApprovesNum"
                    :rejectNumb="person.RejectsNum"
                    :mustConfirmNumb="person.MustBeApprovedByNum"
                    :approved="person.Approved"
                  >
                    <vacation-custom-select
                      :default="null"
                      :person="person"
                      :options="person.statusTypes"
                      v-model="choosenStatus"
                      @select-opt="selectOptHandler"
                      @show-statuses="$emit('show-statuses', person)"
                    />
                  </vacation-confirmation>
                </div>
              </td>
              <td class="actions">
                <span
                  v-if="
                    person.CreatedBy == loggedPerson && person.Approved == null
                  "
                  class="icon-span"
                  @click="$emit('delete-request', person)"
                >
                  <img src="@/assets/delete.svg" alt="delete" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import {
  getDateString,
  CETToTimestamp,
  timestampToCET,
} from '@/Helpers/HelperFunctions.js'
import axios from '@/Helpers/AxiosInstance.js'
import VacationConfirmation from './VacationConfirmation.vue'
import vacationCustomSelect from './vacationCustomSelect.vue'
import commentPopup from '@/components/vacationUser/commentPopup.vue'

export default {
  components: {
    VacationConfirmation,
    vacationCustomSelect,
    commentPopup,
  },
  created() {
    this.loggedPerson = this.$store.state.profile.ID
  },

  data() {
    return {
      choosenStatus: '',
      loggedPerson: '',
      comments: [],
      canEdit: false,

      pageSize: 20,

      showPopup: true,
      // statusType: [
      //   {
      //     Name: "Approve",
      //     ID: 1,
      //     color: "#18cc9f",
      //   },
      //   {
      //     Name: "Reject",
      //     ID: 2,
      //     color: "#d06779",
      //   },
      //   {
      //     Name: "Pending",
      //     ID: 3,
      //     color: "#9696b9",
      //   },
      // ],
    }
  },
  computed: {
    // statusTypeOptions() {
    //   return this.statusType.map((el) => ({
    //     title: el.Name,
    //     value: el.ID,
    //     color: el.color,
    //   }));
    // },
  },

  props: {
    departments: Object,
    maxPageCount: Number,
    page: Number,
    scrollTop: Boolean,
  },

  methods: {
    computedDate(person) {
      let date = getDateString(person, 'dd MMM yyyy')
      return date
    },
    selectOptHandler(value, person) {
      this.$emit('approve', value, person)
    },
    getAvatarSrc(id) {
      return this.$store.state.getAvatar + id
    },
    lastVacationDate(x) {
      let date = getDateString(x, 'dd MMM yyyy')
      return date
    },
    getDurationString(end, start) {
      var duration = Math.abs(start - end)
      var fullDays = parseInt(duration / (24 * 3600000))
      var days = fullDays % 30
      var months = parseInt(fullDays / 30)
      var res = ''
      if (months) {
        if (res) res += ', '
        res += `${months}month`
      }
      if (days) {
        if (res) res += ' & '
        res += `${days}days`
      }
      return res
    },
    getComment(vacationID) {
      axios
        .get(`vacations/GetVacationComments?Vacation=${vacationID}`)
        .then((resp) => {
          this.comments = resp.data.Value
        })
    },
    deleteComment(el) {
      this.$confirm('Are you sure you want to delete comment?').then(() => {
        axios
          .post('vacations/UpdateVacationResponse', {
            ResponseID: el.ResponseID,
            Comment: null,
          })
          .then((resp) => {
            this.getComment(el.VacationID)
          })
      })
    },
    onScroll(event) {
      if (this.page === this.maxPageCount) return
      var el = event.srcElement
      if (el.scrollTop >= el.scrollHeight - el.clientHeight) {
        this.$emit('scolled-bottom')
      }
    },
  },
  watch: {
    scrollTop() {
      if (this.scrollTop) {
        this.$refs.tableContainer.scrollTop = 0
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.vacation-table-content {
  height: calc(100% - 263px);
  overflow-y: scroll;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: unset;
  table-layout: fixed;
  tbody {
    .icon-span {
      height: 35px;
      width: 35px;
      display: block;
      cursor: pointer;
    }
    tr {
      height: 6.1875rem;
      td:nth-child(5) {
        width: 170px;
      }
      td:nth-child(1) {
        padding-left: 80px;
        width: 374px;
      }
      td:nth-child(2) {
        width: 267px;
      }
      td:nth-child(3) {
        width: 110px;
      }
      td:nth-child(4) {
        width: 137px;
      }
      td:nth-child(5) {
        .value {
          font-size: 15px;
        }
      }
      td:nth-child(6) {
        width: 106px;
      }
      td:nth-child(7) {
        // width: 11.5625rem;
      }
      td:nth-child(8) {
        width: 130px;
      }
      td:last-child {
        width: 80px;
      }
    }
    tr:nth-child(2n + 1) {
      background-color: #e7e7f9;
    }
  }
}
::v-deep.conf-container {
  width: 130px;
}
.comment-icon-content {
  cursor: pointer;
  display: inherit;
  .comment-icon {
    height: 38px;
    opacity: 0.8;
  }
  span:nth-child(2) {
    opacity: 0.8;
    margin: 25px 0 0 -10px;
    font-size: 14px;
  }
}
.request-person-container {
  position: relative;
  .person-commetn {
    img {
      height: 38px;
      opacity: 0.8;
      position: absolute;
      left: -18px;
      top: 0;
    }
    div {
      background: #283593;
      padding: 15px;
      border-radius: 10px;
      position: absolute;
      top: 30px;
      left: -7px;
      z-index: 100;
      font-size: 12px;
      color: #ffffff;
      display: none;
    }
    &:hover div {
      display: block;
      cursor: pointer;
    }
  }
  .user-comment-icon {
    height: 38px;
    opacity: 0.8;
    position: absolute;
    left: -18px;
    top: 0;
    .person-request-comment {
      background: #283593;
      padding: 15px;
      border-radius: 10px;
      position: absolute;
      top: 30px;
      left: -7px;
      font-size: 12px;
      color: #ffffff;
      display: none;
    }
    &:hover .person-request-comment {
      display: block;
      cursor: pointer;
    }

    &:hover + .person-request-comment {
      display: block;
    }
  }
}
.request-person {
  display: flex;
  align-items: center;
  width: 16.8rem;
  cursor: pointer;
  .request-person-info {
    display: flex;
    flex-direction: column;

    margin-left: 12px;
  }

  .circle {
    width: 88px;
    height: 88px;
    border-radius: 100%;
    background-color: #ffffff;
  }
  .person-info-name {
    color: $blue-primary;
    font-size: 16px;
    font-weight: 300;
  }
  .job-name {
    font-size: 13px;
    color: #9696b9;
    margin-top: 5px;
    max-width: 150px;
    overflow: hidden;
    white-space: normal;
  }
}

.start-date-content {
  margin-left: 15px;
}
.end-date-content {
  margin-right: 15px;
}
.value-container {
  .dot {
    height: 7 * $rem;
    width: 7 * $rem;
    border-radius: 100%;
    background-color: $blue-primary;
    opacity: 0.3;
    align-self: flex-end;
    margin-bottom: 7px;
  }
}
.dep-name {
  font-weight: 500;
}
.border-div {
  height: 35px;
  width: 1px;
  margin-left: auto;
  background-color: #9696b9;
}
.value-title {
  color: #9696b9;
  opacity: 0.9;
  font-size: 13px;
  font-weight: 300;
}
.value {
  color: $blue-primary;
  font-size: 16px;
  font-weight: 300;
  margin-top: 10px;
  white-space: break-spaces;
  word-break: break-word;
}

.value-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.value-content-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  min-width: 90px;
}
.dep-name {
  font-size: 20px;
  color: $blue-primary;
  margin-bottom: 35 * $rem;
  margin-left: 80 * $rem;
}
.dep-container {
  margin-bottom: 35 * $rem;
}
.dep-container:last-child {
  margin-bottom: 0;
}
.comment-container {
  display: flex;
  align-items: center;
  color: $blue-primary;
  ::v-deep .input-content {
    max-width: 150px;
    overflow: hidden;
  }
  ::v-deep.comment-popup {
    right: 25%;
    position: absolute;
    top: 70px;
    z-index: 20;
    padding-right: 10px;
  }
}
.comment-td {
  position: relative;
}
.avatar {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;

  background-position: center;
}
</style>
