<template>
  <div class="popup" :class="{ flexible: flexibleSize }">
    <div class="title">
      {{ title }}
      <i class="material-icons" @click="$emit('close')">close</i>
    </div>
    <div class="popup-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    flexibleSize: Boolean,
  },
};
</script>

<style scoped lang="scss">
.popup {
  background-color: $background;
  position: absolute;
  max-width: 90vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 1000 * $rem;
  border-radius: 5 * $rem;
  height: 30vh;
  transition: all 0.3s ease-out;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  border: $rem solid $grey-border;

  &.expanded {
    height: 90vh;
  }

  .title {
    font-size: 20 * $rem;
    font-weight: 500;

    height: 3.75rem;
    padding: 0 25 * $rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $blue-primary;

    i {
      font-size: 24 * $rem;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;

      &:hover {
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }

  .popup-container {
    padding: 10 * $rem 25 * $rem;
    width: 100%;
    height: calc(100% - #{60 * $rem});
    overflow: auto;
  }

  ::v-deep span.title.focused {
    background-color: $background !important;
  }
}

.popup.flexible {
  min-width: initial;
  height: initial;
}

@mixin labelWidth($count) {
  width: calc(100% /#{$count} - #{15 * $rem});
}

@media only screen and (max-width: 1100px) {
  .popup {
    min-width: initial;
    width: 90%;
    height: auto;

    form {
      flex-wrap: wrap;
      width: calc(100% + #{15 * $rem});

      label,
      .select-wrapper,
      button {
        @include labelWidth(5);
        flex-shrink: 0;
        margin-bottom: 15 * $rem;
      }
    }
  }
}

@media screen and (max-width: 870px) {
  .popup form {
    label,
    .select-wrapper {
      @include labelWidth(4);
    }
  }
}

@media screen and (max-width: 770px) {
  .popup form {
    label,
    .select-wrapper {
      @include labelWidth(3);
    }
  }
}

@media screen and (max-width: 640px) {
  .popup form {
    label,
    .select-wrapper {
      @include labelWidth(2);
    }
  }
}

@media screen and (max-width: 500px) {
  .popup form {
    label,
    .select-wrapper {
      @include labelWidth(1);
    }
  }
}
</style>
