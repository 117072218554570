<template>
  <div
    class="sidebar"
    :class="{
      admin: !$store.state.showTimeLine,
      dark: $route.fullPath === '/Grades/CareerMapping',
    }"
  >
    <div class="logo" @click="changePageLayout">
      <img :src="logoSource" alt="Logo" />
    </div>
    <div class="routes-wrapper">
      <div class="router-item" v-for="(page, index) in pages" :key="index">
        <router-link
          :to="page.routerLink"
          :class="{ active: page.active }"
          v-if="!page.hidden"
        >
          <i class="material-icons" v-if="page.icon">{{ page.icon }}</i>
          <img
            class="icon"
            v-if="page.svg"
            :id="page.identifier"
            :src="page.active ? page.svg.active : page.svg.inactive"
          />
          <div
            class="page-title"
            v-show="!$store.state.showTimeLine || page.active"
          >
            {{ page.title }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { checkPermission } from '@/Helpers/HelperFunctions'

export default {
  created() {
    this.activeRoute = this.$route.fullPath

    this.setGradingMainRoute()
  },

  mounted() {
    if (this.$route.query.fromOneadmin == 'true') {
      const sideBarElement = document.querySelector('.sidebar')
      const siteContent = document.querySelector('.site-content')
      siteContent.style.marginLeft = '150px'

      sideBarElement.remove()
    }
  },
  data() {
    return {
      accessPermissions: {
        updatePermissions: checkPermission('UpdatePermission'),
        getRoles: checkPermission('GetRoles'),
        getVacations: checkPermission('GetVacations'),
        getSchedules: checkPermission('GetSchedules'),
        getJobs: checkPermission('GetJobs'),
      },
      activeRoute: '',
      previousRoute: '',
      redirectedFromOneadmin: false,
    }
  },

  watch: {
    $route(to, from) {
      this.activeRoute = to.fullPath
      this.previousRoute = from.fullPath

      // if (to.query.fromOneadmin == 'true') {
      //   const sideBarElement = document.querySelector('.sidebar')
      //   const siteContent = document.querySelector('.site-content')
      //   sideBarElement.remove()

      //   this.redirectedFromOneadmin = true

      //   document.removeChil
      // } else {
      //   this.redirectedFromOneadmin = false
      // }
    },
  },
  computed: {
    allPages() {
      var allPages = [
        {
          title: 'Traffic',
          routerLink: '/MyTraffic',
          identifier: 'mytraffic',
          svg: {
            inactive: require('@/assets/icons/traffic.svg'),
            active: require('@/assets/icons/traffic.svg'),
          },
          alternateUrlsToShowThisAsActive: ['/TrafficHistory', '/MyWorkspace'],
        },
        {
          title: 'Schedule',
          routerLink: '/ScheduleUSer',
          identifier: 'schedules',
          svg: {
            inactive: require('@/assets/icons/timer.svg'),
            active: require('@/assets/icons/timer.svg'),
          },
        },
        {
          title: 'Homepage',
          routerLink: '/Dashboard',
          icon: 'dashboard',
        },
        {
          title: 'Employees',
          routerLink: '/Employees',
          svg: {
            inactive: require('@/assets/icons/white/employees.svg'),
            active: require('@/assets/icons/green/employees.svg'),
          },
        },

        {
          title: 'Roles',
          routerLink: '/Roles',
          svg: {
            inactive: require('@/assets/icons/white/roles.svg'),
            active: require('@/assets/icons/green/roles.svg'),
          },
          hidden: !this.accessPermissions.getRoles,
        },
        {
          title: 'Offices',
          routerLink: '/Offices',
          svg: {
            inactive: require('@/assets/icons/white/offices.svg'),
            active: require('@/assets/icons/green/offices.svg'),
          },
        },
        {
          title: 'Structure',
          routerLink: '/Departments',
          svg: {
            inactive: require('@/assets/icons/white/departments.svg'),
            active: require('@/assets/icons/green/departments.svg'),
          },
          alternateUrlsToShowThisAsActive: ['/TeamsUsers'],
        },
        {
          title: 'Structure',
          routerLink: '/DepartmentStructure',
          svg: {
            inactive: require('@/assets/icons/white/departments.svg'),
            active: require('@/assets/icons/green/departments.svg'),
          },
          alternateUrlsToShowThisAsActive: ['/TeamsUsers'],
        },

        {
          title: 'Signify Docs',
          routerLink: '/signifyDocs',
          svg: {
            inactive: require('@/assets/icons/white/jobs.svg'),
            active: require('@/assets/icons/green/jobs.svg'),
          },
          alternateUrlsToShowThisAsActive: [
            '/SignifyDocs',
            '/signifyTemplates',
          ],
        },
        {
          title: 'Calendar',
          routerLink: '/EventCalendar',
          svg: {
            inactive: require('@/assets/icons/white/calendar.svg'),
            active: require('@/assets/icons/green/calendar.svg'),
          },
        },
        {
          title: 'Birthdays',
          routerLink: '/BirthdaysCalendar',
          svg: {
            inactive: require('@/assets/icons/white/cake_w.svg'),
            active: require('@/assets/icons/green/cake_g.svg'),
          },
        },
        {
          title: 'Jobs',
          routerLink: '/Jobs',
          svg: {
            inactive: require('@/assets/icons/white/jobs.svg'),
            active: require('@/assets/icons/green/jobs.svg'),
          },
          hidden: !this.accessPermissions.getJobs,
        },
        {
          title: 'Schedules',
          routerLink: '/ChooseScheduleType',
          identifier: 'schedules',
          svg: {
            inactive: require('@/assets/icons/timer.svg'),
            active: require('@/assets/icons/timer.svg'),
          },
          // hidden: !this.accessPermissions.getSchedules,
          alternateUrlsToShowThisAsActive: [
            '/FixedSchedules',
            '/FlexibleSchedule',
          ],
        },
        {
          title: 'Vacations',
          routerLink: '/VacationsView',
          svg: {
            inactive: require('@/assets/icons/white/whitevacationicon.svg'),
            active: require('@/assets/icons/green/greenvacationicon.svg'),
          },
          alternateUrlsToShowThisAsActive: [
            '/VacationsView/VacationsAdmin',
            '/VacationsView/VacationTeams',
            '/VacationsView/Vacationterms',
          ],
          // hidden: !this.accessPermissions.getVacations,
        },
        {
          title: 'Vacations',
          routerLink: '/VacationsUser',
          svg: {
            inactive: require('@/assets/icons/white/whitevacationicon.svg'),
            active: require('@/assets/icons/green/greenvacationicon.svg'),
          },
        },
        {
          title: 'In/Out Log',
          routerLink: '/Attendance',
          svg: {
            inactive: require('@/assets/icons/white/inouts.svg'),
            active: require('@/assets/icons/green/inouts.svg'),
          },
        },
        {
          title: 'Attendance',
          routerLink: '/InoutAttendanceReport',
          svg: {
            inactive: require('@/assets/icons/white/inouts.svg'),
            active: require('@/assets/icons/green/inouts.svg'),
          },
          alternateUrlsToShowThisAsActive: ['AttendanceUser'],
        },
        {
          title: 'Permissions',
          routerLink: '/Permissions',
          svg: {
            inactive: require('@/assets/icons/white/permissions.svg'),
            active: require('@/assets/icons/green/permissions.svg'),
          },
        },
        {
          title: 'Traffic',
          routerLink: '/Traffic',
          identifier: 'traffic',
          svg: {
            inactive: require('@/assets/icons/traffic.svg'),
            active: require('@/assets/icons/traffic.svg'),
          },

          alternateUrlsToShowThisAsActive: [
            '/TrafficHistory',
            '/TrafficRequests',
            '/TrafficWorkspace',
            '/TrafficRequests',
          ],
        },
        {
          title: 'Attendance',
          routerLink: '/PerformanceAttendance',
          identifier: 'attendance',
          svg: {
            inactive: require('@/assets/attendance-icon-white.svg'),
            active: require('@/assets/attendance-icon-green.svg'),
          },
          alternateUrlsToShowThisAsActive: [
            '/DailyPerformance',
            '/AttendanceReport',
            '/PersonsPerformance',
            this.previousRoute == '/DailyPerformance'
              ? ['/TrafficHistory']
              : null,
          ],
        },
        {
          title: 'Placement',
          routerLink: '/Placement',
          svg: {
            inactive: require('@/assets/icons/white/placement.svg'),
            active: require('@/assets/icons/green/placementicon.svg'),
          },
          alternateUrlsToShowThisAsActive: [
            '/News',
            '/Offers',
            '/Statements',
            'Polls',
            'AllResponses',
          ],
        },
        {
          title: 'Placement',
          routerLink: '/PlacementUser',
          svg: {
            inactive: require('@/assets/icons/white/placement.svg'),
            active: require('@/assets/icons/green/placementicon.svg'),
          },
        },
        {
          title: 'Teams',
          routerLink: '/Teams',
          identifier: 'teams',
          svg: {
            inactive: require('@/assets/icons/team.svg'),
            active: require('@/assets/icons/team.svg'),
          },
        },
        {
          title: 'Monitoring',
          routerLink: '/Monitoring',
          svg: {
            inactive: require('@/assets/icons/white/monitoringWhiteIcon.svg'),
            active: require('@/assets/icons/green/monitoringGreenIcon.svg'),
          },
        },
        {
          title: 'Payroll',
          parentRoute: '/Payroll',
          routerLink: this.isManager ? '/Payroll/Persons&Factors' : '/Payroll',
          svg: {
            inactive: require('@/assets/icons/white/payrol.svg'),
            active: require('@/assets/icons/green/payrol.svg'),
          },
          alternateUrlsToShowThisAsActive: [
            '/Payroll/PayrollDashboard',
            '/Payroll/SalaryFactors',
            '/Payroll/Persons&Factors',
            '/Payroll/BonusLimits',
            '/Payroll/PayrollPreparation',
            '/Payroll/Companies',
            '/Payroll/Analytics',
            '/Payroll/filesList',
          ],
        },
        {
          title: 'Career',
          routerLink: '/Career',
          svg: {
            inactive: require('@/assets/icons/white/career.svg'),
            active: require('@/assets/icons/green/career.svg'),
          },
          identifier: 'career',
        },
        {
          title: 'Career',
          routerLink: '/CareerAdmin',
          svg: {
            inactive: require('@/assets/icons/white/Vacancies-white.svg'),
            active: require('@/assets/icons/green/Vacancies-green.svg'),
          },
          alternateUrlsToShowThisAsActive: ['/ApplicantTracking', '/FollowUp'],
          identifier: 'hr',
        },
        {
          title: 'Vending',
          routerLink:
            this.$store.getters.hasRole('Member') ||
            this.$store.getters.hasRole('Manager') ||
            this.$store.state.showTimeLine
              ? '/Transactions'
              : '/VendingPackages',
          svg: {
            inactive: require('@/assets/icons/white/vending.svg'),
            active: require('@/assets/icons/green/vending.svg'),
          },
          alternateUrlsToShowThisAsActive: [
            '/Packages',
            '/Transactions',
            '/Users',
          ],
          identifier: 'vending',
        },
        {
          title: 'Grades',
          routerLink: this.setGradingMainRoute(),
          svg: {
            inactive: require('@/assets/icons/white/grading.svg'),
            active: require('@/assets/icons/green/grading-green.svg'),
          },
          alternateUrlsToShowThisAsActive: [
            '/Grades/TeamRatingOverall',
            '/Grades/Criterias',
            '/Grades/PersonRatingOverall',
            '/Grades/TeamOverallChart',
            '/Grades/CareerMapping',
          ],
          identifier: 'grading',
        },
      ]
      //mark one sidebar element as active
      return allPages.map((el) => {
        if (
          el.routerLink == this.activeRoute ||
          (el.alternateUrlsToShowThisAsActive &&
            el.alternateUrlsToShowThisAsActive.some((url) =>
              this.activeRoute.includes(url),
            ))
        ) {
          el.active = true
        } else el.active = false
        return el
      })
    },
    superAdminPages() {
      const pages = [
        '/Traffic',
        '/PerformanceAttendance',
        '/ChooseScheduleType',
        '/Employees',
        '/Attendance',
        '/VacationsView',
        '/EventCalendar',
        '/CareerAdmin',
        '/Grades/GradeNodes',
        '/Grades/CareerMapping',
        '/Placement',
        '/VendingPackages',
        '/Transactions',
        '/Departments',
        '/signifyDocs',
        '/Jobs',
        '/Roles',
        '/Permissions',
        '/Offices',
        '/Teams',
        '/Monitoring',
        '/Payroll',
        '/BirthdaysCalendar',
      ]
      return this.getPageObjectsFromStringArray(pages)
    },
    adminPages() {
      const pages = [
        '/Employees',
        '/PerformanceAttendance',
        '/Traffic',
        '/ChooseScheduleType',
        '/Attendance',
        '/VacationsView',
        '/EventCalendar',
        '/CareerAdmin',
        '/Grades/GradeNodes',
        '/signifyDocs',
        '/Grades/CareerMapping',
        '/Placement',
        '/VendingPackages',
        '/Transactions',
        '/Departments',
        '/Jobs',
        '/Roles',
        '/Permissions',
        '/Teams',
        '/Offices',
        '/Monitoring',
        '/BirthdaysCalendar',
      ]
      return this.getPageObjectsFromStringArray(pages)
    },
    memberPages() {
      const pages = [
        '/Dashboard',
        '/MyTraffic',
        '/ScheduleUSer',
        '/InoutAttendanceReport',
        '/VacationsUser',
        this.isTbilisiOffice ? '/EventCalendar' : null,

        '/Career',
        // this.isTbilisiOffice ? '/Grades/CareerMapping' : null,
        this.isTbilisiOffice ? '/VendingPackages' : null,
        // this.isTbilisiOffice ? '/Transactions' : null,
        '/DepartmentStructure',
      ]
      return this.getPageObjectsFromStringArray(pages)
    },
    hrPages() {
      const pages = [
        '/Employees',
        '/PerformanceAttendance',
        '/ChooseScheduleType',
        '/Attendance',
        '/VacationsView',
        '/EventCalendar',
        '/CareerAdmin',
        '/Grades/GradeNodes',
        '/Grades/CareerMapping',
        '/Placement',
        '/VendingPackages',
        '/Transactions',
        '/Departments',
        '/Jobs',
        '/Permissions',
        '/Traffic',
        '/Teams',
        '/Monitoring',
        '/Offices',
        '/signifyDocs',
        '/Payroll',
        '/BirthdaysCalendar',
      ]
      return this.getPageObjectsFromStringArray(pages)
    },
    managerPages() {
      const pages = [
        !this.isTbilisiOffice ? '/Employees' : null,
        '/PerformanceAttendance',
        '/Traffic',
        '/ChooseScheduleType',
        '/Attendance',
        '/VacationsView',
        this.isTbilisiOffice ? '/EventCalendar' : null,
        '/CareerAdmin',
        this.isTbilisiOffice ? '/Grades/GradeNodes' : null,
        this.isTbilisiOffice ? '/Grades/CareerMapping' : null,
        '/Placement',
        this.isTbilisiOffice ? '/VendingPackages' : null,
        '/DepartmentStructure',
        this.isTbilisiOffice ? '/Payroll' : null,
      ]
      return this.getPageObjectsFromStringArray(pages)
    },

    lawyerPages() {
      const pages = ['/Employees', '/Placement', '/signifyDocs']
      return this.getPageObjectsFromStringArray(pages)
    },
    cfoPages() {
      const pages = [
        '/Dashboard',
        '/MyTraffic',
        '/ScheduleUSer',
        '/InoutAttendanceReport',
        '/VacationsUser',
        '/EventCalendar',
        '/Career',
        '/Grades/CareerMapping',
        '/VendingPackages',
        '/Transactions',
        '/DepartmentStructure',
        '/Payroll',
        '/Employees',
      ]
      return this.getPageObjectsFromStringArray(pages)
    },

    oneAdminPages() {
      const pages = ['/EventCalendar']
      return this.getPageObjectsFromStringArray(pages)
    },

    pages() {
      if (this.$store.state.showTimeLine) return this.memberPages
      else if (this.$store.getters.hasRole('Super Admin'))
        return this.superAdminPages
      else if (
        this.isAdmin ||
        this.$store.getters.hasRole('Super Admin 4 Testing')
      )
        return this.adminPages
      else if (this.$store.getters.hasRole('HR')) return this.hrPages
      else if (this.$store.getters.hasRole('Manager')) return this.managerPages
      else if (this.$store.getters.hasRole('Lawyer')) return this.lawyerPages
      else if (this.$store.getters.hasRole('CFO')) return this.cfoPages
      else return this.memberPages
    },
    logoSource() {
      return !this.$store.state.showTimeLine
        ? require('@/assets/upGamingMainLogo.png')
        : require('@/assets/UpGamingLogo.png')
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    isTbilisiOffice() {
      return this.$store.state.isProduction
        ? this.$store.state.profile.OfficeID === 3
        : this.$store.state.profile.OfficeID === 38
    },
  },
  methods: {
    setGradingMainRoute() {
      let role = (arg) => this.$store.getters.hasRole(arg)
      let routerSrc = ''
      if (role('Member') || this.$store.state.showTimeLine) {
        routerSrc = '/Grades/CareerMapping'
      } else {
        routerSrc = '/Grades/GradeNodes'
      }
      return routerSrc
    },
    getPageObjectsFromStringArray(arr) {
      var resultPages = []
      arr.forEach((page) => {
        let found = this.allPages.find((p) => p.routerLink == page)
        if (!found) return
        resultPages.push(found)
      })
      return resultPages
    },
    changePageLayout() {
      if (this.$store.getters.hasRole('Member')) return
      if (this.$store.state.showTimeLine == false) {
        this.$store.state.showTimeLine = true
        this.$router.push('/Dashboard')
      } else this.$store.state.showTimeLine = false
    },
  },
}
</script>

<style lang="scss" scoped>
#mytraffic {
  filter: brightness(23.5);
  transform: scale(0.6);
}

#schedules {
  filter: brightness(23.5);
  transform: scale(0.6);
}

#career {
  transform: scale(0.5);
}
#grading {
  transform: scale(0.7);
}
#vending {
  transform: scale(0.46);
}

#hr {
  transform: scale(0.6);
}
#attendance {
  transform: scale(0.7);
}
.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 96 * $rem;
  height: 100vh;
  transition: width 0.2s ease-out;
  background-color: $blue-primary;
  overflow: auto;

  &.dark {
    background: #21103a;
    transition: all 0.2s;
  }

  &.admin {
    width: 245 * $rem;

    .routes-wrapper .router-item a {
      flex-direction: row;
      justify-content: flex-start;
      height: 45 * $rem;

      &.selected {
        .icon {
          fill: green;
        }
      }

      .page-title {
        font-size: 15 * $rem;
      }

      i {
        margin-left: 30 * $rem;
        margin-right: 15 * $rem;
        font-size: 32 * $rem;
      }

      #teams,
      #traffic,
      #schedules,
      #mytraffic {
        filter: brightness(23.5);
        transform: scale(0.6);
      }

      .icon {
        width: 40 * $rem;
        margin-left: 25 * $rem;
        margin-right: 10 * $rem;
      }
    }

    .logo {
      margin-bottom: 20 * $rem;
      justify-content: center;
      margin-left: 0;
      width: auto;

      img {
        width: 175px;
      }
    }
  }

  .logo {
    width: 100%;
    overflow: hidden;

    height: 50 * $rem;
    margin-top: 16 * $rem;
    margin-bottom: 30 * $rem;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }

  .routes-wrapper {
    // margin-top: 30 * $rem;
    .router-item {
      a {
        width: 100%;
        position: relative;
        height: 4.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        text-decoration: none;
        cursor: pointer;
        transition: padding-left 0.2s ease-out;

        i {
          color: white;
          font-size: 26 * $rem;
        }

        .icon {
          width: 40 * $rem;
        }

        .page-title {
          font-size: 14 * $rem;
          color: white;
          font-weight: 300;
          text-align: center;
        }

        &.active {
          #teams,
          #traffic,
          #schedules,
          #mytraffic {
            filter: invert(95%) sepia(40%) saturate(7460%) hue-rotate(85deg)
              brightness(95%) contrast(97%);
          }

          i,
          .page-title {
            color: $green-primary;
          }

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-color: $green-primary;
            width: 4 * $rem;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
