<template>
  <div class="activity-chart-container" :class="{ bigSize: bigVersion }">
    <div class="total" v-if="activityTotalTime">
      Active Hours {{ getTimeTextFromSeconds(activityTotalTime) }}
    </div>
    <div v-else class="no-data">No Data</div>
    <canvas :class="{ bigSize: bigVersion }" ref="activityChart"></canvas>
    <div class="static-text" v-if="activityTotalTime">
      <div class="apps">
        <div class="dot"></div>
        <span>Apps</span>
      </div>
      <div class="webs">
        <div class="dot"></div>
        <span>Webs</span>
      </div>
    </div>
    <!-- <div class="list">
      <h3 class="list-title" v-show="nonWebActivities.length">Apps</h3>
      <ul>
        <li
          v-for="(activity, index) in nonWebActivities"
          :key="activity.programName"
        >
          <div
            class="color"
            :style="{ 'background-color': nonWebColors[index] || 'black' }"
          ></div>
          <div class="name" :style="{ color: nonWebColors[index] || 'black' }">
            {{ activity.programName }}
            {{ getTimeTextFromSeconds(activity.seconds) }}
          </div>
        </li>
      </ul>
      <h3 class="list-title" v-show="webActivities.length">Web Pages</h3>
      <ul>
        <li v-for="(activity, index) in webActivities" :key="index">
          <div class="color" :style="{ 'background-color': webColor }"></div>
          <div class="name web">
            {{ activity.URL }} {{ getTimeTextFromSeconds(activity.seconds) }}
          </div>
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
import Chart from "chart.js";
import axios from "@/Helpers/AxiosInstance";
import { getDateString } from "@/Helpers/HelperFunctions";
export default {
  props: {
    date: Date,
    bigVersion: Boolean,
  },

  mounted() {
    this.getData().then(this.drawChart);
  },
  data() {
    return {
      activities: [],
      activitiesCache: {},
      chart: null,
      webColor: "#2ee9b4",
      nonWebColors: [
        "#243391",
        "#6662bb",
        "#739def",
        "#9aabb7",
        "#a2c037",
        "#1772e4",
        "#805c27",
      ],
    };
  },
  methods: {
    drawChart() {
      var self = this;
      var computedData = this.getDataForChart();
      var dataIsEmpty = computedData.length == 1 && !computedData[0];
      if (dataIsEmpty) computedData = [];
      var labels = [
        "Web",
        ...this.nonWebActivities.map((el) => el.programName),
      ];
      var ctx = this.$refs.activityChart.getContext("2d");
      if (!this.chart) {
        this.chart = new Chart(ctx, {
          type: "pie",
          data: {
            datasets: [
              {
                data: computedData,
                backgroundColor: [self.webColor, ...self.nonWebColors],
              },
            ],
            labels,
          },
          options: {
            elements: {
              arc: {
                borderWidth: 0,
              },
            },
            tooltips: {
              // enabled: false
              titleFontSize: 16,
              bodyFontSize: this.bigVersion ? 18 : 13,
              callbacks: {
                label: (tooltipItem, data) => {
                  let label = data.labels[tooltipItem.index] || "";

                  if (label) {
                    label += ": ";
                  }

                  label += self.getTimeTextFromSeconds(
                    data.datasets[0].data[tooltipItem.index]
                  );
                  return label;
                },
              },
            },
            hover: {
              mode: null,
            },
            legend: {
              display: false,
            },
            // showTooltips: false,
          },
        });
      } else {
        this.chart.data.datasets[0].data = computedData;
        if (dataIsEmpty) this.chart.data.labels = [];
        else this.chart.data.labels = labels;
        this.chart.update();
      }
    },
    getDataForChart() {
      return [
        this.webTotalTime,
        ...this.nonWebActivities.map((el) => el.seconds),
      ];
    },
    getTimeTextFromSeconds(seconds) {
      if (!seconds) return;
      var minutes = parseInt(seconds / 60);
      var hr = Math.floor(minutes / 60);
      var mm = minutes % 60;
      if (mm == 0) return `${hr}hr`;
      if (hr == 0) return `${mm}m`;
      return `${hr}hr ${mm}m`;
    },
    getData() {
      var cached = this.activitiesCache[getDateString(this.date, "yyyy-MM-dd")];
      if (cached) {
        this.activities = cached;
        return Promise.resolve();
      }
      var self = this;
      return axios
        .get("attendance/GetSoftMonitoringRecords", {
          params: {
            Person: this.$store.state.profile.ID,
            From: getDateString(
              this.date.setHours(0, 0, 0, 0),
              "yyyy-MM-dd HH:mm"
            ),
            To: getDateString(
              this.date.setHours(23, 59, 59, 0),
              "yyyy-MM-dd HH:mm"
            ),
          },
        })
        .then((resp) => {
          this.activities = resp.data.Value.map((el) => ({
            programName: el.ProgramName,
            seconds: el.TimeSpent - el.IdleTime,
            URL: el.URL ? self.extractHostname(el.URL) : "",
          }));
          this.activitiesCache[
            getDateString(this.date, "yyyy-MM-dd")
          ] = this.activities;
        });
    },
    extractHostname(url) {
      var hostname;
      //find & remove protocol (http, ftp, etc.) and get hostname

      if (url.indexOf("//") > -1) {
        hostname = url.split("/")[2];
      } else {
        hostname = url.split("/")[0];
      }

      //find & remove port number
      hostname = hostname.split(":")[0];
      //find & remove "?"
      hostname = hostname.split("?")[0];

      return hostname;
    },
  },
  computed: {
    webTotalTime() {
      return this.webActivities.reduce((total, el) => total + el.seconds, 0);
    },
    activityTotalTime() {
      return this.activities.reduce((sum, el) => (sum += el.seconds), 0);
    },
    nonWebActivities() {
      var sorted = this.activities
        .filter((el) => !el.URL)
        .sort((a, b) => b.seconds - a.seconds);
      if (!sorted.length) return [];
      return [
        ...sorted.slice(0, 5),
        {
          programName: "Other",
          seconds: sorted.slice(5).reduce((sum, el) => sum + el.seconds, 0),
        },
      ];
    },
    webActivities() {
      var sorted = this.activities
        .filter((el) => el.URL)
        .sort((a, b) => b.seconds - a.seconds);
      if (!sorted.length) return [];
      return [
        ...sorted.slice(0, 5),
        {
          programName: "Other",
          seconds: sorted.slice(5).reduce((sum, el) => sum + el.seconds, 0),
          URL: "Other",
        },
      ];
    },
  },
  watch: {
    date(d) {
      this.getData().then(this.drawChart);
    },
  },
};
</script>

<style scoped lang="scss">
.activity-chart-container.bigSize {
  width: 40.875rem;
  height: 25rem;
}
.activity-chart-container canvas.bigSize {
  top: 6rem;
  transform: scale(0.75);
}
.activity-chart-container {
  overflow: hidden;
  width: 300 * $rem;
  height: 360 * $rem;
  background-color: white;
  border: $rem solid #dedff4;
  border-radius: 6 * $rem;
  box-shadow: 0.375rem 0.375rem 0.625rem rgba(40, 52, 147, 0.08);
  margin-top: -1.6rem;
  position: relative;

  canvas {
    position: relative;
    // transform: scale(0.8);
    // left: -90 * $rem;
    top: 140 * $rem;
    z-index: 2;
  }

  .total {
    position: absolute;
    font-weight: normal;
    font-size: 16 * $rem;
    font-weight: 300;
    top: 100px;
    left: 50%;
    white-space: nowrap;
    transform: translate(-50%);
    color: $blue-primary;
  }
  .list {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 235 * $rem;

    h3 {
      margin-top: 20 * $rem;
      font-size: 16 * $rem;
      font-weight: 300;

      &.list-title {
        color: #283593;
      }
    }

    ul {
      list-style: none;

      li {
        font-size: 12 * $rem;
        font-weight: 400;
        margin-top: 10 * $rem;
        display: flex;
        align-items: center;

        .color {
          width: 8.5 * $rem;
          height: 8.5 * $rem;
          border-radius: 50%;
          margin-right: 11 * $rem;
        }

        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.web {
            color: $blue-primary;
          }
        }
      }
    }
  }

  .no-data {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background-color: #fff;
    font-size: 50 * $rem;
    font-weight: 400;
    color: rgba($blue-primary, 0.5);
  }
}
.static-text {
  position: absolute;
  bottom: 15px;
  left: 40px;

  font-weight: 400;
  .apps {
    display: flex;
    align-items: center;
    .dot {
      width: 8px;
      height: 8px;
      background-color: #3f46bf;
      border-radius: 100%;
    }
    span {
      color: $blue-primary;
      font-size: 14px;
      margin-left: 5px;
      font-weight: 300;
    }
  }
  .webs {
    display: flex;
    align-items: center;
    .dot {
      width: 8px;
      height: 8px;
      background-color: #4aedc4;
      border-radius: 100%;
    }
    span {
      color: $blue-primary;
      font-size: 14px;
      margin-left: 5px;
      font-weight: 300;
    }
  }
}
</style>
