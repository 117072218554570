<template>
  <div class="background">
    <div class="loading-wrapper">
      <div class="dashboard" v-if="!loading">
        <div class="slider">
          <div
            class="dashboard-content"
            :class="{
              noBanner: !noAnyNews && !hasBanner,
              noNewsStyle: noAnyNews,
              onlyOffer: haveOnlyOffer,
              onlyOfferWithBanner: haveOnlyOfferAndBanner,
            }"
          >
            <swiper
              v-if="priorityNews.length > 0"
              class="main-news-content"
              ref="swiperOne"
              :options="swiperOneOptions"
            >
              <swiper-slide
                v-for="news in priorityNews"
                :key="news.index"
                :style="[
                  {
                    backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${
                      news.Offer || news.Poll || news.News
                    }&PlacementType=${news.PlacementType})`,
                  },
                ]"
                class="news"
              >
                <div class="forevent" @click="getNewsDetails(news)">
                  <div class="gradient-div"></div>
                  <div class="info-content">
                    <div class="title" v-html="news.Title"></div>
                    <div class="desc" v-html="news.Description"></div>
                  </div>
                </div>
              </swiper-slide>
              <div
                v-if="priorityNews.length > 1"
                class="swiper-button-next"
                slot="button-next"
                @click="next(true)"
              ></div>
              <div
                v-if="priorityNews.length > 1"
                class="swiper-button-prev"
                slot="button-prev"
                @click="prev(true)"
              ></div>
            </swiper>
            <!-- <div class="news"></div> -->

            <news-and-polls
              ref="childcomp"
              v-if="!noAnyNews"
              :noBanner="hasBanner"
              :news="news"
              :polls="polls"
              :onlyOffer="haveOnlyOffer"
              :haveOnlyOfferAndBanner="haveOnlyOfferAndBanner"
            />

            <div
              class="wrapper"
              :class="{ alternativeStyle: noAnyNews == true }"
            >
              <user-attendance :bigVersion="noAnyNews" />
              <user-activity :bigVersion="noAnyNews" />
            </div>
            <section class="offers-section" v-if="offers.length">
              <div class="offer-header">
                <span>Offers</span>
              </div>
              <div class="offer-blocks-content">
                <div
                  class="single-offer"
                  v-for="offer in offersByTime"
                  :key="offer.Offer"
                >
                  <div
                    class="offer-wrapper"
                    @click="getSingleOffer(offer.Offer)"
                  >
                    <div
                      class="offer-banner"
                      :style="{
                        backgroundImage: `url(https://intranet.upgaming.com/api/News/GetPlacementBanner?PlacementID=${offer.Offer}&PlacementType=${offer.PlacementType})`,
                      }"
                    >
                      <span
                        v-if="offer.BannerTitle"
                        class="banner-title"
                        v-html="offer.BannerTitle"
                      ></span>
                    </div>

                    <div class="offer-info">
                      <p class="offer-title">{{ offer.Title }}</p>
                      <div class="offer-desc">
                        {{ htmlToText(offer.Description) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div v-else class="holder">
        <!-- <div class="preloader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div> -->
        <custom-loader :alternativeSize="true"/>
      </div>
    </div>
    <div class="offer-popup" @click.stop="downloadAllVisible = false">
      <custom-popup
        :visible="offerDetailVisible"
        @close="offerDetailVisible = false"
        v-if="offerDetails.offer"
      >
        <div class="banner-desc-content">
          <div
            class="banner-content"
            :style="{
              backgroundImage: `url(data:image/jpeg;base64,${offerDetails.offer.Banner})`,
            }"
          ></div>
          <div class="description-content">
            <p class="title" v-html="offerDetails.offer.Title"></p>
            <div class="desc-text">
              <span v-html="offerDetails.offer.Description"></span>
            </div>
          </div>
        </div>
        <div class="attachment-content">
          <div class="files">
            <div class="title">
              Attachments ({{ offerDetails.offer.Attachments.length }})
              <div
                class="dots-content"
                @click.stop="downloadAllVisible = !downloadAllVisible"
                v-if="offerDetails.offer.Attachments.length > 1"
              >
                <div></div>
                <div></div>
                <div></div>
                <div
                  class="download-button"
                  @click.stop="downloadAllHandler"
                  v-if="downloadAllVisible"
                >
                  <span>Download all</span>
                </div>
              </div>
            </div>
            <div class="files-content">
              <a
                v-for="(att, index) in offerDetails.offer.Attachments"
                @click="attClickHandler(att)"
                :key="index"
                target="_blank"
              >
                <file-block :uploadedFile="att" />
              </a>
            </div>
          </div>

          <div class="url-content">
            <span v-if="offerDetails.offer.URL"
              >Visit web page:
              <a :href="offerDetails.offer.URL" target="_blank">{{
                offerDetails.offer.URL
              }}</a></span
            >
          </div>
        </div>

        <div class="options-content">
          <p>Options</p>
          <div class="options">
            <swiper ref="swiperTwo" :options="swiperOptionsTwo">
              <swiper-slide
                v-for="(opt, index) in offerDetails.offerOpt"
                :key="opt.OfferOptionID"
                class="single-opt"
                :class="{ clickedOptStyle: opt.active }"
              >
                <div class="for-event" @click="optClicked(opt)">
                  <div
                    class="opt-info-wrapper"
                    :title="
                      opt.OfferOptionDescription.length > 25
                        ? opt.OfferOptionDescription
                        : null
                    "
                  >
                    <div class="left-side">
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div class="right-side">
                      <span>{{ opt.OfferOptionDescription }}</span>
                      <span v-if="opt.FoundedByCompany != 0">{{
                        opt.FoundedByCompany + " " + opt.CurrencyCode
                      }}</span>
                      <span v-if="opt.FoundedByCompany != 0"
                        >Funded by company</span
                      >
                      <span v-if="opt.EmployeeShare != 0">{{
                        opt.EmployeeShare + " " + opt.CurrencyCode
                      }}</span>
                      <span v-if="opt.EmployeeShare != 0">My share</span>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <!-- <hooper-navigation
                v-if="offerDetails.offerOpt.length > 5"
                slot="hooper-addons"
              >
              </hooper-navigation> -->
            </swiper>
            <div
              v-if="offerDetails.offerOpt.length > 5"
              class="swiper-button-next"
              slot="button-next"
              @click="next()"
            ></div>
            <div
              v-if="offerDetails.offerOpt.length > 5"
              class="swiper-button-prev"
              slot="button-prev"
              @click="prev()"
            ></div>
          </div>
        </div>
        <div class="comment-content">
          <textarea
            placeholder="Type your comment"
            cols="30"
            rows="10"
            v-model="offerComment"
          ></textarea>
        </div>
        <div class="button-content">
          <button @click="sendResponseOnOffer">Send Response</button>
        </div>
      </custom-popup>
    </div>
  </div>
</template>

<script>
// import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
// import SliderIcon from "@/components/Dashboard/SliderIcon";
import UserAttendance from "@/components/Dashboard/Attendance";
import UserActivity from "@/components/Dashboard/Activity";
// import DashboardSummary from "@/components/Dashboard/DashboardSummary";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";
import NewsAndPolls from "@/components/PlacementUser/NewsAndPolls.vue";
import FileBlock from "@/components/Profile/FileBlock";

// import WeekSchedule from "@/components/WeekSchedule/WeekSchedule";
// import FlexibleSchedule from "@/components/FlexibleSchedule/FlexibleSchedule";
import axios from "@/Helpers/AxiosInstance";
import UserTraffic from "@/components/Traffic/UserTraffic/UserTraffic";
import eventBus from "@/Helpers/EventBus";
import CustomLoader from "@/components/ReusableComponents/Loader/CustomLoader.vue"
export default {
  async created() {   
    this.$store.state.showTimeLine = true;

    this.loading = true;
    await this.getOffers();

    await this.getNews();
    await this.getPolls();
    this.getPersonSchedule();
    this.loading = false;
  },
  components: {
    // UserTraffic,
    // SliderIcon
    UserAttendance,
    UserActivity,
    NewsAndPolls,
    CustomPopup,
    FileBlock,
    CustomLoader
    // CustomSnackbar,
    // DashboardSummary,
    // WeekSchedule,
    // FlexibleSchedule,
  },
  data() {
    return {
      scheduleID: "",
      scheduleType: "",
      slidePosition: 1,
      choosenOfferOpt: "",
      totalWorkingHours: 0,
      offer: {},
      offerComment: "",
      downloadAllVisible: false,
      offerDetailVisible: false,
      loading: false,
      snackbarText: "",
      snackbarVisible: false,
      snackbarPurpose: "",
      congratSText: "",
      priorityNews: [],

      news: [],
      polls: [],
      offerDetails: {},

      swiperThreeOptions: {
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperOptionsTwo: {
        spaceBetween: 1,
        slidesPerView: 5,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOneOptions: {
        slidesPerView: 1,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      offers: [],
    };
  },

  methods: {
    getNewsDetails(el) {
      if (el.PlacementType == "Offer") {
        this.getSingleOffer(el.Offer);
      } else {
        this.$refs.childcomp.getSinglePollOrNews(el);
      }
    },
    getAvatarSrc(id) {
      return this.$store.state.getAvatar + id;
    },

    async getOffers() {
      const resp = await axios.get("/News/GetOffers");
      this.offers = resp.data.Value;
      this.offers.forEach((el) => {
        el.PlacementType = "Offer";
        if (el.IsPriority) {
          this.priorityNews.push(el);
        }
      });
    },
    async getNews() {
      const resp = await axios.get("News/GetNews");
      this.news = resp.data.Value.News;
      this.news.forEach((el) => {
        this.$set(el, "isNews", true);
        this.$set(el, "audienceVisible", false);
        el.PlacementType = "News";
        if (el.IsPriority) {
          this.priorityNews.push(el);
        }
      });
    },
    async getPolls() {
      const resp = await axios.get("News/GetPolls");
      this.polls = resp.data.Value.Polls;
      this.polls.forEach((el) => {
        this.$set(el, "isPoll", true);
        this.$set(el, "audienceVisible", false);
        el.PlacementType = "Poll";
        if (el.IsPriority) {
          this.priorityNews.push(el);
        }
      });
    },
    downloadAllHandler() {
      let files = [];
      this.offerDetails.offer.Attachments.forEach((el) => {
        files.push({
          FileID: el.AttachmentID,
        });
      });
      axios
        .post("files/GetFiles", {
          Files: files,
        })
        .then((resp) => {
          resp.data.Value.forEach((el) => {
            var a = document.createElement("a");
            a.href = `data:${el.FileType};base64,` + el.File;
            a.download = el.FileName;
            a.click();
          });
        });
    },
    attClickHandler(el) {
      axios
        .post("files/GetFiles", {
          Files: [
            {
              FileID: el.AttachmentID,
            },
          ],
        })

        .then((resp) => {
          var a = document.createElement("a");
          a.href =
            `data:${el.AttachmentType};base64,` + resp.data.Value[0].File;
          a.download = el.name;
          a.click();
        });
    },
    prev(swiperOne) {
      if (swiperOne) {
        this.$refs.swiperOne.$swiper.slidePrev();
      } else {
        this.$refs.swiperTwo.$swiper.slidePrev();
      }
    },
    next(swiperOne) {
      if (swiperOne) {
        this.$refs.swiperOne.$swiper.slideNext();
      } else {
        this.$refs.swiperTwo.$swiper.slideNext();
      }
    },

    optClicked(el) {
      this.choosenOfferOpt = el;
      el.active = !el.active;
    },
    getSingleOffer(id) {
      this.offerComment = "";
      axios.get(`News/GetOfferDetails?Offer=${id}`).then((resp) => {
        this.$set(this.offerDetails, "offer", resp.data.Value.Offer);
        this.$set(this.offerDetails, "offerOpt", resp.data.Value.OfferOptions);
        this.$set(
          this.offerDetails,
          "restrictions",
          resp.data.Value.Restrictions
        );

        this.offerDetailVisible = true;

        this.offerDetails.offerOpt.forEach((el) => {
          this.$set(el, "active", false);
        });
      });
    },
    sendResponseOnOffer() {
      axios
        .post("News/MakeChoiceOnOffer", {
          OfferOption: this.choosenOfferOpt.OfferOptionID,
          Person: this.loggedUser,
          Comment: this.offerComment,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.choosenOfferOpt = {};
            this.offerComment = "";
            this.snackbarVisible = true;
            this.snackbarPurpose = "success";
            this.snackbarText = "Response was successfully sent";
            this.offerDetailVisible = false;
          }
        });
    },

    getPersonSchedule() {
      axios
        .get("Schedules/GetPersonSchedules", {
          params: { Person: this.$store.state.profile.ID },
        })
        .then((resp) => {
          if (resp.data.ErrorMsg || !resp.data.Value || !resp.data.Value.length)
            return;
          this.scheduleID = resp.data.Value[0].Schedule;
          this.scheduleType = resp.data.Value[0].ScheduleType;
        });
    },
    htmlToText(html) {
      const e = document.createElement("p");
      e.innerHTML = html;

      return e.textContent;
    },
    getDateString(date, format = "dd MMM, yyyy") {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      if (date === "Invalid Date") return;

      const MONTHS = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      var days = ("0" + date.getDate()).slice(-2),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        year = date.getFullYear(),
        hours = ("0" + date.getHours()).slice(-2),
        minutes = ("0" + date.getMinutes()).slice(-2),
        seconds = ("0" + date.getSeconds()).slice(-2);

      return format
        .replace("dd", days)
        .replace("MMM", MONTHS[parseInt(month) - 1])
        .replace("MM", month)
        .replace("yyyy", year)
        .replace("yy", String(year).slice(-2))
        .replace("HH", hours)
        .replace("mm", minutes)
        .replace("ss", seconds)
        .replace("ww", WEEKDAYS[date.getDay()]);
    },
  },
  computed: {
    hasBanner() {
      if (this.priorityNews.length) return true;
      return false;
    },
    isTimeLineShown() {
      return this.$store.state.showTimeLine;
    },
    noAnyNews() {
      if (this.offers.length || this.polls.length || this.news.length)
        return false;
      return true;
    },
    haveOnlyOffer() {
      if (
        this.offers.length > 0 &&
        this.polls.length < 1 &&
        this.news.length < 1 &&
        this.priorityNews.length < 1
      )
        return true;
      return false;
    },
    haveOnlyOfferAndBanner() {
      if (
        this.offers.length > 0 &&
        this.polls.length < 1 &&
        this.news.length < 1 &&
        this.priorityNews.length > 0
      )
        return true;
      return false;
    },
    slidingStyles() {
      return {
        transform: `translateX(${-100 * this.slidePosition}%)`,
      };
    },
    offersByTime() {
      return this.offers.slice().sort(function (a, b) {
        return b.PublishDate - a.PublishDate;
      });
    },
  },
  watch: {
    choosenOfferOpt(to, from) {
      if (Object.entries(from).length > 0) {
        from.active = false;
      }
    },

    slidePosition(val) {
      var title = "";
      switch (val) {
        case 0:
          title = "Schedule";
          break;
        case 1:
          title = "Dashboard";
          break;
        case 2:
          title = "Traffic";
          break;
      }
      eventBus.$emit("change-title", title);
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard .slider .dashboard-content .wrapper.alternativeStyle {
  justify-content: space-around;
}
.loading-wrapper {
  height: 100%;
}
$count: 10;
$time: 2; //in seconds
$size: 400;
$color: rgba($blue-primary, 0.1);
.holder {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}

.preloader {
  /* size */
  width: $size + px;
  height: $size + px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: rotatePreloader $time + s infinite ease-in;
}
@keyframes rotatePreloader {
  0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(-360deg);
  }
}
.preloader div {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.preloader div:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0%;
  width: 10%;
  height: 10%;
  background-color: $color;
  transform: translateX(-50%);
  border-radius: 50%;
}

@for $i from 1 through $count {
  .preloader div:nth-child(#{$i}) {
    transform: rotateZ(((360 / $count) * ($i - 1)) + deg);
    animation: rotateCircle + $i $time + s infinite linear;
    z-index: $count - $i;
  }
  @keyframes rotateCircle#{$i} {
    #{percentage(((50 / $count) * ($i - 1)) / 100)} {
      opacity: 0;
    }
    #{percentage((((50 / $count) + 0.0001) * ($i - 1)) / 100)} {
      opacity: 1;
      transform: rotateZ((0 - ((360 / $count) * ($i - 2))) + deg);
    }
    #{percentage((((50 / $count) * ($i - 0)) + 2) / 100)} {
      transform: rotateZ((0 - ((360 / $count) * ($i - 1))) + deg);
    }
    #{percentage(((50 + ((50 / $count) * ($i - 0))) + 2) / 100)} {
      transform: rotateZ((0 - ((360 / $count) * ($i - 1))) + deg);
    }
    100% {
      transform: rotateZ((0 - ((360 / $count) * ($count - 1))) + deg);
      opacity: 1;
    }
  }
}
@keyframes loader {
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
.dashboard .slider .dashboard-content.onlyOffer {
  grid-template-areas:
    "news activity"
    "news activity"
    "news offers";
}
.dashboard .slider .dashboard-content.onlyOfferWithBanner {
  grid-template-areas:
    "banner activity"
    "news activity"
    "news offers";
}
.dashboard {
  height: 100%;
  display: flex;

  .slider {
    width: 100%;
    display: flex;
    overflow: hidden;

    .item {
      width: 100%;
      flex-shrink: 0;
      transition: 0.3s ease-out;
      overflow: hidden;
      // display: flex;
      // align-items: center;
      // flex-direction: column;

      &:first-child {
        padding-left: 10 * $rem;
      }

      p.total {
        font-weight: 300;
        color: $blue-primary;
        font-size: 18 * $rem;
        text-align: right;
        margin-left: auto;
        margin-top: 30 * $rem;
        margin-bottom: 30 * $rem;

        span {
          font-weight: 700;
          margin-left: 10 * $rem;
        }
      }

      .flexible-schedule-container {
        width: 100%;
        padding: 20 * $rem 10 * $rem;
        overflow: auto;
      }
    }
    .main-news-content {
      grid-area: banner;
    }
    .news-polls-section {
      grid-area: news;
      margin-top: 0;
      width: 101%;
      ::v-deep .news-and-polls {
        padding-right: 5px;
      }
    }

    .wrapper {
      grid-area: activity;
    }
    .offers-section {
      grid-area: offers;
    }
    .dashboard-content.noNewsStyle {
      grid-template-areas: "activity activity";
    }
    .dashboard-content.noBanner {
      grid-template-areas:
        "news activity"
        "news activity"
        "news offers";
    }
    .dashboard-content {
      // display: flex;
      // flex-direction: column;
      display: grid;
      overflow: auto;
      // grid-template-rows: 250px 1fr;
      grid-gap: 20px 40px;
      grid-template-columns: 1fr 650px;
      grid-template-rows: 250px 83px 1fr;
      grid-template-areas:
        "banner activity"
        "news activity"
        "news offers";

      padding: 60px 40px 0px 40px;
      width: 100%;
      position: relative;
      .main-news-content {
        width: 100%;
        height: 250px;
        .swiper-button-next {
          color: #9696b9;

          &::after {
            font-size: 30px;
          }
        }
        .swiper-button-prev {
          color: #9696b9;
          &::after {
            font-size: 30px;
          }
        }
        .news {
          width: 100% !important;
          height: 100%;
          background-color: $blue-primary;
          border-radius: 6px;
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          .info-content {
            bottom: 30px;
            left: 36px;
            width: 100%;
            position: absolute;

            .title {
              color: white;
              text-transform: capitalize;
              font-weight: 900;
              margin-bottom: 10px;
              font-size: 32px;
            }
            .desc {
              color: white;
              font-size: 16px;
              max-width: 430px;
              word-break: break-word;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
      }

      .wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .activity {
          // margin-left: 60 * $rem;
        }
      }
    }
  }
}

.offer-wrapper {
  height: 100%;
  display: flex;
}
.offers-section {
  width: 102%;
  height: calc(100%);
  overflow: hidden;
  .offer-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    span {
      font-weight: 500;
      font-size: 20px;
      color: $blue-primary;
      font-weight: 300;
    }
  }

  .offer-blocks-content {
    height: calc(100% - 55px);
    overflow: auto;
    .single-offer {
      height: 190px;
      width: 100%;
      overflow: hidden;
      border-radius: 6px;
      margin-bottom: 15px;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }
    }

    .banner-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(#ffffff, 0.5);
      width: 250px;
      text-align: center;
      word-break: break-word;
      white-space: pre-wrap;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 40px;
      border-radius: 3px;
      color: #ffffff;
      font-weight: 500;
    }
    .offer-banner {
      height: 100%;
      width: 305px;
      position: relative;

      background: rgba($blue-primary, 0.6);
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .offer-info {
      background-color: #fff;
      height: 100%;
      width: 52%;

      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;

      padding: 10px 30px 10px 30px;

      .offer-title {
        font-size: 20px;
        font-weight: 500;
        color: #283593;
        overflow: hidden;
        white-space: break-spaces;
        word-break: break-word;
        text-overflow: ellipsis;
        margin-bottom: 15px;
        height: 30%;
        opacity: 0.7;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .offer-desc {
        font-size: 14px;
        font-weight: 300;
        color: $blue-primary;
        display: -webkit-box;
        word-break: break-word;
        white-space: break-spaces;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 53%;
      }
    }
  }
}
::v-deep .swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  pointer-events: visible;
  cursor: pointer;
  opacity: 1;
}
.clickedOptStyle {
  // background-color: #283593 !important;
  background: rgba($blue-primary, 0.6) !important;
  span {
    color: #ffffff !important;
  }
}
.offer-popup {
  p {
    font-size: 20px;
    font-weight: 500;
    color: $blue-primary;
  }

  ::v-deep .overlay .popup {
    width: 1342px;
    height: initial;
    left: 46%;
    top: 54%;
    overflow: auto;
    h3.title {
      // color: #292929;
      // font-weight: 500;
      // font-size: 20px;

      display: none;
    }
    .title {
      padding-left: 15px;
    }
  }
  .options-content {
    position: relative;
    margin-top: 15px;
    .hooper-navigation {
      ::v-deep button:nth-child(1) {
        top: 60%;
        left: -3%;
      }
      ::v-deep button:nth-child(2) {
        top: 60%;
        right: -3%;
      }
    }
  }
  .banner-desc-content {
    display: flex;
    height: 410px;
    justify-content: space-between;
    .banner-content {
      height: 100%;
      width: calc(50% - 15px);
      border-radius: 10px;
      margin-bottom: 40px;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .description-content {
      width: calc(50% - 15px);

      .desc-text {
        margin-top: 10px;
        max-height: 365px;
        border-radius: 5px;
        padding-right: 10px;
        padding-top: 10px;
        font-weight: 300;
        color: rgba($blue-primary, 1);
        overflow: scroll;
        margin-bottom: 15px;
        padding-left: 15px;
        font-size: 14px;
        word-break: break-word;
      }
    }
  }
  .comment-content {
    margin-top: 30px;
    textarea {
      height: 75px;
      font-size: 14px;
      width: 100%;
      outline: none;
      border: 1px solid #dcdde6;
      resize: none;
      border-radius: 6px;
      padding: 10px;
      color: $blue-primary;
      &::placeholder {
        color: rgba($blue-primary, 0.8);
      }
    }
  }
  .button-content {
    button {
      width: 125px;
      height: 45px;
      border-radius: 6px;
      color: #ffffff;
      background: rgba($blue-primary, 0.7);
      float: right;
      margin-top: 25px;
      cursor: pointer;
      font-size: 16px;
    }
  }
  .attachment-content {
    margin-top: 10px;
    min-height: 30px;
    display: flex;

    .files {
      width: 50%;

      .files-content {
        width: 100%;
        overflow-x: scroll;
        white-space: nowrap;
        padding-bottom: 10px;
        display: flex;
        &::-webkit-scrollbar-thumb {
          background: rgba($blue-primary, 0.1);
        }
      }
      .title {
        display: flex;
        font-size: 15px;
        color: rgba($blue-primary, 1);
        align-items: center;
        .dots-content {
          display: flex;
          align-items: center;
          position: relative;
          .download-button {
            position: absolute;
            background-color: #ffffff;
            height: 30px;
            width: 150px;
            border-radius: 6px;
            top: 15px;
            left: 0px;
            z-index: 100;
            box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.23);
            opacity: 1;
            display: flex;
            // justify-content: center;
            padding-left: 5px;
            align-items: center;
            color: rgba($blue-primary, 0.8);
          }
          height: 20px;
          width: 30px;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
          margin-left: 10px;
          div {
            height: 4px;
            width: 4px;
            border-radius: 100%;
            background-color: #292929;
            opacity: 0.7;
            margin-left: 3px;
            &:nth-child(1) {
              margin-left: 0;
            }
          }
        }
      }
      ::v-deep .file-block {
        height: 3.1625rem;
        width: 9.5625rem;
        margin-top: 10px;
        margin-right: 5px;
        display: inline-block;
        box-shadow: none;
        cursor: pointer;

        padding: 0;
        .uploaded {
          margin-top: 10px;
          margin-left: 3px;
          .uploaded-logo {
            margin-right: 5px;
          }
        }
        img {
          display: none;
        }
        .top {
          // flex-shrink: 0;

          span {
            font-weight: 300;
            white-space: nowrap;
            max-width: calc(100% - 70 * -0.9375rem);
            font-size: 12px;
          }
          div {
            height: 28px;
            width: 28px;
            font-size: 12px;
          }
        }
        .bottom {
          display: none;
        }
      }
    }
    .url-content {
      margin-left: 18px;
      span {
        display: flex;
        align-items: center;
        color: rgba($blue-primary, 0.8);
        white-space: nowrap;
        font-size: 15px;
      }
      a {
        color: #1db3e9;
        max-width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 5px;
      }
    }
    .download-content {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #292929;
      margin-bottom: 15px;
      span:nth-child(2) {
        color: $blue-primary;
        font-size: 20px;
        margin-left: 5px;
      }
    }
  }

  .options {
    margin-top: 15px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    .swiper-button-prev {
      color: rgba($blue-primary, 0.3);
      left: -30px;
      top: 66%;
      &::after {
        font-size: 20px;
      }

      // height: 20px;
      // width: 20px;
    }
    .swiper-button-next {
      color: rgba($blue-primary, 0.3);
      right: -30px;
      top: 66%;
      // height: 20px;
      // width: 20px;
      &::after {
        font-size: 20px;
      }
    }
    .single-opt {
      // width: 19.9% !important;
      height: 74px;
      background-color: #ededfc;
      display: inline-block;

      border-radius: 5px;
      cursor: pointer;
      .for-event {
        height: 100%;
      }
      .opt-info-wrapper {
        display: flex;
        height: 100%;

        .left-side {
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 10px;
          margin-right: 10px;
          span {
            font-size: 30px;
            font-weight: 500;
            color: #9696b9;
          }
        }
        .right-side {
          display: flex;
          flex-direction: column;
          justify-content: center;
          span {
            font-size: 14px;
            font-weight: 400;
            color: rgba($blue-primary, 1);
            max-width: 205px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
.forevent {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  .gradient-div {
    position: absolute;
    width: 100%;
    height: 146px;
    bottom: 0;
    border-radius: 10px;
    background: linear-gradient(
      rgba(149, 155, 201, 0) 0%,
      rgba(40, 53, 147, 0.8) 100%
    );
  }
}
</style>
