<template>
    <div class="avatar">
        <div
            class="avatar-background"
            :style="{ backgroundImage: `url(${$store.state.getAvatar}${person.id})` }"
        ></div>
        <span>{{ generateAvatar() }}</span>
    </div>
</template>

<script>
export default {
    props: {
        person: Object,
    },
    methods: {
        generateAvatar() {
            if (this.person.firstName) {
                return (
                    this.person.firstName[0].toUpperCase() + this.person.lastName[0].toUpperCase()
                )
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.avatar {
    position: relative;
    background-color: $green-primary;
    width: 100 * $rem;
    height: 100 * $rem;
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $background;
    font-size: 40 * $rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .avatar-background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 200px;
    }
}
</style>
