<template>
  <div class="tree_list">
    <ul>
      <li
        v-for="(item, ind) in data"
        :key="ind"
        :class="[{ selected: item.selected }]"
        @click.stop="item.collapsed = !item.collapsed"
      >
        <div class="option" v-show="item.visible">
          <div
            :class="['collapse_arrow', { collapsed: item.collapsed }]"
            v-if="item.ChildDeps"
          >
            <!-- <img src="@/assets/icons/arrow_down.svg" alt="" /> -->
            <i
              v-if="!item.collapsed && item.DepType !== null"
              class="material-icons"
            >
              keyboard_arrow_down
            </i>
            <i
              v-if="item.collapsed && item.DepType !== null"
              class="material-icons"
            >
              keyboard_arrow_up
            </i>
          </div>

          <div
            class="option_wrapper"
            :style="[
              item.DepType === null
                ? { marginLeft: '10px', fontWeight: '300' }
                : null,
            ]"
          >
            <span class="option_label">
              {{ item.Name ? item.Name : '' }}
            </span>
            <div class="checkbox_wrapper" @click.stop="select_item(item)">
              <CustomCheckbox :checked="item.selected" :value="item.selected" />
            </div>
          </div>
        </div>
        <transition name="collapse">
          <div
            :class="['children', { collapsedd: item.collapsed }]"
            v-show="item.collapsed"
          >
            <structure-list
              v-show="item.ChildDeps"
              :data="item.ChildDeps"
              @change="$emit('change')"
            />
          </div>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script>
import CustomCheckbox from '@/components/ReusableComponents/CustomCheckbox/CustomCheckbox'

export default {
  name: 'StructureList',

  props: {
    data: Array,
  },
  data() {
    return {
      selected_options: [],
      listVisible: false,
    }
  },
  components: {
    CustomCheckbox,
  },

  mounted() {
    this.checkItemSelections()
  },

  methods: {
    checkItemSelections() {
      let recursiveSelection = (arr, state) => {
        arr.forEach((el) => {
          el.selected = state
          if (el.ChildDeps && el.ChildDeps.length > 0)
            recursiveSelection(el.ChildDeps, state)
        })
      }

      this.data.forEach((item) => {
        if (item.selected && item.ChildDeps && item.ChildDeps.length > 0) {
          recursiveSelection(item.ChildDeps, item.selected)
        }
      })
    },
    select_item(item) {
      let loop = (arr, state) => {
        arr.forEach((el) => {
          el.selected = state
          if (el.ChildDeps) loop(el.ChildDeps, state)
        })
      }

      //   if (!item.ChildDeps || item.ChildDeps.length < 1) {
      //     item.selected = !item.selected
      //   }
      if (item.ChildDeps) {
        item.selected = !item.selected
        loop(item.ChildDeps, item.selected)
      }

      this.$emit('change')
      // collect_selected_options()
    },
  },
}
</script>

<style scoped lang="scss">
.checkbox_wrapper {
  margin-left: 5px;
}

.collapse-enter-active,
.collapse-leave-active {
  transition: all 0.3s ease-in-out;
}

.collapse-enter-from,
.collapse-leave-to {
  opacity: 0;
}

body.theme_dark {
  .collapse_arrow > img {
    filter: invert(89%) sepia(100%) saturate(0%) hue-rotate(259deg)
      brightness(107%) contrast(100%);
  }
  .option_label {
    color: #f9fafa !important;
  }
}

.tree_list {
  ul {
    width: 100%;
    .material-icons {
      color: $blue-primary;
    }
    li {
      width: 100%;
      transition: all 0.3s;
      position: relative;
      list-style: none;
      &.selected {
        //background: $ultra_light_purple;
      }

      .option {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 2.5rem;
        cursor: pointer;

        .option_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        .collapse_arrow {
          cursor: pointer;
          img {
            width: 1.5rem;
            user-select: none;
            transition: all 0.3s;
          }

          &.collapsed {
            img {
              transform: rotate(180deg);
            }
          }
        }

        .option_label {
          font-size: 1rem;
          color: $blue-primary;
          // margin-left: 0.1rem;
        }
      }

      .children {
        margin-left: 0.9375rem;
        &.collapsed {
          animation: 0.3s collapse forwards;
          @keyframes collapse {
            from {
              transform: translateY(-0.3125rem);
              opacity: 0;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
</style>
