<template>
  <div class="background">
    <div class="schedules">
      <schedules-header
        @add-click="addClickHandler"
        :schedules="schedules"
        :selectedSchedule="selectedSchedule"
        @change-selected="(id) => (selectedSchedule = id)"
        @delete="deleteSchedule"
        @edit="editClickHandler"
        :totalHr="totalWeeklyHours"
      />
      <custom-popup
        :visible="popupOpened"
        title="Add Schedule"
        @close="popupOpened = false"
      >
        <form class="fields" @submit.prevent="updatePersonShcedule">
          <div class="row">
            <custom-input
              v-model="formFields.name"
              title="Name"
              :isRequired="true"
            />
            <select-persons
              v-if="popupOpened"
              :initialSelectedPersons="formFields.selectedPeople"
              :departments="departments"
              :withoutDeps="true"
              @select="(people) => (formFields.selectedPeople = people)"
            />
          </div>
          <div class="row">
            <custom-select
              v-if="currentPopupPurpose == popupPurposes.ADD"
              v-model="formFields.office"
              title="Office"
              :options="formFields.officeOptions"
              :isRequired="true"
              :clearVisible="true"
            />
          </div>
          <button class="save">Save</button>
        </form>
      </custom-popup>
      <week-schedule
        :scheduleID="selectedSchedule"
        v-if="selectedSchedule"
        @totalHours="(total) => (totalWeeklyHours = total)"
      />
    </div>
  </div>
</template>

<script>
import WeekSchedule from "@/components/WeekSchedule/WeekSchedule";
import SchedulesHeader from "./SchedulesHeader";
import SelectPersons from "./SelectPersons";
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup.vue";
import axios from "@/Helpers/AxiosInstance";
import { getFullName } from "@/Helpers/HelperFunctions";
export default {
  components: {
    SchedulesHeader,
    CustomPopup,
    SelectPersons,
    CustomInput,
    CustomSelect,
    WeekSchedule,
  },
  created() {
    this.getJobPersons();
    this.getOffices();
    // this.getDepartmentJobs();
    this.getFixedPeople();
    this.getSchedules(true);
  },

  data() {
    return {
      selectedSchedule: 0,
      totalWeeklyHours: 0,
      popupOpened: false,
      departmentJobs: [],
      personsWithFlexible: [],
      popupPurposes: {
        ADD: 1,
        EDIT: 2,
      },
      editingScheduleID: 0,
      currentPopupPurpose: 1,
      jobs: [],
      departments: [],
      schedules: [],
      formFields: {
        selectedPeople: [],
        name: "",
        office: "",
        officeOptions: [],
      },
    };
  },
  methods: {
    addClickHandler() {
      this.currentPopupPurpose = this.popupPurposes.ADD;
      this.popupOpened = true;
      this.formFields.name = "";
      this.formFields.office = "";
      this.formFields.selectedPeople = [];
    },
    editClickHandler(scheduleID) {
      this.currentPopupPurpose = this.popupPurposes.EDIT;
      this.popupOpened = true;
      this.editingScheduleID = scheduleID;
      var schedule = this.schedules.find((el) => el.ID == scheduleID);
      this.formFields.name = schedule.Name;
      this.formFields.selectedPeople = schedule.Persons.map(
        (el) => el.PersonID
      );
    },
    getJobPersons() {
      axios.get("jobs/JobPersons").then((resp) => {
        if (resp.data.ErrorMsg) return;
        var jobs = [];
        resp.data.Value.forEach((person) => {
          let jobID = person.JobID;
          let job = jobs.filter((el) => el.id == jobID)[0];
          if (!job) {
            job = {
              id: jobID,
              name: person.JoBName,
              people: [],
            };
            jobs.push(job);
          }
          job.people.push({
            id: person.PersonID,
            name: getFullName(person),
          });
        });
        this.jobs = jobs;
      });
    },
    getDepartmentJobs() {
      axios.get("departments/GetDepartmentPeople").then((resp) => {
        if (resp.data.ErrorMsg) return;
        let departments = [];
        resp.data.Value.forEach((dep) => {
          let depId = dep.DepartmentID;
          let department = departments.filter((el) => el.id == depId)[0];
          if (!department) {
            department = {
              id: depId,
              name: dep.DepartmentName,
              jobs: [],
            };
            departments.push(department);
          }
          let jobid = dep.JobID;
          let job = department.jobs.filter((el) => el.id == jobid)[0];
          if (!job) {
            department.jobs.push({
              id: jobid,
              name: dep.JobName,
              people: [],
            });
          }
          department.jobs.forEach((el) => {
            if (el.id === dep.JobID) {
              el.people.push({
                id: dep.PersonID,
                name: dep.FirstName + " " + dep.LastName,
              });
            }
          });
        });
        let withoutDep = {
          id: 0,
          name: "Jobs without department",
          jobs: [],
        };
        axios.get("departments/GetJobPersonsWithoutDepartment").then((resp) => {
          resp.data.Value.forEach((el) => {
            let jobid = el.JobID;
            let job = withoutDep.jobs.filter((job) => jobid == job.id)[0];
            if (!job) {
              withoutDep.jobs.push({
                id: el.JobID,
                name: el.JobName,
                people: [],
              });
            }
            withoutDep.jobs.forEach((job) => {
              if (job.id === el.JobID) {
                job.people.push({
                  id: el.ID,
                  name: el.FirstName + " " + el.LastName,
                });
              }
            });
          });
        });

        departments.push(withoutDep);

        this.departments = departments;
      });
    },
    getFixedPeople() {
      axios.get("Schedules/GetFixedSchedulesDropDownPersons").then((resp) => {
        resp.data.Value.JobPersons.forEach((el) => {
          el.Persons.forEach((person) => {
            if (person.HasFlexibleScheduleAssigned == true) {
              this.personsWithFlexible.push(person);
            }
          });
        });
        let data = [
          {
            id: 777,
            name: "",
            jobs: resp.data.Value.JobPersons.map((el) => {
              return {
                id: el.JobID,
                name: el.JobName,
                people: el.Persons.map((pers) => {
                  return {
                    name: pers.FirstName + " " + pers.LastName,
                    id: pers.PersonID,
                    isFlexible: pers.HasFlexibleScheduleAssigned,
                  };
                }),
              };
            }),
          },
        ];

        this.departments = data;
      });
    },
    updatePersonShcedule() {
      let arrayToChangeSchedule = [];
      let names = [];
      this.formFields.selectedPeople.forEach((el) => {
        this.personsWithFlexible.forEach((p) => {
          if (p.PersonID == el) {
            arrayToChangeSchedule.push({
              PersonSchedule: p.PersonScheduleID,
              EndDate: Date.now(),
              Delete: null,
            });
            names.push(p.FirstName + " " + p.LastName);
          }
        });
      });

      if (arrayToChangeSchedule.length) {
        this.$confirm(
          `${names.map((el) => {
            return el + ", ";
          })}has already assigned to flexible schedule, are you sure you want to change schedule type?`
        ).then(() => {
          axios
            .post("Schedules/UpdatePersonsSchedules", {
              TVP: arrayToChangeSchedule,
            })
            .then((resp) => {
              this.submitForm();
            });
        });
      } else {
        axios
          .post("Schedules/UpdatePersonsSchedules", {
            TVP: arrayToChangeSchedule,
          })
          .then((resp) => {
            this.submitForm();
          });
      }
    },
    getOffices() {
      axios.get("Company/GetOffices").then(
        (resp) =>
          (this.formFields.officeOptions = resp.data.Value.map((el) => ({
            title: el.Name,
            value: "" + el.ID,
          })))
      );
    },
    getSchedules(firstLoad) {
      axios.get("Schedules/GetSchedules").then((resp) => {
        this.schedules = resp.data.Value;
        if (this.$route.query.scheduleID) {
          this.selectedSchedule = this.$route.query.scheduleID;
        }
        if (
          firstLoad &&
          resp.data.Value.length > 0 &&
          !this.$route.query.scheduleID
        ) {
          this.selectedSchedule = resp.data.Value[0].ID;
        }
      });
    },
    submitForm() {
      if (this.currentPopupPurpose == this.popupPurposes.ADD) {
        let data = {
          Name: this.formFields.name,
          OfficeID: this.formFields.office,
          TVP: this.formFields.selectedPeople.map((id) => ({
            PersonID: id,
          })),
        };
        axios.post("Schedules/CreateScheduleForFixed", data).then((resp) => {
          if (resp.data.ErrorMsg) return;
          this.popupOpened = false;
          this.getSchedules();
          this.selectedSchedule = resp.data.Value[0].ScheduleID;
        });
      } else if (this.currentPopupPurpose == this.popupPurposes.EDIT) {
        let data = {
          TVP: this.formFields.selectedPeople.map((id) => ({
            PersonID: id,
          })),
          Name: this.formFields.name,
          Schedule: this.editingScheduleID,
        };
        axios.post("Schedules/AssignPersonFixedSchedule", data).then((resp) => {
          this.popupOpened = false;
          this.getSchedules();
        });
      }
    },
    deleteSchedule(scheduleID) {
      this.$confirm("Would you like to delete this schedule?").then(() => {
        axios
          .post("Schedules/UpdateSchedule", {
            Schedule: scheduleID,
            Delete: true,
          })
          .then((resp) => {
            if (resp.data.ErrorMsg) return;
            this.getSchedules();
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.schedules {
  padding-left: 20 * $rem;
  padding-top: 20 * $rem;

  .fields {
    .row {
      display: flex;
      margin-top: 30 * $rem;

      .select-tree {
        margin-left: 80 * $rem;
      }
      .select-wrapper {
        width: 12.125rem;
      }
    }

    .save {
      background-color: rgba($blue-primary, 0.5);
      border-radius: 3 * $rem;
      color: white;
      font-weight: 500;
      font-size: 15.5 * $rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 13 * $rem 32 * $rem;
      position: absolute;
      bottom: 35 * $rem;
      right: 55 * $rem;
      letter-spacing: 0.8 * $rem;
      line-height: 15 * $rem;
      cursor: pointer;
    }
  }

  .week-schedule {
    margin-top: 20 * $rem;
    box-shadow: $box-shadow2;
    border: 0.0625rem solid #dedff4;
  }
}

::v-deep .popup{
  position: relative;
}
</style>
