<template>
  <div class="background">
    <news-polls-offers-wrapper
      @ongoing-clicked="onGoing = !onGoing"
      @draft-clicked="draft = !draft"
      @completed-clicked="completed = !completed"
      @edit-item="getSingleOffer"
      @delete-item="deleteClickHandler"
      :draftClicked="draft"
      :onGoingClicked="onGoing"
      :completedClicked="completed"
      @get-data="getData"
      :maxPageCount="maxPageCount"
      @search-value="(value) => (search = value)"
      @clicked="getOfferStatistics"
      @responses-clicked="redirectToResponses"
      type="offers"
      @add-item="addPopupVisible = true"
      :data="filteredByStatus"
    />
    <custom-popup
      :title="isEditMode ? 'Edit Offer' : 'Add Offer'"
      class="popup"
      :visible="addPopupVisible"
      :flexible-size="true"
      @close="
        addPopupVisible = false;
        isEditMode = false;
        bannerUploadPopup = false;
      "
    >
      <div class="custom-selects-wrapper">
        <custom-select
          title="Choose offer category"
          v-model="choosenOfferType"
          :options="offerTypeOptions"
        />
        <select-persons
          v-if="addPopupVisible"
          :initialSelectedPersons="selectedValues.person"
          :placementTitle="true"
          :departments="departmentPeople"
          @select="(people) => (selectedValues.person = people)"
        />
        <date-picker title="Response expiry" v-model="expiryTime" />
        <div class="priority-content" @click="highPriority = !highPriority">
          <span class="material-icons-round"> priority_high </span>
          <span>High Priority</span>
          <span v-if="highPriority" class="material-icons success">check</span>
        </div>
        <div class="preview-button" @click="previewClickHandler">
          <img src="@/assets/preview-icon.svg" />
          <span>Preview</span>
        </div>
      </div>

      <div class="titles-content">
        <div class="hero-title-wrapper">
          <div class="custom-editor">
            <custom-input
              title="Offer title"
              :trigger-change-on-blur="false"
              v-model="offers.heroTitle"
            />
          </div>
          <div class="offer-title-count">
            <span
              :class="[
                'count',
                {
                  'count--passed': offers.heroTitle.length < 50,
                  'count--error': offers.heroTitle.length > 50,
                },
              ]"
            >
              {{ offers.heroTitle.length }}
            </span>
            <span> / 50 </span>
          </div>
        </div>
        <div class="banner-title-wrapper">
          <custom-editor-inline
            :trigger-change-on-blur="false"
            class="add-news__title-input"
            title="Banner title"
            v-model="offers.bannerTitle"
          />
          <div class="offer-title-count">
            <span
              :class="[
                'count',
                {
                  'count--passed': offers.bannerTitle.length < 50,
                  'count--error': offers.bannerTitle.length > 50,
                },
              ]"
            >
              {{ htmlToText(offers.bannerTitle).length }}
            </span>
            <span> / 50 </span>
          </div>
        </div>
        <div class="add-banner-button" @click="bannerUploadPopup = true">
          <img
            :src="require('@/assets/icons/icon_add_image.svg')"
            alt="add-image"
          />
          <span>Upload banner</span>
          <span v-if="offers.file.base64" class="material-icons success"
            >check</span
          >

          <custom-popup
            style="z-index: 999"
            :visible="bannerUploadPopup"
            :flexible-size="true"
            title="Add Banner"
            @close="bannerUploadPopup = false"
          >
            <custom-file-input
              title="Select File"
              :value="offers.file"
              @change="(file) => (offers.file = file)"
            />

            <div class="upload-button" @click.stop="bannerUploadPopup = false">
              Upload
            </div>
          </custom-popup>
        </div>
      </div>
      <div class="description-content">
        <div class="textarea">
          <custom-editor-inline
            title="Description ..."
            :offerToolbar="true"
            v-model="offers.description"
          />
        </div>
        <div class="attachment-content">
          <div class="url-content" @click="urlInputVisible = true">
            <img src="@/assets/attach-url-icon.svg" alt="" />
            <span v-if="urlInputVisible == false && !url"
              >Enter web page URL</span
            >
            <input
              v-else
              type="text"
              v-model="url"
              placeholder="Paste URL ..."
            />
          </div>
          <div class="document-content" @click="documentPopupVisible = true">
            <img src="@/assets/attach-doc-icon.svg" alt="" />
            <span> Attach document</span>
          </div>

          <div class="uploaded-files-content">
            <!-- <div
              class="single-file"
              v-for="(file, index) in attachments"
              :key="index"
            >
              <span>{{ file.filename }}</span>
            </div> -->
            <file-block
              v-for="(file, index) in attachments"
              :uploadedFile="file"
              :key="index"
              @delete="deleteFile(index)"
            />
          </div>
        </div>
        <custom-popup
          style="z-index: 999"
          :visible="documentPopupVisible"
          :flexible-size="true"
          title="Add document"
          @close="documentPopupVisible = false"
        >
          <div class="upload-container">
            <input
              type="file"
              class="multiple-input"
              multiple
              @change="onFileChange"
            />

            <div
              class="upload-button"
              @click.stop="documentPopupVisible = false"
            >
              Upload
            </div>
          </div>
        </custom-popup>
      </div>
      <div
        class="add-offer__options"
        :class="{ disabled: published == true && isEditMode == true }"
      >
        <div
          class="add-offer__options-option"
          v-for="(offer, index) in offers.options"
          :key="index"
        >
          <div class="add-offer__options-option-index">
            {{ index + 1 }}
          </div>
          <div class="add-offer__options-option-name">
            <input
              class="cstm-input cstm-input--option"
              :placeholder="` Option `"
              v-model="offer.option"
            />
          </div>

          <div class="amount-content">
            <input
              v-model="offer.amount"
              placeholder="Enter amount"
              type="text"
            />
          </div>
          <div class="currency-content">
            <custom-select
              title="Currency"
              :options="currencyOptions"
              v-model="choosenCurrency"
            />
          </div>
          <div class="paymentby-content">
            <custom-select
              title="Payment by"
              :options="offer.paymentBy"
              v-model="offer.choosenPayment"
            />
          </div>
          <div
            class="add-offer__options-option-delete"
            @click="deleteOptionClick(index)"
            v-if="offers.options.length > 2"
          >
            <img :src="require('@/assets/icons/icon_delete.svg')" />
          </div>
        </div>
        <div class="add-offer__options-add-option" @click="addOptionClick">
          <img :src="require('@/assets/icons/icon_add.svg')" />
          <span> Add Option </span>
        </div>
      </div>
      <div class="creation-button-content">
        <div
          class="button-unfilled"
          v-if="!isEditMode"
          @click="createOffer(false)"
        >
          Save Draft
        </div>
        <div class="button" v-if="!isEditMode" @click="createOffer(true)">
          Publish
        </div>
        <div
          class="send-notification"
          @click.stop="sendNotification = !sendNotification"
          v-if="isEditMode"
        >
          <span>Send Notification</span>
          <div class="dot" :class="{ selected: sendNotification }"></div>
        </div>
        <div
          class="button-unfilled"
          v-if="isEditMode && !published"
          @click="updateOffer(false)"
        >
          Save Draft
        </div>
        <div class="button" v-if="isEditMode" @click="updateOffer(true)">
          Publish
        </div>
      </div>
    </custom-popup>
    <custom-popup
      title="Edit offer"
      :visible="editPopupVisible"
      @close="editPopupVisible = false"
    >
    </custom-popup>
    <custom-popup
      class="statistics-popup"
      :title="popupTitle"
      :visible="responsesPopupVisible"
      @close="responsesPopupVisible = false"
    >
      <div class="export-wrapper">
        <p>Response Statistics</p>
        <button @click="exportsAsExcel" class="exportAsExcel">Export</button>
      </div>
      <div
        class="top-container"
        v-show="offerStatistics.Statistics && offerStatistics.Statistics.length"
      >
        <div
          class="chart-container"
          :style="[
            offerStatistics.Statistics && offerStatistics.Statistics.length > 10
              ? { height: '300px' }
              : { height: '200px' },
          ]"
        >
          <canvas class="canvas-container" ref="statisticsChart"></canvas>
        </div>
        <div class="percentage-content">
          <span>{{ percentageOfAnswers }}</span>
          <span>%</span>
          <div>Engagement</div>
        </div>
      </div>
      <div
        class="no-data-container"
        v-if="
          offerStatistics.Statistics && offerStatistics.Statistics.length < 1
        "
      >
        No Data
      </div>
      <div class="bottom-container">
        <p>
          Waiting for {{ allPossibleVoteNum + "/"
          }}<span>{{ numberOfVotes }}</span> responses
        </p>
        <div class="wrapper">
          <div class="persons-container">
            <div v-for="person in waitingList" :key="person.PersonID">
              {{ person.FirstName + " " + person.LastName }}
            </div>
          </div>
          <div
            @click="sendMailNotification"
            v-if="publishedOrNot && allPossibleVoteNum === numberOfVotes"
            class="remainder-button"
          >
            Send Reminder
          </div>
        </div>
      </div>
    </custom-popup>
    <div class="preview-popup">
      <custom-popup
        :visible="offerDetailVisible"
        @close="offerDetailVisible = false"
      >
        <div class="banner-desc-content">
          <div
            v-if="offers.file.base64"
            class="banner-content"
            :style="{
              backgroundImage: `url(data:image/jpeg;base64,${offers.file.base64})`,
            }"
          ></div>
          <div v-else>Banner here</div>
          <div class="description-content">
            <p class="title">
              {{ offers.heroTitle ? offers.heroTitle : "Title here" }}
            </p>
            <div class="desc-text">
              <span v-html="offers.description"></span>
            </div>
          </div>
        </div>
        <div class="attachment-content">
          <div class="files">
            <div class="title">
              Attachments ({{ attachments.length }})
              <div class="dots-content">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div class="files-content">
              <file-block
                v-for="(file, index) in attachments"
                :uploadedFile="file"
                :key="index"
                @delete="deleteFile(index)"
              />
            </div>
          </div>
          <div class="url-content">
            <span v-if="url"
              >Visit web page:
              <a :href="url" target="_blank">{{ url }}</a></span
            >
          </div>
        </div>

        <div class="options-content">
          <p>Options</p>
          <div class="options">
            <hooper :itemsToShow="5" :mouseDrag="false">
              <slide
                v-for="(opt, index) in offers.options"
                :key="opt.OfferOptionID"
                class="single-opt"
              >
                <div class="for-event">
                  <div
                    class="opt-info-wrapper"
                    :title="opt.option.length > 25 ? opt.option : null"
                  >
                    <div class="left-side">
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div class="right-side">
                      <span>{{ opt.option }}</span>
                      <span>{{ opt.amount }}</span>
                      <span v-if="opt.choosenPayment == 1"
                        >Funded by company</span
                      >
                      <span v-else>My share</span>
                    </div>
                  </div>
                </div>
              </slide>
              <hooper-navigation
                v-if="offers.options.length > 5"
                slot="hooper-addons"
              >
              </hooper-navigation>
            </hooper>
          </div>
          <!-- <div class="options" v-else>
            <p>Options here</p>
          </div> -->
        </div>
        <div class="comment-content">
          <textarea
            placeholder="Type your comment"
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <div class="button-content">
          <button>Send Response</button>
        </div>
      </custom-popup>
    </div>
    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
  </div>
</template>

<script>
import NewsPollsOffersWrapper from "@/components/ReusableComponents/NewsPollsOffersWrapper/NewsPollsOffersWrapper";
// import CustomMultiSelect from "@/components/ReusableComponents/CustomMultiSelect/CustomMultiSelect";
import CustomPopup from "../ReusableComponents/CustomPopup/CustomPopup.vue";
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput";
import CustomSelect from "../ReusableComponents/CustomSelect/CustomSelect.vue";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue";
import axios from "@/Helpers/AxiosInstance.js";
import Chart from "chart.js";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import CustomFileInput from "@/components/ReusableComponents/CustomFileInput/CustomFileInput";
// import MultiFileUpload from "@/components/ReusableComponents/MultiFileUpload/MultiFileUpload";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import CustomEditorInline from "@/components/ReusableComponents/CustomEditorInline/CustomEditorInline.vue";
import SelectPersons from "@/components/FixedSchedules/SelectPersons.vue";
import { toBase64 } from "@/Helpers/HelperFunctions";
import FileBlock from "@/components/Profile/FileBlock";

export default {
  components: {
    NewsPollsOffersWrapper,
    CustomPopup,
    CustomSelect,

    // CustomMultiSelect,
    SelectPersons,
    DatePicker,
    CustomInput,
    CustomFileInput,
    CustomSnackbar,
    CustomEditorInline,
    Hooper,
    Slide,
    HooperNavigation,
    // MultiFileUpload,

    FileBlock,
  },
  async created() {
    this.getOfferTypes();
    this.getData(1, true);
    // await this.getDepartments();
    this.getDepartmentJobs();
    this.getCurrency();
  },
  data() {
    return {
      highPriority: false,
      data: [],
      sendNotification: true,
      chart: null,
      currentPage: 1,
      choosenCurrency: "",
      clickedOfferId: null,
      maxPageCount: null,
      addPopupVisible: false,
      showPreviewPopup: false,
      editPopupVisible: false,
      documentPopupVisible: false,
      responsesPopupVisible: false,
      bannerUploadPopup: false,
      urlInputVisible: false,
      isEditMode: false,
      offerDetailVisible: false,
      newAttachments: [],
      departmentPeople: [],
      attachments: [],
      currencyOptions: [],
      audienceLength: "",
      onGoing: false,
      draft: false,
      completed: false,
      snackbarPurpose: "",
      search: "",
      snackbarText: "",
      snackbarVisible: false,
      offerTypes: [],
      choosenOfferType: "",
      departments: [],
      offerStatistics: [],
      expiryTime: "",
      url: "",
      published: false,
      selectedValues: {
        person: [],
        department: [],
        job: [],
      },

      offers: {
        id: null,
        heroTitle: "",
        bannerTitle: "",
        description: "",

        document: {},
        file: {},
        options: [
          {
            option: "",
            amount: "",
            paymentBy: [
              { title: "Funded by Company", value: 1 },
              { title: "Employees Share", value: 2 },
            ],

            choosenPayment: "",
          },
          {
            option: "",
            amount: "",
            paymentBy: [
              { title: "Funded by Company", value: 1 },
              { title: "Employees Share", value: 2 },
            ],

            choosenPayment: "",
          },
        ],
      },
    };
  },
  computed: {
    offerTypeOptions() {
      return this.offerTypes.map((el) => {
        return {
          title: el.Name,
          value: el.ID,
        };
      });
    },
    publishedOrNot() {
      if (!this.offerStatistics.ForPersons) return;
      return this.offerStatistics.ForPersons[0].Published;
    },
    waitingList() {
      if (!this.offerStatistics.ForPersons) return;
      if (!this.offerStatistics.ForPersons.length) return;
      let result = [];
      // this.offerStatistics.ForPersons.forEach((el) => {
      //   if (!el.Voted) {
      //     result.push(el);
      //   }
      // });
      result = this.offerStatistics.ForPersons.filter(
        (el) => el.Voted == false
      );
      return result;
    },
    popupTitle() {
      let title = "";
      if (this.offerStatistics.Statistics) {
        if (this.offerStatistics.Statistics.length) {
          title = this.offerStatistics.Statistics[0].Title;
        }
      }
      return title;
    },
    percentageOfAnswers() {
      if (!this.offerStatistics.Statistics) return;
      if (!this.offerStatistics.Statistics.length) return;
      let result = "";
      let arrOfNumbers = [];
      this.offerStatistics.Statistics.forEach((el) => {
        arrOfNumbers.push(el.OptionCount);
      });
      let sum = arrOfNumbers.reduce(function (a, b) {
        return a + b;
      });
      result = (sum / this.allPossibleVoteNum) * 100;
      // result.toString().split(".")[0];

      return result.toString().split(".")[0];
    },
    numberOfVotes() {
      if (!this.offerStatistics.Statistics) return;
      if (!this.offerStatistics.Statistics.length) return;
      let arrOfNumbers = [];
      this.offerStatistics.Statistics.forEach((el) => {
        arrOfNumbers.push(el.OptionCount);
      });
      let sum = arrOfNumbers.reduce(function (a, b) {
        return a + b;
      });
      let result = this.offerStatistics.ForPersons.length - sum;
      return result;
    },
    allPossibleVoteNum() {
      if (!this.offerStatistics.Statistics) return;
      if (!this.offerStatistics.Statistics.length) return;
      return this.offerStatistics.Statistics[0].AllPossibleVoteNum;
    },
    chartLabels() {
      return [
        ...this.offerStatistics.Statistics.map(
          (el) => el.OfferOptionDescription
        ),
      ];
    },
    chartData() {
      let data = this.offerStatistics.Statistics.map(
        (el) => (el.OptionCount / el.AllPossibleVoteNum) * 100
      );

      let result = [];
      data.forEach((el) => {
        result.push(el.toString().split(".")[0]);
      });
      return result;
    },

    filteredData() {
      let result = [];
      if (!this.search) {
        result = this.data;
      } else {
        result = this.data.filter((el) =>
          el.title.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return result;
    },
    filteredByStatus() {
      let result = [];

      if (this.onGoing) {
        result = this.filteredData.filter((el) => el.status == "ongoing");
      } else if (this.draft) {
        result = this.filteredData.filter((el) => el.status == "draft");
      } else if (this.completed) {
        result = this.filteredData.filter((el) => el.status == "completed");
      } else {
        result = this.filteredData;
      }

      return result;
    },
    departmentsSelect() {
      return this.departments.map((department) => ({
        value: department.ID,
        title: department.Name,
        field: "department",
        children: department.jobs.map((job) => ({
          parentField: "department",
          parentValue: department.ID,
          field: "job",
          title: job.JobName,
          value: job.Job,
          children: job.persons.map((person) => ({
            parentField: "job",
            parentValue: job.Job,
            field: "person",
            title: `${person.FirstName} ${person.LastName}`,
            value: person.PersonID,
          })),
        })),
      }));
    },
  },

  methods: {
    redirectToResponses() {
      this.$router.push("/AllResponses");
    },
    // scrolledToBottom(event) {
    //   var el = event.srcElement;

    //   if (el.scrollTop >= el.scrollHeight - el.clientHeight) {
    //     this.currentPage += 1;

    //     this.getData();
    //   }
    // },

    getCurrency() {
      axios.get("Company/CurrencyTypes").then((resp) => {
        this.currencyOptions = resp.data.Value.map((el) => {
          return {
            title: el.Name,
            value: el.ID,
          };
        });
      });
    },
    exportsAsExcel() {
      const isLocal = ~location.href.indexOf("localhost");

      let OfferId = this.clickedOfferId;
      const link = document.createElement("a");
      link.href = `https://intranet.upgaming.${
        isLocal ? "dev" : "com"
      }/api/News/GetOfferResultsForExcel?Offer=${OfferId}`;
      link.setAttribute("download", `OfferResults.xlsx`);
      document.body.appendChild(link);
      link.click();
    },
    drawChart() {
      const ctx = this.$refs.statisticsChart.getContext("2d");

      this.chart = new Chart(ctx, {
        type: "horizontalBar",

        data: {
          datasets: [
            {
              data: this.chartData,
              backgroundColor: function (el) {
                let index = el.dataIndex;
                let value = el.dataset.data[index];
                return (index = `rgba(40, 53, 147, ${"0." + value})`);
              },

              borderSkipped: false,
              barPercentage: 0.8,
            },
          ],
        },
        options: {
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },
          },

          legend: {
            display: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            enabled: true,

            callbacks: {
              label: function (context) {
                return context.value + "%";
              },
              // labelTextColor: function (context) {
              //   return "#959BC9 ";
              // },
            },
          },

          scales: {
            xAxes: [
              {
                labelMaxWidth: 10,

                gridLines: {
                  display: false,
                },
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 10,
                  fontColor: "#959BC9",
                  fontSize: 12,
                  fontStyle: 300,

                  callback: function (value) {
                    return value + "%";
                  },
                },
              },
            ],
            yAxes: [
              {
                afterSetDimensions: function (axes) {
                  axes.maxWidth = 380;
                },
                display: true,
                gridLines: {
                  display: true,
                },

                labels: this.chartLabels,

                ticks: {
                  fontColor: "#283593",
                  fontFamily: "Roboto",
                  fontStyle: 300,
                  stacked: true,
                  fontSize:
                    this.offerStatistics.Statistics.length > 10 ? 12 : 15,
                  padding: 10,
                  beginAtZero: true,
                  callback: function (value) {
                    if (value.length > 40) {
                      return value.substr(0, 38) + "...";
                    } else {
                      return value;
                    }
                  },
                },
              },
            ],
          },
        },
      });
    },
    getOfferStatistics(el) {
      this.clickedOfferId = el.id;
      axios
        .get(`News/GetOffersResponseStatistics?Offer=${el.id}`)
        .then((resp) => {
          this.offerStatistics = resp.data.Value;
          this.responsesPopupVisible = true;
          if (this.chart) {
            this.chart.destroy();
          }
        });
    },
    async onFileChange(e) {
      if (!this.isEditMode) {
        const filesArr = [];
        let files = e.target.files;

        for (let i = 0; i < files.length; i++) {
          this.readURL(files[i]);

          const object = {
            size: files[i].size,
            type: files[i].type,
            name: files[i].name,
            base64: await toBase64(files[i]),
          };
          filesArr.push(object);
        }

        this.attachments.push(...filesArr);
      } else {
        const filesArr = [];
        let files = e.target.files;
        // console.log(e.target.files, "dd");
        for (let i = 0; i < files.length; i++) {
          this.readURL(files[i]);

          const object = {
            size: files[i].size,
            type: files[i].type,
            name: files[i].name,
            base64: await toBase64(files[i]),
          };
          filesArr.push(object);
        }

        this.newAttachments.push(...filesArr);
        this.attachments.push(...this.newAttachments);
      }
    },
    readURL(file) {
      const reader = new FileReader();
      reader.onload = (event) => event.target.result;
      reader.readAsDataURL(file);
    },
    getDepartmentJobs() {
      axios.get("News/GetPlacementDropDownPersons").then((resp) => {
        if (resp.data.ErrorMsg) return;
        this.departmentPeople = resp.data.Value.DepartmentJobPersons.map(
          (dep) => {
            return {
              id: dep.DepartmentID == null ? 0 : dep.DepartmentID,
              name:
                dep.DepartmentName == null
                  ? "Jobs without department"
                  : dep.DepartmentName,
              jobs: dep.JobPersons.map((job) => {
                return {
                  id: job.JobID,
                  name: job.JobName,
                  people: job.Persons.map((person) => {
                    return {
                      id: person.PersonID,
                      name: person.FirstName + " " + person.LastName,
                    };
                  }),
                };
              }),
            };
          }
        );
      });
    },
    resetOfferOpt() {
      this.expiryTime = "";
      this.choosenOfferType = "";
      this.url = "";
      this.choosenCurrency = "";
      this.newAttachments = [];
      this.attachments = [];
      this.offers = {
        id: null,
        heroTitle: "",
        bannerTitle: "",
        description: "",
        document: {},
        file: {},
        options: [
          {
            option: "",
            amount: "",
            paymentBy: [
              { title: "Funded by Company", value: 1 },
              { title: "Employees Share", value: 2 },
            ],
            choosenPayment: "",
          },
          {
            option: "",
            amount: "",
            paymentBy: [
              { title: "Funded by Company", value: 1 },
              { title: "Employees Share", value: 2 },
            ],
            choosenPayment: "",
          },
        ],
      };
    },
    sendMailNotification(el) {
      let personsId = [];
      this.waitingList.forEach((el) => {
        personsId.push(el.PersonID);
      });
      axios
        .post("notifications/SendMailNotification", {
          Placement: this.clickedOfferId,
          PlacementType: "Offer",
          Persons: personsId,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.snackbarPurpose = "success";
            this.snackbarVisible = true;
            this.snackbarText = "Successfully sent";
          }
        });
    },
    getData(page, getFirst) {
      axios
        .get("News/GetOffersAsAdmin", {
          params: {
            Page: page || this.currentPage,
            PageSize: 12,
          },
        })
        .then((resp) => {
          let mappedData = resp.data.Value.map((el, index) => ({
            title: el.Title,
            description: el.Description,
            id: el.ID,
            creationTime: el.StartDate,
            status: el.Published ? "ongoing" : "draft",
            banner: el.File,
          }));
          if (getFirst) this.data = mappedData;
          else {
            this.data.push(...mappedData);
          }
          this.maxPageCount = resp.data.Paging.TotalPageCount;
        });
    },
    deleteClickHandler(item) {
      this.$confirm("Would you like to delete this news?").then(() => {
        axios
          .post("News/DeleteOffer", {
            Offer: item.id,
          })
          .then((response) => this.getData(1, true));
      });
    },

    htmlToText(html) {
      const e = document.createElement("p");
      e.innerHTML = html;

      return e.textContent;
    },

    async getSingleOffer(item) {
      this.resetSelectedValues();
      this.newAttachments = [];
      this.isEditMode = true;

      const response = await axios
        .get(`News/GetOfferDetails?Offer=${item.id}`)
        .then((resp) => resp.data);
      this.addPopupVisible = true;
      const data = response.Value;
      this.audienceLength = data.Restrictions.length;
      data.Restrictions.forEach((el) => {
        if (el.VisibilityRestrictionType === 1)
          this.selectedValues["person"].push(el.VisibleForEntity);
        else if (el.VisibilityRestrictionType === 2)
          this.selectedValues["job"].push(el.VisibleForEntity);
        else if (el.VisibilityRestrictionType === 4)
          this.selectedValues["department"].push(el.VisibleForEntity);
      });
      const offers = data.Offer;
      const opts = data.OfferOptions.map((el) => {
        if (el.FoundedByCompany != 0) {
          return {
            amount: el.FoundedByCompany,
            choosenPayment: 1,
            option: el.OfferOptionDescription,
            paymentBy: [
              { title: "Funded by Company", value: 1 },
              { title: "Employees Share", value: 2 },
            ],
          };
        } else {
          return {
            amount: el.EmployeeShare,
            choosenPayment: 2,
            option: el.OfferOptionDescription,
            paymentBy: [
              { title: "Funded by Company", value: 1 },
              { title: "Employees Share", value: 2 },
            ],
          };
        }
      });
      this.highPriority = offers.IsPriority;
      this.choosenOfferType = offers.OfferType;
      this.expiryTime = offers.ResponseExpiryDate;
      this.url = offers.URL;
      this.choosenCurrency = offers.currency;
      this.published = offers.Published;
      this.attachments = offers.Attachments;
      this.offers = {
        id: item.id,
        bannerTitle: offers.BannerTitle,
        heroTitle: offers.Title,
        description: offers.Description,

        file: {
          type: offers.FileType,
          size: offers.Size,
          name: offers.Filename || null,
          base64: offers.Banner,
        },
        options: opts,
      };
    },
    updateOffer(publish) {
      this.addPopupVisible = true;
      const options = this.offers.options.map((el) => {
        if (el.choosenPayment === 1) {
          return {
            Description: el.option,
            FoundedByCompany: el.amount,
            EmployeeShare: 0,
          };
        } else {
          return {
            Description: el.option,
            EmployeeShare: el.amount,
            FoundedByCompany: 0,
          };
        }
      });
      let attachmentsArray = [];
      this.attachments.forEach((el) => {
        attachmentsArray.push({
          AttachmentName: el.name,
          AttachmentType: el.AttachmentType || el.type,
          Base64attachment: el.File || el.base64,
        });
      });

      // this.newAttachments.forEach((el) => {
      //   attachmentsArray.push({
      //     AttachmentName: el.name,
      //     AttachmentType: el.type,
      //     Base64attachment: el.base64,
      //   });
      // });

      let today = new Date();
      if (
        this.audienceLength != this.selectedValues.person.length &&
        this.expiryTime < today
      ) {
        this.snackbarVisible = true;

        this.snackbarPurpose = "error";
        this.snackbarText = "Update expiry date";
      } else if (
        publish &&
        this.offers.heroTitle &&
        this.offers.description &&
        this.expiryTime > new Date() &&
        this.choosenOfferType &&
        this.offers.options.length &&
        this.selectedValues.person.length
      ) {
        axios
          .post("News/UpdateOffer", {
            Offer: this.offers.id,
            Title: this.offers.heroTitle,
            Description: this.offers.description,
            URL: this.url,
            Type: this.choosenOfferType,
            Base64: this.offers.file.base64,
            sendNotification: this.sendNotification,
            FileType: this.offers.file.type,
            currency: this.choosenCurrency,
            BannerTitle: this.offers.bannerTitle
              ? this.offers.bannerTitle
              : null,
            FileName: this.offers.file.filename,
            Published: publish,
            ResponseExpiryDate: this.expiryTime,
            Attachments: attachmentsArray,
            Restrictions: [
              ...this.selectedValues["person"].map((el) => ({
                VisibilityRestrictionType: 1,
                VisibleForEntity: el,
              })),
              ...this.selectedValues["job"].map((el) => ({
                VisibilityRestrictionType: 2,
                VisibleForEntity: el,
              })),
              ...this.selectedValues["department"].map((el) => ({
                VisibilityRestrictionType: 4,
                VisibleForEntity: el,
              })),
            ],
            OfferOptions: options,
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.snackbarVisible = true;
              this.addPopupVisible = false;
              this.snackbarPurpose = "success";
              this.snackbarText = "Successfully saved";
              this.getData(1, true);
            }
          });
      } else if (!publish) {
        axios
          .post("News/UpdateOffer", {
            Offer: this.offers.id,
            Title: this.offers.heroTitle,
            Description: this.offers.description,
            URL: this.url,
            Type: this.choosenOfferType,
            Base64: this.offers.file.base64,
            FileType: this.offers.file.type,
            FileName: this.offers.file.filename,
            sendNotification: this.sendNotification,
            currency: this.choosenCurrency,
            BannerTitle: this.offers.bannerTitle
              ? this.offers.bannerTitle
              : null,
            Published: publish,
            ResponseExpiryDate: this.expiryTime,
            Attachments: attachmentsArray,
            Restrictions: [
              ...this.selectedValues["person"].map((el) => ({
                VisibilityRestrictionType: 1,
                VisibleForEntity: el,
              })),
              ...this.selectedValues["job"].map((el) => ({
                VisibilityRestrictionType: 2,
                VisibleForEntity: el,
              })),
              ...this.selectedValues["department"].map((el) => ({
                VisibilityRestrictionType: 4,
                VisibleForEntity: el,
              })),
            ],
            OfferOptions: options,
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.snackbarVisible = true;
              this.addPopupVisible = false;
              this.snackbarPurpose = "success";
              this.snackbarText = "Successfully saved";
              this.getData(1, true);
            }
          });
      } else if (!this.offers.heroTitle) {
        this.snackbarVisible = true;

        this.snackbarPurpose = "error";
        this.snackbarText = "Title must be filled";
      } else if (!this.offers.description) {
        this.snackbarVisible = true;

        this.snackbarPurpose = "error";
        this.snackbarText = "Description must be filled";
      } else if (!this.expiryTime) {
        this.snackbarVisible = true;
        this.snackbarPurpose = "error";
        this.snackbarText = "Response expiry must be filled";
      } else if (!this.choosenOfferType) {
        this.snackbarVisible = true;
        this.snackbarPurpose = "error";
        this.snackbarText = "Choose offer category";
      } else if (!this.offers.options.length) {
        this.snackbarVisible = true;
        this.snackbarPurpose = "error";
        this.snackbarText = "Options must be filled";
      } else if (!this.selectedValues.person.length) {
        this.snackbarVisible = true;
        this.snackbarPurpose = "error";
        this.snackbarText = "Choose audience";
      } else if (this.expiryTime < new Date()) {
        this.snackbarPurpose = "error";
        this.snackbarText = "Expiry date can not be past";
        this.snackbarVisible = true;
      }
    },
    createOffer(publish) {
      const restrictions = [
        ...this.selectedValues["person"].map((el) => ({
          VisibilityRestrictionType: 1,
          VisibleForEntity: el,
        })),
        ...this.selectedValues["job"].map((el) => ({
          VisibilityRestrictionType: 2,
          VisibleForEntity: el,
        })),
        ...this.selectedValues["department"].map((el) => ({
          VisibilityRestrictionType: 4,
          VisibleForEntity: el,
        })),
      ];
      let options = this.offers.options.map((el) => {
        if (el.choosenPayment === 1) {
          return {
            Description: el.option,
            FoundedByCompany: el.amount ? el.amount : 0,
            EmployeeShare: 0,
          };
        } else {
          return {
            Description: el.option,
            EmployeeShare: el.amount ? el.amount : 0,
            FoundedByCompany: 0,
          };
        }
      });

      let attachments = [];

      this.attachments.forEach((el) => {
        attachments.push({
          AttachmentName: el.name,
          AttachmentType: el.type,
          Base64attachment: el.base64,
        });
      });
      if (
        publish &&
        this.offers.heroTitle &&
        this.offers.description &&
        this.expiryTime > new Date() &&
        this.choosenOfferType &&
        this.offers.options[0].option.length &&
        this.offers.options[1].option.length &&
        this.selectedValues.person.length
      ) {
        axios
          .post("News/CreateOffer", {
            Type: this.choosenOfferType,
            Description: this.offers.description,
            ResponseExpiry: this.expiryTime,
            Title: this.offers.heroTitle,
            BannerTitle: this.offers.bannerTitle,
            Base64: this.offers.file.base64,
            FileName: this.offers.file.filename,
            FileType: this.offers.file.type,
            Published: publish,
            currency: this.choosenCurrency,
            Restrictions: restrictions,
            OfferOptions: options,
            URL: this.url,
            IsPriority: this.highPriority,
            Attachments: attachments.length ? attachments : [],
          })

          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.getData(1, true);
              this.resetSelectedValues();
              this.resetOfferOpt();

              this.snackbarVisible = true;
              this.addPopupVisible = false;
              this.snackbarPurpose = "success";
              this.snackbarText = "Successfully saved";
            }
          });
      } else if (!publish) {
        axios
          .post("News/CreateOffer", {
            Type: this.choosenOfferType ? this.choosenOfferType : null,
            Description: this.offers.description,
            ResponseExpiry: this.expiryTime ? this.expiryTime : null,
            Title: this.offers.heroTitle,
            BannerTitle: this.offers.bannerTitle,
            Base64: this.offers.file.base64,
            FileName: this.offers.file.filename,
            FileType: this.offers.file.type,
            Published: publish,
            currency: this.choosenCurrency,
            Restrictions: restrictions,
            OfferOptions:
              options[0].Description.length || options[1].Description.length
                ? options
                : [],
            URL: this.url,
            Attachments: attachments.length ? attachments : [],
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.resetSelectedValues();
              this.resetOfferOpt();
              this.addPopupVisible = false;
              this.snackbarPurpose = "success";
              this.snackbarText = "Saved as draft";
              this.snackbarVisible = true;
              this.getData(1, true);
            }
          });
      } else if (!this.offers.heroTitle) {
        this.snackbarVisible = true;

        this.snackbarPurpose = "error";
        this.snackbarText = "Title must be filled";
      } else if (!this.offers.description) {
        this.snackbarVisible = true;

        this.snackbarPurpose = "error";
        this.snackbarText = "Description must be filled";
      } else if (!this.expiryTime) {
        this.snackbarVisible = true;
        this.snackbarPurpose = "error";
        this.snackbarText = "Response expiry must be filled";
      } else if (!this.choosenOfferType) {
        this.snackbarVisible = true;
        this.snackbarPurpose = "error";
        this.snackbarText = "Choose offer category";
      } else if (
        !this.offers.options[0].option.length ||
        !this.offers.options[1].option.length
      ) {
        this.snackbarVisible = true;
        this.snackbarPurpose = "error";
        this.snackbarText = "Options must be filled";
      } else if (!this.selectedValues.person.length) {
        this.snackbarVisible = true;
        this.snackbarPurpose = "error";
        this.snackbarText = "Choose audience";
      } else if (this.expiryTime < new Date()) {
        this.snackbarPurpose = "error";
        this.snackbarText = "Expiry date can not be past";
        this.snackbarVisible = true;
      }
    },
    resetSelectedValues() {
      this.selectedValues = {
        person: [],
        department: [],
        job: [],
      };
    },
    deleteFile(index) {
      this.attachments.splice(index, 1);
    },
    deleteOptionClick(index) {
      this.$set(
        this.offers,
        "options",
        this.offers.options.filter((e, i) => index !== i)
      );
    },
    optionClick(index) {
      if (this.offers.options.length - 1 === index) {
        this.addOptionClick();
      }
    },
    addOptionClick() {
      this.offers.options.push({
        option: "",
        amount: "",
        paymentBy: [
          { title: "Funded by Company", value: 1 },
          { title: "Employees Share", value: 2 },
        ],
        choosenPayment: "",
      });
    },
    handleChangedValue(selectedList) {
      this.selectedValues = selectedList;
    },
    previewClickHandler() {
      this.offerDetailVisible = !this.offerDetailVisible;
      this.audience = [];
      // if (this.selectedValues.person.length) {
      //   this.selectedValues.person.forEach((el) => {
      //     axios.get(`Person/GetPersons?Person=${el}`).then((resp) => {
      //       this.audience.push(resp.data.Value.Persons[0]);
      //     });
      //   });
      // }
    },
    getOfferTypes() {
      axios.get("News/GetOfferTypes").then((resp) => {
        this.offerTypes = resp.data.Value;
      });
    },
    async getDepartments() {
      const { data: departmentsList } = await axios.get(
        "departments/GetDepartments"
      );
      const departments = departmentsList.Value;
      for (let department of departments) {
        const { data: jobsList } = await axios.get(
          "departments/GetDepartmentJobs",
          {
            params: {
              DepartmentID: department.ID,
            },
          }
        );
        const jobs = jobsList.Value;
        for (let job of jobs) {
          const { data: personList } = await axios.get("jobs/JobPersons", {
            params: {
              JobID: job.Job,
            },
          });
          job.persons = personList.Value;
        }
        department.jobs = jobs;
      }
      this.departments = departments;
      this.resetSelectedValues();
    },
  },
  watch: {
    onGoing() {
      if (this.onGoing == true) {
        this.draft = false;
        this.completed = false;
      }
    },
    draft() {
      if (this.draft == true) {
        this.onGoing = false;
        this.completed = false;
      }
    },
    completed() {
      if (this.completed == true) {
        this.onGoing = false;
        this.draft = false;
      }
    },
    isEditMode() {
      if (this.isEditMode == false) {
        this.resetSelectedValues();
        this.resetOfferOpt();
      }
    },
    offerStatistics: {
      handler() {
        this.drawChart();
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
.statistics-popup {
  ::v-deep .popup {
    width: 1300px;
    left: 56%;
    height: initial;
    h3.title {
      font-size: 24px;
    }
  }
}
p {
  font-size: 18px;
  color: $blue-primary;
  span {
    color: #ff5f58;
  }
}
.no-data-container {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba($blue-primary, 0.3);
  font-size: 90px;
}
.top-container {
  display: flex;
  margin-top: 40px;
  justify-content: flex-end;
  .chart-container {
    width: 100%;

    height: 200px;
    // position: relative;
    // margin-left: auto;
  }
  .percentage-content {
    font-weight: 400;
    font-size: 99px;
    color: rgba($blue-primary, 0.5);
    margin-left: 150px;
    span:nth-child(1) {
      font-family: "Anton", sans-serif;
    }
    span:nth-child(2) {
      font-size: 70px;
      font-family: "Anton", sans-serif;
    }
    div {
      font-size: 18px;
      color: rgba($blue-primary, 1);
      text-align: right;
      font-weight: 400;
    }
  }
}
.bottom-container {
  margin-top: 75px;
  padding-bottom: 5px;
  .wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .remainder-button {
    height: 48px;
    width: 144px;
    background-color: #1de9b6;
    color: #ffffff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    float: right;
    cursor: pointer;
    &:active {
      background-color: #16b18a;
      background-size: 100%;
      transition: background 0.1s;
    }
  }
  .persons-container {
    margin-top: 10px;
    height: 146px;
    border: 1px solid #ededfc;
    border-radius: 6px;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    padding: 8px;
    padding-left: 20px;

    padding-bottom: 0;
    width: 900px;
    overflow: auto;
    div {
      width: 33%;
      font-size: 14px;
      color: #9696b9;
      display: inline-block;
      margin-bottom: 6px;
    }
  }
}
.popup {
  ::v-deep .popup {
    width: 1300px !important;
    overflow: hidden;
  }
  .custom-selects-wrapper {
    display: flex;
    .select-wrapper {
      width: 12.938rem;
    }
    div {
      margin-right: 15px;
    }
    .preview-button {
      margin-left: auto;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        color: $blue-primary;
        font-weight: 300;
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }
  .material-icons {
    font-size: 20px;
    color: $green-primary;
  }
}
// .disabled {
//   pointer-events: none;
//   opacity: 0.4;
// }
.upload-button {
  cursor: pointer;
  width: 197 * $rem;
  display: inline-block;
  height: 40 * $rem;
  line-height: 40 * $rem;
  padding: 0 15 * $rem 0 15 * $rem;
  outline: none;
  text-align: center;
  color: white;
  margin-top: 10px;
  border-radius: 5 * $rem;
  box-shadow: none;
  background-color: #1de9b6;
}
.titles-content {
  display: flex;
  margin-top: 15px;
  .add-banner-button {
    display: flex;
    align-items: center;
    color: $blue-primary;
    font-size: 15px;
    font-weight: 300;
    margin-left: auto;
    white-space: nowrap;
    span {
      font-size: 14px;
    }
    cursor: pointer;
    ::v-deep .popup {
      width: initial !important;
    }
  }
  .hero-title-wrapper,
  .banner-title-wrapper {
    position: relative;
    margin-right: 15px;
    width: 100%;
    .offer-title-count {
      position: absolute;
      right: 10px;
      top: 12px;
      font-size: 14px;
      color: $blue-primary;
      opacity: 0.7;
    }
    ::v-deep label {
      width: 100%;
      input {
        padding-right: 65px;
      }
    }
  }
}
.description-content {
  margin-top: 20px;

  ::v-deep .popup {
    width: initial !important;
    display: flex;
    flex-direction: column;

    .upload-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      input {
        outline: none;
        border: 0;
        width: 194px;
        height: 30px;
        color: $blue-primary;
      }
    }
  }
  .textarea {
    height: 250px;
    width: 100%;
    resize: none;
    outline: none;
    border: 0.0625rem solid #dcdde6;
    border-radius: 5px;
    font-size: 17px;
    color: $blue-primary;
    font-weight: 300;
    padding-left: 10px;
    padding-top: 8px;
    overflow-y: auto;
    div {
      ::v-deep div {
        border: 0;
        display: block;
        cursor: text;
      }
    }
  }
  .attachment-content {
    display: flex;
    align-items: center;
    min-height: 86px;
    > div {
      flex-shrink: 0;
    }
    .url-content,
    .document-content {
      cursor: pointer;
      margin-right: 65px;
      display: flex;
      align-items: center;
      span {
        margin-left: 7px;
        color: $blue-primary;
        font-weight: 300;
        font-size: 14px;
        opacity: 0.8;
      }
      input {
        outline: none;
        border: 0;
        margin-left: 10px;
        color: #1db3e9;
      }
      .material-icons {
        font-size: 20px;
        color: $green-primary;
      }
    }
    .uploaded-files-content {
      overflow-y: auto;
      width: 69%;
      padding-bottom: 10px;
      white-space: nowrap;
      display: flex;
      ::v-deep .file-block {
        height: 3.1625rem;
        width: 10rem;
        margin-top: 10px;
        padding: 12px;
        margin-right: 5px;
        display: inline-block;
        box-shadow: none;
        flex-shrink: 0;
        .top {
          flex-shrink: 0;
          span {
            font-weight: 300;
            white-space: nowrap;
            font-size: 13px;
            cursor: default;
            max-width: calc(100% - 70 * -0.9375rem);
          }
          div {
            height: 25px;
            max-width: 25px;
            font-size: 10px;
          }
        }
        .bottom {
          display: none;
        }
      }
    }
    .document-content {
      margin-right: 15px;
      span {
        padding-top: 3px;
      }
    }
  }
}
.paymentby-content {
  ::v-deep .select-wrapper .selected-box {
    height: 20px;
    width: 205px;
  }
}
.creation-button-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .button-unfilled,
  .button {
    cursor: pointer;
    width: 150 * $rem;
    height: 50 * $rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    background-color: rgba($blue-primary, 0.6);
    color: white;
    transition: 0.2s ease;
    margin-left: 5px;
    &:hover {
      background-color: rgba($blue-primary, 0.8);
      border-color: rgba($blue-primary, 0.8);
    }
  }
  .button-unfilled {
    background-color: transparent;
    color: rgba($blue-primary, 0.6);
    border: 1px solid rgba($blue-primary, 0.6);
    &:hover {
      color: white;
    }
  }
}
.add-offer__options {
  margin-top: 20px;
  height: 300px;
  overflow-y: scroll;
  width: calc(100% + 30px);
  padding: 0.9375rem 30px 0 0;
  margin-bottom: 10px;
  .currency-content {
    margin-right: 10px;

    ::v-deep .select-wrapper {
      .selected-box {
        height: 20px;
        width: 140px;
      }
    }
  }
  .amount-content {
    margin-left: auto;
    margin-right: 10px;
    input {
      width: 160px;
      height: 33px;
      outline: none;
      border: 2px solid #ededfc;
      border-radius: 5px;
      text-align: center;
      color: $blue-primary;
      font-size: 16px;
      &::placeholder {
        color: rgba($blue-primary, 0.8);
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
  .paymentby-content {
    margin-left: auto;
  }
  &-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &-index {
      font-size: 14px;
      color: $blue-primary;
    }

    &-delete {
      margin-left: auto;
      cursor: pointer;
    }
    &-name {
      width: 62%;
      input {
        width: 100%;
        padding-left: 10px;
        outline: none;
        border: 0;
        color: $blue-primary;
        font-weight: 300;
        font-size: 16px;
        &::placeholder {
          color: $blue-primary;
          font-weight: 300;
        }
      }
    }
  }

  &-add-option {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #9696b9;

    img {
      height: 12px;
      width: 12px;
      margin-right: 7px;
    }
  }
}
.preview-popup {
  p {
    font-size: 20px;
    font-weight: 500;
    color: rgba($blue-primary, 1);
  }

  ::v-deep .overlay .popup {
    width: 1342px;
    height: initial;
    left: 52%;
    top: 53%;
    overflow: hidden;
    h3.title {
      display: none;
    }
    .title {
      margin-bottom: 0px;
    }
  }
  .options-content {
    position: relative;
    margin-top: 15px;

    .hooper-navigation {
      ::v-deep button:nth-child(1) {
        top: 60%;
        left: -3%;
      }
      ::v-deep button:nth-child(2) {
        top: 60%;
        right: -3%;
      }
    }
  }
  .banner-desc-content {
    display: flex;
    height: 410px;
    justify-content: space-between;
    .banner-content {
      height: 100%;
      width: calc(50% - 15px);
      border-radius: 10px;
      margin-bottom: 40px;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .description-content {
      width: calc(50% - 15px);

      .desc-text {
        margin-top: 10px;
        max-height: 378px;
        border-radius: 5px;
        padding-right: 10px;
        padding-top: 10px;
        font-weight: 300;
        color: rgba($blue-primary, 1);
        overflow: scroll;
        margin-bottom: 15px;
      }
    }
  }
  .comment-content {
    margin-top: 30px;
    pointer-events: none;
    textarea {
      height: 75px;
      font-size: 14px;
      width: 100%;
      outline: none;
      border: 1px solid #dcdde6;
      resize: none;
      border-radius: 6px;
      padding: 10px;
      color: #757575;
      &::placeholder {
        color: rgba($blue-primary, 0.7);
      }
    }
  }
  .button-content {
    pointer-events: none;
    button {
      width: 125px;
      height: 45px;
      border-radius: 6px;
      color: #ffffff;
      background: rgba($blue-primary, 0.7);
      float: right;
      margin-top: 25px;
      cursor: pointer;
      font-size: 16px;
    }
  }
  .attachment-content {
    margin-top: 25px;
    display: flex;

    .url-content {
      margin-left: 18px;
      span {
        display: flex;
        align-items: center;
        color: rgba($blue-primary, 0.8);
        white-space: nowrap;
        font-size: 15px;
      }
      a {
        color: #1db3e9;
        max-width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 5px;
      }
    }
    .files {
      width: 50%;
      .title {
        display: flex;
        font-size: 15px;
        color: rgba($blue-primary, 1);
        align-items: center;
        .dots-content {
          display: flex;
          align-items: center;
          position: relative;
          .download-button {
            position: absolute;
            background-color: #ffffff;
            height: 30px;
            width: 150px;
            border-radius: 6px;
            top: 15px;
            left: 0px;
            z-index: 100;
            box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.23);
            opacity: 1;
            display: flex;
            // justify-content: center;
            padding-left: 5px;
            align-items: center;
            color: rgba($blue-primary, 0.8);
          }
          height: 20px;
          width: 30px;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
          margin-left: 10px;
          div {
            height: 4px;
            width: 4px;
            border-radius: 100%;
            background-color: #292929;
            opacity: 0.7;
            margin-left: 3px;
            &:nth-child(1) {
              margin-left: 0;
            }
          }
        }
      }
    }
    .download-content {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #292929;
      margin-bottom: 15px;
      span:nth-child(2) {
        color: $blue-primary;
        font-size: 20px;
        margin-left: 5px;
      }
    }
  }

  .options {
    margin-top: 15px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;

    .single-opt {
      width: 19.9% !important;
      height: 74px;
      background-color: #ededfc;
      display: inline-block;
      margin-left: 1px;
      border-radius: 5px;
      cursor: pointer;
      .for-event {
        height: 100%;
      }
      .opt-info-wrapper {
        display: flex;
        height: 100%;

        .left-side {
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 10px;
          margin-right: 10px;
          span {
            font-size: 30px;
            font-weight: 500;
            color: #9696b9;
          }
        }
        .right-side {
          display: flex;
          flex-direction: column;
          justify-content: center;
          span {
            font-size: 14px;
            font-weight: 300;
            color: rgba($blue-primary, 1);
            max-width: 205px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
.priority-content {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  span:nth-child(1) {
    color: $blue-primary;
    opacity: 0.7;
  }
  span:nth-child(2) {
    color: $blue-primary;
  }
}
::v-deep .hooper-liveregion.hooper-sr-only {
  display: none;
}
.hooper {
  outline: none;
}
.hooper-navigation {
  outline: none;
  ::v-deep button {
    position: absolute;
    background: transparent;
    opacity: 0.3;
    cursor: pointer;
  }
  ::v-deep button:nth-child(1) {
    top: 55%;
    left: -3%;
  }
  ::v-deep button:nth-child(2) {
    right: -3%;
    top: 55%;
  }
}
// .custom-editor {
//     width: 100%;
//     height: 2.5rem;
//     border-radius: 0.375rem;
//     -webkit-box-shadow: none;
//     box-shadow: none;
//     border: 0.0625rem solid #dcdde6;
//     color: rgba(40, 53, 147, 0.7);
//     font-size: 0.9375rem;
//     padding: 0 0.9375rem 0 0.9375rem;
//     outline: none;
// }
.files-content {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  padding-bottom: 10px;
  &::-webkit-scrollbar-thumb {
    background: rgba($blue-primary, 0.1);
  }
  ::v-deep .file-block {
    height: 3.1625rem;
    width: 9.5625rem;
    margin-top: 10px;
    margin-right: 5px;
    display: inline-block;
    box-shadow: none;
    cursor: pointer;
    // border: 0;
    padding: 0;
    .uploaded {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      margin-top: 10px;
      margin-left: 3px;
      .uploaded-logo {
        margin-right: 5px;
      }
    }
    img {
      display: none;
    }
    .top {
      // flex-shrink: 0;

      span {
        font-weight: 300;
        white-space: nowrap;
        max-width: calc(100% - 70 * -0.9375rem);
        font-size: 12px;
      }
      div {
        height: 28px !important;
        width: 28px !important;
        font-size: 12px !important;
      }
    }
    .bottom {
      display: none;
    }
  }
}
.export-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    cursor: pointer;
    width: 9rem;
    height: 2.5rem;
    border-radius: 5px;
    background-color: rgba(40, 53, 147, 0.6);
    color: white;
    transition: 0.2s ease;
  }
}
.send-notification {
  margin-right: 30px;
  display: flex;
  align-items: center;
  color: $blue-primary;
  cursor: pointer;
  font-weight: 300;
  font-size: 15px;
  .dot {
    width: 15px;
    height: 15px;
    border: 1px solid #1de9b6;
    border-radius: 100%;
    margin-left: 5px;
    &.selected {
      background-color: #1de9b6;
    }
  }
}
</style>