var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"welcome-text-and-avatar"},[_c('div',{staticClass:"image-wrapper"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showFallbackImage),expression:"!showFallbackImage"}],class:{
        'more-width': _vm.moreWidthThanHeight,
        'more-height': !_vm.moreWidthThanHeight,
      },attrs:{"src":_vm.imageSrc},on:{"error":function($event){_vm.showFallbackImage = true},"load":_vm.loadHandler}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFallbackImage),expression:"showFallbackImage"}],attrs:{"src":require("@/assets/images/choose-image.png"),"alt":""}})]),_c('div',{staticClass:"vertical",class:{ 'show-welcome': _vm.personID == _vm.$store.state.profile.ID }},[_c('h3',[_vm._v("Hi "+_vm._s(_vm.firstName)+",")]),_c('h1',[_vm._v("Welcome Back!")]),(
        (_vm.personID && _vm.$store.getters.isAdmin) ||
        (_vm.personID && _vm.$store.getters.isSuperAdmin) ||
        (_vm.personID && _vm.$store.getters.hasRole('HR'))
      )?_c('div',{staticClass:"btn reset-password",on:{"click":_vm.resetPassword}},[_vm._v(" Reset Password ")]):_vm._e(),(_vm.personID && _vm.personID == _vm.$store.state.profile.ID)?_c('div',{staticClass:"change-password btn",on:{"click":function($event){_vm.changePasswordForm.visible = true}}},[_vm._v(" Change Password ")]):_vm._e(),_c('label',{staticClass:"edit-avatar"},[_c('i',{staticClass:"material-icons"},[_vm._v("camera_alt")]),_c('input',{ref:"avatar",staticStyle:{"visibility":"hidden","width":"0"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.avatarChange}})])]),_c('custom-popup',{attrs:{"title":"Change Password","visible":_vm.changePasswordForm.visible,"flexible-size":true},on:{"close":function($event){_vm.changePasswordForm.visible = false}}},[_c('form',{staticClass:"change-password-form",on:{"submit":function($event){$event.preventDefault();return _vm.changePassword.apply(null, arguments)}}},[_c('custom-input',{attrs:{"title":"Current Password","input-type":"password","is-required":true,"triggerChangeOnBlur":false},model:{value:(_vm.changePasswordForm.currentPassword),callback:function ($$v) {_vm.$set(_vm.changePasswordForm, "currentPassword", $$v)},expression:"changePasswordForm.currentPassword"}}),_c('div',{staticClass:"wrapper"},[_c('custom-input',{attrs:{"title":"New Password","input-type":"password","is-required":true,"triggerChangeOnBlur":false},model:{value:(_vm.changePasswordForm.newPassword),callback:function ($$v) {_vm.$set(_vm.changePasswordForm, "newPassword", $$v)},expression:"changePasswordForm.newPassword"}}),_c('ul',{staticClass:"validation-terms"},[_c('li',{class:{
              success: _vm.validationTerms.passlength,
              matchError: _vm.validationErrors.length,
            }},[_vm._v(" Password length must be greater than 4 characters ")]),_c('li',{class:{
              success: _vm.validationTerms.cases,
              matchError: _vm.validationErrors.caseError,
            }},[_vm._v(" Must contain minimum 1 lowercase & 1 uppercase, or number, or symbol ")]),(_vm.validationTerms.match)?_c('li',{staticClass:"matchError"},[_vm._v(" New password & repeat doesn't match ")]):_vm._e()])],1),_c('custom-input',{attrs:{"title":"Repeat","input-type":"password","is-required":true,"triggerChangeOnBlur":false},model:{value:(_vm.changePasswordForm.repeat),callback:function ($$v) {_vm.$set(_vm.changePasswordForm, "repeat", $$v)},expression:"changePasswordForm.repeat"}}),_c('button',{staticClass:"btn save"},[_vm._v("Save")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }