<template>
    <div class="add-manual-entry">
        <div class="row">
            <custom-select
                title="Device"
                v-model="device"
                :options="devicesOptions"
                :isRequired="true"
            />
            <custom-select
                title="Action Type"
                v-model="actionType"
                :options="actionTypeOptions"
                :isRequired="true"
                v-show="showActionTypes"
            />
            <input-search
                title="Employee"
                :options="employeeOptions"
                @select="(id) => (employee = id)"
                :isRequired="true"
            />
            <date-picker
                :enableTime="true"
                v-model="time"
                title="Time"
                :isRequired="true"
                :addCustom="'datetime'"
            />
        </div>
        <custom-text-area title="Comment" v-model="comment" :isRequired="true" />
        <button @click="addHandler">Add</button>
    </div>
</template>

<script>
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue"
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue"
import InputSearch from "@/components/ReusableComponents/InputSearch/InputSearch.vue"
import CustomTextArea from "@/components/ReusableComponents/CustomTextArea/CustomTextArea.vue"
export default {
    components: {
        DatePicker,
        CustomSelect,
        InputSearch,
        CustomTextArea,
    },
    props: {
        devices: Array,
        employees: Array,
        actionTypes: Array,
    },
    data() {
        return {
            time: 0,
            device: "",
            employee: "",
            actionType: "",
            comment: "",
            showActionTypes: true,
        }
    },
    computed: {
        devicesOptions() {
            return this.devices.map((el) => ({
                title: `${el.Name} - ${el.OfficeName}`,
                value: el.ID,
            }))
        },
        actionTypeOptions() {
            return this.actionTypes.map((el) => ({
                title: el.Name,
                value: el.ID,
            }))
        },
        employeeOptions() {
            return this.employees.map((el) => ({
                title: el.FirstName + " " + el.LastName,
                value: el.ID,
            }))
        },
    },
    methods: {
        addHandler() {
            this.$emit("add", {
                device: this.device,
                actionType: this.actionType,
                employee: this.employee,
                time: this.time,
                comment: this.comment,
            })
        },
    },
    watch: {
        device(value) {
            var device = this.devices.find((el) => el.ID == value)
            if (!device || !device.ActionType) this.showActionTypes = true
            else this.showActionTypes = false
            this.actionType = ""
        },
    },
}
</script>

<style scoped lang="scss">
.add-manual-entry {
    display: flex;
    flex-direction: column;

    .row {
        display: flex;
        align-items: center;

        .input-search,
        .select-wrapper {
            width: 12.938rem;
            margin-right: 15 * $rem;
        }
    }

    & > label {
        margin: 15 * $rem 0;
    }

    button {
        margin-left: auto;
        background-color: rgba($blue-primary, 0.5);
        color: #ffffff;
        height: 40px;
        width: 100px;
        border-radius: 3px;
        font-size: 16px;
        cursor: pointer;
    }
}
</style>