import Vue from 'vue'
import Vuex from 'vuex'
const isLocal = ~location.href.indexOf('localhost')
const isProduction = location.href.indexOf('.upgaming.com') > 0
Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    isProduction: isProduction,
    timezoneOffset: 4,
    profile: null,
    permissions: [],
    roles: [],
    getAvatar: isLocal
      ? 'http://intranet.upgaming.dev/api/Person/GetPersonAvatar2?ID='
      : '/api/Person/GetPersonAvatar2?ID=',
    showBookmarks: false,
    backgroundWithoutPadding: false,
    isAdminPage: true,
    globalSnackbar: {
      visible: false,
      purpose: 'error',
      text: '',
    },
    globalConfirm: {
      resolve: null,
      reject: null,
      text: '',
      visible: false,
    },
    showTimeLine: false,
  },
  mutations: {
    showMessage(state, { text, purpose }) {
      state.globalSnackbar.text = text
      state.globalSnackbar.visible = true
      state.globalSnackbar.purpose = purpose
    },
  },
  actions: {
    confirm({ state }, text) {
      return new Promise((resolve, reject) => {
        state.globalConfirm.text = text
        state.globalConfirm.visible = true
        state.globalConfirm.resolve = resolve
        state.globalConfirm.reject = reject
      })
    },
  },
  getters: {
    isAdmin: (state) => {
      return !!state.roles.find((r) => r.Name == 'Admin')
    },
    isSuperAdmin: (state) => {
      return !!state.roles.find((r) => r.Name == 'Super Admin')
    },
    hasRole: (state) => (roleName) => {
      return !!state.roles.find((r) => r.Name == roleName)
    },
  },
})
