<template>
    <div class="background">
        <div class="content-wrapper">
            <performance-report 
                @close="reportVisible = false"
                :visible="reportVisible"
                :singlePersonMode="true"
                :personID="parseInt(this.$route.params.id)"
            />
            <comment
                :visible="commentChatVisible"
                @close="closeCommentChat"
                :overviewData="overViewDataObject"
                :historyDetailID="historyDetailID"
                :message="commentMessage.messageValue"
                @input="(value) => commentMessage.messageValue = value"
                @send="sendMessage"
                @toolbarVisible="(message) => openCommentChatToolbar(message)"
                :messages="commentMessages"
                :loading="messagesLoading"
                @deleteMsg="(id) => deleteMsg(id,historyDetailID)"
                :scrollBottomMode="scrollBottomMode"
                @seenObj="messageAsSeen"
                :commonchatMode="commonChatMode"
            />
            <custom-snackbar
                :text="snackbar.text"
                :purpose="snackbar.purpose"
                v-if="snackbar.visible"
                @close="snackbar.visible = false"
            />
            <custom-loader v-if="loading" :fixed="true" />
            <div class="top-header">
                <div class="back" @click="$router.push({path:'/Grades/TeamRatingOverall'})">
                    <img src="@/assets/grades/back.svg" alt="">
                    <span>Back</span>
                </div>
                <div class="left-side">
                    <span>Rate</span>
                </div>
                <div class="header-filters">
                    <ul>
                        <li v-for="(f,ind) in filters" :key="ind">
                            <custom-select
                                :title="f.title"
                                :options="f.options"
                                v-model="f.selected"
                                v-if="f.typeName === 'criteria'"
                                :clearVisible="true"
                            />

                            <input-search 
                                :title="f.title"
                                :options="f.options.length > 0 ? f.options : []"
                                @select="(val) => (f.selected = val,changePerson(val))"
                                v-if="f.typeName === 'person'"
                            />

                            <div v-if="f.typeName === 'search'" class="search">
                                <img src="@/assets/grades/search.svg" alt="search">
                                <input v-model="f.value" type="text" @input="hightlightResult" :placeholder="f.title" spellcheck="false">
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="right-side">
                    <div class="actions-wrapper">
                        <div class="performance-report" @click="reportVisible = true">
                            <span>Performance Report</span>
                        </div>
                        <div class="compare-with" ref="compareWithBtn">
                            <div class="wrapper" @click="compareDropdownVisible = !compareDropdownVisible">
                                <img src="@/assets/grades/ratingOverall/team.svg" alt="">
                                <span>
                                    Compare with
                                </span>
                            </div>
                            <transition name="fade" ref="compareDropdown">
                                <div class="persons-dropdown" v-if="compareDropdownVisible">
                                    <ul class="">
                                        <li class="search-persons-list no-hover ">
                                            <input class="" type="text" placeholder="Search..." v-model="personsListSearchValue" >
                                        </li>
                                        <li @click="selectComparablePerson(person)" v-show="filterPersonsList(person.title,personsListSearchValue)" class="single-person" v-for="(person,ind) in gradedPersonsList" :key="ind">
                                            <div class="person-avatar" :style="`background-image:url(${getPersonAvatar(person.PersonID,true)})`" >
                                            </div>
                                            <span>
                                                {{person.title}}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>

            <div class="middle">
                <div class="details-content">
                    <div class="no-records" v-if="alternateText">
                        <span>There are no Grades Assigned For {{personDetails.JobName ? personDetails.JobName : 'This Job'}}</span>
                    </div>
                    <div class="details-header">
                        <div class="left-side">
                            <div class="person-avatar" :style="`background-image:url(${getPersonAvatar()})`">
                            </div>
                            <div class="person-info">
                                <ul>
                                    <li class="name-surname">
                                        <span>
                                            {{`${personDetails.FirstName} ${personDetails.LastName}` || ''}}
                                        </span>
                                        <!-- <div class="common-comment-btn" @click="(openCommentChat(0,0,true),commonChatMode = true)">
                                            <img class="common-comment-btn" src="@/assets/grades/ratingOverall/comment.svg" alt="">
                                        </div> -->
                                    </li>
                                    <li class="job">
                                        <span>
                                            {{personDetails.JobName || ''}}
                                        </span>
                                        <div class="badge">
                                            <span>{{personDetails.BadgeName}}</span>
                                            <img :src="getBadge(personDetails.GradeID)" alt="">
                                        </div>
                                    </li>
                                    <li>
                                        <div class="rating-lines">
                                            <div :class="['single-line',{'blank': personDetails.AvgScore < score.score}]" :style="`background:${score.color}`" v-for="(score,ind) in personScoreRanges()" :key="ind">

                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="salary">
                                            <span class="salary-title"> Salary: <span> {{`${personDetails.Salary || ' '} ${personDetails.SalaryCurrency || ' '}`}} </span></span>
                                            <span class="salary-title"> 
                                                Salary Range: <span> 
                                                    {{`${personDetails.MinSalary || ''} - ${personDetails.MaxSalary || ''} ${personDetails.Code || ''}`}}
                                                </span>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="right-side">
                            <ul>
                                <li>
                                    <span v-if="!personDetails.GradeID || personDetails.GradeID === 0">Not Rated</span>
                                    <span v-else >Rated {{calculateTimeDiff(new Date(personDetails.RatingDate),new Date())}} ago</span>
                                    <div :class="['period-actions',{'alldisabled': this.personHistorysQuery.length < 1 || !this.personHistorysQuery}]">
                                        <div :class="['backward',{'disabled':lastHistoryback || $route.query.createGrading}]" @click="getHistoryFromQuery(true,false)">
                                            <img src="@/assets/grades/arrowCircle.svg" alt="">
                                        </div>
                                        <div :class="['forward',{'disabled':lastHistoryForwards || $route.query.createGrading}]" @click="getHistoryFromQuery(false,true)">
                                            <img src="@/assets/grades/arrowCircle.svg" alt="">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <span class="title">Rating Period: <span> {{personDetails.RatingPeriodName}} </span></span>
                                </li>
                                <li>
                                    <span class="title">Job Start Date: <span> {{convetToDateString(personDetails.PersonJobStartDate,"dd MMM yyyy") || '-'}} </span></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="details-bottom">
                        <div class="bottom-title">
                            <div class="title">
                                <h2>
                                    Assign points according to the criterias
                                </h2>
                            </div>
                            <div class="select-grade" ref="selectGrade" v-if="!alternateText">
                                <div :class="['selected-grade-title',{'wide':gradeListVisible,'error':selectedGradeDataNotAvailable}]" @click="gradeListVisible = !gradeListVisible">
                                    <span v-if="!selectedGrade">Assign Grade</span>
                                    <span v-if="selectedGrade && !selectedGradePart"> Assign Level </span>
                                    <span v-else>
                                        {{gradesList.find(el => el.value === selectedGradePart) ? gradesList.find(el => el.value === selectedGradePart).title : ''}}
                                    </span>
                                    <img :style="{transform:gradeListVisible ? 'rotate(180deg)' : 'rotate(0)'}" src="@/assets/grades/arrowCircleDown.svg" alt="">
                                </div>
                                <div class="grade-list-dropdown" v-if="gradeListVisible">
                                    <ul>
                                        <li v-if="selectedGrade" class="noHover back">
                                            <div class="back-btn" @click.stop="getJobAssignedGradesList($route.query.jobID,true)">
                                                <img src="@/assets/grades/back.svg" alt="">
                                                <span>back</span>
                                            </div>
                                        </li>
                                        <li :class="{'selected': selectedGradePart === grade.value}" @click.stop="selectGrade(grade.value,grade.type)" v-for="(grade,ind) in gradesList" :key="ind">
                                            <span>
                                                {{grade.title}}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="calculated-grade-details" >
                                <div class="loading" v-if="loadingGradeCalculationData">
                                    <img src="@/assets/gifs/pallete.svg" alt="">
                                </div>
                                <div class="calculated-badge" v-if="calculatedGrade.length > 0 && !loadingGradeCalculationData">
                                    <div class="badge-img">
                                        <img :src="`https://intranet.upgaming.dev/api/grading/GetGradeBadgeNameIcon?GradeID=${calculatedGrade[0].GradeID}`" alt="">
                                    </div>
                                    <div class="details">
                                        <h2>
                                            {{
                                                calculatedGrade[0].GradeDescription
                                            }}
                                        </h2>
                                        <span>
                                            {{
                                                calculatedGrade[0].BadgeName
                                            }}
                                        </span>
                                    </div>
                                    <div class="additional">
                                        <h2>
                                            Average Score : {{
                                                calculatedGrade[0].AvgScore.toFixed(1)
                                            }}
                                        </h2>
                                        <span>
                                            Level: {{
                                                calculatedGrade[0].GradePartDescription
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="common-comment-btn" @click="(openCommentChat(0,0,true),commonChatMode = true)">
                                <img class="common-comment-btn" src="@/assets/grades/ratingOverall/comment.svg" alt="">
                            </div>
                        </div>
                        <div class="criterias-wrapper">
                            <ul>
                                <li v-show="filterCriteria(criteria.CriteriaID)" :class="{'collapsed':criteria.collapsed}" v-for="(criteria,ind) in personCriterias" :key="ind">
                                    <div class="main-content">
                                        <div class="left">
                                            <div class="total-score">
                                                <span>
                                                    {{criteria.ScoringRangeMax}}
                                                </span>
                                            </div>
                                            <div class="criteria-title">
                                                <span>
                                                    {{criteria.CriteriaDescription ? criteria.CriteriaDescription : '-'}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="right">
                                            <div v-if="!$route.query.createGrading" :class="['comment-message','comment-chat-btn',{'active':criteria.HasUnseenMessage}]"  @click="(openCommentChat(criteria.gradeHistoryDetailID,criteria.CriteriaDescription,false,criteria.CriteriaID),commonChatMode = false)">
                                                <img class="comment-chat-btn" src="@/assets/grades/ratingOverall/comment.svg" alt="">
                                            </div>
                                            <div class="rating-stars">
                                                <star-rating
                                                    @update:rating="(rateValue) => (criteria.totalRateValue = rateValue,selectGrade(selectedGrade))"
                                                    :rating="criteria.totalRateValue"
                                                    :starSize="22"
                                                    :round-start-rating="false"
                                                    :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                                                    :border-width="0"
                                                    :active-color="['#FFCB33','#FFCB33']"
                                                    :clearable="true"
                                                    active-on-click
                                                    animate
                                                    :padding="5"
                                                    :increment='0.5'
                                                    :max-rating="10"
                                                    :readOnly="false"
                                                    :tooltipText="tooltipText"
                                                    @hover:rating="(value) => hoveredStar(value,criteria.Descriptions,criteria.starTooltipVisible,criteria)"
                                                    :withTooltip="tooltipText && tooltipText.length > 0 && tooltipText !== ''"
                                                >
                                                </star-rating>

                                            </div>
                                            <div class="dropdown-arrow" @click="getCriteriaDescriptions(criteria.CriteriaID)">
                                                <img src="@/assets/grades/ratingOverall/dropdown.svg" alt="">
                                            </div>

                                        </div>
                                    </div>
                                    <transition name="fade">
                                        <div class="content-details" v-if="criteria.collapsed">
                                            <div class="criteria-descriptions">
                                                <div class="single-description" v-for="(desc,ind) in criteria.Descriptions" :key="ind">
                                                    <div class="score-range">
                                                        <div class="rating-info" v-if="desc.CriteriaDescriptionID !== rateBarVisibleID">
                                                            <span>
                                                                {{desc.CriteriaDescriptionMinScore +' - '+ desc.CriteriaDescriptionMaxScore }}
                                                            </span>
                                                            <div class="rate-btn" @click="collapseRatingBar(desc.CriteriaDescriptionID)">
                                                                <transition name="fade">
                                                                    <img v-if="!visibilityBycurrentRatingRange(desc.CriteriaDescriptionMinScore,desc.CriteriaDescriptionMaxScore,criteria.totalRateValue)" src="@/assets/grades/ratingOverall/star.svg" alt="">
                                                                    <img v-if="visibilityBycurrentRatingRange(desc.CriteriaDescriptionMinScore,desc.CriteriaDescriptionMaxScore,criteria.totalRateValue)" src="@/assets/grades/ratingOverall/starfill.svg" alt="">
                                                                </transition>
                                                            </div>
                                                        </div>
                                                        <div class="rating-range rating-range-bar" v-if="desc.CriteriaDescriptionID === rateBarVisibleID" >
                                                            <slide-bar
                                                            v-model="criteria.totalRateValue"
                                                            :min="desc.CriteriaDescriptionMinScore"
                                                            :max="desc.CriteriaDescriptionMaxScore"
                                                            :data="generateRateBarData(desc.CriteriaDescriptionMinScore,desc.CriteriaDescriptionMaxScore)"
                                                            :range="generateRateBarRanges(desc.CriteriaDescriptionMinScore,desc.CriteriaDescriptionMaxScore)"
                                                            :lineHeight="rateBar.lineHeight"
                                                            @dragEnd="(selectGrade(selectedGrade),slidebarDrag(criteria.CriteriaID))"
                                                            :showTooltip="true"
                                                            :isDisabled="false"
                                                            >
                                                            </slide-bar>

                                                            <div class="hide" @click="rateBarVisibleID = null">
                                                                <img src="@/assets/grades/arrowCircle.svg" alt="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="description">
                                                        <p>
                                                            {{desc.CriteriaDescriptionDescription}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <!-- <div class="comment-wrapper">
                                                    <custom-text-area
                                                        :title="'Comment...'"
                                                        v-model="criteria.message"
                                                    />
                                                </div> -->
                                            </div>                                     
                                        </div>
                                    </transition>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div class="right-side chart-placeholder">
                    <div class="additional-top">
                        <div class="title">
                            <span>
                                Engagement by Criterias
                            </span>
                        </div>
                    </div>
                    <div class="additional-middle">
                        <radar-chart
                            v-if="chartData.datasets.length > 0"
                            :chartData="chartData"
                            :options="chartOptions"
                            :triggerChartUpdate="triggerChartRender"
                        />
                        <div class="no-records chart" style="top: 40%" v-if="chartData.datasets.length < 1 && !loading">
                            <span>Rate employee  to <br> see engagement by critearias</span>
                        </div>
                    </div>
                    <div class="additional-bottom">
                        <div :class="['bottom-actions',{'disabled':loading}]">
                            <div v-if="checkActionStatus(true,false)" class="save" @click.stop="ratePersonByCriteria(false,false)">
                                <span>Save</span>
                            </div>
                            <div v-if="checkActionStatus(false,true)" class="send" @click="ratePersonByCriteria(true,true)">
                                <span>Send</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import axios from "@/Helpers/AxiosInstance.js";
import CustomSelect from '@/components/ReusableComponents/CustomSelect/CustomSelect.vue'
import StarRating from '@/components/ReusableComponents/HalfStarRating/star-rating.vue'
import SlideBar from '@/components/Grades/GradeComponents/SlideBar/SlideBar.vue'
// import CustomTextArea from '@/components/ReusableComponents/CustomTextArea/CustomTextArea.vue'
import CustomLoader from '@/components/ReusableComponents/Loader/CustomLoader.vue'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import {getDateString} from '@/Helpers/HelperFunctions.js'
import Score from '@/components/Grades/classes/GradingScore.js'
import RadarChart from "@/components/ReusableComponents/Charts/RadarChart.js";
import Comment from '@/components/Grades/GradeComponents/Comment/Comment.vue'
import InputSearch from '@/components/ReusableComponents/InputSearch/InputSearch.vue'
import PerformanceReport from '@/components/Grades/GradeComponents/PerformanceReport/PerformanceReport.vue'


export default {
    components:{
        CustomSelect,
        StarRating,
        SlideBar,
        // CustomTextArea,
        CustomLoader,
        CustomSnackbar,
        RadarChart,
        Comment,
        InputSearch,
        PerformanceReport,
    },
    data(){
        return{
            snackbar:{
                text:'',
                purpose: '',
                visible: false,
            },
            filters:[
                {
                    title: 'Person',
                    options: [], 
                    selected: '',
                    typeName : 'person'
                },
                {
                    title: 'Criteria',
                    options: [],
                    selected: null,
                    typeName : 'criteria'
                },
                {
                    title: 'Search',
                    value: '',
                    typeName : 'search'
                }
            ],
            personCriteriaRatingValue: null,
            personJobID: null,
            ratingValue: null,
            rateSliderVisible: false,
            rateBar: {
                value: null,
                data: [],
                range: [],
                lineHeight: 3,
            },
            personCriterias:[],
            rateBarVisibleID: null,
            collapsedCriteriaID: null,
            tooltipText: null,
            tooltipVisible: false,
            loading: false,
            gradeListVisible: false,
            selectedGrade: null,
            selectedGradePart: null,
            gradesList:[],
            alternateText: false,
            selectedGradeDataNotAvailable: false,
            loadingGradeCalculationData: false,
            compareDropdownVisible: false,
            personsListSearchValue: '',
            gradedPersonsList: [],

            calculatedGrade: [],
            personDetails: {},


            triggerChartRender: false,
            chartOptions: {
                title: {
                    display: true,
                    text: 'Person Rating Analytics'
                },
                scale: {
                    ticks: {
                        beginAtZero: true,
                        showLabelBackdrop: false
                    },
                    pointLabels: {
                        callback: (label) => {
                            return label.length > 10 ? label.substr(0, 10) + '...' : label;
                        },
                    },
                },
                pointLabels:{
                    callbacks: {
                        label: function(label) {
                            //
                        },
                    }
                },
                legend: {
                    labels: {
                        usePointStyle: true,
                        boxWidth: 6,
                    },
                },        
            },
            chartData:{
                datasets:[],
                labels: [],
            },
            commentChatVisible: false,
            overViewDataObject: {},
            commentMessage: {
                messageValue: null
            },
            historyDetailID: null,
            commentMessages: [],
            messagesLoading: false,
            scrollBottomMode: false,
            lastGrade: null,
            personGradingStatus: null,
            commonChatMode:false,

            lastHistoryback: false,
            lastHistoryForwards: false,
            personHistorysQuery: [],
            reportVisible: false,
        }
    },
    async created(){
        await this.getJobAssignedGradesList(this.$route.query.jobID,false)
        document.addEventListener('click',this.clickhandler)
        this.personJobID = this.$route.query.jobID
        await this.getPersonDetails()
        await this.getGradedPersonsList()
        if(this.$route.query.createGrading){
            await this.getJobCriterias(this.personJobID)
        }else{
            this.getPersonGradingHistory(this.$route.query.gradeHistoryID)
        }

    },
    methods:{
        hoveredStar(val,descriptions,tooltipVisible,criteria){
            if(criteria.collapsed || this.$route.query.createGrading) return
            const criteriaTotalScore = criteria.totalRateValue
            const isFloat = (n) => Number(n) === n && n % 1 !== 0;
            const inRange = (i,min,max) => ((i-min) * (i-max) <= 0)
            let currentObj = {}


            if(isFloat(val) && isFloat(criteriaTotalScore) && Number(val === Number(criteriaTotalScore))
                ){
                descriptions.forEach(el => {
                    if(inRange(val,el.CriteriaDescriptionMinScore,el.CriteriaDescriptionMaxScore)){
                        currentObj = el
                    }
                })
            }else{
                descriptions.forEach(el => {
                    if(inRange(val,el.CriteriaDescriptionMinScore,el.CriteriaDescriptionMaxScore) && Number(val) === Number(criteria.totalRateValue)){
                        currentObj = el
                    }
                })
            }
            this.tooltipText = currentObj.CriteriaDescriptionDescription
        },
        clickhandler($event){
            if($event.path.includes(this.$refs.selectGrade) || $event.path.includes(this.$refs.compareDropdown) || $event.path.includes(this.$refs.compareWithBtn)) return
            else{
                this.compareDropdownVisible = false
                this.gradeListVisible = false  
            } 
        },
        snackbarMessage(text,purpose){
            this.snackbar.visible = true
            this.snackbar.text = text
            this.snackbar.purpose = purpose
        },
        slidebarDrag(criteriaID){
            this.personCriteriaRatingValue = this.ratingValue
        },
        async getPersonDetails(){
            this.loading = true
            await axios.get('grading/GetPersonDetailsForGradingHistory',{
                params:{
                    PersonID: this.$route.params.id,
                    GradingHistoryID: this.$route.query.createGrading ? null : parseInt(this.$route.query.gradeHistoryID) === 0 ? null : this.$route.query.gradeHistoryID
                }
            }).then(resp => {
                this.loading = false
                let personData = resp.data.Value
                this.personDetails = personData
                this.overViewData()
                if(personData.GradingHistories){
                    personData.GradingHistories.forEach((el,ind,arr) => {
                        this.$set(el,'order',ind)
                    })
                    this.personHistorysQuery = personData.GradingHistories ? personData.GradingHistories : []

                    if(this.personHistorysQuery.length > 0){
                        // lastHistoryback lastHistoryForwards
                        if(parseInt(this.$route.query.gradeHistoryID) === this.personHistorysQuery[0].GradingHistoryID){
                            this.lastHistoryback = true
                        }
                        if(parseInt(this.$route.query.gradeHistoryID) === this.personHistorysQuery[this.personHistorysQuery.length - 1].GradingHistoryID){
                            this.lastHistoryForwards = true
                        }
                    }
                }
                this.selectedGrade = personData.GradeID ? personData.GradeID : null
                if(this.selectedGrade && this.selectedGrade !== null){
                    this.selectGrade(this.selectedGrade,'parent')
                }
            }).catch(err => {
                this.loading = false
            })
        },
        async getGradedPersonsList(){
            await axios.get('grading/GetGradedPersons')
            .then(resp => {
                let personList = resp.data.Value
                personList.forEach(el => {
                    el['title'] = el.FirstName + " " + el.LastName
                    el['value'] = el.PersonID
                })
                this.filters.find(el => el.typeName === 'person').options = personList
                this.gradedPersonsList = personList

                // this.filters.find(el => el.typeName ==='person').selected = this.gradedPersonsList.find(el => el.PersonID === parseInt(this.$route.params.id)).value
            })
        },
        personScoreRanges(){
            let personScoresArr = []
            for (let i = 1; i <= 10; i++){
                personScoresArr.push(new Score(i).scoreObj(true))
            }
            return personScoresArr
        },
        personCurrentScoreColor(score){
            return  `background:${new Score(score).currentScoreColor()}`
        },
        recalculateAveragePersonScore(score){
            if(this.isInt(score)) return score
            else{
                if(score.toString().split('.')[1] === '5') return score
                else{
                    if(parseInt(score.toString().split('.')[1]) > 5){
                        return Math.ceil(score)
                    }else{
                        return Math.round(score)
                    }
                }
            }
        },
        async getJobAssignedGradesList(jobID,backtriggered){
            if(backtriggered){
                this.selectedGrade = null
                this.selectedGradePart = null
            }
            await axios.get('grading/GetJobGrades',{
                params:{
                    JobID: jobID,
                }
            }).then(resp => {
                let gradesList = resp.data.Value
                this.gradesList = gradesList.map(el => ({
                    title: el.GradeDescription,
                    value: el.GradeID,
                    type: 'parent',
                }))
            })
        },
        getPersonAvatar(personID,currentPerson){
            let id = null
            if(!currentPerson){
                id = this.$route.params.id
            }else{
                id = personID
            }
            let isProd = location.href.indexOf('.upgaming.com')>0;
            let reqSrc
            if(isProd){
                reqSrc = 'https://intranet.upgaming.com/api/Person/GetPersonAvatar2?ID='
            }else{
                reqSrc = 'https://intranet.upgaming.dev/api/Person/GetPersonAvatar2?ID='
            }
            return reqSrc + id
        },
        async getJobCriterias(id){
            this.loading = true
            await axios.get('grading/GetJobGradeCriterias',{
                params:{
                    JobID: id,
                }
            }).then(resp => {
                this.loading = false
                let data = resp.data.Value
                data.forEach(el => {
                    this.$set(el,'collapsed',false)
                    this.$set(el,'starTooltipVisible',false)
                    this.$set(el,'totalRateValue',0)
                })
                if(data.length === 0 || data.length < 1) this.alternateText = true
                else this.alternateText = false
                this.personCriterias = [...new Map(data.map(e=> [e['CriteriaID'], e])).values()]
            })
            .catch(err => {
                this.loading = false
            })
        },
        convetToDateString(timeStamp,format){
            return getDateString(timeStamp,format)
        },
        calculateTimeDiff(end,start){
                let months;
                months = (start.getFullYear() - end.getFullYear()) * 12
                months -= end.getMonth()
                months += start.getMonth()
                const diffTime = Math.abs(start - end);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                return months <= 0 ? diffDays + ' days' : months + ' months'
        },
        getCriteriaDescriptions(id){
            if(this.$route.query.createGrading){
                this.ratingRangeVisibilities = []
                axios.get('grading/GetGradePartCriteriaDescriptions',{
                    params:{
                        CriteriaID: id ? id : null
                    }
                })
                .then(resp => {
                    let descriptions = resp.data.Value
                    descriptions.forEach(el => {
                        this.$set(el,'ratingBarVisible',true)
                        this.ratingRangeVisibilities.push({
                            visible: false,
                            id: el.CriteriaDescriptionID
                        })
                    })
                    this.personCriterias.filter(el => el.CriteriaID === id)[0]['Descriptions'] = descriptions
    
                    this.personCriterias.forEach(el => {
                        if(el.CriteriaID === id){
                            el.collapsed = !el.collapsed
                            el.starTooltipVisible = false
                        }  
                        else {
                            el.collapsed = false
                            this.rateBarVisibleID = null
                        }
                    })
                })
            }else{
                // history mode 
                this.personCriterias.forEach(el => {
                    if(el.CriteriaID === id) {
                        el.collapsed = !el.collapsed
                        el.starTooltipVisible = false
                    }
                    else {
                        el.collapsed = false 
                        this.rateBarVisibleID = null
                    }
                })
            }

        },
        // checkSelectedGradePartAvailable(){
        //     if(this.selectedGrade || this.selectedGradePart){
        //         let gradings = []

        //         this.personCriterias.forEach(el => {
        //             let obj = {
        //                 criteriaID: el.CriteriaID,
        //                 score: el.totalRateValue
        //             }
        //             gradings.push(obj)
        //         })

        //         this.loadingGradeCalculationData = true
        //         axios.post('grading/GetGradingHistoryAvgScoresAccordingGradePart',{
        //             gradePartID: this.selectedGradePart,
        //             personJobID: parseInt(this.$route.query.personJobID),
        //             gradings: gradings,
        //         }).then(resp => {
        //             this.loadingGradeCalculationData = false
        //             this.calculatedGrade = resp.data.Value
        //         })
        //         .catch(err => {
        //             this.loadingGradeCalculationData = false
        //         })
        //     }
        // },
        getPersonGradingHistory(id,criteriaID){
            this.loading = true
            axios.get('grading/GetPersonGradingHistoryDetails',{
                params:{
                    PersonGradingHistoryID: id || null,
                }
            }).then(resp => {
                this.loading = false
                let historyData = resp.data.Value.GradingHistoryWithDescriptions
                let notRatedCriterias = resp.data.Value.NotGradedCriterias
                let criteriaArr = []
                let mappedCriteriaData = historyData.map(el => ({
                    title: el.CriteriaDescription,
                    value: el.CriteriaID,
                }))
                this.filters.find(el => el.typeName === 'criteria').options = [...new Map(mappedCriteriaData.map(e=> [e['value'], e])).values()]
                
                historyData.forEach((el,ind) => {
                    criteriaArr.push({
                        CriteriaDescription: el.CriteriaDescription,
                        CriteriaID : el.CriteriaID,
                        ScoringRangeMax: el.ScoringRangeMax,
                        collapsed: false,
                        totalRateValue: el.Score,
                        gradeHistoryDetailID: el.GradingHistoryDetailID,
                        Descriptions:[
                            // {
                            //     CriteriaDescriptionDescription: el.CriteriaDescriptionDescription,
                            //     CriteriaDescriptionID: el.CriteriaDescriptionID,
                            //     CriteriaDescriptionMaxScore: el.DescriptionMaxScore,
                            //     CriteriaDescriptionMinScore: el.DescriptionMinScore,
                            //     CriteriaID: el.CriteriaID,
                            //     ratingBarVisible: false,
                            // }
                        ],
                        HasUnseenMessage: el.HasUnseenMessage
                    })

                })
                this.lastGrade = historyData.every(el => el.IsLastGrade)
                this.personGradingStatus = historyData.every(el => el.Status)
                criteriaArr.forEach(el => {
                    this.$set(el,'message','')
                    historyData.find(h => h.CriteriaID === el.CriteriaID).NotChosenDescriptions.forEach(desc => {
                        el.Descriptions.push({
                            CriteriaDescriptionDescription: desc.CriteriaDescriptionDescription,
                            CriteriaDescriptionID: desc.CriteriaDescriptionID,
                            CriteriaDescriptionMaxScore: desc.DescriptionMaxScore,
                            CriteriaDescriptionMinScore: desc.DescriptionMinScore,
                            CriteriaID: desc.CriteriaID,
                            ratingBarVisible: false,
                        })
                        el.descriptions = el.Descriptions.sort((a,b) => b.CriteriaDescriptionMaxScore - a.CriteriaDescriptionMaxScore)
                    })

                })


                // criteriaArr.Descriptions = criteriaArr.Descriptions.sort((a,b) => b.CriteriaDescriptionMaxScore - a.CriteriaDescriptionMaxScore)
                // let assignedGradePart = [...new Map(historyData.map(el => el.GradePartID).map(e=> [e, e])).values()]
                // this.selectedGrade = assignedGradePart ?  assignedGradePart[0] : null
                this.personCriterias = [...new Map(criteriaArr.map(e=> [e['CriteriaID'], e])).values()]
                this.personCriterias.forEach(el => el['starTooltipVisible'] = false)
                // this.checkSelectedGradePartAvailable()
                let chartColor = this.getRandomColor()
                resp.data.Value.Chart.dataSets.forEach(el => {
                    el['backgroundColor'] = chartColor
                    el['borderColor'] = chartColor
                    el['pointBackgroundColor'] = chartColor
                    el['pointBorderColor'] = chartColor
                    el['pointHoverBackgroundColor'] = chartColor
                    el['pointHoverBorderColor'] = chartColor
                })

                this.triggerChartRender = !this.triggerChartRender
                this.chartData.datasets = resp.data.Value.Chart.dataSets
                this.chartData.labels = resp.data.Value.Chart.labels

            }).catch(err => {
                this.loading = false
            })
        },
        getRandomColor() {
            var letters = '0123456789ABCDEF'.split('');
            var color = '#';
            for (var i = 0; i < 6; i++ ) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`;
            return setOpacity(color,.4);
        },
        collapseRatingBar(desc){
            this.rateBarVisibleID = desc
        },
        range(start,stop,step = 0.5){
            return Array(Math.ceil(((stop + 0.5) - start) / step)).fill(start).map((x, y) => x + y * step)
        },
        isInt(n) {
            return n % 1 === 0;
        },
        generateRateBarData(min,max){
            return this.range(min,max)
        },
        generateRateBarRanges(min,max){
            let rangeArray = this.range(min,max)
            let rangeLabels = []

            rangeArray.forEach(el => {
                let obj = {
                    label: el.toString(),
                    isHide: !this.isInt(el),
                    type: this.isInt(el) ? 'int' : !this.isInt(el) ? 'float' : undefined,
                }
                rangeLabels.push(obj)
            })
            return rangeLabels
        },
        ratePersonByCriteria(status,send){
            if(!this.selectedGradePart || this.selectedGradePart === null){
                this.selectedGradeDataNotAvailable = true
                return
            }else{
                this.selectedGradeDataAvailable = false
                let ratedCriteria = {
                    personJobID: parseInt(this.$route.query.personJobID) || null,
                    status: status,
                    gradings:[],
                    gradePartID: this.selectedGradePart ? this.selectedGradePart : null,
                }
    
                this.personCriterias.forEach(el => {
                    if(el.totalRateValue !== 0 && el.totalRateValue !== null && el.totalRateValue){
                        ratedCriteria.gradings.push({
                            criteriaID: el.CriteriaID,
                            score: el.totalRateValue,
                        })
                    }
                })
    
                if(this.$route.query.graded && !send){
                    let newScores = []
    
                    this.personCriterias.forEach(el => {
                        newScores.push({
                            gradingHistoryDetailID: el.gradeHistoryDetailID,
                            score: el.totalRateValue,
                        })
                    })
                    axios.post('grading/UpdatePersonsGradingHistories',{
                        gradePartID: this.selectedGrade,
                        newScores: newScores,
                    })
                    .then(resp => {
                        if(!resp.data.ErrorMsg){
                            this.snackbarMessage('Grading History Updated Successfully','success')
                            this.getPersonGradingHistory(this.$route.query.gradeHistoryID)
                            this.getPersonDetails()
                            this.selectedGradePart = null
                        }else{
                            this.snackbarMessage(resp.data.ErrorMsg,'error')
                        }
                    })
                }
                if(!this.$route.query.graded){
                    axios.post('grading/CreatePersonGradingHistory',ratedCriteria)
                    .then(resp => {
                        if(!resp.data.ErrorMsg){
                            this.snackbarMessage('Request Saved Successfully','success')
                            let gradingHistoryID = resp.data.Value[0].GradingHistoryID
                            let JobID = this.$route.query.jobID
                            let personJobID = this.$route.query.personJobID
                            let graded = gradingHistoryID ? true : false
                            this.$router.replace({query: {jobID: JobID,personJobID: personJobID,gradeHistoryID:gradingHistoryID,graded:graded}})
                        }else{
                            this.snackbarMessage(resp.data.ErrorMsg,'error')
                        }
                    })
                }
                if(this.$route.query.graded && send){
                    let newScores = []
    
                    this.personCriterias.forEach(el => {
                        newScores.push({
                            gradingHistoryDetailID: el.gradeHistoryDetailID,
                            score: el.totalRateValue,
                        })
                    })
                    axios.post('grading/UpdatePersonsGradingHistories',{
                        gradePartID: this.selectedGrade,
                        newScores: newScores,
                    })
                    .then(resp => {
                        if(!resp.data.ErrorMsg){
                            axios.post('grading/UpdatePersonGradingHistoryStatus',{
                                personGradingHistoryID: parseInt(this.$route.query.gradeHistoryID) ? parseInt(this.$route.query.gradeHistoryID) : null,
                                status: status,
                            }).then(resp => {
                                if(!resp.data.ErrorMsg){
                                    this.snackbarMessage('Request Saved Successfully','success')
                                    this.getPersonGradingHistory(this.$route.query.gradeHistoryID)
                                    this.getPersonDetails()
                                }else{
                                    this.snackbarMessage(resp.data.ErrorMsg,'error')
                                }
                            })
                        }else{
                            this.snackbarMessage(resp.data.ErrorMsg,'error')
                        }
                    })
                }
            }
        },
        filterCriteria(id){
            let visible = true
            let selectedCriteria = this.filters.find(el => el.typeName === 'criteria').selected
            if(selectedCriteria === id){
                visible = true
            }else{
                visible = false
            }
            if(selectedCriteria === null || selectedCriteria === '') visible = true
            return visible
        },
        visibilityBycurrentRatingRange(min,max,rateValue){
            let visible = false
            let range = this.range(min,max)
            if(range.includes(rateValue)) visible = true
            else visible = false
            return visible
        },
        async getGradeParts(id){
            await axios.get('grading/GetGradeParts',{
                params: {
                    GradeID: id,
                }
            }).then(resp => {
                let gradeParts = resp.data.Value
                this.gradesList = gradeParts.map(el => ({
                    title: el.Description,
                    value: el.GradePartID,
                    type: 'child'
                }))
                if(this.selectedGrade && this.selectedGradePart === null && this.personDetails.GradePartID){
                    this.selectedGradePart = this.personDetails.GradePartID
                    // this.selectGrade(this.selectedGradePart,'child')
                }
            })
        },
        selectGrade(gradeID,type){
            if(type === 'parent'){
                this.selectedGrade = gradeID
                this.getGradeParts(gradeID)
                return
            }
            if(type === 'child'){
                this.selectedGradePart = gradeID
            }
            this.selectedGradeDataNotAvailable = false
            if(this.selectedGrade === gradeID){
                this.gradeListVisible = false
            }
            let gradings = []
            this.personCriterias.forEach(el => {
                let obj = {
                    criteriaID: el.CriteriaID,
                    score: el.totalRateValue
                }
                gradings.push(obj)
            })
            this.loadingGradeCalculationData = true
            axios.post('grading/GetGradingHistoryAvgScoresAccordingGradePart',{
                gradePartID: this.selectedGradePart,
                personJobID: parseInt(this.$route.query.personJobID),
                gradings: gradings,
            }).then(resp => {
                this.loadingGradeCalculationData = false
                this.calculatedGrade = resp.data.Value
            })
            .catch(err => {
                this.loadingGradeCalculationData = false
            })
        },
        getBadge(id){
            let isProd = location.href.indexOf('.upgaming.com')>0;
            let reqSrc
            if(isProd){
                reqSrc = 'https://intranet.upgaming.com/api/grading/GetGradeBadgeNameIcon?GradeID='
            }else{
                reqSrc = 'https://intranet.upgaming.dev/api/grading/GetGradeBadgeNameIcon?GradeID='
            }

            return reqSrc + id
        },
        openCommentChat(detailID,criteriaName,commonChat,criteriaID){
            if(!commonChat){
                this.historyDetailID = detailID
                this.getHistoryMessages(detailID,true)
                this.overViewDataObject.personSecondaryTitle = criteriaName
                this.commentChatVisible = true
                this.personCriterias.find(el => el.CriteriaID === criteriaID).HasUnseenMessage = false
            }else{
                this.historyDetailID = this.$route.query.personJobID
                this.overViewDataObject['personSecondaryTitle'] = this.personDetails.JobName
                this.overViewDataObject['personTitle'] = this.personDetails.FirstName + ' ' + this.personDetails.LastName
                this.commentChatVisible = true
                this.getHistoryMessages(null,true,true)
            }
        },
        messageAsSeen(msgID,el){
            axios.post('grading/MakePersonGradingHistoryDetailMessageSeen',{
                PersonGradingHistoryDetailID: msgID,
            }).then(resp => {
                if(!resp.data.ErrorMsg){
                    el.Seen = true
                }else{
                    return
                }
            })
        },
        getHistoryMessages(id,bottomScroll,commonChat){
            this.$nextTick(() => {
                this.messagesLoading = true
                if(bottomScroll){
                    this.scrollBottomMode = true
                }else{
                    this.scrollBottomMode = false
                }
                if(!commonChat){
                    axios.get('grading/GetPersonGradingHistoryDetailMessages',{
                        params:{
                            PersonGradingHistoryDetailID: id ? id : null,
                        }
                    }).then(resp => {
                        this.messagesLoading = false
                        let messages = resp.data.Value
                        messages.forEach(el => {
                            let sendDate = getDateString(el.CreateTime,'dd/MMM/yyyy HH:mm')
                            this.$set(el,'sendDate',sendDate)
                            this.$set(el,'toolbarVisible',false)
                        })
                        this.commentMessages = messages
    
                        messages.forEach(el => {
                            if(!el.Seen){
                                this.messageAsSeen(id,el)
                            }
                        })
                    })
                    .catch(err => {
                        this.messagesLoading = false
                    })
                }else{
                    axios.get('Person/GetPersonJobComments',{
                        params:{
                            PersonJobID: parseInt(this.$route.query.personJobID) ? parseInt(this.$route.query.personJobID) : null
                        }
                    }).then(resp => {
                        this.messagesLoading = false
                        let commonPersonMessages = resp.data.Value
                        commonPersonMessages = commonPersonMessages.map(el => ({
                            CreateTime: el.CreateTime,
                            Message: el.Comment,
                            MessageID :el.CommentID,
                            PersonID: el.AuthorID,
                            SenderFirstName: el.AuthorFirstName,
                            SenderLastName: el.AuthorLastName,
                            toolbarVisible: false,

                        }))
                        commonPersonMessages.forEach(el => {
                            let sendDate = getDateString(el.CreateTime,'dd/MMM/yyyy HH:mm')
                            this.$set(el,'sendDate',sendDate)
                            this.$set(el,'toolbarVisible',false)
                        })

                        this.commentMessages = commonPersonMessages

                    })
                    .catch(err => {
                        this.messagesLoading = false
                    })
                }
            })
        },
        closeCommentChat(){
            this.commentChatVisible = false
        },
        overViewData(){
            let overviewData = {
                personTitle: `${this.personDetails.FirstName} ${this.personDetails.LastName}`,
                personSecondaryTitle: ''
            }

            this.overViewDataObject = overviewData
        },
        sendMessage(id){
            if(!this.commonChatMode){
                this.messagesLoading = true
                axios.post('grading/CreatePersonGradingHistoryDetailMessage',{
                    personGradingHistoryDetailID: id,
                    message: this.commentMessage.messageValue,
                }).then(resp => {
                    this.messagesLoading = false
                    if(!resp.data.ErrorMsg){
                        this.getHistoryMessages(id,true,false)
                        this.commentMessage.messageValue = null
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                }).catch(err => {
                    this.messagesLoading = false
                })
            }else{
                this.messagesLoading = true
                axios.post('Person/CreatePersonJobComment',{
                    personJobID: this.$route.query.personJobID,
                    comment: this.commentMessage.messageValue,
                }).then(resp => {
                    this.messagesLoading = false
                    this.commentMessage.messageValue = null
                    this.getHistoryMessages(null,true,true)
                }).catch(err => {
                    this.messagesLoading = false
                })
            }
        },
        openCommentChatToolbar(msg){
            this.commentMessages.forEach(el => {
                if(el.MessageID === msg.MessageID){
                    el.toolbarVisible = !el.toolbarVisible
                }else{
                    el.toolbarVisible = false
                }
            })
        },
        deleteMsg(id,detailID){
            if(!this.commonChatMode){
                axios.post('grading/UpdatePersonGradingHistoryDetailMessage',{
                    delete:true,
                    // message: '',
                    messageID: id,
                }).then(resp => {
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Comment Deleted','success')
                        this.getHistoryMessages(detailID,false)
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                })
            }else{
                axios.post('Person/UpdatePersonJobComment',{
                    delete: true,
                    commentID: id,
                }).then(resp => {
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Comment Deleted','success')
                        this.getHistoryMessages(null,false,true)
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                })
            }
        },
        checkActionStatus(save,send){
            let active = null
            if(save && this.lastGrade){
                active = false
            }
            if(send && this.lastGrade){
                active = true
            }
            if((save || send) && !this.lastGrade && !this.personGradingStatus){
                active = true
            }
            if((save || send) && !this.lastGrade && this.personGradingStatus){
                active = false
            }
            return active
        },
        hightlightResult($event){
            let val = $event.target.value.trim()

            let domEl = ['span','div','p']

            if(val === '' || val === null || !val){
                for(let i of domEl){
                    document.querySelectorAll(`${i}`).forEach(el => {
                        el.style.color = null
                    })
                }                
            }else{
                let searchString = val.toLowerCase().split(' ').join('').trim()
                for(let i of domEl){
                    document.querySelectorAll(`.content-wrapper ${i}`).forEach(el => {
                        let elText = el.innerText.toLowerCase().split(' ').join('').trim() 
                        if(elText.includes(searchString)){
                            el.style.color = '#FFCB33'
                        }else{
                            el.style.color = null
                        }
                    })
                }
            }
        },
        getHistoryFromQuery(back,forward){
            // if(this.personGradingStatus)
            let getQueryParam = (param) => {
                return this.$route.query[param]
            }
            let currentRouteObj = {
                jobID: getQueryParam('jobID'),
                personJobID: getQueryParam('personJobID'),
                gradeHistoryID: getQueryParam('gradeHistoryID'),
                graded: getQueryParam('graded'),
            }
            const currentHistoryID = getQueryParam('gradeHistoryID')
            let currentHistoryDetails = this.personGradingStatus ? this.personHistorysQuery.find(el => el.GradingHistoryID === parseInt(currentHistoryID)).order : this.personHistorysQuery.length

            const changeRoute = (id) => {
                this.$router.replace({query:
                    {
                        jobID: currentRouteObj.jobID,
                        personJobID: currentRouteObj.personJobID,
                        gradeHistoryID:id,
                        graded:currentRouteObj.graded,
                    }
                })
            }
            if(back && parseInt(currentHistoryID) !== this.personHistorysQuery[0].GradingHistoryID){
                let moveTo = this.personHistorysQuery[currentHistoryDetails - 1]
                changeRoute(moveTo.GradingHistoryID)
            }
            if(forward && parseInt(currentHistoryID) !== this.personHistorysQuery[this.personHistorysQuery.length - 1].GradingHistoryID){
                let moveTo = this.personHistorysQuery[currentHistoryDetails + 1]
                changeRoute(moveTo.GradingHistoryID)
            }
        },
        changePerson(personID){
            if(personID){
                let personHistoryData = this.filters.find(el => el.typeName === 'person').options
                let selectedPerson = personHistoryData.find(el => el.PersonID === personID)
    
                const routerObj = {
                    jobID: selectedPerson.JobID,
                    personJobID: selectedPerson.PersonJobID,
                    gradeHistoryID: selectedPerson.GradingHistoryID,
                    graded: true,
                }
                this.$router.replace({params:{id: selectedPerson.PersonID},query:routerObj})
                
            }else return
        },
        filterPersonsList(name,searchString){
            let visible = false
            if(
                name.toLowerCase().split(' ').join('').trim().includes(searchString.toLowerCase().split(' ').join('').trim())
            ) visible = true
            else visible = false
            return visible
        },
        selectComparablePerson(person){
            console.log(person);
            if(person && person.GradingHistoryID){
                let personsForCompare = [
                    this.$route.query.gradeHistoryID,
                    person.GradingHistoryID,
                ]
                this.$router.push({
                    path: '/Grades/OverallCompare',
                    query: {
                        gradingHistoryIDs: personsForCompare
                    }
                })
            }else{
                this.snackbarMessage(`${person.title} Has No Grading History`,'warning')
            }
        },
    }
}
</script>

<style lang="scss" scoped>
*{
    font-family: Roboto,firaGo;
}

.no-records{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    pointer-events: none;
    user-select: none;
    span{
        font-size: 1.5625rem;
        color: rgba($grayBlue, .8);
        white-space: nowrap;
    }

    &.chart{
        text-align: center;
        span{
            font-size: 1.5625rem;
        }
    }
}

.fade-enter-active {
    transition: opacity .5s;
}

.fade-leave-active{
    transition: .2s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
    transform: translateY(0.625rem);
}

ul{
    list-style: none;
}

.background{
    overflow: hidden;
    background: rgba(#ededfc,.6);
}

.disabled{
    pointer-events: none;
    opacity: .8;
}

.content-wrapper{
    width: 100%;
    height: 100%;
    .top-header{
        width: calc(100% - 1.875rem);
        margin: auto;
        background: #ffffff;
        border-radius: 1.25rem;
        height: 5.9375rem;
        margin-top: 1.1875rem;
        @include flex(space-between,center,row);
        padding: 0 1.875rem;
        box-shadow: 0 0 1.25rem rgba($grayBlue, .16);
        position: relative;

        .back{
            cursor: pointer;
            position: absolute;
            top: 0.5rem;
            left: 1.1875rem;
            @include flex(flex-start,center,row);
            user-select: none;
            img{
                width: 1.1875rem;
            }
            span{
                color: #ADB8CC;
                font-size: 0.8125rem;
                margin-left: 0.3125rem;
            }

        }

        .left-side{
            span{
                color: #7D8FB3;
                font-size: 0.9375rem;
                font-weight: 600;
            }
        }

        .header-filters{
            ul{
                @include flex(flex-start,center,row);
                li{
                    width: 13.75rem;
                    height: 2.5rem;
                    margin-left: 0.9375rem;
                    &:first-child{
                        margin-left: 0;
                    }

                    ::v-deep{
                        .input-search,label{
                            width: 100% !important;
                        }
                    }

                    .search{
                        width: 13.75rem;
                        height: 40px;
                        position: relative;
                        @include centerElement;

                        img{
                            width: 1.1rem ;
                            position: absolute;
                            top: 50%;
                            transform: translate(0,-50%);
                            left: 0.9375rem;
                        }

                        input{
                            width: 100%;
                            height: 100%;
                            outline: none;
                            border: none;
                            padding-left: 2.6875rem;
                            color: $grayBlue;
                            font-size: 14px;
                            transition: ease .3s;
                            border-radius: 0.375rem;
                            border: 0.0625rem solid rgba(#959BC9,.4);
                            
                            &::placeholder{
                                color: rgba($grayBlue, .6);
                            }

                            &:focus{
                                box-shadow: 0rem 0rem 0.9375rem rgba($grayBlue, .16);
                            }
                            
                        }
                    }
                }
            }
        }

        .right-side{
            .actions-wrapper{
                @include flex(flex-start,center,row);
                div{
                    border-radius: 1.875rem !important;
                    width: 10rem !important;
                    height: 2.5rem;
                    cursor: pointer;
                    span{
                        font-weight: 600;
                    }
                }
                .performance-report{
                    background: #ffffff;
                    border: 0.0625rem solid $purple;
                    @include centerElement;
                    font-weight: 500;
                    transition: ease .2s;
                    &:active{
                        background: $purple;
                        span{
                            color: #ffffff;
                        }
                    }

                    span{
                        color: $purple;
                        font-size: 0.75rem;
                    }
                }
                .compare-with{
                    margin-left: 0.9375rem;
                    position: relative;
                    .wrapper{
                        @include purpleBtn;
                        @include flex(center,center,row);
                        img{
                            width: 1.25rem;
                        }

                        span{
                            padding-left: 0.625rem;
                            font-size: 12px;
                        }
                    }

                    .persons-dropdown{
                        width: 15rem !important;
                        height: auto;
                        overflow: auto;
                        position: absolute;
                        top: calc(100% + 0.625rem);
                        right: 0;
                        z-index: 1;
                        background: #ffffff;
                        box-shadow: 0 0 1.25rem rgba($grayBlue, .3);
                        border-radius: 0.375rem;
                        padding-bottom: 0.3125rem;
                        border-radius: 7px !important;

                        ul{
                            width: 100%;
                            max-height: 26.5rem !important;
                            min-height: 5rem !important;
                            .search-persons-list{
                                width: 100%;
                                padding: 0.625rem 0.625rem;
                                position: sticky;
                                top: 0;
                                z-index: 1;
                                background: #ffffff;
                                input{
                                    width: 100%;
                                    outline: none;
                                    border: 0.0625rem solid rgba($grayBlue, .4);
                                    border-radius: 0.125rem;
                                    height: 2.2rem;
                                    background: transparent;
                                    padding: 0 0.4375rem;
                                    font-size: 0.875rem;
                                    color: #6B7A99;
                                }                                
                            }

                            li:not(.no-hover){
                                cursor: pointer;
                                width: 100%;
                                padding: 0.625rem 0.625rem;
                                @include flex(flex-start,center,row);
                                transition: ease .2s;
                                span{
                                    color: #6B7A99;
                                    font-size: 0.9375rem;
                                    margin-left: 0.9375rem;
                                    font-weight: 500;
                                }
                                .person-avatar{
                                    width: 1.25rem !important;
                                    height: 1.25rem !important;
                                    background-size: cover;
                                    border-radius: 50%;
                                }
                                &:hover{
                                    background: rgba($purple,.9);
                                    span{
                                        color: #ffffff;
                                    }
                                }   
                            }
                        }

                    }
                }
            }
        }
    }

    
    .middle{
        width: calc(100% - 1.875rem);
        height: 100%;
        margin: auto;
        margin-top: 1.25rem;
        @include flex(space-between,flex-start,row);

        .details-content,.chart-placeholder{
            background: #ffffff;
            box-shadow: 0 0 1.25rem rgba($grayBlue, .16);
            border-radius: 0.625rem;
            position: relative;
        }

        .details-content{
            width: 70.625rem;
            height: calc(100% - 9.375rem);
            padding: 1.5625rem 1.875rem;
            overflow: hidden;
            .details-header{
                width: 100%;
                @include flex(space-between,center,row);
                transition: ease .4s;

                .left-side{
                    @include flex(flex-start,flex-start,row);

                    .person-avatar{
                        width: 5.4375rem;
                        height: 5.4375rem;
                        border-radius: 50%;
                        overflow: hidden;
                        background-size: cover;
                        @include centerElement;
                        img{
                            width: 100%;
                        }
                    }

                    .person-info{
                        margin-left: 0.9375rem;
                        ul{
                            @include flex(flex-start,flex-start,column);
                            li{
                                margin-top: 0.625rem;
                                &.name-surname{
                                    position: relative;
                                    @include flex(flex-start,center,row);
                                    span{
                                        font-size: 0.875rem;
                                        font-weight: 600;
                                        color: #6B7A99;
                                    }

                                }
                                &.job{
                                    margin-top: 0.1875rem;
                                    @include flex(flex-start,center,row);
                                    span{
                                        font-size: 0.75rem;
                                        color: #97A1C2;
                                        font-weight: 500;
                                    }

                                    .badge{
                                        @include flex(flex-start,center,row);
                                        margin-left: 1.875rem;
                                        img{
                                            margin-left: 0.625rem;
                                            width: 1.5625rem;
                                            pointer-events: none;
                                            user-select: none;
                                        }

                                        span{
                                            font-size: 0.8125rem;
                                            color: #4D5E80;
                                            font-weight: 600;
                                        }
                                    }
                                }

                                .salary{
                                    .salary-title{
                                        color: #7D8FB3;
                                        font-size: 0.8125rem;
                                        font-weight: 500;
                                        margin-left: 0.9375rem;

                                        &:first-child{
                                            margin-left: 0;
                                        }

                                        & > span{
                                            font-size: 0.875rem;
                                            font-weight: 600;
                                            color: #4D5E80;
                                            padding-left: 0.3125rem;
                                        }
                                    }
                                }

                                &:first-child{
                                    margin-top: 0;
                                }

                                .rating-lines{
                                    @include flex(flex-start,center,row);
                                    .single-line{
                                        width: 1.875rem;
                                        height: 0.375rem;
                                        border-radius: 1.875rem;
                                        background: #CCD6F6;
                                        margin-left: 0.3125rem;

                                        &:first-child{
                                            margin-left: 0;
                                        }
                                        &.blank{
                                            background: #CCD6F6 !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .right-side{
                    ul{
                        @include flex(flex-end,right,column);
                        text-align: right;
                        li{
                            text-align: left;
                            &:first-child{
                                @include flex(flex-start,center,row);
                                margin-top: 0;
                                
                                span{
                                    color: #4D5E80;
                                    font-size: 0.8125rem;
                                    font-weight: 600;
                                }
                                .period-actions{
                                    margin-left: 0.9375rem;
                                    @include flex(flex-start,center,row);
                                    user-select: none;
                                    &.alldisabled{
                                        opacity: .8;
                                        pointer-events: none;
                                    }
                                    img{
                                        width: 1.4375rem;
                                    }
                                    .backward{
                                        cursor: pointer;
                                        &.disabled{
                                            pointer-events: none;
                                            opacity: .5;
                                        }
                                        img{
                                            transform: rotate(90deg) !important;
                                        }
                                    }
                                    .forward{
                                        margin-left: 0.1875rem;
                                        &.disabled{
                                            pointer-events: none;
                                            opacity: .5;
                                        }
                                        cursor: pointer;
                                        img{
                                            transform: rotate(-90deg) !important;
                                        }
                                    }
                                }
                            }
                            margin-top: 0.3125rem;
                            .title{
                                font-weight: 500;
                                color: #97A1C2;
                                font-size: 0.8125rem;
                                & > span{
                                    padding-left: 0.3125rem;
                                    font-weight: 600;
                                    color: #4D5E80;
                                }
                            }
                        }
                    }
                }
            }
            .details-bottom{
                width: 100%;
                height: calc(100% - 9rem);
                margin-top: 3rem;
                
                .bottom-title{
                    width: 100%;
                    @include flex(flex-start,center,row);
                    .title{
                        h2{
                            color: #6B7A99;
                            font-size: 0.7813rem;
                        }
                    }

                    .common-comment-btn{
                        margin-left: 1rem;
                        cursor: pointer;
                        img{
                            margin-left: 0 !important;
                            width: 1.4375rem;
                        }
                    }

                    .select-grade{
                        margin-left: 1.25rem;
                        position: relative;
                        .selected-grade-title{
                            padding: 0 0.9375rem;
                            width: 9rem;
                            @include flex(space-between,center,row);
                            height: 2.5rem;
                            background: #EDEDFC;
                            border-radius: 0.3125rem;
                            cursor: pointer;
                            transition: ease .3s;
                            &.error{
                                border: 0.0625rem solid rgba(#fe224a,.6);
                                span{
                                    color: rgba(#fe224a,.9);
                                }
                                box-shadow: 0 0 1.25rem rgba(#fe224a,.1);
                                img{
                                    filter: invert(25%) sepia(51%) saturate(5097%) hue-rotate(336deg) brightness(101%) contrast(103%);
                                }
                            }
                            &.wide{
                                width: 16rem;
                            }
                            span{
                                color: #6B7A99;
                                font-size: 0.75rem;
                                font-weight: 500;
                                width: 100%;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }
                            img{
                                width: 1.0625rem;
                                margin-left: 0.625rem;
                            }
                        }

                        .grade-list-dropdown{
                            position: absolute;
                            left: 0;
                            top: 100%;
                            width: 100%;
                            background: #ffffff;
                            box-shadow: 0 0 10px #EDEDFC;
                            z-index: 9;
                            border-bottom-left-radius: 0.3125rem;
                            border-bottom-right-radius: 0.3125rem;

                            ul{
                                max-height: 25rem;
                                overflow: auto;
                                li{
                                    cursor: pointer;
                                    padding: 0.5rem 0.9375rem;
                                    transition: ease .2s;

                                    &.back{
                                        .back-btn{
                                            @include flex(flex-start,center,row);
                                            img{
                                                width: 1.125rem;
                                            }
                                            span{
                                                font-size: 0.875rem;
                                                color: rgba(#4D5E80,.7);
                                                font-weight: 500;
                                                margin-left: 0.3125rem;
                                            }
                                        }
                                    }

                                    &.selected{
                                        background: #6B7A99;
                                        span{
                                            color: #fff;
                                        }                                        
                                    }
                                    &:hover:not(.noHover){
                                        background: #6B7A99;
                                        span{
                                            color: #fff;
                                        }
                                    }
                                    span{
                                        font-size: 0.9375rem;
                                        color: #6B7A99;
                                    }
                                }
                            }
                        }
                    }

                    .calculated-grade-details{
                        height: 100%;
                        margin-left: 1.5625rem;
                        @include flex(flex-start,center,row);
                        position: relative;
                        .loading{
                            position: absolute;
                            left: 0;
                            opacity: .6;
                            pointer-events: none;
                            img{
                                width: 4.375rem;
                            }
                        }
                        .calculated-badge{
                            @include flex(flex-start,center,row);

                            .badge-img{
                                img{
                                    width: 1.5rem;
                                }
                            }
                            .details{
                                margin-left: 0.9375rem;
                                h2{
                                    font-size: 0.875rem;
                                    color: #4D5E80;
                                }
                                span{
                                    color: #7D8FB3;
                                    font-size: 0.8125rem;
                                    font-weight: 500;
                                }
                            }

                            .additional{
                                margin-left: 0.9375rem;
                                h2{
                                    font-size: 0.875rem;
                                    color: #4D5E80;
                                    margin-bottom: 0.125rem;
                                }
                                span{
                                    font-size: 0.8125rem;
                                    color: #7D8FB3;
                                    font-weight: 500;
                                    
                                }

                            }
                        }
                    }
                }

                .criterias-wrapper{
                    width: 100%;
                    margin-top: 1.5625rem;
                    overflow: auto;
                    height: 100%;
                    padding-bottom: 4.625rem;
                    
                    ul{
                        width: 100%;
                        @include flex(flex-start,left,column);

                        li{
                            width: 100%;
                            background: #ffffff;
                            &:first-child{
                                margin-top: 0;
                            }
                            margin-top: 0.5rem;

                            &.collapsed{
                                height: unset;
                                .main-content{
                                    background: $purple;

                                    .total-score,.criteria-title{
                                        span{
                                            color: #ffffff !important; 
                                        }
                                    }
                                }
                            }

                            .main-content{
                                width: 100%;
                                height: 2.7rem;
                                background: rgba(#EDEDFC,.8);
                                @include flex(space-between,center,row);
                                border-radius: 0.375rem;
                                padding: 0 0.9375rem;

                                .left,.right{
                                    @include flex(flex-start,center,row);
                                }

                                .left{
                                    .total-score{
                                        span{
                                            font-size: 0.8125rem;
                                            font-weight: 700;
                                            color: #7D8FB3;
                                        }
                                    }

                                    .criteria-title{
                                        margin-left: 0.9375rem;
                                        span{
                                            font-size: 0.875rem;
                                            color: #7D8FB3;
                                            font-weight: 500;
                                        }
                                    }
                                }

                                .right{
                                    .comment-message{
                                        margin-right: 1.875rem;
                                        user-select: none;
                                        img{
                                            width: 1.375rem;
                                            cursor: pointer;
                                        }
                                        &.active{
                                            position: relative;
                                            &::after{
                                                content: '';
                                                position: absolute;
                                                top: -0.125rem;
                                                right: -0.125rem;
                                                width: 0.5rem;
                                                height: 0.5rem;
                                                background: #fe224a;
                                                border-radius: 50%;
                                            }
                                        }
                                    }
                                    .dropdown-arrow{
                                        cursor: pointer;
                                        @include centerElement;
                                        padding-left: 1.5625rem;
                                        img{
                                            transition: ease .2s;
                                            user-select: none;
                                            width: 0.75rem;

                                            &.collapsed{
                                                transform: rotate(180deg);
                                            }
                                        }
                                    }
                                }
                            }

                            .content-details{
                                width: 100%;
                                background: #ffffff;
                                padding: 0 2.1875rem;
                                margin-top: 0.9375rem;
                                padding-bottom: 1rem;

                                .criteria-descriptions{
                                    width: 100%;
                                    @include flex(flex-start,left,column);


                                    .single-description{
                                        margin-top: 0.9375rem;
                                        &:first-child{
                                            margin-top: 0;
                                        }
                                        .score-range{
                                            width: 100%;
                                            @include flex(flex-start,center,row);
                                            position: relative;
                                            .rating-info{
                                                @include flex(flex-start,center,row);
                                                span{
                                                    color: #97A1C2;
                                                    font-size: 0.875rem;
                                                    font-weight: 600;
                                                }
                                                .rate-btn{
                                                    margin-left: 0.375rem;
                                                    cursor: pointer;
                                                    img{
                                                        width: 1.1875rem;
                                                    }
                                                }
                                            }

                                            .rating-range{
                                                position:relative;

                                                .hide{
                                                    cursor: pointer;
                                                    position: absolute;
                                                    right: -4.5rem;
                                                    top: 40%;
                                                    transform: translate(0,-50%) rotate(90deg);
                                                    transition: ease .2s;
                                                    img{
                                                        user-select: none;
                                                        pointer-events: none;
                                                        width: 1.5rem;
                                                    }
                                                }
                                            }
                                        }

                                        .description{
                                            margin-top: 0.5rem;
                                            p{
                                                font-size: 0.875rem;
                                                color:#7D8FB3;
                                                line-height: 1.25rem;
                                                font-weight: 500;
                                            }
                                        }
                                    }

                                    .comment-wrapper{
                                        width: 100%;
                                        margin-top: 1.5625rem;
                                        position: relative;

                                        ::v-deep{
                                            
                                            .title{
                                                color: rgba(#4D5E80,.8) !important;
                                            }

                                            textarea{
                                                width: 100%;
                                                height: 3rem !important;
                                                resize: none;
                                                border: 0.0625rem solid transparent;
                                                color: #4D5E80 !important;
                                                outline: none;
                                                transition: ease .3s;
                                                border-radius: 0.4375rem;
                                                overflow: hidden;


    
                                                &:focus{
                                                    overflow: auto;
                                                    height: 5rem !important;
                                                    border: 0.0625rem solid rgba(#959BC9,.4);
                                                    box-shadow: 0 0 1.25rem rgba(#959BC9,.1);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .chart-placeholder{
            width: calc(100% - 72.1875rem);
            height: calc(100% - 9.375rem);

            @include flex(flex-start,flex-start,column);

            .additional-top{
                width: 100%;
                height: 2rem;
                .title{
                    font-size: 1.0625rem;
                    color: #97A1C2;
                    padding: 1rem 1.5625rem;
                }

            }
            .additional-middle{
                width: 100%;
                height: 26.5rem;
                margin-top: 1.875rem;
                padding: 1rem 1.5625rem;
            }

            .additional-bottom{
                width: 100%;
                height: 3.5rem;
                margin-top: 0.9375rem;
                padding: 1rem 1.5625rem;

                .bottom-actions{
                    width: 100%;
                    @include flex(flex-end,center,row);
                    padding-right: 0.9375rem;
                    .save,.send{
                        border-radius: 1.25rem;
                        width: 5.625rem;
                        height: 2.4rem;
                        @include centerElement;
                        cursor: pointer;
                        transition: ease .1s;
                        &:active{
                            background: $green-primary !important;
                            border: 0.0625rem solid $green-primary !important;
                            span{
                                color: #ffffff !important;
                            }
                        }
                        span{
                            font-size: 0.875rem;
                            font-weight: 600;
                            user-select: none;
                        }
                    }
                    .save{
                        border: 0.0625rem solid $purple;
                        margin-right: 0.9375rem;
                        span{
                            color: $purple;
                        }
                    }
                    .send{
                        background: $purple;
                        span{
                            color: #ffffff ;
                        }
                        border: 0.0625rem solid transparent;
                    }
                }
            }
        }
    }
}



</style>