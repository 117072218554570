<template>
  <div class="background">
    <div class="header">
      <div class="left-side"></div>
      <div class="right-side">
        <div class="upload-button" @click="addClickHandler">
          <span>Upload File</span>
        </div>
        <div class="search">
          <input type="text" v-model="search" placeholder="Search" />
          <img
            class="search-icon"
            src="@/assets/icons/searchAppIcon.svg"
            alt="hr"
          />
        </div>
      </div>
    </div>
    <div class="list-content" v-if="computedFileListData.length">
      <table class="list-table">
        <thead class="table-head">
          <tr class="list-table-th">
            <th>#</th>
            <th>File</th>
            <th>Purpose</th>
            <th>Factor Type</th>
            <th>Month</th>
            <th>Pay day</th>
            <th>Upload Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="table-body">
          <template v-for="fileList in computedFileListData">
            <tr class="list-table-td" :key="fileList.FileID">
              <td>{{ fileList.FileID }}</td>
              <td :title="fileList.FileName">
                <span
                  class="file-icon"
                  :type="fileList.FileName.split('.').pop()"
                  >{{ fileList.FileName.split(".").pop() }}</span
                >{{ fileList.FileName }}
              </td>
              <td :title="fileList.Purpose">{{ fileList.Purpose }}</td>
              <td :title="fileList.SalaryFactorDestinationTypeName">
                {{ fileList.SalaryFactorDestinationTypeName }}
              </td>
              <td>{{ convertMonth(fileList.Month) }} {{ fileList.PayDay }}</td>
              <td>{{ fileList.PayDay }}</td>
              <td>
                {{
                  fileList.UploadTime ? convertDate(fileList.UploadTime) : "-"
                }}
              </td>
              <td>
                <span
                  @click="downloadFile(fileList)"
                  class="material-icons-outlined"
                >
                  file_download
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-else class="no-data">
      <span>No Data</span>
    </div>
    <custom-popup
      :visible="popUpVisible"
      @close="popUpVisible = false"
      :title="'Upload File'"
    >
      <div class="popup-container">
        <div class="file-select-wrapper">
          <div class="left-side">
            <search-input
              v-model="selectedFactor"
              :options="factorOptions"
              :isRequired="true"
              :title="'Salary factor type'"
              @clear="selectedFactor = ''"
            />
            <date-picker
              class="date"
              title="Factor start month"
              :addCustom="'month'"
              :isRequired="true"
              v-model="month"
              @clear="month = ''"
            />
          </div>
          <div class="right-side">
            <label class="custom-file-input">
              <div class="upload-content">
                <span class="material-icons-outlined"> cloud_upload </span>
                <span class="upload-text">Upload file</span>
                <span class="file-name">{{ file.filename }}</span>
                <input type="file" @change="onChange" :key="fileInputKey" />
              </div>
              <!-- <img class="preview" ref="preview" alt="" /> -->
            </label>
          </div>
        </div>

        <div class="bottom">
          <textarea type="text" v-model="purpose" placeholder="Purpose ..." />
          <div @click="uploadFile" class="save-button">
            <span>Save</span>
          </div>
        </div>
      </div>
    </custom-popup>
    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
  </div>
</template>

<script>
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import { getDateString, CETToTimestamp } from "@/Helpers/HelperFunctions";

import axios from "@/Helpers/AxiosInstance.js";
import SearchInput from "@/components/ReusableComponents/SearchInput/SearchInput.vue";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue";
import { toBase64 } from "@/Helpers/HelperFunctions";

export default {
  components: {
    CustomPopup,
    CustomSnackbar,
    SearchInput,
    DatePicker,
  },
  created() {
    this.getData();
  },
  data() {
    return {
      popUpVisible: false,
      search: "",
      fileInputKey: 0,
      fileListData: [],
      selectedFactor: "",
      fileRecord: null,
      factoriesList: [],
      month: "",
      purpose: "",
      file: {},
      snackbarText: "",
      snackbarPurpose: "",
      snackbarVisible: false,
    };
  },
  methods: {
    addClickHandler() {
      this.selectedFactor = "";
      this.month = "";
      this.purpose = "";
      this.file = {};
      this.fileInputKey++;
      this.getFactories();
      this.popUpVisible = true;
    },
    getFactories() {
      axios.get("salary/GetSalaryFactorTypes").then((response) => {
        this.factoriesList = response.data.Value.FactorsWithPersons;
      });
    },
    getData() {
      axios
        .get(`/salary/GetPayRollFiles?searchTerm=${this.search}`)
        .then((resp) => {
          this.fileListData = resp.data.Value;
        });
    },
    uploadFile() {
      axios
        .post("salary/UploadPayrollFile", {
          salaryFactorTypeID: this.selectedFactor,
          date: this.month,
          purpose: this.purpose,
          name: this.file.filename,
          base64: this.file.base64,
          fileType: this.file.type,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.snackbarText = "Updated Successfully!";
            this.snackbarPurpose = "success";
            this.snackbarVisible = true;
            this.popUpVisible = false;
            this.getData();
          } else {
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
            this.snackbarPurpose = "error";
          }
        })
        .catch(() => {
          this.snackbarVisible = true;
          this.snackbarText = "Error";
          this.snackbarPurpose = "error";
        });
    },
    async onChange(e) {
      const file = e.target.files[0];

      const object = {
        size: file.size,
        type: file.type,
        filename: file.name,
        base64: await toBase64(file),
      };

      this.readURL(file);
      this.file = object;
    },
    readURL(file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
    },
    convertMonth(value) {
      if (value == 1) return "Jan";
      if (value == 2) return "Feb";
      if (value == 3) return "Mar";
      if (value == 4) return "Apr";
      if (value == 5) return "May";
      if (value == 6) return "Jun";
      if (value == 7) return "Jul";
      if (value == 8) return "Aug";
      if (value == 9) return "Sep";
      if (value == 10) return "Oct";
      if (value == 11) return "Nov";
      if (value == 12) return "Dec";
    },
    convertDate(date) {
      return getDateString(CETToTimestamp(date), "dd MMM yyyy");
    },
    downloadFile(el) {
      var a = document.createElement("a");
      a.href = `data:${el.FileType};base64,` + el.File;
      a.download = el.FileName;
      a.click();
    },
  },
  computed: {
    factorOptions() {
      return this.factoriesList.map((el) => {
        return {
          title: el.Name,
          value: el.ID,
        };
      });
    },
    computedFileListData() {
      if (!this.fileListData.length) return [];
      return this.fileListData;
    },
  },
  watch: {
    search(to, from) {
      this.search = to;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.background {
  padding: 1.5625rem 2.1875rem;
}
.no-data {
  color: rgba($blue-primary, 0.5);
  font-size: 55px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.header {
  display: flex;
  justify-content: space-between;
  .left-side {
    color: $blue-primary;
    font-size: 1.125rem;
  }
  .right-side {
    display: flex;
    align-items: center;
    .upload-button {
      padding: 0.5625rem 0.9375rem;
      cursor: pointer;
      border-radius: 0.375rem;
      font-size: 0.9375rem;
      border: 0.0625rem solid $blue-primary;
      color: $blue-primary;
    }
    .search {
      margin-left: 1.25rem;
      position: relative;
      input {
        width: 12.5rem;
        height: 2.375rem;
        background-color: #fff;
        border: 0;
        outline: none;
        border-radius: 0.375rem;
        font-weight: 300;
        font-size: 0.875rem;
        color: #9696b9;
        padding: 0 2.1875rem 0 0.9375rem;
      }
      img {
        position: absolute;
        height: 1.3125rem;
        width: 1.3125rem;
        right: 0.625rem;
        top: 0.5rem;
      }
    }
  }
}
.file-select-wrapper {
  display: flex;
  justify-content: space-between;
  .left-side {
    width: 50%;
    label {
      margin-top: 1.25rem;
    }
  }
}
.custom-file-input {
  input {
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    cursor: pointer;
  }
  .upload-content {
    width: 9.375rem;
    position: relative;
    height: 6.25rem;
    border: 0.0625rem solid #dcdde6;
    border-radius: 0.3125rem;
    padding: 3px 0 3px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .material-icons-outlined {
      font-size: 2.5rem;
      color: $blue-primary;
      opacity: 0.7;
    }
    .upload-text {
      color: $blue-primary;
      opacity: 0.8;
      font-size: 0.9375rem;
      font-weight: 300;
    }
    .file-name {
      margin-top: 0.3125rem;
      opacity: 0.7;
      font-size: 0.8125rem;
      color: $blue-primary;
      font-weight: 300;
      text-align: center;
      overflow: hidden;
    }
  }
}
::v-deep .el-date-editor.el-input {
  width: 100%;
}
::v-deep .file-select-wrapper .left-side label {
  width: 100%;
}
.popup-container {
  display: flex;
  height: calc(100% - 3.375rem);
  flex-direction: column;
  justify-content: space-between;

  .bottom {
    textarea {
      width: 100%;
      height: 3.125rem;
      border: 0.0625rem solid #dcdde6;
      border-radius: 0.375rem;
      outline: none;
      padding-left: 0.625rem;
      resize: none;
      padding-top: 0.3125rem;
      overflow: auto;
      font-size: 0.8125rem;
      color: rgba($blue-primary, 0.8);
      &::placeholder {
        color: rgba($blue-primary, 0.7);
      }
    }
    .save-button {
      margin-top: 1.875rem;
      float: right;
      padding: 0.4375rem 1.125rem;
      display: inline;
      font-size: 0.9375rem;
      font-weight: 400;
      cursor: pointer;
      border-radius: 0.3125rem;
      background: rgba(40, 53, 147, 0.6);
      color: #fff;
    }
  }
}

/* file list */
.list-content {
  .list-table {
    width: 100%;
    table-layout: fixed;
    position: relative;
    border-collapse: collapse;
    margin-top: 1.9375rem;
    .list-table-th {
      width: 100%;
      height: 2.5rem;
      th:first-child {
        width: 5%;
        -webkit-border-top-left-radius: 0.1875rem;
        -webkit-border-bottom-left-radius: 0.1875rem;
        -moz-border-radius-topleft: 0.1875rem;
        -moz-border-radius-bottomleft: 0.1875rem;
        border-top-left-radius: 0.1875rem;
        border-bottom-left-radius: 0.1875rem;
      }
      th:last-child {
        width: 5%;
        -webkit-border-top-right-radius: 0.1875rem;
        -webkit-border-bottom-right-radius: 0.1875rem;
        -moz-border-radius-topright: 0.1875rem;
        -moz-border-radius-bottomright: 0.1875rem;
        border-top-right-radius: 0.1875rem;
        border-bottom-right-radius: 0.1875rem;
      }
      th {
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        font-weight: 600;
        font-size: 12 * $rem;
        color: #ededfc;
        background-color: #6956d6;
        padding-left: 0.625rem;
      }
    }
    .table-head {
      width: 100%;
      display: table;
      table-layout: fixed;
    }
    .table-body {
      height: 100%;
      display: block;
      overflow-y: auto;
      max-height: 44.0625rem;
      tr:nth-child(odd) {
        background-color: #dfe0f4;
      }
    }
    .list-table-td {
      width: 100%;
      display: table;
      table-layout: fixed;
      td:first-child,
      td:last-child {
        width: 5%;
      }
      td {
        height: 2.5rem;
        font-size: 0.875rem;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #283593;
        padding: 0.625rem 0 0.625rem 0.625rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .material-icons-outlined {
          cursor: pointer;
        }

        .file-icon {
          width: 2.5rem;
          height: 2.5rem;
          display: inline-block;
          margin-right: 0.3125rem;
          border-radius: 0.1875rem;
          font-size: 0.75rem;
          padding-top: 0.8125rem;
          text-align: center;

          &[type="pdf"] {
            background-color: #ff6760;
            font-size: 12 * $rem;
            color: white;
            font-weight: 700;
            letter-spacing: $rem;
          }

          &[type="xlsx"] {
            background-color: rgba(17, 123, 65, 0.871);
            font-size: 12 * $rem;
            color: white;
            font-weight: 700;
          }
          &[type="PNG"],
          [type="png"] {
            background-color: $green-primary;
            font-size: 12 * $rem;
            color: white;
            font-weight: 700;
            letter-spacing: $rem;
          }
          &[type="SVG"],
          [type="svg"] {
            background-color: $green-primary;
            font-size: 12 * $rem;
            color: white;
            font-weight: 700;
            letter-spacing: $rem;
          }
          &[type="jpg"] {
            background-color: rgba(17, 123, 65, 0.871);
            font-size: 12 * $rem;
            color: white;
            font-weight: 700;
          }
          &[type="txt"] {
            background-color: #364ba2;
            font-size: 12 * $rem;
            color: white;
            font-weight: 700;
          }

          &[type="docx"],
          &[type="doc"] {
            background-color: #364ba2;
            font-size: 12 * $rem;
            color: white;
            font-weight: 700;
          }
        }
      }
    }
  }
}
/* end file list */
::v-deep .overlay .popup {
  height: 25.125rem;
  width: 43.1875rem;
}
</style>