<template>
  <div
    class="box"
    :class="{ checked: value, disabled: disabled }"
    @click="$emit('input', !value)"
  >
    <div class="checkmark" v-show="value"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    disabled: Boolean,
    customStyle: {
      default: true,
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  width: 15 * $rem;
  height: 15 * $rem;
  border-radius: 3 * $rem;
  border: $rem solid #a0a0c0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  &.alternativeStyle {
    border: $rem solid $green-primary;
  }

  &.checked {
    border-color: $blue-primary;

    .checkmark {
      border-bottom-color: $blue-primary;
      border-left-color: $blue-primary;
    }
  }

  .checkmark {
    position: relative;
    top: -1 * $rem;
    height: 4 * $rem;
    width: 8 * $rem;
    border-bottom: 1 * $rem solid rgba($blue-primary, 0.7);
    border-left: 1 * $rem solid rgba($blue-primary, 0.7);
    transform: rotate(-50deg);
  }

  &.disabled {
    border-color: rgba($blue-primary, 0.6);
    cursor: not-allowed;
    .checkmark {
      border-bottom-color: rgba($blue-primary, 0.6);
      border-left-color: rgba($blue-primary, 0.6);
    }
  }
}
</style>