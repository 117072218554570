<template>
  <div class="container" id="chart">
    <div class="title">Bonus Limits &amp; Enrollments</div>
    <div class="ess-container">
      <div class="filter-div">
        <date-range
          :addCustom="'monthrange'"
          :initialFrom="fromDate"
          :initialTo="toDate"
          @from="(from) => (fromDate = from)"
          @to="(to) => (toDate = to)"
          class="date_range"
        />
      </div>
      <div class="filter-div">
        <custom-select
          class="input"
          title="Department"
          v-model="departmentFilter"
          :options="departmentListOptions"
          @clear="departmentFilter = ''"
          :clearVisible="true"
        />
      </div>
    </div>
    <!-- end ess-container -->
    <div class="chart-container">
      <bar-chart
        v-if="loaded"
        :width="700"
        :chart-data="chartDataList"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script>
import BarChart from "@/components/ReusableComponents/Charts/BarChart.js";
import DateRange from "@/components/ReusableComponents/DateRange/DateRange";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue";
import axios from "@/Helpers/AxiosInstance.js";

export default {
  name: "Pie",
  components: {
    BarChart,
    DateRange,
    CustomSelect,
  },
  data: () => ({
    fromDate: "",
    toDate: "",
    filterfromDate: "",
    filtertoDate: "",
    departmentFilter: "",
    departmentListOptions: [],
    loaded: false,
    chartDataList: null,
    chartOptions: {
      scales: {
        yAxes: [
          {
            stacked: true,
          },
        ],
        xAxes: [
          {
            stacked: true,
          },
        ],
      },
      legend: {
        display: true,
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  }),
  mounted() {
    this.fillData();
    this.getDepartments();
  },
  methods: {
    async fillData() {
      this.loaded = false;
      try {
        axios
          .get(
            `salary/GetDepartmentsBonusLimitsUsageForChart?DepartmentID=${this.departmentFilter}&FromDate=${this.filterfromDate}&ToDate=${this.filtertoDate}`
          )
          .then((resp) => {
            this.chartDataList = {
              labels: resp.data.Value.Months,
              datasets: [
                {
                  label: resp.data.Value.Datasets[0].Name,
                  backgroundColor: resp.data.Value.Datasets[0].Color,
                  data: resp.data.Value.Datasets[0].Amounts,
                },
                {
                  label: resp.data.Value.Datasets[1].Name,
                  backgroundColor: resp.data.Value.Datasets[1].Color,
                  data: resp.data.Value.Datasets[1].Amounts,
                },
              ],
            };
            this.loaded = true;
          });
      } catch (e) {
        console.error(e);
      }
    },
    getDepartments() {
      axios.get(`departments/GetDepartments`).then((resp) => {
        this.departmentListOptions = resp.data.Value.map((el) => ({
          title: el.Name,
          value: el.ID,
        }));
      });
    },
  },
  watch: {
    fromDate() {
      this.filterfromDate = new Date(this.fromDate)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      this.filtertoDate = new Date(this.toDate)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      this.fillData();
    },
    departmentFilter(to, from) {
      this.departmentFilter = to;
      this.fillData();
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-container {
  background-color: #ffffff;
  border-radius: 2px;
  padding: 20px;
}
#chart {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 3.75rem;
  padding: 0 1.25rem;
}
.title {
  // display: flex;
  font-size: 1.125rem;
  color: $blue-primary;
}
.ess-container {
  display: flex;
  justify-content: end;
  .filter-div {
    width: 12.938rem;
    margin: 0 1.275rem 0.0625rem 0;
    margin-bottom: 0.9375rem;
    margin-top: 1.875rem;
  }
}
</style>