<template>
  <form class="profile-fields" @submit.prevent="saveClickHandler" ref="form">
    <div class="info" v-if="!personID || showInfoFields">
      <profile-field
        type="text"
        :required="true"
        label="Name"
        placeholder="Name..."
        :editable="true"
        v-model="fields.firstName"
        :show-public-toggle="!!personID && personID == $store.state.profile.ID"
        :public-toggle-settings="{ disabled: true, value: true }"
      />
      <profile-field
        type="text"
        :required="true"
        label="Surname"
        placeholder="Surname..."
        :editable="true"
        v-model="fields.lastName"
        :show-public-toggle="!!personID && personID == $store.state.profile.ID"
        :public-toggle-settings="{ disabled: true, value: true }"
      />

      <profile-field
        type="text"
        :required="false"
        label="Name Geo"
        placeholder="Name Geo..."
        :editable="true"
        v-model="fields.firstNameGeo"
        :show-public-toggle="!!personID && personID == $store.state.profile.ID"
        :public-toggle-settings="{ disabled: true, value: true }"
      />
      <profile-field
        type="text"
        :required="false"
        label="Surname Geo"
        placeholder="Surname Geo..."
        :editable="true"
        v-model="fields.lastNameGeo"
        :show-public-toggle="!!personID && personID == $store.state.profile.ID"
        :public-toggle-settings="{ disabled: true, value: true }"
      />

      <profile-field
        type="text"
        label="Domain Name"
        placeholder="Domain Name"
        :editable="true"
        v-model="fields.domainName"
        :required="true"
        :read-only="!canEditAdditionalFields && !isManagerNotTbilisi"
      />
      <profile-field
        type="tel"
        label="Per. Num."
        placeholder="Number..."
        :editable="true"
        :disabled="!canEditAdditionalFields"
        :read-only="!canEditAdditionalFields"
        :isError="persNumberError"
        errorText="Incorect format"
        v-model="fields.personalNumber"
      />

      <!-- <profile-field
        type="birthDate"
        label="Birth Date"
        :editable="true"
        @change="birthDateChangeHandler"
        :value="fields.birthDate"
        :show-public-toggle="!!personID && personID == $store.state.profile.ID"
        :public-toggle-settings="{
          disabled: false,
          value: fields.birthDateVisibility,
        }"
        @change-public-status="
          fields.birthDateVisibility = !fields.birthDateVisibility
        "
      /> -->
      <profile-field
        type="date"
        class="field-date"
        label="Birth Date"
        placeholder="Date of Birth"
        :editable="true"
        v-model="fields.birthDate"
        :show-public-toggle="!!personID && personID == $store.state.profile.ID"
        :public-toggle-settings="{
          disabled: false,
          value: fields.birthDateVisibility,
        }"
        @change-public-status="
          fields.birthDateVisibility = !fields.birthDateVisibility
        "
        :required="false"
      />
      <profile-field
        type="select"
        label="Gender"
        placeholder="Choose"
        :editable="true"
        v-model="fields.gender"
        :selectOptions="[
          { value: '1', title: 'Male' },
          { value: '0', title: 'Female' },
        ]"
      />
      <profile-field
        type="text"
        label="Mob. 1"
        placeholder="Phone..."
        :editable="true"
        v-model="fields.phone1"
        :isError="phone1Error"
        errorText="Incorrect format"
        :show-public-toggle="!!personID && personID == $store.state.profile.ID"
        :public-toggle-settings="{
          disabled: false,
          value: fields.phoneNumOneVisibility,
        }"
        @change-public-status="
          fields.phoneNumOneVisibility = !fields.phoneNumOneVisibility
        "
      />
      <profile-field
        type="text"
        label="Mob. 2"
        placeholder="Phone..."
        :editable="true"
        v-model="fields.phone2"
        :isError="phone2Error"
        errorText="Incorrect format"
        :show-public-toggle="!!personID && personID == $store.state.profile.ID"
        :public-toggle-settings="{
          disabled: false,
          value: fields.phoneNumTwoVisibility,
        }"
        @change-public-status="
          fields.phoneNumTwoVisibility = !fields.phoneNumTwoVisibility
        "
      />
      <profile-field
        type="text"
        label="Phone (Family Member)"
        placeholder="Phone (Relation) ..."
        :editable="true"
        v-model="relativesPhoneNumber"
        :isError="phone3Error"
        errorText="Incorrect format"
      />
      <profile-field
        type="email"
        label="E-mail 1"
        placeholder="Email"
        :editable="true"
        :read-only="!canEditAdditionalFields && !isManagerNotTbilisi"
        v-model="fields.email1"
        :isError="email1Error"
        errorText="Incorrect format"
        :required="true"
        :show-public-toggle="!!personID && personID == $store.state.profile.ID"
        :public-toggle-settings="{ disabled: true, value: true }"
      />
      <profile-field
        type="email"
        label="E-mail 2"
        placeholder="Email"
        :editable="true"
        v-model="fields.email2"
        :isError="email2Error"
        errorText="Incorrect format"
        :show-public-toggle="!!personID && personID == $store.state.profile.ID"
        :public-toggle-settings="{
          disabled: false,
          value: fields.mailTwoVisibility,
        }"
        @change-public-status="
          fields.mailTwoVisibility = !fields.mailTwoVisibility
        "
      />
      <profile-field
        type="text"
        label="Skype"
        placeholder="Username..."
        :editable="true"
        :required="true"
        v-model="fields.skype"
        :show-public-toggle="!!personID && personID == $store.state.profile.ID"
        :public-toggle-settings="{
          disabled: true,
          value: true,
        }"
        @change-public-status="fields.skypeVisibility = !fields.skypeVisibility"
      />
      <profile-field
        type="text"
        label="Address"
        placeholder="Number, Street, addr, city"
        :editable="true"
        v-model="fields.address"
      />
      <!-- <profile-field
        type="text"
        label="IBAN"
        placeholder="IBAN"
        :editable="true"
        v-model="fields.iban"
      />
      <profile-field
        type="text"
        label="Bank Code"
        placeholder="Code"
        :editable="true"
        v-model="fields.bankCode"
      /> -->
    </div>
    <div class="additional">
      <div class="row">
        <div
          class="input-wrapper"
          :class="{
            disabled: !canEditAdditionalFields && !isManagerNotTbilisi,
          }"
        >
          <div class="additional-title">
            <span>Job *</span>
            <img src="@/assets/icons/icon_arrow_down.svg" alt="" />
          </div>
          <span
            :title="fields.job ? getJobTitle(fields.job) : ''"
            class="input-search-container"
          >
            <input-search
              title=""
              :placeholder="fields.job ? getJobTitle(fields.job) : 'Select job'"
              :options="jobOptions"
              :disabled="isEditMode"
              @select="
                (id) => {
                  employee = id
                  fields.job = id
                }
              "
            />
          </span>
        </div>

        <profile-field
          type="date"
          label="Job Start Date"
          placeholder="Pick date"
          :editable="true"
          v-model="fields.startDate"
          :required="true"
          :read-only="!canEditAdditionalFields && !isManagerNotTbilisi"
        />

        <profile-field
          type="select"
          label="Status"
          placeholder="Select"
          :editable="true"
          v-model="fields.status"
          :selectOptions="statusOptions"
          :read-only="!canEditAdditionalFields && !isManagerNotTbilisi"
        />
      </div>
      <div class="row">
        <profile-field
          type="select"
          label="Office"
          placeholder="Select"
          :editable="true"
          v-model="fields.office"
          :selectOptions="officeOptions"
          :required="!personID"
          :read-only="!canEditAdditionalFields && !isManagerNotTbilisi"
        />
        <profile-field
          v-if="!$store.getters.hasRole('HR')"
          type="select"
          label="Role"
          :required="true"
          placeholder="Select"
          :editable="true"
          v-model="fields.role"
          :selectOptions="!isManagerNotTbilisi ? roleOptions : notTbilisiRoles"
          :read-only="
            (!canEditAdditionalFields && !isManagerNotTbilisi) ||
            ($store.getters.hasRole('HR') &&
              personID == $store.state.profile.ID)
          "
        />

        <profile-field
          v-if="$store.getters.hasRole('HR')"
          type="select"
          label="Role"
          :required="true"
          placeholder="Select"
          :editable="true"
          v-model="fields.role"
          :selectOptions="
            !isManagerNotTbilisi ? HRroleOptions : notTbilisiRoles
          "
          :read-only="
            (!canEditAdditionalFields && !isManagerNotTbilisi) ||
            ($store.getters.hasRole('HR') &&
              personID == $store.state.profile.ID)
          "
        />
      </div>
    </div>
    <div>
      <button class="save-button">Save</button>
    </div>
  </form>
</template>

<script>
import ProfileField from './ProfileField'
import axios from '@/Helpers/AxiosInstance'
import InputSearch from '@/components/ReusableComponents/InputSearch/InputSearch.vue'

export default {
  components: {
    ProfileField,
    InputSearch,
  },
  props: {
    personID: Number,
    triggerUpdate: Boolean,
  },
  created() {
    if (this.personID) this.getProfileDetails()
    Promise.all([
      this.getOffices(),
      this.getRoles(),
      this.getJobs(),
      this.getStatuses(),
    ])
  },
  data() {
    return {
      fields: {
        firstName: '',
        lastName: '',
        firstNameGeo: '',
        lastNameGeo: '',
        personalNumber: '',
        birthDate: '',
        phone1: '',
        phone2: '',
        phone3: '',
        relation: '',
        email1: '',
        email2: '',
        skype: '',
        address: '',
        gender: '',
        office: '',
        role: '',
        domainName: '',
        job: '',
        startDate: '',
        iban: '',
        bankCode: '',
        status: '',
        birthDateVisibility: false,
        phoneNumOneVisibility: false,
        phoneNumTwoVisibility: false,
        mailTwoVisibility: false,
        skypeVisibility: false,
      },
      relativesPhoneNumber: '',
      officeOptions: [],
      roleOptions: [],
      HRroleOptions: [],
      jobs: [],
      statusOptions: [],
      notTbilisiRoles: [],
      showInfoFields: false,
    }
  },
  methods: {
    getProfileDetails() {
      axios
        .get('Person/GetPersonsDetails', {
          params: { Person: this.personID },
        })
        .then((resp) => {
          if (resp.data.ErrorMsg) return
          this.showInfoFields = true

          var phoneNums = resp.data.Value.PersonContacts.filter(
            (el) => el.ContactTypeName == 'Phone',
          )
          var emails = resp.data.Value.PersonContacts.filter(
            (el) => el.ContactTypeName == 'Mail',
          )
          var relative = resp.data.Value.PersonContacts.filter(
            (el) => el.ContactTypeName == 'RelatedPersonPhone',
          )[0]
          var address = resp.data.Value.PersonContacts.filter(
            (el) => el.ContactTypeName == 'Address',
          )[0]
          var skype = resp.data.Value.PersonContacts.filter(
            (el) => el.ContactTypeName == 'Skype',
          )[0]

          this.fields.firstName = resp.data.Value.FirstName
          this.fields.lastName = resp.data.Value.LastName
          this.fields.firstNameGeo = resp.data.Value.Translations.length
            ? resp.data.Value.Translations.filter(
                (el) => el.LanguageID == 1 && el.ObjectTypeID == 23,
              )[0].Translation
            : null
          this.fields.lastNameGeo = resp.data.Value.Translations.length
            ? resp.data.Value.Translations.filter(
                (el) => el.LanguageID == 1 && el.ObjectTypeID == 24,
              )[0].Translation
            : null
          this.fields.birthDate = resp.data.Value.BirthDate
          this.fields.personalNumber = resp.data.Value.PersonalNumber
          if (phoneNums.length > 0) {
            this.fields.phone1 = phoneNums[0].ContactValue
            this.fields.phoneNumOneVisibility = phoneNums[0].Visibility
          }
          if (phoneNums.length > 1) {
            this.fields.phone2 = phoneNums[1].ContactValue
            this.fields.phoneNumTwoVisibility = phoneNums[1].Visibility
          }
          if (relative) {
            this.relativesPhoneNumber =
              relative.ContactValue && relative.ContactsOwner
                ? `${relative.ContactValue}(${relative.ContactsOwner})`
                : ''
          }
          if (emails.length > 0) this.fields.email1 = emails[0].ContactValue
          if (emails.length > 1) {
            this.fields.email2 = emails[1].ContactValue
            this.fields.mailTwoVisibility = emails[1].Visibility
          }
          if (address) this.fields.address = address.ContactValue
          if (skype) {
            this.fields.skype = skype.ContactValue
            this.fields.skypeVisibility = skype.Visibility
          }
          if (resp.data.Value.Gender != null)
            this.fields.gender = resp.data.Value.Gender ? '1' : '0'
          this.fields.domainName = resp.data.Value.DomainName
          if (resp.data.Value.OfficeID)
            this.fields.office = resp.data.Value.OfficeID
          if (resp.data.Value.RoleID) this.fields.role = resp.data.Value.RoleID
          if (resp.data.Value.JobID) this.fields.job = resp.data.Value.JobID
          if (resp.data.Value.Status)
            this.fields.status = resp.data.Value.Status
          this.fields.startDate = resp.data.Value.StartTime
          this.fields.iban = resp.data.Value.BankAccountIban
          this.fields.bankCode = resp.data.Value.BankAccountNum
          this.fields.birthDateVisibility = resp.data.Value.BirthDateVisibility

          var documents = resp.data.Value.PersonDocuments
          if (documents.length) this.$emit('got-documents', documents)
          //using this in parent component to call edit services properly
          this.$emit('old-values', {
            job: this.fields.job,
            office: this.fields.office,
            role: this.fields.role,
          })
        })
    },
    getJobTitle(id) {
      let title
      this.jobOptions.forEach((el) => {
        if (id == el.value) {
          title = el.title
        }
      })
      return title
    },
    saveClickHandler() {
      if (this.areErrors()) return
      this.$emit('save', this.fields)
    },
    // birthDateChangeHandler(date) {
    //   this.fields.birthDate = `${date.year}-${date.month}-${date.day}`;
    // },
    getOffices() {
      return axios.get('Company/GetOffices').then((resp) => {
        if (resp.data.ErrorMsg) return
        this.officeOptions = resp.data.Value.map((el) => ({
          value: '' + el.ID,
          title: el.Name,
        }))
      })
    },
    getRoles() {
      return axios
        .get('Permission/GetRoles', {
          params: { Page: 1, PageSize: 1000000 },
        })
        .then((resp) => {
          if (resp.data.ErrorMsg) return
          this.roleOptions = resp.data.Value.map((el) => ({
            value: '' + el.ID,
            title: el.Name,
          }))

          if (resp.data.ErrorMsg) return
          let result = []

          result = resp.data.Value.filter((el) => el.ID !== 80)
          this.HRroleOptions = result.map((el) => {
            return {
              value: '' + el.ID,
              title: el.Name,
            }
          })
          this.notTbilisiRoles = resp.data.Value.filter(
            (el) => el.ID === 70 || el.ID === 74,
          ).map((item) => {
            return {
              value: '' + item.ID,
              title: item.Name,
            }
          })
        })
    },
    getJobs() {
      return axios.get('jobs/GetJobs').then((resp) => {
        if (resp.data.ErrorMsg) return
        this.jobs = resp.data.Value.Jobs
      })
    },
    getStatuses() {
      return axios.get('Company/GetEmployeeStatuses').then((resp) => {
        if (resp.data.ErrorMsg) return
        this.statusOptions = resp.data.Value.map((el) => ({
          title: el.Name,
          value: el.ID + '',
        }))
      })
    },
    checkPhoneNumberValidity(phone) {
      const reg = /^[0-9 ]+$/
      if (
        phone.length &&
        (phone.split(' ').join('').length < 9 || !phone.match(reg))
      )
        return true
      return false
    },
    checkEmailValidity(email) {
      const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (email.length && !email.match(reg)) return true
      return false
    },
    checkPersonalNumbValidity(persNumber) {
      if (persNumber && persNumber.length && persNumber.length != 11) {
        return true
      } else {
        return false
      }
    },
    areErrors() {
      return (
        this.phone1Error ||
        this.phone2Error ||
        this.phone3Error ||
        this.email1Error ||
        this.email2Error ||
        this.persNumberError
      )
    },
  },
  computed: {
    phone1Error() {
      return this.checkPhoneNumberValidity(this.fields.phone1)
    },
    phone2Error() {
      return this.checkPhoneNumberValidity(this.fields.phone2)
    },
    phone3Error() {
      return this.checkPhoneNumberValidity(this.fields.phone3)
    },
    email1Error() {
      return this.checkEmailValidity(this.fields.email1)
    },
    email2Error() {
      return this.checkEmailValidity(this.fields.email2)
    },
    persNumberError() {
      return this.checkPersonalNumbValidity(this.fields.personalNumber)
    },
    isEditMode() {
      // console.log(this.$route.params.id);
      return this.personID ? true : false
    },

    isManagerNotTbilisi() {
      return (
        this.$store.getters.hasRole('Manager') &&
        (this.$store.state.isProduction
          ? this.$store.state.profile.OfficeID !== 3
          : this.$store.state.profile.OfficeID !== 38)
      )
    },
    canEditAdditionalFields() {
      return (
        this.$store.getters.isAdmin ||
        this.$store.getters.hasRole('HR') ||
        this.$store.getters.isSuperAdmin
      )
    },
    jobOptions() {
      return this.jobs.map((el) => ({
        value: '' + el.ID,
        title: el.Name,
      }))
    },
  },
  watch: {
    relativesPhoneNumber(number) {
      if (number.includes('(')) {
        this.fields.phone3 = number.split('(')[0]
        this.fields.relation = number.split('(')[1].split(')')[0]
      } else {
        this.fields.phone3 = number
      }
    },
    triggerUpdate() {
      this.getProfileDetails()
    },
  },
}
</script>

<style scoped lang="scss">
.profile-fields {
  margin-left: 100 * $rem;

  .info {
    display: flex;
    flex-wrap: wrap;
    margin-top: -47 * $rem;

    .field {
      width: 260 * $rem;
      margin-top: 40 * $rem;
      height: 45 * $rem;
    }

    .field[type='date'] {
      width: 175 * $rem;
    }
  }

  .additional {
    margin-top: 40 * $rem;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      background-color: #d9daf1;
      height: $rem;
      width: calc(100% - 123 * #{$rem});
    }
    .row:not(:first-of-type) {
      div:nth-child(1) {
        margin-left: 0px;
      }
      div:nth-child(2) {
        // margin-left: 37px;
      }
    }
    .row {
      display: flex;
      justify-content: flex-start;

      .field {
        width: 260 * $rem;
        margin-top: 40 * $rem;
        height: 45 * $rem;
        // margin-left: 18px;
      }

      .field[type='date'] {
        // width: 150 * $rem;
        // margin-left: 30px;

        // width:16rem ;

        ::v-deep .value {
          width: 100%;
        }
      }
    }
  }
  ::v-deep .input-search label input {
    width: 21.875rem;
  }
  .save-button {
    padding: 14 * $rem 38 * $rem;
    background-color: #8a91c7;
    color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16 * $rem;
    border-radius: 6 * $rem;
    float: right;
    margin-top: 18 * $rem;
    position: absolute;
    cursor: pointer;
    bottom: 70 * $rem;
    right: 70 * $rem;

    &:hover {
      filter: brightness(1.1);
    }
  }
}

::v-deep .field[type='date'] {
  width: 16.325rem !important;
}
.disabled {
  pointer-events: none;
}

.input-wrapper {
  position: relative;
  margin-top: 2px;

  .input-search-container {
    width: 90%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .additional-title {
    position: absolute;
    // top: 0.5rem;
    bottom: 30px;
    display: flex;
    align-items: flex-start;
    span {
      color: rgba(40, 53, 147, 0.5);
      font-weight: 300;
      font-size: 0.8125rem;
    }
    img {
      margin-top: 0.3125rem;
      width: 0.5rem;
      margin-left: 0.625rem;
    }
  }
}

::v-deep .input-search {
  width: 16.375rem;
  margin-top: 2.5rem;

  label {
    .title {
      background: transparent;
      font-weight: 400;
      color: rgba(40, 53, 147, 0.7);
      margin: 0;
    }
    input {
      width: 9.875rem;
      background: transparent;
      border: none;
      border-radius: 0;
      box-sizing: border-box;
      padding: 0;
      font-size: 13px;
      margin-top: 0.3125rem;
      &::placeholder {
        font-size: 13px;
        text-align: left;
        color: rgba(40, 53, 147, 0.7);
        position: absolute;
        left: 0;
        top: 0.9375rem;
      }
    }
  }
}
</style>
