<template>
  <transition name="fade">
    <div class="wrapper" :class="{ bottom: position == 'bottom' }">
      <div
        class="snackbar"
        :class="{
          success: purpose == 'success',
          error: purpose == 'error',
          warning: purpose === 'warning',
        }"
      >
        <div class="content">
          {{ text }}
          <div class="btn" @click="$emit('close')">CLOSE</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: "bottom",
    },
    purpose: {
      type: String,
      default: "success",
    },
    text: String,
    timeout: {
      type: Number,
      default: 4000,
    },
  },
  mounted() {
    setTimeout(() => {
      this.$emit("close");
    }, this.timeout);
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  align-items: center;
  color: #ffffff;
  display: flex;
  font-size: 14 * $rem;
  left: 8 * $rem;
  pointer-events: none;
  position: fixed;
  right: 8 * $rem;
  text-align: left;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  z-index: 1000;

  &.bottom {
    bottom: 8 * $rem;
  }

  .snackbar {
    align-items: center;
    background-color: #323232;
    border-radius: 4 * $rem;
    display: flex;
    margin: 0 auto;
    pointer-events: auto;
    transition: inherit;
    transition-property: opacity, transform;
    min-width: 40%;
    box-shadow: 0 * $rem 3 * $rem 5 * $rem -1 * $rem rgba(0, 0, 0, 0.2),
      0 * $rem 6 * $rem 10 * $rem 0 * $rem rgba(0, 0, 0, 0.14),
      0 * $rem 1 * $rem 18 * $rem 0 * $rem rgba(0, 0, 0, 0.12);

    &.success {
      background-color: #4caf50;
    }

    &.error {
      background-color: #ff5252;
    }
    &.warning {
      background-color: #ff9233;
    }

    .content {
      align-items: center;
      display: flex;
      min-height: 48 * $rem;
      justify-content: space-between;
      overflow: hidden;
      padding: 8 * $rem 16 * $rem;
      width: 100%;

      .btn {
        display: flex;
        justify-content: center;
        min-width: initial;
        width: 70 * $rem;
        background-color: rgba(255, 255, 255, 0);
        cursor: pointer;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 800px) {
  .snackbar {
    min-width: 70% !important;
  }
  .wrapper {
    &.bottom {
      bottom: 150px;
    }
  }
}
</style>