<template>
  <div class="terms-content">
    <div class="terms-header-content">
      <div class="terms-title">
        <span>Assigned terms to employees</span>
      </div>
      <div class="vacation-header-rightside">
        <div
          @click="popupVisible = true"
          class="add-button-content"
          v-if="
            $store.getters.isAdmin ||
            $store.getters.hasRole('HR') ||
            $store.getters.isSuperAdmin
          "
        >
          <img
            class="assign-icon"
            src="@/assets/icons/assign.svg"
            alt="assign"
          />
          <span>Assign days</span>
        </div>
        <div class="add-terms-content" @click="rulesPopup = true">
          <span><img src="@/assets/icons/terms.svg" alt="" /></span>
          <span>Terms</span>
        </div>
        <!-- <div class="request-vacation-button" @click="requestPopupVisible = true">
                            <span>Request Vacation</span>
                        </div> -->
        <form class="search-form">
          <input type="text" placeholder="Search Person" v-model="searchName" />
        </form>
      </div>
    </div>
    <div class="list-container">
      <vacation-terms-table
        @cancel="cancel"
        @active-edit="activeEdit"
        :data="filteredList"
        @checked="checkedEdit"
        :canEdition="true"
        @filterParam="(val) => filteredYear = val"
        :withFilters="true"
      />
    </div>

    <custom-popup
      :visible="popupVisible"
      @close="popupVisible = false"
      title="Assign Vacation Terms to Persons"
      class="assign-terms-popup"
    >
    <div class="types-content">
        <div class="years-content">
          <custom-select
            title="Select Year"
            v-model="selectedYear"
            :options="yearsOptions"
          />
          <!-- <input type="text" v-model="selectedYear" placeholder="Enter Year" /> -->
        </div>
        <div class="restrictions-content">
          <ul>
            <li class="type-list" v-for="el in vacationTypes" :key="el.index">
              <span class="index">{{ el.ID }}.</span>
              <span class="type">{{ el.Name }}</span>
            </li>
          </ul>
          <div class="inputs-content">
            <span
              ><input
                type="text"
                v-model="newType.paid"
                placeholder="Enter Days"
            /></span>
            <span
              ><input
                type="text"
                v-model="newType.notPaid"
                placeholder="Enter Days"
            /></span>
            <span
              ><input
                type="text"
                v-model="newType.dayOff"
                placeholder="Enter Days"
            /></span>
            <span
              ><input
                type="text"
                v-model="newType.sickDays"
                placeholder="Enter Days"
            /></span>
            <span
              ><input
                type="text"
                v-model="newType.maternityLeave"
                placeholder="Enter Days"
            /></span>
          </div>
        </div>
      </div>
      <div class="search-content">
        <select-persons
          v-if="popupVisible"
          :departments="departmentPeople"
          :alternativeText="alternativeText"
          :initialSelectedPersons="selectedPersons"
          @select="(people) => (selectedPersons = people)"
        />
      </div>
      <p>Selected {{ selectedPersonsData.length }}</p>
      <div class="selected-people-content">
        <selected-permission
          v-for="selected in selectedPersonsData"
          :key="selected.index"
          :name="selected.FirstName + ' ' + selected.LastName"
          @click.native="remove(selected.ID)"
        />
      </div>

      <button @click="createRestriction()">Assign</button>
    </custom-popup>
    <div class="rules-popup-content">
      <custom-popup :visible="rulesPopup" @close="rulesPopup = false">
        <div class="popup-header">
          <div class="left-side">
            <span class="title">Terms of vacation</span>
          </div>
          <div class="right-side" @click="addNewType">
            <span>+</span>
            <span>Add options for new type vacation</span>
          </div>
        </div>

        <div class="inputs-container">
          <div
            class="inputs"
            v-for="(el, index) in termsOfVacation"
            :key="index"
          >
            <div>
              <custom-select
                title="Select type"
                :options="vacationTypeOptions"
                v-model="el.typeName"
              />
              <span
                @click="deleteTermType(index, el)"
                v-if="el.deleteIcon == true"
                class="material-icons cancel"
                >close</span
              >
            </div>
            <div
              class="definition-content"
              v-for="(definition, index) in el.definitions"
              :key="index"
            >
              <input
                v-model="definition.definition"
                placeholder="Enter definition..."
                class="definition-input"
                type="text"
              />
              <textarea
                v-model="definition.textArea"
                class="text-area"
                placeholder="Type text here..."
                name=""
                id=""
                cols="30"
                rows="10"
              ></textarea>
              <span
                @click="deleteDefinition(index, el)"
                v-if="definition.deleteIcon == true"
                class="material-icons cancel"
                >close</span
              >
            </div>
            <div class="add-option-content" @click="addDefinition(el)">
              <span>+</span> <span>Add option</span>
            </div>
          </div>
          <div
            class="inputs"
            v-for="(el, index) in vacationRules"
            :key="el.Type"
          >
            <div>
              <div class="type-name">
                <span class="term-index">{{ index + 1 + "." }}</span
                >{{ el.Type }}
              </div>
              <span
                @click="deleteRulesType(index, el)"
                v-if="el.deleteIcon == true"
                class="material-icons cancel"
                >close</span
              >
            </div>
            <div
              class="definition-content"
              v-for="(definition, index) in el.Descriptions"
              :key="index"
            >
              <input
                v-model="definition.Definition"
                placeholder="Enter definition..."
                class="definition-input"
                type="text"
              />
              <textarea
                v-model="definition.Text"
                class="text-area"
                placeholder="Type text here..."
                name=""
                id=""
                cols="30"
                rows="10"
              ></textarea>
              <span
                @click="deleteRulesDefinition(index, el)"
                v-if="definition.deleteIcon == true"
                class="material-icons cancel"
                >close</span
              >
            </div>
            <div class="add-option-content" @click="addOptions(el)">
              <span>+</span> <span>Add option</span>
            </div>
          </div>
        </div>
        <button @click="createTerms">Save</button>
      </custom-popup>
    </div>

    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      @close="snackbarVisible = false"
      :text="snackbarText"
    />
  </div>
</template>

<script>
import CustomPopup from "../ReusableComponents/CustomPopup/CustomPopup.vue";
// import InputSearch from '@/components/ReusableComponents/InputSearch/InputSearch'
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue";
import SelectedPermission from "@/components/Permissions/SelectedPermission";
import { getFullName } from "@/Helpers/HelperFunctions";
import axios from "@/Helpers/AxiosInstance.js";
import SelectPersons from "../FixedSchedules/SelectPersons.vue";
import VacationTermsTable from "./VacationTermsTable.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
// import CustomEditorInline from '@/components/ReusableComponents/CustomEditorInline/CustomEditorInline.vue'

export default {
  created() {
    this.getPersons();
    // this.getJobPersons();
    this.getVacationtypes();
    this.getRestrictionsData();
    this.getYears();
    this.getVacationRules();
    // this.getDepartmentJobs();
    // this.getFixedPeople();
    this.getDropdownListByYear()
  },
  components: {
    CustomPopup,
    SelectPersons,
    VacationTermsTable,
    CustomSnackbar,
    SelectedPermission,
    CustomSelect,
    // CustomEditorInline,
  },
  data() {
    return {
      termsOfVacation: [
        {
          typeName: "",

          definitions: [
            {
              definition: "",
              textArea: "",
              deleteIcon: true,
            },
          ],
        },
      ],
      filteredYear: null,
      vacationTypes: [],
      popupVisible: false,
      searchName: "",
      years: [],
      rulesPopup: false,
      alternativeText: true,
      personOptions: null,
      snackbarText: "",
      snackbarVisible: false,
      selectedPersons: [],
      departmentPeople: [],
      vacationRules: [],
      jobs: [],
      personsWithFlexible: [],
      choosen: "",
      selectedPersonsData: [],
      restrictionsData: [],
      people: [],

      selectedYear: "",
      newType: {
        paid: "",
        notPaid: "",
        dayOff: "",
        sickDays: "",
        maternityLeave: "",
      },
    };
  },

  watch: {
    selectedPersons: function () {
      this.getSelectedPersons(this.selectedPersons, this.people);
    },
    selectedYear(){
      this.getDropdownListByYear()
    }
  },
  methods: {
    getPersons() {
      axios.get("Person/GetPersons").then((resp) => {
        this.people = resp.data.Value.Persons;
      });
    },

    getRestrictionsData() {
      axios.get("vacations/GetPersonVacationRestrictions").then((resp) => {
        this.restrictionsData = resp.data.Value.PersonRestrictions;
        this.restrictionsData.forEach((el) => {
          this.$set(el, "canEdit", false);
        });
      });
    },
    getVacationRules() {
      axios.get("vacations/GetVacationTerms").then((resp) => {
        this.vacationRules = resp.data.Value.Types;
        this.vacationRules.forEach((el) => {
          this.$set(el, "deleteIcon", true);
          this.$set(el, "delete", false);
          el.Descriptions.forEach((desc) => {
            this.$set(desc, "deleteIcon", true);
          });
        });
      });
    },
    getSelectedPersons(ids, persons) {
      let parsedids = [];
      ids.forEach((id) => {
        parsedids.push(parseInt(id));
      });
      let arr = persons.filter(function (person) {
        return parsedids.indexOf(person.ID) !== -1;
      });
      this.selectedPersonsData = arr;
    },
    getYears() {
      const nowYear = new Date().getFullYear();
      let year = nowYear - 1;

      const years = [year];
      for (let i = 0; i < 5; i++) {
        year++;
        years.push(year);
      }

      this.years = years;
    },
    // getJobPersons() {
    //   axios.get("jobs/JobPersons").then((resp) => {
    //     if (resp.data.ErrorMsg) return;
    //     var jobs = [];
    //     resp.data.Value.forEach((person) => {
    //       let jobID = person.JobID;
    //       let job = jobs.filter((el) => el.id == jobID)[0];
    //       if (!job) {
    //         job = {
    //           id: jobID,
    //           name: person.JoBName,
    //           people: [],
    //         };
    //         jobs.push(job);
    //       }
    //       job.people.push({
    //         id: person.PersonID,
    //         name: getFullName(person),
    //       });
    //     });
    //     this.jobs = jobs;
    //   });
    // },
    // getDepartmentJobs() {
    //   axios.get("departments/GetDepartmentPeople").then((resp) => {
    //     if (resp.data.ErrorMsg) return;
    //     let departments = [];
    //     resp.data.Value.forEach((dep) => {
    //       let depId = dep.DepartmentID;
    //       let department = departments.filter((el) => el.id == depId)[0];
    //       if (!department) {
    //         department = {
    //           id: depId,
    //           name: dep.DepartmentName,
    //           jobs: [],
    //         };
    //         departments.push(department);
    //       }
    //       let jobid = dep.JobID;
    //       let job = department.jobs.filter((el) => el.id == jobid)[0];
    //       if (!job) {
    //         department.jobs.push({
    //           id: jobid,
    //           name: dep.JobName,
    //           people: [],
    //         });
    //       }
    //       department.jobs.forEach((el) => {
    //         if (el.id === dep.JobID) {
    //           el.people.push({
    //             id: dep.PersonID,
    //             name: dep.FirstName + " " + dep.LastName,
    //           });
    //         }
    //       });
    //     });
    //     let withoutDep = {
    //       id: 0,
    //       name: "Jobs without department",
    //       jobs: [],
    //     };
    //     axios.get("departments/GetJobPersonsWithoutDepartment").then((resp) => {
    //       resp.data.Value.forEach((el) => {
    //         let jobid = el.JobID;
    //         let job = withoutDep.jobs.filter((job) => jobid == job.id)[0];
    //         if (!job) {
    //           withoutDep.jobs.push({
    //             id: el.JobID,
    //             name: el.JobName,
    //             people: [],
    //           });
    //         }
    //         withoutDep.jobs.forEach((job) => {
    //           if (job.id === el.JobID) {
    //             job.people.push({
    //               id: el.ID,
    //               name: el.FirstName + " " + el.LastName,
    //             });
    //           }
    //         });
    //       });
    //     });

    //     departments.push(withoutDep);
    //     this.departmentPeople = departments;
    //     console.log('0',JSON.parse(JSON.stringify(departments)));
    //   });
    // },
    // getFixedPeople() {
    //   axios.get("Schedules/GetFixedSchedulesDropDownPersons").then((resp) => {
    //     resp.data.Value.JobPersons.forEach((el) => {
    //       el.Persons.forEach((person) => {
    //         if (person.HasFlexibleScheduleAssigned == true) {
    //           this.personsWithFlexible.push(person);
    //         }
    //       });
    //     });
    //     let data = [
    //       {
    //         id: 777,
    //         name: "",
    //         jobs: resp.data.Value.JobPersons.map((el) => {
    //           return {
    //             id: el.JobID,
    //             name: el.JobName,
    //             people: el.Persons.map((pers) => {
    //               return {
    //                 name: pers.FirstName + " " + pers.LastName,
    //                 id: pers.PersonID,
    //                 isFlexible: pers.HasFlexibleScheduleAssigned,
    //               };
    //             }),
    //           };
    //         }),
    //       },
    //     ];

    //     this.departmentPeople = data;
    //   });
    // },
    getDropdownListByYear(){
      this.selectedPersons = []
      this.selectedPersonsData = []

      axios.get('vacations/GetPersonsWithoutVacationTerms',{
        params:{
          Year: this.selectedYear ? this.selectedYear : null
        }
      }).then(resp => {
        let dropdown = resp.data.Value.JobPersons
        let data = [
          {
            id: 0,
            name: "",
            jobs: dropdown.map((el) => {
              return {
                id: el.JobID,
                name: el.JobName,
                people: el.Persons.map((person) => {
                  return {
                    name: person.FirstName + " " + person.LastName,
                    id: person.PersonID,
                  };
                }),
              };
            }),
          },
        ];
        this.departmentPeople = data;
      })
    },
    getVacationtypes() {
      axios.get("vacations/Types").then((response) => {
        this.vacationTypes = response.data.Value.filter((el) => el.ID !== 6);
      });
    },

    // ///////////////P O S T      R E Q U E S T S///////////////
    createRestriction() {
      const restArray = [
        {
          VacationType: 1,
          Quantity: Math.abs(this.newType.paid),
        },
        {
          VacationType: 2,
          Quantity: Math.abs(this.newType.notPaid),
        },
        {
          VacationType: 3,
          Quantity: Math.abs(this.newType.dayOff),
        },
        {
          VacationType: 4,
          Quantity: Math.abs(this.newType.sickDays),
        },
        {
          VacationType: 5,
          Quantity: Math.abs(this.newType.maternityLeave),
        },
        {
          VacationType: 6,
          Quantity: 365,
        },
      ];

      const people = this.selectedPersons.map((el) => {
        return {
          PersonID: Math.abs(el),
        };
      });
      if (
        this.newType.paid &&
        this.newType.notPaid &&
        this.newType.dayOff &&
        this.newType.maternityLeave &&
        this.newType.sickDays &&
        this.selectedYear
      ) {
        axios
          .post("vacations/CreateRestrictions", {
            Year: Math.abs(this.selectedYear),
            Restrictions: restArray,
            Persons: people,
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.getRestrictionsData();
              this.popupVisible = false;
              this.snackbarVisible = true;
              this.snackbarText = "Successfully Assigned!";
              this.snackbarPurpose = "success";
              this.newType.paid = "";
              this.newType.notPaid = "";
              this.newType.dayOff = "";
              this.newType.maternityLeave = "";
              this.newType.sickDays = "";
              this.selectedPersonsData = [];
              this.selectedYear = "";
            } else {
              this.popupVisible = false;
              this.snackbarPurpose = "error";
              this.snackbarVisible = true;
              this.snackbarText = resp.data.ErrorMsg;
            }
          });
      } else {
        this.snackbarVisible = true;
        this.snackbarText = "All Types Must Be Filled";
        this.snackbarPurpose = "error";
      }
    },
    createTerms() {
      this.termsOfVacation.forEach((term) => {
        let tvp = term.definitions.map((el) => {
          return {
            Definition: el.definition,
            Text: el.textArea,
          };
        });
        axios
          .post("vacations/CreateVacationTerms", {
            Type: term.typeName,
            TVP: tvp,
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.rulesPopup = false;
              this.snackbarVisible = true;
              this.snackbarText = "Successfully Saved!";
              this.snackbarPurpose = "success";
              term.typeName = "";
              term.definitions.Definition = "";
              term.definitions.textArea = "";
            } else {
              this.rulesPopup = true;
              this.snackbarPurpose = "error";
              this.snackbarVisible = true;
              this.snackbarText = resp.data.ErrorMsg;
            }
          });
      });
      this.vacationRules.forEach((rule) => {
        let tvp = rule.Descriptions.map((el) => {
          return {
            Definition: el.Definition,
            Text: el.Text,
          };
        });
        axios
          .post("vacations/CreateVacationTerms", {
            Type: rule.Type,
            TVP: tvp,
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.rulesPopup = false;
              this.snackbarVisible = true;
              this.snackbarText = "Successfully Saved!";
              this.snackbarPurpose = "success";
              this.getVacationRules();
            } else {
              this.rulesPopup = true;
              this.snackbarPurpose = "error";
              this.snackbarVisible = true;
              this.snackbarText = resp.data.ErrorMsg;
            }
          });
      });
    },
    // ///////////////////////////////////////////////
    addNewType() {
      this.termsOfVacation.push({
        typeName: "",
        deleteIcon: true,

        definitions: [
          {
            deleteIcon: true,

            definition: "",
            textArea: "",
          },
        ],
      });
    },
    addDefinition(el) {
      el.definitions.push({
        definition: "",
        textArea: "",
        deleteIcon: true,
      });
    },
    addOptions(el) {
      el.Descriptions.push({
        Definition: "",
        Text: "",
        deleteIcon: true,
      });
    },
    deleteTermType(index) {
      this.termsOfVacation = this.termsOfVacation.filter(
        (el, i) => index !== i
      );
    },
    deleteRulesType(index, el) {
      let tvp = el.Descriptions.map((e) => {
        return {
          Definition: el.Definition,
          Text: el.Text,
        };
      });
      axios
        .post("vacations/CreateVacationTerms", {
          Type: el.Type,
          Deleted: true,
          TVP: tvp,
        })
        .then((resp) => {
          this.getVacationRules();
        });
    },
    deleteRulesDefinition(index, el) {
      this.$set(
        el,
        "Descriptions",
        el.Descriptions.filter((e, i) => index !== i)
      );
    },
    deleteDefinition(index, el) {
      this.$set(
        el,
        "definitions",
        el.definitions.filter((e, i) => index !== i)
      );
    },

    activeEdit(el) {
      el.canEdit = true;
    },
    cancel(el) {
      el.canEdit = false;
    },
    checkedEdit(y, el, newType) {
      const arrayToSend = el[0].Restrictions.map((x) => {
        let quantity;

        if (x.VacationTypeName == "Paid") {
          quantity = newType.paid;
        } else if (x.VacationTypeName == "NotPaid") {
          quantity = newType.notPaid;
        } else if (x.VacationTypeName == "DayOff") {
          quantity = newType.dayOff;
        } else if (x.VacationTypeName == "SickDays") {
          quantity = newType.sickDays;
        } else if (x.VacationTypeName == "Maternity Leave") {
          quantity = newType.maternityLeave;
        } else {
          quantity = null;
        }

        return {
          RestrictionID: x.VacationRestrictionID,
          Year: newType.Year === "" ? y.Year : Math.abs(newType.Year),
          Quantity:
            Math.abs(quantity) === 0 ? el.AnnualQuantity : Math.abs(quantity),
        };
      });

      axios
        .post("vacations/UpdatePersonVacationRestriction", {
          TVP: arrayToSend,
        })
        .then((resp) => {
          this.getRestrictionsData();
          y.canEdit = false;
          (newType.notPaid = ""),
            (newType.paid = ""),
            (newType.dayOff = ""),
            (newType.sickDays = ""),
            (newType.maternityLeave = "");
        });
    },

    remove(person) {
      this.selectedPersons = this.selectedPersons.filter((el) => el != person);
      this.selectedPersonsData = this.selectedPersonsData.filter(
        (e) => e.ID != person
      );
    },
  },
  computed: {
    filteredList() {
      var result = [];
      result = this.restrictionsData.filter((el) => {
        var fullName = el.Person.FirstName + " " + el.Person.LastName;
        return fullName.toLowerCase().includes(this.searchName.toLowerCase());
      });
      if(this.filteredYear || this.filteredYear !== null && this.filteredYear !== ''){
        result = result.filter(el => el.Person.Year === parseInt(this.filteredYear))
      }
      return result;
    },
    vacationTypeOptions() {
      return this.vacationTypes.map((el) => ({
        title: el.Name,
        value: el.Name,
      }));
    },
    yearsOptions() {
      return this.years.map((el) => ({
        title: el,
        value: el,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-header-content {
  .terms-title {
    color: $blue-primary;
    font-size: 20px;
    span {
      margin-right: 10 * $rem;
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30 * $rem;
  padding-left: 85 * $rem;
  padding-right: 85 * $rem;

  .vacation-header-rightside {
    display: flex;
    align-items: center;
    .request-vacation-button {
      margin-left: 25 * $rem;
    }
    .add-terms-content {
      color: $blue-primary;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: 1.5625rem;
      font-size: 14px;
      img {
        height: 31px;
        width: 31px;
        padding-top: 4px;
      }
    }
  }
  .add-button-content {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $blue-primary;
    font-size: 14px;

    .assign-icon {
      width: 50px;
      height: 50px;
    }
  }
}
.search-form {
  margin-left: 25 * $rem;
  input {
    width: 178px;
    height: 38px;
    outline: none;
    border: 0;
    color: $blue-primary;
    border: 1px solid #dedff4;
    padding-left: 5px;
    font-size: 16px;
    border-radius: 3px;
    &::placeholder {
      color: #9696b9;
      font-size: 14px;
    }
  }
}
.list-container {
  padding-top: 30 * $rem;
}
.selected-people-content {
  height: 110px;
  width: 700px;
  overflow-x: scroll;
}
.types-content {
  margin-top: 40px;
  display: flex;
  ul {
    margin-left: 50px;
    margin-top: 12px;
  }
  .restrictions-content {
    display: flex;
  }
  .inputs-content {
    margin-left: 20px;
    display: flex;
    flex-direction: column;

    span {
      margin-bottom: 10px;
      &:last-child {
        margin-top: 3px;
      }
    }
    input {
      width: 80px;
      height: 35px;
      outline: none;
      border: 0.0625rem solid #dcdde6;
      border-radius: 5px;
      padding-left: 6px;
      font-size: 15px;
      color: $blue-primary;
      &::placeholder {
        font-size: 14px;
        color: $blue-primary;
        font-weight: 300;
        opacity: 0.7;
      }
    }
  }
  .type-list {
    color: $blue-primary;
    font-size: 16px;
    font-weight: 300;
    list-style: none;
    margin-bottom: 26px;

    .type {
      width: 140px;
      margin-left: 5px;
    }
  }
  .years-content {
    input {
      width: 80px;
      height: 38px;
      outline: none;
      border: 0.0625rem solid #dcdde6;
      border-radius: 5px;
      padding-left: 6px;
      font-size: 15px;
      color: $blue-primary;
      &::placeholder {
        font-size: 14px;
        color: $blue-primary;
        font-weight: 300;
        opacity: 0.7;
      }
    }
  }
}
.editor-content {
  height: 40px;
  width: 200px;
}
.popup-header {
  color: $blue-primary;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .right-side {
    cursor: pointer;
  }
}
.inputs-container {
  padding: 10px;
  height: 420px;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% + 42px);

  .text-area {
    width: 800px;
    height: 77px;

    border: 0.0625rem solid #dcdde6;

    outline: none;
    margin-left: 50px;

    font-size: 14px;
    padding-left: 10px;
    color: rgba(40, 53, 147, 0.7);
    resize: none;
    &::placeholder {
      color: rgba(40, 53, 147, 0.6);
      font-size: 14px;
      // padding-top: 30px;
    }
  }
  .typename-input {
    width: 250px;
    height: 38px;

    outline: none;
    border: 0;
    border-bottom: 0.0625rem solid #dcdde6;
    color: rgba(40, 53, 147, 0.7);
    font-size: 15px;
    padding-left: 10px;
    &::placeholder {
      color: rgba(40, 53, 147, 0.6);
      font-size: 14px;
    }
  }
  .definition-input {
    border: none;
    border-bottom: 0.0625rem solid #dcdde6;
    outline: none;
    width: 272px;
    padding-left: 10px;

    color: rgba(40, 53, 147, 0.7);
    padding-bottom: 5px;
    font-size: 15px;
    &::placeholder {
      color: rgba(40, 53, 147, 0.6);
      font-size: 14px;
    }
  }
  .inputs {
    margin-bottom: 50px;
    padding-right: 42px;
    position: relative;
    .term-index {
      color: $blue-primary;
      opacity: 0.5;
      margin-right: 3px;
    }
    .type-name {
      color: $blue-primary;
      font-size: 16px;
      // border-bottom: 0.0625rem solid #dcdde6;
      text-transform: uppercase;
      width: 249px;
      padding-left: 10px;
      padding-bottom: 3px;
      user-select: none;
    }
    div:nth-child(1) {
      position: relative;
    }
    .definition-content {
      display: flex;
      align-items: flex-start;
      margin-top: 20px;
      position: relative;

      // justify-content: space-between;
    }
    .add-option-content {
      cursor: pointer;
      color: $blue-primary;
      font-weight: 300;
      margin-top: 10px;
    }
    .material-icons {
      position: absolute;
      color: #ff2828;
      right: -30px;
      top: 0;
      cursor: pointer;
      font-size: 22px;
    }
  }
}
p {
  color: $blue-primary;
  font-weight: 400;
  opacity: 0.8;
  margin-top: 10px;
}
button {
  width: 144px;
  height: 50px;
  background: rgba(40, 53, 147, 0.6);
  color: #ffffff;
  font-size: 16px;
  border-radius: 5px;
  display: block;
  margin-left: auto;
  cursor: pointer;
  margin-top: 20px;
}
.rules-popup-content {
  ::v-deep .popup {
    width: 1120px !important;
  }
}
::v-deep label {
  width: 230px;
}
::v-deep .popup {
  width: 760px !important;
  height: 38.8125rem !important;
}
::v-deep.select-tree {
  margin-left: 0;
}
::v-deep.select-tree .tree {
  background-color: #fff;
  z-index: 40;
  border-radius: 0;
}
::v-deep .selected-permission {
  margin-right: 10px;
  margin-top: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 216px;
  height: 35px;
  display: inline-block;
  span {
    font-weight: 300;
    color: #283593;
    font-size: 14px;
    vertical-align: sub;

    opacity: 0.8;
  }
}
::v-deep .selected-permission > svg {
  top: 10px;
  opacity: 0.8;
}

::v-deep .select-wrapper .selected-box {
  width: 139px;
}
::v-deep .inputs {
  .select-wrapper .selected-box {
    width: 248px;
  }
}

::v-deep .assign-terms-popup {
  .popup {
    overflow: hidden !important;
  }
}
</style>