<template>
    <div class="star-wrapper">
        <div class="tooltip" v-if="withTooltip">
            <div class="tooltip-content">
                <span>
                    {{tooltipContent}}
                </span>
            </div>
        </div>
        <svg
            :class="['vue-star-rating-star', {'vue-star-rating-star-rotate' : shouldAnimate}]"
            :height="starSize"
            :width="starSize"
            :viewBox="viewBox"
            @mousemove="mouseMoving"
            @click="selected"
            @touchstart="touchStart"
            @touchend="touchEnd"
        >

            <linearGradient
                :id="grad"
                x1="0"
                x2="100%"
                y1="0"
                y2="0"
            >
            <stop
                :offset="starFill"
                :stop-color="(rtl) ? getColor(inactiveColor) : getColor(activeColor)"
                :stop-opacity="(rtl) ? getOpacity(inactiveColor) : getOpacity(activeColor)"
            />
            <stop
                :offset="starFill"
                :stop-color="(rtl) ? getColor(activeColor) : getColor(inactiveColor)"
                :stop-opacity="(rtl) ? getOpacity(activeColor) : getOpacity(inactiveColor)"
            />
            </linearGradient>

            <filter
                :id="glowId"
                height="130%"
                width="130%"
                filterUnits="userSpaceOnUse"
            >
            <feGaussianBlur
                :stdDeviation="glow"
                result="coloredBlur"
            />
            <feMerge>
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="SourceGraphic" />
            </feMerge>
            </filter>

            <polygon
                v-show="glowColor && glow > 0 && fill > 0"
                :points="starPointsToString"
                :fill="gradId"
                :stroke="glowColor"
                :filter="'url(#'+glowId+')'"
                :stroke-width="border"
            />

            <polygon
                :points="starPointsToString"
                :fill="gradId"
                :stroke="getBorderColor"
                :stroke-width="border"
                :stroke-linejoin="strokeLinejoin"
            />
            <polygon
                :points="starPointsToString"
                :fill="gradId"
            />
        </svg>
    </div>
</template>

<script type="text/javascript">
import AlphaColor from './classes/AlphaColor'

export default {
    name: 'Star',
    props: {
        withTooltip: {
            type: Boolean,
            default: false,
        },
        fill: {
            type: Number,
            default: 0
        },
        points: {
            type: Array,
            default() {
                return []
            }
        },
        size: {
            type: Number,
            default: 50
        },
        starId: {
            type: Number,
            required: true
        },
        activeColor: {
            type: String,
            required: true
        },
        inactiveColor: {
            type: String,
            required: true
        },
        borderColor: {
            type: String,
            default: '#000'
        },
        activeBorderColor: {
            type: String,
            default: '#000'
        },
        borderWidth: {
            type: Number,
            default: 0
        },
        roundedCorners: {
            type: Boolean,
            default: false
        },
        rtl: {
            type: Boolean,
            default: false
        },
        glow: {
            type: Number,
            default: 0
        },
        glowColor: {
            type: String,
            default: null,
            required: false
        },
        animate: {
            type: Boolean,
            default: false
        },
        tooltipContent: [String,Number],
    },
    emits: ['star-mouse-move', 'star-selected'],
    data() {
        return {
            starPoints: [19.8, 2.2, 6.6, 43.56, 39.6, 17.16, 0, 17.16, 33, 43.56],
            grad: '',
            glowId: '',
            isStarActive: true,
            tooltipVisible: false,
        }
    },
    computed: {
        starPointsToString() {
            return this.starPoints.join(',')
        },
        gradId() {
            return 'url(#' + this.grad + ')'
        },
        starSize() {
            // Adjust star size when rounded corners are set with no border, to account for the 'hidden' border
            const size = (this.roundedCorners && this.borderWidth <= 0) ? parseInt(this.size) - parseInt(this.border) : this.size
            return parseInt(size) + parseInt(this.border)
        },
        starFill() {
            return (this.rtl) ? 100 - this.fill + '%' : this.fill + '%'
        },
        border() {
            return (this.roundedCorners && this.borderWidth <= 0) ? 6 : this.borderWidth
        },
        getBorderColor() {
            if (this.roundedCorners && this.borderWidth <= 0) {
                // create a hidden border
                return (this.fill <= 0) ? this.inactiveColor : this.activeColor
            }

            return (this.fill <= 0) ? this.borderColor : this.activeBorderColor
        },
        maxSize() {
            return this.starPoints.reduce(function(a, b) {
                return Math.max(a, b)
            })
        },
        viewBox() {
            return '0 0 ' + this.maxSize + ' ' + this.maxSize
        },
        shouldAnimate() {
            return this.animate && this.isStarActive
        },
        strokeLinejoin() {
            return this.roundedCorners ? 'round' : 'miter'
        }
    },
    created() {
        this.starPoints = (this.points.length) ? this.points : this.starPoints
        this.calculatePoints()
        this.grad = this.getRandomId()
        this.glowId = this.getRandomId()
    },
    methods: {
        mouseMoving($event) {
            if ($event.touchAction !== 'undefined') {
                this.$emit('star-mouse-move', {
                    event: $event,
                    position: this.getPosition($event),
                    id: this.starId
                })
            }
        },
        touchStart() {
            this.$nextTick(() => {
                this.isStarActive = true
            })
        },
        touchEnd() {
            this.$nextTick(() => {
                this.isStarActive = false
            })
        },
        getPosition($event) {
            // calculate position in percentage.
            let starWidth = (92 / 100) * this.size
            const offset = (this.rtl) ? Math.min($event.offsetX, 45) : Math.max($event.offsetX, 1)
            let position = Math.round((100 / starWidth) * offset)

            return Math.min(position, 100)
        },
        selected($event) {
            this.$emit('star-selected', {
                id: this.starId,
                position: this.getPosition($event)
            })
        },
        getRandomId() {
            return Math.random().toString(36).substring(7)
        },
        calculatePoints() {
            this.starPoints = this.starPoints.map((point, i) => {
                const offset = i % 2 === 0 ? this.border * 1.5 : 0
                return ((this.size / this.maxSize) * point) + offset
            })
        },
        getColor(color) {
            return new AlphaColor(color).parseAlphaColor().color
        },
        getOpacity(color) {
            return new AlphaColor(color).parseAlphaColor().opacity
        },
        showTooltip(){
            
        }
    }
}
</script>

<style lang="scss" scoped>
    .star-wrapper{
        position: relative;
        &:hover{
            .tooltip{
                display: block;
            }        
            .vue-star-rating-star-rotate{
                transition: transform .3s;
                transform: translateY(-0.3125rem) scale(1.3)
            }
        }
        .tooltip{
            display: none;
            position: absolute;
            top: 100%;
            right: calc(100% - 1.5rem);
            z-index: 999;
            padding: 0.625rem;
            background: transparent;

                &::before {
                    content: '';
                    position: absolute;
                    display: block;    
                    width: 0px;        
                    left: calc(100% - 1.5625rem);
                    top: 0.9375rem;
                    border: 0.625rem solid transparent;
                    border-top: 0;
                    border-bottom: 0.625rem solid #6754D2;
                    transform: translate(-50%, calc(-100% - 5px));
                    z-index: 99;
                }

            .tooltip-content{
                width: 17rem;
                min-height: 2rem;
                max-height: 17rem;
                background: #fff;
                border: 0.0625rem solid rgba(#6754D2,.8);
                box-shadow: 0 0 1.25rem rgba(#6754D2,.2);                
                border-radius: 0.375rem;
                overflow: auto;
                padding: 0.625rem 0.9375rem;
                font-weight: 500;
                position: relative;

                span{
                    color: rgba(#4D5E80,.7);
                    font-size: 0.875rem;
                }
            }

        }
    }
    .vue-star-rating-star {
        overflow: visible !important;
    }

    .vue-star-rating-star-rotate {
        transition: all .25s;
    }

    .vue-star-rating-star-rotate:hover {
        transition: transform .3s;
        transform: translateY(-0.3125rem) scale(1.3)
    }
</style>
