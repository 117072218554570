var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"terms-table-content"},[_c('div',{ref:"gridContainer",staticClass:"tbody-wrapper",on:{"scroll":_vm.scrollHandler}},[_c('table',[_c('thead',{class:{ fixedHeader: _vm.vacationStatistics }},[_c('tr',[(_vm.userView == false && _vm.profileTerms == false)?_c('th',[_vm._v("#")]):_vm._e(),(_vm.userView == false && _vm.profileTerms == false)?_c('th',[_vm._v("Employee")]):_vm._e(),(!_vm.vacationStatistics)?_c('th',{staticClass:"withFilter"},[_vm._v(" Year "),(_vm.withFilters)?_c('employee-filter',{staticClass:"filter",attrs:{"slot":"searchYear","list":_vm.data
                  .sort((a, b) => a.Person.Year - b.Person.Year)
                  .map((el) => el.Person.Year.toString())},on:{"select":(val) => _vm.$emit('filterParam', val)},slot:"searchYear"}):_vm._e()],1):_vm._e(),(_vm.vacationStatistics)?_c('th',[_vm._v("Used Days Count")]):_vm._e(),_c('th',[_vm._v("Paid")]),_c('th',[_vm._v("Not Paid")]),_c('th',[_vm._v("Day Off")]),_c('th',[_vm._v("Sick Days")]),_c('th',[_vm._v("Maternity Leave")]),_c('th',[_vm._v("Working from home")]),(_vm.vacationStatistics)?_c('th',[_vm._v("Business trip")]):_vm._e(),(_vm.vacationStatistics)?_c('th',{staticClass:"for-background"}):_vm._e(),(_vm.canEdition)?_c('th'):_vm._e()])]),_c('tbody',_vm._l((_vm.data),function(el,ind){return _c('tr',{key:el.index},[(_vm.userView == false && _vm.profileTerms == false)?_c('td',[_vm._v(" "+_vm._s(ind + 1)+" ")]):_vm._e(),(_vm.userView == false && _vm.profileTerms == false)?_c('td',[(!_vm.vacationStatistics)?_c('span',[_vm._v(" "+_vm._s(el.Person.FirstName + ' ' + el.Person.LastName)+" ")]):_c('span',[_vm._v(" "+_vm._s(el.FirstName + ' ' + el.LastName)+" ")])]):_vm._e(),_c('td',[(el.canEdit == false && !_vm.vacationStatistics)?_c('span',[_vm._v(" "+_vm._s(el.Person.Year)+" ")]):_vm._e(),(_vm.vacationStatistics)?_c('span',[_vm._v(_vm._s(el.UsedDaysCnt))]):_vm._e(),(el.canEdit == true)?_c('div',{staticClass:"input-content"},[_c('custom-input',{attrs:{"title":el.Person.Year.toString()},model:{value:(_vm.newType.Year),callback:function ($$v) {_vm.$set(_vm.newType, "Year", $$v)},expression:"newType.Year"}})],1):_vm._e()]),_vm._l(((el.Vacs || el.Restrictions[0].Restrictions )),function(rest){return _c('td',{key:rest.VacationRestrictionID || el.index},[(el.canEdit == false)?_c('div',{staticClass:"dot",class:[
                { paidColor: rest.VacationTypeName == 'Paid' },
                { notpaidColor: rest.VacationTypeName == 'NotPaid' },
                { dayoffColor: rest.VacationTypeName == 'DayOff' },
                { sickdaysColor: rest.VacationTypeName == 'SickDays' },
                {
                  maternityLeaveColor:
                    rest.VacationTypeName == 'Maternity Leave',
                },
                {
                  fromHomeColor: rest.VacationType == 6,
                },
              ]}):_vm._e(),(el.canEdit == false && !_vm.vacationStatistics)?_c('span',[_vm._v(" "+_vm._s(rest.AnnualQuantity)+" ")]):_vm._e(),(el.canEdit == false && _vm.vacationStatistics)?_c('div',{staticClass:"vacation-stats"},[(rest.VacationType !== 7)?[_c('span',{staticClass:"free-days"},[_vm._v(_vm._s(rest.Leftdays))]),_c('span',[_vm._v("/")]),_c('span',{staticClass:"used-days"},[_vm._v(_vm._s(rest.UsedDays))])]:[_c('span',{staticClass:"used-days"},[_vm._v(" "+_vm._s(rest.UsedDays)+" ")])]],2):_vm._e(),(el.canEdit == true && _vm.userView == false)?_c('div',{staticClass:"input-content"},[(rest.VacationTypeName == 'Paid')?_c('custom-input',{attrs:{"title":rest.AnnualQuantity.toString()},model:{value:(_vm.newType.paid),callback:function ($$v) {_vm.$set(_vm.newType, "paid", $$v)},expression:"newType.paid"}}):_vm._e(),(rest.VacationTypeName == 'NotPaid')?_c('custom-input',{attrs:{"title":rest.AnnualQuantity.toString()},model:{value:(_vm.newType.notPaid),callback:function ($$v) {_vm.$set(_vm.newType, "notPaid", $$v)},expression:"newType.notPaid"}}):_vm._e(),(rest.VacationTypeName == 'DayOff')?_c('custom-input',{attrs:{"title":rest.AnnualQuantity.toString()},model:{value:(_vm.newType.dayOff),callback:function ($$v) {_vm.$set(_vm.newType, "dayOff", $$v)},expression:"newType.dayOff"}}):_vm._e(),(rest.VacationTypeName == 'SickDays')?_c('custom-input',{attrs:{"title":rest.AnnualQuantity.toString()},model:{value:(_vm.newType.sickDays),callback:function ($$v) {_vm.$set(_vm.newType, "sickDays", $$v)},expression:"newType.sickDays"}}):_vm._e(),(rest.VacationTypeName == 'Maternity Leave')?_c('custom-input',{attrs:{"title":rest.AnnualQuantity.toString()},model:{value:(_vm.newType.maternityLeave),callback:function ($$v) {_vm.$set(_vm.newType, "maternityLeave", $$v)},expression:"newType.maternityLeave"}}):_vm._e()],1):_vm._e()])}),_c('td',{staticClass:"td-actions"},[(
                (_vm.userView == false || _vm.canEdition == true) &&
                el.Person.Year >= _vm.currentYear
              )?_c('div',{staticClass:"actions"},[(el.canEdit == false)?_c('span',{staticClass:"icon-span"},[_c('img',{attrs:{"src":require("@/assets/icons/edit.svg"),"alt":"edit"},on:{"click":function($event){return _vm.$emit('active-edit', el)}}})]):_vm._e(),(el.canEdit == true)?_c('div',{staticClass:"material-icons-content"},[_c('span',{staticClass:"material-icons success",on:{"click":function($event){return _vm.$emit('checked', el, el.Restrictions, _vm.newType)}}},[_vm._v(" check ")]),_c('span',{staticClass:"material-icons cancel",on:{"click":function($event){return _vm.$emit('cancel', el)}}},[_vm._v(" close ")])]):_vm._e()]):_vm._e()])],2)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }