<template>
  <a
    v-if="link"
    class="bookmark"
    :href="link"
    target="_blank"
    @mouseover="showDeleteICon = true"
    @mouseleave="showDeleteICon = false"
    @dragstart="$emit('drag-started', $event)"
  >
    <img :src="icon" class="icon" />
    <span v-if="title" class="title">{{ title }}</span>
    <span class="link" v-else> {{ link }}</span>

    <span
      v-if="showDeleteICon"
      @click.prevent="$emit('delete-clicked')"
      class="material-icons"
    >
      close</span
    >
  </a>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: null,
      required: true,
    },
    link: {
      type: null,
      required: true,
    },
  },
  data() {
    return {
      showDeleteICon: false,
    };
  },
};
</script>

<style scoped lang="scss">
.bookmark {
  position: relative;
  height: 100%;
  display: flex;
  // margin-right: 30px;
  padding: 8px 10px;
  margin: 0 3px;
  transition: all 0.2s ease-out;
  text-decoration: none;
  align-items: center;
  border-radius: 15px;
  color: black;
  padding-right: 30px;

  &:not(:last-child) {
    &:after {
      content: "";
      position: absolute;
      right: -3px;
      height: 16px;
      width: 1px;
      background: rgba(0, 0, 0, 0.19);
    }
  }

  .material-icons {
    position: absolute;
    right: 5px;
    top: 3px;
    font-size: 16px;
    color: $blue-primary;
  }
  .icon {
    width: 15px;
    height: 15px;
    margin-right: 9px;
  }
  .link {
    font-size: 12px;
    max-width: 170px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $blue-primary;
  }

  .title {
    font-size: 13px;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 135px;
    overflow: hidden;
    font-weight: 400;
    color: $blue-primary;
  }

  &:hover {
    background-color: #eeeeef;
    cursor: pointer;
  }
}
</style>