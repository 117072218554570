<template>
  <div v-show="isActive" class="tabs-content">
    <ul>
      <router-link
        tag="li"
        :to="tab.path"
        v-for="(tab, index) in tabs"
        :key="index"
      >
        {{ tab.title }}
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    titles: {
      type: Array,
    },
  },
  data() {
    return {
      isActive: true,
      selectedIndex: 0,
      tabs: [
        {
          title: 'Requests',
          path: '/VacationsView/VacationsAdmin',
        },
        {
          title: 'Team’s Vacation Plan',
          path: '/VacationsView/VacationTeams',
        },
        {
          title: 'Terms',
          path: '/VacationsView/Vacationterms',
        },
        {
          title: 'Vacation Statistics',
          path: '/VacationsView/VacationStatistics',
        },
      ],
    }
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i
      this.$emit('select', i)
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs-content {
  margin-left: 102 * $rem;
  ul {
    display: flex;
  }
  li {
    list-style: none;
    margin-right: 110 * $rem;
    font-size: 16 * $rem;
    color: $blue-primary;
    cursor: pointer;
    padding-bottom: 5px;
  }
}
.router-link-exact-active {
  border-bottom: 4px solid #1de9b6 !important;
  border: transparent;
  font-weight: 500;
}
</style>
