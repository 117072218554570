<template>
  <div>
    <editor
      :api-key="key"
      :value="value"
      @input="inputHandler"
      placeholder="Description ..."
      :init="{
        height: 300,
        resize: false,
        branding: false,
        menubar: false,
        statusbar: false,

        toolbar:
          ' bold italic |  fontsizeselect fontselect | alignleft aligncenter alignright alignjustify | ' +
          'bullist numlist outdent indent | link  | ' +
          'forecolor backcolor emoticons | help ',
        plugins: [
          'advlist autoresize autolink link image lists charmap print preview hr anchor pagebreak',
          ' searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
          'table emoticons template paste help',
        ],
        font_formats:
          'roboto; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif',
        branding: true,
        skin: 'borderless',
      }"
    />
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
let th = this;

export default {
  components: {
    editor: Editor,
  },
  props: {
    value: String,
  },

  methods: {
    inputHandler(val) {
      this.$emit("input", val);
    },
  },
  data() {
    return {
      key: "ryzrlj7vmuqo266grm3goqq8npkvaehj06fdvitz81omre1e",
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .tox-tinymce {
  border-radius: 6px;
  color: $blue-primary !important;
}
::v-deep .tox .tox-edit-area__iframe {
}
</style>
