<template>
  <div class="attendance">
    <h3 class="main-title">Attendance</h3>
    <div class="wrapper">
      <attendance-arrow
        class="left"
        :style="[!bigVersion ? { 'margin-top': '40px' } : null]"
        @click="viewPrevAttendances"
      />
      <div class="main-container" :class="{ bigSize: bigVersion }">
        <attendance-day
          :date="day1.date"
          :work="day1.work"
          :bigVersion="bigVersion"
          :overtime="day1.overtime"
          :totalWork="day1.totalWork"
          :vacation="day1.onVacation"
          :vacationName="day1.vacationName"
          :in-outs="getInOutsForDay(day1.date)"
        />
        <attendance-day
          :middle="true"
          :date="day2.date"
          :bigVersion="bigVersion"
          :work="day2.work"
          :vacation="day2.onVacation"
          :vacationName="day2.vacationName"
          :overtime="day2.overtime"
          :totalWork="day2.totalWork"
          :in-outs="getInOutsForDay(day2.date)"
        />
        <attendance-day
          v-if="bigVersion"
          :middle="false"
          :bigVersion="bigVersion"
          :date="day3.date"
          :work="day3.work"
          :vacation="day3.onVacation"
          :vacationName="day3.vacationName"
          :overtime="day3.overtime"
          :totalWork="day3.totalWork"
          :in-outs="getInOutsForDay(day3.date)"
        />
      </div>
      <attendance-arrow
        :style="[!bigVersion ? { 'margin-top': '40px' } : null]"
        class="right"
        @click="viewNextAttendances"
      />
    </div>
  </div>
</template>

<script>
import AttendanceDay from "@/components/Dashboard/AttendanceDay";
import AttendanceArrow from "@/components/Dashboard/AttendanceArrow";
import axios from "@/Helpers/AxiosInstance";
import { getDateString } from "@/Helpers/HelperFunctions";
export default {
  name: "UserAttendance",
  components: {
    AttendanceDay,
    AttendanceArrow,
  },
  props: {
    userID: {
      type: Number,
    },
    bigVersion: Boolean,
  },
  created() {
    this.getData();
    this.getInOuts();
  },
  data() {
    return {
      day1: {
        date: this.getDay1Date(),
        work: 0,
        overtime: 0,
        totalWork: 0,
        onVacation: false,
      },
      day2: {
        date: this.getDay2Date(),
        work: 0,
        overtime: 0,
        totalWork: 0,
        onVacation: false,
      },
      day3: {
        date: this.getDay3Date(),
        work: 0,
        overtime: 0,
        totalWork: 0,
        onVacation: false,
      },
      inOuts: [],
    };
  },
  methods: {
    getInOuts() {
      const start = new Date(this.day1.date).setHours(0, 0, 0, 0);
      const end = new Date(this.day3.date).setHours(23, 59, 0, 0);
      axios
        .get("attendance/inouts", {
          params: {
            Person: this.userID || this.myID,
            Office: this.$store.state.profile.OfficeID,
            From: start,
            To: end,
          },
        })
        .then((resp) => {
          if (resp.data.ErrorMsg) return;
          this.inOuts = resp.data.Value;
        });
    },
    getDay1Date() {
      var d = new Date();
      d.setDate(d.getDate() - 3);
      return d;
    },
    getDay2Date() {
      var d = new Date();
      d.setDate(d.getDate() - 2);
      return d;
    },
    getDay3Date() {
      var d = new Date();
      d.setDate(d.getDate() - 1);
      return d;
    },
    getData() {
      var start = new Date(this.day1.date).setHours(0, 0, 0, 0);
      var end = new Date(this.day3.date).setHours(23, 59, 0, 0);
      axios
        .get("/attendance/Attendance", {
          params: {
            Person: this.userID || this.myID,
            From: getDateString(start, "yyyy-MM-dd HH:mm"),
            To: getDateString(end, "yyyy-MM-dd HH:mm"),
          },
        })
        .then((resp) => {
          let result = resp.data.Value;
          let changed1 = false,
            changed2 = false,
            changed3 = false;
          result.forEach((attendance) => {
            let dateOfAction = new Date(attendance.ActionDay).getDate();
            let dayToChange = "";
            switch (dateOfAction) {
              case this.day1.date.getDate():
                dayToChange = this.day1;
                changed1 = true;
                break;
              case this.day2.date.getDate():
                dayToChange = this.day2;
                changed2 = true;
                break;
              case this.day3.date.getDate():
                dayToChange = this.day3;
                changed3 = true;
                break;
            }
            dayToChange.onVacation = attendance.IsVacationDay;
            dayToChange.vacationName = attendance.VacationTypeName;
            dayToChange.work = this.timeStampToMinutes(
              attendance.TotalWorkedTime
            );
            dayToChange.overtime = this.timeStampToMinutes(attendance.OverTime);
            if (!attendance.IsVacationDay) {
              dayToChange.totalWork = this.timeStampToMinutes(
                attendance.TotalWorkedTime -
                  attendance.OverTime +
                  attendance.TotalOffTime
              );
            } else {
              dayToChange.totalWork = 0;
            }
            if (attendance.IsVacationDay && attendance.OverTime > 0) {
              dayToChange.work = dayToChange.overtime;
              dayToChange.totalWork = dayToChange.overtime;
            }
            dayToChange.work =
              dayToChange.work > dayToChange.totalWork
                ? dayToChange.totalWork
                : dayToChange.work;
          });
          //empty changes = 0
          if (!changed1) {
            this.day1.work = 0;
            this.day1.overtime = 0;
            this.day1.totalWork = 0;
            this.day1.onVacation = false;
          }
          if (!changed2) {
            this.day2.work = 0;
            this.day2.overtime = 0;
            this.day2.totalWork = 0;
            this.day2.onVacation = false;
          }
          if (!changed3) {
            this.day3.work = 0;
            this.day3.overtime = 0;
            this.day3.totalWork = 0;
            this.day3.onVacation = false;
          }
        });
    },
    getStartAndEndOfTheDay(date) {
      var start = new Date(date).setHours(0, 0, 0, 0);
      var end = new Date(date).setHours(23, 59, 0, 0);
      return { start, end };
    },
    viewPrevAttendances() {
      var newDay1 = new Date(this.day1.date).setDate(
        this.day1.date.getDate() - (this.bigVersion ? 3 : 2)
      );
      var newDay2 = new Date(this.day2.date).setDate(
        this.day2.date.getDate() - (this.bigVersion ? 3 : 2)
      );
      var newDay3 = new Date(this.day3.date).setDate(
        this.day3.date.getDate() - (this.bigVersion ? 3 : 2)
      );
      this.day1.date = new Date(newDay1);
      this.day2.date = new Date(newDay2);
      this.day3.date = new Date(newDay3);
      this.getData();
      this.getInOuts();
    },
    viewNextAttendances() {
      var newDay1 = new Date(this.day1.date).setDate(
        this.day1.date.getDate() + (this.bigVersion ? 3 : 2)
      );
      var newDay2 = new Date(this.day2.date).setDate(
        this.day2.date.getDate() + (this.bigVersion ? 3 : 2)
      );
      var newDay3 = new Date(this.day3.date).setDate(
        this.day3.date.getDate() + (this.bigVersion ? 3 : 2)
      );
      this.day1.date = new Date(newDay1);
      this.day2.date = new Date(newDay2);
      this.day3.date = new Date(newDay3);
      this.getData();
      this.getInOuts();
    },
    timeStampToMinutes(timestamp) {
      return Math.floor(timestamp / 60000);
    },
    getInOutsForDay(day) {
      return this.inOuts.filter((elem) => {
        const date1 = new Date(day);
        const date2 = new Date(elem.InteractionTime);
        return (
          date1.getFullYear() == date2.getFullYear() &&
          date1.getMonth() == date2.getMonth() &&
          date1.getDate() == date2.getDate()
        );
      });
    },
  },
  computed: {
    computedTotalWork() {
      var tw = this.day1.totalWork;
      var hr = Math.floor(tw / 60);
      var mm = tw % 60;
      var time = "";
      if (mm) time = `${hr} hr ${mm} mm`;
      else time = `${hr} hr`;
      return `Planned\ntime\n${time}`;
    },
    myID() {
      return this.$store.state.profile.ID;
    },
  },
};
</script>

<style scoped lang="scss">
.attendance {
  // margin-top: 54 * $rem;
  position: relative;

  h3.main-title {
    font-size: 20 * $rem;
    font-weight: 300;
    color: $blue-primary;
    margin-top: -35px;
    padding-bottom: 10px;
  }

  .wrapper {
    display: flex;
    // width: 585 * $rem;
    position: relative;
    justify-content: space-between;
    // margin-top: 24 * $rem;
    .main-container.bigSize {
      height: 25rem;
    }
    .main-container {
      background-color: #fff;
      border: $rem solid #dedff4;
      border-radius: 6 * $rem;
      height: 360 * $rem;
      width: auto;
      box-shadow: 6 * $rem 6 * $rem 10 * $rem rgba(40, 52, 147, 0.08);
      display: flex;
      overflow: hidden;
    }

    .right {
      transform: rotateY(180deg);
      // margin-left: -25px;

      position: absolute;
      top: 0;
      right: 10px;
    }
    .left {
      position: absolute;
      top: 0;
      left: 10px;
      // margin-right: -25px;
    }
  }

  .total-work {
    position: absolute;
    width: 60 * $rem;
    white-space: pre-wrap;
    text-align: right;
    top: 193 * $rem;
    left: 0;
    font-weight: 300;
    color: $blue-primary;
  }
}
</style>