<template>
  <div class="vacations-content">
    <vacation-admin-tabs />
    <div class="background">
      <transition name="fade" mode="out-in" appear>
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import VacationAdminTabs from './VacationAdminTabs.vue'

export default {
  components: {
    VacationAdminTabs,
  },
}
</script>

<style scoped lang="scss">
.vacations-content {
  height: 100%;
}
</style>
