<template>
  <div class="background">
    <div class="roles" v-if="accessPermissions.getRoles">
      <grid
        title="Roles"
        :gridData="filteredGridData"
        :columns="columns"
        :passedPageSize="gridParams.pageSize"
        :dataCount="gridParams.totalCount"
        identificator="ID"
        :canDelete="true"
        @rowDelete="showDeletePopup"
        :canEdit="true"
        @add-click="addNewClickHandler"
        @rowEdit="editRow"
        @trClicked="trClickHandler"
        :canAdd="true"
        :canSearch="true"
        @search="(value) => (searchTerm = value)"
      />
      <custom-Popup
        :visible="addPopupVisible"
        @close="addPopupVisible = false"
        title="Add Role"
        :flexibleSize="true"
      >
        <form class="add-form" @submit.prevent="addRole">
          <custom-input
            v-model="newRole.name"
            title="Name"
            @clear="newRole.name = ''"
          />
          <label>
            <textarea
              name="description"
              spellcheck="false"
              placeholder="Description"
              rows="4"
              v-model="newRole.description"
              title="Description"
              @clear="newRole.description = ''"
            />
          </label>

          <button class="btn">Save</button>
        </form>
      </custom-Popup>
      <custom-Popup
        :visible="permissionsPopupVisible"
        @close="permissionsPopupVisible = false"
        class="permissions-overlay"
        title="Role Permissions"
      >
        <permissions
          getURL="Permission/GetRolePermmissions"
          assignURL="Permission/AssignRolePermissions"
          :itemID="selectedRoleID"
          itemName="Role"
      /></custom-Popup>

      <delete-item
        @delete="deleteRow"
        @close="deletePopupVisible = false"
        v-if="deletePopupVisible"
        :text="deleteText"
      />
      <custom-snackbar
        v-if="snackbarVisible"
        :purpose="snackbarPurpose"
        :text="snackbarText"
        @close="snackbarVisible = false"
      />
    </div>
  </div>
</template>

<script>
import Grid from "@/components/ReusableComponents/Grid/Grid.vue";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup.vue";
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import Permissions from "@/components/Permissions/Permissions.vue";
import axios from "@/Helpers/AxiosInstance.js";
import DeleteItem from "@/components/ReusableComponents/DeleteItem/DeleteItem.vue";
import { checkPermission } from "@/Helpers/HelperFunctions.js";
export default {
  components: {
    Grid,
    CustomPopup,
    CustomInput,
    CustomSnackbar,
    DeleteItem,
    Permissions,
  },
  created() {
    this.getData();
  },

  data() {
    return {
      accessPermissions: {
        getRoles: checkPermission("GetRoles"),
        createRole: checkPermission("CreateRole"),
      },
      gridData: [],
      addPopupVisible: false,
      newRole: {
        name: "",
        description: "",
      },
      searchTerm: "",
      snackbarPurpose: "error",
      snackbarText: "",
      snackbarVisible: false,
      cities: [],
      deletePopupVisible: false,
      selectedRoleID: null,
      gridParams: {
        currentPage: 1,
        pageSize: 20,
        orderByColumn: "ID",
        ascending: false,
        totalCount: 0,
      },
      permissionsPopupVisible: false,
    };
  },
  methods: {
    getData() {
      axios
        .get("Permission/GetRoles", {
          params: {
            Page: this.gridParams.currentPage,
            PageSize: this.gridParams.pageSize,
            OrderByColumn: this.gridParams.orderByColumn,
            Ascending: this.gridParams.ascending,
          },
        })
        .then((resp) => {
          this.gridData = resp.data.Value;
          this.gridParams.totalCount = resp.data.Paging.TotalItemCount;
        });
    },
    addNewClickHandler() {
      this.addPopupVisible = true;
      this.newRole.name = "";
      this.newRole.description = "";
    },
    addRole() {
      axios
        .post("Permission/CreateRole", {
          Name: this.newRole.name,
          Description: this.newRole.description,
        })
        .then((resp) => {
          this.getData();
          if (!resp.data.ErrorMsg) {
            this.addPopupVisible = false;
            this.snackbarVisible = true;
            this.snackbarText = "Successfully created new Role!";
            this.snackbarPurpose = "success";
          } else {
            this.addPopupVisible = false;
            this.snackbarPurpose = "error";
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
          }
        })
        .catch(() => {
          this.addPopupVisible = false;
          this.snackbarPurpose = "error";
          this.snackbarVisible = true;
          this.snackbarText = "An Error Occured While Adding New Office!";
        });
    },
    showDeletePopup(id) {
      this.selectedRoleID = id;
      this.deletePopupVisible = true;
    },
    deleteRow() {
      var item = this.gridData.filter((el) => el.ID == this.selectedRoleID)[0];
      var data = {
        Id: this.selectedRoleID,
        Name: item.Name,
        Deleted: true,
      };

      axios
        .post("Permission/UpdateRole", data)
        .then((resp) => {
          this.deletePopupVisible = false;
          if (!resp.data.ErrorMsg) {
            this.snackbarText = "Deleted Successfully!";
            this.snackbarPurpose = "success";
            this.snackbarVisible = true;
            this.getData();
          } else {
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
            this.snackbarPurpose = "error";
          }
        })
        .catch(() => {
          this.snackbarVisible = true;
          this.snackbarText = "Error";
          this.snackbarPurpose = "error";
        });
    },
    editRow(values) {
      values["Deleted"] = false;
      axios
        .post("Permission/UpdateRole", values)
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.getData();
            this.snackbarText = "Updated Successfully!";
            this.snackbarPurpose = "success";
            this.snackbarVisible = true;
          } else {
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
            this.snackbarPurpose = "error";
          }
        })
        .catch(() => {
          this.snackbarVisible = true;
          this.snackbarText = "Error";
          this.snackbarPurpose = "error";
        });
    },
    trClickHandler(element, e) {
      if (e.target.matches(".i-permissions")) {
        this.$router.push({
          name: "Permissions",
          params: { role: element, currentSection: "2" },
        });
      }
    },
    clearDescription() {
      if (this.addPopupVisible == false) {
        this.newRole.description = "";
      }
    },
  },
  computed: {
    deleteText() {
      if (this.gridData.length == 0 || !this.selectedRoleID) return "";
      var name = this.gridData.filter((el) => el.ID == this.selectedRoleID)[0]
        .Name;
      return `Would you like to delete role <strong>${name}</strong>?`;
    },
    filteredGridData() {
      if (!this.searchTerm) return this.gridData;
      return this.gridData.filter((el) => {
        return el.Name.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    },
    columns() {
      return [
        {
          title: "Name",
          key: "Name",
          editable: true,
          editType: "text",
        },
        {
          title: "Permissions",
          key: "",
          customTemplate: () =>
            `<img class="custom-icon i-permissions" src="${require("@/assets/permission.svg")}" ></img>`,
          tdClass: "icon",
        },
        {
          title: "Description",
          key: "Description",
          editable: true,
          editType: "text",
          customTemplate: (item, val) => (val ? val : ""),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.roles {
  // padding: 30 * $rem;
  overflow-y: scroll;

  .button-container {
    margin-bottom: 15 * $rem;
    width: 210 * $rem;
  }

  form {
    // display: flex;
    // align-items: center;
    // flex-direction: column;

    label,
    .select-wrapper {
      margin-right: 15 * $rem;
    }
    textarea {
      width: 400px;
      height: 120px;
      border: 0.0625rem solid #dcdde6;
      outline: none;
      resize: none;
      width: 400px;
      height: 120px;
      color: $blue-primary;
      font-size: 1rem;
      font-weight: 300;
      padding-left: 10 * $rem;
      padding-top: 5 * $rem;

      &::placeholder {
        color: rgba(40, 53, 147, 0.7);
        font-size: 1rem;
        font-weight: 300;
      }
    }
    label {
      display: block;
      margin-bottom: 15 * $rem;
    }
    .btn {
      float: right;
      margin-right: 15 * $rem;
    }
  }

  //   /deep/ .table-wrapper {
  //     // overflow-x: initial !important;
  //   }

  ::v-deep .permissions-overlay .popup {
    height: 90vh;
    width: 90%;
  }

  ::v-deep .i-permissions {
    font-size: 28 * $rem;
    cursor: pointer;

    &:hover {
      filter: brightness(1.1);
    }
  }
}
.permission-icon {
  height: 50px;
  width: 50px;
}
img {
  height: 50px;
  width: 50px;
}
::v-deep .custom-icon {
  height: 47px;
}
</style>
