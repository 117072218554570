<template>
  <div class="background">
    <div class="top-header">
      <ul>
        <li class="route">
          <router-link
            tag="span"
            :to="{ path: '/CareerAdmin' }"
            active-class="active"
          >
            Requested Jobs
          </router-link>
        </li>

        <li class="route">
          <router-link
            tag="span"
            :to="{ path: '/ApplicantTracking' }"
            active-class="active"
          >
            Applicant Tracking
          </router-link>
        </li>
      </ul>
    </div>
    <custom-loader v-if="loaderVisible" />

    <div
      v-if="filteredData.length < 1 && !loaderVisible"
      class="alternative-message"
    >
      <h1>There is no Records!</h1>
    </div>

    <div class="additional-page-wrapper">
      <div class="header-filters">
        <div class="follow-up">
          <router-link
            tag="span"
            :to="{ path: '/FollowUp' }"
            active-class="active"
          >
            Follow-up Templates
            <img src="@/assets/icons/arrow-right.svg" />
          </router-link>
        </div>
        <div class="filters">
          <ul>
            <li class="clear-all-filters">
              <span @click="clearFilters">Clear All</span>
            </li>
            <li
              class="removed-records"
              @click="getAllAppliedPositionInfo(true, true)"
            >
              <div>
                <span>Trash</span>
                <div class="dot" v-if="spam"></div>
              </div>
            </li>
            <li>
              <!-- <date-picker
                                v-model="filters.fromDate"
                                title="Request receive date"
                                class="date-picker"
                            /> -->
              <date-range
                :addCustom="'datetimerange'"
                :initialFrom="filters.fromDate"
                :initialTo="filters.toDate"
                @from="(from) => (filters.fromDate = from)"
                @to="(to) => (filters.toDate = to)"
              />
            </li>
            <li>
              <input-search
                title="Recruiter"
                :options="options.recruiters"
                @select="(id) => (filters.recruiterID = id)"
                :valueCleared="clearInputSearch"
                class="input-search"
              />
              <img
                class="arrow-icon"
                src="@/assets/icons/icon_arrow_down.svg"
                alt=""
              />
            </li>
            <li>
              <div class="search-applicant">
                <input type="text" v-model="filters.searchApplicant" />
                <div
                  class="search-title"
                  :class="{ focused: filters.searchApplicant }"
                >
                  Search applicant
                </div>
                <!-- <custom-input
                                    title="Search applicant"
                                    v-model="filters.searchApplicant"
                                /> -->
                <img
                  class="search-icon"
                  src="@/assets/icons/search.svg"
                  alt=""
                />
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>
                #
              </th>
              <th class="withFilters">
                <div class="thead-filters">
                  Applying for Job
                  <employee-filter
                    slot="searchJob"
                    @select="(val) => (val ? (filters.job = val) : null)"
                    :list="
                      filteredData.map((el) => ({
                        title: el.JobName,
                        value: el.JobID,
                      }))
                    "
                    :clearTriggered="clearEmployeFilter"
                    :alternateType="true"
                    :filteredIconVisible="filters.job ? true : false"
                    @clearFilterValue="filters.job = null"
                  />
                </div>
              </th>
              <th>
                Applicant
              </th>
              <th>
                Required Salary
              </th>
              <th class="withFilters">
                <div class="thead-filters">
                  Status
                  <employee-filter
                    slot="searchStatus"
                    @select="(val) => (val ? (filters.status = val) : null)"
                    :list="
                      filteredData.map((el) => ({
                        title: el.StatusName,
                        value: el.StatusID,
                      }))
                    "
                    :clearTriggered="clearEmployeFilter"
                    :alternateType="true"
                    :filteredIconVisible="filters.status ? true : false"
                    @clearFilterValue="filters.status = null"
                  />
                </div>
              </th>
              <th class="withFilters">
                <div class="thead-filters">
                  Rating
                  <employee-filter
                    slot="searchRate"
                    @select="
                      (val) =>
                        val && val !== 'notRated'
                          ? (filters.rating = parseInt(val))
                          : val === 'notRated'
                          ? (filters.rating = '0')
                          : null
                    "
                    :list="
                      rate.map((el) => ({
                        title:
                          el.value && el.value !== 'notRated'
                            ? el.value.toString()
                            : el.value === 'notRated'
                            ? '0'
                            : '0',
                        value: el.value
                          ? el.value
                          : el.value === 'notRated'
                          ? 0
                          : 0,
                      }))
                    "
                    :clearTriggered="clearEmployeFilter"
                    :alternateType="true"
                    :filteredIconVisible="filters.rating ? true : false"
                    @clearFilterValue="filters.rating = null"
                  />
                </div>
              </th>
              <th class="withFilters">
                <div class="thead-filters">
                  Channel
                  <employee-filter
                    slot="channel"
                    @select="(val) => (val ? (filters.source = val) : null)"
                    :list="
                      filteredData.map((el) => ({
                        title: el.SourceName,
                        value: el.SourceID,
                      }))
                    "
                    :clearTriggered="clearEmployeFilter"
                    :alternateType="true"
                    :filteredIconVisible="filters.source ? true : false"
                    @clearFilterValue="filters.source = null"
                  />
                </div>
              </th>

              <th>
                Follow-up
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              @click="getPositionDetails(position.OpenPositionApplicantID)"
              v-for="(position, ind) in filteredData"
              :key="ind"
              :class="{ 'not-seen': !position.Seen }"
            >
              <td>
                {{ filteredData.length - ind }}
              </td>

              <td>
                <div class="text-wrapper">{{ position.JobName }}</div>
              </td>

              <td class="applicantName">
                <img
                  v-if="position.SourceID == 1"
                  class="isInternal"
                  src="@/assets/icons/upgLogo.svg"
                  alt=""
                />
                <div>
                  {{ position.ApplicantFirstName }}
                  {{ position.ApplicantLastName }}
                </div>
              </td>

              <td>
                {{ position.RequiredSalary ? position.RequiredSalary : '-' }}
                {{
                  position.RequiredSalary
                    ? position.RequiredSalaryCurrencyCode
                    : ''
                }}
              </td>

              <td>
                <span :class="{ new: position.StatusName == 'New' }">
                  {{ position.StatusName }}
                </span>
              </td>

              <td>
                <rating
                  :rateState="position.Rate"
                  :disabled="true"
                  :starFill="'orange'"
                  :starStroke="'orange'"
                  :starsCount="5"
                  :starWidth="21"
                  :starHeight="21"
                />
                <!-- <div class="stars">
                                    <form>
                                        <input 
                                            class="star star-5" :id="`star5-`+ind" type="radio"
                                            :checked="position.Rate == 5 ? true : false" name="star"
                                        />
                                        <label class="star star-5" :for="`star5-`+ind"></label>
                                        <input 
                                            class="star star-4" :id="`star4-`+ind" type="radio"
                                            :checked="position.Rate == 4 ? true : false" name="star"
                                        />
                                        <label class="star star-4" :for="`star4-`+ind"></label>
                                        <input 
                                            class="star star-3" :id="`star3-`+ind" type="radio"
                                            :checked="position.Rate == 3 ? true : false" name="star"
                                        />
                                        <label class="star star-3" :for="`star3-`+ind"></label>
                                        <input 
                                            class="star star-2" :id="`star2-`+ind" type="radio"
                                            :checked="position.Rate == 2 ? true : false" name="star"
                                        />
                                        <label class="star star-2" :for="`star2-`+ind"></label>
                                        <input 
                                            class="star star-1" :id="`star1-`+ind" type="radio"
                                            :checked="position.Rate == 1 ? true : false" name="star"
                                        />
                                        <label class="star star-1" :for="`star1-`+ind"></label>
                                    </form>
                                </div> -->
              </td>

              <td>
                {{ position.SourceName }}
              </td>

              <td class="status-template-title">
                <div class="template-title">
                  {{
                    position.FollowUps[0]
                      ? position.FollowUps[position.FollowUps.length - 1]
                          .FollowUpStatusName
                      : '-'
                  }}
                </div>
                <div class="status-title-tooltip" v-if="position.FollowUps[0]">
                  {{
                    position.FollowUps[0]
                      ? position.FollowUps[position.FollowUps.length - 1]
                          .FollowUpStatusName
                      : '-'
                  }}
                </div>
              </td>
              <td
                v-if="!spam"
                @click.stop="
                  removeApplicantFromList(position.OpenPositionApplicantID)
                "
                class="removeApplicant"
              >
                <div>
                  <img src="@/assets/icons/delete-red.svg" alt="" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <custom-popup
        :visible="detailsPopupVisible"
        @close="closeDetailsPopup()"
        title="Applicant Tracking"
        class="position-details-popup"
      >
        <div class="header">
          <div class="job-name">{{ selectedPositionDetails.JobName }}</div>
          <div class="deadline">
            <span>Receive Date</span>
            <span>
              {{ convertDateToString(selectedPositionDetails.ReceiveDate) }}
            </span>
          </div>

          <!-- <div @click="closeDetailsPopup()" class="close">
                        <img src="@/assets/icons/close.svg" alt="">
                    </div> -->
        </div>
        <div class="details-content">
          <custom-loader v-if="detailsLoaderVisible" />
          <div class="applicant-details">
            <div class="left-content">
              <div class="single-detail">
                <div class="left-side">Name Surname</div>
                <div class="right-side">
                  {{
                    selectedPositionDetails.ApplicantFirstName
                      ? selectedPositionDetails.ApplicantFirstName
                      : ''
                  }}
                  {{ selectedPositionDetails.ApplicantLastName }}
                </div>
              </div>
              <div class="single-detail">
                <div class="left-side">Mobile</div>
                <div class="right-side">
                  {{
                    selectedPositionDetails.PersonMobile
                      ? selectedPositionDetails.PersonMobile
                      : ''
                  }}
                </div>
              </div>
              <div class="single-detail">
                <div class="left-side">Email</div>
                <div class="right-side">
                  {{
                    selectedPositionDetails.PersonMail
                      ? selectedPositionDetails.PersonMail
                      : ''
                  }}
                </div>
              </div>

              <div class="single-detail">
                <div class="left-side">LinkedIn</div>
                <div class="right-side">
                  <a
                    v-if="selectedPositionDetails.LinkedInUrl"
                    :href="validateLinkSrc(selectedPositionDetails.LinkedInUrl)"
                    target="blank"
                  >
                    <div class="link">
                      {{ selectedPositionDetails.LinkedInUrl }}
                    </div>
                  </a>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="single-detail">
                <div class="left-side">Portfolio</div>
                <div class="right-side link">
                  <a
                    v-if="selectedPositionDetails.Portfolio"
                    :href="validateLinkSrc(selectedPositionDetails.Portfolio)"
                    target="blank"
                  >
                    <div class="link">
                      {{ selectedPositionDetails.Portfolio }}
                    </div>
                  </a>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="single-detail">
                <div class="left-side">CV</div>
                <div
                  class="right-side download-cv"
                  :class="{ feedback: downloadFeedback }"
                >
                  <button
                    @click="
                      downloadApplicantFile(
                        selectedPositionDetails.ResumeFileID,
                      )
                    "
                  >
                    {{ selectedPositionDetails.FileAvailability }}
                  </button>
                </div>
              </div>
              <div class="single-detail">
                <div class="left-side">Required Salary</div>
                <div class="right-side">
                  {{
                    selectedPositionDetails.RequiredSalary
                      ? selectedPositionDetails.RequiredSalary
                      : '-'
                  }}
                  {{
                    selectedPositionDetails.RequiredSalaryCurrencyCode
                      ? selectedPositionDetails.RequiredSalaryCurrencyCode
                      : ''
                  }}
                </div>
              </div>
            </div>

            <div class="right-content">
              <div class="single-detail applicant-message">
                <div class="left-side">Message</div>
                <div class="right-side message">
                  {{ selectedPositionDetails.Message }}
                </div>
              </div>
            </div>
          </div>

          <div class="hr-edit">
            <div class="single-detail note">
              <div class="left-side">HR Note</div>
              <div class="right-side hr-note">
                <custom-text-area
                  title="Your Note"
                  :spellcheck="false"
                  :maxLength="9999999"
                  v-model="hrEdit.note"
                />
              </div>
            </div>
            <div class="single-detail">
              <div class="left-side">Rate</div>
              <div class="right-side">
                <rating
                  :rateState="selectedPositionDetails.Rate"
                  :disabled="false"
                  @value="(val) => (hrEdit.rate = val)"
                  :starFill="'orange'"
                  :starStroke="'orange'"
                  :starsCount="5"
                  :starWidth="23"
                  :starHeight="23"
                  :spaceBetween="7"
                />
                <!-- <div class="stars">
                                    <form action="">
                                        <input class="star star-5" value="5" v-model.number="hrEdit.rate" :checked="hrEdit.rate == 5 ? true : false" id="rate-star-5a" type="radio"/>
                                        <label class="star star-5" for="rate-star-5a"></label>
                                        <input class="star star-4" value="4" v-model.number="hrEdit.rate" :checked="hrEdit.rate == 4 ? true : false" id="rate-star-4a" type="radio"/>
                                        <label class="star star-4" for="rate-star-4a"></label>
                                        <input class="star star-3" value="3" v-model.number="hrEdit.rate" :checked="hrEdit.rate == 3 ? true : false" id="rate-star-3a" type="radio"/>
                                        <label class="star star-3" for="rate-star-3a"></label>
                                        <input class="star star-2" value="2" v-model.number="hrEdit.rate" :checked="hrEdit.rate == 2 ? true : false" id="rate-star-2a" type="radio"/>
                                        <label class="star star-2" for="rate-star-2a"></label>
                                        <input class="star star-1" value="1" v-model.number="hrEdit.rate" :checked="hrEdit.rate == 1 ? true : false" id="rate-star-1a" type="radio"/>
                                        <label class="star star-1" for="rate-star-1a"></label>
                                    </form>
                                </div> -->
              </div>
            </div>
            <div class="single-detail">
              <div class="left-side">Status</div>
              <div class="right-side status">
                <custom-select
                  :title="selectedPositionDetails.StatusName"
                  :options="applicantStatuses"
                  v-model="hrEdit.status"
                  :class="{ new: selectedPositionDetails.StatusName == 'New' }"
                />
              </div>
            </div>
            <div class="single-detail">
              <div class="left-side">Recruiter</div>
              <div class="right-side recruiter">
                <custom-select
                  :title="
                    selectedPositionDetails.RecruiterFirstName ||
                    selectedPositionDetails.RecruiterFirstName
                      ? selectedPositionDetails.RecruiterFirstName +
                        ' ' +
                        selectedPositionDetails.RecruiterLastName
                      : 'Recruiter'
                  "
                  :options="hrRolePersons"
                  v-model="hrEdit.recruiter"
                />
              </div>
            </div>
            <button class="save" @click="updateApplicantInfo()">Save</button>
            <div class="follow-ups">
              <div
                :class="['followUp-btn', { active: templates.collapsed }]"
                @click="
                  ;(templates.collapsed = !templates.collapsed), hrEditScroll()
                "
              >
                Follow-ups
              </div>
            </div>
            <div
              class="followUp-templates"
              id="status-templates"
              :class="{ collapsed: templates.collapsed }"
            >
              <div class="fill-template">
                <div class="template-header">
                  <span
                    class="template-title add-follow-up"
                    @click="
                      templates.CreateTemplateCollapsed = !templates.CreateTemplateCollapsed
                    "
                  >
                    Add Follow-up

                    <span
                      v-if="!templates.CreateTemplateCollapsed"
                      class="material-icons arrow-down"
                    >
                      keyboard_arrow_down
                    </span>

                    <span
                      v-if="templates.CreateTemplateCollapsed"
                      class="material-icons arrow-up"
                    >
                      keyboard_arrow_up
                    </span>
                  </span>
                </div>
                <div
                  class="create-template"
                  :class="{ collapsed: templates.CreateTemplateCollapsed }"
                >
                  <div class="template-fields">
                    <div class="left-field">
                      <ul>
                        <li>
                          <custom-select
                            title="Language"
                            :options="templates.options.languages"
                            v-model="followUpfilters.templateLang"
                          />
                        </li>
                        <li>
                          <custom-select
                            title="Status"
                            :options="templates.options.statuses"
                            v-model="followUpfilters.templateStatus"
                          />
                        </li>
                      </ul>
                    </div>
                    <div class="right-field">
                      <textarea
                        name="status-template"
                        spellcheck="false"
                        v-model="hrEdit.templateMessageText"
                        cols="30"
                        rows="10"
                      ></textarea>
                      <div class="status-actions">
                        <!-- <div class="edit-status" @click="hrEdit.editTemplate = !hrEdit.editTemplate">
                                                <img src="@/assets/icons/edit-gray.svg" alt="">
                                                </div> -->
                        <div
                          class="remove-status"
                          @click="hrEdit.templateMessageText = ''"
                        >
                          <img src="@/assets/icons/close-gray.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="template-actions">
                    <!-- <div class="checkbox">
                                            <div @click="hrEdit.sendMail = !hrEdit.sendMail" class="custom-checkbox">
                                                <div :class="{'checked': !hrEdit.sendMail}"></div>
                                            </div>
                                            <span>Make only status update, don't send email to applicant</span>
                                        </div> -->
                    <div class="template-send">
                      <button
                        class="template-update-btn"
                        @click="templateUpdate(), assignFollowUp()"
                      >
                        Save Status Update
                      </button>
                      <button
                        :class="[
                          'send-assigned-status',
                          { disabled: !hrEdit.sendMail },
                        ]"
                        @click="assignFollowUp()"
                      >
                        Send Follow-up
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="assigned-followUps"
                v-for="(template, ind) in selectedPositionDetails.FollowUps"
                :key="ind"
              >
                <div class="template-header">
                  <span class="template-title not-sent">
                    #{{(ind + 1)}} Follow-Up
                    <img
                      v-if="!template.MailSent"
                      src="@/assets/icons/notSend.svg"
                      alt=""
                    />
                  </span>
                  <span class="template-date">
                    {{
                      convertDateToString(template.CreateDate) +
                      ' ' +
                      extractHours(template.CreateDate)
                    }}
                  </span>
                </div>
                <div class="template-fields">
                  <div class="left-field">
                    <ul>
                      <li>
                        <custom-select
                          title="Language"
                          :options="templates.options.languages"
                          v-model="template.LanguageID"
                        />
                      </li>
                      <li>
                        <custom-select
                          title="Status"
                          :options="templates.options.statuses"
                          v-model="template.FollowUpStatusID"
                        />
                      </li>
                    </ul>
                  </div>
                  <div class="right-field">
                    <div class="assigned-status-message">
                      <span>Follow-up message</span>
                    </div>
                    <textarea
                      name="status-template"
                      :value="
                        template.TemplateMessageText.replace(/<br>/gi, '\n')
                      "
                      class="assigned-template-text"
                      spellcheck="false"
                      disabled="true"
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </custom-popup>
      <!-- <custom-popup
                :visible="iframeVisible"
                @close="iframeVisible = false"
                class="preview-cv"
            >
                <iframe style="border:none;"  width='100%' height='100%' :src="iframeVisible ? previewFile(selectedPositionDetails.FileType,selectedPositionDetails.ResumeFile,selectedPositionDetails.FileName) : ''"> </iframe>

            </custom-popup> -->
    </div>

    <custom-snackbar
      v-if="snackbar.visible"
      :purpose="snackbar.purpose"
      :text="snackbar.text"
      @close="snackbar.visible = false"
    />
  </div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance.js'
// import DateRange from "@/components/ReusableComponents/DateRange/DateRange";
import InputSearch from '@/components/ReusableComponents/InputSearch/InputSearch.vue'
import EmployeeFilter from '@/components/Employees/EmployeeFilter.vue'
// import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker";
// import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput"
import CustomPopup from '@/components/ReusableComponents/CustomPopup/CustomPopup'
import { getDateString, capitalize } from '@/Helpers/HelperFunctions'
import CustomTextArea from '@/components/ReusableComponents/CustomTextArea/CustomTextArea.vue'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import CustomSelect from '@/components/ReusableComponents/CustomSelect/CustomSelect.vue'
import DateRange from '@/components/ReusableComponents/DateRange/DateRange'
import CustomLoader from '@/components/ReusableComponents/Loader/CustomLoader.vue'
import Rating from '@/components/ReusableComponents/Rating/Rating.vue'

import { t } from 'element-ui/lib/locale'

export default {
  components: {
    // DateRange,
    InputSearch,
    EmployeeFilter,
    // DatePicker,
    // CustomInput,
    CustomPopup,
    CustomTextArea,
    CustomSelect,
    CustomSnackbar,
    DateRange,
    CustomLoader,
    Rating,
  },
  data() {
    return {
      filters: {
        // fromDate: 0,
        fromDate: 0,
        toDate: 0,
        recruiterID: '',
        searchApplicant: '',
        job: null,
        status: null,
        rating: null,
        source: null,
      },
      spam: false,
      followUpfilters: {
        templateLang: '',
        templateStatus: '',
      },
      innerFilters: {
        jobName: '',
        status: '',
        rate: '',
        channel: '',
      },
      options: {
        recruiters: [],
      },
      positionsData: [],
      applicantStatuses: [],
      rate: [
        {
          value: 'notRated',
        },
        {
          value: 1,
        },
        {
          value: 2,
        },
        {
          value: 3,
        },
        {
          value: 4,
        },
        {
          value: 5,
        },
      ],
      hrRolePersons: [],
      filteredData: [],
      detailsPopupVisible: false,
      selectedPositionID: null,
      selectedPositionDetails: {},
      hrEdit: {
        note: '',
        rate: '',
        status: '',
        recruiter: '',
        templateMessageText: '',
        sendMail: true,
        followUpStatusID: null,
        editTemplate: false,
      },
      snackbar: {
        text: '',
        purpose: '',
        visible: false,
      },
      downloadFeedback: false,
      followUps: [],
      templates: {
        collapsed: false,
        CreateTemplateCollapsed: false,
        options: {
          languages: [],
          statuses: [],
        },
      },
      iframeVisible: false,
      clearInputSearch: false,
      clearEmployeFilter: false,
      loaderVisible: false,
      detailsLoaderVisible: false,
    }
  },
  created() {
    this.getAllAppliedPositionInfo(true)
    this.getApplicantStatuses()
    this.getHrRolePersons()
    this.getFollowUpTemplates()
    this.getLanguages()
  },
  watch: {
    filters: {
      handler() {
        this.getAllAppliedPositionInfo(true)
      },
      deep: true,
    },
    followUpfilters: {
      handler() {
        if (this.followUpfilters.templateLang !== '') {
          let filtered = this.followUps.filter(
            (status) => status.LanguageID == this.followUpfilters.templateLang,
          )
          this.templates.options.statuses = filtered.map((status) => ({
            title: status.Name,
            value: status.FollowUpStatusID,
          }))
          this.hrEdit.templateMessageText = ''
        }
        if (this.followUpfilters.templateLang == '') {
          this.templates.options.statuses = this.followUps.map((status) => ({
            title: status.Name,
            value: status.FollowUpStatusID,
          }))
        }
        if (this.followUpfilters.templateStatus !== '') {
          let currentTemplate = this.followUps.filter(
            (template) =>
              template.FollowUpStatusID == this.followUpfilters.templateStatus,
          )
          let welcomeText = `Hello ${
            this.selectedPositionDetails.ApplicantFirstName
              ? this.selectedPositionDetails.ApplicantFirstName.capitalize()
              : ''
          } ,\n`
          this.hrEdit.templateMessageText = this.hrEdit.templateMessageText.concat(
            welcomeText,
            currentTemplate[0].TemplateMessageText,
          )
        }
        if (this.followUpfilters.templateStatus == '') {
          this.hrEdit.templateMessageText = ''
        }
      },
      deep: true,
    },
  },
  methods: {
    getAllAppliedPositionInfo(visibleLoader, spams) {
      console.log('sent')
      this.clearEmployeFilter = !this.clearEmployeFilter
      if (visibleLoader) this.loaderVisible = true
      if (spams) this.spam = !this.spam
      axios
        .get('openposition/GetOpenPositionAppliedInfoById', {
          params: {
            FromDate: this.filters.fromDate
              ? getDateString(this.filters.fromDate, 'yyyy/MM/dd')
              : null,
            ToDate: this.filters.toDate
              ? getDateString(this.filters.toDate, 'yyyy/MM/dd')
              : null,
            RecruiterID: this.filters.recruiterID
              ? this.filters.recruiterID
              : null,
            SearchTerm: this.filters.searchApplicant
              ? this.filters.searchApplicant
              : null,
            JobID: this.filters.job ? this.filters.job : null,
            StatusID: this.filters.status ? this.filters.status : null,
            Rating:
              this.filters.rating && this.filters.rating !== 'notRated'
                ? this.filters.rating
                : this.filters.rating === 'notRate'
                ? 0
                : null,
            SourceID: this.filters.source ? this.filters.source : null,
            Spams: this.spam,
          },
        })
        .then((resp) => {
          let data = resp.data.Value
          data.OpenPositionAppliedInfoWithFollowUps.forEach((el) => {
            el['AppliCantFullName'] =
              el.ApplicantFirstName + el.ApplicantLastName
            el['RateString'] = el.Rate === null ? '0' : el.Rate.toString()
            el.SourceName =
              el.SourceID === 1
                ? 'Internal'
                : el.SourceID === 2
                ? 'Upgaming.com'
                : 'Unknown'
          })
          this.positionsData = data.OpenPositionAppliedInfoWithFollowUps.sort(
            (a, b) => b.OpenPositionApplicantID - a.OpenPositionApplicantID,
          )
          this.filteredData = data.OpenPositionAppliedInfoWithFollowUps.sort(
            (a, b) => b.OpenPositionApplicantID - a.OpenPositionApplicantID,
          )
          this.loaderVisible = false
        })
    },
    updateApplicantInfo() {
      axios
        .post('openposition/UpdateOpenPositionAppliedInfo', {
          id: this.selectedPositionID,
          hrNote: this.hrEdit.note,
          rate: this.hrEdit.rate,
          status: this.hrEdit.status,
          recruiterID: this.hrEdit.recruiter,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.snackbar.visible = true
            this.snackbar.purpose = 'success'
            this.snackbar.text = 'Your request has been saved successfully'

            this.closeDetailsPopup()
            this.getAllAppliedPositionInfo()
          } else {
            this.snackbar.visible = true
            this.snackbar.purpose = 'error'
            this.snackbar.text = resp.data.ErrorMsg
          }
        })
    },
    getHrRolePersons() {
      axios.get('openposition/GetPersonsWithHrRole').then((resp) => {
        let hrList = resp.data.Value.map((el) => ({
          title: el.FirstName + ' ' + el.LastName,
          value: el.PersonID,
        }))
        this.hrRolePersons = hrList
        this.options.recruiters = hrList
      })
    },
    filterByRecruiter(id) {
      this.filteredData = this.filteredData.filter((el) => el.RecruiterID == id)
    },
    getApplicantStatuses() {
      axios
        .get('openposition/GetOpenPositionApplicantStatuses')
        .then((resp) => {
          this.applicantStatuses = resp.data.Value.map((el) => ({
            title: el.StatusName,
            value: el.StatusID,
          }))
        })
    },
    filterBy(arr, value, key) {
      value = value.trim().toLowerCase().split(' ').join('')
      this.filteredData = arr.filter((el) =>
        el[key].trim().toLowerCase().split(' ').join('').includes(value),
      )
      if (!value.length) return (this.filteredData = this.positionsData)
    },
    getPositionDetails(id) {
      this.detailsPopupVisible = true
      this.detailsLoaderVisible = true
      this.selectedPositionID = id

      axios
        .get('openposition/GetOpenPositionAppliedInfoById', {
          params: {
            ID: id,
          },
        })
        .then((resp) => {
          let data = resp.data.Value.OpenPositionAppliedInfoWithFollowUps[0]
          this.detailsLoaderVisible = false
          this.ResumeFileUrl = data ? data.ResumeFileUrl : null

          if (
            data.FileType.includes('image') ||
            data.FileType == 'application/pdf'
          ) {
            data['FileAvailability'] = 'Open'
          } else {
            data['FileAvailability'] = 'Download'
          }

          this.selectedPositionDetails = data
          this.hrEdit.rate = data.Rate
          this.hrEdit.note = data.HrNote

          if (data.FollowUps.length) {
            this.templates.CreateTemplateCollapsed = true
          } else {
            this.templates.CreateTemplateCollapsed = false
          }
        })

      let seenObj = this.positionsData.filter(
        (el) => el.OpenPositionApplicantID === id,
      )

      if (!seenObj[0].Seen) {
        axios
          .post('openposition/MakeOpenPositionAppliedInfoSeen', {
            openPositionApplicantID: id,
          })
          .then((resp) => {
            seenObj[0].Seen = true
            // this.getAllAppliedPositionInfo(false)
          })
      }
    },
    convertDateToString(timestamp) {
      let t = new Date(timestamp)
      let months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      let year = t.getFullYear()
      let month = months[t.getMonth()]
      let date = t.getDate()
      let time = `${date} ${month} ${year}`
      return time
    },
    extractHours(timestamp) {
      const dateObject = new Date(timestamp)

      const hours = dateObject.getHours()
      const minutes = dateObject.getMinutes()
      let meridiem = hours >= 12 ? 'PM' : 'AM'

      const timeString = `${hours}:${minutes}${meridiem}`
      return timeString
    },

    downloadCV(contentType, base64Data, fileName) {
      this.downloadFeedback = true
      setTimeout(() => {
        this.downloadFeedback = false
      }, 500)
      if (!this.selectedPositionDetails.ResumeFile) return

      const linkSource = `data:${contentType};base64,${base64Data}`
      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    },
    closeDetailsPopup() {
      this.detailsPopupVisible = false
      this.selectedPositionID = null
      this.selectedPositionDetails = {}
      this.hrEdit.note = ''
      this.hrEdit.rate = null
      this.hrEdit.status = ''
      this.hrEdit.recruiter = ''
      this.hrEdit.sendMail = true
      this.templates.collapsed = false
      this.hrEdit.templateMessageText = ''
      this.hrEdit.followUpStatusID = ''
      this.hrEdit.editTemplate = ''
      this.followUpfilters.templateLang = ''
      this.followUpfilters.templateStatus = ''

      this.getAllAppliedPositionInfo(true, false)
    },

    getFollowUpTemplates() {
      axios.get('openposition/GetFollowUpStatuses').then((resp) => {
        let data = resp.data.Value
        this.followUps = data
        this.templates.options.statuses = data.map((status) => ({
          title: status.Name,
          value: status.FollowUpStatusID,
          lang: status.LanguageID,
        }))
      })
    },
    getLanguages() {
      axios.get('openposition/GetLanguages').then((resp) => {
        let data = resp.data.Value
        this.templates.options.languages = data.map((lang) => ({
          title: lang.Code,
          value: lang.LanguageID,
        }))
      })
    },
    templateUpdate() {
      this.hrEdit.sendMail = false
    },
    assignFollowUp() {
      axios
        .post('openposition/CreateFollowUp', {
          openPositionApplicantID: this.selectedPositionDetails
            .OpenPositionApplicantID,
          followUpStatusID: this.followUpfilters.templateStatus,
          templateMessageText: this.hrEdit.templateMessageText.replace(
            /\n/g,
            '<br>',
          ),
          sendMail: this.hrEdit.sendMail,
          mail: this.selectedPositionDetails.PersonMail,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.snackbar.visible = true
            this.snackbar.purpose = 'success'
            this.snackbar.text = 'Your request has been saved successfully'

            this.followUpfilters.templateStatus = ''
            this.hrEdit.templateMessageText = ''
            this.hrEdit.sendMail = true
            this.followUpfilters.templateLang = ''
            this.followUpfilters.templateStatus = ''

            this.getAllAppliedPositionInfo()
            setTimeout(() => {
              this.getPositionDetails(this.selectedPositionID)
            }, 1000) //alternative solution temp
          } else {
            this.snackbar.visible = true
            this.snackbar.purpose = 'error'
            this.snackbar.text = resp.data.ErrorMsg
            return
          }
        })
    },
    hrEditScroll() {
      let target = document.querySelector('.hr-edit')
      setTimeout(() => {
        target.scrollTo({
          top: `${target.getBoundingClientRect().x}`,
          behavior: 'smooth',
        })
      }, 100)
    },
    previewFile(contentType, base64Data, fileName) {
      if (
        (this.selectedPositionDetails.ResumeFile &&
          contentType == 'application/pdf') ||
        contentType == 'image/png' ||
        contentType == 'image/jpeg' ||
        contentType == 'image/jpg'
      ) {
        return `data:${contentType};base64,${base64Data}`
      } else {
        this.iframeVisible = false
        this.downloadCV(contentType, base64Data, fileName)
        return
      }
    },
    clearFilters() {
      this.clearEmployeFilter = !this.clearEmployeFilter
      this.filters.recruiterID = null
      this.filters.fromDate = null
      this.clearInputSearch = !this.clearInputSearch
      this.filters.searchApplicant = null
      this.filters.toDate = null
      this.filters.job = null
      this.filters.status = null
      this.filters.rating = null
      this.filters.source = null
      this.spam = false

      this.innerFilters.channel = ''
      this.innerFilters.jobName = ''
      this.innerFilters.rate = ''
      this.innerFilters.status = ''
    },
    validateLinkSrc(link) {
      let src = `https://${link}`
      if (link.startsWith('https')) return link
      else return src
    },

    downloadApplicantFile(id) {
      if (this.ResumeFileUrl) {
        window.open(this.ResumeFileUrl, '_blank')
      }
    },

    removeApplicantFromList(id) {
      this.$confirm('Would you like to remove this record?').then(() => {
        axios
          .post('openposition/UpdateOpenPositionAppliedInfo', {
            id: id,
            isSpam: true,
          })
          .then((resp) => {
            if (resp) {
              this.snackbar.visible = true
              this.snackbar.purpose = 'success'
              this.snackbar.text = 'Your request has been saved successfully'
            }
            this.getAllAppliedPositionInfo(true)
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.top-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
  padding-bottom: 0.625rem;
  ul {
    list-style: none;

    li.route {
      margin-left: 1.875rem;
      display: inline-flex;
      &:first-child {
        margin-left: 0;
      }
      span {
        font-size: 0.9375rem;
        color: $blue-primary;
        font-weight: 500;
        line-height: 1.1875rem;
        cursor: pointer;
        position: relative;

        &.active {
          &::after {
            content: '';
            width: 100%;
            height: 0.25rem;
            background: $green-primary;
            z-index: 999;
            position: absolute;
            display: block;
            bottom: -0.625rem;
            left: 0;
          }
        }
      }
    }
  }
}

.background {
  overflow: hidden !important;
}

.additional-page-wrapper {
  width: 100%;
  height: 100%;

  .header-filters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1.875rem 1.625rem;

    .follow-up {
      margin-left: 1.25rem;
      font-size: 0.9375rem;
      color: $blue-primary;
      font-weight: 500;
      line-height: 1.1875rem;
      display: inline-flex;
      cursor: pointer;

      img {
        width: 0.4375rem;
        margin-left: 0.3125rem;
        transition: ease 0.2s;
      }

      &:hover {
        img {
          filter: invert(67%) sepia(40%) saturate(568%) hue-rotate(104deg)
            brightness(132%) contrast(91%);
        }
      }
    }

    .filters {
      ul {
        display: inline-flex;
        list-style: none;

        li {
          margin-left: 0.9375rem;
          position: relative;

          &.removed-records {
            display: grid;
            place-items: center;
            background: rgba($blue-primary, 0.5);
            border-radius: 4px;
            position: relative;
            cursor: pointer;
            box-shadow: 0 0 0.625rem rgba($blue-primary, 0.2);
            transition: ease 0.3s;
            &:hover {
              box-shadow: 0 0 0.625rem rgba($blue-primary, 0.5);
            }
            > div {
              font-size: 0.875rem !important;
              padding: 0 0.875rem;
              color: #fff;
            }

            .dot {
              position: absolute;
              top: -0.3125rem;
              left: -0.3125rem;
              width: 0.9375rem;
              height: 0.9375rem;
              border-radius: 50%;
              background: lighten($red, 12%);
            }
          }

          &.clear-all-filters {
            @include centerElement();
            padding-right: 0.75rem;

            &:hover {
              span {
                color: rgba($red, 0.5);
              }
              &::before {
                height: calc(100% - 0.75rem);
              }
            }
            span {
              color: rgba($blue-primary, 0.5);
              font-size: 1rem;
              cursor: pointer;
              transition: ease 0.3s;
            }

            &::after {
              content: '';
              position: absolute;
              right: 0;
              top: 50%;
              transform: translate(0, -50%);
              height: calc(100% - 0.75rem);
              width: 0.0625rem;
              background: rgba(#5769d6, 0.2);
            }

            &::before {
              content: '';
              position: absolute;
              right: 0;
              top: 50%;
              transform: translate(0, -50%);
              height: 0;
              width: 0.0625rem;
              background: rgba(#5769d6, 0.8);
              transition: ease 0.3s;
            }
          }
          &:first-child {
            margin-left: 0;
          }

          ::v-deep {
            .input-search {
              .title {
                color: #959bc9;
                font-size: 0.875rem;
                font-weight: 400;
              }
            }
          }

          .search-applicant {
            width: 12.9375rem;
            height: 2.5rem;
            position: relative;
            margin-right: 4.625rem;
            .search-icon {
              position: absolute;
              height: 1.3125rem;
              width: 0.875rem;
              top: 50%;
              right: 1rem;
              transform: translate(0, -50%);
            }

            .search-title {
              position: absolute;
              top: 0.75rem;
              font-size: 0.875rem;
              color: #959bc9;
              left: 0.5625rem;
              transition: ease 0.2s;
              pointer-events: none;

              &.focused {
                top: -0.5rem;
              }
            }

            // ::v-deep .title{
            //     color: #959BC9;
            //     font-size: 0.875rem;
            //     font-weight: 400;
            // }
            input[type='text'] {
              width: 100%;
              height: 2.5rem;
              outline: none;
              box-sizing: border-box;
              padding: 0 0.625rem;
              border-radius: 0.375rem;
              font-weight: 500;
              color: #959bc9;
              font-size: 0.9375rem;
              font-weight: 400;

              &:focus ~ .search-title {
                top: -0.5rem;
              }

              &::placeholder {
                color: rgba($blue-primary, 0.7);
              }
            }
          }
          .arrow-icon {
            width: 0.9rem;
            position: absolute;
            right: 1rem;
            top: 1rem;
          }

          ::v-deep input {
            border: none;
          }
        }
      }

      ::v-deep .date-picker {
        .calendar-icon {
          width: 2.3rem;
          right: 0.4375rem;
          top: 0;
          opacity: 0.5;
        }

        .title {
          font-size: 0.875rem;
          color: #959bc9;
          font-weight: 400;
          margin-top: 0.125rem;
        }
      }
    }
  }

  .table-wrapper {
    overflow: auto;
    height: calc(100% - 8rem);
    padding-bottom: 2rem;

    table {
      width: 100%;
      text-align: left;
      margin: auto;
      border-collapse: collapse;

      tr {
        height: 3.75rem;
        td,
        th {
          width: 14rem;
          font-size: 0.875rem;
          &:first-child {
            padding-left: 3rem;
            width: 11rem !important;
          }
        }
      }

      thead {
        tr {
          background: #ededfc;
          z-index: 5;
          height: 3.125rem;
          position: sticky;
          top: 0;

          th {
            color: $blue-primary;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.125rem;
            position: relative;

            .thead-filters {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
          }
        }
      }

      tbody {
        tr {
          transition: ease 0.3s;
          position: relative;
          cursor: pointer;
          &:hover {
            background-color: rgba(#959bc9, 0.15);

            td.removeApplicant {
              opacity: 1;
              pointer-events: all;
            }
          }

          border-bottom: 0.0625rem solid rgba(#6956d6, 0.06);

          &.not-seen {
            font-weight: 700 !important;

            td {
              .stars {
                label.star {
                  font-weight: 400;
                }
              }
            }
          }

          td {
            color: $blue-primary;
            position: relative;
            padding-right: 2rem;
            vertical-align: middle;
            &:first-child {
              padding-right: 0;
            }

            &:nth-child(2) {
              width: 20rem;
            }

            &.removeApplicant {
              opacity: 0;
              pointer-events: none;
              position: absolute;
              right: -11.5rem;
              top: 50%;
              transform: translate(0, -50%);
              transition: ease 0.2s;
            }

            &.applicantName {
              text-transform: capitalize;
              div {
                width: 10rem !important;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }

            .stars {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              user-select: none;
              pointer-events: none;

              input.star {
                display: none;
              }

              label.star {
                float: right;
                font-size: 2rem;
                color: #d4d4d4;
                transition: all 0.2s;
                cursor: pointer;
              }

              input {
                &.star:checked ~ label.star:before {
                  content: '★';
                  color: #ffa346;
                  transition: all 0.25s;
                }
              }

              label.star {
                &:before {
                  content: '★';
                }
              }
            }

            .isInternal {
              position: absolute;
              left: -1.875rem;
            }

            .text-wrapper {
              width: 15rem !important;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            span {
              &.new {
                color: #ff5f58;
                font-weight: 500;
              }
            }

            &.status-template-title {
              &:hover {
                .status-title-tooltip {
                  display: block;
                }
              }
              position: relative;
              > div.template-title {
                width: 10rem;
                height: 2.4rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                @include flex(flex-start, center, row);
              }
              .status-title-tooltip {
                width: 10.625rem;
                min-height: 2rem;
                position: absolute;
                left: -0.1rem;
                top: 81%;
                background: #5763bb;
                color: #fff;
                justify-content: center;
                align-items: center;
                padding: 0.625rem;
                border-radius: 0.375rem;
                display: none;
                z-index: 2;
              }
            }
          }
        }
      }
    }
  }

  .position-details-popup {
    ::v-deep .popup {
      height: unset;
      width: 83rem;
      padding-top: 1.25rem;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;
      h3.title {
        text-transform: capitalize;
        padding: 0 2.5rem;
      }
    }

    button.save {
      width: 6.4375rem;
      height: 2.8125rem;
      background: #5963ab;
      border-radius: 0.375rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.3125rem;
      color: #fff;
      cursor: pointer;
      float: right;
      margin-top: 0.9375rem;
      margin-right: 3rem;
      transition: ease 0.3s;

      &:hover {
        box-shadow: 0 0 0.9375rem rgba($blue-primary, 0.2);
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.125rem solid rgba(149, 155, 201, 0.2);
      padding-bottom: 1.25rem;
      position: relative;
      padding: 0.85rem 2.5rem;

      .close {
        position: absolute;
        right: 1.5rem;
        top: -4rem;

        &:hover {
          filter: invert(23%) sepia(92%) saturate(7465%) hue-rotate(359deg)
            brightness(92%);
        }

        img {
          width: 30px;
          height: 1.875rem;
          cursor: pointer;
          opacity: 0.6;
        }
      }

      .deadline {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        span:nth-child(1) {
          font-weight: 300;
          color: #959bc9;
        }
        span:nth-child(2) {
          font-size: 1.25rem;
          font-weight: 500;
          color: #959bc9;
        }
      }
      .job-name {
        padding: 0.75rem 1.875rem;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 900;
        background-color: #6956d6;
        border-radius: 0.1875rem;
        display: inline-block;
      }
    }

    .details-content {
      height: calc(100% - 9.5rem);
      overflow: hidden;
      width: 100%;
      padding-right: 0.3125rem;

      .applicant-details {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        .left-content {
          width: 45%;
        }

        .right-content {
          width: 50%;

          .applicant-message {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-right: 1rem;

            .message {
              margin-top: 0.875rem;
              height: 16rem;
              overflow: auto;
            }
          }
        }
      }

      .single-detail {
        margin-top: 1.5625rem;
        padding: 0 2.5rem;

        .left-side {
          color: #959bc9;
          font-size: 0.875rem;
          font-weight: 500;
        }
      }

      .hr-edit {
        height: 24.6rem;
        margin-top: 2.625rem;
        background: rgba(#dfe0f4, 0.1);
        overflow: auto;
        padding-bottom: 2rem;
        position: relative;

        .left-side {
          width: 8.5rem !important;
        }

        .note {
          align-items: flex-start;
        }

        .single-detail {
          margin-top: 1.5625rem;
        }

        .follow-ups {
          width: 5rem;
          margin-top: 2.5rem;
          margin-left: 2.5rem;
          cursor: pointer;

          .followUp-btn {
            font-size: 1rem;
            font-weight: 500;
            color: #719fe2;
            line-height: 1.3125rem;
            position: relative;

            &.active {
              color: rgba(#719fe2, 0.7);

              &:after {
                content: '';
                width: 100%;
                height: 0.0625rem;
                background: #719fe2;
                position: absolute;
                left: -0.0625rem;
                bottom: -0.125rem;
                display: block;
                transition: ease 0.3s;
                opacity: 0.5;
              }
            }

            &:after {
              content: '';
              width: 0;
              height: 0.125rem;
              background: #719fe2;
              position: absolute;
              left: -0.0625rem;
              bottom: -0.125rem;
              display: block;
              transition: ease 0.3s;
            }

            &:hover:after {
              width: 100%;
            }
          }
        }

        .followUp-templates {
          width: 100%;
          padding: 0 2.5rem;
          margin-top: 2.5rem;
          height: 0;
          min-height: 0;
          overflow: hidden;
          transition: ease 1s;

          > * {
            transition: ease 1s;
            opacity: 0;
          }

          &.collapsed {
            overflow: visible;
            transition: ease 1s;

            > * {
              opacity: 1;
            }
          }

          .assigned-followUps {
            margin-top: 2rem;
            padding-bottom: 3rem;

            ::v-deep {
              .selected-box {
                pointer-events: none;
                user-select: none;
                opacity: 0.8;
                .material-icons > .arrow-down,
                .arrow-down {
                  display: none;
                }
              }
            }
          }
          .fill-template,
          .assigned-followUps {
            border-bottom: 0.0625rem solid rgba(#707070, 0.12);
            .template-header {
              @include flex(space-between, center, row);

              span {
                color: $soft-blue;
                font-size: 0.9375rem;
                font-weight: 400;
              }

              .template-title {
                &.not-sent {
                  @include flex(flex-start, center, row);
                  img {
                    width: 1.1875rem;
                    margin-left: 0.9375rem;
                  }
                }

                &.add-follow-up {
                  display: inline-flex;
                  cursor: pointer;
                  transition: ease 0.3s;
                  .material-icons {
                    margin-left: 0.3125rem;
                  }

                  &:hover {
                    color: #5963ab;

                    .material-icons {
                      color: #5963ab;
                    }
                  }
                }
              }
            }

            .create-template {
              height: 15rem;
              transition: ease 0.6s;
              opacity: 1;
              &.collapsed {
                height: 0 !important;
                opacity: 0;
                overflow: hidden;
                margin-top: 1rem !important;
                padding-bottom: 0 !important;
              }
            }

            .template-fields {
              margin-top: 1.5rem;
              @include flex(space-between, flex-start, row);
              .left-field {
                ul {
                  list-style: none;
                  @include flex(unset, left, column);
                  li {
                    width: 12.625rem;
                    margin-top: 2.4rem;

                    &:first-child {
                      margin-top: 0;
                    }
                  }
                }
              }

              .right-field {
                position: relative;
                textarea {
                  width: 61.4375rem;
                  height: 7.5625rem;
                  resize: none;
                  outline: none;
                  border: rgba($blue-primary, 0.2) 0.0625rem solid;
                  box-sizing: border-box;
                  padding: 0.625rem 1rem;
                  color: #959bc9;
                  font-size: 0.875rem;
                  line-height: 1.3125rem;
                  border-radius: 0.1875rem;
                  transition: ease 0.3s;
                  background: rgba($grey-border, 0.2);

                  &:focus {
                    background: #fff;
                  }
                }
                .assigned-status-message {
                  color: rgba($blue-primary, 0.5);
                  font-size: 0.9375rem;
                  padding-left: 1rem;
                }

                textarea {
                  &.assigned-template-text {
                    height: 6.1625rem;
                    border: none;
                    background: none;
                  }
                }

                .status-actions {
                  position: absolute;
                  display: inline-flex;
                  right: 1.25rem;
                  top: 0.75rem;
                  cursor: pointer;

                  .remove-status {
                    margin-left: 0.875rem;
                    &:hover {
                      img {
                        filter: invert(33%) sepia(52%) saturate(5436%)
                          hue-rotate(345deg) brightness(107%) contrast(107%);
                      }
                    }
                  }

                  .edit-status {
                    &:hover {
                      img {
                        filter: invert(1111%) sepia(59%) saturate(3343%)
                          hue-rotate(230deg) brightness(68%) contrast(36%);
                      }
                    }
                  }
                }
              }
            }
            .template-actions {
              margin-top: 2.4375rem;
              padding-bottom: 1rem;
              @include flex(flex-end, center, row);

              // .checkbox{
              //     display: inline-flex;
              //     .custom-checkbox{
              //         width: 1.0625rem;
              //         height: 1.0625rem;
              //         border: 0.0625rem solid #1DE9B6;
              //         border-radius: 50%;
              //         overflow: hidden;
              //         display: grid;
              //         place-items: center;
              //         margin-left: 0.3125rem;
              //         cursor: pointer;

              //         div{
              //             width: calc(100% - 0.125rem);
              //             height: calc(100% - 0.125rem);
              //             border-radius: 50%;

              //             &.checked {
              //                 background: #1DE9B6;
              //             }
              //         }
              //     }
              //     span{
              //         font-size: 0.875rem;
              //         color: $blue-primary;
              //         line-height: 1.1875rem;
              //         margin-left: 0.625rem;
              //     }
              // }

              .template-send {
                display: inline-flex;

                button {
                  @include centerElement;
                  color: $blue-primary;
                  font-size: 1rem;
                  outline: none;
                  border-radius: 0.375rem;
                  width: 8.75rem;
                  height: 2.8125rem;
                  cursor: pointer;
                  font-weight: 450;
                  background: rgba($blue-primary, 0.7);
                  margin-right: 0.9375rem;
                  color: #fff;
                  transition: ease 0.3s;

                  &:hover {
                    box-shadow: 0 0 0.9375rem rgba($blue-primary, 0.2);
                  }

                  &:last-child {
                    margin-right: 0;
                  }

                  &.template-update-btn {
                    width: 10.625rem;
                    background: rgba(#dfe0f4, 0.1);
                    color: $blue-primary;
                    border: 0.0625rem solid $blue-primary;

                    &:hover {
                      color: #fff;
                      background: rgba($blue-primary, 0.7);
                      border: transparent 0.0625rem;
                    }
                  }

                  &.send-assigned-status {
                    &.disabled {
                      pointer-events: none;
                      opacity: 0.5;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .single-detail {
        @include flex(unset, center, row);

        .left-side {
          color: #959bc9;
          font-size: 0.875rem;
          font-weight: 500;
          width: 12.25rem;
          word-break: break-word;
        }
        .right-side {
          color: #3f46bf;
          font-size: 0.9375rem;
          font-weight: 400;
          width: 100%;
          padding-right: 2rem;

          .link {
            width: 19rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          a {
            text-decoration: none;
            color: #3f46bf;
          }

          &.message {
            line-height: 1.25rem;
            color: #959bc9;
          }

          &.download-cv {
            button {
              width: 7.8125rem;
              height: 2.1875rem;
              background: rgba(#5769d6, 0.5);
              color: #283593;
              font-size: 0.8125rem;
              border: none;
              outline: none;
              cursor: pointer;
              border-radius: 0.1875rem;
              transition: ease 0.2s;
            }

            &.feedback {
              button {
                background: $green-primary;
                color: #fff;
              }
            }
          }

          &.hr-note {
            ::v-deep textarea {
              width: 100%;
              height: 6rem;
              resize: none;
              border: 0.0625rem solid rgba($blue-primary, 0);
              background: transparent;
              transition: ease 0.3s;

              &:focus {
                border: 0.0625rem solid rgba($blue-primary, 0.1);
              }
            }

            ::v-deep .title {
              color: rgba($blue-primary, 0.7);
              background: rgb(255, 255, 255);
            }
          }

          .stars {
            width: 100%;
            display: inline-flex;
            user-select: none;
            margin-top: -0.6rem;

            form {
              @include flex(unset, center, row-reverse);
            }

            input.star {
              display: none;
            }

            label.star {
              font-size: 2.0625rem;
              color: #959bc9;
              transition: all 0.2s;
              cursor: pointer;

              &:hover {
                transform: translateY(-0.1875rem);
              }
            }

            input {
              &.star:checked ~ label.star:before {
                content: '★';
                color: #ffa346;
                transition: all 0.25s;
              }

              &.star:hover ~ label.star:before {
                color: rgba(#ffa346, 0.8);
              }
            }

            label.star {
              &:before {
                content: '★';
              }
            }
          }

          &.status,
          &.recruiter {
            ::v-deep .selected-box {
              width: 12.625rem;
            }
          }

          &.status {
            ::v-deep .select-wrapper {
              &.new {
                .title {
                  color: #f11d1d;
                  font-weight: 400;
                }
              }
            }
          }

          .quantity {
            padding: 0.3125rem 0.75rem;
            background-color: #6956d6;
            color: #fff;
            font-weight: 500;
            display: inline-block;
            border-radius: 0.3125rem;
          }
          .single-skill {
            display: inline-block;
            padding: 0.3125rem;
            font-size: 0.8125rem;
            font-weight: 300;
            border-radius: 0.3125rem;
            margin-right: 0.3125rem;
            margin-bottom: 0.3125rem;
            border: 0.0313rem solid #959bc9;
          }
          .single-resp:not(:last-child) {
            margin-bottom: 0.625rem;
          }
        }
      }
    }
  }
}

.preview-cv {
  ::v-deep {
    .popup {
      width: 85rem;
      height: calc(100% - 2rem);
      padding: 0 !important;
      overflow: hidden;
    }
    .title {
      display: none;
    }
  }
}
</style>
