<template>
    <div class="bookmarks-content" ref="bookmark">
        <a
            v-for="el in data"
            :href="el.URL"
            target="_blank"
            @mouseover="el.showDeleteICon = true"
            @mouseleave="el.showDeleteICon = false"
            :key="el.index"
            class="single-link-content"
            @dragstart="$emit('folder-bookmark-drag-started', el)"
        >
            <span><img :src="getObjectIcon(el.URL)" /></span>
            <span v-if="el.Title" class="title">{{ el.Title }}</span>
            <span class="link" v-else>
                <span>
                    {{ el.URL }}
                </span>
            </span>
            <span
                v-if="el.showDeleteICon == true"
                @click.stop.prevent="$emit('delete-clicked', el)"
                class="material-icons"
            >
                close</span
            >
        </a>
        <span class="empty-text" v-if="!data.length">Empty</span>
    </div>
</template>

<script>
export default {
    created() {
        document.addEventListener('click', this.documentClick)
    },
    props: {
        data: Array,
    },
    data() {
        return {
            showDeleteICon: false,
        }
    },
    methods: {
        getObjectIcon(url) {
            return `https://s2.googleusercontent.com/s2/favicons?domain_url=${url}`
        },
        documentClick(event) {
            let specifiedElement = this.$refs.bookmark

            let targetEl = event.target

            if (targetEl != specifiedElement) {
                this.$emit('close-bookmarks')
            }
        },
    },
    destroyed() {
        document.removeEventListener('click', this.documentClick)
    },
}
</script>

<style scoped lang="scss">
.bookmarks-content {
    position: fixed;
    padding: 10px;
    width: 350px;
    background-color: #fff;
    box-shadow: 0.125rem 0.125rem 0.125rem 0.125rem rgba(40, 52, 147, 0.08);
    border-radius: 5px;
}
.material-icons {
    font-size: 1rem;
    position: absolute;
    right: 0.3125rem;
    top: 0;
    margin-left: auto;
}
.link {
    height: unset !important;
    span {
        max-width: 290px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block !important;
    }
}
.empty-text {
    color: $blue-primary;
    font-size: 14px;
    display: block;
    text-align: center;
}
.single-link-content {
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    transition: all 0.2s ease-out;
    padding-left: 5px;
    font-size: 13px;
    color: $blue-primary;
    position: relative;
    span {
        line-height: 25px;
        display: flex;
        align-items: center;
    }
    &:hover {
        background-color: #eeeeef;
        cursor: pointer;
    }

    span:nth-child(2) {
        margin-left: 3px;
        max-height: 1.875rem;
    }
}
</style>