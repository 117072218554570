<template>
  <div>
    <div class="grid">
      <div
        :class="{ pagination: pagination, alternativeStyle: alternativeStyle }"
        class="table-wrapper block"
        :style="gridStyle"
      >
        <div class="background-for-header" v-if="!alternativeStyle">
          <div class="grid-header" v-if="canAdd || canSearch">
            <input
              v-if="canSearch"
              type="text"
              class="search"
              placeholder="Search ..."
              @input="$emit('search', $event.target.value)"
            />
            <add-button v-if="canAdd" @clicked="$emit('add-click')" />
          </div>
        </div>
        <div
          class="table-helper"
          :class="{ alternativeScroll: alternativeStyle }"
        >
          <table
            :class="[
              { alternativeStyle: alternativeStyle },
              { alternativeBody: !alternativeStyle },
            ]"
          >
            <thead
              :class="[
                {
                  'align-right': alignLastRight,
                  alternativeStyle: alternativeStyle,
                },
                { alternativeHeader: !alternativeStyle },
              ]"
            >
              <tr
                :class="{ alternativeStyle: alternativeStyle }"
                v-if="!orderable"
              >
                <th>#</th>

                <th
                  v-for="(column, ind) in filteredColumns"
                  :key="ind"
                  class="plain"
                  :style="[
                    column.width ? { width: '17.5rem' } : '',
                    column.smallWidthTd ? { width: '5%' } : '',
                  ]"
                >
                  {{ column.title }}
                </th>
              </tr>
              <tr v-else :class="{ alternativeStyle: alternativeStyle }">
                <th
                  v-if="!noOrder"
                  :class="{ alternativeHushPadding: alternativeRolesStyle }"
                >
                  #
                </th>
                <th
                  :class="[
                    { alternativeStyle: alternativeStyle },
                    { alternativeRolesPadding: alternativeRolesStyle },
                  ]"
                  v-for="(column, ind) in filteredColumns"
                  :key="ind"
                  @click="sortColumn(column.key)"
                  :style="[
                    column.width ? { width: column.width } : '',
                    column.smallWidthTd ? { width: '5%' } : '',
                  ]"
                >
                  <div class="th-wrapper">
                    <div class="title">
                      {{ column.title }}
                    </div>
                    <div
                      class="sort-icons"
                      v-if="!column.plainColumn && orderable && column.key"
                    >
                      <!-- <i
                        v-if="sortedColumn == column.key && sortedByDesc"
                        class="material-icons"
                        >arrow_drop_down</i
                      >
                      <i
                        v-if="sortedColumn == column.key && !sortedByDesc"
                        class="material-icons"
                        >arrow_drop_up</i
                      > -->
                      <div class="search-content">
                        <slot
                          v-if="column.searchByJob"
                          name="searchByJob"
                        ></slot>
                        <slot
                          v-if="column.searchByCreator"
                          name="searchByCreator"
                        ></slot>
                        <slot
                          v-if="column.searchByPost"
                          name="searchByPost"
                        ></slot>
                        <slot
                          v-if="column.searchByStatus"
                          name="searchByStatus"
                        ></slot>
                        <slot
                          v-if="column.hasFilter"
                          :prop="column"
                          name="columnFilter"
                        ></slot>
                      </div>
                    </div>
                  </div>
                </th>
                <th v-if="customTd">
                  <div class="th-wrapper">
                    <div class="title">Application link/s</div>
                  </div>
                </th>
                <th class="actions"></th>
              </tr>
            </thead>
            <tbody
              @scroll="onScroll"
              ref="tbody"
              :style="{ height: height + 'px' }"
              :class="[
                {
                  'align-right': alignLastRight,
                },
              ]"
            >
              <template v-for="(row, rowIndex) in gridData">
                <tr
                  :class="{ alternativeStyle: alternativeStyle }"
                  :rowID="row[identificator]"
                  :key="row[identificator]"
                  :style="[
                    row.OpenPositionStatusID === 1 ? { fontWeight: 400 } : null,
                  ]"
                  @click="$emit('trClicked', row, $event)"
                  @dblclick="$emit('trDoubleClicked', row, $event)"
                >
                  <td
                    v-if="!noOrder"
                    :class="[{ alternativePadding: alternativeStyle }]"
                  >
                    {{ rowIndex + 1 }}
                  </td>

                  <template v-for="(column, ind) in filteredColumns">
                    <td
                      :style="[
                        row.Status === false
                          ? { color: '#AFB3D5', filter: 'brightness(0.8)' }
                          : null,
                        column.smallWidthTd ? { width: '5%' } : '',
                      ]"
                      :title="
                        getCustomValue(column, row[column.key], row).length >
                          9 &&
                        !getCustomValue(column, row[column.key], row).includes(
                          '<i',
                        ) &&
                        !getCustomValue(column, row[column.key], row).includes(
                          '<span',
                        )
                          ? getCustomValue(column, row[column.key], row)
                          : null
                      "
                      :class="[
                        { alternativePadding: alternativeStyle },
                        column.tdClass,
                        { alternativeRolesPadding: alternativeRolesStyle },
                      ]"
                      v-if="
                        (editMode != row[identificator] || !column.editable) &&
                        column.title.toLowerCase() != 'status'
                      "
                      :key="ind"
                      v-html="getCustomValue(column, row[column.key], row)"
                    ></td>
                    <td
                      class="select-td"
                      v-else-if="
                        column.title.toLowerCase() == 'status' && !whiteDelete
                      "
                      :key="ind"
                    >
                      <div
                        class="select-content"
                        @click.stop="$emit('show-options', row)"
                      >
                        <!-- @click.stop="row.optionsVisible = !row.optionsVisible" -->
                        <span
                          :style="{ color: row.color }"
                          class="selected-name"
                        >
                          {{ row.OpenPositionStatusName }}
                        </span>
                        <div v-if="!row.statusRestricted">
                          <i class="material-icons" v-if="!row.optionsVisible">
                            keyboard_arrow_down
                          </i>
                          <i v-else class="material-icons">keyboard_arrow_up</i>
                        </div>
                        <div class="options" v-if="row.optionsVisible">
                          <template v-for="opt in selectOptions">
                            <div
                              :key="opt.Value"
                              v-if="row.OpenPositionStatusID !== 3"
                              class="wrapper"
                            >
                              <span
                                @click.stop="
                                  $emit('opt-clicked', row, opt.value)
                                "
                                :key="opt.index"
                                v-if="isAdminOrHr && opt.statusType == 1"
                                :style="{ color: opt.color }"
                                class="single-option"
                              >
                                {{ opt.title }}
                              </span>
                              <span
                                @click.stop="
                                  $emit('opt-clicked', row, opt.value)
                                "
                                :key="opt.index"
                                v-else-if="!isAdminOrHr && opt.statusType == 2"
                                :style="{ color: opt.color }"
                                class="single-option"
                              >
                                {{ opt.title }}
                              </span>
                              <span
                                class="single-option"
                                :style="{ color: opt.color }"
                                @click.stop="
                                  $emit('opt-clicked', row, opt.value)
                                "
                                :key="opt.index"
                                v-else-if="opt.statusType == 3"
                              >
                                {{ opt.title }}
                              </span>
                            </div>
                            <div class="wrapper" v-else :key="opt.Value">
                              <span
                                class="single-option"
                                :style="{ color: opt.color }"
                                @click.stop="
                                  $emit('opt-clicked', row, opt.value)
                                "
                                :key="opt.index"
                              >
                                {{ opt.title }}
                              </span>
                            </div>
                          </template>
                        </div>
                      </div>
                    </td>

                    <template v-else>
                      <td
                        :class="[
                          { alternativePadding: alternativeStyle },
                          {
                            alternativeRolesPadding: alternativeRolesStyle,
                          },
                        ]"
                        v-if="column.editType == 'text'"
                        :key="ind"
                      >
                        <custom-input
                          :title="column.title"
                          v-model="editValues[column.key]"
                          @clear="editValues[column.key] = ''"
                        />
                      </td>

                      <td v-if="column.editType == 'Number'" :key="ind">
                        <custom-input
                          :title="column.title"
                          v-model="editValues[column.key]"
                          @clear="editValues[column.key] = ''"
                        />
                      </td>
                      <td :key="ind" v-if="column.editType == 'checkbox'">
                        <span>
                          <input
                            class="check-box"
                            v-model="editValues[column.key]"
                            type="checkbox"
                          />
                        </span>
                      </td>
                      <td
                        v-if="column.editType == 'select'"
                        :key="ind"
                        class="td-edit-select"
                      >
                        <custom-select
                          :title="column.title"
                          :options="column.editOptions"
                          v-model="editValues[column.key]"
                          @clear="editValues[column.key] = ''"
                        />
                      </td>

                      <td
                        v-if="column.editType == 'multi-select'"
                        :key="ind"
                        class="td-edit-select-multiple"
                      >
                        <choose-persons
                          :allPersons="teamLeads"
                          :title="column.title"
                          @person-clicked="personClickHanlder"
                        />
                      </td>

                      <td
                        v-if="column.editType == 'date'"
                        :key="ind"
                        class="td-edit-date"
                      >
                        <date-picker
                          :title="column.title"
                          v-model="editValues[column.key]"
                          @clear="editValues[column.key] = ''"
                        />
                      </td>
                    </template>
                  </template>
                  <td v-if="customTd" class="links-td">
                    <div class="links-content">
                      <img
                        v-if="!row.UrlEntities.length && isAdminOrHr"
                        @click.stop="linkIconClickHandler(row)"
                        class="custom-icon"
                        :src="require('@/assets/linkIcon.svg')"
                      />
                      <div v-if="row.UrlEntities.length">
                        <div class="url-to-show">
                          <a
                            @click.stop
                            target="_blank"
                            :href="row.UrlEntities[0].Url"
                            class="url"
                          >
                            {{ row.UrlEntities[0].Url }}
                          </a>
                          <span
                            v-if="isAdminOrHr"
                            @click.stop="linkIconClickHandler(row)"
                          >
                            {{
                              row.UrlEntities.length - 1 == 0
                                ? '+'
                                : '+' + (row.UrlEntities.length - 1)
                            }}
                          </span>
                          <span v-else @click.stop="linkIconClickHandler(row)">
                            {{
                              row.UrlEntities.length > 1
                                ? '+' + (row.UrlEntities.length - 1)
                                : null
                            }}
                          </span>
                        </div>
                      </div>

                      <div
                        class="links-input-content"
                        v-if="row.OpenPositionID == clickedRowId"
                      >
                        <div
                          class="single-link"
                          v-for="(link, index) in savedLinks"
                          :key="index"
                        >
                          <a :href="link.Url" target="_blank">{{ link.Url }}</a>
                          <span
                            v-if="isAdminOrHr"
                            @click="$emit('delete-saved-link', index)"
                            class="material-icons cancel"
                          >
                            close
                          </span>
                        </div>
                        <template v-for="(link, index) in links">
                          <div
                            v-if="isAdminOrHr"
                            class="single-link"
                            :key="index"
                          >
                            <input
                              placeholder="Paste link here..."
                              class="link-input"
                              @click.stop
                              @input="
                                $emit('link-value', $event.target.value, index)
                              "
                            />
                            <span
                              @click.stop="$emit('delete-link', index)"
                              class="material-icons cancel"
                            >
                              close
                            </span>
                          </div>
                        </template>

                        <span
                          @click="$emit('plus-clicked')"
                          v-if="isAdminOrHr"
                          class="plus-span"
                        >
                          +
                        </span>
                        <div class="link-content-footer" v-if="isAdminOrHr">
                          <span
                            @click.stop="$emit('save-link', row.UrlEntities)"
                            class="save-button"
                          >
                            Save
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td
                    :class="[
                      {
                        noEditable:
                          row.hasOwnProperty('canEdit') && !row.canEdit,
                      },
                      { alternativePadding: alternativeStyle },
                      { alternativeActionsWidth: alternativeRolesStyle },
                    ]"
                    class="actions"
                  >
                    <!-- {{ row }} -->
                    <span
                      class="icon-span"
                      v-if="canEdit && row.OpenPositionStatusID !== 4"
                      v-show="editMode == 0 || editMode != row[identificator]"
                      @click="editClickHandler(row[identificator])"
                    >
                      <img src="@/assets/icons/edit.svg" alt="edit" />
                    </span>
                    <span
                      class="icon-span"
                      v-if="
                        alternativeEdit &&
                        roleEditRestrictions(row.OpenPositionStatusID)
                      "
                      @click.stop="$emit('edit-clicked', row[identificator])"
                    >
                      <img src="@/assets/icons/edit.svg" alt="edit" />
                    </span>
                    <span
                      class="material-icons success"
                      v-if="canEdit && row.OpenPositionStatusID !== 4"
                      v-show="editMode == row[identificator]"
                      @click="editSubmitClickHandler(row)"
                    >
                      check
                    </span>
                    <span
                      class="material-icons cancel"
                      v-if="canEdit && row.OpenPositionStatusID !== 4"
                      v-show="editMode == row[identificator]"
                      @click="editMode = 0"
                    >
                      close
                    </span>
                    <span
                      class="icon-span"
                      v-if="
                        canDelete &&
                        row.OpenPositionStatusID !== 4 &&
                        row.OpenPositionStatusID !== 3
                      "
                      @click.stop="$emit('rowDelete', row[identificator])"
                    >
                      <img
                        v-if="!whiteDelete"
                        src="@/assets/delete.svg"
                        alt="delete"
                      />
                      <img
                        v-else-if="whiteDelete && row.Status"
                        src="@/assets/delete-white.svg"
                        alt="delete"
                        @click.stop="$emit('change-status', row)"
                      />
                    </span>
                  </td>
                </tr>
                <tr
                  v-if="collapsible && rowIndex + 1 == collapsedIndex"
                  :key="row[identificator] + 'col'"
                >
                  <td :colspan="columns.length" class="td-collapsed">
                    <slot></slot>
                  </td>
                </tr>
              </template>
              <tr v-if="lastRow" class="custom-row">
                <td v-for="(value, index) in lastRow" :key="index">
                  {{ value }}
                </td>
              </tr>
              <tr
                v-if="gridData.length == 0"
                :class="{ alternativeNodata: alternativeStyle }"
              >
                <td
                  :class="{ alternativeNodata: alternativeStyle }"
                  :colspan="columns.length + 1"
                  class="no-data"
                >
                  <span
                    v-if="!alternateText"
                    :class="{ alternativeNodataText: alternativeStyle }"
                  >
                    Nothing to see here
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="tfoot" v-if="pagination && gridData.length > 0">
                <div class="footer">
                    <p class="footer-text">
                        Showing
                        <span>{{ entryStartIndex }}</span> - <span>{{ entryEndIndex }}</span> of
                        <span>{{ dataCount }}</span> entries
                    </p>
                    <pagination :pageCount="pageCount" @changed="pageChange"></pagination>
                </div>
            </div> -->
    </div>
  </div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance.js'
// import Pagination from './Pagination.vue'
import CustomInput from '@/components/ReusableComponents/CustomInput/CustomInput.vue'
import CustomSelect from '@/components/ReusableComponents/CustomSelect/CustomSelect.vue'
import DatePicker from '@/components/ReusableComponents/DatePicker/DatePicker.vue'
import AddButton from '@/components/ReusableComponents/AddButton/AddButton.vue'
import choosePersons from '@/components/ReusableComponents/ChoosePersons/choosePersons.vue'

// import EmployeeFilter from "@/components/Employees/EmployeeFilter.vue";

export default {
  name: 'Grid',

  components: {
    // Pagination,
    CustomInput,
    CustomSelect,
    DatePicker,
    AddButton,
    choosePersons,
    // EmployeeFilter,
  },

  props: {
    columns: {
      type: Array,
      required: true,
    },
    noOrder: Boolean,
    whiteDelete: Boolean,
    canSearchByColumn: Boolean,
    savedLinks: Array,
    links: Array,
    linkInputValue: String,
    customTd: Boolean,
    gridData: Array,
    dataCount: Number,
    passedPageSize: Number,
    identificator: String,
    pagination: {
      type: Boolean,
      default: true,
    },
    requiredParameters: Object,
    toggleable: {
      type: Boolean,
      default: true,
    },
    exportable: {
      type: Boolean,
      default: true,
    },
    orderable: {
      type: Boolean,
      default: true,
    },
    collapsible: {
      type: Boolean,
      default: false,
    },
    collapsedIndex: Number,
    initialOrderingColumn: String,
    alignLastRight: Boolean,
    lastRow: Array,
    title: String,
    canDelete: {
      type: Boolean,
      default: false,
    },
    alternativeEdit: Boolean,
    canEdit: {
      type: Boolean,
      default: false,
    },
    gridStyle: {
      type: Object,
    },
    canAdd: {
      type: Boolean,
      default: false,
    },
    canSearch: {
      type: Boolean,
      default: false,
    },
    selectOptions: Array,
    alternativeStyle: Boolean,
    alternativeRolesStyle: Boolean,
    clickedRowId: Number,
    alternateText: Boolean,
  },

  data: function () {
    return {
      //which columns should be filtered
      lastFilters: {},
      showLinkInput: false,
      isLoading: true,

      checkboxesAreExpanded: false,
      pageCountOptions: [
        {
          text: 15,
          value: 15,
        },
        {
          text: 30,
          value: 30,
        },
        {
          text: 50,
          value: 50,
        },
        {
          text: 100,
          value: 100,
        },
      ],
      height: 0,
      currentPageIndex: 1,
      sortedColumn: '',
      sortedByDesc: true,
      showFilter: '',
      totalCount: Number,
      requestData: {
        orderByParam: 'string',
        orderByIsDesc: true,
        pageCount: 1,
        pageLength: 15,
        params: [],
      },
      pageSize: 15,
      selectedPageCount: '15',
      editMode: 0,
      editValues: {},
      selectedPersons: [],
      allPersons: [],
      teamLeads: [],
    }
  },

  watch: {
    gridData: function (newData) {
      this.gridData = newData
      this.height =
        document.documentElement.scrollHeight -
        this.$refs.tbody.getBoundingClientRect().top -
        30
    },
    selectedPageCount(count) {
      this.pageCountChangeHandler(count)
    },
  },
  //   mounted() {
  //     setTimeout(() => {
  //         var elements = document.querySelectorAll('table tbody tr:first-child td')
  //         Array.prototype.forEach.call(elements, function (el, i) {
  //             i++
  //             let table = elements[0].parentNode.parentNode.parentNode
  //             document.querySelector(
  //                 `thead tr th:nth-child(${i})`,
  //                 table,
  //             ).style.width = `${el.offsetWidth}px`
  //         })
  //     }, 500)
  // },

  computed: {
    filteredColumns() {
      return this.columns.filter((el) => !el.hidden)
    },
    isAdminOrHr() {
      return (
        this.$store.getters.hasRole('HR') ||
        this.$store.getters.hasRole('Admin') ||
        this.$store.getters.isSuperAdmin
      )
    },

    pageCount() {
      var count = this.dataCount
      var pageSize = this.passedPageSize
      if (count % pageSize == 0) return count / pageSize
      return Math.floor(count / pageSize) + 1
    },
    entryStartIndex() {
      var count = this.dataCount
      var pageSize = this.passedPageSize
      return Math.min(pageSize * (this.currentPageIndex - 1) + 1, count)
    },
    entryEndIndex() {
      var count = this.dataCount
      var pageSize = this.passedPageSize
      return Math.min(this.entryStartIndex + pageSize - 1, count)
    },
  },

  methods: {
    onScroll(event) {
      var el = event.srcElement

      if (el.scrollTop >= el.scrollHeight - el.clientHeight) {
        this.$emit('scolled-bottom')
      }
    },
    roleEditRestrictions(positionId) {
      let editVisible = true
      if (positionId === 4 || positionId === 3) {
        editVisible = false
      }
      if (
        this.$store.getters.hasRole('Manager') &&
        (positionId === 4 ||
          positionId === 3 ||
          positionId === 5 ||
          positionId === 6)
      ) {
        editVisible = false
      }
      return editVisible
    },
    showCheckboxes() {
      if (this.checkboxesAreExpanded) this.checkboxesAreExpanded = false
      else this.checkboxesAreExpanded = true
    },
    tbodyPosition() {
      // console.log(this.$refs.tbody.getBoundingClientRect());
      // return this.$refs.tbody.getBoundingClientRect();
    },
    linkIconClickHandler(el) {
      this.$emit('clicked-row', el)
      // this.clickedRowId = el.OpenPositionID;
      // el.inputVisible = true;
    },
    ToggleColumn(column) {
      this.$set(column, 'hidden', !column.hidden)
    },
    pageChange(page) {
      this.currentPageIndex = page
      if (this.requestURL) {
        this.requestData.pageCount = page
        this.postData()
      } else {
        this.$emit('pageChanged', page)
      }
    },
    getCustomValue(column, val, row) {
      if (!val) {
        val = ''
      }
      if (!column.customTemplate) return `${val}`
      var text = column.customTemplate(row, val)

      return text
    },
    pageCountChangeHandler(value) {
      this.pageSize = parseInt(value)
      this.currentPageIndex = 1
      if (this.requestURL) {
        this.requestData.pageLength = value
        this.requestData.pageCount = 1
        this.postData()
      } else {
        this.$emit('pageCountChanged', value)
      }
    },
    sortColumn(columnKey) {
      this.sortedColumn = columnKey
      this.sortedByDesc = !this.sortedByDesc
      if (this.requestURL) {
        this.requestData.orderByParam = columnKey
        this.requestData.orderByIsDesc = this.sortedByDesc
        this.postData()
      } else
        this.$emit('sort', {
          orderByDesc: this.sortedByDesc,
          columnName: columnKey,
        })
    },
    filterIconClickHandler(columnKey) {
      if (this.showFilter == columnKey) this.showFilter = ''
      else this.showFilter = columnKey
    },
    postData() {
      axios.post(this.requestURL, this.requestData).then((response) => {
        this.gridData = response.data.data
        this.totalCount = response.data.count
        this.$emit('gotDataFromRequest', this.gridData)
      })
    },
    exportToExcel() {
      /* eslint-disable */
      var tab_text = "<table border='2*$rem'><tr bgcolor='#87AFC6'>"
      var textRange
      var j = 0
      var tab = this.$el.querySelector('table')

      for (j = 0; j < tab.rows.length; j++) {
        var clone = tab.rows[j].cloneNode(true)
        //remove extra texts from rows
        var searches = clone
          .querySelectorAll('.search-wrapper')
          .forEach((s) => s.remove())
        var iconNames = clone
          .querySelectorAll('.filter')
          .forEach((i) => i.remove())
        tab_text += clone.innerHTML + '</tr>'
      }

      tab_text = tab_text + '</table>'
      tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, '') //remove if u want links in your table
      tab_text = tab_text.replace(/<img[^>]*>/gi, '') // remove if u want images in your table
      tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, '') // removes input params

      var ua = window.navigator.userAgent
      var msie = ua.indexOf('MSIE ')

      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // If Internet Explorer
        txtArea1.document.open('txt/html', 'replace')
        txtArea1.document.write(tab_text)
        txtArea1.document.close()
        txtArea1.focus()
        var sa = txtArea1.document.execCommand(
          'SaveAs',
          true,
          'Say Thanks to Sumit.xls',
        )
      } //other browser not tested on IE 11
      else
        var sa = window.open(
          'data:application/vnd.ms-excel,' + encodeURIComponent(tab_text),
        )
      /* eslint-enable */
      return sa
    },
    search(form) {
      this.requestData.params = []
      Object.keys(form).forEach((key) => {
        if (!form[key]) return
        this.requestData.params.push({
          operator: 0,
          paramName: key,
          value: form[key],
        })
      })
      this.postData()
    },
    isCustomTd(col) {
      return col.customTemplate !== undefined
    },
    editClickHandler(id) {
      this.$eventBus.$emit('closeSideChat') // to accomodate better
      var rowItem = this.gridData.filter(
        (el) => el[this.identificator] == id,
      )[0]

      this.columns
        .filter((col) => col.editable)
        .forEach((col) => {
          if (col.hasTextValue) {
            //Case: When td is a select and I only know the title of the selected option but not the value.
            //In order to set select's option (current), I should get the real value first.
            let textValue = rowItem[col.key]
            let realValue = col.editOptions.filter(
              (el) => el.title == textValue,
            )[0]
            realValue = realValue ? realValue.value : ''
            this.$set(this.editValues, col.key, realValue)
            return
          }
          //default
          this.$set(this.editValues, col.key, rowItem[col.key])
        })
      this.editMode = id
    },
    editSubmitClickHandler(row) {
      this.$emit(
        'rowEdit',
        {
          ...this.editValues,
          [this.identificator]: this.editMode,
        },
        row,
      )
      this.editMode = 0
    },
    personClickHanlder(el) {
      el.selected = !el.selected
      const exist = this.selectedPersons.includes(el.value)
      if (exist) {
        this.selectedPersons = this.selectedPersons.filter(
          (element) => element != el.value,
        )
      } else {
        this.selectedPersons.push(el.value)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.grid {
  .background-for-header {
    background-color: #ededfc;
  }
  margin-top: 0;
  width: 100%;
  border-radius: 5 * $rem;

  .table-wrapper {
    padding-bottom: 25 * $rem;
    min-height: 150 * $rem;
    border-radius: 10 * $rem;

    &.pagination {
      min-height: 788 * $rem;
    }
  }

  .grid-header {
    height: 53 * $rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $blue-primary;
    padding-top: 32 * $rem;
    margin-bottom: 45 * $rem;
    font-weight: 400;
    padding-left: 40 * $rem;
    position: fixed;
    right: 30px;
    .add-new {
      margin-right: 100 * $rem;
      margin-left: 0;
    }

    input.search {
      outline: none;
      border: none;
      border-radius: 0.1875rem;
      padding-left: 1.4375rem;
      font-size: 0.875rem;
      color: #283593;
      background-color: #f8f8fe;
      width: 17rem;
      height: 38 * $rem;

      &::placeholder {
        color: $blue-primary;
        font-weight: 300;
      }
    }
  }

  form {
    padding: 0 0 15 * $rem 0;
    display: flex;
    justify-content: flex-end;

    .show-hide-columns {
      margin-left: 7 * $rem;

      .multiselect {
        width: initial;
        position: relative;

        .selectBox {
          cursor: pointer;
          width: 50 * $rem;
          background-color: white;
          height: 33 * $rem;
          border: 1 * $rem solid #b9b9b9b5;
          display: flex;
          justify-content: center;
          align-items: center;

          .overSelect {
            cursor: pointer;
          }

          i.eye {
            color: #4c4c4c;
            z-index: 4;
          }
        }
        .checkboxes {
          z-index: 10;
          background-color: white;
          position: absolute;
          right: 0;
          border: $rem solid $green-primary;
          padding: 5 * $rem;

          label {
            display: block;
            width: 150 * $rem;
            padding: 5 * $rem 15 * $rem;
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
              margin-left: 15 * $rem;
              color: $green-primary;
              font-size: 14 * $rem;
              color: $green-primary;
              font-weight: 400;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          label:hover {
            background-color: #f9f9f9;
          }
        }
      }
    }

    button.export {
      margin-right: 10 * $rem;
      background-color: #4061c5;
      border: none;
      border-radius: $rem;
      font-size: 12 * $rem;
      padding: 0 15 * $rem;
      letter-spacing: 1 * $rem;
      color: white;
      cursor: pointer;

      &:hover {
        filter: brightness(1.1);
      }
    }
  }

  .table-helper {
    width: 100%;
    // overflow: auto;
    table {
      border-collapse: collapse;
      width: 100%;
      table-layout: fixed;
      font-size: 14 * $rem;
      position: relative;

      tr {
        height: 41 * $rem;
        border-bottom: $rem solid #eff0f3;
      }

      thead {
        width: 100%;
        display: table;
        table-layout: fixed;
        border-bottom: 1 * $rem solid #eef0f3;
        th.actions {
          width: 200 * $rem;
          text-align: right;
          padding-left: 20 * $rem;
          padding-right: 4.8rem;
        }
      }

      tbody {
        width: 100%;
        height: 100%;
        display: block;
        overflow-y: auto;

        min-height: 250px;
        tr {
          width: 100%;
          display: table;
          table-layout: fixed;
          font-weight: 300;

          &:hover {
            font-weight: 400;
          }
          &.custom-row td {
            font-style: italic;
            font-weight: 700;
          }
        }

        .select-td {
          overflow: visible;
          .select-content {
            display: flex;
            align-items: center;

            .selected-name {
              text-transform: uppercase;
              min-width: 87px;
            }
            cursor: pointer;
            position: relative;
            .material-icons {
              opacity: 0.5;
            }
            .options {
              position: absolute;
              border-radius: 10px;
              padding: 10px 20px;
              background-color: #fff;
              top: 30px;
              width: auto;
              display: flex;
              flex-direction: column;
              box-shadow: 0px 3px 30px rgba(40, 53, 147, 0.08);
              z-index: 10;

              .single-option {
                margin-bottom: 5px;
                font-weight: 300 !important;
                font-size: 14px;
                &:hover {
                  font-weight: 400 !important;
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        td:first-child {
          width: 5% !important;
        }
        td {
          font-size: 16 * $rem;
          text-align: left;
          max-width: 220 * $rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 12 * $rem 0 12 * $rem 40 * $rem;
          color: $blue-primary;
          ::v-deep & > span.enableButton {
            padding: 11px 14px 10px 9px;
            border-radius: 3px;
            background-color: #5963ab;
            font-size: 12px;
            color: #fff;
            cursor: pointer;
          }
          ::v-deep & > span.disableButton {
            padding: 11px 14px 10px 9px;
            border-radius: 3px;
            background-color: #959bc9;
            font-size: 12px;
            color: #fff;
            cursor: pointer;
          }

          ::v-deep & > span {
            max-width: 145px;
            display: inline-block;

            .check-box {
              height: 16 * $rem;
              width: 16 * $rem;
              cursor: pointer;
            }
          }

          &.td-collapsed {
            white-space: initial;
            overflow: initial;
            text-align: center;
            padding-right: 2.5rem;
          }

          &.td-center {
            text-align: center;
            padding-left: 0;
          }

          &.actions {
            width: 200 * $rem;
            text-align: right;
            padding-left: 20 * $rem;
            padding-right: 4.8rem;

            .delete {
              color: $red;
            }

            .cancel {
              color: $red;
            }

            .success {
              color: rgb(0, 85, 0);
            }

            .material-icons {
              cursor: pointer;
              margin: 5 * $rem;

              &:hover {
                filter: brightness(1.05);
              }
            }
          }

          &.td-edit-select {
            overflow: visible;
          }

          &.td-edit-select-multiple {
            overflow: visible;
          }

          &.icon {
            padding-top: 5 * $rem;
            padding-bottom: 5 * $rem;

            ::v-deep img {
              cursor: pointer;
            }
          }

          label,
          .select-wrapper {
            width: 9rem;
          }

          ::v-deep .el-input__inner {
            width: 9rem;
          }

          ::v-deep span.title.focused {
            display: none;
          }
        }

        td.no-data {
          font-size: 18 * $rem;
          padding: 30 * $rem;
          text-align: center;
        }
      }

      thead {
        &:hover {
          cursor: pointer;
        }
        th:first-child {
          width: 5% !important;
        }
        th {
          height: 60 * $rem;
          color: rgba(99, 99, 99, 0.7);
          text-align: left;
          padding: 15 * $rem 15 * $rem 15 * $rem 40 * $rem;
          color: $blue-primary;

          &.plain {
            padding-left: 1rem;
          }

          &:last-child {
            // text-align: center;
            border-right: none;
          }

          .th-wrapper {
            color: $blue-primary;
            display: flex;
            align-items: center;
            overflow: hidden;
            height: 16 * $rem;
            overflow: visible;
            font-size: 18px;

            .sort-icons {
              width: 24 * $rem;
              padding-top: 2 * $rem;
              position: relative;
              left: 10 * $rem;
            }

            .title {
              font-weight: 400;
              letter-spacing: 0.8px;
              font-size: 14 * $rem;
            }
          }
        }

        th.plain {
          padding-left: 3rem;
        }
      }
    }
  }

  .tfoot {
    width: 100%;
    padding-right: 55 * $rem;

    .footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      p {
        margin-right: 15 * $rem;
        color: #34278f;
        font-weight: 300;

        span {
          font-weight: 500;
        }
      }
    }

    td {
      padding-top: 15 * $rem;
    }
  }
}
.alternativeHeader {
  z-index: 10;
  top: 90px;
  background-color: #ededfc;
  font-weight: 400;
  position: sticky;
}
.alternativeBody {
  top: 88px;
}
.icon-span {
  img {
    cursor: pointer;
    height: 35px;
  }
}
.alternativeBackground {
  background-color: white !important;
}
::v-deep .custom-icon {
  height: 47px;
}
.button-container {
  margin-right: 85px;
}
.grid .table-wrapper.alternativeStyle {
  background-color: #ffffff;
  border-collapse: inherit;
}
.grid .table-helper table tr.alternativeStyle {
  border: 0;
}
.grid .table-helper table tr td.alternativePadding {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.grid .table-helper table tbody {
  tr:nth-of-type(2n + 1).alternativeStyle {
    background-color: #ffffff;
  }
}
.grid .table-helper table thead.alternativeStyle {
  border: 0;
}

.grid .table-helper table tr td.alternativeNodata {
  font-size: 30px;
  background-color: #ffffff;
  text-align: center;
}

.grid .table-helper table tbody td > span.alternativeNodataText {
  display: inline-block;
  margin-left: auto;
  font-weight: 500;
  opacity: 0.5;
  font-size: 30px;
}

.table-helper table tr.alternativeNodata {
  border: 0;
}

.alternativeScroll {
  overflow-y: auto !important;
  height: 520 * $rem !important;
  width: 97% !important;
}

.grid .table-helper table thead th.alternativeRolesPadding {
  padding: 15 * $rem 15 * $rem 15 * $rem 15 * $rem;
  width: 50px;
}
.grid .table-helper table tbody td.alternativeRolesPadding {
  padding: 6 * $rem 15 * $rem 6 * $rem 15 * $rem;
  width: 50px;
  max-width: 31.5rem;
}
.grid .table-helper table thead th.alternativeHushPadding {
  padding-left: 40px;
  width: 50px;
}
.grid .table-helper table tbody td.actions.alternativeActionsWidth {
  width: 8.5rem;
  padding-left: 0;
}

.links-td {
  overflow: visible !important;
  max-width: 12.75rem !important;
}
.links-content {
  position: relative;
  .url-to-show {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .url {
      width: 97%;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      color: #66c1de;
      font-weight: 400;
      text-decoration: none;
    }
    span {
      font-size: 15px;
      color: #66c1de;
      cursor: pointer;
      margin-left: 7px;
    }
  }
  .links-input-content {
    display: flex;
    flex-direction: column;
    padding: 15px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 10px;
    max-height: 140px;
    overflow-y: scroll;
    width: 300px;
    min-height: 60px;
    right: -30px;
    z-index: 2;
    // border: 1px solid #6956d6;
    box-shadow: 0.125rem 0.125rem 0.625rem 0.125rem rgba(40, 52, 147, 0.08);

    .link-content-footer {
      display: flex;
      justify-content: flex-end;
      // margin-top: 5px;
      .save-button {
        font-weight: 400;
        font-size: 13px;
        background-color: $blue-primary;
        color: #ffffff;
        opacity: 0.8;
        border-radius: 5px;
        padding: 5px 8px;
        cursor: pointer;
      }
    }
    .single-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .material-icons {
        font-size: 14px;
        cursor: pointer;

        &:hover {
          font-size: 15px;
        }
      }
      // overflow-x: hidden;
      // text-overflow: ellipsis;
    }
    a {
      color: #66c1de;
      text-decoration: none;
      width: 94%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      display: block;
      font-weight: 300;
      font-size: 14px;
      &:hover {
        font-weight: 400;
      }
    }
    input {
      margin-bottom: 5px;
      border: 0;
      outline: none;
      color: #66c1de;
      width: 90%;
      &::placeholder {
        font-weight: 300;
        color: $blue-primary;
      }
    }
    .plus-span {
      font-size: 18px;
      font-weight: 900;
      opacity: 0.7;
      color: $blue-primary;
      cursor: pointer;
    }
  }
}
.noEditable span {
  display: none !important;
}
</style>
