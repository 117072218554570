<template>
  <div class="background" @click.stop="commentId = 0">
    <header>
      <div class="left-side">
        <span>All Responses</span>
      </div>
      <div class="right-side">
        <input v-model="search" placeholder="Search" type="text" />
      </div>
    </header>
    <div class="grid-container">
      <div class="grid-header">
        <span></span>
        <div class="wrapper">
          <span>Offer Title</span>
          <employee-filter
            :list="filteredDataWithTitle.map((el) => el.Title)"
            @select="(val) => (choosenTitle = val)"
          />
        </div>
        <div class="amount-wrapper">
          <span>Option</span> <span>Amount</span>
        </div>
        <span></span>
        <span></span>
      </div>
      <div class="grid-body">
        <div
          class="single-person-content"
          v-for="el in filteredDataWithTitle"
          :key="el.index"
        >
          <div class="avatar-info-content">
            <div
              class="avatar"
              :style="{ backgroundImage: `url(${getAvatar(el.Person)})` }"
            ></div>
            <img
              v-if="el.Comment"
              class="comment-icon"
              @click.stop="commentClickHandler(el)"
              src="@/assets/icons/red/comment.svg"
            />
            <div
              class="comment-container"
              v-if="el.OfferPersonChoiceID == commentId"
            >
              <div class="triangle"></div>
              <div class="comment">
                <p>{{ el.Comment }}</p>
              </div>
            </div>
            <div class="info">
              <span class="name">{{ el.FirstName }}</span>
              <span class="name">{{ el.LastName }}</span>
              <span>{{ el.JobName }}</span>
            </div>
            <div class="border"></div>
          </div>
          <div class="offer-title-content">
            <span>{{ el.Title }}</span>
            <div class="border"></div>
          </div>
          <div class="option-content">
            <span>{{ el.OptionDescription }}</span>
            <span v-if="el.EmployeeShare > 0">{{
              el.EmployeeShare + " " + el.Currency
            }}</span>
            <span v-else-if="el.FoundedByCompany > 0">
              {{ el.FoundedByCompany + " " + el.Currency }}</span
            >
            <span :style="{ opacity: '0' }" v-else>nodata</span>
            <div class="border"></div>
          </div>
          <div class="contract-content">
            <div
              class="button"
              :style="[el.AlreadySentContract ? { opacity: '0.1' } : null]"
              @click="contractClickHandler(el)"
            >
              <span>Send Contract</span>
            </div>
          </div>
          <div class="admin-comment-content">
            <div
              style="width: 100%; display: flex"
              @mouseenter="el.actionsVisible = true"
              @mouseleave="el.actionsVisible = false"
            >
              <input
                type="text"
                :title="el.commentForAdmin"
                v-model="el.commentForAdmin"
                placeholder="comment..."
              />
              <div
                class="actions"
                v-if="el.actionsVisible && el.commentForAdmin != ''"
              >
                <span class="material-icons success" @click="saveComment(el)"
                  >check</span
                >
                <span class="material-icons cancel" @click="deleteComment(el)"
                  >close</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <custom-popup
      style="z-index: 999"
      :visible="contratUploadPopup"
      :flexible-size="true"
      title="Send Contract"
      @close="contratUploadPopup = false"
    >
      <custom-file-input
        title="Upload contract"
        :value="file"
        @change="onChange"
      />
      <div class="upload-button" @click.stop="sendContract">Send</div>
    </custom-popup>
    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
  </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance.js";
import CustomPopup from "../ReusableComponents/CustomPopup/CustomPopup.vue";
import CustomFileInput from "@/components/ReusableComponents/CustomFileInput/CustomFileInput";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import EmployeeFilter from "@/components/Employees/EmployeeFilter";
export default {
  created() {
    this.getData();
  },
  components: {
    CustomPopup,
    CustomFileInput,
    CustomSnackbar,
    EmployeeFilter,
  },
  data() {
    return {
      data: [],
      contratUploadPopup: false,
      showUserComment: false,
      snackbarText: "",
      snackbarVisible: false,
      snackbarPurpose: "",
      choosenTitle: "",
      search: "",
      commentId: "",
      file: {},
      clickedOffer: "",
      clickedPerson: "",
    };
  },

  methods: {
    getData() {
      axios.get("News/GetOfferPersonChoices").then((resp) => {
        this.data = resp.data.Value;
        this.data.forEach((el) => {
          this.$set(el, "commentVisible", false);
          this.$set(el, "actionsVisible", false);

          if (el.commentForAdmin) {
            this.$set(el, "alreadyHaveComment", true);
          } else {
            this.$set(el, "alreadyHaveComment", false);
          }
        });
      });
    },
    onChange(el) {
      this.file = el;
    },
    contractClickHandler(el) {
      this.contratUploadPopup = true;
      this.clickedOffer = el.OfferID;
      this.clickedPerson = el.Person;
    },
    sendContract() {
      axios
        .post("News/SendOfferAttachment", {
          Files: [
            {
              Base64: this.file.base64,
              Type: this.file.type,
              Name: this.file.filename,
            },
          ],
          Offer: this.clickedOffer,
          Person: this.clickedPerson,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.snackbarVisible = true;
            this.snackbarPurpose = "success";
            this.snackbarText = "Successfully sent";
            this.contratUploadPopup = false;
            this.getData(1);
          }
        });
    },
    commentClickHandler(el) {
      this.commentId = el.OfferPersonChoiceID;
    },
    getAvatar(personID) {
      return this.$store.state.getAvatar + personID;
    },
    blurHandler(x) {
      setTimeout(() => {
        x = false;
      }, 100);
    },
    saveComment(el) {
      if (!el.alreadyHaveComment) {
        axios
          .post("News/CreateOfferPersonChoiceComment", {
            OfferPersonChoice: el.OfferPersonChoiceID,
            Comment: el.commentForAdmin,
          })
          .then((resp) => {
            this.getData();
            this.snackbarVisible = true;

            this.snackbarPurpose = "success";
            this.snackbarText = "Successfully saved";
          });
      } else {
        axios
          .post("News/UpdateOfferPersonChoiceComment", {
            OfferPersonChoice: el.OfferPersonChoiceID,
            Comment: el.commentForAdmin,
            Deleted: false,
          })
          .then((resp) => {
            // el.commentForAdmin = "";
            this.getData();
          });
      }
    },
    deleteComment(el) {
      axios
        .post("News/UpdateOfferPersonChoiceComment", {
          OfferPersonChoice: el.OfferPersonChoiceID,
          Comment: el.commentForAdmin,
          Deleted: true,
        })
        .then((resp) => {
          el.commentForAdmin = "";
          this.getData();
        });
    },
  },
  computed: {
    filteredData() {
      return this.data.filter(
        (el) =>
          (el.FirstName + " " + el.LastName)
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          (el.FirstName + el.LastName)
            .toLowerCase()
            .includes(this.search.toLowerCase())
      );
    },
    filteredDataWithTitle() {
      if (!this.choosenTitle) return this.filteredData;
      return this.filteredData.filter((el) =>
        el.Title.toLowerCase().includes(this.choosenTitle.toLowerCase())
      );
    },
  },
};
</script>

<style scoped lang="scss">
.background {
  padding: 40px 75px;
  overflow: hidden;
}
header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .left-side {
    span {
      font-size: 24px;
      color: $blue-primary;
    }
  }
  .right-side {
    input {
      width: 200px;
      height: 38px;
      outline: none;
      border-radius: 6px;
      font-size: 15px;
      padding: 10px;
      border: 0;
      color: $blue-primary;
      &::placeholder {
        font-size: 14px;
      }
    }
  }
}
.grid-container {
  width: 102%;
  .grid-header {
    display: grid;
    grid-template-columns:
      minmax(292px, 1fr) minmax(212px, 1fr) minmax(397px, 1fr) minmax(
        243px,
        1fr
      )
      minmax(335px, 5fr);
    span {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 17px;
      color: $blue-primary;
    }
    .amount-wrapper {
      display: flex;
      justify-content: space-between;
    }
    .wrapper {
      display: flex;

      // align-items: center;
      // justify-content: ;
      div {
        margin-left: 0;
        margin-top: -5px;
      }
    }
  }
}
.border {
  height: 35px;
  background-color: #959bc9;
  width: 1px;
  margin-left: auto;
}
.grid-body {
  height: 79vh;
  overflow-y: scroll;
  overflow-x: hidden;

  .single-person-content {
    display: grid;
    margin-bottom: 15px;
    grid-template-columns:
      minmax(292px, 1fr) minmax(212px, 1fr) minmax(385px, 1fr) minmax(
        210px,
        1fr
      )
      minmax(345px, 1fr);
    > div {
      display: flex;
      align-items: center;
      padding-left: 10px;
      // justify-content: center;
      span {
        font-size: 16px;
        // color: #9696b9;
        color: $blue-primary;
        font-weight: 300;
        max-width: 295px;
      }
    }
    .avatar-info-content {
      position: relative;
      .avatar {
        background-color: #fff;
        height: 90px;
        width: 90px;
        border-radius: 100%;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .comment-container {
        width: 290px;
        min-height: 60px;
        border-radius: 6px;
        top: 34px;
        left: 5px;
        position: absolute;
        background: rgba(#283593, 0.9);
        padding: 8px;
        z-index: 100;
        .comment {
          overflow: auto;
        }
        .triangle {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid $blue-primary;
          opacity: 0.9;
          position: absolute;
          top: -10px;
          left: 5px;
        }
        p {
          color: #ffffff;
          font-size: 12px;
          white-space: break-spaces;
        }
      }

      .comment-icon {
        height: 33px;
        position: absolute;
        cursor: pointer;
        top: 0;
        left: -2px;
      }

      .info {
        display: flex;
        flex-direction: column;
        margin-left: 22px;
        .name {
          font-size: 16px;
          color: $blue-primary;
          font-weight: 300;
          margin-top: 0;
          margin-bottom: 3px;
          max-width: 149px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        span {
          color: #9696b9;
          margin-top: 4px;
          font-size: 14px;
          max-width: 150px;

          word-break: break-word;
        }
      }
    }
    .offer-title-content {
      span {
        max-width: 185px;
        overflow: hidden;
        display: block;
        // white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .option-content {
      span:nth-child(2) {
        margin-left: auto;
        padding: 10px;
        white-space: nowrap;
      }
      div {
        // justify-self: flex-end;
        margin-left: 0;
      }
    }
    .contract-content {
      .button {
        width: 145px;
        height: 36px;
        background: rgba(#283593, 0.6);
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        span {
          color: #fff;
          font-weight: 400;
        }
      }
    }
    .admin-comment-content {
      input {
        background: transparent;
        outline: none;
        border: 0;
        border-bottom: 1px solid #9696b9;
        padding-left: 5px;
        padding-bottom: 5px;
        width: 80%;
        font-weight: 300;
        color: $blue-primary;
        &::placeholder {
          color: #9696b9;
        }
      }
      .actions {
        span {
          font-size: 18px;
          cursor: pointer;
        }
        span:nth-child(2) {
          color: #ff2828;
        }
      }
    }
  }
}
.upload-button {
  cursor: pointer;
  width: 197 * $rem;
  display: inline-block;
  height: 40 * $rem;
  line-height: 40 * $rem;
  padding: 0 15 * $rem 0 15 * $rem;
  outline: none;
  text-align: center;
  color: white;
  margin-top: 10px;
  border-radius: 5 * $rem;
  box-shadow: none;
  background-color: #1de9b6;
}
</style>