<template>
    <div class="templates">
        <div class="header" @click="saveTemplate">
            <span>Templates</span>
            <i class="plus material-icons">add</i>
        </div>
        <div class="list">
            <div
                class="template"
                v-for="(template, index) in templates"
                :key="index"
                :class="{ night: isAtNight(template) }"
                :style="transform"
                @click="$emit('select', template)"
            >
                {{ timeText(template) }}
                <i class="material-icons" @click="remove(index)">close</i>
            </div>
            <i
                class="material-icons arrow left"
                v-show="showLeftArrow"
                @click="currentScrollRight--"
            >keyboard_arrow_left</i>
            <i
                class="material-icons arrow right"
                v-show="showRightArrow"
                @click="currentScrollRight++"
            >keyboard_arrow_right</i>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fromTime: Number,
        toTime: Number,
    },
    data() {
        return {
            templates: JSON.parse(localStorage.getItem('timeTemplates')) || [],
            currentScrollRight: 0,
        }
    },
    methods: {
        saveTemplate() {
            if (
                this.templates.find(
                    (el) =>
                        el.fromTime == this.fromTime &&
                        el.toTime == this.toTime,
                )
            )
                return
            this.templates.unshift({
                fromTime: this.fromTime,
                toTime: this.toTime,
            })
            localStorage.setItem(
                'timeTemplates',
                JSON.stringify(this.templates),
            )
        },
        timeText(template) {
            const zeroed = (num) => ('0' + num).slice(-2)
            var fh = parseInt(template.fromTime / 60)
            var fm = template.fromTime % 60
            var th = parseInt(template.toTime / 60)
            var tm = template.toTime % 60
            return `${zeroed(fh)}:${zeroed(fm)} - ${zeroed(th)}:${zeroed(tm)}`
        },
        isAtNight(template) {
            return template.fromTime > template.toTime
        },
        remove(index) {
            this.templates.splice(index, 1)
            localStorage.setItem(
                'timeTemplates',
                JSON.stringify(this.templates),
            )
        },
    },
    computed: {
        showLeftArrow() {
            return this.currentScrollRight > 0
        },
        showRightArrow() {
            const canDisplay = 6
            return this.templates.length - this.currentScrollRight > canDisplay
        },
        transform() {
            const margin = 12
            return {
                transform: `translateX(calc(${
                    -100 * this.currentScrollRight
                }% - ${margin * this.currentScrollRight}px))`,
            }
        },
    },
}
</script>

<style scoped lang="scss">
.templates {
    margin-top: 22 * $rem;

    .header {
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
            font-size: 14 * $rem;
            font-weight: 300;
            color: rgba($blue-primary, 0.8);
        }

        i {
            color: white;
            font-size: 16 * $rem;
            background-color: rgba($blue-primary, 0.5);
            border-radius: 3 * $rem;
            margin-left: 12 * $rem;
            padding: 1 * $rem;
        }
    }

    & > span {
        color: rgba($blue-primary, 0.5);
        font-weight: 500;
    }

    .list {
        margin-top: 28 * $rem;
        display: flex;
        overflow-x: hidden;
        height: 70 * $rem;
        align-items: center;

        .template {
            width: 110 * $rem;
            height: 50 * $rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15 * $rem;
            color: #afafc6;
            border-radius: 6 * $rem;
            background-color: #ededfc;
            margin-right: 12 * $rem;
            cursor: pointer;
            position: relative;
            flex-shrink: 0;
            transition: transform .4s ease-out;

            &.night {
                background-color: #a9aed4;
                color: $blue-primary;

                &:before {
                    position: absolute;
                    content: '';
                    width: 12 * $rem;
                    height: 12 * $rem;
                    border-radius: 50%;
                    background-color: $green-primary;
                    top: 2 * $rem;
                    right: 8 * $rem;
                }

                &:after {
                    position: absolute;
                    content: '';
                    width: 13 * $rem;
                    height: 13 * $rem;
                    border-radius: 50%;
                    background-color: #a9aed4;
                    top: 0 * $rem;
                    right: 12 * $rem;
                }
            }

            i {
                position: absolute;
                right: -7 * $rem;
                top: -7 * $rem;
                background-color: #fff;
                border-radius: 50%;
                color: $green-primary;
                font-size: 14 * $rem;
                padding: 1 * $rem;
                background-color: rgba($blue-primary, 0.7);
                display: none;
            }

            &:hover {
                i {
                    display: block;
                }
            }
        }

        i.arrow {
            color: #a9aed4;
            position: absolute;
            cursor: pointer;

            &.left {
                left: 10 * $rem;
            }

            &.right {
                right: 10 * $rem;
            }
        }
    }
}
</style>
