<template>
  <div class="traffic__listview">
    <div class="traffic__tags" v-if="selectedTags.length > 0">
      <div class="traffic__tags-header">
        Selected Tags <span> {{ selectedTags.length }} </span>
      </div>
      <div class="traffic__tags-content">
        <div class="traffic__tags-list">
          <div
            class="traffic__tags-tag"
            v-for="(object, index) in selectedTags"
            :key="index"
          >
            <div class="cancel" @click="deleteTag(object)">X</div>
            <img :src="object.image" alt="" class="image" />
            <span> {{ object.ProgramName || object.URL }} </span>
          </div>
        </div>
        <div class="button" @click="requestAddition">Request Addition</div>
      </div>
    </div>
    <div
      :class="[
        'traffic__data',
        { 'traffic__data--with-header': selectedTags.length },
      ]"
    >
      <div class="traffic__grid" id="appData">
        <traffic-grid
          :loading="appLoading"
          :data="appData"
          @add-tag="addToTags"
          :fields="appFields"
          :options="options"
        />
      </div>
      <div class="traffic__grid" id="webData">
        <traffic-grid
          :loading="webLoading"
          :data="webData"
          @add-tag="addToTags"
          :fields="webFields"
          :options="options"
        />
      </div>
    </div>
    <custom-snackbar
      text="Request was successfully sent"
      v-if="success"
      @close="success = false"
    />
  </div>
</template>

<script>
import TrafficGrid from "@/components/Traffic/TrafficGrid";
import axios from "@/Helpers/AxiosInstance";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar";

export default {
  mounted() {
    const webData = document.getElementById("webData");
    webData.addEventListener("scroll", (event) => {
      const obj = event.target;
      if (obj.scrollTop === obj.scrollHeight - obj.offsetHeight) {
        this.$emit("web-scrolled");
      }
    });

    const appData = document.getElementById("appData");
    appData.addEventListener("scroll", (event) => {
      const obj = event.target;
      if (obj.scrollTop === obj.scrollHeight - obj.offsetHeight) {
        this.$emit("web-scrolled");
      }
    });
  },
  components: {
    CustomSnackbar,
    TrafficGrid,
  },
  props: {
    appData: {
      type: Array,
      required: true,
    },
    webData: {
      type: Array,
      required: true,
    },
    appLoading: Boolean,
    webLoading: Boolean,
  },
  data() {
    return {
      appFields: [
        { title: "Apps", class: "source source--small" },
        { title: "Session Duration" },
        { title: "Traffic Share" },
      ],
      webFields: [
        { title: "Webs", class: "source source--small" },
        { title: "Session Duration" },
        { title: "Traffic Share" },
      ],
      options: {
        withTrafficShare: true,
        withSmallSource: true,
        withCheckbox: true,
        withSmallTd: true,
      },
      selectedTags: [],
      modalIsActive: false,
      selectedPersons: [],
      success: false,
    };
  },
  methods: {
    calculatePercentage(number, numberOf) {
      return (number / numberOf) * 100;
    },
    calculateTrafficSharePercentage(sessionDuration) {
      const objectSessionPercentageOf =
        (sessionDuration / this.highestSession) * 100;
      return (objectSessionPercentageOf / 100) * 400;
    },
    getObjectIcon(url, file) {
      if (url) {
        return `https://s2.googleusercontent.com/s2/favicons?domain_url=${url}`;
      } else if (file) {
        return file;
      } else {
        return require("@/assets/icons/icon_traficapp_default.svg");
      }
    },
    addToTags(object) {
      object.image = this.getObjectIcon(object.URL, object.File);
      object.checked = !object.checked;
      const exists = this.selectedTags.filter((el) => el === object).length > 0;
      if (!exists) {
        this.selectedTags.push(object);
      } else {
        this.selectedTags = this.selectedTags.filter((el) => el !== object);
      }
    },
    deleteTag(object) {
      const isWeb = this.webData.filter((el) => el.ID === object.ID).length > 0;
      if (isWeb) {
        const index = this.webData.indexOf(object);
        object.checked = false;
        this.$set(this.webData, index, object);
      } else {
        const index = this.appData.indexOf(object);
        object.checked = false;
        this.$set(this.appData, index, object);
      }
      this.selectedTags = this.selectedTags.filter((el) => el !== object);
    },
    async requestAddition() {
      if (this.selectedTags.length <= 0) return;

      const programs = this.selectedTags.map((el) => ({
        Program: el.ProgramName,
        URL: el.URL,
      }));

      const response = await axios.post(
        "attendance/CreatePersonProgramsRequest",
        {
          TVP: programs,
          Person: this.$store.state.profile.ID,
        }
      );
      if (!response.data.ErrorMsg) {
        this.selectedTags.forEach((el) => {
          this.deleteTag(el);
        });
        this.success = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.traffic__listview {
  height: calc(100% - 80px);
}
.traffic__data {
  display: flex;
  margin-top: 10px;
  padding: 15px 0;
  border-radius: 10px;
  height: 100%;
  justify-content: space-between;

  &--with-header {
    height: calc(100% - 200px) !important;
  }

  &-header {
    margin-bottom: 13px;
    color: #283593;
    margin-left: 70px;
  }
}

.traffic__grid {
  padding-top: 20px;
  overflow-y: auto;
  height: 100%;
  position: relative;
  &::-webkit-scrollbar {
    width: 7px;
    height: 10px;
    max-width: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #9696b9;
    border-radius: 10px;
    max-width: 7px;
  }
}

.traffic__tags {
  margin-top: 25px;
  &-header {
    margin-bottom: 15px;
    font-size: 16px;
    color: #283593;

    span {
      color: #ff5f58;
    }
  }

  .button {
    background-color: rgba(#283593, 0.6%);
    padding: 10px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 10px;
    height: 40px;
    width: 150px;
    cursor: pointer;
    white-space: nowrap;
  }

  &-content {
    display: flex;
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: column;
    width: 1350px;
    height: 100px;
    overflow-x: auto;
    margin-right: 20px;

    &::-webkit-scrollbar {
      width: 7px;
      height: 10px;
      max-width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #9696b9;
      border-radius: 10px;
      max-width: 7px;
    }
  }

  &-tag {
    width: 220px;
    height: 35px;
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 5px 20px 5px 10px;
    border: 1px solid #283593;
    border-radius: 10px;
    color: #283593;
    margin-bottom: 10px;
    margin-right: 25px;

    .cancel {
      cursor: pointer;
      margin-right: 10px;
    }

    .image {
      margin-right: 7px;
    }
  }
}

.header {
  margin-bottom: 15px;
  font-size: 16px;
  color: #283593;
  span {
    color: #ff5f58;
  }
}

.assign-popup {
  display: flex;
  align-items: center;

  .assign-button {
    background-color: rgba(#283593, 0.6);
    padding: 10px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 10px;
    height: 40px;
    width: 145px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.no-records {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  font-size: 50px;
  color: rgba(#283593, 0.5);
}
</style>
