<template>
  <div class="vending-main-container">
    <div class="header">
      <div class="logo">
        <img src="@/assets/icons/vendingUpgamingLogo.svg" />
      </div>
      <span class="material-icons-round logout-button" @click="logout">
        power_settings_new
      </span>
    </div>
    <div
      class="add-to-wallet-content"
      v-if="!loaderActive"
      :class="{ show: addWallet }"
    >
      <div class="wallet-icon-wrapper">
        <img src="@/assets/wallet-img.png" alt="" />
        <h2>Load your wallet</h2>
        <p>Insert money in the machine.</p>
        <div class="text-wrapper">
          <span>When done, press</span>
          <span>"Add to wallet"</span>
        </div>
      </div>
      <div class="buttons-wrapper">
        <div class="add" @click="addWalletHandler">
          <span>Add to wallet</span>
        </div>
        <div class="cancel" @click="addWallet = false">
          <span>Cancel</span>
        </div>
      </div>
    </div>
    <div
      class="vending-mobile-content"
      v-if="!loaderActive && !walletInfo.blocked"
      :class="{ hide: addWallet }"
    >
      <div class="user-name-content">
        <span class="material-symbols-outlined">person</span>
        <span>{{ 'Hello,' + ' ' + name }}</span>
      </div>
      <div
        class="drop-button-wrapper"
        :class="{ hideTimer: dropButtonClicked }"
      >
        <div class="circle one"></div>
        <div class="circle two"></div>
        <div class="circle three"></div>

        <div class="countdown-timer">
          <div class="pick-snack-text">Pick your snack</div>
          <div class="svg-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 200 200"
              fill="none"
              color="#3f51b5"
            >
              <defs>
                <linearGradient id="spinner-secondHalf">
                  <stop offset="0%" stop-opacity="1" stop-color="#a259ff" />
                  <stop offset="100%" stop-opacity="1" stop-color="#5aa4e1" />
                </linearGradient>
                <linearGradient id="spinner-firstHalf">
                  <stop offset="0%" stop-opacity="1" stop-color="#09f9bf" />
                  <stop offset="100%" stop-opacity="1" stop-color="#5aa4e1" />
                </linearGradient>
              </defs>
              <defs>
                <linearGradient id="spinner-firstHalf2">
                  <stop offset="0%" stop-opacity="0.48" stop-color="#64678f" />
                  <stop
                    offset="100%"
                    stop-opacity="0.28"
                    stop-color="#64678f"
                  />
                </linearGradient>
                <linearGradient id="spinner-secondHalf2">
                  <stop offset="0%" stop-opacity="0.5" stop-color="#64678f" />
                  <stop offset="100%" stop-opacity="0.3" stop-color="#64678f" />
                </linearGradient>
              </defs>

              <mask id="timerMask">
                <path
                  class="mask-path"
                  fill="orange"
                  :d="leftTimeString"
                ></path>
              </mask>

              <g stroke-width="15">
                <path
                  d="M 10 100 A 90 90 0 0 1 190 99.9"
                  stroke="url(#spinner-firstHalf2)"
                ></path>
                <path
                  d="M 190 99.9 A 90 90 0 0 1 10 100"
                  stroke="url(#spinner-secondHalf2)"
                ></path>
              </g>

              <g stroke-width="15" mask="url(#timerMask)">
                <path
                  stroke="url(#spinner-secondHalf)"
                  d="M 10 100 A 90 90 0 0 1 190 99.99"
                />

                <path
                  stroke="url(#spinner-firstHalf)"
                  d="M 190 99.6 A 90 90 0 0 1 10 100"
                />
              </g>
            </svg>
          </div>
          <div class="left-time">
            <span class="time">{{ countDown }}</span>
          </div>
          <div class="cancel-content">
            <div class="cancel-button" @click="cancelDrop">
              <span>Cancel</span>
            </div>
          </div>
        </div>
        <div class="buttons-content">
          <div class="button" @click.stop="onDropButton(3)">
            <img class="btn" src="@/assets//vendingButton3.png" alt="" />
          </div>
          <div class="button" @click.stop="onDropButton(4)">
            <img class="btn" src="@/assets//vendingButton4.png" alt="" />
          </div>
        </div>
      </div>

      <div class="background-package-container">
        <div class="package-content">
          <div class="left-side">
            <div class="top">
              <span class="title">Active Package</span>
              <span class="limit-name">{{ limitInfo.LimitGroupName }}</span>
            </div>
            <div class="bottom">
              <div class="text-container">
                <span>Discount</span>
                <span class="amount percent">
                  {{ limitInfo.DiscountPercentAmount + ' ' + '%' }}
                </span>
              </div>
              <div class="text-container">
                <span>Daily Limit</span>
                <span class="amount">
                  {{
                    limitInfo.DailyLimitFixedAmount
                      ? limitInfo.DailyLimitFixedAmount + ' ' + 'GEL'
                      : '-'
                  }}
                </span>
              </div>
              <div class="text-container">
                <span>Monthly Limit</span>
                <span class="amount" v-if="limitInfo.MonthlyLimitFixedAmount">
                  {{ 'GEL' + ' ' + limitInfo.MonthlyLimitFixedAmount }}
                </span>
                <span v-else>{{ ' ' + '-' }}</span>
              </div>
            </div>
          </div>
          <div class="right-side">
            <div class="logo">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="155"
                viewBox="0 0 17.277 19.949"
                fill
              >
                <g
                  id="Group_2011"
                  data-name="Group 2011"
                  transform="translate(-679.333 -531.774)"
                >
                  <path
                    id="Path_2630"
                    data-name="Path 2630"
                    d="M227.265,175.424l.961.6a.525.525,0,0,0,.8-.442V168.9a.526.526,0,0,1,.261-.452l8.373-4.809,2.171,1.253-8.4,4.827a.526.526,0,0,0-.259.45v9.675l-6.124-3.536a.7.7,0,0,1-.35-.6v-9.275a.525.525,0,0,1,.261-.451l8.376-4.836,2.171,1.253-8.387,4.8a.525.525,0,0,0-.263.453v7.03A.874.874,0,0,0,227.265,175.424Z"
                    transform="translate(454.633 370.624)"
                    fill="#17FFF1"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_2631"
                    data-name="Path 2631"
                    d="M547.415,363.453l-2.217,1.265a.7.7,0,0,0-.35.6v2.084l4.436-2.541a.7.7,0,0,0,.35-.6v-6.125a.525.525,0,0,0-.781-.451l-7.6,4.355a.526.526,0,0,0-.258.452V372.2l1.7-.983a.7.7,0,0,0,.35-.6v-6.748a.524.524,0,0,1,.259-.451l4.374-2.536v2.112A.525.525,0,0,1,547.415,363.453Z"
                    transform="translate(146.975 179.521)"
                    fill="#17FFF1"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div class="background"></div>
      </div>
      <div class="wallet-container">
        <div class="wallet-content">
          <div class="left-side">
            <div class="title">My Wallet</div>
            <div class="amount">
              {{ walletInfo.currency + ' ' + walletInfo.amount }}
            </div>
          </div>
          <div class="right-side">
            <div class="topup-button" @click="onTopupClick">
              <span>Top up</span>
              <span class="plus">+</span>
            </div>
          </div>
        </div>
      </div>
      <div class="debts-totals-container">
        <div class="debt-content">
          <div class="container debt">
            <span class="title">Debt</span>
            <div class="amount">{{ 'GEL' + ' ' + debt.Debt }}</div>
          </div>
          <div class="container monthly">
            <span class="title">Monthly debt limit</span>
            <div class="amount">
              <span
                v-if="!editActive && limitInfo.RequestBlockLimit"
                class="amount-text"
              >
                {{ 'GEL' + ' ' + limitInfo.RequestBlockLimit }}
              </span>
              <div
                v-if="!editActive && !limitInfo.RequestBlockLimit"
                @click="editActive = true"
                class="set-button"
              >
                <span>Set Limit</span>
              </div>
              <div class="input-actions-contnet" v-if="editActive">
                <input
                  v-if="editActive"
                  placeholder="GEL"
                  v-model="limitInfo.RequestBlockLimit"
                  type="text"
                />
                <div class="actions">
                  <span
                    class="material-icons close"
                    v-if="editActive"
                    @click="editActive = false"
                  >
                    close
                  </span>
                  <span
                    class="material-icons done"
                    @click="editDebt"
                    v-if="editActive"
                  >
                    done
                  </span>
                </div>
              </div>
              <span
                class="material-icons"
                @click="editActive = true"
                v-if="!editActive && limitInfo.RequestBlockLimit"
              >
                edit
              </span>
            </div>
          </div>
        </div>
        <div class="totals-content">
          <div class="container discount">
            <span class="title">Total Discount</span>
            <div class="amount">{{ 'GEL' + ' ' + debt.TotalDiscount }}</div>
          </div>
          <div class="container today-total">
            <span class="title">Today's Exspenses</span>
            <div class="amount">
              {{ 'GEL' + ' ' + debt.Debt }}
            </div>
          </div>
        </div>
      </div>
      <div class="transactions-content">
        <div class="header">
          <span>Transactions</span>
        </div>

        <div v-if="transactionsLength > 0" class="transactions">
          <template v-for="(transactions, key) in transGroups">
            <div class="date-container" :key="key">
              <span class="date">{{ transactions[0].monthAndDay }}</span>
              <div
                class="single-transaction"
                v-for="trn in transactions"
                :key="trn.id"
              >
                <div class="left-side">
                  <div
                    class="circle"
                    :class="{
                      green: trn.transaction_type == 'FREEVEND',
                      red: trn.transaction_type != 'FREEVEND',
                    }"
                  ></div>
                  <div class="tansaction-type">
                    <div class="type">
                      {{
                        trn.transaction_type == 'FREEVEND'
                          ? limitInfo.LimitGroupName
                          : trn.transaction_type
                      }}
                    </div>
                    <div class="product-name">{{ trn.product_name }}</div>
                  </div>
                </div>
                <div class="right-side">
                  <div class="amounts">
                    <span>{{ trn.price + ' ' + 'GEL' }}</span>
                    <span>
                      {{
                        trn.price_paid > 0
                          ? '-' + trn.price_paid + ' ' + 'GEL'
                          : trn.price_paid + ' ' + 'GEL'
                      }}
                    </span>
                    <span>
                      {{
                        trn.discount
                          ? trn.discount.amount + ' ' + 'GEL'
                          : 'No discount'
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="no-records" v-else>No Records</div>
      </div>
    </div>
    <div
      class="blocked-user-content"
      v-else-if="walletInfo.blocked && !loaderActive"
    >
      <span>Your account is deactivated</span>
    </div>
    <custom-loader v-if="loaderActive" />
  </div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance.js'
import { getDateString } from '@/Helpers/HelperFunctions'
import CustomLoader from '@/components/ReusableComponents/Loader/CustomLoader.vue'

export default {
  components: {
    CustomLoader,
  },
  async created() {
    this.loaderActive = true
    this.containerHeight = window.innerHeight
    window.addEventListener('resize', function () {
      this.containerHeight = window.innerHeight
    })
    this.getTransactions()
    await this.getPersonInfo()
    this.loaderActive = false
  },

  data() {
    return {
      personInfo: null,
      transactions: null,
      floor: null,
      limitInfo: {
        DailyLimitFixedAmount: '-',
        DiscountPercentAmount: '-',
        LimitGroupID: '-',
        LimitGroupName: 'Set Package',
        LimitID: null,
        MonthlyLimitFixedAmount: null,
        RequestBlockLimit: null,
      },
      dropButtonClicked: false,
      walletInfo: {
        currency: null,
        Code: null,
        amount: null,
      },
      leftTimeString: '',
      debtAmount: '',
      debt: {},
      countDown: 60,
      editActive: false,
      loaderActive: false,
      canceled: false,
      addWallet: false,
      containerHeight: '',
      transactionsLength: '',
    }
  },

  methods: {
    async getPersonInfo() {
      const resp = await axios.get('salary/GetPersonsQuickPickInfo')

      if (resp.data.ErrorMsg) return
      this.personInfo = resp.data.Value
      if (resp.data.Value.LimitInfos[0])
        this.limitInfo = resp.data.Value.LimitInfos[0]
      if (resp.data.Value.UserInfos[0])
        this.walletInfo = resp.data.Value.UserInfos[0]
      if (resp.data.Value.Finances[0]) this.debt = resp.data.Value.Finances[0]
    },
    logout() {
      axios.post('authorization/LogOut').then((resp) => {
        if (!resp.data.ErrorMsg) {
          this.$emit('logout')

          this.$store.state.profile = {}
          this.$store.state.permissions = {}
        }
      })
    },
    collectCatsh() {
      axios.get('salary/CollectCash')
    },
    addWalletHandler() {
      this.collectCatsh()
      this.cancelDrop()
      this.getPersonInfo()
      setTimeout(() => {
        this.addWallet = false
      }, 290)
    },

    async cancelDrop() {
      this.dropButtonClicked = false
      this.canceled = true
      this.countDown = 60
      const resp = await axios.get(
        `/salary/CancelDrop?code=${this.walletInfo.Code}&Floor=${this.floor}`,
      )
    },
    async getTransactions() {
      const resp = await axios.get('/salary/QuickPickTransactions')
      //if (resp.data.ErrorMsg != "") return;
      this.transactions = resp.data.result
      this.transactionsLength = resp.data.result
        ? resp.data.result.length
        : null
    },
    onDropButton(floor) {
      this.canceled = false
      this.dropButtonClicked = true
      this.floor = floor
      this.timer()
      this.countDownTimer()
      this.quickPickDevice(false, floor)
      let interval = setInterval(() => {
        this.getTransactions()
      }, 3000)

      setTimeout(() => {
        clearInterval(interval)
      }, 60000)
      this.$watch('canceled', () => {
        if (this.canceled) {
          clearInterval(interval)
        }
      })
    },
    async onTopupClick() {
      this.cancelDrop()
      setTimeout(() => {
        this.addWallet = true
        this.loaderActive = true
      }, 290)

      await this.quickPickDevice(true)
      this.loaderActive = false
    },
    editDebt() {
      axios
        .post('salary/UpdateQuickPickLimit', {
          quickPickLimitID: this.limitInfo.LimitID,
          requestBlockLimit: this.limitInfo.RequestBlockLimit,
        })
        .then((resp) => {
          this.editActive = false
          this.getPersonInfo()
          this.debtAmount = ''
        })
    },
    async quickPickDevice(addToWallet, floor) {
      const resp = await axios.get(
        `salary/QuickPickDevice?addToWallet=${
          addToWallet ? addToWallet : false
        }&Floor=${floor || null}`,
      )
    },
    timer(leftSeconds = 60) {
      if (leftSeconds < 60) {
        this.leftTimeString = this.pathString(leftSeconds--)
      } else {
        this.leftTimeString =
          'M 99 0.01 A 100 100 0 1 0 100 0 L 100 100 L 99 0.01'
        leftSeconds--
      }
      let intervalRef = setInterval(() => {
        this.leftTimeString = this.pathString(leftSeconds--)
        this.$watch('canceled', () => {
          if (this.canceled) {
            clearInterval(intervalRef)
          }
        })
        if (leftSeconds < 0) {
          clearInterval(intervalRef)

          this.dropButtonClicked = false
        }
      }, 1000)
    },
    countDownTimer() {
      let timeout
      this.$watch('canceled', () => {
        if (this.canceled) {
          clearTimeout(timeout)
          this.countDown = 60
        }
      })
      if (this.countDown > 0) {
        timeout = setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        setTimeout(() => {
          this.countDown = 60
        }, 1000)
      }
    },
    pathString(leftTime = 60) {
      let pathString = this.describeArc(100, 100, 100, 0, 360 * (leftTime / 60))

      return pathString
    },
    describeArc(x, y, radius, startAngle, endAngle) {
      var start = this.polarToCartesian(x, y, radius, endAngle)
      var end = this.polarToCartesian(x, y, radius, startAngle)

      var arcSweep = endAngle - startAngle <= 180 ? '0' : '1'

      var d = [
        'M',
        start.x,
        start.y,
        'A',
        radius,
        radius,
        0,
        arcSweep,
        0,
        end.x,
        end.y,
        'L',
        x,
        y,
        'L',
        start.x,
        start.y,
      ].join(' ')

      return d
    },
    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
      var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
      }
    },
  },
  computed: {
    name() {
      if (!this.$store.state.profile) return
      return this.$store.state.profile.FirstName
    },
    transGroups() {
      if (!this.transactions) return
      let group = {}
      this.transactions.forEach((trans) => {
        let date = new Date(trans.vend_datetime * 1000)
        let dateString = getDateString(date, 'MMM dd yyyy')
        trans.dateString = dateString
        trans.monthAndDay = getDateString(date, 'MMM dd')

        if (group.hasOwnProperty(trans.dateString)) {
          group[trans.dateString].push(trans)
        } else {
          group[trans.dateString] = []
          group[trans.dateString].push(trans)
        }
      })

      return group
    },
  },
  watch: {
    canceled() {
      if (this.canceled) {
        this.leftTimeString =
          'M 99 0.01 A 100 100 0 1 0 100 0 L 100 100 L 99 0.01'
      }
    },
    transactionsLength(to, from) {
      if (from == '') return
      this.cancelDrop()
    },
  },
}
</script>

<style scoped lang="scss">
.blocked-user-content {
  position: absolute;
  top: 20%;
  color: #ff2e63;
  font-weight: 500;
  left: 50%;
  transform: translate(-50%, 50%);
  white-space: nowrap;
}
.no-records {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 13px;
  color: #dadada;
}
.user-name-content {
  display: flex;
  align-items: center;
  padding: 0 25px;
  position: relative;
  z-index: 1;
}
.logout-button {
  &:active {
    transform: scale(0.8);
  }
}
.svg-container {
  position: relative;
}
.countdown-timer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  opacity: 0;
  .pick-your-snack {
    font-size: 20px;
    font-weight: 700;
  }
  svg {
    transform: scaleX(-1) rotate(90deg);
    width: 255px;

    .mask-path {
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
      transition: 1s linear;
    }
  }

  .timer-svg {
    height: 100%;
    width: 100%;
  }

  .circle-bg {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    fill: none;
    // stroke: rgba($blue-primary, 0.2);
    stroke-width: 3;
  }

  .circle {
    fill: none;
    transform: scaleX(-1);
    transform-origin: 50% 50%;

    stroke-width: 3;
    animation: progress 1s ease-out forwards;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
  .timer-svg {
    height: 100%;
    width: 100%;
  }
}

.notVisible {
  opacity: 0;
  transition: opacity 0.4s, height 0 4s;
  height: 0;
}
.button {
  position: relative;
  display: inline-block;
  transition: all 2s;
  border-radius: 50%;
  .btn {
    transition: all 0.3s;
    border-radius: 50%;

    /* &:active {
      transform: scale(0.89);
    } */
  }
  .text {
    position: absolute;
    z-index: 1;
    top: 50%;
    pointer-events: none;
    display: flex;
    font-weight: 700;
    transition: all 0.3s;
    font-size: 18px;
    flex-direction: column;
    // white-space: break-spaces;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.vending-main-container {
  // height: calc(100vh - 40px);

  background-color: #010a43;

  font-family: 'Montserrat';
  > .header {
    height: 40px;
    background-color: #1f2863;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    position: fixed;
    top: 0;
    z-index: 11;
    width: 100%;
    box-shadow: 0 0 10px #010a43;

    .logo {
      img {
        height: 28px;
      }
    }
    > span {
      color: #ffffff;
    }
  }
}
.cancel-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  .cancel-button {
    height: 41px;
    background-color: #263071;
    font-weight: 600;
    width: 60%;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;

    &:active {
      transform: scale(0.89);
    }
  }
}
.vending-mobile-content {
  background-color: #010a43;
  width: 100%;
  height: 100%;
  padding: 25px 0;

  overflow-x: hidden;
  margin-top: 40px;

  color: #ffffff;
  opacity: 1;

  transition: opacity 0.5s ease-in-out;

  &.hide {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  .drop-button-wrapper {
    height: 210px;
    justify-content: center;

    align-items: center;
    display: flex;
    position: relative;
    transition: all 1s;
    .circle {
      width: 200px;
      height: 200px;
      border: 1px solid #141d51;

      position: absolute;
      border-radius: 100%;
      &.two {
        width: 300px;
        height: 300px;
      }
      &.three {
        width: 400px;
        height: 400px;
      }
    }

    &.hideTimer {
      height: 400px;
      margin-top: 30px;
      margin-bottom: 30px;
      .circle {
        display: none;
      }

      .button {
        opacity: 0;

        pointer-events: none;
      }
      .countdown-timer {
        opacity: 1;
        transform: scale(1);
      }
    }

    &.not .name {
      display: flex;
      align-items: center;
      text-transform: capitalize;
      span {
        margin-right: 3px;
      }
      .material-icons-round {
        color: #8f3cff;
      }
    }

    .countdown-timer {
      transition: all 1s;
      opacity: 0;
      transform: scale(0);
      transform-origin: center;
      .pick-snack-text {
        font-size: 22px;
        margin-bottom: 15px;
        font-weight: 700;
      }
      .left-time {
        position: absolute;
        top: 46%;
        left: 51%;
        transform: translate(-50%, -50%);
        span {
          font-size: 12px;
          font-weight: 500;
        }
        .time {
          font-size: 26px;
          letter-spacing: 5px;
          font-weight: 700;
        }
      }
    }
    .buttons-content {
      position: absolute;
      display: flex;
      justify-content: center;
      width: 100%;
      grid-gap: 15px;
    }
    .button {
      /* position: absolute; */
      transition: all 1s;
      opacity: 1;
    }
  }
  .timer-content {
    height: 210px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .background-package-container {
    position: relative;
    padding: 0 25px;
    .background {
      width: 81%;
      border-radius: 18px;
      background-color: #17fff1;
      height: 100px;
      position: absolute;
      bottom: -13px;
      left: 50%;
      transform: translate(-50%);
    }
  }

  .package-content {
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    background-color: #1f2863;
    height: 130px;
    border-radius: 20px;
    padding: 10px 20px;
    .left-side {
      .top {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 14px;
        }

        .limit-name {
          font-weight: 800;
          font-size: 20px;
          z-index: 1;
        }
      }
      .bottom {
        margin-top: 15px;
        .text-container {
          font-size: 10px;
          .amount {
            font-weight: 700;
            color: #1dc7ac;
            font-size: 11px;
            margin-left: 5px;
            &.percent {
              color: #ff2e63;
            }
          }
        }
      }
    }
    .right-side {
      position: absolute;
      top: -15px;
      right: -38px;
      .logo {
        opacity: 0.5;
      }
    }
  }
  .wallet-container {
    padding: 0 25px;
  }
  .wallet-content {
    height: 100px;
    background-color: #1f2863;
    padding: 25px 18px;
    border-radius: 16px;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    .left-side {
      .title {
        font-size: 16px;
      }
      .amount {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 700;
        margin-top: 5px;
      }
    }
    .right-side {
      .topup-button {
        background-color: #8f3cff;
        border-radius: 20px;
        width: 122px;
        height: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        transition: all 0.3s;

        &:active {
          transform: scale(0.89);
        }
        span {
          font-size: 12px;
        }
        .plus {
          font-size: 25px;
        }
      }
    }
  }
  .debts-totals-container {
    padding: 0 25px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    .debt-content,
    .totals-content {
      width: calc(50% - 8px);
      .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 18px;
        height: 85px;
        border-radius: 20px;
        background-color: #192259;
        .title {
          font-size: 12px;
          color: #dadada;
        }
        .amount {
          font-size: 14px;
        }
        &.debt {
          border: 1px solid #ff3c82;
        }
        &.discount {
          border: 1px solid #1dc7ac;
        }
        &.monthly {
          border: 1px solid #8f3cff;
          .input-actions-contnet {
            display: flex;
            height: 30px;
            align-items: center;
            justify-content: space-between;
            border-radius: 20px;
            width: 100%;
            border: 1px solid #858ec5;
            background-color: #293269;
          }
          .set-button {
            span {
              border-radius: 20px;
              padding: 7px 10px;
              background-color: #8f3cff;
              font-size: 13px;
            }
          }
          input {
            outline: none;
            background-color: transparent;
            border: 0;
            padding-left: 5px;
            // border-bottom: 1px solid #8f3cff;
            width: 50px;

            color: #858ec5;
            font-weight: 700;
            font-size: 12px;
            &::placeholder {
              color: #858ec5;
              font-weight: 700;
            }
          }
          .amount {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 7px;
          }
          .material-icons {
            font-size: 15px;
            color: #ffffff;
            background-color: #8f3cff;
            height: 25px;
            width: 25px;
            justify-content: center;
            align-items: center;
            display: flex;
            border-radius: 100%;
            &.done {
              background-color: #1dc7ac;
              height: 25px;
              width: 25px;
              justify-content: center;
              align-items: center;
              display: flex;
              border-radius: 100%;
              margin-right: 2px;
            }
            &.close {
              background-color: #ff3c82;
              margin-right: 10px;
            }
          }
          .actions {
            display: flex;
            justify-content: center;
          }
        }
        &.today-total {
          .amount {
            color: #ff2e63;
          }
        }
      }
    }
  }
  .transactions-content {
    background-color: #10194e;
    margin-top: 15px;
    padding: 20px 0 25px 0;
    border-radius: 40px;
    .header {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
    .transactions {
      .date-container {
        .date {
          margin-bottom: 10px;
          display: block;
          font-size: 12px;
          font-weight: 500;
          color: #858ec5;
          margin-left: 20px;
        }
      }
      .single-transaction {
        background-color: #192259;
        padding: 10px 25px;
        border-radius: 10px;
        height: 64px;
        margin-bottom: 8px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-side {
          display: flex;
          .circle {
            height: 30px;
            width: 30px;
            border-radius: 100%;

            margin-right: 10px;
            &.green {
              background-color: #1dc7ac;
            }
            &.red {
              background-color: #ff3c82;
            }
          }
        }
        .right-side {
          .amounts {
            text-align: right;
            span {
              display: block;
              &:nth-child(2) {
                color: #ff2e63;
                font-size: 11px;
              }
              &:nth-child(3) {
                color: #1dc7ac;
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
}
.add-to-wallet-content {
  position: absolute;
  width: 100%;
  height: calc(100% - 40px);
  background-color: #030c45;
  top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  opacity: 0;
  visibility: hidden;

  &.show {
    transition: opacity 0.5s ease-in-out;
    visibility: visible;
    opacity: 1;
    z-index: 5;
  }
  .wallet-icon-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: 400;

    img {
      margin-bottom: 50px;
    }
    h2 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 40px;
    }
    p {
      font-size: 16px;
    }
    .text-wrapper {
      span:nth-child(2) {
        color: #1dc7ac;
      }
    }
  }
  .buttons-wrapper {
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    div {
      height: 40px;
      width: 55%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    .add {
      background-color: #8f3cff;
      transition: all 0.3s;
      &:active {
        transform: scale(0.89);
      }

      border-radius: 20px;
    }
    .cancel {
      background-color: #263071;

      border-radius: 20px;
    }
  }
}
.material-icons {
  font-family: 'Material Icons';
}
.material-symbols-outlined {
  font-family: 'Material Icons';
  font-size: 19px;
  color: #8f3cff;
}
</style>
