<template>
  <div class="holiday-calendar">
    <div class="year-and-month-selector">
      <div class="select-year">
        <div class="left material-icons" @click="selectedYear--">
          keyboard_arrow_left
        </div>
        <div class="year">{{ selectedYear }}</div>
        <div class="right material-icons" @click="selectedYear++">
          keyboard_arrow_right
        </div>
      </div>
      <div class="select-month">
        <div
          v-for="(month, index) in months"
          class="month"
          :key="index"
          :class="{ selected: selectedMonth == index + 1 }"
          @click="selectedMonth = index + 1"
        >
          <span>{{ month }}</span>
          <span>{{ getHolidayCountForTheMonth(index) }}</span>
        </div>
      </div>
    </div>
    <div class="calendar-content">
      <div class="calendar">
        <div class="select-month">
          <div class="left material-icons" @click="selectedMonth--">
            keyboard_arrow_left
          </div>
          <span class="name">{{ months[selectedMonth - 1] }}</span>
          <div class="right material-icons" @click="selectedMonth++">
            keyboard_arrow_right
          </div>
        </div>
        <div class="week-days">
          <div class="week-day" v-for="day in 7" :key="day">
            {{ dayNames[day - 1] }}
          </div>
        </div>
        <div class="days">
          <div class="row" v-for="row in 6" :key="row">
            <div
              class="day"
              v-for="day in 7"
              :key="(row - 1) * 7 + day"
              :class="{
                'outside-of-selected-month': dateIsOutsideOfSelectedMonth(
                  row,
                  day,
                ),
                'is-holiday': isHoliday(row, day),
              }"
            >
              <div
                class="day-background"
                v-if="isHoliday(row, day)"
                :style="{
                  'background-color':
                    colors[indexOfHoliday(row, day) % colors.length],
                }"
              >
                <span>{{ calculateDay(row, day) }}</span>
              </div>
              <span v-else>{{ calculateDay(row, day) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right-side">
        <div class="vertical"></div>
        <div class="alt-text" v-if="altText">
          <span>No Rest, Just Work</span>
        </div>
        <div class="list">
          <div
            class="holiday"
            v-for="(holiday, index) in holidaysOfThisMonth"
            :key="index"
          >
            <div
              class="color"
              :style="{ 'background-color': colors[index % colors.length] }"
            ></div>
            <div class="date">{{ dateForList(holiday.date) }}</div>
            <div class="name">{{ holiday.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance'
export default {
  created() {
    this.getHolidays()
  },
  data() {
    return {
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      selectedYear: new Date().getFullYear(),
      selectedMonth: new Date().getMonth() + 1,
      colors: [
        '#6FCBF3',
        '#A39CD3',
        '#58E1F2',
        '#90caf1',
        '#F193A3',
        '#79F1D6',
        '#6FE9E1',
        '#F8DD7E',
        '#FBB2E6',
      ],
      holidays: [],
      dayNames: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      altText: false,
    }
  },
  methods: {
    dateForList(date) {
      var month = this.months[date.getMonth()].slice(0, 3)
      return `${month}, ${date.getDate()} ${this.selectedYear
        .toString()
        .slice(-2)}`
    },
    calculateDay(row, weekDay) {
      var number = (row - 1) * 7 + weekDay
      var date = number - this.dayOfFirstInMonth + 1
      if (date > this.lastDateOfMonth) return date - this.lastDateOfMonth
      if (date < 1) return this.lastDateOfPreviousMonth + date
      return date
    },
    // isExcessiveRow(row) {
    //   return this.lastDateOfMonth < (row - 1) * 7 + 1;
    // },
    dateIsOutsideOfSelectedMonth(row, weekDay) {
      var number = (row - 1) * 7 + weekDay
      var date = number - this.dayOfFirstInMonth + 1
      return date > this.lastDateOfMonth || date < 1
    },
    isHoliday(row, weekDay) {
      var number = (row - 1) * 7 + weekDay
      var date = number - this.dayOfFirstInMonth + 1
      return !!this.holidays.find((h) => {
        return (
          h.date.getMonth() + 1 == this.selectedMonth &&
          h.date.getDate() == date
        )
      })
    },
    indexOfHoliday(row, weekDay) {
      var number = (row - 1) * 7 + weekDay
      var date = number - this.dayOfFirstInMonth + 1
      var holiday = this.holidays.find((h) => {
        return (
          h.date.getMonth() + 1 == this.selectedMonth &&
          h.date.getDate() == date
        )
      })
      return this.holidaysOfThisMonth.indexOf(holiday)
    },
    getHolidays() {
      axios
        .get('vacations/GetHolidays', {
          params: {
            Year: this.selectedYear,
            OfficeID: this.$store.state.profile.OfficeID,
          },
        })
        .then((resp) => {
          if (resp.data.ErrorMsg) return
          this.holidays = resp.data.Value.map((h) => ({
            name: h.Name,
            date: new Date(h.FromDate),
          }))
        })
    },
    getHolidayCountForTheMonth(index) {
      return this.holidays.filter((h) => h.date.getMonth() == index).length
    },
  },
  computed: {
    dayOfFirstInMonth() {
      const day = new Date(
        `${this.selectedYear}-${('0' + this.selectedMonth).slice(-2)}-01`,
      ).getDay()
      return day == 0 ? 7 : day
    },
    lastDateOfMonth() {
      var date = new Date(`${this.selectedYear}`, `${this.selectedMonth}`, 0)
      return date.getDate()
    },
    lastDateOfPreviousMonth() {
      var date = new Date(
        `${this.selectedYear}`,
        `${this.selectedMonth - 1}`,
        0,
      )
      return date.getDate()
    },
    holidaysOfThisMonth() {
      let holidays = this.holidays.filter(
        (h) => h.date.getMonth() + 1 == this.selectedMonth,
      )
      return holidays
    },
  },
  watch: {
    selectedMonth(val) {
      if (val > 12) {
        this.selectedMonth = 1
        this.selectedYear++
      }
      if (val < 1) {
        this.selectedMonth = 12
        this.selectedYear--
      }
    },
    selectedYear() {
      this.getHolidays()
    },
    holidaysOfThisMonth(obj) {
      if (!obj.length) {
        this.altText = true
      } else {
        this.altText = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.holiday-calendar {
  display: flex;

  .year-and-month-selector {
    margin-right: 4 * $rem;
    background-color: #9ea3d2;
    border-top-left-radius: 5 * $rem;
    border-bottom-left-radius: 5 * $rem;
    color: white;
    padding: 25 * $rem 0;

    .select-year {
      margin-bottom: 22 * $rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .year {
        font-size: 18 * $rem;
        margin: 0 4 * $rem;
      }

      .left,
      .right {
        font-size: 18 * $rem;
        cursor: pointer;
      }
    }

    .select-month {
      display: flex;
      flex-direction: column;

      .month {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3.5 * $rem 29 * $rem;
        width: 148 * $rem;
        font-size: 12 * $rem;
        cursor: pointer;

        &.selected {
          background-color: #6f77b9;
        }
      }
    }
  }

  .calendar-content {
    background-color: #f6f6fe;
    display: flex;

    .calendar {
      // width: calc(100% - 230 * #{$rem});
      width: unset;
      padding: 33 * $rem;

      .select-month {
        display: flex;
        align-content: center;
        color: rgba($blue-primary, 0.8);
        justify-content: space-between;
        margin-bottom: 35 * $rem;

        .left,
        .right {
          font-size: 18 * $rem;
          cursor: pointer;
        }
      }

      .week-days {
        display: flex;
        margin-bottom: 5 * $rem;

        .week-day {
          text-align: center;
          width: 25 * $rem;
          margin-right: 30 * $rem;
          color: $blue-primary;
          font-weight: 400;
          font-size: 13 * $rem;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .days {
        .row {
          margin-top: 8 * $rem;
          display: flex;

          .day {
            margin-right: 30 * $rem;
            width: 25 * $rem;
            text-align: center;
            padding: 9 * $rem 0;
            color: rgba($blue-primary, 0.8);
            font-weight: 300;

            &:last-child {
              margin-right: 0;
            }

            &.outside-of-selected-month {
              color: rgba($blue-primary, 0.3);
            }

            &.is-holiday {
              color: white;
              position: relative;

              .day-background {
                width: 32 * $rem;
                height: 32 * $rem;
                position: absolute;
                border-radius: 50%;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              span {
                z-index: 1;
              }
            }
          }
        }
      }
    }

    .right-side {
      // width: 230 * $rem;
      width: 23rem;
      height: 100%;
      display: flex;
      padding-left: 6 * $rem;
      position: relative;

      .alt-text {
        width: 70%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        span {
          font-family: 'Anton', sans-serif;
          font-size: 2.425rem;
          color: rgba(#283593, 0.5);
        }
      }

      .vertical {
        height: calc(100% - 18 * #{$rem});
        width: 2 * $rem;
        background-color: #e1e2f3;
        margin-top: 9 * $rem;
        position: absolute;
        left: 9 * $rem;
      }

      .list {
        margin-top: 25 * $rem;
        padding-left: 40 * $rem;
        max-height: 335 * $rem;
        overflow-y: auto;
        width: 100%;

        .holiday {
          display: flex;
          flex-direction: column;
          margin-bottom: 12 * $rem;
          font-size: 14 * $rem;
          position: relative;

          .date {
            color: rgba($blue-primary, 0.7);
            font-weight: 300;
            margin-bottom: 5 * $rem;
          }

          .name {
            margin-left: 30 * $rem;
            color: $blue-primary;
            max-width: 17rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .color {
            position: absolute;
            width: 8 * $rem;
            height: 8 * $rem;
            border-radius: 50%;
            left: -40 * $rem;
            top: 18 * $rem;
          }
        }
      }
    }
  }
}
</style>
