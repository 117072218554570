<template>
  <div class="daily-performance">
    <div class="header">
      <div class="filters">
        <date-range
          v-if="dateRange.fromDate && dateRange.toDate"
          :initialFrom="dateRange.fromDate"
          :initialTo="dateRange.toDate"
          @from="(from) => (dateRange.fromDate = from)"
          @to="(to) => (dateRange.toDate = to)"
        />
        <input-search
          v-show="!userID"
          title="Search Person"
          @select="(value) => (selectedEmployee = value)"
          :options="employeeOptions"
          :isRequired="true"
        />
        <router-link
          :to="`/profile?userID=${selectedEmployee}`"
          v-show="selectedEmployee && !userID && isAdminOrHr"
          class="link"
        >
          View Profile
        </router-link>
        <h1 class="user-details">{{ this.personName }}</h1>
      </div>
      <div class="export-totals">
        <div class="export" @click="exportsAsExcel">
          <span class="material-icons-outlined">file_download</span>
          <span>Export</span>
        </div>
        <div class="total-attendance">
          <span class="total-work">
            Total {{ totals.totalWork ? secondsToHms(totals.totalWork) : 0 }}
          </span>
          <span>/</span>
          <span
            :class="[
              totals.worked >= totals.totalWork ? 'positive' : 'negative',
            ]"
          >
            {{ totals.worked ? secondsToHms(totals.worked) : 0 }}
          </span>
        </div>
      </div>
    </div>
    <div class="performance-wrapper">
      <custom-scrollbar :scrollEvent="scrollEvent">
        <div class="list" @scroll="($event) => (scrollEvent = $event)">
          <template v-if="performances.length">
            <one-day-performance
              v-for="(dayPerformance, index) in performances"
              :key="index"
              :totalAttendance="dayPerformance.totalAttendance"
              :plannedTime="dayPerformance.plannedTime"
              :totalActive="dayPerformance.totalActive"
              @checked-attendance="checkAttendance"
              :date="dayPerformance.date"
              :scheduleStart="dayPerformance.scheduleStart"
              :scheduleEnd="dayPerformance.scheduleEnd"
              :departmentName="dayPerformance.departmentName"
              :inactivities="dayPerformance.inactivities"
              :inOuts="dayPerformance.inOuts"
              :isRecalculated="dayPerformance.isRecalculated"
              :scheduleType="dayPerformance.scheduleType"
              :vacation="dayPerformance.vacation"
              :breakDuration="dayPerformance.breakDuration"
              :totalWork="dayPerformance.scheduleTotalWorkTime"
              :vacationType="dayPerformance.vacationType"
              :firstInList="index == 0"
              :idToSend="selectedEmployee"
              :scheduleId="selectedEmployeeScheduleId"
              @notype="noTypeAssingedHandler()"
            />
          </template>
          <template v-else>
            <one-day-performance
              v-for="(date, index) in range"
              :key="date.getTime()"
              :date="date"
              :firstInList="index == 0"
            />
          </template>
        </div>
      </custom-scrollbar>
    </div>
    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
  </div>
</template>

<script>
import DateRange from '@/components/ReusableComponents/DateRange/DateRange'
import OneDayPerformance from './OneDayPerformance'
import axios from '@/Helpers/AxiosInstance'
import InputSearch from '@/components/ReusableComponents/InputSearch/InputSearch'
import CustomScrollbar from '@/components/ReusableComponents/CustomScrollbar/CustomScrollbar'
import { getDateString } from '@/Helpers/HelperFunctions'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'

export default {
  components: {
    DateRange,
    OneDayPerformance,
    CustomScrollbar,
    InputSearch,
    CustomSnackbar,
  },
  props: {
    userID: {
      type: Number,
      required: false,
    },
  },
  created() {
    this.setInitialDates()
    // this.getPersonName();
    if (this.userID) {
      this.selectedEmployee = this.userID
      this.getPerformances()
    } else this.getEmployees()
  },
  data() {
    return {
      dateRange: {
        fromDate: 0,
        toDate: 0,
      },
      totals: {
        totalWork: '',
        worked: '',
      },
      performances: [],
      personName: '',
      selectedEmployee: '',
      employeeOptions: [],
      scrollEvent: null,
      selectedEmployeeScheduleId: '',
      snackbarPurpose: '',
      snackbarText: '',
      snackbarVisible: false,
    }
  },
  methods: {
    exportsAsExcel() {
      let OfferId = this.clickedOfferId
      const link = document.createElement('a')
      let fromDate = getDateString(this.dateRange.fromDate, 'yyyy/MM/dd')
      let toDate = getDateString(this.dateRange.toDate, 'yyyy/MM/dd')
      link.href = `https://intranet.upgaming.${
        !this.$store.state.isProduction ? 'dev' : 'com'
      }/api/Attendance/GetPersonsDailyPerformanceReport?FromDate=${fromDate}&toDate=${toDate}`
      link.setAttribute('download', `PerformanceReport.xlsx`)
      document.body.appendChild(link)
      link.click()
    },

    // padTo2Digits(num) {
    //   return num.toString().padStart(2, '0')
    // },
    // convertMsToTime(milliseconds) {
    //   let seconds = Math.floor(milliseconds / 1000)
    //   let minutes = Math.floor(seconds / 60)
    //   let hours = Math.floor(minutes / 60)

    //   seconds = seconds % 60
    //   minutes = minutes % 60

    //   hours = hours % 24

    //   return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}`
    // },
    secondsToHms(d) {
      d = Number(d)
      var h = Math.floor(d / 3600)
      var m = Math.floor((d % 3600) / 60)
      var s = Math.floor((d % 3600) % 60)

      var hDisplay = h > 0 ? h + (h == 1 ? ' h,' + ' ' : 'h') : ''
      var mDisplay = m > 0 ? m + (m == 1 ? ' m, ' : 'm ') : ''

      return hDisplay + ' ' + mDisplay
    },
    getPersonName(id) {
      id = this.$route.query.userID

      axios.get(`Person/GetPersonsDetails?Person=${id}`).then((resp) => {
        let firstName = resp.data.Value.FirstName
        let lastName = resp.data.Value.LastName
        let UpperCaseName =
          firstName.charAt(0).toUpperCase() + firstName.slice(1)
        let UpperCaseLastName =
          lastName.charAt(0).toUpperCase() + lastName.slice(1)
        this.personName = `${UpperCaseName} ${UpperCaseLastName}`
      })
    },
    checkAttendance(val, date) {
      axios
        .post('/attendance/AddFlexibleScheduleRecordsIncludeOverTimeLogic', {
          person: this.selectedEmployee,
          from: date.getTime(),
          to: date.getTime(),
          withOverTime: val,
        })
        .then((resp) => {
          if (resp.data.ErrorMsg) return
          this.getPerformances()
        })
    },
    getEmployees() {
      axios
        .get('Person/GetPersons', {
          params: {
            Page: 1,
            PageSize: 1000000,
          },
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.employeeOptions = resp.data.Value.Persons.map((el) => ({
              title: el.FirstName + ' ' + el.LastName,
              value: el.ID,
            }))
          }
        })
    },
    noTypeAssingedHandler() {
      this.snackbarVisible = true
      this.snackbarPurpose = 'error'
      this.snackbarText = 'No schedule assigned'
    },

    setInitialDates() {
      const initialRange = 10
      this.dateRange.toDate = new Date().getTime()
      var d = new Date()
      this.dateRange.fromDate = d.setDate(d.getDate() - initialRange)
    },
    timeStringToMinutes(time) {
      if (!time) return null
      if (time === '23:59:00') return 24 * 60
      if (time === '00:00:00') return 1
      var hours = parseInt(time.split(':')[0])
      var minutes = parseInt(time.split(':')[1])
      return 60 * hours + minutes
    },
    adjustToMyTimeZone(date) {
      date = new Date(date)
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
      return date.getTime()
    },
    getPerformances() {
      if (!this.selectedEmployee) return
      axios
        .get('attendance/GetPersonsDailyPerformance', {
          params: {
            PersonID: this.selectedEmployee,
            FromDate: getDateString(this.dateRange.fromDate, 'yyyy-MM-dd'),
            ToDate: getDateString(this.dateRange.toDate, 'yyyy-MM-dd'),
          },
        })
        .then((resp) => {
          var performances = []
          let VacationTypes = [
            'Paid',
            'NotPaid',
            'DayOff',
            'SickDays',
            'Maternity Leave',
            'Working From Home',
          ]
          this.totals.totalWork =
            resp.data.Value.PerformanceSum.TotalWorkTimeSum
          this.totals.worked = resp.data.Value.PerformanceSum.AttendanceTimeSum
          resp.data.Value.Performances.forEach((el) => {
            let performance = {}
            performance.date = new Date(el.Day)
            performance.scheduleStart = this.timeStringToMinutes(
              el.ScheduleStartTime,
            )
            performance.scheduleEnd = this.timeStringToMinutes(
              el.ScheduleEndTime,
            )
            performance.scheduleTotalWorkTime = el.TotalWorkTime

            performance.BreakEnd = el.BreakEnd
            performance.BreakStart = el.BreakStart
            // if (
            //     el.ScheduleType == 2 &&
            //     performance.scheduleStart &&
            //     performance.scheduleEnd
            // ) {
            //     //adjust flexible schedule start and end accordingly
            //     let offset = new Date().getTimezoneOffset()
            //     performance.scheduleStart -= offset
            //     performance.scheduleEnd -= offset
            // }
            performance.scheduleType = el.ScheduleType
            performance.vacation = el.VacationType
            performance.isRecalculated = el.CalculatedFlexibleWithOverTimeLogic

            performance.totalAttendance = el.AttendanceTime

            performance.plannedTime = el.TotalWorkTime

            performance.totalActive = el.ActiveTime

            // 1 - Vacation
            // 2 - Holiday
            if (el.VacationType) {
              performance.vacationType = VacationTypes.includes(el.VacationType)
                ? 1
                : 2
            }
            performance.breakDuration = el.TotalBreak
              ? this.timeStringToMinutes(el.TotalBreak)
              : performance.BreakEnd && performance.BreakStart
              ? (performance.BreakEnd - performance.BreakStart) / 60000
              : 0
            performance.inactivities = el.PCMonitoringRecords.map((entry) => {
              let start = new Date(entry.IdleStartTime)
              start =
                start.getHours() * 3600 +
                start.getMinutes() * 60 +
                start.getSeconds() +
                new Date().getTimezoneOffset() * 60
              let duration = entry.IdleTime / 1000
              return { start, duration }
            })
            performance.inOuts = el.MonitoringRecords.map((entry) => {
              let time = entry.InteractionTime
              return {
                type: entry.ActionType,
                time: time,
              }
            })
            performances.push(performance)
          })
          this.performances = performances.reverse()
        })
    },
  },
  watch: {
    dateRange: {
      deep: true,
      handler: function (range) {
        if (this.selectedEmployee) this.getPerformances()
      },
    },
    selectedEmployee() {
      this.getPerformances()
      axios
        .get(`Schedules/GetPersonSchedules?Person=${this.selectedEmployee}`)
        .then((resp) => {
          this.selectedEmployeeScheduleId = resp.data.Value[0].Schedule
        })
    },
  },
  computed: {
    range() {
      var range = []
      for (
        let d = this.dateRange.fromDate;
        d <= this.dateRange.toDate;
        d += 24 * 3600000
      ) {
        range.push(new Date(d))
      }
      return range.reverse()
    },
    isAdminOrHr() {
      return (
        this.$store.getters.hasRole('HR') ||
        this.$store.getters.hasRole('Admin')
      )
    },
  },
}
</script>

<style scoped lang="scss">
.daily-performance {
  height: calc(100% - 30px);
  padding: 1.25rem 4.0625rem;

  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    align-items: center;
    .filters {
      display: flex;
      align-items: center;
    }
    .export-totals {
      display: flex;

      .total-attendance {
        display: flex;
        align-items: center;
        margin-left: 40px;
        padding-right: 10px;
        font-weight: 500;
        .total-work {
          color: $blue-primary;
        }

        .positive {
          color: #1de9b6;
          // margin-left: 3px;
        }
        .negative {
          color: #dc3f59;
          // margin-left: 3px;
        }
      }
      .export {
        width: 120px;
        background-color: #6956d6;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        border-radius: 0.3125rem;
        cursor: pointer;
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        margin-left: auto;
        .material-icons-outlined {
          margin-right: 5px;
          color: #1de9b6;
        }
      }
    }
    .date-range {
      margin-right: 15 * $rem;
    }

    a {
      margin-left: 10 * $rem;
    }
  }
}

.performance-wrapper {
  border: $rem solid #dedff4;
  border-radius: 10 * $rem;
  margin-top: 15 * $rem;
  height: calc(100% - 60 * #{$rem});
  overflow: hidden;
  user-select: none;

  .list {
    height: 100%;
    overflow: auto;
    width: calc(100% + 17 * #{$rem});

    &::-webkit-scrollbar {
      height: 8 * $rem;
      width: 9 * $rem;
      color: #fff;
    }

    &::-webkit-scrollbar-track {
      background: rgba($blue-primary, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba($blue-primary, 0.3);
    }
  }
}

.user-details {
  font-family: 'Roboto';
  color: #283593;
  font-size: 1.125rem;
  font-weight: 400;
  margin-top: 0.55rem;
  margin-left: 0.9375rem;
}

::v-deep {
  .scrollbar {
    left: 88 * $rem;
  }
}
</style>
