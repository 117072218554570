var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section",class:_vm.type,style:({
    width:
      ((_vm.isNightTime ? _vm.adjustedTo - _vm.adjustedFrom : _vm.to - _vm.from) * 100) /
        (24 * 60) +
      '%',
    left: ((_vm.isNightTime ? _vm.adjustedFrom : _vm.from) * 100) / (24 * 60) + '%',
  })},[_vm._v(" "+_vm._s(_vm.text)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }