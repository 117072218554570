<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <span
      @click="(open = !open), $emit('show-statuses')"
      class="material-icons arrow-down"
      >keyboard_arrow_down</span
    >
    <div class="items" :class="{ selectHide: !open }">
      <template v-for="(option, i) of options">
        <div
          v-if="!option.IsChosen"
          :style="{ color: option.color }"
          :key="i"
          @click="optClickHandler(option)"
        >
          {{ option.Title }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    person: Object,
  },
  data() {
    return {
      selected: this.default,
      open: false,
    };
  },
  methods: {
    optClickHandler(option) {
      this.selected = option;
      this.open = false;
      this.$emit("select-opt", option, this.person);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;
}
.material-icons {
  cursor: pointer;
  color: $blue-primary;
  opacity: 0.7;
  padding-top: 11px;
  padding-left: 5px;
}

.custom-select .selected {
  background-color: #0a0a0a;
  border-radius: 6px;
  border: 1px solid #666666;
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .selected.open {
  border: 1px solid #ad8225;
  border-radius: 6px 6px 0px 0px;
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.custom-select .items {
  color: #fff;
  border-radius: 6px;
  overflow: hidden;
  position: absolute;
  background-color: white;
  right: 0;
  z-index: 1;
  top: 42px;
}

.custom-select .items div {
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #e3e4eb;
}

.selectHide {
  display: none;
}
.items {
  width: 150px;
}
</style>