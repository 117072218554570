<template>
  <div class="container">
    <div class="round">
      <input type="checkbox" :checked="value" :id="id" @click="$emit('changed', !value)"/>
      <label :for="id"></label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
    },
  },
}
</script>

<style scoped>
.round {
  position: relative;
}

.round label {
  background-color: transparent;
  border: 2px solid #283593;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}


.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #1DE9B6;
  border-color: #1DE9B6;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

</style>
