<template>
    <div class="selected-permission" :class="extraClass" :title="name">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <rect id="Area" width="16" height="16" fill="#fcfcfc" opacity="0"/>
            <svg xmlns="http://www.w3.org/2000/svg" width="9.886" height="9.886" viewBox="0 0 9.886 9.886">
                <line id="Line" x1="8" y2="8" transform="translate(0.943 0.943)" fill="none" stroke="#283593" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.333"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9.886" height="9.886" viewBox="0 0 9.886 9.886">
                <line id="Line" x2="8" y2="8" transform="translate(0.943 0.943)" fill="none" stroke="#283593" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.333"/>
            </svg>
        </svg>
        <span class="name">{{name}}</span>
    </div>
</template>

<script>
export default {
    props: {
        name: String,
        extraClass: String,
    },
}
</script>

<style scoped lang="scss">
.selected-permission {
    display: flex;
    align-items: center;
    height: 36 * $rem;
    border: $rem solid rgba($blue-primary, 0.5);
    border-radius: 6 * $rem;
    cursor: pointer;
    font-size: 13 * $rem;
    font-weight: 500;
    color: $blue-primary;

    & > svg {
        position: relative;
        top: 3 * $rem;
        margin: 0 7 * $rem 0 11 * $rem;
        flex-shrink: 0;
    }

    .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }
}
.noDelete {
    & > svg {
        display: none;
    }
}
</style>