<template>
  <section
    class="traffic background"
    :style="{ margin: `${$route.path.includes('MyTraffic') ? '' : '0px'}` }"
  >
    <div class="traffic__header">
      <div class="traffic__filters">
        <date-range
          style="margin-right: 3px"
          :initialFrom="filters.period.from"
          :initialTo="filters.period.to"
          @from="(from) => (filters.period.from = from)"
          @to="(to) => (filters.period.to = to)"
          title="Filter By Period"
        />
        <router-link
          tag="span"
          :to="{ path: '/TrafficHistory' }"
          class="history-button"
          >History</router-link
        >
        <span class="traffic__workspace">
          <router-link to="MyWorkspace" tag="span"> My Workspace </router-link>
        </span>
      </div>
    </div>
    <user-traffic-list-view
      :app-loading="appDataLoading"
      :web-loading="webDataLoading"
      :period="filters.period"
      @app-scrolled="appDataPage = appDataPage + 1"
      @web-scrolled="webDataPage = webDataPage + 1"
      :app-data="appsData"
      :web-data="websData"
    />
  </section>
</template>

<script>
import DateRange from "@/components/ReusableComponents/DateRange/DateRange";
import axios from "@/Helpers/AxiosInstance";
import UserTrafficListView from "@/components/Traffic/UserTraffic/UserTrafficListView";
import { getDateString } from "@/Helpers/HelperFunctions";

export default {
  components: { UserTrafficListView, DateRange },
  props: {
    currentView: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    await this.getUsedWebs();
    await this.getUsedApps();
    this.$store.state.showTimeLine = true;
  },
  data() {
    return {
      filters: {
        period: {
          to: this.$route.params.date
            ? this.$route.params.date.getTime()
            : new Date(new Date().setDate(new Date().getDate() - 1)).getTime(),

          from: this.$route.params.date
            ? this.$route.params.date.getTime()
            : new Date(new Date().setDate(new Date().getDate() - 1)).getTime(),
        },
      },
      options: {
        persons: null,
        jobs: null,
        departments: null,
      },
      showOptions: [
        {
          title: "20",
          value: 20,
        },
        {
          title: "50",
          value: 50,
        },
        {
          title: "100",
          value: 100,
        },
      ],
      topAppsData: [],
      appsData: [],
      appDataPage: 1,
      websData: [],
      webDataPage: 1,
      appDataLoading: true,
      webDataLoading: true,
    };
  },
  methods: {
    async getUsedApps(insert = false) {
      if (!insert) {
        this.appDataLoading = true;
      }
      const response = await axios.get("attendance/GetUsedPrograms", {
        params: {
          fromDate: getDateString(this.filters.period.from, "yyyy/MM/dd"),
          toDate: getDateString(this.filters.period.to, "yyyy/MM/dd"),
          person: this.$store.state.profile.ID,
          // person: 160,
          page: this.appDataPage,
        },
      });

      const { Value } = response.data;
      Value.forEach((el) => (el.checked = false));

      if (insert) {
        Value.forEach((el) => {
          this.websData.push(el);
        });
      } else {
        this.appsData = Value;
      }

      this.appDataLoading = false;
    },
    async getUsedWebs(insert = false) {
      if (!insert) {
        this.webDataLoading = true;
      }
      const response = await axios.get("attendance/GetUsedWebs", {
        params: {
          fromDate: getDateString(this.filters.period.from, "yyyy/MM/dd"),
          toDate: getDateString(this.filters.period.to, "yyyy/MM/dd"),
          person: this.$store.state.profile.ID,
          // person: 160,
          page: this.webDataPage,
        },
      });
      const { Value } = response.data;
      Value.forEach((el) => (el.checked = false));
      if (insert) {
        Value.forEach((el) => {
          this.websData.push(el);
        });
      } else {
        this.websData = Value;
      }

      this.webDataLoading = false;
    },
  },
  watch: {
    filters: {
      async handler() {
        if (this.currentView) {
          await this.getTopActiveApps();
        } else {
          await this.getUsedWebs();
          await this.getUsedApps();
        }
      },
      deep: true,
      // immediate: true
    },
    async currentView(newVal) {
      if (newVal) {
        await this.getTopActiveApps();
      } else {
        await this.getUsedWebs();
        await this.getUsedApps();
      }
    },
    async appDataPage() {
      await this.getUsedApps(true);
    },
    async webDataPage() {
      await this.getUsedWebs(true);
    },
  },
};
</script>

<style scoped lang="scss">
.traffic {
  padding: 25px 80px 0;

  &__header {
    display: flex;
  }

  &__filters {
    display: flex;
    //margin-left: auto;
    align-items: center;
    justify-content: space-between;

    .traffic__workspace {
      cursor: pointer;
      color: #283593;
      margin-right: 16.5px;
      color: #ffffff;
      background-color: $green-primary;
      width: 150px;
      height: 36px;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      opacity: 0.9;
      margin-bottom: 2px;
    }

    .traffic__requests {
      cursor: pointer;
      color: red;
      margin-right: 16.5px;
      display: flex;
      align-items: center;

      img {
        height: 16px;
        width: 16px;
        margin-right: 5px;
      }
    }
  }
  .history-button {
    color: $blue-primary;
    cursor: pointer;
    font-weight: 500;
    margin-right: 15px;
    margin-left: 15px;
    width: 100px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    color: #ffffff;
    background: rgba(40, 53, 147, 0.5);
    margin-bottom: 2px;
  }

  .background {
    margin: 0;
  }

  &__data {
    margin-top: 10px;
    padding: 5px;
    background-color: white;
    border-radius: 10px;

    &-header {
      margin-bottom: 13px;
      color: #283593;
      margin-left: 70px;
    }
  }
}
// /deep/.container {
//     height: 39px;
// }
</style>
