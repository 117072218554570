<template>
  <label :class="[{ alternativeInputWidth: title == 'Description' },{'disabled':disabled && alternativeDisable}]">
    <span
      :class="[{ focused: focused || (value !== '' && (value || value === 0))},{'purpleColor':alternativeBorder}]"
      class="title"
      :style="{ 'background-color': titleBackgroundColor }"
      >{{ title }} {{ isRequired ? "*" : null }}</span
    >
    <img v-if="icon && !value" :src="icon" class="icon" />
    <input
      :min="min"
      :max="max"
      :type="inputType"
      v-on="listeners"
      :value="value"
      @focus="doFocus"
      @blur="focused = false"
      :name="name"
      :required="isRequired"
      autocomplete="off"
      :disabled="disabled"
      ref="input"
      spellcheck="false"
      :placeholder="placeholder"
      :readonly="readOnly"
      :class="{'purpleBorder':alternativeBorder}"
    />

    <i class="material-icons error" v-show="isError">error</i>
    <img class="warning" v-if="warning" src="@/assets/info-circle.svg" alt="">
    <i
      class="material-icons clear"
      v-show="!isError && value && value.length > 0"
      @click="$emit('clear')"
      >cancel</i
    >
    <span class="helper" v-show="!isError">{{ helperText }}</span>
    <span class="error-message" v-show="isError">{{ errorMessage }}</span>
    <span class="warning-text" v-show="warning">{{ warningText }}</span>
  </label>
</template>

<script>
export default {
  props: {
    min: {
      type: Number,
      default: -9999999999,
    },

    max: {
      type: Number,
      default: 99999999999,
    },
    inputType: {
      type: String,
      default: "text",
    },
    value: [String, Number],
    title: String,
    placeholder: String,
    isError: {
      type: Boolean,
      default: false,
    },
    helperText: String,
    errorMessage: String,
    name: String,
    isRequired: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    titleBackgroundColor: String,
    triggerChangeOnBlur: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: null,
    },
    disabled: Boolean,
    readOnly:{
      type: Boolean,
      default: false,
    },
    alternativeBorder:{
      type: Boolean,
      default: false,
    },
    alternativeDisable:{
      type: Boolean,
      default:false,
    },
    warning:{
      type: Boolean,
      default: false,
    },
    warningText:{
      type: [String,Number],
      default: null
    }
  },
  mounted() {
    if (this.focus) {
      this.$refs.input.focus();
    }
  },
  methods: {
    inputHandler(e) {
      this.$emit("input", e.target.value);
    },
    doFocus() {
      this.focused = true;
      this.$emit("focus");
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    listeners() {
      if (this.triggerChangeOnBlur) {
        return { change: this.inputHandler };
      }
      return { input: this.inputHandler };
    },
  },
};
</script>

<style scoped lang="scss">
$border-color: $green-primary;
label {
  width: 194 * $rem;
  position: relative;
  display: inline-block;
  height: 40 * $rem;

  &.disabled{
    opacity: .7;
    input{
      cursor: not-allowed;
      background: transparent;
    }
  }

  span.title {
    color: rgba($blue-primary, 0.7);
    position: absolute;
    top: 13px;
    font-size: 14px;
    font-weight: 300;
    margin-left: 15 * $rem;
    transition: all 0.2s ease-out;
    background-color: white;
    white-space: nowrap;
    cursor: text;
    max-width: 190 * $rem;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 3px;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(35%, transparent), color-stop(24%, #fff), to(#fff));
    background: linear-gradient(to bottom, transparent 0%, transparent 35%, #fff 24%, #fff 100%);
    &.focused {
      top: -6 * $rem;
      font-size: 12 * $rem;
      padding-left: 2 * $rem;
      padding-right: 2 * $rem;
      left: 0;
      font-weight: 300;
    }
    &.purpleColor{
      color: rgba($purple,.9);
    }
  }

  .icon {
    position: absolute;
    right: 10px;
    top: 20px;
    transform: translateY(-50%);
  }

  input {
    width: 100%;
    height: 40 * $rem;
    border-radius: 6 * $rem;
    box-shadow: none;
    border: 1 * $rem solid #dcdde6;
    color: rgba($blue-primary, 0.7);
    font-size: 15 * $rem;
    padding: 0 15 * $rem 0 15 * $rem;
    outline: none;
    font-weight: 500;
    transition: ease .2s;

    &.purpleBorder{
      &:focus{
        border: 0.0625rem solid rgba($purple,.5);
      }
    }
  }

  .material-icons {
    font-size: 18 * $rem;
    position: absolute;
    right: 15 * $rem;
    top: 12 * $rem;

    &.error {
      color: rgb(175, 3, 3);
    }

    &.clear {
      cursor: pointer;
      color: #b4b4b4;
      display: none;
    }
  }

  .helper {
    position: absolute;
    top: 45 * $rem;
    left: 30 * $rem;
    font-size: 12 * $rem;
    color: #5c5c5c;
    width: calc(100% - 30 *#{$rem});
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
  }

  .error-message {
    position: absolute;
    top: 43 * $rem;
    left: 15 * $rem;
    font-size: 12 * $rem;
    color: rgb(175, 3, 3);
    width: calc(100% - 15 *#{$rem});
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
  }
}

.warning{
  width: 1.125rem;
  position: absolute;
  right: 0.4375rem;
  top: 50%;
  transform: translate(0,-50%);
  user-select: none;
  pointer-events: none;
}

.warning-text{
  font-size: 0.8125rem;
  margin-left: 0.3125rem;
  color: rgba(#FF9233,.7);
  font-weight: 400;
  user-select: none;
  pointer-events: none;
  white-space: nowrap;
  transition: ease .3s;
  animation: show .5s forwards;
  @keyframes show {
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.alternativeInputWidth {
  width: 44rem !important;
}
</style>
