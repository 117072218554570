<template>
  <div class="background">
    <div class="monitoring-header">
      <div class="left-side">
        <div
          class="active-tab"
          @click="(activePerson = !activePerson), (weekendPerson = false)"
          :class="{ clickedActiveTab: activePerson }"
        >
          <span>Active</span><span>{{ activePeopleCount }}</span>
        </div>
        <div
          class="offline-tab"
          @click="(offlinePerson = !offlinePerson), (weekendPerson = false)"
          :class="{ clickedOfflineTab: offlinePerson }"
        >
          <span>Offline</span><span>{{ offlinePeopleCount }}</span>
        </div>
        <div
          class="vacation-tab"
          @click="
            (vacationPerson = !vacationPerson), (weekendPerson = !weekendPerson)
          "
          :class="{ clickedVacationTab: vacationPerson || weekendPerson }"
        >
          <span>Weekend/Vacation</span><span>{{ vacationPeopleCount }}</span>
        </div>
      </div>
      <div class="right-side">
        <div class="date-content">
          <activity-calendar
            :trafficCalendar="true"
            :monitoringCalendar="true"
            @dateChange="getDateValue"
          />
          <span v-if="dateChanged == false">{{ defoultDateText }}</span>
          <span v-if="dateChanged == true">{{ dateText }}</span>
        </div>
        <div class="search-content">
          <input-search
            title="Search Person"
            :options="personOptions"
            @select="(id) => (searchedPersonID = id)"
          />
        </div>
      </div>
    </div>
    <section class="employes-section">
      <template v-for="(dep, key) in groupByDep">
        <div class="dep-container" :key="key">
          <h2 class="dep-name" v-if="key == 'null'"></h2>
          <h2 class="dep-name" v-if="key != 'null'">
            {{ key }}
          </h2>
          <div class="persons-content">
            <div class="person" v-for="person in dep" :key="person.index">
              <div
                class="avatar"
                :class="[
                  {
                    offlineBorder:
                      person.Active == false &&
                      person.OnVacation == false &&
                      !person.OnWeekend,
                  },
                  { vacationBorder: person.OnVacation || person.OnWeekend },
                ]"
                :style="{
                  backgroundImage: `url(${getAvatarSrc(person.PersonID)})`,
                }"
              ></div>
              <div class="person-info-wrapper">
                <span class="person-name">{{
                  person.FirstName + " " + person.LastName
                }}</span>
                <span
                  v-if="(person.FirstName + person.LastName).length > 26"
                  class="tooltip"
                  >{{ person.FirstName + " " + person.LastName }}</span
                >
                <span class="job-name">{{ person.JobName }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance.js";
import { getDateString } from "@/Helpers/HelperFunctions";
import InputSearch from "../ReusableComponents/InputSearch/InputSearch.vue";
import ActivityCalendar from "@/components/Dashboard/ActivityCalendar";

export default {
  components: {
    InputSearch,
    ActivityCalendar,
  },
  created() {
    this.getData();
    this.getPersons();
  },
  data() {
    return {
      data: [],
      persons: [],
      searchedPersonID: "",
      selectedDate: "",
      dateChanged: false,
      activePerson: false,
      offlinePerson: false,
      vacationPerson: false,
      weekendPerson: false,
    };
  },
  watch: {
    activePerson: function () {
      if (this.activePerson == true) {
        this.offlinePerson = false;
        this.vacationPerson = false;
      }
    },
    offlinePerson: function () {
      if (this.offlinePerson == true) {
        this.activePerson = false;
        this.vacationPerson = false;
      }
    },
    vacationPerson: function () {
      if (this.vacationPerson == true) {
        this.activePerson = false;
        this.offlinePerson = false;
      }
    },
    selectedDate: function (to, from) {
      if (from) {
        this.dateChanged = true;
        const date = new Date(to);
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let year = date.getFullYear();
        let day = date.getDate();
        let dateToSend = year + "-" + month + "-" + day;
        axios
          .get(`attendance/GetPersonsPCMonitringSoftStatus?Day=${dateToSend}`)
          .then((resp) => {
            this.data = resp.data.Value;
          });
      }
    },
  },

  methods: {
    getData() {
      axios.get("attendance/GetPersonsPCMonitringSoftStatus").then((resp) => {
        this.data = resp.data.Value;
      });
    },
    getPersons() {
      axios.get("Person/GetPersons").then((resp) => {
        this.persons = resp.data.Value.Persons;
      });
    },
    getDateValue(newValue) {
      this.selectedDate = newValue;
    },
    getAvatarSrc(id) {
      return this.$store.state.getAvatar + id;
    },
  },
  computed: {
    groupByDep() {
      var deps = {};

      this.filteredByStatus.forEach((user) => {
        if (deps.hasOwnProperty(user.DepartmentName)) {
          deps[user.DepartmentName].push(user);
        } else {
          deps[user.DepartmentName] = [];
          deps[user.DepartmentName].push(user);
        }
      });

      return deps;
    },
    defoultDateText() {
      return getDateString(new Date(), "Today, ww, dd MMM   HH:mm");
    },
    dateText() {
      return getDateString(this.selectedDate, "yyyy, ww, dd MMM   HH:mm");
    },
    filteredList() {
      let result;
      if (this.searchedPersonID == "") {
        result = this.data;
      } else {
        result = this.data.filter((person) => {
          return person.PersonID == this.searchedPersonID;
        });
      }

      return result;
    },
    filteredByStatus() {
      let result;
      if (
        !this.activePerson ||
        !this.offlinePerson ||
        !this.vacationPerson ||
        !this.weekendPerson
      ) {
        result = this.filteredList;
      }
      if (this.activePerson == true) {
        result = this.filteredList.filter((el) => el.Active == true);
      }
      if (this.offlinePerson == true) {
        result = this.filteredList.filter(
          (el) =>
            el.Active == false &&
            el.OnVacation == false &&
            el.OnWeekend == false
        );
      }
      if (this.vacationPerson || this.weekendPerson) {
        result = this.filteredList.filter(
          (el) => el.OnVacation == true || el.OnWeekend == true
        );
      }

      return result;
    },
    personOptions() {
      return this.persons.map((emp) => ({
        title: emp.FirstName + " " + emp.LastName,
        value: emp.ID,
      }));
    },
    activePeopleCount() {
      let counter = this.data.filter((el) => el.Active == true);
      return counter.length;
    },
    offlinePeopleCount() {
      let counter = this.data.filter(
        (el) => !el.Active && !el.OnVacation && !el.OnWeekend
      );
      return counter.length;
    },
    vacationPeopleCount() {
      let counter = this.data.filter(
        (el) => el.OnVacation == true || el.OnWeekend == true
      );
      return counter.length;
    },
  },
};
</script>

<style scoped lang="scss">
.background {
  padding: 25px 70px 0 70px;
  overflow-y: hidden;
}
.monitoring-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  .left-side {
    display: flex;
    div {
      background-color: #fff;
      border-radius: 5px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      cursor: pointer;
      span:nth-child(2) {
        margin-left: 5px;
      }
    }
    .active-tab {
      width: 100px;
      color: $blue-primary;
      &.clickedActiveTab {
        background-color: $blue-primary;
        color: #ffffff;
      }
    }
    .offline-tab {
      width: 100px;
      color: #ff5f58;
      &.clickedOfflineTab {
        background-color: #ff5f58;
        color: #ffffff;
      }
    }
    .vacation-tab {
      width: 200px;
      color: #1de9b6;
      &.clickedVacationTab {
        color: #ffffff;
        background-color: #1de9b6;
      }
    }
  }
  .right-side {
    display: flex;
    align-items: center;
    .date-content {
      color: $blue-primary;
      font-weight: 300;
      margin-right: 25px;
      display: flex;

      align-items: center;
    }
  }
}
.employes-section {
  height: 806px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.dep-container {
  .person:nth-child(7n) {
    .tooltip {
      left: -100px;
    }
  }
  .dep-name {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 20px;
    color: $blue-primary;
    font-weight: 400;
  }
  .persons-content {
    display: flex;
    flex-wrap: wrap;

    .tooltip {
      visibility: hidden;
      background-color: #fff;
      color: $blue-primary;
      white-space: nowrap;
      display: flex;
      font-weight: 300;
      font-size: 14px;
      border-radius: 3px;
      padding: 5px;
      opacity: 0;
      transition: opacity 0.5s ease;
      position: absolute;
      top: 28px;
      left: 20px;
      z-index: 20;
    }
    .person {
      width: 14.2%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      text-align: center;
      .person-name {
        color: $blue-primary;
        font-weight: 300;
        margin-top: 10px;
        max-width: 206px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover + .tooltip {
          visibility: visible;
          transition: opacity 0.5s ease;
          opacity: 1;
        }
      }
      .job-name {
        color: #9696b9;
        font-weight: 300;
        margin-top: 5px;
      }

      .avatar {
        width: 90px;
        height: 90px;
        border-radius: 100%;
        background-color: #ffffff;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &.vacationBorder {
          border: 3px solid #1de9b6;
        }
        &.offlineBorder {
          border: 3px solid #ff5f58;
        }
      }
    }
    .person-info-wrapper {
      display: flex;
      flex-direction: column;
      min-height: 75px;
      user-select: none;
      position: relative;
    }
  }
}
::v-deep.search-content {
  .input-search {
    width: 250px;
  }
  label {
    width: 250px;
  }
}
</style>