<template>
  <div class="select-content">
    <div class="container" @click.stop="peopleVisible = !peopleVisible">
      <span class="title" :class="{ focused: checkedPerson }">{{ title }}</span>
      <span class="selected" v-if="checkedPerson">{{ checkedTitle }}</span>
      <i class="material-icons">keyboard_arrow_down</i>
    </div>
    <div class="persons-content" @click.stop v-show="peopleVisible">
      <div class="clear">
        <span @click="clearHandler">clear</span>
      </div>
      <input placeholder="search..." type="text" v-model="search" />
      <div
        class="single-person"
        @click="personClickHanlder(person)"
        v-for="person in filteredPersons"
        :key="person.index"
      >
        <span>{{ person.title }}</span>

        <custom-checkbox :value="person.selected" />
      </div>
    </div>
  </div>
</template>

<script>
import CustomCheckbox from '@/components/ReusableComponents/CustomCheckbox/CustomCheckbox'

export default {
  created() {
    // this.getPersons();
    document.addEventListener('click', this.documentClick)
  },
  components: {
    CustomCheckbox,
  },
  props: {
    allPersons: {
      type: Array,
      default: () => [],
    },
    isOpened: Boolean,
    title: {
      type: String,
      default: 'Select person/s',
    },
    customEvent: Boolean,
  },

  data() {
    return {
      peopleVisible: false,
      search: '',
      checkedPerson: false,
      checkedTitle: '',
    }
  },
  methods: {
    documentClick() {
      this.peopleVisible = false
      // debugger;
    },
    personClickHanlder(el) {
      if (this.customEvent) this.$emit('custom-click')
      this.$emit('person-clicked', el)
      this.checkedPerson = false
      let selectedTitle = this.allPersons
        .filter((pe) => pe.selected === true)
        .map((el) => ({
          title: el.title,
        }))
      if (selectedTitle.length > 0) {
        this.checkedTitle = selectedTitle[0].title
        this.checkedPerson = true
      }
    },
    clearHandler() {
      this.$emit('clear')
      this.search = ''
      this.allPersons.forEach((el) => {
        el.selected = false
      })
      this.checkedPerson = false
    },

    isSelectedMethod() {
      let selectedTitle = this.allPersons
        .filter((pe) => pe.selected === true)
        .map((el) => ({
          title: el.title,
        }))
      if (selectedTitle.length > 0) {
        this.checkedTitle = selectedTitle[0].title
        this.checkedPerson = true
      }
    },
  },
  computed: {
    filteredPersons() {
      if (!this.search.length) return this.allPersons
      return this.allPersons.filter((el) =>
        el.title.toLowerCase().includes(this.search.toLowerCase()),
      )
    },
    isSelected() {
      return this.isSelectedMethod()
      // return this.allPersons
      //   .filter((pe) => pe.selected === true)
      //   .map((el) => ({
      //     title: el.title,
      //   }));
    },
  },
  watch: {
    isOpened(to, from) {
      if (this.isOpened === false) {
        this.checkedPerson = false
        this.checkedTitle = ''

        this.allPersons.forEach((el) => {
          if (el.selected == true) {
            el.selected = false
          }
        })
      }
    },
    isSelected() {
      if (this.isSelected.length > 0) {
        this.checkedTitle = this.isSelected[0].title
        this.checkedPerson = true
      }
    },
    peopleVisible() {
      this.allPersons.sort(function (x, y) {
        return x.selected === y.selected ? 0 : x.selected ? -1 : 1
      })
    },
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick)
  },
}
</script>

<style lang="scss" scoped>
.select-content {
  position: relative;
}
.clear {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 400;
  color: rgba($blue-primary, 0.5);
  span {
    cursor: pointer;
  }
}
.container {
  width: 250 * $rem;
  height: 38 * $rem;
  padding-left: 15 * $rem;
  padding-right: 15 * $rem;
  border-radius: 6 * $rem;
  border: $rem solid #dcdde6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s ease-out;
  .selected {
    font-weight: 300;
    font-size: 16 * $rem;
    color: rgba($blue-primary, 0.8);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 15px;
  }
  .title {
    font-weight: 300;
    font-size: 16 * $rem;
    color: rgba($blue-primary, 0.7);
    transition: all 0.2s ease-out;

    position: absolute;
    top: 0.625rem;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 35%,
      #fff 24%,
      #fff 100%
    );
    &.focused {
      top: -6 * $rem;
      font-size: 13 * $rem !important;
    }
  }

  i {
    font-size: 20 * $rem;
    color: rgba($blue-primary, 0.7);
    position: absolute;
    right: 10px;
  }
}
.persons-content {
  position: absolute;
  list-style: none;
  background-color: #fff;
  z-index: 150;
  overflow-y: auto;
  width: 15.625rem;
  max-height: 17rem;
  background-color: white;
  border: 0.0625rem solid #dcdde6;
  color: #dadcea;
  border-radius: 0.375rem;
  box-shadow: $box-shadow2;
  padding: 12 * $rem 0;
  user-select: none;
  overflow-y: auto;
  animation: show 0.1s linear;
  top: 45px;

  .single-person {
    display: flex;
    justify-content: space-between;
    // align-items: baseline;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    color: rgba($blue-primary, 0.7);
    margin-top: 10px;
  }
  input {
    width: calc(100% - 35px);
    margin: 0 0 0 1.125rem;
    padding-left: 0.75rem;
    background-color: #f4f4f8;
    border-radius: 0.1875rem;
    font-weight: 200;
    font-size: 0.8125rem;
    outline: none;
    color: #9696b9;
    border: none;
    height: 1.875rem;
    margin-bottom: 0.3125rem;
  }
}
</style>
