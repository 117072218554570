<template>
  <section class="traffic background">
    <div class="traffic_additional_header">
      <ul>
        <li>
          <view-changer
            :change-view="currentView"
            @view-changed="(arg) => (currentView = arg)"
          />
        </li>
        <li>
          <router-link
            tag="span"
            :to="{ path: '/TrafficHistory' }"
            class="history-button"
          >
            History
          </router-link>
        </li>

        <li>
          <span class="traffic__workspace">
            <router-link to="TrafficWorkspace" tag="span">
              Workspace
            </router-link>
          </span>
        </li>
        <li>
          <span class="traffic__requests">
            <router-link to="TrafficRequests" tag="span">
              <!-- <img
                :src="require('@/assets/icons/red/icon_notification_red.svg')"
                alt=""
              /> -->
              Requests
            </router-link>
          </span>
        </li>
      </ul>
    </div>
    <div class="traffic__header">
      <!-- <view-changer
        :change-view="currentView"
        @view-changed="(arg) => (currentView = arg)"
      /> -->
      <div class="traffic__filters">
        <!-- <span class="traffic__workspace">
          <router-link to="TrafficWorkspace" tag="span">
            Workspace
          </router-link>
        </span>
        <span class="traffic__requests">
          <router-link to="TrafficRequests" tag="span">
            <img
              :src="require('@/assets/icons/red/icon_notification_red.svg')"
              alt=""
            />
            Requests
          </router-link>
        </span>
        <router-link
          tag="span"
          :to="{ path: '/TrafficHistory' }"
          class="history-button"
          >History</router-link
        > -->
        <date-range
          style="margin-right: 3px"
          :initialFrom="filters.period.from"
          :initialTo="filters.period.to"
          @from="(from) => (filters.period.from = from)"
          @to="(to) => (filters.period.to = to)"
          title="Filter By Period"
        />
        <input-search
          title="Filter By Department"
          :options="options.departments"
          :icon="require('@/assets/icons/icon_arrow_down.svg')"
          @select="(value) => (filters.departments = value)"
        />
        <input-search
          title="Filter By Jobs"
          :options="options.jobs"
          :icon="require('@/assets/icons/icon_arrow_down.svg')"
          @select="(value) => (filters.jobs = value)"
        />
        <input-search
          title="Filter By Persons"
          :options="options.persons"
          :icon="require('@/assets/icons/icon_arrow_down.svg')"
          @select="(value) => (filters.persons = value)"
        />
        <input-search
          title="Filter By App/Web"
          :options="options.apps"
          :icon="require('@/assets/icons/icon_arrow_down.svg')"
          @select="(value) => (filters.apps = value)"
          v-if="!currentView"
        />
        <custom-select
          v-show="currentView"
          :title="`Show ${filters.show}`"
          :options="showOptions"
          @input="(opt) => (filters.show = opt)"
          :value="`Show ${filters.show}`"
          class="select-show"
        />
      </div>
    </div>
    <traffic-top
      v-show="currentView"
      :filters="filters"
      :loading="loading"
      :period="filters.period"
      :data="topAppsData"
    />
    <traffic-list-view
      v-show="!currentView"
      :filters="filters"
      :app-loading="appDataLoading"
      :web-loading="webDataLoading"
      :period="filters.period"
      @app-scrolled="appDataPage = appDataPage + 1"
      @web-scrolled="webDataPage = webDataPage + 1"
      @update-everything="updateUsedApps"
      :jobs="jobs"
      :app-data="appsData"
      :web-data="websData"
      :departments="jobsDropdown"
    />
  </section>
</template>

<script>
import DateRange from "@/components/ReusableComponents/DateRange/DateRange";
import InputSearch from "@/components/ReusableComponents/InputSearch/InputSearch";
import ViewChanger from "@/components/ReusableComponents/ViewChanger/ViewChanger";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect";
import axios from "@/Helpers/AxiosInstance";
import TrafficTop from "@/components/Traffic/GridView/TrafficTop";
import TrafficListView from "@/components/Traffic/ListView/TrafficListView";
import { getDateString, getFullName } from "@/Helpers/HelperFunctions";

export default {
  components: {
    TrafficListView,
    ViewChanger,
    DateRange,
    InputSearch,
    CustomSelect,
    TrafficTop,
  },
  props: {
    currentView: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    // await this.getPersons();
    // await this.getJobs();
    // await this.getDepartments();
    // await this.getDepartmentJobs()
    await this.getTrafficDropdown();
    await this.getTopActiveApps();
    // await this.getJobPersons();
    this.$route.query.view = "grid";
    await this.getTopActiveApps();
    await this.getUsedApps();
    await this.getUsedWebs();
  },
  data() {
    return {
      filters: {
        period: {
          to: new Date(new Date().setDate(new Date().getDate() - 1)).getTime(),
          from: new Date(
            new Date().setDate(new Date().getDate() - 1)
          ).getTime(),
        },
        departments: "",
        jobs: "",
        persons: null,
        show: 20,
        apps: null,
      },
      options: {
        persons: [],
        jobs: [],
        departments: null,
        apps: [],
      },
      showOptions: [
        {
          title: "20",
          value: 20,
        },
        {
          title: "50",
          value: 50,
        },
        {
          title: "100",
          value: 100,
        },
      ],
      topAppsData: [],
      appsData: [],
      appDataPage: 1,
      websData: [],
      webDataPage: 1,
      jobs: [],
      jobsList: [],
      loading: true,
      appDataLoading: true,
      webDataLoading: true,
      departments: [],
      jobsDropdown: [],
    };
  },
  methods: {
    async getTrafficDropdown() {
      const dataList = await axios.get(
        "/attendance/GetTrafficFiltersDropDownList"
      );
      let Departments = dataList.data.Value.Departments;
      this.departments = Departments;

      this.setFilterDepartments();
      this.setFilterJobs();
      this.setFilterPersons();

      this.jobsDropdown = Departments.map((dep) => {
        return {
          jobs: dep.Jobs.map((job) => {
            return {
              id: job.JobID,
              name: job.JobName,
              people: job.Persons.map((person) => {
                return {
                  id: person.PersonID,
                  name: person.FullName,
                };
              }),
            };
          }),
        };
      });
    },
    setFilterDepartments() {
      let DepList = this.departments.map((el) => ({
        title: el.DepartmentName
          ? el.DepartmentName
          : el.DepartmentName == null
          ? "Without Department"
          : el.DepartmentName, // temp
        value: el.DepartmentID,
      }));
      DepList = DepList.filter((el) => {
        if (el.value == 0) return;
        else return DepList;
      });
      this.options.departments = DepList;
    },

    setFilterJobs() {
      this.jobsList = [];
      let allJoblist = [];
      this.departments.forEach((el) => {
        el.Jobs.forEach((job) => {
          this.jobsList.push(job);
          allJoblist.push(job);
        });
      });
      allJoblist = allJoblist.map((el) => ({
        title: el.JobName,
        value: el.JobID,
      }));
      this.options.jobs = allJoblist;
    },

    setFilterPersons() {
      this.options.persons = [];
      this.departments.forEach((el) => {
        el.Jobs.forEach((job) => {
          let PersonList = job.Persons.map((persons) => ({
            title: persons.FullName,
            value: persons.PersonID,
          }));

          if (PersonList.length > 0) {
            PersonList.forEach((person) => {
              this.options.persons.push(person);
            });
          }
        });
      });
    },
    searchInnerFilters() {
      let filteredDepID = this.filters.departments;
      let filteredJobID = this.filters.jobs;

      let selectedDep = this.departments.filter(
        (dep) => dep.DepartmentID == filteredDepID
      );
      let filteredJobs = selectedDep[0].Jobs.map((job) => ({
        title: job.JobName,
        value: job.JobID,
      }));
      this.options.jobs = filteredJobs;
      let filteredPersonsByDep = [];
      selectedDep[0].Jobs.forEach((job) => {
        let personsByDep = job.Persons.map((person) => ({
          title: person.FullName,
          value: person.PersonID,
        }));
        personsByDep.forEach((el) => {
          filteredPersonsByDep.push(el);
        });
      });
      this.options.persons = filteredPersonsByDep;

      let selectedJob = this.jobsList.filter(
        (job) => job.JobID == filteredJobID
      );
      let filteredPersons = selectedJob[0].Persons.map((el) => ({
        title: el.FullName,
        value: el.PersonID,
      }));
      this.options.persons = filteredPersons;
    },
    async getTopActiveApps() {
      this.loading = true;
      const response = await axios.get("attendance/GetTopActiveApps", {
        params: {
          fromDate: getDateString(this.filters.period.from, "yyyy/MM/dd"),
          toDate: getDateString(this.filters.period.to, "yyyy/MM/dd"),
          person: this.filters.persons,
          job: this.filters.jobs,
          department: this.filters.departments,
          topNum: this.filters.show,
        },
      });
      this.topAppsData = response.data.Value;
      this.loading = false;
    },
    async getUsedApps(insert = false) {
      if (!insert) {
        this.appDataLoading = true;
      }
      const response = await axios.get("attendance/GetUsedPrograms", {
        params: {
          fromDate: getDateString(this.filters.period.from, "yyyy/MM/dd"),
          toDate: getDateString(this.filters.period.to, "yyyy/MM/dd"),
          person: this.filters.persons,
          job: this.filters.jobs,
          department: this.filters.departments,
          page: this.appDataPage,
          ProgramName: this.filters.apps,
        },
      });

      const { Value } = response.data;
      Value.forEach((el) => {
        el["blankIcon"] = false;
      });
      Value.forEach((el) => (el.checked = false));

      if (insert) {
        Value.forEach((el) => {
          this.appsData.push(el);
        });
      } else {
        this.appsData = Value;
        let tempArr = this.appsData.map((el) => ({
          title: el.ProgramName,
          value: el.ProgramName,
        }));
        tempArr.forEach((el) => {
          this.options.apps.push(el);
        });
      }

      this.appDataLoading = false;
    },
    async getUsedWebs(insert = false) {
      if (!insert) {
        this.webDataLoading = true;
      }
      const response = await axios.get("attendance/GetUsedWebs", {
        params: {
          fromDate: getDateString(this.filters.period.from, "yyyy/MM/dd"),
          toDate: getDateString(this.filters.period.to, "yyyy/MM/dd"),
          person: this.filters.persons,
          job: this.filters.jobs,
          department: this.filters.departments,
          page: this.webDataPage,
          DomainName: this.filters.apps,
        },
      });
      const { Value } = response.data;
      Value.forEach((el) => (el.checked = false));
      if (insert) {
        Value.forEach((el) => {
          this.websData.push(el);
        });
      } else {
        this.websData = Value;
        let tempArr = this.websData.map((el) => ({
          title: el.URL,
          value: el.URL,
        }));
        tempArr.forEach((el) => {
          this.options.apps.push(el);
        });
      }
      this.webDataLoading = false;
    },
    async updateUsedApps() {
      this.webDataLoading = true;
      this.webDataPage = 1;
      this.appDataLoading = true;
      this.appDataPage = 1;
      await this.getUsedApps();
      await this.getUsedWebs();
    },
  },
  watch: {
    filters: {
      async handler() {
        try {
          this.getTopActiveApps();
          this.getUsedWebs();
          this.getUsedApps();
          this.searchInnerFilters();
        } catch (err) {
          // err
        }
        if (this.filters.departments == "") this.setFilterJobs();
        if (this.filters.jobs == "" && this.filters.departments == "")
          this.setFilterPersons();
      },
      deep: true,
      // immediate: true
    },
    async appDataPage() {
      await this.getUsedApps(true);
    },
    async webDataPage() {
      await this.getUsedWebs(true);
    },
  },
};
</script>

<style scoped lang="scss">
.traffic {
  padding: 25px 25px 0;
  overflow: hidden;

  &__header {
    display: flex;
  }

  &__filters {
    display: flex;
    margin-left: auto;
    align-items: center;
    justify-content: space-between;

    .traffic__workspace {
      cursor: pointer;
      color: #283593;
      margin-right: 16.5px;
    }

    .traffic__requests {
      cursor: pointer;
      color: red;
      margin-right: 16.5px;
      display: flex;
      align-items: center;

      img {
        height: 16px;
        width: 16px;
        margin-right: 5px;
      }
    }
  }

  &__data {
    margin-top: 10px;
    padding: 5px;
    background-color: white;
    border-radius: 10px;

    &-header {
      margin-bottom: 13px;
      color: #283593;
      margin-left: 70px;
    }
  }
}
.history-button {
  color: $blue-primary;
  cursor: pointer;
  margin-right: 15px;
}

.traffic_additional_header {
  width: 120%;
  height: 3.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background: #fff;
  top: -2.025rem;
  left: -3.4375rem;
  padding: 0 1.875rem;

  ul {
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.4375rem;

    li {
      cursor: pointer;
      margin-left: 1.875rem;
      font-size: 1.0625rem;
      color: #283593;

      img {
        width: 0.9375rem;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.select-show {
  ::v-deep .selected-box {
    width: 7rem;
  }
}
</style>
