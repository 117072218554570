<template>
  <date-picker
    :enableTime="true"
    v-model="time"
    :title="time ? 'Date' : 'Today'"
    :isRequired="true"
    :addCustom="'datetime'"
  />
</template>

<script>
import DatePicker from '@/components/ReusableComponents/DatePicker/DatePicker.vue'

export default {
  components: {
    DatePicker,
  },
  data () {
      return{
          time: null,
      }
  },
  props: {
    //   time: [Number, Date, String]
    // title: String,
    // time: 
    // value: [Number, Date, String],
    // enableTime: Boolean,
    // addCustom: [Boolean, String],
    // disabled: Boolean,
    // isRequired: {
    //   type: Boolean,
    //   default: false,
    // },
  },
 watch: {
     time(){
         this.$emit('time-selected', this.time)
     }
 }
}
</script>

<style></style>
