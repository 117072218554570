<template>
    <transition name="fade">
        <div class="wrapper">
            <div class="confirm">
                <div class="content">
                    <i class="material-icons">report_problem</i>
                    <span>{{ text }}</span>
                    <div class="btn yes" @click="$emit('yes') && $emit('close')">YES</div>
                    <div class="btn no" @click="$emit('close')">NO</div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        text: String,
    },
}
</script>

<style lang="scss" scoped>
.wrapper {
    align-items: center;
    color: #ffffff;
    display: flex;
    font-size: 14 * $rem;
    left: 8 * $rem;
    pointer-events: none;
    position: fixed;
    right: 8 * $rem;
    text-align: left;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    z-index: 1000;
    bottom: 5 * $rem;

    .confirm {
        align-items: center;
        border-radius: 4 * $rem;
        display: flex;
        margin: 0 auto;
        pointer-events: auto;
        transition: inherit;
        transition-property: opacity, transform;
        min-width: 40%;
        background-color: #ff9800;
        box-shadow: 0 * $rem 3 * $rem 5 * $rem -1 * $rem rgba(0, 0, 0, 0.2),
            0 * $rem 6 * $rem 10 * $rem 0 * $rem rgba(0, 0, 0, 0.14),
            0 * $rem 1 * $rem 18 * $rem 0 * $rem rgba(0, 0, 0, 0.12);

        .content {
            align-items: center;
            display: flex;
            min-height: 48 * $rem;
            overflow: hidden;
            padding: 0 16 * $rem;
            width: 100%;
            height: 100%;

            i {
                margin-right: 30 * $rem;
            }

            .btn {
                display: flex;
                justify-content: center;
                min-width: initial;
                width: 70 * $rem;
                height: 28 * $rem;
                background-color: rgba(255, 255, 255, 0);
                cursor: pointer;
                align-items: center;

                &:hover {
                    &.yes,
                    &.no {
                        background-color: #f09000;
                    }
                }

                &.yes {
                    margin-left: auto;
                    background-color: #ff9800;
                }

                &.no {
                    margin-left: 5 * $rem;
                    background-color: #ffa319;
                }
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

@media only screen and (max-width: 800px) {
    .snackbar {
        min-width: 70% !important;
    }
}
</style>