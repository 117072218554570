<template>
    <div class="switch-header">
        <ul>
            <li v-show="!route.hidden" :class="['route',{'no-margin':route.hidden}]" v-for="(route,ind) in routes" :key="ind">
                <router-link
                v-if="!route.hidden"
                tag="span"
                :to="{ path: route.path }"
                active-class="active">
                    {{ route.title }}
                </router-link>
            </li>
        </ul>
    </div>    
</template>

<script>
export default {
    props:{
        routes: Array,
    }
    
}
</script>

<style lang="scss" scoped>
.switch-header{
    width: 100%;
    ul{
        list-style: none;

        li.route{
            margin-left: 1.875rem;
            display: inline-flex;

            &.no-margin{
                margin-left: 0 !important;
            }

            &:first-child{
                margin-left: 0;
            }

            span{
                font-size: 0.9375rem;
                color: #4D5E80;
                font-weight: 500;
                line-height: 1.1875rem;
                cursor: pointer;
                position: relative;

                &.active{
                    font-weight: 600;
                    animation: show .5s forwards;
                    &::after{
                        content: "";
                        width: 100%;
                        height: 0.25rem;
                        background: $green-primary;
                        z-index: 9;
                        position: absolute;
                        display: block;
                        bottom: -0.625rem;
                        left: 0;
                        border-radius: 0.3125rem;
                    }
                }
            }
        }
    }
}
</style>