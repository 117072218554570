var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gird"},[_c('div',{staticClass:"table"},[_c('div',{staticClass:"row headers"},[_c('div',{staticClass:"col"}),_c('div',{staticClass:"col"}),_c('div',{staticClass:"col"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"col col--center"},[_vm._v("Users")]),_c('div',{staticClass:"col col--center"},[_vm._v("Session Duration")]),_c('div',{staticClass:"col col--center"},[_vm._v("Traffic Share")])]),_c('div',{staticClass:"body"},[(_vm.loading)?[_vm._m(0)]:(_vm.data && _vm.data.length > 0)?[(!_vm.isWeb)?_vm._l((_vm.data),function(object,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col"},[_c('custom-round-checkbox',{attrs:{"id":`${object.ProgramName}-${index}`,"value":object.checked},on:{"changed":function($event){return _vm.$emit('add-tag', object)}}})],1),_c('div',{staticClass:"col index"},[_vm._v(" "+_vm._s(`${index + 1}`)+" ")]),_c('div',{staticClass:"col"},[[(object.URL)?_c('a',{staticClass:"flex",attrs:{"target":"_blank","href":`https://${object.URL}`}},[_c('div',{staticClass:"img-wrapper"},[_c('img',{class:{
                        'more-width': object.moreWidthThanHeight,
                        'more-height': !object.moreWidthThanHeight,
                      },attrs:{"src":_vm.getObjectIcon(object.URL),"alt":""},on:{"load":(e) => _vm.loadHandler(e, object)}})]),_c('span',{staticClass:"program"},[_vm._v(" "+_vm._s(object.URL)+" ")])]):(!object.blankIcon)?_c('span',{staticClass:"flex app-icon-placeholder"},[_c('div',{staticClass:"img-wrapper"},[_c('img',{class:{
                        'more-width': object.moreWidthThanHeight,
                        'more-height': !object.moreWidthThanHeight,
                      },attrs:{"src":_vm.getProgramLogo(object.ProgramName),"alt":""},on:{"load":(e) => _vm.loadHandler(e, object),"error":function($event){object.blankIcon = true},"click":function($event){_vm.imageModalIsActive = true;
                        _vm.currentObject = object;}}})]),_c('span',{staticClass:"program"},[_vm._v(" "+_vm._s(object.ProgramName)+" ")])]):_c('span',{staticClass:"clickable flex default-icon"},[_c('div',{staticClass:"img-wrapper"},[_c('img',{class:{
                        'more-width': object.moreWidthThanHeight,
                        'more-height': !object.moreWidthThanHeight,
                      },attrs:{"src":require('@/assets/icons/icon_traficapp_default.svg'),"alt":""},on:{"load":(e) => _vm.loadHandler(e, object),"click":function($event){_vm.imageModalIsActive = true;
                        _vm.currentObject = object;}}})]),_c('span',{staticClass:"program"},[_vm._v(" "+_vm._s(object.ProgramName))])])]],2),_c('div',{staticClass:"col col--center",on:{"click":function($event){_vm.userPopupActive = true;
                _vm.currentObject = object;
                _vm.currentObjectIndex = index;}}},[_c('span',{staticClass:"user"},[_vm._v(" "+_vm._s(object.PersonCount ? object.PersonCount : object.Count)+" ")])]),_c('div',{staticClass:"col col--center"},[_vm._v(" "+_vm._s(_vm.timestampToHours(object.SessionDuration))+" ")]),_c('div',{staticClass:"col col--center"},[_vm._v(" "+_vm._s(_vm.calculateTrafficShare( object.SessionDuration, object.TotalTime ))+" ")])])}):_vm._l((_vm.dataComputed),function(object,index){return [_c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col"},[_c('custom-round-checkbox',{attrs:{"id":`${object.ProgramName}-${index}`,"value":object.checked},on:{"changed":function($event){return _vm.$emit('add-tag', object)}}})],1),_c('div',{staticClass:"col index"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"col"},[[(_vm.options.withURL)?_c('i',{class:[
                    'material-icons',
                    'menu',
                    { 'menu--collapsed': object.Collapsed },
                  ],on:{"click":function($event){object.Collapsed = !object.Collapsed;
                    _vm.onCollapseClick(object);}}},[_vm._v(" keyboard_arrow_right")]):_vm._e(),(object.URL)?_c('a',{staticClass:"flex",attrs:{"target":"_blank","href":`https://${object.URL}`}},[_c('div',{staticClass:"img-wrapper"},[_c('img',{class:{
                        'more-width': object.moreWidthThanHeight,
                        'more-height': !object.moreWidthThanHeight,
                      },attrs:{"src":_vm.getObjectIcon(object.URL),"alt":""},on:{"load":(e) => _vm.loadHandler(e, object)}})]),_c('span',{staticClass:"program"},[_vm._v(" "+_vm._s(object.URL)+" ")])]):(object.File)?_c('span',{staticClass:"flex"},[_c('div',{staticClass:"img-wrapper"},[_c('img',{class:{
                        'more-width': object.moreWidthThanHeight,
                        'more-height': !object.moreWidthThanHeight,
                      },attrs:{"src":_vm.getProgramLogo(object.ProgramName),"alt":""},on:{"load":(e) => _vm.loadHandler(e, object),"click":function($event){_vm.imageModalIsActive = true;
                        _vm.currentObject = object;}}})]),_c('span',{staticClass:"program"},[_vm._v(" "+_vm._s(object.ProgramName)+" ")])]):_c('span',{staticClass:"clickable flex"},[_c('div',{staticClass:"img-wrapper"},[_c('img',{class:{
                        'more-width': object.moreWidthThanHeight,
                        'more-height': !object.moreWidthThanHeight,
                      },attrs:{"src":require('@/assets/icons/icon_traficapp_default.svg'),"alt":""},on:{"load":(e) => _vm.loadHandler(e, object),"click":function($event){_vm.imageModalIsActive = true;
                        _vm.currentObject = object;}}})]),_c('span',[_vm._v(" "+_vm._s(object.ProgramName)+" ")])])]],2),_c('div',{staticClass:"col col--center",on:{"click":function($event){_vm.userPopupActive = true;
                _vm.currentObject = object;
                _vm.currentObjectIndex = index;}}},[_c('span',{staticClass:"user"},[_vm._v(" "+_vm._s(object.PersonCount ? object.PersonCount : object.Count)+" ")])]),_c('div',{staticClass:"col col--center"},[_vm._v(" "+_vm._s(_vm.timestampToHours(object.SessionDuration))+" ")]),_c('div',{staticClass:"col col--center"},[_vm._v(" "+_vm._s(_vm.calculateTrafficShare( object.SessionDuration, object.TotalTime ))+" ")])]),(!object.Collapsed && _vm.options.withURL)?_c('div',{key:`urls-${index}`,staticClass:"urls"},[(_vm.urls[object.URL] && _vm.urls[object.URL].length)?_vm._l((_vm.urls[object.URL]),function(obj,ind){return _c('div',{key:`url-${ind}`,staticClass:"row row--urls"},[_c('div',{staticClass:"col"},[_c('a',{staticClass:"flex url-text",attrs:{"target":"_blank","href":`https://${obj.URL}`}},[_vm._v(" "+_vm._s(obj.URL))])]),_c('div',{staticClass:"col col--center users",on:{"click":function($event){_vm.userPopupActive = true;
                    _vm.isURL = true;
                    _vm.currentObject = obj;
                    _vm.currentObjectIndex = ind;}}},[_c('span',{staticClass:"user"},[_vm._v(" "+_vm._s(obj.Count)+" ")])]),_c('div',{staticClass:"col col--center"},[_vm._v(" "+_vm._s(_vm.timestampToHours(obj.SessionDuration))+" ")]),_c('div',{staticClass:"col col--center"},[_vm._v(" "+_vm._s(_vm.calculateTrafficShare( obj.SessionDuration, object.SessionDuration ))+" ")])])}):[_vm._m(1,true)]],2):_vm._e()]})]:_c('div',{staticClass:"no-record"},[_vm._v("No Records")])],2)]),_c('overlay',{attrs:{"visible":_vm.imageModalIsActive},on:{"close":function($event){_vm.imageModalIsActive = false;
      _vm.file = {};}}},[_c('popup',{attrs:{"title":"Add App Logo","visible":_vm.imageModalIsActive,"flexible-size":true},on:{"close":function($event){_vm.imageModalIsActive = false;
        _vm.file = {};}}},[_c('custom-file-input',{attrs:{"title":"Choose File","value":_vm.file},on:{"change":_vm.changeFile}}),_c('div',{staticClass:"upload-button",on:{"click":_vm.uploadLogo}},[_vm._v("Upload")])],1)],1),(_vm.uploadFailed)?_c('custom-snackbar',{attrs:{"text":'Logo size must be less than ' + this.uploadLimit + ' KB - current size is ' + this.logoSize + ' KB',"purpose":_vm.snackbarPurpose},on:{"close":function($event){_vm.uploadFailed = false}}}):_vm._e(),(_vm.uploadSuccess)?_c('custom-snackbar',{attrs:{"text":"Logo was uploaded successfully"},on:{"close":function($event){_vm.uploadSuccess = false}}}):_vm._e(),(_vm.userPopupActive)?_c('custom-popup',{attrs:{"visible":_vm.userPopupActive,"flexible-size":true,"title":_vm.currentObject.URL || _vm.currentObject.ProgramName},on:{"close":function($event){_vm.userPopupActive = false;
      _vm.users = [];}}},[_c('div',{staticClass:"users-modal__wrapper"},[_c('div',{staticClass:"users-modal__title-wrapper"},[_c('div',{staticClass:"users-modal__title"},[_vm._v("Users "+_vm._s(_vm.currentObject.Count))]),_c('custom-input',{staticClass:"users-modal__search",attrs:{"title":"Filter by Persons"},model:{value:(_vm.usersModalSearchInput),callback:function ($$v) {_vm.usersModalSearchInput=$$v},expression:"usersModalSearchInput"}})],1),_c('div',{staticClass:"table"},[_c('div',{staticClass:"row row--user-modal headers"},[_c('div',{staticClass:"col"},[_vm._v("#")]),_c('div',{staticClass:"col"},[_vm._v("User")]),_c('div',{staticClass:"col"},[_vm._v("Session Duration")])]),_c('div',{staticClass:"table-body"},[(_vm.computedUsers.length > 0)?_vm._l((_vm.computedUsers),function(user,index){return _c('div',{key:index,staticClass:"row row--user-modal"},[_c('div',{staticClass:"col"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticClass:"col"},[_vm._v(" "+_vm._s(`${user.FirstName} ${user.LastName}`)+" ")]),_c('div',{staticClass:"col"},[_vm._v(" "+_vm._s(_vm.timestampToHours(user.SessionDuration))+" ")])])}):_c('div',{staticClass:"no-data"},[_vm._v("No Records")])],2)])])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('span',{staticClass:"loader"})]),_c('div',{staticClass:"col"},[_c('span',{staticClass:"loader"})]),_c('div',{staticClass:"col"},[_c('span',{staticClass:"loader"})]),_c('div',{staticClass:"col col--center"},[_c('span',{staticClass:"loader"})]),_c('div',{staticClass:"col col--center"},[_c('span',{staticClass:"loader"})]),_c('div',{staticClass:"col col--center"},[_c('span',{staticClass:"loader"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row row--urls"},[_c('div',{staticClass:"col"},[_c('span',{staticClass:"loader"})]),_c('div',{staticClass:"col col--center"},[_c('span',{staticClass:"loader"})]),_c('div',{staticClass:"col col--center"},[_c('span',{staticClass:"loader"})]),_c('div',{staticClass:"col col--center"},[_c('span',{staticClass:"loader"})])])
}]

export { render, staticRenderFns }