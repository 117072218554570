<template>
    <div class="background" @click="closeDropdowns">
        <custom-snackbar
            :text="snackbar.text"
            :purpose="snackbar.purpose"
            v-if="snackbar.visible"
            @close="snackbar.visible = false"
        />

        <custom-popup
            :visible="editNodePopupVisible"
            @close="editNodePopupVisible = false"
            class="nodeEditPopup"
        >
            <div class="input-wrapper">
                <custom-input
                    :title="'Grading node title'"
                    v-model="updateNode.name"
                    class="custom-input"
                />
                <div class="save-btn">
                    <button @click="saveUpdatedNode">
                        Save
                    </button>
                </div>
            </div>
        </custom-popup>

        <custom-popup
            :title="levelEditMode ? 'Edit Level' : 'Create Level'"
            :visible="createLevelVisible"
            @close="createLevelVisible = false"
            class="create-level-popup"
        >
            <form @submit.prevent="submitCreatedLevel">
                <ul>
                    <li>
                        <custom-input 
                            :title="'Level title'"
                            :isRequired="true"
                            v-model="createLevel.description"
                        />
                    </li>

                    <li>
                        <custom-input 
                            :title="'Min average score'"
                            v-model="createLevel.minAvgScore"
                            :inputType="'number'"
                            :isRequired="true"
                        />
                    </li>

                    <li>
                        <custom-select 
                            :title="'Rating Period'"
                            v-model="createLevel.ratingPeriodID"
                            :options="ratingPeriodOptions"
                            :isRequired="true"
                        />
                    </li>

                    <li>
                        <ul class="horizontal-field">
                            <li class="horizontal-input" v-for="(obj,ind) in salaryFields" :key="ind">
                                <custom-input 
                                    :title="obj.title"
                                    v-model="obj.value"
                                    :inputType="obj.type ? obj.type : ''"
                                    v-if="!obj.select"
                                    :isRequired="true"
                                />
                                <div class="wrapper">
                                    <custom-select
                                        :title="'Currency'"
                                        :options="currencyOptions"
                                        v-model="obj.value"
                                        v-if="obj.select"
                                        :isRequired="true"
                                    />
                                    <div class="actions-wrapper">
                                        <div class="add-salary" @click="addSalaryField" v-if="obj.addPlaceholder">
                                            <img src="@/assets/grades/addpurple.svg" alt="">
                                        </div>
                                        <div @click="deleteSalaryField(obj.fieldCount,obj.deletable)" class="delete-salary-field" v-if="obj.deletePlaceholder">
                                            <img src="@/assets/grades/trash-gray.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="add-salary">
                                <!-- <div @click="addSalaryField">
                                    <img src="@/assets/grades/addpurple.svg" alt="">
                                </div> -->
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="save-btn">
                    <button type="submit">Save</button>
                </div>
            </form>
        </custom-popup>

        <custom-popup
            :title="'Add Criteria'"
            :visible="addCriteriaVisible"
            @close="addCriteriaVisible = false"
            :class="['add-criteria-popup',{'editMode':criteriaEditMode}]"
        >
            <div class="wrapper">
                <form @submit.prevent="saveAddedCriteria(selectedLevelID,parentGradeID)">
                    <div class="top">
                        <div class="field select">
                            <custom-select
                                :title="'Select Criteria'"
                                :options="criteriasList"
                                :disabled="criteriaEditMode"
                                v-model="addCriteria.criteriaID"
                            />
                        </div>
                        <div class="field withWarning">
                            <custom-input
                                :title="'Weight'"
                                v-model="addCriteria.weight"
                                :inputType="'text'"
                                :warning="addCriteria.warning"
                                :warningText="'Weigth Must Be Number Between 0 and 1'"
                                :triggerChangeOnBlur="false"
                                v-if="triggerForcedRender"
                            />
                        </div>
                    </div>
                                
                    <div class="bottom">
                        <div class="field">
                            <custom-input
                                :title="'Min Score'"
                                v-model="addCriteria.minScore"
                                :inputType="'number'"
                            />
                        </div>
                    </div>
                    <div v-if="criteriaEditMode" class="edit-fields">
                        <date-picker
                            :title="'Start Date'"
                            v-model="addCriteria.startDate"
                        />
                        <custom-text-area
                            :title="'Comment'"
                            v-model="addCriteria.comment"
                            :fixedSize="true"
                            :maxLength="999999999"

                        />
                    </div>    
                    <div class="save-wrapper">
                        <button type="submit">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </custom-popup>

        <div class="top-menu">
            <switch-header  :routes="routes" class="switch-header"/>
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="top">
                    <div class="header-title">
                        <span>
                            Grading Nodes
                        </span>
                    </div>
                    <div class="header-filters">
                        <div v-if="!detailsView" class="search">
                            <img src="@/assets/grades/search.svg" alt="search">
                            <input v-model="searchNodeName" type="text" placeholder="Search" spellcheck="false">
                        </div>
                        <ul v-if="detailsView" class="detailed-filters">
                            <li>
                                <div class="search">
                                    <img src="@/assets/grades/search.svg" alt="search">
                                    <input v-model="detailsSearch" type="text" placeholder="Search" spellcheck="false">
                                </div>
                            </li>
                            <!-- <li>
                                <custom-select
                                    :title="'Grade Node'"
                                    :options="filterOptions.gradeNodes"
                                    v-model="gradeNode"
                                />
                            </li>
                            <li>
                                <custom-select
                                    :title="'Grade'"
                                    :options="filterOptions.grades"
                                    v-model="grade"
                                />
                            </li>
                            <li>
                                <custom-select
                                    :title="'Level'"
                                    :options="filterOptions.levels"
                                    v-model="level"
                                />
                            </li>
                            <li>
                                <custom-select
                                    :title="'Criteria'"
                                    :options="filterOptions.criterias"
                                    v-model="criteria"
                                />
                            </li>
                            <li class="clear-filters" @click="clearAllFilters">
                                <div>
                                    <span>Clear Filters</span>
                                </div>
                            </li> -->
                        </ul>
                    </div>
                    <div class="header-actions">
                        <div class="purple-btn add-new-group" @click="createNodeVisible = !createNodeVisible" :style="{'pointer-events':`${assignMode ? 'none' : 'all'}`}">
                            <img :class="{'down':createNodeVisible}" src="@/assets/grades/add.svg" alt="add">
                            <span class="">Grading Node</span>
                        </div>
                        <div class="list-view-btn" @click="viewGradeDetails(0,true)" :style="{'pointer-events':`${assignMode ? 'none' : 'all'}`}">
                            <transition name="fade">
                                <img v-if="detailsView" src="@/assets/grades/list.svg" alt="list">
                                <img v-else class="grid"  src="@/assets/grades/grid.svg" alt="list">    
                            </transition>
                        </div>
                    </div>
                </div>
                <div :class="['bottom',{'visible':createNodeVisible}]">
                    <div class="create-gradeNode">
                        <div class="title">
                            <span>Create Grading Node</span>
                        </div>
                        <div class="input-wrapper">
                            <custom-input
                                :title="'Grading node title'"
                                v-model="createNode.name"
                                class="custom-input"
                            />
                            <div class="save-btn">
                                <button @click="createGradeNode">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="['middle',{'moreHeight':createNodeVisible}]">
                <custom-loader
                    v-if="loading"
                />
                <div v-if="!detailsView" class="gradeNodes-grid">
                    <div v-if="!loading && gradeNodes.length < 1" class="no-records">
                        <span>There are No Records!</span>
                    </div>
                    <node-block
                        :width="'21.3125rem'"
                        :height="'15.625rem'"
                        v-for="(node,ind) in gradeNodes"
                        :editMode="node.editNode"
                        @closeToolbar="node.editNode = false"
                        :key="ind"
                        :title="node.GradeGroup.Name"
                        :grades="node.Grades"
                        :nodeID="node.GradeGroup.GradeGroupID"
                        @view="(id) => viewGradeDetails(id)"
                        :toolbarOptions="nodeGroupToolbarOptions"
                        @editClicked="nodeEditClickHandler"
                        @Edit="updateNodeGroup(node.GradeGroup.GradeGroupID,true,false)"
                        @Delete="updateNodeGroup(node.GradeGroup.GradeGroupID,false,true)"
                        :assignMode="assignMode"
                    />
                </div>
                <div v-if="detailsView" class="gradeNodes-list details">

                    <div :class="['back-btn']">
                        <button :class="{'alert': searchNodeName.length > 0}" @click="exitDetailsMode">
                            <img src="@/assets/grades/back.svg" alt="back">
                            <span>Back</span>
                        </button>
                    </div>
                    <div class="node-list-wrapper">
                        <div class="no-records" v-if="alternateText">
                            <span>There are no Records</span>
                        </div>
                        <ul v-show="!alternateText">
                            <li 
                                :class="{'active':item.dropdownVisible}"
                                v-for="(item,ind) in gradeNodes"
                                :key="ind"
                                ref="nodeItem"
                                v-show="filterByTopParent(item)"
                                >
                                <div class="no-records" v-if="item.dropdownVisible && item.Grades.length < 1 && !item.addGradeVisible">
                                    <span>There are no Records</span>
                                </div>
                                <div :class="['actions-toolbar',{'active':item.toolbarVisible}]">
                                    <ul>
                                        <li class="toolar-dots">
                                            <button @click="showGradeToolbar(item.GradeGroup.GradeGroupID)">
                                                <img v-if="item.toolbarVisible" src="@/assets/grades/toolbardots.svg" alt="">
                                                <img v-else src="@/assets/grades/toolbardots-gray.svg" alt="">
                                            </button>
                                        </li>
                                        <li v-if="item.toolbarVisible">
                                            <button @click="updateNodeName(item,item.GradeGroup.GradeGroupID)">
                                                <img src="@/assets/grades/edit.svg" alt="">
                                                <span>Edit</span>
                                            </button>
                                        </li>
                                        <li v-if="item.toolbarVisible">
                                            <button @click="updateNodeGroup(item.GradeGroup.GradeGroupID,false,true)">
                                                <img src="@/assets/grades/trash.svg" alt="">
                                                <span>Delete</span>
                                            </button>
                                        </li>
                                    </ul>
                                </div>

                                <div class="main-content">
                                    <div class="list-ind">
                                        {{item.GradeGroup.GradeGroupID}}
                                    </div>
                                    <div class="title">
                                        <span v-if="!item.editNode">
                                            {{item.GradeGroup.Name}}
                                        </span>
                                        <div class="edit-node-title" v-if="item.editNode">
                                            <input type="text" v-model="updateNode.name" spellcheck="false" :placeholder="item.GradeGroup.Name">
                                            <div class="save" @click.stop="saveUpdatedNode">
                                                <img src="@/assets/grades/done.svg" alt="">
                                            </div>
                                            <div class="clear" @click="cancelUpdateNode">
                                                <img src="@/assets/grades/exit.svg" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dropdown-arrow">
                                        <button @click="getGradeDetails(item.GradeGroup.GradeGroupID)">
                                            <img src="@/assets/grades/dropdown.svg" alt="arrow">
                                        </button>
                                    </div>
                                </div>
                                <div class="additional-content">
                                    <custom-loader  v-if="item.dropdownVisible && detailsLoading"/>
                                    <div class="details-content">
                                        <draggable 
                                            v-model="selectedGradeDetails.Grades"
                                            @change="dragUpdate"
                                            ghost-class="ghost"
                                            >   
                                                <div  
                                                    class="grades-wrapper"
                                                    v-for="(grade,ind) in selectedGradeDetails.Grades" 
                                                    :key="ind"
                                                    >
                                                        <div class="grade-container">
                                                            <div class="grade-header">
                                                                <div class="col-title">
                                                                    <div class="grade-lvl-index">
                                                                        <span>
                                                                            <img src="@/assets/grades/drag.svg" alt="">
                                                                            {{ind + 1}}
                                                                        </span>
                                                                    </div>
                                                                    <span class="grade-title main-title">
                                                                        Grade
                                                                    </span>
                                                                    <span class="item-title">
                                                                        {{grade.GradeDescription ? grade.GradeDescription : '-'}}
                                                                    </span>
                                                                </div>
                                                                <div class="col-title">
                                                                    <span class="grade-title">
                                                                        Badge
                                                                    </span>
                                                                    <span class="item-title">
                                                                        {{grade.GradeBadgeName ? grade.GradeBadgeName : '-'}}
                                                                    </span>
                                                                </div>
                                                                <div class="col-title col-badge">
                                                                    <img :src="
                                                                        `https://intranet.upgaming.dev/api/grading/GetGradeBadgeNameIcon?GradeID=${grade.GradeID}`
                                                                        " 
                                                                        alt="">
                                                                </div>
                                                                <div class="grade-actions">
                                                                    <button class="edit" @click="editGrade(item.GradeGroup.GradeGroupID,grade.GradeID)">
                                                                        <img src="@/assets/grades/edit-gray.svg" alt="edit">
                                                                    </button>

                                                                    <button @click="deleteGrade(grade.GradeID)" class="delete">
                                                                        <img src="@/assets/grades/trash-gray.svg" alt="delete">
                                                                    </button>
                                                                    <button v-if="grade.GradeParts.length > 0" @click="grade.collapsedLevels = !grade.collapsedLevels" class="collapse">
                                                                        <img :class="[{'down':grade.collapsedLevels}]" src="@/assets/grades/arrowCircle.svg" alt="arrow">
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <draggable 
                                                                v-model="grade.GradeParts"
                                                                @change="dragUpdateLevel"
                                                                ghost-class="ghost"
                                                                v-if="grade.collapsedLevels"
                                                            >
                                                                <div class="levels-container" v-for="(level,ind) in grade.GradeParts" :key="ind">
                                                                    <div class="levels-header">
                                                                        <div class="order-ind">
                                                                            <span title="">
                                                                                {{grade.Level}}.{{level.Level}}
                                                                            </span>
                                                                        </div>
                                                                        <div class="level-col-title">
                                                                            <span class="col-title">
                                                                                Level
                                                                            </span>
                                                                            <span class="col-value">
                                                                                {{level.GradePartDescription ? level.GradePartDescription : '-'}}
                                                                            </span>
                                                                        </div>

                                                                        <div class="level-col-title">
                                                                            <span class="col-title">
                                                                                Rating Period
                                                                            </span>
                                                                            <span class="col-value">
                                                                                {{level.RatingPeriodName ? level.RatingPeriodName : '-'}}
                                                                            </span>
                                                                        </div>

                                                                        <div class="level-col-title">
                                                                            <span class="col-title">
                                                                                Min Salary
                                                                            </span>
                                                                            <span class="col-value">
                                                                                {{filteredSalary(level.SalaryRanges,level.selectedCurrency,true,false)}}
                                                                            </span>
                                                                        </div>

                                                                        <div class="level-col-title">
                                                                            <span class="col-title">
                                                                                Max Salary
                                                                            </span>
                                                                            <span class="col-value">
                                                                                {{filteredSalary(level.SalaryRanges,level.selectedCurrency,false,true)}}
                                                                            </span>
                                                                        </div>

                                                                        <div class="level-col-title">
                                                                            <span class="col-title">
                                                                                Currency
                                                                            </span>
                                                                            <span class="col-value col-currency">
                                                                                {{
                                                                                    currencyOptions.find(el => el.value === level.selectedCurrency).title
                                                                                }}
                                                                                <div ref="currencyOptions" class="currency-list" v-if="level.currencyOptionsVisible">
                                                                                    <div class="wrapper">
                                                                                        <div @click="(level.selectedCurrency = item.value)" :class="['single-currency',{'chosen':level.selectedCurrency === item.value}]" v-for="(item,ind) in salaryRanges(level.SalaryRanges)" :key="ind">
                                                                                            <span>
                                                                                                {{item.title}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <button @click.stop="showCurrencyOptions(level.GradePartID,grade.GradeID)">
                                                                                    <img v-if="level.SalaryRanges.length > 1" :class="{'up':level.currencyOptionsVisible}" src="@/assets/grades/smallarrow.svg" alt="">
                                                                                </button>
                                                                            </span>
                                                                        </div>

                                                                        <div class="level-col-title">
                                                                            <span class="col-title">
                                                                                Min Average Score
                                                                            </span>
                                                                            <span class="col-value">
                                                                                {{level.MinAvgScore ? level.MinAvgScore : '-'}}
                                                                            </span>
                                                                        </div>
                                                                        
                                                                        <div class="level-actions">
                                                                            <button @click="getLevelCriterias(level.GradePartID,grade.GradeID)" class="dropdown-arrow">
                                                                                <img :class="{'down':level.levelsDropdownVisible}" src="@/assets/grades/arrowCircleDown.svg" alt="arrow">
                                                                            </button>

                                                                            <button @click="updateGradeLevel(grade.GradeID,level.GradePartID)" class="edit">
                                                                                <img src="@/assets/grades/edit-gray.svg" alt="edit">
                                                                            </button>

                                                                            <button @click="deleteGradeLevel(level.GradePartID)" class="delete">
                                                                                <img src="@/assets/grades/trash-gray.svg" alt="delete">
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                <transition name="fade">
                                                            
                                                                    <div class="criterias-container" v-if="level.levelsDropdownVisible">
                                                                        <div class="criterias-header" v-if="level.criterias ? level.criterias.length > 0 : false">
                                                                            <div class="criteria-col-title">
                                                                                <span class="col-title">
                                                                                    Criteria
                                                                                </span>
                                                                            </div>
                                                                            <div class="criteria-col-title">
                                                                                <span class="col-title">
                                                                                    Weight
                                                                                </span>
                                                                            </div>
                                                                            <div class="criteria-col-title">
                                                                                <span class="col-title ">
                                                                                    Scoring Range
                                                                                </span>
                                                                            </div>
                                                                            <div class="criteria-col-title">
                                                                                <span class="col-title">
                                                                                    Min Score
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="criteria-descriptions" v-if="level.criterias ? level.criterias.length > 0 : false">
                                                                            <div class="single-description" v-for="(criteria,ind) in level.criterias" :key="ind" >
                                                                                <div class="cols">
                                                                                    <div class="col-value">
                                                                                        <span>
                                                                                            {{criteria.CriteriaDescription ? criteria.CriteriaDescription : '-'}}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="col-value col-bold">
                                                                                        <span>
                                                                                            {{criteria.CriteriaWeight ? criteria.CriteriaWeight : '-'}}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="col-value col-bold">
                                                                                        <span>
                                                                                            {{criteria.CriteriaScoringRangeMax ? criteria.CriteriaScoringRangeMax : '-'}}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="col-value col-bold">
                                                                                        <span>
                                                                                            {{criteria.CriteriaMinScore ? criteria.CriteriaMinScore : '-'}}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="criteria-col-actions">
                                                                                        <div :class="['dropdown',{'down':criteriaDescriptionCollapseState(criteria.CriteriaID,level.GradePartID)}]" @click="getCriteriaDescriptions(criteria.CriteriaID,level.GradePartID,grade.GradeID)">
                                                                                            <img src="@/assets/grades/smallarrow.svg" alt="">
                                                                                        </div>
                                                                                        <div class="edit" @click="editCriteria(criteria.CriteriaID,level.GradePartID,grade.GradeID)">
                                                                                            <img src="@/assets/grades/edit-gray.svg" alt="">
                                                                                        </div>
                                                                                        <div class="delete" @click="disableCriteriaWeight(criteria.CriteriaID,level.GradePartID,grade.GradeID)">
                                                                                            <img src="@/assets/grades/trash-gray.svg" alt="">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div 
                                                                                    :class="['content',{'collapsed-empty':!criteriaDescriptionCollapseState(criteria.CriteriaID,level.GradePartID)}]" 
                                                                                    v-for="(desc,ind) in filterDescription(criteria.CriteriaID)" 
                                                                                    :key="ind"
                                                                                >
                                                                                    <div class="wrapper" v-if="criteriaDescriptionCollapseState(criteria.CriteriaID,level.GradePartID)">
                                                                                        <div class="score-range">
                                                                                            <span>
                                                                                                {{desc.CriteriaDescriptionMinScore}} - {{desc.CriteriaDescriptionMaxScore}}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="description">
                                                                                            <span>
                                                                                                {{desc.CriteriaDescriptionDescription ? desc.CriteriaDescriptionDescription : '-'}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="add-criteria-field">
                                                                            <div class="add-criteria">
                                                                                <button @click="addCriteriaPopup(level.GradePartID,grade.GradeID)">
                                                                                    <img :class="{'down':false}" src="@/assets/grades/addpurple.svg" alt="">
                                                                                    <span> Add Criteria </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </transition>
                                                                </div>
                                                            </draggable>
                                                            <div class="add-level-field">
                                                                <div class="add-level">
                                                                    <button @click="addNewLevel(grade.GradeID)">
                                                                        <img :class="{'down':false}" src="@/assets/grades/addpurple.svg" alt="">
                                                                        <span> Add Level </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                        </draggable>
                                    </div>
                                    <div class="add-grade-field">
                                        <div class="add-grade">
                                            <button @click="showAddGradeField(item.GradeGroup.GradeGroupID)">
                                                <img :class="{'down':item.addGradeVisible}" src="@/assets/grades/addpurple.svg" alt="">
                                                <span> {{gradeEditMode ? 'Edit Grade' : 'Add Grade'}} </span>
                                            </button>
                                        </div>
                                        <div class="create-grade" v-if="item.addGradeVisible">
                                            <form @submit.prevent="createNewGrade">
                                                <ul>
                                                    <li>
                                                        <custom-input
                                                            :title="'Grade title'"
                                                            v-model="createGrade.description"
                                                            class="grade-input"
                                                            :isRequired="true"
                                                        />
                                                    </li>

                                                    <li>
                                                        <img class="unity" src="@/assets/grades/unity.svg" alt="">
                                                    </li>

                                                    <li>
                                                        <custom-input
                                                            :title="'Badge name'"
                                                            v-model="createGrade.badgeName"
                                                            class="grade-input"
                                                        />
                                                    </li>

                                                    <li>
                                                        <div :class="['custom-uploader badge',{'uploaded':createGrade.badgeFile.length > 1}]">
                                                            <input type="file" @change="uploadBadge">
                                                            <img class="preview" v-if="createGrade.badgeFile" :src="`data:${createGrade.badgeFileType};base64,${createGrade.badgeFile}`" alt="">
                                                            <img v-if="!createGrade.badgeFile" src="@/assets/grades/file.svg" alt="">
                                                            <span v-if="createGrade.badgeFileName">{{createGrade.badgeFileName}}</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <button class="save-grade" type="submit">
                                                            Save
                                                        </button>
                                                    </li>
                                                </ul>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance.js";
import SwitchHeader from '@/components/ReusableComponents/SwitchHeader/SwitchHeader.vue'
import CustomInput from '@/components/ReusableComponents/CustomInput/CustomInput.vue'
import NodeBlock from '@/components/Grades/GradeComponents/NodeBlock.vue'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import CustomLoader from '@/components/ReusableComponents/Loader/CustomLoader.vue'
import CustomPopup from '@/components/ReusableComponents/CustomPopup/CustomPopup.vue'
import draggable from 'vuedraggable'
import { toBase64 } from "@/Helpers/HelperFunctions";
import CustomSelect from '@/components/ReusableComponents/CustomSelect/CustomSelect.vue'
import CustomTextArea from '@/components/ReusableComponents/CustomTextArea/CustomTextArea.vue'
import DatePicker from '@/components/ReusableComponents/DatePicker/DatePicker.vue'
export default {
    components:{
        SwitchHeader,
        CustomInput,
        NodeBlock,
        CustomSnackbar,
        CustomLoader,
        CustomPopup,
        draggable,
        CustomSelect,
        CustomTextArea,
        DatePicker
    },
    data(){
        return{
            routes:[
                {
                    title:'Grade Nodes',
                    path:'/Grades/GradeNodes'
                },
                {
                    title:'Criterias',
                    path:'/Grades/Criterias'
                },
                {
                    title:'Team Rating',
                    path:'/Grades/TeamRatingOverall'
                }
            ],
            nodeGroupToolbarOptions: [
                {
                    name: 'Edit',
                    icon: '/grades/edit.svg'
                },
                {
                    name: 'Delete',
                    icon: '/grades/trash.svg'
                }
            ],
            createNode:{
                name: '',
            },
            updateNode:{
                gradeGroupID:null,
                name: '',
            },
            snackbar:{
                text:'',
                purpose: '',
                visible: false,
            },
            loading: false,
            createNodeVisible: false,
            createLevelVisible: false,
            editNodePopupVisible: false,
            detailsView: false,

            //filters////
            searchNodeName: '',
            /////////////
            gradeNodes:[],
            selectedGradeNodeID: null,

            //details view//

                //filters

            gradeNode: null,
            grade: null,
            level: null,
            criteria: null,

            detailsSearch: '',
            alternateText: false,

            filterOptions: {
                gradeNodes: [],
                grades: [],
                levels: [],
                criterias:[],
            },

            filteredIDs: [],

            createGrade:{
                description: '',
                gradeGroupID: null,
                level: null,
                badgeName: '',
                badgeFileName: '',
                badgeFileType: '',
                badgeFile: '',
            },
            createLevel:{
                gradeID: null,
                description: '',
                level: null,
                ratingPeriodID: null,
                minAvgScore: null,
                gradePartSalaryRanges: [
                    {
                        currencyID: 0,
                        minSalary: 0,
                        maxSalary: 0,
                    }
                ],
            },
            salaryFields:[
                {
                    title:'Min Salary',
                    type:'number',
                    value: null,
                    select:false,
                    deletable: false,
                    fieldCount: 1,
                },
                {
                    title:'Max Salary',
                    type:'number',
                    value: null,
                    select:false,
                    deletable: false,
                    fieldCount: 1,

                },
                {
                    title:'Currency',
                    value: null,
                    select:true,
                    deletePlaceholder: true,
                    addPlaceholder: true,
                    deletable: false,
                    fieldCount: 1,
                },
            ],
            levelEditMode:false,
            gradeEditMode: false,
            salaryFieldsCounter: 1,
            selectedGradeDetails:{},
            selectedGradePartSalary:[],
            currencyOptions: [],
            ratingPeriodOptions:[],
            detailsLoading: false,

            selectedLevelID: null,
            addCriteriaVisible: false,
            criteriasList:[],
            addCriteria:{
                criteriaID: null,
                weight: null,
                minScore: null,
                warning: false,
            },
            criteriaDropdowns:[],
            criteriaDescriptions:[],
            parentGradeID: null,
            criteriaEditMode: false,
            assignMode: false,
            selectedJobID: null,
            triggerForcedRender: true,
        }
    },
    async created(){
        const routeParam = this.$route.fullPath 
        const param = routeParam.split('?')
        if(param[1]){
            if(routeParam.split('?')[1].split('=')[0] === 'jobID'){
                const jobID = parseInt(routeParam.split('?')[1].split('=')[1])
                if(jobID && jobID !== 0 && jobID !== null){
                    this.assignMode = true
                    this.selectedJobID = jobID
                }else{
                    this.assignMode = false
                    this.selectedJobID = null
                }
            }
        }

        await this.getGradeNodes(true)
        this.getCurrencies()
        this.getRatingPeriods()
        this.getCriteriasList()
    },
    watch:{
        searchNodeName(){
            this.getGradeNodes(true)
        },
        detailsSearch(val){

            axios.get('grading/GetGradingSearchResult',{
                params:{
                    SearchTerm: val
                }
            }).then(resp => {
                let data = resp.data.Value
                let filteredIDs = []
                data.forEach(el => {
                    if(!el.TopParentID || el.TopParentID === null) return
                    filteredIDs.push(el.TopParentID)
                })
                this.filteredIDs = [...new Set(filteredIDs)]
                if(val && this.filteredIDs.length < 1){
                     this.alternateText = true
                }else{
                    this.alternateText = false
                }
            })

            // let domEl = ['span','div']

            // if(val === '' || val === null || !val){
            //     for(let i of domEl){
            //         document.querySelectorAll(`${i}`).forEach(el => {
            //             el.style.color = null
            //         })
            //     }                
            // }else{
            //     let searchString = val.toLowerCase().split(' ').join('').trim()
            //     for(let i of domEl){
            //         document.querySelectorAll(`.content-wrapper ${i}`).forEach(el => {
            //             let elText = el.innerText.toLowerCase().split(' ').join('').trim() 
            //             if(elText.includes(searchString)){
            //                 el.style.color = 'orange'
            //             }else{
            //                 el.style.color = null
            //             }
            //         })
            //     }
            // } ///// alternate hightlight search >> 
        },
    },
    methods:{
        closeDropdowns(){
            if(!this.selectedGradeDetails.Grades) return
            this.selectedGradeDetails.Grades.forEach(el => {
                el.GradeParts.forEach(el => {
                    el.currencyOptionsVisible = false
                })
            })
        },
        createGradeNode(){
            if(!this.createNode.name || this.createNode.name.length < 1 || this.createNode.name === null){
                this.snackbarMessage('Please Enter Node Name','error')
            }else{
                this.loading = true
                axios.post('grading/CreateGradeGroup',{
                    name:this.createNode.name
                }).then(resp => {
                    this.loading = false
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Node Saved Successfully','success')
                        this.createNode.name = ''
                    }
                    this.getGradeNodes(true)
                }).catch(err => {
                    console.log(err);
                    this.loading = false
                })
                
            }
        },
        async getGradeNodes(withLoader,detailsView,gridChange){
            if(withLoader) this.loading = true
            await axios.get('grading/GetGradeGroups',{
                params:{
                    SearchTerm: this.searchNodeName ? this.searchNodeName : null
                }
            }).then(resp => {
                let data = resp.data.Value.GradeGroups
                this.filterOptions.gradeNodes = data.map(el => ({
                    title: el.GradeGroup.Name,
                    value: el.GradeGroup.GradeGroupID
                }))
                data.forEach((el,ind) => {
                    this.$set(el,'dropdownVisible',false)
                    this.$set(el,'editNode',false)
                    this.$set(el,'addGradeVisible',false)
                    this.$set(el,'toolbarVisible',false)
                    this.$set(el,'order',ind + 1)
                })
                this.gradeNodes = data
                this.loading = false
                if(detailsView && !gridChange){
                    this.getGradeDetails(this.selectedGradeNodeID)
                }else return
            })
        },
        viewGradeDetails(id,gridChange){
            if(this.assignMode){
                this.assignGradeToJob(id,this.selectedJobID)
                return
            }else{
                if(id !== null || id){
                    this.selectedGradeNodeID = id
                    if(gridChange){
                        this.detailsView = !this.detailsView
                    }else{
                        this.detailsView = true
                    }
                    // this.createNodeVisible = false
                    this.$nextTick(() => {
                        if(!gridChange) this.getGradeNodes(true,true)
                        else this.getGradeNodes(true,true,true)
                    })
                }
            }
        },
        assignGradeToJob(gradeNode,jobID){
            this.$confirm('Would You Like To Assign This Grade').then(() => {
                this.loading = true
                axios.post('grading/CreateJobGradeGroup',{
                    jobID: jobID ? jobID : null,
                    gradeGroupID: gradeNode ? gradeNode : null,
                })
                .then(resp => {
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('GradeNode Assigned Successfully','success')
                        this.loading = false
                        this.$nextTick(() => {
                            this.$router.push('/Jobs')
                        })
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                })
                .catch(err => {
                    this.loading = false
                })
            })
        },
        snackbarMessage(text,purpose){
            this.snackbar.visible = true
            this.snackbar.text = text
            this.snackbar.purpose = purpose
        },
        exitDetailsMode(){
            this.detailsView = false
            this.gradeNodes.forEach(el => {
                el.dropdownVisible = false
            })
            this.getGradeNodes(true,false)
        },
        async getGradeDetails(groupID,createMode){
            this.detailsLoading = true
            this.selectedGradeNodeID = groupID
            this.gradeNodes.forEach(el => {
                el.editNode = false
                if(el.GradeGroup.GradeGroupID === groupID){
                    if(createMode) el.dropdownVisible = true
                    else{
                        el.dropdownVisible = !el.dropdownVisible
                        el.toolbarVisible = false
                    }
                }
                else {
                    el.dropdownVisible = false
                }
            })
            
            let noneSelected = this.gradeNodes.every(el => el.dropdownVisible === false)
            if(noneSelected){
                this.selectedGradeNodeID = null
            }


            await axios.get('grading/GetGradeGroupGrades',{
                params:{
                    GradeGroupID: groupID,
                }
            }).then(resp => {
                this.detailsLoading = false
                this.selectedGradeDetails = resp.data.Value
                this.selectedGradeDetails.Grades.sort((a,b) => a.Level - b.Level)

                this.filterOptions.grades = resp.data.Value.Grades.map(el => ({
                    title: el.GradeDescription,
                    value: el.GradeID,
                }))

                this.filterOptions.levels = []
                resp.data.Value.Grades.forEach(grade => {
                    grade.GradeParts.forEach(part => {
                        this.filterOptions.levels.push({
                            title: part.GradePartDescription,
                            value: part.GradePartID,
                        })
                    })
                })

                this.selectedGradeDetails.Grades.forEach(el => {
                    this.$set(el,'collapsedLevels',true)
                    el.GradeParts.forEach(part => {
                        let defaultCurrency = part.SalaryRanges[0] ? part.SalaryRanges[0].CurrencyID : null
                        this.$set(part,'selectedCurrency',defaultCurrency)
                        this.$set(part,'currencyOptionsVisible',false)
                        this.$set(part,'levelsDropdownVisible',false)
                    })
                    el.GradeParts.sort((a,b) => a.Level - b.Level)
                })
            })
        },
        nodeEditClickHandler(id){
            this.updateNode.gradeGroupID = id
            this.updateNode.name = this.gradeNodes.filter(el => el.GradeGroup.GradeGroupID === id)[0].GradeGroup.Name
            this.gradeNodes.forEach(el => {
                if(el.GradeGroup.GradeGroupID === id) el.editNode = !el.editNode
                else el.editNode = false
            })
        },
        updateNodeGroup(id,edit,del){
            if(edit){
                this.editNodePopupVisible = true
            }
            if(del){
                this.editNodePopupVisible = false
                this.$confirm('Would you like to delete this group?')
                .then(() => {
                    this.loading = true
                    axios.post('grading/UpdateGradeGroup',{
                        gradeGroupID:id,
                        name: '',
                        delete: true
                    }).then(resp => {
                        this.loading = false
                        if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Node Deleted Successfully','success')
                        this.getGradeNodes(true,false)
                    }
                        else this.snackbarMessage(resp.data.ErrorMsg,'error') 
                    })
                    .catch(err => {
                        console.log(err);
                        this.loading = false
                    })
                })
            }
        },
        updateNodeName(item,id){ //in details list 
            let collapsedEl = document.querySelector('.main-content')
            let parentEl = document.querySelector('.middle')

            this.updateNode.gradeGroupID = id
            this.updateNode.name = item.GradeGroup.Name
            this.gradeNodes.forEach(el => {
                if(el.GradeGroup.GradeGroupID === id){
                    el.editNode =  !el.editNode
                    if(el.editNode){
                        parentEl.scrollTo({top:collapsedEl.scrollHeight,behavior:'smooth'}) 
                    }
                } 
                else{
                    el.editNode = false
                }
            })
        },
        cancelUpdateNode(){
            this.updateNode.name = '',
            this.updateNode.gradeGroupID = null
            this.gradeNodes.forEach(el => {
                el.editNode = false
            })
        },
        saveUpdatedNode(detailsMode){
            this.loading = true
            axios.post('grading/UpdateGradeGroup',this.updateNode)
            .then(resp => {
                this.loading = false
                if(!resp.data.ErrorMsg){
                    this.snackbarMessage('Node Updated Successfully','success')
                    if(detailsMode){
                        this.getGradeNodes(true,true)
                    }else{
                        this.getGradeNodes(true,false)
                    }
                    this.editNodePopupVisible = false
                }
                else this.snackbarMessage(resp.data.ErrorMsg,'error') 
            }).catch(err => {
                this.loading = false
            })
        },
        showAddGradeField(id){
            this.resetCreateGradeField()
            this.gradeEditMode = false
            this.createGrade.gradeGroupID = id
            this.gradeNodes.forEach(el => {
                if(el.GradeGroup.GradeGroupID === id) el.addGradeVisible = !el.addGradeVisible
                else el.addGradeVisible = false
            })
        },
        showGradeToolbar(id){
            this.gradeNodes.forEach(el => {
                if(el.GradeGroup.GradeGroupID === id){
                    el.toolbarVisible = !el.toolbarVisible
                }else{
                    el.toolbarVisible = false
                }
            })
        },
        async uploadBadge($event){
            let file = $event.target.files
            this.createGrade.badgeFileName = file[0].name
            this.createGrade.badgeFileType = file[0].type
            this.createGrade.badgeFile = await toBase64(file[0])
        },
        resetCreateGradeField(){
            this.createGrade = {
                description: '',
                gradeGroupID: this.createGrade.gradeGroupID,
                level: null,
                badgeName: '',
                badgeFileName: '',
                badgeFileType: '',
                badgeFile: '',
            }
        },
        createNewGrade(){
            this.loading = true
            if(this.gradeEditMode){
                let gradeEditedObj ={
                    gradeID: this.createGrade.gradeID,
                    description: this.createGrade.description,
                    level: this.createGrade.level,
                    badgeName: this.createGrade.badgeName,
                    badgeFileName: this.createGrade.badgeFile.length > 0 ? this.createGrade.badgeFileName : null,
                    badgeFileType: this.createGrade.badgeFile.length > 0 ? this.createGrade.badgeFileType : null,
                    badgeFile: this.createGrade.badgeFile.length > 0 ? this.createGrade.badgeFile : null,
                }
                
                axios.post('grading/UpdateGrade',gradeEditedObj)
                .then(resp => {
                    this.loading = false
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Grade Updated Successfully','success')
                        this.resetCreateGradeField()
                        this.getGradeDetails(this.selectedGradeNodeID,true)
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false
                })
            }else{
                this.loading = true             
                this.createGrade.level = this.selectedGradeDetails.Grades ? this.selectedGradeDetails.Grades.length + 1 : 1
                let gradeObj = {
                    gradeGroupID: this.createGrade.gradeGroupID,
                    description: this.createGrade.description,
                    level: this.createGrade.level,
                    badgeName: this.createGrade.badgeName,
                    badgeFileName: this.createGrade.badgeFileName,
                    badgeFileType: this.createGrade.badgeFileType,
                    badgeFile: this.createGrade.badgeFile,
                }
                axios.post('grading/CreateGrade',gradeObj)
                .then(resp => {
                    this.loading = false
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Grade Saved Successfully','success')
                        this.resetCreateGradeField()
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                    this.getGradeDetails(this.selectedGradeNodeID,true)
    
                }).catch(err => {
                    console.log(err);
                    this.loading = false
                })
            }
        },
        deleteGrade(id){
            this.$confirm('Would you like to delete this Grade?').then(() => {
                this.loading = true
                axios.post('grading/UpdateGrade',{
                    gradeID:id,
                    delete: true,
                }).then(resp => {
                    this.loading = false
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Grade Deleted Successfully','success')
                        this.getGradeDetails(this.selectedGradeNodeID,true)
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false
                })
            })
        },
        editGrade(nodeID,gradeID){
            this.gradeEditMode = true,
            this.gradeNodes.forEach(el => {
                if(el.GradeGroup.GradeGroupID === nodeID) el.addGradeVisible = true
                else el.addGradeVisible = false
            })
            this.$nextTick(() => {
                this.scrolltoBottom('.middle')
            })
            let gradeObj = this.selectedGradeDetails.Grades.filter(el => el.GradeID === gradeID)[0]
            this.createGrade.description = gradeObj. GradeDescription
            this.createGrade['gradeID'] = gradeID
            this.createGrade.level = gradeObj.Level
            this.createGrade.badgeName = gradeObj.GradeBadgeName
        },
        dragUpdate($event){
            let gradeLevels = []
            this.selectedGradeDetails.Grades.forEach((el,ind)=> {
                let obj = {
                    gradeObject: el.GradeID,
                    level: ind + 1
                }
                gradeLevels.push(obj)
            })            

            axios.post('grading/UpdateGradeLevels',{
                gradeLevels: gradeLevels,
            }).then(resp => {
                if(!resp.data.ErrorMsg){
                    this.snackbarMessage('Grade Level Updated Successfully','success')
                    this.getGradeDetails(this.selectedGradeNodeID,true)
                }else{
                    this.snackbarMessage(resp.data.ErrorMsg,'error')
                }
            })
        },
        addNewLevel(gradeID){
            this.levelEditMode = false
            this.resetLevelCreatingValue(false)
            this.createLevelVisible = true
            this.createLevel.gradeID = gradeID
        },
        getCurrencies(){
            axios.get('Company/CurrencyTypes').then(resp => {
                this.currencyOptions = resp.data.Value.map(el => ({
                    title: el.Code,
                    value: el.ID
                }))
            })
        },
        getRatingPeriods(){
            axios.get('grading/GetGradeRatingPeriods')
            .then(resp => {
                this.ratingPeriodOptions = resp.data.Value.map(el => ({
                    title: el.RatingPeriodName,
                    value: el.RatingPeriodID
                }))
            })
        },
        addSalaryField(){
            this.salaryFieldsCounter += 1

            let arr =[ 
                {
                    title:'Min Salary',
                    type:'number',
                    value: null,
                    select:false,
                    fieldCount: this.salaryFieldsCounter,
                    deletable:true,
                },
                {
                    title:'Max Salary',
                    type:'number',
                    value: null,
                    select:false,
                    fieldCount: this.salaryFieldsCounter,
                    deletable:true,

                },
                {
                    title:'Currency',
                    value: null,
                    select:true,
                    fieldCount: this.salaryFieldsCounter,
                    deletePlaceholder: true,
                    addPlaceholder: true,
                    deletable:true,
                },
            ]
            arr.forEach(el => {
                this.salaryFields.push(el)
            })
            this.scrolltoBottom('.gradeNodes-list .popup')
        },
        scrolltoBottom(domElement){
            let domEl = document.querySelector(domElement) 
            domEl.scrollTo({top: domEl.scrollHeight,behavior:'smooth'})
        },
        deleteSalaryField(identifier,deleteable){
            if(deleteable){
                this.salaryFields = this.salaryFields.filter(el => el.fieldCount !== identifier)
            }
            if(!deleteable){
                this.salaryFields.filter(el => el.fieldCount === identifier).forEach(el => {
                    if(!el.deletable) el.value = null
                })
            }
            if(this.salaryFields.every(el => el.deletable === false)){
                this.salaryFieldsCounter = 1
            }
            return
        },
        collectSalaryData(){
            let currentGradeID = this.createLevel.gradeID
            let objData = this.createLevel
            let salaryItems = []
            this.salaryFields.forEach(el => {
                salaryItems.push(el.fieldCount)
            })
            salaryItems = [... new Set(salaryItems)]
            let salaryObjects = []
            salaryItems.forEach(item => {
                let filteredItem = this.salaryFields.filter(el => el.fieldCount === item)
                let object = {}
                filteredItem.forEach(obj => {
                    if(obj.title === 'Currency') object['currencyID'] = obj.value
                    if(obj.title === 'Min Salary') object['minSalary'] = parseInt(obj.value)
                    if(obj.title === 'Max Salary') object['maxSalary'] = parseInt(obj.value)
                })
                salaryObjects.push(object)
            })
            if(!this.levelEditMode){
                let levelIndex = this.selectedGradeDetails.Grades.find(el => el.GradeID === currentGradeID).GradeParts.length + 1
                objData.level = levelIndex
            }
            objData.minAvgScore = parseInt(objData.minAvgScore) 
            objData.gradePartSalaryRanges = salaryObjects

            return objData
        },
        submitCreatedLevel(){
            let data = this.collectSalaryData()
            if(this.levelEditMode){
                this.loading = true
                axios.post('grading/UpdateGradePart',data)
                .then(resp => {
                    this.loading = false
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Level Updated Successfully','success')
                        this.resetLevelCreatingValue(true)
                        this.getGradeDetails(this.selectedGradeNodeID,true)
                    }
                    else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                }).catch(err => {
                    this.loading = false
                })

            }else{
                this.loading = true
                axios.post('grading/CreateGradePart',data)
                .then(resp => {
                    this.loading = false
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Level Saved Successfully','success')
                        this.resetLevelCreatingValue(true)
                        this.getGradeDetails(this.selectedGradeNodeID,true)
                    }
                    else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                })
                .catch(err => {
                    this.loading = false
                })
            }

        },
        resetLevelCreatingValue(closePopup){
            this.createLevel.description = ''
            this.createLevel.gradeID = null
            this.createLevel.minAvgScore = null
            this.createLevel.level = null
            this.createLevel.ratingPeriodID = null
            this.createLevel.gradePartSalaryRanges = [
                {
                   currencyID: 0,
                    maxSalary: 0,
                    minSalary:0
                }
            ]
            this.salaryFields = [
                {
                    title:'Min Salary',
                    type:'number',
                    value: null,
                    select:false,
                    deletable: false,
                    fieldCount: 1,
                },
                {
                    title:'Max Salary',
                    type:'number',
                    value: null,
                    select:false,
                    deletable: false,
                    fieldCount: 1,

                },
                {
                    title:'Currency',
                    value: null,
                    select:true,
                    deletePlaceholder: true,
                    addPlaceholder: true,
                    deletable: false,
                    fieldCount: 1,
                },
                
            ]

            if(closePopup) this.createLevelVisible = false
        },
        salaryRanges(arr){
            let salary = arr.map(el => ({
                title: el.CurrencyCode,
                value: el.CurrencyID
            })) || []
            return salary
        },
        filteredSalary(arr,currencyID,min,max){
            // let currentCurrency =  this.currencyOptions.find(el => el.value === currencyID).title
            let obj = arr.find(el => el.CurrencyID === currencyID)
            if(min) return obj.MinSalary
            if(max) return obj.MaxSalary
        },
        deleteGradeLevel(id){
            this.$confirm('Would you like to delete this Level?').then(() => {
                
                axios.post('grading/UpdateGradePart',{
                    gradePartID: id, 
                    delete: true,
                    gradePartSalaryRanges: []
                }).then(resp => {
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Level Deleted Successfully','success')
                        this.getGradeDetails(this.selectedGradeNodeID,true)
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                })
            })
        },
        updateGradeLevel(gradeID,levelID){
            this.levelEditMode = true
            let levelObj = this.selectedGradeDetails.Grades.filter(grade => grade.GradeID === gradeID)[0].GradeParts
            .filter(part => part.GradePartID === levelID)[0]
            this.createLevelVisible = true

            this.createLevel.description = levelObj.GradePartDescription
            this.createLevel.gradeID = levelObj.GradeID
            this.createLevel.minAvgScore = levelObj.MinAvgScore
            this.createLevel.level = levelObj.Level
            this.createLevel.ratingPeriodID = levelObj.RatingPeriodID
            this.createLevel['gradePartID'] = levelID
    
            let salaryArr = []
            levelObj.SalaryRanges.forEach((el,ind) => {
                this.salaryFieldsCounter += 1
                let minSalary = {
                    deletable: ind !== 0,
                    fieldCount: ind + 1,
                    select: false,
                    title:'Min Salary',
                    value: el.MinSalary,
                    type: 'number',
                    addPlaceholder: (ind + 1) === levelObj.SalaryRanges.length,
                }
                let maxSalary = {
                    deletable: ind !== 0,
                    fieldCount: ind + 1,
                    select: false,
                    title:'Max Salary',
                    value: el.MaxSalary,
                    type: 'number',
                    addPlaceholder:(ind + 1) === levelObj.SalaryRanges.length,
                }
                let currency = {
                    deletable: ind !== 0,
                    fieldCount: ind + 1,
                    select: true,
                    title:'Currency',
                    value: el.CurrencyID,
                    addPlaceholder: true,
                    deletePlaceholder: true,
                }
                salaryArr.push(minSalary,maxSalary,currency)
            })
            this.salaryFields = salaryArr
        },
        dragUpdateLevel($event){
            let parentGradeID = $event.moved.element.GradeID
            let gradePartLevels = []
            this.selectedGradeDetails.Grades.filter(grade => grade.GradeID === parentGradeID)[0]
            .GradeParts.forEach((el,ind) => {
                let obj = {
                    gradeObject: el.GradePartID,
                    level: ind + 1,
                }
                gradePartLevels.push(obj)
            })
            
            axios.post('grading/UpdateGradePartLevels',{
                gradePartLevels: gradePartLevels,
            }).then(resp => {
                if(!resp.data.ErrorMsg){
                    this.snackbarMessage('Level Updated Successfully','success')
                    this.getGradeDetails(this.selectedGradeNodeID,true)
                }else{
                    this.snackbarMessage(resp.data.ErrorMsg,'error')
                }
            })
        },
        showCurrencyOptions(id,gradeID){
            let currentGrade = this.selectedGradeDetails.Grades.find(el => el.GradeID === gradeID)
            if(currentGrade){
                let gradePart = currentGrade.GradeParts.find(el => el.GradePartID === id)
                if(gradePart && gradePart.SalaryRanges.length > 1){
                    this.selectedGradeDetails.Grades.forEach(el => {
                        el.GradeParts.forEach(el => {
                            if(el.GradePartID === id) el.currencyOptionsVisible = !el.currencyOptionsVisible
                            else el.currencyOptionsVisible = false
                        })
                    })
                }else return
            }
        },
        getCriteriasList(){
            axios.get('grading/GetCriterias').then(resp => {
                let data = resp.data.Value
                let options = data.map(el => ({
                    title: el.Description,
                    value: el.CriteriaID,
                }))
                this.criteriasList = options
                this.filterOptions.criterias = options
            })
        },
        addCriteriaPopup(partID,gradeID){
            this.selectedLevelID = partID
            this.criteriaEditMode = false
            this.parentGradeID = gradeID
            this.resetCriteriaFields()
            this.addCriteriaVisible = true
        },
        saveAddedCriteria(partID,gradeID){
            const isInt = (n) => { return n % 1 === 0 }
            let newObj
            if(!this.criteriaEditMode){
                newObj = {
                    gradePartID: partID,
                    criteriaID: this.addCriteria.criteriaID,
                    weight: this.addCriteria.weight,
                    minScore: parseInt(this.addCriteria.minScore),
                    startDate: null,
                    comment: null
                }
            }
            else{
                newObj = {
                    gradePartID: partID,
                    criteriaID: this.addCriteria.criteriaID,
                    weight: this.addCriteria.weight,
                    minScore: parseInt(this.addCriteria.minScore),
                    startDate: this.addCriteria.startDate,
                    comment: this.addCriteria.comment,
                }
            }

            this.addCriteria.warning = false

            if(isInt(Math.abs(newObj.weight)) && parseInt(Math.abs(newObj.weight)) > 1){
                this.addCriteria.warning = true
                this.triggerForcedUpdate()
                return
            }
            if(isInt(Math.abs(newObj.weight)) === false && parseFloat(Math.abs(newObj.weight)) > 1){
                this.addCriteria.warning = true
                this.triggerForcedUpdate()
                return
            }
            this.loading = true
            axios.post('grading/CreateGradePartCriteria',newObj).then(resp => {
                this.loading = false

                if(!resp.data.ErrorMsg){
                    this.snackbarMessage('Criteria Added Successfully','success')
                    this.getLevelCriterias(partID,gradeID,true)
                    this.addCriteriaVisible = false
                    this.resetCriteriaFields()

                }else{
                    this.snackbarMessage(resp.data.ErrorMsg,'error')
                }
            }).catch(err => {
                this.loading = false
            })
        },
        resetCriteriaFields(){
            this.addCriteria = {
                criteriaID: null,
                minScore: null,
                weight: null
            }
        },
        triggerForcedUpdate(){
            this.triggerForcedRender = false
            this.$nextTick(() => {
                this.triggerForcedRender = true
            })
        },
        getLevelCriterias(id,gradeID,keepCollapsed){
            this.detailsLoading = true
            axios.get('grading/GetGradePartCriterias',{
                params:{
                    GradePartID: id,
                }
            }).then(resp => {
                this.detailsLoading = false
                this.selectedGradeDetails.Grades.forEach(el => {
                    el.GradeParts.forEach(part => {
                        if(part.GradePartID === id){
                            if(keepCollapsed){
                                part.levelsDropdownVisible = true
                            }else{
                                part.levelsDropdownVisible = !part.levelsDropdownVisible
                            }
                        }
                    })
                })
                let data = resp.data.Value
                let currentObj = this.selectedGradeDetails.Grades.
                    find(el => el.GradeID === gradeID).GradeParts
                        .find(obj => obj.GradePartID === id) 
                currentObj['criterias'] = data
                this.selectedGradeDetails.Grades.forEach(el => {
                    el.GradeParts.forEach(part => {
                        if(part.criterias){
                            part.criterias.forEach(criteria => {
                                let exists = this.criteriaDropdowns.some(el => el.criteriaID === criteria.CriteriaID && el.gradePartID === criteria.GradePartID)
                                if(exists) return
                                this.criteriaDropdowns.push({
                                    criteriaID: criteria.CriteriaID,
                                    gradePartID: criteria.GradePartID,
                                    collapsed: false,
                                })
                            })
                        }
                    })
                })
            })
        },
        getCriteriaDescriptions(id,gradePartID,gradeID){
            this.detailsLoading = true
            axios.get('grading/GetGradePartCriteriaDescriptions',{
                params:{
                   CriteriaID: id 
                }
            }).then(resp => {
                this.detailsLoading = false
                let descriptions = resp.data.Value
                this.collapseCriteriaObj(id,gradePartID)
                descriptions.forEach(el => {
                    let exists = this.criteriaDescriptions.some(obj => obj.CriteriaDescriptionID === el.CriteriaDescriptionID)
                    if(exists){
                        return
                    } 
                    else{
                        this.criteriaDescriptions.push(el)        
                        this.criteriaDescriptions.forEach(el => {
                            this.$set(el,'levelID',gradePartID)
                        })
                    }
                })

            })
        },
        filterDescription(id){
            return this.criteriaDescriptions.filter(el =>  el.CriteriaID === id)
        },
        filterByTopParent(item){    
            if(this.filteredIDs.includes(item.GradeGroup.GradeGroupID)) return true
            if(this.filteredIDs.length < 1) return true
            else return
        },
        criteriaDescriptionCollapseState(criteriaID,partID){
                let collapsedObj = this.criteriaDropdowns.find(el => el.criteriaID === criteriaID && el.gradePartID === partID).collapsed
                return collapsedObj
        },
        collapseCriteriaObj(criteriaID,partID){
            this.criteriaDropdowns.find(el => el.criteriaID === criteriaID && el.gradePartID === partID).collapsed = !this.criteriaDropdowns.find(el => el.criteriaID === criteriaID && el.gradePartID === partID).collapsed
        },
        editCriteria(criteriaID,partID,gradeId){
            this.selectedLevelID = partID
            this.criteriaEditMode = true
            this.addCriteriaVisible = true
            let criteriaDetails = this.selectedGradeDetails.Grades.find(grade => grade.GradeID === gradeId).
                GradeParts.find(part => part.GradePartID === partID)
                    .criterias.find(criteria => criteria.CriteriaID === criteriaID)
            
            this.addCriteria = {
                criteriaID: criteriaID,
                minScore: criteriaDetails.CriteriaMinScore,
                weight: criteriaDetails.CriteriaWeight,
                startDate: criteriaDetails.StartDate,
                comment: criteriaDetails.Comment || '',
            }
        },
        disableCriteriaWeight(id,partID,gradeID){
            this.$confirm('Would you Like To Delete This Criteria').then(() => {
                this.loading = true
                axios.post('grading/CreateGradePartCriteria',{
                    criteriaID: id,
                    weight: 0,
                }).then(resp => {
                    this.loading = false
                    if(!resp.data.ErrorMsg){
                        this.snackbarMessage('Updated Successfully','success')
                        this.getLevelCriterias(partID,gradeID,true)
                    }else{
                        this.snackbarMessage(resp.data.ErrorMsg,'error')
                    }
                }).catch(err => {
                    console.log(err);
                    this.loading = false
                })
            })
        },
        clearAllFilters(){
            this.gradeNode = null
            this.grade = null
            this.level = null
            this.criteria = null
        },
    },
}
</script>

<style lang="scss" scoped>
*{
    font-family: Roboto,firaGo;
}


.ghost {
    opacity: .5;
    transition: ease .3s;
    background: rgba(#959BC9,.2);
}

.no-records{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%,-50%);
    pointer-events: none;
    user-select: none;
    span{
        font-size: 1.875rem;
        color: rgba($grayBlue, .8);
    }
}

.fade-enter-active {
  transition: opacity .5s;
}

.fade-leave-active{
    transition: .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateX(0.625rem);
}


.background{
    overflow: hidden;
}

.top-menu{
    // position: sticky;
    top: 0;
    z-index: 10;
    background: #fff;
    padding-bottom: 0.625rem;
    padding-left: 1.875rem;
}
//global styles for current component



input{
    box-sizing: border-box;
}

@keyframes slideInto {
    from{
        transform: translateY(35px);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }
}


.create-level-popup{
    ::v-deep h3.title{
        color: $purple !important;
        font-size: 0.875rem !important;
        margin-left: 0.3125rem !important;
    }
    ::v-deep{
        .popup{
            padding: 3.125rem 4.1875rem !important;
            width: 54.875rem;
            height: unset !important;
            max-height: 48rem;
        }
    }

    ul{
        list-style: none;
        @include flex(flex-start,flex-start,column);
        &.horizontal-field{
            display: grid;
            grid-template-columns: auto auto auto;
            position: relative;
            li{
                margin-top: 0.9375rem;
                margin-left: 0.9375rem;

                &.horizontal-input{
                    .wrapper{
                        position: relative;
                        &:hover{
                            .actions-wrapper{
                                opacity: 1 !important;
                            }    
                        }
                        .actions-wrapper{
                            opacity: 0;
                            @include flex(flex-start,center,row);
                            position: absolute;
                            top: 50%;
                            transform: translate(0,-50%);
                            right: -4.1875rem;
                            
                            & > div{
                                cursor: pointer;
                                margin-left: 0.625rem;
                                img{
                                    width: 1.375rem;
                                }
                            }
                        }
                    }
                }

                &.add-salary{
                    width: 2rem;
                    position: absolute;
                    right: calc(-100% * 2.36);
                    margin-top: calc(0.9375rem / 2.2);
                    div{
                        cursor: pointer;
                        background: transparent;
                    }
                }

                &:first-child,&:nth-child(3n+1){
                    margin-left: 0;
                    margin-top: 0.9375rem;
                }
                &::after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translate(0,-50%);
                    right: -0.9375rem;
                    left: unset !important;
                    width: 0.9375rem;
                    height: 0.0625rem;
                    background: #D4D7E9;
                }
                &:nth-child(3n+1):not(.add-salary){
                    &::before{
                        content: '';
                        position: absolute;
                        top: -0.5rem;
                        transform: translate(0,-50%);
                        left: 0.9375rem;
                        width: 0.0625rem;
                        height: 0.9375rem ;
                        background: #D4D7E9;
                    }
                }
                &:last-child,&:nth-child(3n){
                    &::after{
                        content: none;
                    }
                }
            }
        }

        li{
            width: 13.75rem;
            margin-top: 0.9375rem;
            position: relative;


            &::after{
                content: '';
                position: absolute;
                top: 100%;
                left: 0.9375rem;
                width: 0.0625rem;
                height: 0.9375rem;
                background: #D4D7E9;
            }

            &:first-child,&:last-child{
                margin-top: 0;
            }
            &:last-child{
                &::after{
                    content: none;
                }
            }
            ::v-deep{
                label,.select-wrapper{
                    width: 13.75rem;
                    .selected-box{
                        border: 0.0625rem solid rgba(#D4D7E9,.6);
                        .selected-option{
                            color: $grayBlue;
                            font-size: 0.8125rem;
                            font-weight: 400;
                        }
                    }
                }
                label{
                    input{
                        border: 0.0625rem solid rgba(#D4D7E9,.6);
                        color: $grayBlue;
                        font-size: 0.8125rem;
                        font-weight: 400;
                    }
                }
            }

        }
    }

    .save-btn{
        margin-top: 4.9375rem;
        width: 100%;
        @include flex(flex-end,center,row);

        button{
            @include purpleBtn;
        }
    }
}

.nodeEditPopup{
    ::v-deep .popup{
        width: 40.1875rem;
        height: 9.125rem;

    }

    .input-wrapper{
        @include flex(flex-start,center,row);
        margin-top: 1.1875rem;

        .custom-input{
            width: 25.25rem;
        }

        .save-btn{
            margin-left: 1.875rem;

            button{
                @include purpleBtn();

            }
        }
    }
}

.add-criteria-popup{
    ::v-deep h3.title{
        color: $purple !important;
        font-size: 0.875rem !important;
        margin-left: 0.3125rem !important;
    }

    &.editMode{
        ::v-deep{
            .popup{
                max-height: 36rem;
            }
        }
    }

    ::v-deep{
        .popup{
            padding: 3.125rem 4.1875rem !important;
            width: 49.875rem;
            height: unset !important;
            min-height: 20rem;
            max-height: 25rem;
        }
    }

    .edit-fields{
        ::v-deep{
            label{
                margin-top: 25px;
            }
            .title{
                color: $grayBlue;
            }
            textarea{
                width: 100%;
                height: 7.5rem;
                outline: none;
                color:$grayBlue;
                &:focus {
                    border: 0.0625rem solid $grey-border;
                }
            }
        }
    }

    .wrapper{
        width: 100%;
        form{
            @include flex(flex-start,left,column);

            .top,.bottom{
                @include flex(flex-start,center,row);
                ::v-deep{
                    label{
                        width: 13.75rem;
                        .title{
                            color: $grayBlue;
                        }
                        input{
                            font-size: 0.875rem;
                            color: $grayBlue;
                            font-weight: 400;
                        }
                    }
                }
                .field{
                    position: relative;
                    margin-left: 0.9375rem;

                    &.withWarning{
                        ::v-deep{
                            .warning-text{
                                position: absolute;
                                top: calc(100% + 0.3125rem);
                                left: 0;
                            }
                        }
                    }

                    &:first-child{
                        margin-left: 0;
                    }

                    &::after{
                        content: '';
                        width: 0.9375rem;
                        height: 0.0625rem;
                        position: absolute;
                        right: -0.9375rem;
                        top: 1.1875rem;
                        background: #D4D7E9;
                    }

                    &:last-child{
                        &::after{
                            content: none;
                        }
                    }

                }
            }

            .field{
                &.select{
                    width: 25.1875rem;
                }
            }
            .bottom{
                position: relative;
                margin-top: 0.9375rem;
                margin-left: 2rem;

                &::after{
                    content: '';
                    width: 1.125rem;
                    height: 0.0625rem;
                    position: absolute;
                    left: -1.1875rem;
                    top: 1.1875rem;
                    background: #D4D7E9;
                }
                
                &::before{
                    content: '';
                    width: 0.0625rem;
                    height: 2.125rem;
                    position: absolute;
                    left: -1.1875rem;
                    bottom: calc(100% - 1.2rem);
                    background: #D4D7E9;
                }
            }
            .save-wrapper{
                margin-top: 2.5rem;
                text-align: right;
                button{
                    @include purpleBtn;
                    margin-right: 1.5rem;
                }
            }
        }
    }
}

.content-wrapper{
    width: calc(100% - (1.875rem * 2));
    height: calc(100% - 1.5rem);
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    background: #fff;
    overflow: hidden;
    margin: 1.5rem auto;
    box-sizing: border-box;

    .content-header{
        width: 100%;
        
        .top{
            padding: 1.75rem 3.125rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #FAFBFC;
            border-bottom: 0.25rem solid #F5F6F7;
            height: 6rem;

            .header-title{
                span{
                    color: $grayBlue;
                    font-size: 1rem;
                    font-weight: 600;
                }
            }

            .header-actions{
                @include flex(flex-start,center,row);
                
                .list-view-btn{
                    margin-left: 1.5625rem;
                    cursor: pointer;
                    img{
                        animation: show .5s forwards;
                        width: 1.46rem;
                        &.grid{
                            width: 1.5rem;
                        }
                    }

                }
            }
            .header-filters{
                .clear-filters{
                    cursor: pointer;
                    width: auto;
                    margin-left: 1.25rem;

                    &:hover{
                        span{
                            color:rgba($red, .6)
                        }
                    }
                    & > div{
                        height: 100%;
                        @include centerElement;
                        position: relative;
                        &::before{
                            content: '';
                            position: absolute;
                            width: 0.0625rem;
                            height: calc(100% - .8rem);
                            background: rgba($grayBlue,.5);
                            transition: ease .3s;
                            top: 50%;
                            transform: translate(0,-50%);
                            left: -0.375rem;
                        }

                        &:active{
                            &::before{
                                height: calc(100% - 2rem);
                            }
                        }

                        span{
                            color: $grayBlue;
                            transition: ease .3s;
                            font-size: 0.875rem;
                            font-weight: 400;


                        }
                    }
                }
                
                .search{
                    width: 13.75rem;
                    height: 40px;
                    position: relative;
                    @include centerElement;

                    img{
                        width: 1.1rem ;
                        position: absolute;
                        top: 50%;
                        transform: translate(0,-50%);
                        left: 0.9375rem;
                    }

                    input{
                        width: 100%;
                        height: 100%;
                        outline: none;
                        border: none;
                        padding-left: 2.6875rem;
                        color: $grayBlue;
                        font-size: 14px;
                        transition: ease .3s;
                        border-radius: 0.375rem;
                        border: 0.0625rem solid rgba(#959BC9,.4);
                        
                        &::placeholder{
                            color: rgba($grayBlue, .6);
                        }

                        &:focus{
                            box-shadow: 0rem 0rem 0.9375rem rgba($grayBlue, .16);
                        }
                        
                    }
                }

                ul{
                    list-style: none;
                    @include flex(flex-start,center,row);
                    li{
                        width: 13.75rem;
                        height: 2.5rem;
                        margin-left: 0.9375rem;
                        &:first-child{
                            margin-left: 0;
                        }

                        ::v-deep{
                            label{
                                width: 100%;
                            }
                            .title{
                                color: $grayBlue;
                                font-size: 0.875rem;
                            }
                            .selected-option{
                                color: $grayBlue;
                                font-size: 0.875rem;
                                font-weight: 400;
                            }

                            .material-icons{
                                color: $grayBlue;
                            }
                        }
                    }
                }

            }

            .add-new-group{
                img{
                    transition: ease .3s;
                }
                img.down{
                    transform: rotate(45deg);
                }
            }
        }

        .bottom{
            width: 100%;
            height: 0;
            overflow: hidden;
            padding: 1.75rem 3.125rem;
            background: transparent;
            display: none;
            transition: ease .3s;

            &.visible{
                display: block;
                animation: show 1s forwards;
                height: unset;
                overflow: visible;
            }

            .create-gradeNode{
                .title{
                    color: $purple;
                    font-size: 0.875rem;
                    line-height: 1.5rem;
                    font-weight: 500
                }

                .input-wrapper{
                    @include flex(flex-start,center,row);
                    margin-top: 1.1875rem;

                    .custom-input{
                        width: 23.25rem;
                    }

                    .save-btn{
                        margin-left: 1.875rem;

                        button{
                            @include purpleBtn();

                        }
                    }
                }
            }
        }

    }

    .middle{
        width: 100%;
        margin-top: 1.25rem;
        overflow: auto;
        height: calc(100% - 160px);
        &.moreHeight{
            height: 65% !important;
        }


        .gradeNodes-grid{
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 1.4375rem;
            padding: 1rem 3.125rem;
            position: relative;
            animation: slideInto .5s forwards;

        }

        .gradeNodes-list{
            width: 100%;
            padding: 0 3.125rem;
            animation: slideInto .5s forwards;

            .back-btn{
                width: 100%;
                display: flex;
                justify-content: flex-start;
                position: sticky;
                top: 0;
                background: #ffffff;
                z-index: 9;
                padding-bottom: 0.625rem;

                button{ 
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;
                    background: transparent;
                    transition: ease .2s;
                    position: relative;
                    &.alert{
                        &::after{
                            content: '';
                            position: absolute;
                            width: 0.3125rem;
                            height: 0.3125rem;
                            left: 0;
                            top: 0;
                            background: #FE228B;
                            border-radius: 50%;
                        }
                    }

                    &:active{
                        span{
                            color: $green-primary;
                        }
                    }

                    span{
                        font-size: 0.8125rem;
                        color: #ADB8CC;
                        padding-left: 0.625rem;
                    }

                    img{
                        width: 1.5rem;
                    }
                }
            }

            .node-list-wrapper{
                width: 100%;
                margin-top: 1rem;

                ul{
                    width: 100%;
                    @include flex(unset,flex-start,column);
                    list-style: none;

                    li{
                        width: 100%;
                        border: 0.0625rem solid rgba($soft-blue,.4);
                        border-radius: 0.375rem;
                        position: relative;
                        height: 2.5rem;
                        transition: ease .2s;
                        margin-top: 0.9375rem;
                        overflow: hidden;
                        box-shadow: 1px 1px 1.25rem rgba($grayBlue, .1);

                        &:first-child{
                            margin-top: 0;
                        }

                        &.active{
                            height: unset;
                            min-height: 14rem;
                            overflow: visible;


                            .dropdown-arrow{
                                img{
                                    transform: rotate(180deg)
                                }
                            }
                            .main-content{
                                background: $purple;
                                box-shadow: 1px 1px 1.25rem rgba($purple, .4);
                            }

                            .title,.list-ind{
                                color: #ffffff !important;
                            }
                        }

                        .actions-toolbar{
                            width: 3.25rem;
                            height: 2.9rem;
                            position: absolute;
                            right: .5rem;
                            top: 3rem;
                            border-radius: 0.625rem;
                            z-index: 99;
                            transition: ease .2s;

                            &.active{
                                background: $purple;
                                height: 10rem;
                            }

                            ul{
                                width: 100%;
                                list-style: none;
                                @include flex(center,center,column);
                                padding: 0.625rem  1rem;

                                li{
                                    width: unset !important;
                                    margin-top: 1.25rem;
                                    border-radius: 0;
                                    border: none !important;
                                    height: unset !important;

                                    &.toolar-dots{
                                        button img{
                                            width: 1.5rem;
                                        }
                                    }
                                    &:first-child{
                                        margin-top: 0;
                                    }
                                    button{
                                        cursor: pointer;
                                        background: transparent;
                                        @include flex(center,center,column);

                                        img{
                                            width: 1.25rem;
                                        }
                                        span{
                                            margin-top: 0.3125rem;
                                           font-size: 0.625rem;
                                           font-weight: 700;
                                           color: #ffffff; 
                                        }
                                    }
                                }
                            }
                        }

                        .additional-content{
                            width: 100%;
                            padding: 0 2.5rem;
                            padding-bottom: 0.875rem;
                            $marginLeft: 2rem;

                            .details-content{
                                width: 100%;
                                padding-top: 0.875rem;
                                position: relative;
                                border-left: $leftBorder;

                                div,span{
                                    user-select: text !important;
                                }

                                .grade-container{
                                    width: 100%;
                                    height: unset;
                                    margin-left: $marginLeft;
                                    position: relative;
                                    @include mainLine;
                                    padding-bottom: 1.5rem;                                    
                                    .grade-header{
                                        width: 100%;
                                        display: grid;
                                        grid-template-columns: 15.625rem 7.5rem 6.25rem;
                                        grid-gap: 0 0.625rem;
                                        position: relative;
                                        &:hover{
                                            .grade-actions{
                                                opacity: 1;
                                                pointer-events: all;
                                            }
                                        }
                                        .grade-actions{
                                            position: absolute;
                                            top: 0;
                                            right: 59.6%;
                                            @include flex(flex-start,center,row);
                                            opacity: 0;
                                            pointer-events: none;
                                            transition: ease .3s;

                                            &.active{
                                                opacity: 1;
                                                pointer-events: all;
                                            }

                                            button{
                                                cursor: pointer;
                                                &:first-child{
                                                    margin-left: 0;
                                                }
                                                margin-left: 0.9375rem;
                                                background: transparent;

                                                img{
                                                    width: 1.1875rem;
                                                    transition: ease .2s;
                                                    &.down{
                                                        transform: rotate(180deg);
                                                    }
                                                }
                                            }
                                        }

                                        .grade-lvl-index{
                                            position: absolute;
                                            left: -4.025rem;
                                            top: 0;
                                            background: rgba($purple,.4);
                                            border-radius: 0.1875rem;


                                            & > span{
                                                padding: 0.125rem 0.3125rem;
                                                line-height: unset !important;
                                                @include flex(flex-start,center,column-reverse);
                                                cursor: grab;
                                                color: $purple !important;
                                                font-size: 0.6875rem !important;
                                                font-weight: 700;

                                                img{
                                                    width: 0.875rem
                                                }
                                            }
                                        }

                                        & > .col-title{
                                            // border: 0.0625rem red solid;
                                            @include flex(flex-start,left,column);

                                            span{
                                                line-height: 1.5rem;
                                                font-weight: 500;
                                                color: #97A1C2;
                                                font-size: 0.8125rem;
                                                &.main-title{
                                                    color: $purple;
                                                }
                                                &.item-title{
                                                    color: #4D5E80;
                                                    font-weight: 500;
                                                }
                                            }
                                            &.col-badge{
                                                display: grid;
                                                place-items: baseline;
                                                img{
                                                    width: 1.5625rem;
                                                }
                                            }
                                        }
                                    }
                                    .add-level-field{
                                        width: 100%;

                                        .add-level{
                                            button{
                                                cursor: pointer;
                                                background: transparent;
                                                @include flex(flex-start,center,row);
    
                                                img{
                                                    width:1.5rem;
                                                    transition: ease .3s;
                                                    &.down{
                                                        transform: rotate(45deg);
                                                    }
                                                }
                                                span{
                                                    font-size: 0.75rem;
                                                    color: #ffffff;
                                                    padding: 0.4375rem 0.75rem;
                                                    background: $purple;
                                                    border-radius: 0.375rem;
                                                    margin-left: 0.3125rem;
                                                }
                                            }
                                        }
                                    }

                                    .levels-container{
                                        width: 100%;
                                        // height: 200px;
                                        border-left: $leftBorder;
                                        padding-top: 1.5rem;
                                        margin-bottom: 1.5rem;

                                        .levels-header{
                                            width: 100%;
                                            position: relative;
                                            margin-left: $marginLeft;
                                            @include mainLine;
                                            display: grid;
                                            grid-template-columns: 13.5625rem 10rem 11.25rem 11.25rem 11.25rem 11.25rem;
                                            grid-gap: 0 0.625rem;
                                            &:hover{
                                                .level-actions{
                                                    .edit,.delete{
                                                        opacity: 1;
                                                        pointer-events: all;
                                                    }
                                                }
                                            }

                                            .order-ind{
                                                cursor: grab;
                                                position: absolute;
                                                top: 0;
                                                left: -3.9rem;
                                                width: 1.9rem;
                                                text-overflow: ellipsis;
                                                white-space: nowrap;
                                                overflow: hidden;
                                                span{
                                                    color: $purple;
                                                    font-size: 0.625rem;
                                                    font-weight: 700;

                                                }
                                            }

                                            .level-actions{
                                                position: absolute;
                                                top: 0;
                                                right: 9.375rem;
                                                display: inline-flex;

                                                button{
                                                    &.dropdown-arrow{
                                                        img{
                                                            transition: ease .3s;
                                                            &.down{
                                                                transform: rotate(180deg);
                                                            }
                                                        }
                                                    }
                                                    margin-left: 0.8125rem;
                                                    &:first-child{
                                                        margin-left: 0;
                                                    }
                                                    cursor: pointer;
                                                    background: transparent;

                                                    img{
                                                        width: 1.5rem;
                                                        transition: ease .3s;
                                                        transform: none;
                                                    }

                                                    &.edit,&.delete{
                                                        pointer-events: none;
                                                        opacity: 0;
                                                        transition: ease .3s;
                                                        img{
                                                            width: 1.25rem
                                                        }
                                                    }
                                                }
                                            }

                                            .level-col-title{
                                                @include flex(flex-start,left,column);
                                                span{
                                                    line-height: 1.5rem;
                                                    font-size: 0.8125rem;
                                                }
                                                & > .col-title{
                                                    font-weight: 400;
                                                    color: #7D8FB3;
                                                }

                                               & > .col-value{
                                                   color: #4D5E80;
                                                   font-size: 0.875rem;
                                                   font-weight: 700;
                                               }

                                               & > .col-currency{
                                                   display: flex;
                                                   align-items: center;
                                                   position: relative;

                                                   .currency-list{
                                                        top: 100%;
                                                        left: -15%;
                                                        transform: translateX(15%);
                                                        width: 5rem;
                                                        max-height: 10rem;
                                                        min-height: 2.1rem;
                                                        position: absolute;
                                                        border-radius: 0.375rem;
                                                        background: $purple;
                                                        z-index: 9;
                                                        overflow: auto;
                                                        .wrapper{
                                                            width: 100%;

                                                            .single-currency{
                                                                width: 100%;
                                                                text-align: center;
                                                                padding: 0.3125rem 0.625rem;
                                                                transition: ease .2s;
                                                                cursor: pointer;
                                                                &.chosen{
                                                                    span{
                                                                        color: $green-primary;
                                                                    }
                                                                }
                                                                &:hover{
                                                                    background: rgba(#fff,.2);
                                                                }
                                                                span{
                                                                    color: #ffffff;
                                                                    font-size: 0.9375rem !important;
                                                                    transition: ease .2s;
                                                                    font-weight: 400;
                                                                }

                                                            }
                                                        }
                                                   }
                                                   .part-currency{
                                                    position: absolute;
                                                    opacity: 0;
                                                    visibility: none;
                                                   }
                                                   button{
                                                       cursor: pointer;
                                                       margin-left: 0.4375rem;
                                                       @include centerElement;
                                                       width: 0.5625rem;
                                                       height: 0.5625rem;
                                                       background:transparent;
                                                       img{
                                                            width: 100%;
                                                            transition: ease .3s;

                                                            &.up{
                                                                transform: rotate(180deg);
                                                            }
                                                       }
                                                   }
                                               }
                                                // border: 0.0625rem solid red;

                                            }
                                        }

                                        .criterias-container{
                                            width: 100%;
                                            position: relative;
                                            margin-left: $marginLeft;
                                            padding-top: 1.875rem;
                                            border-left: $leftBorder;
                                            padding-left: 2rem;

                                            .criterias-header{
                                                width: 100%;
                                                display: grid;
                                                grid-template-columns: 21.06rem 11.35rem 11.35rem 11.35rem;
                                                grid-gap: 0 0.525rem;
                                                padding-left: 1.125rem;


                                                & > .criteria-col-title{
                                                    position: relative;
                                                    @include flex(flex-start,left,column);

                                                    &:first-child{
                                                        &::before{
                                                            content: '';
                                                            position: absolute;
                                                            width: 0.9375rem;
                                                            height: 0.0625rem;
                                                            background: #D4D7E9;
                                                            display: block;
                                                            left: -1.1125rem;
                                                            top: 0.525rem;
                                                        }

                                                        &::after{
                                                            content: '';
                                                            position: absolute;
                                                            width: 0.0625rem;
                                                            height: 3rem;
                                                            background: #D4D7E9;
                                                            display: block;
                                                            left: -1.1125rem;
                                                            top: -1.3rem;
                                                        }
                                                    }

                                                    span{
                                                        font-size: 0.875rem;
                                                        color: $grayBlue;
                                                        &.col-title{
                                                            font-weight: 500;
                                                        }
                                                        &.col-value{
                                                            margin-top: 0.75rem;
                                                            font-weight: 400;
                                                        }
                                                        &.col-bold{
                                                            font-weight: 500;
                                                        }
                                                    }
                                                }

                                                .criteria-actions{
                                                    position: absolute;
                                                    top: 1.5625rem;
                                                    right: 26.25rem;
                                                    .dropdown-arrow{
                                                        cursor: pointer;
                                                        img{
                                                            width: 1.5rem;
                                                            transition: ease .3s;
                                                            transform: none;
                                                        }
                                                    }
                                                }
                                            }

                                            .criteria-descriptions{
                                                width: 100%;
                                                position: relative;
                                                padding-top: 0.925rem;
                                                margin-top: 0.375rem;
                                                padding-left: 1.125rem;
                                                border-left: $leftBorder;                                            
                                                padding-bottom:  1.5rem;

                                                .single-description{
                                                    position: relative;
                                                    margin-top: 2.1875rem;

                                                    &:hover{
                                                        .cols > .criteria-col-actions{
                                                            & > div{
                                                                &.edit,&.delete{
                                                                    opacity: 1;
                                                                    pointer-events: all;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    &:first-child{
                                                        margin-top: 0;
                                                    }

                                                    .content{
                                                        // margin-top: 1.5625rem;
                                                        .wrapper{
                                                            @include flex(flex-start,baseline,row);
                                                            animation: show .4s forwards;
                                                        }
                                                    }

                                                    .cols{
                                                        display: grid;
                                                        grid-template-columns: 21.06rem 11.35rem 11.35rem 11.35rem;
                                                        grid-gap: 0 0.525rem;
                                                        position: relative;
                                                        .col-value{
                                                            span{
                                                                font-size: 0.875rem;
                                                                color: $grayBlue;
                                                                font-weight: 400;
                                                            }
                                                        }
                                                        .col-bold{
                                                            span{
                                                                font-weight: 600 !important;
                                                            }
                                                        }

                                                        .criteria-col-actions{
                                                            position: absolute;
                                                            top: 0;
                                                            right: 22.2rem;
                                                            @include flex(flex-start,center,row);

                                                            & > div{
                                                                transition: ease .2s;
                                                                cursor: pointer;
                                                                @include centerElement;
                                                                margin-left: 0.9375rem;
                                                                &.delete,&.edit{
                                                                    opacity: 0;
                                                                    pointer-events: none;
                                                                    img{
                                                                        width: 1.25rem;
                                                                    }
                                                                }

                                                                &.dropdown{
                                                                    transition: ease .3s;
                                                                    &.down{
                                                                        transform: rotate(180deg);
                                                                    }
                                                                    img{
                                                                        width: 0.8125rem;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                    }

                                                    &:first-child{
                                                        margin-top: 0;
                                                    }

                                                    &::before{
                                                        content: '';
                                                        position: absolute;
                                                        width: 0.875rem;
                                                        height: 0.0625rem;
                                                        background: #D4D7E9;
                                                        display: block;
                                                        left: -1.1rem;
                                                        top: 0.625rem;
                                                    }
                                                    .content{
                                                        padding-left: 1.1875rem;
                                                        padding-top: 1.25rem;
                                                        position: relative;
                                                        border-left: 0.0625rem solid #D4D7E9;
                                                        margin-left: .7rem;
                                                        
                                                        &:nth-child(2){
                                                            margin-top: 0.55rem !important;
                                                        }
                                                        &.collapsed-empty{
                                                            border-left: none;
                                                            height: 0;
                                                            padding: 0;

                                                            &::before{
                                                                content: none;
                                                            }
                                                        }

                                                        &::before{
                                                            content: '';
                                                            position: absolute;
                                                            width: 1rem;
                                                            height: 0.0625rem;
                                                            background: #D4D7E9;
                                                            left: 0;
                                                            top: 1.825rem;
                                                        }
                                                        
                                                        // &::after{
                                                        //     content: '';
                                                        //     position: absolute;
                                                        //     width: 0.0625rem;
                                                        //     height: 2rem;
                                                        //     background: #D4D7E9;
                                                        //     left: 0;
                                                        //     bottom: calc(100% - .7rem);
                                                        // }

                                                    }
                                                    .score-range{
                                                        span{
                                                            white-space: nowrap;
                                                            font-size: 0.875rem;
                                                            color: #4D5E80;
                                                            font-weight: 700;
                                                        }
                                                    }
                                                    .description{
                                                        margin-left: 0.5rem;
                                                        width: calc(100% - 14.875rem);
                                                        span{
                                                            font-size: 0.8125rem;
                                                            color: $grayBlue;
                                                            line-height: 0.875rem;
                                                        }
                                                    }
                                                }
                                            }

                                            .add-criteria-field{
                                                .add-criteria{
                                                    margin-top: 1.3125rem;
                                                    button{
                                                        cursor: pointer;
                                                        background: transparent;
                                                        @include flex(flex-start,center,row);
                                                        img{
                                                            width: 1.5rem;
                                                        }
                                                        span{
                                                            color: $purple;
                                                            font-size: 0.8125rem;
                                                            font-weight: 400;
                                                            margin-left: 0.3125rem;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .add-grade-field{
                                width: 100%;
                                margin-top: 0.625rem;

                                .add-grade{
                                    button{
                                        cursor: pointer;
                                        background: transparent;
                                        @include flex(flex-start,center,row);

                                        img{
                                            width:1.5rem;
                                            transition: ease .3s;
                                            &.down{
                                                transform: rotate(45deg);
                                            }
                                        }
                                        span{
                                            font-size: 0.75rem;
                                            color: #ffffff;
                                            padding: 0.4375rem 0.75rem;
                                            background: $purple;
                                            border-radius: 0.375rem;
                                            margin-left: 0.3125rem;
                                        }
                                    }
                                }
                            }

                            .create-grade{
                                margin-top: 0.9375rem;
                                animation:show .4s forwards;
                                form{
                                    ul{
                                        list-style: none;
                                        @include flex(flex-start,center,row);
                                        li{
                                            @include centerElement;
                                            border: none;
                                            width:unset !important;
                                            margin-top: unset !important;
                                            margin-left: 0.9375rem;
                                            overflow: visible !important;

                                            button.save-grade{
                                                @include purpleBtn;
                                            }

                                            .grade-input{
                                                width: 13.75rem;
                                            }

                                            .unity{
                                                pointer-events: none;
                                                user-select: none;
                                            }

                                            .custom-uploader{
                                                width: 3.5rem;
                                                height: 2.5rem;
                                                @include centerElement;
                                                border-radius: 0.375rem;
                                                border: solid 0.0625rem rgba(#959BC9,.4);
                                                overflow: hidden;
                                                transition: ease .3s;

                                                &.uploaded{
                                                    width: 10rem;
                                                    display: flex;
                                                    justify-content: flex-start;
                                                    align-items: center;
                                                    padding-left: .5rem;

                                                }

                                                span{
                                                    margin-left: 0.3125rem;
                                                    color: $grayBlue;
                                                    font-size: 0.8125rem;
                                                    width: 7.3rem;
                                                    white-space: nowrap;
                                                    text-overflow: ellipsis;
                                                    overflow: hidden;
                                                }

                                                > *{
                                                    cursor: pointer;
                                                }
                                                img{
                                                    width: 1.5rem;
                                                    &.preview{
                                                        height: 1.5rem;
                                                    }
                                                }
                                                position: relative;
                                                & > input{
                                                    width: 100%;
                                                    height: 100%;
                                                    position:absolute;
                                                    top: 0;
                                                    left: 0;
                                                    opacity: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .main-content{
                            @include flex(flex-start,center,row);
                            height: 2.5rem;
                            padding: 0  0.9375rem;
                            background: transparent;
                            transition: ease .3s;
                            border-radius: 0.375rem;
                            position: relative;

                            .title{
                                font-size: 0.8125rem;
                                color: #4D5E80;
                                line-height: 1.5rem;
                                padding-left: 0.625rem;
                            }

                            .edit-node-title{
                                @include flex(unset,center,row);
                                & > input{
                                    width: 16rem;
                                    height: 1.6rem;
                                    outline: none;
                                    border: none;
                                    padding: 0 0.3125rem;
                                    font-size: 0.8125rem;
                                    color: $grayBlue;
                                    border-radius: 0.3125rem;
                                    background: rgba(#ffffff,1);
                                }

                                & > .save,.clear{
                                    margin-left: 0.625rem;
                                    @include centerElement;
                                    cursor: pointer;
                                }

                                & > .save{
                                    img{
                                        width: 0.9375rem;
                                    }
                                }

                                & > .clear{
                                    img{
                                        width: 0.75rem;
                                    }
                                }
                            }

                            .list-ind{
                                font-size: 0.8125rem;
                                color: $grayBlue;
                                user-select: none;

                            }

                            .dropdown-arrow{
                                position: absolute;
                                top: 50%;
                                transform: translate(0,-50%);
                                right: 1.4375rem;
                                z-index: 8;
                                margin-top: 0.125rem;
                                button{
                                    background: transparent;
                                    cursor: pointer;

                                    img{
                                        width: 1.5rem;
                                        transition: ease .3s;
                                    }
                                }                                    
                            }
                        }
                    }
                }
            }
        }
    }
}

</style>