import { render, staticRenderFns } from "./WelcomeTextAndAvatar.vue?vue&type=template&id=63965fd5&scoped=true"
import script from "./WelcomeTextAndAvatar.vue?vue&type=script&lang=js"
export * from "./WelcomeTextAndAvatar.vue?vue&type=script&lang=js"
import style0 from "./WelcomeTextAndAvatar.vue?vue&type=style&index=0&id=63965fd5&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63965fd5",
  null
  
)

export default component.exports