<template>
  <div class="background">
    <div class="top-menu">
      <switch-header
        :routes="routes"
        :class="['switch-header', { 'user-view': isMember() }]"
      />
    </div>
    <custom-snackbar
      :text="snackbar.text"
      :purpose="snackbar.purpose"
      v-if="snackbar.visible"
      @close="snackbar.visible = false"
    />
    <div class="content-wrapper">
      <custom-loader v-if="loading" />
      <div class="no-records" v-if="transactions.length < 1 && !loading">
        <span>There are no Records</span>
      </div>
      <div class="content-header">
        <div :class="['top', { 'user-mode': isMember() }]">
          <div
            :class="[
              'header-filters',
              { noTimelineMode: !this.$store.state.showTimeLine && isMember() },
            ]"
          >
            <ul>
              <li class="header_total_amount_pay" v-if="isMember()">
                <div>
                  <span>
                    <span> Paid: </span>
                    <span> {{ calctotalPaid() + " GEL" }} </span>
                  </span>
                </div>
              </li>
              <li
                :class="[
                  'filter-field',
                  { iconOnly: isMember() && field.type === 'date' },
                ]"
                v-for="(field, ind) in filterFields"
                :key="ind"
                v-show="field.visible"
              >
                <div
                  v-if="field.type === 'date'"
                  :class="[
                    'date-filter',
                    { iconOnly: isMember() },
                    { notify: (isMember() && field.to) || field.from },
                  ]"
                >
                  <span>
                    {{
                      field.to || field.from
                        ? dateRange(field.to, field.from)
                        : field.title
                    }}
                  </span>
                  <img src="@/assets/calendar.svg" alt="" />
                  <date-range
                    @from="(from) => (field.from = from)"
                    @to="(to) => ((field.to = to), getTransactions())"
                    :initialFrom="field.from"
                    :initialTo="field.to"
                    v-if="field.type === 'date'"
                    class="range"
                  />
                </div>

                <!-- <custom-select
                                    v-if="field.type !== 'date' && field.visible && field.filterType !== 2 || !field.type && field.visible && field.filterType !== 2 "
                                    :title="field.title"
                                    :options="field.options"
                                    v-model="field.selected"
                                    :clearVisible="true"
                                    @select="getTransactions(false)"
                                /> -->
                <input-search
                  v-if="
                    (field.type !== 'date' && field.visible) ||
                    (!field.type && field.visible)
                  "
                  :title="field.title"
                  @select="
                    (val) => ((field.selected = val), getTransactions(false))
                  "
                  :options="field.options"
                  :valueCleared="clearChildCompValues"
                />
              </li>
              <li class="clear-filters">
                <div @click="clearAllFilters">
                  <span>Clear Filters</span>
                </div>
              </li>
              <!-- <li
                v-if="isMember()"
                :class="['user-email', { 'not-border': !setEmailVisible }]"
              >
                <div
                  class="set-email-btn"
                  v-if="!setEmailVisible"
                  @click="setEmailVisible = true"
                >
                  <span>Set Email</span>
                </div>
                <img class="inf" src="@/assets/info-circle.svg" alt="" />
                <div class="info-wrapper">
                  <span>
                    Entering the same email address you used in your QuickPik
                    app is required to pair account to Intranet.
                  </span>
                </div>
                <div class="email" v-if="setEmailVisible">
                  <div class="placeholder">
                    <input
                      ref="userEmail"
                      type="email"
                      @blur="blurchange(true)"
                      v-if="userEmailEditMode"
                      v-model="editUserLimit.userEmail"
                    />
                    <span v-if="!userEmailEditMode">
                      {{ userEmail ? userEmail : "Enter You Email" }}
                    </span>
                    <div
                      v-if="userEmailEditMode"
                      class="done"
                      @click="submitUpdatedEmail(), blurchange(false)"
                    >
                      <img src="@/assets/done.svg" alt="" />
                    </div>
                    <div @click="showEmailUpdate" v-else class="edit">
                      <img src="@/assets/grades/edit-gray.svg" alt="" />
                    </div>
                  </div>
                </div>
              </li> -->
            </ul>
          </div>
          <div class="header-actions">
            <div
              class="purple-btn export"
              v-if="!isMember()"
              @click="exportTransactions"
            >
              <img
                :class="{ down: false }"
                src="@/assets/grades/add.svg"
                alt="add"
              />
              <span class="">Export</span>
            </div>
            <div
              :class="['limit-field', { 'no-border': !setLimitVisible }]"
              v-else
            >
              <div
                class="set-limit"
                v-if="!setLimitVisible"
                @click="setLimitVisible = true"
              >
                <span>Set Limit</span>
              </div>
              <img class="inf" src="@/assets/info-circle.svg" alt="" />
              <div class="info-wrapper">
                <span
                  >Reaching personal purchasing limit will block the access to
                  vending machine until the next month.</span
                >
              </div>
              <div
                class="limit-placeholder"
                v-if="!editLimitVisible && setLimitVisible"
              >
                <span> {{ userLimitBlock ? userLimitBlock : 0 }} GEL </span>
                <div class="edit-limit" @click="showLimitField">
                  <img src="@/assets/grades/edit-gray.svg" alt="" />
                </div>
              </div>
              <div
                class="limit-edit"
                v-if="editLimitVisible && setLimitVisible"
              >
                <input
                  ref="limitInput"
                  :placeholder="userLimit ? userLimit : 'No Limit'"
                  type="number"
                  @blur="closeLimitField"
                  v-model="editUserLimit.userLimitBlock"
                />
                <div class="done" @click="submitUpdatedLimit()">
                  <img src="@/assets/done.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="transactions-list">
          <ul ref="transactionsList">
            <li
              :class="['list-header', 'noHover', { 'user-mode': isMember() }]"
            >
              <div
                v-show="title.visible"
                :class="['header-col-title', title.class]"
                v-for="(title, ind) in listColumnTitles"
                :key="ind"
              >
                <span v-if="title.visible">{{ title.title }}</span>
                <!-- <div class="sort" @click="sortByPaid()" v-if="title.title === 'Paid'">
                                    <img :class="{'down':paidSorted}" src="@/assets/grades/smallarrow.svg" alt="">
                                </div> -->
              </div>
            </li>
            <li
              :class="['list-item', { 'user-mode': isMember() }]"
              v-for="(t, ind) in transactions"
              :key="ind"
            >
              <div class="main-content">
                <div class="col-value ind">
                  <span>
                    {{ ind + 1 }}
                  </span>
                </div>
                <div class="col-value date">
                  <span>
                    {{ t.vend_datetime ? dateSting(t.vend_datetime) : "-" }}
                  </span>
                </div>
                <div class="col-value user-name" v-if="!isMember()">
                  <span>
                    {{ t.FirstName + " " + t.LastName }}
                  </span>
                </div>
                <div class="col-value selection">
                  <span>
                    {{ t.Selection ? t.Selection : "-" }}
                  </span>
                </div>
                <div class="col-value product">
                  <span>
                    {{ t.product_name ? t.product_name : "-" }}
                  </span>
                </div>
                <div class="col-value price">
                  <span>
                    {{ t.price ? `${t.price} GEL` : "-" }}
                  </span>
                </div>
                <div class="col-value paid">
                  <span>
                    {{ t.price_paid ? `${t.price_paid} GEL` : "0 GEL" }}
                  </span>
                </div>
                <div class="col-value discount">
                  <span>
                    {{ t.dicountTotal ? `${t.dicountTotal} GEL` : "-" }}
                  </span>
                </div>
                <div class="col-value package">
                  <span>
                    {{ t.LimitGroupName ? t.LimitGroupName : "No Package" }}
                  </span>
                </div>
                <div class="col-value quickpikID">
                  <span> fe52524 </span>
                </div>
              </div>
            </li>
            <li
              :class="[
                'list-item',
                'total-purchase',
                'noHover',
                { 'user-mode': isMember() },
              ]"
              v-if="isMember()"
            >
              <div class="main-content">
                <div class="col-value ind"></div>
                <div class="col-value date"></div>
                <div class="col-value user-name" v-if="!isMember()"></div>
                <div class="col-value selection"></div>
                <div class="col-value product"></div>
                <div class="col-value price total total-prices">
                  <span>
                    {{ calculateTotalValues() + " GEL" }}
                  </span>
                  <div class="title-placeholder">Total Purchases</div>
                </div>
                <div class="col-value paid total-payed">
                  <span class="limit">
                    {{ calctotalPaid() + " GEL" }}
                  </span>
                  <span class="calculated">
                    {{ calcPaidDiscount() + " GEL" }}
                  </span>
                </div>
                <div class="col-value discount total-discount">
                  <span>
                    {{ calctotalDiscounts() + " GEL" }}
                  </span>
                </div>
                <div class="col-value package"></div>
                <div class="col-value quickpikID"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchHeader from "@/components/ReusableComponents/SwitchHeader/SwitchHeader.vue";
// import CustomSelect from '@/components/ReusableComponents/CustomSelect/CustomSelect.vue'
import DateRange from "@/components/ReusableComponents/DateRange/DateRange.vue";
import { getDateString } from "@/Helpers/HelperFunctions";
import axios from "@/Helpers/AxiosInstance.js";
import CustomLoader from "@/components/ReusableComponents/Loader/CustomLoader.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import InputSearch from "@/components/ReusableComponents/InputSearch/InputSearch.vue";

export default {
  components: {
    SwitchHeader,
    // CustomSelect,
    DateRange,
    CustomLoader,
    CustomSnackbar,
    InputSearch,
  },
  data() {
    return {
      snackbar: {
        text: "",
        purpose: "",
        visible: false,
      },
      routes: [
        {
          title: "Packages",
          path: "/VendingPackages",
          hidden: this.isMember(),
        },
        {
          title: "Users",
          path: "/Users",
          hidden: this.isMember(),
        },
        {
          title: "Transactions",
          path: "/Transactions",
        },
      ],
      filterFields: [
        {
          title: "Period",
          from: null,
          to: null,
          type: "date",
          visible: true,
          filterType: 1,
        },
        {
          title: "User",
          options: [],
          selected: null,
          visible: !this.isMember(),
          filterType: 2,
        },
        {
          title: "Paid",
          options: [],
          selected: null,
          visible: this.isMember(),
          filterType: 3,
        },
        {
          title: "Product",
          options: [],
          selected: null,
          visible: true,
          filterType: 4,
        },
        {
          title: "Stand. Price",
          options: [],
          selected: null,
          visible: true,
          filterType: 5,
        },
        {
          title: "Package",
          options: [],
          selected: null,
          visible: true,
          filterType: 6,
        },
      ],
      listColumnTitles: [
        {
          title: "#",
          class: "ind",
          visible: true,
        },
        {
          title: "Date",
          class: "date",
          visible: true,
        },
        {
          title: "User",
          class: "user-name",
          visible: !this.isMember(),
        },
        {
          title: "Selection",
          class: "selection",
          visible: true,
        },
        {
          title: "Product",
          class: "product",
          visible: true,
        },
        {
          title: "Stand. Price",
          class: "price",
          visible: true,
        },
        {
          title: "Paid",
          class: "paid",
          visible: true,
        },
        {
          title: "Discount",
          class: "discount",
          visible: true,
        },
        {
          title: "Package",
          class: "package",
          visible: true,
        },
        {
          title: "QuickPik ID",
          class: "quickpikID",
          visible: true,
        },
      ],
      paidSorted: false,
      userEmail: "",
      userLimit: null,
      userEmailEditMode: false,
      editLimitVisible: false,
      packageList: [],
      transactions: [],
      userLimitBlock: null,
      editUserLimit: {
        userEmail: "",
        userLimitBlock: null,
        limitID: null,
      },
      packages: [],
      totals: {
        totalPrice: null,
        totalPaid: null,
        discountPaid: null,
      },
      loading: false,
      clearChildCompValues: false,
      setEmailVisible: false,
      setLimitVisible: false,
    };
  },
  async created() {
    this.getPackages();
    await this.getTransactions(true);
    this.getUserData();
  },
  methods: {
    async getTransactions(firstCall) {
      this.loading = true;
      let todayDate = new Date();
      let dateFrom = this.filterFields.find((el) => el.filterType === 1).from
        ? this.filterFields.find((el) => el.filterType === 1).from
        : getDateString(todayDate);
      let dateTo = this.filterFields.find((el) => el.filterType === 1).to
        ? this.filterFields.find((el) => el.filterType === 1).to
        : getDateString(todayDate);
      await axios
        .get("salary/GetQuickPickTransactions", {
          params: {
            PersonID: this.isMember() ? this.$store.state.profile.ID : null,
            FromDate: dateFrom ? getDateString(dateFrom) : null,
            ToDate: dateTo ? getDateString(dateTo) : null,
          },
        })
        .then((resp) => {
          this.loading = false;
          let data = resp.data.Value;
          this.getQuickPickUserData();

          data.forEach((el) => {
            let discountTotal = el.price - el.price_paid;
            el["dicountTotal"] = discountTotal.toFixed(2);
          });

          if (firstCall) {
            let personList = data.map((person) => ({
              title: `${person.FirstName} ${person.LastName}`,
              value: person.PersonID,
            }));

            let products = data.map((prod) => ({
              title: prod.product_name,
              value: prod.product_name,
            }));

            let prices = data.map((price) => ({
              title: price.price + ` GEL`,
              value: price.price,
            }));

            let paid = data.map((p) => ({
              title: p.price_paid + " GEL",
              value: p.price_paid,
            }));

            paid = [...new Map(paid.map((e) => [e["value"], e])).values()];
            personList = [
              ...new Map(personList.map((e) => [e["value"], e])).values(),
            ];
            products = [
              ...new Map(products.map((e) => [e["value"], e])).values(),
            ];
            prices = [...new Map(prices.map((e) => [e["value"], e])).values()];

            this.filterFields.find(
              (el) => el.filterType === 2
            ).options = personList;
            this.filterFields.find(
              (el) => el.filterType === 3
            ).options = paid.sort((a, b) => a.value - b.value);
            this.filterFields.find(
              (el) => el.filterType === 4
            ).options = products;
            this.filterFields.find(
              (el) => el.filterType === 5
            ).options = prices.sort((a, b) => a.value - b.value);
          }

          let filterAvailable = this.filterFields.some((el) => el.selected);
          if (filterAvailable) {
            this.filterFields.forEach((el) => {
              if (el.filterType === 2 && el.selected)
                data = data.filter((user) => user.PersonID === el.selected);
              if (el.filterType === 3 && el.selected)
                data = data.filter((user) => user.price_paid === el.selected);
              if (el.filterType === 4 && el.selected)
                data = data.filter((user) => user.product_name === el.selected);
              if (el.filterType === 5 && el.selected)
                data = data.filter((user) => user.price === el.selected);
              if (el.filterType === 6 && el.selected)
                data = data.filter((user) => user.LimitGroupID === el.selected);
            });
          }
          this.transactions = data;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getQuickPickUserData() {
      axios.get("salary/GetQuickPickUsers").then((resp) => {
        let data = resp.data.Value;
        this.transactions.forEach((el) => {
          let userData = data.filter(
            (el) => el.PersonInternalID === el.PersonID
          );
          this.$set(el, "AdditionalData", userData);
        });
      });
    },
    isMember() {
      let userState;
      if (
        this.$store.getters.hasRole("Member") ||
        this.$store.getters.hasRole("Manager")
      ) {
        userState = true;
      }
      if (
        (!this.$store.getters.hasRole("Member") &&
          this.$store.state.showTimeLine) ||
        (!this.$store.getters.hasRole("Manager") &&
          this.$store.state.showTimeLine)
      ) {
        userState = true;
      } else {
        false;
      }
      return userState;
    },
    dateRange(to, from) {
      let toDate = getDateString(to, "yyyy-MM-dd");
      let fromDate = getDateString(from, "yyyy-MM-dd");
      return `${fromDate} - ${toDate}`;
    },
    showEmailUpdate() {
      this.userEmailEditMode = true;
      this.$nextTick(() => {
        this.$refs.userEmail.focus();
      });
    },
    showLimitField() {
      this.editLimitVisible = true;
      this.$nextTick(() => {
        this.$refs.limitInput.focus();
      });
    },
    blurchange(state) {
      if (state) {
        setTimeout(() => {
          this.userEmailEditMode = false;
        }, 400);
      } else return;
    },
    closeLimitField() {
      setTimeout(() => {
        this.editLimitVisible = false;
      }, 400);
    },
    snackbarMessage(text, purpose) {
      this.snackbar.visible = true;
      this.snackbar.text = text;
      this.snackbar.purpose = purpose;
    },
    getUserData() {
      this.loading = true;
      if (!this.isMember()) {
        this.loading = false;
        return;
      } else {
        axios
          .get("salary/GetPersonsLimitGroupInfo", {
            params: {
              PersonID: this.$store.state.profile.ID,
            },
          })
          .then((resp) => {
            this.loading = false;
            if (resp.data.Value.length > 0) {
              let limitBlock = resp.data.Value[0].RequestBlockLimit
                ? resp.data.Value[0].RequestBlockLimit
                : null;
              let email = resp.data.Value[0].ExternalID
                ? resp.data.Value[0].ExternalID
                : "";
              let limitID = resp.data.Value[0].ExternalID
                ? resp.data.Value[0].LimitID
                : null;
              if (!email) this.setEmailVisible = false;
              if (email) this.setEmailVisible = true;
              this.userEmail = email;
              if (!limitBlock) this.setLimitVisible = false;
              if (limitBlock) this.setLimitVisible = true;
              this.userLimitBlock = limitBlock;
              this.editUserLimit.userEmail = email;
              this.editUserLimit.userLimitBlock = limitBlock;
              this.editUserLimit.limitID = limitID;
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    dateSting(date) {
      return getDateString(date, "dd.MM.yyyy / HH:mm");
    },
    clearAllFilters() {
      this.clearChildCompValues = !this.clearChildCompValues;
      this.filterFields.forEach((el) => {
        el.selected = null;
        if (el.filterType === 1) (el.from = null), (el.to = null);
        this.getTransactions(false);
      });
    },
    filterPackageDetails(id) {
      if (!id || id === null || id === undefined || id === 0) return 0;
      else {
        let currentPackage = this.packages.find((el) => el.LimitGroupID === id);
        return `${
          currentPackage.DiscountPercentAmount
            ? currentPackage.DiscountPercentAmount + " %"
            : 0
        } `;
      }
    },
    calculateTotalValues() {
      let totalPrices = [];
      this.transactions.forEach((el) => {
        totalPrices.push(el.price);
      });
      const totalsum = totalPrices.reduce((partialSum, a) => partialSum + a, 0);
      this.totals.totalPrice = totalsum;
      return totalsum.toFixed(2);
    },
    calctotalPaid() {
      let totalPaid = [];
      this.transactions.forEach((el) => {
        totalPaid.push(el.price_paid);
      });
      const totalPaidsum = totalPaid.reduce(
        (partialSum, a) => partialSum + a,
        0
      );
      this.totals.totalPaid = totalPaidsum;
      return totalPaidsum.toFixed(2);
    },
    calcPaidDiscount() {
      let discount = this.totals.totalPrice - this.totals.totalPaid;
      this.totals.discountPaid = discount;
      return discount.toFixed(2);
    },
    calctotalDiscounts() {
      let discounts = [];
      this.transactions.forEach((el) => {
        discounts.push(parseFloat(el.dicountTotal));
      });
      const discounTotalSum = discounts.reduce(
        (partialSum, a) => partialSum + a,
        0
      );
      return discounTotalSum.toFixed(2);
    },
    submitUpdatedEmail() {
      if (!this.isMember()) return;

      this.loading = true;
      let email = this.editUserLimit.userEmail;
      if (email) {
        if (/^\S+@\S+\.\S+$/.test(email)) {
          axios
            .post("salary/CreateQuickPickExternalID", {
              personID: this.$store.state.profile.ID,
              externalID: email ? email : "",
            })
            .then((resp) => {
              this.loading = false;
              if (!resp.data.ErrorMsg) {
                this.snackbarMessage("Email Updated Successfully", "success");
                this.getUserData();
              } else {
                this.snackbarMessage(resp.data.ErrorMsg, "error");
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        } else {
          this.snackbarMessage("Please Enter Valid Email", "error");
        }
      } else {
        this.loading = false;
      }
    },
    // sortByPaid(){
    //     this.paidSorted = !this.paidSorted
    //     if(this.paidSorted){
    //         this.transactions.sort((a,b) => b.price_paid - a.price_paid)
    //     }else{
    //         this.transactions.sort((a,b) => a.price_paid - b.price_paid)
    //     }
    // },
    submitUpdatedLimit() {
      let limit = this.editUserLimit.userLimitBlock;
      let limitID = this.editUserLimit.limitID;
      if (limit) {
        if (limitID === null) {
          this.snackbarMessage(
            "Purchasing limit can be set only after the package is assigned.",
            "error"
          );
          return;
        } else {
          this.loading = true;
          axios
            .post("salary/UpdateQuickPickLimit", {
              quickPickLimitID: limitID,
              requestBlockLimit: parseInt(limit),
            })
            .then((resp) => {
              this.loading = false;
              if (!resp.data.ErrorMsg) {
                this.snackbarMessage("Limit Updated Successfully", "success");
                this.getUserData();
              } else {
                this.snackbarMessage(resp.data.ErrorMsg, "error");
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      }
    },
    exportTransactions() {
      this.loading = true;
      let filters = this.filterFields;
      let dateFrom = filters.find((el) => el.filterType === 1).from;
      let dateTo = filters.find((el) => el.filterType === 1).to;
      let person = filters.find((el) => el.filterType === 2).selected;
      let product = filters.find((el) => el.filterType === 4).selected;
      let packageID = filters.find((el) => el.filterType === 6).selected;
      let price = filters.find((el) => el.filterType === 5).selected;
      let paid = filters.find((el) => el.filterType === 3).selected;

      axios
        .get("salary/ExportPickTransactions", {
          params: {
            PersonID: person ? person : null,
            FromDate: dateFrom ? getDateString(dateFrom) : null,
            ToDate: dateTo ? getDateString(dateTo) : null,
            ProductName: product ? product : "",
            InternalGroupType: packageID ? packageID : "",
            PriceStandartFrom: price ? price : null,
            PriceStandardTo: price ? price : null,
            PricePaidFrom: paid ? paid : null,
            PricePaidTo: paid ? paid : null,
          },
        })
        .then((resp) => {
          this.loading = false;
          if (!resp.data.ErrorMsg) {
            this.snackbarMessage(
              "Transactions Exported Successfully",
              "success"
            );
            let src = resp.request.responseURL;
            const exportLink = document.createElement("a");
            exportLink.href = src;
            exportLink.click();
            exportLink.target = "blank";
          } else {
            this.snackbarMessage(resp.data.ErrorMsg, "error");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getPackages() {
      axios
        .get("salary/GetQuickPickLimitGroups", {
          params: {
            SearchTerm: "",
          },
        })
        .then((resp) => {
          let data = resp.data.Value;
          this.packages = resp.data.Value;

          this.packageList = data.map((el) => ({
            title: el.LimitGroupName || "Title Not Available",
            value: el.LimitGroupID,
          }));
          this.filterFields.find(
            (el) => el.filterType === 6
          ).options = this.packageList;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.switch-header {
  &.user-view {
    ::v-deep {
      .route {
        margin-left: 0;
      }
    }
  }
}

.no-records {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  user-select: none;
  span {
    font-size: 1.875rem;
    color: rgba($grayBlue, 0.8);
  }
}

.background {
  overflow: hidden;
}

ul {
  list-style: none;
}

.top-menu {
  top: 0;
  z-index: 10;
  background: #fff;
  padding-bottom: 0.625rem;
  padding-left: 1.875rem;
}

.content-wrapper {
  width: calc(100% - (1.875rem * 2));
  height: calc(100% - 1.5rem);
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  background: #fff;
  overflow: hidden;
  margin: 1.5rem auto;
  box-sizing: border-box;

  .content-header {
    width: 100%;

    .top {
      padding: 1.75rem 3.125rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fafbfc;
      border-bottom: 0.25rem solid #f5f6f7;
      height: 6rem;

      &.user-mode {
        justify-content: flex-start;
      }

      .header-title {
        span {
          color: $grayBlue;
          font-size: 1rem;
          font-weight: 600;
        }
      }

      .header-actions {
        margin-left: 0.9375rem;
        @include flex(flex-start, center, row);

        .limit-field {
          width: 12rem;
          height: 2.5rem;
          background: #fafbfc;
          border: 0.0625rem solid #dcdde6;
          padding: 0 0.625rem;
          border-radius: 0.375rem;
          @include flex(flex-start, center, row);
          position: relative;

          &.no-border {
            border: 0.0625rem solid transparent;

            .inf {
              left: 5.4rem !important;
              top: calc(100% - 0.125rem);
            }
            .info-wrapper {
              left: -34.125rem;
            }
          }

          .set-limit {
            width: 6rem;
            height: 2.5rem;
            cursor: pointer;
            background: $purple;
            box-shadow: 0 0 1.25rem rgba($purple, 0.2);
            border-radius: 1.5625rem;
            @include centerElement;
            position: absolute;
            top: 0;
            left: 0;
            span {
              color: #ffffff;
              font-size: 0.875rem;
            }
          }
          .inf {
            position: absolute;
            top: calc(100% + 0.25rem);
            right: -0.3125rem;
            transition: ease 0.2s;
            &:hover ~ .info-wrapper {
              display: block;
            }
          }
          .info-wrapper {
            display: none;
            transition: ease 0.2s;
            pointer-events: none;
            user-select: none;
            width: 39.375rem;
            position: absolute;
            font-size: 0.875rem;
            left: -28.425rem;
            top: calc(100% + 0.4375rem);
            padding: 0.1875rem 0.3125rem;
            background: #ff9233;
            border-radius: 0.3125rem;
            color: #ffffff;
          }

          .limit-placeholder {
            width: 100%;
            @include flex(space-between, center, row);
            span {
              color: $grayBlue;
              font-size: 0.875rem;
            }

            .edit-limit {
              cursor: pointer;
              font-size: 0.8125rem;
              background: transparent;
              padding: 0.375rem 0.0625rem;
              border-radius: 0.9375rem;
              font-weight: 500;
              img {
                width: 1.25rem;
              }
            }
          }
          .limit-edit {
            width: 100%;
            height: 100%;
            @include flex(space-between, center, row);

            input {
              width: 100%;
              height: calc(100% - 0.625rem);
              padding-right: 0.625rem;
              border: none;
              outline: none;
              color: $grayBlue;
              font-size: 0.875rem;

              &::-webkit-inner-spin-button,
              &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }

            .done {
              cursor: pointer;
              @include centerElement;
              img {
                width: 1.5rem;
              }
            }
          }
        }
        .info {
          padding-left: 0.625rem;
          position: relative;
          cursor: pointer;
          &:hover {
            .info-wrapper {
              opacity: 1;
              pointer-events: all;
            }
          }
          img {
            width: 1.25rem;
          }
          .info-wrapper {
            opacity: 0;
            transition: ease 0.2s;
            pointer-events: none;
            user-select: none;
            width: 35.375rem;
            position: absolute;
            font-size: 0.875rem;
            left: calc(-28.125rem - 5.90375rem);
            top: calc(100% + 1rem);
            padding: 0.1875rem 0.3125rem;
            background: #ff9233;
            border-radius: 0.3125rem;
            color: #ffffff;
          }
        }
      }
      .header-filters {
        &.noTimelineMode {
          ul {
            li.filter-field:not(.iconOnly) {
              width: 10.45rem !important;

              ::v-deep .input-search {
                width: 100% !important;
              }
            }
          }
        }
        .clear-filters {
          cursor: pointer;
          width: auto;
          margin-left: 1.25rem;

          &:hover {
            span {
              color: rgba($red, 0.6);
            }
          }
          & > div {
            height: 100%;
            @include centerElement;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              width: 0.0625rem;
              height: calc(100% - 0.8rem);
              background: rgba($grayBlue, 0.5);
              transition: ease 0.3s;
              top: 50%;
              transform: translate(0, -50%);
              left: -0.375rem;
            }

            &:active {
              &::before {
                height: calc(100% - 2rem);
              }
            }

            span {
              color: $grayBlue;
              transition: ease 0.3s;
              font-size: 0.875rem;
              font-weight: 400;
            }
          }
        }

        .header_total_amount_pay {
          max-width: 9rem;
          padding-left: 0.3125rem;
          @include centerElement;
          border-radius: 0.375rem;
          overflow: auto;
          border: 0.0625rem solid rgba(#fe228b, 0.7);
          box-shadow: 0 0 1.25rem rgba($grayBlue, 0.3);
          // background: rgba($purple,.8);
          user-select: none;
          span {
            font-size: 0.875rem;
            font-weight: 500;
            color: #fe228b;
            &:first-child {
              color: $grayBlue;
            }
          }
        }

        ul {
          list-style: none;
          @include flex(flex-start, center, row);
          li {
            width: 14.75rem;
            height: 2.5rem;
            margin-left: 0.375rem;

            &.iconOnly {
              width: 3rem;
            }
            &:first-child {
              margin-left: 0;
            }

            ::v-deep {
              .input-search {
                width: 100%;
              }
              label {
                width: 100%;
              }
              .title {
                color: $grayBlue;
                font-size: 0.875rem;
              }
              .selected-option {
                color: $grayBlue;
                font-size: 0.875rem;
                font-weight: 400;
              }

              .material-icons {
                color: $grayBlue;
              }
            }

            .date-filter {
              width: 100%;
              height: 100%;
              border: 0.0625rem solid #dcdde6;
              border-radius: 0.375rem;
              position: relative;
              background: #ffffff;
              padding: 0 0.625rem;
              @include flex(space-between, center, row);

              span {
                font-size: 0.875rem;
                color: $grayBlue;
                font-weight: 300;
              }
              img {
                width: 1.4375rem;
              }
              .range {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
              }

              &.iconOnly {
                border: 0.0625rem solid transparent;

                span {
                  display: none;
                }
                background: transparent;
                &.notify {
                  &::before {
                    content: "";
                    position: absolute;
                    right: 0.5rem;
                    top: 0.5rem;
                    width: 0.3125rem;
                    height: 0.3125rem;
                    background: #fe228b;
                    border-radius: 50%;
                  }
                }
              }
            }

            &.user-email {
              width: 17rem;
              padding: 0 0.7rem;
              margin-left: 0.9375rem;
              @include flex(flex-start, center, row);
              border: 0.0625rem solid #dcdde6;
              border-radius: 0.375rem;
              position: relative;

              &.not-border {
                border: 0.0625rem transparent solid;

                .inf {
                  top: calc(100% - 0.125rem) !important;
                }
              }

              .set-email-btn {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
                width: 6rem;
                height: 2.5rem;
                border-radius: 1.5625rem;
                background: #ff3356 !important;
                box-shadow: 0 0 1.25rem rgba(#ff3356, 0.2);
                color: #fff;
                @include centerElement;
                font-size: 0.875rem;
              }

              .inf {
                position: absolute;
                top: calc(100% + 0.25rem) !important;
                right: -0.4375rem;
                transition: ease 0.2s;
                &:hover ~ .info-wrapper {
                  display: block;
                }
              }

              .info-wrapper {
                display: none;
                pointer-events: none;
                width: 40rem;
                position: absolute;
                top: calc(100% + 0.2rem);
                right: 0.75rem;
                background: #ff9233;
                color: #ffffff;
                font-size: 0.875rem;
                padding: 0.3125rem 0.3125rem;
                white-space: nowrap;
                border-radius: 0.3125rem;
              }

              .email {
                width: 100%;
                height: 100%;
              }
              .placeholder {
                width: 100%;
                height: 100%;
                font-size: 0.875rem;
                font-weight: 400;
                color: $purple;

                span {
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  padding-right: 0.3125rem;
                }

                input {
                  width: 100%;
                  height: calc(100% - 0.3125rem);
                  outline: none;
                  border: none;
                  height: 100%;
                  color: $grayBlue;
                  padding-right: 0.625rem;
                }
                @include flex(space-between, center, row);
                .done,
                .edit {
                  cursor: pointer;
                  @include centerElement;
                  img {
                    width: 1.5rem;
                  }
                  &.edit {
                    img {
                      width: 1.25rem;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .add-new-group {
        img {
          transition: ease 0.3s;
        }
        img.down {
          transform: rotate(45deg);
        }
      }
    }
  }
}
.middle {
  width: 100%;

  .top {
    padding: 1.75rem 3.125rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fafbfc;
    border-bottom: 0.25rem solid #f5f6f7;
    height: 6rem;

    &.user-mode {
      justify-content: flex-start;
    }

    .header-title {
      span {
        color: $grayBlue;
        font-size: 1rem;
        font-weight: 600;
      }
    }

    .header-actions {
      margin-left: 0.9375rem;
      @include flex(flex-start, center, row);

      .limit-field {
        width: 12rem;
        height: 2.5rem;
        background: #fafbfc;
        border: 0.0625rem solid #dcdde6;
        padding: 0 0.625rem;
        border-radius: 0.375rem;
        @include flex(flex-start, center, row);
        position: relative;

        &.no-border {
          border: 0.0625rem solid transparent;

          .inf {
            left: 5.4rem !important;
            top: calc(100% - 0.125rem);
          }
          .info-wrapper {
            left: -34.125rem;
          }
        }

        .set-limit {
          width: 6rem;
          height: 2.5rem;
          cursor: pointer;
          background: $purple;
          box-shadow: 0 0 1.25rem rgba($purple, 0.2);
          border-radius: 1.5625rem;
          @include centerElement;
          position: absolute;
          top: 0;
          left: 0;
          span {
            color: #ffffff;
            font-size: 0.875rem;
          }
        }
        .inf {
          position: absolute;
          top: calc(100% + 0.25rem);
          right: -0.3125rem;
          transition: ease 0.2s;
          &:hover ~ .info-wrapper {
            display: block;
          }
        }
        .info-wrapper {
          display: none;
          transition: ease 0.2s;
          pointer-events: none;
          user-select: none;
          width: 39.375rem;
          position: absolute;
          font-size: 0.875rem;
          left: -28.425rem;
          top: calc(100% + 0.4375rem);
          padding: 0.1875rem 0.3125rem;
          background: #ff9233;
          border-radius: 0.3125rem;
          color: #ffffff;
        }

        .limit-placeholder {
          width: 100%;
          @include flex(space-between, center, row);
          span {
            color: $grayBlue;
            font-size: 0.875rem;
          }

          .edit-limit {
            cursor: pointer;
            font-size: 0.8125rem;
            background: transparent;
            padding: 0.375rem 0.0625rem;
            border-radius: 0.9375rem;
            font-weight: 500;
            img {
              width: 1.25rem;
            }
          }
        }
        .limit-edit {
          width: 100%;
          height: 100%;
          @include flex(space-between, center, row);

          input {
            width: 100%;
            height: calc(100% - 0.625rem);
            padding-right: 0.625rem;
            border: none;
            outline: none;
            color: $grayBlue;
            font-size: 0.875rem;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }

          .done {
            cursor: pointer;
            @include centerElement;
            img {
              width: 1.5rem;
            }
          }
        }
      }
      .info {
        padding-left: 0.625rem;
        position: relative;
        cursor: pointer;
        &:hover {
          .info-wrapper {
            opacity: 1;
            pointer-events: all;
          }
        }
        img {
          width: 1.25rem;
        }
        .info-wrapper {
          opacity: 0;
          transition: ease 0.2s;
          pointer-events: none;
          user-select: none;
          width: 35.375rem;
          position: absolute;
          font-size: 0.875rem;
          left: calc(-28.125rem - 5.90375rem);
          top: calc(100% + 1rem);
          padding: 0.1875rem 0.3125rem;
          background: #ff9233;
          border-radius: 0.3125rem;
          color: #ffffff;
        }
      }
    }
    .header-filters {
      &.noTimelineMode {
        ul {
          li.filter-field:not(.iconOnly) {
            width: 10.45rem !important;

            ::v-deep .input-search {
              width: 100% !important;
            }
          }
        }
      }
      .clear-filters {
        cursor: pointer;
        width: auto;
        margin-left: 1.25rem;

        &:hover {
          span {
            color: rgba($red, 0.6);
          }
        }
        & > div {
          height: 100%;
          @include centerElement;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 0.0625rem;
            height: calc(100% - 0.8rem);
            background: rgba($grayBlue, 0.5);
            transition: ease 0.3s;
            top: 50%;
            transform: translate(0, -50%);
            left: -0.375rem;
          }

          &:active {
            &::before {
              height: calc(100% - 2rem);
            }
          }

          span {
            color: $grayBlue;
            transition: ease 0.3s;
            font-size: 0.875rem;
            font-weight: 400;
          }
        }
      }

      .header_total_amount_pay {
        max-width: 9rem;
        padding-left: 0.3125rem;
        @include centerElement;
        border-radius: 0.375rem;
        overflow: auto;
        border: 0.0625rem solid rgba(#fe228b, 0.7);
        box-shadow: 0 0 1.25rem rgba($grayBlue, 0.3);
        // background: rgba($purple,.8);
        user-select: none;
        span {
          font-size: 0.875rem;
          font-weight: 500;
          color: #fe228b;
          &:first-child {
            color: $grayBlue;
          }
        }
      }

      ul {
        list-style: none;
        @include flex(flex-start, center, row);
        li {
          width: 14.75rem;
          height: 2.5rem;
          margin-left: 0.375rem;

          &.iconOnly {
            width: 3rem;
          }
          &:first-child {
            margin-left: 0;
          }

          ::v-deep {
            label {
              width: 100%;
            }
            .title {
              color: $grayBlue;
              font-size: 0.875rem;
            }
            .selected-option {
              color: $grayBlue;
              font-size: 0.875rem;
              font-weight: 400;
            }

            .material-icons {
              color: $grayBlue;
            }
          }

          .date-filter {
            width: 100%;
            height: 100%;
            border: 0.0625rem solid #dcdde6;
            border-radius: 0.375rem;
            position: relative;
            background: #ffffff;
            padding: 0 0.625rem;
            @include flex(space-between, center, row);

            span {
              font-size: 0.875rem;
              color: $grayBlue;
              font-weight: 300;
            }
            img {
              width: 1.4375rem;
            }
            .range {
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
            }

            &.iconOnly {
              border: 0.0625rem solid transparent;

              span {
                display: none;
              }
              background: transparent;
              &.notify {
                &::before {
                  content: "";
                  position: absolute;
                  right: 0.5rem;
                  top: 0.5rem;
                  width: 0.3125rem;
                  height: 0.3125rem;
                  background: #fe228b;
                  border-radius: 50%;
                }
              }
            }
          }

          &.user-email {
            width: 17rem;
            padding: 0 0.7rem;
            margin-left: 0.9375rem;
            @include flex(flex-start, center, row);
            border: 0.0625rem solid #dcdde6;
            border-radius: 0.375rem;
            position: relative;

            &.not-border {
              border: 0.0625rem transparent solid;

              .inf {
                top: calc(100% - 0.125rem) !important;
              }
            }

            .set-email-btn {
              position: absolute;
              top: 0;
              right: 0;
              cursor: pointer;
              width: 6rem;
              height: 2.5rem;
              border-radius: 1.5625rem;
              background: #ff3356 !important;
              box-shadow: 0 0 1.25rem rgba(#ff3356, 0.2);
              color: #fff;
              @include centerElement;
              font-size: 0.875rem;
            }

            .inf {
              position: absolute;
              top: calc(100% + 0.25rem) !important;
              right: -0.4375rem;
              transition: ease 0.2s;
              &:hover ~ .info-wrapper {
                display: block;
              }
            }

            .info-wrapper {
              display: none;
              pointer-events: none;
              width: 40rem;
              position: absolute;
              top: calc(100% + 0.2rem);
              right: 0.75rem;
              background: #ff9233;
              color: #ffffff;
              font-size: 0.875rem;
              padding: 0.3125rem 0.3125rem;
              white-space: nowrap;
              border-radius: 0.3125rem;
            }

            .email {
              width: 100%;
              height: 100%;
            }
            .placeholder {
              width: 100%;
              height: 100%;
              font-size: 0.875rem;
              font-weight: 400;
              color: $purple;

              span {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                padding-right: 0.3125rem;
              }

              input {
                width: 100%;
                height: calc(100% - 0.3125rem);
                outline: none;
                border: none;
                height: 100%;
                color: $grayBlue;
                padding-right: 0.625rem;
              }
              @include flex(space-between, center, row);
              .done,
              .edit {
                cursor: pointer;
                @include centerElement;
                img {
                  width: 1.5rem;
                }
                &.edit {
                  img {
                    width: 1.25rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    .add-new-group {
      img {
        transition: ease 0.3s;
      }
      img.down {
        transform: rotate(45deg);
      }
    }
  }
}

.middle {
  width: 100%;
  margin-top: 1.25rem;
  overflow: auto;
  height: calc(100% - 160px);

  .transactions-list {
    width: 100%;
    padding: 0 3.125rem;
    animation: show 0.5s forwards;

    ul {
      width: 100%;
      @include flex(flex-start, flex-start, column);

      li {
        width: 100%;
        @include flex(flex-start, center, row);
        height: 2.5rem;
        margin-top: 0.9375rem;

        border: 0.0625rem solid rgba(#959bc9, 0.4);
        border-radius: 0.375rem;
        padding: 0 0.9375rem;
        background: #fff;
        transition: ease 0.1s;
        position: relative;
        cursor: default;

        &:hover:not(.noHover) {
          background: rgba($purple, 0.9);
          .col-value {
            span {
              color: #ffffff !important;
            }
          }
        }

        &:first-child {
          margin-top: 0;
          border: 0.0625rem solid transparent;
        }
        &:nth-child(2) {
          margin-top: 0.3125rem;
        }

        &.list-header {
          position: sticky;
          top: 0;
          z-index: 1;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          .header-col-title {
            color: $purple;
            font-size: 0.875rem;
            font-weight: 500;
            white-space: nowrap;
            margin-left: 1.375rem;
            white-space: nowrap;
            position: relative;
            &:first-child {
              margin-left: 0;
            }

            .sort {
              cursor: pointer;
              position: absolute;
              top: -0.125rem;
              right: 3.5rem;

              img {
                width: 0.5625rem;
                transition: ease 0.2s;
                transform: rotate(-180deg);
                &.down {
                  transform: rotate(0);
                }
              }
            }
          }
        }

        &.list-header,
        &.list-item {
          .ind {
            width: 3rem;
          }

          .date {
            width: 7rem;
            margin-left: 0 !important;
          }
          .user-name {
            width: 11.5rem;
          }
          .selection {
            width: 5rem;
          }
          .product {
            width: 11.5rem;
          }
          .price,
          .paid,
          .discount {
            width: 6.5rem;
          }
          .package {
            width: 9rem;
          }

          .quickpikID {
            width: 9rem;
          }
        }

        &.user-mode {
          &.list-header,
          &.list-item,
          &.total-purchase {
            .ind {
              width: 5rem;
            }

            .date {
              width: 10rem;
              margin-left: 0 !important;
            }
            .user-name {
              width: 15.5rem;
            }
            .selection {
              width: 8rem;
            }
            .product {
              width: 15rem;
            }
            .price,
            .paid,
            .discount {
              width: 8rem;
            }
            .package {
              width: 12rem;
            }

            .quickpikID {
              width: 12rem;
            }
          }
        }

        &.list-item {
          overflow: hidden;
          .main-content {
            width: 100%;
            @include flex(flex-start, center, row);

            .col-value {
              white-space: nowrap;
              overflow: hidden;
              margin-left: 1.375rem;
              span {
                color: #7d8fb3;
                font-size: 0.8125rem;
                font-weight: 400;
              }
              &:first-child {
                margin-left: 0;
              }
            }
          }
        }

        &.total-purchase {
          margin-top: 2.1875rem !important;
          padding-bottom: 3rem;
          height: unset;
          border: 0.0625rem solid transparent;

          .col-value {
            overflow: visible !important;
          }

          .total {
            position: relative;
            span {
              color: $purple !important;
              font-weight: 500 !important;
            }
            .title-placeholder {
              position: absolute;
              top: 0;
              left: calc(-100% - 5.625rem);
              font-size: 0.875rem;
              color: $grayBlue;
              font-weight: 500;
            }
          }

          .total-payed {
            @include flex(flex-start, left, column);
            position: relative;
            span {
              color: $purple !important;
              font-weight: 500 !important;

              &:nth-child(2) {
                position: absolute;
                bottom: calc(-100% - 0.625rem);
              }

              &.limit {
                color: #fe228b !important;
              }
            }
          }

          .total-discount {
            span {
              color: #1de9b6 !important;
              font-weight: 500 !important;
            }
          }
        }
      }
    }
  }
}
</style>