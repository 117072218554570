<template>
    <div
        class="vacation"
        v-if="accessPermissions.getVacations"
    >
        <add-button
            title="Add Vacation"
            @clicked="showPopup"
            v-if="accessPermissions.createVacation"
        />
        <grid
            title="Vacations"
            :gridData="vacations"
            :columns="columns()"
            identificator="VacationID"
            :passedPageSize="gridParams.pageSize"
            :dataCount="gridParams.totalCount"
            :canDelete="accessPermissions.createVacation"
            :canEdit="accessPermissions.createVacation"
            @trClicked="trClickHandler"
            @rowEdit="editRow"
            @rowDelete="deletePopupVisible = true"
            :gridStyle="{ borderRadios: 0, border: 0 }"
        />
        <overlay
            :visible="popupVisible"
            @close="popupVisible = false"
        >
            <popup
                title="Add Employee"
                @close="popupVisible = false"
            >
                <form
                    class="add-form"
                    @submit.prevent="AddVacation"
                >
                    <custom-select
                        v-if="!person"
                        title="Select Person"
                        v-model="newVacation.Person"
                        :options="personList"
                        @clear="newVacation.Person = ''"
                    />
                    <custom-select
                        title="Select Vacation type"
                        v-model="newVacation.VacationType"
                        :options="vacationTypes"
                        @clear="newVacation.VacationType = ''"
                    />
                    <date-picker
                        title="From Date"
                        v-model="newVacation.FromDate"
                        @clear="newVacation.FromDate = ''"
                    />
                    <date-picker
                        title="To Date (including)"
                        v-model="newVacation.ToDate"
                        @clear="newVacation.ToDate = ''"
                    />
                    <button class="btn">Save</button>
                </form>
            </popup>
        </overlay>
        <delete-item
            @delete="deleteRow"
            @close="deletePopupVisible = false"
            v-if="deletePopupVisible"
            text="Are you sure that you want delete this Vacation?"
        />
        <snackbar
            v-if="snackbarVisible"
            :purpose="snackbarPurpose"
            :text="snackbarText"
            @close="snackbarVisible = false"
        />
    </div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance'
import Grid from '@/components/ReusableComponents/Grid/Grid'
import AddButton from '@/components/ReusableComponents/AddButton/AddButton'
import DeleteItem from '@/components/ReusableComponents/DeleteItem/DeleteItem'
import Snackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar'
import Popup from '@/components/ReusableComponents/Popup/Popup'
import Overlay from '@/components/ReusableComponents/Overlay/Overlay'
import CustomSelect from '@/components/ReusableComponents/CustomSelect/CustomSelect'
import DatePicker from '@/components/ReusableComponents/DatePicker/DatePicker'
import {
    timestampToDateTime,
    checkPermission,
    getProperDateForBack,
} from '@/Helpers/HelperFunctions'
export default {
    created() {
        this.getVacationTypes()
        this.getVacations()
    },
    components: {
        Grid,
        AddButton,
        DeleteItem,
        Snackbar,
        Popup,
        Overlay,
        CustomSelect,
        DatePicker,
    },
    props: {
        person: Object,
    },
    data() {
        return {
            accessPermissions: {
                createVacation: checkPermission('CreateVacation'),
                getVacations: checkPermission('GetVacations'),
            },
            vacations: [],
            vacationTypes: [],
            selectedVacation: '',
            deletePopupVisible: false,
            snackbarPurpose: 'error',
            snackbarText: '',
            snackbarVisible: false,
            popupVisible: false,
            newVacation: {
                Person: '',
                VacationType: '',
                FromDate: 0,
                ToDate: 0,
            },
            personList: [],
            gridParams: {
                currentPage: 1,
                pageSize: 9,
                orderByColumn: 'ID',
                ascending: true,
                totalCount: 0,
            },
        }
    },
    methods: {
        showPopup() {
            if (!this.personList.length) {
                this.getPersonList()
            }
            this.popupVisible = true
        },
        getVacations() {
            axios
                .get('vacations/Vacations', {
                    params: {
                        PersonID: this.person ? this.person.id : null,
                        Page: this.gridParams.currentPage,
                        PageSize: this.gridParams.pageSize,
                        OrderByColumn: this.gridParams.orderByColumn,
                        Ascending: this.gridParams.ascending,
                    },
                })
                .then(response => {
                    this.vacations = response.data.Value
                    this.gridParams.totalCount =
                        response.data.Paging.TotalItemCount
                })
        },
        getVacationTypes() {
            axios.get('vacations/Types').then(response => {
                this.vacationTypes = response.data.Value.map(el => ({
                    value: el.ID,
                    title: el.Name,
                }))
            })
        },
        columns() {
            return [
                {
                    title: 'ID',
                    key: 'VacationID',
                },
                {
                    title: 'Name',
                    key: 'FirstName',
                    customTemplate: (item, val) => val + ' ' + item.LastName,
                },
                {
                    title: 'From Date',
                    key: 'FromDate',
                    customTemplate: (item, val) => timestampToDateTime(val),
                },
                {
                    title: 'To Date (including)',
                    key: 'ToDate',
                    customTemplate: (item, val) =>
                        timestampToDateTime(this.substractDayFromTime(val)),
                    editable: true,
                    editType: 'date',
                },
                {
                    title: 'Vacation Type',
                    key: 'VacationType',
                },
            ]
        },
        deleteRow() {
            this.$confirm('Would you like to delete this vacation?').then(() => {
                axios
                    .post('vacations/UpdateVacation', {
                        VacationID: this.selectedVacation.VacationID,
                        Deleted: true,
                        ToDate: this.selectedVacation.ToDate,
                    })
                    .then(response => {
                        if (response.data.ErrorMsg) {
                            return
                        } else {
                            this.getVacations()
                            this.deletePopupVisible = false
                            this.snackbarPurpose = 'success'
                            this.snackbarText = 'Vacation was successfully deleted'
                            this.snackbarVisible = true
                        }
                    })
            })
        },
        editRow(value) {
            axios
                .post('vacations/UpdateVacation', {
                    VacationID: this.selectedVacation.VacationID,
                    Deleted: false,
                    ToDate: getProperDateForBack(value.ToDate),
                })
                .then(response => {
                    if (response.data.ErrorMsg) {
                        return
                    } else {
                        this.getVacations()
                        this.snackbarPurpose = 'success'
                        this.snackbarText = 'Vacation was successfully edited'
                        this.snackbarVisible = true
                    }
                })
        },
        trClickHandler(element) {
            this.selectedVacation = element
        },
        getPersonList() {
            axios.get('Person/GetPersons').then(response => {
                response.data.Value.forEach(el => {
                    this.personList.push({
                        title: `${el.FirstName} ${el.LastName}`,
                        value: el.ID,
                    })
                })
            })
        },
        substractDayFromTime(time) {
            let nowDate = new Date(time)
            nowDate.setDate(nowDate.getDate() - 1)
            return nowDate
        },
        addDayToTime(time) {
            let nowDate = new Date(time)
            nowDate.setDate(nowDate.getDate() + 1)
            return nowDate
        },
        AddVacation() {
            axios
                .post('vacations/CreateVacation', {
                    Person: this.person
                        ? this.person.id
                        : parseInt(this.newVacation.Person),
                    VacationType: this.newVacation.VacationType,
                    FromDate: getProperDateForBack(this.newVacation.FromDate),
                    ToDate:
                        this.newVacation.ToDate == this.newVacation.FromDate
                            ? getProperDateForBack(
                                  this.addDayToTime(
                                      this.addDayToTime(
                                          this.newVacation.ToDate,
                                      ),
                                  ),
                              )
                            : getProperDateForBack(
                                  this.addDayToTime(this.newVacation.ToDate),
                              ),
                })
                .then(response => {
                    if (response.data.ErrorMsg) {
                        return
                    } else {
                        this.getVacations()
                        this.popupVisible = false
                        this.snackbarPurpose = 'success'
                        this.snackbarText = 'Vacation was successfully added'
                        this.snackbarVisible = true
                    }
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.vacation {
    padding: 30 * $rem;

    .button-container {
        width: 200 * $rem;
        margin-bottom: 15 * $rem;
    }

    form.add-form {
        display: flex;
        align-items: center;

        label,
        .select-wrapper {
            margin-right: 15 * $rem;
        }
    }
}
</style>
