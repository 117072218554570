<template>
      <div class="lang-switcher">
            <ul>
                <li @click="switchLang(lang)" v-for="(lang,ind) in langs" :key="ind">
                    <div :class="['lang-wrapper',{'selected' : lang.selected}]">
                        {{lang.Code}}
                    </div>
                </li>
            </ul>
        </div>
</template>

<script>
export default {
    props:{
        langs: Array,
    },
    data(){
        return{

        }
    },
    methods:{
        switchLang(lang){
            this.$emit('selected',lang)
        }
    }
}
</script>

<style scoped lang="scss">
.lang-switcher{
    position: relative;
    ul{
        list-style: none;
        li{
            display: inline-flex;
            color: rgba($blue-primary, .9);
            margin-left: 0.5625rem;
            font-size: 0.8125rem;
            color: #6956D6;

            > .lang-wrapper{
                width: 1.6875rem;
                height: 1.6875rem;
                @include centerElement;
                border-radius: 50%;
                border: 0.0625rem solid #6956D6;
                font-weight: 400;
                filter: drop-shadow(0rem 0rem 5px rgba(#6956D6, .3));
                transition: ease .2s;
                cursor: pointer;
                user-select: none;

                &.selected{
                    background: #6956D6;
                    color: #fff;
                }
            }

            &:first-child{
                margin-left: 0;
            }
        }
    }
}
</style>