<template>
  <div class="fill-height" id="EventCalendar">
        <div class="calendarTop">
          <div class="calendarTopTitle">
            <div>{{ title }}</div>
            <div class="calendarActions" @click="prev">
              <svg class="changerIcon" width="6.959" height="9.764" viewBox="0 0 6.959 9.764">
                <path id="Path_2413" data-name="Path 2413" d="M-371.868-265.9l4.065-5.327a.816.816,0,0,0-.648-1.311h-8.13a.816.816,0,0,0-.648,1.311l4.065,5.327A.816.816,0,0,0-371.868-265.9Z" transform="translate(-265.576 377.399) rotate(90)" fill="#6956d6"/>
              </svg>
            </div>
            <div class="calendarActions" @click="next">
              <svg class="changerIcon" width="6.959" height="9.764" viewBox="0 0 6.959 9.764">
                <path id="Path_2414" data-name="Path 2414" d="M-371.868-265.9l4.065-5.327a.816.816,0,0,0-.648-1.311h-8.13a.816.816,0,0,0-.648,1.311l4.065,5.327A.816.816,0,0,0-371.868-265.9Z" transform="translate(272.535 -367.635) rotate(-90)" fill="#6956d6"/>
              </svg>

            </div>
          </div>  
          <!-- end calendarTopTitle -->   
          <div class="calendarHeader">          
            <ul class="calendarChangerList">
              <li v-bind:class="{ 'activeMenu' : this.type === 'day'}" @click="type = 'day'">DAY</li>
              <li v-bind:class="{ 'activeMenu' : this.type === 'week'}" @click="type = 'week'">WEEK</li>
              <li v-bind:class="{ 'activeMenu' : this.type === 'month'}" @click="type = 'month'">MONTH</li>
            </ul>
            <!-- end calendarChangerList -->
            <div class="calendarFilter">
              <div class="authorFilter" :class="{ 'authorFilterActive': authorFilter }" @click="authorFilterFunction()">
                Author <span>{{this.authorFilterCount}}</span>
              </div>
              <div class="attendingFilter" :class="{ 'attendingFilterActive': attendingFilter }" @click="attendingFilterFunction()">
                Attending <span>{{this.attendingFilterCount}}</span>
              </div>
              <div class="filterDiv">
                <custom-select
                    title="Meeting Room"
                    :options="appointmentLocationTypes"    
                    v-model="appLocationFilter" 
                    :clearVisible="true" 
                />  
              </div>
              <div class="filterDiv">
                <choose-persons
                  :allPersons="allPersonsCustom"
                  @person-clicked="personClickHanlder"
                  v-model="appOrganizerFilter"
                  :isOpened="true"                  
                  title="Organizer"
                />
              </div>
              <!-- <div class="filterDiv">
                <custom-select
                    title="Appointment Type"
                    :options="appointmentTypes"   
                    v-model="appAppointmentTypeFilter"   
                    :clearVisible="true"  
                />
              </div> -->
              <div class="filterDiv app-search-content">
                <input type="text" v-model="searchAppFilter" placeholder="Search" />
                <img class="search-icon" src="@/assets/icons/searchAppIcon.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!-- end calendarTop -->

         <div v-if="dialog">
            <custom-popup  :visible="dialog" @close="dialog = false, slideForm = false">
            <form class="createEventForm" @submit.prevent="addEvent">
              <div class="wrapper">
             <div class="meeting-rooms-content" :class="{applied: slideForm}">
                <div class="header">
                  <span>Show Availabe Meeting Rooms </span>
                </div>
                <div class="filters">
                <div class="filter-wrapper">
                   <CalendarTimePicker @time-selected="(time) => roomsFilter.date = time"/>
               </div>
                  <div class="filter-wrapper">
                   
                   <custom-select
                     title="Select Meeting Room"
                     :options="appointmentLocationTypes"                    
                     :disabled="!applyMeetingRoom"
                     v-model="roomsFilter.room"  
                     :clearVisible="true"
                  />  
                  </div>
                  <div class="filter-wrapper">
                    <div class="time-tags">
                     <div class="tag" :class="{selected:item.selected}" @click="minTimeHandler(item, index)" v-for="(item, index) in minTimes" :key="index">
                       <span>{{item.text}}</span>
                     </div>
                    
                    </div>
                  </div>
                </div>
                  <div class="free-rooms-content">
                   <div class="room" v-for="room in freeRooms" :key="room.MeetingRoomID">
                     <div class="room-name-container">
                       <span class="room-name">{{room.MeetingRoomName}}</span>
                     </div>
                     <div class="free-slots-content">
                       <div v-for="slot in room.FreeSlots" :class="{selected: slot.selected}" :key="slot.index" @click="slotClickHandler(room, slot)"  class="slot" >
                         <div class="start-end">
                          <span>{{getTimeString(slot.StartTime)}}</span>
                          <span>-</span>
                          <span>{{getTimeString(slot.EndTime)}}</span>
                         </div>
                          <span class="duration">
                            
                            {{msToTime(slot.EndTime- slot.StartTime)}}
                          </span>
                       </div>

                     </div>
                   </div>
                 
                  </div>
                <div class="apply-button" @click="slideForm = true">
                  <span>Apply</span>
                </div>
              </div>
              <div class="forms-content" :class="{applied: slideForm}" > 
              <div class="box_title">
                <span v-if="showBackIcon" @click="slideForm = false" class="material-icons-outlined">
                   keyboard_backspace
                 </span>
                <span> Add Appointment</span>
               </div>
              <div class="choose_field">
                <custom-select
                    title="Appointment Type"
                    :options="appointmentTypes"                                  
                    :isRequired="true"
                    v-model="appAppointmentTypeValue"    
                    :clearVisible="true" 
                />
              </div>
              <div class="choose_field appMeetingTypeValue" v-if="showMetingTypes">     
                <custom-select
                    title="Meeting Type"
                    :options="meetingTypes"                                  
                    :isRequired="true"
                    v-model="appMeetingTypeValue" 
                    :clearVisible="true"    
                />
              </div>
              <div class="input_text">
                 <custom-input                    
                    title="Title..."
                    @clear="''"
                    :isRequired="true"
                    v-model="appTitleValue"
                 />
              </div>
              <div class="choose_time" :class="{ 'require_select': appDateTimeActive }">               
                <div class="choose_datepicker" v-on:click.prevent.self>
                  <date-picker
                  v-if="slotClicked"
                   ref="datePicker"                    
                   :dateInput="dateInput" 
                   :showHelperButtons="showHelperButtons"
                   :switchButtonLabel="switchButtonLabel"
                   :isMondayFirst="isMondayFirst"
                   @date-applied="dateApplied"
                   @select-date="selectDate"
                   :required="true"
                   :initialDates="defoultDates"
                   :calendarTimeInput="calendarTimeInput"
                   v-model="appDateValue"
                  /> 
                </div>
                <div class="choose_timeZone appTimeZoneValue" v-if="hideAddFields">
                  <custom-select
                    title="Time zone"
                    :options="appointmentTimeZoneTypes"                    
                    :isRequired="true"
                    v-model="appTimeZoneValue"  
                    :clearVisible="true"
                />
                </div>
              </div>
              <div class="choose_field" v-if="hideAddFields">
                 <custom-select
                    title="Repeat"
                    :options="appointmentPeriodTypes"   
                    v-model="appRepeatValue"  
                    :clearVisible="true"
                />
              </div>
              <div class="choose_address" v-if="hideAddFields">
                <div class="input_text appLocationValue">
                  <custom-select
                     title="Location"
                     :options="appointmentLocationTypes"                    
                     :isRequired="applyMeetingRoom"
                     :disabled="!applyMeetingRoom"
                     v-model="appLocationValue"  
                     :clearVisible="true"
                  />  
                </div>
                <div class="or">or</div>
                <div class="input_text">
                  <custom-input                    
                     title="Enter address"
                     :isRequired="!applyMeetingRoom"
                     :triggerChangeOnBlur="false"
                     v-model="appEnterAddres"
                  />
                </div>
              </div>
              <div class="choose_field" v-if="hideAddFields">
                <custom-select
                    title="Set Alarm"
                    :options="appointmentSetAlarmTypes" 
                    v-model="appSetAlarmValue" 
                    :clearVisible="true"
                />  
              </div>
                <div class="invite_choose" v-if="hideAddFields">
                <select-persons
                  :inviteTitle="true"
                  :initialSelectedPersons="selectedPersons"
                  :departments="departmentPeople"
                  @select="(people) => (selectedPersons = people)"
                  ref="SelectPersons"
                />
              </div>                      
              <div class="invite" v-if="selectedPersons != ''">
                <selected-permission
                  v-for="selected in selectedPersonsData"
                  :key="selected.index"
                  :name="selected.Mail"
                  @click.native="remove(selected.ID)" />
              </div>

                <div class="invite_external_choose" v-if="hideAddFields">
                  <custom-input                    
                     title="External guests"
                     v-model="externalEmail"
                  />
                  <span class="material-icons success add_external_email" @click="(addExternalEmail())">check</span>
              </div>                      
              <div class="invite" v-if="hideAddFields">
                <selected-permission
                  v-for="(selected, index) in externalEmailArray"
                  :key="selected"
                  :name="selected"
                  :extraClass="!create ? 'noDelete' : ''"
                  @click.native="removeExternal(index)" />
              </div>

              <div class="textarea_field" v-if="hideAddFields">
                <div class="description" v-if="!textArea" @click.stop="textArea = true">Description<div></div></div>
                <custom-text-area v-if="textArea"
                  title="Description"
                  :rows="5"
                  v-model="appDescriptionValue"
                />
              </div>
              <div class="choose_field appOrganizerValue" v-if="hideAddFields">
                 <search-input
                    :options="allPersonsCustom"
                    title="Organizer"
                    :isRequired="true"
                    @clear="appOrganizerValue=''"
                    v-model="appOrganizerValue"
                />
              </div>    
              <div class="file_visibility" v-if="hideAddFields">
                 <div class="choose_files">
                   <div class="choose_files_title">Attach Files</div>                    
                   <div class="attachedFiles" v-if="edit">
                    <div class="viewContetnDataValue" v-for="el in selectedEvent.Attachments" :key="el.FileID">
                      <div v-if="el.FileName" :type="el.FileName.split('.').pop()" class="uploaded-logo" @click="downloadFile(el)">
                         <img src="@/assets/delete_attached.svg" alt="upgaming"  @click.stop="deleteAttachedImage(el)">        
                         <span>{{el.FileName}}</span>
                      </div>
                    </div>
                   </div>
                   <VueFileAgent v-if="this.maxFiles > 0"
                        ref="vueFileAgent"
                        :theme="'default'"
                        :multiple="true"
                        :deletable="true"
                        :meta="true"
                        :accept="'image/*,.zip,.pdf,.docx,.doc,.xlsx,.xls,.pptx,.ppt,.mp4'"
                        :maxSize="'5MB'"
                        :maxFiles="this.maxFiles"
                        :helpText="' '"
                        :errorText="{
                          type: 'Invalid file type. Only images or zip Allowed',
                          size: 'Files should not exceed 5MB in size',
                        }"
                        @select="filesSelected($event)"
                        @beforedelete="onBeforeDelete($event)"
                        @delete="fileDeleted($event)"
                        v-model="appFileListValue">
                    </VueFileAgent>
                 </div>
                 <div class="visible">                   
                    <div class="visible_title">Visibility</div>
                    <div class="visibleLabel">
                      <label class="visibleRadio public">
                        <input type="radio" id="public" value="1" :checked="isChecked" v-model="appVisivleValue">
                        <span>Public</span>
                      </label>
                      <label class="visibleRadio private">
                        <input type="radio" id="private" value="2" :checked="isChecked" v-model="appVisivleValue">
                        <span>Private</span>
                      </label>
                    </div>
                 </div>
              </div>
              <div class="save">
                <button class="button">Save</button>
              </div>

              </div>
                </div>              
           
            </form>
          </custom-popup>   
         </div>

<v-sheet height="90%">
  <v-calendar
  ref="calendar"
  v-model="focus"
  color="primary"
  class="calendarContainer"
  :events="events"
  :key="CalendarcomponentKey"
  :event-color="getEventColor"
  :event-margin-bottom="3"
  :event-overlap-mode="mode"
  :now="today"
  :type="type"
  :weekdays="[1, 2, 3, 4, 5, 6, 0]"
  :interval-format="intervalFormat"
  :short-weekdays="false"
  @click:event="showEvent"
  @click:more="viewDay"
  @click:date="setDialogDate"
  @click:time="setDialogDate"
  @change="getEvents"  
  :event-ripple="false"
  >
  <template #event="{ event, timed }">
    <div class="pl-1" 
      v-html="getEventHTML(event, timed)"
    ></div>
  </template>
  <template #day-body="{ date, week }">
    <div class="v-current-time"
        :class="{ first: date === week[0].date }"
        :style="{ top: nowY }">
      <div class="v-current-time-time">
        {{ nowTime }}
      </div>
    </div>
  </template>
  &nbsp;
  </v-calendar>

  <v-menu v-if="!this.bdayEvent" v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement">
    <div class="viewContetn">
      <div class="viewContetnHeader">
        <div class="viewContetnHeaderTitle" v-if="selectedEvent.Title">{{selectedEvent.Title}}</div>
        <div class="viewContetnHeaderAction" v-if="selectedEvent.CreatorID == this.$store.state.profile.ID || selectedEvent.AuthorID == this.$store.state.profile.ID"> 
          <img src="@/assets/eventCalendarEdit.svg" @click.prevent="editEvent(selectedEvent)" alt="Calendar" />
          <img src="@/assets/eventCalendarDelete.svg" @click="deleteEvent(selectedEvent.AppointmentID)" alt="Calendar" />
        </div>
      </div>
      <div class="viewContetnDate">
        <div class="viewContetnDateTitle">
           {{ selectedEvent.DateTime }} {{ selectedEvent.IsPrivate ? '' : selectedEvent.City }}
        </div>
        <div class="viewContetnMeetingType" v-if="selectedEvent.AppointmentTypesType && !selectedEvent.IsPrivate">{{ selectedEvent.AppointmentTypesType }}</div>
      </div>
      <div class="viewContetnData viewContetnDataFirst" v-if="selectedEvent.PersonContacts && !selectedEvent.IsPrivate">
        <div class="viewContetnDataTitle">Employees</div>
        <div class="viewContetnDataValue showMoreGuests showMoreGuests color">{{ selectedEvent.PersonContacts }}</div>       
      </div>

      <div class="viewContetnData viewContetnDataFirst" v-if="selectedEvent.ExternalMailStr && !selectedEvent.IsPrivate">
        <div class="viewContetnDataTitle">Guests</div>
        <div class="viewContetnDataValue showMoreGuests showMoreGuests color">{{ selectedEvent.ExternalMailStr }}</div>       
      </div>

      
      <div class="viewContetnData" v-if="selectedEvent.AuthorFirstName">
        <div class="viewContetnDataTitle">Organizer</div>
        <div class="viewContetnDataValue color">{{ selectedEvent.AuthorFirstName }} {{ selectedEvent.AuthorLastName }}</div>
      </div>      
      <div class="viewContetnData" v-if="selectedEvent.MeetingRoomName">
        <div class="viewContetnDataTitle">Location</div>
        <div class="viewContetnDataValue">{{ selectedEvent.MeetingRoomName }}</div>
      </div>
      <div class="viewContetnData" v-if="selectedEvent.PeriodType && !selectedEvent.IsPrivate">
        <div class="viewContetnDataTitle">Repeat</div>
        <div class="viewContetnDataValue">{{ selectedEvent.PeriodType }}</div>
      </div>
      <div class="viewContetnData" v-if="selectedEvent.RemainderType && !selectedEvent.IsPrivate">
        <div class="viewContetnDataTitle">Alarm</div>
        <div class="viewContetnDataValue">{{ selectedEvent.RemainderType }}</div>
      </div>
      <div class="viewContetnData" v-if="selectedEvent.Description && !selectedEvent.IsPrivate">
        <div class="viewContetnDataTitle">Description</div>
        <div class="viewContetnDataValue">{{ selectedEvent.Description }}</div>
      </div>
      <div class="viewContetnData" v-if="selectedEvent.Attachments && selectedEvent.Attachments.length > 0 && !selectedEvent.IsPrivate">
        <div class="viewContetnDataTitle">Attached</div>
        <div class="viewContetnDataValue" v-for="el in selectedEvent.Attachments" :key="el.FileID">
          <div v-if="el.FileName" :type="el.FileName.split('.').pop()" class="uploaded-logo" @click="downloadFile(el)">       
            <span>{{el.FileName}}</span>
          </div>
        </div>
      </div>
      <div class="cancelButton" @click="cancelButtonClick(selectedEvent.AppointmentID)" 
        v-if="(selectedEvent.CreatorID == this.$store.state.profile.ID  || selectedEvent.AuthorID == this.$store.state.profile.ID) && selectedEvent.Status">Cancel</div>
    </div>

</v-menu> 
</v-sheet>
  <div class="createEvent" @click="addButtonClick()">+</div>
  <custom-snackbar
    v-if="snackbarVisible"
    :purpose="snackbarPurpose"
    :text="snackbarText"
    @close="snackbarVisible = false"
  />
</div>
</template>

<script>
import CustomTextArea from "@/components/ReusableComponents/CustomTextArea/CustomTextArea.vue";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue"
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput.vue";
import axios from "@/Helpers/AxiosInstance.js";
import { CETToTimestamp } from "@/Helpers/HelperFunctions.js";
import SelectPersons from "@/components/FixedSchedules/SelectPersons.vue";
import DatePicker from 'vue-time-date-range-picker/dist/vdprDatePicker';
import { toBase64 } from "@/Helpers/HelperFunctions";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";
import SearchInput from '@/components/ReusableComponents/SearchInput/SearchInput.vue'
import choosePersons from "@/components/ReusableComponents/ChoosePersons/choosePersons.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import SelectedPermission from "@/components/Permissions/SelectedPermission"
import CalendarTimePicker from "./CalendarTimePicker.vue";
import { getDateString } from "@/Helpers/HelperFunctions";


export default {
  components: {
    CustomTextArea,
    CustomSelect,
    CustomInput,
    SelectPersons,
    DatePicker,
    CustomPopup,
    SearchInput,
    SelectedPermission,
    choosePersons,
    CustomSnackbar,
    CalendarTimePicker
  },
 async created() {
    this.getPersons()
    await this.getAppointmentTypes()
    await this.GetAppointmentPeriodTypes()
    await this.getAppointmentRemainderTypes()
    await this.getAppointmentLocationTypes()
    await this.getAppointmentSetAlarmTypes()
    await this.getAppointmentTimeZoneTypes()
    this.getDepartmentJobs()
   
  },
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    "modelValue": { default: "" },
    "value": { type: String, default: undefined },
    limitPrompt: {
      type: Function,
      default: (limit) => `Upload max of ${limit} files`,
    },
  },
  watch: {
     selectedPersons: function () {
       this.getSelectedPersons(this.selectedPersons, this.allPersons);
     },
     appAppointmentTypeValue (to, from) {
       this.showMetingTypes = false
       this.hideAddFields = true
       if (this.resetMetingTypes) {this.appMeetingTypeValue = ''}
       const options = this.appointmentTypes.find( ({ value }) => value === to)
        if(options && options.HasChild == true) {
          this.showMetingTypes = true
          this.getMeetingTypes(to)
        }
        if(options && options.CanFullyUpdate == false) {
          this.hideAddFields = false
          this.getMeetingTypes(to)
          document.querySelectorAll(".vdpr-datepicker__calendar-input-wrapper")[3].style.display = 'none'
          document.querySelectorAll(".vdpr-datepicker__calendar-input-wrapper")[4].style.display = 'none'
       
        }
     },
     appEnterAddres (val) {
       this.applyMeetingRoom = false
       this.appLocationValue = ''
       if (val === '') {
         this.applyMeetingRoom = true
       }
     },
     appLocationFilter (to, from) {
       this.appLocationFilter = to
       this.getEvents(this)
     },
     appOrganizerFilter (to, from) {
       this.appOrganizerFilter = to
       this.getEvents(this)
     },
     appAppointmentTypeFilter (to, from) {
       this.appAppointmentTypeFilter = to
       this.getEvents(this)
     },
     searchAppFilter (to, from) {
       this.searchAppFilter = to
       this.getEvents(this)
     },
     roomsFilter:{
       handler(){
          this.getFreeRooms()
       },
       deep: true
     }

  },
  data: () => ({    
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    type: 'week',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'day',
    },
    applyTime: "",
    slideForm: false,   
    mode: 'stack',
    name: null,  
    id: null,
    details: null,
    start: null,
    end: null,
    color: '#1976D2', // default event color
    currentlyEditing: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    dialog: false,
    dialogDate: false,  
    textArea: false,    
    slotClicked: true,
    bdayEvent: true,
    create:false,
    edit:false,
    CalendarcomponentKey: 0,
    applyMeetingRoom: true,
    roomsFilter:{
      date: null,
      room: null,
      duration: null
    },
    snackbarPurpose: "error",
    snackbarText: "",
    snackbarVisible: false,    

    dateInput: {
      placeholder: "Date & time",
      inputClass: 'chooseDateRange',
    },
    showHelperButtons: false,
    isMondayFirst: true,
    switchButtonLabel: 'Hide Times',
    defoultDates: [],
    calendarTimeInput: {
        step: 30,
        inputClass: 'timeInputClass',
      },

    appAppointmentTypeValue: '',
    appMeetingTypeValue: '',
    appTitleValue: '',
    appRepeatValue: '',
    appSetAlarmValue: '',
    appOrganizerValue: '',
    appLocationValue: [],
    appEnterAddres: '',
    appFileListValue: [],
    appVisivleValue: '1',
    appDateValue: [],
    appTimeZoneValue: '',
    appDescriptionValue: '',
    externalEmail: '',
    externalEmailArray: [],
    showBackIcon: false,
    appLocationFilter: '',
    appOrganizerFilter: [],
    appAppointmentTypeFilter: '',
    searchAppFilter: '',
    authorFilterCount: 0,
    attendingFilterCount: 0,

    authorFilter: false,
    attendingFilter: false,

    appDateTimeActive: false,

    dragEvent: null,
    dragStart: null,
    lastEvent: '',
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    isMounted: false,    
    newEvent: [],
    colors: [],
    meetingTypes: [],
    showMetingTypes: false,
    hideAddFields: true,
    resetMetingTypes: true,
    appointmentTypes: [],
    appointmentPeriodTypes: [],
    appointmentLocationTypes: [],
    appointmentSetAlarmTypes: [],
    appointmentRemainderTypes: [], 
    appointmentTimeZoneTypes: [],   
    allPersons: [],
    allPersonsCustom: [],
    departmentPeople: [],
    departments: [],

    selectedPersons: [],
    selectedPersonsData: [],
    freeRooms:[],
    fileRecordsForUpload: [],
    maxFiles: 3,

    minTimes:[
      {
        text: '15m',
        seconds: 900,
        selected: false, 
      },
      {
        text: '30m',
        seconds: 1800,
        selected: false, 
      },
        {
        text: '1h',
        seconds: 3600,
        selected: false, 
      }, 
       {
        text: '2h',
        seconds: 7200,
        selected: false, 
      }
    ]

  }),
  mounted () {    
    const cal = this.$refs.calendar;    
    window.app = this;
    window.cal = cal;    
    this.isMounted = true;    
    // scroll to the current time
    const minutes = cal.times.now.hour * 60 + cal.times.now.minute;
    const firstTime = Math.max(0, minutes - (minutes % 30) - 30);
    cal.scrollToTime(firstTime);    
    // every minute update the current time bar
    setInterval(() => cal.updateTimes(), 60 * 1000); 
    setTimeout(() => { this.scrollToElement() }, 1000)
    
  },
  computed: {    
    title () {
      const { start, end } = this
      if (!start || !end) {
        return ''
      }
      const startMonth = this.monthFormatter(start)
      const endMonth = this.monthFormatter(end)
      const suffixMonth = startMonth === endMonth ? '' : endMonth
      const startYear = start.year
      const endYear = end.year
      const suffixYear = startYear === endYear ? '' : endYear
      const startDay = start.day + this.nth(start.day)
      const endDay = end.day + this.nth(end.day)

      switch (this.type) {
        case 'month':
            return `${startMonth} ${startYear}`
        case 'week':
        case '4day':
            return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
        case 'day':
            return `${startMonth} ${startDay} ${startYear}`
      }
      return ''
    },
    monthFormatter () {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC', month: 'long',
      })
    },
    nowY() {
      const cal = this.$refs.calendar;
      if (!cal && !this.isMounted) {
        return -1;
      }
      
      return cal.timeToY(cal.times.now) + 'px';
    },
    nowTime() {
      const cal = this.$refs.calendar;
      if (!cal && !this.isMounted) {
        return -1;
      }
      
      return cal.formatTime(cal.times.now);
    },
    isChecked() {
      return this.modelValue == this.value
    },
  },
  methods: {
    minTimeHandler(item, index){
      // this.minTimes.forEach((el, ind) => {
      //   if(index === ind){
      //     item.selected = !item.selected
      //   }else{
      //     el.selected = false
      //   }
      // })
      this.minTimes.forEach((el, ind) => {
        if(ind === index){
          el.selected = !el.selected
          if(!el.selected){
            this.roomsFilter.duration = null
          }else{
            this.roomsFilter.duration = item.seconds
          }
        }else{
          el.selected = false
        }
      })

   
   
    },
    slotClickHandler(room, slot){
      this.slotClicked = false
     
      this.freeRooms.forEach(el => {
        el.FreeSlots.forEach(item => {
          this.$set(item, 'selected', false)
        })
      })
      this.$set(slot, 'selected', true)
      this.appLocationValue = room.MeetingRoomID
      this.appDateValue.StartTime = slot.StartTime 
       this.appDateValue = {
            "StartTime": slot.StartTime,
            "EndTime": slot.EndTime,
     }
      let start = new Date(this.toTimestamp(new Date(slot.StartTime)))
      let end = new Date(this.toTimestamp(new Date(slot.EndTime)))   
      
       this.defoultDates = [start, end]
       setTimeout(() => {
         
         this.slotClicked = true
       }, 300);
      
       
    },
    personClickHanlder(el) {
      el.selected = !el.selected;
      const exist = this.appOrganizerFilter.includes(el.value);
      if (exist) {
        this.appOrganizerFilter = this.appOrganizerFilter.filter(
          (element) => element != el.value
        );
      } else {
        this.appOrganizerFilter.push(el.value);
      }
    },
    getSelectedPersons(ids, persons) {
      let parsedids = [];
      ids.forEach((id) => {
        parsedids.push(parseInt(id));
      });
      let arr = persons.filter(function (person) {
        return parsedids.indexOf(person.ID) !== -1;
      });
      this.selectedPersonsData = arr;
    },
    remove(person) {
      this.selectedPersons = this.selectedPersons.filter((el) => el != person);
      this.selectedPersonsData = this.selectedPersonsData.filter(
        (e) => e.ID != person
      );
    },
    addButtonClick () { 
      let defoultStart = new Date()
      let defoultEnd = new Date(new Date().toDateString())
      defoultEnd.setHours('23')
      defoultEnd.setMinutes('59') 
      this.appAppointmentTypeValue = ''
      this.appMeetingTypeValue = ''
      this.showBackIcon = true
      this.appTitleValue = ''
      this.appDateValue = []      
      this.defoultDates = [defoultStart, defoultEnd]      
      this.appTimeZoneValue = 1,
      this.appRepeatValue = ''
      this.appLocationValue = ''
      this.appEnterAddres = ''
      this.appSetAlarmValue = ''
      this.appOrganizerValue = ""
      this.appOrganizerName = ""
      this.selectedPersons = []
      this.externalEmail = ''
      this.externalEmailArray = []
      this.appDescriptionValue = ''
      this.appFileListValue = [] 
      this.appDateTimeActive = false
      this.maxFiles = 3
      this.edit = false
      this.create = true
      this.dialog = true
      this.getFreeRooms()
    },
    getTimeString(date){
     return getDateString(date, 'HH:mm')
    },
  msToTime(duration) {
    var milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours =  hours + 'h'
  minutes = (minutes < 10) ? "0" + minutes + 'm' : minutes + 'm';


  return hours + " " + minutes 
},
    getPersons() {
      axios.get("/Person/GetPersons").then((resp) => {
        this.allPersons = resp.data.Value.Persons;
        this.allPersonsCustom = resp.data.Value.Persons.map((el) => ({
          title: el.FirstName + " " + el.LastName,
          value: el.ID,
          selected: el.selected,
        }));
      });
    },       
    async getAppointmentTypes() {
      await axios.get("/appointment/GetAppointmentTypes").then((resp) => {
        this.appointmentTypes = resp.data.Value.map((el) => ({
          value:  el.ID,
          title: el.Name,
          HasChild: el.HasChild,
          CanFullyUpdate: el.CanFullyUpdate,
        }));
      });
    },
    async getMeetingTypes(id) {     
      await axios.get(`/appointment/GetAppointmentTypesTypes?AppointmentType=${id}`).then((resp) => {
        this.meetingTypes = resp.data.Value.map((el) => ({
          value:  el.AppointmentTypeType,
          title: el.AppointmentTypeTypeName,
        }));
      });  
    },
    async GetAppointmentPeriodTypes() {
        await axios.get("/appointment/GetAppointmentPeriodTypes").then((resp) => {
          this.appointmentPeriodTypes = resp.data.Value.map((el) => ({
            value:  el.ID,
            title: el.Name,
          }));
        });
    },
    async getAppointmentLocationTypes() {
        await axios.get("/appointment/GetAppointmentMeetingRooms").then((resp) => {
          this.appointmentLocationTypes = resp.data.Value.map((el) => ({
            value:  el.MeetingRoomID,
            title: el.MeetingRoomName,
          }));
        });
    },
    async getAppointmentSetAlarmTypes() {
        await axios.get("/appointment/GetAppointmentRemainderTypes").then((resp) => {
          this.appointmentSetAlarmTypes = resp.data.Value.map((el) => ({
            value:  el.ID,
            title: el.Name,
          }));
        });
    },
    async getAppointmentRemainderTypes() {
        await axios.get("/appointment/GetAppointmentRemainderTypes").then((resp) => {
          this.appointmentRemainderTypes = resp.data.Value.map((el) => ({
            value:  el.ID,
            title: el.Name,
          }));
        });
    },
    async getAppointmentTimeZoneTypes() {
        await axios.get("/Company/GetCities").then((resp) => {
          this.appointmentTimeZoneTypes = resp.data.Value.map((el) => ({
            value:  el.ID,
            title: el.Name,
          }));
        });
    },
    getEvents ({ start, end }) {

        let birthDayObj = []
        let eventDayObj = []
        let appOrganizerFilterObj = []
        this.authorFilterCount = 0
        this.attendingFilterCount = 0
        this.start = start
        this.end = end
        const userID = this.$store.state.profile.ID 
        if(this.appOrganizerFilter.length > 0) {
           appOrganizerFilterObj = this.appOrganizerFilter.map((el) => ({
            personID:  el,
          }));
        } 
        let GetPostData = {
          FromDate: CETToTimestamp(start.date),
          ToDate: CETToTimestamp(end.date),
          ClientID: userID,
          MeetingRoom: this.appLocationFilter,
          AppointmentType: this.appAppointmentTypeFilter,
          SearchTerm: this.searchAppFilter,
          AuthorsTVP: appOrganizerFilterObj,
        }  
        if(this.authorFilter) {
          let authorFilterValue = {            
            AuthorsTVP: [{personID: this.$store.state.profile.ID}],
          }
          GetPostData = {...GetPostData, ...authorFilterValue }
        }

        if(this.attendingFilter) {
          let attendingFilterValue = {
            Person: this.$store.state.profile.ID,
          }
          GetPostData = {...GetPostData, ...attendingFilterValue }
        }

        axios.post(`/appointment/GetPersonsAppointments`, GetPostData)        
        .then((resp) => {
           if(resp.data.Value.Appointments.length) {
              this.authorFilterCount = resp.data.Value.Appointments[0].ClientAuthorCount
              this.attendingFilterCount = resp.data.Value.Appointments[0].PersonInCount
           }
           eventDayObj = resp.data.Value.Appointments.map((el) => ({
             id:  el.AppointmentID,
             name: this.getHoutMinuteFormat(el.StartTime, 'Etc/GMT') +'&nbsp;&nbsp; '+ el.Title,
             details: el.Description,
             start: new Date(el.StartTime).toISOString().slice(0, 19).replace('T', ' '),
             end: new Date(el.EndTime).toISOString().slice(0, 19).replace('T', ' '),
             color: el.AppointmentTypeColor,
             author: el.AuthorID,
             status: el.status,
           }));
           birthDayObj = resp.data.Value.Bdays.map((el) => ({
             id:  el.PersonID,
             name: el.FirstName,
             details: '',
             start: new Date(el.BirthDate).toISOString().slice(0, 19).replace('T', ' '),
             end: new Date(el.BirthDate).toISOString().slice(0, 19).replace('T', ' '),
             color: '#1DDFAF',
             bday: true,
           }));
           this.events = [ ...eventDayObj, ...birthDayObj ]; 
        });          
    },
    getEventHTML(event, timed) {  
      const cal = this.$refs.calendar;
      let name = event.name;
      if (event.start.hasTime) {
        if (timed) {
          const showStart = event.start.hour < 12 && event.end.hour >= 12;
          const start = cal.formatTime(event.start, showStart);
          const end = cal.formatTime(event.end, true);
          const singline = false;
          const separator = singline ? ', ' : '<br>'
          return `<strong>${name}</strong>${separator}${start} - ${end}`
        } else {
          const time = this.formatTime(event.start, true)
          return `<strong>${time}</strong> ${name}`
        }
      }
      return name;
    },
    setDialogDate(e) {
      const date = this.toDate(e);    
      this.dialogDate = true
      this.focus = date
      this.slideForm = true
      this.showBackIcon = false
      let start = new Date(this.toTimestamp(new Date(date)))
      let end = new Date(this.toTimestamp(new Date(date)))   
      start.setHours(new Date(date).getHours())  
      start.setMinutes(new Date(date).getMinutes())    
      end.setHours('23')
      end.setMinutes('59')  
      this.appAppointmentTypeValue = ''
      this.appMeetingTypeValue = ''
      this.appTitleValue = ''
      this.appDateValue = []
      this.appTimeZoneValue = 1
      this.appRepeatValue = ''
      this.appLocationValue = ''
      this.appEnterAddres = ''
      this.appSetAlarmValue = ''
      this.appOrganizerValue = ""
      this.appOrganizerName = ""
      this.selectedPersons = []
      this.externalEmail = ''
      this.externalEmailArray = []
      this.appDescriptionValue = ''
      this.appFileListValue = []  
      this.defoultDates = [start, end]
      this.appDateTimeActive = false
      this.appDateValue = {
          "StartTime": start,
          "EndTime": end,
      }
      this.edit = false
      this.create = true
      this.dialog = true
      this.lastEvent = 'startTime';
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      const rgb = parseInt(event.color.substring(1), 16);
      const r = (rgb >> 16) & 0xFF;
      const g = (rgb >>  8) & 0xFF;
      const b = (rgb >>  0) & 0xFF;
      
      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.5)`
        : event === this.createEvent
          ? `rgba(${r}, ${g}, ${b}, 0.5)`
          : event.color;
    },
    setToday () {
      this.focus = this.today
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    async addEvent (e) { addEvent:{      
      let AttachFilesArray = []
      let CheckPersonsArray = []     

      if(this.appDateValue.length == 0 && this.defoultDates.length > 0) {
        this.appDateValue = {
            "StartTime": CETToTimestamp(this.defoultDates[0]),
            "EndTime": CETToTimestamp(this.defoultDates[1]),
        }
      }    
      this.appDateTimeActive = false

      if ((!this.appDateValue.EndTime || !this.appDateValue.StartTime) && this.defoultDates.length <= 0) {
         this.appDateTimeActive = true
          break addEvent
      }    

      if(this.appFileListValue.length > 0) {
        for (let i = 0; i < this.appFileListValue.length; i++) {
          if (this.appFileListValue[i]['file']) {
            this.readURL(this.appFileListValue[i]['file'])
            const attachObject = {
              Size: this.appFileListValue[i]['file'].size,
              Type: this.appFileListValue[i]['file'].type,
              Name: this.appFileListValue[i]['file'].name,
              Base64: await toBase64(this.appFileListValue[i]['file']),
            };
            AttachFilesArray.push(attachObject);
          }
        }
      }
      if(this.selectedPersons) {
        CheckPersonsArray = this.selectedPersons.map((el) => ({
          PersonID : el,
        }));
      }  

      if (!Number.isInteger(this.appDateValue.EndTime)) 
         this.appDateValue.EndTime = CETToTimestamp(this.appDateValue.EndTime)

      if (!Number.isInteger(this.appDateValue.StartTime)) 
         this.appDateValue.StartTime = CETToTimestamp(this.appDateValue.StartTime)

      const createFormValues = {
         AppointmentType: this.appAppointmentTypeValue,
         AppointmentTypeType: this.appMeetingTypeValue,
         Title: this.appTitleValue,
         AppointmentDates: this.appDateValue,
         City: this.appTimeZoneValue,
         PeriodType: this.appRepeatValue,
         MeetingRoom: this.appLocationValue,
         EnteredMeetingRoom: this.appEnterAddres,
         ReminderType: this.appSetAlarmValue,
         Author: this.appOrganizerValue,
         Persons: CheckPersonsArray,
         externalMailsList: this.externalEmailArray,
         Description: this.appDescriptionValue,
         Attachments: AttachFilesArray,
         Visibility: this.appVisivleValue,
      }
      
      if(!this.appDateValue) 
        delete createFormValues.AppointmentDates

      if (this.create) {
          await axios.post("/appointment/CreateAppointment", createFormValues)
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.snackbarVisible = true;
              this.slideForm = false
              this.snackbarText = "An appointment has been created successfully!";
              this.snackbarPurpose = "success";
              this.dialog = false 
              this.getEvents(this)
              this.appAppointmentTypeValue = ''
              this.appMeetingTypeValue = ''
              this.appTitleValue = ''
              this.appDateValue = []
              this.defoultDates = []
              this.appTimeZoneValue = ''
              this.appRepeatValue = ''
              this.appLocationValue = ''
              this.appEnterAddres = ''
              this.appSetAlarmValue = ''
              this.appOrganizerValue = ''
              this.selectedPersons = []
              this.externalEmail = ''
              this.externalEmailArray = []
              this.appDescriptionValue = ''
              this.appFileListValue = []
              this.appVisivleValue = 1           
              this.CalendarcomponentKey += 1;
            } else {
              this.snackbarPurpose = "error";
              this.snackbarVisible = true;
              this.snackbarText = resp.data.ErrorMsg;
            }
          })
          .catch(() => {
            this.snackbarPurpose = "error";
            this.snackbarVisible = true;
            this.snackbarText = "An Error Occured While Adding New Appointment";
          })
      }
      else {
          createFormValues.AppointmentID = this.currentlyEditing
          await axios.post(`/appointment/UpdateAppointment`, createFormValues)
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.snackbarVisible = true;
              this.snackbarText = "An appointment has been updated successfully";
              this.snackbarPurpose = "success";
              this.dialog = false 
              this.getEvents(this)
              this.appAppointmentTypeValue = ''
              this.appMeetingTypeValue = ''
              this.appTitleValue = ''
              this.appDateValue = []
              this.defoultDates = []
              this.appTimeZoneValue = ''
              this.appRepeatValue = ''
              this.appLocationValue = ''
              this.appEnterAddres = ''
              this.appSetAlarmValue = ''
              this.appOrganizerValue = ''
              this.selectedPersons = []
              this.externalEmail = ''
              this.externalEmailArray = []
              this.appDescriptionValue = ''
              this.appFileListValue = []
              this.appVisivleValue = 1           
              this.CalendarcomponentKey += 1;
            } else {
              this.snackbarPurpose = "error";
              this.snackbarVisible = true;
              this.snackbarText = resp.data.ErrorMsg;
            }
          })
          .catch(() => {
            this.snackbarPurpose = "error";
            this.snackbarVisible = true;
            this.snackbarText = "An Error Occured While Adding New Appointment!";
          })
      }
     }},
    editEvent (ev) { 
      this.create = false
      this.edit = true
      this.selectedOpen = false
      this.showBackIcon = false
      this.resetMetingTypes = false
      this.slideForm = true
      if(ev.Description) {
        this.textArea = true
      }
      if(ev.Persons.length > 0) {
        ev.Persons.forEach((person) => {
          this.selectedPersons.push(person.PersonID)
        });
      }

      this.appAppointmentTypeValue = ev.AppointmentTypeID
      this.appMeetingTypeValue = ev.AppointmentTypesTypeID
      this.appTitleValue = ev.Title
      let start = new Date(this.toTimestamp(new Date(new Date(ev.DefaultStartTime).toLocaleString('en-EN', {timeZone:'Etc/GMT'}))))
      let end = new Date(this.toTimestamp(new Date(new Date(ev.DefaultEndTime).toLocaleString('en-EN', {timeZone:'Etc/GMT'}))))  
      this.defoultDates = [start, end]   
      this.appTimeZoneValue = ev.CityID
      this.appRepeatValue = ev.PeriodTypeID
      this.appLocationValue = ev.MeetingRoomID
      this.appEnterAddres = ev.EnteredMeetingRoom
      this.appSetAlarmValue = ev.RemainderTypeID
      this.appOrganizerValue = ev.AuthorID   
      this.appOrganizerName = ev.AuthorFirstName+' '+ev.AuthorLastName 
      this.externalEmailArray = ev.ExternalMails
      this.appDescriptionValue = ev.Description
      this.appFileListValue = ev.Attachments
      this.appVisivleValue = ev.VisibilityTypeID
      this.currentlyEditing = ev.AppointmentID  
      this.maxFiles = 3-ev.Attachments.length
      this.appDateTimeActive = false 
      this.dialog = true  
    },
    deleteEvent (ev) {
      this.$confirm("Would you like to remove this event from the list?").then(
        () => {
         const update_object = {
            AppointmentID: ev,
            Delete: true,   
            Attachments: [],
            Persons: [],    
            ExternalMailsList: [],
         }
         axios.post("/appointment/UpdateAppointment", update_object)
            .then((resp) => {
               this.selectedOpen = false,
               this.getEvents(this)
            });
        }
      );
    },
    cancelButtonClick(ev) {
      this.$confirm("Would you like to cancel this event from the list?").then(
        () => {
         const update_object = {
            clientID: this.$store.state.profile.ID,
            id: ev,
            status: false,      
         }
         axios.post("/appointment/UpdateAppointmentStatus", update_object)
            .then((resp) => {
               this.selectedOpen = false,
               this.getEvents(this)
            });
        }
      );
    },
    async showEvent ({ nativeEvent, event }) {
      if(!event.bday) {        
        let selectedObject = []        
        const open = async () => {
          let postData = {
            ID: event.id,
            AuthorsTVP: [],
          }
          selectedObject = await axios.post(`/appointment/GetPersonsAppointments`, postData)
          this.selectedEvent = selectedObject.data.Value.Appointments[0]   
          if(selectedObject.data.Value.Appointments[0].StartTime && selectedObject.data.Value.Appointments[0].EndTime) {
             this.selectedEvent.DefaultStartTime = selectedObject.data.Value.Appointments[0].StartTime
             this.selectedEvent.DefaultEndTime = selectedObject.data.Value.Appointments[0].EndTime
          }
          let startOptions, endOptions;
          startOptions = endOptions = {day: 'numeric', month: 'long', weekday: 'short', hour: '2-digit', minute: '2-digit', hour12: false, timeZone:'Etc/GMT'}
          this.selectedEvent.StartTime = new Date(this.selectedEvent.StartTime).toLocaleTimeString('en-EN', startOptions)
          this.selectedEvent.EndTime = new Date(this.selectedEvent.EndTime).toLocaleDateString('en-EN', endOptions)
          
          if((this.selectedEvent.StartTime === this.selectedEvent.EndTime) || !this.selectedEvent.CanFullyUpdate) {      
             this.selectedEvent.DateTime = this.selectedEvent.StartTime
          } else {
            // if same days, need only hour
            if(new Date(this.selectedEvent.StartTime).getFullYear() === new Date(this.selectedEvent.EndTime).getFullYear() && 
               new Date(this.selectedEvent.StartTime).getMonth() === new Date(this.selectedEvent.EndTime).getMonth() &&
               new Date(this.selectedEvent.StartTime).getDate() === new Date(this.selectedEvent.EndTime).getDate()) {
              this.selectedEvent.EndTime = this.getHoutMinuteFormat(this.selectedEvent.EndTime, '')
            } 
             this.selectedEvent.DateTime = this.selectedEvent.StartTime + ' - ' + this.selectedEvent.EndTime
          }

          this.selectedElement = nativeEvent.target
          setTimeout(() => this.selectedOpen = true, 5)
        }
        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }
        nativeEvent.stopPropagation()
        this.bdayEvent = false       
      }
    },
    convertTZ(date, tzString) {
        return new Date((typeof date === "number" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}))
    },
    getHoutMinuteFormat(time, gmt) {
      let takeTime
      gmt != '' ? takeTime = this.convertTZ(time, gmt) : takeTime = time         
      let currentHour = new Date(takeTime).getHours()
      let currenMinute = new Date(takeTime).getMinutes() < 10 ? '00' : new Date(takeTime).getMinutes()
      return ('0'+currentHour).substr(-2)+':'+currenMinute
    },
    nth (d) {
      return d > 3 && d < 21
      ? 'th'
      : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    },
    intervalFormat(locale, getOptions) {
      return locale.time;
    },
    eventMove(e) {
      console.log('eventMove', e);
    },
    startDrag(e) {
      if (e.event && e.timed) {
        this.dragEvent = e.event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
      this.lastEvent = 'startDrag';
    },
    startTime(e) {
      const mouse = this.toDate(e);
      if (this.dragEvent && this.dragTime === null) {
        const start = this.toDate(this.dragEvent.start);        
        this.dragTime = mouse.getTime() - start.getTime();        
      } else {
        this.createStart = this.roundTime(mouse.getTime());        
        let start = new Date(this.toTimestamp(new Date(this.createStart)))
        let end = new Date(this.toTimestamp(new Date(this.createStart)))          
        
        this.defoultDates = [start, end]
        this.appDateValue = {
            "StartTime": this.createStart,
            "EndTime": this.createStart,
        }
        this.edit = false
        this.create = true
        this.dialog = true
      }
      this.lastEvent = 'startTime';
    },
    mouseMoveEvent(e) {
         console.log('mouseMoveEvent', e);
    },
    mouseMove(e) {
      if (this.dragEvent && this.dragTime !== null) {
        const start = this.toDate(this.dragEvent.start);
        const end = this.toDate(this.dragEvent.end);
        const duration = end.getTime() - start.getTime();
        const mouse = this.toDate(e);
        
        const newStartTime = mouse.getTime() - this.dragTime;
        const newStart = new Date(this.roundTime(newStartTime));
        const newEnd = new Date(newStart.getTime() + duration);
        
        this.dragEvent.start = this.toTimestamp(newStart);
        this.dragEvent.end = this.toTimestamp(newEnd);
      }
      else if (this.createEvent && this.createStart !== null) {
        const mouse = this.toDate(e).getTime();
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, CETToTimestamp(this.createStart));
        const max = Math.max(mouseRounded, CETToTimestamp(this.createStart));

        this.createEvent.start = this.toTimestamp(new Date(min));
        this.createEvent.end = this.toTimestamp(new Date(max));
      }
    },
    roundTime(time, down = true) {
      const roundDownTime = 15 * 60 * 1000; // 15 minutes
      
      return down
        ? time - time % roundDownTime
        : time + (roundDownTime - (time % roundDownTime));
    },
    toDate(tms) {
      return typeof tms === 'string'
        ? new Date(tms)
        : new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute);
    },
    toTimestamp(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
    },
    toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
    },
    rndElement (arr) {
        return arr[this.rnd(0, arr.length - 1)]
    },
    getDepartmentJobs() {
      axios.get("News/GetPlacementDropDownPersons").then((resp) => {
        if (resp.data.ErrorMsg) return;
        this.departmentPeople = resp.data.Value.DepartmentJobPersons.map(
          (dep) => {
            return {
              id: dep.DepartmentID == null ? 0 : dep.DepartmentID,
              name:
                dep.DepartmentName == null
                  ? "Jobs without department"
                  : dep.DepartmentName,
              jobs: dep.JobPersons.map((job) => {
                return {
                  id: job.JobID,
                  name: job.JobName,
                  people: job.Persons.map((person) => {
                    return {
                      id: person.PersonID,
                      name: person.FirstName + " " + person.LastName,
                    };
                  }),
                };
              }),
            };
          }
        );
      });
    },
    authorFilterFunction () {
      this.authorFilter = !this.authorFilter
      this.getEvents(this)
    },
    attendingFilterFunction () {
      this.attendingFilter = !this.attendingFilter
      this.getEvents(this)
    },
    readURL(file) {
      const reader = new FileReader();
      reader.onload = (event) => event.target.result;
      reader.readAsDataURL(file);
    },
    dateApplied(date1, date2) {
      document.querySelectorAll('.timeInputClass')[0].classList.remove("require_field")
      let startTimeLength = document.querySelectorAll('.timeInputClass')[0].value.length
      let endTimeLength = document.querySelectorAll('.timeInputClass')[1].value.length
      if((new Date(document.querySelectorAll('.timeInputClass')[0].value)).getTime() > 0 || startTimeLength < 5) {
        document.querySelectorAll('.timeInputClass')[0].classList.add("require_field") 
        this.$refs.datePicker.showCalendarDialog = true
      }
      if((new Date(document.querySelectorAll('.timeInputClass')[1].value)).getTime() > 0 || endTimeLength < 5) {
        document.querySelectorAll('.timeInputClass')[1].classList.add("require_field")
        this.$refs.datePicker.showCalendarDialog = true
      }      
      this.appDateValue = {
          StartTime: CETToTimestamp(date1),
          EndTime: CETToTimestamp(date2),
      };
      return false;
    },
    selectDate(selectStart, selectEnd) {      
      let newDate = new Date()
      let time = newDate.getHours() + ":" +(newDate.getMinutes()<10?'0':'') + newDate.getMinutes() 
      selectStart.setHours(newDate.getHours())
      selectStart.setMinutes(newDate.getMinutes())
      selectEnd.setHours('23')
      selectEnd.setMinutes('59')       
      setTimeout(() => { document.querySelectorAll('.timeInputClass')[0].value = time }, 100);      
    },
    downloadFile (el) {
      var a = document.createElement("a");
          a.href =
            `data:${el.FileType};base64,` + el.File;
          a.download = el.FileName;
          a.click();
    },
    deleteAttachedImage (el) { 
      let object = {
         "clientID": this.$store.state.profile.ID,
         "appointmentAttachmentID": el.AppointmentAttachmentID,
         "delete": true,
      }     
      
      axios.post(`/appointment/UpdateAppointmentAttachment`, object)        
        .then((resp) => {
             el.FileName = false
             this.maxFiles = this.maxFiles+1
        })
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
      }
    },
    addExternalEmail () {
      if(this.externalEmail !== '') {
        this.externalEmailArray.push(this.externalEmail)
        this.externalEmail = ''
      }
    },
     removeExternal(index) {
       this.externalEmailArray.splice(index, 1)
    },
    scrollToElement() {
      let element = document.getElementsByClassName("v-event-timed")      
      if(element.length > 0) {  
        let scrollElement = document.getElementsByClassName("v-calendar-daily__scroll-area")      
        let top = element[0].offsetTop
        scrollElement[0].scrollTo(0, top)
      }
    },
    getFreeRooms(){
      axios.post('appointment/GetFreeMeetingRooms', {
        date: this.roomsFilter.date,
        meetingRoomID: this.roomsFilter.room,
        minTime: this.roomsFilter.duration
      }).then(resp => {
        this.freeRooms = resp.data.Value.Rooms
      })
    }
  
  
  },
}
</script>

<style scoped lang="scss">
.meeting-rooms-content{
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
    transition:ease-out 0.3s;

   left: 0;
  &.applied{
    left: -100%;
    transition:ease-out 0.3s;
  }
  .header{
    color: #6F787B;
    font-weight: 500;
    margin-bottom: 25px;
    font-size: 18px;
  }
  .free-rooms-content{
    transition:ease-out 0.3s;
   
    margin-top: 50px;
    max-height: 400px; 
    overflow:auto;
    .room{
      display: flex;
      margin-bottom: 30px;

      .room-name-container{
        padding: 5px;
        display: flex;
        flex: 1;

        .room-name{
          color: #6F787B;
          font-size: 14px;
          white-space: nowrap;
        }
      }
      .free-slots-content{
        margin-left: 20px;
        display: flex;
        flex-wrap: wrap;
        flex: 3;

        .slot{
          margin: 5px;
          border: 1px solid #3FC699;
          border-radius: 10px;
          padding: 10px 5px;
          font-size: 12px;
          font-weight: 500;
          color: #45BB94;
          cursor: pointer;
          display: flex;
          background-color: #E2F4EE;
          align-items: center;
          .duration{
            color: #6F787B;
            margin-left: 5px
          }
            &.selected{
               background-color:#45BB94;
               color: #ffffff;
                .duration{
                      color: #ffffff;
                  }
            }
        }
      }
    }
  }
}
.createEventForm{
  height: 100%;
 
  .wrapper{
    height: calc(100%);
    position: relative;
    overflow-x: hidden;
  }
}
.filters{
  display: flex;
  align-items: center;
}
.filter-wrapper{
  margin-right: 10px;
  .select-wrapper{
  width: 250px;
  }
  .time-tags{
    display: flex;
    .tag{
      cursor: pointer;
      margin-right: 5px;
      height: 40px;
      width: 45px;
      border-radius: 10px;
      background-color: #E2F4EE;
      border: 1px solid #3FC699;
      color: #469F81;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
       &.selected{
            background-color:#45BB94;
            color: #ffffff;
        }
    }
  }
}
.forms-content{
  overflow: auto;
  height: 100%;
   position: absolute;
   left: 100%;
   padding-right: 10px;
  &.applied{
   transition:ease-out 0.3s;
   width: 100%;
     left: 0;
  }

}

.fill-height {
  background-color:#EDEDFC;
  padding: 1.25rem 2.8125rem 0 2.8125rem;
  margin-left: 2.375rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  border-radius: 0.625rem;
  position: relative;
}
#EventCalendar .loader {
  background: #000000;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}
#EventCalendar .loader ::v-deep .wrapper {
  position: absolute;
  top: 55%;
  left: 50%;
}
.activeMenu {
    border-bottom:#1de9b6 0.3125rem solid;
    font-weight: 500;
    padding-bottom: 0.3125rem;

}
.calendarTopTitle {
    display: flex;
    justify-content: flex-start;
    font-size: 1.375rem;
    font-weight: 500;
    color: rgba(40, 53, 147, 0.5);
}
.calendarActions {
    margin:-0.125rem 0 0 0.9375rem;
    cursor: pointer;
}
.changerIcon:hover {
  filter: grayscale(100%) sepia(100%);
}
.calendarChangerList {
    display: flex;
    justify-content: flex-start;
    margin-top: 0.9375rem;
}
.calendarChangerList li{
    list-style: none;
    font-size: 0.875rem;
    color: #283593;
    cursor: pointer;
}
.calendarChangerList li:not(:first-child) {
    margin-left: 1.5625rem;
}
.calendarContainer {
    border: none !important;
}
.createEvent {
    width: 4.25rem;
    height: 4.25rem;
    position: absolute !important;
    z-index: 100;
    right: 1.5rem;
    bottom: 1.875rem;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    color: #ffffff;
    font-size: 2.5rem;
    background-color: #6956D6;
    text-align: center;
    padding: 0.625rem;
    cursor: pointer;
    box-shadow: 0 0.1875rem 0.625rem 0 #9696b9;
}
#EventCalendar ::v-deep .overlay .popup h3.title{
  display: none;
}
#EventCalendar ::v-deep .overlay .popup {
  height:36.125rem;
  min-height: 70%;
  box-shadow: 0 0.1875rem 1.25rem 0 rgba(149, 155, 201, 0.5);
  border:none;
  overflow: hidden;
}
.box_title {
    -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
    font-size: 1.125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #283593;
    display: flex;
    align-items: center;
    .material-icons-outlined{
      color: #6F787B;
      margin-right: 10px;
      cursor: pointer;
    }
}
.choose_field, .input_text, .choose_time, .textarea_field, .invite_choose, .invite_external_choose {
  margin-top: 1.25rem;
}
.invite_choose, .invite_external_choose, .select-tree {
  margin-left: 0rem;
  position: relative;
}
.add_external_email {
  position: absolute;
  top: 10px;
  right: 8px;
  font-size: 18px;
  cursor: pointer;
  opacity: .8;
}
.input_text label, .input-search, .input-search::v-deep label, .invite_external_choose label {
  width: 100%;
}
.textarea_field label textarea {
  font-weight: 300;
}
.choose_time, .choose_address {
  display: flex;
  justify-content: space-between;
}
.choose_datepicker {
  position: relative;

}
.eventCalendar .job-person-tree {
  width: 100%;
}
.description, .choose_files_title {
  font-size: 0.875rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(40, 53, 147, 0.7);
  cursor: pointer;
}
.description {
  text-indent: 1rem;
}
.choose_files_title {
  padding-bottom: 0.625rem;
}
.description div {
  height: 0.0625rem;
  margin-top: 0.3187rem;
  background-color: rgba(149, 155, 201, 0.5);
}
.invite {
  max-height: 8.625rem;
  overflow-x: scroll;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: start;    
  flex-wrap: wrap;
  padding: 0.625rem 0.625rem 0 0.625rem;
}
.invite div {
     margin: 0.625rem 0.625rem 0 0;
     padding: 0.4375rem 1.5625rem 0.4375rem 0.9375rem;
     border-radius: 0.9375rem;
     border: solid 0.0313rem #959bc9;
     font-size: 0.75rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #283593;
    position: relative;
}
.invite span {
    cursor: pointer;
    color: rgba(40, 53, 147, 0.5);
    font-size: 1.2em;
    font-weight: 500;
    position: absolute;
    top: 0.125rem;
    right: 0.5rem;
}


.v-calendar {
  user-select: none;
  -webkit-user-select: none;
}
.v-event-drag-bottom {
  position: absolute; 
  left: 0; 
  right: 0;
  bottom: 0.25rem; 
  height: 0.25rem; 
  cursor: ns-resize;
}
.v-event-drag-bottom::after {
  display: none;
  position: absolute; 
  left: 50%; 
  height: 0.25rem; 
  border-top: 0.0625rem solid white; 
  border-bottom: 0.0625rem solid white; 
  width: 1.25rem; 
  margin-left: -0.625rem;
  opacity: 0.8;
  content: '';
}
.v-event-timed:hover .v-event-drag-bottom::after {
  display: block;
}

.file_visibility {
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
}
.visible_title {
  font-size: 0.875rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #283593;
}
.visibleLabel {
  padding: 0.1563rem;
  border-radius: 0.5rem;
  border: solid 0.0625rem rgba(105, 86, 214, 0.15);
  background-color: #ffffff;
  margin-top: 0.3125rem;
}
.file_visibility input {
  display: none;
}
.file_visibility label {
  display: inline-block;
  cursor: pointer;
  border-radius: 0.5rem;
}

.file_visibility span {
  display: block;
  padding: 0.3125rem 0.9375rem 0.3125rem 0.9375rem;
  border-radius: 0.3125rem;
  position: relative;
  transition: all 0.25s linear;
  color: #6956d6;
  font-size: 0.875rem;
}
.file_visibility input:checked + span {
  background-color: #6956d6;
  box-shadow: 0 0 0.3125rem 0.125rem rgba(0, 0, 0, 0.1);
}
.file_visibility input:checked + span {
  color: #fff;
  border-color: #6956d6;
}
.file_visibility input:checked + span:before {
  background-color: #6956d6;
}

.save {
  display: flex;
  justify-content: flex-end;
}
.save .button {
  width: 6.25rem;
  height: 2.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: 0.2s ease;
  margin-top: 1.25rem;
  border-radius: 0.375rem;
  background-color: #6956d6;
  font-size: 1rem;
}
.apply-button{
  width: 6.25rem;
  height: 2.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: 0.2s ease;
  margin-top: 1.25rem;
  border-radius: 0.375rem;
  background-color: #6956d6;
  font-size: 1rem;
  margin-top: auto;
  margin-left: auto;
}

.viewContetn {
  width: 45.0625rem;
  background: #ffffff;
  padding: 1.875rem;
}
.viewContetnHeader {
  display: flex;
  justify-content: space-between;
}
.viewContetnHeaderTitle {
  max-width: 37.5rem;
  -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #283593;
  text-align: left;
}
.viewContetnHeaderAction img {
  cursor: pointer;
}
.viewContetnHeaderAction img:last-child {
  margin-left: 1rem;
}
.viewContetn .viewContetnDataFirst {
  margin-top: 1.875rem;
}
.viewContetnDate {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}
.viewContetnDateTitle {
  -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: rgba(40, 53, 147, 0.7);
}
.viewContetnMeetingType {
  -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
  font-size: 0.75rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #283593
}
.viewContetnData {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
  position: relative;
}
.viewContetnDataTitle {
  min-width: 4.4375rem;
  -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(40, 53, 147, 0.7);
  text-align: left;
}
.viewContetnData div:nth-child(2) {
   margin-left: 2.3125rem;
}
.viewContetnDataValue  {  
  -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
  font-size: 0.875rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #283593;
}
.viewContetnData .color {
  color: #0389fb;
}
.showMoreGuests {
  max-height: 4.375rem;
  overflow:auto;
}
.eventCalendar .uploaded-logo {
    width: 4.9375rem;
    height: 4.9375rem;
    border-radius: 6 * $rem;
    background-color: #e9eaf4;
    margin-right: 12 * $rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 0.75rem !important;
    position: relative;
    overflow:hidden;

    img {
      position: absolute;
      right: 0.375rem;
      top: 0.3125rem;	
      cursor: url(~@/assets/delete.png), auto;
    }
    span {
      width: 100%;
      max-width: 4.6875rem;
      max-height: 3.1875rem;
      position: absolute;
      bottom: 0.3125rem;
      font-weight: 300;
      text-align: left;
    }

    &[type="pdf"] {
      background-color: #ff6760;
      font-size: 17 * $rem;
      color: white;
      font-weight: 700;
      letter-spacing: $rem;
    }

    &[type="xlsx"] {
      background-color: rgba(17, 123, 65, 0.871);
      font-size: 20 * $rem;
      color: white;
      font-weight: 700;
    }
    &[type="PNG"],
    [type="png"] {
      background-color: $green-primary;
      font-size: 15 * $rem;
      color: white;
      font-weight: 700;
      letter-spacing: $rem;
    }
    &[type="SVG"],
    [type="svg"] {
      background-color: $green-primary;
      font-size: 15 * $rem;
      color: white;
      font-weight: 700;
      letter-spacing: $rem;
    }
    &[type="jpg"] {
      background-color: rgba(17, 123, 65, 0.871);
      font-size: 17 * $rem;
      color: white;
      font-weight: 700;
    }
    &[type="txt"] {
      background-color: #364ba2;
      font-size: 17 * $rem;
      color: white;
      font-weight: 700;
    }

    &[type="docx"],
    &[type="doc"] {
      background-color: #364ba2;
      font-size: 20 * $rem;
      color: white;
      font-weight: 700;
    }
  }
.choose_files {
  position: relative;
}
.attachedFiles {
  display: flex;
  float: left;
}
.is-drag-valid {
  float:left;
}
.cancelButton {
  width: 6.25rem;
  height: 2.1875rem;
  padding: 0.5rem 1.75rem 0.5rem 1.8125rem;
  border-radius: 0.375rem;
  border: solid 0.0625rem #ff5f58;
  margin-top: 2.4375rem;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ff5f58;
  cursor: pointer;
  margin-left: auto; 
  margin-right: 0;
}

.calendarHeader {
  display: flex;
  justify-content: space-between;
}
.calendarFilter {
  display: flex;
  justify-content: end;
  position: relative;
  top: -1.375rem;
}
.authorFilter {
  height: 2.375rem;
  border: #1de9b6 0.0625rem solid;
  color: #1de9b6;
  font-size: 0.875rem;
  text-align: center;
  padding: 0.625rem 1.25rem;
  border-radius: 0.5rem;
  cursor: pointer;
  position: relative;
}
.authorFilterActive {
  background: #1de9b6;
  color: #ffffff;
}
.attendingFilter {
  height: 2.375rem;
  border: #6956d6 0.0625rem solid;
  color: #6956d6;
  font-size: 0.875rem;
  text-align: center;
  padding: 0.625rem 1.25rem;
  border-radius: 0.5rem;
  margin-left: 0.9375rem;
  cursor: pointer;
  position: relative;
}
.attendingFilterActive {
  background: #6956d6;
  color: #ffffff;
}
.authorFilter span, .attendingFilter span {
  min-width: 1.4375rem;
  min-height: 1.4375rem;
  display: block;
  border-radius: 50%;
  color: #ffffff;
  font-size: 0.6875rem;
  padding: 0.3125rem;
  background: #E0B137;
  position:absolute;
  right: -0.6875rem;
}
.filterDiv {
  width: 12.5rem;
  margin-left: 0.9375rem;
}
.filterDiv ::v-deep .container {
  width: 12.5rem;
  background: #fff;
}
.filterDiv ::v-deep .persons-content input {
  background: #ffffff;
  border: 0.0625rem solid #dcdde6;
  border-radius: 0.375rem;
}
.filterDiv ::v-deep .select-wrapper .selected-box span.selected-option {
  font-size: 0.875rem;
}
.app-search-content {
  position: relative;
}
.app-search-content input {
  width: 12.5rem;
  height: 2.375rem;
  background-color: #fff;
  border: 0;
  outline: none;
  border-radius: .375rem;
  font-weight: 300;
  font-size: 0.875rem;
  color: #9696b9;
  padding:0 2.1875rem 0 0.9375rem;
}
.app-search-content img {
  position: absolute;
  height: 1.3125rem;
  width: 1.3125rem;
  right: 0.625rem;
  top: 0.5rem;
}
.or {
  -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #283593;
  margin-top: 2rem;
}
.choose_time, .choose_time .input_text, .choose_address, .choose_address .input_text, .choose_timeZone {
  min-width: 21.5625rem;
}
</style>
