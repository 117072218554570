<template>
  <div
    class="tree-content"
    :style="[
      zoom > 0.3 || !zoom.length
        ? { cursor: `url(${zoomOutIcon()}), auto !important` }
        : { cursor: `url(${zoomInIcon()}), auto !important` },
    ]"
  >
    <organization-chart
      ref="chart"
      v-if="Object.keys(data).length > 0"
      :zoom="true"
      :pan="true"
      :datasource="data"
      :zoomout-limit="0.2"
      :zoomin-limit="1"
    ></organization-chart>
  </div>
</template>



<script>
import OrganizationChart from "vue-organization-chart";
import "vue-organization-chart/dist/orgchart.css";
export default {
  components: {
    OrganizationChart,
  },

  props: {
    data: Object,
    search: String,
    userPage: Boolean,
  },
  data() {
    return {
      initialLoad: true,
      zoom: "",
    };
  },

  methods: {
    createTreeNodes() {
      // create Elements ///////////////

      let container = document.getElementsByClassName("orgchart")[0];

      let parentNode = container.querySelectorAll(".node");
      let titleNode = container.querySelectorAll(".title");

      let th = this;
      if (parentNode) {
        titleNode.forEach((el) => {
          el.addEventListener("click", function (event) {
            th.$emit("redirect", el.parentElement.id);
            event.stopPropagation();
          });

          if (el.innerText.length > 30) {
            el.setAttribute("title", el.innerText);
          }

          const exists = Array.from(el.children).find(
            (el) =>
              el.className.includes("dropdown") ||
              el.className.includes("material-icons cancel")
          );

          if (exists) return;
          if (!this.userPage) {
            let dropDownIcon = document.createElement("img");
            dropDownIcon.src = require("@/assets/build-structure-icon.svg");
            dropDownIcon.className = "dropdown";
            let cancelIcon = document.createElement("span");
            cancelIcon.className = "material-icons cancel";
            cancelIcon.innerText = "close";
            if (el.parentElement.id.split("_")[1] != "job") {
              el.appendChild(dropDownIcon);
            } else {
              el.appendChild(cancelIcon);
            }

            cancelIcon.addEventListener("click", function (event) {
              event.stopPropagation();
              th.$emit("delete-job", el.parentElement.id);
            });
            let buttonsContainer = document.getElementsByClassName(
              "buttons-container"
            );
            dropDownIcon.addEventListener("click", function (event) {
              event.stopPropagation();
              const nodeId = event.currentTarget.parentElement.parentElement.id;
              buttonsContainer.forEach((el) => {
                if (nodeId == el.parentElement.id) {
                  if (el.classList.contains("show")) {
                    el.classList.remove("show");
                    el.classList.add("hide");
                  } else {
                    el.classList.remove("hide");
                    el.classList.add("show");
                  }
                } else {
                  if (el.classList.contains("show")) {
                    el.classList.remove("show");
                  }
                }
              });
            });
          }
        });

        parentNode.forEach((el) => {
          const splitedId = el.id.split("_")[0];
          const splitedIdString = el.id.split("_")[1];
          // SET CLASSNAMES TO PARENT NODES/////////////////////////////////
          const exists = Array.from(el.children).find((el) =>
            el.className.includes("buttons-container")
          );
          if (exists) return;

          switch (splitedIdString) {
            case "top":
              el.classList.add("top");
              break;
            case "department":
              el.classList.add("department");
              break;
            case "director":
              el.classList.add("director");
              break;
            case "job":
              el.classList.add("job");
              break;
            case "division":
              el.classList.add("division");
              break;
            case "subDivision":
              el.classList.add("sub-division");
              break;
          }

          // ///////
          // CREATE ELEMENTS //////////////////////////////////////
          let buttonsContainer = document.createElement("div");

          let addDirectorButton = document.createElement("div");
          let addDepartmentButton = document.createElement("div");
          let addJobButton = document.createElement("div");
          let addDivisionButton = document.createElement("div");
          let addSubDivisionButton = document.createElement("div");
          let deleteButton = document.createElement("div");
          let editButton = document.createElement("div");

          let editIcon = document.createElement("img");
          editIcon.src = require("@/assets/edit-white.svg");
          editButton.appendChild(editIcon);

          let deleteIcon = document.createElement("img");
          deleteIcon.src = require("@/assets/delete-white.svg");
          deleteButton.appendChild(deleteIcon);

          addDirectorButton.innerText = "Director";
          addDepartmentButton.innerText = "Department";
          addJobButton.innerText = "Job";
          addDivisionButton.innerText = "Division";
          addSubDivisionButton.innerText = "Sub-Division";

          //   SET CLASSNAMES////////////////////////////////

          buttonsContainer.className = "buttons-container";
          addDirectorButton.className = "director-button";
          addDepartmentButton.className = "department-button";
          addJobButton.className = "job-button";
          addDivisionButton.className = "division-button";
          addSubDivisionButton.className = "sub-division-button";
          deleteButton.className = "delete-button";
          editButton.className = "edit-button";

          //   SET EVENTS ///////////////////////////////////////
          addDirectorButton.addEventListener("click", function () {
            event.stopPropagation();
            th.$emit("director-button-clicked", splitedId);
          });
          addDepartmentButton.addEventListener("click", function () {
            event.stopPropagation();
            th.$emit("department-button-clicked", el.id);
          });
          addJobButton.addEventListener("click", function () {
            event.stopPropagation();
            th.$emit("job-button-clicked", el.id);
          });
          addDivisionButton.addEventListener("click", function () {
            event.stopPropagation();
            th.$emit("division-button-clicked", splitedId);
          });
          addSubDivisionButton.addEventListener("click", function () {
            event.stopPropagation();
            th.$emit("sub-division-button-clicked", splitedId);
          });
          deleteButton.addEventListener("click", function () {
            event.stopPropagation();
            th.$emit("delete-button-clicked", el.id);
          });
          editButton.addEventListener("click", function () {
            event.stopPropagation();
            th.$emit("edit-button-clicked", el.id);
          });

          // INSERT ELEMENTS To DOM///////////////////////////////////
          ////////////////////////////////

          if (el.id.split("_")[1] == "top") {
            el.appendChild(buttonsContainer);
            buttonsContainer.appendChild(addDirectorButton);
            buttonsContainer.appendChild(addDepartmentButton);
            buttonsContainer.appendChild(addJobButton);
            buttonsContainer.appendChild(editButton);
            buttonsContainer.appendChild(deleteButton);
          } else if (el.id.split("_")[1] == "director") {
            el.appendChild(buttonsContainer);
            buttonsContainer.appendChild(addDepartmentButton);
            buttonsContainer.appendChild(editButton);
            buttonsContainer.appendChild(deleteButton);
          } else if (el.id.split("_")[1] == "department") {
            el.appendChild(buttonsContainer);
            buttonsContainer.appendChild(addDivisionButton);
            buttonsContainer.appendChild(addJobButton);
            buttonsContainer.appendChild(editButton);
            buttonsContainer.appendChild(deleteButton);
          } else if (el.id.split("_")[1] == "division") {
            el.appendChild(buttonsContainer);
            buttonsContainer.appendChild(addSubDivisionButton);
            buttonsContainer.appendChild(addJobButton);
            buttonsContainer.appendChild(editButton);
            buttonsContainer.appendChild(deleteButton);
          } else if (el.id.split("_")[1] == "subDivision") {
            el.appendChild(buttonsContainer);
            buttonsContainer.appendChild(addJobButton);
            buttonsContainer.appendChild(editButton);
            buttonsContainer.appendChild(deleteButton);
          }
        });
      }

      //   //////////////////////////////////
    },
    zoomInIcon() {
      const icon = require("@/assets/zoomin.svg");

      return icon;
    },
    zoomOutIcon() {
      const icon = require("@/assets/zoomout.svg");
      return icon;
    },
  },
  watch: {
    data() {
      this.$nextTick(() => {
        this.createTreeNodes();
        this.$watch("$refs.chart._data.transformVal", () => {
          this.zoom = this.$refs.chart._data.transformVal
            .split("(")[1]
            .split(",")[0];
        });
        // if (!this.initialLoad) return;
        let scrollTarget = document.getElementsByClassName(
          "orgchart-container"
        )[0];
        let containerHalfWidth =
          document.getElementsByClassName("orgchart")[0].clientWidth / 2;
        scrollTarget.scroll(containerHalfWidth - 750, 0);
        this.initialLoad = false;
      });
    },

    search() {
      let container = document.getElementsByClassName("orgchart")[0];
      let titleNode = container.querySelectorAll(".title");

      titleNode.forEach((el) => {
        if (el.innerText.toLowerCase().includes(this.search.toLowerCase())) {
          if (this.search.length > 2) {
            el.classList.add("selected");
          }
          if (this.search == "") {
            el.classList.remove("selected");
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
// .zoomOut {
//   cursor: url("@/assets/images/schedulesImage.png");
// }

::v-deep .orgchart-container {
  width: 100%;
  height: 807px;
  .orgchart {
    cursor: unset !important;
    &:active {
      cursor: move !important;
      // cursor: url(require('@/assets/zoomin.png'))`
      // cursor: url("@/assets/zoomin.png"), auto;
    }
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .show {
    display: flex !important;
  }
  .hide {
    display: none !important;
  }
  .selected {
    background-color: #94ffd4 !important;
    color: $blue-primary !important;
  }
  border: 0;
  .orgchart .node.focused,
  .orgchart .node:hover {
    background-color: transparent;
  }
  .buttons-container {
    display: none;
    justify-content: center;
    height: 36px;
    margin-top: 5px;
    div {
      cursor: pointer;
      padding: 10px 10px;
      color: #ffffff;
      border-radius: 6px;
      font-size: 12px;
      display: inline-block;
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-left: 10px;
      &:hover {
        transform: scale(0.98);
      }
      &:active {
        transform: scale(0.9);
      }
      &:first-child {
        margin-left: 0;
      }
    }

    .director-button {
      background-color: #6956d6;
    }
    .department-button {
      background-color: #5769d6;
    }
    .job-button {
      background-color: #ffffff;
      border: 1px solid #959bc9;
      color: #959bc9;
    }
    .edit-button {
      background-color: #ffa346;
    }
    .delete-button {
      background-color: #ff5f58;
    }
    .division-button {
      background-color: #719fe2;
    }
    .sub-division-button {
      background-color: #66c1de;
    }
  }
  .orgchart .node {
    width: 350px;
    border: 0;
    margin-left: 30px;
    margin-right: 30px;
    .material-icons {
      display: none;
      color: #ff5f58;
      position: absolute;
      right: 15px;
      cursor: pointer;
      bottom: 50% !important;
      transform: translate(50%, 50%) !important;
    }
    &:hover .material-icons {
      display: block;
    }
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
  }
  .top,
  [id*="top"] {
    .title {
      width: 350px;
      padding: 45px 10px;
      height: 114px;
      background-color: #3f46bf;
      box-shadow: 0px 8px 20px rgba(63, 70, 191, 0.32);
    }
  }
  .director,
  [id*="director"] {
    .title {
      padding: 28px;
      background-color: #6956d6;

      box-shadow: 0px 8px 20px rgba(105, 86, 214, 0.32);
    }
  }

  .department,
  [id*="department"] {
    .title {
      padding: 28px 10px;
      background-color: #5769d6;
      box-shadow: 0px 8px 20px rgba(87, 105, 214, 0.32);
    }
  }
  .division,
  [id*="division"] {
    .title {
      background-color: #719fe2;
      padding: 18px 10px;
      box-shadow: 0px 8px 20px rgba(113, 159, 226, 0.32);

      img {
        right: 20px !important;
        display: none;
        left: unset !important;
        bottom: 50% !important;
        transform: translate(50%, 50%) !important;
      }
      &:hover img {
        display: block;
        cursor: pointer;
      }
    }
  }
  .sub-division,
  [id*="subDivision"] {
    .title {
      padding: 18px 25px;
      background-color: #66c1de;
      box-shadow: 0px 2px 13px rgba(102, 193, 222, 0.32);

      img {
        right: 20px !important;
        display: none;
        left: unset !important;
        bottom: 50% !important;
        transform: translate(50%, 50%) !important;
      }
      &:hover img {
        display: block;
        cursor: pointer;
      }
    }
  }
  .job,
  [id*="job"] {
    .title {
      padding: 16px 25px;
      border-radius: 28px !important;
      background: #fff;
      border: 1.6px solid #959bc9 !important;
      color: #959bc9;
    }
  }
  .orgchart {
    background-image: unset;
    border: 0;
    padding: 0;
  }

  .orgchart .node .title {
    border-radius: 6px;
    border: 0;
    font-weight: 500;
    font-size: 18px;
    text-transform: capitalize;
    width: 350px;

    height: unset;
    &:active {
      transform: scale(0.97);
    }
    position: relative;
    cursor: pointer;
    img {
      height: 20px;
      width: 50px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 5px;
      display: none;
    }
    &:hover img {
      display: block;
      cursor: pointer;
    }
  }
  .orgchart .node .content {
    display: none;
  }

  .orgchart .lines .leftLine {
    border-left: 1px solid #959bc9;
  }
  .orgchart .lines .rightLine {
    border-right: 1px solid #959bc9;
  }
  .orgchart .lines .topLine {
    border-top: 1px solid #959bc9;
  }
  .orgchart .lines .bottomLine {
    border-bottom: 1px solid #959bc9;
  }
  .orgchart .lines .downLine {
    background-color: #959bc9;
  }
}
</style>