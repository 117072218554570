<template>
  <div class="background">
    <form class="container" @submit.prevent="handleSubmit">
      <div class="main-logo">
        <img src="@/assets/upGamingMainLogo.png" alt="logo" />
      </div>
      <h2>INTRANET</h2>
      <!-- <custom-input
                title="Username"
                v-model="username"
                titleBackgroundColor="rgba(255, 255, 255, 0.81)"
            />  -->
      <!-- <custom-input
                title="Password"
                v-model="password"
                inputType="password"
                titleBackgroundColor="rgba(255, 255, 255, 0.81)"
            /> -->
      <div class="username-content">
        <input
          type="text"
          placeholder="Username"
          class="username"
          v-model="username"
        />
      </div>
      <div class="password-content">
        <input
          type="password"
          placeholder="Password"
          class="username"
          v-model="password"
        />
      </div>

      <!-- <switcher title="Remember" v-model="remember" /> -->
      <!-- <div class="remember-content">
                <label class="checkbox-container">
                    <span class="remember-text">Remember me</span>
                    <input v-model="remember" type="checkbox" checked="checked" />
                    <span class="checkmark"></span>
                </label>
            </div> -->
      <button class="btn-login">Log in</button>
    </form>
  </div>
</template>

<script>
// import CustomInput from '@/components/ReusableComponents/CustomInput/CustomInput.vue'
// import Switcher from '@/components/ReusableComponents/Switcher/Switcher.vue'
export default {
  components: {
    // CustomInput,
    // Switcher,
  },
  props: {
    login: Function,
  },
  data() {
    return {
      username: "",
      password: "",
      remember: true,
    };
  },
  methods: {
    handleSubmit() {
      this.login(this.username, this.password, this.remember);
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  position: relative;
  background-image: url("~@/assets/login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $blue-primary;
  height: 100vh;
  width: 100%;
  .main-logo {
    margin-bottom: 50px;
  }

  .container {
    width: 600px;
    height: 553px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25 * $rem;
    display: flex;
    flex-flow: column;
    align-items: center;

    h2 {
      font-size: 40 * $rem;
      font-weight: 400;
      margin-bottom: 65 * $rem;
      color: #1de9b6;
    }

    button {
      height: 56 * $rem;
      width: 196 * $rem;
      border-radius: 10px;
      background-color: #1de9b6;
      font-size: 14px;
      color: $blue-primary;
      font-weight: 500;
      cursor: pointer;
      margin-top: 41px;
    }
    .username {
      width: 320px;
      height: 56px;
      border-radius: 10px;
      outline: none;
      border: 0;
      color: $blue-primary;
      font-size: 14px;
      padding-left: 25px;
    }
    ::placeholder {
      color: $blue-primary;
    }
    .password-content {
      margin-top: 18px;
    }
  }
}
.remember-content {
  width: 320px;
  margin-top: 15px;
}
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-top: 5px;
  .remember-text {
    font-size: 14px;
    font-weight: 300;
    color: #ffffff;
  }
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  border-radius: 5px;
  background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #1de9b6;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 10px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media screen and (max-width: 700px) {
  .background .container {
    width: 92%;

    label,
    .switcher-container,
    button {
      width: 95%;
    }
  }
}
</style>