<template>
  <div class="views">
    <span :class="{active:!changeView}" @click="$emit('view-changed', false)">
        Details
    </span>
    <!-- <img
        v-if="changeView === false"
        src="@/assets/icons/list-icon-clicked.svg"
        alt="grid-icon"
    /> -->
    <span :class="{active:changeView}" @click="$emit('view-changed',true)">
        Tops
    </span>
    <!-- <img
        v-if="changeView === true"
        src="@/assets/icons/grid-icon-clicked.svg"
        alt="grid-icon"
    /> -->
  </div>
</template>

<script>
export default {
  props: {
    /**
     * @param {Boolean} changeView - determines if view is grid or list, True - Grid , False - List1
     */
    changeView: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.views {
  img {
    height: 40px;
    cursor: pointer;
  }
}

span{
    font-size: 1.0625rem;
    color: #283593;
    margin-left: 1.25rem;
    position: relative;

    &:first-child{
      margin-left: 0;
    }

    &.active{
      font-weight: 500;

      &::after{
        content: '';
        display: block;
        position: absolute;
        bottom: -0.5625rem;
        left: 0;
        width: 100%;
        height: 0.1875rem;
        background: #1de9b6;
      }
    }
}
</style>
