var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"attendance-perofrmance-content"},[_c('div',{staticClass:"top-header"},[_c('ul',[_c('li',{staticClass:"route"},[_c('router-link',{attrs:{"tag":"span","to":{ path: '/DailyPerformance' },"active-class":"active"}},[_vm._v(" Daily Performance ")])],1),(
          this.$store.state.profile.OfficeID === 38 ||
          this.$store.state.profile.OfficeID === 3
        )?_c('li',{staticClass:"route"},[_c('router-link',{attrs:{"tag":"span","to":{ path: '/AttendanceReport' },"active-class":"active"}},[_vm._v(" Attendance Report ")])],1):_vm._e(),(
          this.$store.state.profile.OfficeID === 38 ||
          this.$store.state.profile.OfficeID === 3
        )?_c('li',{staticClass:"route"},[_c('router-link',{attrs:{"tag":"span","to":{ path: '/PersonsPerformance' },"active-class":"active"}},[_vm._v(" Attendance by Current Day ")])],1):_vm._e()])]),_c('div',{staticClass:"background"},[_c('router-view')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }