<template>
    <div
        class="separator"
        :style="{ left: ((isNightTime ? adjustedLeft : left) * 100) / (24 * 60) + '%' }"
        :class="color"
    >
        <div class="arrow-down"></div>
        <div class="arrow-up"></div>
    </div>
</template>

<script>
export default {
    props: {
        color: String,
        left: Number,
        isNightTime: Boolean,
    },
    computed: {
        adjustedLeft() {
            return this.adjust(this.left)
        },
    },
    methods: {
        adjust(time) {
            return (time + 12 * 60) % (24 * 60)
        },
    },
}
</script>

<style scoped lang="scss">
.separator {
    position: absolute;
    width: $rem;
    height: 100%;
    background-color: #fff;

    &.blue {
        background-color: $blue-primary;
        z-index: 11;

        .arrow-down {
            border-top-color: $blue-primary;
        }

        .arrow-up {
            border-bottom-color: $blue-primary;
        }
    }

    &.red {
        z-index: 10;
        background-color: #fd4a4c;

        .arrow-down {
            border-top-color: #fd4a4c;
        }

        .arrow-up {
            border-bottom-color: #fd4a4c;
        }
    }

    &.green {
        z-index: 10;
        background-color: #84ead6;

        .arrow-down {
            border-top-color: #84ead6;
        }

        .arrow-up {
            border-bottom-color: #84ead6;
        }
    }

    .arrow-down {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-45%, -50%);
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid;
    }

    .arrow-up {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translate(-45%, 50%);
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid;
    }
}
</style>