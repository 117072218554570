<template>
  <div class="select-wrapper">
    <div
      class="selected-box"
      :class="{ 'is-selected': value || collapsed, collapsed: collapsed, 'disabled' : disabled }"
      @click.stop="selectBoxClickHandler"
      ref="box"
    >
      <span
        class="title"
        :class="{ focused: selectedOptionName !== '' && selectedOptionName }"
        >{{ title }} {{ isRequired ? "*" : null }}
      </span>
      <span class="selected-option">{{ selectedOptionName }}</span>
      <span class="material-icons arrow-up" v-show="collapsed"
        >keyboard_arrow_up</span
      >
      <span v-show="!collapsed" class="material-icons arrow-down"
        >keyboard_arrow_down</span
      >
    </div>
    <input
      type="text"
      class="hidden-input-for-validation"
      v-model="value"
      :required="isRequired"
      :disabled="disabled"
    />
    <portal to="custom-select" v-if="collapsed && options.length > 0">
      <ul class="custom-select-options" v-show="collapsed" :style="ulStying">
        <!-- @click="optionClickHandler('')"
          :class="{ selected: value == '' }"
          :key="null" -->

        <div class="clear-wrapper">
          <span
            @click.stop="optionClickHandler('')"
            :class="{ selected: value == '' }"
            :key="null"
            class="clear-button"
            v-if="clearVisible"
          >
            clear
          </span>
        </div>

        <li
          v-for="option in options"
          :key="option.value"
          @click="optionClickHandler(option.value)"
          :class="{ selected: value == option.value }"
        >
          {{ option.title }}
        </li>
      </ul>
    </portal>
  </div>
</template>

<script>
export default {
  mounted() {
    document.addEventListener("click", this.documentClickHandler);
  },
  props: {
    title: String,
    options: Array,
    value: {
      type: [Number, String, Boolean, Array],
      required: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearVisible:{
      type: Boolean,
      default: false,
    },
    triggerCollapse: {
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
      collapsed: false,
      optionsLeftOffset: 0,
      optionsRightOffset: 0,
      optionsTopOffset: 0,
    };
  },
  methods: {
    selectBoxClickHandler() {
      if (this.disabled) return;
      this.collapsed = !this.collapsed;
    },
    optionClickHandler(value) {
      this.collapsed = false;
      this.$emit("input", value);
      this.$emit('select',value)
    },
    clear() {
      this.$emit("clear");
      this.collapsed = false;
    },
    documentClickHandler() {
      this.collapsed = false;
    },
  },
  computed: {
    selectedOptionName() {
      var opt = this.options.filter((el) => el.value === this.value)[0];
      if (opt) return opt.title;
      return "";
    },
    hasEmptyOption() {
      this.options.forEach((elem) => {
        if (elem.value == "") return true;
      });
      return false;
    },
    ulStying() {
      let fullWidth = window.innerWidth - 200;
      let rect = this.$el.getBoundingClientRect();
      let styling

      if (rect.x > fullWidth) {
        styling = {
          right: this.optionsRightOffset + 50 + "px",
          top: this.optionsTopOffset + 50 + "px",
        };
      }
      else{
        styling = {
          left: this.optionsLeftOffset + "px",
          top: this.optionsTopOffset + 50 + "px",
        };
      }

      if (this.optionsTopOffset > 600)
        styling["transform"] = "translateY(-100%)"; //open to the top
      return styling;
    },
  },
  destroyed() {
    document.removeEventListener("click", this.documentClickHandler);
  },
  watch: {
    collapsed(val) {
      if (val) {
        var box = this.$refs.box;
        var clientBoundintRect = box.getBoundingClientRect();
        this.optionsLeftOffset = clientBoundintRect.left;
        this.optionsTopOffset = clientBoundintRect.top;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.clear-wrapper {
  height: 10px;
}
.clear-button {
  position: absolute;
  right: 20px;
  top: 5px;
  font-size: 14px;
  font-weight: 400;
  color: rgba($blue-primary, 0.7);
  cursor: pointer;
  height: 30px;
  display: block;
}
.select-wrapper {
  position: relative; 

  .selected-box {
    padding: 0.9375rem;
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    height: 40 * $rem;
    border: 0.0625rem solid #dcdde6;
    border-radius: 6 * $rem;
    background-color: white;
    font-size: 15 * $rem;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 15 * $rem;

    span.title {
      color: rgba($blue-primary, 0.7);
      font-size: 14px;
      font-weight: 300;
      transition: all 0.2s ease-out;

      background: linear-gradient(to bottom, transparent 0%, transparent 35%, #fff 24%, #fff 100%);
      white-space: nowrap;
      border-radius: 4 * $rem;
      position: absolute;
      top: 0.625rem;

      cursor: text;
      cursor: pointer;
      &.focused {
        top: -7 * $rem;
        font-size: 12 * $rem !important;
        font-weight: 300;
      }
    }

    span.selected-option {
      color: rgba($blue-primary, 0.7);
      font-size: 14;
      font-weight: 500;
      position: relative;
      top: 2px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .material-icons {
      color: rgba($blue-primary, 0.7);
      margin-left: 10 * $rem;
      font-size: 18 * $rem;
    }
  }
  .disabled {
    background-color: #FAFAFA;
    cursor:default;
  }

  .hidden-input-for-validation {
    opacity: 0;
    width: 0;
    height: 0;
    margin-left: 50 * $rem;
    position: absolute;
  }
}
</style>
