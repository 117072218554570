<template>
  <div
    v-if="!mobile"
    id="app"
    class="t"
    data-app
    app-data="true"
    light
    v-bind:class="{ eventCalendar: $route.path == '/EventCalendar' }"
  >
    <login v-if="authentificated == false" :login="handleLogin" />
    <div v-if="authentificated == true && $store.state.profile.ID">
      <div class="entire-container">
        <saved-bookmarks v-show="showBookmarks"></saved-bookmarks>
        <div class="sidebar-and-main-content" :class="{ full: !showBookmarks }">
          <div class="bookmark-arrow">
            <span
              class="material-icons arrow-up"
              v-show="this.$store.state.showBookmarks"
              @click="hideBookmarksHandler"
            >
              keyboard_arrow_up
            </span>
            <span
              v-show="!this.$store.state.showBookmarks"
              @click="showBookmarksHandler"
              class="material-icons arrow-down"
            >
              keyboard_arrow_down
            </span>
          </div>
          <site-header
            :notificationsVisible="notificationsVisible"
            @show-notifications="notificationsVisible = !notificationsVisible"
            :hub="socketConnection"
            @logout="authentificated = false"
          />
          <site-sidebar />
          <div
            class="site-content"
            :class="[
              { admin: !$store.state.showTimeLine },
              { bookmarkSiteContentStyle: showBookmarks },
              { fullwidth: $route.fullPath === '/Grades/CareerMapping' },
            ]"
          >
            <router-view :key="$route.fullPath"></router-view>
          </div>
          <!-- <right-section v-show="this.$store.state.showTimeLine" /> -->
        </div>
      </div>
    </div>

    <custom-popup
      :visible="ActiveStatements"
      :title="statement.Title ? statement.Title : ''"
      :globalComponent="true"
      v-for="(statement, ind) in Statements"
      :key="ind"
      class="active-statement-popup"
    >
      <div class="details-description" v-html="statement.Description"></div>

      <div class="files-wrapper">
        <span>Attached files</span>
        <ul class="files-list">
          <li
            class="uploaded-document"
            v-for="(file, ind) in statement.StatementFiles"
            :key="ind"
            :type="
              file.AttachmentName ? file.AttachmentName.split('.').pop() : ''
            "
            @click="previewDocument(file.AttachmentID, statement.StatementID)"
          >
            <div>
              {{
                file.AttachmentName ? file.AttachmentName.split('.').pop() : ''
              }}
            </div>
            <div class="file-name">
              {{ file.AttachmentName }}
            </div>
          </li>
        </ul>
      </div>

      <div class="response-btn-wrapper">
        <button
          :class="{ triggered: btnTriggered }"
          @click="MakeStatementResponse(statement.StatementID)"
        >
          {{ statement.ResponseTo }}
        </button>
      </div>
    </custom-popup>

    <custom-snackbar
      v-if="snackbarStatement.visible"
      :purpose="snackbarStatement.purpose"
      :text="snackbarStatement.text"
    />

    <custom-popup
      :visible="previewPopupVisible"
      @close=";(previewPopupVisible = false), (fileForPreview = [])"
      :title="''"
      class="file-preview"
      :forIframe="true"
    >
      <iframe
        style="border: none;"
        width="100%"
        height="100%"
        :src="previewFile()"
      ></iframe>
    </custom-popup>

    <messenger
      v-for="(topic, index) in activeChatWindows"
      :position="index"
      @close="closeMessenger(topic.TopicID)"
      :topicID="topic.TopicID"
      :hub="socketConnection"
      :key="topic.TopicID"
      :isOneToOne="topic.IsOneToOne"
      :title="topic.Title"
    />
    <custom-snackbar
      v-if="$store.state.globalSnackbar.visible"
      :purpose="$store.state.globalSnackbar.purpose"
      :text="$store.state.globalSnackbar.text"
      @close="$store.state.globalSnackbar.visible = false"
    />
    <confirm-modal
      v-if="$store.state.globalConfirm.visible"
      :text="$store.state.globalConfirm.text"
      @yes="$store.state.globalConfirm.resolve()"
      @close="
        $store.state.globalConfirm.reject(),
          ($store.state.globalConfirm.visible = false)
      "
    />
    <portal-target name="custom-select" />
    <portal-target name="in-out" />
    <portal-target name="table-filter" />
    <portal-target name="job-person-tree" />
  </div>
  <div id="mobile-app" class="mobile-app" v-else-if="mobile === true">
    <vending-mobile
      @logout="authentificated = false"
      v-if="authentificated == true && $store.state.profile.ID"
    />
    <login v-if="authentificated == false" :login="handleLogin" />
    <custom-snackbar
      v-if="snackbarStatement.visible"
      :purpose="snackbarStatement.purpose"
      :text="snackbarStatement.text"
      @close="snackbarStatement.visible = false"
    />
  </div>
</template>

<script>
import SavedBookmarks from '@/components/Bookmarks/SavedBookmarks'
import Login from '@/components/Login/Login.vue'
import axios from '@/Helpers/AxiosInstance.js'
import Messenger from '@/components/Messenger/Messenger.vue'
import SiteHeader from '@/components/SiteHeader/SiteHeader.vue'
import SiteSidebar from '@/components/SiteSidebar/SiteSidebar.vue'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import ConfirmModal from '@/components/ReusableComponents/ConfirmModal/ConfirmModal.vue'
// import RightSection from "@/components/RightSection/RightSection";
import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
import CustomPopup from '@/components/ReusableComponents/CustomPopup/CustomPopup'
import VendingMobile from '@/components/VendingMobile/VendingMobile.vue'

export default {
  name: 'app',
  components: {
    // RightSection,
    SavedBookmarks,
    Login,
    Messenger,
    SiteHeader,
    SiteSidebar,
    CustomSnackbar,
    ConfirmModal,
    CustomPopup,
    VendingMobile,
  },
  created() {
    this.checkAuth()
    this.$eventBus.$on('logout', this.logoutHandler)
    this.$eventBus.$on('createChat', this.openMessenger)
    document.addEventListener(
      'click',
      () => (this.notificationsVisible = false),
    )

    //interceptor
    axios.interceptors.response.use(
      (response) => {
        if (response.data.ErrorMsg) {
          this.$store.state.globalSnackbar.visible = true
          this.$store.state.globalSnackbar.purpose = 'error'
          this.$store.state.globalSnackbar.text =
            response.data.ErrorMsg || 'An Error Has Occured'
        }
        return response
      },
      (error) => {
        this.$store.state.globalSnackbar.visible = true
        this.$store.state.globalSnackbar.purpose = 'error'
        this.$store.state.globalSnackbar.text = 'An Error Has Occured'
        return Promise.reject(error)
      },
    )
    //chat expanded
    this.$eventBus.$on('chatExpand', (value) => {
      this.chatExpanded = value
    })
    this.$eventBus.$on('sidebarShrink', (value) => (this.sidebarShrunk = value))
  },
  data() {
    return {
      authentificated: null,
      activeChatWindows: [],
      socketConnection: null,
      notificationsVisible: false,
      chatExpanded: '',
      sidebarShrunk: false,
      mobile: false,
      bookmarkCollapsed: false,
      Statements: [],
      ActiveStatements: false,
      fileForPreview: [],
      previewPopupVisible: false,
      snackbarStatement: {
        visible: false,
        purpose: '',
        text: '',
      },
      btnTriggered: false,
    }
  },

  methods: {
    checkAuth() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        this.mobile = true
        this.$router.push('/VendingMobile').catch(() => {})
      } else {
        this.mobile = false
      }
      return axios
        .post('authorization/CheckSession')
        .then((resp) => {
          this.$store.state.profile = resp.data.Value.Person
          this.$store.state.permissions = resp.data.Value.Permissions
          this.$store.state.roles = resp.data.Value.Roles
          this.$store.state.showTimeLine = this.$store.getters.hasRole('Member')
            ? true
            : false

          if (this.$store.getters.hasRole('Member')) {
            if (this.$route.query.fromOneadmin != 'true') {
              this.$router.push('/Dashboard')
            }
          } else if (
            (this.$store.getters.hasRole('Admin') ||
              this.$store.getters.hasRole('HR') ||
              this.$store.getters.hasRole('Manager')) &&
            (this.$route.fullPath === '/' ||
              this.$route.fullPath === '/DailyPerfomance')
          ) {
            this.$router.push('/DailyPerformance')
          }

          this.authentificated = !resp.data.ErrorMsg
          if (resp.data.Value.IsFirstAuthorization) {
            this.$router.push({
              path: 'profile',
              query: { userID: this.$store.state.profile.ID, firstAuth: true },
            })
          }

          if (!this.authentificated) {
            this.autoLigin()
          }
          this.getStatements()
        })
        .catch(() => {
          this.authentificated = false
          this.autoLigin()
        })
    },
    autoLigin() {
      let isdev = 'dev/'
      if (this.$store.state.isProduction) isdev = ''

      fetch(`https://auth-api01.ip.local:8443/${isdev}auth`, {
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((data) => {
          axios
            .post('authorization/CheckDomainAuthSession', data)
            .then((resp) => {
              this.handleLoginResponse(resp)
            })
        })
    },
    handleLogin(username, password, remember) {
      axios
        .post('authorization/Login', {
          NickName: username,
          Password: password,
          IsPermanent: remember,
        })
        .then((resp) => {
          this.handleLoginResponse(resp)
        })
    },
    handleLoginResponse(resp) {
      if (!resp.data.ErrorMsg) {
        this.authentificated = true
        this.checkAuth().then(() => {
          if (this.$store.getters.hasRole('Member'))
            if (this.$route.query.fromOneadmin != 'true') {
              this.$router.push('/Dashboard')
            }

          if (
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i)
          ) {
            this.$router.push('/VendingMobile').catch(() => {})
          } else this.$router.push('/DailyPerformance')
        })
      } else {
        this.snackbarStatement.visible = true
        this.snackbarStatement.purpose = 'error'
        this.snackbarStatement.text = resp.ErrorMsg
      }
    },
    // openSignalRConnection() {
    //   this.socketConnection = new HubConnectionBuilder()
    //     .withUrl("/chatHub")
    //     .configureLogging(LogLevel.Information)
    //     .build();

    //   this.socketConnection.start();
    // },
    logoutHandler() {
      axios.post('authorization/LogOut').then((resp) => {
        if (!resp.data.ErrorMsg) {
          this.authentificated = false
          this.$store.state.profile = {}
          this.$store.state.permissions = {}
        }
      })
    },
    openMessenger(topic) {
      if (
        ~this.activeChatWindows.findIndex((el) => el.TopicID == topic.TopicID)
      )
        return
      this.activeChatWindows.unshift(topic)
      this.activeChatWindows = this.activeChatWindows.slice(0, 3)
    },
    closeMessenger(topicID) {
      this.activeChatWindows = this.activeChatWindows.filter(
        (topic) => topic.TopicID != topicID,
      )
    },
    showBookmarksHandler() {
      this.bookmarkCollapsed = true
      this.$store.state.showBookmarks = true
    },
    hideBookmarksHandler() {
      this.bookmarkCollapsed = false
      this.$store.state.showBookmarks = false
    },

    getStatements() {
      axios.get('News/GetStatements').then((resp) => {
        let data = resp.data.Value.Statements
        data = data.sort((a, b) => b.CreateTime - a.CreateTime)
        if (!data.length) {
          this.ActiveStatements = false
        }
        this.ActiveStatements = true
        this.Statements = data
      })
    },

    previewDocument(fileID, statementID) {
      let currentStatement = this.Statements.filter(
        (statement) => statement.StatementID == statementID,
      )
      let currentFile = currentStatement[0].StatementFiles.filter(
        (file) => file.AttachmentID == fileID,
      )
      this.fileForPreview = currentFile
      let imageFile = this.fileForPreview[0].AttachmentType.split('/')
      if (
        this.fileForPreview[0].AttachmentType == 'application/pdf' ||
        imageFile[0] == 'image'
      ) {
        this.ActiveStatements = true
        this.previewPopupVisible = true
      } else {
        return
      }
    },

    previewFile() {
      if (this.fileForPreview.length > 0) {
        let fileType = this.fileForPreview[0].AttachmentType
        let file = this.fileForPreview[0].File
        return `data:${fileType};base64,${file}`
      } else return
    },

    MakeStatementResponse(statementID) {
      this.btnTriggered = true

      setTimeout(() => {
        this.btnTriggered = false
      }, 300)

      axios
        .post('News/MakeStatementResponse', {
          statementID: statementID,
        })
        .then((resp) => {
          this.getStatements()
          if (!resp.ErrorMsg) {
            this.snackbarStatement.visible = true
            this.snackbarStatement.purpose = 'success'
            this.snackbarStatement.text =
              'Your request has been sent successfully'
            setTimeout(() => {
              this.snackbarStatement.visible = false
            }, 400)
          } else {
            this.snackbarStatement.visible = true
            this.snackbarStatement.purpose = 'error'
            this.snackbarStatement.text = resp.ErrorMsg
            setTimeout(() => {
              this.snackbarStatement.visible = false
            }, 400)
          }
        })
    },
  },
  computed: {
    isDashboard() {
      if (this.$route.path === '/Dashboard') return true
      return false
    },
    isAdmin() {
      return this.$store.getters.isAdmin || this.$store.getters.isSuperAdmin
    },
    openedTopics() {
      return this.activeChatWindows.map((el) => el.TopicID)
    },
    showBookmarks() {
      return this.$store.state.showBookmarks
    },
    backGroundWithoutPadding() {
      return this.$store.state.backgroundWithoutPadding
    },
  },
  watch: {
    authentificated(value) {
      if (value == true) {
        // this.openSignalRConnection();
      }
    },
  },
}
</script>

<style lang="scss">
.no-app-text {
  color: rgba($blue-primary, 0.7);
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

@font-face {
  font-family: firaGo;
  src: url('./assets/fonts/firaGo.ttf'); // do not delete
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.material-icons {
  user-select: none;
}
.bookmark-arrow {
  position: absolute;
  top: 1px;
  right: 12px;
  z-index: 40;

  span {
    color: $blue-primary;
    cursor: pointer;
  }
}

.switcher {
  width: 40 * $rem;
  height: 18 * $rem;
  background-color: transparent;
  border: 2px solid #959bc9;
  border: 2px solid #959bc9;
  border-radius: 15 * $rem;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-out;

  &:after {
    position: absolute;
    content: '';
    left: 1 * $rem;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 12 * $rem;
    height: 12 * $rem;
    border: 1px solid #959bc9;
    border-radius: 50%;
    background-color: white;
    transition: all 0.3s ease-out;
  }

  &.active {
    background-color: #959bc9;
    &:after {
      left: 20 * $rem;
    }
  }
}

button {
  border: none;
}

html {
  font-size: 16px;
  font-family: Roboto;
  height: -webkit-fill-available;
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 15px;
  }
  body {
    background-color: #010a43;
  }
}

.site-content {
  margin-top: 60 * $rem;
  margin-left: 96 * $rem;
  width: calc(100% - 144 * 0.0625rem);
  min-height: calc(100% - 60 * #{$rem});
  transition: all 0.2s ease-out;
  padding-top: 20 * $rem;

  &.fullwidth {
    width: 100% !important;
    margin-top: 0;
  }
  // overflow-y: auto;

  &.admin {
    margin-left: 245 * $rem;
    width: calc(100% - 285 * #{$rem});
  }

  &.expanded {
    width: 93%;
  }

  .background {
    background-color: #ededfc;
    height: 100%;
    border-radius: 0.375rem;
    margin-left: 2.5rem;
    position: relative;
    overflow-y: auto;

    &.without-paddings {
      padding: 0;
    }
  }
}

.bookmarkSiteContentStyle {
  padding-top: 2.5rem;
}

ul.custom-select-options {
  position: absolute;
  list-style: none;
  background-color: #fff;
  z-index: 250;
  overflow-y: auto;
  min-width: 13.625rem;
  max-height: 17rem;
  background-color: white;
  border: 0.0625rem solid #dcdde6;
  color: #dadcea;
  border-radius: 0.375rem;
  box-shadow: $box-shadow2;
  padding: 12 * $rem 0;
  user-select: none;
  overflow-y: auto;
  animation: show 0.1s linear;

  li {
    min-height: 30 * $rem;
    padding: 0 30 * $rem;
    display: flex;
    align-items: center;
    font-weight: 300;
    cursor: pointer;
    color: rgba($blue-primary, 0.7);

    &:hover {
      background-color: #f4f4f8;
    }

    &.selected {
      background-color: rgba($blue-primary, 0.05);
    }
  }

  .found {
    font-weight: 500;
    color: $blue-primary;
  }

  @keyframes show {
    0% {
      opacity: 0.5;
    }
  }
}

.job-person-tree {
  z-index: 250;
  background-color: white;
  position: absolute;
  top: 45 * $rem;
  width: 300 * $rem;
  height: 365 * $rem;
  border: $rem solid #dcdde6;
  color: #dadcea;
  border-radius: 6 * $rem;
  box-shadow: 6 * $rem -7 * $rem 12 * $rem 0 rgba($blue-primary, 0.08);
  padding: 18 * $rem 0;
  user-select: none;
  overflow-y: auto;
  animation: show 0.1s linear;

  input[type='text'] {
    width: calc(100% - 35px);
    margin: 0 0 0 18 * $rem;
    padding-left: 12 * $rem;
    background-color: #fff;
    border-radius: 3 * $rem;
    font-weight: 200;
    font-size: 13 * $rem;
    outline: none;
    color: $blue-primary;
    border: 0.0625rem solid rgba(#283593, 0.2);
    height: 30 * $rem;
    margin-bottom: 5 * $rem;

    &::placeholder {
      color: #9696b9;
      font-weight: 200;
    }
  }
  .department {
    .row {
      display: flex;
      align-items: center;
      padding-left: 0.1125rem;
      padding-right: 1.125rem;
      cursor: pointer;
      .material-icons {
        color: $blue-primary;
      }
      .dep-name {
        color: $blue-primary;
        font-weight: 500;
        margin-right: auto;
      }
    }
  }

  .job {
    font-weight: 300;
    color: #9696b9;
    font-size: 15 * $rem;

    .row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 13 * $rem;
      padding-right: 18 * $rem;
      height: 29 * $rem;
      cursor: pointer;

      & > span.name {
        margin-right: auto;
        font-weight: 400;
        color: $blue-primary;

        &.selected {
          font-weight: 500;
        }
      }

      i {
        font-size: 16 * $rem;
        color: $blue-primary;
      }

      &:hover {
        background-color: #f4f4f8;
      }
    }

    &.all .row {
      justify-content: space-between;
      padding-left: 30 * $rem;

      &:hover {
        background-color: #f4f4f8;
      }
    }

    .child-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13 * $rem;
      font-weight: 300;
      color: rgba($blue-primary, 1);
      padding-left: 42 * $rem;
      cursor: pointer;
      height: 29 * $rem;
      padding-left: 40 * $rem;
      padding-right: 18 * $rem;
      font-size: 14px;

      &:hover {
        background-color: #f4f4f8;
      }
    }
  }

  .selected {
    color: rgba($blue-primary, 0.7);
    font-weight: 500;
  }
}

ul.custom-multi-select-options {
  position: absolute;
  list-style: none;
  background-color: #fff;
  z-index: 150;
  overflow-y: auto;
  min-width: 19.625rem;
  max-height: 17rem;
  background-color: white;
  border: 0.0625rem solid #dcdde6;
  color: #dadcea;
  border-radius: 0.375rem;
  box-shadow: $box-shadow2;
  padding: 12 * $rem 0;
  user-select: none;
  overflow-y: auto;
  animation: show 0.1s linear;

  li {
    min-height: 30 * $rem;
    padding: 0 30 * $rem;
    display: flex;
    align-items: center;
    font-weight: 300;
    cursor: pointer;
    color: rgba($blue-primary, 0.7);

    //&:hover {
    //  background-color: #f4f4f8;
    //}

    &.selected {
      background-color: rgba($blue-primary, 0.05);
    }
  }

  .found {
    font-weight: 500;
    color: $blue-primary;
  }

  @keyframes show {
    0% {
      opacity: 0.5;
    }
  }
}

.in-out-popup {
  background-color: rgba(40, 53, 147, 0.9);
  position: absolute;
  width: 148 * $rem;
  border-left: 4 * $rem solid $blue-primary;
  min-height: 100 * $rem;
  animation: show 0.3s ease-out;
  border-top-right-radius: 20 * $rem;
  border-bottom-right-radius: 20 * $rem;

  &.less-opacity {
    background-color: rgba(#c3c7ef, 0.8);
    border-left-color: #7880c1;
  }

  .header {
    color: #ffffff;
    font-weight: 700;
    font-size: 17 * $rem;
    margin-top: 9 * $rem;
    margin-bottom: 15 * $rem;
    display: flex;

    span:first-of-type {
      margin-left: 25 * $rem;
    }

    span + span {
      margin-right: 28 * $rem;
      margin-left: auto;
    }
  }
  .entry {
    margin-bottom: 7 * $rem;
    font-size: 14 * $rem;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    padding-right: 18px;

    span:first-of-type {
      margin-left: 25 * $rem;
      margin-right: 30 * $rem;
    }
  }

  @keyframes show {
    //do not delete
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.table-filter {
  position: absolute;
  z-index: 10;
  width: 15.625rem;
  max-height: 400 * $rem;
  color: rgba(40, 53, 147, 0.7);
  border: $rem solid #dcdde6;
  border-radius: 6 * $rem;
  box-shadow: 6 * $rem -7 * $rem 12 * $rem 0 rgba($blue-primary, 0.08);
  padding: 18 * $rem 0;
  user-select: none;
  overflow-y: auto;
  background-color: white;
  list-style: none;

  input {
    width: calc(100% - 35px);
    margin: 0 0 12 * $rem 18 * $rem;
    padding-left: 12 * $rem;
    border: 0.0625rem solid rgba(40, 53, 147, 0.2);
    border-radius: 3 * $rem;
    font-weight: 200;
    font-size: 13 * $rem;
    outline: none;
    color: #9696b9;

    height: 30 * $rem;

    &::placeholder {
      color: #9696b9;
      font-weight: 200;
    }
  }

  & > div {
    text-align: right;
    .clear {
      color: #9696b9;
      font-size: 12 * $rem;
      cursor: pointer;
      margin-bottom: 18 * $rem;
      display: inline-block;
      justify-content: flex-end;
      margin-right: 18 * $rem;
      margin-left: auto;
      text-align: right;
    }
  }

  li {
    cursor: pointer;
    padding-left: 30 * $rem;
    padding-right: 30 * $rem;
    color: rgba($blue-primary, 0.5);
    font-weight: 400;
    font-size: 14 * $rem;
    height: 29 * $rem;
    line-height: 29 * $rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &.selected {
      font-weight: 400;
      color: rgba($blue-primary, 0.7);
      background-color: #f4f4f8;
    }
  }
}

.entire-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  .sidebar-and-main-content {
    display: flex;
    position: relative;
    // margin-top: 35px;
    // height: calc(100vh - 35 * #{$rem});
    height: 100vh;
    transition: all 0.3s ease-out;

    &.full {
      margin-top: 0;
      height: 100vh;
    }
  }
}

a.link {
  text-decoration: none;
  color: $blue-primary;
  font-weight: 300;
  font-size: 15 * $rem;
}

.btn-filled {
  height: 29 * $rem;
  padding: 0 16 * $rem 0 16 * $rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13 * $rem;
  font-weight: 300;
  background-color: $blue-primary;
  color: $green-primary;
  border-radius: 14.5 * $rem;
  cursor: pointer;
  user-select: none;
  margin-right: 19 * $rem;
}

.overlay .btn {
  width: 5.6875rem;
  height: 3.125rem;
  background: #6956d6;
  font-size: 1rem;
  color: #fff;
  line-height: 1.3125rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: ease 0.3s;

  &:hover {
    box-shadow: 0 0 0.9375rem rgba(#6956d6, 0.4);
  }
}

button {
  outline: none;
}

* {
  scrollbar-color: #9696b9 rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}

/* Track */
*::-webkit-scrollbar-track {
  border-radius: 0.1875rem;
  background: transparent;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #a4a9d3;
  border-radius: 0.1875rem;
}

*::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}
.tox .tox-editor-container {
  z-index: 100;
}

.active-statement-popup {
  .details-description {
    width: 100%;
    height: 27rem;
    overflow: auto;
    color: $blue-primary;
    font-size: 1rem;
    margin-top: 0.625rem;
  }

  .files-wrapper {
    width: 100%;
    margin-top: 1.25rem;

    span {
      color: $blue-primary;
      font-size: 0.875rem;
      font-weight: 300;
    }

    ul.files-list {
      margin-top: 0.75rem;
      list-style: none;
      display: flex;
      justify-content: left;

      li {
        width: 4.4375rem;
        height: 4.4375rem;
        border-radius: 0.625rem;
        margin-left: 0.5625rem;
        font-size: 1rem;
        display: grid;
        place-items: center;
        color: #fff;
        font-weight: 700;
        position: relative;
        // background-size: 100% 100%;

        span {
          z-index: 1;
          color: #fff;
          font-weight: 700;
        }

        &:first-child {
          margin-left: 0;
        }

        &.uploaded-document {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &[type='pdf'] {
            background-color: #ff6760;
          }

          &[type='xlsx'] {
            background-color: rgba(17, 123, 65, 0.871);
          }
          &[type='PNG'],
          [type='png'] {
            background-color: #1de9b6;
          }
          &[type='SVG'],
          [type='svg'] {
            background-color: $green-primary;
          }
          &[type='jpg'] {
            background-color: #1de9b6;
          }
          &[type='txt'] {
            background-color: #364ba2;
          }
          &[type='jpeg'] {
            background-color: rgba(17, 123, 65, 0.871);
          }

          &[type='docx'],
          &[type='doc'] {
            background-color: #364ba2;
          }

          .file-name {
            margin-top: 0.3125rem;
            width: 3.4rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 0.8125rem;
          }
        }
      }
    }
  }

  .response-btn-wrapper {
    width: 100%;
    margin-top: 3.75rem;
    display: grid;
    place-items: center;
    padding-bottom: 1.25rem;

    button {
      width: calc(100% - (14.0625rem * 2));
      height: 4.6875rem;
      outline: none;
      border: none;
      background: rgba(#6956d6, 0.8);
      border-radius: 0.375rem;
      font-size: 1rem;
      line-height: 1.3125rem;
      color: #fff;
      cursor: pointer;
      transition: ease 0.3s;
      &.triggered {
        background: $green-secondary;
      }
    }
  }
}

.alternative-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  h1 {
    font-size: 2.1875rem;
    color: rgba($blue-primary, 0.5);
  }
}
//// grade global styles
.purple-btn {
  max-width: 13.25rem;
  height: 2.8125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6875rem 1.5625rem;
  background: $purple;
  border-radius: 1.875rem;
  cursor: pointer;
  transition: ease 0.3s;

  &:hover {
    box-shadow: $purpleShadow;
  }

  img {
    width: 1.125rem;
  }
  span {
    color: #ffffff;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 800;
    margin-left: 0.6188rem;
  }
}
</style>
