<template>
    <div class="employee-jobs">
        <form
            @submit.prevent="addJob"
            v-if="permission"
        >
            <input-search
                :options="inputOptions"
                title="Choose Job"
                :isRequired="true"
                @select="job => this.selectedJob = job"
            />
            <date-picker
                v-model="startTime"
                title="Start Date"
            />
            <button class="btn">Add</button>
        </form>
        <grid
            title="Employee Jobs"
            :gridData="personJobs"
            :columns="columns"
            :passedPageSize="PageSize"
            :dataCount="dataCount"
            identificator="ID"
            :pagination="pagination"
            :canEdit="permission"
            :canDelete="permission"
            @rowEdit="editRow"
            @rowDelete="deleteRow"
        />
    </div>
</template>

<script>
import InputSearch from '@/components/ReusableComponents/InputSearch/InputSearch.vue'
import DatePicker from '@/components/ReusableComponents/DatePicker/DatePicker.vue'
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
import axios from '@/Helpers/AxiosInstance.js'
import { getDateString, CETToTimestamp } from '@/Helpers/HelperFunctions.js'
export default {
    components: {
        InputSearch,
        DatePicker,
        Grid,
    },
    props: {
        person: Object,
        permission: {
            type: Boolean,
            default: true,
        },
        pagination: {
            type: Boolean,
            default: true,
        },
        showHistory: Boolean,
    },
    created() {
        this.getEmployeeJobs()
        this.getAllJobs()
    },
    data() {
        return {
            allJobs: [],
            personJobs: [],
            selectedJob: '',
            startTime: Date.now(),
            CurrentPage: 1,
            PageSize: 10,
            OrderByColumn: 'ID',
            Ascending: false,
            dataCount: 0,
            columns: [
                {
                    title: 'ID',
                    key: 'ID',
                },
                {
                    title: 'Job',
                    key: 'JobName',
                },
                {
                    title: 'End Time',
                    key: 'EndTime',
                    customTemplate: (item, val) =>
                        val
                            ? getDateString(CETToTimestamp(val), 'yyyy-MM-dd')
                            : '-',
                    editable: true,
                    editType: 'date',
                },
            ],
        }
    },
    methods: {
        getAllJobs() {
            axios
                .get('jobs/GetJobs', {
                    params: {
                        Page: 1,
                        PageSize: 1000000,
                        OrderByColumn: 'ID',
                        Ascending: false,
                        ShowHistory: this.showHistory,
                    },
                })
                .then(resp => {
                    this.allJobs = resp.data.Value.Jobs
                })
        },
        getEmployeeJobs() {
            axios
                .get('jobs/GetPersonJobs', {
                    params: {
                        Person: this.person.id,
                        Page: this.CurrentPage,
                        PageSize: this.PageSize,
                        OrderByColumn: this.OrderByColumn,
                        Ascending: this.Ascending,
                        ShowHistory: this.showHistory,
                    },
                })
                .then(resp => {
                    if (!resp.data.Value) return
                    this.personJobs = resp.data.Value.map(el => {
                        if (!el.EndTime) el.EndTime = 0
                        return el
                    })
                    this.dataCount = resp.data.Value
                        ? resp.data.Value.length
                        : 0
                })
        },
        addJob() {
            axios
                .post('jobs/AssignPersonjob', {
                    Person: this.person.id,
                    Job: this.selectedJob,
                    StartTime: this.startTime,
                })
                .then(() => {
                    this.getEmployeeJobs()
                })
        },
        editRow(values) {
            values['Deleted'] = false
            axios
                .post('jobs/UpdatePersonJob', values)
                .then(this.getEmployeeJobs)
        },
        deleteRow(ID) {
            var item = this.personJobs.filter(el => el.ID == ID)[0]
            this.$confirm('Would you like to remove this person from the job?').then(() => {
                axios
                    .post('jobs/UpdatePersonJob', {
                        ID: ID,
                        EndTime: item.EndTime,
                        Deleted: true,
                    })
                    .then(this.getEmployeeJobs)
            })
        },
    },
    computed: {
        inputOptions() {
            return this.allJobs
                .filter(job => {
                    if (this.personJobs.find(el => el.ID == job.ID))
                        return false
                    return true
                })
                .map(job => ({
                    title: job.Name,
                    value: job.ID,
                }))
        },
    },
    watch: {
        showHistory(newVal, oldVal) {
            if (newVal != oldVal) this.getEmployeeJobs()
        },
    },
}
</script>

<style lang="scss" scoped>
form {
    display: inline-flex;
    margin: auto;
    margin-bottom: 30 * $rem;

    .input-search,
    label {
        margin-right: 15 * $rem;
    }
}
</style>