<template>
    <div class="container">
        <div class="week-day" :style="{'width': `${cellWidth}rem`}" v-for="day in days" :key="day">
            <span class="day-text" :class="{'today': isToday(day)}">{{ day }}</span>
            <div class="totals" v-if="showTotals(day)">
                <div class="total">
                    <span>Work</span>
                    <span>{{ totalWork(day) }}</span>
                </div>
                <div class="total">
                    <span>Break</span>
                    <span>{{ totalBreak(day) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        cellWidth: Number,
        schedule: Array,
    },
    data() {
        return {
            days: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
            sqlDays: {
                SUN: 1,
                MON: 2,
                TUE: 3,
                WED: 4,
                THU: 5,
                FRI: 6,
                SAT: 7,
            },
        }
    },
    methods: {
        isToday(day) {
            const D = {
                'SUN': 0, 'MON': 1, 'TUE': 2, 'WED': 3, 'THU': 4, 'FRI': 5, 'SAT': 6,
            }
            if(new Date().getDay() == D[day]) return true
            else return false
        },
        totalWork(day) {
            var sqlDay = this.sqlDays[day]
            var schedule = this.schedule.filter(el => el.Day == sqlDay)[0]
            var hr = Math.floor(schedule.TotalWorkTime / (60000 * 60))
            var mm = (schedule.TotalWorkTime / 60000) % 60
            return `${hr} hr ${mm} mm`
        },
        totalBreak(day) {
            var sqlDay = this.sqlDays[day]
            var schedule = this.schedule.filter(el => el.Day == sqlDay)[0]
            var hr = Math.floor(schedule.TotalBreakTime / (60000 * 60))
            var mm = (schedule.TotalBreakTime / 60000) % 60
            return `${hr} hr ${mm} mm`
        },
        showTotals(day) {
            if(this.schedule.length == 0) return false
            var sqlDay = this.sqlDays[day]
            var schedule = this.schedule.filter(el => el.Day == sqlDay)[0]
            if(!schedule) return 0
            return schedule.TotalWorkTime
        },
    },
}
</script>

<style lang="scss" scoped>
    .container {
        background-color: #fff;
        display: inline-flex;
        height: 70 * $rem;
        align-items: center;
        padding-top: 11 * $rem;
        padding-left: 50 * $rem;

        .week-day {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: center;

            span.day-text {
                font-size: 20 * $rem;
                color: $blue-primary;
                font-weight: 300;
            }

            .totals {
                font-size: 14 * $rem;
                font-weight: 300;
                display: flex;
                color: #afafc6;
                justify-content: space-evenly;
                align-items: center;
                width: 100%;

                .total {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }
</style>