<template>
  <div class="overlay" v-show="visible">
    <div
      :class="[
        { flexible: flexibleSize, bigSize: bigSize },
        { alternativeWidth: vacationSize,unscoped: globalComponent,unscopedIframe:forIframe},
      ]"
      class="popup"
      @mousedown.stop
    >
      <div class="closeBtn" @click="$emit('close')">
        <img src="@/assets/icons/red/close_red.svg" alt="">
      </div>
      <h3 class="title">{{ title }}</h3>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    document.addEventListener("keydown", this.handleKeydown);
  },
  props: {
    visible: Boolean,
    title: String,
    flexibleSize: Boolean,
    bigSize: Boolean,
    vacationSize: Boolean,
    globalComponent: Boolean,
    forIframe: Boolean,
  },
  watch: {
    visible(val) {
      if (val) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "initial";
      }
    },
  },
  methods: {
    handleKeydown(e) {
      const event = e || window.event;
      let isEscape;
      if ("key" in event) {
        isEscape = event.key === "Escape" || event.key === "Esc";
      } else {
        isEscape = event.keyCode === 27;
      }
      if (isEscape) {
        this.$emit("close");
      }
    },
  },
  destroyed() {
    document.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>

<style scoped lang="scss">
.overlay {
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  z-index: 100;

  .title {
    margin-bottom: 30px;
    // pointer-events: none;
  }
  .popup {
    height: 530 * $rem;
    width: 835 * $rem;
    border-radius: 6 * $rem;
    border: $rem solid #eeeff7;
    background-color: #fff;
    padding: 25 * $rem 43 * $rem;
    overflow: auto;
    animation: fade .3s forwards;

    @keyframes fade {
        from{
            opacity: 0;
            transform: translateY(0.625rem);
        }
        to{
            opacity: 1;
            transform: translateY(0);
        }
    }

    .closeBtn{
      cursor: pointer;
      position: absolute;
      top: 0.3125rem;
      right: 0.625rem;
      z-index: 99;

      &:hover{
        img{
          opacity: 1;
        }
      }

      img{
        width: 22px;
        opacity: .7;
        transition: ease .2s;
      }
    }

    &.flexible {
      width: initial;
      height: initial;
    }

    h3.title {
      font-size: 20 * $rem;
      color: $blue-primary;
      font-weight: 400;
    }

    &.unscoped{
      width: 80.4375rem;
      height: 52.875rem;
      @media screen and (max-width: 1024px) {
        width: 63.4375rem;
        height: calc(100% - 1rem);
      }
    }
    &.unscopedIframe{
      width: calc(100% - 25rem);
      height: calc(100% - 2rem);
      overflow:hidden;

      h3.title{
          display:none;
      }
    }
  }
}
.overlay {
  .popup.alternativeWidth {
    width: 834 * $rem;
  }
}
.bigSize {
  width: 1395 * $rem !important;
  height: 697 * $rem !important;
  top: 54% !important;
  left: 56% !important;
}
</style>
