import { Radar, mixins} from "vue-chartjs";
const { reactiveProp} = mixins

export default {
  name: 'radar-chart',
  extends: Radar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null,
    },
    triggerChartUpdate:Boolean
  },
  mounted() {
      this.renderChart(this.chartData, this.options)    
  },
  chartData () {
    this.$data._chart.update()
  },
  watch: {
    triggerChartUpdate(){
        this.renderChart(this.chartData, this.options)    
    }
  }
};