<template>
    <div :class="['background',{'locked': personData.length < 1}]" v-dragscroll>
        <div class="comets-wrapper" :style="{'height':elHeight() + 'px'}" ref="commet">
            <div :class="['comets', {'animated':toggleAnimate}]" v-for="c in 2" :key="c">
                <div class="comet" v-for="i in 2" :key="i">
                    <img src="@/assets/grades/personLevels/comet.svg" alt="">
                </div>
            </div>
        </div>
        <div class="particles-wrapper" :style="{height:`${containerSize}px`}">
            <vue-particles
                color="#dedede"
                :particleOpacity="0.4"
                :particlesNumber="36"
                shapeType="circle"
                :particleSize="4"
                linesColor="#dedede"
                :linesWidth="1"
                :lineLinked="false"
                :lineOpacity="0"
                :linesDistance="150"
                :moveSpeed="1"
                :hoverEffect="false"
                hoverMode="bubble"
                :clickEffect="false"
                clickMode="push"
                :style="{height:`${containerSize}px`}"
                v-if="containerSize && containerSize !== null"
            >
            </vue-particles>
        </div>
        <div :class="['background-layer',{'height': personData.length <= 2}]" :style="background">
        <div class="no-level-available" v-if="personData.length < 1 && !loading">
            <div class="container">
                <div class="image">
                    <img src="@/assets/grades/personLevels/ghost.svg" alt="">
                </div>
                <h1>
                    Your Grade Level
                    <br>
                    Is Not Specified Yet
                </h1>
            </div>
        </div>
            <div class="single-planet-container" v-for="(i,ind) in personData" :key="ind">
                <div class="planet" ref="planet">
                    <div class="glow" :style="{'box-shadow':`0 0 15rem 12rem ${setOpacity(planetColor(i.planet),.4)}`}"></div>
                    <div class="label-wrapper" v-if="i.IsCurrentGradePart">
                        <div class="wrapper" @click="showBadge(i.GradePartID)">
                            <div class="layer pointer" v-if="!i.badgeVisible"></div>

                            <div v-else class="badge-info" :style="{'border':`0.125rem solid ${setOpacity(planetColor(i.planet),.5)}`}">
                                <span :style="{'color':`${planetColor(i.planet)}`}">You Are Here</span>
                                <div class="badge" :style="{'background-image':`url(${getBadge(i.GradeID)})`}">
                                </div>
                                <span>
                                    {{i.BadgeName}}
                                </span>
                            </div>
                        </div>
                    </div>
                        <div class="order-label" :style="{border: `0.0625rem solid ${planetColor(i.planet)}`}">
                            <div class="order">
                                <span :style="{color:planetColor(i.planet),textShadow: `1px 1px 11px ${planetColor(i.planet)}`}">
                                    <!-- {{i.GradePartLevel}} -->
                                    {{personData.length - ind}}
                                </span>
                            </div>
                            <div class="details">
                                <div class="details-list" :style="[
                                        {borderLeft: `0.125rem solid ${setOpacity(planetColor(i.planet),.3)}`},
                                        {borderBottom: `0.125rem solid ${setOpacity(planetColor(i.planet),.3)}`}
                                    ]"
                                >
                                    <ul>
                                        <li>
                                            <span class="title" :style="{color:planetColor(i.planet)}" > 
                                                Grade
                                            </span>
                                            <span class="value">
                                                {{i.GradeDescription}}
                                            </span>
                                            <div class="value">
                                                Level: {{ i.GradePartDescription }}
                                            </div>
                                        </li>
                                        <li>
                                            <span class="title" :style="{color:planetColor(i.planet)}">
                                                Period
                                            </span>
                                            <span class="value">
                                                {{i.RatingPeriodName}}
                                            </span>
                                        </li>
                                        <li class="withCurrency">
                                            <span class="title" :style="{color:planetColor(i.planet)}">
                                                Range
                                            </span>
                                            <div class="curr">
                                                <span class="value">
                                                    {{
                                                        `${i.currentSalary.MinSalary}${i.currentSalary.Code} - ${i.currentSalary.MaxSalary}${i.currentSalary.Code}`
                                                    }}
                                                </span>
                                                <div v-if="i.Salaries.length > 1" class="switch-curr" @click="switchCurrency(i.GradePartID)">
                                                    <img src="@/assets/grades/switch.svg" alt="">
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    <div class="image">
                        <img :src="require(`@/assets/grades/personLevels/planets/${i.planet}.svg`)" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance.js";
import { dragscroll } from "vue-dragscroll";
export default {
    directives: {
        dragscroll,
    },
    data(){
        return{
            background:{
                backgroundImage: `url(${require('@/assets/grades/personLevels/bg.svg')})`
            },
            personData: [],
            loading: false,
            toggleAnimate: true,
            planets:[
                {
                    color: '#1DE9B6',
                    id: 1,
                },
                {
                    color: '#FFA733',
                    id: 2,
                },
                {
                    color: '#FF3D86',
                    id: 3,
                },
                {
                    color: '#1DE9B6',
                    id: 4,
                },
                {
                    color: '#FFA733',
                    id: 5,
                },
                {
                    color: '#1DE9B6',
                    id: 6,
                },
                {
                    color: '#FF3D86',
                    id: 7,
                },
                {
                    color: '#D474FF',
                    id: 8,
                },
                {
                    color: '#FFA733',
                    id: 9,
                },
                {
                    color: '#1DE9B6',
                    id: 10,
                },
            ],
            containerSize: null,
        }
    },
    created() {
        this.getPersonGrades()
        this.toggleAnimationInterval()
    },
    methods:{
        getPersonGrades(){
            this.loading = true
            axios.post('grading/GetGradingTimeLine',{})
            .then(resp => {
                let data = resp.data.Value.GradesWithSalaries
                let counter = 1
                data.forEach(el => {
                    if(counter > 8){
                        counter = 1
                        el['planet'] = counter++
                    }else{
                        el['planet'] = counter++
                    }
                    this.$set(el,'x',this.generateRandomMovement(true,false))
                    this.$set(el,'y',this.generateRandomMovement(false,true))
                    this.$set(el,'badgeVisible',false)
                    this.$set(el,'currentSalary',el.Salaries[0] && el.Salaries[0] ? el.Salaries[0] : null)
                })
                this.personData = data.sort((a,b) => a.GradeLevel - b.GradeLevel)
                this.loading = false

                this.$nextTick(() => {
                    this.containerSize = document.querySelector('.background').scrollHeight
                    // this.scrolltoBottom('.background')
                })
            })
            .catch(err => {
                this.loading = false
            })
        },
        generateRandomMovement(x,y){
            let xAxis = Math.abs(Math.ceil(Math.random() * 50))
            let yAxis = Math.abs(Math.ceil(Math.random() * 50))
            if(x) return xAxis
            if(y) return yAxis
        },
        setOpacity(hex,alpha){
            return `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`
        },
        planetColor(id){
            let color = this.planets.find(el => el.id === id).color
            return color
        },
        switchCurrency(partID){
            let currentPart = this.personData.filter(el => el.GradePartID === partID)[0]
            let currentCurrCode = currentPart.currentSalary.Code
            if(currentCurrCode && currentPart.Salaries.length > 0 && currentPart.Salaries.length > 1){
                let currObjInd = currentPart.Salaries.findIndex((el) => el.Code === currentCurrCode)

                if(currentPart.Salaries[currObjInd + 1] && currentPart.Salaries[currObjInd + 1] !== undefined){
                    this.personData.find(el => el.GradePartID === partID).currentSalary = currentPart.Salaries[currObjInd + 1]
                    this.log('SALARY OBJECT',currentPart.Salaries[currObjInd + 1])
                }else{
                    this.personData.find(el => el.GradePartID === partID).currentSalary = currentPart.Salaries[currObjInd - 1]
                }
            }else return
        },
        getBadge(id){
            let isProd = location.href.indexOf('.upgaming.com') > 0;
            let reqSrc
            if(isProd){
                reqSrc = 'https://intranet.upgaming.com/api/grading/GetGradeBadgeNameIcon?GradeID='
            }else{
                reqSrc = 'https://intranet.upgaming.dev/api/grading/GetGradeBadgeNameIcon?GradeID='
            }

            return reqSrc + id
        },
        showBadge(partID){
            this.personData.forEach(el => {
                if(el.GradePartID === partID) el.badgeVisible = !el.badgeVisible
                else{
                    el.badgeVisible = false
                }
            })
        },
        scrolltoBottom(domElement){
            let domEl = document.querySelector(domElement) 
            domEl.scrollTo({top: domEl.scrollHeight,behavior:'smooth'})
        },
        elHeight(){
            this.$nextTick(() => {
                return this.$refs.commet.scrollHeight
            })
        },
        toggleAnimationInterval(){
            setInterval(() => {
                this.toggleAnimate = !this.toggleAnimate
            }, 5000);
        },
    }
}
</script>

<style lang="scss" scoped>

*{
    font-family: Roboto,firaGo;
}

.background{
    background-color: transparent;
    margin-left: 0 !important;
    background-color: #30175C;


    &.locked{
        overflow-y: hidden !important;
    }
}

.particles-wrapper{
    top: 0;
    width: calc(100% - 14rem);
    left: 0;
    position: absolute;
    z-index: 1;
    pointer-events: none;
}

@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));
  @return $randomNum;
}

.no-level-available{
    width: 23.0625rem;
    height: 18.25rem;
    // background: rgba(#fff,.2);
    background: rgba(86,178,172,0.2);
    border-radius: 1.5625rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    .container{
        width: 100%;
        height: 100%;
        @include flex(center,center,column);
        text-align: center;
        user-select: none;
        pointer-events: none;

        .image{
            width: 8rem;
            height: 8rem;
            border-radius: 50%;
            @include centerElement;
            // background: rgb(78,45,100);
            // background: radial-gradient(circle, rgba(78,45,100,1) 17%, rgba(51,28,97,1) 100%);
            background: radial-gradient(circle, #241140 17%, #331c61 100%);

            // filter: drop-shadow(0 0 10px rgb(78,45,100));
            img{
                width: 5.5625rem;
            }
        }

        h1{
            margin-top: 0.9375rem;
            font-size: 1.875rem;
            line-height: 2.5rem;
            color: #EDEDFC;
            font-weight: 700;
        }
    }
}

.comets-wrapper{
    width: 100%;
    // height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 20;
    overflow: hidden;
    pointer-events: none;
    @include flex(center,center,row);
    padding-left: 50%;
    .comets{
        width: calc(100% - 10rem);
        margin-top: 5rem;
        position: relative;
        .comet{
            width: 100%;
            height: 25rem;
            opacity: 0;
            transform: rotate(-25deg) !important;

            img{
                box-shadow: 0.125rem 0.125rem 24rem 6rem rgba(#10FCF5,.1);
                width: 7.8125rem;
            }
                &:nth-child(even){
                    transform: translate(45%,20%) rotate(-25deg);
                }
        }

        &.animated{
            transform: rotate(-25deg) !important;
            &:first-child{
                .comet{
                    @for $i from 1 through 10 {
                        &:nth-child(#{$i}) {
                            animation: 1s dropComet#{$i} infinite;
                            $delay: random(5000) + 300ms;
                            animation-delay: $delay;
                            
                            @keyframes dropComet#{$i} {
                                0%,20%{
                                    transform: translateX(0) translateY(0) rotate(-25deg);
                                    opacity: 0;
                                }
                                0%,20%,30%,50%,70%,80%{
                                    transform: translate(-100%,random(700) + px) rotate(-25deg);
                                    opacity: .1;
                                }
                                100%{
                                    transform: translate(calc(-100% - 10rem),268.75rem) rotate(-25deg);
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }
            }
    
            &:last-child{
                .comet{
                    @for $i from 1 through 10 {
                        &:nth-child(#{$i}) {
                            animation: 1s dropComet#{$i} infinite steps(120);
                            $delay: random(9000) + 300ms;
                            animation-delay: $delay;
                            
                            @keyframes dropComet#{$i} {
                                // 0%,20%,30%{
                                //     transform: translateX(0) translateY(0);
                                //     opacity: 0;
                                // }
                                0%,20%,30%,50%,70%,80%{
                                    transform: translate(-90%,-random(300) + px);
                                    opacity: .1;
                                }
                                90%,100%{
                                    transform: translate(calc(-100% - 20rem),100%);
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }   
            }

        }
    }
}

.background-layer{
    width: 100%;
    position: absolute;
    top: 3.0625rem;
    right: 0;
    background-size: cover;
    padding-bottom: 20rem;
    background-repeat: repeat-y;

    &.height{
        height: 100%;
    }

    .single-planet-container{
        width: calc(100% - 32rem);
        margin: auto;
        margin-top: 1.5625rem;
        @include centerElement;
        @include flex(flex-start,center,row);
        position: relative;
        z-index: 9;

        &:nth-child(even){
            @include flex(flex-end,center,row);
            .planet{

                // &::before{
                //     content: '';
                //     width: 100%;
                //     height: 100%;
                //     position: absolute;
                // }
                &:hover{
                    .order-label{
                        .details{
                            right: 100%;
                        }
                    }
                }
            }

            .order-label{
                right: unset !important;
                left: 0;


            }
            .details{
                left: unset !important;
                right: -50%;

                .details-list{
                    margin-left: -1.5625rem !important;
                }
            }
        }

        @for $i from 1 through 100 {
            &:nth-child(#{$i}) {
                animation: linear 15s bg24#{$i} infinite alternate;
                $delay: random(500) + 0ms;
                animation-delay: $delay;
                
                @keyframes bg24#{$i} {
                    
                    0%{
                        transform: translate(random(50) + px,random(40) + px);
                    }
                    20%{
                        transform: translate(random(50) + px,random(25) + px);
                    }
                    50%{
                        transform: translate(random(70) + px,random(20) + px);
                    }
                    70%{
                        transform: translate(random(40) + px,random(25) + px);
                    }

                    100%{
                        transform: translate(0,0);
                    }
                }
            }
        }

        .planet{
            position: relative;
            width: 25rem;
            height: 25rem;
            $random: random(40) + px;

            .glow{
                width: 0;
                height: 0;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
            &:hover{
                .order-label{

                    .details{
                        opacity: 1 !important;
                        pointer-events: all;
                        transform: scaleX(1);
                        left: 100%;
                        top: 1rem;
                    }
                }
            }


            .label-wrapper{
                position: absolute;
                z-index: 9;
                top: 0;
                left: 50%;
                transform: translate(-50%,-50%);
                cursor: pointer;
                
                .wrapper{
                    width: 2.5rem;
                    height: 2.5rem;
                    position: relative;

                    .badge-info{
                        position: absolute;
                        top: -5rem;
                        left: -4rem;
                        width: 11rem;
                        height: 11rem;
                        @include flex(center,center,column);
                        border-radius: 50%;
                        background: rgba(#ffffff,.2);

                        .badge{
                            width: 2.5rem;
                            height: 2.5rem;
                            @include centerElement;
                            border-radius: 50%;
                            overflow: hidden;
                            background-size: cover;
                            background-position: center;
                        }
                        span{
                            font-weight: 600;
                            &:first-child{
                                font-size: 1.1875rem;
                                padding-bottom: 0.5rem;
                            }
                            &:last-child{
                                padding-top: 0.5rem;
                                font-size: 1.125rem;
                                color: #fff;
                            }
                        }
                    }

                    @keyframes pulse1 {
                        0%,20%{
                            transform: scale(1.3);
                        }
                        50%,70%{
                            transform: scale(1.5);
                        }
                        100%{
                            transform: scale(1.3);
                        }
                    }

                    @keyframes pulse2 {
                        0%,20%{
                            transform: scale(1.7);
                        }
                        50%,70%{
                            transform: scale(1.9);
                        }
                        100%{
                            transform: scale(1.7);
                        }
                    }

                    & > div{
                        position: relative;
                        top: 0;
                        transition: all .3s;
                    }
                    .layer{
                        width: 100%;
                        height: 100%;
                        // background: rgba(#ffffff,.4);
                        background: rgb(255,255,255);
                        background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0.3701855742296919) 100%);
                        border-radius: 50%;
                        border-bottom-left-radius: 10%;
                        border-top-left-radius: 50%;
                        border-top-right-radius: 50%;
                        transform: rotate(312deg) scale(1.3);

                        &::before{
                            content: '';
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            border-bottom-left-radius: 10%;
                            border-top-left-radius: 50%;
                            border-top-right-radius: 50%;
                            transform: scale(1.4);
                            opacity: .2;
                            position: absolute;
                            z-index: -1;
                            transition-delay: .1s;
                            animation: pulse1 2s infinite  steps(35);
                            animation-delay: .1s;
                            background: rgb(255,255,255);
                            background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0.3701855742296919) 100%);

                        }
                        &::after{
                            content: '';
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            border-bottom-left-radius: 10%;
                            border-top-left-radius: 50%;
                            border-top-right-radius: 50%;
                            transform: scale(1.7);
                            opacity: .5;
                            position: absolute;
                            z-index: -1;
                            animation: pulse2 1.8s infinite  steps(35);
                            animation-delay: .2s;
                            background: rgb(255,255,255);
                            background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0.3701855742296919) 100%);
                        }
                    }
                }
            }

            .image{
                transition: ease .2s;
                img{
                    width: 25rem;
                    user-select: none;
                    pointer-events: none;
                }
            }


            .order-label{
                position: absolute;
                width: 3.75rem;
                height: 3.75rem;
                @include centerElement;
                border-radius: 50%;
                background: rgba(#fff,.19);
                top: 20%;
                right: 0;

                .order{
                    span{
                        user-select: none;
                        color: #fff;
                        font-size: 1.75rem;
                        font-weight: 900;
                    }
                }

                .details{
                    width: 15.5rem;
                    min-height: 5rem;
                    max-height: 10rem;
                    position: absolute;
                    top: 0;
                    left: -50%;
                    transition: all .4s;
                    pointer-events: none;
                    transform: scaleX(0);
                    opacity: 0;


                    .details-list{
                        margin-left: 1.5625rem;
                        width: 100%;
                        background: rgba(#fff, .18);
                        padding: 1.5625rem 1.625rem;
                        border-radius: 1.5625rem;
                        background: rgb(255,255,255);
                        background: linear-gradient(90deg, rgba(255,255,255,0.12368697478991597) 2%, rgba(255,255,255,0.1516981792717087) 51%, rgba(255,255,255,0.08727240896358546) 100%);
                        box-shadow: inset 0 0 40px 30px rgba(#fff,.1);

                        ul{
                            list-style-type: none;

                            li{
                                &:first-child{
                                    margin-top: 0;
                                }
                                margin-top: 0.9375rem;
                                @include flex(left,left,column);

                                .title{
                                    font-size: 1.0625rem;
                                }

                                .value{
                                    margin-top: 0.25rem;
                                    color: #ffffff;
                                    font-size: 1rem;
                                }

                                &.withCurrency{
                                    .curr{
                                        @include flex(flex-start,center,row);
                                    }
                                    .value{
                                        white-space: nowrap;
                                    }
                                    .switch-curr{
                                        margin-left: 0.375rem;
                                        img{
                                            margin-top: 0.5rem;
                                            cursor: pointer;
                                            width: 1.375rem;
                                            filter: invert(100%) sepia(0%) saturate(7438%) hue-rotate(185deg) brightness(106%) contrast(94%);
                                        }
                                    }
                                }
                            }


                        }
                    }
                }
            }
        }
    }
}

::v-deep .site-content{
    margin-top: 0 !important;
}

</style>