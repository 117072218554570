<template>
    <calendar @update:to-page="test" :attributes="attributes" @dayclick="onDayClick" />
</template>

<script>
import axios from '@/Helpers/AxiosInstance'
export default {
    created() {
        // this.getHolidays()
    },
    props: {
        days: Array,
    },
    data() {
        return {
            holidays: [],

            city: '',
        }
    },
    computed: {
        dates() {
            return this.days.map((day) => day)
        },
        attributes() {
            return this.days.map((date) => ({
                highlight: true,
                dates: date,
            }))
        },
    },
    methods: {
        onDayClick(day) {
            const idx = this.days.findIndex((d) => d === day.id)

            if (idx >= 0) {
                this.$emit('remove-date', { date: day.id, fulldate: day.date })
            } else {
                this.$emit('add-date', { date: day.id, fulldate: day.date })
            }
        },
        getHolidays() {
            var currentYear = new Date().getFullYear()
            var id
            if (this.city == '') {
                id = 1
            } else {
                id = this.newHoliday.city
            }

            axios.get(`vacations/GetHolidays?CountryID=${id}&Year=${currentYear}`).then((resp) => {
                this.holidays = resp.data.Value.map((h) => ({
                    name: h.Name,
                    date: new Date(h.FromDate),
                }))
            })
        },
        test(val) {
            this.$emit('monthIndex', val.month, val.year)
        },
    },
}
</script>

<style lang="scss" style scoped>
.vc-container {
    border: 0;
    font-weight: 400;
}

.vc-header {
    padding: 0 10px 0 10px;
    font-weight: 400;
}

::v-deep {
    .vc-pane-container {
        .vc-title {
            color: $blue-primary;
            font-weight: 400;
            opacity: 0.8;
        }
        .vc-header {
            padding: 2px 10px 0 10px;
            font-weight: 400;
        }
        .vc-arrows-container {
            padding-top: 0;
            color: $blue-primary;
        }
        .vc-arrow {
            color: $blue-primary;
            opacity: 0.7;
        }
        .vc-weekday {
            color: $blue-primary;
            opacity: 0.7;
            font-weight: 500;
        }
        .vc-day-content {
            color: $blue-primary;

            font-family: 'Roboto';
            font-weight: 300;
        }
        .vc-highlight {
            background-color: $green-primary !important;
            // opacity: 0.6 !important;
        }
        // .vc-grid-container {
        //   .vc-highlights {
        //     .vc-day-layer {
        //       .vc-highlight {
        //         // background-color: $blue-primary;
        //       }
        //     }
        //   }
        // }
    }
}
</style>