<template>
  <div :class="['nodes-container', { 'nodes-container--assign': forAssign }]">
    <permission-nodes-item
      :for-assign="forAssign"
      :node="node"
      @select-node="(node) => $emit('select-node', node)"
      @add-group="(args) => $emit('add-group', args)"
      @remove-group="(args) => $emit('remove-group', args)"
      :selected-permissions="selectedPermissions"
      :value="selectedPermissions.has(node.ID)"
      @updatePermissions="$emit('update-permissions')"
      @select-permission-from-node="
        (args) => $emit('select-permission-from-node', args)
      "
      @edit-node="(node) => $emit('edit-node', node)"
      v-for="(node, key) in nodes"
      :key="key"
    />
  </div>
</template>

<script>
import PermissionNodesItem from "@/components/Permissions/PermissionNodes/PermissionNodesItem";

export default {
  components: { PermissionNodesItem },
  props: {
    selectedPermissions: Set,
    forAssign: Boolean,
    nodes: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.nodes-container {
  margin-left: -40px;
  margin-top: 25px;
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  width: calc(100% + 40px);

  &--assign {
    padding-right: 10px;
    width: calc(100% + 50px);
    height: 350px;
    overflow-y: auto;
  }
}
</style>
