<template>
  <div class="background" :class="{ termsVisible: termsVisible }">
    <section class="header-section">
      <div>
        <span>Terms</span>
        <div class="terms-popup-button" @click="termsVisible = !termsVisible">
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div style="width: 350px;"></div>
        <div
          class="showVactaionSwichClass"
          @click=";(showVactaionSwich = !showVactaionSwich), getDaysLeft()"
        >
          <img src="@/assets/grades/switch.svg" />
          <span v-if="showVactaionSwich">Day Off</span>
          <span v-else>Vacation</span>
        </div>
      </div>
      <div class="request-vacation-button" @click="popupVisibleClick(true)">
        <span>Request Vacation</span>
      </div>
      <div class="terms-popup-content">
        <div class="table-header">
          <span>Assigned terms</span>
        </div>
        <vacation-terms-table :userView="true" :data="personRestrictions" />
        <p>Standard rules</p>
        <div class="rules-content">
          <div
            v-for="type in vacationRules.Types"
            :key="type.index"
            class="single-rule-content"
          >
            <p>{{ type.Type }}</p>
            <div
              class="definitions-content"
              v-for="def in type.Descriptions"
              :key="def.index"
            >
              <div class="definition">
                <span class="def-name">{{ def.Definition }}</span>
                <span class="def-text">{{ def.Text }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <custom-popup
      :visible="popupVisible"
      @close="popupVisible = false"
      title="Request Vacation"
    >
      <form>
        <div class="form-div">
          <div class="select-wrapper">
            <custom-select
              title="Select Type"
              v-model="selectedVacationType"
              :options="vacationTypeOptions"
            />
          </div>
          <date-range
            :initialFrom="dateRange.fromDate"
            :initialTo="dateRange.toDate"
            @from="(from) => (dateRange.fromDate = from)"
            @to="(to) => (dateRange.toDate = to)"
          />
          <div class="total-days">
            Total Days
            <div v-if="getVacationsTotalDays() > 0">
              {{ getVacationsTotalDays() }}
            </div>
          </div>
        </div>
        <div class="row">
          <custom-text-area
            class="customtxt-area"
            title="Comment"
            v-model="requestVacationComment"
          />
        </div>
      </form>
      <button @click.stop="submitButtonFlag ? createVacation() : ''">
        Send Request
      </button>
    </custom-popup>

    <section class="calendar-section">
      <div class="days-left-content" v-if="!showVactaionSwich">
        <div class="left-side">
          <span>Days</span>
          <span>Left</span>
        </div>
        <div class="right-side">
          <div class="day-count-content">
            <svg viewBox="0 0 36 36" class="circular-chart orange">
              <path
                class="circle-bg"
                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <path
                class="circle"
                :stroke-dasharray="daysLeftPerc + ', 100'"
                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
              />
            </svg>

            <span class="daysLeft">{{ daysLeft }}</span>
          </div>

          <div class="period-content">
            <span>Period From Last Vacation</span>
            <span v-if="data.length">{{ periodFromLastVacation }}</span>
            <span v-else>-</span>
          </div>
        </div>
      </div>

      <div class="days-left-content" v-if="showVactaionSwich">
        <div class="left-side">
          <span>Days</span>
          <span>Left</span>
        </div>
        <div class="right-side">
          <div class="day-count-content">
            <svg viewBox="0 0 36 36" class="circular-chart orange">
              <path
                class="circle-bg"
                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <path
                class="circle"
                :stroke-dasharray="daysLeftPerc + ', 100'"
                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
              />
            </svg>

            <span class="daysLeft">{{ daysLeft }}</span>
          </div>

          <div class="period-content">
            <span>Period From Last Vacation</span>
            <span v-if="data.length">{{ periodFromLastVacation }}</span>
            <span v-else>-</span>
          </div>
        </div>
      </div>

      <div class="wrap">
        <div class="holiday-calendar-title">
          <span>Holidays</span>
        </div>
        <holiday-calendar />
      </div>
    </section>
    <p class="my-requests-title">My requests</p>
    <section class="table-section">
      <vacation-user-table
        :requests="data"
        :canEdit="canEdit"
        @active-delete="deleteVacation"
        @active-edit="editVacation"
        @checked="checkedEdition"
        @cancel="cancelEdition"
      />
    </section>
    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      @close="snackbarVisible = false"
      :text="snackbarText"
    />
  </div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance.js'
import VacationUserTable from './VacationUserTable'
import HolidayCalendar from '@/components/vacationUser/HolidayCalendar'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import VacationTermsTable from '@/components/VacationAdmin/VacationTermsTable'

import {
  timestampToCET,
  checkPermission,
  getDateString,
  CETToTimestamp,
} from '@/Helpers/HelperFunctions.js'

import CustomPopup from '../ReusableComponents/CustomPopup/CustomPopup.vue'
import CustomSelect from '../ReusableComponents/CustomSelect/CustomSelect.vue'
import DateRange from '@/components/ReusableComponents/DateRange/DateRange'
import CustomTextArea from '@/components/ReusableComponents/CustomTextArea/CustomTextArea.vue'

export default {
  components: {
    VacationUserTable,
    HolidayCalendar,
    CustomPopup,
    CustomSelect,
    DateRange,
    CustomSnackbar,
    VacationTermsTable,
    CustomTextArea,
  },
  created() {
    this.person = this.$store.state.profile.ID
    this.$store.state.showTimeLine = true

    this.getData()
    this.getVacationtypes()
    this.getDaysLeft()
    this.getPersVacRestrictions()
    this.getVacationRules()
    this.getLastVacatioDate()
  },
  data() {
    return {
      person: '',
      lastVacationDate: '',
      data: [],
      vacation: true,
      commentVisible: false,
      snackbarText: '',
      personRestrictions: [],
      vacationRules: [],
      snackbarVisible: false,
      canEdit: false,
      popupVisible: false,
      vacationTotalDays: '',
      daysLeft: '',
      daysLeftPerc: '',
      annualQuantity: '',
      vacationTypes: [],
      selectedVacationType: '',
      requestVacationComment: '',
      termsVisible: false,
      submitButtonFlag: false,
      dateRange: {
        fromDate: 0,
        toDate: 0,
      },
      showVactaionSwich: false,
      showVactaionType: null,
    }
  },
  computed: {
    paidDaysLeftText() {
      let number
      var test = []
      this.data.forEach((el) => {
        number = el.LeftDaysOfPaidVacation
      })
      return number
    },
    vacationTypeOptions() {
      return this.$store.getters.hasRole('Manager')
        ? this.vacationTypes
            .filter((el) => el.ID !== 6)
            .map((el) => ({
              title: el.Name,
              value: el.ID,
            }))
        : this.vacationTypes
            .filter((el) => el.ID !== 6 && el.ID !== 8)
            .map((el) => ({ title: el.Name, value: el.ID }))
      //  store.getters.hasRole('Manager')
    },
    periodFromLastVacation() {
      let date
      if (this.data.length > 0) {
        let lastVacation = this.lastVacationDate
        let dateNow = Date.now()
        date = this.getDurationString(dateNow, lastVacation)
      }
      return date
    },
  },
  methods: {
    popupVisibleClick(status) {
      this.submitButtonFlag = true
      this.selectedVacationType = ''
      this.vacationTotalDays = ''
      this.dateRange.fromDate = 0
      this.dateRange.toDate = 0
      this.popupVisible = status
    },
    getData(id) {
      id = this.person
      axios.get(`vacations/GetPersonsVacations?PersonID=${id}`).then((resp) => {
        this.data = resp.data.Value
        this.data.forEach((el) => {
          this.$set(el, 'commentVisible', false)
          this.$set(el, 'canEdit', false)
        })
      })
    },
    getLastVacatioDate() {
      axios
        .get(`vacations/GetPersonsLastPaidVacation?Person=${this.person}`)
        .then((resp) => {
          this.lastVacationDate = resp.data.Value[0].LastVacationDate
        })
    },
    getPersVacRestrictions(id) {
      id = this.person
      axios
        .get(`vacations/GetPersonVacationRestrictions?PersonID=${id}`)
        .then((resp) => {
          this.personRestrictions = resp.data.Value.PersonRestrictions
          this.personRestrictions.forEach((el) => {
            this.$set(el, 'canEdit', false)
          })
        })
    },
    getVacationRules() {
      axios.get('vacations/GetVacationTerms').then((resp) => {
        this.vacationRules = resp.data.Value
      })
    },

    getDaysLeft(id) {
      id = this.person
      this.showVactaionSwich
        ? (this.showVactaionType = 3)
        : (this.showVactaionType = 1)
      axios
        .get(
          `vacations/GetPersonsVacationsTypesDaysLeft?PersonID=${id}&VacationTypeID=${this.showVactaionType}`,
        )
        .then((resp) => {
          this.daysLeft = resp.data.Value[0].DaysLeft
          this.annualQuantity = resp.data.Value[0].AnnualQuantity
          this.daysLeftPerc = this.daysLeft * (100 / this.annualQuantity)
        })
    },
    getVacationtypes() {
      axios.get('vacations/Types').then((response) => {
        this.vacationTypes = response.data.Value
      })
    },
    createVacation() {
      this.submitButtonFlag = false
      axios
        .post('vacations/CreateVacation', {
          Person: this.person,
          VacationType: this.selectedVacationType,
          FromDate: this.dateRange.fromDate,
          ToDate: this.dateRange.toDate,
          Comment: this.requestVacationComment,
        })
        .then((resp) => {
          if (resp.data.ErrorMsg) return
          this.snackbarVisible = true
          this.snackbarPurpose = 'success'
          this.snackbarText = 'Request was successfully sent!'
          this.popupVisible = false
          this.getData()
        })
    },

    getVacationsTotalDays(person, type, fromDate, toDate) {
      person = this.person
      type = this.selectedVacationType
      fromDate = getDateString(this.dateRange.fromDate)
      toDate = getDateString(this.dateRange.toDate)

      if (
        this.selectedVacationType &&
        this.dateRange.fromDate &&
        this.dateRange.toDate
      ) {
        axios
          .get(
            `vacations/GetPersonsVacationsTotalDays?PersonID=${person}&VacationType=${type}&FromDate=${fromDate}&ToDate=${toDate}`,
          )
          .then((resp) => {
            this.vacationTotalDays = resp.data.Value[0].TotalDays
          })
      }

      return this.vacationTotalDays
    },

    getDurationString(end, start) {
      var duration = Math.abs(start - end)
      var fullDays = parseInt(duration / (24 * 3600000))
      var days = fullDays % 30
      var months = parseInt(fullDays / 30)
      var res = ''
      if (months) {
        if (res) res += ', '
        res += `${months}month`
      }
      if (days) {
        if (res) res += ' & '
        res += `${days}days`
      }
      return res
    },

    // ///////////////////////////////////////////////////////////////////////////////////////////////
    deleteVacation(vacation) {
      this.$confirm('Are you sure you want to delete request?').then(() => {
        axios
          .post('vacations/UpdateVacation', {
            VacationID: vacation.VacationID,
            Deleted: true,
          })
          .then((resp) => {
            this.getData()
          })
      })
    },
    editVacation(vacation) {
      vacation.canEdit = true
    },
    checkedEdition(vacation, newVacation) {
      axios
        .post('vacations/UpdateVacation', {
          VacationID: vacation.VacationID,
          Deleted: null,
          FromDate: newVacation.newVacationFromDate
            ? newVacation.newVacationFromDate
            : null,
          ToDate: newVacation.newVacationToDate
            ? newVacation.newVacationToDate
            : null,
          Type: newVacation.newVacationType
            ? newVacation.newVacationType
            : null,
          Comment: vacation.Comment,
        })
        .then((resp) => {
          this.getData()
          vacation.canEdit = false
          ;(newVacation.newVacationFromDate = ''),
            (newVacation.newVacationToDate = ''),
            (newVacation.newVacationType = '')
        })
    },
    cancelEdition(vacation) {
      vacation.canEdit = false
    },
  },
}
</script>

<style lang="scss" scoped>
.background {
  padding: 35px 45px 0 45px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &.termsVisible {
    .terms-popup-button {
      background: #fff;
      height: 44px;
      width: 57px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 10px 10px 0 0;
      z-index: 9;
      position: relative;
      border: 1px solid #e0dff4;
      border-bottom: 0;
    }
    .terms-popup-content {
      height: 82vh !important;
      visibility: visible;
      z-index: 99;
    }
  }
}
.rules-content {
  font-size: 15px;
  height: 520px;
  overflow: auto;
  margin-top: 30px;
  p {
    margin-bottom: 20px;
    font-weight: 700;
  }
  .definitions-content {
    margin-bottom: 20px;
    .definition {
      display: flex;

      .def-name {
        width: 350px;
      }
      .def-text {
        width: 900px;
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
}

.header-section {
  display: flex;
  justify-content: space-between;
  color: $blue-primary;
  font-size: 20px;
  align-items: center;
  position: relative;
  height: 43px;
  > div:nth-child(2) {
    margin-left: -100px;
  }
  > div:nth-child(1) {
    display: flex;
    align-items: center;
    span {
      width: 74px;
    }
  }
  .showVactaionSwichClass {
    cursor: pointer;
    color: #283593;
    font-size: 20px;
    img {
      width: 25px;
      margin-right: 20px;
    }
  }
  .request-vacation-button {
    width: 174 * $rem;
    height: 2.3125rem;
    background: rgba($blue-primary, 0.6);
    border-radius: 0.1875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      color: #ffffff;
      font-size: 16px;
    }
  }
  .terms-popup-button {
    cursor: pointer;
    height: 44px;
    width: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    div {
      height: 2px;
      background: rgba($blue-primary, 0.3);
      width: 30px;
      margin-bottom: 3px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .terms-popup-content {
    position: absolute;
    height: 0;
    z-index: 5;
    background-color: #fff;
    border-radius: 10px;
    top: 4.4vh;
    width: 100%;
    border: 1px solid #e0dff4;
    transition: 0.8s ease;
    visibility: hidden;
    padding: 0 45px 0 45px;
    overflow: hidden;
    .table-header {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
      margin-top: 35px;
      position: relative;
      top: 44px;

      span {
        font-size: 1.1875rem;
      }
    }
    >>> .tbody-wrapper {
      height: 105px;
      overflow: auto;
    }
  }
}
.table-section {
  height: 50%;
  .user-table-content {
    height: 340px;
    padding-bottom: 3rem;
  }
}

.my-requests-title {
  color: #283593;
  font-size: 20px;
  margin-bottom: 10px;
}
.form-div {
  display: flex;
  justify-content: space-between;
  padding: 10 * $rem;
  .select-wrapper {
    width: 160px;
  }
}
form .row {
  width: 31.6875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0.9375rem 0 0 0.7rem;
}
button {
  width: 174px;
  height: 50px;
  background: rgba(40, 53, 147, 0.6);
  color: #ffffff;
  font-size: 16px;
  float: right;
  margin-top: 5%;
  border-radius: 3px;
  cursor: pointer;
}
.total-days {
  color: $blue-primary;
  font-weight: 300;
  div {
    color: #9696b9;
    font-weight: 400;
  }
}
.calendar-section {
  border-radius: 7px;
  display: flex;
  justify-content: flex-start;
  margin-top: 10 * $rem;
  margin-bottom: 50 * $rem;

  .days-left-content {
    background-color: #f6f6fe;
    width: 38.3125rem;
    display: flex;
    .left-side {
      span {
        font-size: 93px;
        color: rgba($blue-primary, 0.5);
        font-family: 'Anton';
        font-weight: 400;
        user-select: none;
        display: block;
      }
      span:nth-child(1) {
        margin-left: 40 * $rem;
        margin-top: 14px;
      }
      span:nth-child(2) {
        margin-left: 90 * $rem;
        margin-top: -10px;
      }
    }
    .right-side {
      margin-left: 5.125rem;
      padding-right: 0.5rem;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .single-chart {
        width: 33%;
        justify-content: space-around;
      }

      .circular-chart {
        display: block;
        height: 200px;
      }

      .circle-bg {
        fill: none;
        stroke: rgba($blue-primary, 0.1);
        stroke-width: 4.4;
      }

      .circle {
        fill: none;
        stroke-width: 4;
        animation: progress 1s ease-out forwards;
      }

      @keyframes progress {
        0% {
          stroke-dasharray: 0 100;
        }
      }

      .circular-chart.orange .circle {
        stroke: #1de9b6;
      }
      .day-count-content {
        position: relative;
      }
      .daysLeft {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 80px;
        color: #283593;
        opacity: 0.8;
        font-weight: 900;
      }
      .percentage {
        font-size: 14px;
        fill: $blue-primary;
        opacity: 0.8;
        font-weight: 900;
        text-anchor: middle;
      }
      .period-content {
        margin-bottom: 30 * $rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        span {
          display: block;
          font-size: 16px;
        }
        span:nth-child(1) {
          color: #9696b9;
          opacity: 0.7;
        }
        span:nth-child(2) {
          color: rgba($blue-primary, 0.9);
        }
      }
    }
  }

  .wrap {
    position: relative;
    margin-left: 6.125rem;
    .holiday-calendar-title {
      color: #283593;
      font-size: 20px;
      position: absolute;
      top: -2.5rem;
      left: 0;
    }
  }
}
.terms-popup-content {
  >>> .terms-table-content > * {
    display: flex;
    justify-content: center;
    margin-left: 211px;
  }
  >>> table tbody tr td {
    width: 0 !important;
    font-size: 16px;
  }
  >>> table {
    width: 75%;
    // table-layout: fixed;

    thead {
      tr {
        height: 30px;
      }
    }
  }
  >>> table tbody tr {
    background-color: #fff !important;
    height: 35px;
    td {
      padding-left: 0 !important;
    }
  }
  >>> table {
    tbody {
      height: 105px;
      overflow: auto;
    }
  }
  >>> thead tr th {
    font-size: 16px;
    padding-left: 0 !important;
    width: 35px !important;
  }
  >>> .date-range {
    .container {
      background-color: #fff;
    }
  }
}
.customtxt-area {
  ::v-deep textarea {
    width: 45.8rem;
    resize: none;
  }
}
</style>
