<template>
  <div class="gird">
    <div class="table">
      <div class="row headers">
        <div class="col"></div>
        <div class="col"></div>
        <div class="col">
          {{ title }}
        </div>
        <div class="col col--center">Users</div>
        <div class="col col--center">Session Duration</div>
        <div class="col col--center">Traffic Share</div>
      </div>
      <div class="body">
        <template v-if="loading">
          <div class="row">
            <div class="col">
              <span class="loader"></span>
            </div>
            <div class="col">
              <span class="loader"></span>
            </div>
            <div class="col">
              <span class="loader"></span>
            </div>
            <div class="col col--center">
              <span class="loader"></span>
            </div>
            <div class="col col--center">
              <span class="loader"></span>
            </div>
            <div class="col col--center">
              <span class="loader"></span>
            </div>
          </div>
        </template>
        <template v-else-if="data && data.length > 0">
          <template v-if="!isWeb">
            <div class="row" v-for="(object, index) in data" :key="index">
              <div class="col">
                <custom-round-checkbox
                  :id="`${object.ProgramName}-${index}`"
                  :value="object.checked"
                  @changed="$emit('add-tag', object)"
                />
              </div>
              <div class="col index">
                {{ `${index + 1}` }}
              </div>
              <div class="col">
                <template>
                  <a
                    target="_blank"
                    class="flex"
                    v-if="object.URL"
                    :href="`https://${object.URL}`"
                  >
                    <div class="img-wrapper">
                      <img
                        @load="(e) => loadHandler(e, object)"
                        :src="getObjectIcon(object.URL)"
                        alt=""
                        :class="{
                          'more-width': object.moreWidthThanHeight,
                          'more-height': !object.moreWidthThanHeight,
                        }"
                      />
                    </div>
                    <span class="program"> {{ object.URL }} </span>
                  </a>
                  <span class="flex app-icon-placeholder" v-else-if="!object.blankIcon">
                    <div class="img-wrapper">
                      <img
                        @load="(e) => loadHandler(e, object)"
                        @error="object.blankIcon = true"
                        @click="
                          imageModalIsActive = true;
                          currentObject = object;
                        "
                        :src="getProgramLogo(object.ProgramName)"
                        :class="{
                          'more-width': object.moreWidthThanHeight,
                          'more-height': !object.moreWidthThanHeight,
                        }"
                        alt=""
                      />
                    </div>

                    <span class="program"> {{ object.ProgramName }} </span>
                  </span>
                  <span class="clickable flex default-icon" v-else>
                    <div class="img-wrapper">
                      <img
                        @load="(e) => loadHandler(e, object)"
                        @click="
                          imageModalIsActive = true;
                          currentObject = object;
                        "
                        :src="
                          require('@/assets/icons/icon_traficapp_default.svg')
                        "
                        alt=""
                        :class="{
                          'more-width': object.moreWidthThanHeight,
                          'more-height': !object.moreWidthThanHeight,
                        }"
                      />
                    </div>
                    <span class="program"> {{ object.ProgramName }}</span>
                  </span>
                </template>
              </div>
              <div
                class="col col--center"
                @click="
                  userPopupActive = true;
                  currentObject = object;
                  currentObjectIndex = index;
                "
              >
                <span class="user">
                  {{ object.PersonCount ? object.PersonCount : object.Count }}
                </span>
              </div>
              <div class="col col--center">
                {{ timestampToHours(object.SessionDuration) }}
              </div>
              <div class="col col--center">
                {{
                  calculateTrafficShare(
                    object.SessionDuration,
                    object.TotalTime
                  )
                }}
              </div>
            </div>
          </template>
          <template v-else v-for="(object, index) in dataComputed">
            <div class="row" :key="index">
              <div class="col">
                <custom-round-checkbox
                  :id="`${object.ProgramName}-${index}`"
                  :value="object.checked"
                  @changed="$emit('add-tag', object)"
                />
              </div>
              <div class="col index">
                {{ index + 1 }}
              </div>
              <div class="col">
                <template class="flex">
                  <i
                    v-if="options.withURL"
                    :class="[
                      'material-icons',
                      'menu',
                      { 'menu--collapsed': object.Collapsed },
                    ]"
                    @click="
                      object.Collapsed = !object.Collapsed;
                      onCollapseClick(object);
                    "
                  >
                    keyboard_arrow_right</i
                  >
                  <a
                    target="_blank"
                    class="flex"
                    v-if="object.URL"
                    :href="`https://${object.URL}`"
                  >
                    <div class="img-wrapper">
                      <img
                        @load="(e) => loadHandler(e, object)"
                        :src="getObjectIcon(object.URL)"
                        alt=""
                        :class="{
                          'more-width': object.moreWidthThanHeight,
                          'more-height': !object.moreWidthThanHeight,
                        }"
                      />
                    </div>
                    <span class="program"> {{ object.URL }} </span>
                  </a>
                  <span class="flex" v-else-if="object.File">
                    <div class="img-wrapper">
                      <img
                        @load="(e) => loadHandler(e, object)"
                        @click="
                          imageModalIsActive = true;
                          currentObject = object;
                        "
                        :src="getProgramLogo(object.ProgramName)"
                        :class="{
                          'more-width': object.moreWidthThanHeight,
                          'more-height': !object.moreWidthThanHeight,
                        }"
                        alt=""
                      />
                    </div>
                    <span class="program"> {{ object.ProgramName }} </span>
                  </span>
                  <span class="clickable flex" v-else>
                    <div class="img-wrapper">
                      <img
                        @load="(e) => loadHandler(e, object)"
                        @click="
                          imageModalIsActive = true;
                          currentObject = object;
                        "
                        :src="
                          require('@/assets/icons/icon_traficapp_default.svg')
                        "
                        alt=""
                        :class="{
                          'more-width': object.moreWidthThanHeight,
                          'more-height': !object.moreWidthThanHeight,
                        }"
                      />
                    </div>
                    <span> {{ object.ProgramName }} </span>
                  </span>
                </template>
              </div>
              <div
                class="col col--center"
                @click="
                  userPopupActive = true;
                  currentObject = object;
                  currentObjectIndex = index;
                "
              >
                <span class="user">
                  {{ object.PersonCount ? object.PersonCount : object.Count }}
                </span>
              </div>
              <div class="col col--center">
                {{ timestampToHours(object.SessionDuration) }}
              </div>
              <div class="col col--center">
                {{
                  calculateTrafficShare(
                    object.SessionDuration,
                    object.TotalTime
                  )
                }}
              </div>
            </div>
            <div
              class="urls"
              :key="`urls-${index}`"
              v-if="!object.Collapsed && options.withURL"
            >
              <template v-if="urls[object.URL] && urls[object.URL].length">
                <div
                  class="row row--urls"
                  :key="`url-${ind}`"
                  v-for="(obj, ind) in urls[object.URL]"
                >
                  <div class="col">
                    <a
                      target="_blank"
                      class="flex url-text"
                      :href="`https://${obj.URL}`"
                    >
                      {{ obj.URL }}</a
                    >
                  </div>
                  <div
                    class="col col--center users"
                    @click="
                      userPopupActive = true;
                      isURL = true;
                      currentObject = obj;
                      currentObjectIndex = ind;
                    "
                  >
                    <span class="user">
                      {{ obj.Count }}
                    </span>
                  </div>
                  <div class="col col--center">
                    {{ timestampToHours(obj.SessionDuration) }}
                  </div>
                  <div class="col col--center">
                    {{
                      calculateTrafficShare(
                        obj.SessionDuration,
                        object.SessionDuration
                      )
                    }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="row row--urls">
                  <div class="col">
                    <span class="loader"></span>
                  </div>
                  <div class="col col--center">
                    <span class="loader"></span>
                  </div>
                  <div class="col col--center">
                    <span class="loader"></span>
                  </div>
                  <div class="col col--center">
                    <span class="loader"></span>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </template>
        <div v-else class="no-record">No Records</div>
      </div>
    </div>
    <overlay
      :visible="imageModalIsActive"
      @close="
        imageModalIsActive = false;
        file = {};
      "
    >
      <popup
        title="Add App Logo"
        @close="
          imageModalIsActive = false;
          file = {};
        "
        :visible="imageModalIsActive"
        :flexible-size="true"
      >
        <custom-file-input
          title="Choose File"
          :value="file"
          @change="changeFile"
        />
        <div class="upload-button" @click="uploadLogo">Upload</div>
      </popup>
    </overlay>
    <custom-snackbar
      v-if="uploadFailed"
      @close="uploadFailed = false"
      :text="'Logo size must be less than ' + this.uploadLimit + ' KB - current size is ' + this.logoSize + ' KB'"
      :purpose="snackbarPurpose"
    />
    <custom-snackbar
      v-if="uploadSuccess"
      @close="uploadSuccess = false"
      text="Logo was uploaded successfully"
    />
    <custom-popup
      v-if="userPopupActive"
      :visible="userPopupActive"
      :flexible-size="true"
      :title="currentObject.URL || currentObject.ProgramName"
      @close="
        userPopupActive = false;
        users = [];
      "
    >
      <div class="users-modal__wrapper">
        <div class="users-modal__title-wrapper">
          <div class="users-modal__title">Users {{ currentObject.Count }}</div>
          <custom-input
            class="users-modal__search"
            title="Filter by Persons"
            v-model="usersModalSearchInput"
          />
        </div>
        <div class="table">
          <div class="row row--user-modal headers">
            <div class="col">#</div>
            <div class="col">User</div>
            <div class="col">Session Duration</div>
          </div>
          <div class="table-body">
            <template v-if="computedUsers.length > 0">
              <div
                class="row row--user-modal"
                v-for="(user, index) in computedUsers"
                :key="index"
              >
                <div class="col">{{ index + 1 }}</div>
                <div class="col">
                  {{ `${user.FirstName} ${user.LastName}` }}
                </div>
                <div class="col">
                  {{ timestampToHours(user.SessionDuration) }}
                </div>
              </div>
            </template>
            <div v-else class="no-data">No Records</div>
          </div>
        </div>
      </div>
    </custom-popup>
  </div>
</template>

<script>
import CustomRoundCheckbox from "@/components/ReusableComponents/CustomRoundCheckbox/CustomRoundCheckbox";
import axios from "@/Helpers/AxiosInstance";
import { getDateString } from "@/Helpers/HelperFunctions";
import Overlay from "@/components/ReusableComponents/Overlay/Overlay";
import Popup from "@/components/ReusableComponents/Popup/Popup";
import CustomFileInput from "@/components/ReusableComponents/CustomFileInput/CustomFileInput";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput";

export default {
  components: {
    CustomInput,
    CustomPopup,
    CustomSnackbar,
    CustomFileInput,
    Popup,
    Overlay,
    CustomRoundCheckbox,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: false,
    },
    highestSession: {
      type: Number,
      required: false,
    },
    period: {
      type: Object,
    },
    loading: Boolean,
    filters: Object,
    isWeb: Boolean,
    title: String,
  },
  data() {
    return {
      users: [],
      currentObject: null,
      userPopupActive: false,
      currentObjectIndex: null,
      imageModalIsActive: null,
      uploadLimit: 10,
      logoSize: null,
      file: {},
      uploadSuccess: false,
      uploadFailed: false,
      dataComputed: [],
      urls: {},
      snackbarPurpose: 'error',
      usersModalSearchInput: "",
      isURL: false,
    };
  },
  computed: {
    computedUsers() {
      if (this.usersModalSearchInput) {
        return this.users.filter(
          (el) =>
            el.FirstName.toLowerCase().includes(
              this.usersModalSearchInput.toLowerCase()
            ) ||
            el.LastName.toLowerCase().includes(
              this.usersModalSearchInput.toLowerCase()
            ) ||
            `${el.FirstName.toLowerCase()} ${el.LastName.toLowerCase()}`.includes(
              this.usersModalSearchInput.toLowerCase()
            )
        );
      }
      return this.users;
    },
  },
  methods: {
    loadHandler(e, object) {
      const width = e.target.naturalWidth;
      const height = e.target.naturalHeight;
      if (width == height) return;
      object.moreWidthThanHeight = width > height;

      this.$set(this.dataComputed, this.data.indexOf(object), object);
    },
    getProgramLogo(name) {
      let isLocal = ~location.href.indexOf('localhost')
      let programName = name.trim()
      let source = `https://intranet.upgaming.${isLocal ? 'dev':'com'}/api/attendance/GetProgramLogoImg?ProgramName=${programName}`; 
      return source
    },
    onCollapseClick(object) {
      if (!this.urls[object.URL] && object.URL) this.getUrls(object.URL);
    },
    getObjectIcon(url) {
      return `https://s2.googleusercontent.com/s2/favicons?domain_url=${url}`;
    },
    timestampToHours(timestamp) {
      const sec_num = parseInt(timestamp, 10);
      const hours = Math.floor(sec_num / 3600);
      const minutes = Math.floor(sec_num / 60) % 60;
      const seconds = sec_num % 60;

      return [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .join(":");
    },
    calculatePercentage(number, numberOf) {
      return (number / numberOf) * 100;
    },
    calculateTrafficShare(sessionDuration, allTime) {
      const number = (sessionDuration / allTime) * 100;
      return `${Math.round((number + Number.EPSILON) * 100) / 100}%`;
    },
    async getUsersData(object) {
      const response = await axios.get("attendance/GetProgramActivePersons", {
        params: {
          program: object.ProgramName,
          url: object.URL,
          fromDate: getDateString(this.period.from, "yyyy/MM/dd"),
          toDate: getDateString(this.period.to, "yyyy/MM/dd"),
          department: this.filters ? this.filters.departments : null,
          job: this.filters ? this.filters.jobs : null,
          person: this.filters ? this.filters.persons : null,
          isUrl: this.isURL,
        },
      });

      this.users = response.data.Value;
    },
    changeFile(file) {
      this.file = file;
    },
    async uploadLogo() {
      let imgSize = this.file.size
      let imgSizeInkB = (imgSize / 1024).toFixed(1)

      if(imgSizeInkB > this.uploadLimit){
        this.uploadFailed = true
        this.logoSize = imgSizeInkB
        return
      }else{
        const response = await axios.post("attendance/CreateProgramLogo", {
          ProgramName: this.currentObject.ProgramName,
          Base64: this.file.base64,
        });
          if (response) {
            this.uploadSuccess = true;
          }
      }
      this.imageModalIsActive = false;
    },
    urlToName(url) {
      const splittedURL = url.split("/");
      return splittedURL
        ? splittedURL[2]
          ? splittedURL[2]
          : "Wrong URL"
        : "Wrong URL";
    },
    getUrls(domain) {
      axios
        .get("attendance/GetUrlsFromDomain", {
          params: {
            domain: domain,
            fromDate: getDateString(this.filters.period.from, "yyyy/MM/dd"),
            toDate: getDateString(this.filters.period.to, "yyyy/MM/dd"),
            Person: this.filters.persons,
            Job: this.filters.jobs,
            Department: this.filters.departments,
          },
        })
        .then((response) => {
          const data = response.data.Value;
          data.forEach((el) => {
            el.checked = false;
          });
          this.$set(this.urls, domain, response.data.Value);
        });
    },
  },
  watch: {
    userPopupActive(val, previous) {
      if (val) {
        if (this.currentObject === previous) return;
        this.getUsersData(this.currentObject);
      } else {
        this.users = [];
        this.currentObjectIndex = null;
      }
    },
    data: {
      handler() {
        const data = JSON.parse(JSON.stringify(this.data));
        for (const el of data) {
          el.Collapsed = true;
        }
        this.dataComputed = data;
        this.urls = [];
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.index {
  color: #9696b9;
}

a {
  color: #1db3e9;

  text-decoration: none;
}

.table {
  display: flex;
  flex-direction: column;
  width: 100%;

  .headers {
    margin-bottom: 10px;
    color: #283593;
    font-weight: 500;
  }

  .body {
    max-height: 700px;
    overflow-y: auto;
    padding-bottom: 2rem;
  }

  .user {
    cursor: pointer;
  }

  .row {
    color: #283593;
    display: grid;
    height: 25px;
    grid-template-columns: 30px 40px 320px 80px 150px 113px;
    align-items: center;
    text-align: left;
    margin-bottom: 10px;
    position: relative;
    &--urls {
      margin-left: 90px;
      grid-template-columns: 300px 80px 150px 113px;
    }

    &--user-modal {
      grid-template-columns: 30px 300px 113px;
    }

    .col {
      display: flex;
      text-overflow: ellipsis;
      white-space: nowrap;

      a {
        overflow: hidden;
      }

      .flex {
        overflow: hidden;
      }

      &--center {
        justify-content: left;
      }
    }
  }
}

.users {
  span {
    cursor: pointer;
  }
}


.upload-button {
  cursor: pointer;
  width: 197 * $rem;
  display: inline-block;
  height: 40 * $rem;
  line-height: 40 * $rem;
  padding: 0 15 * $rem 0 15 * $rem;
  outline: none;
  text-align: center;
  color: white;
  margin-top: 10px;
  border-radius: 5 * $rem;
  box-shadow: none;
  background-color: $green-primary;
}

.loader {
  display: block;
  height: 12px;
  width: 80%;
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  border-radius: 5px;
}

.img-wrapper {
  overflow: hidden;
  display: grid;
  place-items: center;
  margin-right: 10px;
  height: 24px;
  width: 24px;

  img {
    &.more-width {
      width: 100%;
      height: auto;
    }

    &.more-height {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
      border-radius: 0.1875rem;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
}

.menu {
  transform: rotate(-90deg);
  transition: transform 0.1s ease;

  &--collapsed {
    transform: rotate(90deg);
  }
}

.url-text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@-webkit-keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }
  100% {
    background-position: 250px 0;
  }
}
  
  
.no-record {
  position: absolute;
  left: 50%;
  top: 21.875rem;
  transform: translate(-50%, -50%);

  font-size: 50px;
  color: rgba(#283593, 0.5);
}

.users-modal {
  &__search {
    margin-left: auto;
  }

  &__title {
    color: #283593;
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }
}

.table-body {
  max-height: 480px;
  overflow-y: auto;
}

.no-data {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $blue-primary;
}

.program {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
