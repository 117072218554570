class Day{
    constructor(day, isCurrent, isHoliday, birthdays) {
        this.day = day;
        this.isCurrent = isCurrent;
        this.currentDay = false;
        this.isHoliday = isHoliday;
        this.birthdays = birthdays;
        this.showBirthdays = false;
        this.isFilteredBirthday = false;
    }
}

export default Day