<template>
    <div class="background">
        <div class="top-header">
            <ul>
            <li class="route">
                <router-link
                tag="span"
                :to="{ path: '/CareerAdmin' }"
                active-class="active">
                Requested Jobs
                </router-link>
            </li>

            <li class="route">
                <router-link
                    tag="span"
                    :to="{ path: '/ApplicantTracking' }"
                    class="active"
                >
                Applicant Tracking
                </router-link>
            </li>        
            </ul>
        </div>
        <div class="additional-page-wrapper">
            <div class="header-filters">
                <div class="add-status-wrapper">
                    <div class="back">
                        <router-link
                            tag="span"
                            :to="{ path: '/ApplicantTracking' }"
                        >
                        <img src="@/assets/icons/arrow-right.svg" alt="">
                        </router-link>
                    </div>
                    <div class="add-status-btn" @click="statusPopupVisible = true">
                        Add Status
                    </div>
                </div>
                <div class="filters">
                        <custom-select
                            title="Language" 
                            class="langauge select"
                            :options="Status.langList"
                            v-model="filters.lang"
                            :clearVisible="true"
                        />
                        <input-search
                            title="Status"
                            :options="options.statuses"
                            @select="(id) => (filters.status = id)"
                            class="select"
                        />

                        <!-- <div class="search-status">
                            <input type="text" v-model="filters.statusName"  placeholder="Search applicant" />
                            <img
                                class="search-icon"
                                src="@/assets/icons/search.svg"
                                alt=""
                            />
                        </div> -->
                        

                </div>
            </div>

            <div class="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                Language
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                Template Message
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(status,ind) in filteredData" :key="ind" @click="setStatusDetails(status.FollowUpStatusID)">
                            <td>
                                {{ind + 1}}
                            </td>

                            <td>
                                {{status.Code}}
                            </td>

                            <td>
                                <div class="status-name">
                                    {{status.Name}}
                                </div>
                            </td>

                            <td class="template-message">
                                <div class="message">
                                    {{status.TemplateMessageText}}
                                </div>
                                <div class="actions">
                                    <div class="edit" @click.stop="editStatusTemplate(status.FollowUpStatusID)">
                                        <img src="@/assets/icons/edit-small.svg" alt="">
                                    </div>
                                    <div class="delete" @click.stop="deleteStatusTemplate(status.FollowUpStatusID)">
                                        <img src="@/assets/icons/delete-red.svg" alt="">
                                    </div>
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <custom-popup
            :visible="statusPopupVisible"
            @close="(statusPopupVisible = false,resetStatusValues(),editMode = false)"
            :title="!editMode ? 'Add Status' : 'Edit Status'"
            class="add-status-popup"
        >

            <div class="add-status-wrapper">
                <div class="top">
                    <custom-input
                        title="Name Status"
                        v-model="Status.statusName"
                        class="status-name"
                    />
                    
                    <custom-select
                        title="Language" 
                        :options="Status.langList"
                        v-model="Status.language"
                        class="status-lang"
                    />
                </div>
                <div class="middle">
                    <custom-text-area title="Your Note" :spellcheck="false" :maxLength="99999999"  v-model="Status.template" />
                </div>
                <div class="bottom">
                    <button class="saveStatus" @click="createStatusTemplate">Save</button>
                </div>
            </div>

        </custom-popup>

        <custom-snackbar
            v-if="snackbar.visible"
            :purpose="snackbar.purpose"
            :text="snackbar.text"
            @close="snackbar.visible = false"
        />

        <custom-popup
            :visible="templateDetailsVisible"
            @close="(templateDetailsVisible = false,resetStatusValues())"
            :title="Status.statusName"
            class="status-details"
        >   
            <span class="languageCode">
                {{Status.langCode}}              
            </span>
            <div class="template-placeholder">
                <p v-for="(text,ind) in Status.formattedTemplate" :key="ind">
                    {{text}}
                </p>
            </div>

        </custom-popup>


    </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance.js";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue"
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup"
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput"
import CustomTextArea from "@/components/ReusableComponents/CustomTextArea/CustomTextArea.vue"
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import InputSearch from "@/components/ReusableComponents/InputSearch/InputSearch.vue";





export default {
    components: {
        CustomSelect,
        CustomPopup,
        CustomInput,
        CustomTextArea,
        CustomSnackbar,
        InputSearch,
    },
    data(){
        return{
            editMode: false,
            statusPopupVisible: false,
            templateDetailsVisible: false,
            Status:{
                langList:[],
                formattedTemplate: [],
                language: '',
                langCode: '',
                statusName: '',
                template: '',
                statusID: null,
            },
            filters:{
                lang: '',
                status: '',
                statusName: '',
            },
            options:{
                statuses: [],
            },
            statuses:[],
            filteredData: [],
            snackbar:{
                text:"",
                purpose: "",
                visible: false,
            },
            
        }
    },
    created(){
        this.getStatusTemplates()
        this.getLanguages()
    },
    methods:{
        getStatusTemplates(){
            axios.get("openposition/GetFollowUpStatuses")
            .then(resp => {
                let data = resp.data.Value
                this.statuses = data
                this.filteredData = data
                this.options.statuses = data.map(status => ({
                    title: status.Name,
                    value: status.FollowUpStatusID
                }))
            })
        },
        createStatusTemplate(){
            if(this.editMode){
                axios.post("openposition/UpdateFollowUpStatus",{
                    followUpStatusID: this.Status.statusID,
                    name : this.Status.statusName,
                    languageID: this.Status.language,
                    templateMessageText: this.Status.template,
                }).then(resp => {
                    if(!resp.data.ErrorMsg){
                        this.snackbar.visible = true
                        this.snackbar.purpose = "success"
                        this.snackbar.text = 'Your request has been saved successfully'
                        this.getStatusTemplates()
                        this.statusPopupVisible = false
                        this.resetStatusValues()
                        this.editMode = false
                    }
                    else{
                        this.snackbar.visible = true
                        this.snackbar.purpose = "success"
                        this.snackbar.text = 'Fill in all fields'
                        return
                    }
                })
            }else{
                axios.post("openposition/CreateFollowUpStatus",{
                    name: this.Status.statusName,
                    languageID: this.Status.language,
                    templateMessageText: this.Status.template,
                }).then(resp => {
                    if(!resp.data.ErrorMsg){
                        this.snackbar.visible = true
                        this.snackbar.purpose = "success"
                        this.snackbar.text = 'Your request has been saved successfully'
                        this.getStatusTemplates()
                        this.statusPopupVisible = false
                        this.resetStatusValues()
                    }
                    else{
                        this.snackbar.visible = true
                        this.snackbar.purpose = "success"
                        this.snackbar.text = 'Fill in all fields'
                        return
                    }
                })
            }
        },
        deleteStatusTemplate(id){
            this.$confirm(`Are you sure you want delete this Status Template ?`).then(() => {
                axios.post("openposition/UpdateFollowUpStatus",{
                    followUpStatusID: id,
                    delete: true,
                })
                .then(resp => {
                    this.getStatusTemplates()
                })
            })

        },
        getLanguages(){
            axios.get("openposition/GetLanguages")
            .then(resp => {
                let data = resp.data.Value
                this.Status.langList = data.map(lang => ({
                    title: lang.Code,
                    value: lang.LanguageID
                }))
                
            })
        },
        resetStatusValues(){
            this.Status.language = ''
            this.Status.template = ''
            this.Status.statusName = ''
        },
        editStatusTemplate(id){
            this.Status.statusID = id
            this.statusPopupVisible = true
            this.editMode = true
            let currentStatus = this.statuses.filter(status => status.FollowUpStatusID == id)
            this.Status.language = currentStatus[0].LanguageID
            this.Status.langCode = currentStatus[0].Code
            this.Status.statusName = currentStatus[0].Name
            this.Status.template = currentStatus[0].TemplateMessageText
        },
        setStatusDetails(id){
            this.templateDetailsVisible = true
            let currentStatus = this.statuses.filter(status => status.FollowUpStatusID == id)
            this.Status.language = currentStatus[0].LanguageID
            this.Status.langCode = currentStatus[0].Code
            this.Status.statusName = currentStatus[0].Name
            this.Status.template = currentStatus[0].TemplateMessageText
            this.Status.formattedTemplate = currentStatus[0].TemplateMessageText.split('\n')

            
        },
    },
    watch:{
        filters:{
            handler(){
                if(this.filters.lang){
                    this.filteredData = this.statuses.filter(status => status.LanguageID == this.filters.lang)
                }
                if(this.filters.lang == ""){
                    this.filteredData = this.statuses
                }

                if(this.filters.status){
                    this.filteredData = this.filteredData.filter(status => status.FollowUpStatusID == this.filters.status)
                }
            },
            deep:true
        }
    }
}
</script>

<style scoped lang="scss">

.background{
    overflow: hidden;
}

.top-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
  padding-bottom: 0.625rem;
  ul{
    list-style: none;

    li.route{
      margin-left: 1.875rem;
      display: inline-flex;
      &:first-child{
        margin-left: 0;
      }
      span{
        font-size: 0.9375rem;
        color: $blue-primary;
        font-weight: 500;
        line-height: 1.1875rem;
        cursor: pointer;
        position: relative;

        &.active{
          &::after{
            content: "";
            width: 100%;
            height: 0.25rem;
            background: $green-primary;
            z-index: 999;
            position: absolute;
            display: block;
            bottom: -0.625rem;
            left: 0;
          }
        }
      }
    }
  }
}

.additional-page-wrapper{
    width: 100%;
    height: 100%;

    .header-filters{
        width: 100%;
        display: flex;
        justify-content:space-between;
        padding: 1.875rem 3.2rem;

        .add-status-wrapper{
            display: flex;
            align-items: center;

            .back{
                cursor: pointer;
                img{
                    transform: rotate(180deg);
                    transition: ease .3s;
                    &:hover{
                        filter: invert(67%) sepia(40%) saturate(568%) hue-rotate(104deg) brightness(132%) contrast(91%);
                    }
                }
            }

            .add-status-btn{
                margin-left: 2.8125rem;
                font-size: 0.875rem;
                color: #fff;
                border-radius: 0.1875rem;
                background: rgba($blue-primary,.5);
                width: 6.0625rem;
                height: 2.5rem;
                cursor: pointer;
                @include centerElement();
            }
        }

        .filters{
            @include flex(unset,center,row);

            .select{
                margin-right: 0.625rem;
                ::v-deep {
                    .selected-box,label > input{
                        width: 12.9375rem;
                        border: 0.0625rem solid #fff;
                    }
                    .title{
                        font-weight: 400;
                        color: #959BC9;
                    }
                }
            }


            .search-status {
                width: 12.9375rem;
                height: 2.5rem;
                position: relative;
                margin-right: 4.625rem;
                .search-icon {
                    position: absolute;
                    height: 1.3125rem;
                    width: 0.875rem;
                    top: 50%;
                    right: 1.25rem;
                    transform:translate(0,-50%)
                }

                input{
                    width: 100%;
                    color: #959BC9;
                    font-size: 0.875rem;
                    font-weight: 400;
                    border: none;
                    outline: none;
                    height: 2.5rem;
                    border-radius: 0.375rem;
                    box-sizing: border-box;
                    padding: 0 1rem;
                    &::placeholder{
                        color: #959BC9;
                    }
                }
            }
        }
    }

    .table-wrapper{
        overflow: auto;
        height: calc(100% - 8rem);
        padding-bottom: 2rem;

        table{
            width: 100%;
            text-align: left;
            margin: auto;
            border-collapse: collapse;
            
            tr {
                height: 3.75rem;
                td,th{
                    width: 14rem;
                    font-size: 0.875rem;
                    &:first-child {
                        padding-left:3rem;
                        width: 11rem !important;
                    }

                    &:last-child{
                        padding-right:3rem;
                    }
                }
            }

            thead {
                tr{
                    background: #ededfc;
                    z-index: 5;
                    height: 3.125rem;
                    position: sticky;
                    top: 0;

                    th{
                        color: $blue-primary;
                        font-size: 0.875rem;
                        font-weight: 500;
                        line-height: 1.125rem;
                        position: relative;


                        .thead-filters{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;

                        }
                    }
                }
            }
            
            tbody{
                tr{
                    transition: ease .3s;
                    cursor: pointer;
                    border-bottom: 0.0625rem solid rgba(#6956D6, .06);
                    
                    &:hover{    
                        background-color: rgba(#959BC9,.15);
                    }

                    td{
                        color: $blue-primary;

                        > .status-name{
                            padding-right: 1rem;
                        }
                    }

                    .template-message{
                        width: 64rem;
                        height: 3rem;
                        @include flex(space-between,center,unset);

                        .message{
                            height:2.2rem;
                            overflow: hidden;
                            @include centerElement();
                        }

                        .actions{
                            padding-left: 1.2rem;
                            @include flex(space-between,flex-start,unset);

                            .edit{
                                img{
                                    width: 1rem;
                                }
                            }

                            .delete{
                                margin-left: 1.25rem;
                                img{
                                    width: 0.75rem;
                                }
                            }
                        }
                    }


                }
            }

        }

    }
}

.add-status-popup{

    ::v-deep{
        .popup{
            width: 43.75rem;
            height: 28.75rem;
            border-radius: 0.625rem;
        }
    }

    .add-status-wrapper{
        .top{
            @include flex(space-between,unset,row);

            ::v-deep{
                .title{
                    color: $blue-primary;
                }
                .selected-box{
                    width: 15.625rem;
                    font-weight: 300;
                }
                .status-name{
                    width: 21.3125rem;
                }

                input,.selected-option{
                    font-weight: 400;
                    color: $blue-primary;
                }
            }
        }

        .middle{
            margin-top: 1.25rem;

            ::v-deep {
                .title{
                    color: $blue-primary;
                    font-weight: 300;
                }
                textarea{
                    resize: none;

                    &:focus{
                       border: 0.0625rem solid #e3e4eb;
                    }
                }
            }
        }

        .bottom{
            margin-top: 1.5rem;
            @include flex(flex-end,center,row);

            .saveStatus{
                width: 5.6875rem;
                height: 3.125rem;
                background: #6956D6;
                font-size: 1rem;
                color: #fff;
                line-height: 1.3125rem;
                border-radius: 0.375rem;
                cursor: pointer;
                transition: ease .3s;

                &:hover{
                    box-shadow: 0 0 0.9375rem rgba(#6956D6,.4);
                }
            }
        }
    }
}

.status-details{

    ::v-deep{
        .popup{
            height: 36.125rem;
        }
    }
    span.languageCode{
        position: absolute;
        top: 1.5625rem;
        right: 1.5rem;
        font-size: 13px;
        color: #fff;
        background-color: #6956d6;
        padding:.5rem;
        border-radius: 50%;
        pointer-events: none;
        user-select: none;
    }

    .template-placeholder{
        width: 100%;
        overflow: auto;
        height:29rem;
        margin-top: 1.25rem;
        p{
            color: rgba($blue-primary, .8);
            font-size: 1rem;
            line-height: 1.5rem;
            margin-top: 1rem;
            padding-right: 1rem;
        }
    }
}
</style>