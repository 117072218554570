export default class Score{
    constructor(score){
        this.score = score
    }

    parseScore(){
        return parseInt(this.score)
    }

    setScoreState(){
        if(typeof(this.score) !== 'number') throw new Error('Not Number Type')

        let colors = ['#FF3356','#FF9233','#29CC39']
        switch(this.score){
            case 0:
            case 0.5:
            case 1:
            case 1.5:
            case 2:
            case 2.5:
            case 3:
            case 3.5:
                return colors[0]
            case 4:
            case 4.5:
            case 5:
            case 5.5:
            case 6:
            case 6.5:

                return colors[1]
            case 7:
            case 7.5:
            case 8:
            case 8.5:
            case 9:
            case 9.5:
            case 10:
                return colors[2]
        }
    }

    scoreStateName(){
        const lowScores = [1,2,3]
        const mediumScores = [4,5,6]
        const hightScores = [7,8,9,10]


        if(lowScores.includes(this.score)) return 'Low score'

        if(mediumScores.includes(this.score)) return 'Medium score'
        
        if(hightScores.includes(this.score)) return 'High score'


    }

    scoreObj(withCurrent){
        this.withCurrent = withCurrent
        let obj = {
            score: new Score(this.score).parseScore(),
            color: new Score(this.score).setScoreState(),
            scoreTypeName: new Score(this.score).scoreStateName(),
        }
        if(this.withCurrent){
            obj['current'] = false
        }
        return obj
    }

    currentScoreColor(){
        let colors = ['#FF3356','#FF9233','#29CC39']
        const lowScores = [0,0.5,1,1.5,2,2.5,3,3.5]
        const mediumScores = [4,4.5,5,5.5,6,6.5]
        const hightScores = [7,7.5,8,8.5,9,9.5,10]

        if(lowScores.includes(this.score)) return colors[0]

        if(mediumScores.includes(this.score)) return colors[1]
        
        if(hightScores.includes(this.score)) return colors[2]
    }

}