<template>
  <div class="container">
    <div class="helper">
      <h3>Office Members</h3>
      <form @submit.prevent="addMember">
        <input-search
          title="Search People"
          @select="(value) => (newMember.person = value)"
          :options="employeeOptions"
          :isRequired="true"
        />
        <date-picker
          title="Start Date"
          v-model="newMember.startTime"
          @clear="newMember.startTime = ''"
        />
        <button class="btn">Add</button>
      </form>
    </div>

    <grid
      identificator="OfficePersonID"
      title="Members"
      :gridData="filteredData"
      :columns="columns"
      :pagination="false"
      :canEdit="true"
      @rowEdit="editRow"
      :canDelete="true"
      @rowDelete="deleteRow"
      :alternativeStyle="true"
    />
    <custom-snackbar
      v-if="snackbar.visible"
      :text="snackbar.text"
      :purpose="snackbar.purpose"
      @close="snackbar.visible = false"
    />
  </div>
</template>

<script>
import InputSearch from "@/components/ReusableComponents/InputSearch/InputSearch.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue";
import Grid from "@/components/ReusableComponents/Grid/Grid.vue";
import axios from "@/Helpers/AxiosInstance.js";
import { getDateString, CETToTimestamp } from "@/Helpers/HelperFunctions.js";
export default {
  components: {
    Grid,
    InputSearch,
    DatePicker,
    CustomSnackbar,
  },
  props: {
    officeID: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.getOfficeMembers();
    this.getPersons();
  },
  data() {
    return {
      popupVisible: true,
      people: [],
      gridData: [],
      officeEmployees: [],
      newMember: {
        person: "",
        startTime: "",
      },
      snackbar: {
        text: "",
        purpose: "success",
        visible: false,
      },
      employees: [],
    };
  },
  methods: {
    getOfficeMembers() {
      axios
        .get("offices/OfficePersons", {
          params: {
            OfficeID: this.officeID,
            ShowHistory: true,
          },
        })
        .then((resp) => {
          this.officeEmployees = resp.data.Value;
          this.officeEmployees.forEach(
            (el) => (el.EndTime = el.EndTime == null ? 0 : el.EndTime)
          );
        });
    },
    getPersons() {
      axios
        .get("Person/GetPersons", {
          params: {
            Page: 1,
            PageSize: 1000000,
          },
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.employees = resp.data.Value.Persons;
          }
        });
    },
    addMember() {
      if (!this.newMember.person) return;
      axios
        .post("offices/AssignOfficePerson", {
          OfficeID: this.officeID,
          PersonID: this.newMember.person,
          StartTime: this.newMember.startTime,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.getOfficeMembers();
            this.newMember.person = "";
          }
        });
    },
    editRow(values) {
      axios
        .post("offices/UpdateOfficePerson", values)
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.getOfficeMembers();
          }
        })
        .catch(() => {});
    },
    deleteRow(id) {
      this.$confirm(
        "Would you like to remove this person from the office?"
      ).then(() => {
        axios
          .post("offices/UpdateOfficePerson", {
            OfficePersonID: id,
            EndTime: new Date().getTime(),
            Delete: true,
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.getOfficeMembers();
            }
          });
      });
    },
  },
  computed: {
    columns() {
      return [
        {
          title: "First Name",
          key: "FirstName",
        },
        {
          title: "Last Name",
          key: "LastName",
        },
        {
          title: "End Time",
          key: "EndTime",
          customTemplate: (item, val) =>
            val ? getDateString(CETToTimestamp(val)) : "-",
          editable: true,
          editType: "date",
        },
      ];
    },
    employeeOptions() {
      return this.employees.map((emp) => ({
        title: emp.FirstName + " " + emp.LastName,
        value: emp.ID,
      }));
    },
    filteredData() {
      if (!this.newMember.person) return this.officeEmployees;
      return this.officeEmployees.filter((el) => {
        return el.PersonID == this.newMember.person;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  margin-top: 30 * $rem;
  display: flex;
  margin-bottom: 15 * $rem;

  .input-search,
  .select-wrapper,
  label {
    margin-right: 15 * $rem;
  }
}
.btn {
  background-color: rgba($blue-primary, 0.5);
  color: #ffffff;
  height: 40px;
  width: 100px;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
}

::v-deep .grid {
  border-radius: 0.625rem;
  overflow: hidden;
}
.helper {
  display: flex;
  justify-content: space-between;
  padding-left: 40 * $rem;
  padding-right: 40 * $rem;
  align-items: center;
  h3 {
    font-size: 1.25rem;
    color: #283593;
    font-weight: 400;
  }
}
</style>