<template>
  <div class="background">
    <div class="signify-docs-content">
      <div class="header">
        <div class="filter"></div>
        <div class="button" @click="popupHandler">
          <span>Upload New Template</span>
        </div>
      </div>

      <grid
        :gridData="docTemplates"
        :columns="columns"
        identificator="ID"
        :pagination="false"
        :canDelete="true"
        :alternativeEdit="true"
        @edit-clicked="editClickHandler"
        @rowDelete="deleteRow"
        @trClicked="trClickHandler"
      ></grid>
    </div>
    <custom-popup
      :visible="popupVisible"
      @close="closeHandler"
      :title="!editMode ? 'Upload Template' : 'Edit Template'"
      class="document-popup"
      :class="[editMode ? 'big-size' : null]"
    >
      <div class="forms-wrapper">
        <div class="main-forms" v-if="!editMode">
          <div class="single-form">
            <custom-input
              v-model="docName"
              :title="'Document title'"
              @clear="field.selected = ''"
            />

            <div class="job" v-if="job">
              {{ 'Job Name:' + ' ' + job }}
            </div>
          </div>
          <div class="single-form">
            <custom-input
              v-model="docKey"
              :title="'Key'"
              @clear="field.selected = ''"
            />
          </div>
          <div class="single-form">
            <custom-file-input
              title="Select File"
              :value="file"
              @change="(file) => fileUploader(file)"
            />
          </div>
        </div>
        <!-- <div
          class="dynamic-forms"
          :style="{ flexWrap: fields.length > 12 ? 'nowrap' : null }"
        >
          <div
            class="single-form"
            :class="[fields && fields.length > 5 ? 'many-fields' : null]"
            v-for="field in fields"
            :key="field.KeyName"
          >
            <custom-input
              v-model="field.Value"
              :title="field.DisplayName"
              @clear="field.selected = ''"
            />
          </div>
        </div> -->
        <!-- <div v-for="(item, index) in fields" :key="index">
          <div v-if="index > 0" class="separator"></div>
          <div>
            <label>{{ item }}</label>
            <input v-model="item[index]" @input="updateSigner(index, 'name')" />
          </div>
          <div>
            <label>Email:</label>
            <input
              v-model="item[index]"
              @input="updateSigner(index, 'email')"
            />
          </div>
  
          <div v-for="(field, fieldIndex) in item.fields" :key="fieldIndex">
            <label>Field {{ fieldIndex + 1 }}:</label>
            <input
              v-model="field.value"
              @input="updateField(index, fieldIndex)"
            />
          </div>

        </div> -->

        <textarea
          v-if="editMode"
          id="myTextArea"
          v-model="fields"
          spellcheck="false"
          rows="10"
          cols="50"
        ></textarea>
      </div>
      <div
        class="send-button"
        :class="{ disabled: buttonDisabled }"
        @click="!editMode ? createDocument() : updateDocument()"
      >
        <div class="send">Save</div>
      </div>
    </custom-popup>
    <custom-popup
      :visible="detailsVisible"
      @close="detailsVisible = false"
      :title="'Details'"
      class="details-popup"
    >
      <grid
        :gridData="details"
        :columns="detailColumns"
        identificator="ID"
        :height="300"
        :pagination="false"
        :canDelete="false"
      ></grid>
    </custom-popup>
    <CustomSnackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :timeout="snackbarTime"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
  </div>
</template>

<script>
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
import CustomPopup from '@/components/ReusableComponents/CustomPopup/CustomPopup'
import SearchInput from '@/components/ReusableComponents/SearchInput/SearchInput.vue'
import CustomInput from '@/components/ReusableComponents/CustomInput/CustomInput.vue'
import axios from '@/Helpers/AxiosInstance.js'
import CustomSnackbar from '@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue'
import CustomFileInput from '@/components/ReusableComponents/CustomFileInput/CustomFileInput'

export default {
  components: {
    Grid,
    CustomPopup,
    // SearchInput,
    CustomInput,
    CustomFileInput,

    CustomSnackbar,
  },

  data() {
    return {
      popupVisible: false,

      mainFields: {
        selectedPerson: '',
        selectedTemplate: '',
      },
      // selectedPerson: '',
      // selectedTemplate: '',
      docName: '',
      docKey: '',
      file: {},
      docTemplates: [],
      selectedRow: null,
      snackbarPurpose: '',
      details: [],
      detailsVisible: false,
      snackbarText: '',
      snackbarTime: '',
      snackbarVisible: false,
      editMode: false,
      fields: [],
      documents: [],
      detailColumns: [
        {
          title: 'ID',
          key: 'ID',
        },
        {
          title: 'Key Name',
          key: 'KeyName',
        },
        {
          title: 'Doc Name',
          key: 'Name',
        },
      ],
      columns: [
        {
          title: 'Key Name',
          key: 'KeyName',
          hasFilter: true,
        },
        {
          title: 'Doc Name',
          key: 'Name',
          hasFilter: true,
        },

        // {
        //   title: 'Employee',
        //   key: 'FullName',
        //   hasFilter: true,
        //   customTemplate: (item, val) => {
        //     return `<div class="job-name-wrapper">
        //     <span class="name">${val}</span>
        //     <span class="job-name">${item.JobName}</span>
        //     </div>`
        //   },
        // },
        // {
        //   title: 'Generate date & time',
        //   key: 'CreateTime',
        //   customTemplate: (item, val) =>
        //     getDateString(val, 'yyyy-MM-dd  HH:mm'),
        // },
        // {
        //   title: 'Status ',
        //   key: 'Status',
        //   hasFilter: true,
        //   customTemplate: (item, val) => {
        //     return `<span class="${
        //       val === 'FINISHED' ? 'green' : 'yellow'
        //     }">${val}</span>`
        //   },
        // },
        {
          title: 'Document',
          key: 'FileID',
          customTemplate: () => {
            return `<span  class="download-doc">Download Document </span>`
          },
        },
        {
          title: 'Details',
          customTemplate: () => {
            return `<span  class="show-details">Show Details </span>`
          },
        },

        // {
        //   title: 'Signify ID',
        //   key: 'SignifyDocumentID',
        //   hasFilter: true,
        //   customTemplate: (item, val) => {
        //     return `<span title="click to copy" class="copy-content">${val} </span>`
        //   },
        // },
      ],

      selectedPersonID: '',
      page: 1,
      totalPage: null,
      job: '',
      buttonDisabled: false,
    }
  },
  mounted() {
    this.getTemplates()
  },
  methods: {
    popupHandler() {
      this.editMode = false
      this.popupVisible = true
    },
    showDetails(id) {
      this.detailsVisible = true
      axios
        .post('signify/GetTemplateFileDetails', {
          TemplateID: id,
        })
        .then((resp) => {
          this.details = resp.data.Items || []
        })
    },

    fileUploader(val) {
      this.file = val
    },
    editClickHandler(id) {
      this.popupHandler()
      this.editMode = true
      const targetItem = this.docTemplates.find((el) => el.ID === id)
      this.selectedRow = targetItem
      let parsed = JSON.parse(targetItem.ParamsJson)
      this.fields = JSON.stringify(parsed, undefined, 4)
    },
    deleteRow(id) {
      axios
        .post('signify/UploadTemplate', {
          ID: id,
          Delete: true,
        })
        .then((resp) => {
          this.getTemplates()
        })
    },

    // downloadTemplate(){

    // }

    trClickHandler(element, event) {
      if (event.target.matches('.download-doc')) {
        const downloadLink = document.createElement('a')
        const isProduction = location.href.indexOf('.upgaming.com') > 0
        downloadLink.href = `https://intranet.upgaming.${
          isProduction ? 'com' : 'dev'
        }/api/signify/DownloadTemplate?ID=${element.ID}`
        downloadLink.click()
      } else if (event.target.matches('.copy-content')) {
        const copyContent = async () => {
          try {
            await navigator.clipboard.writeText(event.target.innerHTML)
            this.snackbarVisible = true
            this.snackbarPurpose = 'success'
            this.isOpened = false
            this.snackbarTime = 1000
            this.snackbarText = 'Copied'
          } catch (err) {
            console.error('Failed to copy: ', err)
          }
        }
        copyContent()
      } else if (event.target.matches('.show-details')) {
        this.showDetails(element.ID)
      }
    },

    searchHandler(val, column) {
      this.filters[column.key] = val
    },

    createDocument() {
      this.buttonDisabled = true
      axios
        .post('signify/UploadTemplate', {
          KeyName: this.docKey,
          File: this.file.base64,
          Name: this.docName,
        })
        .then((resp) => {
          // if (!resp.data.Value) return
          if (
            resp.status !== 200 ||
            (resp.data.ErrorMsg && resp.data.ErrorMsg.length)
          ) {
            this.buttonDisabled = false
            return
          }
          this.getTemplates()

          this.snackbarTime = 2000
          this.snackbarVisible = true
          this.snackbarPurpose = 'success'
          this.snackbarText = 'The document was successfully saved'
          this.buttonDisabled = false
          this.popupVisible = false
          this.mainFields.selectedPerson = ''
          this.mainFields.selectedTemplate = ''
          this.fields = []
          this.job = ''
        })
    },
    updateDocument() {
      let updated = this.selectedRow
      let parsed = JSON.parse(this.fields)
      updated.ParamsJson = JSON.stringify(parsed)
      this.buttonDisabled = true
      axios
        .post('signify/UploadTemplate', {
          ...updated,
        })
        .then((resp) => {
          // if (!resp.data.Value) return
          if (
            resp.status !== 200 ||
            (resp.data.ErrorMsg && resp.data.ErrorMsg.length)
          ) {
            this.buttonDisabled = false
            return
          }
          this.getTemplates()

          this.snackbarTime = 2000
          this.snackbarVisible = true
          this.snackbarPurpose = 'success'
          this.snackbarText = 'The document was successfully uploaded'
          this.buttonDisabled = false
          this.popupVisible = false
          this.mainFields.selectedPerson = ''
          this.mainFields.selectedTemplate = ''
          this.fields = []
          this.job = ''
        })
    },

    // getTemplateFileKeys(firstLoad) {
    //   axios
    //     .post('signify/GetTemplateFileKeys', {
    //       // ...this.filters,
    //       page: this.page,
    //       pageSize: 20,
    //     })
    //     .then((resp) => {
    //       console.log('====================================')
    //       console.log(resp)
    //       console.log('====================================')
    //       if (firstLoad) {
    //         this.documents = resp.data.Documents
    //       } else {
    //         this.documents = [...this.documents, ...resp.data.Documents]
    //       }
    //       this.totalPage = resp.data.PagingRes.TotalPageCount
    //     })
    // },
    closeHandler() {
      this.popupVisible = false
      this.mainFields.selectedPerson = ''
      this.mainFields.selectedTemplate = ''
      this.fields = []
      this.job = ''
    },
    selectPerson(value) {
      this.getPersonJob(value)
      // this.mainFields.selectedPerson = value
    },
    getTemplates() {
      axios.post('signify/GetTemplates').then((resp) => {
        this.docTemplates = resp.data.Items.map((el) => ({
          ...el,
          canEdit: true,
        }))
      })
    },

    getTemplateFields() {
      axios
        .post('signify/GetTemplateFields', {
          templateID: this.mainFields.selectedTemplate,
          personID: this.mainFields.selectedPerson,
        })
        .then((resp) => {
          if (resp.data.Error) return
          this.fields = resp.data.Items
        })
    },
    getPersonJob(id) {
      axios
        .get('Person/GetPersonsActiveJob', {
          params: {
            PersonID: id,
          },
        })
        .then((resp) => {
          this.job = resp.data.Value ? resp.data.Value[0].JobName || '' : null
        })
    },
  },
  watch: {
    mainFields: {
      deep: true,
      handler: function (to, from) {
        // Access the 'to' and 'from' values

        if (
          this.mainFields.selectedPerson !== '' &&
          this.mainFields.selectedTemplate !== ''
        ) {
          this.getTemplateFields()
        }

        if (this.mainFields.selectedPerson) {
          this.selectedPersonID = this.mainFields.selectedPerson
        }
      },
    },
    selectedPersonID() {
      this.getPersonJob(this.selectedPersonID)
    },
  },

  // created(())
}
</script>

<style scoped lang="scss">
.signify-docs-content {
  padding: 1.25rem;
}
.job {
  color: #959bc9;
  font-size: 14px;
  margin-top: 3px;
}

.button {
  width: 175px;
  background-color: #6956d6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-left: auto;
  border-radius: 0.3125rem;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  margin-left: auto;
}
.document-popup {
  ::v-deep .popup {
    height: auto;
    width: 30rem;

    /* width: 46rem !important; */
  }

  &.big-size {
    ::v-deep .popup {
      width: 55rem !important;
      height: 500px;
      textarea {
        height: 500px;
      }
    }
  }

  /* &.many-fields {
    ::v-deep .popup {
      width: 46rem;
    }
  } */
  .dynamic-forms {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: calc(100% - 50px);
    flex-wrap: wrap;
    overflow: auto;
    padding: 10px 0;
  }
  ::v-deep .popup {
    /* width: 23.125rem; */
    /* height: calc(100% - 2.3125rem); */
    /* overflow: auto;
    position: relative; */

    .forms-wrapper {
      height: calc(100% - 92px);
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      &.many-fields {
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
        gap: 10px;
      }
      .main-forms {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .single-form {
          flex: 1;
        }
        &.many-fields {
          flex-direction: unset;
        }
      }
    }
    .single-form {
      flex: 1;
      flex-grow: 0;
      /* &.many-fields {
        width: 50%;
      } */
      label {
        width: 100%;
      }
    }
    h3.title {
      font-size: 1.25rem;
      color: #959bc9;
      margin-bottom: 1.125rem;
      font-weight: 500;
    }
  }
}
.send-button {
  background-color: #0ad6a3;
  color: #fcfcfc;
  height: 3rem;
  margin-left: auto;
  width: 6.25rem;
  border-radius: 0.375rem;
  font-size: 1rem;
  cursor: pointer;
  display: grid;
  place-items: center;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
::v-deep .grid .table-helper table tbody td > span {
  &.green {
    color: #10d0a0;
  }
  &.yellow {
    color: rgb(245, 157, 24);
  }
}
.details-popup {
  ::v-deep .popup {
    height: 500px;
    overflow: hidden;
    width: 70rem;
    tbody {
      height: 300px !important;
    }
  }
}
::v-deep .grid {
  .table-wrapper {
    padding-bottom: 0;
  }
  .show-details {
    cursor: pointer;
  }
  .job-name-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .job-name {
      font-size: 13px;
      margin-top: 5px;
      opacity: 0.8;
    }
  }
  .download-doc {
    cursor: pointer;
  }
  .copy-content {
    cursor: pointer;
  }
}
::v-deep .alternativeHeader,
::v-deep .alternativeBody {
  top: 0rem;
}
::v-deep {
  .custom-file-input {
    .select-button {
      width: 100%;
    }
  }
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
</style>
