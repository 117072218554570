var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"day",class:{ first: _vm.firstInList }},[(!_vm.personsAttendance)?_c('div',{staticClass:"date"},[_c('span',{staticClass:"week-day"},[_vm._v(_vm._s(_vm.weekDay))]),_c('span',{staticClass:"day-and-month"},[_vm._v(_vm._s(_vm.dayMonth))])]):_c('div',{staticClass:"avatar-content"},[_c('div',{staticClass:"avatar",style:({
        'background-image': `url(https://intranet.upgaming.com/api/Person/GetPersonAvatar2?ID=${_vm.personID})`,
      })}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.fullName))])]),_c('div',{staticClass:"performace-grid"},[_c('div',{staticClass:"times"},_vm._l((_vm.times),function(hour,index){return _c('span',{key:index,staticClass:"time",class:{ included: _vm.timeIncludedInSchedule(hour) }},[_vm._v(" "+_vm._s(`${('0' + hour).slice(-2)} : 00`)+" ")])}),0),_c('div',{staticClass:"performance-background"},[(_vm.scheduleStart)?_c('p-separator',{attrs:{"color":"blue","left":_vm.scheduleStart,"isNightTime":_vm.isNightSchedule}}):_vm._e(),(_vm.scheduleEnd)?_c('p-separator',{attrs:{"color":"blue","left":_vm.scheduleEnd,"isNightTime":_vm.isNightSchedule}}):_vm._e(),(_vm.firstIn !== null)?_c('div',{staticClass:"p-time",style:(_vm.firstInTextStyle)},[_vm._v(" "+_vm._s(_vm.firstInString)+" ")]):_vm._e(),(_vm.lastOut)?_c('div',{staticClass:"p-time",style:(_vm.lastOutTextStyle)},[_vm._v(" "+_vm._s(_vm.lastOutString)+" ")]):_vm._e(),(_vm.isPastDay)?[(_vm.scheduleStart && _vm.scheduleEnd && !_vm.vacation)?_c('p-section',{attrs:{"from":_vm.scheduleStart,"to":_vm.scheduleEnd,"type":"schedule","isNightTime":_vm.isNightSchedule}}):_vm._e(),(_vm.isFirstOut && _vm.inOuts.length > 1)?[_c('p-separator',{attrs:{"color":"green","left":0,"isNightTime":_vm.isNightSchedule}}),_c('p-section',{attrs:{"type":"overtime","from":0,"to":_vm.isFirstOut,"isNightTime":_vm.isNightSchedule}}),_c('p-separator',{attrs:{"color":"green","left":_vm.isFirstOut,"isNightTime":_vm.isNightSchedule}})]:_vm._e(),(
            _vm.firstIn !== null &&
            _vm.lastOut !== null &&
            _vm.firstIn < _vm.scheduleEnd &&
            _vm.lastOut > _vm.scheduleEnd
          )?[_c('p-separator',{attrs:{"color":"green","left":_vm.lastOut,"isNightTime":_vm.isNightSchedule}}),_c('p-section',{attrs:{"type":"overtime","from":_vm.scheduleEnd,"to":_vm.lastOut,"isNightTime":_vm.isNightSchedule}})]:_vm._e(),(
            _vm.firstIn !== null &&
            _vm.lastOut !== null &&
            _vm.firstIn < _vm.scheduleStart &&
            _vm.lastOut > _vm.scheduleStart
          )?[_c('p-separator',{attrs:{"color":"green","left":_vm.firstIn,"isNightTime":_vm.isNightSchedule}}),_c('p-section',{attrs:{"type":"overtime","from":_vm.firstIn,"to":_vm.scheduleStart,"isNightTime":_vm.isNightSchedule}})]:_vm._e(),(
            _vm.firstIn !== null &&
            _vm.lastOut !== null &&
            _vm.firstIn < _vm.scheduleEnd &&
            _vm.lastOut > _vm.scheduleEnd
          )?[_c('p-separator',{attrs:{"color":"green","left":_vm.lastOut,"isNightTime":_vm.isNightSchedule}}),_c('p-section',{attrs:{"type":"overtime","from":_vm.scheduleEnd,"to":_vm.lastOut,"isNightTime":_vm.isNightSchedule}})]:_vm._e(),(
            _vm.scheduleType === 2 &&
            _vm.firstIn !== null &&
            _vm.scheduleStart &&
            _vm.firstIn > _vm.scheduleStart + 15
          )?[_c('p-separator',{attrs:{"color":_vm.scheduleType === 2 || !_vm.vacation ? 'red' : 'green',"left":_vm.firstIn,"isNightTime":_vm.isNightSchedule}}),_c('p-section',{attrs:{"type":_vm.missed,"from":_vm.scheduleStart,"to":_vm.firstIn,"isNightTime":_vm.isNightSchedule}})]:_vm._e(),(
            _vm.scheduleType !== 2 &&
            _vm.firstIn !== null &&
            _vm.firstIn > _vm.scheduleStart &&
            _vm.firstIn < _vm.scheduleEnd
          )?[_c('p-separator',{attrs:{"color":_vm.scheduleType === 2 || !_vm.vacation ? 'red' : 'green',"left":_vm.firstIn,"isNightTime":_vm.isNightSchedule}}),_c('p-section',{attrs:{"type":"missed","from":_vm.scheduleStart,"to":_vm.firstIn,"isNightTime":_vm.isNightSchedule}})]:_vm._e(),(
            _vm.scheduleType === 2 &&
            _vm.lastOut &&
            _vm.lastOut > _vm.scheduleStart &&
            _vm.lastOut < _vm.scheduleEnd - 15
          )?[_c('p-separator',{attrs:{"color":_vm.scheduleType == 2 || !_vm.vacation ? 'red' : 'green',"left":_vm.lastOut,"isNightTime":_vm.isNightSchedule}}),_c('p-section',{attrs:{"type":!_vm.isToday ? 'missed' : 'today',"from":_vm.lastOut,"to":_vm.scheduleEnd,"isNightTime":_vm.isNightSchedule}})]:_vm._e(),(
            _vm.scheduleType !== 2 &&
            _vm.lastOut &&
            _vm.lastOut > _vm.scheduleStart &&
            _vm.lastOut < _vm.scheduleEnd
          )?[_c('p-separator',{attrs:{"color":_vm.scheduleType == 2 || !_vm.vacation ? 'red' : 'green',"left":_vm.lastOut,"isNightTime":_vm.isNightSchedule}}),_c('p-section',{attrs:{"type":!_vm.isToday ? 'missed' : 'today',"from":_vm.lastOut,"to":_vm.scheduleEnd,"isNightTime":_vm.isNightSchedule}})]:_vm._e(),(
            (!_vm.scheduleStart || !_vm.scheduleEnd) &&
            _vm.firstIn !== null &&
            _vm.lastOut !== null
          )?[_c('p-section',{attrs:{"from":_vm.firstIn,"to":_vm.lastOut,"type":"overtime","isNightTime":_vm.isNightSchedule}}),_c('p-separator',{attrs:{"color":"green","left":_vm.firstIn,"isNightTime":_vm.isNightSchedule}}),_c('p-separator',{attrs:{"color":"green","left":_vm.lastOut,"isNightTime":_vm.isNightSchedule}})]:_vm._e(),(
            _vm.scheduleStart !== null &&
            _vm.scheduleEnd !== null &&
            _vm.firstIn !== null &&
            _vm.lastOut !== null &&
            ((_vm.firstIn < _vm.scheduleStart && _vm.lastOut < _vm.scheduleStart) ||
              (_vm.firstIn > _vm.scheduleEnd && _vm.lastOut > _vm.scheduleEnd))
          )?[_c('p-section',{attrs:{"type":_vm.personsAttendance && _vm.isToday ? 'today' : 'missed',"from":_vm.scheduleStart,"to":_vm.scheduleEnd,"isNightTime":_vm.isNightSchedule}}),_c('p-section',{attrs:{"type":"overtime","from":_vm.firstIn,"to":_vm.lastOut,"isNightTime":_vm.isNightSchedule}}),_c('p-separator',{attrs:{"left":_vm.firstIn,"color":"green","isNightTime":_vm.isNightSchedule}}),_c('p-separator',{attrs:{"left":_vm.lastOut,"color":"green","isNightTime":_vm.isNightSchedule}})]:_vm._e(),_vm._l((_vm.inactivities),function(inactivity,index){return [(inactivity.duration > 60)?_c('p-section',{key:index,attrs:{"type":"inactive","from":inactivity.start / 60,"to":(inactivity.start + inactivity.duration) / 60,"isNightTime":_vm.isNightSchedule}}):_vm._e()]}),_vm._l((_vm.outInPairs),function(pair,index){return [(pair.to - pair.from > 1)?_c('p-section',{key:index,attrs:{"type":"out","from":pair.from,"to":pair.to,"isNightTime":_vm.isNightSchedule}}):_vm._e()]}),(
            (_vm.firstIn === null || _vm.lastOut === null) &&
            !_vm.vacation &&
            _vm.scheduleStart &&
            _vm.scheduleEnd
          )?[_c('p-section',{attrs:{"type":"missed","from":_vm.scheduleStart,"to":_vm.scheduleEnd,"isNightTime":_vm.isNightSchedule}})]:_vm._e(),(_vm.vacation && _vm.scheduleStart && _vm.scheduleEnd)?[_c('p-section',{attrs:{"from":_vm.scheduleStart,"to":_vm.scheduleEnd,"type":"vacation","text":_vm.vacation,"isNightTime":_vm.isNightSchedule}})]:_vm._e(),(
            _vm.scheduleType === 2 &&
            _vm.vacationType === 2 &&
            _vm.scheduleStart &&
            _vm.scheduleEnd &&
            (_vm.firstIn === null || _vm.lastOut === null)
          )?[_c('p-section',{attrs:{"from":_vm.scheduleStart,"to":_vm.scheduleEnd,"type":"missed","isNightTime":_vm.isNightSchedule}})]:_vm._e()]:_vm._e()],2)]),_c('one-day-totals',{attrs:{"scheduleStart":_vm.scheduleStart,"scheduleEnd":_vm.scheduleEnd,"breakDuration":_vm.breakDuration,"scheduleType":_vm.scheduleType,"isVacation":!!_vm.vacation,"isvacationType":_vm.vacationType,"inOuts":_vm.inOuts,"inactivities":_vm.inactivities,"totalAttendance":_vm.totalAttendance,"totalActive":_vm.totalActive,"plannedTime":_vm.plannedTime,"firstIn":_vm.firstIn,"lastOut":_vm.lastOut,"outIns":_vm.outInPairs,"firstInList":_vm.firstInList,"totalWork":_vm.totalWork,"idToSend":_vm.idToSend,"scheduleId":_vm.scheduleId},on:{"notype":function($event){return _vm.$emit('notype')}}},[_c('custom-checkbox',{attrs:{"title":"By clicking this check-box,  command will recalculate attendance time, to count overtime for this day","value":_vm.isRecalculated},on:{"input":_vm.checkboxClickHandler}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }