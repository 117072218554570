<template>
  <div class="statistics">
    <div class="statistics__waiting">
      <div class="statistics__waiting-title">
        Waiting for {{ nonActivePersons }} responses from {{ persons.length }}
      </div>
      <div
        v-if="!finished"
        class="statistics__waiting-button"
        @click="$emit('reminder-clicked')"
      >
        Send Reminder
      </div>
    </div>
    <div class="statistics__chart">
      <canvas ref="statisticsChart"></canvas>
      <div class="statistics__chart-options">
        <div
          class="statistics__chart-options-option"
          v-for="(option, i) in chartStatistics"
          :key="i"
        >
          <div
            class="statistics__chart-options-option-chart"
            :style="{ backgroundColor: colors[i] }"
          />
          <div
            class="statistics__chart-options-option-name"
            :title="option.option.length > 20 ? option.option : null"
          >
            {{ option.option }}
          </div>
          <div class="statistics__chart-options-option-percentage">
            {{
              option.quantity === 0
                ? 0
                : ((option.quantity / option.allQuantity) * 100).toFixed(1)
            }}%
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: {
    published: Boolean,
    persons: {
      type: Array,
      required: true,
    },
    nonActivePersons: {
      type: Number,
      required: true,
    },
    finished: Boolean,
    chartStatistics: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.drawChart();
  },
  computed: {
    chartData() {
      return [...this.chartStatistics.map((el) => el.quantity)];
    },
    chartLabels() {
      return [...this.chartStatistics.map((el) => el.option)];
    },
  },
  data() {
    return {
      chart: null,
      colors: [
        "#2ee9b4",
        "#243391",
        "#6662bb",
        "#739def",
        "#9aabb7",
        "#a2c037",
        "#1772e4",
        "#805c27",
        "#2ee9b4",
        "#243391",
        "#6662bb",
        "#739def",
        "#9aabb7",
        "#a2c037",
        "#1772e4",
        "#805c27",
        "#2ee9b4",
        "#243391",
        "#6662bb",
        "#739def",
        "#9aabb7",
        "#a2c037",
        "#1772e4",
        "#805c27",
        "#2ee9b4",
        "#243391",
        "#6662bb",
        "#739def",
        "#9aabb7",
        "#a2c037",
        "#1772e4",
        "#805c27",
      ],
    };
  },
  methods: {
    drawChart() {
      const ctx = this.$refs.statisticsChart.getContext("2d");
      if (!this.chart) {
        this.chart = new Chart(ctx, {
          type: "pie",
          data: {
            datasets: [
              {
                data: this.chartData.length > 0 ? this.chartData : [1],
                backgroundColor:
                  this.chartData.length > 0 ? [...this.colors] : ["#959bc9"],
              },
            ],
            labels: this.chartData.length > 0 ? this.chartLabels : ["No data"],
          },
          options: {
            elements: {
              arc: {
                borderWidth: 0,
              },
            },
            tooltips: {
              // enabled: false
              titleFontSize: 16,
              bodyFontSize: 16,
              callbacks: {
                label: (tooltipItem, data) => {
                  return data.labels[tooltipItem.index] || "";
                },
              },
            },
            hover: {
              mode: null,
            },
            legend: {
              display: false,
            },
            // showTooltips: false,
          },
        });
      } else {
        this.chart.data.datasets[0].data = this.chartData;
        this.chart.data.datasets[0].backgroundColor =
          this.chartData.length > 0 ? [...this.colors] : ["#959bc9"];
        if (this.chartData.length === 0) this.chart.data.labels = ["No data"];
        else this.chart.data.labels = this.chartLabels;
        this.chart.update();
      }
    },
  },

  watch: {
    chartStatistics: {
      handler() {
        this.drawChart();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics {
  height: calc(100% - 69px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__waiting {
    padding: 40px 70px;
    width: 100%;
    height: 100px;
    background: white;
    border-radius: 10px;
    color: #283593;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-button {
      cursor: pointer;
      padding: 13px 10px;
      background-color: #4aedc4;
      color: white;
      border-radius: 10px;
      &:active {
        background-color: #16b18a;
        background-size: 100%;
        transition: background 0.1s;
      }
    }
  }
  &__chart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 40px;
    background-color: white;
    border-radius: 10px;

    &-options {
      margin-top: 50px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-height: 200px;
      overflow-y: auto;
      padding-right: 10px;

      &-option {
        display: flex;
        width: 50%;
        align-items: center;
        color: #283593;
        font-size: 13px;
        margin-bottom: 20px;

        &:nth-child(even) {
          justify-content: flex-end;
        }

        &-chart {
          height: 10px;
          width: 10px;
          background-color: red;
          border-radius: 100%;
        }

        &-name {
          margin-left: 15px;
          white-space: nowrap;
          width: 65%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-percentage {
          margin-left: 30px;
          color: #acb1d5;
          font-weight: 500;
        }
      }
    }
  }

  canvas {
    width: 300px;
    height: 300px;
  }
}
</style>
