var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_c('news-polls-offers-wrapper',{attrs:{"type":"news","data":_vm.filteredByStatus,"draftClicked":_vm.draft,"onGoingClicked":_vm.onGoing,"completedClicked":_vm.completed,"maxPageCount":_vm.maxPageCount},on:{"search-value":(value) => (_vm.search = value),"ongoing-clicked":function($event){_vm.onGoing = !_vm.onGoing},"draft-clicked":function($event){_vm.draft = !_vm.draft},"get-data":_vm.getData,"completed-clicked":function($event){_vm.completed = !_vm.completed},"clicked":_vm.getSingleNews,"add-item":function($event){_vm.addPopupVisible = true},"edit-item":_vm.getSingleNews,"delete-item":_vm.deleteClickHandler}}),_c('custom-popup',{staticStyle:{"z-index":"999"},attrs:{"visible":_vm.bannerUploadPopup,"flexible-size":true,"title":"Add Banner"},on:{"close":function($event){_vm.bannerUploadPopup = false}}},[_c('custom-file-input',{attrs:{"title":"Select File","value":_vm.news.banner},on:{"change":(file) => (_vm.news.banner = file)}}),_c('div',{staticClass:"upload-button",on:{"click":function($event){_vm.bannerUploadPopup = false}}},[_vm._v("Upload")])],1),_c('div',{staticClass:"preview-popup"},[_c('custom-popup',{attrs:{"visible":_vm.showPreviewPopup},on:{"close":function($event){_vm.showPreviewPopup = false}}},[_c('div',{staticClass:"desc-banner-content"},[(Object.keys(_vm.news.file).length > 0)?_c('div',{staticClass:"banner-content",style:({
            backgroundImage: `url(data:image/jpeg;base64,${_vm.news.file.base64})`,
          })}):_c('div',{staticClass:"preview-banner"},[_vm._v("Banner here")]),_c('div',{staticClass:"desc-content"},[_c('div',{staticClass:"preview-header"},[_vm._v(" "+_vm._s(_vm.news.title ? _vm.news.title : "Title here")+" ")]),(_vm.news.text)?_c('div',{staticClass:"preview-desc",domProps:{"innerHTML":_vm._s(_vm.news.text)}}):_c('div',{staticClass:"preview-desc"},[_vm._v("Description here")])])]),_c('div',{staticClass:"news__audience"},[_c('div',{staticClass:"news__audience-header"},[_c('div',{staticClass:"news__audience-header-title"},[_c('b',[_vm._v("Audience")]),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.audience.length)+" ")])])]),_c('div',{directives:[{name:"dragscroll",rawName:"v-dragscroll"}],staticClass:"news__audience-items"},_vm._l((_vm.audience),function(person){return _c('div',{key:person.PersonID,staticClass:"news__audience-items-item"},[(person)?_c('div',{staticClass:"news__audience-items-item-avatar",style:({
                backgroundImage: `url(data:image/jpeg;base64,${person.File})`,
              })}):_vm._e(),_c('div',{staticClass:"news__audience-items-item-name"},[_c('p',[_vm._v(_vm._s(person.FirstName))]),_c('p',[_vm._v(_vm._s(person.LastName))])])])}),0)])])],1),_c('custom-popup',{staticClass:"popup",attrs:{"title":!_vm.isEditMode ? 'Add News' : 'Edit News',"visible":_vm.addPopupVisible,"flexible-size":true},on:{"close":function($event){_vm.addPopupVisible = false;
      _vm.isEditMode = false;
      _vm.fileRecords = [];
      _vm.fileRecordsForUpload = [],
      _vm.news.banner.file = null,
      _vm.news.banner.fileName = null
      _vm.news.banner.fileType = null
      _vm.news.banner.id = null}}},[_c('div',{staticClass:"add-news"},[_c('div',{staticClass:"add-news__header"},[_c('div',{staticClass:"add-news__title"},[_c('custom-input',{staticClass:"add-news__title-input",attrs:{"trigger-change-on-blur":false,"title":"Title ..."},model:{value:(_vm.news.title),callback:function ($$v) {_vm.$set(_vm.news, "title", $$v)},expression:"news.title"}}),_c('div',{staticClass:"add-news__title-count"},[_c('span',{class:[
                'count',
                {
                  'count--passed': _vm.news.title.length < 50,
                  'count--error': _vm.news.title.length > 50,
                },
              ]},[_vm._v(" "+_vm._s(_vm.news.title.length)+" ")]),_c('span',[_vm._v(" / 50 ")])])],1),_c('div',{staticClass:"add-news__select"},[(_vm.addPopupVisible)?_c('select-persons',{attrs:{"initialSelectedPersons":_vm.selectedValues.person,"placementTitle":true,"departments":_vm.departmentPeople},on:{"select":(people) => (_vm.selectedValues.person = people)}}):_vm._e()],1),_c('div',{staticClass:"priority-content",on:{"click":function($event){_vm.highPriority = !_vm.highPriority}}},[_c('span',{staticClass:"material-icons-round"},[_vm._v(" priority_high ")]),_c('span',[_vm._v("High Priority")]),(_vm.highPriority)?_c('span',{staticClass:"material-icons success"},[_vm._v("check")]):_vm._e()]),_c('div',{staticClass:"add-news__button",on:{"click":function($event){_vm.bannerUploadPopup = true}}},[_c('img',{attrs:{"src":require('@/assets/icons/icon_add_image.svg'),"alt":"add-image"}}),_c('span',[_vm._v("Upload banner")]),(_vm.news.banner.file !== null)?_c('span',{staticClass:"material-icons success"},[_vm._v("check")]):_vm._e()])]),_c('div',{staticClass:"add-news__editor"},[_c('custom-editor',{attrs:{"value":_vm.news.text},model:{value:(_vm.news.text),callback:function ($$v) {_vm.$set(_vm.news, "text", $$v)},expression:"news.text"}}),_c('div',{staticClass:"add-news__editor-count"},[_c('div',{staticClass:"preview-button",on:{"click":_vm.previewClickHandler}},[_c('img',{attrs:{"src":require("@/assets/preview-icon.svg")}}),_c('span',[_vm._v("Preview")])]),_c('span',{class:[
              'count',
              {
                'count--passed': _vm.stripHTML(_vm.news.text).length < 3000,
                'count--error': _vm.stripHTML(_vm.news.text).length > 3000,
              },
            ]},[_vm._v(" "+_vm._s(_vm.stripHTML(_vm.news.text).length)+" ")]),_c('span',[_vm._v(" / 3000 ")])])],1),_c('div',{staticClass:"news-attachments"},[_c('div',{staticClass:"edit-attached-files"},_vm._l((_vm.news.newsAttachments),function(file,ind){return _c('div',{key:ind,staticClass:"editable-attached-file",class:{'border':file.FileName},on:{"click":function($event){return _vm.downloadAttachment(file.FileID)}}},[(file.FileName)?_c('div',{staticClass:"delete",on:{"click":function($event){$event.stopPropagation();return _vm.deleteAttachedFile(file.FileID,_vm.news.id)}}},[_vm._v("X")]):_vm._e(),(file.FileName)?_c('div',{staticClass:"file-block",attrs:{"type":file.FileName ? file.FileName.split('.').pop() : ''}},[_vm._v(" "+_vm._s(file.FileName ? file.FileName.split(".").slice(-1)[0] : '')+" ")]):_vm._e(),(file.FileName)?_c('div',{staticClass:"file-name"},[_vm._v(" "+_vm._s(file.FileName ? file.FileName : '')+" ")]):_vm._e()])}),0)]),_c('div',{staticClass:"file-uploader-content"},[_c('div',{staticClass:"uploader-wrapper eventCalendar"},[_c('div',{staticClass:"upload-files"},[_c('span',{staticClass:"title"},[_vm._v(" Attach documents ")]),_c('div',{staticClass:"additional-uploader-wrapper"},[_c('VueFileAgent',{ref:"vueFileAgent",attrs:{"theme":'default',"multiple":true,"deletable":true,"meta":true,"accept":'image/*,.zip,.pdf,.docx,.doc,.xlsx,.xls,.pptx,.ppt',"maxSize":'3MB',"maxFiles":3,"helpText":' ',"errorText":{
                  type: 'Invalid file type',
                  size: 'Files should not exceed 3MB in size',
                }},on:{"select":function($event){return _vm.filesSelected($event)},"beforedelete":function($event){return _vm.onBeforeDelete($event)},"delete":function($event){return _vm.fileDeleted($event)}},model:{value:(_vm.fileRecords),callback:function ($$v) {_vm.fileRecords=$$v},expression:"fileRecords"}})],1)])])]),(!_vm.isEditMode)?_c('div',{staticClass:"add-news__buttons"},[_c('div',{staticClass:"button button--unfilled",on:{"click":function($event){return _vm.createNews(false)}}},[_vm._v(" Save Draft ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.createNews(true)}}},[_vm._v("Publish")])]):_c('div',{staticClass:"add-news__buttons"},[(!_vm.news.published)?_c('div',{staticClass:"button button--unfilled",on:{"click":function($event){return _vm.saveNews(false)}}},[_vm._v(" Save Draft ")]):_vm._e(),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.saveNews(true)}}},[_vm._v("Publish")])])])]),(_vm.snackbarVisible)?_c('custom-snackbar',{attrs:{"purpose":_vm.snackbarPurpose,"text":_vm.snackbarText},on:{"close":function($event){_vm.snackbarVisible = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }