<template>
  <div class="background">
    <news-polls-offers-wrapper
      type="poll"
      :data="filteredByStatus"
      @search-value="(value) => (search = value)"
      @clicked="redirectToPoll"
      @add-item="addPopupVisible = true"
      @edit-item="editClickHandler"
      :draftClicked="draft"
      :onGoingClicked="onGoing"
      :completedClicked="completed"
      @get-data="getData"
      :maxPageCount="maxPageCount"
      @delete-item="deleteClickHandler"
      @ongoing-clicked="onGoing = !onGoing"
      @draft-clicked="draft = !draft"
      @completed-clicked="completed = !completed"
    />
    <custom-popup
      style="z-index: 999"
      :visible="bannerUploadPopup"
      :flexible-size="true"
      title="Add Banner"
      @close="bannerUploadPopup = false"
    >
      <custom-file-input
        title="Select File"
        :value="poll.file"
        @change="(file) => (poll.file = file)"
      />
      <div class="upload-button" @click="bannerUploadPopup = false">Upload</div>
    </custom-popup>
    <div class="preview-popup">
      <custom-popup
        :visible="showPreviewPopup"
        @close="showPreviewPopup = false"
      >
        <div class="poll">
          <div class="poll__content">
            <div class="banner-details-content">
              <div
                class="poll__banner"
                :style="{
                  'background-image': `url(data:image/jpeg;base64,${poll.file.base64})`,
                }"
              />
              <div class="poll__details">
                <div class="wrapper">
                  <div class="poll-date">
                    Expiry date:
                    {{ getDateString(poll.expiryDate, "dd MMM yyyy") }}
                  </div>
                  <div class="poll__question">
                    {{ poll.question || "question" }}
                  </div>
                  <div class="poll__options">
                    <div
                      class="poll__option"
                      v-for="(option, i) in poll.options"
                      :key="i"
                    >
                      <div class="poll__option-wrapper">
                        <div class="poll__option-index">{{ i + 1 }}</div>
                        <div class="poll__option-name">{{ option.option }}</div>
                      </div>
                      <div :class="['poll__option-checkbox']"></div>
                    </div>
                  </div>
                </div>

                <div class="poll__comment"></div>
              </div>
            </div>
            <div class="desc-comment-content">
              <div class="poll__description">{{ poll.description }}</div>
              <textarea
                placeholder="Type your comment"
                type="text"
                class="cstm-input"
              />
            </div>
          </div>
          <div class="poll__button">Send</div>
        </div>
      </custom-popup>
    </div>
    <custom-popup
      class="popup"
      :visible="addPopupVisible"
      :flexible-size="true"
      @close="
        addPopupVisible = false;
        isEditMode = false;
      "
    >
      <div class="add-poll">
        <div class="add-poll__top">
          <div class="add-poll__title">
            <input
              class="cstm-input cstm-input--title"
              v-model="poll.title"
              placeholder="Title"
            />
          </div>
          <div class="add-poll__description">
            <textarea
              rows="5"
              class="cstm-input cstm-input--description"
              v-model="poll.description"
              placeholder="Type Description ..."
            />
          </div>
        </div>
        <div class="padding-wrapper">
          <div class="add-poll__header">
            <div class="add-poll__expire">
              <date-picker v-model="poll.expiryDate" title="Expiry date" />
            </div>
            <div class="add-poll__select">
              <select-persons
                v-if="addPopupVisible"
                :initialSelectedPersons="selectedValues.person"
                :placementTitle="true"
                :departments="departmentPeople"
                @select="(people) => (selectedValues.person = people)"
              />
            </div>
            <div class="priority-content" @click="highPriority = !highPriority">
              <span class="material-icons-round"> priority_high </span>
              <span>High Priority</span>
              <span v-if="highPriority" class="material-icons success"
                >check</span
              >
            </div>
            <div class="add-poll__button" @click="bannerUploadPopup = true">
              <img
                :src="require('@/assets/icons/icon_add_image.svg')"
                alt="add-image"
              />
              <span>Upload image</span>

              <span
                v-if="Object.keys(poll.file).length > 0"
                class="material-icons success"
                >check</span
              >
            </div>

            <div class="add-poll__header-wrapper"></div>
          </div>
          <div class="add-poll__question">
            <input
              class="cstm-input cstm-input--question"
              v-model="poll.question"
              placeholder="Type Question ..."
            />
          </div>
          <div class="add-poll__type">
            <span class="add-poll__type-title"> Select Response Type </span>
            <div class="add-poll__type-wrapper" @click="poll.type = 1">
              <img
                v-if="poll.type === 1"
                :src="require('@/assets/icons/check-fill.svg')"
              />
              <img v-else :src="require('@/assets/icons/check-unfilled.svg')" />
            </div>
            <div class="add-poll__type-wrapper" @click="poll.type = 2">
              <img
                v-if="poll.type === 2"
                :src="require('@/assets/icons/icon_multiple-choice-active.svg')"
              />
              <img
                v-else
                :src="
                  require('@/assets/icons/icon_multiple-choice-deselected.svg')
                "
              />
            </div>
          </div>

          <div class="add-poll__options">
            <div
              class="add-poll__options-option"
              v-for="(opt, index) in poll.options"
              :key="index"
            >
              <div class="add-poll__options-option-index">
                {{ index + 1 }}
              </div>
              <div class="add-poll__options-option-name">
                <input
                  class="cstm-input cstm-input--option"
                  :placeholder="`Option ${index + 1}`"
                  v-model="opt.option"
                  @focus="optionClick(index)"
                />
              </div>
              <div
                class="add-poll__options-option-delete"
                @click="deleteOptionClick(index)"
                v-if="poll.options.length > 2"
              >
                <img :src="require('@/assets/icons/icon_delete.svg')" />
              </div>
            </div>
            <div class="add-poll__options-add-option" @click="addOptionClick">
              <img :src="require('@/assets/icons/icon_add.svg')" />
              <span> Add Option </span>
            </div>
          </div>
          <div class="add-poll__buttons">
            <switcher v-model="poll.anonymous" title="Anonymous" />
            <div class="preview-button" @click="previewClickHandler">
              <img src="@/assets/preview-icon.svg" />
              <span>Preview</span>
            </div>
            <div class="wrapper-button">
              <div class="button button--unfilled" @click="createPoll(false)">
                Save Draft
              </div>
              <div class="button" @click="createPoll(true)">Publish</div>
            </div>
          </div>
        </div>
      </div>
    </custom-popup>
    <custom-snackbar
      v-if="snackbarVisible"
      :purpose="snackbarPurpose"
      :text="snackbarText"
      @close="snackbarVisible = false"
    />
  </div>
</template>

<script>
import axios from "@/Helpers/AxiosInstance";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";
import NewsPollsOffersWrapper from "@/components/ReusableComponents/NewsPollsOffersWrapper/NewsPollsOffersWrapper";
import Switcher from "@/components/ReusableComponents/Switcher/Switcher";
import CustomFileInput from "@/components/ReusableComponents/CustomFileInput/CustomFileInput";
// import CustomMultiSelect from "@/components/ReusableComponents/CustomMultiSelect/CustomMultiSelect";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import SelectPersons from "@/components/FixedSchedules/SelectPersons.vue";

export default {
  components: {
    Switcher,
    NewsPollsOffersWrapper,
    SelectPersons,
    CustomFileInput,
    // CustomMultiSelect,
    DatePicker,
    CustomSnackbar,
    CustomPopup,
  },
  async created() {
    await this.getData(1, true);
    // await this.getDepartments();
    this.getDepartmentJobs();
  },
  computed: {
    departmentsSelect() {
      return this.departments.map((department) => ({
        value: department.ID,
        title: department.Name,
        field: "department",
        children: department.jobs.map((job) => ({
          parentField: "department",
          parentValue: department.ID,
          field: "job",
          title: job.JobName,
          value: job.Job,
          children: job.persons.map((person) => ({
            parentField: "job",
            parentValue: job.Job,
            field: "person",
            title: `${person.FirstName} ${person.LastName}`,
            value: person.PersonID,
          })),
        })),
      }));
    },
    filteredData() {
      let result = [];
      if (!this.search) {
        result = this.data;
      } else {
        result = this.data.filter((el) =>
          el.title.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return result;
    },
    filteredByStatus() {
      let result = [];
      if (this.onGoing) {
        result = this.filteredData.filter((el) => el.status == "ongoing");
      } else if (this.draft) {
        result = this.filteredData.filter((el) => el.status == "draft");
      } else if (this.completed) {
        result = this.filteredData.filter((el) => el.status == "completed");
      } else {
        result = this.filteredData;
      }

      return result;
    },
  },
  data() {
    return {
      currentPage: 1,
      maxPageCount: null,
      poll: {
        title: "",
        file: {},
        anonymous: false,
        type: 1,
        question: "",
        description: "",

        options: [
          {
            option: "",
          },
          {
            option: "",
          },
        ],
        expiryDate: "",
      },

      selectedValues: {
        person: [],
        department: [],
        job: [],
      },
      data: [],

      addPopupVisible: false,
      bannerUploadPopup: false,
      highPriority: false,
      isEditMode: false,
      departmentPeople: [],
      onGoing: false,
      draft: false,
      completed: false,
      snackbarPurpose: "",
      snackbarText: "",
      search: "",
      snackbarVisible: false,
      showPreviewPopup: false,
    };
  },
  methods: {
    redirectToPoll({ id }) {
      this.$router.push(`/Polls/Edit/${id}`);
    },
    getDepartmentJobs() {
      axios.get("News/GetPlacementDropDownPersons").then((resp) => {
        if (resp.data.ErrorMsg) return;
        this.departmentPeople = resp.data.Value.DepartmentJobPersons.map(
          (dep) => {
            return {
              id: dep.DepartmentID == null ? 0 : dep.DepartmentID,
              name:
                dep.DepartmentName == null
                  ? "Jobs without department"
                  : dep.DepartmentName,
              jobs: dep.JobPersons.map((job) => {
                return {
                  id: job.JobID,
                  name: job.JobName,
                  people: job.Persons.map((person) => {
                    return {
                      id: person.PersonID,
                      name: person.FirstName + " " + person.LastName,
                    };
                  }),
                };
              }),
            };
          }
        );
      });
    },
    async getData(page, getFirst) {
      const polls = await axios
        .get("News/GetPollsAsAdmin", {
          params: {
            Page: page || this.currentPage,
            PageSize: 12,
          },
        })
        .then((res) => res.data);
      this.maxPageCount = polls.Paging.TotalPageCount;
      let mappedData = polls.Value.map(
        ({
          ExpiryDate,
          File,
          ID,
          Title,
          Published,
          Description,
          Type,
          StartTime,
        }) => ({
          title: Title,
          description: Description,
          id: ID,
          type: Type,
          creationTime: StartTime,
          status: Published
            ? new Date() >
              new Date(ExpiryDate).setDate(new Date(ExpiryDate).getDate() + 1)
              ? "completed"
              : "ongoing"
            : "draft",
          banner: File,
        })
      );
      if (getFirst) this.data = mappedData;
      else {
        this.data.push(...mappedData);
      }
    },
    getDateString(date, format = "yyyy-MM-dd") {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      if (date === "Invalid Date") return;

      const MONTHS = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      var days = ("0" + date.getDate()).slice(-2),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        year = date.getFullYear(),
        hours = ("0" + date.getHours()).slice(-2),
        minutes = ("0" + date.getMinutes()).slice(-2),
        seconds = ("0" + date.getSeconds()).slice(-2);

      return format
        .replace("dd", days)
        .replace("MMM", MONTHS[parseInt(month) - 1])
        .replace("MM", month)
        .replace("yyyy", year)
        .replace("yy", String(year).slice(-2))
        .replace("HH", hours)
        .replace("mm", minutes)
        .replace("ss", seconds)
        .replace("ww", WEEKDAYS[date.getDay()]);
    },
    previewClickHandler() {
      this.showPreviewPopup = !this.showPreviewPopup;
      this.audience = [];
      let TVP = [];
      if (this.selectedValues.person.length) {
        TVP = this.selectedValues.person.map((el) => {
          return {
            PersonID: el,
          };
        });
        axios
          .post("Person/GetManyPersons", {
            TVP: TVP,
          })
          .then((resp) => {
            this.audience = resp.data.Value;
          });
      }
    },
    async getSinglePoll(id) {
      const data = await axios
        .get("News/GetPollDetails", {
          params: {
            Poll: id,
          },
        })
        .then((resp) => {
          const { Value } = resp.data;
          return Value;
        });
      const {
        Poll: {
          Poll,
          Anonymous,
          Description,
          ExpiryDate,
          File,
          FileType,
          Filename,
          Question,
          Title,
          Type,
        },
        PollOptions: options,
        PollVisibilities: restrictions,
      } = data;

      this.poll = {
        id: Poll,
        title: Title,
        file: {
          filename: Filename,
          type: FileType,
          base64: File,
        },
        anonymous: Anonymous,
        type: Type,
        question: Question,
        description: Description,
        options: options.map((el) => ({ option: el.Option })),
        expiryDate: new Date(ExpiryDate),
      };
      restrictions.forEach((el) => {
        if (el.VisibilityRestrictionType === 1)
          this.selectedValues["person"].push(el.VisibleForEntity);
        else if (el.VisibilityRestrictionType === 2)
          this.selectedValues["job"].push(el.VisibleForEntity);
        else if (el.VisibilityRestrictionType === 4)
          this.selectedValues["department"].push(el.VisibleForEntity);
      });
    },
    // errorText(){

    // },
    createPoll(published) {
      const restrictions = [
        ...this.selectedValues["person"].map((el) => ({
          VisibilityRestrictionType: 1,
          VisibleForEntity: el,
        })),
        ...this.selectedValues["job"].map((el) => ({
          VisibilityRestrictionType: 2,
          VisibleForEntity: el,
        })),
        ...this.selectedValues["department"].map((el) => ({
          VisibilityRestrictionType: 4,
          VisibleForEntity: el,
        })),
      ];
      const { poll } = this;
      const options = poll.options
        .filter((el) => el.option.length > 0)
        .map((el) => ({
          Option: el.option,
        }));
      const todayDate = new Date();

      if (
        poll.title &&
        published &&
        this.selectedValues.person.length &&
        poll.question &&
        poll.options[0].option.length &&
        poll.options[1].option.length &&
        poll.expiryDate >= todayDate.getDate()
      ) {
        const response = axios
          .post("News/CreatePoll", {
            Title: poll.title,
            Description: poll.description,
            Question: poll.question,

            ResponseExpiry: new Date(poll.expiryDate).getTime(),
            Type: poll.type,
            Published: published,
            Anonymous: poll.anonymous,
            FileName: poll.file.filename,
            FileType: poll.file.type,
            Base64: poll.file.base64,
            Restrictions: restrictions,
            IsPriority: this.highPriority,
            PollOptions: options,
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.resetSelectedValues();
              this.addPopupVisible = false;
              this.snackbarPurpose = "success";
              this.snackbarText = "success";
              this.snackbarVisible = true;
              this.getData(1, true);

              this.poll = {
                title: "",
                file: {},
                anonymous: false,
                type: 1,
                question: "",
                description: "",
                options: [
                  {
                    option: "",
                  },
                ],
                expiryDate: "",
              };
            }
          });
      } else if (!published) {
        axios
          .post("News/CreatePoll", {
            Title: poll.title,
            Description: poll.description,
            Question: poll.question,

            ResponseExpiry: new Date(poll.expiryDate).getTime(),
            Type: poll.type,
            Published: published,
            Anonymous: poll.anonymous,
            FileName: poll.file.filename,
            FileType: poll.file.type,
            Base64: poll.file.base64,
            Restrictions: restrictions,
            PollOptions: options,
          })
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.resetSelectedValues();
              this.addPopupVisible = false;
              this.snackbarPurpose = "success";
              this.snackbarText = "Saved as draft";
              this.snackbarVisible = true;
              this.getData(1, true);
            }
          });
      } else if (!poll.title) {
        this.snackbarPurpose = "error";
        this.snackbarText = "Title must be filled";
        this.snackbarVisible = true;
      } else if (!this.selectedValues.person.length) {
        this.snackbarPurpose = "error";
        this.snackbarText = "Choose audience";
        this.snackbarVisible = true;
      } else if (!poll.question) {
        this.snackbarPurpose = "error";
        this.snackbarText = "Question must be filled";
        this.snackbarVisible = true;
      } else if (
        !poll.options[0].option.length ||
        !poll.options[1].option.length
      ) {
        this.snackbarPurpose = "error";
        this.snackbarText = "Options must be filled";
        this.snackbarVisible = true;
      } else if (!poll.expiryDate) {
        this.snackbarPurpose = "error";
        this.snackbarText = "Expiry date must be filled";
        this.snackbarVisible = true;
      } else if (poll.expiryDate < todayDate.getDate()) {
        this.snackbarPurpose = "error";
        this.snackbarText = "Expiry date can not be past";
        this.snackbarVisible = true;
      }

      // if (poll.title && published && this.selectedValues.person.length) {
      //   // response;
      //   this.addPopupVisible = false;
      //   await this.getData();
      // } else if (
      //   poll.title &&
      //   !published &&
      //   !this.selectedValues.person.length
      // ) {
      //   // response;
      //   this.addPopupVisible = false;
      //   await this.getData();
      // } else {
      //   this.snackbarPurpose = "error";
      //   this.snackbarText = "Something went wrong";
      //   this.snackbarVisible = true;
      //   this.addPopupVisible = true;
      // }
    },
    editClickHandler(item) {
      this.$router.push({ path: `/Polls/Edit/${item.id}` });
    },
    deleteClickHandler(item) {
      this.$confirm("Would you like to delete this poll?").then(() => {
        axios
          .post("News/DeletePoll", {
            Poll: item.id,
          })
          .then((response) => this.getData(1, true));
      });
    },
    addOptionClick() {
      // if (this.poll.options.length > 8) return;
      this.poll.options.push({
        option: "",
      });
    },
    optionClick(index) {
      if (this.poll.options.length - 1 === index) {
        this.addOptionClick();
      }
    },
    deleteOptionClick(index) {
      this.$set(
        this.poll,
        "options",
        this.poll.options.filter((e, i) => index !== i)
      );
    },
    handleChangedValue(selectedList) {
      this.selectedValues = selectedList;
      this.departmentsSelect.forEach((child) => {
        this.shouldParentBeSelected(child);
      });
      this.departmentsSelect.forEach((child) => {
        this.shouldParentBeSelected(child);
      });
    },
    shouldParentBeSelected(parent) {
      if (!parent.children || parent.children <= 0) return;
      const childrenLength = parent.children.length;
      let selectedChildrenLength = 0;
      parent.children.forEach((child) => {
        if (this.selectedValues[child.field].includes(child.value))
          selectedChildrenLength += 1;
      });

      if (selectedChildrenLength === childrenLength)
        this.selectedValues[parent.field].push(parent.value);
      else
        this.selectedValues[parent.field] = this.selectedValues[
          parent.field
        ].filter((el) => el !== parent.value);

      parent.children.forEach((el) => this.shouldParentBeSelected(el));
      this.$emit("value-changed", this.selectedValues);
    },
    resetSelectedValues() {
      this.selectedValues = {
        person: [],
        department: [],
        job: [],
      };
    },
    async getDepartments() {
      const { data: departmentsList } = await axios.get(
        "departments/GetDepartments"
      );
      const departments = departmentsList.Value;
      for (let department of departments) {
        const { data: jobsList } = await axios.get(
          "departments/GetDepartmentJobs",
          {
            params: {
              DepartmentID: department.ID,
            },
          }
        );
        const jobs = jobsList.Value;
        for (let job of jobs) {
          const { data: personList } = await axios.get("jobs/JobPersons", {
            params: {
              JobID: job.Job,
            },
          });
          job.persons = personList.Value;
        }
        department.jobs = jobs;
      }
      this.departments = departments;
      this.resetSelectedValues();
    },
  },
  watch: {
    addPopupVisible(val) {
      if (val === false) {
        this.isEditMode = false;
        this.resetSelectedValues();
        this.poll = {
          title: "",
          file: {},
          anonymous: false,
          type: 1,
          question: "",
          description: "",
          options: [
            {
              option: "",
            },
            {
              option: "",
            },
          ],
          expiryDate: "",
        };
      }
    },
    onGoing() {
      if (this.onGoing == true) {
        this.draft = false;
        this.completed = false;
      }
    },
    draft() {
      if (this.draft == true) {
        this.onGoing = false;
        this.completed = false;
      }
    },
    completed() {
      if (this.completed == true) {
        this.onGoing = false;
        this.draft = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-poll {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;

  &__top {
    background-color: #959bc9;
    padding: 50px 75px;
  }

  &__header {
    margin-top: 15px;
    display: flex;

    &-wrapper {
      display: flex;
      margin-left: auto;
    }
  }

  &__type {
    display: flex;
    font-size: 12px;
    margin-top: 25px;
    color: #9696b9;
    align-items: center;

    &-wrapper {
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 12px;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }

    &-title {
      margin-right: 15px;
      font-size: 14px;
      color: $blue-primary;
      font-weight: 300;
    }
  }

  &__question {
    margin-top: 30px;
  }

  &__expire,
  &__select {
    margin-right: 10px;
  }

  &__options {
    margin-top: 20px;
    height: 350px;
    overflow-y: scroll;
    width: calc(100% + 30px);
    padding-right: 30px;
    margin-bottom: 10px;

    &-option {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &-index {
        font-size: 13px;
        color: #9696b9;
      }

      &-delete {
        margin-left: auto;
        cursor: pointer;
      }
    }

    &-add-option {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #9696b9;

      img {
        height: 12px;
        width: 12px;
        margin-right: 7px;
      }
    }
  }

  &__switch {
    margin-top: 35px;
  }

  &__title {
    position: relative;
    width: 750 * $rem;

    &-input {
      width: 750 * $rem;
    }

    &-count {
      position: absolute;
      right: 10px;
      top: 12px;
      color: $blue_primary;
      font-size: 14px;
      font-weight: lighter;
      background-color: #fff;
    }
  }
  .preview-button {
    display: flex;
    align-items: center;
    margin-left: 15%;
    cursor: pointer;
    span {
      color: rgba($blue-primary, 1);
      margin-left: 5px;
      font-weight: 300;
      font-size: 14px;
    }
  }

  &__buttons {
    margin-top: auto;
    display: flex;
    align-items: center;

    .button {
      cursor: pointer;
      width: 150 * $rem;
      height: 50 * $rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      background-color: rgba($blue-primary, 0.6);
      color: white;
      transition: 0.2s ease;

      &:hover {
        background-color: rgba($blue-primary, 0.8);
        border-color: rgba($blue-primary, 0.8);
      }

      &--unfilled {
        background-color: transparent;
        color: rgba($blue-primary, 0.6);
        border: 1px solid rgba($blue-primary, 0.6);

        &:hover {
          border-color: rgba($blue-primary, 0.8);
          background-color: rgba($blue-primary, 0.8);
          color: white;
        }
      }

      &:not(:last-child) {
        margin-right: 10 * $rem;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: $blue_primary;
    cursor: pointer;
    .material-icons {
      color: $green-primary;
      font-size: 20px;
      margin-left: 3px;
    }
    span:nth-child(2) {
      font-size: 14px;
      font-weight: 300;
    }
  }
}
.preview-popup {
  ::v-deep .overlay {
    z-index: 100;
    .popup {
      width: 72%;
      height: 82%;
      left: 55%;
    }
    .banner-details-content {
      display: flex;
      width: 100%;
      height: 410px;
    }
    .desc-comment-content {
      margin-top: 30px;
    }
    .cstm-input {
      background-color: transparent;
      border: 0;
      font-size: 14 * $rem;
      width: 100%;
      color: #283593;
      height: 60px;
      max-width: 100%;
      font-weight: 300;
      border: 1px solid #dcdde6;
      padding-left: 10px;
      padding-top: 5px;
      border-radius: 5px;

      &::placeholder {
        color: #9696b9;
      }

      &:focus {
        outline: 0;
      }
    }

    .poll {
      display: flex;
      flex-direction: column;

      &__content {
        display: flex;
        height: 550px;
        flex-direction: column;
      }

      &__banner {
        width: 50%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 10px;
        margin-top: 24px;
      }

      &__details {
        margin-left: 50px;
        color: #9696b9;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 50%;
        .wrapper {
          // width: 106%;
        }
      }

      &__description {
        height: 60px;
        margin-bottom: 15px;
        font-weight: 300;
        font-size: 14px;
        word-break: break-all;
        overflow: auto;
        color: $blue-primary;
      }

      &__question {
        height: 50px;
        overflow-y: auto;
        margin-bottom: 60px;
        color: $blue-primary;
      }

      &__options {
        max-height: 300px;
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;
        overflow-y: auto;
        padding-right: 20px;
      }

      &__option {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 10px;

        &-wrapper {
          display: flex;
          align-items: flex-start;
        }

        &-index {
          margin-right: 10px;
          font-weight: 500;
          color: #9696b9;
        }

        &-name {
          color: #283593;
          max-width: 490px;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 300;
        }

        &-checkbox {
          margin-left: auto;
          width: 16px;
          height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
          border: 1px solid #959bc9;
          background-color: transparent;
          cursor: pointer;

          &--selected {
            border: 1px solid #283593 !important;
          }

          svg {
            width: 12px;
            height: 10px;
          }
        }
      }
      .poll-date {
        text-align: right;
        padding-right: 10px;
        margin-bottom: 10px;
        color: $blue-primary;
      }

      &__button {
        cursor: pointer;
        margin-top: 105px;
        margin-left: auto;
        background-color: #959bc9;
        color: white;
        border-radius: 6px;
        width: 145px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    // .options-content {
    //   max-height: 55%;
    //   overflow-y: scroll;
    //   .single-option {
    //     display: flex;
    //     margin-bottom: 15px;

    //     span:nth-child(2) {
    //       margin-left: 5px;
    //       max-width: 650px;
    //     }
    //     .checkbox {
    //       height: 16px;
    //       width: 16px;
    //       border: 1px solid $blue_primary;
    //       border-radius: 3px;
    //       margin-left: auto;
    //       opacity: 0.7;
    //       margin-right: 10px;
    //     }
    //   }
    // }
    // .poll-preview-content {
    //   color: #757575;
    //   display: flex;
    //   .right-side {
    //     margin-left: 50px;
    //     width: 100%;
    //     .descr-content {
    //       margin-top: 20px;
    //       min-height: 60px;
    //       max-height: 70px;
    //       overflow-y: scroll;
    //       margin-bottom: 15px;
    //     }
    //     .question-content {
    //       min-height: 60px;
    //       max-height: 70px;
    //       overflow-y: scroll;
    //       margin-bottom: 15px;
    //     }
    //     .date-content {
    //       margin-bottom: 15px;
    //     }
    //   }
    //   .left-side {
    //     min-width: 40%;
    //     height: 500px;

    //     .banner {
    //       width: 100%;
    //       height: 100%;
    //       background-position: center;
    //       background-size: cover;
    //       background-repeat: no-repeat;
    //       border-radius: 10px;
    //     }
    //   }
    // }
  }
}
// .preview-popup {
//   ::v-deep .overlay {
//     z-index: 100;
//     .popup {
//       width: 72%;
//       height: 72%;
//       left: 55%;
//     }
//     .options-content {
//       max-height: 55%;
//       overflow-y: scroll;
//       .single-option {
//         display: flex;
//         margin-bottom: 15px;

//         span:nth-child(2) {
//           margin-left: 5px;
//         }
//         .checkbox {
//           height: 16px;
//           width: 16px;
//           border: 1px solid $blue_primary;
//           border-radius: 3px;
//           margin-left: auto;
//           opacity: 0.7;
//           margin-right: 10px;
//         }
//       }
//     }
//     .poll-preview-content {
//       color: #757575;
//       display: flex;
//       .right-side {
//         margin-left: 50px;
//         width: 100%;
//         .descr-content {
//           margin-top: 20px;
//           min-height: 60px;
//           max-height: 70px;
//           overflow-y: scroll;
//           margin-bottom: 15px;
//         }
//         .question-content {
//           min-height: 60px;
//           max-height: 70px;
//           overflow-y: scroll;
//           margin-bottom: 15px;
//         }
//         .date-content {
//           margin-bottom: 15px;
//         }
//       }
//       .left-side {
//         min-width: 40%;
//         height: 500px;

//         .banner {
//           width: 100%;
//           height: 100%;
//           background-position: center;
//           background-size: cover;
//           background-repeat: no-repeat;
//           border-radius: 10px;
//         }
//       }
//     }
//   }
// }

.popup {
  ::v-deep .popup {
    width: 1300px !important;
    padding: 0;
    left: 56%;

    .title {
      margin: 0;
      left: 10px;
    }
  }
}

.padding-wrapper {
  height: 100%;
  padding: 0 75px;
  display: flex;
  flex-direction: column;
}

.cstm-input {
  background-color: transparent;
  border: 0;
  font-size: 14px;
  width: 100%;
  color: #283593;

  &::placeholder {
    color: $blue-primary;
  }

  &:focus {
    outline: 0;
  }

  &--title {
    color: white;
    font-weight: 500;
    font-size: 16px;

    &::placeholder {
      color: white;
    }
  }

  &--question {
    font-size: 16px;
  }

  &--description {
    color: white;
    margin-top: 20px;
    resize: none;
    height: 35px;
    &::placeholder {
      color: white;
    }
  }

  &--option {
    width: 600px;
    margin-left: 10px;
    font-weight: lighter;
    font-size: 13px;
  }
}
::v-deep .switcher-container {
  border: 0;
  .switcher {
    margin-left: 15px;
  }
}
.wrapper-button {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.upload-button {
  cursor: pointer;
  width: 197 * $rem;
  display: inline-block;
  height: 40 * $rem;
  line-height: 40 * $rem;
  padding: 0 15 * $rem 0 15 * $rem;
  outline: none;
  text-align: center;
  color: white;
  margin-top: 10px;
  border-radius: 5 * $rem;
  box-shadow: none;
  background-color: #1de9b6;
}
.priority-content {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 20px;
  cursor: pointer;
  span:nth-child(1) {
    color: $blue-primary;
    opacity: 0.7;
  }
  span:nth-child(2) {
    color: $blue-primary;
  }
  span:nth-child(3) {
    color: #1de9b6;
    font-size: 20px;
    margin-left: 3px;
  }
}
</style>
