<template>
  <div class="background">
    <div class="payroll-companies">
      <div class="essentials">
        <div class="add">
          <add-button title="Add Manual Entry" @clicked="popUpVisible(true), create = true" />
          <span @click="popUpVisible(true), create = true">Add Company</span>
        </div>
        <div class="search">
          <input type="text" v-model="searchCompany" placeholder="Search" />
          <img class="search-icon" src="@/assets/icons/searchAppIcon.svg" alt="hr" />
        </div>
      </div>
      <!-- end essentials -->

      <div class="companies-list">
        <div class="companies-box" v-for="el in companiesList" :key="el.ID">
          <div class="companies-box-header" :class="el.IsTax ? 'noTax' : 'isTax'">
             <div class="companies-box-title"><span :title="el.Name">{{el.Name}}</span></div>
             <div class="companies-box-edit" v-if="el.canEdit">
               <img src="@/assets/icons/edit.svg" alt="hr" @click="editCompany(el.ID)" />
               <img src="@/assets/icons/close.svg" alt="hr" @click="deleteCompany(el.ID)" />
             </div>
          </div>
          <!-- end companies-box-header -->
          <div class="companies-info">
             <div class="companies-info-div"><span>Is Tax</span><span>{{el.IsTax ? 'Yes' : 'No'}}</span></div>
             <div class="companies-info-div"><span>Payment  type</span><span>{{el.CompanyPaymentTypeName ? el.CompanyPaymentTypeName : '-'}}</span></div>
             <div class="companies-info-div"><span>Pay day</span><span>{{el.PayDay ? el.PayDay : '-'}}</span></div>
             <div class="companies-info-div"><span>Bank code</span><span>{{el.BankCode ? el.BankCode : '-'}}</span></div>
             <div class="companies-info-div"><span>IBAN</span><span>{{el.Iban ? el.Iban : '-'}}</span></div>
          </div>
          <!-- end companies-info -->
        </div>
        <!-- end companies-box -->
      </div>
      <!-- end companies-list -->

      <custom-snackbar
        v-if="snackbarVisible"
        :purpose="snackbarPurpose"
        :text="snackbarText"
        @close="snackbarVisible = false"
      />
      <form class="assign-form" @submit.prevent="createCompany">      
        <custom-popup
          :visible="popupVisible"
          @close="popUpVisible(false)"
          :title="create ? 'Add Company' : 'Edit Company'"
          :flexibleSize="false"
        >

          <div class="flex">
            <div class="form-field">
              <custom-input
                class="input"
                v-model="newCompany.name"
                title="Company/Service name"
                :isRequired="true"
                @clear="newCompany.name = ''"
              />              
            </div>
            <div class="form-field">
              <custom-select
                class="input"
                title="Tax type"
                :isRequired="true"
                v-model="newCompany.taxTypeID"
                :options="salaryFactorTaxTypesOptions"
                @clear="newCompany.taxTypeID = ''"
                :clearVisible="true"
              />
            </div>
          </div>

          <div class="flex">
            <div class="form-field">
              <custom-select
                class="input"
                title="Payment type"
                :isRequired="true"
                v-model="newCompany.paymentType"
                :options="paymentTypeOptions"
                @clear="newCompany.paymentType = ''"
                :clearVisible="true"
              />
            </div>
            <div class="form-field">
              <custom-input
                class="input"
                v-model="newCompany.payDay"
                title="Pay day"
                :isRequired="true"
                @clear="newCompany.payDay = ''"
              />
            </div>
          </div>
          <!-- end flex -->
          <div class="flex">
            <div class="form-field">
              <custom-input
                class="input"
                v-model="newCompany.bankCode"
                title="Bank Code"
                @clear="newCompany.bankCode = ''"
              />
            </div>
            <div class="form-field">
              <custom-input
                class="input"
                v-model="newCompany.iban"
                title="IBAN"
                @clear="newCompany.iban = ''"
              />
            </div>
          </div>
          <!-- end flex -->
          <div class="save-button-div"><button class="save-button">Save</button></div>
        </custom-popup>
      </form>

      <delete-item
        @delete="deleteRow"
        @close="popUpVisible(false)"
        v-if="deletePopupVisible"
        :text="`Would you like to delete the company?`"
      />
    </div>
    <!-- end payroll-companies -->
  </div>
</template>   
<script>   
import AddButton from "@/components/ReusableComponents/AddButton/AddButton.vue";    
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup.vue"; 
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import DeleteItem from "@/components/ReusableComponents/DeleteItem/DeleteItem.vue";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue";
import axios from "@/Helpers/AxiosInstance.js";
export default {  
  components: {
    AddButton,
    CustomPopup,
    CustomInput,
    CustomSnackbar,
    DeleteItem,
    CustomSelect,
  },
  data() {
    return {
      create:false,
      popupVisible: false,
      deletePopupVisible: false,
      snackbarVisible: false,
      snackbarPurpose: "error",
      snackbarText: "",
      searchCompany: '',

      paymentTypeOptions: [],
      salaryFactorTaxTypesOptions: [],
      companiesList: [],
      selectedcompany: '',
      serviceName: '',

      newCompany: {
        name: null,
        taxTypeID: false,
        paymentType: null,
        payDay: null,
        bankCode: null,
        iban: null,
      },
    }
  },
  created() {
    this.getCompanies()
    this.getPaymentTypes()
    this.getSalaryFactorTaxTypes()
  },
  watch: {
    searchCompany (to, from) {
      this.searchCompany = to
      this.getCompanies()
    },
  },
  methods: {  
    popUpVisible (status) {
        this.newCompany.name = null;
        this.newCompany.taxTypeID = false;
        this.newCompany.payDay = null;
        this.newCompany.paymentType = null;
        this.newCompany.bankCode = null;
        this.newCompany.iban = null;
        this.popupVisible = status
    },
    getCompanies() {
      this.serviceName = `salary/GetSalaryFactorsDestinationTypes`
      if (this.searchCompany)
         this.serviceName = `salary/GetSalaryFactorsDestinationTypes?SearchTerm=${this.searchCompany}`

      axios.get(this.serviceName).then((response) => {
        this.companiesList = response.data.Value.DestinationTypes;
        if(this.companiesList) {
          this.companiesList.forEach((el) => {
            this.$set(el, "canEdit", el.CanUpdate);
            this.$set(el, "canDelete", el.CanUpdate);
          });
        }
      });
    },
    createCompany() {
      let objectData = {
        name: this.newCompany.name,
        taxTypeID: this.newCompany.taxTypeID,
        payDay: this.newCompany.payDay,
        paymentType: this.newCompany.paymentType,
        bankCode: this.newCompany.bankCode,
        iban: this.newCompany.iban,
      }

      this.serviceName = "salary/CreateSalaryFactorsDestinationTypes" 
      if (!this.create) { 
        objectData.id = this.selectedcompany
        this.serviceName = "salary/UpdateSalaryFactorsDestinationTypes"}
               
      axios.post(this.serviceName, objectData).then((resp) => {
        if (!resp.data.ErrorMsg) {
          this.popupVisible = false;
          this.newCompany.name = null;
          this.newCompany.taxTypeID = false;
          this.newCompany.payDay = null;
          this.newCompany.paymentType = null;
          this.newCompany.bankCode = null;
          this.newCompany.iban = null;
          this.getCompanies();
        } else {
          this.snackbarVisible = true;
          this.snackbarText = resp.data.ErrorMsg;
          this.snackbarPurpose = "error";
        }  
      });   
    },
    editCompany (id)
    {
      this.create = false   
      this.selectedcompany = id   
       axios.get(`salary/GetSalaryFactorsDestinationTypes?SearchID=${id}`).then((response) => {
        this.newCompany = {
          name: response.data.Value.DestinationTypes[0].Name,
          taxTypeID: response.data.Value.DestinationTypes[0].TaxTypeID,
          paymentType: response.data.Value.DestinationTypes[0].CompanyPaymentTypeID,
          payDay: response.data.Value.DestinationTypes[0].PayDay,
          bankCode: response.data.Value.DestinationTypes[0].BankCode,
          iban: response.data.Value.DestinationTypes[0].Iban,
        }
        this.popupVisible = true;
      });
    },
    deleteCompany (id)
    {
      this.selectedcompany = id;
      this.deletePopupVisible = true;
    },
    deleteRow() {
      var data = {
        id: this.selectedcompany,
        delete: true,
        PayDayTVP: [],
      };     
      axios.post("salary/UpdateSalaryFactorsDestinationTypes", data).then((resp) => {
          this.deletePopupVisible = false;
          if (!resp.data.ErrorMsg) {
            this.snackbarText = "Deleted Successfully!";
            this.snackbarPurpose = "success";
            this.snackbarVisible = true;
            this.getCompanies();
          } else {
            this.snackbarVisible = true;
            this.snackbarText = resp.data.ErrorMsg;
            this.snackbarPurpose = "error";
          }
        })
        .catch(() => {
          this.snackbarVisible = true;
          this.snackbarText = "Error";
          this.snackbarPurpose = "error";
        });
    },
    getPaymentTypes() {
      axios.get("salary/GetCompanyPaymentTypes").then((resp) => {
        this.paymentTypeOptions = resp.data.Value.map((el) => ({
          title:  el.Name,
          value: el.ID,
        }));
      });
    },
    getSalaryFactorTaxTypes() {
      axios.get("salary/GetSalaryFactorTaxTypes").then((resp) => {
        this.salaryFactorTaxTypesOptions = resp.data.Value.map((el) => ({
          title:  el.Name,
          value: el.ID,
        }));
      });
    },
  },
}
</script>
<style lang="scss" scoped>
.background {
  overflow: hidden;
}
.payroll-companies {
  height: 100%;
  .companies-list {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;  
    height: calc(100% - 8.125rem);
    overflow: auto; 
     .companies-box {
        width: 21.375rem;
        height: 13.125rem;
        border-radius: 0.375rem;
        background-color: #fff;
        overflow:hidden;
        margin: 1.6875rem;
       .isTax {         
         background-color: #B6E7F8;
       }
       .noTax {
         background-color: #FBB6B6;
       }
       .companies-box-header {
         width: 100%;
         height: 3.125rem;
         display: flex;
         justify-content: space-between;
         .companies-box-title {
            max-width: 16.25rem;
            padding-left: 1.875rem;
            -webkit-text-stroke: 0.0625rem rgba(0, 0, 0, 0);
            font-size: 1rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.35;
            letter-spacing: normal;
            color: #5963ab;
            overflow:hidden;
            span {
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-top: 0.875rem;
                  display: inline-block;
            }
         }
         .companies-box-edit {
           img:first-child {
             width: 1.6875rem;
             margin: 0.75rem 0.3125rem 0 0rem;
             cursor: pointer;
           }
           img:last-child {
             width: 1.6875rem;
             margin: 0.75rem 0.9375rem 0 0rem;
             cursor: pointer;
           }
         }
       }
       .companies-info {
         padding: 0 1.875rem;
         .companies-info-div {
           margin-top: 0.625rem;
           span:first-child {
             width: 45%;
             font-size: 0.8125rem;
             font-weight: 300;
             font-stretch: normal;
             font-style: normal;
             line-height: 1.38;
             letter-spacing: normal;
             text-align: left;
             color: #5963ab;
             display: inline-block;
           }
           span:last-child {
             width: 55%;
             font-size: 0.8125rem;
             font-weight: normal;
             font-stretch: normal;
             font-style: normal;
             line-height: 1.38;
             letter-spacing: normal;
             text-align: left;
             color: #5963ab;
             display: inline-block;
           }
         }

       }
     }
  }
  .essentials {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15 * $rem;
    padding: 0 1.75rem;
    margin-top: 1.875rem;

    form {
      margin-right: 15 * $rem;
    }

    & > .add {
      display: flex;
      align-items: center;

      span {
        display: inline-block;
        margin-left: 10 * $rem;
        font-weight: 400;
        font-size: 18 * $rem;
        color: $blue-primary;
        cursor: pointer;
      }
    }
    
    .search {
      position: relative;    
      input {
        width: 12.5rem;
        height: 2.375rem;
        background-color: #fff;
        border: 0;
        outline: none;
        border-radius: .375rem;
        font-weight: 300;
        font-size: 0.875rem;
        color: #9696b9;
        padding:0 2.1875rem 0 0.9375rem;
      }
      img {
        position: absolute;
        height: 1.3125rem;
        width: 1.3125rem;
        right: 0.625rem;
        top: 0.5rem;
      }
    }

  }
  .form-check-field {
    height: 0.9375rem;
    display: flex;
    justify-content: start;
    margin: 1.5rem 0 1.5rem 1rem;
    .input_title {
      font-size: 0.875rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: rgba(40, 53, 147, 0.7);
      margin-bottom: 0.625rem; 
      padding-right: 1rem;
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
    .form-field {
      width: 48%;
    }
  }
  .form-field {
    width: 100%;    
    label {
      width: 100%;
    }
  }  
  .save-button-div {
    width: 100%;
    text-align: right;  
  }
  .save-button {
    background-color: #6956d6;
    color: #fcfcfc;
    height: 3rem;
    width: 6.25rem;
    border-radius: 0.375rem;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 3.75rem;
  }
  .assign-form {
    ::v-deep .popup {
      height: auto;
    }
  }
}
</style>