<template>
  <div v-show="isActive" class="tabs-content">
    <ul>
      <router-link
        tag="li"
        :to="tab.path"
        v-for="(tab, index) in tabs"
        :key="index"
      >
        {{ tab.title }}
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  // props: {
  //     titles: {
  //         type: Array,
  //     },
  // },
  data() {
    return {
      isActive: true,
      selectedIndex: 0,
      tabs: [
        {
          title: "Personal info",
          path: `/Profile/${this.$route.params.id}/info`,
        },
        {
          title: "Jobs",
          path: `/Profile/${this.$route.params.id}/jobs`,
        },
        {
          title: "Salaries",
          path: `/Profile/${this.$route.params.id}/salaries`,
        },
        {
          title: "Performance",
          path: `/Profile/${this.$route.params.id}/perfomance`,
        },
        {
          title: "Attendance",
          path: `/Profile/${this.$route.params.id}/attendence`,
        },
        {
          title: "Schedule",
          path: `/Profile/${this.$route.params.id}/schedule`,
        },
        {
          title: "Vacations",
          path: `/Profile/${this.$route.params.id}/vacation`,
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.tabs-content {
  margin-left: 2.575rem;
  ul {
    display: flex;
  }
  li {
    list-style: none;
    margin-right: 110 * $rem;
    font-size: 16 * $rem;
    color: $blue-primary;
    cursor: pointer;
    padding-bottom: 0.625rem;
  }
}
.router-link-active {
  border-bottom: 4px solid #1de9b6 !important;
  border: transparent;
  font-weight: 500;
  // font-size: 20 * $rem !important;
}
</style>
