<template>
    <section class="background">
        <div class="page-wrapper">
            <div class="statements-header">
                <div class="title">
                    STATEMENTS
                </div>

                <div class="header-actions">
                    <div class="ongoing-status" @click="(filters.ongoing = !filters.ongoing, filters.draft = false)">
                        <img v-if="!filters.ongoing" :src="require('@/assets/icons/Flag Ongoing stroke.svg')" alt="ongoing">
                        <img v-else :src="require('@/assets/icons/icon_ongoing.svg')" alt="ongoing">

                    </div>

                    <div class="draft-status"  @click="(filters.draft = !filters.draft, filters.ongoing = false)">
                        <img v-if="!filters.draft" :src="require('@/assets/icons/Flag Draft stroke.svg')" alt="draft">
                        <img v-else :src="require('@/assets/icons/icon_draft.svg')" alt="draft">

                    </div>

                    <div class="action-wrapper">
                        <add-button @clicked="(addPopupVisible = true,resetStatementForm(),editMode = false)" />
                        <input type="text" placeholder="Search" v-model="filters.searchStatement" class="search-statement">
                    </div>
                </div>
            </div>
            <div class="content">
                <reusable-block
                    v-for="(item, index) in filteredData"
                    :key="index"
                    :item="item"
                    :class="['statement-block',{
                        'disabled': item.status == 'disabled',
                        'notDeletable': item.status =='ongoing' || item.status == 'disabled'
                    }]"
                    @edit-item="editStatement(item.id)"
                    @clicked="getStatementStatisctics(item.id)"
                    @delete-item="deleteStatement(item.id,item.status)"
                />
            </div>
        </div>

       <custom-popup
        :visible="addPopupVisible"
        @close="(addPopupVisible = false,fileRecords = [],fileRecordsForUpload =[],createStatement.repeatedResponseOn = false)"
        :title="editMode ? 'Edit Statement' : 'Add Statement'"
        class="add-statement-popup"
        >
            <form action="" @submit.prevent="createNewStatement()">
                <div class="top">
                    <custom-input title="Title *"  v-model="createStatement.title" class="statement-title"/>

                    <select-persons
                        class="select-persons"
                        :departments="departmentPeople"
                        :alternativeText="false"
                        :placementTitle="true"
                        :listWithoutDeps="true"
                        :initialSelectedPersons="createStatement.sharedTo"
                        @select="(people) => (createStatement.sharedTo = people)"

                    />

                    <date-picker
                        title="Start Date *"
                        class="start-date"
                        :class="{disabled:createStatement.status || createStatement.status == false}"
                        v-model="createStatement.startDate"
                    />

                </div>

                <div class="middle ">
                    <div class="text-editor">
                        <custom-editor :value="createStatement.description" v-model="createStatement.description" />
                    </div>
                    <div class="uploader-wrapper eventCalendar">
                        <div class="upload-files">
                            <span class="title">
                                Attach documents
                            </span>
                            <div class="additional-uploader-wrapper">
                                <VueFileAgent
                                    ref="vueFileAgent"
                                    :theme="'default'"
                                    :multiple="true"
                                    :deletable="true"
                                    :meta="true"
                                    :accept="'image/*,.zip,.pdf,.docx,.doc,.xlsx,.xls,.pptx,.ppt'"
                                    :maxSize="'3MB'"
                                    :maxFiles="!createStatement.apiAttachments.length ? 3 : createStatement.apiAttachments.length ? (3 - createStatement.apiAttachments.length) : 3"
                                    :helpText="' '"
                                    :errorText="{
                                    type: 'Invalid file type',
                                    size: 'File is Too Large',
                                    }"
                                    @select="filesSelected($event)"
                                    @beforedelete="onBeforeDelete($event)"
                                    @delete="fileDeleted($event)"
                                    v-model="fileRecords"
                                    v-if="createStatement.apiAttachments.length < 3"
                                >
                                </VueFileAgent>
                            </div>
                        
                        </div>
                    </div>
                    <div class="edit-attached-files">
                        <div class="editable-attached-file" :class="{'border':file.FileName}" v-for="(file,ind) in createStatement.apiAttachments" :key="ind">
                            <div v-if="file.FileName" @click="deleteEditableFile(file.FileID)" class="delete">X</div>
                            <div v-if="file.FileName" class="file-block" :type="file.FileName ? file.FileName.split('.').pop() : '' ">
                                {{file.FileName ? file.FileName.split(".").slice(-1)[0] : ''}}
                            </div>
                              <div v-if="file.FileName" class="file-name">
                                {{file.FileName ? file.FileName : ''}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <div class="response-text-wrapper">
                        <span>Response to *</span>
                        <input 
                            v-model="createStatement.responseToText"
                            type="text" 
                            placeholder="Enter response text here …."
                            :title="createStatement.responseToText"
                        >
                        <div v-if="createStatement.responseToText.length" @click="createStatement.responseToText = ''" class="delete">X</div>
                    </div>
                    <div class="actions-wrapper">
                        <div @click="previewStatement()" class="preview">
                            <span>Preview</span>
                            <img src="@/assets/preview-icon.svg" alt="">
                        </div>
                        <div v-if="!editMode" class="btns notPublished">
                            <button @click="saveDraft()" class="draft">Save Draft</button>
                            <button @click="publishStatement()" class="publish">Publish</button>
                        </div>
                        <div v-if="editMode" class="btns edit-mode">
                            <div class="wrapper" v-if="!createStatement.disabled">
                                <button v-if="createStatement.status !== null || createStatement.status" class="disable" @click="disabled()">Disable</button>
                                <button v-if="createStatement.status == null" class="editDraft" @click="editDraft()">Edit Draft</button>
                                <button class="publish" @click="publishStatement()">Publish</button>
                                <div class="repeated-resp" v-if="createStatement.status">
                                    <span>Repetead Response ON </span>
                                    <div @click="createStatement.repeatedResponseOn = !createStatement.repeatedResponseOn" class="custom-checkbox">
                                        <div  :class="{checked:createStatement.repeatedResponseOn}"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            
        </custom-popup>

        <custom-popup
            :visible="detailsPopupVisible"
            @close="(detailsPopupVisible = false)"
            :title="selectedStatement.title"
            class="statement-details-popup"
        >   
            <div class="details-description" v-html="selectedStatement.description">

            </div>

            <div class="files-wrapper">
                <span>Attached files</span>
                <ul class="files-list">
                    <li 
                        :type="file.FileName.split('.').pop()"
                        class="uploaded-document"
                        v-for="(file,ind) in selectedStatement.attachedFiles" 
                        :key="ind"
                        @click="previewDocument(file.FileID)"
                        >
                        <!-- :style="`background-image: url(data:${file.FileType};base64,${file.File})`" -->
                        <span> 
                            {{file.FileName.split(".").slice(-1)[0]}}
                        </span>
                    </li>
                </ul>
            </div>

            <div class="response-btn-wrapper">
                <button>
                    {{selectedStatement.responseTo}}
                </button>
            </div>


        </custom-popup>

        <custom-popup
            :visible="previewPopupVisible"
            @close="(previewPopupVisible = false, fileForPreview = [])"
            :title="''"
            class="file-preview"
        >

            <iframe style="border:none;"  width='100%' height='100%' :src="previewFile()">
    
        </iframe>
            
        </custom-popup>

        <custom-popup
            :visible="statisticsVisible"
            @close="(statisticsVisible = false)"
            :title="''"
            class="statistics"
        >
            <div class="statistics-header">
                <div class="wrap">
                    <span>Response Statistics</span>
                    <span class="title"> {{this.statementStatistic.title}} </span>
                </div>
                <div @click="exportResponses(statementStatistic.statementID)" class="export-statistics">
                    <img src="@/assets/icons/upload.svg" alt="">
                    <span>Responses</span>
                </div>
            </div>

            <div class="statistic-details">
                <div class="person-votes">
                    <span> Waiting for {{this.statementStatistic.allPersons.length}}/<span>{{this.statementStatistic.personlist.length}} </span>  responses  </span>
                    <div class="person-list">
                        <ul>
                            <li v-for="(person,ind) in statementStatistic.personlist" :key="ind">
                                {{person.FirstName + " " + person.LastName }}
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="statistic-results">
                    <h1 class="percentage">{{this.statementStatistic.statistic ? this.statementStatistic.statistic : "0"}}</h1>
                    <span>Engagement</span>
                </div>
            </div>
            
        </custom-popup>


        <custom-snackbar
            v-if="snackbar.visible"
            :purpose="snackbar.purpose"
            :text="snackbar.text"
            @close="snackbar.visible = false"
        />
    </section>      
</template>


<script>
import axios from "@/Helpers/AxiosInstance";
import AddButton from "@/components/ReusableComponents/AddButton/AddButton";
import ReusableBlock from "@/components/ReusableComponents/ReusableBlock/ReusableBlock";
import CustomInput from "@/components/ReusableComponents/CustomInput/CustomInput";
import CustomPopup from "@/components/ReusableComponents/CustomPopup/CustomPopup";
import SelectPersons from "@/components/FixedSchedules/SelectPersons";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker";
import CustomEditor from "@/components/ReusableComponents/CustomEditor/CustomEditor";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import {getDateString} from "@/Helpers/HelperFunctions";
import { toBase64 } from "@/Helpers/HelperFunctions";



export default {
    components: {
        AddButton,
        ReusableBlock,
        CustomInput,
        CustomPopup,
        SelectPersons,
        DatePicker,
        CustomEditor,
        CustomSnackbar
    },

    data(){
        return{
            data: [],
            filteredData: [],
            addPopupVisible: false,
            detailsPopupVisible: false,
            previewPopupVisible: false,
            editMode: false,
            selectedStatementID: null,
            statisticsVisible: false,

            fileForPreview: [],

            filters:{
                searchStatement: '',
                ongoing: false,
                draft: false,
            },

            statementStatistic:{
                title:'',
                statistic: '',
                personlist:[],
                voted: [],
                statementID: null,
                allPersons: [],
            },

            createStatement:{
                title: '',
                startDate: null,
                description: '',
                responseToText: '',
                repeatedResponseOn: false,
                apiAttachments:[],
                status: true,
                published: null,
                sharedTo: [],
                disabled: false,
            },

            selectedStatement: {
                title: '',
                description: '',
                responseTo: '',
                attachedFiles: [],
                startDate: null,
                status: null,
                published: null,

            },
            fileRecords: [],
            fileRecordsForUpload: [],
            departmentPeople: [],
            tempDeps: [],
            snackbar:{
                visible: false,
                purpose: '',
                text: '',
            },
            
        }
    },
    created(){
        this.getDepartmentJobsPeople();
        this.getStatements();
    },
    watch:{
        filters:{
            handler(){
                let value = this.filters.searchStatement.split(' ').join('').toLowerCase().trim()
                if(value.length){
                    this.filteredData = this.data.filter(statement => statement.title.split(' ').join('').toLowerCase().includes(value) )
                }
                else{
                    this.filteredData = this.data
                }

                if(this.filters.ongoing){
                   this.filteredData = this.filteredData.filter(statement => statement.status == 'ongoing')
                }
                if(this.filters.draft){
                   this.filteredData = this.filteredData.filter(statement => statement.status == 'draft')
                }
            },
            deep:true,
        }
    },
    methods: {
        async createNewStatement(){

            if(
                !this.createStatement.startDate || !this.createStatement.title || !this.createStatement.responseToText
                || !this.createStatement.sharedTo.length
            ){
                this.createStatement.status = null
                this.snackbar.visible = true
                this.snackbar.text = "Please Fill In Required Fields"
                this.snackbar.purpose = "error"
                return
            }


            if(this.fileRecords.length > 0) {
                for (let i = 0; i < this.fileRecordsForUpload.length; i++) {
                    if (this.fileRecordsForUpload[i]['file']) {
                        this.readURL(this.fileRecordsForUpload[i]['file'])
                        let attachObject = {
                            attachmentType: this.fileRecordsForUpload[i]['file'].type,
                            attachmentName: this.fileRecordsForUpload[i]['file'].name,
                            attachmentBase64: await toBase64(this.fileRecordsForUpload[i]['file']),
                        };
                        
                        this.createStatement.apiAttachments.push(attachObject);
                    }
                }
            }
            const restrictions = [
                ...this.createStatement.sharedTo.map((el) => ({
                    VisibilityRestrictionType: 1,
                    VisibleForEntity: el,
                })),
            ];

            if(!this.editMode){
                axios.post("News/CreateStatement",{
                    title: this.createStatement.title,
                    description: this.createStatement.description,
                    responseTo: this.createStatement.responseToText,
                    status: this.createStatement.status,
                    startDate: this.createStatement.startDate,
                    published: this.createStatement.published,
                    restrictions: restrictions,
                    apiAttachments: this.createStatement.apiAttachments
                }).then(resp => {
                        if(!resp.ErrorMsg){
                            this.resetStatementForm()
                            this.snackbar.visible = true
                            this.snackbar.purpose = "success"
                            this.snackbar.text = 'Your request has been sent successfully'
                            this.addPopupVisible = false
                            this.getStatements()
                            this.fileRecords = []
                            this.fileRecordsForUpload =[]
                        }
                        else{
                            this.snackbar.visible = true;
                            this.snackbar.purpose = "error";
                            this.snackbar.text = resp.data.ErrorMsg;
                        }
                    })
            }

            else{
                const attachments = this.createStatement.apiAttachments.map(file => ({
                    attachmentBase64: file.File ? file.File : file.attachmentBase64,
                    attachmentName: file.FileName ? file.FileName : file.attachmentName,
                    attachmentType: file.FileType ? file.FileType : file.attachmentType,
                }))

                axios.post("News/UpdateStatement",{
                    statementID: this.selectedStatementID,
                    title: this.createStatement.title,
                    description: this.createStatement.description,
                    responseTo: this.createStatement.responseToText,
                    status: this.createStatement.status,
                    startDate: this.createStatement.startDate,
                    repeatedResponse: this.createStatement.repeatedResponseOn,
                    delete: false,
                    published: this.createStatement.published,
                    restrictions: restrictions,
                    apiAttachments: attachments,

                }).then(resp => {
                    if(!resp.ErrorMsg){
                        this.snackbar.visible = true
                        this.snackbar.purpose = "success"
                        this.snackbar.text = 'Your request has been sent successfully'
                        this.addPopupVisible = false
                        this.getStatements()
                        this.fileRecords = []
                        this.fileRecordsForUpload =[]

                        
                    }else{
                        this.snackbar.visible = true
                        this.snackbar.purpose = "error"
                        this.snackbar.text = resp.data.ErrorMsg
                    }
                })
            }

            
        },

        editStatement(id){
            this.selectedStatementID = id

            axios.get("News/GetStatementsDetailsAsAdmins",{
                params: {
                    StatementID: id,
                }
            }).then(resp => {
                let statementData = resp.data.Value
                
                this.editMode = true
                this.addPopupVisible = true

                this.createStatement.title = statementData.Statements[0].Title
                this.createStatement.description = statementData.Statements[0].Description
                this.createStatement.responseToText = statementData.Statements[0].ResponseTo
                this.createStatement.status = statementData.Statements[0].Status
                this.createStatement.startDate = statementData.Statements[0].StartDate
                this.createStatement.published = statementData.Statements[0].Published
                this.createStatement.disabled = statementData.Statements[0].Disabled

                this.createStatement.apiAttachments = statementData.StatementFiles
                this.createStatement.sharedTo = statementData.Persons.map(person => (
                    person.PersonID
                ))

            })
        },

        deleteStatement(id,status){
            if(status == 'ongoing' || status == 'disabled') return

            else{
                this.$confirm(`Are you sure you want delete this Statement ?`).then(() => {
                    axios.post('News/UpdateStatement',{
                        statementID: id,
                        delete:true,
                        restrictions: [],
                        apiAttachments: [],
                    })
                    .then(resp => {
                        this.getStatements()
                    })
                })
            }

        },

        saveDraft(){
            this.createStatement.status = null
            this.createStatement.published = false
        },
        editDraft(){
            this.createStatement.published = false
            this.createStatement.status = null
        },
        disabled(){
            this.createStatement.status = false
            this.createStatement.published = false
        },

        publishStatement(){
            this.createStatement.status = true
            this.createStatement.published = true
        },

        resetStatementForm(){
            this.createStatement.title = ''
            this.createStatement.description = ''
            this.createStatement.responseToText = ''
            this.createStatement.status = null
            this.createStatement.startDate = null
            this.createStatement.published = null
            this.createStatement.apiAttachments = []
            this.createStatement.sharedTo = []
            this.fileRecords = []

        },
        getStatements(){
            axios.get("News/GetStatementsAsAdmin")
            .then(resp => {
                 let statements = resp.data.Value.map((el, index) => ({
                    title: el.Title,
                    description: el.Description,
                    id: el.StatementID,
                    creationTime: el.StartDate,
                    status: el.Published ? "ongoing" : el.Status == null ? 'draft' : el.Status == false ? 'disabled' : '', 
                
                }));
                this.data = statements.reverse();
                this.filteredData = statements
            })
        },

        // getStatementDetails(id){
        //     axios.get('News/GetStatementsDetailsAsAdmins',{
        //         params:{
        //             StatementID: id 
        //         }
        //     }).then(resp => {
        //         let details = resp.data.Value
        //         let statementDetails = details.Statements[0]
        //         let statementFiles = details.StatementFiles

        //         this.selectedStatement.title = statementDetails.Title
        //         this.selectedStatement.description = statementDetails.Description
        //         this.selectedStatement.responseTo = statementDetails.ResponseTo
        //         this.selectedStatement.attachedFiles = statementFiles

        //     })

        //     this.detailsPopupVisible = true
        // },

        getStatementStatisctics(id){
            this.statisticsVisible = true
            axios.get('News/GetStatementsDetailsAsAdmins',{
                  params:{
                    StatementID: id 
                }
            }).then(resp => {
                let details = resp.data.Value
                let statementDetails = details.Statements[0]

                let allPersons = details.Persons
                let votes = details.PersonVotes
                let voted = votes.filter(el => el.Voted == true)

                const percentage = (voted.length / allPersons.length) * 100
                let result = percentage.toFixed(0)
                let votedPersonIDs = [...voted.map(el => el.PersonID)]

                let filteredPersons = details.Persons.filter(e=> !votedPersonIDs.includes(e.PersonID))
                
                if(isNaN(result)) return "0"

                this.statementStatistic.title = statementDetails.Title
                this.statementStatistic.statistic = result
                this.statementStatistic.personlist = filteredPersons
                this.statementStatistic.allPersons = details.Persons
                this.statementStatistic.voted = voted
                this.statementStatistic.statementID = statementDetails.StatementID



            })
        },

        readURL(file) {
            const reader = new FileReader();
            reader.onload = (event) => event.target.result;
            reader.readAsDataURL(file);
        },

        uploadFiles: function () {
            this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload);
            this.fileRecordsForUpload = [];
        },
        deleteUploadedFile: function (fileRecord) {
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },
        filesSelected: function (fileRecordsNewlySelected) {
            var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
        },
        onBeforeDelete: function (fileRecord) {
            var i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
            this.fileRecordsForUpload.splice(i, 1);
            var k = this.fileRecords.indexOf(fileRecord);
            if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
            }
        },
        fileDeleted: function (fileRecord) {
            var i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
            this.fileRecordsForUpload.splice(i, 1);
            } else {
            this.deleteUploadedFile(fileRecord);
            }
        },

        getDepartmentJobsPeople() {
            axios.get("News/GetPlacementDropDownPersons").then((resp) => {
                if (resp.data.ErrorMsg) return;
                this.departmentPeople = resp.data.Value.DepartmentJobPersons.map(
                    (dep) => {
                        return {
                            jobs: dep.JobPersons.map((job) => {
                                return {
                                id: job.JobID,
                                name: job.JobName,
                                people: job.Persons.map((person) => {
                                    return {
                                        id: person.PersonID,
                                        name: person.FirstName + " " + person.LastName,
                                        };
                                    }),
                                };
                            }),
                        };
                    }
                );

            });
        },

        previewStatement(){
            this.detailsPopupVisible = true
            
            this.selectedStatement.title = this.createStatement.title
            this.selectedStatement.description = this.createStatement.description
            this.selectedStatement.responseTo = this.createStatement.responseToText
            this.selectedStatement.attachedFiles = []
        },
        previewDocument(fileID){
            
            this.detailsPopupVisible = false
            let currentFile = this.selectedStatement.attachedFiles.filter(file => file.FileID == fileID) 
            this.fileForPreview = currentFile
            let imageFile = this.fileForPreview[0].FileType.split('/')
            if(this.fileForPreview[0].FileType == 'application/pdf' || imageFile[0] == 'image'){
                this.previewPopupVisible = true
            }else{
                this.previewPopupVisible = false
            }
        },
        previewFile(){
            if(this.fileForPreview.length > 0){
                let fileType = this.fileForPreview[0].FileType
                let file = this.fileForPreview[0].File
                return `data:${fileType};base64,${file}`
            }else return
        },
        downloadBase64File(base64,fileName,fileType) {
            const linkSource = `data:${fileType};base64,${base64}`;
            const downloadLink = document.createElement('a');
            document.body.appendChild(downloadLink);

            downloadLink.href = linkSource;
            downloadLink.target = '_self';
            downloadLink.download = fileName;
            downloadLink.click(); 
        },

        exportResponses(id){
            const linkSource = `https://intranet.upgaming.${this.$store.state.isProduction ? 'com' : 'dev'}/api/News/GetStatementResultsForExcel?StatementID=${id}`
            const downloadLink = document.createElement('a');
            document.body.appendChild(downloadLink);

            downloadLink.href = linkSource;
            downloadLink.target = '_self';
            downloadLink.click(); 
        },
        
        deleteEditableFile(id){
            this.createStatement.apiAttachments = this.createStatement.apiAttachments.filter(file => file.FileID !== id)
        }
    },
}
</script>


<style scoped lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');


.background {
    overflow: hidden !important;
}

.statement-block{
    &.disabled{
        opacity: .56;

        ::v-deep{
            .block__type{
                display: none;
            }
        }
    }

    &.notDeletable{
        ::v-deep .block__delete{
            display: none;
        }
    }
}

.file-preview{
    ::v-deep{
        .popup{
            width: calc(100% - 25rem);
            height: calc(100% - 2rem);
            overflow:hidden;
        }

        h3.title{
            display:none;
        }
    }
}

.page-wrapper{
    padding: 2.3125rem 4.375rem;
    .statements-header {
        width: 100%;
        display: flex;
        align-items: space-between;
        .title {
            font-size: 1.5rem;
            font-weight: 400;
            color: #283593;
            text-transform: uppercase;
            margin-right: auto;
        }

        .header-actions{
            display: flex;
            justify-content: flex-start;

            .ongoing-status,.draft-status{
                cursor: pointer;
                margin-left: 0.625rem;

                img{
                    pointer-events: none;
                    user-select: none;
                }
                
                &:first-child{
                    margin-left: 0;
                }
            }


            .action-wrapper{
                margin-left: 3.25rem;
                display:inline-flex;

                .search-statement{
                    width: 12.5rem;
                    height: 2.375rem;
                    border: none;
                    border-radius: 0.375rem;
                    background:#fff;
                    outline: none;
                    color: $blue-primary;
                    font-size: 0.875rem;
                    box-sizing: border-box;
                    padding: 0 0.5625rem;
                    margin-left: 0.625rem;
                    &::placeholder{
                        color: #9696B9;
                    }
                }
            }
        }
    }
    .content{
        width: 100%;
        height: 76vh;
        margin-top: 3.75rem;
        display: grid;
        grid-template-columns: auto auto auto auto;
        justify-content: left;
        overflow: auto;
        padding-bottom: 2rem;
        grid-gap: 1.5625rem 1.575rem;
    }
}

.add-statement-popup{
    ::v-deep .popup{
        width: 81.25rem;
        height: 44.75rem;
        overflow: hidden;
        position: relative;

        h3.title{
            font-size: 1rem;
            color: $blue-primary;
            margin-bottom: 1.125rem;
            font-weight: 500;
        }
    }

        .top{
            width: 100%;
            display: inline-flex;

            ::v-deep .statement-title{  
                width: 43.75rem;
                height: 2.5rem;
            }

            ::v-deep .select-persons{
                margin-left: 1.0625rem;
                .container {
                    width: 16.25rem;
                    height: 2.5rem;

                }
            }

            ::v-deep .start-date{
                margin-left: 1.0625rem;
                width: 11.25rem;
                height: 2.5rem;

                &.disabled {
                    pointer-events: none;
                    opacity: .7;
                }

                .calendar-icon{
                    right: 0;
                }
            }
        }

        .middle{
            margin-top: 1.25rem;
            .text-editor{
                ::v-deep .tox-tinymce {
                height: 14.375rem !important;
                overflow-y: scroll !important;
                border: 0.0625rem solid rgba(#DCDDE6, .8);

                .tox-icon{
                    svg{
                        fill: #959BC9;
                    }
                }
                .tox-tbtn{
                    color: #959BC9;
                }
            }
        }
        .uploader-wrapper{
            width: 23rem;
        }
        .upload-files{
            margin-top: 0.625rem;
            .title{
                font-size: 0.8125rem;
                color: #959BC9;
            }

            .additional-uploader-wrapper{
                margin-top: 0.6875rem;

                .file-agent{
                    &.disabled{
                        ::v-deep{
                            .file-preview{
                                opacity: .2;
                            }
                            input[type="file"]{
                                pointer-events: none;
                            }
                        }
                    }
                }
            }
        }

        ::v-deep .eventCalendar .file-preview-new .file-preview span:not(.help-text){
            top: 1.5rem !important;
        }

        .edit-attached-files{
            width: 100%;
            display: inline-flex;
            margin-top: 2rem;

            .editable-attached-file{
                width: 10rem;
                height: 3.1625rem;
                border-radius: 0.375rem;
                margin-left: 0.625rem;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                &.border{
                    border: 0.0625rem solid #dedff4;
                }
                .file-name{
                    width: 5rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: $blue-primary;
                    font-size: 0.875rem;
                }

                .file-block{
                    width: 1.875rem;
                    height: 1.875rem;
                    font-size: 0.8125rem;
                    display: grid;
                    place-items: center;
                    color: #fff;
                    margin-right: 0.625rem;
                    border-radius: 0.375rem;

                    &[type="pdf"] {
                        background-color: #ff6760;
                    }

                    &[type="xlsx"] {
                        background-color: rgba(17, 123, 65, 0.871);
                    }
                    &[type="PNG"],[type="png"] {
                        background-color: $green-primary;
                    }
                    &[type="SVG"],[type="svg"] {
                        background-color: $green-primary;
                    }
                    &[type="jpg"] {
                        background-color: rgba(17, 123, 65, 0.871);
                    }
                    &[type="txt"] {
                        background-color: #364ba2;
                    }

                    &[type="docx"],&[type="doc"] {
                        background-color: #364ba2;
                    }
                    &[type="jpeg"]{
                        background-color: $green-primary;
                    }
                }

                .delete{
                    position: absolute;
                    top: 0.125rem;
                    right: 0.3125rem;
                    font-size: 0.8125rem;
                    color: $blue-primary;
                    cursor: pointer;

                }
                &:first-child{
                    margin-left: 0;
                }
            }
        }
    }

    .bottom{
        width: 100%;
        margin-top: 1.875rem;

        .response-text-wrapper{
            display: inline-flex;

            span{
                color: $blue-primary;
                font-size: 1rem;
            }

            input{
                outline: none;
                border: none;
                width: 30rem;
                font-size: 0.875rem;
                font-weight: 300;
                margin-left: 2.5rem;
                color: $blue-primary;
                &::placeholder{
                    color: $blue-primary;
                }
            }

            .delete{
                font-size: 0.9375rem;
                color: #FF5F58;
                cursor: pointer;
                padding-left: 0.75rem;
                padding-top: 0.125rem;
            }
        }

        .actions-wrapper{
            display: flex;
            margin-top: 2.5rem;
            justify-content: space-between;

            .preview{
                cursor: pointer;
                span{
                    font-size: 0.9375rem;
                    color: $blue-primary;
                    font-weight: 300;
                }

                img{
                    width: 1.125rem;
                    margin-left: 0.5rem;
                }
            }

            .btns{
                position: relative;
                button{
                    width: 8.375rem;
                    height: 2.875rem;
                    outline: none;
                    cursor:pointer;
                    font-size: 1rem;
                    margin-right: 0.9375rem;
                    border-radius: 0.375rem;

                    &:nth-child(2){
                        margin-right: 0;
                    }

                    &.draft,&.disable,&.editDraft{
                        background:#fff;
                        color: $blue-primary;
                        border: 0.0625rem solid $blue-primary;
                    }

                    &.publish{
                        background: rgba($blue-primary, .6);
                        color: #fff;
                    }
                }

                .repeated-resp{
                    display: inline-flex;
                    position: absolute;
                    top: -4rem;
                    right: 0.3125rem;

                    span{
                        font-size: 0.875rem;
                        color: $blue-primary;
                        font-weight: 300;
                    }

                    .custom-checkbox{
                        width: 1.0625rem;
                        height: 1.0625rem;
                        border: 0.0625rem solid #1DE9B6;
                        border-radius: 50%;
                        overflow: hidden;
                        display: grid;
                        place-items: center;
                        margin-left: 0.4375rem;
                        cursor: pointer;

                        div{
                            width: calc(100% - 0.125rem);
                            height: calc(100% - 0.125rem);
                            border-radius: 50%;

                            &.checked {
                                background: #1DE9B6;
                            }
                        }
                    }

                }
            }
        }
    }
}

.statement-details-popup{
    ::v-deep .popup{
        width: 80.4375rem;
        height: 49.875rem;
        height: unset !important;

        .title{
            font-size: 1.125rem;
            color: $blue-primary;
        }
    }

    .details-description{
        width: 100%;
        min-height: 13rem;
        max-height: 25.25rem;
        overflow: auto;
        color: $blue-primary;
        font-size: 1rem;
        margin-top: 0.625rem;
    }

    .files-wrapper{
        width: 100%;
        margin-top: 1.25rem;

        span{
            color: $blue-primary;
            font-size: 0.875rem;
            font-weight: 300;
        }
        
        ul.files-list{
            margin-top: 0.75rem;
            list-style: none;
            display: flex;
            justify-content: left;

            li{
                width: 4.4375rem;
                height: 4.4375rem;
                border-radius: 0.625rem;
                margin-left: 0.5625rem;
                font-size: 1rem;
                display: grid;
                place-items: center;
                color: #fff;
                font-weight: 700;
                position: relative;
                // background-size: 100% 100%;

                span{
                    z-index: 1;
                    color: #fff;
                    font-weight: 700;
                }
                
                &:first-child{
                    margin-left: 0;
                }

                &.uploaded-document{
                    cursor: pointer;
                    &[type="pdf"] {
                        background-color: #ff6760;
                    }

                    &[type="xlsx"] {
                        background-color: rgba(17, 123, 65, 0.871);
                    }
                    &[type="PNG"],[type="png"] {
                        background-color: $green-primary;
                    }
                    &[type="SVG"],[type="svg"] {
                        background-color: $green-primary;
                    }
                    &[type="jpg"] {
                        background-color: rgba(17, 123, 65, 0.871);
                    }
                    &[type="txt"] {
                        background-color: #364ba2;
                    }

                    &[type="docx"],&[type="doc"] {
                        background-color: #364ba2;
                    }
                }
            }
        }
    }

    .response-btn-wrapper{
        width: 100%;
        margin-top: 3.75rem;
        display: grid;
        place-items: center;
        padding-bottom: 1.25rem;

        button{
            width: calc(100% - (14.0625rem * 2));
            height: 4.6875rem;
            outline: none;
            border: none;
            background: rgba(#6956D6, .8);
            border-radius: 0.375rem;
            font-size: 1rem;
            line-height: 1.3125rem;
            color: #fff;
            cursor: pointer;
        }
    }
}

.statistics{
    ::v-deep{
        .popup{
            width:  67.8125rem;
            height: unset;
            padding: 1.5625rem 3.6875rem;
        }
    }

    .statistics-header{
        width: 100%;
        display: flex;
        justify-content: space-between;


        .wrap{
            display: flex;
            flex-direction: column;

            span{
                font-size: 1.125rem;
                color: $blue-primary;
                font-weight: 500;
                max-width: 50rem;

                &.title{
                    margin-top: 1.3125rem;
                }
            }
        }

        .export-statistics{
            width: 8.25rem;
            height: 3rem;
            background: #6956D6;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.1875rem;
            cursor: pointer;

            img{
                width: 1.0625rem;
            }

            span{
                font-size: 0.9375rem;
                color: #fff;
                font-weight: 400;
                margin-left: 0.375rem;
                user-select: none;
            }
        }
    }


    .statistic-details{
        width: 100%;
        height: 14.875rem;
        display: flex;
        justify-content:space-between;
        align-items: center;
        margin-top: 1.5625rem;

        .person-votes{
            border-radius: 0.375rem;
            border: solid #EDEDFC 0.0625rem;
            padding: 0.9375rem 1.6875rem;
            span{
                color: $blue-primary;
                font-size: 1rem;
                span{
                    color: #DC3F59;
                }
            }

            .person-list{
                width: 41.6875rem;
                height: 7.8125rem;
                overflow: auto;
                margin-top: 1.375rem;

                ul{
                    list-style-type: none;
                    display: grid;
                    grid-template-columns: auto auto auto;
                    li{
                        font-size: 0.875rem;
                        color: $blue-primary;
                        font-weight: 300;
                        line-height: 1.1875rem;
                        margin-top: 0.3125rem;


                        &:first-child{
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .statistic-results{
            h1.percentage{
                font-family: 'Anton', sans-serif;
                font-size: 5rem;
                color: rgba(#283593 ,.5);
                font-weight: 500;
                &:after{
                    content: "%";
                    font-size: 3.75rem;
                }
            }
            span{
                color: $blue-primary;
                font-size: 1.25rem;
                margin-top: 0.625rem;
                margin-left: 1.5625rem;
            }
        }
    }
}


</style>