<template>
  <div class="employee-roles">
    <form @submit.prevent="addRole">
      <input-search
        :class="{ inputDisabled: employeeRoles.length > 0 }"
        title="Choose Role"
        :options="isHR ? hrOptions : inputOptions"
        :isRequired="true"
        @select="(value) => (this.selectedRoleID = value)"
      />
      <button :class="{ inputDisabled: employeeRoles.length > 0 }" class="btn">
        Add
      </button>
    </form>
    <div class="roles-container">
      <div v-for="role in employeeRoles" :key="role.ID" class="role-container">
        <span class="name">{{ role.Name }}</span>
        <!-- <i class="material-icons" @click="deleteRole(role.ID)">delete_forever</i> -->
        <img
          class="material-icons"
          @click="deleteRole(role.ID)"
          src="@/assets/icons/delete.svg"
          alt="delete"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputSearch from '@/components/ReusableComponents/InputSearch/InputSearch.vue'
import axios from '@/Helpers/AxiosInstance.js'
export default {
  components: {
    InputSearch,
  },
  props: {
    employeeID: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.getEmployeeRoles()
    this.getAllRoles()
  },
  data() {
    return {
      employeeRoles: [],
      allRoles: [],
      selectedRoleID: '',
    }
  },
  methods: {
    getEmployeeRoles() {
      axios
        .get('Permission/GetPersonRoles', {
          params: {
            ID: this.employeeID,
          },
        })
        .then((resp) => {
          this.employeeRoles = resp.data.Value.filter((el) => el.IsActive)
        })
    },
    getAllRoles() {
      axios
        .get('Permission/GetRoles', {
          params: {
            Page: 1,
            PageSize: 1000000,
            OrderByColumn: 'ID',
            Ascending: false,
          },
        })
        .then((resp) => (this.allRoles = resp.data.Value))
    },
    addRole() {
      axios
        .post('Permission/AssignPersonRole', {
          Person: this.employeeID,
          Role: this.selectedRoleID,
          Activate: true,
        })
        .then(() => {
          this.getEmployeeRoles()
        })
    },
    deleteRole(roleID) {
      axios
        .post('Permission/AssignPersonRole', {
          Person: this.employeeID,
          Role: roleID,
          Activate: false,
        })
        .then(() => {
          this.getEmployeeRoles()
        })
    },
  },
  computed: {
    inputOptions() {
      return this.allRoles
        .filter((el) => !this.employeeRoles.find((jr) => jr.ID == el.ID))
        .map((el) => {
          el.value = el.ID
          el.title = el.Name
          return el
        })
    },
    hrOptions() {
      return this.allRoles
        .filter((el) => !this.employeeRoles.find((jr) => jr.ID == el.ID))
        .map((el) => {
          el.value = el.ID
          el.title = el.Name
          return el
        })
        .filter((el) => el.ID !== 80)
    },
    isHR() {
      return this.$store.getters.hasRole('HR')
    },
  },
}
</script>

<style lang="scss" scoped>
form {
  width: 310px;
  display: flex;
  justify-content: center;

  margin-bottom: 30 * $rem;

  .input-search {
    margin-right: 15 * $rem;
  }
}
.inputDisabled {
  pointer-events: none;
  opacity: 0.5;
}

.roles-container {
  display: flex;
  align-items: center;

  .role-container {
    display: flex;
    align-items: center;
    width: 221 * $rem;
    height: 45 * $rem;
    background-color: #fff;
    border-radius: 5 * $rem;
    margin-right: 15 * $rem;
    border: $rem solid $grey-border;
    padding: 0 15 * $rem;
    color: $blue-primary;

    .material-icons {
      margin-left: auto;
      cursor: pointer;
      height: 40px;

      &:hover {
        filter: brightness(1.05);
      }
    }
  }
}
</style>
