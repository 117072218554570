<template>
  <overlay :visible="true" @close="$emit('close')">
    <popup title="Team Members" @close="$emit('close')">
      <div class="container">
        <form @submit.prevent="addMember">
          <input-search
            title="Search People"
            @select="(value) => (newMember.person = value)"
            :options="notYetAddedPersonOptions"
            :isRequired="true"
          />
          <custom-select
            title="Position"
            v-model="newMember.position"
            :options="positionTypeOptions"
            :isRequired="true"
            @clear="newMember.position = ''"
          />
          <date-picker
            title="Start Date"
            v-model="newMember.startTime"
            @clear="newMember.startTime = ''"
          />
          <button class="btn">Add</button>
        </form>
        <grid
          identificator="ID"
          title="Members"
          :gridData="gridData"
          :columns="columns"
          :pagination="false"
          :canEdit="true"
          :alternativeStyle="true"
          @rowEdit="editRow"
          :canDelete="true"
          @rowDelete="deleteRow"
        />
      </div>
    </popup>
    <custom-snackbar
      v-if="snackbar.visible"
      :text="snackbar.text"
      :purpose="snackbar.purpose"
      @close="snackbar.visible = false"
    />
  </overlay>
</template>

<script>
import Overlay from "@/components/ReusableComponents/Overlay/Overlay.vue";
import Popup from "@/components/ReusableComponents/Popup/Popup.vue";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue";
import InputSearch from "@/components/ReusableComponents/InputSearch/InputSearch.vue";
import CustomSnackbar from "@/components/ReusableComponents/CustomSnackbar/CustomSnackbar.vue";
import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue";
import Grid from "@/components/ReusableComponents/Grid/Grid.vue";
import axios from "@/Helpers/AxiosInstance.js";
import { timestampToDateTime } from "@/Helpers/HelperFunctions.js";
export default {
  components: {
    Overlay,
    Popup,
    Grid,
    CustomSelect,
    InputSearch,
    DatePicker,
    CustomSnackbar,
  },
  props: {
    teamID: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.getPositionTypes().then(() => this.getTeamMembers());
    this.getPersons();
  },
  data() {
    return {
      popupVisible: true,
      positionTypes: [],
      people: [],
      gridData: [],
      componentLoaded: false,
      newMember: {
        position: "",
        person: "",
        startTime: new Date().getTime(),
      },
      snackbar: {
        text: "",
        purpose: "success",
        visible: false,
      },
    };
  },
  methods: {
    getPositionTypes() {
      return new Promise((resolve, reject) => {
        axios
          .get("Company/GetPositionTypes")
          .then((resp) => {
            if (!resp.data.ErrorMsg) {
              this.positionTypes = resp.data.Value;
              resolve();
            } else reject();
          })
          .catch(() => reject());
      });
    },
    getTeamMembers() {
      axios
        .get("Company/GetTeamPersons", {
          params: {
            Team: this.teamID,
            Page: 1,
            PageSize: 1000000,
          },
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            resp.data.Value.Persons.map((el) => {
              if (!el.EndTime) el.EndTime = 0;
              el.Name = el.FirstName + " " + el.LastName;
            });
            this.gridData = resp.data.Value.Persons;
          }
        });
    },
    getPersons() {
      axios
        .get("person/getpersons", {
          params: {
            Page: 1,
            PageSize: 1000000,
          },
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.people = resp.data.Value;
          }
        });
    },
    addMember() {
      if (!this.newMember.person) return;
      axios
        .post("Company/AssignTeamPersons", {
          Team: this.teamID,
          Person: this.newMember.person,
          PositionType: this.newMember.position,
          StartTime: this.newMember.startTime,
        })
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.getTeamMembers();
            this.newMember.position = "";
            this.newMember.person = "";
          }
        });
    },
    editRow(values) {
      values["Deleted"] = false;
      axios
        .post("Company/UpdateTeamPersons", values)
        .then((resp) => {
          if (!resp.data.ErrorMsg) {
            this.getTeamMembers();
          }
        })
        .catch(() => {});
    },
    deleteRow(id) {
      this.$confirm("Would you like to remove this person from the team?").then(
        () => {
          axios
            .post("Company/UpdateTeamPersons", {
              ID: id,
              Deleted: true,
            })
            .then((resp) => {
              if (!resp.data.ErrorMsg) {
                this.getTeamMembers();
              }
            });
        }
      );
    },
  },
  computed: {
    positionTypeOptions() {
      return this.positionTypes.map((type) => ({
        title: type.Name,
        value: type.ID,
      }));
    },
    notYetAddedPersonOptions() {
      if (this.people.Persons == undefined) {
        return [
          {
            title: "",
            value: "",
          },
        ];
      }
      return this.people.Persons.filter((person) => {
        if (this.gridData.filter((p) => p.Person == person.ID).length)
          return false;
        return true;
      }).map((person) => ({
        title: person.FirstName + " " + person.LastName,
        value: person.ID,
      }));
    },
    columns() {
      return [
        {
          title: "Position",
          key: "PositionType",
          editable: true,
          editType: "select",
          editOptions: this.positionTypeOptions,
          hasTextValue: true,
        },
        {
          title: "Name",
          key: "Name",
        },
        {
          title: "Job",
          key: "Jobs",
          customTemplate: (item, val) => {
            let list = "";
            val.forEach((job) => {
              list += job.Name + ", ";
            });
            if (list) return list.substring(0, list.length - 2);
            return "-";
          },
        },
        {
          title: "Start Date",
          key: "StartTime",
          customTemplate: (item, val) => (val ? timestampToDateTime(val) : "-"),
          editable: true,
          editType: "date",
        },
        {
          title: "End Time",
          key: "EndTime",
          customTemplate: (item, val) => (val ? timestampToDateTime(val) : "-"),
          editable: true,
          editType: "date",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: flex;
  margin-bottom: 15 * $rem;

  .input-search,
  .select-wrapper,
  label {
    margin-right: 15 * $rem;
  }
  .select-wrapper {
    min-width: 12.938rem;
  }
}

::v-deep .popup {
  height: 80vh !important;
  width: 1300 * $rem;
}

::v-deep .grid .table-wrapper.alternativeStyle {
  background: transparent;
}
::v-deep
  .grid
  .table-helper
  table
  tbody
  tr:nth-of-type(2n + 1).alternativeStyle {
  background-color: transparent;
}
</style>