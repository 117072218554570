<template>
  <div class="statistics">
    <div class="statistics__responses">
      <div class="statistics__responses-top">
        <div class="statistics__responses-top-left">
          {{ personChoices.length }} Responses
        </div>
        <div class="statistics__responses-top-right">
          <div
            class="options-head"
            ref="content-right"
            @scroll="handleScrollHeader"
          >
            <div
              class="option-head"
              :key="i"
              v-for="(option, i) in chartStatistics"
            >
              {{ i + 1 }}
            </div>
          </div>
        </div>
      </div>
      <div class="statistics__responses-body">
        <div
          class="statistics__responses-response"
          :key="i"
          v-for="(person, i) in personChoices"
        >
          <div class="statistics__responses-left">
            <div class="persons">
              <div class="person">
                <img
                  v-if="person.comment && person.comment.length > 0"
                  class="person-icon"
                  src="@/assets/icons/icon_comment.svg"
                  alt=""
                  @click.stop="$emit('comment-clicked', person)"
                />
                <div class="person-name">{{ person.name }}</div>
                <div class="person-hover" v-if="commentId === person.id">
                  {{ person.comment }}
                </div>
              </div>
            </div>
          </div>
          <div class="statistics__responses-right">
            <div
              class="options"
              ref="content-right-body"
              @scroll="handleScrollBody"
            >
              <div
                class="option"
                :key="i"
                v-for="(option, i) in chartStatistics"
              >
                <div
                  :class="[
                    'checkbox',
                    {
                      'checkbox--active': idsArray(person.choices).includes(
                        option.id
                      ),
                    },
                  ]"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="statistics__waiting">
      <div class="wrapper">
        <div class="statistics__waiting-title">
          Waiting for {{ inactivePersons.length }} responses
        </div>
        <div
          v-if="published && !finished && inactivePersons.length > 0"
          class="statistics__waiting-button"
          :class="{ disabled: !reminderActive }"
          @click="$emit('reminder-clicked')"
        >
          Send Reminder
        </div>
      </div>
      <div class="users">
        <div class="user" v-for="(user, i) in inactivePersons" :key="i">
          {{ user.name }}
        </div>
      </div>
    </div>
    <div class="statistics__chart">
      <div class="canvas-container">
        <canvas ref="statisticsChart"></canvas>
      </div>
      <div class="statistics__chart-options">
        <div
          class="statistics__chart-options-option"
          v-for="(option, i) in chartStatistics"
          :key="i"
        >
          <div
            class="statistics__chart-options-option-chart"
            :style="{ backgroundColor: colors[i] }"
          />
          <div class="statistics__chart-options-option-name">
            {{ option.option }}
          </div>
          <div class="statistics__chart-options-option-percentage">
            {{
              option.quantity === 0
                ? 0
                : ((option.quantity / option.allQuantity) * 100).toFixed(1)
            }}%
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: {
    published: Boolean,
    reminderActive: Boolean,
    persons: {
      type: Array,
      required: true,
    },
    personChoices: {
      type: Array,
      required: true,
    },
    inactivePersons: {
      type: Array,
      required: true,
    },
    finished: Boolean,
    chartStatistics: {
      type: Array,
      required: true,
    },
    commentId: Number,
  },
  mounted() {
    this.drawChart();
  },
  computed: {
    chartData() {
      return [...this.chartStatistics.map((el) => el.quantity)];
    },
    chartLabels() {
      return [...this.chartStatistics.map((el) => el.option)];
    },
  },
  data() {
    return {
      chart: null,
      showComment: false,
      colors: [
        "#243391",
        "#739def",
        "#9aabb7",
        "#a2c037",
        "#1772e4",
        "#805c27",
        "#6FCBF3",
        "#A39CD3",
        "#58E1F2",
        "#90caf1",
        "#F193A3",
        "#79F1D6",
        "#6FE9E1",
        "#F8DD7E",
        "#FBB2E6",
      ],
    };
  },
  methods: {
    drawChart() {
      const ctx = this.$refs.statisticsChart.getContext("2d");
      if (!this.chart) {
        this.chart = new Chart(ctx, {
          type: "pie",
          data: {
            datasets: [
              {
                data: this.personChoices.length > 0 ? this.chartData : [99],
                backgroundColor:
                  this.personChoices.length > 0
                    ? [...this.colors]
                    : ["#959bc9"],
              },
            ],
            labels:
              this.personChoices.length > 0 ? this.chartLabels : ["No data"],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            elements: {
              arc: {
                borderWidth: 0,
              },
            },
            tooltips: {
              tooltipCaretSize: 0,
              titleFontSize: 16,
              bodyFontSize: 11,

              callbacks: {
                label: (tooltipItem, data) => {
                  return data.labels[tooltipItem.index] || "";
                },
              },
            },
            hover: {
              mode: null,
            },
            legend: {
              display: false,
            },
            // showTooltips: false,
          },
        });
      } else {
        this.chart.data.datasets[0].data =
          this.personChoices.length > 0 ? this.chartData : [99];
        this.chart.data.datasets[0].backgroundColor =
          this.personChoices.length > 0 ? [...this.colors] : ["#959bc9"];
        if (this.personChoices.length === 0)
          this.chart.data.labels = ["No data"];
        else this.chart.data.labels = this.chartLabels;
        this.chart.update();
      }
    },
    idsArray(choices) {
      let ids = [];
      choices.forEach((ch) => {
        ids.push(parseInt(ch.Option));
      });
      return ids;
    },

    handleScrollHeader(event) {
      const bodyElement = this.$refs["content-right-body"];
      bodyElement.scrollLeft = event.target.scrollLeft;
    },
    handleScrollBody(event) {
      const bodyElement = this.$refs["content-right"];
      bodyElement.scrollLeft = event.target.scrollLeft;
    },
  },

  watch: {
    chartStatistics: {
      handler() {
        this.drawChart();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics {
  height: calc(100% - 69px);

  display: flex;
  flex-direction: column;

  font-weight: 300;
  > div {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__waiting {
    overflow-y: auto;
    width: 100%;
    height: 180px;
    background: white;
    border-radius: 10px;
    color: #283593;
    padding: 20px 30px 10px;

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-button {
      cursor: pointer;
      width: 144px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #4aedc4;
      color: white;
      border-radius: 10px;
      font-weight: 400;
      transition-duration: 0.4s;
      position: relative;
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      &:active {
        background-color: #16b18a;
        background-size: 100%;
        transition: background 0.1s;
      }
    }
    &-title {
      font-weight: 400;
    }
  }
  &__chart {
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 21px;
    background-color: white;
    border-radius: 10px;

    &-options {
      height: 100%;
      width: 370px;
      display: flex;
      flex-direction: column;
      max-height: 200px;
      overflow-y: auto;

      &-option {
        color: #283593;
        font-size: 13px;
        margin-bottom: 20px;
        align-items: baseline;
        display: grid;
        grid-template-columns: 20px 1fr 1fr;
        text-align: left;

        &-chart {
          height: 10px;
          width: 10px;
          background-color: red;
          border-radius: 100%;
        }

        &-percentage {
          color: #acb1d5;
          font-weight: 500;
        }
        &-name {
          min-width: 300px;
          max-width: 340px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  &__responses {
    height: 330px;

    padding: 30px;
    background: white;
    border-radius: 10px;

    padding-bottom: 0;

    font-size: 14px;
    width: 100%;
    &-top {
      height: 20px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      &-left {
        width: 170px;
        font-size: 16px;
        color: #283593;
        font-weight: 400;
      }

      &-right {
        width: calc(100% - 170px);
        color: #9696b9;
      }
    }

    &-body {
      height: 260px;
      overflow-y: auto;
    }

    &-left {
      width: 170px;
      color: #283593;
      font-weight: 300;
    }

    &-right {
      width: calc(100% - 170px);

      //overflow-y: auto;
    }

    &-response {
      display: flex;
    }
  }
}

.options-head {
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */

  &::-webkit-scrollbar {
    width: 0px !important; /* Remove scrollbar space */
    background: transparent !important; /* Optional: just make scrollbar invisible */
  }

  &::-webkit-scrollbar-thumb {
    background: transparent !important;
  }
}

.options {
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar-thumb {
    background: rgba($green-primary, 0.1);
  }
}

.option {
  min-width: 30px;
  padding-bottom: 10px;
}

.option-head {
  min-width: 30px;
}

.checkbox {
  height: 12px;
  width: 12px;
  background-color: white;
  border: 1px solid #959bc9;
  transition: 0.2s ease;
  border-radius: 3px;

  &--active {
    background-color: #1de9b6;
    border-color: #1de9b6;
  }
}

.users {
  margin-top: 6px;
  max-height: calc(100% - 49px);
  overflow-y: auto;
  width: 102%;
  display: flex;
  flex-wrap: wrap;
  font-weight: 300;
  font-size: 14px;
  .user {
    width: 50%;
  }
}

.canvas-container {
  width: 160px;
  height: 160px;
}

.person {
  position: relative;

  .person-name {
    max-width: 8.9rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &-icon {
    cursor: pointer;
    position: absolute;
    right: -0.4rem;
    transform: translateX(-150%);
  }

  .person-hover {
    position: absolute;
    min-height: 50px;
    margin-left: 111px;
    background-color: rgba(40, 53, 147, 0.8);
    font-size: 12px;
    color: white;
    width: 300px;
    padding: 10px 15px;
    border-radius: 5px;
    z-index: 50;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
    margin-left: 8rem;
    max-height: 27.9rem;
    overflow: auto;

    &:after {
      z-index: 49;
      content: "";
      background-color: rgba(#283593, 0.8);
      width: 15px;
      height: 15px;
      position: absolute;
      top: 0;
      clip-path: polygon(50% 0, 0 50%, 100% 50%);
      left: 15px;
      transform: translateY(-49.8%);
    }
  }
}
</style>
