<template>
    <div class="user" @click="$emit('click')">
        <span class="full-name">{{ fullname }}</span>
        <custom-avatar :person="{id:this.$store.state.profile.ID,firstName:this.$store.state.profile.FirstName,lastName:this.$store.state.profile.LastName}" />
<!--        <div class="notification-count">{{notificationCount}}</div>-->
    </div>
</template>

<script>
import CustomAvatar from '@/components/ReusableComponents/CustomAvatar/CustomAvatar'
export default {
    components: {
        CustomAvatar,
    },
    data() {
        return {
            notificationCount: 2,
        }
    },
    computed: {
        fullname() {
            return `${this.$store.state.profile.FirstName} ${this.$store.state.profile.LastName}`
        },
    },
}
</script>

<style scoped lang="scss">
    .user {
        position: relative;
        display: flex;
        align-items: center;
        margin: 0 15 * $rem;

        .avatar {
            width: 50 * $rem;
            height: 50 * $rem;
            font-size: 16 * $rem;
            border: 1px solid $blue-primary;
            cursor: pointer;
        }

        .full-name {
            margin-right: 35 * $rem;
            color: $blue-primary;
            font-size: 15.75 * $rem;
            font-weight: 500;
            width: 139px;
            text-align: right;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .notification-count {
            font-size: 14 * $rem;
            font-weight: 500;
            width: 18 * $rem;
            height: 18 * $rem;
            background-color: #2ee9b4;
            color: $blue-primary;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: -5 * $rem;
            right: 0;
        }
    }
</style>