var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"day"},[_c('div',{staticClass:"day-header",style:([!_vm.bigVersion ? { height: '5rem' } : null])},[_c('span',{staticClass:"day",class:{ yesterday: _vm.isYesterday(_vm.date) },style:([!_vm.bigVersion ? { 'margin-top': '0px' } : null])},[_vm._v(_vm._s(_vm.computedDay))]),_c('span',{staticClass:"date",style:([!_vm.bigVersion ? { 'font-size': '17px' } : null])},[_vm._v(_vm._s(_vm.computedDate))])]),_c('div',{staticClass:"chart-container",class:{ middle: _vm.middle, withBorder: _vm.bigVersion },style:([
      !_vm.bigVersion
        ? { height: 'calc(100% - 80 * 0.0625rem)', 'padding-bottom': '10px' }
        : null,
    ])},[(!_vm.vacation)?_c('span',{staticClass:"worked"},[_vm._v(_vm._s(_vm.computedWork))]):_c('span',{staticClass:"worked",style:([!_vm.bigVersion ? { 'font-size': '16px' } : null])},[_vm._v(_vm._s(_vm.vacationName))]),(
        (_vm.vacation == true && _vm.work > 0) ||
        (_vm.vacation == true && _vm.work == 0 && _vm.overtime > 0) ||
        _vm.vacation == false
      )?_c('span',{staticClass:"difference",class:{ negative: _vm.differenceIsNegative() }},[_vm._v(_vm._s(_vm.computedDifference))]):_vm._e(),_c('attendance-chart',{attrs:{"work":_vm.work,"overtime":_vm.overtime,"totalWork":_vm.totalWork}}),_c('attendance-in-out',{style:([!_vm.bigVersion ? { 'margin-top': '14px' } : null]),attrs:{"in-outs":_vm.inOuts}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }