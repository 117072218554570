<template>
  <div class="user-table-content">
    <table>
      <thead>
        <tr>
          <th v-if="profileVacation == false">#</th>
          <th class="datesHead">From Date - To Date </th>
          <th>Days</th>
          <th class="vcType">Vacation Type</th>
          <th>My Comment</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(el, ind) in requests" :key="el.index">
          <td class="vacations-ind" v-if="profileVacation == false">{{ ind + 1 }}</td>
          <td class="dates">
            <span class="date_range" v-if="!el.canEdit">{{ computedDate(el.FromDate) }}</span>
            <span style="margin-left: 0.4375rem" v-if="!el.canEdit">-</span>
            <span class="date_range" v-if="!el.canEdit">{{ computedDate(el.ToDate) }}</span>
            <date-range
                  :initialFrom="el.FromDate"
                  :initialTo="el.ToDate"
                  @from="(from) => (newVacation.newVacationFromDate = from)"
                  @to="(to) => (newVacation.newVacationToDate = to)"
                  v-if="el.canEdit == true"
            />
          </td>
          <td>
            <span>{{ el.TotalDays }}</span>
          </td>
          <td class="vcType">
            <span v-if="!el.canEdit">{{ el.VacationTypeName }}</span>
            <div class="select-content">
              <custom-select
                v-if="el.canEdit == true"
                v-model="newVacation.newVacationType"
                :title="el.VacationTypeName"
                :options="vacationTypeOptions"
                @clear="newVacation.newVacationType = ''"
              />
            </div>
          </td>
          <td>
            <span :title="el.Comment" v-if="!el.canEdit">{{ el.Comment }}</span>
            <custom-text-area
              title="comment"
              v-model="el.Comment"
              v-if="el.canEdit == true"
              class="userComment"
            />
          </td>
          <td class="confInfo_wrapper"> 
            <vacation-confirmation
              :approved="el.Approved"
              :mustConfirmNumb="el.MustBeApprovedByNum"
              :rejectNumb="el.RejectsNum"
              :confirmNumb="el.ApprovesNum"
              class="conf"
            />
              <span
                v-if="(!el.confirmationInfoVisible)"
                @click.stop="GetVacationApprovedBy(el),(active = true)"
                class="material-icons arrow-down"
                >keyboard_arrow_down</span
              >
              <span
                v-if="el.confirmationInfoVisible"
                class="material-icons arrow-up"
                @click.stop="
                  (el.confirmationInfoVisible = false),
                    (clickedVacationID = 0),(active = true)
                "
                >keyboard_arrow_up</span
              >
          </td>
          <!-- <td class="comment-td">
            <div
              class="comment-content"
              @click.stop="
                getComment(el.VacationID),
                  (el.commentVisible = !el.commentVisible)
              "
            >
              <img class="comment-icon" src="@/assets/icons/red/comment.svg" />
              <span>{{ el.CommentsNum }}</span>
            </div>
            <comment-popup
              v-if="el.CommentsNum > 0"
              :commentVisible="el.commentVisible"
              @close="el.commentVisible = false"
              :data="comments"
            >
            </comment-popup>
          </td> -->
          <td>
            <div class="actions" v-if="el.Approved == null">
              <span class="icon-span" v-if="el.canEdit == false">
                <img
                  @click="$emit('active-edit', el)"
                  src="@/assets/icons/edit.svg"
                  alt="edit"
                />
              </span>
              <div class="material-icons-content" v-if="el.canEdit == true">
                <span
                  @click="$emit('checked', el, newVacation)"
                  class="material-icons success"
                  >check</span
                >
                <span @click="$emit('cancel', el)" class="material-icons cancel"
                  >close</span
                >
              </div>
              <span @click="$emit('active-delete', el)" class="icon-span">
                <img src="@/assets/delete.svg" alt="delete" />
              </span>
            </div>
          </td>
          <div v-if="clickedVacationID == el.VacationID" class="vacation-confirmation-details" :class="{visible:active,hidden:!active}">
            <div class="confirmation-detail" >
              <div class="persons-confirmations" v-if="el.confInfo.length">
                <div class="person" v-for="(e) in el.confInfo" :key="e.index">
                  <span>{{e.FirstName + ' ' + e.LastName}}</span>
                  <span> {{e.Approved ? "Approved" : e.Approved == false  ? "Rejected" : "Pending"}}</span>
                  <span>
                    {{e.StartDate ? getDateString(e.StartDate, "dd MMM yyyy HH:mm") : ''}}
                  </span>
                  <span class="person-comment">{{e.Comment}}</span>
                </div>
              </div>
              <div class="altText" v-else>
                <span>You don't have any approver</span>
              </div>
            </div>
          </div>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  getDateString,
  CETToTimestamp,
  timestampToCET,
} from "@/Helpers/HelperFunctions.js";
import VacationConfirmation from "@/components/VacationAdmin/VacationConfirmation.vue";
import axios from "@/Helpers/AxiosInstance.js";
// import commentPopup from "./commentPopup";
// import DatePicker from "@/components/ReusableComponents/DatePicker/DatePicker.vue";
import CustomSelect from "@/components/ReusableComponents/CustomSelect/CustomSelect.vue";
import CustomTextArea from "@/components/ReusableComponents/CustomTextArea/CustomTextArea.vue";
import DateRange from "@/components/ReusableComponents/DateRange/DateRange";



export default {
  created() {
    this.getVacationtypes();
  },
  props: {
    requests: Array,
    profileVacation: Boolean,
  },
  components: {
    VacationConfirmation,
    // commentPopup,
    // DatePicker,
    CustomSelect,
    CustomTextArea,
    DateRange,

  },
  data() {
    return {
      active: false,
      comments: [],
      dateRange: {
        fromDate: 0,
        toDate: 0,
      },
      clickedVacationID: '',
      vacationTypes: [],
      newVacation: {
        newVacationToDate: "",
        newVacationFromDate: "",
        newVacationType: "",
      },
    };
  },

  methods: {
    getDateString,
    computedDate(x) {
      let date = getDateString(x, "dd MMM yyyy");
      return date;
    },
    GetVacationApprovedBy(el) {
      axios
        .get(`vacations/GetVacationApprovedBy?Vacation=${el.VacationID}`)
        .then((resp) => {
          this.clickedVacationID = el.VacationID;
          this.$set(el, "confInfo", resp.data.Value);
          this.$set(el, "confirmationInfoVisible", true);
          this.confirmationInfo = resp.data.Value;
        });
        this.requests.map((e) => 
          e.confirmationInfoVisible = e.VacationID == el ? true : false
        )
    },
    // getComment(vacationID) {
    //   axios
    //     .get(`vacations/GetVacationComments?Vacation=${vacationID}`)
    //     .then((resp) => {
    //       this.comments = resp.data.Value;
    //     });
    // },
    getVacationtypes() {
      axios.get("vacations/Types").then((response) => {
        this.vacationTypes = response.data.Value;
      });
    },
  },
  computed: {
    commentsLength() {
      return this.comments.length;
    },
    vacationTypeOptions() {
      return this.vacationTypes.map((el) => ({
        title: el.Name,
        value: el.ID,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.user-table-content {
  height: 100%;
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  padding-left: 100px;
  .vcType{
    padding-left: 5rem;
  }
  thead {
    tr {
      th {
        color: $blue-primary;
        font-size: 15px;
        font-weight: 400;
        padding: 15 * $rem 15 * $rem 15 * $rem 40 * $rem;
        text-align: left;

      }
      th:nth-child(1) {
        padding-left: 2.625rem;
        // width: 8.125rem;
      }
    }
  }

  tbody {
    max-height: 290px;
    overflow-y: scroll;
    tr {
      height: 60px;
      position: relative;
      &:nth-of-type(2n + 1) {
        background: rgba(40, 53, 147, 0.03);
      }

      td {
        font-size: 16 * $rem;
        text-align: left;

        font-weight: 300;
        padding: 10 * $rem 0 0.25rem 40 * $rem;
        color: $blue-primary;
        span {
          white-space: nowrap;
          max-width: 250px;
          text-overflow: ellipsis;
          display: inline-block;
          overflow: hidden;

          &.date_range{
            margin-left: 0.4375rem;

            &:first-child{
              margin-left: 0;
            }
          }
        }

        // &.dates{
        //   padding-left: 0;
        // }

        &.vacations-ind{
          width: 2.4375rem;
        }
        .select-content {
          width: 150px;
        }
      }
    }
    .actions {
      display: flex;
      cursor: pointer;
      padding-right: 1.4375rem;
      .icon-span {
        height: 35px;
        width: 35px;
      }
    }
    .material-icons-content {
      padding-top: 6px;
      span:nth-child(1) {
        margin-right: 5px;
      }
      span:nth-child(2) {
        color: red;
      }
    }
  }
}
.comment-icon {
  height: 38px;
}
.comment-content {
  opacity: 0.8;

  cursor: pointer;
  span:nth-child(2) {
    font-size: 14px;
  }
}
.comment-td {
  position: relative;
}
::v-deep.comment-popup {
  right: 25%;
  position: absolute;
  top: 55px;
  z-index: 10;
}
::v-deep.confirmation-content {
  margin-top: 0;
  margin-left: 0;
  padding-top: 0;
}
::v-deep.confirmation-tabs {
  margin-bottom: 6px;
}
.confInfo_wrapper{
  width: 11.3rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
  .conf{
    margin-top: 0.9375rem;
  }
  span{
    margin-left: 0.9375rem;
    font-size: 1.5625rem;
    cursor: pointer;
    opacity: 0.5;
  }
  
}
.vacation-confirmation-details{
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 999;
  left: 0;



  &.visible{
    display: block;
  }
  &.hidden{
    display: none;
  }


  .confirmation-detail{
    width: 100%;
    padding: 5px 40px;
    background: #e7e8f9;
    position: absolute;
    left: 0;
    box-sizing: border-box;
    padding: 1.0625rem 1.3rem;
    padding-top: 0.1875rem;
    border-top: 0.125rem solid #EDEDFC;
    border-bottom: 0.125rem solid #EDEDFC;

    .altText{
      width: 100%;
      padding: 1.05rem;
      text-align: center;
      height: 0;

      span{
        font-weight: 300;
        color: #283593;
        font-size: 1rem;
        margin-top: 0.3125rem;
      }
    }
    

    .persons-confirmations{
      padding-left: 5.5rem;
    }

    .person{
      display: flex;
      justify-content: unset;
      align-items: flex-start;
      margin-top: 0.625rem;

      span{
        width: 10.625rem;
        padding-left: 3.125rem;
        font-size: 0.875rem;
        color: rgba($blue-primary, .5);

        &.person-comment{
          width: 50rem !important;
        }

        &:first-child{
          padding-left: 0;
        }
      }
    }
  }
}

.userComment{
  ::v-deep textarea{
    width: 22rem;
    height: 4rem;
    resize: none;
  }
}

::v-deep .select-wrapper .selected-box{
    width: 105%;
  }

</style>