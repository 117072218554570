<template>
    <div class="in-out">
        <i class="material-icons" ref="icon" @mouseenter="displayInOuts" @mouseleave="hovering = false">access_alarm</i>
        <portal to="in-out" v-if="hovering">
            <div class="in-out-popup" :style="{'left': inOutLeftOffset+27+'px', 'top': inOutTopOffset+10+'px'}">
                <div class="header">
                    <span>In</span>
                    <span>Out</span>
                </div>
                <div v-for="(entry, index) in modifiedInOuts" class="entry" :key="index">
                    <span>{{entry.in}}</span>
                    <span>{{entry.out}}</span>
                </div>
            </div>
        </portal>
    </div>

</template>

<script>
import axios from '@/Helpers/AxiosInstance'
export default {
    props: {
        inOuts: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            hovering: false,
            inOutLeftOffset: 0,
            inOutTopOffset: 0,
        }
    },
    methods: {
        displayInOuts() {
            this.hovering = true
            var icon = this.$refs.icon
            const clientBoundingRect = icon.getBoundingClientRect()
            this.inOutLeftOffset = clientBoundingRect.left
            this.inOutTopOffset = clientBoundingRect.top
        },
    },
    computed: {
        modifiedInOuts() {
            var ins = []
            var outs = []
            let copy = [...this.inOuts]
                .sort((a, b) => a.InteractionTime - b.InteractionTime)
                .forEach(el => {
                    let d = new Date(el.InteractionTime)
                    var time = `${('0' + d.getHours()).slice(-2)}:${('0' + d.getMinutes()).slice(-2)}`
                    if(el.ActionType == 'IN') ins.push({'in': time})
                    else if(el.ActionType == 'OUT') outs.push({'out': time})
                })
            var inOutArr = []
            for(let i = 0; i<Math.max(ins.length, outs.length); i++) {
                inOutArr.push({...ins[i], ...outs[i]})
            }
            return inOutArr
        },
    },
    watch: {
        date() {
            this.getData()
        },
    },
}
</script>

<style scoped lang="scss">
    .in-out {
        position: relative;

        i {
            margin-top: auto;
            margin-bottom: 15 * $rem;
            color: #eeeff7;
            font-size: 24 * $rem;
            cursor: pointer;
            transition: color .3s ease-out;
    
            &:hover {
                color: $blue-primary;
            }
        }
        
        .in-out-popup {
            display: none;
            background-color: rgba(40, 53, 147, 0.2);
            position: absolute;
            width: 148 * $rem;
            border-right: 4 * $rem solid $blue-primary;
        }
    }
</style>