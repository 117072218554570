<template>
  <div class="persons-performance">
    <div class="filters">
      <div class="filter-wrapper">
        <StructureDropDown
          :data="strucTureData"
          @clear-selected="clearSelected"
          @selected="filterPersons"
        />
      </div>
      <div class="filter-wrapper">
        <date-picker
          :title="selectedDate  ? 'Filter by date' : 'Today'"
          v-model="selectedDate"
          @clear="SelectedDate = ''"
        />
      </div>
    </div>

    <div class="performance-wrapper">
      <template v-for="(dep, key) in groupByDep">
        <div class="dep-content" :key="key">
          <span class="dep-name">
            {{ key !== 'null' ? key : 'Without Department' }}
          </span>
          <OneDayPerformance
            v-for="(dayPerformance, index) in dep"
            :key="index"
            :totalAttendance="dayPerformance.totalAttendance"
            :plannedTime="dayPerformance.plannedTime"
            :totalActive="dayPerformance.totalActive"
            :fullName="dayPerformance.fullName"
            :date="dayPerformance.date"
            :scheduleStart="dayPerformance.scheduleStart"
            :scheduleEnd="dayPerformance.scheduleEnd"
            :inactivities="dayPerformance.inactivities"
            :inOuts="dayPerformance.inOuts"
            :isRecalculated="dayPerformance.isRecalculated"
            :scheduleType="dayPerformance.scheduleType"
            :vacation="dayPerformance.vacation"
            :breakDuration="dayPerformance.breakDuration"
            :totalWork="dayPerformance.scheduleTotalWorkTime"
            :vacationType="dayPerformance.vacationType"
            :personID="dayPerformance.personID"
            :firstInList="index == 0"
            @notype="noTypeAssingedHandler()"
            :personsAttendance="true"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import OneDayPerformance from './OneDayPerformance'
import axios from '@/Helpers/AxiosInstance'
import DatePicker from '@/components/ReusableComponents/DatePicker/DatePicker.vue'
import DateRange from '@/components/ReusableComponents/DateRange/DateRange'
import StructureDropDown from '@/components/ReusableComponents/StructureDropDown/StructureDropDown'
// import CustomScrollbar from '@/components/ReusableComponents/CustomScrollbar/CustomScrollbar'

export default {
  components: {
    OneDayPerformance,
    StructureDropDown,
    DatePicker,
  },

  created() {
    this.getData()
    this.getStructure()
  },
  data() {
    return {
      performances: [],
      selectedDate: '',
      strucTureData: [],
      selectedPersons: [],
    }
  },

  methods: {
    getData(persons) {
      axios
        .post('attendance/GetPersonsPerformance', {
          persons: persons ? persons : [],
          date: this.selectedDate ? this.selectedDate : null,
        })
        .then((resp) => {
          var performances = []
          let VacationTypes = [
            'Paid',
            'NotPaid',
            'DayOff',
            'SickDays',
            'Maternity Leave',
            'Working From Home',
          ]
          resp.data.Value.PersonSchedules.forEach((el) => {
            let performance = {}
            performance.date = new Date(el.Date)
            console.log(el.Date)
            performance.scheduleStart = this.timeStringToMinutes(
              el.ScheduleStartTime,
            )
            performance.startTimeString = el.ScheduleStartTime

            performance.endTimeString = el.ScheduleEndTime

            performance.scheduleEnd = this.timeStringToMinutes(
              el.ScheduleEndTime,
            )
            performance.scheduleTotalWorkTime = el.TotalWorkTime
            performance.depName = el.DepartmentName
            performance.BreakEnd = el.BreakEnd
            performance.BreakStart = el.BreakStart
            // if (
            //     el.ScheduleType == 2 &&
            //     performance.scheduleStart &&
            //     performance.scheduleEnd
            // ) {
            //     //adjust flexible schedule start and end accordingly
            //     let offset = new Date().getTimezoneOffset()
            //     performance.scheduleStart -= offset
            //     performance.scheduleEnd -= offset
            // }
            performance.scheduleType = el.ScheduleType
            performance.vacation = el.VacationType
            performance.isRecalculated = el.CalculatedFlexibleWithOverTimeLogic

            performance.totalAttendance = el.AttendanceTime

            performance.plannedTime = el.TotalWorkTime

            performance.totalActive = el.ActiveTime
            performance.fullName = el.FirstName + ' ' + el.LastName
            performance.personID = el.PersonID

            // 1 - Vacation
            // 2 - Holiday
            if (el.VacationType) {
              performance.vacationType = VacationTypes.includes(el.VacationType)
                ? 1
                : 2
            }
            performance.breakDuration = el.TotalBreak
              ? this.timeStringToMinutes(el.TotalBreak)
              : performance.BreakEnd && performance.BreakStart
              ? (performance.BreakEnd - performance.BreakStart) / 60000
              : 0
            performance.inactivities = el.Idles.map((entry) => {
              let start = new Date(entry.IdleStartTime)
              start =
                start.getHours() * 3600 +
                start.getMinutes() * 60 +
                start.getSeconds() +
                new Date().getTimezoneOffset() * 60
              let duration = entry.IdleTime / 1000
              return { start, duration }
            })
            performance.inOuts = el.InOuts.map((entry) => {
              let time = entry.InteractionTime
              return {
                type: entry.ActionType,
                time: time,
                status: entry.Status,
              }
            })
            performances.push(performance)
          })
          this.performances = performances.reverse()
        })
    },
    getStructure() {
      axios.get('departments/GetStructureForFilter').then((resp) => {
        this.strucTureData = resp.data.Value.Structure
      })
    },
    clearSelected() {
      this.selectedPersons = []
      this.getData(this.selectedPersons)
    },
    timeStringToMinutes(time) {
      if (!time) return null
      if (time === '23:59:00') return 24 * 60
      if (time === '00:00:00') return 1
      var hours = parseInt(time.split(':')[0])
      var minutes = parseInt(time.split(':')[1])
      return 60 * hours + minutes
    },
    filterPersons(persons) {
      let personsArr = persons.filter((el) => el.DepType === null)
      let mapped = personsArr.map((el) => {
        return {
          ID: el.ID,
        }
      })
      this.selectedPersons = mapped
      this.getData(mapped)
    },
  },

  watch: {
    selectedDate() {
      this.getData(this.selectedPersons)
    },
  },
  computed: {
    groupByDep() {
      let deps = {}
      this.performances.forEach((el) => {
        if (deps.hasOwnProperty(el.depName)) {
          deps[el.depName].push(el)
        } else {
          deps[el.depName] = []
          deps[el.depName].push(el)
        }
      })
      return deps
    },
  },
}
</script>

<style scoped lang="scss">
.persons-performance {
  height: calc(100% - 30px);
  padding: 1.25rem 4.0625rem;
}
.dep-name {
  color: $blue-primary;
  padding: 15px;
  font-weight: 500;
  display: block;
}
.filters {
  display: flex;
  .filter-wrapper {
    margin-right: 15px;
  }
}
.performance-wrapper {
  border: $rem solid #dedff4;
  border-radius: 10 * $rem;
  margin-top: 15 * $rem;
  height: calc(100% - 60 * #{$rem});
  overflow: auto;
  user-select: none;

  .list {
    height: 100%;
    overflow: auto;
    width: calc(100% + 17 * #{$rem});

    &::-webkit-scrollbar {
      height: 8 * $rem;
      width: 9 * $rem;
      color: #fff;
    }

    &::-webkit-scrollbar-track {
      background: rgba($blue-primary, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba($blue-primary, 0.3);
    }
  }
}
</style>
