<template>
  <div class="activity" @click="redirectToTraffic">
    <div class="header">
      <h3 class="main-title">Performance</h3>
      <activity-calendar @dateChange="handleDateChange" />
    </div>
    <activity-chart
      v-if="selectedDate"
      :bigVersion="bigVersion"
      :date="selectedDate"
    />
  </div>
</template>

<script>
import ActivityCalendar from "@/components/Dashboard/ActivityCalendar";
import ActivityChart from "@/components/Dashboard/ActivityChart";
export default {
  name: "UserActivity",
  components: {
    ActivityCalendar,
    ActivityChart,
  },
  data() {
    return {
      selectedDate: "",
      activityData: [],
    };
  },
  props: {
    bigVersion: Boolean,
  },
  methods: {
    handleDateChange(date) {
      this.selectedDate = date;
    },
    redirectToTraffic() {
      this.$router.push({
        name: "My Traffic",
        params: { date: this.selectedDate },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.activity {
  width: auto;
  position: relative;
  cursor: pointer;
  .header {
    display: flex;
    height: 24 * $rem;

    padding-bottom: 25px;

    h3.main-title {
      font-size: 20 * $rem;
      font-weight: 300;
      margin-top: -35px;

      color: $blue-primary;
    }

    .activity-calendar {
      margin-left: auto;
      position: absolute;
      z-index: 1;
      flex-direction: column-reverse;
      left: 50%;
      top: 13px;
      transform: translate(-50%);
      ::v-deep .chosen-value {
        white-space: nowrap;
        font-size: 17px;
      }
    }
  }
}
</style>