<template>
  <div class="totals" :class="{ first: firstInList }">
    <div class="container">
      <div class="item">
        <router-link
          tag="div"
          v-if="scheduleType == 1"
          :to="{ path: '/FixedSchedules', query: { scheduleID: scheduleId } }"
          class="label"
          >Planned</router-link
        >
        <router-link
          tag="div"
          v-if="scheduleType == 2"
          :to="{ path: '/FlexibleSchedule' }"
          class="label"
          >Planned</router-link
        >
        <div v-if="scheduleType == null" @click="$emit('notype')" class="label">
          Planned
        </div>
        <div class="value">{{ checkIfExist(msToTime(plannedTime)) }}</div>
      </div>
      <div
        class="item hover"
        @mouseover="hoverHandler"
        @mouseout="hovering = false"
      >
        <div class="label">Attendance</div>
        <div class="value">{{ checkIfExist(msToTime(totalAttendance)) }}</div>
      </div>
      <div class="item">
        <div class="label"></div>
        <slot v-if="scheduleType == 2"></slot>

        <div
          class="value"
          :class="{
            positive: attendanceDifference > 0,
            negative: attendanceDifference < 0,
          }"
        >
          {{ checkIfExist(attendanceDifferenceString) }}
        </div>
      </div>
      <div class="item">
        <router-link
          tag="div"
          :to="{ path: '/TrafficHistory', query: { personId: idToSend } }"
          class="label active"
          >Active</router-link
        >
        <div class="value">{{ checkIfExist(msToTime(totalActive)) }}</div>
      </div>
    </div>
    <portal to="in-out" v-if="hovering">
      <div class="in-out-popup less-opacity" :style="inOutStyling">
        <div class="header">
          <span>In</span>
          <span>Out</span>
        </div>
        <div
          v-for="(entry, index) in inOutsForPortal"
          class="entry"
          :key="index"
        >
          <span>{{ entry.in }}</span>
          <span>{{  entry.out  }}</span>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
// import CustomCheckbox from "@/components/ReusableComponents/CustomCheckbox/CustomCheckbox";

export default {
  components: {
    // CustomCheckbox,
  },
  props: {
    CustomCheckboxheduleStart: Number, //minutes
    scheduleEnd: Number, //minutes
    breakDuration: Number, //minutes
    scheduleType: Number,
    isVacation: Boolean,
    plannedTime: Number,
    isvacationType: Number,
    totalAttendance: Number,

    totalActive: Number,
    inOuts: Array,
    inactivities: {
      type: Array,
      default: () => [],
    },
    firstIn: Number,
    lastOut: Number,
    outIns: Array,
    firstInList: Boolean,
    totalWork: Number, //minutes
    idToSend: [Number, String],
    scheduleId: [Number, String],
  },
  data() {
    return {
      hovering: false,

      hoveringX: 0,
      hoveringY: 0,
    };
  },
  methods: {
    msToTime(duration) {
      var milliseconds = parseInt((duration % 1000) / 100),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;

      return hours + ":" + minutes;
    },
    checkIfExist(val) {
      if (!val.includes("NaN")) return val;
      return "00:00";
    },
    hoverHandler(e) {
      this.hovering = true;
      var rect = e.target.getBoundingClientRect();
      this.hoveringX = rect.x;
      this.hoveringY = rect.y;
    },
  },
  computed: {
    inOutStyling() {
      var style = {
        left: this.hoveringX + "px",
        top: this.hoveringY + 20 + "px",
      };
      if (this.hoveringY > window.outerHeight / 2) {
        style.transform = "translateY(-100%)";
        style.top = this.hoveringY - 5 + "px";
      }
      return style;
    },
    inOutsForPortal() {
      var ins = [];
      var outs = [];
      this.inOuts.forEach((el) => {
        
        let d = new Date(el.time);
        let time = `${("0" + d.getHours()).slice(-2)}:${(
          "0" + d.getMinutes()
        ).slice(-2)}`;
        // let status = el.status
        if (el.type == 1) ins.push({ in: time });
        else if (el.type == 2 && el.status !== false) outs.push({ out: time });
      });
      var inOutArr = [];
      for (let i = 0; i < Math.max(ins.length, outs.length); i++) {
        inOutArr.push({ ...ins[i], ...outs[i] });
      }
      return inOutArr;
    },

    attendanceDifference() {
      if (this.isVacation && this.isvacationType === 1)
        return this.totalAttendance;
      if (this.isVacation && !this.totalAttendance && this.scheduleType === 1)
        return 0;
      return this.totalAttendance - this.plannedTime;
    },

    attendanceDifferenceString() {
      if (this.attendanceDifference == 0) return "00:00";
      return this.attendanceDifference > 0
        ? "+" + this.msToTime(this.attendanceDifference)
        : "-" + this.msToTime(Math.abs(this.attendanceDifference));
    },
  },
};
</script>

<style scoped lang="scss">
.totals {
  background-color: #f7f7fe;

  &.first .container {
    margin-top: 52 * $rem;
  }

  .container {
    margin-left: auto;
    width: 138 * $rem;
    margin-right: 37 * $rem;
    margin-top: 25 * $rem;
    .label.active {
      cursor: pointer;
    }
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 6.9 * $rem;

      &.hover {
        cursor: pointer;
      }

      &:last-child {
        margin-bottom: none;
      }

      .label {
        color: rgba($blue-primary, 0.7);
        font-weight: 300;
        font-size: 12 * $rem;
        cursor: pointer;
      }

      .value {
        margin-left: auto;
        color: #9b9ba5;
        font-weight: 400;
        font-size: 12 * $rem;

        &.positive {
          color: #0fcb9c;
        }

        &.negative {
          color: rgba(#db526a, 0.77);
        }
      }
    }
  }
}
</style>
